import React from 'react';
import Layout from '../../base/LayoutTableDetail';
import Detail from './FotoAyarDetail';


const COLUMNS_DEFAULT = [
  { id: 'buyuk_genislik',       tableProp: 'buyuk_genislik',                filterProp: 'buyuk_genislik',           label: 'Büyük Fotorağ Genişlik Ayarı',    element: 'input',           type: 'number',   arrayTableProp: null, show: true,  sortable: true,  highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'buyuk_yukseklik',      tableProp: 'buyuk_yukseklik',               filterProp: 'buyuk_yukseklik',          label: 'Büyük Fotorağ Yükseklik Ayarı',   element: 'input',           type: 'number',   arrayTableProp: null, show: true,  sortable: true,  highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'kucuk_genislik',       tableProp: 'kucuk_genislik',                filterProp: 'kucuk_genislik',           label: 'Küçük Fotorağ Genişlik Ayarı',    element: 'input',           type: 'number',   arrayTableProp: null, show: true,  sortable: true,  highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'kucuk_yukseklik',      tableProp: 'kucuk_yukseklik',               filterProp: 'kucuk_yukseklik',          label: 'Küçük Fotorağ Yükseklik Ayarı',   element: 'input',           type: 'number',   arrayTableProp: null, show: true,  sortable: true,  highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'olusturan_kullanici',  tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',   label: 'Oluşturan Kullanıcı',             element: 'select-multiple', type: 'number',   arrayTableProp: null, show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'degistiren_kullanici', tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',  label: 'Değiştiren Kullanıcı',            element: 'select-multiple', type: 'number',   arrayTableProp: null, show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'olusturma_zamani',     tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',         label: 'Oluşturma',                       element: 'input',           type: 'datetime', arrayTableProp: null, show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'degistirme_zamani',    tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',        label: 'Son Değişiklik',                  element: 'input',           type: 'datetime', arrayTableProp: null, show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
];

const PageFotoAyarList = () => {

  return (
    <>
      <Layout
        title="FOTOĞRAF AYARLARI"
        path="fotoayar"
        columnsDefault={COLUMNS_DEFAULT}
        detailComponent={Detail}
        storekey="fotoayar"
      />
    </>
  );
};

export default PageFotoAyarList;
