export const QUERY_STRING_OPTIONS = {
  arrayFormat: 'repeat',
  // arrayFormat: 'comma',
  allowDots: true,
  skipNull: true,
  skipEmptyString: true,
  // parseBooleans: true,
  // parseNumbers: true
};

