import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isEmpty as _isEmpty,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Checkbox,
  Typography,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';

import django from '../../../api/django';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },
    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
};

const CariDetailTab_Adresler = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.defaultSelectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  const IS_BIREYSEL = useMemo(() => _result(FORM, 'adres_tip', '0') === '0', [FORM]);

  const ulkeId = useMemo(() => FORM?.ulke ?? '-1', [FORM]);
  const sehirId = useMemo(() => FORM?.sehir ?? '-1', [FORM]);

  const [sehirler, setSehirler] = useState([]);
  const [sehirlerDic, setSehirlerDic] = useState({});
  const [ilceler, setIlceler] = useState([]);
  const [ilcelerDic, setIlcelerDic] = useState({});


  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.form.tanim,
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);

  useLayoutEffect(() => {
    const setDataAsync = async () => {
      if (ulkeId !== '-1') {
        const sehirdata = django(`ulke/${ulkeId}/sehir/mini_list`);
        await sehirdata.then(({ data }) => {
          const dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
          setSehirlerDic(dict);
          setSehirler(data);
        });
        sehirdata.then(({ data }) => {
          const selected = data.find(sehir => sehir.id === Number(sehirId));
          if (selected) {
            django(`ulke/${ulkeId}/sehir/${selected.id}/ilce/mini_list`).then(({ data }) => {
              const dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
              setIlcelerDic(dict);
              setIlceler(data);
            });
          } else {
            setIlceler([]);
          }
        });
      } else {
        setSehirler([]);
        setIlceler([]);
      }
    }
    setDataAsync();
  }, [ulkeId, sehirId]);

  useLayoutEffect(() => {
    if (!props.adreslerAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.adreslerAdd, COUNT_TABS]);


  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const handleChangeForm = (property, value) => {
    props.onChangeForm(selectedTabIndex, property, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleSetErrors = (errors, remove = null) => {
    props.onCheckErrors(errors, selectedTabIndex, remove);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <LayoutDetailVerticalTab
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                add={props.onAddNewTab}
                buttonText={"YENİ ADRES EKLE"}
                buttonShow={props.adreslerAdd}
                delete={handleDetailDelete}
              >

                {COUNT_TABS === 0 && (
                  <div className="flex flex-col items-start justify-start p-4 space-y-4">
                    <Typography variant="body1">
                      Cariye ait bir adres bulunamadı.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      onClick={props.onAddNewTab}
                    >YENİ ADRES EKLE</Button>
                  </div>
                )}

                {COUNT_TABS > 0 && (
                  <>
                    <div className={classes.tabDiv}>
                      <div className={classes.column}>
                        <Typography variant="h6" component="h3">Adres Bilgileri</Typography>

                        <ul className={classes.formList}>
                          <li className={classes.formItem} id="adres_tip">
                            <label className={classes.formItemLabel} htmlFor="adres_tip">Tip</label>
                            <Tooltip title={ERROR?.adres_tip ? ERROR.adres_tip[0] : FORM?.adres_tip === "0" ? 'Bireysel' : 'Kurumsal'}>
                              <TextField
                                {...createTextFieldProps('adres_tip')}
                                onChange={(e) => handleChangeForm('adres_tip', e.target.value)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                <option value='0'>Bireysel</option>
                                <option value='1'>Kurumsal</option>
                              </TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="tanim">
                            <label className={classes.formItemLabel} htmlFor="tanim">Tanım *</label>
                            <Tooltip title={ERROR?.tanim ? ERROR.tanim[0] : _isNil(FORM?.tanim) ? '' : FORM.tanim}>
                              <TextField
                                {...createTextFieldProps('tanim')}
                                onChange={(e) => handleChangeForm('tanim', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'tanim', handleSetErrors)}
                                inputProps={{
                                  maxLength: 250,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="adres">
                            <label className={classes.formItemLabel} htmlFor="adres">Adres *</label>
                            <Tooltip title={ERROR?.adres ? ERROR.adres[0] : _isNil(FORM?.adres) ? '' : FORM.adres}>
                              <TextField
                                {...createTextFieldProps('adres')}
                                onChange={(e) => handleChangeForm('adres', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'adres', handleSetErrors)}
                                multiline
                                rows={3}
                                inputProps={{
                                  maxLength: 255,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="tarifi">
                            <label className={classes.formItemLabel} htmlFor="tarifi">Adres Tarifi</label>
                            <Tooltip title={ERROR?.tarifi ? ERROR.tarifi[0] : _isNil(FORM?.tarifi) ? '' : FORM.tarifi}>
                              <TextField
                                {...createTextFieldProps('tarifi')}
                                onChange={(e) => handleChangeForm('tarifi', e.target.value)}
                                multiline
                                rows={3}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="posta_kodu">
                            <label className={classes.formItemLabel} htmlFor="posta_kodu">Posta Kodu</label>
                            <Tooltip title={ERROR?.posta_kodu ? ERROR.posta_kodu[0] : _isNil(FORM?.posta_kodu) ? '' : FORM.posta_kodu}>
                              <TextField
                                {...createTextFieldProps('posta_kodu')}
                                onChange={(e) => handleChangeForm('posta_kodu', e.target.value)}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="adres_kodu">
                            <label className={classes.formItemLabel} htmlFor="adres_kodu">Adres Kodu</label>
                            <Tooltip title={ERROR?.adres_kodu ? ERROR.adres_kodu[0] : _isNil(FORM?.adres_kodu) ? '' : FORM.adres_kodu}>
                              <TextField
                                {...createTextFieldProps('adres_kodu')}
                                onChange={(e) => handleChangeForm('adres_kodu', e.target.value)}
                                inputProps={{
                                  maxLength: 20,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="ulke">
                            <label className={classes.formItemLabel} htmlFor="ulke">Ülke *</label>
                            <Tooltip title={ERROR?.ulke ? ERROR.ulke[0] : (FORM?.ulke === "-1" || _isNil(FORM?.ulke)) ? '' : props.ulkelerDic ? props.ulkelerDic[FORM.ulke] : ''}>
                              <TextField
                                {...createTextFieldProps('ulke', '-1')}
                                onChange={(e) => {
                                  handleChangeForm('ulke', e.target.value);
                                  handleChangeForm('ilce', '-1');
                                }}
                                onBlur={() => handleInputErrors2(FORM, 'ulke', handleSetErrors)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                <option value='-1' >Seçilmedi</option>
                                {props.ulkeler?.map((ulke) => (
                                  <option value={ulke.id} key={ulke.id}>{ulke.tanim}</option>
                                ))}
                              </TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="sehir">
                            <label className={classes.formItemLabel} htmlFor="sehir">Şehir *</label>
                            <Tooltip title={ERROR?.sehir ? ERROR.sehir[0] : (FORM?.sehir === "-1" || _isNil(FORM?.sehir)) ? '' : !_isEmpty(sehirlerDic) ? sehirlerDic[FORM.sehir] : ''}>
                              <TextField
                                {...createTextFieldProps('sehir', '-1')}
                                onChange={(e) => {
                                  handleChangeForm('sehir', e.target.value);
                                  handleChangeForm('ilce', '-1');
                                }}
                                onBlur={() => handleInputErrors2(FORM, 'sehir', handleSetErrors)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                <option value='-1' >Seçilmedi</option>
                                {sehirler?.map((sehir) => (
                                  <option value={sehir.id} key={sehir.id}>{sehir.tanim}</option>
                                ))}
                              </TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="ilce">
                            <label className={classes.formItemLabel} htmlFor="ilce">İlçe *</label>
                            <Tooltip title={ERROR?.ilce ? ERROR.ilce[0] : (FORM?.ilce === "-1" || _isNil(FORM?.ilce)) ? '' : !_isEmpty(ilcelerDic) ? ilcelerDic[FORM.ilce] : ''}>
                              <TextField
                                {...createTextFieldProps('ilce', '-1')}
                                onChange={(e) => {
                                  handleChangeForm('ilce', e.target.value);
                                }}
                                onBlur={() => handleInputErrors2(FORM, 'ilce', handleSetErrors)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                <option value='-1' >Seçilmedi</option>
                                {ilceler?.map((ilce) => (
                                  <option value={ilce.id} key={ilce.id}>{ilce.tanim}</option>
                                ))}
                              </TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="mahalle">
                            <label className={classes.formItemLabel} htmlFor="mahalle">Mahalle</label>
                            <Tooltip title={ERROR?.mahalle ? ERROR.mahalle[0] : _isNil(FORM?.mahalle) ? '' : FORM.mahalle}>
                              <TextField
                                {...createTextFieldProps('mahalle')}
                                onChange={(e) => handleChangeForm('mahalle', e.target.value)}
                                inputProps={{
                                  maxLength: 255,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="siralama">
                            <label className={classes.formItemLabel} htmlFor="siralama">Sıralama</label>
                            <Tooltip title={ERROR?.siralama ? ERROR.siralama[0] : _isNil(FORM?.siralama) ? '' : FORM.siralama}>
                              <TextField
                                {...createTextFieldProps('siralama')}
                                onChange={(e) => handleChangeForm('siralama', e.target.value)}
                                type="number"
                                inputProps={{
                                  min: -2147483648,
                                  max: 2147483647,
                                  step: 1,
                                  style: { textAlign: 'right' }
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="aktif">
                            <label className={classes.formItemLabel} htmlFor="aktif">Aktif</label>
                            <Checkbox
                              name="aktif"
                              color="primary"
                              checked={_result(FORM, 'aktif', false)}
                              onChange={(e, checked) => handleChangeForm('aktif', checked)}
                            ></Checkbox>
                          </li>

                        </ul>
                      </div>

                      <div className={classes.column}>
                        <Typography variant="h6" component="h3">Kişi Bilgileri</Typography>
                        <ul className={classes.formList}>
                          <li className={classes.formItem} id="firma_adi">
                            <label className={classes.formItemLabel} htmlFor="firma_adi">Firma Adı</label>
                            <Tooltip title={ERROR?.firma_adi ? ERROR.firma_adi[0] : _isNil(FORM?.firma_adi) ? '' : FORM.firma_adi}>
                              <TextField
                                {...createTextFieldProps('firma_adi')}
                                onChange={(e) => handleChangeForm('firma_adi', e.target.value)}
                                onBlur={() => {
                                  if (FORM.adres_tip === "1") {
                                    handleInputErrors2(FORM, 'firma_adi', handleSetErrors)
                                  } else {
                                    handleSetErrors(null, 'firma_adi')
                                  }
                                }}
                                inputProps={{
                                  maxLength: 250,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="ad_soyad">
                            <label className={classes.formItemLabel} htmlFor="ad_soyad">Ad Soyad *</label>
                            <Tooltip title={ERROR?.ad_soyad ? ERROR.ad_soyad[0] : _isNil(FORM?.ad_soyad) ? '' : FORM.ad_soyad}>
                              <TextField
                                {...createTextFieldProps('ad_soyad')}
                                onChange={(e) => handleChangeForm('ad_soyad', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'ad_soyad', handleSetErrors)}
                                inputProps={{
                                  maxLength: 250,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="email">
                            <label className={classes.formItemLabel} htmlFor="email">E-Posta Adresi</label>
                            <Tooltip title={ERROR?.email ? ERROR.email[0] : _isNil(FORM?.email) ? '' : FORM.email}>
                              <TextField
                                {...createTextFieldProps('email')}
                                onChange={(e) => handleChangeForm('email', e.target.value)}
                                inputProps={{
                                  maxLength: 254,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="web_sitesi">
                            <label className={classes.formItemLabel} htmlFor="web_sitesi">Websitesi</label>
                            <Tooltip title={ERROR?.web_sitesi ? ERROR.web_sitesi[0] : _isNil(FORM?.web_sitesi) ? '' : FORM.web_sitesi}>
                              <TextField
                                {...createTextFieldProps('web_sitesi')}
                                onChange={(e) => handleChangeForm('web_sitesi', e.target.value)}
                                inputProps={{
                                  maxLength: 200,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="tel_no">
                            <label className={classes.formItemLabel} htmlFor="tel_no">Tel. No</label>
                            <Tooltip title={ERROR?.tel_no ? ERROR.tel_no[0] : _isNil(FORM?.tel_no) ? '' : FORM.tel_no}>
                              <TextField
                                {...createTextFieldProps('tel_no')}
                                onChange={(e) => handleChangeForm('tel_no', e.target.value)}
                                inputProps={{
                                  maxLength: 20,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="gsm_no">
                            <label className={classes.formItemLabel} htmlFor="gsm_no">GSM No</label>
                            <Tooltip title={ERROR?.gsm_no ? ERROR.gsm_no[0] : _isNil(FORM?.gsm_no) ? '' : FORM.gsm_no}>
                              <TextField
                                {...createTextFieldProps('gsm_no')}
                                onChange={(e) => handleChangeForm('gsm_no', e.target.value)}
                                inputProps={{
                                  maxLength: 20,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>
                        </ul>
                        <Typography variant="h6" component="h3">Vergi Bilgileri</Typography>
                        {IS_BIREYSEL && (
                          <ul className={classes.formList}>
                            <li className={classes.formItem} id="vergi_no">
                              <label className={classes.formItemLabel} htmlFor="vergi_no">TC Kimlik No</label>
                              <Tooltip title={ERROR?.vergi_no ? ERROR.vergi_no[0] : _isNil(FORM?.vergi_no) ? '' : FORM.vergi_no}>
                                <TextField
                                  {...createTextFieldProps('vergi_no')}
                                  onChange={(e) => handleChangeForm('vergi_no', e.target.value)}
                                  inputProps={{
                                    maxLength: 11,
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>
                          </ul>
                        )}

                        {!IS_BIREYSEL && (
                          <ul className={classes.formList}>
                            <li className={classes.formItem} id="vergi_no">
                              <label className={classes.formItemLabel} htmlFor="vergi_no">Vergi No</label>
                              <Tooltip title={ERROR?.vergi_no ? ERROR.vergi_no[0] : _isNil(FORM?.vergi_no) ? '' : FORM.vergi_no}>
                                <TextField
                                  {...createTextFieldProps('vergi_no')}
                                  onChange={(e) => handleChangeForm('vergi_no', e.target.value)}
                                  inputProps={{
                                    maxLength: 20,
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>
                            <li className={classes.formItem} id="vergi_dairesi">
                              <label className={classes.formItemLabel} htmlFor="vergi_dairesi">Vergi Dairesi</label>
                              <Tooltip title={ERROR?.vergi_dairesi ? ERROR.vergi_dairesi[0] : _isNil(FORM?.vergi_dairesi) ? '' : FORM.vergi_dairesi}>
                                <TextField
                                  {...createTextFieldProps('vergi_dairesi')}
                                  onChange={(e) => handleChangeForm('vergi_dairesi', e.target.value)}
                                  inputProps={{
                                    maxLength: 255,
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>
                          </ul>
                        )}

                      </div>
                    </div>
                  </>
                )}

              </LayoutDetailVerticalTab>
            </div>
          )}
        </>
      }
    </>
  );
});

export default CariDetailTab_Adresler;
