import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  IconButton,
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  Tooltip,
  Button
} from '@material-ui/core';

import {
  Search as IconSearch,
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import ListSelectorSingle from '../../../components/ListSelectorSingle';
import ListSelectorTwoStep from '../../../components/ListSelectorTwoStep';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& + &': {
        marginLeft: 10,
      },
    },
    button2: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
  };
};

const GmerchantDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const [openSelectorMagaza, setopenSelectorMagaza] = useState(false);
  const [openSelectorDil, setOpenSelectorDil] = useState(false);
  const [openSelectorMarka, setOpenSelectorMarka] = useState(false);
  const [detayListOpen, setDetayListOpen] = useState(true);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form[name], `${name2}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form, `${name}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    }
  }, [props.error, props.form]);


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="account_name">
                    <label className={classes.layoutFormItemLabel} htmlFor="account_name">Account Name *</label>
                    <Tooltip title={props.error?.account_name ? props.error.account_name[0] : _isNil(props.form?.account_name) ? '' : props.form.account_name}>
                      <TextField
                        {...createTextFieldProps('account_name', '')}
                        onChange={(e) => props.onChangeForm('account_name', e.target.value)}
                        onBlur={() => handleInputErrors('gmerhantaccount', props.form, props.formdefault, props.onCheckErrors, add, 'account_name', true, true, false, '', 'Account Name', 'hesap')}
                        inputProps={{
                          maxLength: 128,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="merchant_id">
                    <label className={classes.layoutFormItemLabel} htmlFor="merchant_id">Merchant Id *</label>
                    <Tooltip title={props.error?.merchant_id ? props.error.merchant_id[0] : _isNil(props.form?.merchant_id) ? '' : props.form.merchant_id}>
                      <TextField
                        {...createTextFieldProps('merchant_id', '')}
                        onChange={(e) => props.onChangeForm('merchant_id', e.target.value)}
                        onBlur={() => handleInputErrors('gmerhantaccount', props.form, props.formdefault, props.onCheckErrors, add, 'merchant_id', true, true, false, '', 'Merchant Id', 'hesap')}
                        inputProps={{
                          maxLength: 64,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="magaza">
                    <label className={classes.layoutFormItemLabel} htmlFor="magaza">Mağaza</label>
                    <Tooltip title={props.error?.magaza ? props.error.magaza[0] : _isNil(props.form?.magaza) ? '' : props.form.magaza.tanim}>
                      <TextField
                        {...createTextFieldProps('magaza', 'tanim')}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button2}
                              disableRipple
                              onClick={() => setopenSelectorMagaza(true)}
                            ><IconSearch /></IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="dil">
                    <label className={classes.layoutFormItemLabel} htmlFor="dil">Dil</label>
                    <Tooltip title={props.error?.dil ? props.error.dil[0] : _isNil(props.form?.dil) ? '' : props.form.dil.tanim}>
                      <TextField
                        {...createTextFieldProps('dil', 'tanim')}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button2}
                              disableRipple
                              onClick={() => setOpenSelectorDil(true)}
                            ><IconSearch /></IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="katalog_iliskili_calis">
                    <label className={classes.layoutFormItemLabel} htmlFor="katalog_iliskili_calis">Katalog İlişkili Çalış</label>
                    <Checkbox
                      name="katalog_iliskili_calis"
                      color="primary"
                      checked={_result(props.form, 'katalog_iliskili_calis', false)}
                      onChange={(e, checked) => props.onChangeForm('katalog_iliskili_calis', checked)}
                    ></Checkbox>
                  </li>
                </ul>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="marka_basliklari">
                    <label className={classes.layoutFormItemLabel} htmlFor="marka_basliklari">Marka Başlıkları</label>
                    <Button
                      onClick={() => setOpenSelectorMarka(true)}
                      className={classes.button}
                      component="label"
                    >BAŞLIK SEÇ</Button>
                  </li>
                  {props.form?.marka_basliklari.length > 0 && (
                    <li className={classes.layoutFormItem} id="marka_basliklari_list">
                      <label className={classes.layoutFormItemLabel} htmlFor="marka_basliklari_list"></label>
                      <div>{props.form.marka_basliklari.join(", ")}</div>
                    </li>
                  )}
                </ul>

              </div>
              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setopenSelectorMagaza(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Mağazalar'
                      listPath='magaza/mini_list?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.magaza}
                      onChangeItems={(items) => props.onChangeForm('magaza', items)}
                      onClose={() => setopenSelectorMagaza(false)}
                      newPath='tanimlar/magaza'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorDil &&
                <Dialog
                  open={openSelectorDil}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorDil(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Diller'
                      listPath='dil/mini_list?aktif=true'
                      listCountPath='dil/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.dil}
                      onChangeItems={(items) => props.onChangeForm('dil', items)}
                      onClose={() => setOpenSelectorDil(false)}
                      newPath='tanimlar/diller'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorMarka &&
                <Dialog
                  open={openSelectorMarka}
                  PaperProps={{
                    style: {
                      background: 'transparent',
                      height: 600,
                      width: detayListOpen ? '100%' : 480
                    },
                  }}
                  fullWidth
                  maxWidth={detayListOpen ? 'md' : 'sm'}
                  onClose={() => setOpenSelectorMarka(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorTwoStep
                      title="Özellikler"
                      mainItemsPath='ozellikbaslik'
                      mainItemsCountPath='ozellikbaslik/count'
                      mainDefaultItems={props.form.ozellik_basliklar}
                      mainMultiple={true}
                      mainKeyProp='id'
                      mainValueProp='tanim'
                      listPath='ozellikdeger'
                      listCountPath='ozellikdeger/count'
                      listMultiple={true}
                      keyProp='id'
                      valueProp='tanim'
                      keyUId='ozellik_baslik_id'
                      valueUV='ozellik_baslik_tanim'
                      defaultItems={props.form.ozellik_degerler}
                      onChangeItems={(items) => props.onChangeForm('marka_basliklari', items)}
                      onClose={() => {
                        setOpenSelectorMarka(false);
                      }}
                      onDetayListClose={(res) => setDetayListOpen(res)}
                      detayListOpen={detayListOpen}
                      detayList={props.form.ozellik_tree_list}
                      url="tanimlar/ozellikdetay"
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default GmerchantDetailTabGenel;
