import React, { useMemo, useState, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  Tooltip
} from '@material-ui/core';

import {
  Search as IconSearch
} from '@material-ui/icons';

import ListSelectorSingle from '../../../components/ListSelectorSingle';

import { withStyles } from '@material-ui/core/styles';
import { Hata } from '../../base/Hata';
import { handleInputErrors } from '../../base/InputErrors';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
  };
};

const BilesenDetailTabMenu = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const [openSelectorMenu, setOpenSelectorMenu] = useState(false);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form.menu, 'tanim') || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="menu">
                    <label className={classes.layoutFormItemLabel} htmlFor="menu">Menü *</label>
                    <Tooltip title={props.error?.menu ? props.error.menu[0] : _isNil(props.form?.menu?.tanim) ? '' : props.form.menu.tanim}>
                      <TextField
                        {...createTextFieldProps('menu')}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button}
                              disableRipple
                              onClick={() => setOpenSelectorMenu(true)}
                            ><IconSearch /></IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                </ul>
              </div>
              {openSelectorMenu &&
                <Dialog
                  open={openSelectorMenu}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorMenu(false);
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, false, 'menu', true, false, false, '', '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Menuler'
                      listPath='menu/mini_list?aktif=true'
                      listCountPath='menu/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.menu}
                      onChangeItems={(items) => props.onChangeForm('menu', items)}
                      onClose={() => {
                        setOpenSelectorMenu(false);
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, false, 'menu', true, false, false, '', '', '');
                      }}
                      newPath='tanimlar/menu'
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default BilesenDetailTabMenu;
