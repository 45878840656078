import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../../pages/base/LayoutTableDetail';
import Detail from './MagazaDetail';
import django from '../../../api/django';

const COLUMNS_DEFAULT = [
  { id: 'tanim',                tableProp: 'tanim',                         filterProp: 'tanim',                  label: 'Mağaza Adı',           element: 'input',            type: 'text',     arrayTableProp: null,     show: true, sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'depolar',              tableProp: 'depolar',                       filterProp: 'depolar.depo.id',        label: 'Depolar',              element: 'select-multiple',  type: 'array',    arrayTableProp: 'tanim',  show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'depo',       multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null,  selectKey: null,  selectValue: null , newpath:"tanimlar/depolar" },
  { id: 'magaza_cikis_depo',    tableProp: 'magaza_cikis_depo.tanim',       filterProp: 'magaza_cikis_depo.id',   label: 'Çıkış Depo',           element: 'select-multiple',  type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'depo',       multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null,  selectKey: null,  selectValue: null , newpath:"tanimlar/depolar" },
  { id: 'site',                 tableProp: 'site.domain',                   filterProp: 'site.id',                label: 'Site',                 element: 'select-multiple',  type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'site',       multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'domain',   multipleSelectOptionViewProp: 'domain',   shortDescription: null,  selectKey: null,  selectValue: null , newpath:"tanimlar/site" },
  { id: 'para_birim',           tableProp: 'para_birim.tanim',              filterProp: 'para_birim.id',          label: 'Para Birimi',          element: 'select',           type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'id',   selectValue:'tanim'},
  { id: 'ulke',                 tableProp: 'ulke.tanim',                    filterProp: 'ulke.id',                label: 'Ülke',                 element: 'select',           type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'id',   selectValue:'tanim'},
  { id: 'siralama',             tableProp: 'siralama',                      filterProp: 'siralama',               label: 'Sıralama',             element: 'input',            type: 'number',   arrayTableProp: null,     show: true, sortable: false, highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'aktif',                tableProp: 'aktif',                         filterProp: 'aktif',                  label: 'Aktif',                element: 'select',           type: 'boolean',  arrayTableProp: null,     show: true, sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'olusturan_kullanici',  tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id', label: 'Oluşturan Kullanıcı',  element: 'select-multiple',  type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistiren_kullanici', tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',label: 'Değiştiren Kullanıcı', element: 'select-multiple',  type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'olusturma_zamani',     tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',       label: 'Oluşturma',            element: 'input',            type: 'datetime', arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistirme_zamani',    tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',      label: 'Son Değişiklik',       element: 'input',            type: 'datetime', arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
];

const PageMagazaList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        } 
        return column;
      });
    });
  }, []);

  useLayoutEffect(() => {
    django('parabirim/mini_list?aktif=true').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'para_birim') {
            return {
              ...column,
              selectOptions: data,
            };
          }   
          return column;
        });
      });
    })
  }, []);

  useLayoutEffect(() => {
    django('ulke/mini_list?aktif=true').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'ulke') {
            return {
              ...column,
              selectOptions: data,
            };
          }   
          return column;
        });
      });
    })
  }, []);

  return (
    <>
      <Layout
        title="MAĞAZALAR"
        path="magaza"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="magaza"
      />
    </>
  );
};

export default PageMagazaList;
