import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';
import moment from 'moment';
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  CircularProgress,
  Typography,
  Checkbox
} from '@material-ui/core';
import { NumericFormat } from 'react-number-format';
import {
  Add as IconAdd,
  Delete as IconDelete,
  ArrowDropDown as IconArrowDropDown,
  ArrowDropUp as IconArrowDropUp,
  Search as IconSearch,
  Close as IconClose,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import ListSelectorSingle from '../../../components/ListSelectorSingle';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import { MoneyFormatConvert } from '../../../helpers/helpers'

const styles = ({ spacing, palette }) => {
  return {
    layoutContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
      whiteSpace: 'nowrap',
      width: 'max-content',
      minWidth: "100%",
    },
    borderLayout: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      width: 'max-content',
      minWidth: "100%",
      padding: spacing(1),
      border: "1px solid",
      borderColor: palette.primary.main,
      borderRadius: 4,
      height: '100%'
    },
    borderLayout2: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      width: '100%',
      padding: spacing(1),
      border: "1px solid",
      borderColor: palette.primary.main,
      borderRadius: 4,
      height: 'fit-content'
    },
    detailOpen: {
      position: 'absolute',
      left: 0,
      background: "transparent",
      '& .MuiIconButton-root': {
        color: palette.primary.main
      }
    },
    detailClose: {
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      background: "transparent",
      '& .MuiIconButton-root': {
        color: palette.primary.main
      }
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      position: 'relative',
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      }
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
      whiteSpace: 'initial',
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button3: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      fontSize: '0.75rem',
    },
    ekAlanList: {
      border: '1px solid #eacfea',
      padding: spacing(3.25, 1, 1),
      borderRadius: 4,
      position: 'relative',
    },
    ekAlanLayout: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateRows: 'repeat(7, 1fr)',
      gridColumnGap: 32,
      gridRowGap: 5
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    formListTable: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: "relative"
    },
    tableHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 35,
      borderBottom: `1px solid ${palette.divider}`,
    },
    tableHeaderItem: {
      marginRight: 5,
      fontWeight: 500,
      textAlign: "right",
      width: 120,
      whiteSpace: "normal"
    },
    tableFormRow: {
      borderBottom: `1px solid ${palette.divider}`,
      height: 35,
      display: "flex",
      alignItems: 'center',
      '&:hover': {
        backgroundColor: palette.action.hover,
      },
      '&:last-child': {
        borderBottomWidth: 0,
      }
    },
    tableFormItem: {
      marginRight: 5,
      width: 120,
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      },
    },
    selectedRow: {
      border: '1px solid #eacfea !important',
    },
  }
};

const TahsilatDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const LOADING_EK = useMemo(() => props.loadingEkAlan, [props.loadingEkAlan]);
  const LOADING_DETAY = useMemo(() => props.loadingdetay, [props.loadingdetay]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATA_DETAY = useMemo(() => props.hatadetay, [props.hatadetay]);
  const add = useMemo(() => props.add, [props.add]);

  const DETAYLISTE = useMemo(() => props?.detay.length > 0 ? props.detay : [], [props]);
  const LISTELENGTH = useMemo(() => props?.detay.length > 0 ? props.detay.length : 0, [props]);

  const [durum, setDurum] = useState('-1');
  const [selectedRow, setSelectedRow] = useState(null);
  const [lastRow, setLastRow] = useState(null);
  const [vadeSelectedRow, setVadeSelectedRow] = useState(0);

  const [openDetail, setOpenDetail] = useState(false);
  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);
  const [openSelectorCari, setOpenSelectorCari] = useState(false);
  const [openSelectorDurum, setOpenSelectorDurum] = useState(false);

  const [blockedAdd, setBlockedAdd] = useState(true);
  const [topOpen, setTopOpen] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const createTextFieldPropsGenel = useCallback((name, name2, defaultValue = '') => {
    switch (name) {
      case 'evrak_durum':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form[name]?.[name2], 'tanim') || defaultValue,
          error: _has(props.error?.evrak?.[name], `${name2}`),
          fullWidth: true,
        };
      case 'magaza':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form[name], `${name2}`, defaultValue) + ' ' + props.paraKoduList[props.form?.magaza?.para_birim.id ?? '-1'],
          error: _has(props.error?.evrak, `${name}`),
          fullWidth: true,
        };
      case 'evrak_kullanici':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form[name], `${name2}`) || defaultValue,
          error: _has(props.error?.evrak, `${name}`),
          fullWidth: true,
        };
      case 'evrak_kullanici_adi':
        return {
          name2,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form, 'evrak_kullanici.first_name', defaultValue) + ' ' + _result(props.form, 'evrak_kullanici.last_name', defaultValue),
          error: _has(props.error?.evrak, `${name2}`),
          fullWidth: true,
        };
      default:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form, `${name}`) || defaultValue,
          error: _has(props.error?.evrak, `${name}`),
          fullWidth: true,
        };
    }
  }, [props.error, props.form, props.paraKoduList]);

  const createTextFieldProps2 = useCallback((index, index2, name, name2, name3, u, defaultValue = '') => {
    switch (name) {
      case 'vade':
        if (name3 !== '') {
          return {
            name,
            variant: 'outlined',
            size: 'small',
            value: _result(u[name][index2][name2], `${name3}`) || defaultValue,
            error: _has(props.error?.tahsilat_detay?.[index]?.[name]?.[index2]?.[name2], `${name3}`),
            fullWidth: true,
          };
        } else {
          return {
            name,
            variant: 'outlined',
            size: 'small',
            value: _result(u[name][index2], `${name2}`) || defaultValue,
            error: _has(props.error?.tahsilat_detay?.[index]?.[name]?.[index2], `${name2}`),
            fullWidth: true,
          };
        }
      default:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(u, `${name}`) || defaultValue,
          error: _has(props.error?.tahsilat_detay?.[index], `${name}`),
          fullWidth: true,
        };
    }
  }, [props.error]);

  const createTextFieldPropsEkAlan = useCallback((item, name, type, index) => {
    switch (type) {
      case 0:
      case 3:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
        }
      case 2:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
          select: true,
          SelectProps: {
            native: true,
          }
        }
      case 1:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
          type: "number",
          inputProps: {
            style: { textAlign: 'right' }
          }
        }
      case 4:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
          rows: 3,
          multiline: true
        }
      case 5:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
          type: "date",
        }
      case 6:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
          type: "datetime-local"
        }
      case 7:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
          type: "time"
        }
      default:
        break;
    }
  }, [props.error]);

  const paralist = useMemo(() => {
    const list = [<option value="-1" key="-1" disabled>Seçilmedi</option>];
    props.paraBirimList.forEach((pb) => {
      list.push(<option value={pb.id} key={pb.id}>{pb.tanim}</option>)
    });
    return list;
  }, [props.paraBirimList]);

  useEffect(() => {
    if (props.form) {
      if (props.form.para_birimi === '-1' || !props.form.evrak_kullanici || !props.form.magaza) {
        setBlockedAdd(true);
      } else {
        if (LISTELENGTH > 0) {
          if (lastRow !== null) {
            if (DETAYLISTE[lastRow]?.tutar !== '' && DETAYLISTE[lastRow]?.para_birimi !== '-1' && DETAYLISTE[lastRow]?.kur !== '' && DETAYLISTE[lastRow]?.cari_hesap_kur !== '') {
              setBlockedAdd(false);
            } else {
              setBlockedAdd(true);
            }
          } else {
            setBlockedAdd(false);
          }
        } else {
          setBlockedAdd(false);
        }
      }
    }
  }, [lastRow, DETAYLISTE, LISTELENGTH, props.form]);

  const handleAddNewRow = () => {
    props.addNew();
    const index = LISTELENGTH;
    setSelectedRow(index);
    setLastRow(index);
    setVadeSelectedRow(0);
  }

  const handleDeleteRow = (di) => {
    const index = selectedRow;
    const length = LISTELENGTH;
    const deleteRow = async () => {
      props.deleteRow(di)
    }
    const setRows = () => {
      if (length > 1) {
        setSelectedRow(index - 1);
        setLastRow(length - 2);
      } else if (length === 1) {
        setSelectedRow(null);
        setLastRow(null);
      } else {
        setSelectedRow(null);
        setLastRow(null);
      }
      setVadeSelectedRow(0);
    }
    deleteRow().then(() => setRows())
  }

  const handleCekSenetBorclusu = (e, index, index2) => {
    setDurum(e);
    if (e === '1') {
      props.onChangeFormDetay(index, 'vade', 'kimin', '', index2, `${_result(props.form, 'evrak_kullanici.first_name', '')} ${_result(props.form, 'evrak_kullanici.last_name', '')}`)
    } else {
      props.onChangeFormDetay(index, 'vade', 'kimin', '', index2, '')
    }
  }

  const handleErrors = (errors) => {
    props.onCheckErrors("evrak", errors, null, null, null, null, null);
  }

  const handleErrors2 = (errors, name2 = null, index = null, length = null) => {
    props.onCheckErrors("tahsilat_detay", errors, selectedRow, LISTELENGTH, name2, index, length)
  }

  const handleErrors3 = (name, errors, index, length) => {
    props.onCheckErrors(name, errors, index, length, null, null, null)
  }

  return (
    <>
      {!LOADING && !LOADING_EK &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className="flex flex-col h-full w-full">
              <div className={classes.layoutContainer}>
                <div className={classes.borderLayout}>
                  {topOpen && (
                    <>
                      <ul className={classes.layoutFormList}>
                        <li className={classes.layoutFormItem} id="magaza">
                          <label className={classes.layoutFormItemLabel} htmlFor="magaza">Mağaza *</label>
                          <Tooltip title={props.error?.evrak?.magaza ? props.error.evrak.magaza[0] : _isNil(props.form?.magaza) ? '' : props.form.magaza.tanim}>
                            <TextField
                              {...createTextFieldPropsGenel('magaza', 'tanim')}
                              InputProps={{
                                endAdornment:
                                  <IconButton
                                    size="small"
                                    className={classes.button}
                                    disableRipple
                                    onClick={() => setOpenSelectorMagaza(true)}
                                  ><IconSearch /></IconButton>
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="versiyon">
                          <label className={classes.layoutFormItemLabel} htmlFor="versiyon">Versiyon</label>
                          <Tooltip title={props.error?.evrak?.versiyon ? props.error.evrak.versiyon[0] : _isNil(props.form?.versiyon) ? '' : props.form.versiyon}>
                            <TextField
                              {...createTextFieldPropsGenel('versiyon', '')}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="kodu">
                          <label className={classes.layoutFormItemLabel} htmlFor="kodu">Evrak No *</label>
                          <Tooltip title={props.error?.evrak?.kodu ? props.error.evrak.kodu[0] : _isNil(props.form?.kodu) ? '' : props.form.kodu}>
                            <TextField
                              {...createTextFieldPropsGenel('kodu', '')}
                              onChange={(e) => props.onChangeForm('kodu', e.target.value)}
                              onBlur={() => handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'kodu', true, true, false, '', 'Evrak No', 'evrak')}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="evrak_tarihi">
                          <label className={classes.layoutFormItemLabel} htmlFor="evrak_tarihi">Evrak Tarihi *</label>
                          <Tooltip title={props.error?.evrak?.evrak_tarihi ? props.error.evrak.evrak_tarihi[0] : _isNil(props.form?.evrak_tarihi) ? '' : moment(props.form.evrak_tarihi).format('DD.MM.YYYY, HH.mm')}>
                            <TextField
                              {...createTextFieldPropsGenel('evrak_tarihi', '')}
                              type="datetime-local"
                              onChange={(e) => props.onChangeForm('evrak_tarihi', e.target.value)}
                              onBlur={() => handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'evrak_tarihi', true, false, '', '')}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="tip">
                          <label className={classes.layoutFormItemLabel} htmlFor="tip">Evrak Tipi *</label>
                          <Tooltip title={props.error?.evrak?.tip ? props.error.evrak.tip[0] : 'Tahsilat'}>
                            <TextField
                              {...createTextFieldPropsGenel('tip', '')}
                              onChange={(e) => props.onChangeForm('tip', e.target.value)}
                              select
                              SelectProps={{
                                native: true,
                              }}
                            >
                              <option value='12' key='12'>Tahsilat</option>
                            </TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="para_birimi">
                          <label className={classes.layoutFormItemLabel} htmlFor="para_birimi">Evrak Para Birimi *</label>
                          <Tooltip title={props.error?.evrak?.para_birimi ? props.error.evrak.para_birimi[0] : (_isNil(props.form?.para_birimi) || props.form?.para_birimi === '-1') ? '' : props.paraBirimDic[props.form.para_birimi]}>
                            <TextField
                              {...createTextFieldPropsGenel('para_birimi', '')}
                              onChange={(e) => props.onChangeForm('para_birimi', e.target.value)}
                              select
                              SelectProps={{
                                native: true,
                              }}
                            >
                              {paralist}
                            </TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="evrak_durum.durum">
                          <label className={classes.layoutFormItemLabel} htmlFor="evrak_durum.durum">Evrak Durum</label>
                          <Tooltip title={props.error?.evrak?.evrak_durum?.durum ? props.error.evrak.evrak_durum.durum[0] : _isNil(props.form?.evrak_durum?.durum) ? '' : props.form.evrak_durum.durum.tanim}>
                            <TextField
                              {...createTextFieldPropsGenel('evrak_durum', 'durum')}
                              InputProps={{
                                endAdornment:
                                  <IconButton
                                    size="small"
                                    className={classes.button}
                                    disableRipple
                                    onClick={() => {
                                      if (props.form.magaza && props.form.evrak_kullanici) {
                                        setOpenSelectorDurum(true)
                                      } else {
                                        enqueueSnackbar("Lütfen önce mağaza ve evrak kullanıcısı seçin!", { variant: 'error' });
                                      }
                                    }}
                                  ><IconSearch /></IconButton>
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                      </ul>

                      <ul className={classes.layoutFormList}>
                        <li className={classes.layoutFormItem} id="evrak_kullanici">
                          <label className={classes.layoutFormItemLabel} htmlFor="evrak_kullanici">Cari Kod *</label>
                          <Tooltip title={props.error?.evrak?.evrak_kullanici ? props.error.evrak.evrak_kullanici[0] : _isNil(props.form?.evrak_kullanici) ? '' : props.form.evrak_kullanici.kodu}>
                            <TextField
                              {...createTextFieldPropsGenel('evrak_kullanici', 'kodu')}
                              InputProps={{
                                endAdornment:
                                  <IconButton
                                    size="small"
                                    className={classes.button}
                                    disableRipple
                                    onClick={() => setOpenSelectorCari(true)}
                                  ><IconSearch /></IconButton>
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="evrak_kullanici">
                          <label className={classes.layoutFormItemLabel} htmlFor="evrak_kullanici">Cari Hesap Adı *</label>
                          <Tooltip title={props.error?.evrak?.evrak_kullanici ? props.error.evrak.evrak_kullanici[0] : _isNil(props.form?.evrak_kullanici) ? '' : props.form.evrak_kullanici?.first_name + ' ' + props.form.evrak_kullanici?.last_name}>
                            <TextField
                              {...createTextFieldPropsGenel('evrak_kullanici_adi', 'evrak_kullanici')}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="genel_toplam">
                          <label className={classes.layoutFormItemLabel} htmlFor="genel_toplam">Genel Toplam</label>
                          <Tooltip title={props.error?.evrak?.genel_toplam ? props.error.evrak.genel_toplam[0] : _isNil(props.form?.genel_toplam) ? '' : MoneyFormatConvert(props.form.genel_toplam, "money")}>
                            <span className="flex w-full">
                              <NumericFormat
                                {...createTextFieldPropsGenel('genel_toplam', '')}
                                customInput={TextField}
                                thousandSeparator="."
                                decimalSeparator=","
                                inputProps={{
                                  style: { textAlign: 'right' },
                                }}
                                InputProps={{
                                  readOnly: true
                                }}
                                valueIsNumericString={true}
                              ></NumericFormat>
                            </span>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="aciklama" style={{ height: "auto" }}>
                          <label className={classes.layoutFormItemLabel} htmlFor="aciklama">Evrak Not</label>
                          <Tooltip title={props.error?.evrak?.aciklama ? props.error.evrak.aciklama[0] : props.form?.aciklama == null ? '' : props.form.aciklama}>
                            <TextField
                              {...createTextFieldPropsGenel('aciklama', '')}
                              multiline
                              rows={3}
                              onChange={(e) => props.onChangeForm('aciklama', e.target.value)}
                            ></TextField>
                          </Tooltip>
                        </li>
                        {props.form.evrak_durum !== null &&
                          <>
                            <li className={classes.layoutFormItem} id="evrak_durum.mail_gondersin">
                              <label className={classes.layoutFormItemLabel} htmlFor="evrak_durum.mail_gondersin">Eposta gönderilsin</label>
                              <Checkbox
                                name="evrak_durum.mail_gondersin"
                                color="primary"
                                checked={_result(props.form?.evrak_durum, 'mail_gondersin', false)}
                                onChange={(e, checked) => props.onChangeFormDurum('mail_gondersin', checked)}
                              ></Checkbox>
                            </li>
                            <li className={classes.layoutFormItem} id="evrak_durum.kullaniciya_mail_gondersin">
                              <label className={classes.layoutFormItemLabel} htmlFor="evrak_durum.kullaniciya_mail_gondersin">Evrak Kullanıcısına Eposta gönderilsin</label>
                              <Checkbox
                                name="evrak_durum.kullaniciya_mail_gondersin"
                                color="primary"
                                checked={_result(props.form?.evrak_durum, 'kullaniciya_mail_gondersin', false)}
                                onChange={(e, checked) => props.onChangeFormDurum('kullaniciya_mail_gondersin', checked)}
                              ></Checkbox>
                            </li>
                          </>
                        }
                      </ul>
                      {props.form?.ek_bilgiler.length > 0 &&
                        <ul className={classes.layoutFormList}>
                          <div className={classes.ekAlanList}>
                            <div className="absolute bg-palette-background-paper" style={{ top: -8, margin: 2 }}>Ek Alanlar</div>
                            <div className={classes.ekAlanLayout}>
                              {props.form?.ek_bilgiler?.map((item, index) => (
                                <li className={classes.layoutFormItem} style={{ marginTop: 0 }} id={item.tanim} key={item.tanim}>
                                  <label className={classes.layoutFormItemLabel} htmlFor={item.tanim}>{item.tanim}</label>
                                  <Tooltip title={props.error?.ek_bilgi?.[index]?.veri ? props.error.ek_bilgi[index].veri[0] : _isNil(item.veri) ? "" : item.alan_tip === 5 ? moment(item.veri).format('DD/MM/YYYY') : item.type === 6 ? moment(item.veri).format('DD/MM/YYYY, HH.mm') : item.alan_tip === 7 ? moment(item.veri).format('HH/MM') : item.veri}>
                                    <TextField
                                      {...createTextFieldPropsEkAlan(item, item.tanim, item.alan_tip, index)}
                                      onChange={(e) => props.onChangeForm('ek_bilgiler', e.target.value, index)}
                                      onBlur={() => {
                                        if (item.zorunlu) {
                                          handleInputErrors('', item, item, (errors) => handleErrors3("ek_bilgi", errors, index, props.form.ek_bilgiler.length), add, 'veri', true, false, false, '', '', '')
                                        }
                                      }}
                                    >
                                      {item.alan_tip === 2 && item.secim_degerleri?.map((sd) => (
                                        <option value={sd} key={sd}>{sd}</option>
                                      ))}
                                    </TextField>
                                  </Tooltip>
                                </li>
                              ))}
                            </div>
                          </div>
                        </ul>
                      }
                    </>
                  )}
                  {!topOpen && (
                    <div>
                      <span className="font-medium truncate text-base mr-10">Evrak Tarihi : {_result(props.form, 'evrak_tarihi') || ''}</span>
                      <span className="font-medium truncate text-base mr-10">Evrak No : {_result(props.form, 'kodu') || ''}</span>
                      <span className="font-medium truncate text-base mr-10">Mağaza : {_result(props.form?.magaza, 'tanim') || ''}</span>
                      <span className="font-medium truncate text-base mr-10">Cari Kod : {_result(props.form?.evrak_kullanici, 'kodu') || ''}</span>
                      <span className="font-medium truncate text-base mr-10">Genel Toplam : {(MoneyFormatConvert(_result(props.form, 'genel_toplam'), "money") || '') + ' ' + props.paraKoduList[props.form?.para_birimi ?? '-1']}</span>
                    </div>
                  )}
                </div>
                <div className={topOpen ? classes.detailOpen : classes.detailClose}>
                  <IconButton
                    style={{ width: "1rem", height: "1rem", padding: 0 }}
                    disableRipple
                    onClick={() => setTopOpen(!topOpen)}
                  >
                    {topOpen ? <IconArrowDropUp style={{ fontSize: "2rem" }} /> : <IconArrowDropDown style={{ fontSize: "2rem" }} />}
                  </IconButton>
                </div>
              </div>

              {!LOADING_DETAY && (
                <>
                  {HATA_DETAY && (
                    <Hata />
                  )}
                  {!HATA_DETAY && (
                    <div className={classes.layoutContainer}>
                      <div className={classes.borderLayout2}>
                        <div className={classes.formListTable}>
                          <div className={classes.tableHeader}>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 25 }}>
                              <IconButton
                                size="small"
                                disabled={blockedAdd}
                                disableRipple
                                onClick={() => handleAddNewRow()}
                              >
                                <IconAdd />
                              </IconButton>
                            </div>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>TİP</div>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 200 }}>TUTAR</div>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 250 }}>TANIM</div>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 200 }}>BANKA HESAP</div>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>PARA BİRİMİ</div>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>KUR</div>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>TAKSİT SAYISI</div>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>C/H DÖVİZ</div>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>C/H DÖVİZ KUR</div>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 200 }}>C/H DÖVİZ KUR TUTAR</div>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 100 }}>TAHSİL EDİLDİ/ÖDENDİ</div>
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 100 }}></div>
                          </div>
                          <div style={{ flex: '1 1 auto' }}>
                            {DETAYLISTE.map((item, index) => (
                              <div key={`detay-${index}`} className={`${classes.tableFormRow} ${selectedRow === index ? classes.selectedRow : ''}`}
                                onClick={() => {
                                  setSelectedRow(index);
                                  if (lastRow === null) {
                                    setLastRow(index)
                                  }
                                  setVadeSelectedRow(0);
                                }}
                              >
                                <div className={classes.tableFormItem} style={{ width: 25 }}>
                                  <Tooltip title="Sil">
                                    <IconButton
                                      size="small"
                                      disableRipple
                                      onClick={() => handleDeleteRow(index)}
                                    >
                                      <IconDelete />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <div style={{ width: 150 }} className={classes.tableFormItem}>
                                  <div className="flex items-center">
                                    <Tooltip title={props.error?.tahsilat_detay?.[index]?.tip ? props.error.tahsilat_detay[index].tip[0] : _isNil(item.tip) ? '' : props.odemeTipDic ? props.odemeTipDic[item.tip] : ''}>
                                      <TextField
                                        {...createTextFieldProps2(index, null, 'tip', '', '', item, '')}
                                        onChange={(e) => props.onChangeFormDetay(index, 'tip', '', '', '', e.target.value)}
                                        select
                                        SelectProps={{
                                          native: true,
                                        }}
                                      >
                                        {props.odemeTip?.map((ot) => (
                                          <option key={ot.key} value={ot.key}>{ot.value}</option>
                                        ))}
                                      </TextField>
                                    </Tooltip>
                                  </div>
                                </div>

                                <div style={{ width: 200 }} className={classes.tableFormItem}>
                                  <div className="flex items-center">
                                    {(item.tip !== '3') && (
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.tutar ? props.error.tahsilat_detay[index].tutar[0] : (_isNil(item.tutar) || item.tutar === "") ? '' : MoneyFormatConvert(item.tutar, "money") + " " + props.paraKoduList[item.para_birimi ?? '-1']}>
                                        <span className='flex w-full'>
                                          <NumericFormat
                                            {...createTextFieldProps2(index, null, 'tutar', '', '', item, '')}
                                            onValueChange={(e) => props.onChangeFormDetay(index, 'tutar', '', '', '', e.value)}
                                            onBlur={() => handleInputErrors('evrak', item, item, (errors) => handleErrors2(errors, "tutar"), add, 'tutar', true, false, false, '', '', '')}
                                            inputProps={{
                                              style: { textAlign: 'right' }
                                            }}
                                            InputProps={{
                                              endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[item.para_birimi ?? "-1"] || ''}</Typography>
                                            }}
                                            customInput={TextField}
                                            thousandSeparator="."
                                            decimalSeparator=','
                                            valueIsNumericString={true}
                                          ></NumericFormat>
                                        </span>
                                      </Tooltip>
                                    )}
                                    {(item.tip === '3') && (
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.tutar ? props.error.tahsilat_detay[index].tutar[0] : (_isNil(item.tutar) || item.tutar === "") ? '' : MoneyFormatConvert(item.tutar, "money") + " " + _result(item["parabirimlist"]?.[0], 'tanim', '')}>
                                        <span className='flex w-full'>
                                          <NumericFormat
                                            name='para_birimi'
                                            variant='outlined'
                                            size='small'
                                            value={_result(item, 'tutar' || '')}
                                            error={_has(props.error?.tahsilat_detay?.[index], 'tutar')}
                                            fullWidth={true}
                                            onValueChange={(e) => props.onChangeFormDetay(index, 'tutar', '', '', '', e.value)}
                                            onBlur={() => handleInputErrors('evrak', item, item, (errors) => handleErrors2(errors, "tutar"), add, 'tutar', true, false, false, '', '', '')}
                                            inputProps={{
                                              style: { textAlign: 'right' }
                                            }}
                                            InputProps={{
                                              endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[item.para_birimi ?? "-1"] || ''}</Typography>
                                            }}
                                            customInput={TextField}
                                            thousandSeparator="."
                                            decimalSeparator=','
                                            valueIsNumericString={true}
                                          ></NumericFormat>
                                        </span>
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>

                                <div style={{ width: 250 }} className={classes.tableFormItem}>
                                  <div className="flex items-center">
                                    <Tooltip title={props.error?.tahsilat_detay?.[index]?.odeme_tanim ? props.error.tahsilat_detay[index].odeme_tanim[0] : _isNil(item.odeme_tanim) || item.odeme_tanim === "-1" ? '' : props.odemeTanimDic ? props.odemeTanimDic[item.odeme_tanim] : ''}>
                                      <TextField
                                        {...createTextFieldProps2(index, null, 'odeme_tanim', '', '', item, '')}
                                        onChange={(e) => props.onChangeFormDetay(index, 'odeme_tanim', '', '', '', e.target.value)}
                                        select
                                        SelectProps={{
                                          native: true,
                                        }}
                                      >
                                        <option value='-1' disabled>Seçilmedi</option>
                                        {item.odemetanimlist.map((ot) => (
                                          <option key={ot.id} value={ot.id}>{ot.tanim}</option>
                                        ))}
                                      </TextField>
                                    </Tooltip>
                                  </div>
                                </div>

                                <div style={{ width: 200 }} className={classes.tableFormItem}>
                                  <div className="flex items-center">
                                    {(item.tip === '0' || item.tip === '2') && (
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.banka_hesap ? props.error.tahsilat_detay[index].banka_hesap[0] : _isNil(item.banka_hesap) || item.banka_hesap === "-1" ? '' : props.bankalarDic ? props.bankalarDic[item.banka_hesap] : ''}>
                                        <TextField
                                          {...createTextFieldProps2(index, null, 'banka_hesap', '', '', item, '')}
                                          onChange={(e) => props.onChangeFormDetay(index, 'banka_hesap', '', '', '', e.target.value)}
                                          select
                                          SelectProps={{
                                            native: true,
                                          }}
                                        >
                                          <option value='-1' disabled>Seçilmedi</option>
                                          {item.bankalist.map((b) => (
                                            <option value={b.id} key={b.id}>{b.tanim}</option>
                                          ))}
                                        </TextField>
                                      </Tooltip>
                                    )}

                                    {(item.tip === '1' || item.tip === '4' || item.tip === '5') && (
                                      <TextField
                                        name='banka_hesap'
                                        variant='outlined'
                                        size='small'
                                        fullWidth={true}
                                        disabled={true}
                                      ></TextField>
                                    )}
                                    {item.tip === '3' && (
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.banka_hesap ? props.error.tahsilat_detay[index].banka_hesap[0] : _result(item["bankalist"][0], 'tanim') || ''}>
                                        <TextField
                                          name='banka_hesap'
                                          variant='outlined'
                                          size='small'
                                          value={_result(item["bankalist"][0], 'tanim') || ''}
                                          error={_has(props.error?.tahsilat_detay?.[index], 'banka_hesap')}
                                          fullWidth={true}
                                        >
                                        </TextField>
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>

                                <div style={{ width: 150 }} className={classes.tableFormItem}>
                                  <div className="flex items-center">
                                    {(item.tip !== '3') && (
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.para_birimi ? props.error.tahsilat_detay[index].para_birimi[0] : _isNil(item.para_birimi) || item.para_birimi === "-1" ? '' : props.paraBirimDic ? props.paraBirimDic[item.para_birimi] : ''}>
                                        <TextField
                                          {...createTextFieldProps2(index, null, 'para_birimi', '', '', item, '')}
                                          onChange={(e) => props.onChangeFormDetay(index, 'para_birimi', '', '', '', e.target.value)}
                                          select
                                          SelectProps={{
                                            native: true,
                                          }}
                                        >
                                          <option value='-1' disabled>Seçilmedi</option>
                                          {item.parabirimlist.map((b) => (
                                            <option value={b.id} key={b.id}>{b.tanim}</option>
                                          ))}
                                        </TextField>
                                      </Tooltip>
                                    )}

                                    {(item.tip === '3') && (
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.para_birimi ? props.error.tahsilat_detay[index].para_birimi[0] : _result(item["parabirimlist"][0], 'tanim') || ''}>
                                        <TextField
                                          {...createTextFieldProps2(index, null, 'para_birimi', '', '', item, '')}
                                          onChange={(e) => props.onChangeFormDetay(index, 'para_birimi', '', '', '', e.target.value)}
                                          select
                                          SelectProps={{
                                            native: true,
                                          }}
                                        >
                                          <option value='-1' disabled>Seçilmedi</option>
                                          {item.parabirimlist.map((b) => (
                                            <option value={b.id} key={b.id}>{b.tanim}</option>
                                          ))}
                                        </TextField>
                                      </Tooltip>
                                    )}

                                  </div>
                                </div>

                                <div style={{ width: 150 }} className={classes.tableFormItem}>
                                  <div className="flex items-center">
                                    <Tooltip title={props.error?.tahsilat_detay?.[index]?.kur ? props.error.tahsilat_detay[index].kur[0] : _isNil(item.kur) ? '' : MoneyFormatConvert(item.kur, "money") + " " + props.paraKoduList[props.form?.['para_birimi'] ?? '-1']}>
                                      <span className='flex w-full'>
                                        <NumericFormat
                                          {...createTextFieldProps2(index, null, 'kur', '', '', item, '')}
                                          onValueChange={(e) => props.onChangeFormDetay(index, 'kur', '', '', '', e.value)}
                                          onBlur={() => handleInputErrors('evrak', item, item, (errors) => handleErrors2(errors, "kur"), add, 'kur', true, false, false, '', '', '')}
                                          inputProps={{
                                            style: { textAlign: 'right' }
                                          }}
                                          InputProps={{
                                            endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form?.para_birimi ?? '-1'] || ''}</Typography>,
                                            readOnly: item.para_birimi !== props.form.para_birimi ? false : true
                                          }}
                                          customInput={TextField}
                                          thousandSeparator="."
                                          decimalSeparator=','
                                          valueIsNumericString={true}
                                        ></NumericFormat>
                                      </span>
                                    </Tooltip>
                                  </div>
                                </div>

                                <div style={{ width: 150 }} className={classes.tableFormItem}>
                                  <div className="flex items-center">
                                    {(item.tip !== '3') && (
                                      <TextField
                                        name='odeme_vade'
                                        variant='outlined'
                                        size='small'
                                        fullWidth={true}
                                        disabled={true}
                                      >
                                      </TextField>
                                    )}

                                    {(item.tip === '3') && (
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.odeme_vade ? props.error.tahsilat_detay[index].odeme_vade[0] : _isNil(item.odeme_vade) || item.odeme_vade === "-1" ? '' : item.odemevadelistdic ? item.odemevadelistdic[item.odeme_vade] : ''}>
                                        <TextField
                                          {...createTextFieldProps2(index, null, 'odeme_vade', '', '', item, '')}
                                          onChange={(e) => props.onChangeFormDetay(index, 'odeme_vade', '', '', '', e.target.value)}
                                          select
                                          SelectProps={{
                                            native: true,
                                          }}
                                        >
                                          <option value='-1' disabled>Seçilmedi</option>
                                          {item.odemevadelist?.map((ot) => (
                                            <option value={ot.id} key={ot.id}>{ot.tanim}</option>
                                          ))}
                                        </TextField>
                                      </Tooltip>
                                    )}

                                  </div>
                                </div>

                                <div style={{ width: 150 }} className={classes.tableFormItem}>
                                  <div className="flex items-center">
                                    <Tooltip title={props.error?.tahsilat_detay?.[index]?.cari_hesap_para_birim ? props.error.tahsilat_detay[index].cari_hesap_para_birim[0] : _isNil(item.cari_hesap_para_birim) || item.cari_hesap_para_birim === "-1" ? '' : props.paraBirimDic ? props.paraBirimDic[item.cari_hesap_para_birim] : ''}>
                                      <TextField
                                        {...createTextFieldProps2(index, null, 'cari_hesap_para_birim', '', '', item, '')}
                                        onChange={(e) => props.onChangeFormDetay(index, 'cari_hesap_para_birim', '', '', '', e.target.value)}
                                        select
                                        SelectProps={{
                                          native: true,
                                        }}
                                      >
                                        {paralist}
                                      </TextField>
                                    </Tooltip>
                                  </div>
                                </div>

                                <div style={{ width: 150 }} className={classes.tableFormItem}>
                                  <div className="flex items-center">
                                    <Tooltip title={props.error?.tahsilat_detay?.[index]?.cari_hesap_kur ? props.error.tahsilat_detay[index].cari_hesap_kur[0] : _isNil(item.cari_hesap_kur) ? '' : MoneyFormatConvert(item.cari_hesap_kur, "money") + " " + props.paraKoduList[props.form?.['para_birimi'] ?? '-1']}>
                                      <span className='flex w-full'>
                                        <NumericFormat
                                          {...createTextFieldProps2(index, null, 'cari_hesap_kur', '', '', item, '')}
                                          onValueChange={(e) => props.onChangeFormDetay(index, 'cari_hesap_kur', '', '', '', e.value)}
                                          onBlur={() => handleInputErrors('evrak', item, item, (errors) => handleErrors2(errors, "cari_hesap_kur"), add, 'cari_hesap_kur', true, false, false, '', '', '')}
                                          inputProps={{
                                            style: { textAlign: 'right' }
                                          }}
                                          InputProps={{
                                            endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form?.para_birimi ?? '-1'] || ''}</Typography>,
                                            readOnly: item.cari_hesap_para_birim !== props.form.para_birimi ? false : true
                                          }}
                                          customInput={TextField}
                                          thousandSeparator="."
                                          decimalSeparator=','
                                          valueIsNumericString={true}
                                        ></NumericFormat>
                                      </span>
                                    </Tooltip>
                                  </div>
                                </div>

                                <div style={{ width: 200 }} className={classes.tableFormItem}>
                                  <Tooltip title={props.error?.tahsilat_detay?.[index]?.net_tutar ? props.error.tahsilat_detay[index].net_tutar[0] : _isNil(item.net_tutar) ? '' : MoneyFormatConvert(item.net_tutar, "money") + " " + props.paraKoduList[item.cari_hesap_para_birim ?? '-1']}>
                                    <span className='flex w-full'>
                                      <NumericFormat
                                        {...createTextFieldProps2(index, null, 'net_tutar', '', '', item, '')}
                                        inputProps={{
                                          style: { textAlign: 'right' }
                                        }}
                                        InputProps={{
                                          endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[item.cari_hesap_para_birim ?? '-1'] || ''}</Typography>
                                        }}
                                        customInput={TextField}
                                        thousandSeparator="."
                                        decimalSeparator=','
                                        valueIsNumericString={true}
                                      ></NumericFormat>
                                    </span>
                                  </Tooltip>
                                </div>

                                <div style={{ width: 100 }} className={classes.tableFormItem}>
                                  <div className="flex items-center">
                                    <Checkbox
                                      name="tahsil_edildi"
                                      color="primary"
                                      checked={_result(item, 'tahsil_edildi', false)}
                                      onChange={(e, checked) => props.onChangeFormDetay(index, 'tahsil_edildi', '', '', '', checked)}
                                    ></Checkbox>
                                  </div>
                                </div>
                                <div style={{ width: 100 }} className={classes.tableFormItem}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disableElevation
                                    onClick={() => setOpenDetail(true)}
                                  >DETAY</Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      {openDetail &&
                        <Dialog
                          open={openDetail}
                          onClose={() => setOpenDetail(false)}
                        >
                          <DialogContent style={{ padding: 0 }}>
                            <div className="flex">
                              <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                                <header className="flex items-center justify-between py-2 px-4">
                                  <Button
                                    className="pointer-events-none"
                                    color="inherit"
                                  >DETAY</Button>
                                  <Tooltip title="Kapat">
                                    <IconButton
                                      edge="end"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setOpenDetail(false)
                                        setVadeSelectedRow(0);
                                      }}
                                    ><IconClose /></IconButton>
                                  </Tooltip>
                                </header>
                                <main className="border-t border-palette-action-hover py-1">
                                  <>
                                    {(DETAYLISTE[selectedRow]?.tip === '2' || DETAYLISTE[selectedRow]?.tip === '3' || DETAYLISTE[selectedRow]?.tip === '4') &&
                                      <>
                                        <ul className="flex flex-col list-none m-0 p-0">
                                          <li className="flex items-center justify-between py-1 pl-4 pr-8 space-x-2">
                                            <div className="flex flex-col">
                                              <div className={classes.formListTable}>
                                                <div className={classes.tableHeader}>
                                                  {/* {DETAYLISTE[selectedRow].tip === '4' &&
                                                    <div className={classes.tableHeaderItem} style={{ width: 25 }} >
                                                      <IconButton
                                                        size="small"
                                                        disableRipple
                                                        onClick={() => props.addVade(selectedRow, DETAYLISTE[selectedRow].tip)}
                                                      >
                                                        <IconAdd />
                                                      </IconButton>
                                                    </div>
                                                  } */}
                                                  <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 50 }}>Satır No</div>
                                                  <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>Vade</div>
                                                  <div className={classes.tableHeaderItem} style={{ textAlign: "right", width: 200 }}>Tutar</div>
                                                  <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>Tahsil Tarihi</div>
                                                </div>
                                                <div style={{ flex: '1 1 auto' }}>
                                                  {DETAYLISTE[selectedRow]?.vade?.length > 0 && DETAYLISTE[selectedRow].vade.map((v, index) => (
                                                    <div key={`detayvade1-${index}`} className={`${classes.tableFormRow} ${vadeSelectedRow === index ? classes.selectedRow : ''}`}
                                                      onClick={() => setVadeSelectedRow(index)}
                                                    >
                                                      {/* {DETAYLISTE[selectedRow].tip === '4' &&
                                                        <div className={classes.tableFormItem} style={{ width: 25 }}>
                                                          <Tooltip title="Sil">
                                                            <IconButton
                                                              size="small"
                                                              disableRipple
                                                              onClick={() => {
                                                                setVadeSelectedRow(0);
                                                                props.deleteVade(selectedRow, index, DETAYLISTE[selectedRow].tip)
                                                              }}
                                                            >
                                                              <IconDelete />
                                                            </IconButton>
                                                          </Tooltip>
                                                        </div>
                                                      } */}
                                                      <div style={{ width: 50 }} className={classes.tableFormItem}>
                                                        <div className="flex items-center h-full">{v.satir_no}</div>
                                                      </div>
                                                      <div style={{ width: 150 }} className={classes.tableFormItem}>
                                                        <div className="flex items-center">
                                                          <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[index]?.vade ? props.error.tahsilat_detay[selectedRow].vade[index].vade[0] : _isNil(v.vade) ? "" : moment(v.vade).format('DD.MM.YYYY')}>
                                                            <TextField
                                                              {...createTextFieldProps2(selectedRow, index, 'vade', 'vade', '', DETAYLISTE[selectedRow], '')}
                                                              onChange={(e) => {
                                                                if (DETAYLISTE[selectedRow].tip === '2' || DETAYLISTE[selectedRow].tip === '4') {
                                                                  props.onChangeFormDetay(selectedRow, 'vade', 'vade', '', index, e.target.value);
                                                                }
                                                              }}
                                                              type="date"
                                                            ></TextField>
                                                          </Tooltip>
                                                        </div>
                                                      </div>
                                                      <div style={{ width: 200 }} className={classes.tableFormItem}>
                                                        <div className="flex items-center">
                                                          <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[index]?.tutar ? props.error.tahsilat_detay[selectedRow].vade[index].tutar[0] : _isNil(v.tutar) ? "" : MoneyFormatConvert(v.tutar, "money")}>
                                                            <span className='flex w-full'>
                                                              <NumericFormat
                                                                {...createTextFieldProps2(selectedRow, index, 'vade', 'tutar', '', DETAYLISTE[selectedRow], '')}
                                                                inputProps={{
                                                                  style: { textAlign: 'right' }
                                                                }}
                                                                customInput={TextField}
                                                                thousandSeparator="."
                                                                decimalSeparator=','
                                                                valueIsNumericString={true}
                                                                InputProps={{
                                                                  readOnly: true
                                                                }}
                                                              ></NumericFormat>
                                                            </span>
                                                          </Tooltip>
                                                        </div>
                                                      </div>
                                                      <div style={{ width: 150 }} className={classes.tableFormItem}>
                                                        <div className="flex items-center">
                                                          <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[index]?.tahsilat_tarihi ? props.error.tahsilat_detay[selectedRow].vade[index].vade[0] : _isNil(v.tahsilat_tarihi) ? "" : moment(v.tahsilat_tarihi).format('DD.MM.YYYY')}>
                                                            <TextField
                                                              {...createTextFieldProps2(selectedRow, index, 'vade', 'tahsilat_tarihi', '', DETAYLISTE[selectedRow], '')}
                                                              onChange={(e) => {
                                                                if (DETAYLISTE[selectedRow].tip === '2' || DETAYLISTE[selectedRow].tip === '4') {
                                                                  props.onChangeFormDetay(selectedRow, 'vade', 'tahsilat_tarihi', '', index, e.target.value);
                                                                }
                                                              }}
                                                              type="date"
                                                            ></TextField>
                                                          </Tooltip>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                        <ul className="flex flex-col list-none m-0 p-0">
                                          <li className="flex items-center justify-between py-1 pl-4 pr-8 space-x-2">
                                            <ul className={classes.layoutFormList}>
                                              {(DETAYLISTE[selectedRow].tip === '2' || DETAYLISTE[selectedRow].tip === '4') &&
                                                <>
                                                  <li className={classes.layoutFormItem}>
                                                    <label className={classes.layoutFormItemLabel}>Statüs</label>
                                                    <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[vadeSelectedRow]?.status ? props.error.tahsilat_detay[selectedRow].vade[vadeSelectedRow].status[0] : _isNil(DETAYLISTE[selectedRow]?.vade?.[vadeSelectedRow].status) ? "" : props.cekSenetDurumDic ? props.cekSenetDurumDic[DETAYLISTE[selectedRow].vade[vadeSelectedRow].status] : ""}>
                                                      <TextField
                                                        {...createTextFieldProps2(selectedRow, vadeSelectedRow, 'vade', 'status', '', DETAYLISTE[selectedRow], '')}
                                                        onChange={(e) => props.onChangeFormDetay(selectedRow, 'vade', 'status', '', vadeSelectedRow, e.target.value)}
                                                        select
                                                        SelectProps={{
                                                          native: true,
                                                        }}
                                                      >
                                                        {props.cekSenetDurum?.map((csd) => (
                                                          <option key={csd.key} value={csd.key}>{csd.value}</option>
                                                        ))}
                                                      </TextField>
                                                    </Tooltip>
                                                  </li>
                                                  <li className={classes.layoutFormItem}>
                                                    <label className={classes.layoutFormItemLabel}>Şahsi/Ciro</label>
                                                    <Tooltip title={durum === "-1" ? "" : durum === "1" ? "Şahsi" : "Ciro"}>
                                                      <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth={true}
                                                        value={durum}
                                                        onChange={(e) => handleCekSenetBorclusu(e.target.value, selectedRow, vadeSelectedRow)}
                                                        select
                                                        SelectProps={{
                                                          native: true,
                                                        }}
                                                      >
                                                        <option value='-1'>Seçilmedi</option>
                                                        <option value='1'>Şahsi</option>
                                                        <option value='2'>Ciro</option>
                                                      </TextField>
                                                    </Tooltip>
                                                  </li>
                                                  <li className={classes.layoutFormItem}>
                                                    <label className={classes.layoutFormItemLabel}>Borçlu</label>
                                                    <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[vadeSelectedRow]?.kimin ? props.error.tahsilat_detay[selectedRow].vade[vadeSelectedRow].kimin[0] : _isNil(DETAYLISTE[selectedRow]?.vade?.[vadeSelectedRow].kimin) ? "" : DETAYLISTE[selectedRow].vade[vadeSelectedRow].kimin}>
                                                      <TextField
                                                        {...createTextFieldProps2(selectedRow, vadeSelectedRow, 'vade', 'kimin', '', DETAYLISTE[selectedRow], '')}
                                                        onChange={(e) => props.onChangeFormDetay(selectedRow, 'vade', 'kimin', '', vadeSelectedRow, e.target.value)}
                                                      ></TextField>
                                                    </Tooltip>
                                                  </li>
                                                </>
                                              }

                                              {DETAYLISTE[selectedRow].tip === '2' &&
                                                <>
                                                  <li className={classes.layoutFormItem}>
                                                    <label className={classes.layoutFormItemLabel}>Banka Adı</label>
                                                    <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[vadeSelectedRow]?.cek_banka_bilgi?.adi ? props.error.tahsilat_detay[selectedRow].vade[vadeSelectedRow].cek_banka_bilgi.adi[0] : _isNil(DETAYLISTE[selectedRow]?.vade?.[vadeSelectedRow]?.cek_banka_bilgi?.adi) ? "" : DETAYLISTE[selectedRow].vade[vadeSelectedRow].cek_banka_bilgi.adi}>
                                                      <TextField
                                                        {...createTextFieldProps2(selectedRow, vadeSelectedRow, 'vade', 'cek_banka_bilgi', 'adi', DETAYLISTE[selectedRow], '')}
                                                        onChange={(e) => props.onChangeFormDetay(selectedRow, 'vade', 'cek_banka_bilgi', 'adi', vadeSelectedRow, e.target.value)}
                                                      ></TextField>
                                                    </Tooltip>
                                                  </li>
                                                  <li className={classes.layoutFormItem}>
                                                    <label className={classes.layoutFormItemLabel}>Banka Şube Kodu</label>
                                                    <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[vadeSelectedRow]?.cek_banka_bilgi?.sube_kodu ? props.error.tahsilat_detay[selectedRow].vade[vadeSelectedRow].cek_banka_bilgi.sube_kodu[0] : _isNil(DETAYLISTE[selectedRow]?.vade?.[vadeSelectedRow]?.cek_banka_bilgi?.sube_kodu) ? "" : DETAYLISTE[selectedRow].vade[vadeSelectedRow].cek_banka_bilgi.sube_kodu}>
                                                      <TextField
                                                        {...createTextFieldProps2(selectedRow, vadeSelectedRow, 'vade', 'cek_banka_bilgi', 'sube_kodu', DETAYLISTE[selectedRow], '')}
                                                        onChange={(e) => props.onChangeFormDetay(selectedRow, 'vade', 'cek_banka_bilgi', 'sube_kodu', vadeSelectedRow, e.target.value)}
                                                      ></TextField>
                                                    </Tooltip>
                                                  </li>
                                                  <li className={classes.layoutFormItem}>
                                                    <label className={classes.layoutFormItemLabel}>Banka Hesap Kodu</label>
                                                    <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[vadeSelectedRow]?.cek_banka_bilgi?.hesap_kodu ? props.error.tahsilat_detay[selectedRow].vade[vadeSelectedRow].cek_banka_bilgi.hesap_kodu[0] : _isNil(DETAYLISTE[selectedRow]?.vade?.[vadeSelectedRow]?.cek_banka_bilgi?.hesap_kodu) ? "" : DETAYLISTE[selectedRow].vade[vadeSelectedRow].cek_banka_bilgi.hesap_kodu}>
                                                      <TextField
                                                        {...createTextFieldProps2(selectedRow, vadeSelectedRow, 'vade', 'cek_banka_bilgi', 'hesap_kodu', DETAYLISTE[selectedRow], '')}
                                                        onChange={(e) => props.onChangeFormDetay(selectedRow, 'vade', 'cek_banka_bilgi', 'hesap_kodu', vadeSelectedRow, e.target.value)}
                                                      ></TextField>
                                                    </Tooltip>
                                                  </li>
                                                  <li className={classes.layoutFormItem}>
                                                    <label className={classes.layoutFormItemLabel}>Iban</label>
                                                    <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[vadeSelectedRow]?.cek_banka_bilgi?.iban ? props.error.tahsilat_detay[selectedRow].vade[vadeSelectedRow].cek_banka_bilgi.iban[0] : _isNil(DETAYLISTE[selectedRow]?.vade?.[vadeSelectedRow]?.cek_banka_bilgi?.iban) ? "" : DETAYLISTE[selectedRow].vade[vadeSelectedRow].cek_banka_bilgi.iban}>
                                                      <TextField
                                                        {...createTextFieldProps2(selectedRow, vadeSelectedRow, 'vade', 'cek_banka_bilgi', 'iban', DETAYLISTE[selectedRow], '')}
                                                        onChange={(e) => props.onChangeFormDetay(selectedRow, 'vade', 'cek_banka_bilgi', 'iban', vadeSelectedRow, e.target.value)}
                                                      ></TextField>
                                                    </Tooltip>
                                                  </li>
                                                  <li className={classes.layoutFormItem}>
                                                    <label className={classes.layoutFormItemLabel}>Sıra No</label>
                                                    <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[vadeSelectedRow]?.cek_banka_bilgi?.cek_sira_no ? props.error.tahsilat_detay[selectedRow].vade[vadeSelectedRow].cek_banka_bilgi.cek_sira_no[0] : _isNil(DETAYLISTE[selectedRow]?.vade?.[vadeSelectedRow]?.cek_banka_bilgi?.cek_sira_no) ? "" : DETAYLISTE[selectedRow].vade[vadeSelectedRow].cek_banka_bilgi.cek_sira_no}>
                                                      <TextField
                                                        {...createTextFieldProps2(selectedRow, vadeSelectedRow, 'vade', 'cek_banka_bilgi', 'cek_sira_no', DETAYLISTE[selectedRow], '')}
                                                        onChange={(e) => props.onChangeFormDetay(selectedRow, 'vade', 'cek_banka_bilgi', 'cek_sira_no', vadeSelectedRow, e.target.value)}
                                                      ></TextField>
                                                    </Tooltip>
                                                  </li>
                                                </>
                                              }
                                              {DETAYLISTE[selectedRow].tip === '4' &&
                                                <>
                                                  <li className={classes.layoutFormItem}>
                                                    <label className={classes.layoutFormItemLabel}>Kefil Ad Soyad</label>
                                                    <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[vadeSelectedRow]?.kefil_bilgi?.ad_soyad ? props.error.tahsilat_detay[selectedRow].vade[vadeSelectedRow].kefil_bilgi.ad_soyad[0] : _isNil(DETAYLISTE[selectedRow]?.vade?.[vadeSelectedRow]?.kefil_bilgi?.ad_soyad) ? "" : DETAYLISTE[selectedRow].vade[vadeSelectedRow].kefil_bilgi.ad_soyad}>
                                                      <TextField
                                                        {...createTextFieldProps2(selectedRow, vadeSelectedRow, 'vade', 'kefil_bilgi', 'ad_soyad', DETAYLISTE[selectedRow], '')}
                                                        onChange={(e) => props.onChangeFormDetay(selectedRow, 'vade', 'kefil_bilgi', 'ad_soyad', vadeSelectedRow, e.target.value)}
                                                      ></TextField>
                                                    </Tooltip>
                                                  </li>
                                                  <li className={classes.layoutFormItem}>
                                                    <label className={classes.layoutFormItemLabel}>Kefil Telefon</label>
                                                    <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[vadeSelectedRow]?.kefil_bilgi?.telefon ? props.error.tahsilat_detay[selectedRow].vade[vadeSelectedRow].kefil_bilgi.telefon[0] : _isNil(DETAYLISTE[selectedRow]?.vade?.[vadeSelectedRow]?.kefil_bilgi?.telefon) ? "" : DETAYLISTE[selectedRow].vade[vadeSelectedRow].kefil_bilgi.telefon}>
                                                      <TextField
                                                        {...createTextFieldProps2(selectedRow, vadeSelectedRow, 'vade', 'kefil_bilgi', 'telefon', DETAYLISTE[selectedRow], '')}
                                                        onChange={(e) => props.onChangeFormDetay(selectedRow, 'vade', 'kefil_bilgi', 'telefon', vadeSelectedRow, e.target.value)}
                                                      ></TextField>
                                                    </Tooltip>
                                                  </li>
                                                  <li className={classes.layoutFormItem}>
                                                    <label className={classes.layoutFormItemLabel}>Kefil Adres</label>
                                                    <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[vadeSelectedRow]?.kefil_bilgi?.adres ? props.error.tahsilat_detay[selectedRow].vade[vadeSelectedRow].kefil_bilgi.adres[0] : _isNil(DETAYLISTE[selectedRow]?.vade?.[vadeSelectedRow]?.kefil_bilgi?.adres) ? "" : DETAYLISTE[selectedRow].vade[vadeSelectedRow].kefil_bilgi.adres}>
                                                      <TextField
                                                        {...createTextFieldProps2(selectedRow, vadeSelectedRow, 'vade', 'kefil_bilgi', 'adres', DETAYLISTE[selectedRow], '')}
                                                        onChange={(e) => props.onChangeFormDetay(selectedRow, 'vade', 'kefil_bilgi', 'adres', vadeSelectedRow, e.target.value)}
                                                      ></TextField>
                                                    </Tooltip>
                                                  </li>
                                                  <li className={classes.layoutFormItem}>
                                                    <label className={classes.layoutFormItemLabel}>Kefil Kimlik No</label>
                                                    <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[vadeSelectedRow]?.kefil_bilgi?.kimlik_no ? props.error.tahsilat_detay[selectedRow].vade[vadeSelectedRow].kefil_bilgi.kimlik_no[0] : _isNil(DETAYLISTE[selectedRow]?.vade?.[vadeSelectedRow]?.kefil_bilgi?.kimlik_no) ? "" : DETAYLISTE[selectedRow].vade[vadeSelectedRow].kefil_bilgi.kimlik_no}>
                                                      <TextField
                                                        {...createTextFieldProps2(selectedRow, vadeSelectedRow, 'vade', 'kefil_bilgi', 'kimlik_no', DETAYLISTE[selectedRow], '')}
                                                        onChange={(e) => props.onChangeFormDetay(selectedRow, 'vade', 'kefil_bilgi', 'kimlik_no', vadeSelectedRow, e.target.value)}
                                                      ></TextField>
                                                    </Tooltip>
                                                  </li>
                                                </>
                                              }
                                              <li className={classes.layoutFormItem}>
                                                <label className={classes.layoutFormItemLabel}>Satır Açıklama</label>
                                                <Tooltip title={props.error?.tahsilat_detay?.[selectedRow]?.vade?.[vadeSelectedRow]?.aciklama ? props.error.tahsilat_detay[selectedRow].vade[vadeSelectedRow].aciklama[0] : _isNil(DETAYLISTE[selectedRow]?.vade?.[vadeSelectedRow].aciklama) ? "" : DETAYLISTE[selectedRow].vade[vadeSelectedRow].aciklama}>
                                                  <TextField
                                                    {...createTextFieldProps2(selectedRow, vadeSelectedRow, 'vade', 'aciklama', '', DETAYLISTE[selectedRow], '')}
                                                    onChange={(e) => props.onChangeFormDetay(selectedRow, 'vade', 'aciklama', '', vadeSelectedRow, e.target.value)}
                                                    multiline
                                                    rows={2}
                                                  ></TextField>
                                                </Tooltip>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </>
                                    }
                                    <ul className={`flex flex-col list-none m-0 p-0 ${(DETAYLISTE[selectedRow]?.tip === '2' || DETAYLISTE[selectedRow]?.tip === '3' || DETAYLISTE[selectedRow]?.tip === '4') ? "border-t-2 border-palette-action-hover" : ""} `}>
                                      <li className="flex items-center justify-between py-4 pl-4 pr-8 space-x-2">
                                        <ul className={classes.layoutFormList}>
                                          <li className={classes.layoutFormItem}>
                                            <label className={classes.layoutFormItemLabel}>Not</label>
                                            <TextField
                                              {...createTextFieldProps2(selectedRow, '', 'aciklama', '', '', DETAYLISTE[selectedRow], '')}
                                              onChange={(e) => props.onChangeFormDetay(selectedRow, 'aciklama', '', '', '', e.target.value)}
                                              multiline
                                              rows={3}
                                            ></TextField>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </>
                                </main>
                              </section>
                            </div>
                          </DialogContent>
                        </Dialog>
                      }
                    </div>
                  )}
                </>
              )}

              {openSelectorDurum &&
                <Dialog
                  open={openSelectorDurum}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorDurum(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Durumlar'
                      listPath={`evrakdurumtanim/?aktif=true&evrak_tip=${props.form.tip}&magazalar.magaza.id=${props.form?.magaza?.id}`}
                      listCountPath={`evrakdurumtanim/count/?aktif=true&evrak_tip=${props.form.tip}&magazalar.magaza.id=${props.form?.magaza?.id}`}
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.evrak_durum?.durum ?? null}
                      onChangeItems={(items) => props.onChangeFormDurum('durum', items)}
                      onClose={() => setOpenSelectorDurum(false)}
                      newPath="evrak/durumtanim"
                    />
                  </DialogContent>
                </Dialog>
              }

              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorMagaza(false);
                    handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'magaza', true, false, false, '', '', '')
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Mağazalar'
                      listPath='magaza?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      valueProp2='para_birim'
                      notEmpty={true}
                      defaultItem={props.form.magaza}
                      onChangeItems={(items) => props.onChangeForm('magaza', items)}
                      onClose={() => {
                        setOpenSelectorMagaza(false);
                        handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'magaza', true, false, false, '', '', '');
                      }}
                      newPath="tanimlar/magaza"
                    />
                  </DialogContent>
                </Dialog>
              }

              {openSelectorCari &&
                <Dialog
                  open={openSelectorCari}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorCari(false);
                    handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'evrak_kullanici', true, false, false, '', '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Cariler'
                      listPath='kullanici/?cari=true&is_staff=false&is_active=true'
                      listCountPath='kullanici/count/?cari=true&is_staff=false&is_active=true'
                      keyProp='id'
                      valueProp='kodu'
                      valueProp2='first_name'
                      valueProp3='last_name'
                      extraVal='email'
                      defaultItem={props.form.evrak_kullanici}
                      onChangeItems={(items) => props.onChangeForm('evrak_kullanici', items)}
                      notEmpty={true}
                      onClose={() => {
                        setOpenSelectorCari(false);
                        handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'evrak_kullanici', true, false, false, '', '', '');
                      }}
                      newPath="tanimlar/cari"
                    />
                  </DialogContent>
                </Dialog>
              }

              {LOADING_DETAY && (
                <div className={classes.layoutContainer}>
                  <div className="flex w-full justify-center items-center">
                    <CircularProgress color="primary" />
                    <Typography variant="caption">Yükleniyor...</Typography>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      }
    </>
  );
});

export default TahsilatDetailTabGenel;
