import React, { useState, useLayoutEffect } from 'react';

import {
  Paper,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import {
  result as _result,
} from 'lodash';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
} from '@material-ui/icons';

import django from '../api/django';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
});

const LoadingMessage = () => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>
      <p className="font-medium">Seçenekler yükleniyor</p>
    </div>
  );
};

const ErrorMessage = () => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>
      <p className="font-medium mb-2">Beklenmeyen bir hata oluştu</p>
    </div>
  );
};

const RightMenuDetail = ({ title, url, itemList, onClose, baseData }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [list, setList] = useState(baseData);

  useLayoutEffect(() => {
    setError(null);
    setLoading(true);
    django(url).then(({ data }) => {
      let dataitem = data;
      if (data.length > 0) {
        dataitem = data[0];
      }
      const newList = itemList.map((item) => (
        { ...item, value: item.key2 ? _result(dataitem[item.key], `${item.key2}`, '-') : _result(dataitem, `${item.key}`, '-') }
      ));
      setList((prev) => {
        return [...prev, ...newList];
      });
      setLoading(false);
    }).catch(() => {
      setError(true);
    }).finally(() => setLoading(false));
  }, [url, itemList])

  return (
    <>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">{title}</h3>
            <span>
              <Tooltip title="Kapat">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={onClose}
                ><IconClose /></IconButton>
              </Tooltip>
            </span>
          </Toolbar>
        </AppBar>
        <main className="relative flex flex-grow flex-col overflow-hidden">
          <div className="h-full flex-grow p-1">
            {loading && (
              <LoadingMessage />
            )}
            {error && (
              <ErrorMessage />
            )}
            {(!loading && !error) && (
              <ul className={classes.layoutFormList}>
                {list.map(item => (
                  <li key={item.key} className={classes.layoutFormItem}>
                    <label className={classes.layoutFormItemLabel}>{item.title}</label>
                    <span>: {item.value}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </main>
      </Paper>
    </>
  );
};

export default RightMenuDetail;
