import React, { useCallback, useMemo, useEffect, useState, useLayoutEffect, useRef } from 'react';
import {
  has as _has,
  result as _result,
  isEmpty as _isEmpty,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Button,
  Tooltip,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';


const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerForm: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    imageDiv: {
      marginTop: 10,
      marginBottom: 10,
      display: 'flex',
      maxWidth: 800,
      maxHeight: 800
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    thumbdiv: {
      marginTop: 10,
      marginBottom: 10,
    },
    button: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& + &': {
        marginLeft: 10,
      },
    },
  };
};

const FotoDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const [total, setTotal] = useState(0);
  const [fileTotal, setFileTotal] = useState(0);
  const [orgsize, setorgsize] = useState({});
  const [fotosize, setfotosize] = useState({});
  const [thumbsize, setthumbsize] = useState({});
  const [fetchError, setFetchError] = useState(false);

  const inputRef = useRef();

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);


  const upload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      props.onChangeForm('old_image', false);
      const file = e.target.files[0];
      const filetype = file.name.split('.').pop();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        uploadOne(reader.result, filetype);
      }
    }
  }

  const uploadOne = (img, filetype) => {
    const image = new Image();
    image.src = img;
    image.onload = () => {
      const maxWidth = 1920;
      const maxHeight = 1200;
      const maxsize = 90;

      let width2 = image.naturalWidth;
      let height2 = image.naturalHeight;

      let width3 = image.naturalWidth;
      let height3 = image.naturalHeight;

      if (width2 > height2) {
        if (width2 > maxWidth) {
          height2 = height2 * (maxWidth / width2);
          width2 = maxWidth;
        }
      } else {
        if (height2 > maxHeight) {
          width2 = width2 * (maxHeight / height2);
          height2 = maxHeight;
        }
      }

      if (width3 > height3) {
        if (width3 > maxsize) {
          height3 = height3 * (maxsize / width3);
          width3 = maxsize;
        }
      } else {
        if (height3 > maxsize) {
          width3 = width3 * (maxsize / height3);
          height3 = maxsize;
        }
      }

      const canvas1 = document.createElement("canvas");
      const ctx1 = canvas1.getContext("2d");

      const canvas2 = document.createElement("canvas");
      const ctx2 = canvas2.getContext("2d");

      const canvas3 = document.createElement("canvas");
      const ctx3 = canvas3.getContext("2d");


      canvas1.width = image.naturalWidth;
      canvas1.height = image.naturalHeight;
      ctx1.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
      const dataUrl1 = canvas1.toDataURL(filetype === 'png' ? 'image/png' : 'image/jpeg');
      props.onChangeForm('gercek_image', dataUrl1);

      canvas2.width = width2;
      canvas2.height = height2;
      ctx2.drawImage(image, 0, 0, width2, height2);
      const dataUrl2 = canvas2.toDataURL(filetype === 'png' ? 'image/png' : 'image/jpeg');
      props.onChangeForm('image', dataUrl2);

      canvas3.width = 100;
      canvas3.height = 100;
      const dx = (100 - width3) / 2;
      const dy = (100 - height3) / 2;
      ctx3.drawImage(image, dx, dy, width3, height3);
      const dataUrl3 = canvas3.toDataURL();
      props.onChangeForm('thumbnail', dataUrl3);

    }
  }

  useEffect(() => {
    const formatByte = () => {
      if (fileTotal === 0) {
        return '';
      }
      const k = 1024;
      const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(fileTotal) / Math.log(k));
      return parseFloat((fileTotal / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };
    const calctotal = formatByte();
    setTotal(calctotal);
  }, [fileTotal]);


  const calc = async (src, func) => {
    let filesize = await fetch(src)
      .then(r => r.blob())
      .then(r => r.size)
      .catch(e => setFetchError(true))
    let imagesize = {};
    const image = new Image();
    image.src = src;
    image.onload = () => {
      imagesize = { width: image.naturalWidth, height: image.naturalHeight, filesize: filesize }
      func(imagesize);
    }
  }

  useLayoutEffect(() => {
    if (props.form) {
      if (props.form.gercek_image) {
        calc(props.form.gercek_image, setorgsize);
      } else {
        setorgsize({});
      }
      if (props.form.image) {
        calc(props.form.image, setfotosize);
      } else {
        setfotosize({});
      }
      if (props.form.thumbnail) {
        calc(props.form.thumbnail, setthumbsize);
      } else {
        setthumbsize({});
      }
    }
    setFetchError(false)
  }, [props.form]);

  useLayoutEffect(() => {
    if (!_isEmpty(orgsize) && !_isEmpty(fotosize) && !_isEmpty(thumbsize)) {
      setFileTotal(orgsize.filesize + fotosize.filesize + thumbsize.filesize);
    } else {
      setFileTotal(0);
    }
  }, [orgsize, fotosize, thumbsize]);


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.layoutContainer}>
              <div className={classes.layoutContainerForm}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="tanim">
                    <label className={classes.layoutFormItemLabel} htmlFor="tanim">Fotoğraf Tanım *</label>
                    <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                      <TextField
                        {...createTextFieldProps('tanim')}
                        onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                        onBlur={() => handleInputErrors('foto', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Fotoğraf Tanım', 'fotoğraf')}
                        inputProps={{
                          maxLength: 200,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  {props.form.old_image &&
                    <li className={classes.layoutFormItem} id="gercek_image">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Fotoğraf Linki</label>
                      <Tooltip title={_isNil(props.form?.gercek_image) ? '' : props.form.gercek_image}>
                        <TextField
                          {...createTextFieldProps('gercek_image')}
                        ></TextField>
                      </Tooltip>
                    </li>
                  }
                  <li className={classes.layoutFormItem} id="foto_tip">
                    <label className={classes.layoutFormItemLabel} htmlFor="foto_tip">Fotoğraf Tip *</label>
                    <Tooltip title={props.error?.foto_tip ? props.error.foto_tip[0] : _isNil(props.form?.foto_tip) ? '' : props.tipDic ? props.tipDic[props.form.foto_tip] : ''}>
                      <TextField
                        {...createTextFieldProps('foto_tip')}
                        onChange={(e) => props.onChangeForm('foto_tip', e.target.value)}
                        select
                        SelectProps={{ native: true }}
                      >
                        {props?.tip.map((tip) => (
                          <option key={tip.key} value={tip.key}>{tip.value}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>


                  <li className={classes.layoutFormItem} id="image">
                    <label className={classes.layoutFormItemLabel} htmlFor="image">Fotoğraf *</label>
                    <Tooltip title={props.error?.image ? props.error.image[0] : _isNil(props.form?.image) ? 'Fotoğraf Yükle' : ''}>
                      <Button
                        component="label"
                        className={classes.button}
                        style={{ borderWidth: 1, borderStyle: 'solid', borderColor: props.error?.image ? 'red' : 'transparent' }}
                      >
                        FOTOĞRAF YÜKLE
                        <input
                          type="file" hidden
                          accept=".jpeg,.jpg,.png"
                          ref={inputRef}
                          onClick={() => inputRef.current.value = ""}
                          onChange={(e) => upload(e)} />
                      </Button>
                    </Tooltip>
                  </li>

                </ul>

                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem}>
                    <section className="min-w-72 border border-palette-action-selected bg-palette-background-default rounded">
                      <header className="flex items-center justify-between py-2 px-4">
                        <Button
                          className="pointer-events-none"
                          color="inherit"
                        >BOYUT</Button>
                      </header>
                      <main className="border-t border-palette-action-hover py-1">
                        <ul className="flex flex-col list-none m-0 p-0">
                          <li className="flex items-center justify-start py-1 pl-4 pr-8 space-x-2">
                            <span>Gerçek Fotoğraf Ölçüleri</span>
                            <span>{!_isEmpty(orgsize) ? Math.round(orgsize.width) + ' X ' + Math.round(orgsize.height) : ''}</span>
                          </li>
                          <li className="flex items-center justify-start py-1 pl-4 pr-8 space-x-2">
                            <span>Fotoğraf Ölçüleri</span>
                            <span>{!_isEmpty(fotosize) ? Math.round(fotosize.width) + ' X ' + Math.round(fotosize.height) : ''}</span>
                          </li>
                          <li className="flex items-center justify-start py-1 pl-4 pr-8 space-x-2">
                            <span>Küçük Fotoğraf Ölçüleri</span>
                            <span>{!_isEmpty(thumbsize) ? Math.round(thumbsize.width) + ' X ' + Math.round(thumbsize.height) : ''}</span>
                          </li>
                          <li className="flex items-center justify-start py-1 pl-4 pr-8 space-x-2">
                            <span>Toplam Dosya Boyutu</span>
                            <span>{total}</span>
                          </li>
                          <li className="flex items-center justify-start py-1 pl-4 pr-8 space-x-2">
                            <small style={{ color: "#976797", fontWeight: "bold" }}>Toplam dosya boyutu 5mb üzerinde olamaz</small>
                          </li>
                        </ul>
                      </main>
                    </section>
                  </li>
                </ul>


              </div>

              {!fetchError && (
                <>
                  {props?.form?.image &&
                    <div className={classes.imageDiv}>
                      <img src={props.form.image} alt={props.form.tanim} className={classes.image} />
                    </div>
                  }
                  {props?.form?.thumbnail &&
                    <div className={classes.thumbdiv}>
                      <img src={props.form.thumbnail} alt={props.form.tanim} />
                    </div>
                  }
                </>
              )}
              {fetchError && (
                <Hata />
              )}
            </div>
          )}
        </>
      }
    </>
  );
});

export default FotoDetailTabGenel;

