import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../../pages/base/LayoutTableDetail';
import Detail from './EpostaAuthDetail';

const COLUMNS_DEFAULT = [
  { id: 'host',                 tableProp: 'host',                          filterProp: 'host',                     label: 'Mail Server Adresi',    element: 'input',           type: 'text',     arrayTableProp: null,     show: true,  sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'port',                 tableProp: 'port',                          filterProp: 'port',                     label: 'Bağlantı Portu',        element: 'input',           type: 'number',   arrayTableProp: null,     show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'username',             tableProp: 'username',                      filterProp: 'username',                 label: 'Eposta Kullanıcı Adı',  element: 'input',           type: 'text',     arrayTableProp: null,     show: true,  sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'magazalar',            tableProp: 'magazalar',                     filterProp: 'magazalar.magaza.id',      label: 'Mağazalar',             element: 'select-multiple', type: 'array',    arrayTableProp: 'tanim',  show: true,  sortable: true,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: 'magaza',     multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null, selectKey: null, selectValue: null , newpath:"tanimlar/magaza" },
  { id: 'use_tls',              tableProp: 'use_tls',                       filterProp: 'use_tls',                  label: 'TLS',                   element: 'select',          type: 'boolean',  arrayTableProp: null,     show: true,  sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'olusturan_kullanici',  tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',   label: 'Oluşturan Kullanıcı',   element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: false, sortable: true,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null, selectValue: null },
  { id: 'degistiren_kullanici', tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',  label: 'Değiştiren Kullanıcı',  element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: false, sortable: true,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null, selectValue: null },
  { id: 'olusturma_zamani',     tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',         label: 'Oluşturma',             element: 'input',           type: 'datetime', arrayTableProp: null,     show: false, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'degistirme_zamani',    tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',        label: 'Son Değişiklik',        element: 'input',           type: 'datetime', arrayTableProp: null,     show: false, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
];

const PageEpostaAuthList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        } 
        return column;
      });
    });
  }, []);

  return (
    <>
      <Layout
        title="E-POSTA YÖNETİMİ"
        path="epostaauth"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="epostaauth"
      />
    </>
  );
};

export default PageEpostaAuthList;
