import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../../pages/base/LayoutTableDetail';
import Detail from './CariDetail';


const COLUMNS_DEFAULT = [
  { id: 'kodu',                 tableProp: 'kodu',                          filterProp: 'kodu',                   label: 'Cari Kodu',            element: 'input',            type: 'text',     arrayTableProp: null,     show: true,  sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,                 multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'username',             tableProp: 'username',                      filterProp: 'username',               label: 'Kullanıcı Adı',        element: 'input',            type: 'text',     arrayTableProp: null,     show: true,  sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,                 multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'first_name',           tableProp: 'first_name',                    filterProp: 'first_name',             label: 'Unvan/Adı',            element: 'input',            type: 'text',     arrayTableProp: null,     show: true,  sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,                 multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'last_name',            tableProp: 'last_name',                     filterProp: 'last_name',              label: 'Soyadı',               element: 'input',            type: 'text',     arrayTableProp: null,     show: true,  sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,                 multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'email',                tableProp: 'email',                         filterProp: 'email',                  label: 'E-Posta',              element: 'input',            type: 'text',     arrayTableProp: null,     show: true,  sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,                 multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'kaynak',               tableProp: 'kaynak.value',                  filterProp: 'kaynak',                 label: 'Kaynak',               element: 'select',           type: 'number',   arrayTableProp: null,     show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,                 multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey:'key',  selectValue:'value'},
  { id: 'date_joined',          tableProp: 'date_joined',                   filterProp: 'date_joined',            label: 'Katılma Tarihi',       element: 'input',            type: 'datetime', arrayTableProp: null,     show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,                 multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'last_login',           tableProp: 'last_login',                    filterProp: 'last_login',             label: 'Son Giriş Tarihi',     element: 'input',            type: 'datetime', arrayTableProp: null,     show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,                 multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'groups',               tableProp: 'groups',                        filterProp: 'groups.group.id',        label: 'Üye/Cari Grupları',    element: 'select-multiple',  type: 'array',    arrayTableProp: 'name',   show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici-grup',     multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'name',     multipleSelectOptionViewProp: 'name',     shortDescription: null,   selectKey: null,  selectValue: null , newpath:"tanimlar/kullanici_grup" },
  { id: 'is_active',            tableProp: 'is_active',                     filterProp: 'is_active',              label: 'Aktif',                element: 'select',           type: 'boolean',  arrayTableProp: null,     show: true, sortable: false,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,                 multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey:'key',  selectValue:'value'},
  { id: 'olusturan_kullanici',  tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id', label: 'Oluşturan Kullanıcı',  element: 'select-multiple',  type: 'number',   arrayTableProp: null,     show: false, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',          multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null ,  selectKey: null,  selectValue: null  },
  { id: 'degistiren_kullanici', tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',label: 'Değiştiren Kullanıcı', element: 'select-multiple',  type: 'number',   arrayTableProp: null,     show: false, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',          multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null ,  selectKey: null,  selectValue: null  },
];

const PageCariList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }  
        return column;
      });
    });
  }, []);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: 0, value: 'Site' },
      { key: 1, value: 'Panel' },
      { key: 2, value: 'Entegrsyon' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.id === 'kaynak') {
          return {
            ...column,
            selectOptions,
          };
        } 
        return column;
      });
    });
  }, []);

  return (
    <>
      <Layout
        title="CARİLER"
        path="kullanici"
        pagefilter="?cari=true&is_staff=false"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="cari"
      />
    </>
  );
};

export default PageCariList;
