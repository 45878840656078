import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
	isEqual as _isEqual,
	pickBy as _pickBy,
	identity as _identity,
	has as _has,
	isEmpty as _isEmpty,
	assign as _assign,
	trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList } from '../../base/InputErrors';

import TabGenel from './SiteDetailTab_Genel';

const PATH = 'site';

const TABS_DEFAULT = [
	{ label: 'GENEL', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
	name: '',
	domain: '',
};

const DILLER_FORM_DEFAULT = {
	dil_id: '',
	sablon: ''
};

const bos_mail = {
	baslik: '',
	email: ''
}

const createDillerStates = async (diller, siparisdiller) => {
	const states = [];
	diller.sort((a, b,) => a.id - b.id).forEach((dil) => {
		const data = {
			dil: {
				id: dil.id,
				tanim: dil.tanim,
			},
			form: {
				...DILLER_FORM_DEFAULT,
				dil_id: dil.id,
			},
			errors: null,
		};
		const siparisdil = siparisdiller?.find((bd) => bd.dil_id === dil.id);
		if (siparisdil) {
			data.form.dil_id = siparisdil.dil_id;
			data.form.sablon = siparisdil.sablon;
		}
		states.push(data);
	});
	return states;
};

const FORM_DEFAULT_AYAR = {
	ayar: {
		baslik: '',
		anahtar_kelimeler: '',
		bilgilendirme: '',
		uyelik_zorunlu: false,
		urun_satilabilen_getirsin: "false",
		site_close: false,
		bildirim_ayarlari: {
			fiyat_dustu: bos_mail,
			stoklarimizda: bos_mail,
		},
		siparis_ayarlari: {
			siparis_alt_limit: '',
			siparis_alt_limit_etiket: []
		},
		gorunum_ayarlari: {
			odeme_fiyatlari: {
				katalog: false,
				urun_detay: false,
				sepet: false,
				urun_bilesen: false,
			},
			sepet_limit_kargolar: []
		}
	}
}

const createWithKargo = async (form, kargolar) => {
	const newkargolar = form.map((x) => ({ id: x, tanim: kargolar.find(a => a.id === x).tanim }));
	return newkargolar;
}

const SiteDetail = (props) => {
	const [loading, setLoading] = useState(true);
	const [loadingAyar, setLoadingAyar] = useState(true);
	const [loadingDil, setLoadingDil] = useState(true);

	const [hataGenel, setHataGenel] = useState(null);
	const [hataAyar, setHataAyar] = useState(null);
	const [hataDil, setHataDil] = useState(null);

	const [lastUpdateTime, setLastUpdateTime] = useState(0);
	const [add, setAdd] = useState(false);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [tabs, setTabs] = useState(TABS_DEFAULT);

	const [genelFormDefault, setGenelFormDefault] = useState(null);
	const [genelForm, setGenelForm] = useState(null);
	const [genelError, setGenelError] = useState(null);

	const [ayarFormDefault, setAyarFormDefault] = useState(null);
	const [ayarForm, setAyarForm] = useState(null);
	const [ayarError, setAyarError] = useState(null);
	const [validMagazalar, setValidMagazalar] = useState([]);
	const [kargo, setKargo] = useState(null);

	const [dillerSelectedTabIndex] = useState(0);
	const [dillerForm, setDillerForm] = useState(null);
	const [dillerFormDefault, setDillerFormDefault] = useState(null);
	const [dillerFormAdd, setDillerFormAdd] = useState(null);
	const [ayarDilleri, setAyarDilleri] = useState([]);

	const [dilMiniList, setDilMiniList] = useState([]);

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

	const [saveErrors, setSaveErrors] = useState([[], []]);

	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const ID = useMemo(() => props.id, [props]);
	const IS_ADD = useMemo(() => props.addnew, [props]);

	const TITLE = useMemo(() => {
		if (add) {
			return 'Yeni Ekle';
		}
		return genelForm?.name;
	}, [add, genelForm]);

	const IS_EQUAL = useMemo(() => {
		if (add) {
			return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(ayarForm, FORM_DEFAULT_AYAR) && _isEqual(dillerForm, dillerFormAdd);
		}
		const isEqualGenel = _isEqual(genelForm, genelFormDefault);
		const isEqualAyar = _isEqual(ayarForm, ayarFormDefault);
		const isEqualDiller = _isEqual(dillerForm, dillerFormDefault);
		return isEqualGenel && isEqualAyar && isEqualDiller;
	}, [add, genelForm, genelFormDefault, ayarForm, ayarFormDefault, dillerForm, dillerFormDefault, dillerFormAdd]);

	const LOADING = useMemo(() => {
		if (!loading && !loadingAyar && !loadingDil) {
			return false;
		}
		return true;
	}, [loading, loadingAyar, loadingDil]);

	useLayoutEffect(() => {
		if (props.eventType === 0) {
			setSaveErrors([[], []]);
			setTabs(TABS_DEFAULT);
		}
	}, [props.eventType, ID]);

	useLayoutEffect(() => {
		if (!saveErrors[0].includes("err")) {
			setLoading(true);
			if (!IS_ADD) {
				const debounce = setTimeout(() => {
					django(`${PATH}/${ID}`).then(({ data }) => {
						const form = {
							name: data.name,
							domain: data.domain,
						};
						setGenelFormDefault(form);
						setGenelForm(form);
						setHataGenel(null);
					}).catch((error) => {
						if (error.response) {
							if (error.response.status === 500) {
								setHataGenel(true);
							}
						}
					}).finally(() => {
						setLoading(false);
						setAdd(false);
						setGenelError(null);
					});
				}, 300);
				return () => {
					clearTimeout(debounce);
					setLoading(false);
				};
			} else {
				setGenelForm(FORM_DEFAULT);
				setGenelFormDefault(FORM_DEFAULT);
				setGenelError(null);
				setHataGenel(null);
				setAdd(true);
				setLoading(false);
			}
		}
	}, [ID, IS_ADD, lastUpdateTime, saveErrors]);

	useLayoutEffect(() => {
		if (!saveErrors[1].includes("err")) {
			setLoadingAyar(true);
			if (!IS_ADD && kargo) {
				const debounce = setTimeout(() => {
					django(`${PATH}/${ID}/siteayar`).then(({ data }) => {
						if (data.length > 0) {
							const filtred = data[0];
							let form = {
								id: filtred.id,
								ayar: {
									baslik: filtred?.ayar?.baslik ?? '',
									anahtar_kelimeler: filtred?.ayar?.anahtar_kelimeler ?? '',
									bilgilendirme: filtred?.ayar?.bilgilendirme ?? '',
									uyelik_zorunlu: filtred?.ayar?.uyelik_zorunlu ?? false,
									urun_satilabilen_getirsin: String(filtred?.ayar?.urun_satilabilen_getirsin ?? false),
									site_close: filtred?.ayar?.site_close ?? false,
									bildirim_ayarlari: {
										fiyat_dustu: filtred?.ayar?.bildirim_ayarlari?.fiyat_dustu ?? bos_mail,
										stoklarimizda: filtred?.ayar?.bildirim_ayarlari?.stoklarimizda ?? bos_mail,
									},
									siparis_ayarlari: {
										siparis_alt_limit: filtred?.ayar?.siparis_ayarlari?.siparis_alt_limit ?? '',
										siparis_alt_limit_etiket: filtred?.ayar?.siparis_ayarlari?.siparis_alt_limit_etiket ?? []
									},
									gorunum_ayarlari: {
										odeme_fiyatlari: {
											katalog: filtred?.ayar?.gorunum_ayarlari?.odeme_fiyatlari?.katalog ?? false,
											urun_detay: filtred?.ayar?.gorunum_ayarlari?.odeme_fiyatlari?.urun_detay ?? false,
											sepet: filtred?.ayar?.gorunum_ayarlari?.odeme_fiyatlari?.sepet ?? false,
											urun_bilesen: filtred?.ayar?.gorunum_ayarlari?.odeme_fiyatlari?.urun_bilesen ?? false,
										},
										sepet_limit_kargolar: filtred?.ayar?.gorunum_ayarlari?.sepet_limit_kargolar ?? []
									}
								}
							};
							createWithKargo(form.ayar.gorunum_ayarlari.sepet_limit_kargolar, kargo).then((res) => {
								form = { ...form, ayar: { ...form.ayar, gorunum_ayarlari: { ...form.ayar.gorunum_ayarlari, sepet_limit_kargolar: res } } }
								setAyarFormDefault(form);
								setAyarForm(form);
								setAyarDilleri(filtred?.ayar?.siparis_ayarlari?.siparis_alt_limit_etiket ?? []);
							})
						} else {
							setAyarForm(FORM_DEFAULT_AYAR);
							setAyarFormDefault(FORM_DEFAULT_AYAR);
							setAyarDilleri([]);
						}
						setHataAyar(null);
					}).catch((error) => {
						if (error.response) {
							if (error.response.status === 500) {
								setHataAyar(true);
							}
						}
					}).finally(() => {
						setLoadingAyar(false);
						setAyarError(null);
					});
				}, 300);
				return () => {
					clearTimeout(debounce);
					setLoadingAyar(false);
				};
			} else {
				setAyarForm(FORM_DEFAULT_AYAR);
				setAyarFormDefault(FORM_DEFAULT_AYAR);
				setAyarDilleri([]);
				setLoadingAyar(false);
				setHataAyar(false);
				setAyarError(null);
			}
		}
	}, [ID, IS_ADD, lastUpdateTime, saveErrors, kargo]);

	useLayoutEffect(() => {
		django('dil/mini_list').then(({ data }) => setDilMiniList(data)).catch((error) => {
			if (error.response) {
				if (error.response.status === 500) {
					setHataDil(true);
				}
			}
		});
	}, [lastUpdateTime]);

	useLayoutEffect(() => {
		if (!IS_ADD) {
			django(`magaza/mini_list?site.id=${ID}`).then(({ data }) => setValidMagazalar(data.map(x => x.id))).catch((error) => {
				if (error.response) {
					if (error.response.status === 500) {
						setAyarError(true);
					}
				}
			})
		}
	}, [ID, IS_ADD, lastUpdateTime]);

	useLayoutEffect(() => {
		django('kargo/mini_list').then(({ data }) => setKargo(data)).catch((error) => {
			if (error.response) {
				if (error.response.status === 500) {
					setAyarError(true);
				}
			}
		});
	}, [lastUpdateTime]);


	useLayoutEffect(() => {
		setLoadingDil(true);
		createDillerStates(dilMiniList, ayarDilleri).then((states) => {
			setDillerFormDefault(states);
			setDillerForm(states);
		}).finally(() => setLoadingDil(false));
	}, [dilMiniList, ayarDilleri]);

	useLayoutEffect(() => {
		createDillerStates(dilMiniList, []).then((states) => {
			setDillerFormAdd(states);
		})
	}, [dilMiniList]);

	useLayoutEffect(() => {
		if (!props.single) {
			props.onDisableClose(!IS_EQUAL);
		}
	}, [props, IS_EQUAL]);

	const handleGenelChangeForm = (property, value) => {
		setGenelForm((prev) => {
			return immer(prev, (next) => {
				next[property] = value;
			});
		});
	};

	const handleAyarChangeForm = (property, property2, property3, value) => {
		if (property2 !== '') {
			if (property3 !== '') {
				setAyarForm((prev) => {
					return immer(prev, (next) => {
						next['ayar'][property][property2][property3] = value;
					});
				});
			} else {
				setAyarForm((prev) => {
					return immer(prev, (next) => {
						next['ayar'][property][property2] = value;
					});
				});
			}
		} else {
			setAyarForm((prev) => {
				return immer(prev, (next) => {
					next['ayar'][property] = value;
				});
			});
		}
	};

	const handleDillerChangeForm = (index, property, value) => {
		setDillerForm((prev) => {
			return immer(prev, (next) => {
				next[index].form[property] = value;
			});
		});
	}

	const handleAdd = () => {
		setAdd(true);
		setGenelForm(FORM_DEFAULT);
		setAyarForm(FORM_DEFAULT_AYAR);
		setDillerForm(dillerFormAdd);
		setGenelError(null);
		setAyarError(null);
		setTabs((prev) => {
			return immer(prev, (next) => {
				next[0].hasError = false;
			});
		});
	};

	const handleReload = () => {
		setSaveErrors([[], []]);
		setLastUpdateTime(Date.now());
	};

	const handleCancel = () => {
		if (saveErrors[0].includes("err") || saveErrors[1].includes("err")) {
			setSaveErrors([[], []]);
		}
		setGenelForm(genelFormDefault);
		setAyarForm(ayarFormDefault);
		setDillerForm(dillerFormDefault);
		setGenelError(null);
		setAyarError(null);
		if (!IS_ADD) {
			setAdd(false);
		}
		setTabs((prev) => {
			return immer(prev, (next) => {
				next[0].hasError = false;
			});
		});
	};

	const handleDelete = () => {
		setIsDeleting(true);
		setIsDeletingErrorMessage(null);
		const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
		django.delete(`${PATH}/${ID}`).then(({ status }) => {
			if (status === 204) {
				setShowDeleteDialog(false);
				setIsDeletingErrorMessage(null);
				setTimeout(() => {
					if (props.single) {
						history.push(URLS.tanimlar.site.list);
					} else {
						props.onDeleted();
					}
				}, 100);
			} else {
				setIsDeletingErrorMessage(errorMessage);
			}
		}).catch(() => {
			setIsDeletingErrorMessage(errorMessage);
		}).finally(() => {
			setIsDeleting(false);
		});
	};

	const handleDeleteDetailIndex = (index) => {
		setDillerForm((prev) => {
			return immer(prev, (next) => {
				next[index].form = { ...DILLER_FORM_DEFAULT, dil_id: next[index].form.dil_id };
				next[index].errors = null;
			})
		});
	}

	const checkAll = async () => {
		const list = [
			{ prop: 'name', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Site Tanım', pagename: 'site', prop2: '' },
			{ prop: 'domain', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Alan Adı', pagename: 'site', prop2: '' },
		]
		let errors = [];
		await checkAllList('site', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
		return errors;
	};

	const checkAll2 = async () => {
		const list = [
			{ prop: 'ayar', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'baslik' },
		]
		let errors = [];
		await checkAllList('', ayarForm, ayarFormDefault, handleErrorsDetail, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
		return errors;
	};

	const handleSave = async () => {
		const createRequestOptionsTabGenel = async () => {
			let reqopt = null;
			let err = null;
			if (add ? _isEqual(genelForm, FORM_DEFAULT) : _isEqual(genelForm, genelFormDefault)) {
				reqopt = null;
				err = null;
			} else {
				await checkAll().then((res) => {
					if (_isEmpty(res)) {
						reqopt = {
							data: genelForm,
							method: add ? 'POST' : 'PUT',
							id: null,
							successMessage: `Site ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
							errorMessageUnexpected: `Site ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
						};
						err = null;
					} else {
						reqopt = null;
						err = res;
					}
				})
			}
			return [reqopt, err]
		}
		const createRequestOptionsTabAyar = async () => {
			let reqopt = null;
			let err = null;
			if (add ? _isEqual(ayarForm, FORM_DEFAULT_AYAR) && _isEqual(dillerForm, dillerFormAdd) : _isEqual(ayarForm, ayarFormDefault) && _isEqual(dillerForm, dillerFormDefault)) {
				reqopt = null;
				err = null;
			} else {
				await checkAll2().then((res) => {
					const diller = dillerForm.filter((dil) => (!_isEmpty(dil.form.sablon))).map((d) => d.form);
					const data = {
						ayar: {
							...ayarForm.ayar,
							urun_satilabilen_getirsin: ayarForm.ayar.urun_satilabilen_getirsin === "true" ? true : false,
							siparis_ayarlari: {
								siparis_alt_limit: ayarForm.ayar.siparis_ayarlari.siparis_alt_limit ? Number(ayarForm.ayar.siparis_ayarlari.siparis_alt_limit) : 0, siparis_alt_limit_etiket: diller
							},
							gorunum_ayarlari: {
								odeme_fiyatlari: { ...ayarForm.ayar.gorunum_ayarlari.gorunum_ayarlari },
								sepet_limit_kargolar: ayarForm.ayar.gorunum_ayarlari.sepet_limit_kargolar?.map((m) => m.id)
							}
						}
					}
					if (_isEmpty(res)) {
						const isHas = _has(ayarForm, 'id');
						if (isHas) {
							reqopt = {
								data: data,
								method: 'PUT',
								id: ayarForm.id,
								successMessage: `Site ayar güncelleme başarıyla tamamlandı`,
								errorMessageUnexpected: `Site ayar güncellenirken beklenmeyen bir hata oluştu`,
							};
						} else {
							reqopt = {
								data: data,
								method: 'POST',
								id: null,
								successMessage: `Site ayar ekleme başarıyla tamamlandı`,
								errorMessageUnexpected: `Site ayar eklenirken beklenmeyen bir hata oluştu`,
							};
						}
						err = null;
					} else {
						reqopt = null;
						err = res;
					}
				})
			}
			return [reqopt, err]
		}

		let errors = [[], []];

		let SAVEID = add ? null : ID;

		const start = async () => {
			const tabs = [
				{ index: 0, name: 'genel', request: [], error: [] },
				{ index: 1, name: 'ayar', request: [], error: [] },
			];

			const res = await createRequestOptionsTabGenel();
			tabs[0].request.push(res[0]);
			tabs[0].error.push(res[1]);

			const res2 = await createRequestOptionsTabAyar();
			tabs[1].request.push(res2[0]);
			tabs[1].error.push(res2[1]);

			await asyncForEach(tabs, async (tab) => {
				await asyncForEach(tab.request, async (request, requestIndex) => {
					if (request !== null) {
						if (tab.name === 'genel') {
							setGenelError(null);
							await django({
								method: request.method,
								data: request.data,
								url: add ? "site" : `site/${SAVEID}`
							}).then(({ data }) => {
								enqueueSnackbar(request.successMessage, { variant: 'success' });
								if (!add) {
									props.onUpdated();
								}
								if (props.single && add) {
									history.push(URLS.tanimlar.site.detail(data.id, "detay"));
								}
								if (!props.single && add) {
									props.onAdded(data);
								}
								SAVEID = data.id;
								errors[tab.index].splice(requestIndex, 0, null);
							}).catch((error) => {
								if (error.response) {
									if (error.response.status === 500) {
										enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
									} else {
										setGenelError(error.response.data);
									}
								} else {
									enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
								}
								errors[tab.index].splice(requestIndex, 0, "err");
							});
						}

						if (tab.name === 'ayar') {
							setAyarError(null);
							if (SAVEID !== null) {
								await django({
									method: request.method,
									data: request.data,
									url: request.id ? `site/${SAVEID}/siteayar/${request.id}` : `site/${SAVEID}/siteayar`
								}).then(() => {
									enqueueSnackbar(request.successMessage, { variant: 'success' });
									errors[tab.index].splice(requestIndex, 0, null);
								}).catch((error) => {
									if (error.response) {
										setAyarError(error.response.data);
									} else {
										enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
									}
									errors[tab.index].splice(requestIndex, 0, "err");
								});
							}
						}
					} else {
						if (!_isEmpty(tab.error[requestIndex])) {
							errors[tab.index].splice(requestIndex, 0, "err");
						} else {
							errors[tab.index].splice(requestIndex, 0, null);
						}
					}
				});
			});
		}
		await start();
		if (SAVEID !== null) {
			setSaveErrors(errors);
			if (!add) {
				if (!errors[0].includes("err") || !errors[1].includes("err")) {
					setLastUpdateTime(Date.now());
				}
			}
		} else {
			enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
		}
		setTabs((prev) => {
			return immer(prev, (next) => {
				next[0].hasError = !errors[0].includes("err") ? SAVEID ? !errors[1].includes("err") ? false : true : true : true;
			});
		});
	};

	const handleErrors = (errors) => {
		setGenelError((prev) => {
			const next = _pickBy({ ...prev, ...errors }, _identity);
			if (Object.keys(next).length === 0) {
				return null;
			}
			return next;
		});
	}

	const handleErrorsDetail = (errors) => {
		setAyarError((prev) => {
			let obj = {}
			let next = {};
			if (Object.keys(errors)[0] === 'ayar') {
				if (_has({ ...prev }, Object.keys(errors)[0])) {
					_assign(prev[Object.keys(errors)[0]], errors[Object.keys(errors)[0]])
					obj = { ...prev }
				} else {
					obj = { ...prev, ...errors }
				}
			} else {
				obj = { ...prev, ...errors }
			}

			if (_has(obj, "ayar")) {
				const ayar = _pickBy(obj["ayar"], _identity);
				if (_isEmpty(ayar)) {
					next = _pickBy({ ...obj, 'ayar': null }, _identity);
				} else {
					next = _pickBy({ ...obj, 'ayar': ayar }, _identity);
				}
			} else {
				next = _pickBy(obj, _identity);
			}
			if (Object.keys(next).length === 0) {
				return null;
			}
			return next;
		});
	}

	const handleErrorsDetailDil = (errors, index) => {
		setDillerForm((prev) => {
			const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
			return immer(prev, (next) => {
				next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
			});
		});
	}


	return (
		<>
			<LayoutDetail
				loading={LOADING}
				title={TITLE}

				single={props.single}

				tabs={tabs}
				selectedTabIndex={selectedTabIndex}
				onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

				disableCloseButton={LOADING}
				disableAddButton={LOADING || add || IS_ADD}
				disableReloadButton={LOADING || add || IS_ADD}
				disableCancelButton={!add && (LOADING || IS_EQUAL) && !IS_ADD}
				disableSaveButton={LOADING || IS_EQUAL}
				disableDeleteButton={LOADING || add || IS_ADD}
				disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
				disableNextButton={props.disableNextButton || add || IS_ADD}

				onClickCloseButton={props.onClose}
				onClickAddButton={handleAdd}
				onClickReloadButton={handleReload}
				onClickCancelButton={handleCancel}
				onClickSaveButton={handleSave}
				onClickDeleteButton={() => setShowDeleteDialog(true)}
				onClickPreviousButton={props.onGoPrevious}
				onClickNextButton={props.onGoNext}
				onOpenInNewTab={() => {
					if (IS_ADD) {
						window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
					} else {
						window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
					}
				}}
			>
				{selectedTabIndex === 0 && (
					<TabGenel
						loading={LOADING}
						hata={hataGenel}
						hataAyar={hataAyar}
						hataDil={hataDil}
						add={add}

						form={genelForm}
						formdefault={genelFormDefault}
						error={genelError}
						onChangeForm={handleGenelChangeForm}
						onCheckErrors={(errors) => handleErrors(errors)}

						ayarForm={ayarForm}
						ayarFormDefault={ayarFormDefault}
						ayarError={ayarError}
						onChangeFormAyar={handleAyarChangeForm}
						validMagazalar={validMagazalar}

						selectedTabIndex={dillerSelectedTabIndex}
						dilform={dillerForm}
						onChangeDilForm={handleDillerChangeForm}
						deleteDetailWithIndex={handleDeleteDetailIndex}

						onCheckErrorsAyar={(errors) => handleErrorsDetail(errors)}
						onCheckErrorsDil={(errors, index) => handleErrorsDetailDil(errors, index)}
					/>
				)}
			</LayoutDetail>

			{showDeleteDialog &&
				<DialogConfirm
					title="Sil"
					message={
						isDeletingErrorMessage
							? isDeletingErrorMessage
							: isDeleting
								? 'Site siliniyor lütfen bekleyin'
								: 'Bu siteyi gerçekten silmek istiyor musunuz?'
					}
					cancelText="VAZGEÇ"
					submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
					submittingText="SİLİNİYOR"
					submitButtonProps={{ color: 'secondary' }}
					isSubmitting={isDeleting}
					onSubmit={handleDelete}
					onCancel={() => setShowDeleteDialog(false)}
				></DialogConfirm>
			}
		</>
	);
}
export default SiteDetail;