import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList } from '../../base/InputErrors';

import TabGenel from './BirimDetailTab_Genel';

const PATH = 'birim';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  kodu: '',
  tanim: '',
  siralama: '0',
  aktif: true,
  diller: [],
  magazalar: []
};

const DILLER_FORM_DEFAULT = {
  dil_id: '',
  tanim: '',
  kisa_tanim: ''
};

const createDillerStates = async (diller, birimDiller) => {
  const states = [];
  diller.sort((a, b,) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...DILLER_FORM_DEFAULT,
        dil_id: dil.id,
      },
      errors: null,
    };
    const birimdil = birimDiller?.find((bd) => bd.dil_id === dil.id);
    if (birimdil) {
      data.form.dil_id = birimdil.dil_id;
      data.form.tanim = birimdil.tanim;
      data.form.kisa_tanim = birimdil.kisa_tanim;
    }
    states.push(data);
  });
  return states;
};

const BirimDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingDil, setLoadingDil] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataDil, setHataDil] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [dillerSelectedTabIndex] = useState(0);
  const [dillerForm, setDillerForm] = useState(null);
  const [dillerFormDefault, setDillerFormDefault] = useState(null);
  const [dillerFormAdd, setDillerFormAdd] = useState(null);
  const [birimDilleri, setBirimDilleri] = useState([]);

  const [dilMiniList, setDilMiniList] = useState([]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(dillerForm, dillerFormAdd);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualDiller = _isEqual(dillerForm, dillerFormDefault);
    return isEqualGenel && isEqualDiller;
  }, [add, genelForm, genelFormDefault, dillerForm, dillerFormDefault, dillerFormAdd]);

  const LOADING = useMemo(() => {
    if (!loading && !loadingDil) {
      return false;
    }
    return true;
  }, [loading, loadingDil]);

  useLayoutEffect(() => {
    setLoading(true);
    setTabs(TABS_DEFAULT);
    if (!IS_ADD) {
      const debounce = setTimeout(() => {
        django(`${PATH}/${ID}`).then(({ data }) => {
          const form = {
            tanim: data.tanim,
            kodu: data.kodu,
            siralama: String(data.siralama),
            aktif: data.aktif,
            diller: data.diller,
            magazalar: data.magazalar
          };
          setGenelFormDefault(form);
          setGenelForm(form);
          setBirimDilleri(form.diller);
          setHataGenel(null);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataGenel(true);
            }
          }
        }).finally(() => {
          setLoading(false);
          setGenelError(null);
          setAdd(false);
        });
      }, 300);
      return () => {
        clearTimeout(debounce);
        setLoading(false);
      };
    } else {
      setGenelForm(FORM_DEFAULT);
      setGenelFormDefault(FORM_DEFAULT);
      setBirimDilleri([]);
      setGenelError(null);
      setHataGenel(null);
      setAdd(true);
      setLoading(false);
    }
  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    django('dil/mini_list').then(({ data }) => setDilMiniList(data)).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataDil(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setLoadingDil(true);
    createDillerStates(dilMiniList, birimDilleri).then((states) => {
      setDillerFormDefault(states);
      setDillerForm(states);
    }).finally(() => setLoadingDil(false));
  }, [dilMiniList, birimDilleri]);

  useLayoutEffect(() => {
    createDillerStates(dilMiniList, []).then((states) => {
      setDillerFormAdd(states);
    })
  }, [dilMiniList]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleDillerChangeForm = (index, property, value) => {
    setDillerForm((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      });
    });
  }

  const handleAdd = () => {
    setAdd(true);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setDillerForm(dillerFormAdd);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    setDillerForm(dillerFormDefault);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Birim Tanım', pagename: 'birim', prop2: '' },
      { prop: 'kodu', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Birim Kodu', pagename: 'birim', prop2: '' }
    ];
    let errors = [];
    await checkAllList('birim', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = () => {
    checkAll().then((res) => {
      if (_isEmpty(res)) {
        let requestoptions = {};
        const diller = dillerForm.filter((dil) => (!_isEmpty(dil.form.tanim))).map((d) => d.form);
        const data = { ...genelForm, siralama: genelForm.siralama ? Number(genelForm.siralama) : 0, diller: diller, magazalar: genelForm.magazalar?.map((m) => m.id) }

        if (IS_EQUAL) {
          requestoptions = null;
        } else {
          requestoptions = {
            config: {
              method: add ? 'POST' : 'PUT',
              url: add ? 'birim' : `birim/${ID}`,
              data: data
            },
            successMessage: `Birim ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
            errorMessageUnexpected: `Birim ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
          }
        }

        const start = async () => {
          let errorCounts = 0;
          if (requestoptions !== null) {
            setGenelError(null);
            await django(requestoptions.config).then(({ data }) => {
              enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
              if (!add) {
                props.onUpdated();
              }
              if (props.single && add) {
                history.push(URLS.tanimlar.birim.detail(data.id, "detay"));
              }
              if (!props.single && add) {
                props.onAdded(data);
              }
            }).catch((error) => {
              if (error.response) {
                if (error.response.status === 500) {
                  enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
                } else {
                  setGenelError(error.response.data);
                }
              } else {
                enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
              }
              ++errorCounts;
            });
            if (errorCounts === 0) {
              setLastUpdateTime(Date.now());
            } else {
              setTabs((prev) => {
                return immer(prev, (next) => {
                  next[0].hasError = true;
                });
              });
            }
          }
        }
        start();
      } else {
        setTabs((prev) => {
          return immer(prev, (next) => {
            next[0].hasError = true;
          });
        });
      }
    })
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.birim.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteDetailIndex = (index) => {
    setDillerForm((prev) => {
      return immer(prev, (next) => {
        next[index].form = { ...DILLER_FORM_DEFAULT, dil_id: next[index].form.dil_id };
        next[index].errors = null;
      })
    });
  }


  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsDetail = (errors, index) => {
    setDillerForm((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={LOADING}
            hata={hataGenel}
            hataDil={hataDil}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}

            selectedTabIndex={dillerSelectedTabIndex}
            dilform={dillerForm}
            onChangeDilForm={handleDillerChangeForm}
            deleteDetailWithIndex={handleDeleteDetailIndex}

            onCheckErrors={(errors) => handleErrors(errors)}
            onCheckErrorsDil={(errors, index) => handleErrorsDetail(errors, index)}
          />
        )}

      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Birim siliniyor lütfen bekleyin'
                : 'Bu birimi gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
    </>
  );
};

export default BirimDetail;

