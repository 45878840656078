import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Grid,
  List,
  ListItem,
  makeStyles
} from '@material-ui/core';

import sideBarItems from '../constants/sidebar-items';


const useStyles = makeStyles(theme => ({
  list: {
    display: "flex",
    width: '100%',
    flexWrap: "wrap",
    justifyContent: "center",
    '& + &': {
      borderTop: "1px solid"
    }
  },
  listheader: {
    backgroundColor: theme.palette.toolbar,
    color: 'black',
    padding: theme.spacing(.5),
    margin: theme.spacing(1),
    justifyContent: 'center',
    borderRadius: 4,
    cursor: "pointer",
    width: 250,
    fontSize: 14
  },
  selectedListHeader: {
    backgroundColor: "#976797",
    color: 'white',
    padding: theme.spacing(.5),
    margin: theme.spacing(1),
    justifyContent: 'center',
    borderRadius: 4,
    cursor: "pointer",
    width: 250,
    fontSize: 14
  }
}));



const Dashboard = () => {
  const [selecteds, setSelecteds] = useState([]);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const classes = useStyles();
  const gridRef = useRef();
  const items = sideBarItems;

  const handleClick = (item) => {
    let copySelecteds = [...selecteds];
    if (copySelecteds.length > 0) {
      const findIndex = copySelecteds.findIndex(x => x.label === item.label);
      if (findIndex === -1) {
        for (let i = 0; i < copySelecteds.length; i++) {
          const submenu = copySelecteds[i].items;
          const findIndex2 = submenu.findIndex(x => x.label === item.label);
          if (findIndex2 > -1) {
            copySelecteds = [...copySelecteds.slice(0, i + 1), item]
            setSelecteds(copySelecteds)
            setSelectedHeaders(copySelecteds.map(x => x.label));
            return
          } else {
            if (i === copySelecteds.length - 1) {
              copySelecteds = [item];
            }
          }
        }
      } else {
        copySelecteds.length = findIndex + 1;
      }
    } else {
      copySelecteds = [item];
    }
    setSelecteds(copySelecteds);
    setSelectedHeaders(copySelecteds.map(x => x.label));
  }

  return (
    <Grid container spacing={2} ref={gridRef}>
      <List className={classes.list}>
        {items.map((item) => (
          <React.Fragment key={item.label}>
            {Array.isArray(item.items) ?
              (
                <ListItem
                  className={selectedHeaders.includes(item.label) ? classes.selectedListHeader : classes.listheader}
                  onClick={() => handleClick(item)}
                >
                  {item.label}
                </ListItem>
              ) :
              (
                <ListItem className={classes.listheader} component={Link} to={item.to}>
                  {item.label}
                </ListItem>
              )
            }
          </React.Fragment>
        ))}
      </List>
      {selecteds.map((item) => (
        <List className={classes.list} key={item.label}>
          {item.items.map((item2) => (
            <React.Fragment key={item2.label}>
              {Array.isArray(item2.items) ?
                (
                  <ListItem
                    className={selectedHeaders.includes(item2.label) ? classes.selectedListHeader : classes.listheader}
                    onClick={() => handleClick(item2)}
                  >
                    {item2.label}
                  </ListItem>
                ) :
                (
                  <ListItem className={classes.listheader} component={Link} to={item2.to}>
                    {item2.label}
                  </ListItem>
                )
              }
            </React.Fragment>
          ))}
        </List>
      ))}
    </Grid>
  );
};

export default Dashboard;
