import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../base/LayoutTableDetail';
import Detail from './EvrakDurumTanimDetail';
import django from '../../../api/django';

const COLUMNS_DEFAULT = [
  { id: 'tanim',                          tableProp: 'tanim',                         filterProp: 'tanim',             		          label: 'Durum Tanımı',          		      element: 'input',           type: 'text',     arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'icerik_sablon',                  tableProp: 'icerik_sablon.tanim',           filterProp: 'icerik_sablon',                  label: 'Panel Şablon',                    element: 'select',          type: 'number',   arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'id',   selectValue:'tanim'},
  { id: 'icerik_sablon_site',             tableProp: 'icerik_sablon_site.tanim',      filterProp: 'icerik_sablon_site',             label: 'Tema Şablon',                     element: 'select',          type: 'number',   arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'id',   selectValue:'tanim'},
  { id: 'evrak_tip',                      tableProp: 'evrak_tip.value',               filterProp: 'evrak_tip',                      label: 'Evrak Tip',                       element: 'select',          type: 'number',   arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'aktif',                          tableProp: 'aktif',                         filterProp: 'aktif',                          label: 'Aktif',                           element: 'select',          type: 'boolean',  arrayTableProp: null,     show: true,   sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'siralama',                       tableProp: 'siralama',                      filterProp: 'siralama',                       label: 'Sıralama',                        element: 'input',           type: 'number',   arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'bildirim_gondersin',             tableProp: 'bildirim_gondersin',            filterProp: 'bildirim_gondersin',             label: 'Bildirim Gönderilsin',            element: 'select',          type: 'boolean',  arrayTableProp: null,     show: true,   sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'key',  selectValue:'value'}, 
  { id: 'kullaniciya_bildirim_gondersin', tableProp: 'kullaniciya_bildirim_gondersin',filterProp: 'kullaniciya_bildirim_gondersin', label: 'Kullanıcıya Bildirim Gönderilsin',element: 'select',          type: 'boolean',  arrayTableProp: null,     show: true,   sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'key',  selectValue:'value'}, 
  { id: 'olusturan_kullanici',            tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',   		  label: 'Oluşturan Kullanıcı',   		      element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici', multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistiren_kullanici',           tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',  		  label: 'Değiştiren Kullanıcı',  		      element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici', multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'olusturma_zamani',               tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',         		  label: 'Oluşturma',             		      element: 'input',           type: 'datetime', arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistirme_zamani',              tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',        		  label: 'Son Değişiklik',        		      element: 'input',           type: 'datetime', arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
];


const EvrakDurumTanimList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }

        return column;
      });
    });
  }, []);

  useLayoutEffect(() => {
    django('iceriksablon/mini_list').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'icerik_sablon') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  useLayoutEffect(() => {
    django('evrak/evrak_tip').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'evrak_tip') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  useLayoutEffect(() => {
    const setList = async (list) => {
      let tempList = [];
      if (list.length > 0) {
        for (const li of list) {
          const res = (await django(`sablon_paket/${li.id}/sablonmail/mini_list`)).data;
          tempList = res;
        }
      }
      return tempList;
    }
    django('sablon_paket/mini_list?aktif=true').then(({ data }) => {
      setList(data).then((res) => {
        setColumnsDefault((prev) => {
          return prev.map((column) => {
            if (column.id === 'icerik_sablon_site') {
              return {
                ...column,
                selectOptions: res,
              };
            }
            return column;
          });
        });
      })
    })
  }, []);

  return (
    <>
      <Layout
        title="EVRAK DURUMLARI"
        path="evrakdurumtanim"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="evrakdurumtanim"
      />
    </>
  )
}
export default EvrakDurumTanimList;