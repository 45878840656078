import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';

import {
  Close as IconClose,
  Search as IconSearch,
  Add as IconAdd,
  Refresh as IconRefresh,
  Edit as IconEdit,
  Delete as IconDelete,
  ErrorOutline as IconErrorOutline,
} from '@material-ui/icons';

import { Link as RouterLink } from 'react-router-dom';

import { Paper, Toolbar, AppBar, IconButton, Tooltip, CircularProgress, Button, Divider, Link, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import django from '../api/django';

import DialogConfirm from '../components/DialogConfirm';

const PER_PAGE = 100;
const ROW_HEIGHT = 34;

const useStyles = makeStyles((theme) => {
  return {
    containersingle: {
      display: 'inline-flex',
      gap: '1rem',
      padding: '1rem',
    },
    containerwithdetail: {
      display: 'inline-flex',
      width: '100%',
      flex: 'none',
      gap: '1rem',
      padding: '1rem',
    },
    detail: {
      width: 'calc(100% - 24rem)',
      display: 'flex',
      height: '100%',
    },
    appBar: {
      position: 'relative',
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.toolbar
    },
    toolbarIconButton: {
      color: 'white'
    },

  };
});

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>
      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text, submitText, onSubmit }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>
      <p className="font-medium mb-2">{text}</p>
      {onSubmit &&
        <Button
          color="primary"
          onClick={onSubmit}
        >{submitText ?? 'TEKRAR DENE'}</Button>
      }
    </div>
  );
};

const VerticalListX = (props) => {
  // #region all
  const classes = useStyles();

  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const [items, setItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsLoadingErrorMessage, setItemsLoadingErrorMessage] = useState(null);

  const [itemsTotalCount, setItemsTotalCount] = useState(0);

  const [moreItemsLoading, setMoreItemsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchItems, setSearchItems] = useState([]);
  const [searchItemsLoading, setSearchItemsLoading] = useState(false);
  const [searchItemsLoadingErrorMessage, setSearchItemsLoadingErrorMessage] = useState(null);

  const [openDetail, setOpenDetail] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [defaultSelectedIndex, setDefaultSelectedIndex] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [disableCloseDetail, setDisableCloseDetail] = useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [isAdd, setIsAdd] = useState(false);
  const [eventType, setEventType] = useState(0);

  const [menuPosition, setMenuPosition] = useState(null);

  const mainContainerRef = useRef();
  const mainRef = useRef();
  const detailRef = useRef();
  const scrollRef = useRef();

  const listPath = useMemo(() => props?.path ?? null, [props]);
  const PAGE = useMemo(() => items.length > 0 ? Math.ceil(items.length / PER_PAGE) : 1, [items.length]);
  const PAGES_COUNT = useMemo(() => Math.ceil(itemsTotalCount / PER_PAGE), [itemsTotalCount]);
  const TITLE = useMemo(() => props?.title, [props]);

  const LOADING_MESSAGE = useMemo(() => {
    if (itemsLoading) {
      return 'Seçenekler yükleniyor';
    }
    if (searchItemsLoading) {
      return 'Arama yapılıyor';
    }
    return null;
  }, [itemsLoading, searchItemsLoading]);

  const ERROR_MESSAGE = useMemo(() => {
    if (itemsLoadingErrorMessage) {
      return itemsLoadingErrorMessage;
    }
    if (searchItemsLoadingErrorMessage) {
      return searchItemsLoadingErrorMessage;
    }
    return null;
  }, [itemsLoadingErrorMessage, searchItemsLoadingErrorMessage]);

  const FILTERED_ITEMS = useMemo(() => {
    if (searchQuery !== '') {
      return searchItems;
    }
    return items;
  }, [items, searchItems, searchQuery]);

  useLayoutEffect(() => {
    setItemsLoading(true);
    django(listPath, { params: { size: PER_PAGE } }).then(({ data }) => {
      setItems(data);
      if (defaultSelectedIndex !== null) {
        setSelectedRowIndex(defaultSelectedIndex);
        setSelectedRow(data[defaultSelectedIndex]);
      }
    }).catch(() => {
      setItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
    }).finally(() => {
      setItemsLoading(false);
    });
  }, [listPath, lastUpdateTime, defaultSelectedIndex]);

  useLayoutEffect(() => {
    let url = `${listPath}/count`;
    django(url).then(({ data }) => {
      setItemsTotalCount(data);
    });
  }, [listPath, lastUpdateTime]);

  useEffect(() => {
    const handleGet = ({ url, config }) => {
      setSearchItems([]);
      setSearchItemsLoading(true);
      setSearchItemsLoadingErrorMessage(null);

      django(url, config).then(({ data }) => {
        setSearchItems(data);
      }).catch(function (error) {
        if (django?.isCancel(error)) {
        } else {
          setSearchItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
        }
      }).finally(() => {
        setSearchItemsLoading(false);
      });
    };

    if (searchQuery === '') {
      setSearchItems([]);
      setSearchItemsLoading(false);
      setSearchItemsLoadingErrorMessage(null);
    }

    if (searchQuery !== '') {
      setSearchItems([]);
      setSearchItemsLoading(true);
      setSearchItemsLoadingErrorMessage(null);

      const CancelToken = django.CancelToken;
      const cancelTokenSource = CancelToken.source();

      const config = {
        params: {
          size: PER_PAGE,
          "tanim": `*${searchQuery}*`,
        },
        cancelToken: cancelTokenSource.token,
      };

      const debounce = setTimeout(() => handleGet({ url: listPath, config }), 300);

      return () => {
        cancelTokenSource.cancel();
        clearTimeout(debounce);
        setSearchItemsLoading(false);
      };
    }
  }, [searchQuery, listPath]);

  useEffect(() => {
    if (openDetail) {
      detailRef.current.scrollIntoView();
    } else {
      mainRef.current.scrollIntoView();
    }
  }, [openDetail]);

  const handleGetMoreOptions = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setMoreItemsLoading(true);
    django(listPath, { params: { size: PER_PAGE, page } }).then(({ data }) => {
      setItems((prev) => [...prev, ...data]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setMoreItemsLoading(false);
    });
  };

  const confirmCloseDetail = (func) => {
    if (!disableCloseDetail || (disableCloseDetail && window.confirm('Yaptığınız değişiklikler iptal edilecek'))) {
      func();
      setDisableCloseDetail(false);
      setIsAdd(false);
    }
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    if (!menuPosition) {
      setMenuPosition({
        top: e.clientY,
        left: e.clientX,
      });
    }
  }

  const handleMenuEvent = (e, selected) => {
    e.stopPropagation();
    if (selected === 'OPEN_NEW_TAB') {
      window.open(`${window.location.origin}/${props.singledetail}/${props.ustId}/${selectedRow.id}/detay`, '_blank');
    } else if (selected === 'SHOW_DETAIL') {
      setOpenDetail(true);
    } else if (selected === 'DELETE') {
      setShowDeleteDialog(true);
    }
    setTimeout(() => {
      setMenuPosition(null);
    }, 100);
  };

  const targetParse = (id) => {
    if (props.replace_key) {
      return props.target.replace(props.replace_key, id)
    } else {
      return `${props.target}/${id}`
    }
  }

  const OpenSubPanel = ({ item, searchQuery }) => {
    if (props.link === false) {
      return (
        <Link color="textPrimary">
          <Highlighter
            textToHighlight={`${item.tanim}`}
            searchWords={[searchQuery ?? '']}
            className=""
            activeClassName=""
            highlightClassName="leading-none"
            unhighlightClassName="leading-none"
          ></Highlighter>
        </Link>
      )
    } else {
      return (
        <Link
          to={targetParse(item.id)}
          component={RouterLink}
          color="textPrimary"
        >
          <Highlighter
            textToHighlight={`${item.tanim}`}
            searchWords={[searchQuery ?? '']}
            className=""
            activeClassName=""
            highlightClassName="leading-none"
            unhighlightClassName="leading-none"
          ></Highlighter>
        </Link>
      )
    }

  }

  const ListItem = ({ item, searchQuery }) => {
    return (
      <div
        key={`${item.id}${item.value}`}
        className='w-full flex items-center justify-between hover:bg-palette-background-default active:bg-palette-action-hover px-4 space-x-3 select-none cursor-pointer'
        style={{ height: ROW_HEIGHT }}
      >
        <OpenSubPanel item={item} searchQuery={searchQuery} />
        <div>
          <Tooltip title="Düzenle">
            <IconButton size="small" onClick={() => setOpenDetail(true)}>
              <IconEdit style={{ fontSize: 15 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sil">
            <IconButton size="small" onClick={() => setShowDeleteDialog(true)}>
              <IconDelete style={{ fontSize: 15 }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  const rowRenderer = ({ index, style }) => {
    const item = FILTERED_ITEMS[index];
    return (
      <div
        key={item.id}
        style={style}
        onClick={() => {
          confirmCloseDetail(() => {
            setSelectedRowIndex(index);
            setSelectedRow(FILTERED_ITEMS[index]);
            setEventType(0);
          })
        }}
        onContextMenu={(e) => {
          setSelectedRowIndex(index);
          setSelectedRow(FILTERED_ITEMS[index]);
          handleContextMenu(e);
          setEventType(0);
        }}
      >
        {index !== 0 && <Divider />}
        <ListItem
          item={item}
          searchQuery={searchQuery}
        ></ListItem>
      </div>
    );
  };

  const handleAdd = () => {
    setIsAdd(true);
    setOpenDetail(true);
    setSelectedRow(null);
  }

  const handleRemoveRowByIndex = (index) => {
    const rowsCopy = [...FILTERED_ITEMS];
    if (index > -1) {
      rowsCopy.splice(index, 1);
      setItems(rowsCopy);
      setItemsTotalCount(itemsTotalCount - 1);
      setDefaultSelectedIndex(index);
      if (rowsCopy.length === 0) {
        setOpenDetail(false);
        props.onClose();
      }
      setLastUpdateTime(Date.now());
      setEventType(0);
    }
  };

  const handleDeleteSelectedRow = (e) => {
    e.preventDefault();
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    django.delete(selectedRow.detay_url).then(() => {
      handleRemoveRowByIndex(selectedRowIndex);
      setShowDeleteDialog(false);
    }).catch(() => {
      setIsDeletingErrorMessage('Silme işleminde beklenmeyen bir hata oluştu.');
    }).finally(() => {
      setIsDeleting(false);
    });
  };
  // #endregion

  return (
    <>
      <div className={openDetail ? classes.containerwithdetail : classes.containersingle} ref={mainRef}>
        <div className="flex w-96 h-full">
          <Paper className="w-full">
            <AppBar className={classes.appBar} position="static" elevation={1} >
              <Toolbar variant="dense" className='border-b border-solid border-white'>
                <div className="flex justify-between items-center w-full text-white">

                  <div className="flex items-center truncate">
                    {props.canClose && (
                      <Tooltip title="Kapat">
                        <span>
                          <IconButton size="small" className={classes.toolbarIconButton} onClick={props.onClose}>
                            <IconClose />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                    <span className="font-roboto font-medium">{TITLE}</span>
                  </div>


                  <div>
                    <Tooltip title="Yeni">
                      <span>
                        <IconButton size="small" className={classes.toolbarIconButton} onClick={() => handleAdd()}>
                          <IconAdd />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title="Yenile">
                      <span>
                        <IconButton size="small" className={classes.toolbarIconButton} onClick={() => setLastUpdateTime(Date.now())}>
                          <IconRefresh />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>

                </div>
              </Toolbar>

              <nav className="w-full flex-1 flex items-center justify-between bg-palette-background-default py-2 px-4 space-x-4">
                <span className="relative w-full h-8 leading-none bg-palette-background-paper apperance-none">
                  <input
                    className='absolute inset-0 w-full h-full px-10 bg-transparent rounded border border-palette-action-selected focus:outline-none focus:border-primary-300 focus:ring-2 focus:ring-primary-200'
                    value={searchQuery ?? ''}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />

                  <span className="absolute top-2 left-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                    <IconSearch />
                  </span>

                  {searchQuery.length > 0 &&
                    <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSearchQuery('');
                        }}
                      >
                        <IconClose />
                      </IconButton>
                    </span>
                  }
                </span>
              </nav>

            </AppBar>

            <main style={{ height: 'calc( 100% - 84px)' }} ref={mainContainerRef}>
              <div className="w-full h-full px-2 py-2">
                {LOADING_MESSAGE && (
                  <LoadingMessage text={LOADING_MESSAGE} />
                )}
                {ERROR_MESSAGE && (
                  <ErrorMessage text={ERROR_MESSAGE} />
                )}
                {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length === 0) && (
                  <ErrorMessage text={props.emptyText} />
                )}
                {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length > 0) && (
                  <InfiniteLoader
                    ref={scrollRef}
                    minimumBatchSize={PER_PAGE}
                    threshold={PER_PAGE}
                    isRowLoaded={({ index }) => !!FILTERED_ITEMS[index]}
                    loadMoreRows={({ startIndex, stopIndex }) => {
                      if (itemsTotalCount > 0 && PAGES_COUNT > PAGE && !moreItemsLoading) {
                        handleGetMoreOptions(PAGE + 1);
                      }
                    }}
                    rowCount={itemsTotalCount > 0 ? itemsTotalCount : PER_PAGE * 2}

                  >
                    {({ onRowsRendered, registerChild }) => (
                      <AutoSizer>
                        {({ width, height }) => (
                          <>
                            <List
                              width={width}
                              height={height}
                              rowHeight={ROW_HEIGHT + 1}
                              rowCount={FILTERED_ITEMS.length}
                              estimatedRowSize={PAGES_COUNT > 0 ? PAGES_COUNT * (ROW_HEIGHT + 1) : undefined}
                              rowRenderer={rowRenderer}
                              onRowsRendered={onRowsRendered}
                              ref={registerChild}
                              onScroll={() => setMenuPosition(null)}
                            />
                            {menuPosition &&
                              <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => setMenuPosition(null)}>
                                <Paper className="fixed" style={menuPosition}>
                                  <MenuList className="focus:outline-none">
                                    <MenuItem onClick={(e) => handleMenuEvent(e, 'OPEN_NEW_TAB')}>Yeni Sekmede Aç</MenuItem>
                                    <MenuItem onClick={(e) => handleMenuEvent(e, 'SHOW_DETAIL')}>Detay Göster</MenuItem>
                                    <Divider />
                                    <MenuItem onClick={(e) => handleMenuEvent(e, 'DELETE')}>Sil</MenuItem>
                                  </MenuList>
                                </Paper>
                              </ClickAwayListener>
                            }
                          </>
                        )}
                      </AutoSizer>
                    )}
                  </InfiniteLoader>
                )}
              </div>
            </main>

          </Paper>
        </div>

        {openDetail &&
          <div className={classes.detail} ref={detailRef}>
            <props.detail
              id={selectedRow?.id}
              isAdd={isAdd}
              onAdded={() => {
                setLastUpdateTime(Date.now());
                setDefaultSelectedIndex(0);
                setIsAdd(false);
                setEventType(1);
              }}
              onUpdated={() => {
                setLastUpdateTime(Date.now());
                setDefaultSelectedIndex(selectedRowIndex);
                setIsAdd(false);
              }}
              onDeleted={() => handleRemoveRowByIndex(selectedRowIndex)}

              onDisableClose={(status) => setDisableCloseDetail(status)}

              onClose={() => {
                confirmCloseDetail(() => {
                  setIsAdd(false);
                  setOpenDetail(false);
                });
              }}

              ustId={props.ustId}
              state={props.state}
              eventType={eventType}
            />
          </div>
        }
      </div>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Siliniyor lütfen bekleyin'
                : 'Gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDeleteSelectedRow}
          onCancel={() => setTimeout(() => setShowDeleteDialog(false))}
        ></DialogConfirm>
      }

    </>
  )
};

// eventType 
// 0 ===> seçili satırın değişmesi
// 1 ===> yeni ekleme

export default VerticalListX;
