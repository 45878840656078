import React, { useState, useLayoutEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import immer from 'immer';

import {
  isEqual as _isEqual
} from 'lodash';

import {
  Button,
  Checkbox,
  Paper,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Tooltip,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
  Refresh as IconRefresh,
  Restore as IconRestore,
} from '@material-ui/icons';

import django from '../api/django';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      width: 300,
      border: '1px solid',
      borderColor: theme.palette.toolbar
    },
    listHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: `1px solid ${theme.palette.toolbar}`,
    },
    listRow: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 2, 0, 2),
      userSelect: 'none',
      cursor: 'pointer'
    },
    selectedRow: {
      background: '#eacfea',
    }
  };
});

const PER_PAGE = 100;
const ROW_HEIGHT = 34;

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text, submitText, onSubmit }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>

      <p className="font-medium mb-2">{text}</p>

      {onSubmit &&
        <Button
          color="primary"
          onClick={onSubmit}
        >{submitText ?? 'TEKRAR DENE'}</Button>
      }
    </div>
  );
};

const VaryantSelector = ({ selectedVaryant, urunId, onClose, defaultSelecteds, reloadVaryants }) => {
  const [varyantList, setVaryantList] = useState([]);
  const [varyantListCount, setVaryantListCount] = useState([]);
  const [varyantListLoading, setVaryantListLoading] = useState(false);
  const [varyantListLoadingErrors, setVaryantListLoadingErrors] = useState(false);
  const [varyantListLoadingMore, setVaryantListLoadingMore] = useState(false);

  const [showSelectedVaryants, setShowSelectedVaryants] = useState([]);
  const [selectedVaryants, setSelectedVaryants] = useState([]);
  const [selectedVaryantsDefault, setSelectedVaryantsDefault] = useState([]);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const classes = useStyles();
  let mainContainerRefs = useRef([]);
  const { enqueueSnackbar } = useSnackbar();

  useLayoutEffect(() => {
    if (selectedVaryant) {
      const list = [...selectedVaryant.ozellikbasliklar].reverse();
      setVaryantListLoading(new Array(list.length).fill(false));
      setVaryantListLoadingErrors(new Array(list.length).fill(false));
      setVaryantListLoadingMore(new Array(list.length).fill(false));
      setVaryantList(new Array(list.length).fill({ baslik: null, degerler: [] }));
      setVaryantListCount(new Array(list.length).fill(0));
      setShowSelectedVaryants(new Array(list.length).fill(false));
      setSelectedVaryants(new Array(list.length).fill([]));
      setSelectedVaryantsDefault(new Array(list.length).fill([]));
      async function setListe() {
        for (let i in list) {
          try {
            setVaryantListLoading((prev) => immer(prev, (next) => { next[i] = true }));
            const response = (await django(`ozellikbaslik/${list[i].id}/ozellikdeger/mini_list`, { params: { size: PER_PAGE } })).data;
            const responsecount = (await django(`ozellikbaslik/${list[i].id}/ozellikdeger/count`)).data;

            const selecteds = defaultSelecteds.find((x) => x.baslik?.id === list[i].id);
            const setList = response.map((x) => ({
              id: x.id, tanim: x.tanim, disabled: selecteds ? selecteds.degerler.findIndex(y => y.id === x.id) > -1 : false
            }));

            setVaryantList((prev) => immer(prev, (next) => {
              next[i] = { baslik: list[i], degerler: setList }
            }));
            setVaryantListCount((prev) => immer(prev, (next) => {
              next[i] = responsecount;
            }));

            setSelectedVaryants((prev) => immer(prev, (next) => {
              next[i] = { baslik: list[i], degerler: selecteds ? selecteds.degerler : [] }
            }));
            setSelectedVaryantsDefault((prev) => immer(prev, (next) => {
              next[i] = { baslik: list[i], degerler: selecteds ? selecteds.degerler : [] }
            }));
            setVaryantListLoading((prev) => immer(prev, (next) => { next[i] = false }));
          } catch {
            setVaryantListLoadingErrors((prev) => immer(prev, (next) => { next[i] = true }));
            setVaryantListLoading((prev) => immer(prev, (next) => { next[i] = false }));
          }

        }
      }
      setListe();
    }
  }, [selectedVaryant, defaultSelecteds, lastUpdateTime]);

  const handleCheckItem = (index, item, checked) => {
    let selecteds = [...selectedVaryants[index].degerler];
    if (checked) {
      selecteds.push(item);
    } else {
      const index = selecteds.findIndex((item2) => item2.id === item.id);
      if (index > -1) {
        selecteds.splice(index, 1);
      }
    }
    setSelectedVaryants((prev) => immer(prev, (next) => {
      next[index].degerler = [...selecteds];
    }))
  }

  const handleGetMore = (index, page) => {
    const firstScrollTop = mainContainerRefs.current?.[index]?.scrollTop;
    setVaryantListLoadingMore((prev) => immer(prev, (next) => { next[index] = true }));
    django(`ozellikbaslik/${varyantList[index].baslik.id}/ozellikdeger/mini_list`, { params: { size: PER_PAGE, page } }).then(({ data }) => {
      const selecteds = defaultSelecteds.find((x) => x.baslik?.id === varyantList[index].baslik.id);
      const setList = data.map((x) => ({
        id: x.id, tanim: x.tanim, disabled: selecteds ? selecteds.degerler.findIndex(y => y.id === x.id) > -1 : false
      }));
      setVaryantList((prev) => immer(prev, (next) => {
        next[index].degerler = [...prev[index].degerler, ...setList];
      }));
    }).finally(() => {
      mainContainerRefs.current[index].scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setVaryantListLoadingMore((prev) => immer(prev, (next) => { next[index] = false }));
    })
  }

  const rowRenderer = (i, index, style) => {
    let item = null;
    if (showSelectedVaryants[i]) {
      item = selectedVaryants[i].degerler[index];
    } else {
      item = varyantList[i].degerler[index];
    }
    const checked = selectedVaryants[i].degerler.findIndex((item2) => item2.id === item.id) > -1;

    return (
      <div key={`${varyantList[i].baslik.id}-${item.id}`} style={style}>
        <Divider />
        <ListItem
          item={item}
          checked={checked}
          onClick={(checked) => handleCheckItem(i, item, checked)}
        ></ListItem>
      </div>
    );
  };

  const ListItem = ({ item, checked, onClick }) => {
    return (
      <div
        key={`${item.id}${item.tanim}`}
        className={`${classes.listRow} ${checked ? classes.selectedRow : ''}`}
        style={{ height: ROW_HEIGHT }}
        onClick={() => {
          if (!item.disabled) {
            onClick(!checked)
          }
        }}
      >
        <span>{item.tanim}</span>
      </div>
    );
  };

  const handleSave = async () => {
    const liste = [...selectedVaryants].map((x) => ({
      baslik: x.baslik.id,
      degerler: x.degerler.map(y => y.id)
    }));
    const requestoptions = {
      config: {
        method: 'POST',
        url: `urun/varyant_olustur/${urunId}/${selectedVaryant.id}`,
        data: { varyant_list: liste }
      },
      successMessage: `Varyant ekleme başarıyla tamamlandı`,
      errorMessageUnexpected: `Varyant eklenirken beklenmeyen bir hata oluştu`,
    }
    await django(requestoptions.config).then(() => {
      enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
      reloadVaryants([...selectedVaryants].map((x) => ({ ...x, degerler: x.degerler.map((y) => ({ ...y, disabled: true })) })), selectedVaryant);
    }).catch(() => {
      enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
    });
  }

  const handleCancel = () => {
    setSelectedVaryants(selectedVaryantsDefault);
  }

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  return (
    <>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">Varyant Grupları</h3>
            <span>
              <Tooltip title="Yenile">
                <span>
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={handleReload}
                  ><IconRefresh /></IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Değişiklikleri İptal Et">
                <span>
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={handleCancel}
                    disabled={_isEqual(selectedVaryants, selectedVaryantsDefault)}
                  >
                    <IconRestore />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Kapat">
                <span>
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={onClose}
                  ><IconClose /></IconButton>
                </span>
              </Tooltip>
            </span>
          </Toolbar>
        </AppBar>

        {varyantList.length > 0 && (
          <main className='flex flex-col w-full h-full p-2'>
            <div className="w-full h-full overflow-x-auto">
              <div className='flex h-full gap-4' style={{ width: 'fit-content' }}>
                {varyantList.map((v, i) => (
                  <div key={`${v.baslik?.tanim}-${i}`} className={classes.list} >
                    <div className={classes.listHeader}>
                      <span>{v.baslik?.tanim}</span>
                      <span>
                        <Tooltip title="Sadece seçili seçenekleri göster">
                          <Checkbox
                            color="primary"
                            checked={showSelectedVaryants[i]}
                            style={{ padding: 0 }}
                            disableRipple
                            onChange={(e, checked) => setShowSelectedVaryants((prev) => immer(prev, (next) => { next[i] = checked }))}
                          ></Checkbox>
                        </Tooltip>
                      </span>
                    </div>
                    <div className="relative flex flex-grow flex-col overflow-hidden" ref={listref => mainContainerRefs.current[i] = listref}>
                      <div className="h-full flex-grow">
                        {varyantListLoading[i] && (
                          <LoadingMessage text='Seçenekler yükleniyor' />
                        )}

                        {varyantListLoadingErrors[i] && (
                          <ErrorMessage text='Beklenmeyen bir hata oluştu' />
                        )}

                        {(!varyantListLoading[i] && !varyantListLoadingErrors[i] && (showSelectedVaryants[i] ? selectedVaryants[i].degerler.length === 0 : v.degerler.length === 0)) && (
                          <ErrorMessage text="Seçenek bulunamadı" />
                        )}

                        {(!varyantListLoading[i] && !varyantListLoadingErrors[i] && (showSelectedVaryants[i] ? selectedVaryants[i].degerler.length > 0 : v.degerler.length > 0)) && (
                          <InfiniteLoader
                            minimumBatchSize={PER_PAGE}
                            threshold={PER_PAGE}
                            isRowLoaded={({ index }) => showSelectedVaryants[i] ? !!selectedVaryants[i].degerler[index] : !!v.degerler[index]}
                            loadMoreRows={({ startIndex, stopIndex }) => {
                              if (varyantListCount[i] > 0 && varyantListCount[i] > (v.degerler.length > 0 ? Math.ceil(v.degerler.length / PER_PAGE) : 1) && !varyantListLoadingMore[i] && !showSelectedVaryants[i]) {
                                handleGetMore(i, (v.degerler.length > 0 ? Math.ceil(v.degerler.length / PER_PAGE) : 1) + 1);
                              }
                            }}
                            rowCount={varyantListCount[i] > 0 ? varyantListCount[i] : PER_PAGE * 2}
                          >
                            {({ onRowsRendered, registerChild }) => (
                              <AutoSizer>
                                {({ width, height }) => (
                                  <List
                                    width={width}
                                    height={height}
                                    rowHeight={ROW_HEIGHT + 1}
                                    rowCount={showSelectedVaryants[i] ? selectedVaryants[i].degerler.length : v.degerler.length}
                                    estimatedRowSize={varyantListCount[i] > 0 ? varyantListCount[i] * (ROW_HEIGHT + 1) : undefined}
                                    rowRenderer={({ index, style }) => rowRenderer(i, index, style)}
                                    onRowsRendered={onRowsRendered}
                                    ref={registerChild}
                                  />
                                )}
                              </AutoSizer>
                            )}
                          </InfiniteLoader>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-center border-t border-palette-action-selected p-2">
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disableRipple
                  disabled={_isEqual(selectedVaryants, selectedVaryantsDefault)}
                  onClick={handleSave}
                >KAYDET</Button>
              </span>
            </div>
          </main>
        )}
      </Paper>
    </>
  );

};

export default VaryantSelector;
