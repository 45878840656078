import React, { useState, useLayoutEffect } from 'react';
import slugify from 'slugify';
import django from '../../../api/django';
import Layout from '../../../pages/base/LayoutTableDetail';
import Detail from './UrunDetail';


const COLUMNS_DEFAULT = [
  { id: 'kodu',                 tableProp: 'kodu',                          filterProp: 'kodu',                     label: 'Kod',                   element: 'input',                 type: 'text',     arrayTableProp: null,       show: true,   sortable: true,   highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'barkod',               tableProp: 'barkod',                        filterProp: 'barkod',                   label: 'Barkod',                element: 'input',                 type: 'text',     arrayTableProp: null,       show: true,   sortable: true,   highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'tip',                  tableProp: 'tip.value',                     filterProp: 'tip',                      label: 'Tip',                   element: 'select',                type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'birim',                tableProp: 'birim.tanim',                   filterProp: 'birim.id',                 label: 'Birim',                 element: 'select-multiple',       type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'birim',      multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null, selectKey: null,  selectValue: null, newpath: "tanimlar/birimler" },
  { id: 'vergitip',             tableProp: 'vergi.vergi_tip.tanim',         filterProp: 'vergi.vergi_tip.id',       label: 'Vergi Tip',             element: 'select-multiple',       type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'vergi_tip',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null, selectKey: null,  selectValue: null, newpath: "tanimlar/vergidetay", ustId: "null" },
  { id: 'vergioran',            tableProp: 'vergi.tanim',                   filterProp: 'vergi.id',                 label: 'Vergi Oran',            element: 'select-multiple-step',  type: 'number',   arrayTableProp: null,       show: true,   sortable: false,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'vergi',      multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null, selectKey: null,  selectValue: null, firstOptionsPath: 'vergi_tip', firstOptionViewProp: 'tanim', newpath: "tanimlar/vergidetay" },
  { id: 'tanim',                tableProp: 'tanim',                         filterProp: 'tanim',                    label: 'Ürün Adı',              element: 'input',                 type: 'text',     arrayTableProp: null,       show: true,   sortable: true,   highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'magazalar',            tableProp: 'magazalar',                     filterProp: 'magazalar.magaza.id',      label: 'Mağazalar',             element: 'select-multiple',       type: 'array',    arrayTableProp: 'tanim',    show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'magaza',     multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null, selectKey: null,  selectValue: null, newpath: "tanimlar/magaza" },
  { id: 'kategoriler',          tableProp: 'kategoriler',                   filterProp: 'kategoriler.kategori.id',  label: 'Kategoriler',           element: 'select-multiple',       type: 'array',    arrayTableProp: 'parents',  show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kategori',   multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'parents',  multipleSelectOptionViewProp: 'parents',  shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'gruplar',              tableProp: 'gruplar',                       filterProp: 'gruplar.grup.id',          label: 'Ürün Grupları',         element: 'select-multiple',       type: 'array',    arrayTableProp: 'tanim',    show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'grup',       multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null, selectKey: null,  selectValue: null, newpath: "tanimlar/gruplar" },
  { id: 'toplam_miktar',        tableProp: 'toplam_miktar',                 filterProp: 'toplam_miktar',            label: 'Miktar',                element: 'input',                 type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'desi',                 tableProp: 'desi',                          filterProp: 'desi',                     label: 'Desi',                  element: 'input',                 type: 'number',   arrayTableProp: null,       show: false,  sortable: true,   highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'tedarik_suresi',       tableProp: 'tedarik_suresi',                filterProp: 'tedarik_suresi',           label: 'Tedarik Süresi',        element: 'input',                 type: 'number',   arrayTableProp: null,       show: false,  sortable: true,   highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'fiyat_bildirim_adet',  tableProp: 'fiyat_bildirim_adet',           filterProp: 'fiyat_bildirim_adet',      label: 'Fiyat Bildirim',        element: 'input',                 type: 'number',   arrayTableProp: null,       show: false,  sortable: true,   highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: 'Fiyatta indirim olduğunda bildirim bekleyen kullanıcı sayısı', selectKey: null, selectValue: null },
  { id: 'stok_bildirim_adet',   tableProp: 'stok_bildirim_adet',            filterProp: 'stok_bildirim_adet',       label: 'Stok Bildirim',         element: 'input',                 type: 'number',   arrayTableProp: null,       show: false,  sortable: true,   highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: 'Ürün stoğa girdiğinde bildirim bekleyen kullanıcı sayısı',     selectKey: null, selectValue: null },
  { id: 'katalogta_gizle',      tableProp: 'katalogta_gizle',               filterProp: 'katalogta_gizle',          label: 'Katalogda Gizle',       element: 'select',                type: 'boolean',  arrayTableProp: null,       show: false,  sortable: false,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'aktif',                tableProp: 'aktif',                         filterProp: 'aktif',                    label: 'Aktif',                 element: 'select',                type: 'boolean',  arrayTableProp: null,       show: true,   sortable: false,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'webde_goster',         tableProp: 'webde_goster',                  filterProp: 'webde_goster',             label: 'Webde Göster',          element: 'select',                type: 'boolean',  arrayTableProp: null,       show: false,  sortable: false,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'uretici',              tableProp: 'uretici.username',              filterProp: 'uretici.id',               label: 'Üretici',               element: 'select',                type: 'number',   arrayTableProp: null,       show: false,  sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'id',  selectValue: 'username' },
  { id: 'olusturan_kullanici',  tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',   label: 'Oluşturan Kullanıcı',   element: 'select-multiple',       type: 'number',   arrayTableProp: null,       show: false,  sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'degistiren_kullanici', tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',  label: 'Değiştiren Kullanıcı',  element: 'select-multiple',       type: 'number',   arrayTableProp: null,       show: false,  sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'olusturma_zamani',     tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',         label: 'Oluşturma',             element: 'input',                 type: 'datetime', arrayTableProp: null,       show: false,  sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'degistirme_zamani',    tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',        label: 'Son Değişiklik',        element: 'input',                 type: 'datetime', arrayTableProp: null,       show: false,  sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
];

const PageUrunList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        return column;
      });
    });
  }, []);

  useLayoutEffect(() => {
    django('urun/tip').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'tip') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  useLayoutEffect(() => {
    django('kullanici/uretici_mini_list').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'uretici') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  useLayoutEffect(() => {
    const columnDefaultProperties = {
      id: 'ozellikler',
      tableProp: 'ozellikler.ozellikAdi',
      filterProp: 'ozellikler.ozellikAdi',
      label: 'Özellik',
      element: 'select-multiple',
      type: 'array',
      arrayTableProp: null,
      show: true,
      sortable: false,
      highlightable: false,
      alignment: 0,
      width: 1,
      selectOptions: [],
      multipleSelectOptionsPath: null,
      multipleSelectOptionFilterProp: null,
      multipleSelectOptionSortProp: 'tanim',
      multipleSelectOptionViewProp: 'tanim',
      shortDescription: null,
      newpath: 'tanimlar/ozellikdetay',
      ustId: "null"
    };

    django('ozellikbaslik/mini_list').then(({ data }) => {
      const newColumns = [];
      data.forEach(({ id, tanim }) => {
        const columnIdSlug = slugify(tanim, {
          lower: true,
          strict: false,
          locale: 'tr',
        });
        newColumns.push({
          ...columnDefaultProperties,
          id: `ozellikler-${columnIdSlug}`,
          tableProp: `ozellikler.${tanim.replace(/\s/g, '')}`,
          filterProp: `ozellikler.${tanim.replace(/\s/g, '')}`,
          label: tanim,
          multipleSelectOptionsPath: `ozellikbaslik/${id}/ozellikdeger`,
          multipleSelectOptionFilterProp: 'tanim',
          ustId: id
        });
      });

      setColumnsDefault((columnsDefault) => {
        const newColumnsFiltered = newColumns.filter((c) => !columnsDefault.some((c2) => c.id === c2.id));
        return [...columnsDefault, ...newColumnsFiltered];
      });
    });
  }, []);

  return (
    <>
      <Layout
        title="ÜRÜNLER"
        path="urun"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="urun"
        varyant={true}
      />
    </>
  );
};

export default PageUrunList;
