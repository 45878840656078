import React, { useState, useLayoutEffect, useMemo, useCallback, useRef } from 'react';
import { SketchPicker } from 'react-color';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import moment from 'moment';

import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip
} from '@material-ui/core';

import {
  Search as IconSearch
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import ListPhotoSelector from '../../../components/ListPhotoSelector';
import FotoAddDialog from '../../../components/FotoAddDialog';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      display: 'flex',
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
    },
    layoutContainerTab: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 0, 1, 0),
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button2: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& + &': {
        marginLeft: 10,
      },
    },
    imageDiv: {
      width: 400,
      height: 400,
      display: "flex",
      borderRadius: 4,
      border: '1px solid #eacfea',
      padding: spacing(2, 2, 2, 2),
      marginTop: 10
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      cursor: "pointer"
    },
    addNew: {
      display: "flex",
      marginBottom: 10,
    },
  };
};

const BilesenDetailTabAfisSet = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATADIL = useMemo(() => props.hataDil, [props.hataDil]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const [tabsDil, setTabsDil] = useState([]);
  const [selectedTabIndexDil, setSelectedTabIndexDil] = useState(props.selectedTabIndexDil);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  const [openPicker1, setOpenPicker1] = useState(false);
  const [openPicker2, setOpenPicker2] = useState(false);

  const COUNT_TABS_DİL = useMemo(() => props?.dilform?.length ?? 0, [props]);
  const FORM_DİL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.form ?? null, [props, selectedTabIndex, selectedTabIndexDil]);
  const ERROR_DIL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.errors ?? null, [props, selectedTabIndex, selectedTabIndexDil]);

  const [openSelectorResim, setOpenSelectorResim] = useState(false);
  const [uploadImageFile, setUploadImageFile] = useState('');
  const [uploadImageType, setUploadImageType] = useState('');
  const [openSelector, setOpenSelector] = useState(false);

  const inputRef = useRef();

  useLayoutEffect(() => {
    if (props.dilform?.length > 0) {
      const copyForms = [...props.dilform];
      const newTabs = copyForms[selectedTabIndex]?.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabsDil(newTabs);
    } else {
      setTabsDil([]);
    }

  }, [props.dilform, selectedTabIndex]);


  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((bannerset) => {
        return {
          label: bannerset.form.tanim,
          hasError: bannerset.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);

  useLayoutEffect(() => {
    if (!props.vadeAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.vadeAdd, COUNT_TABS]);


  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(FORM?.[name], `${name2}`) || defaultValue,
        error: _has(ERROR?.name, `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(FORM, `${name}`) || defaultValue,
        error: _has(ERROR, `${name}`),
        fullWidth: true,
      };
    }
  }, [FORM, ERROR]);

  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM_DİL, `${name}`) || defaultValue,
      error: _has(ERROR_DIL, `${name}`),
      fullWidth: true,
    };
  }, [FORM_DİL, ERROR_DIL]);

  const handleChangeForm = (property, property2, value) => {
    props.onChangeForm(selectedTabIndex, property, property2, value);
  };

  const handleChangeFormDil = (property, value) => {
    props.onChangeDilForm(selectedTabIndex, selectedTabIndexDil, property, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }

  const handleSetErrorsDil = (errors) => {
    props.onCheckErrorsDil(errors, selectedTabIndex, selectedTabIndexDil);
  }

  const resimEkle = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const filetype = file.name.split('.').pop();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setUploadImageFile(reader.result);
        setUploadImageType(filetype);
      }
      setOpenSelectorResim(true);
    }
  }

  const CloseSelectorResim = () => {
    setUploadImageFile('');
    setOpenSelectorResim(false);
    handleInputErrors2(FORM, 'foto', handleSetErrors);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <LayoutDetailVerticalTab
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                  add={props.onAddNewTab}
                  buttonText={"YENİ BANNER EKLE"}
                  buttonShow={props.afisSetAdd}
                  delete={handleDetailDelete}
                  flexDirection='column'
                >
                  {COUNT_TABS === 0 && (
                    <div className="flex flex-col items-start justify-start p-4 space-y-4">
                      <Typography variant="body1">
                        Banner Sete ait banner bulunamadı.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disableElevation
                        onClick={props.onAddNewTab}
                      >YENİ BANNER EKLE</Button>
                    </div>
                  )}
                  {COUNT_TABS > 0 &&
                    <>
                      <div className={classes.tabDiv}>
                        <div className={classes.column}>
                          <ul className={classes.formList}>
                            <li className={classes.formItem} id="tanim">
                              <label className={classes.formItemLabel} htmlFor="tanim">Tanım *</label>
                              <Tooltip title={ERROR?.tanim ? ERROR.tanim[0] : _isNil(FORM?.tanim) ? '' : FORM.tanim}>
                                <TextField
                                  {...createTextFieldProps('tanim', '')}
                                  onChange={(e) => handleChangeForm('tanim', '', e.target.value)}
                                  onBlur={() => handleInputErrors2(FORM, 'tanim', handleSetErrors)}
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>
                            <li className={classes.formItem} id="baslangic_tarihi">
                              <label className={classes.formItemLabel} htmlFor="baslangic_tarihi">Başlangıç Tarihi</label>
                              <Tooltip title={ERROR?.baslangic_tarihi ? ERROR.baslangic_tarihi[0] : _isNil(FORM?.baslangic_tarihi) ? '' : moment(FORM.baslangic_tarihi).format('DD/MM/YYYY, HH.mm')}>
                                <TextField
                                  {...createTextFieldProps('baslangic_tarihi', '')}
                                  type="datetime-local"
                                  onChange={(e) => handleChangeForm('baslangic_tarihi', '', e.target.value)}
                                ></TextField>
                              </Tooltip>
                            </li>
                            <li className={classes.formItem} id="bitis_tarihi">
                              <label className={classes.formItemLabel} htmlFor="bitis_tarihi">Bitiş Tarihi</label>
                              <Tooltip title={ERROR?.bitis_tarihi ? ERROR.bitis_tarihi[0] : _isNil(FORM?.bitis_tarihi) ? '' : moment(FORM.bitis_tarihi).format('DD/MM/YYYY, HH.mm')}>
                                <TextField
                                  {...createTextFieldProps('bitis_tarihi', '')}
                                  type="datetime-local"
                                  onChange={(e) => handleChangeForm('bitis_tarihi', '', e.target.value)}
                                ></TextField>
                              </Tooltip>
                            </li>
                            <li className={classes.formItem} id="link">
                              <label className={classes.formItemLabel} htmlFor="link">Link</label>
                              <Tooltip title={ERROR?.link ? ERROR.link[0] : _isNil(FORM?.link) ? '' : FORM.link}>
                                <TextField
                                  {...createTextFieldProps('link', '')}
                                  onChange={(e) => handleChangeForm('link', '', e.target.value)}
                                  inputProps={{
                                    maxLength: 250,
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="family_name">
                              <label className={classes.formItemLabel} htmlFor="family_name">Yazı Fontu Ailesi</label>
                              <Tooltip title={ERROR?.yazi_tipi_ayarlari?.family_name ? ERROR.yazi_tipi_ayarlari.family_name[0] : _isNil(FORM?.yazi_tipi_ayarlari?.family_name) ? '' : FORM.yazi_tipi_ayarlari.family_name}>
                                <TextField
                                  {...createTextFieldProps('yazi_tipi_ayarlari', 'family_name')}
                                  onChange={(e) => handleChangeForm('yazi_tipi_ayarlari', 'family_name', e.target.value)}
                                  select
                                  SelectProps={{
                                    native: true,
                                  }}
                                >
                                  {props.fontFamilyList.map((ff) => (
                                    <option key={ff} value={ff}>{ff}</option>
                                  ))}
                                </TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="size">
                              <label className={classes.formItemLabel} htmlFor="size">Yazı Boyutu</label>
                              <Tooltip title={ERROR?.yazi_tipi_ayarlari?.size ? ERROR.yazi_tipi_ayarlari.size[0] : _isNil(FORM?.yazi_tipi_ayarlari?.size) ? '' : FORM.yazi_tipi_ayarlari.size}>
                                <TextField
                                  {...createTextFieldProps('yazi_tipi_ayarlari', 'size')}
                                  onChange={(e) => handleChangeForm('yazi_tipi_ayarlari', 'size', e.target.value)}
                                  type="number"
                                  inputProps={{
                                    min: -2147483648,
                                    max: 2147483647,
                                    step: 1,
                                    style: { textAlign: 'right' }
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="type">
                              <label className={classes.formItemLabel} htmlFor="type">Yazı Stili</label>
                              <Tooltip title={ERROR?.yazi_tipi_ayarlari?.type ? ERROR.yazi_tipi_ayarlari.type[0] : _isNil(FORM?.yazi_tipi_ayarlari?.type) ? '' : FORM.yazi_tipi_ayarlari.type}>
                                <TextField
                                  {...createTextFieldProps('yazi_tipi_ayarlari', 'type')}
                                  onChange={(e) => handleChangeForm('yazi_tipi_ayarlari', 'type', e.target.value)}
                                  select
                                  SelectProps={{
                                    native: true,
                                  }}
                                >
                                  {props.fontStyleList.map((fs) => (
                                    <option key={fs.key} value={fs.key}>{fs.value}</option>
                                  ))}
                                </TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="weight">
                              <label className={classes.formItemLabel} htmlFor="weight">Yazı Kalınlığı</label>
                              <Tooltip title={ERROR?.yazi_tipi_ayarlari?.weight ? ERROR.yazi_tipi_ayarlari.weight[0] : _isNil(FORM?.yazi_tipi_ayarlari?.weight) ? '' : FORM.yazi_tipi_ayarlari.weight}>
                                <TextField
                                  {...createTextFieldProps('yazi_tipi_ayarlari', 'weight')}
                                  onChange={(e) => handleChangeForm('yazi_tipi_ayarlari', 'weight', e.target.value)}
                                  select
                                  SelectProps={{
                                    native: true,
                                  }}
                                >
                                  {props.fontWeightList.map((fw) => (
                                    <option key={fw} value={fw}>{fw}</option>
                                  ))}
                                </TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="color" style={{ position: 'relative' }}>
                              <label className={classes.formItemLabel} htmlFor="color">Buton Rengi</label>
                              <Tooltip title={ERROR?.dugme_ayarlari?.color ? ERROR.dugme_ayarlari.color[0] : _isNil(FORM?.dugme_ayarlari?.color) ? '' : FORM.dugme_ayarlari.color}>
                                <TextField
                                  {...createTextFieldProps('dugme_ayarlari', 'color')}
                                  onChange={(e) => handleChangeForm('dugme_ayarlari', 'color', e.target.value)}
                                  InputProps={{
                                    endAdornment:
                                      <IconButton
                                        size="small"
                                        className={classes.button}
                                        disableRipple
                                        onClick={() => setOpenPicker1(true)}
                                      ><IconSearch /></IconButton>
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="text_color" style={{ position: 'relative' }}>
                              <label className={classes.formItemLabel} htmlFor="text_color">Buton Yazısı Rengi</label>
                              <Tooltip title={ERROR?.dugme_ayarlari?.text_color ? ERROR.dugme_ayarlari.text_color[0] : _isNil(FORM?.dugme_ayarlari?.text_color) ? '' : FORM.dugme_ayarlari.text_color}>
                                <TextField
                                  {...createTextFieldProps('dugme_ayarlari', 'text_color')}
                                  onChange={(color) => handleChangeForm('dugme_ayarlari', 'text_color', color.hex)}
                                  InputProps={{
                                    endAdornment:
                                      <IconButton
                                        size="small"
                                        className={classes.button}
                                        disableRipple
                                        onClick={() => setOpenPicker2(true)}
                                      ><IconSearch /></IconButton>
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="position">
                              <label className={classes.formItemLabel} htmlFor="position">Buton Pozisyonu</label>
                              <Tooltip title={ERROR?.dugme_ayarlari?.position ? ERROR.dugme_ayarlari.position[0] : _isNil(FORM?.dugme_ayarlari?.position) ? '' : props.buttonDic[FORM.dugme_ayarlari.position]}>
                                <TextField
                                  {...createTextFieldProps('dugme_ayarlari', 'position')}
                                  onChange={(e) => handleChangeForm('dugme_ayarlari', 'position', e.target.value)}
                                  select
                                  SelectProps={{
                                    native: true,
                                  }}
                                >
                                  {props.buttonPositonList.map((bp) => (
                                    <option key={bp.key} value={bp.key}>{bp.value}</option>
                                  ))}
                                </TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem}>
                              <label className={classes.formItemLabel}>Buton Örnek</label>
                              <Button style={{ background: _result(FORM?.dugme_ayarlari, 'color'), color: _result(FORM?.dugme_ayarlari, 'text_color', '') }}>Örnek</Button>
                            </li>
                            <li className={classes.formItem}>
                              <label className={classes.formItemLabel}>Yazı Örnek</label>
                              <span
                                style={{
                                  fontFamily: _result(FORM?.yazi_tipi_ayarlari, 'family_name'),
                                  fontSize: _result(FORM?.yazi_tipi_ayarlari, 'size') + 'px',
                                  fontStyle: _result(FORM?.yazi_tipi_ayarlari, 'type'),
                                  fontWeight: _result(FORM?.yazi_tipi_ayarlari, 'weight')
                                }}
                              >Yazı Örneği</span>
                            </li>
                          </ul>
                          <ul className={classes.formList}>
                            <div className={classes.addNew}>
                              <Button
                                className={classes.button2}
                                style={{ borderWidth: 1, borderStyle: 'solid', borderColor: ERROR?.foto ? 'red' : 'transparent' }}
                                onClick={() => setOpenSelector(true)}
                              >GALERİDEN EKLE</Button>
                              <Button
                                className={classes.button2}
                                component="label"
                                style={{ borderWidth: 1, borderStyle: 'solid', borderColor: ERROR?.foto ? 'red' : 'transparent' }}
                              >
                                YENİ EKLE
                                <input
                                  type="file"
                                  hidden
                                  accept=".jpeg,.jpg,.png"
                                  ref={inputRef}
                                  onClick={() => inputRef.current.value = ""}
                                  onChange={(e) => resimEkle(e)}
                                />
                              </Button>
                            </div>
                            {ERROR?.foto && (
                              <div><p className="text-red-500">{ERROR.foto[0]}</p></div>
                            )}
                            <div className={classes.imageDiv}>
                              {FORM?.foto && (
                                <img src={FORM.foto.image} alt={FORM.foto.tanim} className={classes.img} />
                              )}
                            </div>
                          </ul>
                        </div>
                      </div>
                      <div className={classes.layoutContainerTab}>
                        <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                        <>
                          {HATADIL && (
                            <Hata />
                          )}
                          {!HATADIL && (
                            <LayoutDetailVerticalTab
                              tabs={tabsDil}
                              selectedTabIndex={selectedTabIndexDil}
                              onChangeTabIndex={(index) => setSelectedTabIndexDil(index)}
                              buttonShow={false}
                            >
                              {COUNT_TABS_DİL === 0 && (
                                <div className="flex flex-col items-start justify-start p-4 space-y-4">
                                  <Typography variant="body1">
                                    Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                                </div>
                              )}
                              {COUNT_TABS_DİL > 0 && (
                                <div className={classes.tabDiv}>
                                  <ul className={classes.formList}>
                                    <li className={classes.formItem} id="baslik">
                                      <label className={classes.formItemLabel} htmlFor="baslik">Başlık *</label>
                                      <Tooltip title={ERROR_DIL?.baslik ? ERROR_DIL.baslik[0] : _isNil(FORM_DİL?.baslik) ? '' : FORM_DİL.baslik}>
                                        <TextField
                                          {...createTextFieldProps2('baslik')}
                                          onChange={(e) => handleChangeFormDil('baslik', e.target.value)}
                                          onBlur={() => handleInputErrors2(FORM_DİL, 'baslik', handleSetErrorsDil)}
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                    <li className={classes.formItem} id="yazi">
                                      <label className={classes.formItemLabel} htmlFor="yazi">Yazı</label>
                                      <Tooltip title={ERROR_DIL?.yazi ? ERROR_DIL.yazi[0] : _isNil(FORM_DİL?.yazi) ? '' : FORM_DİL.yazi}>
                                        <TextField
                                          {...createTextFieldProps2('yazi')}
                                          onChange={(e) => handleChangeFormDil('yazi', e.target.value)}
                                          onBlur={() => handleInputErrors2(FORM_DİL, 'yazi', handleSetErrorsDil)}
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                    <li className={classes.formItem} id="buton_yazi">
                                      <label className={classes.formItemLabel} htmlFor="buton_yazi">Buton Yazı</label>
                                      <Tooltip title={ERROR_DIL?.buton_yazi ? ERROR_DIL.buton_yazi[0] : _isNil(FORM_DİL?.buton_yazi) ? '' : FORM_DİL.buton_yazi}>
                                        <TextField
                                          {...createTextFieldProps2('buton_yazi')}
                                          onChange={(e) => handleChangeFormDil('buton_yazi', e.target.value)}
                                          onBlur={() => handleInputErrors2(FORM_DİL, 'buton_yazi', handleSetErrorsDil)}
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                  </ul>
                                </div>
                              )
                              }
                            </LayoutDetailVerticalTab>
                          )}
                        </>
                      </div>


                    </>
                  }
                </LayoutDetailVerticalTab>
              </div>

              {openPicker1 &&
                <Dialog
                  open={openPicker1}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenPicker1(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <div className="flex w-full h-full overflow-hidden">
                      <SketchPicker
                        color={_result(FORM?.dugme_ayarlari, 'color')}
                        onChangeComplete={(color) => handleChangeForm('dugme_ayarlari', 'color', color.hex)}
                        width='100%'
                        disableAlpha={true}
                      />
                    </div>
                  </DialogContent>
                </Dialog>
              }
              {openPicker2 &&
                <Dialog
                  open={openPicker2}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenPicker2(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <div className="flex w-full h-full overflow-hidden">
                      <SketchPicker
                        color={_result(FORM?.dugme_ayarlari, 'text_color')}
                        onChangeComplete={(color) => handleChangeForm('dugme_ayarlari', 'text_color', color.hex)}
                        width='100%'
                        disableAlpha={true}
                      />
                    </div>
                  </DialogContent>
                </Dialog>
              }

              {openSelectorResim &&
                <Dialog
                  open={openSelectorResim}
                  PaperProps={{
                    style: {
                      width: '600px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorResim(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <FotoAddDialog
                      image={uploadImageFile}
                      onChange={(res) => handleChangeForm('foto', '', res)}
                      close={CloseSelectorResim}
                      fototip={2}
                      type={uploadImageType}
                    />
                  </DialogContent>
                </Dialog>
              }


              {openSelector &&
                <Dialog
                  open={openSelector}
                  PaperProps={{
                    style: {
                      width: '600px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelector(false);
                    handleInputErrors2(FORM, 'foto', handleSetErrors);
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListPhotoSelector
                      defaultItems={FORM?.foto}
                      onClose={() => {
                        setOpenSelector(false);
                        handleInputErrors2(FORM, 'foto', handleSetErrors);
                      }}
                      onChangeItems={(items) => handleChangeForm('foto', '', items)}
                      single={true}
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}

        </>

      }
    </>
  );
});

export default BilesenDetailTabAfisSet;
