import React, { useCallback, useMemo } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  // Dialog,
  // DialogContent,
  Tooltip,
} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';

import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';


const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
  };
};

const BilesenDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="tanim">
                    <label className={classes.layoutFormItemLabel} htmlFor="tanim">Bileşen Tanım *</label>
                    <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                      <TextField
                        {...createTextFieldProps('tanim')}
                        onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                        onBlur={() => handleInputErrors('bilesen', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Bileşen Tanım', 'bileşen')}
                        inputProps={{
                          maxLength: 250,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="baslik">
                    <label className={classes.layoutFormItemLabel} htmlFor="baslik">Başlık *</label>
                    <Tooltip title={props.error?.baslik ? props.error.baslik[0] : _isNil(props.form?.baslik) ? '' : props.form.baslik}>
                      <TextField
                        {...createTextFieldProps('baslik')}
                        onChange={(e) => props.onChangeForm('baslik', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'baslik', true, false, false, '', '', '')}
                        inputProps={{
                          maxLength: 255,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="bilesen_tip">
                    <label className={classes.layoutFormItemLabel} htmlFor="bilesen_tip">Bileşen Tip *</label>
                    <Tooltip title={props.error?.bilesen_tip ? props.error.bilesen_tip[0] : _isNil(props.form?.bilesen_tip) ? '' : props.bilesenTipDic ? props.bilesenTipDic[props.form.bilesen_tip] : ''}>
                      <TextField
                        {...createTextFieldProps('bilesen_tip')}
                        onChange={(e) => props.onChangeForm('bilesen_tip', e.target.value)}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        {props.bilesenTip.map((bt) => (
                          <option key={bt.key} value={bt.key}>{bt.value}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="temp">
                    <label className={classes.layoutFormItemLabel} htmlFor="temp">Template *</label>
                    <Tooltip title={props.error?.temp ? props.error.temp[0] : (props.form?.temp === '-1' || _isNil(props.form?.temp)) ? '' : props.form.temp}>
                      <TextField
                        {...createTextFieldProps('temp')}
                        onChange={(e) => props.onChangeForm('temp', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'temp', true, false, false, '', '', '')}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option key='-1' value='-1'>Seçilmedi</option>
                        {props.tempList.map((t) => (
                          <option key={t.id} value={t.tanim}>{t.tanim}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="mobile_temp">
                    <label className={classes.layoutFormItemLabel} htmlFor="mobile_temp">Mobil Template</label>
                    <Tooltip title={props.error?.mobile_temp ? props.error.mobile_temp[0] : (props.form?.mobile_temp === '-1' || _isNil(props.form?.mobile_temp)) ? '' : props.form.mobile_temp}>
                      <TextField
                        {...createTextFieldProps('mobile_temp')}
                        onChange={(e) => props.onChangeForm('mobile_temp', e.target.value)}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option key='-1' value='-1'>Seçilmedi</option>
                        {props.tempListFilterMobil.map((t) => (
                          <option key={t.id} value={t.tanim}>{t.tanim}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="siralama">
                    <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                    <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                      <TextField
                        {...createTextFieldProps('siralama')}
                        onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                        type="number"
                        inputProps={{
                          min: -2147483648,
                          max: 2147483647,
                          step: 1,
                          style: { textAlign: 'right' }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="aktif">
                    <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                    <Checkbox
                      name="aktif"
                      color="primary"
                      checked={_result(props.form, 'aktif', false)}
                      onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                    ></Checkbox>
                  </li>
                </ul>

              </div>
            </>
          )}
        </>
      }
    </>
  );
});

export default BilesenDetailTabGenel;
