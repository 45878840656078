// import React, { useState, useLayoutEffect } from 'react';
// import django from 'api/django';
// import Layout from '../../../pages/base/LayoutTableDetail';
// import Detail from './BilesenDetail';


// const COLUMNS_DEFAULT = [
//   { id: 'tanim',                tableProp: 'tanim',                         filterProp: 'tanim',                  label: 'Bileşen Tanım',        element: 'input',            type: 'text',     arrayTableProp: null,     show: true, sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
//   { id: 'baslik',               tableProp: 'baslik',                        filterProp: 'baslik',                 label: 'Bileşen Başlık',       element: 'input',            type: 'text',     arrayTableProp: null,     show: true, sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
//   { id: 'bilesen_tip',          tableProp: 'bilesen_tip.value',             filterProp: 'bilesen_tip',            label: 'Bileşen Tip',          element: 'select',           type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey:'key',  selectValue:'value'},
//   { id: 'bolumler',             tableProp: 'bolumler',                      filterProp: 'bolumler.bolum.id',      label: 'Bölümler',             element: 'select-multiple',  type: 'array',    arrayTableProp: 'tanim',  show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'bolum',      multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null ,  selectKey: null,  selectValue: null  },
//   { id: 'siralama',             tableProp: 'siralama',                      filterProp: 'siralama',               label: 'Sıralama',             element: 'input',            type: 'number',   arrayTableProp: null,     show: true, sortable: false, highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
//   { id: 'aktif',                tableProp: 'aktif',                         filterProp: 'aktif',                  label: 'Aktif',                element: 'select',           type: 'boolean',  arrayTableProp: null,     show: true, sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey:'key',  selectValue:'value'},
//   { id: 'olusturan_kullanici',  tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id', label: 'Oluşturan Kullanıcı',  element: 'select-multiple',  type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null ,  selectKey: null,  selectValue: null  },
//   { id: 'degistiren_kullanici', tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',label: 'Değiştiren Kullanıcı', element: 'select-multiple',  type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null ,  selectKey: null,  selectValue: null  },
//   { id: 'olusturma_zamani',     tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',       label: 'Oluşturma',            element: 'input',            type: 'datetime', arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
//   { id: 'degistirme_zamani',    tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',      label: 'Son Değişiklik',       element: 'input',            type: 'datetime', arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null ,  selectKey: null,  selectValue: null  },
// ];

// const PageBilesenList = () => {
//   const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

//   useLayoutEffect(() => {
//     const selectOptions = [
//       { key: true, value: 'Evet' },
//       { key: false, value: 'Hayır' },
//     ];

//     setColumnsDefault((prev) => {
//       return prev.map((column) => {
//         if (column.type === 'boolean') {
//           return {
//             ...column,
//             selectOptions,
//           };
//         }  
//         return column;
//       });
//     });
//   }, []);

//   useLayoutEffect(() => {
//     django('bilesen/bilesen_tip').then(({ data }) => {
//       setColumnsDefault((prev) => {
//         return prev.map((column) => {
//           if (column.id === 'bilesen_tip') {
//             return {
//               ...column,
//               selectOptions: data,
//             };
//           }
//           return column;
//         });
//       });
//     })
//   }, []);

//   return (
//     <>
//       <Layout
//         title="BİLEŞENLER"
//         path="bilesen"
//         columnsDefault={columnsDefault}
//         detailComponent={Detail}
//         storekey="bilesen"
//       />
//     </>
//   );
// };

// export default PageBilesenList;

import React from 'react';
import Layout from './BilesenGrid';
import Detail from './BilesenDetail';


const PageBilesenList = () => {

  return (
    <>
      <Layout
        title="BİLEŞENLER"
        path="bilesen"
        detailComponent={Detail}
        storekey="bilesen"
      />
    </>
  );
};

export default PageBilesenList;

