import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import moment from 'moment';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';

import TabGenel from './FiyatTanimDetailTab_Genel';
import TabDetay from './FiyatTanimDetailTab_FiyatDetay';

const PATH = 'fiyattanim';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'LİSTE', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  tanim: '',
  // sezon: null,
  siralama: null,
  aktif: true,
  gecerlilik_baslangic_tarihi: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
  gecerlilik_bitis_tarihi: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
};

const FIYAT_DETAY_DEFAULT = {
  form: {
    tanim: 'Tanımsız',
    vergi_dahil: false,
    varsayilan: false,
    kullanici_gruplari: [],
    magazalar: [],
    indirim_gecerlilik_baslangic_tarihi: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
    indirim_gecerlilik_bitis_tarihi: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm')
  },
  errors: null
}

const FiyatTanimDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingListe, setLoadingListe] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataListe, setHataListe] = useState(null);

  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [fiyatlarSelectedTabIndex, setFiyatlarSelectedTabIndex] = useState(0);
  const [fiyatlarFormsDefault, setFiyatlarFormsDefault] = useState([]);
  const [fiyatlarForms, setFiyatlarForms] = useState([]);
  const [fiyatlarAdd, setFiyatlarAdd] = useState(true);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteId, setDeleteIdDetail] = useState(null);
  const [showDeleteDialogDetail, setShowDeleteDialogDetail] = useState(false);
  const [isDeletingDetail, setIsDeletingDetail] = useState(false);
  const [isDeletingErrorMessageDetail, setIsDeletingErrorMessageDetail] = useState(null);

  const [type, setType] = useState(props.eventType ?? 0);
  const [loadDone, setLoadDone] = useState(false);
  const [loadTab1, setLoadTab1] = useState(false);
  const [loadTab2, setLoadTab2] = useState(false);
  const [equal1, setEqual1] = useState(true);
  const [equal2, setEqual2] = useState(true);
  const [saveErrors, setSaveErrors] = useState([[], []]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props.id]);
  const IS_ADD = useMemo(() => props.addnew, [props.addnew]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(fiyatlarForms, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualFiyat = _isEqual(fiyatlarForms, fiyatlarFormsDefault);
    return isEqualGenel && isEqualFiyat;
  }, [add, genelForm, genelFormDefault, fiyatlarForms, fiyatlarFormsDefault]);

  useLayoutEffect(() => {
    setAdd(false);
    setLoadDone(false);
  }, [ID]);

  useLayoutEffect(() => {
    if (loadDone) {
      setType(2);
    } else {
      setType(props.eventType ?? 0);
    }
  }, [props.eventType, loadDone]);

  useLayoutEffect(() => {
    if (type === 0) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setFiyatlarForms([]);
      setFiyatlarFormsDefault([]);
      setTabs(TABS_DEFAULT);
      if (selectedTabIndex === 0) {
        setLoadTab1(true);
      } else {
        setLoadTab1(true);
        setLoadTab2(true);
      }
      setLoadDone(true);
    } else if (type === 1) {
      if (selectedTabIndex === 0) {
        setGenelForm(null);
        setGenelFormDefault(null);
        if (!saveErrors[1].includes("err")) {
          setFiyatlarForms([]);
          setFiyatlarFormsDefault([]);
        }
        setLoadTab1(true);
      } else {
        setGenelForm(null);
        setGenelFormDefault(null);
        setLoadTab1(true);
        if (!saveErrors[1].includes("err")) {
          setFiyatlarForms([]);
          setFiyatlarFormsDefault([]);
          setLoadTab2(true);
        }
      }
      setLoadDone(true);
    } else {
      if (!add) {
        if (selectedTabIndex === 0) {
          if (equal1) {
            setLoadTab1(true);
          }
        } else {
          if (equal2) {
            setLoadTab2(true);
          }
        }
      } else {
        setLoading(false);
        setLoadingListe(false);
        setLoadTab1(false);
        setLoadTab2(false);
      }
    }
  }, [type, selectedTabIndex, saveErrors, equal1, equal2, add]);

  useLayoutEffect(() => {
    if (add) {
      setEqual1(_isEqual(genelForm, FORM_DEFAULT));
    } else {
      setEqual1(_isEqual(genelForm, genelFormDefault));
    }
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual2(_isEqual(fiyatlarForms, []));
    } else {
      setEqual2(_isEqual(fiyatlarForms, fiyatlarFormsDefault));
    }
  }, [add, fiyatlarForms, fiyatlarFormsDefault]);

  useLayoutEffect(() => {
    if (loadTab1) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              //sezon: data.sezon,
              siralama: String(data.siralama),
              aktif: data.aktif,
              gecerlilik_baslangic_tarihi: moment(data.gecerlilik_baslangic_tarihi).format('YYYY-MM-DD[T]HH:mm'),
              gecerlilik_bitis_tarihi: moment(data.gecerlilik_bitis_tarihi).format('YYYY-MM-DD[T]HH:mm'),
            };
            setGenelFormDefault(form);
            setGenelForm(form);
            setHataGenel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setLoadTab1(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DEFAULT);
        setGenelFormDefault(FORM_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
        setLoadTab1(false);
      }
    }
  }, [ID, IS_ADD, loadTab1]);

  useLayoutEffect(() => {
    if (loadTab2) {
      if (!IS_ADD) {
        setLoadingListe(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/fiyatdetaylari`).then(({ data }) => {
            const forms = data.map((fiyat) => (
              {
                form: {
                  id: fiyat.id,
                  tanim: fiyat.tanim,
                  vergi_dahil: fiyat.vergi_dahil,
                  varsayilan: fiyat.varsayilan,
                  kullanici_gruplari: fiyat.kullanici_gruplari,
                  magazalar: fiyat.magazalar,
                  indirim_gecerlilik_baslangic_tarihi: moment(fiyat.indirim_gecerlilik_baslangic_tarihi).format('YYYY-MM-DD[T]HH:mm'),
                  indirim_gecerlilik_bitis_tarihi: moment(fiyat.indirim_gecerlilik_bitis_tarihi).format('YYYY-MM-DD[T]HH:mm')
                },
                errors: null
              }
            ));
            setFiyatlarFormsDefault(forms);
            setFiyatlarForms(forms);
            setHataListe(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataListe(true);
              }
            }
          }).finally(() => {
            setLoadingListe(false);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        }
      } else {
        setFiyatlarFormsDefault([]);
        setFiyatlarForms([]);
        setHataListe(null);
        setLoadingListe(false);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, loadTab2]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleFiyatChangeForm = (index, property, value) => {
    setFiyatlarForms((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      });
    });
  };

  const handleFiyatlarAddNewTab = () => {
    setFiyatlarAdd(false);
    setFiyatlarForms((prev) => immer(prev, (next) => {
      next.push({ ...FIYAT_DETAY_DEFAULT });
    }));
  }


  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setFiyatlarForms([]);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLoadTab1(true);
    setLoadTab2(true);
    setFiyatlarAdd(true);
    setTabs(TABS_DEFAULT);
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    setFiyatlarForms(fiyatlarFormsDefault);
    setFiyatlarAdd(true);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
      });
    });
  };

  const handleSave = async () => {
    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (add ? _isEqual(genelForm, FORM_DEFAULT) : _isEqual(genelForm, genelFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            reqopt = {
              method: add ? 'POST' : 'PUT',
              id: null,
              data: { ...genelForm, siralama: genelForm.siralama ? Number(genelForm.siralama) : 0 },
              successMessage: `Fiyat Listesi ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Fiyat Listesi ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabListe = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = fiyatlarFormsDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'tanim', prop2: '' },
        ];
        await checkAllTabs(next.form, (err) => handleErrorsDetail(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const data = { ...next.form, magazalar: next.form.magazalar?.map((m) => m.id), kullanici_gruplari: next.form.kullanici_gruplari?.map((k) => k.id) };
            if (isHas) {
              reqopt = {
                data: data,
                method: "PUT",
                id: next.form.id,
                successMessage: `${data.tanim} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${data.tanim} güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: data,
                method: "POST",
                id: null,
                successMessage: `${data.tanim} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${data.tanim} eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    let errors = [[], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'liste', request: [], error: [] },
      ];
      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in fiyatlarForms) {
        const fiyatstate = [...fiyatlarForms];
        const res = await createRequestOptionsTabListe(fiyatstate[i], i)
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "fiyattanim" : `fiyattanim/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.fiyat_tanim.detail(data.id,"detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }

            if (tab.name === 'liste') {
              setFiyatlarForms((prev) => {
                return immer(prev, (next) => {
                  next[requestIndex].errors = null;
                })
              });
              if (SAVEID !== null) {
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `fiyattanim/${SAVEID}/fiyatdetaylari/${request.id}` : `fiyattanim/${SAVEID}/fiyatdetaylari`
                }).then(() => {
                  setFiyatlarAdd(true);
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setFiyatlarAdd(false);
                  setSelectedTabIndex(tab.index);
                  setFiyatlarSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setFiyatlarForms((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }
    await start();
    if (SAVEID !== null) {
      if (!add) {
        if (!errors[0].includes("err")) {
          setLoadTab1(true);
        }
        if (!errors[1].includes("err")) {
          setLoadTab2(true);
        }
      } else {
        setSaveErrors(errors);
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
      await checkAll();
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? false : true : true;
        next[1].hasError = !errors[1].includes("err") ? false : true;
      });
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.grup.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteDetail = () => {
    setIsDeletingDetail(true);
    setIsDeletingErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/fiyatdetaylari/${detailDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetail(false);
        setIsDeletingErrorMessageDetail(null);
      } else {
        setIsDeletingErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingDetail(false);
      setLoadTab2(true);
    });
  }

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Fiyat Tanım', pagename: 'fiyat', prop2: '' },
    ]
    let errors = [];
    await checkAllList('fiyattanim', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsDetail = (errors, index) => {
    setFiyatlarForms((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}

        {selectedTabIndex === 1 &&
          <TabDetay
            loading={loadingListe}
            hata={hataListe}

            selectedTabIndex={fiyatlarSelectedTabIndex}
            forms={fiyatlarForms}
            minDate={genelForm?.gecerlilik_baslangic_tarihi}
            maxDate={genelForm?.gecerlilik_bitis_tarihi}
            onChangeForm={handleFiyatChangeForm}
            onAddNewTab={handleFiyatlarAddNewTab}
            fiyatlarAdd={fiyatlarAdd}

            onCheckErrors={(errors, index) => handleErrorsDetail(errors, index)}

            setDeleteId={setDeleteIdDetail}
            openDeleteForm={setShowDeleteDialogDetail}
          ></TabDetay>
        }
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Fiyat Listesi siliniyor lütfen bekleyin'
                : 'Bu fiyat listesini gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetail &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetail
              ? isDeletingErrorMessageDetail
              : isDeletingDetail
                ? 'Fiyat siliniyor lütfen bekleyin'
                : 'Bu fiyatı gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetail}
          onSubmit={handleDeleteDetail}
          onCancel={() => setShowDeleteDialogDetail(false)}
        ></DialogConfirm>
      }
    </>
  );
};

export default FiyatTanimDetail;

