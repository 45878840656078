import React, { useCallback, useMemo } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Tooltip
} from '@material-ui/core';

import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
};

const FiyatTanimDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const setValidDate = (prop) => {
    let errors = {};
    if (prop === 'gecerlilik_baslangic_tarihi') {
      if (props.form.gecerlilik_baslangic_tarihi > props.form.gecerlilik_bitis_tarihi) {
        errors[prop] = ['Başlangıç tarihi bitiş tarihinden sonra olamaz.']
      } else {
        errors['gecerlilik_bitis_tarihi'] = null;
        errors[prop] = null;
      }
    } else {
      if (props.form.gecerlilik_bitis_tarihi < props.form.gecerlilik_baslangic_tarihi) {
        errors[prop] = ['Bitiş tarihi başlangıç tarihinden önce olamaz.']
      } else {
        errors['gecerlilik_baslangic_tarihi'] = null;
        errors[prop] = null;
      }
    }
    props.onCheckErrors(errors);
  }

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.layoutContainer}>
              <ul className={classes.layoutFormList}>
                <li className={classes.layoutFormItem} id="tanim">
                  <label className={classes.layoutFormItemLabel} htmlFor="tanim">Fiyat Tanım *</label>
                  <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                    <TextField
                      {...createTextFieldProps('tanim')}
                      onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                      onBlur={() => handleInputErrors('fiyattanim', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Fiyat Tanım', 'fiyat')}
                      inputProps={{
                        maxLength: 250,
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="gecerlilik_baslangic_tarihi">
                  <label className={classes.layoutFormItemLabel} htmlFor="gecerlilik_baslangic_tarihi">Başlangıç Tarihi</label>
                  <Tooltip title={props.error?.gecerlilik_baslangic_tarihi ? props.error.gecerlilik_baslangic_tarihi[0] : _isNil(props.form?.gecerlilik_baslangic_tarihi) ? '' : moment(props.form.gecerlilik_baslangic_tarihi).format('DD/MM/YYYY, HH.mm')}>
                    <TextField
                      {...createTextFieldProps('gecerlilik_baslangic_tarihi')}
                      onChange={(e) => props.onChangeForm('gecerlilik_baslangic_tarihi', e.target.value)}
                      type="datetime-local"
                      onBlur={() => setValidDate('gecerlilik_baslangic_tarihi')}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="gecerlilik_bitis_tarihi">
                  <label className={classes.layoutFormItemLabel} htmlFor="gecerlilik_bitis_tarihi">Bitiş Tarihi</label>
                  <Tooltip title={props.error?.gecerlilik_bitis_tarihi ? props.error.gecerlilik_bitis_tarihi[0] : _isNil(props.form?.gecerlilik_bitis_tarihi) ? '' : moment(props.form.gecerlilik_bitis_tarihi).format('DD/MM/YYYY, HH.mm')}>
                    <TextField
                      {...createTextFieldProps('gecerlilik_bitis_tarihi')}
                      onChange={(e) => props.onChangeForm('gecerlilik_bitis_tarihi', e.target.value)}
                      type="datetime-local"
                      onBlur={() => setValidDate('gecerlilik_bitis_tarihi')}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="siralama">
                  <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                  <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                    <TextField
                      {...createTextFieldProps('siralama')}
                      onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                      type="number"
                      inputProps={{
                        min: -2147483648,
                        max: 2147483647,
                        step: 1,
                        style: { textAlign: 'right' }
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="aktif">
                  <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                  <Checkbox
                    name="aktif"
                    color="primary"
                    checked={_result(props.form, 'aktif', false)}
                    onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                  ></Checkbox>
                </li>
              </ul>
            </div>
          )}
        </>
      }
    </>
  );
});

export default FiyatTanimDetailTabGenel;
