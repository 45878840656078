import React, { useState, useEffect, useLayoutEffect, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import Highlighter from 'react-highlight-words';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import RightMenu from '../helpers/rightMenu';
import moment from 'moment';

import {
  Button,
  Paper,
  CircularProgress,
  LinearProgress,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Tooltip,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
  Search as IconSearch,
  Refresh as IconRefresh,
} from '@material-ui/icons';

import django from '../api/django';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    }
  };
});

const PER_PAGE = 100;
const ROW_HEIGHT = 34;

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text, submitText, onSubmit }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>

      <p className="font-medium mb-2">{text}</p>

      {onSubmit &&
        <Button
          color="primary"
          onClick={onSubmit}
        >{submitText ?? 'TEKRAR DENE'}</Button>
      }
    </div>
  );
};

const ListItem = ({ item, defaultObj, searchQuery, grid }) => {
  return (
    <div
      key={`${item.id}`}
      className={clsx('w-full grid items-center justify-start hover:text-primary px-4 select-none gap-2')}
      style={{ height: ROW_HEIGHT, gridTemplateColumns: grid }}
    >
      {defaultObj.map((x) => (
        <Tooltip title={item[x.key]} key={x.key + item.id}>
          <span className="truncate">{item[x.key]}</span>
        </Tooltip>
      ))}
    </div>
  );
};

const ListSelector = ({ title, listPath, listCountPath, searchKeyProp, searchKeyTitle, onClose, defaultObj, dics, grid }) => {

  const [items, setItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsLoadingErrorMessage, setItemsLoadingErrorMessage] = useState(null);

  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchItems, setSearchItems] = useState([]);
  const [searchItemsLoading, setSearchItemsLoading] = useState(false);
  const [searchItemsLoadingErrorMessage, setSearchItemsLoadingErrorMessage] = useState(null);

  const [selectedItem, setSelectedItem] = useState(null);
  // const [selectedPosition, setSelectedPosition] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const classes = useStyles();

  const mainContainerRef = useRef();

  const PAGE = useMemo(() => items.length > 0 ? Math.ceil(items.length / PER_PAGE) : 1, [items.length]);
  const PAGES_COUNT = useMemo(() => Math.ceil(itemsTotalCount / PER_PAGE), [itemsTotalCount]);

  const SHOW_LINEAR_PROGRESS = useMemo(() => {
    if (itemsLoading || moreItemsLoading || searchItemsLoading) {
      return true;
    }
    return false;
  }, [itemsLoading, moreItemsLoading, searchItemsLoading]);

  const LOADING_MESSAGE = useMemo(() => {
    if (itemsLoading) {
      return 'Seçenekler yükleniyor';
    }
    if (searchItemsLoading) {
      return 'Arama yapılıyor';
    }
    return null;
  }, [itemsLoading, searchItemsLoading]);

  const ERROR_MESSAGE = useMemo(() => {
    if (itemsLoadingErrorMessage) {
      return itemsLoadingErrorMessage;
    }
    if (searchItemsLoadingErrorMessage) {
      return searchItemsLoadingErrorMessage;
    }
    return null;
  }, [itemsLoadingErrorMessage, searchItemsLoadingErrorMessage]);

  const FILTERED_ITEMS = useMemo(() => {
    if (searchQuery !== '') {
      return searchItems;
    }
    return items;
  }, [items, searchItems, searchQuery]);

  const mapItem = useCallback((item) => {
    let obj = {}
    for (let i = 0; i < defaultObj.length; i++) {
      let value = "";
      switch (defaultObj[i].type) {
        case "text":
          value = item[defaultObj[i].key];
          break;
        case "array":
          value = item[defaultObj[i].key].map(x => x[defaultObj[i].keyProp]).join(", ");
          break;
        case "date":
          value = moment(item[defaultObj[i].key]).format('DD/MM/YYYY, HH.mm');
          break;
        case "obj":
          value = item[defaultObj[i].key][defaultObj[i].keyProp];
          break;
        case "dic":
          value = dics[defaultObj[i].key][item[defaultObj[i].key]];
          break;
        case "bool":
          value = item[defaultObj[i].key] === true ? "Evet" : "Hayır";
          break;
        default:
          break;
      }
      obj[defaultObj[i].key] = value;
    }
    obj["id"] = item.id;
    return obj;
  }, [defaultObj, dics]);

  // set items
  useLayoutEffect(() => {
    setItemsLoading(true);
    django(listPath, { params: { size: PER_PAGE } }).then(({ data }) => {
      const mapped = data.map(mapItem);
      setItems(mapped);
    }).catch(() => {
      setItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
    }).finally(() => {
      setItemsLoading(false);
    });
  }, [listPath, mapItem, lastUpdateTime]);

  // set options count
  useLayoutEffect(() => {
    let url = `${listCountPath}`;

    django(url).then(({ data }) => {
      setItemsTotalCount(data);
    });
  }, [listCountPath, lastUpdateTime]);

  // handle search
  useEffect(() => {
    const handleGet = ({ url, config }) => {
      setSearchItems([]);
      setSearchItemsLoading(true);
      setSearchItemsLoadingErrorMessage(null);

      django(url, config).then(({ data }) => {
        const mapped = data.map(mapItem);

        setSearchItems(mapped);
      }).catch(function (error) {
        if (django?.isCancel(error)) {
        } else {
          setSearchItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
        }
      }).finally(() => {
        setSearchItemsLoading(false);
      });
    };

    if (searchQuery === '') {
      setSearchItems([]);
      setSearchItemsLoading(false);
      setSearchItemsLoadingErrorMessage(null);
    }

    if (searchQuery !== '') {
      setSearchItems([]);
      setSearchItemsLoading(true);
      setSearchItemsLoadingErrorMessage(null);

      const CancelToken = django.CancelToken;
      const cancelTokenSource = CancelToken.source();

      const config = {
        params: {
          size: PER_PAGE,
          [searchKeyProp]: `*${searchQuery}*`,
        },
        cancelToken: cancelTokenSource.token,
      };

      const debounce = setTimeout(() => handleGet({ url: listPath, config }), 300);

      return () => {
        cancelTokenSource.cancel();

        clearTimeout(debounce);

        setSearchItemsLoading(false);
      };
    }
  }, [mapItem, searchQuery, listPath, searchKeyProp, lastUpdateTime]);

  const handleGetMoreOptions = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setMoreItemsLoading(true);
    django(listPath, { params: { size: PER_PAGE, page } }).then(({ data }) => {
      const mapped = data.map(mapItem);
      setItems((prev) => [...prev, ...mapped]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setMoreItemsLoading(false);
    });
  };

  const rowRenderer = ({ index, style }) => {
    const item = FILTERED_ITEMS[index];
    return (
      <div key={`mesaj-${item.id}`} style={style}
      >
        {index !== 0 && <Divider />}
        <ListItem
          item={item}
          defaultObj={defaultObj}
          searchQuery={searchQuery}
          grid={grid}
        ></ListItem>
      </div>
    );
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  return (
    <>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">{title ? title : 'Filtrele'}</h3>
            <span>
              <Tooltip title="Yenile">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={handleReload}
                ><IconRefresh /></IconButton>
              </Tooltip>
              <Tooltip title="Kapat">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={onClose}
                ><IconClose /></IconButton>
              </Tooltip>
            </span>
          </Toolbar>
          {searchKeyProp && (
            <nav className="w-full flex-1 flex items-center justify-between bg-palette-background-default py-2 px-4 space-x-4">
              <span className="relative w-full h-8 leading-none bg-palette-background-paper apperance-none">
                <input
                  className={clsx([
                    'absolute inset-0 w-full h-full px-10 bg-transparent rounded border border-palette-action-selected',
                    'focus:outline-none focus:border-primary-300 focus:ring-2 focus:ring-primary-200',
                  ])}
                  value={searchQuery ?? ''}
                  placeholder={`${searchKeyTitle} göre Ara`}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />

                <span className="absolute top-2 left-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                  <IconSearch />
                </span>

                {searchQuery.length > 0 &&
                  <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSearchQuery('');
                      }}
                    >
                      <IconClose />
                    </IconButton>
                  </span>
                }
              </span>
            </nav>
          )}
          <nav className="w-full flex-1 flex items-center justify-between bg-palette-background-default py-2 border-t border-palette-action-selected">
            <div className='w-full grid items-center justify-start px-4 select-none gap-2' style={{ gridTemplateColumns: grid }}>
              {defaultObj.map((x, index) => (
                <div key={x.title}>
                  <span>{x.title}</span>
                </div>
              ))}
            </div>
          </nav>
          <span className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', { 'hidden': !SHOW_LINEAR_PROGRESS })}>
            <LinearProgress style={{ height: 2 }} />
          </span>
        </AppBar>

        <main className="relative flex flex-grow flex-col overflow-hidden" ref={mainContainerRef}>
          <div className="h-full flex-grow">
            {LOADING_MESSAGE && (
              <LoadingMessage text={LOADING_MESSAGE} />
            )}

            {ERROR_MESSAGE && (
              <ErrorMessage text={ERROR_MESSAGE} />
            )}

            {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length === 0) && (
              <ErrorMessage text="Seçenek bulunamadı" />
            )}

            {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length > 0) && (
              <InfiniteLoader
                minimumBatchSize={PER_PAGE}
                threshold={PER_PAGE}
                isRowLoaded={({ index }) => !!FILTERED_ITEMS[index]}
                loadMoreRows={({ startIndex, stopIndex }) => {
                  if (itemsTotalCount > 0 && PAGES_COUNT > PAGE && !moreItemsLoading) {
                    handleGetMoreOptions(PAGE + 1);
                  }
                }}
                rowCount={itemsTotalCount > 0 ? itemsTotalCount : PER_PAGE * 2}
              >
                {({ onRowsRendered, registerChild }) => (
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        width={width}
                        height={height}
                        rowHeight={ROW_HEIGHT + 1}
                        rowCount={FILTERED_ITEMS.length}
                        estimatedRowSize={PAGES_COUNT > 0 ? PAGES_COUNT * (ROW_HEIGHT + 1) : undefined}
                        rowRenderer={rowRenderer}

                        onRowsRendered={onRowsRendered}
                        ref={registerChild}
                        onScroll={() => setSelectedItem(null)}
                      />
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            )}
          </div>
          {selectedItem && (
            <RightMenu
            // url={newPath}
            // itemId={selectedItem?.key}
            // ustId={ustId}
            // position={selectedPosition}
            ></RightMenu>
          )}
        </main>
      </Paper>
    </>
  );
};

ListSelector.propTypes = {
  title: PropTypes.string.isRequired,
  listPath: PropTypes.string.isRequired,
  listCountPath: PropTypes.string,
  keyProp: PropTypes.string,
  valueProp: PropTypes.string,
  defaultItems: PropTypes.array,
  onChangeItems: PropTypes.func,
  onClose: PropTypes.func,
};

ListSelector.defaultTypes = {
  listCountPath: undefined,
  keyProp: 'id',
  valueProp: 'tanim',
  defaultItems: [],
  onChangeItems: () => { },
  onClose: () => { },
};

export default ListSelector;
