import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  Tooltip,
  IconButton
} from '@material-ui/core';

import {
  HelpOutline as IconHelp
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import ListSelector from '../../../components/ListSelector';
import { handleInputErrors } from '../../base/InputErrors';
import MultipleSelectList from '../../../components/MultipleSelectList';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1, 1, 1, 4),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
    layoutFormItemCaption: {
      display: 'flex',
      position: 'relative',
      height: 16,
      marginBottom: 10,
      marginTop: 10,
    },
    layoutFormItemCaptionText: {
      position: 'absolute',
      fontStyle: 'italic'
    },
  };
};

const KodSeriDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const handleRemoveMagazaById = (id) => {
    const copyMagazalar = [...props.form.magazalar];
    const index = copyMagazalar.findIndex((c) => c.id === id);
    if (index > -1) {
      copyMagazalar.splice(index, 1);
      props.onChangeForm('magazalar', copyMagazalar);
    }
  };

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="on_ek">
                    <label className={classes.layoutFormItemLabel} htmlFor="on_ek">Seri *</label>
                    <Tooltip title={props.error?.on_ek ? props.error.on_ek[0] : _isNil(props.form?.on_ek) ? '' : props.form.on_ek}>
                      <TextField
                        {...createTextFieldProps('on_ek')}
                        onChange={(e) => props.onChangeForm('on_ek', e.target.value)}
                        onBlur={() => handleInputErrors('kodseri', props.form, props.formdefault, props.onCheckErrors, add, 'on_ek', true, true, false, '', 'Seri', 'seri')}
                        inputProps={{
                          maxLength: 6,
                        }}
                      ></TextField>
                    </Tooltip>
                    <IconButton
                      size="small"
                      className={classes.button2}
                      disableRipple
                      onClick={() => {
                        setHelpText('“Başlangıç numarası, ör.: Ön ekimiz EBX olsun uzunluğu 10 yaptık ve seri başlangıcımız, 807 olsun, oluşacak olan kod arayı 0 lar ile tamamlayacaktır. sonuc; EBX0000807 şeklindedir, her yeni oluşumda artım miktarı kadar değer yükselir, ör.: artım miktarımız 5 olsun, EBX0000807 kodundan sonra oluşacak yeni kod, EBX0000812 şeklinde olacaktır.”');
                        setHelpOpen(true)
                      }}
                    ><IconHelp /></IconButton>
                  </li>
                  <li className={classes.layoutFormItem} id="kod_tip">
                    <label className={classes.layoutFormItemLabel} htmlFor="kod_tip">Tipi *</label>
                    <Tooltip title={props.error?.kod_tip ? props.error.kod_tip[0] : _isNil(props.form?.kod_tip) ? '' : props.tipListDic ? props.tipListDic[props.form.kod_tip] : ''}>
                      <TextField
                        {...createTextFieldProps('kod_tip')}
                        onChange={(e) => props.onChangeForm('kod_tip', e.target.value)}
                        onBlur={() => handleInputErrors('kodseri', props.form, props.formdefault, props.onCheckErrors, add, 'kod_tip', true, false, false, '', '', '')}
                        select
                        SelectProps={{ native: true }}
                      >
                        {props.tipList.map((tip) => (
                          <option key={tip.key} value={tip.key}>{tip.value}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="uzunluk">
                    <label className={classes.layoutFormItemLabel} htmlFor="uzunluk">Uzunluk *</label>
                    <Tooltip title={props.error?.uzunluk ? props.error.uzunluk[0] : _isNil(props.form?.uzunluk) ? '' : props.form.uzunluk}>
                      <TextField
                        {...createTextFieldProps('uzunluk')}
                        onChange={(e) => props.onChangeForm('uzunluk', e.target.value)}
                        onBlur={() => handleInputErrors('kodseri', props.form, props.formdefault, props.onCheckErrors, add, 'uzunluk', true, false, false, '', '', '')}
                        type="number"
                        inputProps={{
                          min: 8,
                          max: 30,
                          step: 1,
                          style: { textAlign: "right" }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="step">
                    <label className={classes.layoutFormItemLabel} htmlFor="step">Artım Miktarı</label>
                    <Tooltip title={props.error?.step ? props.error.step[0] : _isNil(props.form?.step) ? '' : props.form.step}>
                      <TextField
                        {...createTextFieldProps('step')}
                        onChange={(e) => props.onChangeForm('step', e.target.value)}
                        type="number"
                        inputProps={{
                          min: -32768,
                          max: 32767,
                          step: 1,
                          style: { textAlign: "right" }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="seri">
                    <label className={classes.layoutFormItemLabel} htmlFor="seri">No</label>
                    <Tooltip title={props.error?.seri ? props.error.seri[0] : _isNil(props.form?.seri) ? '' : props.form.seri}>
                      <TextField
                        {...createTextFieldProps('seri')}
                        onChange={(e) => props.onChangeForm('seri', e.target.value)}
                        type="number"
                        inputProps={{
                          min: -32768,
                          max: 32767,
                          step: 1,
                          style: { textAlign: "right" }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                </ul>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItemBox} id="magazalar">
                    <MultipleSelectList
                      headername="MAĞAZALAR"
                      name="Mağazalar"
                      name2="Mağaza"
                      form={props.form}
                      list={props?.form.magazalar ?? []}
                      listlength={props?.form?.magazalar?.length ?? 0}
                      opendialog={setOpenSelectorMagaza}
                      keyProp="id"
                      valueProp="tanim"
                      removefonk={handleRemoveMagazaById}
                      loading={props.loading}
                      error={props.error?.magazalar ? props.error.magazalar[0] : null}
                      newPath="tanimlar/magaza"
                      ustId={false}
                    />
                  </li>
                </ul>
              </div>
              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    handleInputErrors('magazalar', props.form, props.formdefault, props.onCheckErrors, add, 'uzunluk', true, false, false, '', '', '')
                    setOpenSelectorMagaza(false)
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Mağazalar'
                      listPath='magaza?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.magazalar}
                      onChangeItems={(items) => props.onChangeForm('magazalar', items)}
                      onClose={() => {
                        handleInputErrors('magazalar', props.form, props.formdefault, props.onCheckErrors, add, 'uzunluk', true, false, false, '', '', '')
                        setOpenSelectorMagaza(false)
                      }}
                      newPath="tanimlar/magaza"
                    />
                  </DialogContent>
                </Dialog>
              }
              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default KodSeriDetailTabGenel;
