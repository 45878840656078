import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  Typography,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import ListSelector from '../../../components/ListSelector';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors, handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import MultipleSelectList from '../../../components/MultipleSelectList';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),

    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: 0,
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
  };
};

const OdemeTanimDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATADIL = useMemo(() => props.hataDil, [props.hataDil]);
  const add = useMemo(() => props.add, [props.add]);

  const [openSelectorBanka, setOpenSelectorBanka] = useState(false);
  const [openSelectorBinCode, setOpenSelectorBinCode] = useState(false);


  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.dilform?.length ?? 0, [props]);
  const FORM = useMemo(() => props?.dilform?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.dilform?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.dilform?.length > 0) {
      const copyForms = [...props.dilform];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.dilform]);


  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);


  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const handleChangeForm = (property, value) => {
    props.onChangeDilForm(selectedTabIndex, property, value);
  };

  const handleSetErrors = (errors) => {
    props.onCheckErrorsDil(errors, selectedTabIndex);
  }

  const handleRemoveBankaById = (id) => {
    const copyBankalar = [...props.form.banka_hesaplari];
    const index = copyBankalar.findIndex((c) => c.id === id);
    if (index > -1) {
      copyBankalar.splice(index, 1);
      props.onChangeForm('banka_hesaplari', copyBankalar);
    }
  };

  const handleRemoveBinCodeById = (id) => {
    const copyBinkodlar = [...props.form.binkodlar];
    const index = copyBinkodlar.findIndex((c) => c.id === id);
    if (index > -1) {
      copyBinkodlar.splice(index, 1);
      props.onChangeForm('binkodlar', copyBinkodlar);
    }
  };

  const handleDetailDil = (index) => {
    props.deleteDilWithIndex(index);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>

                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Ödeme Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim')}
                          onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                          onBlur={() => handleInputErrors('odemetanim', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Ödeme Tanım', 'ödeme')}
                          inputProps={{
                            maxLength: 250,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>

                    <li className={classes.layoutFormItem} id="tip">
                      <label className={classes.layoutFormItemLabel} htmlFor="tip">Ödeme Tipi *</label>
                      <Tooltip title={props.error?.tip ? props.error.tip[0] : _isNil(props.form?.tip) ? '' : props.tipDic ? props.tipDic[props.form.tip] : ''}>
                        <TextField
                          {...createTextFieldProps('tip')}
                          onChange={(e) => {
                            if (add) {
                              props.onChangeForm('tip', e.target.value)
                            } else {
                              props.onCheckErrors({ tip: ['Kayıtlı ödeme tanımında bu alan değiştirilemez.'] })
                            }
                          }}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'tip', true, false, false, '', '', '')}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          {props.tip?.map((t) => (
                            <option key={t.key} value={t.key}>{t.value}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                    </li>

                    {props.form?.tip === '3' && (
                      <li className={classes.layoutFormItem} id="odeme_servis">
                        <label className={classes.layoutFormItemLabel} htmlFor="tip">Ödeme Servisi</label>
                        <Tooltip title={props.error?.odeme_servis ? props.error.odeme_servis[0] : (props.form?.odeme_servis === '-1' || _isNil(props.form?.odeme_servis)) ? '' : props.servisDic ? props.servisDic[props.form.odeme_servis] : ''}>
                          <TextField
                            {...createTextFieldProps('odeme_servis')}
                            onChange={(e) => props.onChangeForm('odeme_servis', e.target.value)}
                            onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'odeme_servis', true, false, false, '', '', '')}
                            select
                            SelectProps={{
                              native: true,
                            }}
                          >
                            <option value='-1' disabled>Seçilmedi</option>
                            {props.servis?.map((t) => (
                              <option key={t.id} value={t.id}>{t.tanim}</option>
                            ))}
                          </TextField>
                        </Tooltip>
                      </li>
                    )}
                    {props.form?.tip === '3' && (
                      <li className={classes.layoutFormItem} id="odeme_3d_bicim">
                        <label className={classes.layoutFormItemLabel} htmlFor="odeme_3d_bicim">3d Ödeme</label>
                        <Tooltip title={props.error?.odeme_3d_bicim ? props.error.odeme_3d_bicim[0] : _isNil(props.form?.odeme_3d_bicim) ? '' : props.form.odeme_3d_bicim === "0" ? "3d Ödeme Kullanma" : props.form.odeme_3d_bicim === "1" ? "Kullanıcıya Sor" : "3d Ödeme Kullan"}>
                          <TextField
                            {...createTextFieldProps('odeme_3d_bicim')}
                            onChange={(e) => props.onChangeForm('odeme_3d_bicim', e.target.value)}
                            onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'odeme_3d_bicim', true, false, false, '', '', '')}
                            select
                            SelectProps={{
                              native: true,
                            }}
                          >
                            <option key="0" value="0">3d Ödeme Kullanma</option>
                            <option key="1" value="1">Kullanıcıya Sor</option>
                            <option key="2" value="2">3d Ödeme Kullan</option>
                          </TextField>
                        </Tooltip>
                      </li>
                    )}

                    <li className={classes.layoutFormItem} id="hedef_evrak_tip">
                      <label className={classes.layoutFormItemLabel} htmlFor="hedef_evrak_tip">Hedef Evrak Tip *</label>
                      <Tooltip title={props.error?.hedef_evrak_tip ? props.error.hedef_evrak_tip[0] : (props.form?.hedef_evrak_tip === '-1' || _isNil(props.form?.hedef_evrak_tip)) ? '' : props.evrakTipDic ? props.evrakTipDic[props.form.hedef_evrak_tip] : ''}>
                        <TextField
                          {...createTextFieldProps('hedef_evrak_tip')}
                          onChange={(e) => props.onChangeForm('hedef_evrak_tip', e.target.value)}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'hedef_evrak_tip', true, false, false, '', '', '')}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option disabled value="-1">Seçilmedi</option>
                          {props.evrakTip.map((et) => (
                            <option key={et.key} value={et.key}>{et.value}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                    </li>

                    <li className={classes.layoutFormItem} id="siralama">
                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                      <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                        <TextField
                          {...createTextFieldProps('siralama')}
                          onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                          type="number"
                          inputProps={{
                            min: -2147483648,
                            max: 2147483647,
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>

                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                      ></Checkbox>
                    </li>

                  </ul>

                  {props.form?.tip === '0' &&
                    <ul className={classes.layoutFormList}>
                      <li className={classes.layoutFormItemBox}>
                        <MultipleSelectList
                          headername="BANKALAR"
                          name="Bankalar"
                          name2="Banka"
                          form={props.form}
                          list={props.form?.banka_hesaplari ?? []}
                          listlength={props?.form?.banka_hesaplari?.length ?? 0}
                          opendialog={setOpenSelectorBanka}
                          keyProp="id"
                          valueProp="tanim"
                          removefonk={handleRemoveBankaById}
                          loading={props.loading}
                          error={props.error?.banka_hesaplari ? props.error.banka_hesaplari[0] : null}
                          newPath="tanimlar/bankahesap"
                          ustId={false}
                        />
                      </li>

                    </ul>

                  }

                  {props.form?.tip === '3' &&
                    <ul className={classes.layoutFormList}>
                      <li className={classes.layoutFormItemBox}>
                        <MultipleSelectList
                          headername="BIN KODLAR"
                          name="Bin kodlar"
                          name2="Bin kodlar"
                          form={props.form}
                          list={props.form?.binkodlar ?? []}
                          listlength={props?.form?.binkodlar?.length ?? 0}
                          opendialog={() => {
                            if (props.form.odeme_servis !== "-1") {
                              setOpenSelectorBinCode(true)
                            }
                          }}
                          keyProp="id"
                          valueProp="bincode"
                          removefonk={handleRemoveBinCodeById}
                          loading={props.loading}
                          error={props.error?.binkodlar ? props.error.binkodlar[0] : null}
                          newPath={null}
                          ustId={false}
                        />
                      </li>
                    </ul>
                  }
                </div>

                <div className={classes.layoutContainerTab}>
                  <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                  <>
                    {HATADIL && (
                      <Hata />
                    )}
                    {!HATADIL && (
                      <LayoutDetailVerticalTab
                        tabs={tabs}
                        selectedTabIndex={selectedTabIndex}
                        onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                        buttonShow={false}
                        delete={handleDetailDil}
                        deleteButtonShow={true}
                      >
                        {COUNT_TABS === 0 && (
                          <div className="flex flex-col items-start justify-start p-4 space-y-4">
                            <Typography variant="body1">
                              Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.
                            </Typography>
                          </div>
                        )}
                        {COUNT_TABS > 0 && (
                          <div className={classes.tabDiv}>
                            <div className={classes.column}>
                              <ul className={classes.layoutFormList}>

                                <li className={classes.layoutFormItem} id="tanim">
                                  <label className={classes.layoutFormItemLabel} htmlFor="tanim">Ödeme Tanım</label>
                                  <Tooltip title={ERROR?.tanim ? ERROR.tanim[0] : _isNil(FORM?.tanim) ? '' : FORM.tanim}>
                                    <TextField
                                      {...createTextFieldProps2('tanim')}
                                      onChange={(e) => handleChangeForm('tanim', e.target.value)}
                                      onBlur={() => handleInputErrors2(FORM, 'tanim', handleSetErrors)}
                                    ></TextField>
                                  </Tooltip>
                                </li>

                                <li className={classes.layoutFormItem} id="kisa_tanim">
                                  <label className={classes.layoutFormItemLabel} htmlFor="kisa_tanim">Kısa Açıklama</label>
                                  <Tooltip title={ERROR?.kisa_tanim ? ERROR.kisa_tanim[0] : _isNil(FORM?.kisa_tanim) ? '' : FORM.kisa_tanim}>
                                    <TextField
                                      {...createTextFieldProps2('kisa_tanim')}
                                      onChange={(e) => handleChangeForm('kisa_tanim', e.target.value)}
                                    ></TextField>
                                  </Tooltip>
                                </li>

                              </ul>
                            </div>
                          </div>
                        )
                        }
                      </LayoutDetailVerticalTab>
                    )}
                  </>
                </div>
              </div>
              {openSelectorBanka &&
                <Dialog
                  open={openSelectorBanka}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'banka_hesaplari', true, false, false, '', '', '')
                    setOpenSelectorBanka(false)
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Bankalar'
                      listPath='bankahesap?aktif=true'
                      listCountPath='bankahesap/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.banka_hesaplari}
                      onChangeItems={(items) => props.onChangeForm('banka_hesaplari', items)}
                      onClose={() => {
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'banka_hesaplari', true, false, false, '', '', '')
                        setOpenSelectorBanka(false)
                      }}
                      newPath="tanimlar/bankahesap"
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorBinCode &&
                <Dialog
                  open={openSelectorBinCode}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => { setOpenSelectorBinCode(false) }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Bin Kodlar'
                      listPath={`odemeservis/${props.form.odeme_servis}/binkodlar`}
                      listCountPath={`odemeservis/${props.form.odeme_servis}/binkodlar/count`}
                      keyProp='id'
                      valueProp='bincode'
                      defaultItems={props.form.binkodlar}
                      onChangeItems={(items) => props.onChangeForm('binkodlar', items)}
                      onClose={() => { setOpenSelectorBinCode(false) }}
                      newPath={null}
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default OdemeTanimDetailTabGenel;
