import React, { useState, useMemo, useLayoutEffect } from 'react';

import {
  Button,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Radio,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
  Delete as IconDelete
} from '@material-ui/icons';

import {
  isEqual as _isEqual,
} from 'lodash';


const CalCulator = ({ fiyat, iskontolar, onClose, onChange, pb }) => {

  const [inputValue, setInputValue] = useState(0);
  const [selectedType, setSelectedType] = useState("yuzde");

  const [sonfiyat, setFiyat] = useState(null);
  const [indirimTutar, setIndirimTutar] = useState(null);
  const [iskonto, setIskonto] = useState([]);
  const [iskontoDefault] = useState(iskontolar);

  const [iskontoListe, setIskontoListe] = useState([]);

  const FIYATDEFAULT = useMemo(() => fiyat, [fiyat]);

  const IS_EQUAL = useMemo(() => {
    return _isEqual(iskonto, iskontoDefault);
  }, [iskonto, iskontoDefault]);


  useLayoutEffect(() => { setIskonto(iskontolar) }, [iskontolar]);

  useLayoutEffect(() => {
    const calc = () => {
      let copyFiyat = FIYATDEFAULT * 1;
      let copyIskonto = [...iskonto];
      let IskontoListe = [];
      if (copyIskonto.length > 0) {
        copyIskonto.forEach(isk => {
          if (isk.iskonto_tutar !== '0.0000') {
            copyFiyat = (copyFiyat - (isk.iskonto_tutar * 1));
            IskontoListe.push({ indirim_turu: "Tutar İndirimi", indirim_miktar: `${isk.iskonto_tutar} ${pb}`, son_fiyat: copyFiyat });
          } else {
            copyFiyat = (copyFiyat * ((100 - (isk.iskonto_oran * 1)) / 100));
            IskontoListe.push({ indirim_turu: "Yüzde İndirimi", indirim_miktar: isk.iskonto_oran + ' %', son_fiyat: copyFiyat });
          }
        });
      }
      setFiyat(copyFiyat.toFixed(4));
      setIndirimTutar(((FIYATDEFAULT * 1) - copyFiyat).toFixed(4));
      setIskontoListe(IskontoListe);
    }
    calc();
  }, [iskonto, FIYATDEFAULT, pb]);

  const setIndirim = () => {
    let copyIskonto = [...iskonto];
    if (inputValue === 0) {
      return;
    } else {
      if (selectedType === "tutar") {
        copyIskonto.push({ iskonto_tutar: Number(inputValue).toFixed(4), iskonto_oran: "0.00", iskonto_tip: 0 });
      } else {
        copyIskonto.push({ iskonto_tutar: "0.0000", iskonto_oran: Number(inputValue).toFixed(2), iskonto_tip: 0 });
      }
    }
    setIskonto(copyIskonto);
  }

  const removeIndirimByIndex = (index) => {
    let copyIskonto = [...iskonto];
    if (copyIskonto.length > 0) {
      copyIskonto.splice(index, 1);
    }
    setIskonto(copyIskonto);
  }

  return (
    <>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className="flex items-center justify-between bg-primary text-palette-background-paper px-4"
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">İndirim Hesapla</h3>
            <IconButton
              edge="end"
              color="inherit"
              size="small"
              onClick={onClose}
            ><IconClose /></IconButton>
          </Toolbar>
          <nav className="w-full flex-1 flex justify-between items-center bg-palette-background-default py-2 px-4 space-x-4">
            <div className="w-full flex-1 flex justify-start items-center">
              <div className="flex flex-row items-center justify-center">
                <Radio
                  checked={selectedType === "yuzde"}
                  value="yuzde"
                  onChange={(event) => setSelectedType(event.target.value)}
                  color="default"
                  size="small"
                />
                <label>Yüzde</label>
              </div>
              <div className="flex flex-row items-center justify-center">
                <Radio
                  checked={selectedType === "tutar"}
                  value="tutar"
                  onChange={(event) => setSelectedType(event.target.value)}
                  color="default"
                  size="small"
                />
                <label>Tutar</label>
              </div>
              <div className="mx-2">
                <TextField
                  variant="outlined"
                  size="small"
                  value={inputValue}
                  type="number"
                  onChange={(e) => setInputValue(e.target.value)}
                ></TextField>
              </div>
            </div>

            <Button
              className="h-7"
              variant="text"
              color="default"
              size="small"
              onClick={setIndirim}
            >
              İNDİRİM EKLE
            </Button>
          </nav>
        </AppBar>
        <main className="relative flex flex-grow flex-col overflow-y-auto">
          <div className="h-full flex-grow">
            {iskontoListe.length > 0 &&
              <TableContainer>
                <Table >
                  <TableHead >
                    <TableRow>
                      <TableCell>İndirim Türü</TableCell>
                      <TableCell align="center">İndirim Miktarı</TableCell>
                      <TableCell align="right">Hesaplanan Fiyat</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {iskontoListe.map((item, index) => (
                      <TableRow key={index} >
                        <TableCell>{item.indirim_turu}</TableCell>
                        <TableCell align="center">{item.indirim_miktar}</TableCell>
                        <TableCell align="right">{(item.son_fiyat).toFixed(4).replace('.', ',') + ' ' + pb}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            edge="end"
                            color="inherit"
                            size="small"
                            onClick={() => removeIndirimByIndex(index)}
                          ><IconDelete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            }
            {iskonto.length === 0 &&
              <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
                <div className="w-full h-16 flex items-center justify-center">
                  <IconErrorOutline
                    color="primary"
                    style={{
                      fontSize: 48,
                    }}
                  />
                </div>
                <p className="font-medium mb-2">Eklenmiş bir indirim bulunmamaktadır.</p>
              </div>
            }
          </div>
        </main>
        <div className="flex w-full h-16">
          <div className="w-full flex-1 flex justify-between items-center bg-palette-background-default py-2 px-4 space-x-4 border-t border-palette-action-selected">
            <div className="w-full flex-1 flex justify-between items-center">
              <div>
                <label>İlk Fiyat: {Number(FIYATDEFAULT).toFixed(4) + ' ' + pb}</label>
              </div>
              <div>
                <label>Son Fiyat: {sonfiyat + ' ' + pb}</label>
              </div>
            </div>
            <Button
              className="h-7"
              variant="contained"
              color="primary"
              size="small"
              disabled={IS_EQUAL}
              onClick={() => {
                onChange({ fiyat: sonfiyat, iskonto: iskonto, indirimtutar: indirimTutar });
                onClose();
              }}
            >
              ONAYLA
            </Button>
          </div>
        </div>
      </Paper>
    </>
  )
}

export default CalCulator