import React, { useLayoutEffect, useState, useMemo } from 'react';

import { useSnackbar } from 'notistack';
import immer from 'immer';

import django from '../../../api/django';

import LayoutDetailWithoutTabs from '../../../pages/base/LayoutDetailWithoutTabs';
import Inside from './DetailParaBirimInside';

const path = "enttanim-parabirimtemp";
const PER_PAGE = 100;

const PageParaBirimDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const [hataListe, setHataListe] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const [genelFormListe, setGenelFormListe] = useState([]);
  const [genelFormListeCount, setGenelFormListeCount] = useState(0);

  const PAGE = useMemo(() => genelFormListe.length > 0 ? Math.ceil(genelFormListe.length / PER_PAGE) : 1, [genelFormListe.length]);
  const PAGES_COUNT = useMemo(() => Math.ceil(genelFormListeCount / PER_PAGE), [genelFormListeCount]);

  const { enqueueSnackbar } = useSnackbar();

  useLayoutEffect(() => {
    django(`${path}/count`).then(({ data }) => {
      setGenelFormListeCount(data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataListe(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setLoading(true);
    const debounce = setTimeout(() => {
      django(path, { params: { size: PER_PAGE } }).then(({ data }) => {
        const forms = data.map((parabirim) => (
          {
            id: parabirim.id,
            tanim: parabirim.tanim,
            para_birim: parabirim.para_birim,
          }
        ));
        setGenelFormListe(forms);
        setHataListe(null);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            setHataListe(true);
          }
        }
      }).finally(() => {
        setLoading(false);
      });
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoading(false);
    };
  }, [lastUpdateTime]);

  const handleGetMoreList = (page, mainContainerRef, query) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setLoadingMore(true);
    const url = path;
    const config = {
      params: {
        size: PER_PAGE,
        page
      },
    };
    for (let index = 0; index < query.length; index++) {
      const element = query[index];
      if (element.value !== "") {
        config.params[element.key] = `*${element.value}*`
      }
    }
    django(url, config).then(({ data }) => {
      const datas = data.map((parabirim) => ({ id: parabirim.id, tanim: parabirim.tanim, para_birim: parabirim.para_birim }));
      setGenelFormListe((prev) => [...prev, ...datas]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setLoadingMore(false);
    });
  };

  const searchListSet = (query) => {
    const config = {
      params: {
        size: PER_PAGE,
      },
    };
    const configCount = {
      params: {}
    }
    for (let index = 0; index < query.length; index++) {
      const element = query[index];
      if (element.value !== "") {
        config.params[element.key] = `*${element.value}*`;
        configCount.params[element.key] = `*${element.value}*`;
      }
    }
    const handleGet = ({ url, config }) => {
      setLoading(true);
      django(url, config).then(({ data }) => {
        const forms = data.map((parabirim) => (
          {
            id: parabirim.id,
            tanim: parabirim.tanim,
            para_birim: parabirim.para_birim,
          }
        ));
        setGenelFormListe(forms);
        setHataListe(null);
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            setHataListe(true);
          }
        }
      }).finally(() => {
        setLoading(false);
      });
    };
    const handleGetCount = ({ urlCount, configCount }) => {
      django(urlCount, configCount).then(({ data }) => {
        setGenelFormListeCount(data);
        setHataListe(null);
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            setHataListe(true);
          }
        }
      })
    };
    handleGetCount({ url: `${path}/count`, config });
    handleGet({ url: path, config });
  }

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  const handleChangeListe = (index, value) => {
    setGenelFormListe((prev) => {
      return immer(prev, (next) => {
        next[index].para_birim = value;
      });
    });
  }

  const handleSave = (item) => {
    const data = { ...item, para_birim: item.para_birim ? Number(item.para_birim.id) : null };
    const requestoptions = {
      config: {
        method: 'PUT',
        url: `${path}/${item.id}`,
        data: data
      },
      successMessage: `${item.tanim} güncelleme başarıyla tamamlandı`,
      errorMessageUnexpected: `${item.tanim} güncellenirken beklenmeyen bir hata oluştu`,
    }
    const start = async () => {
      await django(requestoptions.config).then(({ data }) => {
        enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
          } else {
            enqueueSnackbar(error.response.data, { variant: 'error' });
          }
        } else {
          enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
        }
      });
    }
    start();
  }

  return (
    <>
      <LayoutDetailWithoutTabs
        loading={loading}
        title="Para Birim Eşleştirme"
        single={true}

        canClose={false}
        canOpenNew={false}
        canAdd={false}
        canReload={true}
        canSave={false}
        canCancel={false}
        canDelete={false}
        canPrevNext={false}
        disableReloadButton={loading}
        onClickReloadButton={handleReload}
      >
        <Inside
          loading={loading}
          loadingMore={loadingMore}
          hata={hataListe}
          liste={genelFormListe}
          listeCount={genelFormListeCount}
          page={PAGE}
          pagesCount={PAGES_COUNT}
          getMore={handleGetMoreList}
          rowCount={PER_PAGE}
          searchListSet={searchListSet}
          onChange={handleChangeListe}
          onSave={handleSave}
        />
      </LayoutDetailWithoutTabs>
    </>
  );
};

export default PageParaBirimDetail;