import React, { useState, useLayoutEffect } from 'react';

import {
  Button,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  Divider,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
  Delete as IconDelete,
  Search as IconSearch
} from '@material-ui/icons';

import {
  isEqual as _isEqual
} from 'lodash';

import django from '../api/django';
import { makeStyles } from '@material-ui/styles';
import immer from 'immer';

import ListSelectorTwoStep from './ListSelectorTwoStep';
import ListSelector from './ListSelector';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },
    layoutFormItem: {
      padding: (5, 0, 5, 0)
    },
    layoutFormItemInside: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
    },
    layoutFormInput: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
  };
});

const MenuLinkGenerator = ({ defaultLinkler, confirm, onClose }) => {
  const [linkler, setLinkler] = useState([]);
  const [linklerDefault, setLinklerDefault] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [selectedType, setSelectedType] = useState("0");
  const [tip, setTip] = useState([]);
  const [tipDic, setTipDic] = useState(null);

  const [openSelectorGrup, setOpenSelectorGrup] = useState(false);
  const [openSelectorOzellik, setOpenSelectorOzellik] = useState(false);
  const [detayListOpen, setDetayListOpen] = useState(true);

  const classes = useStyles();

  useLayoutEffect(() => {
    django('menu/menu_icerik_ozel_link_tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setTipDic(dict);
      setTip(data);
    });
  }, []);

  useLayoutEffect(() => {
    const items = [];
    defaultLinkler.forEach(element => {
      let item = { model: element.model };
      const icerik_idler = element.model === "0" ? [...element.icerik.icerik_idler] : null;
      const icerik_deger = element.model === "1" ? { baslik: element.icerik.icerik_deger.baslik, degerler: [...element.icerik.icerik_deger.degerler].map((x) => ({ baslik_id: element.icerik.icerik_deger.baslik.id, baslik_tanim: element.icerik.icerik_deger.baslik.tanim, id: x.id, tanim: x.tanim })), tree_list: [{ title: element.icerik.icerik_deger.baslik.tanim, list: element.icerik.icerik_deger.degerler.map((x) => ({ key: x.id, value: x.tanim })) }] } : null
      item = { ...item, icerik: { icerik_idler: icerik_idler, icerik_deger: icerik_deger } }
      items.push(item);
    });
    setLinkler(items);
    setLinklerDefault(items);
  }, [defaultLinkler])

  const handleAddLinkItem = () => {
    const link = {
      model: selectedType,
      icerik: {
        icerik_deger: selectedType === "1" ? { baslik: null, degerler: [], tree_list: [] } : null,
        icerik_idler: selectedType === "0" ? [] : null
      }
    }
    setLinkler((prev) => immer(prev, (next) => {
      next.push(link);
    }));
  }

  const handleRemoveLinkItem = (index) => {
    setOpenSelectorGrup(false);
    setOpenSelectorOzellik(false);
    setLinkler((prev) => immer(prev, (next) => {
      next.splice(index, 1);
    }));
    setSelectedIndex(null);
  }

  const handleChangeOzellikler = (i, value) => {
    if (value.length > 0) {
      setLinkler((prev) => immer(prev, (next) => {
        next[i].icerik.icerik_deger.baslik = { id: value[0].baslik_id, tanim: value[0].baslik_tanim };
        next[i].icerik.icerik_deger.degerler = value;
        next[i].icerik.icerik_deger.tree_list = [{ title: value[0].baslik_tanim, list: value.map((x) => ({ key: x.id, value: x.tanim })) }];
      }));
    } else {
      handleRemoveLinkItem(i);
    }
  }

  const handleChangeGruplar = (i, value) => {
    if (value.length > 0) {
      setLinkler((prev) => immer(prev, (next) => {
        next[i].icerik.icerik_idler = value;
      }));
    } else {
      handleRemoveLinkItem(i);
    }
  }

  const handleConfirm = () => {
    const items = [];
    linkler.forEach(element => {
      let item = { model: element.model };
      const icerik_idler = element.model === "0" ? [...element.icerik.icerik_idler] : null;
      const icerik_deger = element.model === "1" ? { baslik: element.icerik.icerik_deger.baslik, degerler: [...element.icerik.icerik_deger.degerler].map((x) => ({ id: x.id, tanim: x.tanim })) } : null
      item = { ...item, icerik: { icerik_idler: icerik_idler, icerik_deger: icerik_deger } }
      if (element.model === "0") {
        if (element.icerik.icerik_idler.length > 0) {
          items.push(item);
        }
      } else {
        if (element.icerik.icerik_deger.degerler.length > 0) {
          items.push(item);
        }
      }
    });
    confirm(items);
    onClose();
  }

  return (
    <>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">Link Oluştur</h3>
            <IconButton
              edge="end"
              color="inherit"
              size="small"
              onClick={onClose}
            ><IconClose /></IconButton>
          </Toolbar>
          <nav className="w-full flex-1 flex justify-between items-center bg-palette-background-default py-2 px-4 space-x-4">
            <div className="w-full h-8 leading-none bg-palette-background-paper apperance-none">
              <Tooltip title={tipDic ? tipDic[selectedType] : ''}>
                <TextField
                  name='tip'
                  variant='outlined'
                  size='small'
                  fullWidth={true}
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                  select
                  SelectProps={{ native: true }}
                >
                  {tip?.map((tip) => (
                    <option key={tip.key} value={tip.key}>{tip.value}</option>
                  ))}
                </TextField>
              </Tooltip>
            </div>
            <div>
              <Button
                className="h-7"
                variant="text"
                color="default"
                size="small"
                onClick={handleAddLinkItem}
              >
                EKLE
              </Button>
            </div>
          </nav>

        </AppBar>

        <main className="relative flex flex-grow flex-col overflow-hidden">
          <div className="h-full flex-grow overflow-y-auto">
            {linkler.length > 0 &&
              <div className={classes.layoutFormList}>
                {linkler.map((item, index) => (
                  <div className={classes.layoutFormItem} key={`linkitem${index}`} onClick={() => setSelectedIndex(index)}>
                    {index !== 0 && <Divider />}
                    <div className={classes.layoutFormItemInside}>
                      {item.model === "1" && (
                        <div className={classes.layoutFormInput}>
                          <label className={classes.layoutFormItemLabel} htmlFor="icerik_deger">Özellikler</label>
                          <Tooltip title={item?.icerik?.icerik_deger.tree_list.length > 0 ? `${item.icerik.icerik_deger.tree_list[0].title}: ${item.icerik.icerik_deger.tree_list[0].list.map(x => x.value).join(', ')}` : ''}>
                            <TextField
                              name='icerik_idler'
                              variant='outlined'
                              size='small'
                              fullWidth={true}
                              value={item?.icerik?.icerik_deger.tree_list.length > 0 ? `${item.icerik.icerik_deger.tree_list[0].title}: ${item.icerik.icerik_deger.tree_list[0].list.map(x => x.value).join(', ')}` : ''}
                              InputProps={{
                                endAdornment:
                                  <IconButton
                                    size="small"
                                    className={classes.button}
                                    disableRipple
                                    onClick={() => setOpenSelectorOzellik(true)}
                                  ><IconSearch /></IconButton>
                              }}
                            ></TextField>
                          </Tooltip>
                        </div>
                      )}
                      {item.model === "0" && (
                        <div className={classes.layoutFormInput}>
                          <label className={classes.layoutFormItemLabel} htmlFor="icerik_idler">Gruplar</label>
                          <Tooltip title={item?.icerik?.icerik_idler.length > 0 ? item.icerik.icerik_idler.map((x) => x.tanim).join(", ") : ''}>
                            <TextField
                              name='icerik_idler'
                              variant='outlined'
                              size='small'
                              fullWidth={true}
                              value={item?.icerik?.icerik_idler.length > 0 ? item.icerik.icerik_idler.map(x => x.tanim).join(", ") : ''}
                              InputProps={{
                                endAdornment:
                                  <IconButton
                                    size="small"
                                    className={classes.button}
                                    disableRipple
                                    onClick={() => setOpenSelectorGrup(true)}
                                  ><IconSearch /></IconButton>
                              }}
                            ></TextField>
                          </Tooltip>
                        </div>
                      )}
                      <span>
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveLinkItem(index)}
                        ><IconDelete />
                        </IconButton>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            }
            {linkler.length === 0 &&
              <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
                <div className="w-full h-16 flex items-center justify-center">
                  <IconErrorOutline
                    color="primary"
                    style={{
                      fontSize: 48,
                    }}
                  />
                </div>
                <p className="font-medium mb-2">Seçenek bulunamadı</p>
              </div>
            }
          </div>
          <div className="flex items-center justify-end border-t border-palette-action-selected p-2">
            <span>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disableRipple
                disabled={_isEqual(linkler, linklerDefault)}
                onClick={handleConfirm}
              >ONAYLA</Button>
            </span>
          </div>
        </main>
      </Paper>
      {openSelectorGrup &&
        <Dialog
          open={openSelectorGrup}
          PaperProps={{
            style: {
              width: '480px',
              height: '600px',
            },
          }}
          onClose={() => setOpenSelectorGrup(false)}
        >
          <DialogContent style={{ padding: 0 }}>
            <ListSelector
              title='Ürün Grupları'
              listPath='grup/mini_list?aktif=true'
              listCountPath='grup/count?aktif=true'
              keyProp='id'
              valueProp='tanim'
              defaultItems={linkler[selectedIndex].icerik.icerik_idler}
              onChangeItems={(items) => handleChangeGruplar(selectedIndex, items)}
              onClose={() => setOpenSelectorGrup(false)}
              newPath='tanimlar/gruplar'
            />
          </DialogContent>
        </Dialog>
      }
      {openSelectorOzellik &&
        <Dialog
          open={openSelectorOzellik}
          PaperProps={{
            style: {
              background: 'transparent',
              height: 600,
              width: detayListOpen ? '100%' : 480
            },
          }}
          fullWidth
          maxWidth={detayListOpen ? 'md' : 'sm'}
          onClose={() => setOpenSelectorOzellik(false)}
        >
          <DialogContent style={{ padding: 0 }}>
            <ListSelectorTwoStep
              title="Özellikler"
              mainItemsPath='ozellikbaslik'
              mainItemsCountPath='ozellikbaslik/count'
              mainDefaultItems={linkler[selectedIndex].icerik.icerik_deger.baslik}
              mainMultiple={false}
              mainKeyProp='id'
              mainValueProp='tanim'
              listPath='ozellikdeger'
              listCountPath='ozellikdeger/count'
              listMultiple={true}
              keyProp='id'
              valueProp='tanim'
              keyUId='baslik_id'
              valueUV='baslik_tanim'
              defaultItems={linkler[selectedIndex].icerik.icerik_deger.degerler}
              onChangeItems={(items) => handleChangeOzellikler(selectedIndex, items)}
              onClose={() => {
                setOpenSelectorOzellik(false);
              }}
              onDetayListClose={(res) => setDetayListOpen(res)}
              detayListOpen={detayListOpen}
              detayList={linkler[selectedIndex].icerik.icerik_deger.tree_list}
              url="tanimlar/ozellikdetay"
            />
          </DialogContent>
        </Dialog>
      }
    </>
  )
}

export default MenuLinkGenerator