import React, {useState} from 'react';
import AuthBase from "./base/AuthBase";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {ArrowBack} from "@material-ui/icons";

const Login = props => {
  const [email, setEmail] = useState("");

  function handleSubmit(event) {
    event.preventDefault();

    // TODO: api.auth.passwordReset()
    console.log('api.auth.passwordReset()')
  }

  return (
    <AuthBase>

      <h3>
        <Link to="" onClick={props.history.goBack}><ArrowBack /></Link>
      </h3>

      <Typography component="h1" variant="h5">
        Sign in
      </Typography>

      <form noValidate onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={e => setEmail(e.target.value)}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Reset Password
        </Button>
      </form>
    </AuthBase>
  );
}

export default Login;
