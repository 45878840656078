import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';
import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './SatisBirimGrupDetailTab_Genel';

const PATH = 'satisbirimgrup';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
];


const FORM_DEFAULT = {
  tanim: '',
  birim: '-1',
  aktif: true,
  siralama: '0',
};

const CARPAN_DEFAULT = {
  form: {
    katsayi: '1',
    birim: '-1',
    aktif: true,
    varsayilan: false,
    siralama: '0'
  },
  errors: null
}


const SatisBirimGrupDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingCarpan, setLoadingCarpan] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataCarpan, setHataCarpan] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(FORM_DEFAULT);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [carpanSelectedTabIndex, setCarpanSelectedTabIndex] = useState(0);
  const [carpanFormsDefault, setCarpanFormsDefault] = useState([]);
  const [carpanForms, setCarpanForms] = useState([]);
  const [carpanAdd, setCarpanAdd] = useState(true);

  const [birimler, setBirim] = useState([]);
  const [birimDic, setBirimDic] = useState({});

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteId, setDeleteIdDetail] = useState(null);
  const [showDeleteDialogDetail, setShowDeleteDialogDetail] = useState(false);
  const [isDeletingDetail, setIsDeletingDetail] = useState(false);
  const [isDeletingErrorMessageDetail, setIsDeletingErrorMessageDetail] = useState(null);

  const [saveErrors, setSaveErrors] = useState([[], []]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(carpanForms, []);
    }
    return _isEqual(genelForm, genelFormDefault) && _isEqual(carpanForms, carpanFormsDefault);
  }, [add, genelForm, genelFormDefault, carpanForms, carpanFormsDefault]);

  const LOADING = useMemo(() => {
    if (!loading && !loadingCarpan) {
      return false;
    }
    return true;
  }, [loading, loadingCarpan]);

  useLayoutEffect(() => {
    if (props.eventType === 0) {
      setSaveErrors([[], []]);
      setCarpanSelectedTabIndex(0);
      setTabs(TABS_DEFAULT);
    }
  }, [props.eventType, ID]);

  useLayoutEffect(() => {
    if (!saveErrors[0].includes("err")) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              aktif: data.aktif,
              siralama: String(data.siralama),
              birim: String(data.birim.id),
            };
            setGenelFormDefault(form);
            setGenelForm(form);
            setHataGenel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setAdd(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DEFAULT);
        setGenelFormDefault(FORM_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setLoading(false);
      }
    }
  }, [ID, lastUpdateTime, IS_ADD, saveErrors]);

  useLayoutEffect(() => {
    django('birim/mini_list').then(({ data }) => {
      let dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      dict = Object.assign(dict, { [-1]: 'Seçilmedi' })
      setBirimDic(dict);
      setBirim(data);
      setHataGenel(null);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataGenel(true);
        }
      }
    })
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!saveErrors[1].includes("err")) {
      setLoadingCarpan(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/satisbirimgrupcarpan`).then(({ data }) => {
            const forms = data.map((carpan) => (
              {
                form: {
                  id: carpan.id,
                  katsayi: carpan.katsayi,
                  aktif: carpan.aktif,
                  varsayilan: carpan.varsayilan,
                  siralama: String(carpan.siralama),
                  birim: String(carpan.birim.id)
                },
                errors: null
              }
            ));
            setCarpanFormsDefault(forms);
            setCarpanForms(forms);
            setHataCarpan(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataCarpan(true)
              }
            }
          }).finally(() => {
            setLoadingCarpan(false);
          })
        }, 300);
        return () => {
          clearTimeout(debounce);
        }
      } else {
        setCarpanFormsDefault([]);
        setCarpanForms([]);
        setHataCarpan(null);
        setLoadingCarpan(false);
      }
    }
  }, [ID, lastUpdateTime, IS_ADD, saveErrors]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
    if (property === "birim") {
      carpanForms.forEach((_, index) => {
        handleDetailChangeForm(index, "birim", "-1")
      })
    }
  };

  const handleDetailChangeForm = (index, property, value) => {
    setCarpanForms((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      });
    });
  };

  const handleCarpanAddNewTab = () => {
    setCarpanAdd(false);
    setCarpanForms((prev) => immer(prev, (next) => {
      next.push({ ...CARPAN_DEFAULT });
    }));
  }

  const handleAdd = () => {
    setAdd(true);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setCarpanForms([]);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setSaveErrors([[], []]);
    setLastUpdateTime(Date.now());
    setCarpanAdd(true);
  };

  const handleCancel = () => {
    if (saveErrors[0].includes("err") || saveErrors[1].includes("err")) {
      setSaveErrors([[], []]);
    }
    setGenelForm(genelFormDefault);
    setGenelError(null);
    setCarpanAdd(true);
    setCarpanForms(carpanFormsDefault);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.satisbirimgrup.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDesiDeleteDetail = () => {
    setIsDeletingDetail(true);
    setIsDeletingErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/satisbirimgrupcarpan/${detailDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetail(false);
        setIsDeletingErrorMessageDetail(null);
      } else {
        setIsDeletingErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingDetail(false);
      setLastUpdateTime(Date.now());
    });
  }

  const handleDeleteDetailIndex = (index) => {
    setCarpanForms((prev) => {
      return immer(prev, (next) => {
        next[index] = { ...CARPAN_DEFAULT };
      })
    });
  }

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Satış Birim Grup Tanım', pagename: 'satış birim grup', prop2: '' },
      { prop: 'birim', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ]
    let errors = [];
    await checkAllList('satisbirimgrup', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {

    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (!add ? _isEqual(genelForm, genelFormDefault) : _isEqual(genelForm, FORM_DEFAULT)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            const data = { ...genelForm, siralama: genelForm.siralama ? Number(genelForm.siralama) : 0, birim: genelForm.birim === '-1' ? null : Number(genelForm.birim) }
            reqopt = {
              data: data,
              method: add ? 'POST' : 'PUT',
              id: null,
              successMessage: `Satış Birim Grup ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Satış Birim Grup ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabListe = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = carpanFormsDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'birim', prop2: '' },
          { prop: 'katsayi', prop2: '' },
        ];
        await checkAllTabs(next.form, (err) => handleErrorsDetail(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const form = { ...next.form, siralama: next.form.siralama ? Number(next.form.siralama) : 0, birim: next.form.birim === '-1' ? null : Number(next.form.birim) };
            if (isHas) {
              reqopt = {
                data: form,
                method: 'PUT',
                id: form.id,
                successMessage: `${form.katsayi} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: form,
                method: 'POST',
                id: null,
                successMessage: `${form.katsayi} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    let errors = [[], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'carpan', request: [], error: [] },
      ];

      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in carpanForms) {
        const carpanstate = [...carpanForms];
        const res = await createRequestOptionsTabListe(carpanstate[i], i)
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "satisbirimgrup" : `satisbirimgrup/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.satisbirimgrup.detail(data.id,"detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }

            if (tab.name === 'carpan') {
              setCarpanForms((prev) => {
                return immer(prev, (next) => {
                  next[requestIndex].errors = null;
                })
              });
              if (SAVEID !== null) {
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `satisbirimgrup/${SAVEID}/satisbirimgrupcarpan/${request.id}` : `satisbirimgrup/${SAVEID}/satisbirimgrupcarpan`
                }).then(() => {
                  setCarpanAdd(true);
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setCarpanAdd(false);
                  setCarpanSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setCarpanForms((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }
    await start();
    if (SAVEID !== null) {
      setSaveErrors(errors);
      if (!add) {
        if (!errors[0].includes("err") || !errors[1].includes("err")) {
          setLastUpdateTime(Date.now());
        }
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? !errors[1].includes("err") ? false : true : true : true;
      });
    });
  };

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsDetail = (errors, index) => {
    setCarpanForms((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  return (
    <>
      <LayoutDetail
        loading={LOADING}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={LOADING}
        disableAddButton={LOADING || add || IS_ADD}
        disableReloadButton={LOADING || add || IS_ADD}
        disableCancelButton={!add && (LOADING || IS_EQUAL) && !IS_ADD}
        disableSaveButton={LOADING || IS_EQUAL}
        disableDeleteButton={LOADING || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            loadingCarpan={loadingCarpan}
            hataCarpan={hataCarpan}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            birim={birimler}
            birimDic={birimDic}
            error={genelError}
            onChangeForm={handleGenelChangeForm}
            onCheckErrors={(errors) => handleErrors(errors)}

            selectedTabIndex={carpanSelectedTabIndex}
            carpanForms={carpanForms}
            onChangeFormCarpan={handleDetailChangeForm}
            onAddNewTab={handleCarpanAddNewTab}
            carpanAdd={carpanAdd}
            setDeleteId={setDeleteIdDetail}
            openDeleteForm={setShowDeleteDialogDetail}
            onCheckErrorsCarpan={(errors, index) => handleErrorsDetail(errors, index)}
            deleteDetailWithIndex={handleDeleteDetailIndex}
          />
        )}
      </LayoutDetail>


      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Satış birim grup siliniyor lütfen bekleyin'
                : 'Bu satış birim grubu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetail &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetail
              ? isDeletingErrorMessageDetail
              : isDeletingDetail
                ? 'Çarpan siliniyor lütfen bekleyin'
                : 'Bu çarpanı gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetail}
          onSubmit={handleDesiDeleteDetail}
          onCancel={() => setShowDeleteDialogDetail(false)}
        ></DialogConfirm>
      }

    </>
  );
}

export default SatisBirimGrupDetail;