import React from 'react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {flattenObject, slugify} from "../helpers/helpers";
import {useHistory} from "react-router-dom";
import {Trans} from "../locale/LanguageProvider";
import searchBarItems from '../constants/sidebar-items';
import _ from 'lodash';
import URLS from 'urls';

const useStyles = makeStyles(theme => ({
  textField: {
    width: 250,
    padding: '5px 12px',
  },
}));

const MenuSearchInput = (props) => {
  const classes = useStyles();
  const items = searchBarItems;
  const history = useHistory();

  const flatItems = _.sortBy(flattenObject(items, 'items'), ['label']);

  const onSearchChange = (e, value) => {
    if(!value) {return false}

    if(value.to) {
      return history.push(value.to);
    }

    if(value && value.label.length) {
      history.push(URLS.page.detail(slugify(value.label)));
    }

  }

  return (
    <Autocomplete
      id="combo-box-demo"
      blurOnSelect
      clearOnBlur={true}
      clearOnEscape={true}
      options={flatItems}
      getOptionLabel={(option) => option.label}
      onChange={(e, val) => onSearchChange(e, val)}
      renderInput={(params) =>
        <TextField
          {...params}
          className={classes.textField}
          placeholder={Trans("Menüde Ara")}
          variant="standard" />
      }
    />
  )
}

export default MenuSearchInput;
