import React, { useCallback, useMemo } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
};

const FotoAyarDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.layoutContainer}>
              <ul className={classes.layoutFormList}>
                <li className={classes.layoutFormItem} id="buyuk_genislik">
                  <label className={classes.layoutFormItemLabel} htmlFor="buyuk_genislik">Büyük Fotoğraf Genel Genişlik Ayarı</label>
                  <Tooltip title={props.error?.buyuk_genislik ? props.error.buyuk_genislik[0] : _isNil(props.form?.buyuk_genislik) ? '' : props.form.buyuk_genislik}>
                    <TextField
                      {...createTextFieldProps('buyuk_genislik')}
                      onChange={(e) => props.onChangeForm('buyuk_genislik', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'buyuk_genislik', true, false, false, '', '', '')}
                      type="number"
                      inputProps={{
                        min: -2147483648,
                        max: 2147483647,
                        step: 1,
                        style: { textAlign: 'right' }
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="buyuk_yukseklik">
                  <label className={classes.layoutFormItemLabel} htmlFor="buyuk_yukseklik">Büyük Fotoğraf Genel Yükseklik Ayarı</label>
                  <Tooltip title={props.error?.buyuk_yukseklik ? props.error.buyuk_yukseklik[0] : _isNil(props.form?.buyuk_yukseklik) ? '' : props.form.buyuk_yukseklik}>
                    <TextField
                      {...createTextFieldProps('buyuk_yukseklik')}
                      onChange={(e) => props.onChangeForm('buyuk_yukseklik', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'buyuk_yukseklik', true, false, false, '', '', '')}
                      type="number"
                      inputProps={{
                        min: -2147483648,
                        max: 2147483647,
                        step: 1,
                        style: { textAlign: 'right' }
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="kucuk_genislik">
                  <label className={classes.layoutFormItemLabel} htmlFor="kucuk_genislik">Küçük Fotoğraf Genel Genişlik Ayarı</label>
                  <Tooltip title={props.error?.kucuk_genislik ? props.error.kucuk_genislik[0] : _isNil(props.form?.kucuk_genislik) ? '' : props.form.kucuk_genislik}>
                    <TextField
                      {...createTextFieldProps('kucuk_genislik')}
                      onChange={(e) => props.onChangeForm('kucuk_genislik', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'kucuk_genislik', true, false, false, '', '', '')}
                      type="number"
                      inputProps={{
                        min: -2147483648,
                        max: 2147483647,
                        step: 1,
                        style: { textAlign: 'right' }
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="kucuk_yukseklik">
                  <label className={classes.layoutFormItemLabel} htmlFor="kucuk_yukseklik">Küçük Fotoğraf Genel Yükseklik Ayarı</label>
                  <Tooltip title={props.error?.kucuk_yukseklik ? props.error.kucuk_yukseklik[0] : _isNil(props.form?.kucuk_yukseklik) ? '' : props.form.kucuk_yukseklik}>
                    <TextField
                      {...createTextFieldProps('kucuk_yukseklik')}
                      onChange={(e) => props.onChangeForm('kucuk_yukseklik', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'kucuk_yukseklik', true, false, false, '', '', '')}
                      type="number"
                      inputProps={{
                        min: -2147483648,
                        max: 2147483647,
                        step: 1,
                        style: { textAlign: 'right' }
                      }}
                    ></TextField>
                  </Tooltip>
                </li>                
              </ul>
            </div>
          )}
        </>
      }
    </>
  );
});

export default FotoAyarDetailTabGenel;
