import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';


import {
  TextField,
  Checkbox,
  Tooltip,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton
} from '@material-ui/core';

import {
  HelpOutline as IconHelp,
  Add as IconAdd,
  Delete as IconDelete,
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import MultipleSelectList from '../../../components/MultipleSelectList';
import ListSelector from '../../../components/ListSelector';
import { handleInputErrors, handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';


const styles = ({ palette, spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 0, 1, 0),
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    column: {
      display: 'flex',
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 1, 1, 4),
    },
    tabDiv2: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 1, 1, 1),
    },

    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    tablehead: {
      display: 'flex',
      borderBottom: '1px solid #976797',
      marginBottom: spacing(0.5)
    },
    tablebody: {
      display: 'flex',
      flexDirection: 'column',
    },
    tablerow: {
      display: 'flex',
      padding: spacing(0.5, 1, 0.5, 1),
      alignItems: "center"
    },
    tablecell: {
      display: 'flex',
      padding: spacing(0, 0.5, 0, 0.5),
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    }
  };
};

const MenuDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {

  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);
  const [openSelectorIliskiTip, setOpenSelectorIliskiTip] = useState(false);

  const LOADING_ALAN = useMemo(() => props.loadingAlan, [props.loadingAlan]);
  const HATA_ALAN = useMemo(() => props.hataAlan, [props.hataAlan]);
  const HATA_ALAN_DIL = useMemo(() => props.hataDil, [props.hataDil]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const [tabsDil, setTabsDil] = useState([]);
  const [selectedTabIndexDil, setSelectedTabIndexDil] = useState(props.selectedTabIndexDil);

  const COUNT_TABS = useMemo(() => props?.forms?.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  const COUNT_TABS_DİL = useMemo(() => props?.dilform?.length ?? 0, [props]);
  const FORM_DIL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.form ?? null, [props, selectedTabIndex, selectedTabIndexDil]);
  const ERROR_DIL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.errors ?? null, [props, selectedTabIndex, selectedTabIndexDil]);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  const FORM_SECİM = useMemo(() => props?.secimler?.[selectedTabIndex] ?? null, [props, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.dilform?.length > 0) {
      const copyForms = [...props.dilform];
      const newTabs = copyForms[selectedTabIndex]?.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabsDil(newTabs);
    } else {
      setTabsDil([]);
    }

  }, [props.dilform, selectedTabIndex]);


  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((eleman, index) => {
        return {
          label: eleman.form.tanim,
          hasError: eleman.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);

  useLayoutEffect(() => {
    if (!props.ekAlanAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.ekAlanAdd, COUNT_TABS]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);


  const createTextFieldProps3 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM_DIL, `${name}`) || defaultValue,
      error: _has(ERROR_DIL, `${name}`),
      fullWidth: true,
    };
  }, [FORM_DIL, ERROR_DIL]);

  const handleRemoveMagazaById = (id) => {
    const copyMagazalar = [...props.form.magazalar];
    const index = copyMagazalar.findIndex((c) => c.id === id);
    if (index > -1) {
      copyMagazalar.splice(index, 1);
      props.onChangeForm('magazalar', copyMagazalar);
    }
  };

  const handleRemoveIliskiTipById = (key) => {
    const copyIliskiİTipler = [...props.form.alan_iliski_tip];
    const index = copyIliskiİTipler.findIndex((c) => c.key === key);
    if (index > -1) {
      copyIliskiİTipler.splice(index, 1);
      props.onChangeForm('alan_iliski_tip', copyIliskiİTipler);
    }
  };

  const handleChangeFormAlan = (property, value) => {
    props.onChangeFormEkAlan(selectedTabIndex, property, value);
  };

  const handleChangeFormDil = (property, value) => {
    props.onChangeDilForm(selectedTabIndex, selectedTabIndexDil, property, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleSetErrorsAlan = (errors) => {
    props.onCheckErrorsEkAlan(errors, selectedTabIndex);
  }

  const handleSetErrorsDil = (errors) => {
    props.onCheckErrorsEkAlanDil(errors, selectedTabIndex, selectedTabIndexDil);
  }

  const handleChangeFormSecim = (index, index2, value) => {
    props.onChangeSecimForm(selectedTabIndex, index, index2, value);
  };

  const handleDilDelete = (index) => {
    props.deleteDil(selectedTabIndex, index);
  }


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Ek Alan Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim')}
                          onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                          onBlur={() => handleInputErrors('ek_alan_tanim', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Ek Alan Tanım', 'ek alan')}
                          inputProps={{
                            maxLength: 255,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="siralama">
                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                      <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                        <TextField
                          {...createTextFieldProps('siralama', '')}
                          onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                          type="number"
                          inputProps={{
                            min: -2147483648,
                            max: 2147483647,
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="detay">
                      <label className={classes.layoutFormItemLabel} htmlFor="detay">Detay</label>
                      <Checkbox
                        name="detay"
                        color="primary"
                        checked={_result(props.form, 'detay', false)}
                        onChange={(e, checked) => props.onChangeForm('detay', checked)}
                      ></Checkbox>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                      ></Checkbox>
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItemBox} id="alan_iliski_tip">
                      <MultipleSelectList
                        headername="İLİŞKİ TİP *"
                        name="İlişki Tip"
                        name2="İlişki tip"
                        form={props.form}
                        list={props?.form.alan_iliski_tip ?? []}
                        listlength={props?.form?.alan_iliski_tip?.length ?? 0}
                        opendialog={setOpenSelectorIliskiTip}
                        keyProp="key"
                        valueProp="value"
                        removefonk={handleRemoveIliskiTipById}
                        loading={props.loading}
                        error={props.error?.alan_iliski_tip ? props.error.alan_iliski_tip[0] : null}
                        newPath={null}
                        ustId={false}
                      />
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItemBox} id="magazalar">
                      <MultipleSelectList
                        headername="MAĞAZALAR *"
                        name="Mağazalar"
                        name2="Mağaza"
                        form={props.form}
                        list={props?.form.magazalar ?? []}
                        listlength={props?.form?.magazalar?.length ?? 0}
                        opendialog={setOpenSelectorMagaza}
                        keyProp="id"
                        valueProp="tanim"
                        removefonk={handleRemoveMagazaById}
                        loading={props.loading}
                        error={props.error?.magazalar ? props.error.magazalar[0] : null}
                        newPath="tanimlar/magazalar"
                        ustId={false}
                      />
                    </li>
                  </ul>
                </div>


                <div className={classes.layoutContainerTab}>
                  <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">ELEMANLAR</span></div>
                  {!LOADING_ALAN && (
                    <>
                      {HATA_ALAN && (
                        <Hata />
                      )}
                      {!HATA_ALAN && (
                        <LayoutDetailVerticalTab
                          tabs={tabs}
                          selectedTabIndex={selectedTabIndex}
                          onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                          add={props.onAddNewTab}
                          buttonText={"YENİ ELEMAN EKLE"}
                          buttonShow={props.ekAlanAdd}
                          delete={handleDetailDelete}
                          flexDirection='column'
                        >
                          {COUNT_TABS === 0 && (
                            <div className="flex flex-col items-start justify-start p-4 space-y-4">
                              <Typography variant="body1">
                                Ek Alan tanımına ait eleman bulunamadı.
                              </Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                disableElevation
                                onClick={props.onAddNewTab}
                              >YENİ ELEMAN EKLE</Button>
                            </div>
                          )}
                          {COUNT_TABS > 0 &&
                            <>
                              <div className={classes.tabDiv}>
                                <div className={classes.column}>
                                  <ul className={classes.layoutFormList}>

                                    <li className={classes.layoutFormItem} id="tanim">
                                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Eleman Tanım *</label>
                                      <Tooltip title={ERROR?.tanim ? ERROR.tanim[0] : _isNil(FORM?.tanim) ? '' : FORM.tanim}>
                                        <TextField
                                          {...createTextFieldProps2('tanim', '')}
                                          onChange={(e) => handleChangeFormAlan('tanim', e.target.value)}
                                          onBlur={() => handleInputErrors2(FORM, 'tanim', handleSetErrorsAlan)}
                                          inputProps={{
                                            maxLength: 50,
                                          }}
                                        ></TextField>
                                      </Tooltip>
                                    </li>

                                    <li className={classes.layoutFormItem} id="alan_tip">
                                      <label className={classes.layoutFormItemLabel} htmlFor="alan_tip">Tip *</label>
                                      <Tooltip title={ERROR?.alan_tip ? ERROR.alan_tip[0] : _isNil(FORM?.alan_tip) ? '' : props.tipDic ? props.tipDic[FORM.alan_tip] : ''}>
                                        <TextField
                                          {...createTextFieldProps2('alan_tip', '')}
                                          onChange={(e) => handleChangeFormAlan('alan_tip', e.target.value)}
                                          onBlur={() => handleInputErrors2(FORM, 'alan_tip', handleSetErrorsAlan)}
                                          select
                                          SelectProps={{ native: true }}
                                        >
                                          {props?.tip.map((tip) => (
                                            <option key={tip.key} value={tip.key}>{tip.value}</option>
                                          ))}
                                        </TextField>
                                      </Tooltip>
                                    </li>

                                    <li className={classes.layoutFormItem} id="siralama">
                                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                                      <Tooltip title={ERROR?.siralama ? ERROR.siralama[0] : _isNil(FORM?.siralama) ? '' : FORM.siralama}>
                                        <TextField
                                          {...createTextFieldProps2('siralama', '')}
                                          onChange={(e) => handleChangeFormAlan('siralama', e.target.value)}
                                          type="number"
                                          inputProps={{
                                            min: -2147483648,
                                            max: 2147483647,
                                            step: 1,
                                            style: { textAlign: 'right' }
                                          }}
                                        ></TextField>
                                      </Tooltip>
                                    </li>

                                    <li className={classes.layoutFormItem} id="zorunlu">
                                      <label className={classes.layoutFormItemLabel} htmlFor="zorunlu">Zorunlu Alan</label>
                                      <Checkbox
                                        name="zorunlu"
                                        color="primary"
                                        checked={_result(FORM, 'zorunlu', false)}
                                        onChange={(e, checked) => handleChangeFormAlan('zorunlu', checked)}
                                      ></Checkbox>
                                    </li>

                                    <li className={classes.layoutFormItem} id="arayuzde_sor">
                                      <label className={classes.layoutFormItemLabel} htmlFor="arayuzde_sor">Sitede Sor</label>
                                      <Checkbox
                                        name="arayuzde_sor"
                                        color="primary"
                                        checked={_result(FORM, 'arayuzde_sor', false)}
                                        onChange={(e, checked) => handleChangeFormAlan('arayuzde_sor', checked)}
                                      ></Checkbox>
                                      <IconButton
                                        size="small"
                                        className={classes.button2}
                                        disableRipple
                                        onClick={() => {
                                          setHelpText('“Sitede kullanıcıya sorulacak bilgi ise seçin.”');
                                          setHelpOpen(true)
                                        }}
                                      ><IconHelp /></IconButton>
                                    </li>

                                    <li className={classes.layoutFormItem} id="aktif">
                                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                                      <Checkbox
                                        name="aktif"
                                        color="primary"
                                        checked={_result(FORM, 'aktif', false)}
                                        onChange={(e, checked) => handleChangeFormAlan('aktif', checked)}
                                      ></Checkbox>
                                    </li>

                                  </ul>
                                </div>
                                {FORM && FORM.alan_tip === "2" && (
                                  <div className={classes.column} style={{ width: "100%" }}>
                                    <div className={classes.table}>
                                      <div className={classes.tablehead}>
                                        <div className={classes.tablerow}>
                                          <div className={classes.tablecell}>
                                            <Tooltip title="Seçenek Ekle">
                                              <IconButton
                                                size="small"
                                                disableRipple
                                                onClick={() => props.onAddNewSecim(selectedTabIndex)}
                                              >
                                                <IconAdd />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                          <div className={classes.tablecell}>Seçenek Değer</div>
                                        </div>
                                      </div>
                                      <div className={classes.tablebody}>
                                        {FORM_SECİM && FORM_SECİM.map((secim, index) => (
                                          <div className={classes.tablerow} key={`secim-${index}`}>
                                            <div className={classes.tablecell}>
                                              <Tooltip title="Sil">
                                                <IconButton
                                                  size="small"
                                                  disableRipple
                                                  onClick={() => props.onRemoveSecim(selectedTabIndex, index)}
                                                >
                                                  <IconDelete />
                                                </IconButton>
                                              </Tooltip>
                                            </div>
                                            <div className={classes.tablecell}>
                                              <Tooltip title={_isNil(secim?.deger) ? "" : secim.deger}>
                                                <TextField
                                                  variant="outlined"
                                                  size="small"
                                                  fullWidth={true}
                                                  value={secim.deger}
                                                  onChange={(e) => handleChangeFormSecim(index, "", e.target.value)}
                                                >
                                                </TextField>
                                              </Tooltip>
                                            </div>
                                            {secim.diller.map((dil, index2) =>
                                              <div key={`secenek-${index}-${index2}`} className={classes.tablecell}>
                                                <Tooltip title={_isNil(dil?.form?.deger) ? "" : dil.form.deger}>
                                                  <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth={true}
                                                    value={dil.form.deger}
                                                    onChange={(e) => handleChangeFormSecim(index, index2, e.target.value)}
                                                    label={dil.dil.tanim}
                                                    InputLabelProps={{ shrink: true }}
                                                  ></TextField>
                                                </Tooltip>
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                )}

                              </div>

                              <div className={classes.layoutContainerTab}>
                                <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                                <>
                                  {HATA_ALAN_DIL && (
                                    <Hata />
                                  )}
                                  {!HATA_ALAN_DIL && (
                                    <LayoutDetailVerticalTab
                                      tabs={tabsDil}
                                      selectedTabIndex={selectedTabIndexDil}
                                      onChangeTabIndex={(index) => setSelectedTabIndexDil(index)}
                                      buttonShow={false}
                                      delete={handleDilDelete}
                                      deleteButtonShow={true}
                                    >
                                      {COUNT_TABS_DİL === 0 && (
                                        <div className="flex flex-col items-start justify-start p-4 space-y-4">
                                          <Typography variant="body1">
                                            Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                                        </div>
                                      )}
                                      {COUNT_TABS_DİL > 0 && (
                                        <div className={classes.tabDiv2}>
                                          <ul className={classes.layoutFormList}>
                                            <li className={classes.layoutFormItem} id="baslik">
                                              <label className={classes.layoutFormItemLabel} htmlFor="baslik">Başlık</label>
                                              <Tooltip title={ERROR_DIL?.baslik ? ERROR_DIL.baslik[0] : _isNil(FORM_DIL?.baslik) ? '' : FORM_DIL.baslik}>
                                                <TextField
                                                  {...createTextFieldProps3('baslik')}
                                                  onChange={(e) => handleChangeFormDil('baslik', e.target.value)}
                                                  onBlur={() => handleInputErrors2(FORM_DIL, 'baslik', handleSetErrorsDil)}
                                                ></TextField>
                                              </Tooltip>
                                            </li>
                                            <li className={classes.layoutFormItem} id="yardim">
                                              <label className={classes.layoutFormItemLabel} htmlFor="yardim">Yardım</label>
                                              <Tooltip title={ERROR_DIL?.yardim ? ERROR_DIL.yardim[0] : _isNil(FORM_DIL?.yardim) ? '' : FORM_DIL.yardim}>
                                                <TextField
                                                  {...createTextFieldProps3('yardim')}
                                                  onChange={(e) => handleChangeFormDil('yardim', e.target.value)}
                                                ></TextField>
                                              </Tooltip>
                                            </li>
                                          </ul>
                                        </div>
                                      )
                                      }
                                    </LayoutDetailVerticalTab>
                                  )}
                                </>
                              </div>
                            </>
                          }
                        </LayoutDetailVerticalTab>
                      )}
                    </>
                  )}
                </div>
              </div>
              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'magazalar', true, false, false, '', '', '')
                    setOpenSelectorMagaza(false)
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Mağazalar'
                      listPath='magaza/mini_list?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.magazalar}
                      onChangeItems={(items) => props.onChangeForm('magazalar', items)}
                      onClose={() => {
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'magazalar', true, false, false, '', '', '')
                        setOpenSelectorMagaza(false)
                      }}
                      newPath='tanimlar/magaza'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorIliskiTip &&
                <Dialog
                  open={openSelectorIliskiTip}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'alan_iliski_tip', true, false, false, '', '', '')
                    setOpenSelectorIliskiTip(false)
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='İlişki Tip'
                      listPath='ek_alan_tanim/alan_iliski_tip'
                      keyProp='key'
                      valueProp='value'
                      defaultItems={props.form.alan_iliski_tip}
                      onChangeItems={(items) => props.onChangeForm('alan_iliski_tip', items)}
                      onClose={() => {
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'alan_iliski_tip', true, false, false, '', '', '')
                        setOpenSelectorIliskiTip(false)
                      }}
                      newPath={null}
                      staticpage={true}
                    />
                  </DialogContent>
                </Dialog>
              }
              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default MenuDetailTabGenel;
