/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import {
  result as _result,
  isNil as _isNil
} from 'lodash';
import {
  TextField,
  Checkbox,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { Hata } from '../../base/Hata';
import JsonConvert from '../../../components/JsonConvert'

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox2: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 360,
      '& + &': {
        marginTop: 5,
      },
    },
  };
};

const OdemeLogDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);


  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      fullWidth: true,
    };
  }, [props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.layoutContainer}>
              <ul className={classes.layoutFormList}>
                <li className={classes.layoutFormItem} id="olusturan_kullanici">
                  <label className={classes.layoutFormItemLabel} htmlFor="olusturan_kullanici">Oluşturan Kullanıcı</label>
                  <Tooltip title={_isNil(props.form?.olusturan_kullanici) ? '' : props.form.olusturan_kullanici}>
                    <TextField
                      {...createTextFieldProps('olusturan_kullanici')}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="olusturma_zamani">
                  <label className={classes.layoutFormItemLabel} htmlFor="olusturma_zamani">Oluşturma Zamanı</label>
                  <Tooltip title={_isNil(props.form?.olusturma_zamani) ? '' : props.form.olusturma_zamani}>
                    <TextField
                      {...createTextFieldProps('olusturma_zamani')}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="tutar">
                  <label className={classes.layoutFormItemLabel} htmlFor="tutar">Tutar</label>
                  <Tooltip title={_isNil(props.form?.tutar) ? '' : props.form.tutar}>
                    <TextField
                      {...createTextFieldProps('tutar')}
                      inputProps={{
                        style: { textAlign: 'right' }
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="evrak">
                  <label className={classes.layoutFormItemLabel} htmlFor="evrak">Evrak</label>
                  <Tooltip title={_isNil(props.form?.evrak) ? '' : props.form.evrak}>
                    <TextField
                      {...createTextFieldProps('evrak')}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="odeme_tanim">
                  <label className={classes.layoutFormItemLabel} htmlFor="odeme_tanim">Ödeme Tanım</label>
                  <Tooltip title={_isNil(props.form?.odeme_tanim) ? '' : props.form.odeme_tanim}>
                    <TextField
                      {...createTextFieldProps('odeme_tanim')}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="odeme_son_durum">
                  <label className={classes.layoutFormItemLabel} htmlFor="odeme_son_durum">Ödeme Akışındaki Son Mesaj</label>
                  <Tooltip title={_isNil(props.form?.odeme_son_durum) ? '' : props.form.odeme_son_durum}>
                    <TextField
                      {...createTextFieldProps('odeme_son_durum')}
                      multiline
                      minRows={3}
                      maxRows={3}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="result">
                  <label className={classes.layoutFormItemLabel} htmlFor="result">Ödeme Gerçekleşti mi</label>
                  <Checkbox
                    name="result"
                    color="primary"
                    checked={_result(props.form, 'result', false)}
                  ></Checkbox>
                </li>
              </ul>
              {/* <ul className={classes.layoutFormList}>
                <div className={classes.layoutFormItemBox2}>
                  <JsonConvert
                    headername="3D GÜVENLİK KONTROLU"
                    name="3d güvenlik kontrolu verisi"
                    name2="3d güvenlik kontrolu verisi"
                    jsonlist={props.form.callback_3d_request}
                    loading={props.loading}
                  />
                </div>
                <div className={classes.layoutFormItemBox2}>
                  <JsonConvert
                    headername="ÖDEME İSTEĞİ"
                    name="Ödeme istek verisi"
                    name2="Ödeme istek verisi"
                    jsonlist={props.form.odeme_istek}
                    loading={props.loading}
                  />
                </div>
                <div className={classes.layoutFormItemBox2}>
                  <JsonConvert
                    headername="ÖDEME CEVABI"
                    name="Ödeme cevap verisi"
                    name2="Ödeme cevap verisi"
                    jsonlist={props.form.odeme_cevap}
                    loading={props.loading}
                  />
                </div>
              </ul> */}
            </div>
          )}
        </>
      }
    </>
  );
});

export default OdemeLogDetailTabGenel;
