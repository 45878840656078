import {Route} from "react-router-dom";
import django from "../api/django";
import URLS from "../urls";
import React from "react";


const PrivateRoute = ({component: Component, ...rest}) => {
  if(django.isAuthenticated()) {
    return <Route {...rest} render={props => {
      return <Component {...props} />
    }} />
  }else {
    window.location.href = `${URLS.user.login}?next=${window.location.pathname}`
  }

}

export default PrivateRoute;
