import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  IconButton,
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  Tooltip,
  Typography
} from '@material-ui/core';

import {
  Search as IconSearch
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import ListSelectorSingle from '../../../components/ListSelectorSingle';
import { handleInputErrors } from '../../base/InputErrors';
import MultipleSelectList from '../../../components/MultipleSelectList';
import ListSelector from '../../../components/ListSelector';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    keyDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: 10,
      flexWrap: "wrap"
    },
    keyCloud: {
      padding: 4,
      border: "1px solid #eacfea",
      position: "relative",
      borderRadius: 4,
      margin: "0 5px 5px",
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    layoutFormItemCaptionText: {
      fontStyle: 'italic',
      fontSize: "11px !important",
    },
  };
};

const MagazaDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const [openSelectorDepoCikis, setopenSelectorDepoCikis] = useState(false);
  const [openSelectorSite, setOpenSelectorSite] = useState(false);
  const [openSelectorDepo, setOpenSelectorDepo] = useState(false);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      if (name === 'magaza_cikis_depo') {
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form[name], `${name2}`) || defaultValue,
          error: _has(props.error, `${name}`),
          fullWidth: true,
        };
      } else {
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form[name], `${name2}`) || defaultValue,
          error: _has(props.error?.[name], `${name2}`),
          fullWidth: true,
        };
      }
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form, `${name}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    }
  }, [props.error, props.form]);

  const createTextFieldPropsMail = useCallback((name, name2, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form['magaza_ayar'][name], `${name2}`) || defaultValue,
      error: _has(props.error?.['magaza_ayar'], `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const handleRemoveDepoById = (id) => {
    const copyMagazaDepolar = [...props.form.depolar];
    const index = copyMagazaDepolar.findIndex((c) => c.id === id);
    if (index > -1) {
      copyMagazaDepolar.splice(index, 1);
      props.onChangeForm('depolar', copyMagazaDepolar);
    }
  };


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="tanim">
                    <label className={classes.layoutFormItemLabel} htmlFor="tanim">Mağaza Tanım *</label>
                    <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                      <TextField
                        {...createTextFieldProps('tanim', '')}
                        onChange={(e) => props.onChangeForm('tanim', '', e.target.value)}
                        onBlur={() => handleInputErrors('magaza', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Mağaza Tanım', 'mağaza')}
                        inputProps={{
                          maxLength: 250,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="magaza_cikis_depo">
                    <label className={classes.layoutFormItemLabel} htmlFor="magaza_cikis_depo">Mağaza Çıkış Depo *</label>
                    <Tooltip title={props.error?.magaza_cikis_depo ? props.error.magaza_cikis_depo[0] : _isNil(props.form?.magaza_cikis_depo) ? '' : props.form.magaza_cikis_depo.tanim}>
                      <TextField
                        {...createTextFieldProps('magaza_cikis_depo', 'tanim')}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button}
                              disableRipple
                              onClick={() => setopenSelectorDepoCikis(true)}
                            ><IconSearch /></IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="site">
                    <label className={classes.layoutFormItemLabel} htmlFor="site">Site</label>
                    <Tooltip title={props.error?.site ? props.error.site[0] : _isNil(props.form?.site) ? '' : props.form.site.name}>
                      <TextField
                        {...createTextFieldProps('site', 'name')}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button}
                              disableRipple
                              onClick={() => setOpenSelectorSite(true)}
                            ><IconSearch /></IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="para_birim">
                    <label className={classes.layoutFormItemLabel} htmlFor="para_birim">Para Birimi *</label>
                    <Tooltip title={props.error?.para_birim ? props.error.para_birim[0] : (props.form?.para_birim === "-1" || _isNil(props.form?.para_birim)) ? '' : props.paraBirimDic ? props.paraBirimDic[props.form.para_birim] : ''}>
                      <TextField
                        {...createTextFieldProps('para_birim', '')}
                        onChange={(e) => props.onChangeForm('para_birim', '', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'para_birim', true, false, false, '', '', '')}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option value={'-1'}>Seçilmedi</option>
                        {props?.paraBirim?.map((pb) => (
                          <option key={pb.id} value={pb.id}>{pb.tanim}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="ulke">
                    <label className={classes.layoutFormItemLabel} htmlFor="ulke">Ülke *</label>
                    <Tooltip title={props.error?.ulke ? props.error.ulke[0] : (props.form?.ulke === "-1" || _isNil(props.form?.ulke)) ? '' : props.ulkelerDic ? props.ulkelerDic[props.form?.ulke] : ''}>
                      <TextField
                        {...createTextFieldProps('ulke', '')}
                        onChange={(e) => props.onChangeForm('ulke', '', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'ulke', true, false, false, '', '', '')}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option value={'-1'}>Seçilmedi</option>
                        {props?.ulkeler?.map((u) => (
                          <option key={u.id} value={u.id}>{u.tanim}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="genel_vergi_tanim">
                    <label className={classes.layoutFormItemLabel} htmlFor="genel_vergi_tanim">Genel Vergi Tanım</label>
                    <Tooltip title={props.error?.magaza_ayar?.genel_vergi_tanim ? props.error.magaza_ayar.genel_vergi_tanim[0] : _isNil(props.form?.magaza_ayar?.genel_vergi_tanim) ? '' : props.form.magaza_ayar.genel_vergi_tanim}>
                      <TextField
                        {...createTextFieldProps('magaza_ayar', 'genel_vergi_tanim')}
                        onChange={(e) => props.onChangeForm('magaza_ayar', 'genel_vergi_tanim', e.target.value)}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="fiyat_virgul_sonrasi_hane">
                    <label className={classes.layoutFormItemLabel} htmlFor="fiyat_virgul_sonrasi_hane">Fiyat Virgül Sonrası Hane</label>
                    <Tooltip title={props.error?.magaza_ayar?.fiyat_virgul_sonrasi_hane ? props.error.magaza_ayar.fiyat_virgul_sonrasi_hane[0] : _isNil(props.form?.magaza_ayar?.fiyat_virgul_sonrasi_hane) ? '' : props.form.magaza_ayar.fiyat_virgul_sonrasi_hane}>
                      <TextField
                        {...createTextFieldProps('magaza_ayar', 'fiyat_virgul_sonrasi_hane')}
                        onChange={(e) => props.onChangeForm('magaza_ayar', 'fiyat_virgul_sonrasi_hane', e.target.value)}
                        type="number"
                        inputProps={{
                          min: 0,
                          step: 1,
                          style: { textAlign: 'right' }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="siralama">
                    <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                    <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                      <TextField
                        {...createTextFieldProps('siralama', '')}
                        onChange={(e) => props.onChangeForm('siralama', '', e.target.value)}
                        type="number"
                        inputProps={{
                          min: -2147483648,
                          max: 2147483647,
                          step: 1,
                          style: { textAlign: 'right' }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="vergiler_dahil">
                    <label className={classes.layoutFormItemLabel} htmlFor="vergiler_dahil">Fiyatlar Vergiler Dahil Çalışsın</label>
                    <Checkbox
                      name="vergiler_dahil"
                      color="primary"
                      checked={_result(props.form?.magaza_ayar, 'vergiler_dahil', false)}
                      onChange={(e, checked) => props.onChangeForm('magaza_ayar', 'vergiler_dahil', checked)}
                    ></Checkbox>
                  </li>
                  <li className={classes.layoutFormItem} id="aktif">
                    <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                    <Checkbox
                      name="aktif"
                      color="primary"
                      checked={_result(props.form, 'aktif', false)}
                      onChange={(e, checked) => props.onChangeForm('aktif', '', checked)}
                    ></Checkbox>
                  </li>
                </ul>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem}>
                    <Typography variant="caption" color="textPrimary" className={classes.layoutFormItemCaptionText}>“Eposta yönetiminde bu mağazaya ait ayarları yapmayı unutmayın.”</Typography>
                  </li>

                  <li className={classes.layoutFormItem} id="base_gonderen_mail">
                    <label className={classes.layoutFormItemLabel} htmlFor="base_gonderen_mail">Genel Eposta</label>
                    <Tooltip title={props.error?.magaza_ayar?.base_gonderen_mail ? props.error.magaza_ayar.base_gonderen_mail[0] : _isNil(props.form?.magaza_ayar?.base_gonderen_mail?.email) ? '' : props.form.magaza_ayar.base_gonderen_mail.email}>
                      <TextField
                        {...createTextFieldPropsMail('base_gonderen_mail', 'email')}
                        onChange={(e) => props.onChangeForm('base_gonderen_mail', 'email', e.target.value, true)}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="base_gonderen_mail">
                    <label className={classes.layoutFormItemLabel} htmlFor="base_gonderen_mail">Genel Eposta Görünecek Başlık</label>
                    <Tooltip title={props.error?.magaza_ayar?.base_gonderen_mail ? props.error.magaza_ayar.base_gonderen_mail[0] : _isNil(props.form?.magaza_ayar?.base_gonderen_mail?.baslik) ? '' : props.form.magaza_ayar.base_gonderen_mail.baslik}>
                      <TextField
                        {...createTextFieldPropsMail('base_gonderen_mail', 'baslik')}
                        onChange={(e) => props.onChangeForm('base_gonderen_mail', 'baslik', e.target.value, true)}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="uyelik_gonderen_mail">
                    <label className={classes.layoutFormItemLabel} htmlFor="uyelik_gonderen_mail">Üyelik Eposta</label>
                    <Tooltip title={props.error?.magaza_ayar?.uyelik_gonderen_mail ? props.error.magaza_ayar.uyelik_gonderen_mail[0] : _isNil(props.form?.magaza_ayar?.uyelik_gonderen_mail?.email) ? '' : props.form.magaza_ayar.uyelik_gonderen_mail.email}>
                      <TextField
                        {...createTextFieldPropsMail('uyelik_gonderen_mail', 'email')}
                        onChange={(e) => props.onChangeForm('uyelik_gonderen_mail', 'email', e.target.value, true)}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="uyelik_gonderen_mail">
                    <label className={classes.layoutFormItemLabel} htmlFor="uyelik_gonderen_mail">Üyelik Eposta Görünecek Başlık</label>
                    <Tooltip title={props.error?.magaza_ayar?.uyelik_gonderen_mail ? props.error.magaza_ayar.uyelik_gonderen_mail[0] : _isNil(props.form?.magaza_ayar?.uyelik_gonderen_mail?.baslik) ? '' : props.form.magaza_ayar.uyelik_gonderen_mail.baslik}>
                      <TextField
                        {...createTextFieldPropsMail('uyelik_gonderen_mail', 'baslik')}
                        onChange={(e) => props.onChangeForm('uyelik_gonderen_mail', 'baslik', e.target.value, true)}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="bilgilendirme_gonderen_mail">
                    <label className={classes.layoutFormItemLabel} htmlFor="bilgilendirme_gonderen_mail">Bilgilendirme Eposta</label>
                    <Tooltip title={props.error?.magaza_ayar?.bilgilendirme_gonderen_mail ? props.error.magaza_ayar.bilgilendirme_gonderen_mail[0] : _isNil(props.form?.magaza_ayar?.bilgilendirme_gonderen_mail?.email) ? '' : props.form.magaza_ayar.bilgilendirme_gonderen_mail.email}>
                      <TextField
                        {...createTextFieldPropsMail('bilgilendirme_gonderen_mail', 'email')}
                        onChange={(e) => props.onChangeForm('bilgilendirme_gonderen_mail', 'email', e.target.value, true)}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="bilgilendirme_gonderen_mail">
                    <label className={classes.layoutFormItemLabel} htmlFor="bilgilendirme_gonderen_mail">Bilgilendirme Eposta  Görünecek Başlık</label>
                    <Tooltip title={props.error?.magaza_ayar?.bilgilendirme_gonderen_mail ? props.error.magaza_ayar.bilgilendirme_gonderen_mail[0] : _isNil(props.form?.magaza_ayar?.bilgilendirme_gonderen_mail?.baslik) ? '' : props.form.magaza_ayar.bilgilendirme_gonderen_mail.baslik}>
                      <TextField
                        {...createTextFieldPropsMail('bilgilendirme_gonderen_mail', 'baslik')}
                        onChange={(e) => props.onChangeForm('bilgilendirme_gonderen_mail', 'baslik', e.target.value, true)}
                      ></TextField>
                    </Tooltip>
                  </li>
                </ul>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItemBox} id="depolar">
                    <MultipleSelectList
                      headername="DEPOLAR"
                      name="Depolar"
                      name2="Depo"
                      form={props.form}
                      list={props?.form.depolar ?? []}
                      listlength={props?.form?.depolar?.length ?? 0}
                      opendialog={setOpenSelectorDepo}
                      keyProp="id"
                      valueProp="tanim"
                      removefonk={handleRemoveDepoById}
                      loading={props.loading}
                      error={props.error?.depolar ? props.error.depolar[0] : null}
                      newPath="tanimlar/depolar"
                      ustId={false}
                    />
                  </li>
                </ul>
              </div>
              {openSelectorDepoCikis &&
                <Dialog
                  open={openSelectorDepoCikis}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setopenSelectorDepoCikis(false);
                    handleInputErrors('magaza', props.form, props.formdefault, props.onCheckErrors, add, 'magaza_cikis_depo', true, false, '', '')
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Depolar'
                      listPath='depo/mini_list?aktif=true'
                      listCountPath='depo/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.magaza_cikis_depo}
                      onChangeItems={(items) => props.onChangeForm('magaza_cikis_depo', '', items)}
                      onClose={() => {
                        setopenSelectorDepoCikis(false);
                        handleInputErrors('magaza', props.form, props.formdefault, props.onCheckErrors, add, 'magaza_cikis_depo', true, false, '', '')
                      }}
                      newPath='tanimlar/depolar'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorSite &&
                <Dialog
                  open={openSelectorSite}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorSite(false);
                    handleInputErrors('magaza', props.form, props.formdefault, props.onCheckErrors, add, 'site', true, false, '', '')
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Siteler'
                      listPath='site'
                      listCountPath='site/count'
                      keyProp='id'
                      valueProp='name'
                      disabledProp='magaza_iliskisi_var'
                      defaultItem={props.form.site}
                      onChangeItems={(items) => props.onChangeForm('site', '', items)}
                      onClose={() => {
                        setOpenSelectorSite(false);
                        handleInputErrors('magaza', props.form, props.formdefault, props.onCheckErrors, add, 'site', true, false, '', '')
                      }}
                      newPath='tanimlar/site'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorDepo &&
                <Dialog
                  open={openSelectorDepo}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorDepo(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Depolar'
                      listPath='depo/mini_list?aktif=true'
                      listCountPath='depo/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.depolar}
                      onChangeItems={(items) => props.onChangeForm('depolar', '', items)}
                      onClose={() => setOpenSelectorDepo(false)}
                      newPath="tanimlar/depolar"
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default MagazaDetailTabGenel;
