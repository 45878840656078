const Store = {}

Store.get = key => {
  try {
    return JSON.parse(localStorage.getItem(key))
  }catch (e) {
    return null
  }
}

Store.set = (key, value) => {
  if(typeof value === "object")  {
    localStorage.setItem(key, '')
    localStorage.setItem(key, JSON.stringify(value))
    return JSON.parse(localStorage.getItem(key))
  } else {
    throw new Error(`Store.set: Stored value must be a javascript object not "${typeof value}"`)
  }
}

Store.delete = key => {
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key)
    return true
  }
  return false
}

Store.clear = () => localStorage.clear()

window.Store = Store;
export default Store;

