import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';
import moment from 'moment';
import {
  TextField,
  Button,
  IconButton,
  Tooltip,
  Typography,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { NumericFormat } from 'react-number-format';
import {
  ArrowDropDown as IconArrowDropDown,
  ArrowDropUp as IconArrowDropUp,
  Close as IconClose,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import { MoneyFormatConvert } from '../../../helpers/helpers'

const styles = ({ spacing, palette }) => {
  return {
    layoutContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
      whiteSpace: 'nowrap',
      width: 'max-content',
      minWidth: "100%",
    },
    borderLayout: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      width: 'max-content',
      minWidth: "100%",
      padding: spacing(1),
      border: "1px solid",
      borderColor: palette.primary.main,
      borderRadius: 4,
      height: '100%'
    },
    borderLayout2: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      width: '100%',
      padding: spacing(1),
      border: "1px solid",
      borderColor: palette.primary.main,
      borderRadius: 4,
      height: 'fit-content'
    },
    detailOpen: {
      position: 'absolute',
      left: 0,
      background: "transparent",
      '& .MuiIconButton-root': {
        color: palette.primary.main
      }
    },
    detailClose: {
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      background: "transparent",
      '& .MuiIconButton-root': {
        color: palette.primary.main
      }
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: spacing(4),
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      position: 'relative',
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button3: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      fontSize: '0.75rem',
    },
    tableContainer: {
      maxHeight: 240,
      border: "1px solid",
      borderColor: palette.primary.main,
      borderRadius: 4,
    },
    table: {
      '&': {
        whiteSpace: 'nowrap'
      },
      '& tr th:first-child, & tr td:first-child': {
        padding: spacing(0, 0, 0, 1),
      },
      '& tr th, & tr td': {
        lineHeight: 1,
        padding: 0,
      },
      '& tbody tr:hover td': {
        backgroundColor: palette.action.hover,
      },
      '& tbody tr:last-child td': {
        borderBottomWidth: 0,
      },
    },
    tableCell: {
      minWidth: 200,
      width: 200,
      height: 40
    },
    formListTable: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: "relative"
    },
    tableHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 35,
      borderBottom: `1px solid ${palette.divider}`,
    },
    tableHeaderItem: {
      marginRight: 5,
      fontWeight: 500,
      textAlign: "right",
      width: 120,
      whiteSpace: "normal"
    },
    tableFormRow: {
      borderBottom: `1px solid ${palette.divider}`,
      height: 35,
      display: "flex",
      alignItems: 'center',
      '&:hover': {
        backgroundColor: palette.action.hover,
      },
      '&:last-child': {
        borderBottomWidth: 0,
      }
    },
    tableFormItem: {
      marginRight: 5,
      width: 120,
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    selectedRow: {
      border: '1px solid #eacfea !important',
    },
  }
};

const SatisIadeFaturaDetailTab_Tahsilat = withStyles(styles)(({ classes, ...props }) => {
  const ADD = useMemo(() => props.add, [props.add]);
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [topTOpen, setTopTOpen] = useState(true);
  const [selectedTRow, setSelectedTRow] = useState(null);
  const [selectedTRow2, setSelectedTRow2] = useState(null);

  const [topOpen, setTopOpen] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const [openDetail, setOpenDetail] = useState(false);
  const [openDetailT, setOpenDetailT] = useState(false);

  const FORM = useMemo(() => props.tahsilatForm?.[selectedTRow] ?? null, [props.tahsilatForm, selectedTRow]);
  const TAHSILAT_FORM = useMemo(() => props.tahsilatForm, [props.tahsilatForm]);
  const ODEMESIZ_TAHSILAT = useMemo(() => props.form, [props.form]);

  useLayoutEffect(() => {
    setSelectedTRow(null);
    setSelectedTRow2(null);
    setSelectedRow(null);
  }, [props.id]);

  const paralist = useMemo(() => {
    const list = [<option value="-1" key="-1" disabled>Seçilmedi</option>];
    props.paraBirimList.forEach((pb) => {
      list.push(<option value={pb.id} key={pb.id}>{pb.tanim}</option>)
    });
    return list;
  }, [props.paraBirimList]);

  const createTextFieldPropsGenel = useCallback((name, name2, defaultValue = '') => {
    switch (name) {
      case 'magaza':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form.evrak[name], `${name2}`, defaultValue) + ' ' + props.paraKoduList[props.form.evrak.magaza?.para_birim.id ?? '-1'],
          error: _has(props.error?.evrak, `${name}`),
          fullWidth: true,
        };
      case 'evrak_kullanici':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form.evrak[name], `${name2}`) || defaultValue,
          error: _has(props.error?.evrak, `${name}`),
          fullWidth: true,
        };
      case 'evrak_kullanici_adi':
        return {
          name2,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form.evrak, 'evrak_kullanici.first_name', defaultValue) + ' ' + _result(props.form.evrak, 'evrak_kullanici.last_name', defaultValue),
          error: _has(props.error?.evrak, `${name2}`),
          fullWidth: true,
        };
      case 'tip':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form.evrak[name], `${name2}`) || defaultValue,
          error: _has(props.error?.evrak, `${name}`),
          fullWidth: true,
        };
      default:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form.evrak, `${name}`) || defaultValue,
          error: _has(props.error?.evrak, `${name}`),
          fullWidth: true,
        };
    }
  }, [props.form, props.error, props.paraKoduList]);

  const createTextFieldProps2 = useCallback((index, name, u, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(u, `${name}`) || defaultValue,
      error: _has(props.error?.tahsilat_detay?.[index], `${name}`),
      fullWidth: true,
    };
  }, [props.error]);

  const handleErrors = (errors) => {
    props.onCheckErrors(errors, null);
  }

  const handleErrors2 = (errors, index = null, length = null) => {
    props.onCheckErrors(errors, selectedRow, 3)
  }
  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && !ADD && (
            <div className="flex flex-col h-full w-full">
              {TAHSILAT_FORM.length > 0 && (
                <>
                  <div className={classes.layoutContainer}>
                    <TableContainer className={classes.tableContainer}>
                      <Table className={classes.table} size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell component="th" scope="row" className={classes.tableCell}>Evrak Tarihi</TableCell>
                            <TableCell component="th" scope="row" className={classes.tableCell}>Evrak No</TableCell>
                            <TableCell component="th" scope="row" className={classes.tableCell}>Mağaza</TableCell>
                            <TableCell component="th" scope="row" className={classes.tableCell}>Cari Kod</TableCell>
                            <TableCell component="th" scope="row" className={classes.tableCell}>Genel Toplam</TableCell>
                            <TableCell component="th" scope="row" />
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                          {TAHSILAT_FORM.map((item, index) => (
                            <TableRow key={`${item.kodu}-${index}`} selected={index === selectedTRow} onClick={() => {
                              setSelectedTRow(selectedTRow !== null ? null : index);
                              setSelectedTRow2(null);
                            }}>
                              <TableCell className={classes.tableCell}>{_result(item, 'evrak_tarihi') || ''}</TableCell>
                              <TableCell className={classes.tableCell}>{_result(item, 'kodu') || ''}</TableCell>
                              <TableCell className={classes.tableCell}>{_result(item?.magaza, 'tanim') || ''}</TableCell>
                              <TableCell className={classes.tableCell}>{_result(item?.evrak_kullanici, 'kodu') || ''}</TableCell>
                              <TableCell className={classes.tableCell}>{(MoneyFormatConvert(_result(item, 'genel_toplam'), "money") || '') + ' ' + props.paraKoduList[item?.para_birimi_id ?? '-1']}</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  {FORM && (
                    <>
                      <div className={classes.layoutContainer}>
                        <div className={classes.borderLayout}>
                          {topTOpen && (
                            <>
                              <ul className={classes.layoutFormList}>
                                <li className={classes.layoutFormItem} id="magaza">
                                  <label className={classes.layoutFormItemLabel} htmlFor="magaza">Mağaza</label>
                                  <Tooltip title={_isNil(FORM.magaza) ? '' : FORM.magaza.tanim}>
                                    <TextField
                                      name='magaza'
                                      variant='outlined'
                                      size='small'
                                      value={_result(FORM['magaza'], 'tanim', '') + ' ' + props.paraKoduList[FORM?.magaza?.para_birim.id ?? '-1']}
                                      fullWidth={true}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="versiyon">
                                  <label className={classes.layoutFormItemLabel} htmlFor="versiyon">Versiyon</label>
                                  <Tooltip title={_isNil(FORM.versiyon) ? '' : FORM.versiyon}>
                                    <TextField
                                      name='versiyon'
                                      variant='outlined'
                                      size='small'
                                      value={_result(FORM, 'versiyon', '')}
                                      fullWidth={true}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="kodu">
                                  <label className={classes.layoutFormItemLabel} htmlFor="kodu">Evrak No</label>
                                  <Tooltip title={_isNil(FORM.kodu) ? '' : FORM.kodu}>
                                    <TextField
                                      name='kodu'
                                      variant='outlined'
                                      size='small'
                                      value={_result(FORM, 'kodu', '')}
                                      fullWidth={true}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="evrak_tarihi">
                                  <label className={classes.layoutFormItemLabel} htmlFor="evrak_tarihi">Evrak Tarihi</label>
                                  <Tooltip title={_isNil(FORM.evrak_tarihi) ? '' : FORM.evrak_tarihi}>
                                    <TextField
                                      name='evrak_tarihi'
                                      variant='outlined'
                                      size='small'
                                      value={_result(FORM, 'evrak_tarihi', '')}
                                      fullWidth={true}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="tip">
                                  <label className={classes.layoutFormItemLabel} htmlFor="tip">Evrak Tipi</label>
                                  <Tooltip title={_isNil(FORM.tip) ? '' : FORM.tip.value}>
                                    <TextField
                                      name='tip'
                                      variant='outlined'
                                      size='small'
                                      value={_result(FORM["tip"], 'value', '')}
                                      fullWidth={true}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="para_birimi">
                                  <label className={classes.layoutFormItemLabel} htmlFor="para_birimi">Evrak Para Birimi</label>
                                  <Tooltip title={_isNil(FORM.para_birimi) ? '' : FORM.para_birimi.tanim}>
                                    <TextField
                                      name='para_birimi'
                                      variant='outlined'
                                      size='small'
                                      value={_result(FORM["para_birimi"], 'tanim', '')}
                                      fullWidth={true}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                              </ul>

                              <ul className={classes.layoutFormList}>
                                <li className={classes.layoutFormItem} id="evrak_kullanici">
                                  <label className={classes.layoutFormItemLabel} htmlFor="evrak_kullanici">Cari Kod</label>
                                  <Tooltip title={_isNil(FORM.evrak_kullanici) ? '' : FORM.evrak_kullanici.kodu}>
                                    <TextField
                                      name='evrak_kullanici'
                                      variant='outlined'
                                      size='small'
                                      value={_result(FORM["evrak_kullanici"], 'kodu', '')}
                                      fullWidth={true}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="evrak_kullanici">
                                  <label className={classes.layoutFormItemLabel} htmlFor="evrak_kullanici">Cari Hesap Adı</label>
                                  <Tooltip title={_isNil(FORM.evrak_kullanici) ? '' : FORM.evrak_kullanici?.first_name + ' ' + FORM.evrak_kullanici?.last_name}>
                                    <TextField
                                      name='evrak_kullanici'
                                      variant='outlined'
                                      size='small'
                                      value={_result(FORM['evrak_kullanici'], 'first_name', '') + ' ' + _result(FORM['evrak_kullanici'], 'last_name', '')}
                                      fullWidth={true}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="genel_toplam">
                                  <label className={classes.layoutFormItemLabel} htmlFor="genel_toplam">Genel Toplam</label>
                                  <Tooltip title={_isNil(FORM.genel_toplam) ? '' : FORM.genel_toplam}>
                                    <TextField
                                      name='genel_toplam'
                                      variant='outlined'
                                      size='small'
                                      value={MoneyFormatConvert(_result(FORM, 'genel_toplam', ''), "money")}
                                      fullWidth={true}
                                      inputProps={{
                                        style: { textAlign: 'right' },
                                      }}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="aciklama" style={{ height: "auto" }}>
                                  <label className={classes.layoutFormItemLabel} htmlFor="aciklama">Evrak Not</label>
                                  <Tooltip title={FORM?.aciklama == null ? '' : FORM.aciklama}>
                                    <TextField
                                      name='aciklama'
                                      variant='outlined'
                                      size='small'
                                      value={_result(FORM, 'aciklama', '')}
                                      fullWidth={true}
                                      multiline
                                      rows={3}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                              </ul>
                            </>
                          )}
                          {!topTOpen && (
                            <div>
                              <span className="font-medium truncate text-base mr-10">Evrak Tarihi : {_result(FORM, 'evrak_tarihi') || ''}</span>
                              <span className="font-medium truncate text-base mr-10">Evrak No : {_result(FORM, 'kodu') || ''}</span>
                              <span className="font-medium truncate text-base mr-10">Mağaza : {_result(FORM?.magaza, 'tanim') || ''}</span>
                              <span className="font-medium truncate text-base mr-10">Cari Kod : {_result(FORM?.evrak_kullanici, 'kodu') || ''}</span>
                              <span className="font-medium truncate text-base mr-10">Genel Toplam : {(MoneyFormatConvert(_result(FORM, 'genel_toplam'), "money") || '') + ' ' + props.paraKoduList[FORM?.para_birimi_id ?? '-1']}</span>
                            </div>
                          )}
                        </div>
                        <div className={topTOpen ? classes.detailOpen : classes.detailClose}>
                          <IconButton
                            style={{ width: "1rem", height: "1rem", padding: 0 }}
                            disableRipple
                            onClick={() => setTopTOpen(!topTOpen)}
                          >
                            {topTOpen ? <IconArrowDropUp style={{ fontSize: "2rem" }} /> : <IconArrowDropDown style={{ fontSize: "2rem" }} />}
                          </IconButton>
                        </div>
                      </div>
                      <div className={classes.layoutContainer}>
                        <div className={classes.borderLayout2}>
                          <div className={classes.formListTable}>
                            <div className={classes.tableHeader}>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>TİP</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 200 }}>TUTAR</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 250 }}>TANIM</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 200 }}>BANKA HESAP</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>PARA BİRİMİ</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>KUR</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>TAKSİT SAYISI</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>C/H DÖVİZ</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>C/H DÖVİZ KUR</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 200 }}>C/H DÖVİZ KUR TUTAR</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 100 }}>TAHSİL EDİLDİ/ÖDENDİ</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 100 }}></div>
                            </div>
                            <div style={{ flex: '1 1 auto' }}>
                              {FORM.evrak_tahsilat.map((item, index) => (
                                <div key={`evrak_tahsilat-${item.id}`} className={`${classes.tableFormRow} ${selectedTRow2 === index ? classes.selectedRow : ''}`}
                                  onClick={() => setSelectedTRow2(index)}
                                >
                                  <div style={{ width: 150 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={_isNil(item.tip) ? '' : props.odemeTipDic ? props.odemeTipDic[item.tip] : ''}>
                                        <TextField
                                          name='tip'
                                          variant='outlined'
                                          size='small'
                                          value={props.odemeTipDic ? props.odemeTipDic[item.tip] : ''}
                                          fullWidth={true}
                                        ></TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 200 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={_isNil(item.tutar) ? '' : item.tutar + " " + props.paraKoduList[item.para_birimi_id ?? '-1']}>
                                        <TextField
                                          name='tutar'
                                          variant='outlined'
                                          size='small'
                                          value={MoneyFormatConvert(_result(item, 'tutar', ''), "money") + ' ' + props.paraKoduList[item.para_birimi_id ?? '-1']}
                                          fullWidth={true}
                                          inputProps={{
                                            style: { textAlign: 'right' }
                                          }}
                                        >
                                        </TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 250 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={_isNil(item.odeme_tanim) ? '' : item.odeme_tanim.tanim}>
                                        <TextField
                                          name='odeme_tanim'
                                          variant='outlined'
                                          size='small'
                                          value={_result(item["odeme_tanim"], 'tanim', '')}
                                          fullWidth={true}
                                        ></TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 200 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={_isNil(item.banka_hesap) ? '' : item.banka_hesap.tanim}>
                                        <TextField
                                          name='banka_hesap'
                                          variant='outlined'
                                          size='small'
                                          value={_result(item["banka_hesap"], 'tanim', '')}
                                          fullWidth={true}
                                        ></TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 150 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={_isNil(item.para_birimi) ? '' : item.para_birimi.tanim}>
                                        <TextField
                                          name='para_birimi'
                                          variant='outlined'
                                          size='small'
                                          value={_result(item["para_birimi"], 'tanim', '')}
                                          fullWidth={true}
                                        ></TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 150 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={_isNil(item.kur) ? '' : item.kur + " " + props.paraKoduList[item.para_birimi_id ?? '-1']}>
                                        <TextField
                                          name='kur'
                                          variant='outlined'
                                          size='small'
                                          value={MoneyFormatConvert(_result(item, 'kur', ''), "money") + ' ' + props.paraKoduList[item.para_birimi_id ?? '-1']}
                                          fullWidth={true}
                                          inputProps={{
                                            style: { textAlign: 'right' }
                                          }}
                                        ></TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 150 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={_isNil(item.odeme_vade) ? '' : item.odeme_vade}>
                                        <TextField
                                          name='odeme_vade'
                                          variant='outlined'
                                          size='small'
                                          value={_result(item["odeme_vade"], 'tanim', '')}
                                          fullWidth={true}
                                        ></TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 150 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={_isNil(item.cari_hesap_para_birim) ? '' : item.cari_hesap_para_birim.tanim}>
                                        <TextField
                                          name='cari_hesap_para_birim'
                                          variant='outlined'
                                          size='small'
                                          value={_result(item["cari_hesap_para_birim"], 'tanim', '')}
                                          fullWidth={true}
                                        ></TextField>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div style={{ width: 150 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={_isNil(item.cari_hesap_kur) ? '' : item.cari_hesap_kur + " " + props.paraKoduList[item.para_birimi_id ?? '-1']}>
                                        <TextField
                                          name='cari_hesap_kur'
                                          variant='outlined'
                                          size='small'
                                          value={MoneyFormatConvert(_result(item, 'cari_hesap_kur', ''), "money") + ' ' + props.paraKoduList[item.para_birimi_id ?? '-1']}
                                          fullWidth={true}
                                          inputProps={{
                                            style: { textAlign: 'right' }
                                          }}
                                        ></TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 200 }} className={classes.tableFormItem}>
                                    <Tooltip title={_isNil(item.net_tutar) ? '' : item.net_tutar + " " + props.paraKoduList[item.cari_hesap_para_birim_id ?? '-1']}>
                                      <TextField
                                        name='net_tutar'
                                        variant='outlined'
                                        size='small'
                                        value={MoneyFormatConvert(_result(item, 'net_tutar', ''), "money") + ' ' + props.paraKoduList[item.cari_hesap_para_birim_id ?? '-1']}
                                        fullWidth={true}
                                        inputProps={{
                                          style: { textAlign: 'right' }
                                        }}
                                      >
                                      </TextField>
                                    </Tooltip>
                                  </div>
                                  <div style={{ width: 100 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Checkbox
                                        name="tahsil_edildi"
                                        color="primary"
                                        checked={_result(item, 'tahsil_edildi', false)}
                                      ></Checkbox>
                                    </div>
                                  </div>
                                  <div style={{ width: 100 }} className={classes.tableFormItem}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      disableElevation
                                      onClick={() => setOpenDetailT(true)}
                                    >DETAY</Button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        {openDetailT &&
                          <Dialog
                            open={openDetailT}
                            onClose={() => setOpenDetailT(false)}
                          >
                            <DialogContent style={{ padding: 0 }}>
                              <div className="flex">
                                <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                                  <header className="flex items-center justify-between py-2 px-4">
                                    <Button
                                      className="pointer-events-none"
                                      color="inherit"
                                    >DETAY</Button>
                                    <Tooltip title="Kapat">
                                      <IconButton
                                        edge="end"
                                        color="inherit"
                                        size="small"
                                        onClick={() => setOpenDetailT(false)}
                                      ><IconClose /></IconButton>
                                    </Tooltip>
                                  </header>
                                  <main className="border-t border-palette-action-hover py-1">
                                    <>
                                      {(FORM.evrak_tahsilat[selectedTRow2]?.tip === '3') &&
                                        <ul className="flex flex-col list-none m-0 p-0">
                                          <li className="flex items-center justify-between py-1 pl-4 pr-8 space-x-2">
                                            <div className="flex flex-col">
                                              <div className={classes.formListTable}>
                                                <div className={classes.tableHeader}>
                                                  <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 50 }}>Satır No</div>
                                                  <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>Vade</div>
                                                  <div className={classes.tableHeaderItem} style={{ textAlign: "right", width: 200 }}>Tutar</div>
                                                  <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>Tahsil Tarihi</div>
                                                </div>
                                                <div style={{ flex: '1 1 auto' }}>
                                                  {FORM.evrak_tahsilat[selectedTRow2]?.tahsilat_vade?.length > 0 && FORM.evrak_tahsilat[selectedTRow2].tahsilat_vade.map((v) => (
                                                    <div key={`detayvade1-${v.satir_no}`} className={classes.tableFormRow}>
                                                      <div style={{ width: 50 }} className={classes.tableFormItem}>
                                                        <div className="flex items-center h-full">{v.satir_no}</div>
                                                      </div>
                                                      <div style={{ width: 150 }} className={classes.tableFormItem}>
                                                        <div className="flex items-center">
                                                          <Tooltip title={_isNil(v.vade) ? "" : v.vade}>
                                                            <TextField
                                                              name='vade'
                                                              variant='outlined'
                                                              size='small'
                                                              value={_result(v, 'vade', '')}
                                                              fullWidth={true}
                                                            ></TextField>
                                                          </Tooltip>
                                                        </div>
                                                      </div>
                                                      <div style={{ width: 200 }} className={classes.tableFormItem}>
                                                        <div className="flex items-center">
                                                          <Tooltip title={_isNil(v.tutar) ? "" : v.tutar}>
                                                            <TextField
                                                              name='tutar'
                                                              variant='outlined'
                                                              size='small'
                                                              value={MoneyFormatConvert(_result(v, 'tutar', ''), "money")}
                                                              fullWidth={true}
                                                              inputProps={{
                                                                style: { textAlign: 'right' }
                                                              }}
                                                            ></TextField>
                                                          </Tooltip>
                                                        </div>
                                                      </div>
                                                      <div style={{ width: 150 }} className={classes.tableFormItem}>
                                                        <div className="flex items-center">
                                                          <Tooltip title={_isNil(v.tahsilat_tarihi) ? "" : v.tahsilat_tarihi}>
                                                            <TextField
                                                              name='tahsilat_tarihi'
                                                              variant='outlined'
                                                              size='small'
                                                              value={_result(v, 'tahsilat_tarihi', '')}
                                                              fullWidth={true}
                                                            ></TextField>
                                                          </Tooltip>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      }
                                      <ul className={`flex flex-col list-none m-0 p-0 ${(FORM.evrak_tahsilat[selectedTRow2]?.tip === '3') ? "border-t-2 border-palette-action-hover" : ""} `}>
                                        <li className="flex items-center justify-between py-4 pl-4 pr-8 space-x-2">
                                          <ul className={classes.layoutFormList}>
                                            <li className={classes.layoutFormItem}>
                                              <label className={classes.layoutFormItemLabel}>Not</label>
                                              <Tooltip title={FORM.evrak_tahsilat[selectedTRow2]?.aciklama == null ? '' : FORM.evrak_tahsilat[selectedTRow2].aciklama}>
                                                <TextField
                                                  name='vade'
                                                  variant='outlined'
                                                  size='small'
                                                  value={_result(FORM.evrak_tahsilat[selectedTRow2], 'aciklama', '')}
                                                  fullWidth={true}
                                                  multiline
                                                  rows={3}
                                                ></TextField>
                                              </Tooltip>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </>
                                  </main>
                                </section>
                              </div>
                            </DialogContent>
                          </Dialog>
                        }
                      </div>
                    </>
                  )}
                </>
              )}

              {TAHSILAT_FORM.length === 0 && (
                <>
                  {props.tahsilatOlustur && ODEMESIZ_TAHSILAT && (
                    <div className={classes.layoutContainer}>
                      <div className='flex items-center gap-3'>
                        <Typography variant='body1' color='textPrimary'>
                          {props.form.evrak.genel_toplam} {props.paraBirimDic[props.form.evrak.para_birimi]} tutarındaki {props.odemeTipDic[props.form.tahsilat_detay[0].tip]} ödemesi için
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          disableElevation
                          onClick={props.funcTahsilatOlustur}
                        >TAHSİLAT OLUŞTUR</Button>
                      </div>
                    </div>
                  )}
                  {!props.tahsilatOlustur && ODEMESIZ_TAHSILAT && (
                    <>
                      <div className={classes.layoutContainer}>
                        <div className={classes.borderLayout}>
                          {topOpen && (
                            <>
                              <ul className={classes.layoutFormList}>
                                <li className={classes.layoutFormItem} id="magaza">
                                  <label className={classes.layoutFormItemLabel} htmlFor="magaza">Mağaza</label>
                                  <Tooltip title={_isNil(props.form.evrak.magaza) ? '' : props.form.evrak.magaza.tanim}>
                                    <TextField
                                      {...createTextFieldPropsGenel('magaza', 'tanim')}
                                      disabled
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="versiyon">
                                  <label className={classes.layoutFormItemLabel} htmlFor="versiyon">Versiyon</label>
                                  <Tooltip title={_isNil(props.form.evrak.versiyon) ? '' : props.form.evrak.versiyon}>
                                    <TextField
                                      {...createTextFieldPropsGenel('versiyon', '')}
                                      disabled
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="kodu">
                                  <label className={classes.layoutFormItemLabel} htmlFor="kodu">Evrak No *</label>
                                  <Tooltip title={props.error?.evrak?.kodu ? props.error.evrak.kodu[0] : _isNil(props.form.evrak.kodu) ? '' : props.form.evrak.kodu}>
                                    <TextField
                                      {...createTextFieldPropsGenel('kodu', '')}
                                      onChange={(e) => props.onChangeForm('evrak', 'kodu', null, e.target.value)}
                                      onBlur={() => handleInputErrors('evrak', props.form.evrak, props.formdefault.evrak, handleErrors, true, 'kodu', true, true, false, '', 'Evrak No', 'evrak')}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="evrak_tarihi">
                                  <label className={classes.layoutFormItemLabel} htmlFor="evrak_tarihi">Evrak Tarihi *</label>
                                  <Tooltip title={props.error?.evrak?.evrak_tarihi ? props.error.evrak.evrak_tarihi[0] : _isNil(props.form.evrak.evrak_tarihi) ? '' : moment(props.form.evrak.evrak_tarihi).format('DD.MM.YYYY, HH.mm')}>
                                    <TextField
                                      {...createTextFieldPropsGenel('evrak_tarihi', '')}
                                      type="datetime-local"
                                      onChange={(e) => props.onChangeForm('evrak', 'evrak_tarihi', null, e.target.value)}
                                      onBlur={() => handleInputErrors('', props.form.evrak, props.formdefault.evrak, handleErrors, true, 'evrak_tarihi', true, false, false, '', '', '')}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="tip">
                                  <label className={classes.layoutFormItemLabel} htmlFor="tip">Evrak Tipi</label>
                                  <Tooltip title={_isNil(props.form.evrak.tip) ? '' : props.form.evrak.tip.value}>
                                    <TextField
                                      {...createTextFieldPropsGenel('tip', 'value')}
                                      disabled
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="para_birimi">
                                  <label className={classes.layoutFormItemLabel} htmlFor="para_birimi">Evrak Para Birimi</label>
                                  <Tooltip title={_isNil(props.form.evrak.para_birimi) ? '' : props.paraBirimDic[props.form.evrak.para_birimi]}>
                                    <TextField
                                      {...createTextFieldPropsGenel('para_birimi', '')}
                                      disabled
                                      select
                                      SelectProps={{
                                        native: true,
                                      }}
                                    >{paralist}</TextField>
                                  </Tooltip>
                                </li>
                              </ul>

                              <ul className={classes.layoutFormList}>
                                <li className={classes.layoutFormItem} id="evrak_kullanici">
                                  <label className={classes.layoutFormItemLabel} htmlFor="evrak_kullanici">Cari Kod</label>
                                  <Tooltip title={_isNil(props.form.evrak.evrak_kullanici) ? '' : props.form.evrak.evrak_kullanici.kodu}>
                                    <TextField
                                      {...createTextFieldPropsGenel('evrak_kullanici', 'kodu')}
                                      disabled
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="evrak_kullanici">
                                  <label className={classes.layoutFormItemLabel} htmlFor="evrak_kullanici">Cari Hesap Adı</label>
                                  <Tooltip title={_isNil(props.form.evrak.evrak_kullanici) ? '' : props.form.evrak.evrak_kullanici?.first_name + ' ' + props.form.evrak.evrak_kullanici?.last_name}>
                                    <TextField
                                      {...createTextFieldPropsGenel('evrak_kullanici_adi', 'evrak_kullanici')}
                                      disabled
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="genel_toplam">
                                  <label className={classes.layoutFormItemLabel} htmlFor="genel_toplam">Genel Toplam</label>
                                  <Tooltip title={_isNil(props.form.evrak.genel_toplam) ? '' : MoneyFormatConvert(props.form.evrak.genel_toplam, "money")}>
                                    <span className="flex w-full">
                                      <NumericFormat
                                        {...createTextFieldPropsGenel('genel_toplam', '')}
                                        customInput={TextField}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        inputProps={{
                                          style: { textAlign: 'right' },
                                        }}
                                        InputProps={{
                                          readOnly: true
                                        }}
                                        valueIsNumericString={true}
                                      ></NumericFormat>
                                    </span>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="aciklama" style={{ height: "auto" }}>
                                  <label className={classes.layoutFormItemLabel} htmlFor="aciklama">Evrak Not</label>
                                  <Tooltip title={props.error?.evrak?.aciklama ? props.error.evrak.aciklama[0] : props.form.evrak?.aciklama == null ? '' : props.form.evrak.aciklama}>
                                    <TextField
                                      {...createTextFieldPropsGenel('aciklama', '')}
                                      multiline
                                      rows={3}
                                      onChange={(e) => props.onChangeForm('evrak', 'aciklama', null, e.target.value)}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                              </ul>
                            </>
                          )}
                          {!topOpen && (
                            <div>
                              <span className="font-medium truncate text-base mr-10">Evrak Tarihi : {_result(props.form.evrak, 'evrak_tarihi') || ''}</span>
                              <span className="font-medium truncate text-base mr-10">Evrak No : {_result(props.form.evrak, 'kodu') || ''}</span>
                              <span className="font-medium truncate text-base mr-10">Mağaza : {_result(props.form.evrak?.magaza, 'tanim') || ''}</span>
                              <span className="font-medium truncate text-base mr-10">Cari Kod : {_result(props.form.evrak?.evrak_kullanici, 'kodu') || ''}</span>
                              <span className="font-medium truncate text-base mr-10">Genel Toplam : {(MoneyFormatConvert(_result(props.form.evrak, 'genel_toplam'), "money") || '') + ' ' + props.paraKoduList[props.form.evrak?.para_birimi ?? '-1']}</span>
                            </div>
                          )}
                        </div>
                        <div className={topOpen ? classes.detailOpen : classes.detailClose}>
                          <IconButton
                            style={{ width: "1rem", height: "1rem", padding: 0 }}
                            disableRipple
                            onClick={() => setTopOpen(true)}
                          >
                            {topOpen ? <IconArrowDropUp style={{ fontSize: "2rem" }} /> : <IconArrowDropDown style={{ fontSize: "2rem" }} />}
                          </IconButton>
                        </div>
                      </div>
                      <div className={classes.layoutContainer}>
                        <div className={classes.borderLayout2}>
                          <div className={classes.formListTable}>
                            <div className={classes.tableHeader}>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>TİP</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 200 }}>TUTAR</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 250 }}>TANIM</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 200 }}>BANKA HESAP</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>PARA BİRİMİ</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>KUR</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>C/H DÖVİZ</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 150 }}>C/H DÖVİZ KUR</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 200 }}>C/H DÖVİZ KUR TUTAR</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 100 }}>TAHSİL EDİLDİ/ÖDENDİ</div>
                              <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 100 }}></div>
                            </div>
                            <div style={{ flex: '1 1 auto' }}>
                              {props.form.tahsilat_detay.map((item, index) => (
                                <div key={`detay-${index}`} className={`${classes.tableFormRow} ${selectedRow === index ? classes.selectedRow : ''}`}
                                  onClick={() => setSelectedRow(index)}
                                >
                                  <div style={{ width: 150 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={_isNil(item.tip) ? '' : props.odemeTipDic ? props.odemeTipDic[item.tip] : ''}>
                                        <TextField
                                          {...createTextFieldProps2(index, 'tip', item, '')}
                                          disabled
                                          select
                                          SelectProps={{
                                            native: true,
                                          }}
                                        >
                                          {props.odemeTip?.map((ot) => (
                                            <option key={ot.key} value={ot.key}>{ot.value}</option>
                                          ))}
                                        </TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 200 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.tutar ? props.error.tahsilat_detay[index].tutar[0] : _isNil(item.tutar) ? '' : MoneyFormatConvert(item.tutar, "money") + " " + props.paraKoduList[item.para_birimi ?? '-1']}>
                                        <span className='flex w-full'>
                                          <NumericFormat
                                            {...createTextFieldProps2(index, 'tutar', item, '')}
                                            onValueChange={(e) => props.onChangeForm('tahsilat_detay', 'tutar', index, e.value)}
                                            onBlur={() => handleInputErrors('', item, item, handleErrors2, true, 'tutar', true, false, false, '', '', '')}
                                            inputProps={{
                                              style: { textAlign: 'right' }
                                            }}
                                            InputProps={{
                                              endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[item.para_birimi ?? "-1"] || ''}</Typography>
                                            }}
                                            customInput={TextField}
                                            thousandSeparator="."
                                            decimalSeparator=','
                                            valueIsNumericString={true}
                                          ></NumericFormat>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 250 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.odeme_tanim ? props.error.tahsilat_detay[index].odeme_tanim[0] : _isNil(item.odeme_tanim) ? '' : props.odemeTanimDic ? props.odemeTanimDic[item.odeme_tanim] : ''}>
                                        <TextField
                                          {...createTextFieldProps2(index, 'odeme_tanim', item, '')}
                                          onChange={(e) => props.onChangeForm('tahsilat_detay', 'odeme_tanim', index, e.target.value)}
                                          select
                                          SelectProps={{
                                            native: true,
                                          }}
                                        >
                                          {item.odemetanimlist.map((ot) => (
                                            <option key={ot.id} value={ot.id}>{ot.tanim}</option>
                                          ))}
                                        </TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 200 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.banka_hesap ? props.error.tahsilat_detay[index].banka_hesap[0] : _isNil(item.banka_hesap) ? '' : props.bankalarDic ? props.bankalarDic[item.banka_hesap] : ''}>
                                        <TextField
                                          {...createTextFieldProps2(index, 'banka_hesap', item, '')}
                                          onChange={(e) => props.onChangeForm('tahsilat_detay', 'banka_hesap', index, e.target.value)}
                                          select
                                          SelectProps={{
                                            native: true,
                                          }}
                                        >
                                          <option value='-1' disabled>Seçilmedi</option>
                                          {item.bankalist.map((b) => (
                                            <option value={b.id} key={b.id}>{b.tanim}</option>
                                          ))}
                                        </TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 150 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.para_birimi ? props.error.tahsilat_detay[index].para_birimi[0] : _isNil(item.para_birimi) || item.para_birimi === "-1" ? '' : props.paraBirimDic ? props.paraBirimDic[item.para_birimi] : ''}>
                                        <TextField
                                          {...createTextFieldProps2(index, 'para_birimi', item, '')}
                                          onChange={(e) => props.onChangeForm('tahsilat_detay', 'para_birimi', index, e.target.value)}
                                          select
                                          SelectProps={{
                                            native: true,
                                          }}
                                        >
                                          <option value='-1' disabled>Seçilmedi</option>
                                          {item.parabirimlist.map((b) => (
                                            <option value={b.id} key={b.id}>{b.tanim}</option>
                                          ))}
                                        </TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 150 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.kur ? props.error.tahsilat_detay[index].kur[0] : _isNil(item.kur) ? '' : MoneyFormatConvert(item.kur, "money") + " " + props.paraKoduList[props.form.evrak.para_birimi ?? '-1']}>
                                        <span className='flex w-full'>
                                          <NumericFormat
                                            {...createTextFieldProps2(index, 'kur', item, '')}
                                            onValueChange={(e) => props.onChangeForm('tahsilat_detay', 'kur', index, e.value)}
                                            onBlur={() => handleInputErrors('', item, item, handleErrors2, true, 'kur', true, false, false, '', '', '')}
                                            inputProps={{
                                              style: { textAlign: 'right' }
                                            }}
                                            InputProps={{
                                              endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form.evrak?.para_birimi ?? '-1'] || ''}</Typography>,
                                              readOnly: item.para_birimi !== props.form.evrak.para_birimi ? false : true
                                            }}
                                          ></NumericFormat>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 150 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.cari_hesap_para_birim ? props.error.tahsilat_detay[index].cari_hesap_para_birim[0] : _isNil(item.cari_hesap_para_birim) || item.para_birimi === "-1" ? '' : props.paraBirimDic ? props.paraBirimDic[item.cari_hesap_para_birim] : ''}>
                                        <TextField
                                          {...createTextFieldProps2(index, 'cari_hesap_para_birim', item, '')}
                                          onChange={(e) => props.onChangeForm('tahsilat_detay', 'cari_hesap_para_birim', index, e.target.value)}
                                          select
                                          SelectProps={{
                                            native: true,
                                          }}
                                        >
                                          {paralist}
                                        </TextField>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 150 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.cari_hesap_kur ? props.error.tahsilat_detay[index].cari_hesap_kur[0] : _isNil(item.cari_hesap_kur) ? '' : MoneyFormatConvert(item.cari_hesap_kur, "money") + " " + props.paraKoduList[props.form.evrak.para_birimi ?? '-1']}>
                                        <span className='flex w-full'>
                                          <NumericFormat
                                            {...createTextFieldProps2(index, 'cari_hesap_kur', item, '')}
                                            onValueChange={(e) => props.onChangeForm('tahsilat_detay', 'cari_hesap_kur', index, e.value)}
                                            onBlur={() => handleInputErrors('', item, item, handleErrors2, true, 'cari_hesap_kur', true, false, false, '', '', '')}
                                            inputProps={{
                                              style: { textAlign: 'right' }
                                            }}
                                            InputProps={{
                                              endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form.evrak?.para_birimi ?? '-1'] || ''}</Typography>,
                                              readOnly: item.cari_hesap_para_birim !== props.form.evrak.para_birimi ? false : true
                                            }}
                                          ></NumericFormat>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 200 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Tooltip title={props.error?.tahsilat_detay?.[index]?.net_tutar ? props.error.tahsilat_detay[index].net_tutar[0] : _isNil(item.net_tutar) ? '' : MoneyFormatConvert(item.net_tutar, "money") + " " + props.paraKoduList[item.cari_hesap_para_birim ?? '-1']}>
                                        <span className='flex w-full'>
                                          <NumericFormat
                                            {...createTextFieldProps2(index, 'net_tutar', item, '')}
                                            inputProps={{
                                              style: { textAlign: 'right' }
                                            }}
                                            InputProps={{
                                              endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[item.cari_hesap_para_birim ?? "-1"] || ''}</Typography>,
                                              readOnly: true
                                            }}
                                          ></NumericFormat>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div style={{ width: 100 }} className={classes.tableFormItem}>
                                    <div className="flex items-center">
                                      <Checkbox
                                        name="tahsil_edildi"
                                        color="primary"
                                        checked={_result(item, 'tahsil_edildi', false)}
                                        onChange={(e, checked) => props.onChangeForm('tahsilat_detay', 'tahsil_edildi', index, checked)}
                                      ></Checkbox>
                                    </div>
                                  </div>
                                  <div style={{ width: 100 }} className={classes.tableFormItem}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      disableElevation
                                      onClick={() => setOpenDetail(true)}
                                    >DETAY</Button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        {openDetail &&
                          <Dialog
                            open={openDetail}
                            onClose={() => setOpenDetail(false)}
                          >
                            <DialogContent style={{ padding: 0 }}>
                              <div className="flex">
                                <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                                  <header className="flex items-center justify-between py-2 px-4">
                                    <Button
                                      className="pointer-events-none"
                                      color="inherit"
                                    >DETAY</Button>
                                    <Tooltip title="Kapat">
                                      <IconButton
                                        edge="end"
                                        color="inherit"
                                        size="small"
                                        onClick={() => setOpenDetail(false)}
                                      ><IconClose /></IconButton>
                                    </Tooltip>
                                  </header>
                                  <main className="border-t border-palette-action-hover py-1">
                                    <ul className={`flex flex-col list-none m-0 p-0 } `}>
                                      <li className="flex items-center justify-between py-4 pl-4 pr-8 space-x-2">
                                        <ul className={classes.layoutFormList}>
                                          <li className={classes.layoutFormItem}>
                                            <label className={classes.layoutFormItemLabel}>Not</label>
                                            <Tooltip title={props.form.tahsilat_detay[selectedRow]?.aciklama == null ? '' : props.form.tahsilat_detay[selectedRow].aciklama}>
                                              <TextField
                                                {...createTextFieldProps2(selectedRow, 'aciklama', props.form.tahsilat_detay[selectedRow], '')}
                                                onChange={(e) => props.onChangeForm('tahsilat_detay', 'aciklama', selectedRow, e.target.value)}
                                                multiline
                                                rows={3}
                                              ></TextField>
                                            </Tooltip>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </main>
                                </section>
                              </div>
                            </DialogContent>
                          </Dialog>
                        }
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </>
      }
    </>
  );
});

export default SatisIadeFaturaDetailTab_Tahsilat;
