import React, { useState, useLayoutEffect, useMemo } from 'react';

import {
  result as _result,
  has as _has,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { Hata } from '../../base/Hata';
import { handleInputErrors2 } from '../../base/InputErrors';

import Store from '../../../libs/store'
import Themes from '../../../themes/index';


const appTheme = Themes[Store.get('themeChoice')?.theme ?? 'light'];

const styles = ({ spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    table: {
      '&': {
        whiteSpace: 'nowrap'
      },
      '& tr th, & tr td': {
        lineHeight: 1,
        padding: 0,
      },
      '& tbody tr:hover td': {
        backgroundColor: appTheme.palette.action.hover,
      },
      '& tbody tr:last-child td': {
        borderBottomWidth: 0,
      }
    },
    tablecell: {
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
  };

};

const UrunDetailXTabFiyatlar = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.liste.listeTanim,
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <LayoutDetailVerticalTab
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                buttonShow={false}
              >
                {COUNT_TABS === 0 && (
                  <div className="flex flex-col items-start justify-start p-4 space-y-4">
                    <Typography variant="body1">Panele ait tanımlanmış bir fiyat tanımı bulunamadı.Lütfen önce bir fiyat tanımı tanımlayın.</Typography>
                  </div>
                )}
                {COUNT_TABS > 0 && (
                  <div className={classes.tabDiv}>
                    <Table className={classes.table}
                      size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell component="th" scope="row" style={{ minWidth: 200, width: 200, padding: 2, height: 40 }}>Fiyat Adı</TableCell>
                          <TableCell component="th" scope="row" style={{ minWidth: 200, width: 200, padding: 2, height: 40 }} align="right">Liste Fiyat</TableCell>
                          <TableCell component="th" scope="row" style={{ minWidth: 200, width: 200, padding: 2, height: 40 }} align="right">İndirimli Fiyat</TableCell>
                          <TableCell component="th" scope="row" style={{ minWidth: 200, width: 200, padding: 2, height: 40 }} align="right">Para Birimi</TableCell>
                          <TableCell component="th" scope="row" style={{ minWidth: 200, width: 200, padding: 2, height: 40 }}>Oluşturan Kullanıcı</TableCell>
                          <TableCell component="th" scope="row" style={{ minWidth: 200, width: 200, padding: 2, height: 40 }}>Değiştiren Kullanıcı</TableCell>
                          <TableCell component="th" scope="row" style={{ minWidth: 200, width: 200, padding: 2, height: 40 }}>Oluşturma</TableCell>
                          <TableCell component="th" scope="row" style={{ minWidth: 200, width: 200, padding: 2, height: 40 }}>Son Değişiklik</TableCell>
                          <TableCell component="th" scope="row" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {FORM.map((state, index) => (
                          <TableRow key={state.form.fiyat_detay}>
                            <TableCell style={{ padding: 2 }}>
                              <div>
                                {_result(state.form, 'fiyattanim') || ''}
                              </div>
                            </TableCell>
                            <TableCell style={{ padding: 2, textAlign: "right" }} className={classes.tablecell}>
                              <Tooltip title={state.errors?.liste_fiyat ? state.errors.liste_fiyat[0] : _isNil(state?.form?.liste_fiyat) ? '' : state.form.liste_fiyat}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  value={_result(state.form, 'liste_fiyat') || ''}
                                  error={_has(state.errors, 'liste_fiyat')}
                                  onChange={(e) => props.onChangeFormData(selectedTabIndex, index, 'liste_fiyat', e.target.value)}
                                  onBlur={() => handleInputErrors2(state.form, 'liste_fiyat', (errors) => { props.onCheckErrors(errors, selectedTabIndex, index) })}
                                  fullWidth
                                  inputProps={{
                                    min: 0,
                                    style: { textAlign: "right" }
                                  }}
                                ></TextField>
                              </Tooltip>
                            </TableCell>
                            <TableCell style={{ padding: 2, textAlign: "right" }} className={classes.tablecell}>
                              <Tooltip title={state.errors?.tanim ? state.errors.tanim[0] : _isNil(state?.form?.deger) ? '' : state.form.deger}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  value={_result(state.form, 'deger') || ''}
                                  error={_has(state.errors, 'deger')}
                                  onChange={(e) => props.onChangeFormData(selectedTabIndex, index, 'deger', e.target.value)}
                                  fullWidth
                                  inputProps={{
                                    min: 0,
                                    style: { textAlign: "right" }
                                  }}
                                ></TextField>
                              </Tooltip>
                            </TableCell>
                            <TableCell style={{ padding: 2, textAlign: "right" }}>
                              <Tooltip title={state.errors?.para_birimi ? state.errors.para_birimi[0] : (state?.form?.para_birimi === "-1" || _isNil(state?.form?.para_birimi)) ? '' : props.parabirimListDic[state.form.para_birimi]}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  value={_result(state.form, 'para_birimi')}
                                  error={_has(state.errors, 'para_birimi')}
                                  onChange={(e) => props.onChangeFormData(selectedTabIndex, index, 'para_birimi', e.target.value)}
                                  onBlur={() => handleInputErrors2(state.form, 'para_birimi', (errors) => { props.onCheckErrors(errors, selectedTabIndex, index) })}
                                  fullWidth
                                  select
                                  SelectProps={{ native: true }}
                                  inputProps={{
                                    style: { textAlign: "right" }
                                  }}
                                >
                                  <option value='-1' disabled>Seçilmedi</option>
                                  {props.parabirim.map((pb) => (
                                    <option key={pb.id} value={pb.id}>{pb.tanim}</option>
                                  ))}
                                </TextField>
                              </Tooltip>
                            </TableCell>

                            <TableCell style={{ padding: 2 }} className={classes.tablecell}>
                              <Tooltip title={_isNil(state?.form?.olusturan_kullanici) ? '' : state.form.olusturan_kullanici}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  value={_result(state.form, 'olusturan_kullanici') || ''}
                                  fullWidth
                                ></TextField>
                              </Tooltip>
                            </TableCell>
                            <TableCell style={{ padding: 2 }} className={classes.tablecell}>
                              <Tooltip title={_isNil(state?.form?.degistiren_kullanici) ? '' : state.form.degistiren_kullanici}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  value={_result(state.form, 'degistiren_kullanici') || ''}
                                  fullWidth
                                ></TextField>
                              </Tooltip>
                            </TableCell>
                            <TableCell style={{ padding: 2 }} className={classes.tablecell}>
                              <Tooltip title={_isNil(state?.form?.olusturma_zamani) ? '' : state.form.olusturma_zamani}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  value={_result(state.form, 'olusturma_zamani') || ''}
                                  fullWidth
                                ></TextField>
                              </Tooltip>
                            </TableCell>
                            <TableCell style={{ padding: 2 }} className={classes.tablecell}>
                              <Tooltip title={_isNil(state?.form?.degistirme_zamani) ? '' : state.form.degistirme_zamani}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  value={_result(state.form, 'degistirme_zamani') || ''}
                                  fullWidth
                                ></TextField>
                              </Tooltip>
                            </TableCell>

                            <TableCell />
                          </TableRow>
                        ))

                        }
                      </TableBody>
                    </Table>
                  </div>
                )}
              </LayoutDetailVerticalTab>
            </div>
          )}
        </>
      }
    </>
  )
});

export default UrunDetailXTabFiyatlar;
