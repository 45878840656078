import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import moment from 'moment';

import {
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import {
  Search as IconSearch,
  Send as IconSend
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import ListSelectorSingle from '../../../components/ListSelectorSingle';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 0, 1, 0),
      overflow: 'hidden',
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      padding: spacing(1),
      overflow: 'hidden'
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    mesajAlan: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
    income: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    sent: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    mesajBox: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 300,
      maxWidth: 600,
      border: '1px solid #eacfea',
      padding: spacing(1),
      marginBottom: spacing(1),
      borderRadius: 4,
    },
    mesajText: {

    },
    mesajTime: {
      textAlign: 'end',
      fontSize: 10,
      marginTop: spacing(1),
    }
  };
};
const DepartmanDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);
  const LOADING_MESSAGE = useMemo(() => props.mesajLoading, [props.mesajLoading]);
  const HATA_MESSAGE = useMemo(() => props.hataMesaj, [props.hataMesaj]);

  const ITEMS = useMemo(() => props.mesajlar, [props.mesajlar]);

  const [openSelectorDepartman, setOpenSelectorDepartman] = useState(false);
  const [openSelectorKime, setOpenSelectorKime] = useState(false);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2) {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form[name], `${name2}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form, `${name}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    }

  }, [props.error, props.form]);

  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.mesaj, `${name}`) || defaultValue,
      error: _has(props.mesajError, `${name}`),
      fullWidth: true,
    };
  }, [props.mesajError, props.mesaj]);



  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="baslik">
                      <label className={classes.layoutFormItemLabel} htmlFor="baslik">Mesaj Başlık *</label>
                      <Tooltip title={props.error?.baslik ? props.error.baslik[0] : _isNil(props.form?.baslik) ? '' : props.form.baslik}>
                        <TextField
                          {...createTextFieldProps('baslik', '')}
                          disabled={!add}
                          onChange={(e) => {
                            if (add) {
                              props.onChangeForm('baslik', e.target.value)
                            }
                          }}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'baslik', true, false, false, '', '', '')}
                          inputProps={{
                            maxLength: 200,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="departman">
                      <label className={classes.layoutFormItemLabel} htmlFor="departman">Departman</label>
                      <Tooltip title={props.error?.departman ? props.error.departman[0] : _isNil(props.form?.departman) ? '' : props.form.departman.tanim}>
                        <TextField
                          {...createTextFieldProps('departman', 'tanim')}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                size="small"
                                className={classes.button}
                                disableRipple
                                disabled={!add}
                                onClick={() => {
                                  setOpenSelectorDepartman(true)
                                }}
                              ><IconSearch /></IconButton>
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="kime">
                      <label className={classes.layoutFormItemLabel} htmlFor="kime">Kime</label>
                      <Tooltip title={props.error?.kime ? props.error.kime[0] : _isNil(props.form?.kime) ? '' : props.form.kime.username}>
                        <TextField
                          {...createTextFieldProps('kime', 'username')}
                          onChange={(e) => props.onChangeForm('kime', e.target.value)}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                size="small"
                                className={classes.button}
                                disableRipple
                                disabled={!add}
                                onClick={() => {
                                  setOpenSelectorKime(true)
                                }}
                              ><IconSearch /></IconButton>
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                  </ul>
                </div>
                <div className={classes.layoutContainerTab}>
                  <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">MESAJLAR</span></div>
                  <>
                    {HATA_MESSAGE && (
                      <Hata />
                    )}
                    {!HATA_MESSAGE && (
                      <div className={classes.tabDiv}>
                        <div className={classes.mesajAlan}>
                          <div className="h-full overflow-y-auto">
                            {LOADING_MESSAGE && (
                              <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
                                <div className="w-full h-16 flex items-center justify-center">
                                  <CircularProgress />
                                </div>
                                <p className="font-medium">Mesajlar yükleniyor</p>
                              </div>
                            )}
                            {(!LOADING_MESSAGE && ITEMS.length === 0) && (
                              <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
                                <p className="font-medium mb-2">Mesaj bulunamadı.</p>
                              </div>
                            )}
                            {(!LOADING_MESSAGE && ITEMS.length > 0) && (
                              <>
                                {ITEMS.map(item => (
                                  <div key={`${item.id}-mesaj`} className={item.yonetici ? classes.sent : classes.income}>
                                    <div className={classes.mesajBox}>
                                      <div className={classes.mesajText}>{item.mesaj}</div>
                                      <div className={classes.mesajTime}>{moment(item.zaman).format('DD/MM/YYYY, HH.mm')}</div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                          <div>
                            <Tooltip title={props.mesajError?.mesaj ? props.mesajError.mesaj[0] : _isNil(props.mesaj?.mesaj) ? '' : props.mesaj.mesaj}>
                              <TextField
                                {...createTextFieldProps2('mesaj')}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    props.onSendMessage()
                                  }
                                }}
                                onChange={(e) => props.onChangeMesaj(e.target.value)}
                                InputProps={{
                                  endAdornment:
                                    <IconButton
                                      size="small"
                                      className={classes.button}
                                      disableRipple
                                      onClick={props.onSendMessage}
                                    ><IconSend /></IconButton>
                                }}
                              ></TextField>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </>
          )}
        </>
      }
      {openSelectorKime &&
        <Dialog
          open={openSelectorKime}
          PaperProps={{
            style: {
              width: '480px',
              height: '600px',
            },
          }}
          onClose={() => {
            handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'kime', true, false, false, '', '', '')
            setOpenSelectorKime(false);
          }}
        >
          <DialogContent style={{ padding: 0 }}>
            <ListSelectorSingle
              title='Kullanıcılar'
              listPath='kullanici/mini_list?is_staff=false&aktif=true'
              listCountPath='kullanici/count?is_staff=false&aktif=true'
              keyProp='id'
              valueProp='username'
              defaultItem={props.form.kime}
              onChangeItems={(items) => props.onChangeForm('kime', items)}
              onClose={() => {
                handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'kime', true, false, false, '', '', '')
                setOpenSelectorKime(false);
              }}
              newPath='tanimlar/uye'
            />
          </DialogContent>
        </Dialog>
      }
      {openSelectorDepartman &&
        <Dialog
          open={openSelectorDepartman}
          PaperProps={{
            style: {
              width: '480px',
              height: '600px',
            },
          }}
          onClose={() => {
            handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'departman', true, false, false, '', '', '')
            setOpenSelectorDepartman(false);
          }}
        >
          <DialogContent style={{ padding: 0 }}>
            <ListSelectorSingle
              title='Departmanlar'
              listPath='departman/mini_list?aktif=true'
              listCountPath='departman/count?aktif=true'
              keyProp='id'
              valueProp='tanim'
              defaultItem={props.form.departman}
              onChangeItems={(items) => props.onChangeForm('departman', items)}
              onClose={() => {
                handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'departman', true, false, false, '', '', '')
                setOpenSelectorDepartman(false);
              }}
              newPath='tanimlar/uye'
            />
          </DialogContent>
        </Dialog>
      }
    </>
  );
});

export default DepartmanDetailTabGenel;
