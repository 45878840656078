import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';
import moment from 'moment';

import {
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  Tooltip,
  IconButton,
  Button,
} from '@material-ui/core';

import {
  HelpOutline as IconHelp
} from '@material-ui/icons';


import { Editor } from "@tinymce/tinymce-react";
import { withStyles } from '@material-ui/core/styles';
import ListSelector from '../../../components/ListSelector';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors, handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import MultipleSelectList from '../../../components/MultipleSelectList';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      position: 'relative',
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 0, 1, 0),
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    layoutFormItemEditor: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: 5,
    },
    button: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& + &': {
        marginLeft: 10,
      },
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
  };
};

const FormTanimDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATADIL = useMemo(() => props.hataDil, [props.hataDil]);
  const add = useMemo(() => props.add, [props.add]);
  const [openSelectorSite, setOpenSelectorSite] = useState(false);
  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);
  const [htmlShow, setHtmlShow] = useState(false);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.dilform?.length ?? 0, [props]);
  const FORM = useMemo(() => props?.dilform?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.dilform?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.dilform?.length > 0) {
      const copyForms = [...props.dilform];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.dilform]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const handleRemoveSiteById = (id) => {
    const copysiteler = [...props.form.siteler];
    const index = copysiteler.findIndex((c) => c.id === id);
    if (index > -1) {
      copysiteler.splice(index, 1);
      props.onChangeForm('siteler', copysiteler);
    }
  }

  const handleChangeForm = (property, value) => {
    props.onChangeDilForm(selectedTabIndex, property, value);
  };

  const handleSetErrors = (errors) => {
    props.onCheckErrorsDil(errors, selectedTabIndex);
  }

  const setValidDate = (prop) => {
    let errors = {};
    if (prop === 'yayina_baslangic') {
      if (props.form.yayina_baslangic > props.form.yayin_bitis) {
        errors[prop] = ['Başlangıç tarihi bitiş tarihinden sonra olamaz.']
      } else {
        errors['yayin_bitis'] = null;
        errors[prop] = null;
      }
    } else {
      if (props.form.yayin_bitis < props.form.yayina_baslangic) {
        errors[prop] = ['Bitiş tarihi başlangıç tarihinden önce olamaz.']
      } else {
        errors['yayina_baslangic'] = null;
        errors[prop] = null;
      }
    }
    props.onCheckErrors(errors);
  }

  const handleDilDelete = (index) => {
    props.deleteDil(index);
  }


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim')}
                          onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                          onBlur={() => handleInputErrors('form', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Tanım', 'form tanim')}
                          inputProps={{
                            maxLength: 250,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="slug">
                      <label className={classes.layoutFormItemLabel} htmlFor="slug">Form İsmi *</label>
                      <Tooltip title={props.error?.slug ? props.error.slug[0] : _isNil(props.form?.slug) ? '' : props.form.slug}>
                        <TextField
                          {...createTextFieldProps('slug')}
                          onChange={(e) => props.onChangeForm('slug', e.target.value)}
                          onBlur={() => handleInputErrors('form', props.form, props.formdefault, props.onCheckErrors, add, 'slug', true, true, false, '', 'Form İsmi', 'form tanim')}
                          inputProps={{
                            maxLength: 200,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="adres">
                      <label className={classes.layoutFormItemLabel} htmlFor="adres">Adres *</label>
                      <Tooltip title={props.error?.adres ? props.error.adres[0] : _isNil(props.form?.adres) ? '' : props.form.adres}>
                        <TextField
                          {...createTextFieldProps('adres')}
                          onChange={(e) => props.onChangeForm('adres', e.target.value)}
                          onBlur={() => handleInputErrors('form', props.form, props.formdefault, props.onCheckErrors, add, 'adres', true, true, false, '', 'Adres', 'form tanim')}
                          inputProps={{
                            maxLength: 200,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="yayina_baslangic">
                      <label className={classes.layoutFormItemLabel} htmlFor="yayina_baslangic">Yayına Başlangıç Tarihi</label>
                      <Tooltip title={props.error?.yayina_baslangic ? props.error.yayina_baslangic[0] : _isNil(props.form?.yayina_baslangic) ? '' : moment(props.form.yayina_baslangic).format('DD/MM/YYYY, HH.mm')}>
                        <TextField
                          {...createTextFieldProps('yayina_baslangic', Date.now())}
                          onChange={(e) => props.onChangeForm('yayina_baslangic', e.target.value)}
                          type="datetime-local"
                          onBlur={() => setValidDate('yayina_baslangic')}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="yayin_bitis">
                      <label className={classes.layoutFormItemLabel} htmlFor="yayin_bitis">Yayın Bitiş Tarihi</label>
                      <Tooltip title={props.error?.yayin_bitis ? props.error.yayin_bitis[0] : _isNil(props.form?.yayin_bitis) ? '' : moment(props.form.yayin_bitis).format('DD/MM/YYYY, HH.mm')}>
                        <TextField
                          {...createTextFieldProps('yayin_bitis', Date.now())}
                          onChange={(e) => props.onChangeForm('yayin_bitis', e.target.value)}
                          type="datetime-local"
                          onBlur={() => setValidDate('yayin_bitis')}
                        ></TextField>
                      </Tooltip>
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="pasif_sayfa_basligi">
                      <label className={classes.layoutFormItemLabel} htmlFor="pasif_sayfa_basligi">Pasif Form Sayfası Başlığı</label>
                      <Tooltip title={props.error?.pasif_sayfa_basligi ? props.error.pasif_sayfa_basligi[0] : _isNil(props.form?.pasif_sayfa_basligi) ? '' : props.form.pasif_sayfa_basligi}>
                        <TextField
                          {...createTextFieldProps('pasif_sayfa_basligi')}
                          onChange={(e) => props.onChangeForm('pasif_sayfa_basligi', e.target.value)}
                          inputProps={{
                            maxLength: 255,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif_sayfa_basligi">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif_sayfa_basligi">Aktif Form Sayfası Başlığı</label>
                      <Tooltip title={props.error?.aktif_sayfa_basligi ? props.error.aktif_sayfa_basligi[0] : _isNil(props.form?.aktif_sayfa_basligi) ? '' : props.form.aktif_sayfa_basligi}>
                        <TextField
                          {...createTextFieldProps('aktif_sayfa_basligi')}
                          onChange={(e) => props.onChangeForm('aktif_sayfa_basligi', e.target.value)}
                          inputProps={{
                            maxLength: 255,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="siralama">
                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                      <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                        <TextField
                          {...createTextFieldProps('siralama')}
                          onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                          type="number"
                          inputProps={{
                            min: -2147483648,
                            max: 2147483647,
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="kullanici_al">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Kullanıcı Bilgisi Al</label>
                      <Checkbox
                        name="kullanici_al"
                        color="primary"
                        checked={_result(props.form, 'kullanici_al', false)}
                        onChange={(e, checked) => props.onChangeForm('kullanici_al', checked)}
                      ></Checkbox>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText('“Form ile beraber kullanıcı bilgisi gelmesi için kullanılır, bu alan aktif olduğunda form sayfasına gelindiğinde, kullanıcı giriş yapmamışsa, giriş sayfasına yönlendirilir.”');
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                      ></Checkbox>
                    </li>
                  </ul>

                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItemBox} id="aktif">
                      <MultipleSelectList
                        headername="SİTELER"
                        name="Siteler"
                        name2="Site"
                        form={props.form}
                        list={props.form?.siteler ?? []}
                        listlength={props?.form?.siteler?.length ?? 0}
                        opendialog={setOpenSelectorSite}
                        keyProp="id"
                        valueProp="name"
                        removefonk={handleRemoveSiteById}
                        loading={props.loading}
                        error={props.error?.siteler ? props.error.siteler[0] : null}
                        newPath="tanimlar/site"
                        ustId={false}
                      />
                    </li>
                  </ul>
                </div>

                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItemEditor} id="pasif_formset_html_show_hide">
                      <div>
                        <Button
                          className={classes.button}
                          onClick={() => setHtmlShow(!htmlShow)}
                        >PASİF FORM SAYFASI HTML {htmlShow ? "GİZLE" : "GÖSTER"}</Button>
                      </div>
                    </li>
                    {htmlShow && (
                      <li className={classes.layoutFormItemEditor} id="pasif_formset_html">
                        <label className={classes.layoutFormItemLabel} htmlFor="pasif_formset_html">Pasif Form Sayfası Html *</label>
                        <div style={{ borderWidth: 1, borderStyle: 'solid', borderColor: _has(props.error, "pasif_formset_html") ? 'red' : 'transparent' }}>
                          <Editor
                            initialValue={_result(props.form, 'pasif_formset_html') || ""}
                            init={{
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar: 'undo redo copy cut paste remove removeformat selectall | formatselect | fontselect | fontsizeselect |' +
                                'bold italic underline lineheight backcolor forecolor blockquote | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | code | help',
                              language: 'tr',
                              valid_elements: '+*[*]',
                              valid_children: "+body[style]",
                              verify_html: false
                            }}
                            onChange={(e) => props.onChangeForm('pasif_formset_html', e.target.getContent())}
                          />
                        </div>
                      </li>
                    )}
                  </ul>
                </div>

                <div className={classes.layoutContainerTab}>
                  <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                  <>
                    {HATADIL && (
                      <Hata />
                    )}
                    {!HATADIL && (
                      <LayoutDetailVerticalTab
                        tabs={tabs}
                        selectedTabIndex={selectedTabIndex}
                        onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                        buttonShow={false}
                        delete={handleDilDelete}
                        deleteButtonShow={true}
                      >
                        {COUNT_TABS === 0 && (
                          <div className="flex flex-col items-start justify-start p-4 space-y-4">
                            <Typography variant="body1">Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                          </div>
                        )}
                        {COUNT_TABS > 0 && (
                          <div className={classes.tabDiv}>
                            <div className={classes.column}>
                              <ul className={classes.layoutFormList}>
                                <li className={classes.layoutFormItem} id="tanim">
                                  <label className={classes.layoutFormItemLabel} htmlFor="tanim">Tanım</label>
                                  <Tooltip title={ERROR?.tanim ? ERROR.tanim[0] : _isNil(FORM?.tanim) ? '' : FORM.tanim}>
                                    <TextField
                                      {...createTextFieldProps2('tanim')}
                                      onChange={(e) => handleChangeForm('tanim', e.target.value)}
                                      onBlur={() => handleInputErrors2(FORM, 'tanim', handleSetErrors)}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </LayoutDetailVerticalTab>
                    )}
                  </>
                </div>


              </div>


              {openSelectorSite &&
                <Dialog
                  open={openSelectorSite}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorSite(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Siteler'
                      listPath='site/mini_list?aktif=true'
                      listCountPath='site/count?aktif=true'
                      keyProp='id'
                      valueProp='name'
                      defaultItems={props.form.siteler}
                      onChangeItems={(items) => props.onChangeForm('siteler', items)}
                      onClose={() => setOpenSelectorSite(false)}
                      newPath='tanimlar/site'
                    />
                  </DialogContent>
                </Dialog>
              }
              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default FormTanimDetailTabGenel;
