import React, { useMemo } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { Hata } from '../../base/Hata';

import { ThemeProvider } from '@material-ui/core/styles';

import Store from '../../../libs/store'
import Themes from '../../../themes/index';

// main app theme configs
const appTheme = Themes[Store.get('themeChoice')?.theme ?? 'light'];

// in-component theme configs
const theme = {
  overrides: {
    MuiTable: {
      root: {
        '& th:first-child, & td:first-child': {
          paddingLeft: appTheme.spacing(4),
        },

        '& th:last-child, & td:last-child': {
          paddingRight: appTheme.spacing(4),
        },

        '& tbody tr:hover td': {
          backgroundColor: appTheme.palette.action.hover,
        },
        '& td,th': {
          fontSize: '0.75rem'
        }
      },
    },
  },
};

const UrunDetailXTabTedarikciler = ({ tedarikciler, hata, loading }) => {
  const LOADING = useMemo(() => loading, [loading]);
  const HATA = useMemo(() => hata, [hata]);
  const LISTE = useMemo(() => tedarikciler, [tedarikciler])

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className="py-4 relative w-full h-full flex flex-row items-start overflow-x-auto">
              <ThemeProvider theme={theme}>
                {LISTE.length > 0 &&
                  <>
                    <Table
                      className="focus:outline-none whitespace-nowrap"
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell component="th" scope="row" style={{ width: 1 }}>Tedarikçi Ürün Kodu</TableCell>
                          <TableCell component="th" scope="row" style={{ width: 1 }}>Tedarikçi</TableCell>
                          <TableCell component="th" scope="row" />
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {LISTE.map((row) => (
                          <TableRow
                            key={row.id}
                          >
                            <TableCell>{row.urun_kodu}</TableCell>
                            <TableCell>{row.kullanici.username}</TableCell>
                            <TableCell />
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                }

                {LISTE.length === 0 &&
                  <div className="px-8">Bu ürüne ait tedarikçi kaydı bulunamadı.</div>
                }
              </ThemeProvider>
            </div>
          )}
        </>
      }
    </>
  );
};

export default UrunDetailXTabTedarikciler;
