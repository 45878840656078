import React, { useMemo, useState } from 'react';

import {
  Button,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import {
  Search as IconSearch,
  HelpOutline as IconHelp
} from '@material-ui/icons';

import {
  Delete as IconDelete
} from '@material-ui/icons';

import RightMenu from '../helpers/rightMenu';

import { withStyles } from '@material-ui/core/styles';

const styles = () => {
  return {
    button: {
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button2: {
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
  };
};


const MultipleSelectList = withStyles(styles)(({ classes, headername, name, name2, form, list, listlength, opendialog, keyProp, valueProp, removefonk, loading, error, index, newPath, ustId, notEdit, multiple = false, helper = false, helperOpen, helperSet, openModalInside, setModalInside }) => {

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const MAPPED_LIST_ITEMS = useMemo(() => {
    if (ustId) {
      return list.map((item) => ({
        key: item[keyProp],
        value: item[valueProp],
        parent: item["parent"]
      }))
    } else {
      return list.map((item) => ({
        key: item[keyProp],
        value: item[valueProp],
      }))
    }

  }, [list, keyProp, valueProp, ustId]);

  return (
    <>
      <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
        <header className="flex items-center justify-between py-2 px-4">
          <Button
            className="pointer-events-none"
            color="inherit"
          >{headername}</Button>
          <span className="space-x-3">
            {!notEdit && (
              <Tooltip title={headername + " içinden seç"}>
                <IconButton
                  size="small"
                  className={classes.button}
                  disableRipple
                  onClick={() => {
                    if (multiple) {
                      opendialog(index);
                    } else {
                      opendialog(true);
                    }
                  }}
                ><IconSearch /></IconButton>
              </Tooltip>
            )}
            {helper && (
              <IconButton
                size="small"
                className={classes.button}
                disableRipple
                onClick={() => {
                  helperSet();
                  helperOpen();
                }}
              ><IconHelp /></IconButton>
            )}
          </span>
        </header>
        <main className="border-t border-palette-action-hover py-1 max-h-48 overflow-y-auto">
          {listlength > 0 &&
            <ul className="flex flex-col list-none m-0 p-0">
              {MAPPED_LIST_ITEMS.map((item) => (
                <li
                  key={item.key}
                  className="flex items-center justify-start py-1 pl-4 pr-8 space-x-2"
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setSelectedItem(item);
                    setSelectedPosition({ top: e.clientY, left: e.clientX });
                  }}
                >
                  {!notEdit && (
                    <Tooltip title="Sil">
                      <IconButton
                        size="small"
                        disableRipple
                        className={classes.button2}
                        onClick={() => {
                          if (multiple) {
                            removefonk(item.key, index)
                          } else {
                            removefonk(item.key)
                          }
                        }}
                      >
                        <IconDelete />
                      </IconButton>
                    </Tooltip>
                  )}
                  <span className="cursor-pointer">{item.value}</span>
                </li>
              ))}
            </ul>
          }
          {(loading && !form) && (
            <p className="py-1 px-6 m-0">{name} alınıyor.</p>
          )}
          {(!loading && listlength === 0) && (
            <p className="py-1 px-6 m-0">{name2} seçilmedi.</p>
          )}
          {(error) && (
            <p className="py-1 px-6 m-0 text-red-500">{error}</p>
          )}
          {selectedItem && newPath && (
            <RightMenu
              url={newPath}
              itemId={selectedItem?.key}
              ustId={ustId ? selectedItem?.parent ? selectedItem.parent : "null" : null}
              position={selectedPosition}
            ></RightMenu>
          )}
          {selectedItem && openModalInside && (
            <RightMenu
              position={selectedPosition}
              openModalInside={openModalInside}
              selectedItem={selectedItem}
              setModalInsideItem={(selectedItem) => setModalInside(selectedItem)}
            ></RightMenu>
          )}
        </main>
      </section>
    </>
  )
});

export default MultipleSelectList