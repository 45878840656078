import React, { useEffect, useState, } from 'react';
import clsx from 'clsx';

import {
  takeRight as _takeRight,
  take as _take,
} from 'lodash';

import {
  Button,
} from '@material-ui/core';

import {
  FirstPage as IconFirstPageIcon,
  KeyboardArrowLeft as IconKeyboardArrowLeftIcon,
  KeyboardArrowRight as IconKeyboardArrowRightIcon,
  LastPage as IconLastPageIcon,
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

const styles = ({ palette }) => {
  return {
    paginationButton: {
      height: 24,
      minWidth: 24,
      borderRadius: '0.25rem',
      padding: '0px 4px',
      fontSize: '.875rem',

      '&:active': {
        backgroundColor: palette.action.selected,
      },
    },

    paginationInput: {
      minWidth: 24,
      height: 24,
      padding: '0px 4px',
      fontSize: '.875rem',

      '&:disabled': {
        color: palette.action.disabled,
      },
    },
  };
};

const GridTableXPagination = withStyles(styles)(({ classes, page, countPages, onSetPage }) => {
  const [formValue, setFormValue] = useState();
  const [beforePages, setBeforePages] = useState([]);
  const [afterPages, setAfterPages] = useState([]);

  // const [isOpenDialog, setIsOpenDialog] = useState(false);
  // const [dialogValue, setDialogValue] = useState(page);

  // set form value
  useEffect(() => {
    setFormValue(page);
  }, [page]);

  // set before/after page numbers
  useEffect(() => {
    if (countPages > 1) {
      const beforePageCounts = page - 1;
      const afterPageCounts = countPages - page;

      const createBeforePages = [...new Array(beforePageCounts).keys()];
      const createAfterPages = [...new Array(afterPageCounts).keys()];

      setBeforePages(_takeRight(createBeforePages, 2).map((p) => p + 1));
      setAfterPages(_take(createAfterPages, 2).map((p) => page + p + 1));
    } else {
      setBeforePages([]);
      setAfterPages([]);
    }
  }, [page, countPages]);

  return (
    <>
      <div className="flex items-center justify-center space-x-2 overflow-hidden py-1">
        <Button
          className={classes.paginationButton}
          size="small"
          variant="outlined"
          disableRipple
          disabled={page === 1}
          onClick={() => onSetPage(1)}
        ><IconFirstPageIcon style={{ fontSize: 18 }} /></Button>

        <Button
          className={classes.paginationButton}
          size="small"
          variant="outlined"
          disableRipple
          disabled={page === 1}
          onClick={() => onSetPage(page - 1)}
        ><IconKeyboardArrowLeftIcon style={{ fontSize: 18 }} /></Button>

        {beforePages && beforePages.map((pageNumber) => {
          return (
            <Button
              key={pageNumber}
              className={classes.paginationButton}
              size="small"
              variant="outlined"
              disableRipple
              onClick={() => onSetPage(pageNumber)}
            >{pageNumber}</Button>
          );
        })}

        {/* <Button
          className={classes.paginationButton}
          size="small"
          variant="outlined"
          disableRipple
          onClick={() => setIsOpenDialog(true)}
        >{page}/{countPages}</Button> */}

        <input
          type="number"
          className={clsx(classes.paginationInput, 'font-roboto font-medium text-palette-text-primary rounded text-center border focus:outline-none no-arrow-input-number', {
            'bg-transparent border-palette-action-disabledBackground select-none': countPages < 2, // disabled
            'border-palette-action-disabled bg-palette-action-selected focus:bg-palette-background-paper': countPages > 1, // enabled
          })}
          value={formValue ?? page}
          min={1}
          max={countPages}
          disabled={countPages < 2}
          onChange={(e) => {
            if (e.target.value === '') {
              setFormValue('')
            } else if (!isNaN(e.target.value)) {
              if (countPages > Number(e.target.value)) {
                setFormValue(Number(e.target.value));
              }
            }
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onSetPage(e.target.value);
            }
          }}
        />

        {afterPages && afterPages.map((pageNumber) => {
          return (
            <Button
              key={pageNumber}
              className={classes.paginationButton}
              size="small"
              variant="outlined"
              disableRipple
              onClick={() => onSetPage(pageNumber)}
            >{pageNumber}</Button>
          );
        })}

        <Button
          className={classes.paginationButton}
          size="small"
          variant="outlined"
          disableRipple
          disabled={page >= countPages}
          onClick={() => onSetPage(page + 1)}
        ><IconKeyboardArrowRightIcon style={{ fontSize: 18 }} /></Button>

        <Button
          className={classes.paginationButton}
          size="small"
          variant="outlined"
          disableRipple
          disabled={page >= countPages}
          onClick={() => onSetPage(countPages)}
        ><IconLastPageIcon style={{ fontSize: 18 }} /></Button>
      </div>

      {/* <div className="fixed left-0 bottom-0 bg-white border border-gray-200 rounded p-2">
        <pre className="text-xs m-0 p-1">page {JSON.stringify(page)}</pre>
        <pre className="text-xs m-0 p-1">countPages {JSON.stringify(countPages)}</pre>
        <pre className="text-xs m-0 p-1">beforePages {JSON.stringify(beforePages)}</pre>
        <pre className="text-xs m-0 p-1">afterPages {JSON.stringify(afterPages)}</pre>
      </div> */}

      {/* <Dialog
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          className="py-2 px-4"
        >Sayfaya Git</DialogTitle>

        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              onSetPage(dialogValue);
              setIsOpenDialog(false);
            }}
          >
            <TextField
              inputProps={{ type: 'number', min: 1, max: countPages }}
              name="page"
              label="Sayfa Numarası"
              helperText={`En fazla ${countPages} yazabilirsiniz.`}
              defaultValue={dialogValue}
              onFocus={(e) => e.target.select()}
              onChange={(e) => setDialogValue(Number(e.target.value))}
              autoFocus
              fullWidth
            ></TextField>
          </form>
        </DialogContent>

        <DialogActions>
          <span className="flex items-center justify-end py-2 px-6 space-x-4">
            <Button
              onClick={() => setIsOpenDialog(false)}
            >KAPAT</Button>

            <Button
              color="primary"
              variant="contained"
              onClick={(e) => {
                e.preventDefault();

                onSetPage(dialogValue);
                setIsOpenDialog(false);
              }}
            >GİT</Button>
          </span>
        </DialogActions>
      </Dialog> */}
    </>
  );
});

export default GridTableXPagination;
