import React, { useLayoutEffect, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  pickBy as _pickBy,
  identity as _identity,
  isEmpty as _isEmpty
} from 'lodash';

import {
  TextField,
  Button,
  IconButton,
  Paper,
  AppBar,
  Toolbar,
  CircularProgress,
  Tooltip
} from '@material-ui/core';

import {
  Close as IconClose,
} from '@material-ui/icons';

import { handleInputErrors } from '../pages/base/InputErrors';

import { useSnackbar } from 'notistack';

import immer from 'immer';
import django from '../api/django';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
    },
    inputDivLabel: {
      width: 150,
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: 500,
      '& .MuiFormHelperText-root': {
        margin: 0,
        marginTop: 5,
      },
    },
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    }
  };
});



const FotoAddDialog = ({ image, onChange, close, fototip, type, name = "" }) => {
  const imageFile = useMemo(() => image ?? null, [image]);
  const [postImage, setPostImage] = useState({ tanim: " ", gercek_image: "", image: "", thumbnail: "" });
  const [postImageError, setPostImageError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  useLayoutEffect(() => {
    const uploadOne = (img) => {
      const image = new Image();
      image.src = img;
      image.onload = () => {
        const maxWidth = 1920;
        const maxHeight = 1200;
        const maxsize = 90;

        let width2 = image.naturalWidth;
        let height2 = image.naturalHeight;

        let width3 = image.naturalWidth;
        let height3 = image.naturalHeight;

        if (width2 > height2) {
          if (width2 > maxWidth) {
            height2 = height2 * (maxWidth / width2);
            width2 = maxWidth;
          }
        } else {
          if (height2 > maxHeight) {
            width2 = width2 * (maxHeight / height2);
            height2 = maxHeight;
          }
        }

        if (width3 > height3) {
          if (width3 > maxsize) {
            height3 = height3 * (maxsize / width3);
            width3 = maxsize;
          }
        } else {
          if (height3 > maxsize) {
            width3 = width3 * (maxsize / height3);
            height3 = maxsize;
          }
        }

        const canvas1 = document.createElement("canvas");
        const ctx1 = canvas1.getContext("2d");

        const canvas2 = document.createElement("canvas");
        const ctx2 = canvas2.getContext("2d");

        const canvas3 = document.createElement("canvas");
        const ctx3 = canvas3.getContext("2d");


        canvas1.width = image.naturalWidth;
        canvas1.height = image.naturalHeight;
        ctx1.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
        const dataUrl1 = canvas1.toDataURL(type === 'png' ? 'image/png' : 'image/jpeg');
        handleChange('gercek_image', dataUrl1);

        canvas2.width = width2;
        canvas2.height = height2;
        ctx2.drawImage(image, 0, 0, width2, height2);
        const dataUrl2 = canvas2.toDataURL(type === 'png' ? 'image/png' : 'image/jpeg');
        handleChange('image', dataUrl2);

        canvas3.width = 100;
        canvas3.height = 100;
        const dx = (100 - width3) / 2;
        const dy = (100 - height3) / 2;
        ctx3.drawImage(image, dx, dy, width3, height3);
        const dataUrl3 = canvas3.toDataURL();
        handleChange('thumbnail', dataUrl3);

        if (name) {
          handleChange('tanim', name);
        }
      }
    }
    if (imageFile) {
      uploadOne(imageFile);
    }
  }, [imageFile, type, name]);

  useLayoutEffect(() => {
    handleInputErrors('foto', postImage, {}, onCheckErrors, false, 'tanim', true, true, false, '', 'Fotoğraf Tanım', 'fotoğraf', '')
  }, [postImage]);


  const resmiYukle = () => {
    if (!postImageError) {
      const gercek = postImage.gercek_image.split(",")[1].length;
      const img = postImage.image.split(",")[1].length;
      const thumb = postImage.thumbnail.split(",")[1].length;
      const totalsize = img + gercek + thumb;

      if (totalsize > 5000000) {
        return enqueueSnackbar('Dosya boyutunuz büyük!', { variant: 'error' });
      } else {
        setLoading(true);
        const formdata = new FormData();
        formdata.append('image', postImage.image);
        formdata.append('thumbnail', postImage.thumbnail);
        formdata.append('gercek_image', postImage.gercek_image);
        formdata.append('tanim', postImage.tanim);
        formdata.append('foto_tip', fototip);
        django.post('foto', formdata).then((res) => {

          enqueueSnackbar("Resim ekleme başarıyla tamamlandı", { variant: 'success' });
          onChange({ id: res.data.id, tanim: res.data.tanim, image: res.data.image, thumbnail: res.data.thumbnail });
          close();
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              enqueueSnackbar("Resim eklenirken beklenmeyen bir hata oluştu", { variant: 'error' });
            } else {
              setPostImageError(error.response.data);
            }
          } else {
            enqueueSnackbar("Resim eklenirken beklenmeyen bir hata oluştu", { variant: 'error' });
          }
        }).finally(() => {
          setLoading(false);
        });
      }
    }
  }

  const handleChange = (property, value) => {
    setPostImage((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      })
    })
  }
  const onCheckErrors = (errors) => {
    setPostImageError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  return (
    <>
      <Paper className="flex flex-col w-full h-full overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">Fotoğraf Yükleme</h3>
            <Tooltip title="Kapat">
              <IconButton
                edge="end"
                color="inherit"
                size="small"
                onClick={close}
              ><IconClose /></IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <main className="relative flex flex-grow flex-col overflow-hidden">
          <div className="h-full flex-grow">
            <div className="relative flex flex-col w-full h-full items-start overflow-hidden p-4">
              {!loading && (
                <>
                  <div className={classes.layoutFormItem}>
                    <label htmlFor="tanim" className={classes.inputDivLabel}>Galeri Tanım *</label>
                    <TextField
                      name="tanim"
                      variant="outlined"
                      size="small"
                      value={_result(postImage, 'tanim')}
                      error={_has(postImageError, 'tanim')}
                      helperText={_result(postImageError, 'tanim[0]')}
                      fullWidth={true}
                      onChange={(e) => handleChange('tanim', e.target.value)}
                      onBlur={() => handleInputErrors('foto', postImage, {}, onCheckErrors, false, 'tanim', true, true, false, '', 'Fotoğraf Tanım', 'fotoğraf', '')}
                    ></TextField>
                  </div>

                  <div className="flex flex-col w-full mt-3">
                    <div style={{ height: 300 }}>
                      <img src={postImage.image} alt={postImage.tanim} style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                    </div>
                    <div style={{ height: 100, display: "flex", justifyContent: "center", marginTop: 10 }}>
                      <img src={postImage.thumbnail} alt={postImage.tanim} />
                    </div>
                  </div>

                  <div className="flex w-full mt-3 justify-center">
                    <Button
                      className={classes.button}
                      onClick={() => resmiYukle()}
                      disabled={!_isEmpty(postImageError) || !postImage.tanim}
                    >KAYDET</Button>
                  </div>
                </>
              )}
              {loading && (
                <div className="flex w-full mt-3 justify-center">
                  <CircularProgress color="primary" />
                </div>
              )}
            </div>
          </div>
        </main>
      </Paper>
    </>
  );
};

export default FotoAddDialog;