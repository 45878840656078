import React, { useState, useLayoutEffect } from 'react';
import django from '../../../../api/django';
import Layout from '../../../base/LayoutTableDetailInside';
import Detail from './IslemDetail';


const COLUMNS_DEFAULT = [
  { id: 'islem_tipi',           tableProp: 'islem_tipi.value',              filterProp: 'islem_tipi',               label: 'Entegrasyon İşlem Tipi',    element: 'select',          type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey:'key',  selectValue:'value'},
  { id: 'bitis_zamani',         tableProp: 'bitis_zamani',                  filterProp: 'bitis_zamani',             label: 'İşlemin Bitiş Zamanı',      element: 'input',           type: 'datetime', arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'hata',                 tableProp: 'hata',                          filterProp: 'hata',                     label: 'Hata Oluşmuş',              element: 'select',          type: 'boolean',  arrayTableProp: null,     show: true, sortable: false,  highlightable: false, alignment: 1, width: 1, selectOptions: null,  multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'olusturan_kullanici',  tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',   label: 'Oluşturan Kullanıcı',       element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: 'kullanici', multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'degistiren_kullanici', tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',  label: 'Değiştiren Kullanıcı',      element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: 'kullanici', multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'olusturma_zamani',     tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',         label: 'Oluşturma',                 element: 'input',           type: 'datetime', arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'degistirme_zamani',    tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',        label: 'Son Değişiklik',            element: 'input',           type: 'datetime', arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: null,        multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
];

const PageEntegrasyonUrunList = (props) => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        return column;
      });
    });
  }, []);

  useLayoutEffect(() => {
    django('enttanim/islem_tip').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'islem_tipi') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  return (
    <>
      <Layout
        title="ENTEGRASYON İŞLEMLER"
        path={props.ID ? `enttanim/${props.ID}/islem` : ""}
        ustId={props.ID}
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="enttanim-islem"
        singledetail="tanimlar/entegrasyon-islem"
      />
    </>
  );
};

export default PageEntegrasyonUrunList;
