import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  // Typography,
  Tooltip,
  IconButton
} from '@material-ui/core';

import {
  Search as IconSearch,
  HelpOutline as IconHelp
} from '@material-ui/icons';


import { withStyles } from '@material-ui/core/styles';
import ListSelectorSingle from '../../../components/ListSelectorSingle';
import CategorySelector from '../../../components/CategorySelector';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import ListSelectorTwoStep from '../../../components/ListSelectorTwoStep';


const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1, 1, 1, 4),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
    layoutFormItemCaption: {
      display: 'flex',
      marginBottom: 5,
      minWidth: 360,
      maxWidth: "100%",
    },
    layoutFormItemCaptionText: {
      fontStyle: 'italic'
    }
  };
};

const BilesenDetailTabUrun = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [openSelectorKategori, setOpenSelectorKategori] = useState(false);
  const [openSelectorGrup, setOpenSelectorGrup] = useState(false);
  const [openSelectorOzellikDeger, setOpenSelectorOzellikDeger] = useState(false);

  const [detayListOpen, setDetayListOpen] = useState(true);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form[name], `${name2}`) || defaultValue,
        error: _has(props.error?.[name], `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form, `${name}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    }

  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="urun_sayisi">
                    <label className={classes.layoutFormItemLabel} htmlFor="urun_sayisi">Ürün Sayısı *</label>
                    <Tooltip title={props.error?.urun_sayisi ? props.error.urun_sayisi[0] : _isNil(props.form?.urun_sayisi) ? '' : props.form.urun_sayisi}>
                      <TextField
                        {...createTextFieldProps('urun_sayisi', '')}
                        onChange={(e) => props.onChangeForm('urun_sayisi', '', e.target.value)}
                        onBlur={() => handleInputErrors('bilesen', props.form, props.formdefault, props.onCheckErrors, false, 'urun_sayisi', true, false, false, '', '', '')}
                        type="number"
                        inputProps={{
                          min: -2147483648,
                          max: 2147483647,
                          step: 1,
                          style: { textAlign: 'right' }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="bilesen_tip">
                    <label className={classes.layoutFormItemLabel} htmlFor="bilesen_tip">Bileşen Tip *</label>
                    <Tooltip title={props.error?.bilesen_tip ? props.error.bilesen_tip[0] : _isNil(props.form?.bilesen_tip) ? '' : props.urunBilesenTipDic ? props.urunBilesenTipDic[props.form.bilesen_tip] : ''}>
                      <TextField
                        {...createTextFieldProps('bilesen_tip', '')}
                        onChange={(e) => props.onChangeForm('bilesen_tip', '', e.target.value)}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        {props.urunBilesenTip.map((bt) => (
                          <option key={bt.key} value={bt.key}>{bt.value}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="satilabilen_urunler_gelsin">
                    <label className={classes.layoutFormItemLabel} htmlFor="satilabilen_urunler_gelsin">Ürün Gösterim Tip *</label>
                    <Tooltip title={props.error?.satilabilen_urunler_gelsin ? props.error.satilabilen_urunler_gelsin[0] : props.form?.bilesen_tip === true ? 'Sadece satışa açık ürünleri göster' : 'Tüm ürünleri göster'}>
                      <TextField
                        {...createTextFieldProps('satilabilen_urunler_gelsin', '')}
                        onChange={(e) => props.onChangeForm('satilabilen_urunler_gelsin', '', e.target.value)}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option key="1" value={false}>Tüm ürünleri göster</option>
                        <option key="2" value={true}>Sadece satışa açık ürünleri göster</option>
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="kategori">
                    <label className={classes.layoutFormItemLabel} htmlFor="kategori">Kategori</label>
                    <Tooltip title={props.error?.kategori ? props.error.kategori[0] : _isNil(props.form?.kategori?.parents) ? '' : props.form.kategori.parents}>
                      <TextField
                        {...createTextFieldProps('kategori', 'parents')}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button}
                              disableRipple
                              onClick={() => setOpenSelectorKategori(true)}
                            ><IconSearch /></IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="grup">
                    <label className={classes.layoutFormItemLabel} htmlFor="grup">Grup</label>
                    <Tooltip title={props.error?.grup ? props.error.grup[0] : _isNil(props.form?.grup?.tanim) ? '' : props.form.grup.tanim}>
                      <TextField
                        {...createTextFieldProps('grup', 'tanim')}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button}
                              disableRipple
                              onClick={() => setOpenSelectorGrup(true)}
                            ><IconSearch /></IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="ozellik_deger">
                    <label className={classes.layoutFormItemLabel} htmlFor="ozellik_deger">Özellik</label>
                    <Tooltip title={props.error?.ozellik_deger ? props.error.ozellik_deger[0] : _isNil(props.form?.ozellik_deger?.tanim) ? '' : props.form.ozellik_deger.tanim}>
                      <TextField
                        {...createTextFieldProps('ozellik_deger', 'tanim')}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button}
                              disableRipple
                              onClick={() => setOpenSelectorOzellikDeger(true)}
                            ><IconSearch /></IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="kategoriye_gore">
                    <label className={classes.layoutFormItemLabel} htmlFor="kategoriye_gore">Kategoriye Göre Getir</label>
                    <Checkbox
                      name="kategoriye_gore"
                      color="primary"
                      checked={_result(props.form, 'kategoriye_gore', false)}
                      onChange={(e, checked) => props.onChangeForm('kategoriye_gore', '', checked)}
                    ></Checkbox>
                    <IconButton
                      size="small"
                      className={classes.button2}
                      disableRipple
                      onClick={() => {
                        setHelpText('“Ürünleri katalog sayfasında bulunduğu kategoriden getirmek için bu alanı seçebilirsiniz. Diğer sayfalar için belirttiğiniz kriterler ile çalışır.”');
                        setHelpOpen(true)
                      }}
                    ><IconHelp /></IconButton>
                  </li>

                  <li className={classes.layoutFormItem} id="ozellikdegere_gore">
                    <label className={classes.layoutFormItemLabel} htmlFor="ozellikdegere_gore">Özelliğe Göre Getir</label>
                    <Checkbox
                      name="ozellikdegere_gore"
                      color="primary"
                      checked={_result(props.form, 'ozellikdegere_gore', false)}
                      onChange={(e, checked) => props.onChangeForm('ozellikdegere_gore', '', checked)}
                    ></Checkbox>
                    <IconButton
                      size="small"
                      className={classes.button2}
                      disableRipple
                      onClick={() => {
                        setHelpText('“Ürünleri özellik sayfasında bulunduğu özellik değerinden getirmek için bu alanı seçebilirsiniz. Diğer sayfalar için belirttiğiniz kriterler ile çalışır.”');
                        setHelpOpen(true)
                      }}
                    ><IconHelp /></IconButton>
                  </li>

                  <li className={classes.layoutFormItem} id="karisik_siralama">
                    <label className={classes.layoutFormItemLabel} htmlFor="karisik_siralama">Ürünleri Karışık Getir</label>
                    <Checkbox
                      name="karisik_siralama"
                      color="primary"
                      checked={_result(props.form, 'karisik_siralama', false)}
                      onChange={(e, checked) => props.onChangeForm('karisik_siralama', '', checked)}
                    ></Checkbox>
                  </li>

                </ul>
                <ul className={classes.layoutFormList}>

                  <li className={classes.layoutFormItem} id="show_item_count">
                    <label className={classes.layoutFormItemLabel} htmlFor="show_item_count">Öğe Sayısı</label>
                    <Tooltip title={props.error?.show_item_count ? props.error.show_item_count[0] : _isNil(props.form?.ayarlar?.show_item_count) ? '' : props.form.ayarlar.show_item_count}>
                      <TextField
                        {...createTextFieldProps('ayarlar', 'show_item_count')}
                        onChange={(e) => props.onChangeForm('ayarlar', 'show_item_count', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, false, 'ayarlar', true, false, false, '', '', '', 'show_item_count')}
                        type="number"
                        inputProps={{
                          step: 1,
                          style: { textAlign: "right" }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="vertical_horizontal">
                    <label className={classes.layoutFormItemLabel} htmlFor="vertical_horizontal">Dikey / Yatay</label>
                    <Tooltip title={props.error?.vertical_horizontal ? props.error.vertical_horizontal[0] : (props.form?.ayarlar?.vertical_horizontal === '0' || _isNil(props.form?.ayarlar?.vertical_horizontal)) ? 'Dikey' : 'Yatay'}>
                      <TextField
                        {...createTextFieldProps('ayarlar', 'vertical_horizontal')}
                        onChange={(e) => props.onChangeForm('ayarlar', 'vertical_horizontal', e.target.value)}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option key='0' value='0'>Dikey</option>
                        <option key='1' value='1'>Yatay</option>
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="display_type">
                    <label className={classes.layoutFormItemLabel} htmlFor="display_type">Gösterim Tipi</label>
                    <Tooltip title={props.error?.display_type ? props.error.display_type[0] : (props.form?.ayarlar?.display_type === '0' || _isNil(props.form?.ayarlar?.display_type)) ? 'Sabit' : 'Hareketli'}>
                      <TextField
                        {...createTextFieldProps('ayarlar', 'display_type')}
                        onChange={(e) => props.onChangeForm('ayarlar', 'display_type', e.target.value)}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option key='0' value='0'>Sabit</option>
                        <option key='1' value='1'>Hareketli</option>
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="wait_time">
                    <label className={classes.layoutFormItemLabel} htmlFor="wait_time">Bekleme Süresi (ms)</label>
                    <Tooltip title={props.error?.wait_time ? props.error.wait_time[0] : _isNil(props.form?.ayarlar?.wait_time) ? '' : props.form.ayarlar.wait_time}>
                      <TextField
                        {...createTextFieldProps('ayarlar', 'wait_time')}
                        onChange={(e) => props.onChangeForm('ayarlar', 'wait_time', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, false, 'ayarlar', true, false, false, '', '', '', 'wait_time')}
                        type="number"
                        inputProps={{
                          step: 1,
                          style: { textAlign: "right" }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="auto_slide">
                    <label className={classes.layoutFormItemLabel} htmlFor="auto_slide">Otomatik Kayma</label>
                    <Checkbox
                      name="auto_slide"
                      color="primary"
                      checked={_result(props.form?.ayarlar, 'auto_slide', false)}
                      onChange={(e, checked) => props.onChangeForm('ayarlar', 'auto_slide', checked)}
                    ></Checkbox>
                  </li>

                </ul>
              </div>

              {openSelectorOzellikDeger &&
                <Dialog
                  open={openSelectorOzellikDeger}
                  PaperProps={{
                    style: {
                      background: 'transparent',
                      height: 600,
                      width: detayListOpen ? '100%' : 480
                    },
                  }}
                  fullWidth
                  maxWidth={detayListOpen ? 'md' : 'sm'}
                  onClose={() => {
                    setOpenSelectorOzellikDeger(false);
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, false, 'ozellik_deger', true, false, true, 'Kategori,Grup ve Özellik alanlarından en az biri seçilmelidir.', '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorTwoStep
                      title="Özellikler"
                      mainItemsPath='ozellikbaslik'
                      mainItemsCountPath='ozellikbaslik/count'
                      mainDefaultItems={props.form.ozellik_baslik}
                      mainMultiple={false}
                      mainKeyProp='id'
                      mainValueProp='tanim'
                      listPath='ozellikdeger'
                      listCountPath='ozellikdeger/count'
                      listMultiple={false}
                      keyProp='id'
                      valueProp='tanim'
                      keyUId='ozellik_baslik_id'
                      valueUV='ozellik_baslik_tanim'
                      defaultItems={props.form.ozellik_deger}
                      onChangeItems={(items) => props.onChangeForm('ozellik_deger', '', items)}
                      onClose={() => {
                        setOpenSelectorOzellikDeger(false);
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, false, 'ozellik_deger', true, false, true, 'Kategori,Grup ve Özellik alanlarından en az biri seçilmelidir.', '', '');
                      }}
                      onDetayListClose={(res) => setDetayListOpen(res)}
                      detayListOpen={detayListOpen}
                      detayList={props.form.treeList}
                      url="tanimlar/ozellikdetay"
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorKategori &&
                <Dialog
                  open={openSelectorKategori}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorKategori(false);
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, false, 'kategori', true, false, true, 'Kategori,Grup ve Özellik alanlarından en az biri seçilmelidir.', '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <CategorySelector
                      selected={props.form.kategori}
                      onChange={(items) => props.onChangeForm('kategori', '', items)}
                      onClose={() => {
                        setOpenSelectorKategori(false);
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, false, 'kategori', true, false, true, 'Kategori,Grup ve Özellik alanlarından en az biri seçilmelidir.', '', '');
                      }}
                      single={true}
                      parentselect={false}
                    />
                  </DialogContent>
                </Dialog>
              }

              {openSelectorGrup &&
                <Dialog
                  open={openSelectorGrup}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorGrup(false);
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, false, 'grup', true, false, true, 'Kategori,Grup ve Özellik alanlarından en az biri seçilmelidir.', '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Gruplar'
                      listPath='grup/mini_list?aktif=true'
                      listCountPath='grup/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.grup}
                      onChangeItems={(items) => props.onChangeForm('grup', '', items)}
                      onClose={() => {
                        setOpenSelectorGrup(false);
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, false, 'grup', true, false, true, 'Kategori,Grup ve Özellik alanlarından en az biri seçilmelidir.', '', '');
                      }}
                      newPath='tanimlar/gruplar'
                    />
                  </DialogContent>
                </Dialog>
              }
              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }

            </>
          )}
        </>
      }
    </>
  );
});

export default BilesenDetailTabUrun;
