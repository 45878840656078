import React, { useState, useLayoutEffect } from 'react';
import django from '../../../../api/django';
import slugify from 'slugify';
import Layout from '../../../base/LayoutTableDetailInside';
import Detail from './UrunDetail';


const COLUMNS_DEFAULT = [
  { id: 'tanim', tableProp: 'tanim', filterProp: 'tanim', label: 'Tanım', element: 'input', type: 'text', arrayTableProp: null, show: true, sortable: true, highlightable: true, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null, selectKey: null, selectValue: null },
  { id: 'kod', tableProp: 'kod', filterProp: 'kod', label: 'Kod', element: 'input', type: 'text', arrayTableProp: null, show: true, sortable: true, highlightable: true, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null, selectKey: null, selectValue: null },
  { id: 'barkod', tableProp: 'barkod', filterProp: 'barkod', label: 'Barkod', element: 'input', type: 'text', arrayTableProp: null, show: true, sortable: true, highlightable: true, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null, selectKey: null, selectValue: null },
  { id: 'uretici_kodu', tableProp: 'uretici_kodu', filterProp: 'uretici_kodu', label: 'Üretici Kodu', element: 'input', type: 'text', arrayTableProp: null, show: true, sortable: true, highlightable: true, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null, selectKey: null, selectValue: null },
  { id: 'desi', tableProp: 'desi', filterProp: 'desi', label: 'Desi', element: 'input', type: 'number', arrayTableProp: null, show: false, sortable: true, highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null, selectKey: null, selectValue: null },
  { id: 'kategoriler', tableProp: 'kategoriler', filterProp: 'kategoriler.kategoritemp.id', label: 'Kategoriler', element: 'select-multiple', type: 'array', arrayTableProp: 'tanim', show: true, sortable: true, highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim', multipleSelectOptionViewProp: 'tanim', shortDescription: null, selectKey: null, selectValue: null, isEnt: true },
  { id: 'gruplar', tableProp: 'gruplar', filterProp: 'gruplar.gruptemp.id', label: 'Ürün Grupları', element: 'select-multiple', type: 'array', arrayTableProp: 'tanim', show: true, sortable: true, highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'enttanim-gruptemp', multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim', multipleSelectOptionViewProp: 'tanim', shortDescription: null, selectKey: null, selectValue: null, newpath: "tanimlar/entegrasyon-eslestirme" },
  { id: 'urun', tableProp: 'urun.tanim', filterProp: 'urun.id', label: 'Panel Ürün', element: 'select-multiple', type: 'number', arrayTableProp: null, show: true, sortable: true, highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'urun', multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim', multipleSelectOptionViewProp: 'tanim', shortDescription: null, selectKey: null, selectValue: null, newpath: "tanimlar/urun" },
  { id: 'fiyati_isle', tableProp: 'fiyati_isle', filterProp: 'fiyati_isle', label: 'Fiyat Entegrasyondan Gelsin', element: 'select', type: 'boolean', arrayTableProp: null, show: true, sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null, selectKey: 'key', selectValue: 'value' },
];

const PageEntegrasyonUrunList = (props) => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        if (column.id === 'kategoriler') {
          return {
            ...column,
            multipleSelectOptionsPath: `enttanim/${props.ID}/ent-kategoritemp`
          }
        }
        return column;
      });
    });
  }, [props.ID]);


  useLayoutEffect(() => {
    const columnDefaultProperties = {
      id: 'ozellikler',
      tableProp: 'ozellikler.ozellikAdi',
      filterProp: 'ozellikler.ozellikAdi',
      label: 'Özellik',
      element: 'select-multiple',
      type: 'array',
      arrayTableProp: null,
      show: false,
      sortable: false,
      highlightable: false,
      alignment: 0,
      width: 1,
      selectOptions: [],
      multipleSelectOptionsPath: null,
      multipleSelectOptionFilterProp: null,
      multipleSelectOptionSortProp: 'tanim',
      multipleSelectOptionViewProp: 'tanim',
      shortDescription: null,
      newpath: 'tanimlar/entegrasyon-eslestirme',
      ustId: "null"
    };

    const setList = async (page) => {
      await django('enttanim-ozellikbaslik/mini_list', { params: { page: page, size: 100, sort: 'tanim' } }).then(({ data }) => {
        const newColumns = [];
        data.forEach(({ id, tanim }) => {
          const columnIdSlug = slugify(tanim, {
            lower: true,
            strict: false,
            locale: 'tr',
          });
          newColumns.push({
            ...columnDefaultProperties,
            id: `ozellikler-${columnIdSlug}-${id}`,
            tableProp: `ozellikler.${tanim.replace(/\s/g, '')}`,
            filterProp: `ozellikler.${tanim.replace(/\s/g, '')}`,
            label: tanim,
            multipleSelectOptionsPath: `enttanim-ozellikbaslik/${id}/ozellikdeger`,
            multipleSelectOptionFilterProp: 'tanim',
            ustId: id
          });
        });
        setColumnsDefault((columnsDefault) => {
          let newColumnsFiltered = newColumns.filter((c) => !columnsDefault.some((c2) => c.id === c2.id));
          if (page === 1) {
            newColumnsFiltered = newColumnsFiltered.map((colrow) => ({ ...colrow, show: true }))
          }
          return [...columnsDefault, ...newColumnsFiltered];
        });
        if (data.length === 100) {
          setList(page + 1);
        }
      });
    }
    setList(1);
  }, []);

  return (
    <>
      <Layout
        title="ENTEGRASYON ÜRÜNLER"
        path={props.ID ? `enttanim/${props.ID}/urun` : ""}
        ustId={props.ID}
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="enttanim-urun"
        singledetail="tanimlar/entegrasyon-urun"
        filtreButtonShow={props.ID ? true : false}
        manuelIslem={true}
        filter={true}
        filterProps={{
          title: 'Filtreler',
          deleyeM: 'Fitre siliniyor lütfen bekleyin',
          deleyeM2: 'Bu filtreyi gerçekten silmek istiyor musunuz?',
          listPath: `enttanim/${props.ID}/satirkosul`,
          listCountPath: `enttanim/${props.ID}/satirkosul/count`,
          valueProp: 'tanim',
          editablePropList: [
            { key: "tanim", type: "text", label: "Filtre Tanım", required: true, propname: 'Filtre Tanım', pagename: 'filtre', },
            { key: "aktar", type: "bool", label: "Aktar", required: false },
            { key: "kosul", type: "kosul", label: "Koşul", required: false },
          ],
          filterList: [
            { key: 'tanim', title: 'Tanım', url: null, type: 'text' },
            { key: 'kod', title: 'Kod', url: null, type: 'text' },
            { key: 'barkod', title: 'Barkod', url: null, type: 'text' },
            { key: 'uretici_kodu', title: 'Üretici Kodu', url: null, type: 'text' },
            { key: 'desi', title: 'Desi', url: null, type: 'text' },
            { key: 'kategoriler', title: 'Kategoriler', url: `enttanim/${props.ID}/ent-kategoritemp`, type: 'entcat', valueKey: "tanim" },
            { key: 'gruplar', title: 'Ürün Grupları', url: `enttanim-gruptemp/mini_list`, type: 'array', valueKey: "tanim" },
            { key: 'urun', title: 'Panel Ürün', url: `urun/mini_list`, type: 'array', valueKey: "tanim" },
            { key: 'fiyati_isle', title: 'Fiyat Entegrasyondan Gelsin', url: null, type: 'bool' },
            { key: 'ozellikler', title: 'Özellikler', url: null, type: 'ozellik' },
          ]
        }}
      />
    </>
  );
};

export default PageEntegrasyonUrunList;
