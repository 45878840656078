import React from 'react';
import { useParams } from 'react-router-dom';

import IslemDetail from './IslemDetail';

const PageIslemDetail = () => {
  const params = useParams();

  return (
    <IslemDetail
      id={params?.id ? params.id === "yeni" ? null : params.id : params.id}
      path={`enttanim/${params?.ustId}/islem`}
      single={true}
    />
  );
};

export default PageIslemDetail;