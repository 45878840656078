import React, { useMemo } from 'react';

import {
  has as _has,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import {
  Add as IconAdd,
  Delete as IconDelete,
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import { Hata } from '../../base/Hata';

const styles = ({ spacing, palette }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    tablehead: {
      display: 'flex',
      borderBottom: '1px solid #976797',
      marginBottom: spacing(0.5)
    },
    tablebody: {
      display: 'flex',
      flexDirection: 'column',
    },
    tablerow: {
      display: 'flex',
      padding: spacing(0.5, 1, 0.5, 1),
      alignItems: "center"
    },
    tablecell: {
      display: 'flex',
      padding: spacing(0, 0.5, 0, 0.5),
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    }

  };
};

const UrunEkOzellik = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const LISTE = useMemo(() => props.forms, [props.forms]);

  const handleChangeForm = (index, index2, value) => {
    props.onChangeForm(index, index2, value);
  };

  const handleDeleteRow = (index) => {
    const isHas = _has(LISTE[index].form, "id");
    if (isHas) {
      props.setDeleteId(LISTE[index].form.id);
      props.openDeleteForm(true);
    } else {
      props.deleteDetailWithIndex(index);
    }
  }


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>

                <div className={classes.table}>
                  <div className={classes.tablehead}>
                    <div className={classes.tablerow}>
                      <div className={classes.tablecell}>
                        <Tooltip title="Ek Özellik Ekle">
                          <IconButton
                            size="small"
                            disableRipple
                            onClick={() => props.onAddNewRow()}
                          >
                            <IconAdd />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className={classes.tablecell}>Ek Özellikler</div>
                    </div>
                  </div>
                  <div className={classes.tablebody}>
                    {LISTE && LISTE.map((oz, index) => (
                      <div className={classes.tablerow} key={`ekoz-${index}`}>
                        <div className={classes.tablecell}>
                          <Tooltip title="Sil">
                            <IconButton
                              size="small"
                              disableRipple
                              onClick={() => handleDeleteRow(index)}
                            >
                              <IconDelete />
                            </IconButton>
                          </Tooltip>
                        </div>
                        {oz.form.diller.map((dil, index2) =>
                          <div key={`secenek-${index}-${index2}`} className={classes.tablecell}>
                            <Tooltip title={_isNil(dil?.form?.tanim) ? "" : dil.form.tanim}>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                value={dil.form.tanim}
                                onChange={(e) => handleChangeForm(index, index2, e.target.value)}
                                label={dil.dil.tanim}
                                InputLabelProps={{ shrink: true }}
                              ></TextField>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

              </div>

            </>
          )}
        </>
      }
    </>
  );
});

export default UrunEkOzellik;
