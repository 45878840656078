import React, { useCallback, useMemo, useState, useEffect, createRef } from 'react';
import {
  has as _has,
  result as _result
} from 'lodash';

import immer from 'immer';

import moment from 'moment';

import ListSelectorSingle from '../../../components/ListSelectorSingle';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import { List, AutoSizer } from 'react-virtualized';
import { turkishToLower } from '../../../helpers/helpers';
import SearchListDrop from '../../../components/SearchListDrop';

import {
  TextField,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  CircularProgress,
  Typography,
} from '@material-ui/core';

import {
  Add as IconAdd,
  Delete as IconDelete,
  ArrowDropDown as IconArrowDropDown,
  ArrowDropUp as IconArrowDropUp,
  Search as IconSearch,
  Close as IconClose,
  Check as IconCheck,
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';


const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: "100%",
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    layoutContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
      whiteSpace: 'nowrap',
      width: 'max-content',
      minWidth: "100%",
    },
    borderLayout: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      width: 'max-content',
      minWidth: "100%",
      padding: spacing(1),
      border: "1px solid",
      borderColor: palette.primary.main,
      borderRadius: 4,
      height: '100%'
    },
    borderLayout2: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      width: '100%',
      padding: spacing(1),
      border: "1px solid",
      borderColor: palette.primary.main,
      borderRadius: 4,
      height: 'fit-content'
    },
    detailOpen: {
      position: 'absolute',
      left: 0,
      background: "transparent",
      '& .MuiIconButton-root': {
        color: palette.primary.main
      }
    },
    detailClose: {
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      background: "transparent",
      '& .MuiIconButton-root': {
        color: palette.primary.main
      }
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      position: 'relative',
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button3: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      fontSize: '0.75rem',
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    formListTable: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: "relative"
    },
    tableHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 35,
      borderBottom: `1px solid ${palette.divider}`,
    },
    tableHeaderItem: {
      marginRight: 5,
      fontWeight: 500,
      textAlign: "right",
      width: 120,
      whiteSpace: "normal"
    },
    tableFormRow: {
      borderBottom: `1px solid ${palette.divider}`,
      height: 35,
      display: "flex",
      alignItems: 'center',
      '&:hover': {
        backgroundColor: palette.action.hover,
      },
      '&:last-child': {
        borderBottomWidth: 0,
      }
    },
    tableFormItem: {
      marginRight: 5,
      width: 120,
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    selectedRow: {
      border: '1px solid #eacfea !important',
    },
  }
};

const DepoCikisDetailTab_Genel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hataGenel, [props.hataGenel]);
  const LOADINGDETAY = useMemo(() => props.loadingdetay, [props.loadingdetay]);
  const HATADETAY = useMemo(() => props.hataDetay, [props.hataDetay]);
  const add = useMemo(() => props.add, [props.add]);


  const DETAYLISTE = useMemo(() => props?.detay.length > 0 ? props.detay : [], [props]);
  const LISTELENGTH = useMemo(() => props?.detay.length > 0 ? props.detay.length : 0, [props]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowSearch, setSelectedRowSearch] = useState(null);
  const [lastRow, setLastRow] = useState(null);
  const [blockedAdd, setBlockedAdd] = useState(true);

  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);
  const [openSelectorUrun, setOpenSelectorUrun] = useState(false);
  const [openSelectorDepo, setOpenSelectorDepo] = useState(false);

  const [topOpen, setTopOpen] = useState(true);
  const [scrollNew, setScrollNew] = useState(0);

  const [filtredList, setFiltredList] = useState([]);
  const [nameSearchOpen, setNameSearchOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [queryName, setQueryName] = useState("");

  const [searchItems, setSearchItems] = useState([]);

  const elementRef = DETAYLISTE.map(() => createRef());
  const inputRef = DETAYLISTE.map(() => createRef());

  useEffect(() => {
    if (props.form) {
      if (!props.form.magaza) {
        setBlockedAdd(true);
      } else {
        if (nameSearchOpen) {
          setBlockedAdd(true);
        } else {
          if (LOADINGDETAY) {
            setBlockedAdd(true);
          } else {
            if (LISTELENGTH > 0) {
              if (lastRow !== null) {
                if (LISTELENGTH > lastRow) {
                  if (DETAYLISTE[lastRow]?.urun?.id && DETAYLISTE[lastRow]?.depo?.id && DETAYLISTE[lastRow]?.mikar !== '') {
                    setBlockedAdd(false);
                  } else {
                    setBlockedAdd(true);
                  }
                } else {
                  setLastRow(null);
                  setBlockedAdd(false);
                }
              } else {
                setBlockedAdd(false);
              }
            } else {
              setBlockedAdd(false);
            }
          }
        }
      }
    }
  }, [props.form, lastRow, LOADINGDETAY, LISTELENGTH, DETAYLISTE, nameSearchOpen]);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    switch (name) {
      case 'magaza':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form[name], `${name2}`, defaultValue),
          error: _has(props.error?.evrak, `${name}`),
          fullWidth: true,
        };

      default:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form, `${name}`) || defaultValue,
          error: _has(props.error?.evrak, `${name}`),
          fullWidth: true,
        };
    }
  }, [props.error, props.form]);

  const createTextFieldProps2 = useCallback((index, name, name2, d, defaultValue = '') => {
    switch (name) {
      case 'urun':
      case 'depo':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(d[name], `${name2}`) || defaultValue,
          error: _has(props.error?.giriscikis_detay?.[index], `${name}`),
          fullWidth: true,
        };

      default:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(d, `${name}`) || defaultValue,
          error: _has(props.error?.giriscikis_detay?.[index], `${name}`),
          fullWidth: true,
        };
    }
  }, [props.error]);


  const handleAddNewRow = () => {
    props.addDetay();
    const index = LISTELENGTH;
    setSelectedRow(index);
    setLastRow(index);
    setScrollNew(index);
  }

  const handleDeleteRow = (di) => {
    const index = selectedRow;
    const length = LISTELENGTH;
    const deleteRow = async () => {
      if (nameSearchOpen) {
        const sindex = filtredList.findIndex(x => x.index === di);
        setFiltredList((prev) => immer(prev, (next) => {
          next.splice(sindex, 1);
        }));
      }
      props.deleteRow(di)
    }
    const setRows = () => {
      if (length > 1) {
        setSelectedRow(index - 1);
        setLastRow(length - 2);
      } else if (length === 1) {
        setSelectedRow(null);
        setLastRow(null);
      } else {
        setSelectedRow(null);
        setLastRow(null);
      }
    }
    deleteRow().then(() => setRows())
  }

  const handleErrors = (errors) => {
    props.onCheckErrors(errors, null);
  }
  const handleErrors2 = (errors) => {
    props.onCheckErrors(errors, selectedRow, DETAYLISTE.length)
  }

  const searchList = (tanim = queryName) => {
    let filter = [];
    const mainList = [...DETAYLISTE];
    if (tanim !== '') {
      setOpenFilter(true);
      filter = mainList.filter((x) => x.urun && (turkishToLower(x.urun.tanim).includes(turkishToLower(tanim))))
    }
    if (tanim === '') {
      setOpenFilter(false);
      filter = [];
    }
    setFiltredList(filter);
  }

  const handleSearch = (index, val) => {
    let arr = [...searchItems];
    arr[index] = val;
    setSearchItems(arr);
  }

  const rowRenderer = ({ index, key, style }) => {
    const item = DETAYLISTE[index];
    return (
      <div key={key} style={style} className={`${classes.tableFormRow} ${selectedRow === index ? classes.selectedRow : ''}`} onClick={() => {
        setSelectedRow(index);
        if (lastRow === null) {
          setLastRow(index)
        }
      }}>
        <div className="flex justify-start items-center w-max">
          <div className={classes.tableFormItem} style={{ width: 25 }}>
            <Tooltip title="Sil">
              <IconButton
                size="small"
                disableRipple
                onClick={() => handleDeleteRow(index)}
              >
                <IconDelete />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ position: "relative", width: 200 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={searchItems[index] ? '' : props.error?.giriscikis_detay?.[index]?.urun ? props.error.giriscikis_detay[index].urun[0] : item.urun == null ? '' : item.urun.tanim}>
                <TextField
                  {...createTextFieldProps2(index, 'urun', 'tanim', item)}
                  onChange={(e) => {
                    props.onChangeFormDetay(index, 'urun', e.target.value, true)
                    handleSearch(index, e.target.value)
                  }}
                  InputProps={{
                    endAdornment:
                      <IconButton
                        size="small"
                        className={classes.button}
                        disableRipple
                        onClick={() => setOpenSelectorUrun(true)}
                      ><IconSearch /></IconButton>
                  }}
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if (e.keyCode === 40 && searchItems[index]) {
                      const el = elementRef[index].current
                      if (el) {
                        elementRef[index].current.focus()
                      }
                    }
                  }}
                  inputRef={inputRef[index]}
                ></TextField>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.miktar ? props.error.urun[index].miktar[0] : item?.miktar == null ? '' : item.miktar}>
                <TextField
                  {...createTextFieldProps2(index, 'miktar', '', item)}
                  onChange={(e) => props.onChangeFormDetay(index, 'miktar', e.target.value, false)}
                  onBlur={() => handleInputErrors('evrak', item, item, handleErrors2, add, 'miktar', true, false, false, '', '', '')}
                  type="number"
                  inputProps={{
                    min: 0,
                    style: { textAlign: 'right' }
                  }}
                ></TextField>
              </Tooltip>
            </div>
          </div>
          <div style={{ width: 200 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.giriscikis_detay?.[index]?.depo ? props.error.giriscikis_detay[index].depo[0] : item.depo == null ? '' : item.depo.tanim}>
                <TextField
                  {...createTextFieldProps2(index, 'depo', 'tanim', item)}
                  InputProps={{
                    endAdornment:
                      <IconButton
                        size="small"
                        className={classes.button}
                        disableRipple
                        onClick={() => setOpenSelectorDepo(true)}
                      ><IconSearch /></IconButton>
                  }}
                ></TextField>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const rowRendererSearch = ({ index, key, style }) => {
    const searchitem = filtredList[index];
    const item = DETAYLISTE.find(x => x.index === searchitem.index);
    return (
      <div key={key} style={style} className={`${classes.tableFormRow} ${selectedRow === index ? classes.selectedRow : ''}`} onClick={() => {
        setSelectedRowSearch(index);
        setSelectedRow(item.index);
        if (lastRow === null) {
          setLastRow(item.index)
        }
      }}>
        <div className="flex justify-start items-center w-max">
          <div className={classes.tableFormItem} style={{ width: 25 }}>
            <Tooltip title="Sil">
              <IconButton
                size="small"
                disableRipple
                onClick={() => handleDeleteRow(item.index)}
              >
                <IconDelete />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ position: "relative", width: 200 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.giriscikis_detay?.[item.index]?.urun ? props.error.giriscikis_detay[item.index].urun[0] : item.urun == null ? '' : item.urun.tanim}>
                <TextField
                  {...createTextFieldProps2(item.index, 'urun', 'tanim', item)}
                  onChange={(e) => {
                    props.onChangeFormDetay(item.index, 'urun', e.target.value, true)
                    handleSearch(item.index, e.target.value)
                  }}
                  InputProps={{
                    endAdornment:
                      <IconButton
                        size="small"
                        className={classes.button}
                        disableRipple
                        onClick={() => setOpenSelectorUrun(true)}
                      ><IconSearch /></IconButton>
                  }}
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if (e.keyCode === 40 && searchItems[item.index]) {
                      const el = elementRef[item.index].current
                      if (el) {
                        elementRef[item.index].current.focus()
                      }
                    }
                  }}
                  inputRef={inputRef[item.index]}
                ></TextField>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.miktar ? props.error.urun[item.index].miktar[0] : item?.miktar == null ? '' : item.miktar}>
                <TextField
                  {...createTextFieldProps2(item.index, 'miktar', '', item)}
                  onChange={(e) => props.onChangeFormDetay(item.index, 'miktar', e.target.value, false)}
                  onBlur={() => handleInputErrors('evrak', item, item, handleErrors2, add, 'miktar', true, false, false, '', '', '')}
                  type="number"
                  inputProps={{
                    min: 0,
                    style: { textAlign: 'right' }
                  }}
                ></TextField>
              </Tooltip>
            </div>
          </div>
          <div style={{ width: 200 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.giriscikis_detay?.[item.index]?.depo ? props.error.giriscikis_detay[item.index].depo[0] : item.depo == null ? '' : item.depo.tanim}>
                <TextField
                  {...createTextFieldProps2(item.index, 'depo', 'tanim', item)}
                  InputProps={{
                    endAdornment:
                      <IconButton
                        size="small"
                        className={classes.button}
                        disableRipple
                        onClick={() => setOpenSelectorDepo(true)}
                      ><IconSearch /></IconButton>
                  }}
                ></TextField>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <div className={classes.layoutContainer}>
                <div className={classes.borderLayout}>
                  {topOpen && (
                    <>
                      <ul className={classes.layoutFormList}>
                        <li className={classes.layoutFormItem} id="magaza">
                          <label className={classes.layoutFormItemLabel} htmlFor="magaza">Mağaza *</label>
                          <Tooltip title={props.error?.evrak?.magaza ? props.error.evrak.magaza[0] : props.form?.magaza == null ? '' : props.form.magaza.tanim}>
                            <TextField
                              {...createTextFieldProps('magaza', 'tanim')}
                              InputProps={{
                                endAdornment:
                                  <IconButton
                                    size="small"
                                    className={classes.button}
                                    disableRipple
                                    onClick={() => setOpenSelectorMagaza(true)}
                                  ><IconSearch /></IconButton>
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="versiyon">
                          <label className={classes.layoutFormItemLabel} htmlFor="versiyon">Versiyon</label>
                          <Tooltip title={props.error?.evrak?.versiyon ? props.error.evrak.versiyon[0] : props.form?.versiyon == null ? '' : props.form.versiyon}>
                            <TextField
                              {...createTextFieldProps('versiyon', '')}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="kodu">
                          <label className={classes.layoutFormItemLabel} htmlFor="kodu">Evrak No *</label>
                          <Tooltip title={props.error?.evrak?.kodu ? props.error.evrak.kodu[0] : props.form?.kodu == null ? '' : props.form.kodu}>
                            <TextField
                              {...createTextFieldProps('kodu', '')}
                              onChange={(e) => props.onChangeForm('kodu', e.target.value)}
                              onBlur={() => handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'kodu', true, true, false, '', 'Evrak No', 'evrak')}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="evrak_tarihi">
                          <label className={classes.layoutFormItemLabel} htmlFor="evrak_tarihi">Evrak Tarihi *</label>
                          <Tooltip title={props.error?.evrak?.evrak_tarihi ? props.error.evrak.evrak_tarihi[0] : props.form?.evrak_tarihi == null ? '' : moment(props.form.evrak_tarihi).format('DD/MM/YYYY, HH.mm')}>
                            <TextField
                              {...createTextFieldProps('evrak_tarihi', '')}
                              type="datetime-local"
                              onChange={(e) => props.onChangeForm('evrak_tarihi', e.target.value)}
                              onBlur={() => handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'evrak_tarihi', true, false, '', '')}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="tip">
                          <label className={classes.layoutFormItemLabel} htmlFor="tip">Evrak Tipi *</label>
                          <Tooltip title={props.error?.evrak?.tip ? props.error.evrak.tip[0] : 'Depo Çıkış'}>
                            <TextField
                              {...createTextFieldProps('tip', '')}
                              onChange={(e) => props.onChangeForm('tip', e.target.value)}
                              select
                              SelectProps={{
                                native: true,
                              }}
                            >
                              <option key='14' value='14'>Depo Çıkış</option>
                            </TextField>
                          </Tooltip>
                        </li>
                      </ul>

                      <ul className={classes.layoutFormList}>
                        <li className={classes.layoutFormItem} id="aciklama" style={{ height: "auto" }}>
                          <label className={classes.layoutFormItemLabel} htmlFor="aciklama">Evrak Not</label>
                          <Tooltip title={props.error?.evrak?.aciklama ? props.error.evrak.aciklama[0] : props.form?.aciklama == null ? '' : props.form.aciklama}>
                            <TextField
                              {...createTextFieldProps('aciklama', '')}
                              multiline
                              rows={3}
                              onChange={(e) => props.onChangeForm('aciklama', e.target.value)}
                            ></TextField>
                          </Tooltip>
                        </li>
                      </ul>
                    </>
                  )}
                  {!topOpen && (
                    <div>
                      <span className="font-medium truncate text-base mr-10">Evrak Tarihi : {_result(props.form, 'evrak_tarihi') || ''}</span>
                      <span className="font-medium truncate text-base mr-10">Evrak No : {_result(props.form, 'kodu') || ''}</span>
                      <span className="font-medium truncate text-base mr-10">Mağaza : {_result(props.form?.magaza, 'tanim') || ''}</span>
                    </div>
                  )}
                </div>
                <div className={topOpen ? classes.detailOpen : classes.detailClose}>
                  <IconButton
                    style={{ width: "1rem", height: "1rem", padding: 0 }}
                    disableRipple
                    onClick={() => setTopOpen(!topOpen)}
                  >
                    {topOpen ? <IconArrowDropUp style={{ fontSize: "2rem" }} /> : <IconArrowDropDown style={{ fontSize: "2rem" }} />}
                  </IconButton>
                </div>
              </div>

              {!LOADINGDETAY && (
                <>
                  {HATADETAY && (
                    <Hata />
                  )}
                  {!HATADETAY && (
                    // <div className={classes.layoutContainer} style={{ height: tableRef?.current ? (tableRef.current?.offsetParent.scrollHeight < (LISTELENGTH * 35 + 70) ? '100%' : (LISTELENGTH * 35 + 70)) : '100%', minHeight: tableRef?.current ? (tableRef.current?.offsetParent.scrollHeight < (LISTELENGTH * 35 + 70) ? '100%' : (LISTELENGTH * 35 + 70)) : '100%' }}>

                    <div className={classes.layoutContainer} style={{ height: (LISTELENGTH * 35 + 70) }}>
                      <div className={classes.borderLayout}>
                        <div className={classes.formListTable} >
                          <div className={classes.tableHeader}>
                            <div className={classes.tableHeaderItem} style={{ width: 25 }}>
                              <IconButton
                                size="small"
                                disabled={blockedAdd}
                                disableRipple
                                onClick={() => handleAddNewRow()}
                              >
                                <IconAdd />
                              </IconButton>
                            </div>
                            {!nameSearchOpen && (
                              <div className={classes.tableHeaderItem} style={{ width: 200, textAlign: "left", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <span>ÜRÜN ADI</span>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  onClick={() => setNameSearchOpen(true)}
                                >
                                  <IconSearch />
                                </IconButton>
                              </div>
                            )}
                            {nameSearchOpen && (
                              <div className={classes.tableHeaderItem} style={{ width: 200, textAlign: "left", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <TextField
                                  variant="outlined"
                                  size='small'
                                  fullWidth={true}
                                  label="ÜRÜN ADI"
                                  value={queryName}
                                  onChange={(e) => setQueryName(e.target.value)}
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    endAdornment:
                                      <IconButton
                                        size="small"
                                        disableRipple
                                        onClick={() => searchList()}
                                      >
                                        <IconCheck />
                                      </IconButton>
                                  }}
                                ></TextField>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  onClick={() => {
                                    setNameSearchOpen(false);
                                    setQueryName("");
                                    searchList('');
                                  }}
                                >
                                  <IconClose />
                                </IconButton>
                              </div>
                            )}
                            <div className={classes.tableHeaderItem}>MİKTAR</div>
                            <div style={{ width: 200 }} className={classes.tableHeaderItem}>DEPO</div>
                          </div>
                          {(!openFilter) && (
                            <>
                              <div style={{ flex: '1 1 auto' }}>
                                <AutoSizer>
                                  {({ height, width }) => (
                                    <List
                                      height={height}
                                      rowCount={LISTELENGTH}
                                      rowHeight={35}
                                      rowRenderer={rowRenderer}
                                      width={width}
                                      scrollToIndex={scrollNew}
                                    />
                                  )}
                                </AutoSizer>
                              </div>
                              {searchItems[selectedRow] && (
                                <div style={{ width: 300, height: 300, position: "absolute", left: 0, top: ((selectedRow + 2) * 35), zIndex: 100 }}>
                                  <SearchListDrop
                                    listPath={`urun/mini_list/?magazalar.magaza.id=${props.form.magaza?.id}&aktif=true`}
                                    listCountPath={`urun/count?magazalar.magaza.id=${props.form.magaza?.id}&aktif=true`}
                                    searchQuery={searchItems[selectedRow]}
                                    keyProp='id'
                                    valueProp='tanim'
                                    onChageItem={(item) => {
                                      if (inputRef[selectedRow].current) {
                                        inputRef[selectedRow].current.focus();
                                      }
                                      props.onChangeFormDetay(selectedRow, 'urun', item, false);
                                      handleSearch(selectedRow, "");
                                    }}
                                    onClickAway={() => {
                                      handleSearch(selectedRow, "")
                                      props.onChangeFormDetay(selectedRow, 'urun', null, false)
                                    }}
                                    childRef={elementRef[selectedRow]}
                                  />
                                </div>
                              )}
                            </>
                          )}
                          {(openFilter) && (
                            <>
                              <div style={{ flex: '1 1 auto' }}>
                                <AutoSizer>
                                  {({ width, height }) => (
                                    <List
                                      width={width}
                                      height={height}
                                      rowHeight={35}
                                      rowCount={filtredList.length}
                                      rowRenderer={rowRendererSearch}
                                      estimatedRowSize={filtredList.length * 35}
                                      scrollToIndex={scrollNew}
                                    />
                                  )}
                                </AutoSizer>
                              </div>
                              {searchItems[selectedRow] && (
                                <div style={{ width: 300, height: 300, position: "absolute", left: 0, top: ((selectedRowSearch + 2) * 35), zIndex: 100 }}>
                                  <SearchListDrop
                                    listPath={`urun/mini_list/?magazalar.magaza.id=${props.form.magaza?.id}&aktif=true`}
                                    listCountPath={`urun/count?magazalar.magaza.id=${props.form.magaza?.id}&aktif=true`}
                                    searchQuery={searchItems[selectedRow]}
                                    keyProp='id'
                                    valueProp='tanim'
                                    onChageItem={(item) => {
                                      if (inputRef[selectedRow].current) {
                                        inputRef[selectedRow].current.focus();
                                      }
                                      props.onChangeFormDetay(selectedRow, 'urun', item, false)
                                      handleSearch(selectedRow, "")
                                    }}
                                    onClickAway={() => {
                                      handleSearch(selectedRow, "")
                                      props.onChangeFormDetay(selectedRow, 'urun', null, false)
                                    }}
                                    childRef={elementRef[selectedRow]}
                                  />
                                </div>
                              )}
                            </>
                          )}

                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}


              {LOADINGDETAY && (
                <div className={classes.layoutContainer}>
                  <div className="flex w-full justify-center items-center">
                    <CircularProgress color="primary" />
                    <Typography variant="caption">Yükleniyor...</Typography>
                  </div>
                </div>
              )}

              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorMagaza(false);
                    handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'magaza', true, false, false, '', '', '')
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Mağazalar'
                      listPath='magaza?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      notEmpty={true}
                      defaultItem={props.form.magaza}
                      onChangeItems={(items) => props.onChangeForm('magaza', items)}
                      onClose={() => {
                        setOpenSelectorMagaza(false);
                        handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'magaza', true, false, false, '', '', '');
                      }}
                      newPath="tanimlar/magaza"
                    />
                  </DialogContent>
                </Dialog>
              }

              {openSelectorUrun &&
                <Dialog
                  open={openSelectorUrun}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorUrun(false);
                    handleInputErrors('evrak', DETAYLISTE[selectedRow], DETAYLISTE[selectedRow], handleErrors2, add, 'urun', true, false, false, '', '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Ürünler'
                      listPath={`urun/mini_list/?magazalar.magaza.id=${props.form.magaza?.id}&aktif=true`}
                      listCountPath={`urun/count?magazalar.magaza.id=${props.form.magaza?.id}&aktif=true`}
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={DETAYLISTE[selectedRow]?.urun}
                      onChangeItems={(items) => { props.onChangeFormDetay(selectedRow, 'urun', items, false) }}
                      onClose={() => {
                        setOpenSelectorUrun(false);
                        handleInputErrors('evrak', DETAYLISTE[selectedRow], DETAYLISTE[selectedRow], handleErrors2, add, 'urun', true, false, false, '', '', '');
                      }}
                      newPath="tanimlar/urun"
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorDepo &&
                <Dialog
                  open={openSelectorDepo}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorDepo(false);
                    handleInputErrors('evrak', DETAYLISTE[selectedRow], DETAYLISTE[selectedRow], handleErrors2, add, 'depo', true, false, false, '', '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Depolar'
                      listPath='depo/mini_list?aktif=true'
                      listCountPath='depo/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={DETAYLISTE[selectedRow].depo}
                      onChangeItems={(items) => props.onChangeFormDetay(selectedRow, 'depo', items, false)}
                      onClose={() => {
                        setOpenSelectorDepo(false);
                        handleInputErrors('evrak', DETAYLISTE[selectedRow], DETAYLISTE[selectedRow], handleErrors2, add, 'depo', true, false, false, '', '', '');
                      }}
                      newPath="tanimlar/depolar"
                    />
                  </DialogContent>
                </Dialog>
              }
            </div>
          )}
        </>
      }
    </>
  );

});

export default DepoCikisDetailTab_Genel;
