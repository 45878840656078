import React from 'react';

import {
  Tabs,
  Tab,
  Tooltip,
  // IconButton
} from '@material-ui/core';

import {
  Error as IconError,
  Delete as IconDelete
} from '@material-ui/icons';

import { ThemeProvider } from '@material-ui/core/styles';

import Store from '../libs/store'
import Themes from '../themes/index';

const appTheme = Themes[Store.get('themeChoice')?.theme ?? 'light'];

const theme = {
  overrides: {
    MuiTabs: {
      root: {
        minWidth: undefined,
        overflow: 'visible',
        borderRight: undefined,
      },
    },

    MuiTab: {
      root: {
        padding: appTheme.spacing(.75, 3, .75, 3),
        maxWidth: 'none',
        textTransform: undefined,
        [appTheme.breakpoints.up('sm')]: {
          minWidth: 0,
        },
      },

      wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        textAlign: 'left',
        flexDirection: undefined,
      },
    },

    PrivateTabIndicator: {
      vertical: {
        right: undefined,
        left: 0,
      },
    },
  },
};

const DetailVerticalTabs = (props) => {

  return (
    <ThemeProvider theme={theme}>
      <Tabs
        orientation="vertical"
        indicatorColor="primary"
        textColor="primary"
        variant="standard"
        //variant="scrollable"
        // scrollButtons="auto"
        value={props.selectedTabIndex}
        onChange={(e, index) => props.onChangeTabIndex(index)}
      >
        {props.tabs?.length > 0 && props.tabs.map((tab, index) => {
          return (
            <Tab wrapped key={index} label={
              <div className="flex items-center justify-center space-x-2">
                {(props.buttonShow || props.deleteButtonShow) && (
                  <Tooltip title="Sil">
                    <span
                      className="flex items-center justify-center"
                      onClick={() => props.delete(index)}
                      style={{ color: "red" }}
                    >
                      <IconDelete />
                    </span>
                  </Tooltip>
                )}
                <span className="block pr-6">{tab.label}</span>

                {tab.hasError && (<IconError style={{ fontSize: 16, color: 'red' }} />)}
              </div>
            } />
          );
        })}
      </Tabs>
    </ThemeProvider>
  );
};

export default DetailVerticalTabs;
