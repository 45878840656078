import React from 'react';
import { useParams } from 'react-router-dom';

import SatisSiparistenSevkEmriDetail from './SatisSiparistenSevkEmriDetail';

const PageSatisSiparistenSevkEmriDetail = () => {
  const params = useParams();

  return (
    <SatisSiparistenSevkEmriDetail
      id={params?.id ? params.id === "yeni" ? null : params.id : params.id}
      addnew={params?.addnew ? params.addnew === "yeni" ? true : false : false}
      single={true}
    />
  );
};

export default PageSatisSiparistenSevkEmriDetail;