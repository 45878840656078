import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  Tooltip,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import {
  Search as IconSearch,
  HelpOutline as IconHelp
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import CategorySelector from '../../../components/CategorySelector';
import ListSelector from '../../../components/ListSelector';
import ListSelectorSingle from '../../../components/ListSelectorSingle';
import ListSelectorTwoStep from '../../../components/ListSelectorTwoStep';
import VaryantSelector from '../../../components/VaryantSelector';
import { handleInputErrors, handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';


import MultipleSelectList from '../../../components/MultipleSelectList';

const styles = ({ spacing, palette }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1, 1, 1, 4),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
    tableContainer: {
      border: `1px solid ${palette.divider}`,
      borderRadius: 4,
      padding: spacing(1),
    },
    table: {
      '&': {
        whiteSpace: 'nowrap'
      },
      '& tr th, & tr td': {
        lineHeight: 1,
        padding: 0,
      },
      '& tbody tr:hover td': {
        backgroundColor: palette.action.hover,
      },
      '& tbody tr:last-child td': {
        borderBottomWidth: 0,
      }
    },
    tablecell: {
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
  };
};


const UrunDetailXTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const LOADINGKAMPANYA = useMemo(() => props.loadingKampanya, [props.loadingKampanya]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATAKAMPANYA = useMemo(() => props.hataKampanya, [props.hataKampanya]);
  const add = useMemo(() => props.add, [props.add]);

  const birim = useMemo(() => props.birim, [props.birim]);
  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);
  const [openSelectorGrup, setOpenSelectorGrup] = useState(false);
  const [openModalCategorySelector, setOpenModalCategorySelector] = useState(false);
  const [openSelectorVergi, setOpenSelectorVergi] = useState(false);
  const [detayListOpen, setDetayListOpen] = useState(true);
  const [openSelectorVaryant, setOpenSelectorVaryant] = useState(false);
  const [openSelectorVaryantSelector, setOpenSelectorVaryantSelector] = useState(false);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const handleRemoveMagazaById = (id) => {
    const copyMagazalar = [...props.form.magazalar];
    const index = copyMagazalar.findIndex((c) => c.id === id);
    if (index > -1) {
      copyMagazalar.splice(index, 1);
      props.onChangeForm('magazalar', copyMagazalar)
    }
  };
  const handleRemoveGrupById = (id) => {
    const copyGruplar = [...props.form.gruplar];
    const index = copyGruplar.findIndex((c) => c.id === id);
    if (index > -1) {
      copyGruplar.splice(index, 1);
      props.onChangeForm('gruplar', copyGruplar)
    }
  };
  const handleRemoveCategoryById = (id) => {
    const copyUrunKategoriler = [...props.form.kategoriler];
    const index = copyUrunKategoriler.findIndex((c) => c.id === id);
    if (index > -1) {
      copyUrunKategoriler.splice(index, 1);
      props.onChangeForm('kategoriler', copyUrunKategoriler)
    }
  };

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>

                  <li className={classes.layoutFormItem} id="kodu">
                    <label className={classes.layoutFormItemLabel} htmlFor="kodu">Ürün Kodu *</label>
                    <Tooltip title={props.error?.kodu ? props.error.kodu[0] : _isNil(props.form?.kodu) ? '' : props.form.kodu}>
                      <TextField
                        {...createTextFieldProps('kodu')}
                        onChange={(e) => props.onChangeForm('kodu', e.target.value)}
                        onBlur={() => handleInputErrors('urun', props.form, props.formdefault, props.onCheckErrors, add, 'kodu', true, true, false, '', 'Ürün Kodu', 'ürün')}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="tanim">
                    <label className={classes.layoutFormItemLabel} htmlFor="tanim">Ürün Tanım *</label>
                    <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                      <TextField
                        {...createTextFieldProps('tanim')}
                        onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                        onBlur={() => handleInputErrors('urun', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Ürün Tanım', 'ürün')}
                        multiline
                        rows={3}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="barkod">
                    <label className={classes.layoutFormItemLabel} htmlFor="barkod">Barkod</label>
                    <Tooltip title={props.error?.barkod ? props.error.barkod[0] : _isNil(props.form?.barkod) ? '' : props.form.barkod}>
                      <TextField
                        {...createTextFieldProps('barkod')}
                        onChange={(e) => props.onChangeForm('barkod', e.target.value)}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="tip">
                    <label className={classes.layoutFormItemLabel} htmlFor="tip">Tip *</label>
                    <Tooltip title={props.error?.tip ? props.error.tip[0] : _isNil(props.form?.tip) ? '' : props.urunTipleriDic ? props.urunTipleriDic[props.form.tip] : ''}>
                      <TextField
                        {...createTextFieldProps('tip')}
                        onChange={(e) => props.onChangeForm('tip', e.target.value)}
                        onBlur={() => handleInputErrors('tip', props.form, props.formdefault, props.onCheckErrors, add, 'tip', true, false, false, '', 'Tip', 'ürün')}
                        select
                        SelectProps={{ native: true }}
                      >
                        {props.urunTipleri?.map((tip) => (
                          <option key={tip.key} value={tip.key}>{tip.value}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="varyant_grup">
                    <label className={classes.layoutFormItemLabel} htmlFor="varyant">Varyant Grup</label>
                    <Tooltip title={props.error?.varyant_grup ? props.error.varyant_grup[0] : _isNil(props.form?.varyant_grup) ? '' : props.form.varyant_grup.tanim}>
                      <TextField
                        name='varyant_grup'
                        variant='outlined'
                        size='small'
                        value={_result(props.varyant, 'varyant_grup.tanim') || ''}
                        fullWidth={true}
                        disabled={add}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button}
                              disableRipple
                              disabled={add}
                              onClick={() => {
                                if (!add) {
                                  if (props.form.varyant_var) {
                                    setOpenSelectorVaryantSelector(true)
                                  } else {
                                    setOpenSelectorVaryant(true)
                                  }
                                }
                              }}
                            ><IconSearch /></IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="tedarik_suresi">
                    <label className={classes.layoutFormItemLabel} htmlFor="tedarik_suresi">Tedarik Süresi</label>
                    <Tooltip title={props.error?.tedarik_suresi ? props.error.tedarik_suresi[0] : _isNil(props.form?.tedarik_suresi) ? '' : props.form.tedarik_suresi}>
                      <TextField
                        {...createTextFieldProps('tedarik_suresi')}
                        onChange={(e) => props.onChangeForm('tedarik_suresi', e.target.value)}
                        type="number"
                        inputProps={{
                          min: 0,
                          max: 2147483647,
                          step: 1,
                          style: { textAlign: "right" }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="desi">
                    <label className={classes.layoutFormItemLabel} htmlFor="desi">Desi</label>
                    <Tooltip title={props.error?.desi ? props.error.desi[0] : _isNil(props.form?.desi) ? '' : props.form.desi}>
                      <TextField
                        {...createTextFieldProps('desi')}
                        onChange={(e) => props.onChangeForm('desi', e.target.value)}
                        type="number"
                        inputProps={{
                          style: { textAlign: "right" }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="birim">
                    <label className={classes.layoutFormItemLabel} htmlFor="birim">Birim *</label>
                    <Tooltip title={props.error?.birim ? props.error.birim[0] : (props.form?.birim === '-1' || _isNil(props.form?.birim)) ? '' : props.birimListDic ? props.birimListDic[props.form.birim] : ''}>
                      <TextField
                        {...createTextFieldProps('birim')}
                        onChange={(e) => props.onChangeForm('birim', e.target.value)}
                        onBlur={() => handleInputErrors('birim', props.form, props.formdefault, props.onCheckErrors, add, 'birim', true, false, false, '', 'Birim', 'ürün')}
                        select
                        SelectProps={{ native: true }}
                      >
                        <option value='-1' disabled>Seçilmedi</option>
                        {props.birimList.map((birim) => (
                          <option key={birim.id} value={birim.id}>{birim.tanim}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="satis_birim_grup">
                    <label className={classes.layoutFormItemLabel} htmlFor="satis_birim_grup">Satış Birim Grup</label>
                    <Tooltip title={props.error?.satis_birim_grup ? props.error.satis_birim_grup[0] : (props.form?.satis_birim_grup === '-1' || _isNil(props.form?.satis_birim_grup)) ? '' : props.satisBirimGrupDic ? props.satisBirimGrupDic[props.form.satis_birim_grup] : ''}>
                      <TextField
                        {...createTextFieldProps('satis_birim_grup')}
                        onChange={(e) => props.onChangeForm('satis_birim_grup', e.target.value)}
                        select
                        SelectProps={{ native: true }}
                      >
                        <option value='-1'>Seçilmedi</option>
                        {props.satisBirimGrup?.map((satisbirimgrup) => (
                          <option key={satisbirimgrup.id} value={satisbirimgrup.id}>{satisbirimgrup.tanim}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>

                  {props?.carpanList && (
                    <li className={classes.layoutFormItem} id="satis_birim_grup">
                      <label className={classes.layoutFormItemLabel} htmlFor="satis_birim_grup"></label>
                      <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                        <header className="flex items-center justify-between py-2 px-4">
                          <Button
                            className="pointer-events-none"
                            color="inherit"
                          >ÇARPANLAR</Button>
                        </header>
                        <main className="border-t border-palette-action-hover py-1">
                          {props.carpanList?.length > 0 &&
                            <ul className="flex flex-col list-none m-0 p-0">
                              {props?.carpanList?.map((carpan) => (
                                <li
                                  key={carpan.id}
                                  className="flex items-center justify-start py-1 pl-4 pr-8 space-x-2"
                                >
                                  <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '30% 25px auto' }}>
                                    <span>{carpan.birim.tanim}</span>
                                    <span>{` --> `}</span>
                                    <span>{carpan.katsayi} {birim.tanim}</span>
                                  </div>

                                </li>
                              ))}
                            </ul>
                          }
                          {(props.loading && !props.carpanList) && (
                            <p className="py-1 px-6 m-0">Çarpanlar alınıyor.</p>
                          )}

                          {(!props.loading && props.carpanList?.length === 0) && (
                            <p className="py-1 px-6 m-0">Çarpan yok.</p>
                          )}
                        </main>
                      </section>
                    </li>
                  )}

                  <li className={classes.layoutFormItem} id="uretici">
                    <label className={classes.layoutFormItemLabel} htmlFor="uretici">Üretici</label>
                    <Tooltip title={props.error?.uretici ? props.error.uretici[0] : (props.form?.uretici === '-1' || _isNil(props.form?.uretici)) ? '' : props.ureticiListDic ? props.ureticiListDic[props.form.uretici] : ''}>
                      <TextField
                        {...createTextFieldProps('uretici')}
                        onChange={(e) => props.onChangeForm('uretici', e.target.value)}
                        select
                        SelectProps={{ native: true }}
                      >
                        <option value='-1'>Seçilmedi</option>
                        {props.ureticiList.map((uretici) => (
                          <option key={uretici.id} value={uretici.id}>{uretici.username}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="vergi">
                    <label className={classes.layoutFormItemLabel} htmlFor="vergi">Vergi</label>
                    <Tooltip title={props.error?.vergi ? props.error.vergi[0] : _isNil(props.form?.vergi) ? '' : props.form.vergi.tanim}>
                      <TextField
                        {...createTextFieldProps('vergi.tanim')}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button}
                              disableRipple
                              onClick={() => setOpenSelectorVergi(true)}
                            ><IconSearch /></IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="katalogta_gizle">
                    <label className={classes.layoutFormItemLabel} htmlFor="katalogta_gizle">Katalogda Gizle</label>
                    <Checkbox
                      name="katalogta_gizle"
                      color="primary"
                      checked={_result(props.form, 'katalogta_gizle', false)}
                      onChange={(e, checked) => props.onChangeForm('katalogta_gizle', checked)}
                    ></Checkbox>
                  </li>

                  <li className={classes.layoutFormItem} id="webde_goster">
                    <label className={classes.layoutFormItemLabel} htmlFor="webde_goster">Webde Göster</label>
                    <Checkbox
                      name="webde_goster"
                      color="primary"
                      checked={_result(props.form, 'webde_goster', false)}
                      onChange={(e, checked) => props.onChangeForm('webde_goster', checked)}
                    ></Checkbox>
                  </li>
                  <li className={classes.layoutFormItem} id="miktar_kontrol_yapma">
                    <label className={classes.layoutFormItemLabel} htmlFor="miktar_kontrol_yapma">Miktar Kontrolu Yapma</label>
                    <Checkbox
                      name="miktar_kontrol_yapma"
                      color="primary"
                      checked={_result(props.form, 'miktar_kontrol_yapma', false)}
                      onChange={(e, checked) => props.onChangeForm('miktar_kontrol_yapma', checked)}
                    ></Checkbox>
                    <IconButton
                      size="small"
                      className={classes.button2}
                      disableRipple
                      onClick={() => {
                        setHelpText("“Ürün hizmet tipinde değilse, sipariş verilirken bu ürün için miktar kontrol edilmesi istenmiyorsa, 0 veya altında iken satış yapılabilir.”");
                        setHelpOpen(true)
                      }}
                    ><IconHelp /></IconButton>
                  </li>

                  <li className={classes.layoutFormItem} id="aktif">
                    <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                    <Checkbox
                      name="aktif"
                      color="primary"
                      checked={_result(props.form, 'aktif', false)}
                      onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                    ></Checkbox>
                  </li>
                </ul>

                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItemBox}>
                    <MultipleSelectList
                      headername="MAĞAZALAR"
                      name="Mağazalar"
                      name2="Mağaza"
                      form={props.form}
                      list={props.form?.magazalar ?? []}
                      listlength={props?.form?.magazalar?.length ?? 0}
                      opendialog={setOpenSelectorMagaza}
                      keyProp="id"
                      valueProp="tanim"
                      removefonk={handleRemoveMagazaById}
                      loading={props.loading}
                      error={props.error?.magazalar ? props.error.magazalar[0] : null}
                      ustId={false}
                      newPath='tanimlar/magaza'
                    />
                  </li>
                  <li className={classes.layoutFormItemBox}>
                    <MultipleSelectList
                      headername="ÜRÜN GRUPLARI"
                      name="Ürün Grupları"
                      name2="Ürün grup"
                      form={props.form}
                      list={props.form?.gruplar ?? []}
                      listlength={props?.form?.gruplar?.length ?? 0}
                      opendialog={setOpenSelectorGrup}
                      keyProp="id"
                      valueProp="tanim"
                      removefonk={handleRemoveGrupById}
                      loading={props.loading}
                      error={props.error?.gruplar ? props.error.gruplar[0] : null}
                      ustId={false}
                      newPath='tanimlar/gruplar'
                    />
                  </li>
                  <li className={classes.layoutFormItemBox}>
                    <MultipleSelectList
                      headername="KATEGORİLER"
                      name="Kategoriler"
                      name2="Kategori"
                      form={props.form}
                      list={props.form?.kategoriler ?? []}
                      listlength={props?.form?.kategoriler?.length ?? 0}
                      opendialog={setOpenModalCategorySelector}
                      keyProp="id"
                      valueProp="parents"
                      removefonk={handleRemoveCategoryById}
                      loading={props.loading}
                      error={props.error?.kategoriler ? props.error.kategoriler[0] : null}
                      ustId={true}
                      newPath='tanimlar/kategoridetay'
                    />
                  </li>
                </ul>

                <ul className={classes.layoutFormList}>
                  <div>Kampanya Miktar</div>
                  {!LOADINGKAMPANYA && (
                    <>
                      {HATAKAMPANYA && (
                        <Hata />
                      )}
                      {!HATAKAMPANYA && (
                        <div className={classes.tableContainer}>
                          <Table className={classes.table}
                            size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell component="th" scope="row" style={{ minWidth: 200, width: 200, padding: 2, height: 40 }}>Kampanya Adı</TableCell>
                                <TableCell component="th" scope="row" style={{ minWidth: 200, width: 200, padding: 2, height: 40 }} align="right">Ürün Miktar</TableCell>
                                <TableCell component="th" scope="row" style={{ minWidth: 200, width: 200, padding: 2, height: 40 }} align="right">Kampanya Adetli Çalışsın</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {props.kampanya.map((kmpy, index) => (
                                <TableRow key={`kampanya-${index}`}>
                                  <TableCell style={{ padding: 2 }}>{kmpy.form.kampanya.tanim}</TableCell>
                                  <TableCell style={{ padding: 2, textAlign: "right" }} className={classes.tablecell}>
                                    <Tooltip title={kmpy.errors?.urun_adedi ? kmpy.errors.urun_adedi[0] : _isNil(kmpy.form?.urun_adedi) ? '' : kmpy.form.urun_adedi}>
                                      <TextField
                                        name='urun_adedi'
                                        variant='outlined'
                                        size='small'
                                        fullWidth={true}
                                        value={kmpy.form.urun_adedi || ""}
                                        error={_has(kmpy.errors, `urun_adedi`)}
                                        onChange={(e) => props.onChangeFormKampanya(index, 'urun_adedi', e.target.value)}
                                        type="number"
                                        inputProps={{
                                          min: -2147483648,
                                          max: 2147483647,
                                          step: 1,
                                          style: { textAlign: "right" }
                                        }}
                                        onBlur={() => {
                                          if (kmpy.form.kampanya_adetli_calissin) {
                                            handleInputErrors2(kmpy.form, 'urun_adedi', (errors)=>props.onCheckErrorsKampanya(errors, index))
                                          }
                                        }}
                                      ></TextField>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell style={{ padding: 2, textAlign: "right" }} className={classes.tablecell}>
                                    <Checkbox
                                      name="kampanya_adetli_calissin"
                                      color="primary"
                                      checked={_result(kmpy.form, 'kampanya_adetli_calissin', false)}
                                      onChange={(e, checked) => props.onChangeFormKampanya(index, 'kampanya_adetli_calissin', checked)}
                                    ></Checkbox>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </div>
                      )}
                    </>
                  )}
                </ul>
              </div>
              {openModalCategorySelector &&
                <Dialog
                  open={openModalCategorySelector}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenModalCategorySelector(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <CategorySelector
                      selected={props.form.kategoriler}
                      onChange={(categories) => props.onChangeForm('kategoriler', categories)}
                      onClose={() => setOpenModalCategorySelector(false)}
                      single={false}
                      parentselect={true}
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorMagaza(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Mağazalar'
                      listPath='magaza/mini_list?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.magazalar}
                      onChangeItems={(items) => props.onChangeForm('magazalar', items)}
                      onClose={() => setOpenSelectorMagaza(false)}
                      newPath='tanimlar/magaza'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorGrup &&
                <Dialog
                  open={openSelectorGrup}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorGrup(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Ürün Grupları'
                      listPath='grup/mini_list?aktif=true'
                      listCountPath='grup/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.gruplar}
                      onChangeItems={(items) => props.onChangeForm('gruplar', items)}
                      onClose={() => setOpenSelectorGrup(false)}
                      newPath='tanimlar/gruplar'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorVergi &&
                <Dialog
                  open={openSelectorVergi}
                  PaperProps={{
                    style: {
                      background: 'transparent',
                      height: 600,
                      width: detayListOpen ? '100%' : 480
                    },
                  }}
                  fullWidth
                  maxWidth={detayListOpen ? 'md' : 'sm'}
                  onClose={() => setOpenSelectorVergi(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorTwoStep
                      title="Vergiler"
                      mainItemsPath='vergi_tip'
                      mainItemsCountPath='vergi_tip/count'
                      mainDefaultItems={props.form.vergi_baslik}
                      mainMultiple={false}
                      mainKeyProp='id'
                      mainValueProp='tanim'
                      listPath='vergi'
                      listCountPath='vergi/count'
                      listMultiple={false}
                      keyProp='id'
                      valueProp='tanim'
                      keyUId='baslik_id'
                      valueUV='baslik_tanim'
                      defaultItems={props.form.vergi}
                      onChangeItems={(items) => props.onChangeForm('vergi', items)}
                      onClose={() => {
                        setOpenSelectorVergi(false);
                      }}
                      onDetayListClose={(res) => setDetayListOpen(res)}
                      detayListOpen={detayListOpen}
                      detayList={props.form.vergi_tree_list}
                      url="tanimlar/vergidetay"
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorVaryant &&
                <Dialog
                  open={openSelectorVaryant}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorVaryant(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Varyant Grupları'
                      listPath='varyant-grup?aktif=true'
                      listCountPath='varyant-grup/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      extraVal='ozellikbasliklar'
                      defaultItem={props.varyant.varyant_grup}
                      onChangeItems={(items) => {
                        props.onChangeFormVaryant('varyant_grup', items)
                        setOpenSelectorVaryant(false)
                        setOpenSelectorVaryantSelector(true);
                      }}
                      onClose={() => setOpenSelectorVaryant(false)}
                      newPath='tanimlar/varyant-grup'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorVaryantSelector &&
                <Dialog
                  open={openSelectorVaryantSelector}
                  PaperProps={{
                    style: {
                      minWidth: '480px',
                      maxWidth: '80%',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorVaryantSelector(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <VaryantSelector
                      selectedVaryant={props.varyant.varyant_grup}
                      urunId={props.id}
                      onClose={() => {
                        if (!props.form.varyant_var) {
                          props.onChangeFormVaryant('varyant_grup', null)
                        }
                        setOpenSelectorVaryantSelector(false)
                      }}
                      defaultSelecteds={props.varyant.varyants}
                      reloadVaryants={(items) => props.reloadVaryants(items)}
                    />
                  </DialogContent>
                </Dialog>
              }
              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default UrunDetailXTabGenel;