import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  IconButton,
  TextField,
  Tooltip,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';

import {
  HelpOutline as IconHelp
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import ListSelector from '../../../components/ListSelector';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import MultipleSelectList from '../../../components/MultipleSelectList';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      position: "relative",
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemLabel2: {
      minWidth: 330,
      maxWidth: 330,
    },
    button: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 0, 1, 0),
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1, 1, 1, 4),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
  };
};

const SiteDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATAAYAR = useMemo(() => props.hataAyar, [props.hataAyar]);
  const HATADIL = useMemo(() => props.hataDil, [props.hataDil]);
  const add = useMemo(() => props.add, [props.add]);
  const [openSelectorKargo, setOpenSelectorKargo] = useState(false);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  const COUNT_TABS = useMemo(() => props?.dilform?.length ?? 0, [props]);
  const FORM = useMemo(() => props?.dilform?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.dilform?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.dilform?.length > 0) {
      const copyForms = [...props.dilform];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.dilform]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const createTextFieldPropsAyar = useCallback((name, name2, name3, defaultValue = '') => {
    if (name3 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.ayarForm?.['ayar'][name][name2], `${name3}`) || defaultValue,
        error: _has(props.ayarError?.['ayar']?.[name]?.[name2], `${name3}`),
        fullWidth: true,
      };
    } else {
      if (name2 !== '') {
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.ayarForm?.['ayar'][name], `${name2}`) || defaultValue,
          error: _has(props.ayarError?.['ayar']?.[name], `${name2}`),
          fullWidth: true,
        };
      } else {
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.ayarForm?.['ayar'], `${name}`) || defaultValue,
          error: _has(props.ayarError?.['ayar'], `${name}`),
          fullWidth: true,
        };
      }
    }
  }, [props.ayarError, props.ayarForm]);

  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);


  const handleRemoveKargoById = (id) => {
    const copykargolar = [...props.ayarForm.ayar.gorunum_ayarlari.sepet_limit_kargolar];
    const index = copykargolar.findIndex((c) => c.id === id);
    if (index > -1) {
      copykargolar.splice(index, 1);
      props.onChangeFormAyar('gorunum_ayarlari', 'sepet_limit_kargolar', '', copykargolar);
    }
  }

  const handleChangeForm = (property, value) => {
    props.onChangeDilForm(selectedTabIndex, property, value);
  };

  const handleDetailDelete = (index) => {
    props.deleteDetailWithIndex(index);
  }

  return (
    <>
      {!LOADING &&
        <>
          {(HATA || HATAAYAR) && (
            <Hata />
          )}
          {(!HATA && !HATAAYAR) && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="name">
                      <label className={classes.layoutFormItemLabel} htmlFor="name">Site Tanım *</label>
                      <Tooltip title={props.error?.name ? props.error.name[0] : _isNil(props.form?.name) ? '' : props.form.name}>
                        <TextField
                          {...createTextFieldProps('name')}
                          onChange={(e) => props.onChangeForm('name', e.target.value)}
                          onBlur={() => handleInputErrors('site', props.form, props.formdefault, props.onCheckErrors, add, 'name', true, true, false, '', 'Site Tanım', 'site')}
                          inputProps={{
                            maxLength: 50,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="domain">
                      <label className={classes.layoutFormItemLabel} htmlFor="domain">Alan Adı *</label>
                      <Tooltip title={props.error?.domain ? props.error.domain[0] : _isNil(props.form?.domain) ? '' : props.form.domain}>
                        <TextField
                          {...createTextFieldProps('domain')}
                          onChange={(e) => props.onChangeForm('domain', e.target.value)}
                          onBlur={() => handleInputErrors('site', props.form, props.formdefault, props.onCheckErrors, add, 'domain', true, true, false, '', 'Alan Adı', 'site')}
                          inputProps={{
                            maxLength: 100,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="baslik">
                      <label className={classes.layoutFormItemLabel} htmlFor="baslik">Meta Başlık *</label>
                      <Tooltip title={props.ayarError?.ayar?.baslik ? props.ayarError.ayar.baslik[0] : _isNil(props.ayarForm?.ayar?.baslik) ? '' : props.ayarForm.ayar.baslik}>
                        <TextField
                          {...createTextFieldPropsAyar('baslik', '', '')}
                          onChange={(e) => props.onChangeFormAyar('baslik', '', '', e.target.value)}
                          onBlur={() => handleInputErrors('site', props.ayarForm, props.ayarFormDefault, props.onCheckErrorsAyar, add, 'ayar', true, false, false, '', '', '', 'baslik')}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="anahtar_kelimeler">
                      <label className={classes.layoutFormItemLabel} htmlFor="anahtar_kelimeler">Meta Anahtar Kelimeler</label>
                      <Tooltip title={props.ayarError?.ayar?.anahtar_kelimeler ? props.ayarError.ayar.anahtar_kelimeler[0] : _isNil(props.ayarForm?.ayar?.anahtar_kelimeler) ? '' : props.ayarForm.ayar.anahtar_kelimeler}>
                        <TextField
                          {...createTextFieldPropsAyar('anahtar_kelimeler', '', '')}
                          onChange={(e) => props.onChangeFormAyar('anahtar_kelimeler', '', '', e.target.value)}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="bilgilendirme">
                      <label className={classes.layoutFormItemLabel} htmlFor="bilgilendirme">Meta Bilgilendirme</label>
                      <Tooltip title={props.ayarError?.ayar?.bilgilendirme ? props.ayarError.ayar.bilgilendirme[0] : _isNil(props.ayarForm?.ayar?.bilgilendirme) ? '' : props.ayarForm.ayar.bilgilendirme}>
                        <TextField
                          {...createTextFieldPropsAyar('bilgilendirme', '', '')}
                          onChange={(e) => props.onChangeFormAyar('bilgilendirme', '', '', e.target.value)}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="urun_satilabilen_getirsin">
                      <label className={classes.layoutFormItemLabel} htmlFor="urun_satilabilen_getirsin">Katalog Ürün Gösterim Tip *</label>
                      <Tooltip title={props.ayarError?.ayar?.urun_satilabilen_getirsin ? props.ayarError.ayar.urun_satilabilen_getirsin[0] : props.ayarForm?.ayar?.urun_satilabilen_getirsin === "1" ? 'Tüm ürünleri göster' : 'Sadece satışa açık ürünleri göster'}>
                        <TextField
                          {...createTextFieldPropsAyar('urun_satilabilen_getirsin', '', '')}
                          onChange={(e) => props.onChangeFormAyar('urun_satilabilen_getirsin', '', '', e.target.value)}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option key="1" value="false">Tüm ürünleri göster</option>
                          <option key="2" value="true">Sadece satışa açık ürünleri göster</option>
                        </TextField>
                      </Tooltip>
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="siparis_ayarlari.siparis_alt_limit">
                      <label className={classes.layoutFormItemLabel} htmlFor="siparis_ayarlari.siparis_alt_limit">Sipariş Alt Limit</label>
                      <Tooltip title={props.ayarError?.ayar?.siparis_ayarlari?.siparis_alt_limit ? props.ayarError.ayar.siparis_ayarlari.siparis_alt_limit[0] : _isNil(props.ayarForm?.ayar?.siparis_ayarlari?.siparis_alt_limit) ? '' : props.ayarForm.ayar.siparis_ayarlari.siparis_alt_limit}>
                        <TextField
                          {...createTextFieldPropsAyar('siparis_ayarlari', 'siparis_alt_limit', '')}
                          onChange={(e) => props.onChangeFormAyar('siparis_ayarlari', 'siparis_alt_limit', '', e.target.value)}
                          type="number"
                          inputProps={{
                            min: 0,
                            style: { textAlign: "right" }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="bildirim_ayarlari.fiyat_dustu.baslik">
                      <label className={classes.layoutFormItemLabel} htmlFor="bildirim_ayarlari.fiyat_dustu.baslik">Fiyatı Düştü Epostası Başlık</label>
                      <Tooltip title={props.ayarError?.ayar?.bildirim_ayarlari?.fiyat_dustu?.baslik ? props.ayarError.ayar.bildirim_ayarlari.fiyat_dustu.baslik[0] : _isNil(props.ayarForm?.ayar?.bildirim_ayarlari?.fiyat_dustu?.baslik) ? '' : props.ayarForm.ayar.bildirim_ayarlari.fiyat_dustu.baslik}>
                        <TextField
                          {...createTextFieldPropsAyar('bildirim_ayarlari', 'fiyat_dustu', 'baslik')}
                          onChange={(e) => props.onChangeFormAyar('bildirim_ayarlari', 'fiyat_dustu', 'baslik', e.target.value)}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="bildirim_ayarlari.fiyat_dustu.email">
                      <label className={classes.layoutFormItemLabel} htmlFor="bildirim_ayarlari.fiyat_dustu.email">Fiyatı Düştü Epostası</label>
                      <Tooltip title={props.ayarError?.ayar?.bildirim_ayarlari?.fiyat_dustu?.email ? props.ayarError.ayar.bildirim_ayarlari.fiyat_dustu.email[0] : _isNil(props.ayarForm?.ayar?.bildirim_ayarlari?.fiyat_dustu?.email) ? '' : props.ayarForm.ayar.bildirim_ayarlari.fiyat_dustu.email}>
                        <TextField
                          {...createTextFieldPropsAyar('bildirim_ayarlari', 'fiyat_dustu', 'email')}
                          onChange={(e) => props.onChangeFormAyar('bildirim_ayarlari', 'fiyat_dustu', 'email', e.target.value)}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="bildirim_ayarlari.stoklarimizda.baslik">
                      <label className={classes.layoutFormItemLabel} htmlFor="bildirim_ayarlari.stoklarimizda.baslik">Stoklarda Epostası Başlık</label>
                      <Tooltip title={props.ayarError?.ayar?.bildirim_ayarlari?.stoklarimizda?.baslik ? props.ayarError.ayar.bildirim_ayarlari.stoklarimizda.baslik[0] : _isNil(props.ayarForm?.ayar?.bildirim_ayarlari?.stoklarimizda?.baslik) ? '' : props.ayarForm.ayar.bildirim_ayarlari.stoklarimizda.baslik}>
                        <TextField
                          {...createTextFieldPropsAyar('bildirim_ayarlari', 'stoklarimizda', 'baslik')}
                          onChange={(e) => props.onChangeFormAyar('bildirim_ayarlari', 'stoklarimizda', 'baslik', e.target.value)}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="bildirim_ayarlari.stoklarimizda.email">
                      <label className={classes.layoutFormItemLabel} htmlFor="bildirim_ayarlari.stoklarimizda.email">Stoklarda Epostası</label>
                      <Tooltip title={props.ayarError?.ayar?.bildirim_ayarlari?.stoklarimizda?.email ? props.ayarError.ayar.bildirim_ayarlari.stoklarimizda.email[0] : _isNil(props.ayarForm?.ayar?.bildirim_ayarlari?.stoklarimizda?.email) ? '' : props.ayarForm.ayar.bildirim_ayarlari.stoklarimizda.email}>
                        <TextField
                          {...createTextFieldPropsAyar('bildirim_ayarlari', 'stoklarimizda', 'email')}
                          onChange={(e) => props.onChangeFormAyar('bildirim_ayarlari', 'stoklarimizda', 'email', e.target.value)}
                        ></TextField>
                      </Tooltip>
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="uyelik_zorunlu">
                      <label className={classes.layoutFormItemLabel2} htmlFor="uyelik_zorunlu">Üyelik Zorunlu</label>
                      <Checkbox
                        name="uyelik_zorunlu"
                        color="primary"
                        checked={_result(props.ayarForm?.ayar, 'uyelik_zorunlu', false)}
                        onChange={(e, checked) => props.onChangeFormAyar('uyelik_zorunlu', '', '', checked)}
                      ></Checkbox>
                    </li>
                    <li className={classes.layoutFormItem} id="site_close">
                      <label className={classes.layoutFormItemLabel2} htmlFor="site_close">Siteyi Erişime Kapat</label>
                      <Checkbox
                        name="site_close"
                        color="primary"
                        checked={_result(props.ayarForm?.ayar, 'site_close', false)}
                        onChange={(e, checked) => props.onChangeFormAyar('site_close', '', '', checked)}
                      ></Checkbox>
                    </li>
                    <li className={classes.layoutFormItem} id="odeme_fiyatlari.katalog">
                      <label className={classes.layoutFormItemLabel2} htmlFor="odeme_fiyatlari.katalog">Ödeme Fiyatları Katalogta Gelsin</label>
                      <Checkbox
                        name="odeme_fiyatlari.katalog"
                        color="primary"
                        checked={_result(props.ayarForm?.ayar?.gorunum_ayarlari?.odeme_fiyatlari, 'katalog', false)}
                        onChange={(e, checked) => props.onChangeFormAyar('gorunum_ayarlari', 'odeme_fiyatlari', 'katalog', checked)}
                      ></Checkbox>
                      <IconButton
                        size="small"
                        className={classes.button}
                        disableRipple
                        onClick={() => {
                          setHelpText("“Tüm katalog ürün görünümleri için ayar (grup, kategori, özellik değer vs)”");
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                    <li className={classes.layoutFormItem} id="odeme_fiyatlari.urun_detay">
                      <label className={classes.layoutFormItemLabel2} htmlFor="odeme_fiyatlari.urun_detay">Ödeme Fiyatları Ürün Detayda Gelsin</label>
                      <Checkbox
                        name="odeme_fiyatlari.urun_detay"
                        color="primary"
                        checked={_result(props.ayarForm?.ayar?.gorunum_ayarlari?.odeme_fiyatlari, 'urun_detay', false)}
                        onChange={(e, checked) => props.onChangeFormAyar('gorunum_ayarlari', 'odeme_fiyatlari', 'urun_detay', checked)}
                      ></Checkbox>
                    </li>
                    <li className={classes.layoutFormItem} id="odeme_fiyatlari.sepet">
                      <label className={classes.layoutFormItemLabel2} htmlFor="odeme_fiyatlari.sepet">Ödeme Fiyatları Sepette Gelsin</label>
                      <Checkbox
                        name="odeme_fiyatlari.sepet"
                        color="primary"
                        checked={_result(props.ayarForm?.ayar?.gorunum_ayarlari?.odeme_fiyatlari, 'sepet', false)}
                        onChange={(e, checked) => props.onChangeFormAyar('gorunum_ayarlari', 'odeme_fiyatlari', 'sepet', checked)}
                      ></Checkbox>
                    </li>
                    <li className={classes.layoutFormItem} id="odeme_fiyatlari.urun_bilesen">
                      <label className={classes.layoutFormItemLabel2} htmlFor="odeme_fiyatlari.urun_bilesen">Ödeme Fiyatları Ürün Bileşende Gelsin</label>
                      <Checkbox
                        name="odeme_fiyatlari.urun_bilesen"
                        color="primary"
                        checked={_result(props.ayarForm?.ayar?.gorunum_ayarlari?.odeme_fiyatlari, 'urun_bilesen', false)}
                        onChange={(e, checked) => props.onChangeFormAyar('gorunum_ayarlari', 'odeme_fiyatlari', 'urun_bilesen', checked)}
                      ></Checkbox>
                    </li>
                  </ul>
                  {!add &&
                    <ul className={classes.layoutFormList}>
                      <li className={classes.layoutFormItemBox} id="sepet_limit_kargolar">
                        <MultipleSelectList
                          headername="KARGOLAR"
                          name="Kargolar"
                          name2="kargo"
                          form={props.ayarForm}
                          list={props.ayarForm?.ayar?.gorunum_ayarlari?.sepet_limit_kargolar ?? []}
                          listlength={props.ayarForm?.ayar?.gorunum_ayarlari?.sepet_limit_kargolar.length ?? 0}
                          opendialog={setOpenSelectorKargo}
                          keyProp="id"
                          valueProp="tanim"
                          removefonk={handleRemoveKargoById}
                          loading={props.loading}
                          error={props.ayarError?.ayar?.gorunum_ayarlari?.sepet_limit_kargolar ? props.ayarError.ayar.gorunum_ayarlari.sepet_limit_kargolar[0] : null}
                          newPath="tanimlar/kargo"
                          ustId={false}
                          helper={true}
                          helperOpen={() => setHelpOpen(true)}
                          helperSet={() => setHelpText("“Sepet sayfasında, kargo(ların) ücretsiz olması için gereken sepet toplamının ulaşması gereken tutarı belirten etiketlerin hangi kargolar için çalışacağı bilgisidir.”")}
                        />
                      </li>
                    </ul>
                  }
                </div>

                <div className={classes.layoutContainerTab}>
                  <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                  <>
                    {HATADIL && (
                      <Hata />
                    )}
                    {!HATADIL && (
                      <LayoutDetailVerticalTab
                        tabs={tabs}
                        selectedTabIndex={selectedTabIndex}
                        onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                        buttonShow={false}
                        delete={handleDetailDelete}
                        deleteButtonShow={true}
                      >
                        {COUNT_TABS === 0 && (
                          <div className="flex flex-col items-start justify-start p-4 space-y-4">
                            <Typography variant="body1">Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                          </div>
                        )}
                        {COUNT_TABS > 0 && (
                          <div className={classes.tabDiv}>
                            <div className={classes.column}>
                              <ul className={classes.layoutFormList}>
                                <li className={classes.layoutFormItem} id="sablon">
                                  <label className={classes.layoutFormItemLabel} htmlFor="sablon">Sipariş Alt Limit Şablon</label>
                                  <Tooltip title={ERROR?.sablon ? ERROR.sablon[0] : FORM?.sablon == null ? '' : FORM.sablon}>
                                    <TextField
                                      {...createTextFieldProps2('sablon')}
                                      onChange={(e) => handleChangeForm('sablon', e.target.value)}
                                      multiline
                                      rows={4}
                                    ></TextField>
                                  </Tooltip>
                                  <IconButton
                                    size="small"
                                    className={classes.button}
                                    disableRipple
                                    onClick={() => {
                                      setHelpText('“{siparis_alt_limit} kalıbı cümlede sipariş alt limit bedelinin kullanılmak istendiği yere, {siparis_icin_gereken_tutar} sipariş verebilmek için gereken kalan meblağnın kullanılmak istendiği yere etiket olarak bırakılarak kullanılabilir. ÖRN1. (Minimum sipariş tutarı {siparis_alt_limit} TL) şeklindeki fiyat şablonunun görüntüsü (Minimum sipariş tutarı 250 TL ) şeklinde olacaktır. ÖRN2. (Siparişi verebilmen için sepetine {siparis_icin_gereken_tutar} TL lik daha ürün eklemen yeterlidir.) şeklindeki fiyat şablonunun görüntüsü (Siparişi verebilmen için sepetine 2 TL lik daha ürün eklemen yeterlidir.) şeklinde olacaktır.”');
                                      setHelpOpen(true)
                                    }}
                                  ><IconHelp /></IconButton>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </LayoutDetailVerticalTab>
                    )}
                  </>
                </div>
              </div>


              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
              {openSelectorKargo &&
                <Dialog
                  open={openSelectorKargo}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorKargo(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Kargolar'
                      listPath={`kargo/mini_list?aktif=true&magazalar.magaza.id=${props.validMagazalar.join(',')}`}
                      listCountPath={`kargo/count?aktif=true&magazalar.magaza.id=${props.validMagazalar.join(',')}`}
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.ayarForm.ayar.gorunum_ayarlari.sepet_limit_kargolar}
                      onChangeItems={(items) => props.onChangeFormAyar('gorunum_ayarlari', 'sepet_limit_kargolar', '', items)}
                      onClose={() => setOpenSelectorKargo(false)}
                      newPath='tanimlar/kargo'
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default SiteDetailTabGenel;
