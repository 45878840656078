import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isEmpty as _isEmpty,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  Tooltip,
  IconButton,
  Button
} from '@material-ui/core';

import {
  HelpOutline as IconHelp,
  Close as IconClose,
  Check as IconCheck
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import ListSelector from '../../../components/ListSelector';
import MultipleSelectList from '../../../components/MultipleSelectList';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutContainer2: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1, 1, 1, 4),
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 0, 1, 0),
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    layoutFormItemBox2: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 360,
      marginBottom: 5
    },
    keyDivContainer: {
      display: 'flex',
      maxWidth: 'fit-content'
    },
    keyDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 10,
      flexWrap: "wrap"
    },
    keyCloud: {
      padding: 4,
      border: "1px solid #eacfea",
      position: "relative",
      borderRadius: 4,
    },
    button3: {
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    layoutFormItemCaptionText: {
      fontStyle: 'italic',
      fontSize: "11px !important",
    },
  };
};

const EvrakDurumTanimDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);
  const [selectedDepartmanlar, setSelectedDepartmanlar] = useState([]);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);
  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);
  const [openSelectorMailSablonlar, setOpenSelectorMailSablonlar] = useState(false);
  const [openSelectorPdfSablonlar, setOpenSelectorPdfSablonlar] = useState(false);
  const [openSelectorMailSablonlarSite, setOpenSelectorMailSablonlarSite] = useState(false);
  const [openSelectorDepartman, setOpenSelectorDepartman] = useState(false);

  const [searchKey, setSearchKey] = useState("");
  const [searchKeyError, setSearchKeyError] = useState(null);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2) {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form[name], `${name2}`) || defaultValue,
        error: _has(props.error?.[name], `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form, `${name}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    }
  }, [props.error, props.form]);

  const handleRemoveMagazaById = (id) => {
    const copymagazalar = [...props.form.magazalar];
    const index = copymagazalar.findIndex((c) => c.id === id);
    if (index > -1) {
      copymagazalar.splice(index, 1);
      props.onChangeForm('magazalar', null, copymagazalar);
    }
  }

  const handleRemoveMailSablonById = (id) => {
    const copymailsablonlar = [...props.form.mail_sablonlar];
    const index = copymailsablonlar.findIndex((c) => c.id === id);
    if (index > -1) {
      copymailsablonlar.splice(index, 1);
      props.onChangeForm('mail_sablonlar', null, copymailsablonlar);
    }
  }

  const handleRemoveMailSablonSiteById = (id) => {
    const copymailsablonlarsite = [...props.form.mail_sablonlar_site];
    const index = copymailsablonlarsite.findIndex((c) => c.id === id);
    if (index > -1) {
      copymailsablonlarsite.splice(index, 1);
      props.onChangeForm('mail_sablonlar_site', null, copymailsablonlarsite);
    }
  }

  const handleRemovePdfSablonById = (id) => {
    const copypdfsablonlar = [...props.form.pdf_sablonlar];
    const index = copypdfsablonlar.findIndex((c) => c.id === id);
    if (index > -1) {
      copypdfsablonlar.splice(index, 1);
      props.onChangeForm('pdf_sablonlar', null, copypdfsablonlar);
    }
  }

  const handleAddOrRemoveSearchKeys = (type, value) => {
    let list = [...props.form.alici_mailleri];
    const val = value.trim();
    if (type === "add") {
      if (!list.includes(val) && val) {
        list.push(val);
        setSearchKey("");
        setSearchKeyError(null);
      } else {
        const errors = val ? "Aynı kelime birden fazla kullanılamaz" : null;
        setSearchKeyError(errors);
      }
    } else {
      list = list.filter(k => k !== val);
    }
    props.onChangeForm("alici_mailleri", null, list);
  }

  const handleRefreshOnOpenSiteSablonSelector = (x) => {
    setOpenSelectorMailSablonlarSite(x);
    props.setLastUpdateTimeList();
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="evrak_tip">
                      <label className={classes.layoutFormItemLabel} htmlFor="evrak_tip">Evrak Tip *</label>
                      <Tooltip title={props.error?.evrak_tip ? props.error.evrak_tip[0] : props.form?.evrak_tip === '-1' ? '' : !_isEmpty(props.tipListDic) ? props.tipListDic[props.form.evrak_tip] : ''}>
                        <TextField
                          {...createTextFieldProps('evrak_tip', null)}
                          onChange={(e) => props.onChangeForm('evrak_tip', null, e.target.value)}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'evrak_tip', true, false, false, '', '', '')}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option disabled value="-1">Seçilmedi</option>
                          {props.tipList.map((et) => (
                            <option key={et.key} value={et.key}>{et.value}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : props.form?.tanim == null ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim', null)}
                          onChange={(e) => props.onChangeForm('tanim', null, e.target.value)}
                          onBlur={() => handleInputErrors('evrakdurumtanim', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Durum tanım', 'durum')}
                          inputProps={{
                            maxLength: 250,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="gonderen_mail_email">
                      <label className={classes.layoutFormItemLabel} htmlFor="gonderen_mail_email">Gönderen Eposta *</label>
                      <Tooltip title={props.error?.gonderen_mail?.email ? props.error.gonderen_mail.email[0] : _isNil(props.form?.gonderen_mail?.email) ? '' : props.form.gonderen_mail.email}>
                        <TextField
                          {...createTextFieldProps('gonderen_mail', 'email')}
                          onChange={(e) => props.onChangeForm('gonderen_mail', 'email', e.target.value)}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'gonderen_mail', true, false, false, '', '', '', 'email')}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="gonderen_mail_baslik">
                      <label className={classes.layoutFormItemLabel} htmlFor="gonderen_mail_baslik">Gönderen Eposta Görünecek Başlık</label>
                      <Tooltip title={props.error?.gonderen_mail?.baslik ? props.error.gonderen_mail.baslik[0] : _isNil(props.form?.gonderen_mail?.baslik) ? '' : props.form.gonderen_mail.baslik}>
                        <TextField
                          {...createTextFieldProps('gonderen_mail', 'baslik')}
                          onChange={(e) => props.onChangeForm('gonderen_mail', 'baslik', e.target.value)}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="siralama">
                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                      <Tooltip title={props.error?.siralama ? props.error.siralama[0] : props.form?.siralama == null ? '' : props.form.siralama}>
                        <TextField
                          {...createTextFieldProps('siralama', null)}
                          onChange={(e) => props.onChangeForm('siralama', null, e.target.value)}
                          type="number"
                          inputProps={{
                            min: -2147483648,
                            max: 2147483647,
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItemBox}>
                      <MultipleSelectList
                        headername="MAĞAZALAR *"
                        name="Mağazalar"
                        name2="Mağaza"
                        form={props.form}
                        list={props.form?.magazalar ?? []}
                        listlength={props?.form?.magazalar?.length ?? 0}
                        opendialog={setOpenSelectorMagaza}
                        keyProp="id"
                        valueProp="tanim"
                        removefonk={handleRemoveMagazaById}
                        loading={props.loading}
                        error={props.error?.magazalar ? props.error.magazalar[0] : null}
                        newPath="tanimlar/magaza"
                        ustId={false}
                      />
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="mail_gondersin">
                      <label className={classes.layoutFormItemLabel} htmlFor="mail_gondersin">Eposta gönderilsin</label>
                      <Checkbox
                        name="mail_gondersin"
                        color="primary"
                        checked={_result(props.form, 'mail_gondersin', false)}
                        onChange={(e, checked) => props.onChangeForm('mail_gondersin', null, checked)}
                      ></Checkbox>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText('“Evrak bu duruma geçince alıcı epostalarına eposta gönderilir.”');
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                    <li className={classes.layoutFormItem} id="kullaniciya_mail_gondersin">
                      <label className={classes.layoutFormItemLabel} htmlFor="kullaniciya_mail_gondersin">Kullanıcıya eposta gönderilsin</label>
                      <Checkbox
                        name="kullaniciya_mail_gondersin"
                        color="primary"
                        checked={_result(props.form, 'kullaniciya_mail_gondersin', false)}
                        onChange={(e, checked) => props.onChangeForm('kullaniciya_mail_gondersin', null, checked)}
                      ></Checkbox>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText('“Evrak bu duruma geçince evrak kullanıcı epostasına eposta gönderilir.”');
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', null, checked)}
                      ></Checkbox>
                    </li>
                    <li className={classes.layoutFormItem} id="varsayilan">
                      <label className={classes.layoutFormItemLabel} htmlFor="varsayilan">Varsayılan</label>
                      <Checkbox
                        name="varsayilan"
                        color="primary"
                        checked={_result(props.form, 'varsayilan', false)}
                        onChange={(e, checked) => props.onChangeForm('varsayilan', null, checked)}
                      ></Checkbox>
                    </li>
                  </ul>
                </div>
                <div className={classes.layoutContainer2}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItemBox2}>
                      <MultipleSelectList
                        headername="EPOSTA ŞABLONLARI *"
                        name="Eposta şablonları"
                        name2="Eposta şablonu"
                        form={props.form}
                        list={props.form?.mail_sablonlar ?? []}
                        listlength={props?.form?.mail_sablonlar?.length ?? 0}
                        opendialog={setOpenSelectorMailSablonlar}
                        keyProp="id"
                        valueProp="tanim"
                        removefonk={handleRemoveMailSablonById}
                        loading={props.loading}
                        error={props.error?.mail_sablonlar ? props.error.mail_sablonlar[0] : null}
                        newPath="tanimlar/icerik_sablon"
                        ustId={false}
                      />
                    </li>
                    <li className={classes.layoutFormItem} id="mail_sablon">
                      <label className={classes.layoutFormItemLabel} htmlFor="mail_sablon">Eposta Varsayılan Şablon *</label>
                      <Tooltip title={props.error?.mail_sablon ? props.error.mail_sablon[0] : props.form?.mail_sablon == null ? '' : props.form.mail_sablon_adi}>
                        <TextField
                          {...createTextFieldProps('mail_sablon', null)}
                          onChange={(e) => props.onChangeForm('mail_sablon', null, e.target.value)}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'mail_sablon', true, false, false, '', '', '')}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option disabled value="-1">Seçilmedi</option>
                          {props.form.mail_sablonlar.map((bt) => (
                            <option key={bt.id} value={bt.id}>{bt.tanim}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText('“Panelden oluşturulan evraklar için kullanılacak varsayılan eposta şablonudur.”');
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                  </ul>
                  {(props.form.evrak_tip !== "13" && props.form.evrak_tip !== "14" && props.form.evrak_tip !== "15" && props.form.evrak_tip !== "17" && props.form.evrak_tip !== "-1") &&
                    <ul className={classes.layoutFormList}>
                      <li className={classes.layoutFormItemBox2}>
                        <MultipleSelectList
                          headername="SİTE EPOSTA ŞABLONLARI"
                          name="Site eposta şablonları"
                          name2="Site eposta şablonu"
                          form={props.form}
                          list={props.form?.mail_sablonlar_site ?? []}
                          listlength={props?.form?.mail_sablonlar_site?.length ?? 0}
                          opendialog={handleRefreshOnOpenSiteSablonSelector}
                          keyProp="id"
                          valueProp="tanim"
                          removefonk={handleRemoveMailSablonSiteById}
                          loading={props.loading}
                          error={props.error?.mail_sablonlar_site ? props.error.mail_sablonlar_site[0] : null}
                          newPath={null}
                          ustId={false}
                        />
                      </li>
                      <li className={classes.layoutFormItem} id="mail_sablon_site">
                        <label className={classes.layoutFormItemLabel} htmlFor="mail_sablon_site">Site Eposta Varsayılan Şablon</label>
                        <Tooltip title={props.error?.mail_sablon_site ? props.error.mail_sablon_site[0] : props.form?.mail_sablon_site == null ? '' : props.form.mail_sablon_site_adi}>
                          <TextField
                            {...createTextFieldProps('mail_sablon_site', null)}
                            onChange={(e) => props.onChangeForm('mail_sablon_site', null, e.target.value)}
                            select
                            SelectProps={{
                              native: true,
                            }}
                          >
                            <option disabled value="-1">Seçilmedi</option>
                            {props.form.mail_sablonlar_site.map((bt) => (
                              <option key={bt.id} value={bt.id}>{bt.tanim}</option>
                            ))}
                          </TextField>
                        </Tooltip>
                        <IconButton
                          size="small"
                          className={classes.button2}
                          disableRipple
                          onClick={() => {
                            setHelpText('Siteden oluşturulan evraklar için kullanılacak varsayılan eposta şablonudur.”');
                            setHelpOpen(true)
                          }}
                        ><IconHelp /></IconButton>
                      </li>
                    </ul>
                  }
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItemBox2}>
                      <MultipleSelectList
                        headername="PDF ŞABLONLARI"
                        name="Pdf şablonları"
                        name2="Pdf şablonu"
                        form={props.form}
                        list={props.form?.pdf_sablonlar ?? []}
                        listlength={props?.form?.pdf_sablonlar?.length ?? 0}
                        opendialog={setOpenSelectorPdfSablonlar}
                        keyProp="id"
                        valueProp="tanim"
                        removefonk={handleRemovePdfSablonById}
                        loading={props.loading}
                        error={props.error?.pdf_sablonlar ? props.error.pdf_sablonlar[0] : null}
                        newPath="tanimlar/icerik_sablon"
                        ustId={false}
                      />
                    </li>
                    <li className={classes.layoutFormItem} id="pdf_sablon">
                      <label className={classes.layoutFormItemLabel} htmlFor="pdf_sablon">Pdf Varsayılan Şablon</label>
                      <Tooltip title={props.error?.pdf_sablon ? props.error.pdf_sablon[0] : props.form?.pdf_sablon == null ? '' : props.form.pdf_sablon_adi}>
                        <TextField
                          {...createTextFieldProps('pdf_sablon', null)}
                          onChange={(e) => props.onChangeForm('pdf_sablon', null, e.target.value)}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option disabled value="-1">Seçilmedi</option>
                          {props.form.pdf_sablonlar.map((bt) => (
                            <option key={bt.id} value={bt.id}>{bt.tanim}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText('“Pdf şablonları için kullanılacak varsayılan pdf şablonudur.”');
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                  </ul>
                </div>
                <div className={classes.layoutContainerTab}>
                  <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">ALICI EPOSTALARI</span></div>
                  <div className={classes.layoutContainer}>
                    <div className='flex flex-col gap-5 w-full'>
                      <div className={classes.layoutFormList}>
                        <div className={classes.layoutFormItem} id="alici_mailleri_add">
                          <label className={classes.layoutFormItemLabel} htmlFor="alici_mailleri_add">Alıcı Epostası Ekle</label>
                          <Tooltip title={searchKeyError ? searchKeyError : searchKey == null ? '' : searchKey}>
                            <TextField
                              name='alici_mailleri_add'
                              variant='outlined'
                              size='small'
                              value={searchKey}
                              fullWidth={true}
                              onChange={(e) => setSearchKey(e.target.value)}
                              error={searchKeyError ? true : false}
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  handleAddOrRemoveSearchKeys("add", searchKey)
                                }
                              }}
                              InputProps={{
                                endAdornment:
                                  <Tooltip title="Ekle">
                                    <IconButton
                                      size="small"
                                      disableRipple
                                      className={classes.button}
                                      onClick={() => handleAddOrRemoveSearchKeys("add", searchKey)}
                                    >
                                      <IconCheck />
                                    </IconButton>
                                  </Tooltip>
                              }}
                              inputProps={{ maxLength: 50 }}
                            ></TextField>
                          </Tooltip>
                        </div>
                        <div className={classes.layoutFormItem}>
                          <label className={classes.layoutFormItemLabel}></label>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disableElevation
                            onClick={() => setOpenSelectorDepartman(true)}
                          >Departmandan Ekle</Button>
                        </div>
                      </div>
                      <div className={classes.keyDivContainer}>
                        <div className={classes.keyDiv}>
                          {props?.form?.alici_mailleri && props.form.alici_mailleri.map((key, index) => (
                            <div className={classes.keyCloud} key={index}>
                              <span>{key}</span>
                              <Tooltip title="Sil">
                                <IconButton
                                  size="small"
                                  disableRipple
                                  className={classes.button3}
                                  onClick={() => handleAddOrRemoveSearchKeys("delete", key)}
                                >
                                  <IconClose />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {openSelectorMailSablonlar &&
                <Dialog
                  open={openSelectorMailSablonlar}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorMailSablonlar(false)
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'mail_sablonlar', true, false, false, '', '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Eposta Şablonları'
                      listPath='iceriksablon/mini_list?aktif=true&tip=2'
                      listCountPath='iceriksablon/count?aktif=true&tip=2'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.mail_sablonlar}
                      onChangeItems={(items) => props.onChangeForm('mail_sablonlar', null, items)}
                      onClose={() => {
                        setOpenSelectorMailSablonlar(false)
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'mail_sablonlar', true, false, false, '', '', '');
                      }}
                      newPath='tanimlar/icerik_sablon'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorMailSablonlarSite &&
                <Dialog
                  open={openSelectorMailSablonlarSite}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorMailSablonlarSite(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Site Eposta Şablonları'
                      listPath='null'
                      listCountPath='null'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.mail_sablonlar_site}
                      onChangeItems={(items) => props.onChangeForm('mail_sablonlar_site', null, items)}
                      onClose={() => setOpenSelectorMailSablonlarSite(false)}
                      staticdata={props.form?.evrak_tip === "-1" ? [] : (props.form?.evrak_tip === "12" || props.form?.evrak_tip === "16") ? props.tempListOdeme : props.tempListSiparis}
                      setLastUpdateTimeStaticdata={props.setLastUpdateTimeList}
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorPdfSablonlar &&
                <Dialog
                  open={openSelectorPdfSablonlar}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorPdfSablonlar(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Eposta Şablonları'
                      listPath='iceriksablon/mini_list?aktif=true&tip=3'
                      listCountPath='iceriksablon/count?aktif=true&tip=3'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.pdf_sablonlar}
                      onChangeItems={(items) => props.onChangeForm('pdf_sablonlar', null, items)}
                      onClose={() => setOpenSelectorPdfSablonlar(false)}
                      newPath='tanimlar/icerik_sablon'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorMagaza(false)
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'magazalar', true, false, false, '', '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Mağazalar'
                      listPath='magaza/mini_list?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.magazalar}
                      onChangeItems={(items) => props.onChangeForm('magazalar', null, items)}
                      onClose={() => {
                        setOpenSelectorMagaza(false)
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'magazalar', true, false, false, '', '', '');
                      }}
                      newPath='tanimlar/magaza'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorDepartman &&
                <Dialog
                  open={openSelectorDepartman}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setSelectedDepartmanlar([]);
                    setOpenSelectorDepartman(false);
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Departmanlar'
                      listPath='departman/?aktif=true'
                      listCountPath='departman/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      extraProp='kullanicilar'
                      defaultItems={selectedDepartmanlar}
                      onChangeItems={(items) => setSelectedDepartmanlar(items)}
                      onClose={() => {
                        setSelectedDepartmanlar([]);
                        setOpenSelectorDepartman(false);
                      }}
                      newPath='tanimlar/departman'
                      confirmButton={true}
                      confirmEnable={props.loadingMailAdd ? false : true}
                      confirmBtnTitle="EKLE"
                      onConfirm={() => props.handleGetKullaniciFromDepartman(selectedDepartmanlar)}
                    />
                  </DialogContent>
                </Dialog>
              }
              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default EvrakDurumTanimDetailTabGenel;
