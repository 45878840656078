import React, { useState, useLayoutEffect, useRef, useMemo } from 'react';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import clsx from 'clsx';
import {
  Divider,
  CircularProgress,
  Paper,
  ClickAwayListener,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
} from '@material-ui/icons';

import django from '../../api/django';

const PER_PAGE = 100;
const ROW_HEIGHT = 34;

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>
      <p className="font-medium mb-2">{text}</p>
    </div>
  );
};

const ListItem = ({ item, onClick }) => {
  return (
    <div
      key={`${item.id}${item.tanim}`}
      className={clsx('w-full flex items-center justify-start hover:bg-palette-background-default active:bg-palette-action-hover px-4 space-x-3 select-none cursor-pointer')}
      style={{ height: ROW_HEIGHT }}
      onClick={onClick}
      tabIndex="0"
    >
      <span style={{ color: !item.tamamlandi ? "#976797" : "initial" }}>{item.tanim}</span>
    </div>
  );
};

const DropList = ({ onClickItem, onClickAway }) => {

  const [listItems, setListItems] = useState([]);
  const [listItemsLoading, setListItemsLoading] = useState(false);
  const [listItemsLoadingErrorMessage, setListItemsLoadingErrorMessage] = useState(null);
  const [listItemsTotalCount, setListItemsTotalCount] = useState(0);
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);

  const mainContainerRef = useRef();

  const PAGE = useMemo(() => listItems.length > 0 ? Math.ceil(listItems.length / PER_PAGE) : 1, [listItems.length]);
  const PAGES_COUNT = useMemo(() => Math.ceil(listItemsTotalCount / PER_PAGE), [listItemsTotalCount]);

  const LOADING_MESSAGE = useMemo(() => {
    if (listItemsLoading) {
      return 'Bildirimler yükleniyor';
    }
    return null;
  }, [listItemsLoading]);

  const ERROR_MESSAGE = useMemo(() => {
    if (listItemsLoadingErrorMessage) {
      return listItemsLoadingErrorMessage;
    }
    return null;
  }, [listItemsLoadingErrorMessage]);

  useLayoutEffect(() => {
    setListItemsLoading(true);
    django('iliskilitanim', { params: { size: PER_PAGE } }).then(({ data }) => {
      const list = data.sort((a, b) => a.tamamlandi - b.tamamlandi);
      setListItems(list);
    }).catch(() => {
      setListItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
    }).finally(() => {
      setListItemsLoading(false);
    });
  }, []);

  useLayoutEffect(() => {
    django('iliskilitanim/count').then(({ data }) => {
      setListItemsTotalCount(data);
    });
  }, []);

  const handleGetMoreOptions = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setMoreItemsLoading(true);
    django('iliskilitanim', { params: { size: PER_PAGE, page } }).then(({ data }) => {
      setListItems((prev) => [...prev, ...data].sort((a, b) => a.tamamlandi - b.tamamlandi));
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setMoreItemsLoading(false);
    });
  };

  const rowRenderer = ({ index, style }) => {
    const item = listItems[index];
    return (
      <div key={item.id} style={style}>
        {index !== 0 && <Divider />}
        <ListItem
          item={item}
          onClick={() => onClickItem(item)}
        ></ListItem>
      </div>
    );
  };

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => onClickAway()}>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <main className="relative flex flex-grow flex-col overflow-hidden" ref={mainContainerRef}>
          <div className="h-full flex-grow outline-none"
          >
            {LOADING_MESSAGE && (
              <LoadingMessage text={LOADING_MESSAGE} />
            )}

            {ERROR_MESSAGE && (
              <ErrorMessage text={ERROR_MESSAGE} />
            )}

            {(!LOADING_MESSAGE && !ERROR_MESSAGE && listItems.length === 0) && (
              <ErrorMessage text="Bildirim yok" />
            )}

            {(!LOADING_MESSAGE && !ERROR_MESSAGE && listItems.length > 0) && (
              <InfiniteLoader
                minimumBatchSize={PER_PAGE}
                threshold={PER_PAGE}
                isRowLoaded={({ index }) => !!listItems[index]}
                loadMoreRows={({ startIndex, stopIndex }) => {
                  if (listItemsTotalCount > 0 && PAGES_COUNT > PAGE && !moreItemsLoading) {
                    handleGetMoreOptions(PAGE + 1);
                  }
                }}
                rowCount={listItemsTotalCount > 0 ? listItemsTotalCount : PER_PAGE * 2}
              >
                {({ onRowsRendered, registerChild }) => (
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        width={width}
                        height={height}
                        rowHeight={ROW_HEIGHT + 1}
                        rowCount={listItems.length}
                        estimatedRowSize={PAGES_COUNT > 0 ? PAGES_COUNT * (ROW_HEIGHT + 1) : undefined}
                        rowRenderer={rowRenderer}
                        onRowsRendered={onRowsRendered}
                        ref={registerChild}
                      />
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            )}
          </div>
        </main>
      </Paper>
    </ClickAwayListener>
  )


}

export default DropList;