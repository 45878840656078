import React, { useState, useLayoutEffect, useMemo, useCallback, useRef } from 'react';
import { useSnackbar } from 'notistack';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Typography,
  Checkbox,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { handleInputErrors2 } from '../../base/InputErrors';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { Hata } from '../../base/Hata';


const styles = ({ spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        margin: spacing(4, 0, 0, 0),
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    column2: {
      display: 'flex',
      flexDirection: 'column',
      '& + &': {
        margin: spacing(0, 0, 0, 4),
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },

    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: spacing(0, 0, 4, 0),
      padding: 0,
    },
    selectItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& + &': {
        marginTop: 10,
      },
    },

    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& + &': {
        marginLeft: 10,
      },
    },
    imagediv: {
      display: 'flex',
      width: 400,
      height: 400
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'scale-down',
      padding: spacing(4, 4, 4, 4)
    }
  };
};

const SablonDetailTabDiger = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);
  const inputRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.form.dosya.dosya_ismi,
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);

  useLayoutEffect(() => {
    if (!props.kosulAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.kosulAdd, COUNT_TABS]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    if (name === 'dosya_ismi') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(FORM?.dosya, `${name}`) || defaultValue,
        error: _has(ERROR?.dosya, `${name}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(FORM, `${name}`) || defaultValue,
        error: _has(ERROR, `${name}`),
        fullWidth: true,
      };
    }
  }, [FORM, ERROR]);

  const handleChangeForm = (property, property2, value) => {
    props.onChangeForm(selectedTabIndex, property, property2, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const dosyaEkle = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const filetype = file.name.split('.').pop();
      const filesize = file.size;
      if (filesize > 256000) {
        enqueueSnackbar('Dosya boyutu 250 kb tan fazla olamaz!', { variant: 'error' })
      } else {
        if (filetype === 'jpg' || filetype === 'jpeg' || filetype === 'png') {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            handleChangeForm('dosya', '', { dosya: reader.result, dosya_ismi: file.name })
          }
        } else {
          enqueueSnackbar('Sadece .jpeg,.jpg ve .png uzantılı dosyalar eklenebilir!', { variant: 'error' })
        }
      }
    }
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <LayoutDetailVerticalTab
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                add={props.onAddNewTab}
                buttonText={"YENİ DOSYA EKLE"}
                buttonShow={props.digerAdd}
                delete={handleDetailDelete}
                flexDirection='column'
              >
                {COUNT_TABS === 0 && (
                  <div className="flex flex-col items-start justify-start p-4 space-y-4">
                    <Typography variant="body1">
                      Şablon dosyalarına ait bir dosya bulunamadı.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      onClick={props.onAddNewTab}
                    >YENİ DOSYA EKLE</Button>
                  </div>
                )}

                {COUNT_TABS > 0 && (
                  <div className={classes.tabDiv}>
                    <div className={classes.column}>
                      <ul className={classes.formList}>
                        <li className={classes.formItem} id="dosya_ismi">
                          <label className={classes.formItemLabel} htmlFor="dosya_ismi">Dosya Adı *</label>
                          <Tooltip title={ERROR?.dosya?.dosya_ismi ? ERROR.dosya.dosya_ismi[0] : _isNil(FORM?.dosya?.dosya_ismi) ? '' : FORM.dosya.dosya_ismi}>
                            <TextField
                              {...createTextFieldProps('dosya_ismi')}
                              onChange={(e) => handleChangeForm('dosya', 'dosya_ismi', e.target.value)}
                              onBlur={() => handleInputErrors2(FORM, 'dosya', handleSetErrors, 'dosya_ismi')}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.formItem} id="mobil_web">
                          <label className={classes.formItemLabel} htmlFor="mobil_web">Webde Kullanılsın</label>
                          <Checkbox
                            name="mobil_web"
                            color="primary"
                            checked={FORM?.mobil_web?.includes(0) ? true : false}
                            onChange={(e, checked) => handleChangeForm('mobil_web', 'web', checked)}
                          ></Checkbox>
                        </li>
                        <li className={classes.formItem} id="mobil_web">
                          <label className={classes.formItemLabel} htmlFor="mobile">Mobilde Kullanılsın</label>
                          <Checkbox
                            name="mobile"
                            color="primary"
                            checked={FORM?.mobil_web?.includes(1) ? true : false}
                            onChange={(e, checked) => handleChangeForm('mobil_web', 'mobil', checked)}
                          ></Checkbox>
                        </li>

                        <li className={classes.formItem}>
                          <div className={classes.formItemLabel} style={{ maxWidth: "none" }}>
                            <Button
                              className={classes.button}
                              component="label"
                            >RESİM EKLE<input
                                type="file"
                                accept=".png,.jpeg,.jpg"
                                hidden
                                ref={inputRef}
                                onClick={() => inputRef.current.value = ""}
                                onChange={(e) => dosyaEkle(e)}
                              />
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className={classes.column2} style={{ borderWidth: 1, borderStyle: 'solid', borderColor: _has(ERROR, `dosya.dosya`) ? 'red' : 'transparent' }}>
                      {_has(ERROR, `dosya.dosya`) && (
                        <div className={classes.imagediv}>
                          <Typography variant="caption" color="error">{_result(ERROR, `dosya.dosya[0]`)}</Typography>
                        </div>
                      )}
                      <div className={classes.imagediv}>
                        {FORM?.dosya && FORM?.dosya?.dosya !== '' && (
                          <img className={classes.image} src={FORM.dosya.dosya} alt={FORM.dosya.dosya_ismi} />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </LayoutDetailVerticalTab>
            </div>
          )}
        </>
      }

    </>
  );
});

export default SablonDetailTabDiger;
