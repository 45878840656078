import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach, SlugMaker } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './GrupDetailTab_Genel';

const PATH = 'grup';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  tanim: '',
  tip: '0',
  siralama: '0',
  aktif: true,
};

const DILLER_FORM_DEFAULT = {
  dil_icerik: {
    baslik: '',
    aciklama: '',
    bilgilendirme: '',
    kisa_aciklama: '',
    anahtar_kelimeler: '',
    tanim: '',
  },
  slug: '',
  dil: 0,
};

const createDillerStates = async (diller, grupDiller) => {
  const states = [];
  diller.sort((a, b,) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...DILLER_FORM_DEFAULT,
        dil: dil.id,
      },
      errors: null,
    };

    const grupdil = grupDiller?.find((gd) => gd.dil === dil.id);
    if (grupdil) {
      data.form.dil_icerik = grupdil.dil_icerik;
      data.form.id = grupdil.id;
      data.form.slug = grupdil.slug;
    }
    states.push(data);
  });
  return states;
};

const GrupDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingDil, setLoadingDil] = useState(true);
  const [loadingDilSet, setLoadingDilSet] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataDil, setHataDil] = useState(null);

  const [add, setAdd] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [dillerSelectedTabIndex, setDillerSelectedTabIndex] = useState(0);
  const [dillerFormDefault, setDillerFormDefault] = useState(null);
  const [dillerForm, setDillerForm] = useState(null);
  const [dillerFormAdd, setDillerFormAdd] = useState(null);
  const [grupDilleri, setGrupDilleri] = useState([]);

  const [dilMiniList, setDilMiniList] = useState([]);
  const [tipList, setTiplist] = useState([]);
  const [tipListDic, setTiplistDic] = useState({});

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteId, setDeleteIdDetail] = useState(null);
  const [showDeleteDialogDetail, setShowDeleteDialogDetail] = useState(false);
  const [isDeletingDetail, setIsDeletingDetail] = useState(false);
  const [isDeletingErrorMessageDetail, setIsDeletingErrorMessageDetail] = useState(null);

  const [saveErrors, setSaveErrors] = useState([[], []]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(dillerForm, dillerFormAdd);
    }
    return _isEqual(genelForm, genelFormDefault) && _isEqual(dillerForm, !saveErrors[1].includes("err") ? dillerFormDefault : dillerFormAdd);
  }, [add, genelForm, genelFormDefault, dillerForm, dillerFormDefault, dillerFormAdd, saveErrors]);

  const LOADINGDIL = useMemo(() => {
    if (!loadingDil && !loadingDilSet) {
      return false;
    }
    return true;
  }, [loadingDil, loadingDilSet]);

  const LOADING = useMemo(() => {
    if (!loading && !LOADINGDIL) {
      return false;
    }
    return true;
  }, [loading, LOADINGDIL]);

  useLayoutEffect(() => {
    if (props.eventType === 0) {
      setSaveErrors([[], []]);
      setDillerSelectedTabIndex(0);
      setTabs(TABS_DEFAULT);
    }
  }, [props.eventType, ID]);

  useLayoutEffect(() => {
    if (!saveErrors[0].includes("err")) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              tip: String(data.tip.key),
              siralama: String(data.siralama),
              aktif: data.aktif,
            };
            setGenelFormDefault(form);
            setGenelForm(form);
            setHataGenel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setAdd(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DEFAULT);
        setGenelFormDefault(FORM_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setLoading(false);
      }
    }
  }, [ID, lastUpdateTime, IS_ADD, saveErrors]);

  useLayoutEffect(() => {
    if (!saveErrors[1].includes("err")) {
      setLoadingDil(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`grup/${ID}/grupdil`).then(({ data }) => {
            setGrupDilleri(data);
            setHataDil(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataDil(true);
              }
            }
          }).finally(() => {
            setLoadingDil(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setGrupDilleri([]);
        setHataDil(null);
        setLoadingDil(false);
      }
    }
  }, [ID, lastUpdateTime, IS_ADD, saveErrors]);

  useLayoutEffect(() => {
    django('dil/mini_list?aktif=true').then(({ data }) => setDilMiniList(data)).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataDil(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!saveErrors[1].includes("err")) {
      setLoadingDilSet(true);
      createDillerStates(dilMiniList, grupDilleri).then((states) => {
        setDillerFormDefault(states);
        setDillerForm(states);
      }).finally(() => {
        setLoadingDilSet(false);
      });
    }
  }, [dilMiniList, grupDilleri, saveErrors]);

  useLayoutEffect(() => {
    createDillerStates(dilMiniList, []).then((states) => {
      setDillerFormAdd(states);
    })
  }, [dilMiniList]);

  useLayoutEffect(() => {
    django('grup/tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setTiplistDic(dict);
      setTiplist(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleDillerChangeForm = (index, property, property2, value) => {
    if (property2 === 'tanim') {
      const slug = SlugMaker(value);
      setDillerForm((prev) => {
        return immer(prev, (next) => {
          next[index].form[property][property2] = value;
          next[index].form['slug'] = slug;
        })
      });
    } else {
      setDillerForm((prev) => {
        return immer(prev, (next) => {
          next[index].form[property][property2] = value;
        })
      });
    }
  }

  const handleAdd = () => {
    setAdd(true);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setDillerForm(dillerFormAdd);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setSaveErrors([[], []]);
    setLastUpdateTime(Date.now());
  };

  const handleCancel = () => {
    setSaveErrors([[], []]);
    setGenelForm(genelFormDefault);
    setGenelError(null);
    setDillerForm(dillerFormDefault);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Grup Tanım', pagename: 'grup', prop2: '' },
    ]
    let errors = [];
    await checkAllList('grup', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {
    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (add ? _isEqual(genelForm, FORM_DEFAULT) : _isEqual(genelForm, genelFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            reqopt = {
              data: { ...genelForm, tip: Number(genelForm.tip), siralama: genelForm.siralama ? Number(genelForm.siralama) : 0 },
              method: add ? 'POST' : 'PUT',
              id: null,
              successMessage: `Grup ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Grup ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    };

    const createDilRequestOption = async (diller, dillerDefault, index) => {
      let reqopt = null;
      let err = null;
      const isEqual = _isEqual(diller, dillerDefault);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'slug', prop2: '' },
          { prop: 'dil_icerik', prop2: 'baslik' },
          { prop: 'dil_icerik', prop2: 'tanim' },
        ];
        await checkAllTabs(diller.form, (err) => handleErrorsDetail(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const isHas = _has(diller.form, 'id');
            if (isHas) {
              reqopt = {
                data: diller.form,
                method: 'PUT',
                id: diller.form.id,
                successMessage: `${diller.dil.tanim} içerik güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${diller.dil.tanim} içerik güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: diller.form,
                method: 'POST',
                id: null,
                successMessage: `${diller.dil.tanim} içerik ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${diller.dil.tanim} içerik eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err];
    };

    let errors = [[], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', requests: [], error: [] },
        { index: 1, name: 'diller', requests: [], error: [] }
      ];

      const res = await createRequestOptionsTabGenel();
      tabs[0].requests.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in dilMiniList) {
        const diller = [...dillerForm];
        const dillerDefault = add ? [...dillerFormAdd] : !saveErrors[1].includes("err") ? [...dillerFormDefault] : [...dillerFormAdd];
        const res = await createDilRequestOption(diller[i], dillerDefault[i], i)
        tabs[1].requests.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.requests, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "grup" : `grup/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.grup.detail(data.id,"detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (tab.name === 'diller') {
              setDillerForm((prev) => {
                return immer(prev, (next) => {
                  next[requestIndex].errors = null;
                });
              });
              if (SAVEID !== null) {
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `grup/${SAVEID}/grupdil/${request.id}` : `grup/${SAVEID}/grupdil`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setDillerSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setDillerForm((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      });
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }
    await start();
    if (SAVEID !== null) {
      setSaveErrors(errors);
      if (!add) {
        if (!errors[0].includes("err") || !errors[1].includes("err")) {
          setLastUpdateTime(Date.now());
        }
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? !errors[1].includes("err") ? false : true : true : true;
      });
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.grup.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteDetail = () => {
    setIsDeletingDetail(true);
    setIsDeletingErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/grupdil/${detailDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetail(false);
        setIsDeletingErrorMessageDetail(null);
      } else {
        setIsDeletingErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingDetail(false);
      setLastUpdateTime(Date.now());
    });
  }
  const handleDeleteDetailIndex = (index) => {
    setDillerForm((prev) => {
      return immer(prev, (next) => {
        next[index].form = { ...DILLER_FORM_DEFAULT, dil: next[index].form.dil };
        next[index].errors = null;
      })
    });
  }

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsDetail = (errors, index) => {
    setDillerForm((prev) => {
      let errobj = {};
      let err = {};
      if (Object.keys(errors)[0] === 'dil_icerik') {
        if (_has({ ...prev[index].errors }, Object.keys(errors)[0])) {
          let copyerr = { ...prev[index].errors }
          copyerr.dil_icerik = { ...copyerr.dil_icerik, ...errors.dil_icerik };
          errobj = { ...copyerr }
        } else {
          errobj = { ...prev[index].errors, ...errors }
        }
      } else {
        errobj = { ...prev[index].errors, ...errors }
      }

      if (_has(errobj, 'dil_icerik')) {
        const dilicerik = _pickBy(errobj['dil_icerik'], _identity);
        if (_isEmpty(dilicerik)) {
          err = _pickBy({ ...errobj, 'dil_icerik': null }, _identity);
        } else {
          err = _pickBy({ ...errobj, 'dil_icerik': dilicerik }, _identity);
        }
      } else {
        err = _pickBy(errobj, _identity);
      }

      return immer(prev, (next) => {
        next[index].errors = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  return (
    <>
      <LayoutDetail
        loading={LOADING}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={LOADING}
        disableAddButton={LOADING || add || IS_ADD}
        disableReloadButton={LOADING || add || IS_ADD}
        disableCancelButton={!add && (LOADING || IS_EQUAL) && !IS_ADD}
        disableSaveButton={LOADING || IS_EQUAL}
        disableDeleteButton={LOADING || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            loadingdil={LOADINGDIL}
            hata={hataGenel}
            hataDil={hataDil}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            tipList={tipList}
            tipListDic={tipListDic}
            onChangeForm={handleGenelChangeForm}
            onCheckErrors={(errors) => handleErrors(errors)}

            selectedTabIndex={dillerSelectedTabIndex}
            dillerForm={dillerForm}
            onChangeFormDiller={handleDillerChangeForm}
            setDeleteId={setDeleteIdDetail}
            openDeleteForm={setShowDeleteDialogDetail}
            deleteDetailWithIndex={handleDeleteDetailIndex}

            onCheckErrorsDiller={(errors, index) => handleErrorsDetail(errors, index)}
          />
        )}
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Grup siliniyor lütfen bekleyin'
                : 'Bu grubu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetail && detailDeleteId &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetail
              ? isDeletingErrorMessageDetail
              : isDeletingDetail
                ? 'Dil siliniyor lütfen bekleyin'
                : 'Bu dili gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetail}
          onSubmit={handleDeleteDetail}
          onCancel={() => setShowDeleteDialogDetail(false)}
        ></DialogConfirm>
      }
    </>
  );
};

export default GrupDetail;

