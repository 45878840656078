import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';
import moment from 'moment';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  assign as _assign,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';

import LayoutDetail from '../../base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './DepoCikisDetailTab_Genel';

const PATH = 'evrak';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  tip: '14',
  ust: [],
  kodu: '',
  versiyon: '0',
  evrak_tarihi: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
  aciklama: '',
  magaza: null,
};

const DETAY_DEFAULT = {
  miktar: '',
  depo: null,
  urun: null,
};

const DepoCikisDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingdetay, setLoadingDetay] = useState(false);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataDetay, setHataDetay] = useState(null);

  const [add, setAdd] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(FORM_DEFAULT);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [formCount, setFormCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [detayFormDefault, setDetayFormDefault] = useState([]);
  const [detayForm, setDetayForm] = useState([]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.kodu;
  }, [add, genelForm]);

  const IS_ADD = useMemo(() => props.addnew, [props]);

  const IS_EQUAL = useMemo(() => {
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualDetay = _isEqual(detayForm, detayFormDefault);
    return isEqualGenel && isEqualDetay;
  }, [genelForm, genelFormDefault, detayForm, detayFormDefault]);

  useLayoutEffect(() => {
    setDetayFormDefault([]);
    setDetayForm([]);
    setFormCount(0);
    setPage(1);
    setLastPage(1);
  }, [ID]);

  useLayoutEffect(() => {
    setLoading(true);
    const debounce = setTimeout(() => {
      if (!IS_ADD) {
        django(`${PATH}/${ID}`).then(({ data }) => {
          const form = {
            tip: String(data.tip.key),
            ust: data.ust.map(x => x.id),
            versiyon: String(data.versiyon),
            kodu: data.kodu,
            evrak_tarihi: moment(data.evrak_tarihi).format('YYYY-MM-DD[T]HH:mm'),
            aciklama: data.aciklama,
            magaza: data.magaza,
          };
          setGenelFormDefault(form);
          setGenelForm(form);
          setHataGenel(null);
        }).catch((err) => {
          if (err.response) {
            if (err.response.status === 500) {
              setHataGenel(true);
            }
          }
        }).finally(() => {
          setTabs(TABS_DEFAULT);
          setLoading(false);
          setGenelError(null);
          setAdd(false);
        });
      } else {
        setTabs(TABS_DEFAULT);
        setGenelForm(FORM_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setLoading(false);
      }
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoading(false);
    };
  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    if (!IS_ADD) {
      django(`${PATH}/${ID}/giriscikis/count`).then(({ data }) => {
        setTotalCount(data);
        setLastPage(Math.ceil(data / 100));
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            setHataDetay(true);
          }
        }
      });
    }
  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    setPage((prev) => immer(prev, (next) => {
      if (formCount > 0) {
        if (totalCount > formCount) {
          if (Math.ceil(formCount / 100) === next) {
            return next + 1
          } else {
            return Math.ceil(formCount / 100)
          }
        }
      }
    }));
  }, [totalCount, formCount]);

  useLayoutEffect(() => {
    setLoadingDetay(true);
    const debounce = setTimeout(() => {
      if (!IS_ADD) {
        django(`${PATH}/${ID}/giriscikis`, { params: { size: 100, page: page } }).then(({ data }) => {
          const forms = data.map((stok, index) => ({
            index: page === 1 ? index : (page - 1) * 100 + index,
            miktar: Number(stok.miktar) < 0 ? String((stok.miktar * -1).toFixed(2)) : stok.miktar,
            depo: stok.depo,
            urun: stok.urun,
          }));
          setDetayForm((prev) => immer(prev, (next) => {
            next.push.apply(next, forms);
          }));
          setDetayFormDefault((prev) => immer(prev, (next) => {
            setFormCount([...prev, ...forms].length);
            next.push.apply(next, forms);
          }));
          setHataDetay(false);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataDetay(true);
            }
          }
        }).finally(() => {
          if (lastPage === page) {
            setLoadingDetay(false);
          }
        });
      } else {
        setHataDetay(false);
        setLoadingDetay(false);
      }
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoadingDetay(false);
    };
  }, [ID, lastUpdateTime, IS_ADD, page, lastPage]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    if (page === lastPage) {
      switch (property) {
        case 'kodu':
          if (add) {
            setGenelForm((prev) => {
              return immer(prev, (next) => {
                next[property] = value;
              });
            });
          }
          break;
        case 'magaza':
          if (add && value) {
            django(`evrak/evrak_kod/${value.id}/${genelForm.tip}`).then(({ data }) => {
              setGenelForm((prev) => {
                return immer(prev, (next) => {
                  next["kodu"] = data?.value;
                });
              });
            });
          }
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next[property] = value;
            });
          });
          break;
        default:
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next[property] = value;
            });
          });
          break;
      }
    }
  };

  const handleDetayChangeForm = (index, property, value, search) => {
    const ui = detayForm.findIndex(x => x.index === index);
    if (property === 'urun') {
      if (search) {
        setDetayForm((prev) => {
          return immer(prev, (next) => {
            next[ui] = {
              ...DETAY_DEFAULT,
              index: next[ui].index,
              urun: {
                id: null,
                tanim: value,
              },
            };
          });
        });
      } else {
        if (value) {
          setDetayForm((prev) => {
            return immer(prev, (next) => {
              next[ui][property] = value;
            });
          });
        } else {
          setDetayForm((prev) => {
            return immer(prev, (next) => {
              next[ui] = { ...DETAY_DEFAULT, index: next[ui].index };
            });
          });
        }
      }
    } else {
      setDetayForm((prev) => {
        return immer(prev, (next) => {
          next[ui][property] = value;
        });
      });
    }
  };

  const handleDetayFormAddNew = () => {
    setDetayForm((prev) => immer(prev, (next) => {
      next.push({ ...DETAY_DEFAULT, index: detayForm.length })
    }));
  }

  const handleDetayFormDelete = (index) => {
    const dindex = detayForm.findIndex(x => x.index === index);
    setDetayForm((prev) => immer(prev, (next) => {
      next.splice(dindex, 1);
    }));
  }
  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DEFAULT);
    setDetayForm([]);
    setGenelError(null);
  };

  const handleReload = () => {
    setDetayForm([]);
    setDetayFormDefault([]);
    setFormCount(0);
    setPage(1);
    setLastUpdateTime(Date.now());
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setDetayForm(detayFormDefault);
    setGenelError(null);
    if (!IS_ADD) {
      setAdd(false);
    }
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.evrak.depocikis.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const checkAll = async () => {
    const list = [
      { prop: 'tip', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'kodu', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'evrak_tarihi', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'magaza', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ];
    const listdetay = [
      { prop: 'urun', prop2: '' },
      { prop: 'miktar', prop2: '' },
      { prop: 'depo', prop2: '' },
    ];

    let errors = [];
    await checkAllList('evrak', genelForm, genelFormDefault, (err) => handleErrors(err, null), add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);

    const length = detayForm.length;
    for (const i in detayForm) {
      const res = await checkAllList2(detayForm[i], (err) => handleErrors(err, i, length), listdetay);
      if (!_isEmpty(res)) {
        errors = [...errors, res];
      }
    }
    return errors;
  }

  const handleSave = () => {
    checkAll().then((res) => {
      if (_isEmpty(res)) {
        let requestoptions = {};
        const evrak = { ...genelForm, magaza: genelForm.magaza.id, tip: Number(genelForm.tip), aciklama: genelForm.aciklama.length <= 0 ? null : genelForm.aciklama, versiyon: add ? 0 : Number(genelForm.versiyon) + 1 }
        const detay = detayForm.map((d) => ({ miktar: d.miktar, urun: d.urun.id, depo: d.depo.id, tip: 1 }));
        const data = { evrak: evrak, giriscikis_detay: detay };

        if (IS_EQUAL) {
          requestoptions = null;
        } else {
          requestoptions = {
            config: {
              method: 'POST',
              url: 'evrak/giris_cikis',
              data: data
            },
            successMessage: `Evrak ekleme başarıyla tamamlandı`,
            errorMessageUnexpected: `Evrak eklenirken beklenmeyen bir hata oluştu`,
          }
        }

        const start = async () => {
          let errorCounts = 0;
          if (requestoptions !== null) {
            setGenelError(null);
            await django(requestoptions.config).then(({ data }) => {
              enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
              if (props.single) {
                history.push(URLS.evrak.depocikis.detail(data.id, "detay"));
              }
              if (!props.single) {
                props.onAdded();
              }
            }).catch((error) => {
              if (error.response) {
                if (error.response.status === 500) {
                  enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
                } else {
                  setGenelError(error.response.data);
                }
              } else {
                enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
              }
              ++errorCounts;
            });
            if (errorCounts === 0) {
              setDetayFormDefault([]);
              setDetayForm([]);
              setFormCount(0);
              setPage(1);
            }
          }
        }
        start();
      }
    })
  };

  const handleErrors = (errors, index, length) => {
    let obj = {}
    let next = {};
    setGenelError((prev) => {
      if (index === null) {
        if (_has({ ...prev }, 'evrak')) {
          _assign(prev['evrak'], errors);
          obj = { ...prev }
        } else {
          obj = { ...prev, evrak: { ...errors } }
        }
      } else {
        if (_has({ ...prev }, 'giriscikis_detay')) {
          prev['giriscikis_detay'].length = length;
          prev['giriscikis_detay'][index] = { ...prev['giriscikis_detay'][index], ...errors };
          obj = { ...prev }
        } else {
          let errobj = new Array(length).fill({});
          errobj[index] = { ...errors }
          obj = { ...prev, giriscikis_detay: errobj }
        }
      }

      if (_has(obj, "evrak")) {
        const evrak = _pickBy(obj["evrak"], _identity);
        if (_isEmpty(evrak)) {
          next = _pickBy({ ...obj, 'evrak': null }, _identity);
        } else {
          next = _pickBy({ ...obj, 'evrak': evrak }, _identity);
        }
      } else {
        let err = obj["giriscikis_detay"];
        if (err.length > 0) {
          for (let i = 0; i < err.length; i++) {
            err[i] = _pickBy(err[i], _identity);
          }
        }
        const list = _pickBy(err, _identity);
        if (_isEmpty(list)) {
          next = _pickBy({ ...obj, 'giriscikis_detay': null }, _identity);
        } else {
          next = _pickBy({ ...obj, 'giriscikis_detay': list }, _identity);
        }
      }

      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            loadingdetay={loadingdetay}
            hataGenel={hataGenel}
            hataDetay={hataDetay}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            detay={detayForm}
            error={genelError}
            onChangeForm={handleGenelChangeForm}
            onChangeFormDetay={handleDetayChangeForm}
            addDetay={handleDetayFormAddNew}
            deleteRow={handleDetayFormDelete}
            onCheckErrors={handleErrors}
          />
        )}

      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Depo çıkış siliniyor lütfen bekleyin'
                : 'Bu depo çıkışını gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
    </>
  );
}

export default DepoCikisDetail;