import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
	isEqual as _isEqual,
	pickBy as _pickBy,
	identity as _identity,
	has as _has,
	isEmpty as _isEmpty,
	trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './MenuDetailTab_Genel';

const PATH = 'menu';

const TABS_DEFAULT = [
	{ label: 'GENEL', disabled: false, hasError: false },
];

const FORM_DATA_DEFAULT = {
	tanim: '',
	aktif: true,
};

const MENU_ICERIK_DATA_DEFAULT = {
	tanim: 'Tanımsız',
	kategori: null,
	ozellik_baslik: {},
	ozellik_deger: {},
	ozellik_deger_baslik: null,
	ozellik_tree_list: [],
	sabit_sayfa: {},
	ozel_link_icerik: [],
	siralama: '0',
	diller: [],
	foto: {},
	ust: null,
	index: null,
	aktif: true,
};

const DILLER_FORM_DEFAULT = {
	dil_id: '',
	tanim: '',
	kisa_tanim: ''
};

const createDillerStatesIcerik = async (diller, icerikdiller) => {
	const states = [];
	diller.sort((a, b,) => a.id - b.id).forEach((dil) => {
		const data = {
			dil: {
				id: dil.id,
				tanim: dil.tanim,
			},
			form: {
				...DILLER_FORM_DEFAULT,
				dil_id: dil.id,
			},
			errors: null,
		};
		const icerikdil = icerikdiller?.find((vd) => vd.dil_id === dil.id);
		if (icerikdil) {
			data.form.dil_id = icerikdil.dil_id;
			data.form.tanim = icerikdil.tanim;
			data.form.kisa_tanim = icerikdil.kisa_tanim;
		}
		states.push(data);
	});
	return states;
};

const createMenuTree = (list, id = null) => {
	let liste = [];
	liste =
		list
			.filter(item => item.form['ust'] === id)
			.map(item => {
				if (item.form.id) {
					return ({ ...item, children: createMenuTree(list, item.form.id) })
				} else {
					return ({ ...item, children: [] })
				}
			});
	return liste
}

const MenuTanimDetail = (props) => {
	const [loading, setLoading] = useState(true);
	const [loadingIcerik, setLoadingIcerik] = useState(true);
	const [loadingDilIcerik, setLoadingDilIcerik] = useState(true);

	const [hataGenel, setHataGenel] = useState(null);
	const [hataIcerik, setHataIcerik] = useState(null);
	const [hataDil, setHataDil] = useState(null);

	const [lastUpdateTime, setLastUpdateTime] = useState(0);
	const [add, setAdd] = useState(false);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [tabs, setTabs] = useState(TABS_DEFAULT);

	const [genelFormDefault, setGenelFormDefault] = useState(FORM_DATA_DEFAULT);
	const [genelForm, setGenelForm] = useState(null);
	const [genelError, setGenelError] = useState(null);

	const [menuIceriklerSelectedTabIndex, setMenuIceriklerSelectedTabIndex] = useState(null);
	const [menuIceriklerFormsDefault, setMenuIceriklerFormsDefault] = useState([]);
	const [menuIceriklerForms, setMenuIceriklerForms] = useState([]);
	const [menuAdd, setMenuAdd] = useState(false);

	const [dilMiniList, setDilMiniList] = useState([]);
	const [icerikDiller, setIcerikDiller] = useState([]);
	const [dillerSelectedTabIndexIcerik] = useState(0);
	const [dillerFormDefaultIcerik, setDillerFormDefaultIcerik] = useState(null);
	const [dillerFormIcerik, setDillerFormIcerik] = useState(null);

	const [menuTree, setMenuTree] = useState([]);
	const [isActive, setActive] = useState([]);

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

	const [detailDeleteId, setDeleteIdDetail] = useState(null);
	const [showDeleteDialogDetail, setShowDeleteDialogDetail] = useState(false);
	const [isDeletingDetail, setIsDeletingDetail] = useState(false);
	const [isDeletingErrorMessageDetail, setIsDeletingErrorMessageDetail] = useState(null);

	const [saveErrors, setSaveErrors] = useState([[], []]);

	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const ID = useMemo(() => props.id, [props.id]);
	const IS_ADD = useMemo(() => props.addnew, [props.addnew]);

	const TITLE = useMemo(() => {
		if (add) {
			return 'Yeni Ekle';
		}
		return genelForm?.tanim;
	}, [add, genelForm]);

	const IS_EQUAL = useMemo(() => {
		if (add) {
			return _isEqual(genelForm, FORM_DATA_DEFAULT) && _isEqual(menuIceriklerForms, []);
		}
		const isEqualGenel = _isEqual(genelForm, genelFormDefault);
		const isEqualMenuIcerikler = _isEqual(menuIceriklerForms, menuIceriklerFormsDefault);
		const isEqualIcerikDiller = _isEqual(dillerFormIcerik, dillerFormDefaultIcerik);
		return isEqualGenel && isEqualMenuIcerikler && isEqualIcerikDiller;
	}, [add, genelForm, genelFormDefault, menuIceriklerForms, menuIceriklerFormsDefault, dillerFormIcerik, dillerFormDefaultIcerik]);

	const LOADINGICERIK = useMemo(() => {
		if (!loadingIcerik && !loadingDilIcerik) {
			return false;
		}
		return true;
	}, [loadingIcerik, loadingDilIcerik]);

	useLayoutEffect(() => {
		if (props.eventType === 0) {
			setSaveErrors([[], []]);
			setMenuIceriklerSelectedTabIndex(0);
			setTabs(TABS_DEFAULT);
		}
	}, [props.eventType, ID]);

	useLayoutEffect(() => {
		if (!saveErrors[0].includes("err")) {
			setLoading(true);
			if (!IS_ADD) {
				const debounce = setTimeout(() => {
					django(`${PATH}/${ID}`).then(({ data }) => {
						const form = {
							tanim: data.tanim,
							aktif: data.aktif,
						};
						setGenelFormDefault(form);
						setGenelForm(form);
						setHataGenel(null);
					}).catch((error) => {
						if (error.response) {
							if (error.response.status === 500) {
								setHataGenel(true);
							}
						}
					}).finally(() => {
						setLoading(false);
						setGenelError(null);
						setAdd(false);
					});
				}, 300);
				return () => {
					clearTimeout(debounce);
					setLoading(false);
				};
			} else {
				setGenelForm(FORM_DATA_DEFAULT);
				setGenelFormDefault(FORM_DATA_DEFAULT);
				setGenelError(null);
				setHataGenel(null);
				setAdd(true);
				setLoading(false);
			}
		}
	}, [ID, lastUpdateTime, IS_ADD, saveErrors]);

	useLayoutEffect(() => {
		if (!saveErrors[1].includes("err")) {
			if (!IS_ADD) {
				setLoadingIcerik(true);
				const debounce = setTimeout(() => {
					django(`${PATH}/${ID}/menu-icerik`).then(({ data }) => {
						const forms = data.map((icerik, index) => (
							{
								form: {
									id: icerik?.id ?? null,
									tanim: icerik?.tanim ?? '',
									kategori: icerik?.kategori ?? null,
									ozellik_baslik: icerik?.ozellik_baslik ?? {},
									ozellik_deger: icerik?.ozellik_deger ? { id: icerik.ozellik_deger.id, tanim: icerik.ozellik_deger.tanim, baslik_id: icerik.ozellik_deger.ozellikbaslik.id, baslik_tanim: icerik.ozellik_deger.ozellikbaslik.tanim } : null,
									ozellik_deger_baslik: icerik?.ozellik_deger ? { id: icerik.ozellik_deger.ozellikbaslik.id, tanim: icerik.ozellik_deger.ozellikbaslik.tanim } : null,
									sabit_sayfa: icerik?.sabit_sayfa ?? {},
									url: icerik.url,
									siralama: String(icerik.siralama),
									diller: icerik.diller,
									ust: icerik.ust,
									aktif: icerik.aktif,
									foto: icerik.foto,
									index: index,
									ozellik_tree_list: icerik?.ozellik_deger ? [{ title: icerik.ozellik_deger.ozellikbaslik.tanim, list: [{ key: icerik.ozellik_deger.id, value: icerik.ozellik_deger.tanim }] }] : [],
									ozel_link_icerik: icerik?.ozel_link_icerik ? icerik.ozel_link_icerik : []
								},
								errors: null
							}
						));
						setMenuIceriklerFormsDefault(forms);
						setMenuIceriklerForms(forms);
						setMenuTree(createMenuTree(forms));
						setIcerikDiller(forms.map((icerik) => icerik.form.diller));
						setHataIcerik(null);
					}).catch((error) => {
						if (error.response) {
							if (error.response.status === 500) {
								setHataIcerik(true);
							}
						}
					}).finally(() => {
						setLoadingIcerik(false);
					})
				}, 300);
				return () => {
					clearTimeout(debounce);
				}
			} else {
				setMenuIceriklerFormsDefault([]);
				setMenuIceriklerForms([]);
				setMenuTree(createMenuTree([]));
				setIcerikDiller([]);
				setHataIcerik(null);
				setLoadingIcerik(false);
			}
		}
	}, [ID, lastUpdateTime, IS_ADD, saveErrors]);

	useLayoutEffect(() => {
		django('dil/mini_list').then(({ data }) => setDilMiniList(data)).catch((error) => {
			if (error.response) {
				if (error.response.status === 500) {
					setHataDil(true);
				}
			}
		});
	}, [lastUpdateTime]);

	useLayoutEffect(() => {
		setLoadingDilIcerik(true);
		const setDil = async () => {
			let diller = [];
			icerikDiller.forEach((dil) => {
				createDillerStatesIcerik(dilMiniList, dil).then((states) => diller.push(states));
			})
			return diller;
		}
		setDil().then((state) => {
			setDillerFormIcerik(state);
			setDillerFormDefaultIcerik(state);
		}).finally(() => setLoadingDilIcerik(false));

	}, [dilMiniList, icerikDiller]);

	useLayoutEffect(() => {
		setActive([]);
	}, [loading]);

	useLayoutEffect(() => {
		if (!props.single) {
			props.onDisableClose(!IS_EQUAL);
		}
	}, [props, IS_EQUAL]);

	const handleGenelChangeForm = (property, value) => {
		setGenelForm((prev) => {
			return immer(prev, (next) => {
				next[property] = value;
			});
		});
	};

	const handleMenuIceriklerChangeForm = (index, property, value) => {
		if (property === "tanim") {
			setMenuIceriklerForms((prev) => {
				return immer(prev, (next) => {
					next[index].form[property] = value;
				})
			});
			let list = [...menuIceriklerForms];
			list[index] = { form: { ...list[index].form, tanim: value }, errors: list[index].errors };
			setMenuTree(createMenuTree(list));
		} else {
			if (property === "kategori") {
				setMenuIceriklerForms((prev) => {
					return immer(prev, (next) => {
						next[index].form[property] = value;
						next[index].form["ozellik_baslik"] = {};
						next[index].form["ozellik_deger"] = {};
						next[index].form["sabit_sayfa"] = {};
						next[index].form["url"] = null;
						next[index].form["ozel_link_icerik"] = [];
					});
				});
			} else if (property === "ozellik_baslik") {
				setMenuIceriklerForms((prev) => {
					return immer(prev, (next) => {
						next[index].form[property] = value;
						next[index].form["kategori"] = {};
						next[index].form["sabit_sayfa"] = {};
						next[index].form["ozellik_deger"] = {};
						next[index].form["url"] = null;
						next[index].form["ozel_link_icerik"] = [];
					});
				});
			} else if (property === "ozellik_deger") {
				setMenuIceriklerForms((prev) => {
					return immer(prev, (next) => {
						next[index].form[property] = value ? value : null;
						next[index].form["ozellik_deger_baslik"] = value ? { id: value.baslik_id, tanim: value.baslik_tanim } : null;
						next[index].form["ozellik_tree_list"] = value ? [{ title: value.baslik_tanim, list: [{ key: value.id, value: value.tanim }] }] : [];
						next[index].form["kategori"] = {};
						next[index].form["sabit_sayfa"] = {};
						next[index].form["ozellik_baslik"] = {};
						next[index].form["ozellik_baslik"] = {};
						next[index].form["url"] = null;
						next[index].form["ozel_link_icerik"] = [];
					});
				});
			} else if (property === "sabit_sayfa") {
				setMenuIceriklerForms((prev) => {
					return immer(prev, (next) => {
						next[index].form[property] = value;
						next[index].form["ozellik_baslik"] = {};
						next[index].form["ozellik_deger"] = {};
						next[index].form["kategori"] = {};
						next[index].form["url"] = null;
						next[index].form["ozel_link_icerik"] = [];
					});
				});
			} else if (property === "url") {
				setMenuIceriklerForms((prev) => {
					return immer(prev, (next) => {
						next[index].form[property] = value;
						next[index].form["ozellik_baslik"] = {};
						next[index].form["ozellik_deger"] = {};
						next[index].form["sabit_sayfa"] = {};
						next[index].form["kategori"] = {};
						next[index].form["ozel_link_icerik"] = [];
					});
				});
			} else if (property === "ozel_link_icerik") {
				setMenuIceriklerForms((prev) => {
					return immer(prev, (next) => {
						next[index].form[property] = value;
						next[index].form["ozellik_baslik"] = {};
						next[index].form["ozellik_deger"] = {};
						next[index].form["sabit_sayfa"] = {};
						next[index].form["kategori"] = {};
						next[index].form["url"] = null;
					});
				});
			} else {
				setMenuIceriklerForms((prev) => {
					return immer(prev, (next) => {
						next[index].form[property] = value;
					});
				});
			}
		}
	};

	const handleDillerChangeFormIcerik = (index, index2, property, value) => {
		setDillerFormIcerik((prev) => {
			return immer(prev, (next) => {
				next[index][index2].form[property] = value;
			});
		});
	}

	const handleIceriklerAddNewTab = (ust, toggleIndex = '') => {
		setMenuAdd(true);
		const index = menuIceriklerForms.length;
		const newItem = { form: { ...MENU_ICERIK_DATA_DEFAULT, index: index, ust: ust }, erros: null }
		setMenuIceriklerForms((prev) => immer(prev, (next) => {
			next.push(newItem);
		}));
		setIcerikDiller((prev) => immer(prev, (next) => {
			next.push([]);
		}));
		setMenuTree(createMenuTree([...menuIceriklerForms, newItem]));
		if (toggleIndex !== '') {
			if (isActive.indexOf(toggleIndex) === -1) {
				setActive([...isActive, toggleIndex]);
			}
		}
		setMenuIceriklerSelectedTabIndex(index)
	};

	const handleAdd = () => {
		setAdd(true);
		setGenelForm(FORM_DATA_DEFAULT);
		setGenelError(null);
		setMenuIceriklerForms([]);
		setIcerikDiller([]);
		setMenuTree(createMenuTree([]));
		setTabs((prev) => {
			return immer(prev, (next) => {
				next[0].hasError = false;
			});
		});
	};

	const handleReload = () => {
		setLastUpdateTime(Date.now());
	};

	const handleCancel = () => {
		setGenelForm(genelFormDefault);
		setGenelError(null);
		setMenuIceriklerForms(menuIceriklerFormsDefault);
		setMenuTree(createMenuTree(menuIceriklerFormsDefault));
		setIcerikDiller(menuIceriklerFormsDefault.map((icerik) => icerik.form.diller));
		setMenuAdd(false);
		if (!IS_ADD) {
			setAdd(false);
		}
		setTabs((prev) => {
			return immer(prev, (next) => {
				next[0].hasError = false;
			});
		});
	};

	const checkAll = async () => {
		const list = [
			{ prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Menü Tanım', pagename: 'menü', prop2: '' },
		]
		let errors = [];
		await checkAllList('menu', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
		return errors;
	};

	const checkAllTabs = async (form, func, list) => {
		let errors = [];
		await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
		return errors;
	}

	const handleSave = async () => {
		const createRequestOptionsTabGenel = async () => {
			let reqopt = null;
			let err = null;
			if (!add ? _isEqual(genelForm, genelFormDefault) : _isEqual(genelForm, FORM_DATA_DEFAULT)) {
				reqopt = null;
				err = null;
			} else {
				await checkAll().then((res) => {
					if (_isEmpty(res)) {
						reqopt = {
							data: genelForm,
							method: add ? 'POST' : 'PUT',
							id: null,
							successMessage: `Menü ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
							errorMessageUnexpected: `Menü ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
						};
						err = null;
					} else {
						reqopt = null;
						err = res;
					}
				})
			}
			return [reqopt, err]
		}

		const createRequestOptionsTabListe = async (next, index) => {
			let reqopt = null;
			let err = null;
			const isHas = _has(next.form, 'id');
			const prev = menuIceriklerFormsDefault.find(x => x.form.id === next.form.id);
			const isEqual = !isHas ? false : _isEqual(prev, next) && _isEqual(dillerFormIcerik[index], dillerFormDefaultIcerik[index]);
			if (isEqual) {
				reqopt = null;
				err = null;
			} else {
				const list = [
					{ prop: 'tanim', prop2: '' },
					{ prop: 'siralama', prop2: '' },
				];
				await checkAllTabs(next.form, (err) => handleErrorsDetail(err, index), list).then((res) => {
					if (_isEmpty(res)) {
						const diller = dillerFormIcerik[index].filter((dil) => (!_isEmpty(dil.form.tanim))).map((d) => d.form);

						let link_icerik = [];
						if (genelForm.ozel_link_icerik) {
							genelForm.ozel_link_icerik.forEach(element => {
								const obj = { model: element.model }
								if (element.model === "0") {
									obj.icerik = { icerik_deger: null, icerik_idler: element.icerik.icerik_idler.map(x => x.id) }
									link_icerik.push(obj);
								} else {
									obj.icerik = { icerik_deger: { baslik: element.icerik.icerik_deger.baslik.id, degerler: element.icerik.icerik_deger.degerler.map(x => x.id) }, icerik_idler: null }
									link_icerik.push(obj);
								}
							});
						} else {
							link_icerik = null;
						}

						let form = { ...next.form, kategori: next.form.kategori?.id ?? null, ozellik_baslik: next.form.ozellik_baslik?.id ?? null, ozellik_deger: next.form.ozellik_deger?.id ?? null, sabit_sayfa: next.form.sabit_sayfa?.id ?? null, ozel_link_icerik: link_icerik, siralama: next.form.siralama ? Number(next.form.siralama) : null, diller: diller, foto: next.form.foto?.id ?? null };
						if (isHas) {
							reqopt = {
								data: form,
								method: 'PUT',
								id: next.form.id,
								successMessage: `${form.tanim} güncelleme başarıyla tamamlandı`,
								errorMessageUnexpected: `${form.tanim} güncellenirken beklenmeyen bir hata oluştu`,
							}
						} else {
							reqopt = {
								data: form,
								method: 'POST',
								id: null,
								successMessage: `${form.tanim} ekleme başarıyla tamamlandı`,
								errorMessageUnexpected: `${form.tanim} eklenirken beklenmeyen bir hata oluştu`,
							}
						}
						err = null;
					} else {
						reqopt = null;
						err = res;
					}
				})
			}
			return [reqopt, err]
		}

		let errors = [[], []];

		let SAVEID = add ? null : ID;

		const start = async () => {
			const tabs = [
				{ index: 0, name: 'genel', request: [], error: [] },
				{ index: 1, name: 'icerik', request: [], error: [] },
			];
			const res = await createRequestOptionsTabGenel();
			tabs[0].request.push(res[0]);
			tabs[0].error.push(res[1]);

			for (const i in menuIceriklerForms) {
				const icerikstate = [...menuIceriklerForms];
				const res = await createRequestOptionsTabListe(icerikstate[i], i)
				tabs[1].request.push(res[0]);
				tabs[1].error.push(res[1]);
			}

			await asyncForEach(tabs, async (tab) => {
				await asyncForEach(tab.request, async (request, requestIndex) => {
					if (request !== null) {
						if (tab.name === 'genel') {
							setGenelError(null);
							await django({
								method: request.method,
								data: request.data,
								url: add ? "menu" : `menu/${SAVEID}`
							}).then(({ data }) => {
								enqueueSnackbar(request.successMessage, { variant: 'success' });
								if (!add) {
									props.onUpdated();
								}
								if (props.single && add) {
									history.push(URLS.tanimlar.menu.detail(data.id, "detay"));
								}
								if (!props.single && add) {
									props.onAdded(data);
								}
								SAVEID = data.id;
								errors[tab.index].splice(requestIndex, 0, null);
							}).catch((error) => {
								if (error.response) {
									if (error.response.status === 500) {
										enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
									} else {
										setGenelError(error.response.data);
									}
								} else {
									enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
								}
								errors[tab.index].splice(requestIndex, 0, "err");
							});
						}
						if (SAVEID !== null) {
							if (tab.name === 'icerik') {
								setMenuIceriklerForms((prev) => {
									return immer(prev, (next) => {
										next[requestIndex].errors = null;
									})
								});
								setMenuAdd(false);
								await django({
									method: request.method,
									data: request.data,
									url: request.id ? `menu/${SAVEID}/menu-icerik/${request.id}` : `menu/${SAVEID}/menu-icerik`
								}).then(() => {
									enqueueSnackbar(request.successMessage, { variant: 'success' });
									errors[tab.index].splice(requestIndex, 0, null);
								}).catch((error) => {
									setMenuIceriklerSelectedTabIndex(requestIndex);
									if (error.response) {
										setMenuIceriklerForms((prev) => {
											return immer(prev, (next) => {
												next[requestIndex].errors = error.response.data;
											})
										});
									} else {
										enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
									}
									errors[tab.index].splice(requestIndex, 0, "err");
								});
							}
						}
					} else {
						if (!_isEmpty(tab.error[requestIndex])) {
							errors[tab.index].splice(requestIndex, 0, "err");
						} else {
							errors[tab.index].splice(requestIndex, 0, null);
						}
					}
				});
			});
		}
		await start();
		if (SAVEID !== null) {
			setSaveErrors(errors);
			if (!add) {
				if (!errors[0].includes("err") || !errors[1].includes("err")) {
					setLastUpdateTime(Date.now());
				}
			}
		} else {
			enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
		}
		setTabs((prev) => {
			return immer(prev, (next) => {
				next[0].hasError = !errors[0].includes("err") ? SAVEID ? !errors[1].includes("err") ? false : true : true : true;
			});
		});
	};

	const handleDelete = () => {
		setIsDeleting(true);
		setIsDeletingErrorMessage(null);
		const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
		django.delete(`${PATH}/${ID}`).then(({ status }) => {
			if (status === 204) {
				setShowDeleteDialog(false);
				setIsDeletingErrorMessage(null);

				setTimeout(() => {
					if (props.single) {
						history.push(URLS.tanimlar.menu.list);
					} else {
						props.onDeleted();
					}
				}, 100);
			} else {
				setIsDeletingErrorMessage(errorMessage);
			}
		}).catch(() => {
			setIsDeletingErrorMessage(errorMessage);
		}).finally(() => {
			setIsDeleting(false);
		});
	};


	const handleDeleteDetail = () => {
		setMenuAdd(false);
		setIsDeletingDetail(true);
		setIsDeletingErrorMessageDetail(null);
		const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
		django.delete(`${PATH}/${ID}/menu-icerik/${detailDeleteId}`).then(({ status }) => {
			if (status === 204) {
				setShowDeleteDialogDetail(false);
				setIsDeletingErrorMessageDetail(null);
			} else {
				setIsDeletingErrorMessageDetail(errorMessage);
			}
		}).catch(() => {
			setIsDeletingErrorMessageDetail(errorMessage);
		}).finally(() => {
			setIsDeletingDetail(false);
			setLastUpdateTime(Date.now());
		});
	}

	const handleDeleteDetailWithoutId = (index) => {
		setMenuAdd(false);
		let newList = [...menuIceriklerForms];
		newList.splice(index, 1);
		setMenuIceriklerForms((prev) => immer(prev, (next) => {
			next.splice(index, 1);
		}));
		setIcerikDiller((prev) => immer(prev, (next) => {
			next.splice(index, 1);
		}));
		setMenuTree(createMenuTree(newList));
	}

	const handleDeleteDetailDilIndex = (index, index2) => {
		setDillerFormIcerik((prev) => {
			return immer(prev, (next) => {
				next[index][index2].form = { ...DILLER_FORM_DEFAULT, dil_id: next[index][index2].form.dil_id };
				next[index][index2].errors = null;
			})
		});
	}

	const toggle = (i) => {
		if (isActive.indexOf(i) === -1) {
			setActive([...isActive, i]);
		} else {
			const index = isActive.indexOf(i);
			setActive((prev) => {
				return immer(prev, (next) => {
					next.splice(index, 1)
				});
			});
		}
	};

	const handleErrors = (errors) => {
		setGenelError((prev) => {
			const next = _pickBy({ ...prev, ...errors }, _identity);
			if (Object.keys(next).length === 0) {
				return null;
			}
			return next;
		});
	}

	const handleErrorsDetail = (errors, index) => {
		setMenuIceriklerForms((prev) => {
			const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
			return immer(prev, (next) => {
				next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
			});
		});
	}

	const handleErrorsDetailDil = (errors, index, index2) => {
		setDillerFormIcerik((prev) => {
			const err = _pickBy({ ...prev[index][index2]["errors"], ...errors }, _identity);
			return immer(prev, (next) => {
				next[index][index2]["errors"] = Object.keys(err).length === 0 ? null : err;
			});
		});
	}

	return (
		<>
			<LayoutDetail
				loading={loading}
				title={TITLE}

				single={props.single}

				tabs={tabs}
				selectedTabIndex={selectedTabIndex}
				onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

				disableCloseButton={loading}
				disableAddButton={loading || add || IS_ADD}
				disableReloadButton={loading || add || IS_ADD}
				disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
				disableSaveButton={loading || IS_EQUAL}
				disableDeleteButton={loading || add || IS_ADD}
				disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
				disableNextButton={props.disableNextButton || add || IS_ADD}

				onClickCloseButton={props.onClose}
				onClickAddButton={handleAdd}
				onClickReloadButton={handleReload}
				onClickCancelButton={handleCancel}
				onClickSaveButton={handleSave}
				onClickDeleteButton={() => setShowDeleteDialog(true)}
				onClickPreviousButton={props.onGoPrevious}
				onClickNextButton={props.onGoNext}
				onOpenInNewTab={() => {
					if (IS_ADD) {
						window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
					} else {
						window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
					}
				}}
			>
				{selectedTabIndex === 0 && (
					<TabGenel
						loading={loading}
						hata={hataGenel}
						add={add}

						form={genelForm}
						formdefault={genelFormDefault}
						error={genelError}
						onChangeForm={handleGenelChangeForm}

						onCheckErrors={(errors) => handleErrors(errors)}

						loadingIcerik={LOADINGICERIK}
						hataIcerik={hataIcerik}
						hataDil={hataDil}

						defaultSelectedTabIndex={menuIceriklerSelectedTabIndex}
						forms={menuIceriklerForms}
						onChangeFormIcerik={handleMenuIceriklerChangeForm}
						onAddNewTab={handleIceriklerAddNewTab}
						setDeleteId={setDeleteIdDetail}
						openDeleteForm={setShowDeleteDialogDetail}
						deleteNewAdd={handleDeleteDetailWithoutId}

						selectedTabIndexDil={dillerSelectedTabIndexIcerik}
						dilform={dillerFormIcerik}
						onChangeDilForm={handleDillerChangeFormIcerik}
						deleteDetailDilWithIndex={handleDeleteDetailDilIndex}
						menuTree={menuTree}
						isActive={isActive}
						toggle={toggle}
						menuAdd={menuAdd}

						onCheckErrorsIcerik={(errors, index) => handleErrorsDetail(errors, index)}

						onCheckErrorsDil={(errors, index, index2) => handleErrorsDetailDil(errors, index, index2)}
					/>
				)}
			</LayoutDetail>

			{showDeleteDialog &&
				<DialogConfirm
					title="Sil"
					message={
						isDeletingErrorMessage
							? isDeletingErrorMessage
							: isDeleting
								? 'Ödeme Tanım siliniyor lütfen bekleyin'
								: 'Bu ödeme tanımı gerçekten silmek istiyor musunuz?'
					}
					cancelText="VAZGEÇ"
					submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
					submittingText="SİLİNİYOR"
					submitButtonProps={{ color: 'secondary' }}
					isSubmitting={isDeleting}
					onSubmit={handleDelete}
					onCancel={() => setShowDeleteDialog(false)}
				></DialogConfirm>
			}

			{showDeleteDialogDetail &&
				<DialogConfirm
					title="Sil"
					message={
						isDeletingErrorMessageDetail
							? isDeletingErrorMessageDetail
							: isDeletingDetail
								? 'Menü içerik siliniyor lütfen bekleyin'
								: 'Bu menü içeriği gerçekten silmek istiyor musunuz?'
					}
					cancelText="VAZGEÇ"
					submitText={isDeletingErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
					submittingText="SİLİNİYOR"
					submitButtonProps={{ color: 'secondary' }}
					isSubmitting={isDeletingDetail}
					onSubmit={handleDeleteDetail}
					onCancel={() => setShowDeleteDialogDetail(false)}
				></DialogConfirm>
			}
		</>
	);
}
export default MenuTanimDetail;