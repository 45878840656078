import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  Button,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

import ListSelector from '../../../components/ListSelector';
import VadeSelector from '../../../components/VadeSelector';
import MultipleSelectList from '../../../components/MultipleSelectList';
import ListSelectorTwoStep from '../../../components/ListSelectorTwoStep';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    button: {
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      }
    },
    treeList: {
      marginTop: 10,
    },
    list: {
      marginBottom: 10
    },
    title: {
      position: 'relative',
      display: 'inline-block',
      color: '#976797',
      fontSize: 15,
      fontWeight: '500'
    },
    ul: {
      position: 'relative',
      paddingLeft: '3em',
      '&::before': {
        position: 'absolute',
        top: 0,
        bottom: 7,
        left: '3em',
        display: 'block',
        width: 0,
        borderLeft: '2px solid #eacfea',
        content: '""',
      }
    },
    li: {
      padding: '15px 0 0 40px',
      position: 'relative',
      lineHeight: '15px',
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        height: 25,
        width: 30,
        borderBottom: '2px solid #eacfea',
        borderLeft: '2px solid #eacfea',
        borderRadius: '0 0 0 5px',
        content: '""',
      }
    },
  };
};

const OdemeSetDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);
  const [openSelectorTanim, setOpenSelectorTanim] = useState(false);
  const [openSelectorCariGrup, setOpenSelectorCariGrup] = useState(false);
  const [openSelectorUrunGrup, setOpenSelectorUrunGrup] = useState(false);
  const [openSelectorVade, setOpenSelectorVade] = useState(false);
  const [openSelectorOzellik, setOpenSelectorOzellik] = useState(false);
  const [detayListOpen, setDetayListOpen] = useState(true);

  const [vadeDetayOpen, setVadeDetayOpen] = useState(true);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const handleRemoveMagazaById = (id) => {
    const copymagazalar = [...props.form.magazalar];
    const index = copymagazalar.findIndex((c) => c.id === id);
    if (index > -1) {
      copymagazalar.splice(index, 1);
      props.onChangeForm('magazalar', copymagazalar);
    }
  }

  const handleRemoveTanimById = (id) => {
    const copytanimlar = [...props.form.odeme_tanimlari];
    const index = copytanimlar.findIndex((c) => c.id === id);
    if (index > -1) {
      copytanimlar.splice(index, 1);
      props.onChangeForm('odeme_tanimlari', copytanimlar);
    }
  }

  const handleRemoveCariGrupById = (id) => {
    const copygruplar = [...props.form.kullanici_gruplari];
    const index = copygruplar.findIndex((c) => c.id === id);
    if (index > -1) {
      copygruplar.splice(index, 1);
      props.onChangeForm('kullanici_gruplari', copygruplar);
    }
  }

  const handleRemoveUrunGrupById = (id) => {
    const copygruplar = [...props.form.gruplar];
    const index = copygruplar.findIndex((c) => c.id === id);
    if (index > -1) {
      copygruplar.splice(index, 1);
      props.onChangeForm('gruplar', copygruplar);
    }
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <div className="flex flex-row" style={{ marginBottom: 5 }}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Ödeme Set Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim')}
                          onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                          onBlur={() => handleInputErrors('odemeset', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Ödeme Set Tanım', 'ödeme')}
                          inputProps={{
                            maxLength: 250,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="min_tutar">
                      <label className={classes.layoutFormItemLabel} htmlFor="min_tutar">Minimum Tutar *</label>
                      <Tooltip title={props.error?.min_tutar ? props.error.min_tutar[0] : _isNil(props.form?.min_tutar) ? '' : props.form.min_tutar}>
                        <TextField
                          {...createTextFieldProps('min_tutar')}
                          onChange={(e) => props.onChangeForm('min_tutar', e.target.value)}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'min_tutar', true, false, false, '', '', '')}
                          type="number"
                          inputProps={{
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="max_tutar">
                      <label className={classes.layoutFormItemLabel} htmlFor="max_tutar">Maksimum Tutar</label>
                      <Tooltip title={props.error?.max_tutar ? props.error.max_tutar[0] : _isNil(props.form?.max_tutar) ? '' : props.form.max_tutar}>
                        <TextField
                          {...createTextFieldProps('max_tutar')}
                          onChange={(e) => props.onChangeForm('max_tutar', e.target.value)}
                          type="number"
                          inputProps={{
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="tip">
                      <label className={classes.layoutFormItemLabel} htmlFor="tip">Tip</label>
                      <Tooltip title={props.error?.tip ? props.error.tip[0] : _isNil(props.form?.tip) ? '' : props.tipDic ? props.tipDic[props.form.tip] : ''}>
                        <TextField
                          {...createTextFieldProps('tip')}
                          onChange={(e) => props.onChangeForm('tip', e.target.value)}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          {props?.tip?.map((kod) => (
                            <option key={kod.key} value={kod.key}>{kod.value}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                    </li>
                    {props.form?.tip === '1' && (
                      <li className={classes.layoutFormItem} id="yuzdeler">
                        <label className={classes.layoutFormItemLabel} htmlFor="yuzdeler">Yüzdeler</label>
                        <Tooltip title={props.error?.yuzdeler ? props.error.yuzdeler[0] : _isNil(props.form?.yuzdeler) ? '' : props.form.yuzdeler}>
                          <TextField
                            {...createTextFieldProps('yuzdeler', '')}
                            onChange={(e) => props.onChangeForm('yuzdeler', e.target.value)}
                            inputProps={{
                              style: { textAlign: "right" }
                            }}
                          ></TextField>
                        </Tooltip>
                      </li>
                    )}
                    <li className={classes.layoutFormItem} id="siralama">
                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                      <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                        <TextField
                          {...createTextFieldProps('siralama')}
                          onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                          type="number"
                          inputProps={{
                            min: -2147483648,
                            max: 2147483647,
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                      ></Checkbox>
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItemBox} id="magazalar">
                      <MultipleSelectList
                        headername="MAĞAZALAR *"
                        name="Mağazalar"
                        name2="Mağaza"
                        form={props.form}
                        list={props.form?.magazalar ?? []}
                        listlength={props?.form?.magazalar?.length ?? 0}
                        opendialog={setOpenSelectorMagaza}
                        keyProp="id"
                        valueProp="tanim"
                        removefonk={handleRemoveMagazaById}
                        loading={props.loading}
                        error={props.error?.magazalar ? props.error.magazalar[0] : null}
                        newPath="tanimlar/magaza"
                        ustId={false}
                      />
                    </li>
                    <li className={classes.layoutFormItemBox} id="kullanici_gruplari">
                      <MultipleSelectList
                        headername="ÜYE/CARİ GRUPLARI *"
                        name="Üye/cari grupları"
                        name2="Üye/cari grubu"
                        form={props.form}
                        list={props.form?.kullanici_gruplari ?? []}
                        listlength={props?.form.kullanici_gruplari?.length ?? 0}
                        opendialog={setOpenSelectorCariGrup}
                        keyProp="id"
                        valueProp="name"
                        removefonk={handleRemoveCariGrupById}
                        loading={props.loading}
                        error={props.error?.kullanici_gruplari ? props.error.kullanici_gruplari[0] : null}
                        newPath="tanimlar/kullanici_grup"
                        ustId={false}
                      />
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItemBox} id="odeme_tanimlari">
                      <MultipleSelectList
                        headername="ÖDEME/TAHSİLAT TANIMLARI *"
                        name="Ödeme/tahsilat tanımları"
                        name2="Ödeme/tahsilat tanım"
                        form={props.form}
                        list={props.form?.odeme_tanimlari}
                        listlength={props?.form?.odeme_tanimlari?.length ?? 0}
                        opendialog={setOpenSelectorTanim}
                        keyProp="id"
                        valueProp="tanim"
                        removefonk={handleRemoveTanimById}
                        loading={props.loading}
                        error={props.error?.odeme_tanimlari ? props.error.odeme_tanimlari[0] : null}
                        newPath="tanimlar/odeme_tanim"
                        ustId={false}
                      />
                    </li>
                    <li className={classes.layoutFormItemBox} id="gruplar">
                      <MultipleSelectList
                        headername="ÜRÜN GRUPLARI"
                        name="Ürün grupları"
                        name2="Ürün grubu"
                        form={props.form}
                        list={props.form?.gruplar}
                        listlength={props?.form?.gruplar?.length ?? 0}
                        opendialog={setOpenSelectorUrunGrup}
                        keyProp="id"
                        valueProp="tanim"
                        removefonk={handleRemoveUrunGrupById}
                        loading={props.loading}
                        error={props.error?.gruplar ? props.error.gruplar[0] : null}
                        newPath="tanimlar/gruplar"
                        ustId={false}
                      />
                    </li>
                  </ul>
                </div>

                <div className="flex w-full h-full">

                  <div className="w-full h-full flex flex-col justify-start">
                    <div className={classes.layoutFormItemBox}>
                      <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                        <header className="flex items-center justify-between py-2 px-4">
                          <Button
                            className="pointer-events-none"
                            color="inherit"
                          >ÖDEME VADELERİ</Button>
                          <Button
                            className={classes.button}
                            variant="text"
                            color="default"
                            size="small"
                            onClick={() => setOpenSelectorVade(true)}
                          >SEÇ</Button>
                        </header>
                      </section>
                    </div>
                    <div className={classes.treeList}>
                      <>
                        {props.form?.odeme_gruplar?.length > 0 && (
                          <>
                            {props.form.odeme_gruplar.map((item, index) => (
                              <div key={index + "-" + item.tanim} className={classes.list}>
                                <div className={classes.title}>{item.tanim}</div>
                                <div className={classes.ul}>
                                  {item.vadeler.map((v) => (
                                    <div key={v.id + "-" + v.tanim} className={classes.li}>{v.tanim}</div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    </div>
                  </div>

                  <div className="w-full h-full flex flex-col justify-start">
                    <div className={classes.layoutFormItemBox}>
                      <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                        <header className="flex items-center justify-between py-2 px-4">
                          <Button
                            className="pointer-events-none"
                            color="inherit"
                          >ÖZELLİKLER</Button>
                          <Button
                            className={classes.button}
                            variant="text"
                            color="default"
                            size="small"
                            onClick={() => setOpenSelectorOzellik(true)}
                          >SEÇ</Button>
                        </header>
                      </section>
                    </div>
                    <div className={classes.treeList}>
                      <>
                        {props.form?.ozellik_tree_list?.length > 0 && (
                          <>
                            {props.form.ozellik_tree_list.map((item, index) => (
                              <div key={index + "-" + item.title} className={classes.list}>
                                <div className={classes.title}>{item.title}</div>
                                <div className={classes.ul}>
                                  {item.list.map((v) => (
                                    <div key={v.id + "-" + v.value} className={classes.li}>{v.value}</div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    </div>
                  </div>

                </div>

              </div>




              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorMagaza(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Mağazalar'
                      listPath='magaza/mini_list?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.magazalar}
                      onChangeItems={(items) => props.onChangeForm('magazalar', items)}
                      onClose={() => setOpenSelectorMagaza(false)}
                      newPath='tanimlar/magaza'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorTanim &&
                <Dialog
                  open={openSelectorTanim}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorTanim(false);
                    handleInputErrors('odemeset', props.form, props.formdefault, props.onCheckErrors, add, 'odeme_tanim', true, false, '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Ödeme/Tahsilat Tanımları'
                      listPath='odemetanim?aktif=true'
                      listCountPath='odemetanim/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.odeme_tanimlari}
                      onChangeItems={(items) => props.onChangeForm('odeme_tanimlari', items)}
                      onClose={() => {
                        setOpenSelectorTanim(false);
                        handleInputErrors('odemeset', props.form, props.formdefault, props.onCheckErrors, add, 'odeme_tanimlari', true, false, '', '');
                      }}
                      newPath='tanimlar/odeme_tanim'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorCariGrup &&
                <Dialog
                  open={openSelectorCariGrup}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorCariGrup(false);
                    handleInputErrors('odemeset', props.form, props.formdefault, props.onCheckErrors, add, 'kullanici_gruplari', true, false, '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Cari/Üye Grupları'
                      listPath='kullanici-grup/mini_list'
                      listCountPath='kullanici-grup/count'
                      keyProp='id'
                      valueProp='name'
                      defaultItems={props.form.kullanici_gruplari}
                      onChangeItems={(items) => props.onChangeForm('kullanici_gruplari', items)}
                      onClose={() => {
                        setOpenSelectorCariGrup(false);
                        handleInputErrors('odemeset', props.form, props.formdefault, props.onCheckErrors, add, 'kullanici_gruplari', true, false, '', '');
                      }}
                      newPath='tanimlar/kullanici_grup'
                    />
                  </DialogContent>
                </Dialog>
              }

              {openSelectorUrunGrup &&
                <Dialog
                  open={openSelectorUrunGrup}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorUrunGrup(false);
                    handleInputErrors('odemeset', props.form, props.formdefault, props.onCheckErrors, add, 'kullanici_gruplari', true, false, '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Ürün Grupları'
                      listPath='grup/mini_list?aktif=true'
                      listCountPath='grup/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.gruplar}
                      onChangeItems={(items) => props.onChangeForm('gruplar', items)}
                      onClose={() => {
                        setOpenSelectorUrunGrup(false);
                        handleInputErrors('odemeset', props.form, props.formdefault, props.onCheckErrors, add, 'gruplar', true, false, '', '');
                      }}
                      newPath='tanimlar/gruplar'
                    />
                  </DialogContent>
                </Dialog>
              }

              {openSelectorVade &&
                <Dialog
                  open={openSelectorVade}
                  PaperProps={{
                    style: {
                      background: 'transparent',
                      height: 600,
                      width: vadeDetayOpen ? '100%' : 480
                    },
                  }}
                  fullWidth
                  maxWidth={vadeDetayOpen ? 'md' : 'sm'}
                  onClose={() => {
                    setOpenSelectorVade(false);
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <VadeSelector
                      defaultItems={props.form.odeme_gruplar}
                      onChangeItems={(items) => props.onChangeForm('odeme_gruplar', items)}
                      onClose={() => {
                        setOpenSelectorVade(false);
                      }}
                      onListClose={(res) => setVadeDetayOpen(res)}
                      detayListOpen={vadeDetayOpen}
                    />
                  </DialogContent>
                </Dialog>
              }

              {openSelectorOzellik &&
                <Dialog
                  open={openSelectorOzellik}
                  PaperProps={{
                    style: {
                      background: 'transparent',
                      height: 600,
                      width: detayListOpen ? '100%' : 480
                    },
                  }}
                  fullWidth
                  maxWidth={detayListOpen ? 'md' : 'sm'}
                  onClose={() => setOpenSelectorOzellik(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorTwoStep
                      title="Özellikler"
                      mainItemsPath='ozellikbaslik'
                      mainItemsCountPath='ozellikbaslik/count'
                      mainDefaultItems={props.form.ozellik_basliklar}
                      mainMultiple={true}
                      mainKeyProp='id'
                      mainValueProp='tanim'
                      listPath='ozellikdeger'
                      listCountPath='ozellikdeger/count'
                      listMultiple={true}
                      keyProp='id'
                      valueProp='tanim'
                      keyUId='odeme_tanim_id'
                      valueUV='ozellik_baslik_tanim'
                      defaultItems={props.form.ozellik_degerleri}
                      onChangeItems={(items) => props.onChangeForm('ozellik_degerleri', items)}
                      onClose={() => {
                        setOpenSelectorOzellik(false);
                      }}
                      onDetayListClose={(res) => setDetayListOpen(res)}
                      detayListOpen={detayListOpen}
                      detayList={props.form.ozellik_tree_list}
                      url="tanimlar/ozellikdetay"
                    />
                  </DialogContent>
                </Dialog>
              }

            </>
          )}
        </>
      }
    </>
  );
});

export default OdemeSetDetailTabGenel;
