import React, { useMemo, useState, useCallback } from 'react';
import {
  result as _result,
  has as _has,
  isNil as _isNil
} from 'lodash';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Tooltip,
  TextField
} from '@material-ui/core';

import {
  HelpOutline as IconHelp
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import { Hata } from '../../base/Hata';
import { handleInputErrors } from '../../base/InputErrors';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1, 1, 1, 4),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      position: 'relative',
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemCaption: {
      display: 'flex',
      marginBottom: 5,
      minWidth: 360,
      maxWidth: "100%",
    },
    layoutFormItemCaptionText: {
      fontStyle: 'italic'
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
  };
};

const BilesenDetailTabKategori = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="bilesen_tip">
                    <label className={classes.layoutFormItemLabel} htmlFor="bilesen_tip">Bileşen Tip *</label>
                    <Tooltip title={props.error?.bilesen_tip ? props.error.bilesen_tip[0] : (props.form?.bilesen_tip === "-1" || _isNil(props.form?.bilesen_tip)) ? '' : props.kategoriBilesenTipDic ? props.kategoriBilesenTipDic[props.form.bilesen_tip] : ''}>
                      <TextField
                        {...createTextFieldProps('bilesen_tip')}
                        onChange={(e) => props.onChangeForm('bilesen_tip', e.target.value)}
                        select
                        SelectProps={{
                          native: true,
                        }}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, false, 'bilesen_tip', true, false, false, '', '', '')}
                      >
                        <option key="-1" value="-1">Seçilmedi</option>
                        {props.kategoriBilesenTip.map((bt) => (
                          <option key={bt.key} value={bt.key}>{bt.value}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="urun_sayisi_goster">
                    <label className={classes.layoutFormItemLabel} htmlFor="urun_sayisi_goster">Ürün Sayısı</label>
                    <Tooltip title={props.error?.urun_sayisi_goster ? props.error.urun_sayisi_goster[0] : (props.form?.urun_sayisi_goster === "-1" || _isNil(props.form?.urun_sayisi_goster)) ? '' : props.kategoriSayiTipDic ? props.kategoriSayiTipDic[props.form.urun_sayisi_goster] : ''}>
                      <TextField
                        {...createTextFieldProps('urun_sayisi_goster')}
                        onChange={(e) => props.onChangeForm('urun_sayisi_goster', e.target.value)}
                        select
                        SelectProps={{
                          native: true,
                        }}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, false, 'urun_sayisi_goster', true, false, false, '', '', '')}
                      >
                        <option key="-1" value="-1">Seçilmedi</option>
                        {props.kategoriSayiTip.map((bt) => (
                          <option key={bt.key} value={bt.key}>{bt.value}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                    <IconButton
                      size="small"
                      className={classes.button2}
                      disableRipple
                      onClick={() => {
                        setHelpText("“Kategoride bulunan ürün sayılarını gösterir, seçilen tipe göre site performansında düşüş yaşanabilir.”");
                        setHelpOpen(true)
                      }}
                    ><IconHelp /></IconButton>
                  </li>
                </ul>
              </div>

              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default BilesenDetailTabKategori;
