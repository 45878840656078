import React, { useMemo, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Tooltip
} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';
import { Hata } from '../../base/Hata';
import { handleInputErrors } from '../../base/InputErrors';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
};

const BilesenDetailTabAfis = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form.ayarlar, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="show_item_count">
                    <label className={classes.layoutFormItemLabel} htmlFor="show_item_count">Öğe Sayısı</label>
                    <Tooltip title={props.error?.show_item_count ? props.error.show_item_count[0] : _isNil(props.form?.ayarlar?.show_item_count) ? '' : props.form.ayarlar.show_item_count}>
                      <TextField
                        {...createTextFieldProps('show_item_count')}
                        onChange={(e) => props.onChangeForm('show_item_count', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form.ayarlar, props.formdefault.ayarlar, props.onCheckErrors, false, 'show_item_count', true, false, false, '', '', '')}
                        type="number"
                        inputProps={{
                          step: 1,
                          style: { textAlign: "right" }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="vertical_horizontal">
                    <label className={classes.layoutFormItemLabel} htmlFor="vertical_horizontal">Dikey / Yatay</label>
                    <Tooltip title={props.error?.vertical_horizontal ? props.error.vertical_horizontal[0] : (props.form?.ayarlar?.vertical_horizontal === '0' || _isNil(props.form?.ayarlar?.vertical_horizontal)) ? 'Dikey' : 'Yatay'}>
                      <TextField
                        {...createTextFieldProps('vertical_horizontal', '')}
                        onChange={(e) => props.onChangeForm('vertical_horizontal', e.target.value)}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option key='0' value='0'>Dikey</option>
                        <option key='1' value='1'>Yatay</option>
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="banner_type">
                    <label className={classes.layoutFormItemLabel} htmlFor="banner_type">Gösterim Tipi</label>
                    <Tooltip title={props.error?.banner_type ? props.error.banner_type[0] : (props.form?.ayarlar?.banner_type === '0' || _isNil(props.form?.ayarlar?.banner_type)) ? 'Sabit' : 'Hareketli'}>
                      <TextField
                        {...createTextFieldProps('banner_type', '')}
                        onChange={(e) => props.onChangeForm('banner_type', e.target.value)}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option key='0' value='0'>Sabit</option>
                        <option key='1' value='1'>Hareketli</option>
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="wait_time">
                    <label className={classes.layoutFormItemLabel} htmlFor="wait_time">Bekleme Süresi (ms)</label>
                    <Tooltip title={props.error?.wait_time ? props.error.wait_time[0] : _isNil(props.form?.ayarlar?.wait_time) ? '' : props.form.ayarlar.wait_time}>
                      <TextField
                        {...createTextFieldProps('wait_time')}
                        onChange={(e) => props.onChangeForm('wait_time', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form.ayarlar, props.formdefault.ayarlar, props.onCheckErrors, false, 'wait_time', true, false, false, '', '', '')}
                        type="number"
                        inputProps={{
                          step: 1,
                          style: { textAlign: "right" }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="auto_slide">
                    <label className={classes.layoutFormItemLabel} htmlFor="auto_slide">Otomatik Kayma</label>
                    <Checkbox
                      name="auto_slide"
                      color="primary"
                      checked={_result(props.form?.ayarlar, 'auto_slide', false)}
                      onChange={(e, checked) => props.onChangeForm('auto_slide', checked)}
                    ></Checkbox>
                  </li>
                </ul>
              </div>
            </>
          )}
        </>
      }
    </>
  );
});

export default BilesenDetailTabAfis;
