import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../../pages/base/LayoutTableDetail';
import Detail from './UrunListTanimDetail';
import django from '../../../api/django';


const COLUMNS_DEFAULT = [
  { id: 'tanim',                tableProp: 'tanim',                         filterProp: 'tanim',                  label: 'Tanım',                element: 'input',            type: 'text',     arrayTableProp: null,   show: true,   sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'tip',                  tableProp: 'tip.value',                     filterProp: 'tip',                    label: 'Tip',                  element: 'select',           type: 'number',   arrayTableProp: null,   show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: 'key', selectValue: 'value' },
  { id: 'slug',                 tableProp: 'slug',                          filterProp: 'slug',                   label: 'Slug',                 element: 'input',            type: 'text',     arrayTableProp: null,   show: true,   sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'siteler',              tableProp: 'siteler',                       filterProp: 'siteler.site.id',        label: 'Siteler',              element: 'select-multiple',  type: 'array',    arrayTableProp: 'name', show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'site',       multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'name',     multipleSelectOptionViewProp: 'name',     shortDescription: null,  selectKey: null,  selectValue: null , newpath:"tanimlar/site" },
  { id: 'siralama',             tableProp: 'siralama',                      filterProp: 'siralama',               label: 'Sıralama',             element: 'input',            type: 'number',   arrayTableProp: null,   show: true,   sortable: false, highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'aktif',                tableProp: 'aktif',                         filterProp: 'aktif',                  label: 'Aktif',                element: 'select',           type: 'boolean',  arrayTableProp: null,   show: true,   sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'olusturan_kullanici',  tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id', label: 'Oluşturan Kullanıcı',  element: 'select-multiple',  type: 'number',   arrayTableProp: null,   show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistiren_kullanici', tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',label: 'Değiştiren Kullanıcı', element: 'select-multiple',  type: 'number',   arrayTableProp: null,   show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'olusturma_zamani',     tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',       label: 'Oluşturma',            element: 'input',            type: 'datetime', arrayTableProp: null,   show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistirme_zamani',    tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',      label: 'Son Değişiklik',       element: 'input',            type: 'datetime', arrayTableProp: null,   show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
];

const PageUrunListTanimList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        return column;
      });
    });
  }, []); 

  useLayoutEffect(() => {
    django('urunlisttanim/tip').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'tip') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  return (
    <>
      <Layout
        title="ÜRÜN LİSTE TANIMLARI"
        path="urunlisttanim"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="urunlisttanim"
      />
    </>
  );
};

export default PageUrunListTanimList;
