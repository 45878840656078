import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  form: {
    alignItems: 'center',
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.primary.main,
      // theme.palette.type === 'light' ?  : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function AuthBase(props) {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline/>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square className={classes.form}>
        <div className={classes.paper}>
          {props.children}
        </div>
      </Grid>
      <Grid item xs={false} sm={4} md={7} className={classes.image}/>
    </Grid>
  );
}