import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../../pages/base/LayoutTableDetail';
import Detail from './KullaniciGrupDetail';


const COLUMNS_DEFAULT = [
  { id: 'name', tableProp: 'name', filterProp: 'name', label: 'Kullanıcı Grup Tanım', element: 'input', type: 'text', arrayTableProp: null, show: true, sortable: true, highlightable: true, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null, selectKey: null,  selectValue: null  },
];

const PageKullaniciGrupList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        return column;
      });
    });
  }, []);

  return (
    <>
      <Layout
        title="ÜYE/CARİ GRUPLARI"
        path="kullanici-grup"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="kullanici-grup"
      />
    </>
  );
};

export default PageKullaniciGrupList;
