import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';
import moment from 'moment';
import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import DialogExtraConfirm from '../../../components/DialogExtraConfirm';
import { checkAllList, checkAllList2, handleInputErrors, handleInputErrors2 } from '../../base/InputErrors';

import TabGenel from './SablonDetailTab_Genel';
import TabArayuz from './SablonDetailTab_Arayuz';
import TabDosya from './SablonDetailTab_Dosya';
import TabLogo from './SablonDetailTab_Logo';
import TabDiger from './SablonDetailTAb_Diger';
import TabMail from './SablonDetailTab_Mail';

const PATH = 'sablon_paket';

const icerik = `
{% load static %}
{% load i18n %}
{% load front_end %}
<!DOCTYPE HTML>
<html lang="en">
<head>
  <meta charset="utf-8">
  <meta http-equiv="pragma" content="no-cache" />
  <meta http-equiv="cache-control" content="max-age=604800" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

  <title>{% block title_txt %}{{ request.magaza.name }}{% endblock title_txt %}</title>
  <meta name="keywords" content="{% block keywords_meta %}{{ request.magaza.name }}{% endblock keywords_meta %}"/>
  <meta name="description" content="{% block description_meta %}{{ request.magaza.name }}{% endblock description_meta %}"/>
    {% autoescape off %}
        {% get_favicon %}
    {% endautoescape %}

  <!-- jQuery -->
  <script src="{% static "base/js/jquery-2.0.0.min.js" %}" type="text/javascript"></script>

  <!-- Bootstrap4 files-->
  <script src="{% static "base/js/bootstrap.bundle.min.js" %}" type="text/javascript"></script>
  <link href="{% static "base/css/bootstrap.css" %}" rel="stylesheet" type="text/css" />

  <!-- Font awesome 5 -->
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css" />

  <!-- custom style -->
    {% autoescape off %}
        {% get_css_metas %}
    {% endautoescape %}
</head>
<body>

{% block site %}{% endblock site %}

<!-- custom javascript -->
    {% autoescape off %}
        {% get_js_metas %}
    {% endautoescape %}
{% block extra_js %}{% endblock %}

</body>
</html>
`;

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'ARAYÜZ ŞABLON', disabled: false, hasError: false },
  { label: 'DOSYA', disabled: false, hasError: false },
  { label: 'LOGO', disabled: false, hasError: false },
  { label: 'DİĞER RESİMLER', disabled: false, hasError: false },
  { label: 'EPOSTA ŞABLON', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  tanim: '',
  yayina_baslama_zamani: null,
  yayini_bitirme_zamani: null,
  site: [],
  aktif: true,
  varsayilan: false,
};

const ARAYUZ_SABLON_FORM_DEFAULT = {
  form: {
    tanim: '',
    alternatif_tanim: '',
    yayina_baslama_zamani: null,
    yayini_bitirme_zamani: null,
    versiyon: '0',
    icerik: '',
    aktif: true,
    cekildimi: true
  },
  errors: null
}

const DOSYA_FORM_DEFAULT = {
  form: {
    dosya: {
      dosya: '',
      dosya_ismi: ''
    },
    dosya_tip: '0',
    mobil_web: []
  },
  errors: null
}

const DİGER_FORM_DEFAULT = {
  form: {
    dosya: {
      dosya: '',
      dosya_ismi: ''
    },
    dosya_tip: '2',
    mobil_web: []
  },
  errors: null
}

const LOGO_FORM_DEFAULT = [
  {
    form: {
      dosya: {
        dosya: '',
        dosya_ismi: ''
      },
      dosya_tip: '3',
      mobil_web: [0]
    },
    errors: null
  },
  {
    form: {
      dosya: {
        dosya: '',
        dosya_ismi: ''
      },
      dosya_tip: '4',
      mobil_web: [0]
    },
    errors: null
  },
  {
    form: {
      dosya: {
        dosya: '',
        dosya_ismi: ''
      },
      dosya_tip: '5',
      mobil_web: [0, 1]
    },
    errors: null
  },
  {
    form: {
      dosya: {
        dosya: '',
        dosya_ismi: ''
      },
      dosya_tip: '6',
      mobil_web: [1]
    },
    errors: null
  }
]

const SABLON_MAIL_DEFAULT = {
  form: {
    tanim: 'Tanımsız',
    tip: 0,
    diller: []
  },
  errors: null
}

const ICERIK_FORM_DEFAULT = {
  dil_id: '',
  baslik: '',
  konu: '',
  sablon: '',
  yazi_sablon: '',
}

const createIceriklerStates = async (diller, icerikler) => {
  const states = [];
  diller.sort((a, b) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...ICERIK_FORM_DEFAULT,
        dil_id: dil.id,
      },
      errors: null
    }
    const sablonicerik = icerikler?.find((i) => i.dil_id === dil.id);
    if (sablonicerik) {
      data.form.dil_id = sablonicerik.dil_id;
      data.form.baslik = sablonicerik.baslik;
      data.form.konu = sablonicerik.konu;
      data.form.sablon = sablonicerik.sablon;
      data.form.yazi_sablon = sablonicerik.yazi_sablon;
    }
    states.push(data);
  });
  return states;
};

const createFileTree = async (list) => {
  let result = [];
  let start = { result };
  list.forEach(listItem => {
    listItem.tanim.split('/').reduce((p, title) => {
      if (title.includes('.')) {
        if (!p[title]) {
          p[title] = { result: [] };
          p.result.push({ title, path: listItem.tanim.split(title)[0] + title, key: listItem.tanim.split(title)[0] + title + listItem.versiyon, multi: false, children: p[title].result, object: listItem });
        }
        else {
          p[title].result.push({ title, path: listItem.tanim.split(title)[0] + title, key: listItem.tanim.split(title)[0] + title + listItem.versiyon, multi: true, children: [], object: listItem });
        }
      } else {
        if (!p[title]) {
          p[title] = { result: [] };
          p.result.push({ title, path: listItem.tanim.split(title)[0] + title, key: listItem.tanim.split(title)[0] + title, multi: false, children: p[title].result });
        }
      }
      return p[title];
    }, start)
  })
  return result;
}


const SablonDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingAraYuz, setLoadingAraYuz] = useState(true);
  const [loadingDosya, setLoadingDosya] = useState(true);
  const [loadingMailSablon, setLoadingMailSablon] = useState(true);
  const [loadingDil, setLoadingDil] = useState(true);
  const [sablonCekLoad, setSablonCekLoad] = useState(false);
  const [dosyaCekLoad, setDosyaCekLoad] = useState(false);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataAraYuz, setHataAraYuz] = useState(null);
  const [hataDosya, setHatDosya] = useState(null);
  const [hataSablonMail, setHataSablonMail] = useState(null);
  const [hataDil, setHataDil] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(FORM_DEFAULT);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [formCount, setFormCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  const [araYuzForm, setAraYuzForm] = useState([]);
  const [araYuzFormDefault, setAraYuzFormDefault] = useState([]);
  const [fileTree, setFileTree] = useState([]);
  const [devMode, setDevMode] = useState(false);

  const [dosyaSelectedTabIndex, setDosyaSelectedTabIndex] = useState(0);
  const [dosyaForm, setDosyaForm] = useState([]);
  const [dosyaFormDefault, setDosyaFormDefault] = useState([]);
  const [dosyaAdd, setDosyaAdd] = useState(true);

  const [logoForm, setLogoForm] = useState([]);
  const [logoFormDefault, setLogoFormDefault] = useState([]);

  const [digerSelectedTabIndex, setDigerSelectedTabIndex] = useState(0);
  const [digerForm, setDigerForm] = useState([]);
  const [digerFormDefault, setDigerFormDefault] = useState([]);
  const [digerAdd, setDigerAdd] = useState(true);

  const [mailSablonSelectedTabIndex, setMailSablonSelectedTabIndex] = useState(0);
  const [mailSablonFormDefault, setMailSablonFormDefault] = useState([]);
  const [mailSablonForm, setMailSablonForm] = useState([]);
  const [mailAdd, setMailAdd] = useState(true);
  const [mailTip, setMailTip] = useState([]);
  const [mailTipDic, setMailTipDic] = useState({});

  const [mailIcerikSelectedTabIndex] = useState(0);
  const [mailIcerikForms, setMailIcerikForm] = useState([]);
  const [mailIcerikFormsDefault, setMailIcerikFormDefault] = useState([]);
  const [dilMiniList, setDilMiniList] = useState([]);
  const [mailIcerikDiller, setMailIcerikDiller] = useState([]);

  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteId, setDeleteIdDetail] = useState(null);
  const [showDeleteDialogDetail, setShowDeleteDialogDetail] = useState(false);
  const [isDeletingDetail, setIsDeletingDetail] = useState(false);
  const [isDeletingErrorMessageDetail, setIsDeletingErrorMessageDetail] = useState(null);

  const [detailDeleteId2, setDeleteIdDetail2] = useState(null);
  const [showDeleteDialogDetail2, setShowDeleteDialogDetail2] = useState(false);
  const [isDeletingDetail2, setIsDeletingDetail2] = useState(false);
  const [isDeletingErrorMessageDetail2, setIsDeletingErrorMessageDetail2] = useState(null);

  const [detailDeleteId3, setDeleteIdDetail3] = useState(null);
  const [showDeleteDialogDetail3, setShowDeleteDialogDetail3] = useState(false);
  const [isDeletingDetail3, setIsDeletingDetail3] = useState(false);
  const [isDeletingErrorMessageDetail3, setIsDeletingErrorMessageDetail3] = useState(null);

  const [type, setType] = useState(props.eventType ?? 0);
  const [loadDone, setLoadDone] = useState(false);
  const [loadTab1, setLoadTab1] = useState(false);
  const [loadTab2, setLoadTab2] = useState(false);
  const [loadTab3, setLoadTab3] = useState(false);
  const [loadTab4, setLoadTab4] = useState(false);
  const [equal1, setEqual1] = useState(true);
  const [equal2, setEqual2] = useState(true);
  const [equal3, setEqual3] = useState(true);
  const [equal4, setEqual4] = useState(true);
  const [saveErrors, setSaveErrors] = useState([[], [], [], []]);


  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props.id]);
  const IS_ADD = useMemo(() => props.addnew, [props.addnew]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(araYuzForm, []) && _isEqual(dosyaForm, []) && _isEqual(logoForm, []) && _isEqual(digerForm, []) && _isEqual(mailSablonForm, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualAraYuz = _isEqual(araYuzForm, araYuzFormDefault);
    const isEqualDosya = _isEqual(dosyaForm, dosyaFormDefault);
    const isEqualLogo = _isEqual(logoForm, logoFormDefault);
    const isEqualDiger = _isEqual(digerForm, digerFormDefault);
    const isEqualMail = _isEqual(mailSablonForm, mailSablonFormDefault) && _isEqual(mailIcerikForms, mailIcerikFormsDefault);
    return isEqualGenel && isEqualAraYuz && isEqualDosya && isEqualLogo && isEqualDiger && isEqualMail;
  }, [add, genelForm, genelFormDefault, araYuzForm, araYuzFormDefault, dosyaForm, dosyaFormDefault, logoForm, logoFormDefault, digerForm, digerFormDefault, mailSablonForm, mailSablonFormDefault, mailIcerikForms, mailIcerikFormsDefault]);

  useLayoutEffect(() => {
    setAdd(false);
    setLoadDone(false);
  }, [ID]);

  useLayoutEffect(() => {
    setAraYuzForm([]);
    setAraYuzFormDefault([]);
    setFormCount(0);
    setPage(1);
  }, [selectedTabIndex]);

  useLayoutEffect(() => {
    if (loadDone) {
      setType(2);
    } else {
      setType(props.eventType ?? 0);
    }
  }, [props.eventType, loadDone]);

  useLayoutEffect(() => {
    if (type === 0) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setAraYuzForm([]);
      setAraYuzFormDefault([]);
      setFormCount(0);
      setPage(1);

      setDosyaForm([]);
      setDosyaFormDefault([]);
      setLogoForm([]);
      setLogoFormDefault([]);
      setDigerForm([]);
      setDigerFormDefault([]);

      setMailSablonForm([]);
      setMailSablonFormDefault([]);
      setMailIcerikForm(null);
      setMailIcerikFormDefault(null);
      setTabs(TABS_DEFAULT);

      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          setLoadTab2(true);
          break;
        case 2:
        case 3:
        case 4:
          setLoadTab1(true);
          setLoadTab3(true);
          break;
        case 5:
          setLoadTab1(true);
          setLoadTab4(true);
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else if (type === 1) {
      setGenelForm(null);
      setGenelFormDefault(null);
      if (!saveErrors[1].includes("err")) {
        setAraYuzForm([]);
        setAraYuzFormDefault([]);
        setFormCount(0);
        setPage(1);
      }
      if (!saveErrors[2].includes("err")) {
        setDosyaForm([]);
        setDosyaFormDefault([]);
        setLogoForm([]);
        setLogoFormDefault([]);
        setDigerForm([]);
        setDigerFormDefault([]);
      }
      if (!saveErrors[3].includes("err")) {
        setMailSablonForm([]);
        setMailSablonFormDefault([]);
        setMailIcerikForm(null);
        setMailIcerikFormDefault(null);
      }
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          if (!saveErrors[1].includes("err")) {
            setLoadTab2(true);
          }
          break;
        case 2:
        case 3:
        case 4:
          setLoadTab1(true);
          if (!saveErrors[2].includes("err")) {
            setLoadTab3(true);
          }
          break;
        case 5:
          setLoadTab1(true);
          if (!saveErrors[3].includes("err")) {
            setLoadTab4(true);
          }
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else {
      if (!add) {
        switch (selectedTabIndex) {
          case 0:
            if (equal1) {
              setLoadTab1(true);
            }
            break;
          case 1:
            if (equal2) {
              setLoadTab2(true);
            }
            break;
          case 2:
          case 3:
          case 4:
            if (equal3) {
              setLoadTab3(true);
            }
            break;
          case 5:
            if (equal4) {
              setLoadTab4(true);
            }
            break;
          default:
            break;
        }
      } else {
        setLoading(false);
        setLoadingAraYuz(false);
        setLoadingDosya(false);
        setLoadingMailSablon(false);
        setLoadingDil(false);
        setLoadTab1(false);
        setLoadTab2(false);
        setLoadTab3(false);
        setLoadTab4(false);
      }
    }
  }, [type, selectedTabIndex, saveErrors, equal1, equal2, equal3, equal4, add]);

  useLayoutEffect(() => {
    if (add) {
      setEqual1(_isEqual(genelForm, FORM_DEFAULT));
    } else {
      setEqual1(_isEqual(genelForm, genelFormDefault));
    }
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual2(_isEqual(araYuzForm, []));
    } else {
      if (!sablonCekLoad) {
        setEqual2(_isEqual(araYuzForm, araYuzFormDefault));
      }
    }
  }, [add, araYuzForm, araYuzFormDefault, sablonCekLoad]);

  useLayoutEffect(() => {
    if (add) {
      setEqual3(_isEqual(dosyaForm, []) && _isEqual(logoForm, []) && _isEqual(digerForm, []));
    } else {
      if (!dosyaCekLoad) {
        setEqual3(_isEqual(dosyaForm, dosyaFormDefault) && _isEqual(logoForm, logoFormDefault) && _isEqual(digerForm, digerFormDefault));
      }
    }
  }, [add, dosyaForm, dosyaFormDefault, logoForm, logoFormDefault, digerForm, digerFormDefault, dosyaCekLoad]);

  useLayoutEffect(() => {
    if (add) {
      setEqual4(_isEqual(mailSablonForm, []));
    } else {
      setEqual4(_isEqual(mailSablonForm, mailSablonFormDefault) && _isEqual(mailIcerikForms, mailIcerikFormsDefault));
    }
  }, [add, mailSablonForm, mailSablonFormDefault, mailIcerikForms, mailIcerikFormsDefault]);

  useLayoutEffect(() => {
    if (loadTab1) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              yayina_baslama_zamani: data.yayina_baslama_zamani ? moment(data.yayina_baslama_zamani).format('YYYY-MM-DD[T]HH:mm') : null,
              yayini_bitirme_zamani: data.yayini_bitirme_zamani ? moment(data.yayini_bitirme_zamani).format('YYYY-MM-DD[T]HH:mm') : null,
              site: data.site,
              aktif: data.aktif,
              varsayilan: data.varsayilan,
            };
            setGenelFormDefault(form);
            setGenelForm(form);
            setHataGenel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setLoadTab1(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DEFAULT);
        setGenelForm(FORM_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
        setLoadTab1(false);
      }
    }
  }, [ID, IS_ADD, loadTab1]);

  useLayoutEffect(() => {
    if (loadTab2) {
      if (!IS_ADD) {
        django(`${PATH}/${ID}/arayuzsablon/count`).then(({ data }) => setTotalCount(data)).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataAraYuz(true);
            }
          }
        });
      } else {
        setTotalCount(0);
      }
    }
  }, [ID, IS_ADD, loadTab2]);

  useLayoutEffect(() => {
    setPage((prev) => immer(prev, (next) => {
      if (formCount > 0) {
        if (totalCount > formCount) {
          setLoadTab2(true);
          if (Math.ceil(formCount / 100) === next) {
            return next + 1
          } else {
            return Math.ceil(formCount / 100)
          }

        }
      }
    }));
  }, [totalCount, formCount]);

  useLayoutEffect(() => {
    if (loadTab2) {
      setLoadingAraYuz(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/arayuzsablon`, { params: { size: 100, page: page } }).then(({ data }) => {
            const forms = data.map((data, index) => (
              {
                form: {
                  index: page === 1 ? index : (page - 1) * 100 + index,
                  id: data.id,
                  tanim: data.tanim,
                  alternatif_tanim: data.alternatif_tanim,
                  yayina_baslama_zamani: data.yayina_baslama_zamani ? moment(data.yayina_baslama_zamani).format('YYYY-MM-DD[T]HH:mm') : null,
                  yayini_bitirme_zamani: data.yayini_bitirme_zamani ? moment(data.yayini_bitirme_zamani).format('YYYY-MM-DD[T]HH:mm') : null,
                  versiyon: String(data.versiyon),
                  icerik: "",
                  aktif: data.aktif,
                  cekildimi: false
                },
                errors: null
              }
            ));

            setAraYuzForm((prev) => {
              if ([...prev].length > 0) {
                if ([...prev].map(x => x.form.id).some(y => [...forms].map(x => x.form.id).includes(y))) {
                  createFileTree([...prev].map((x) => x.form)).then((res) => setFileTree(res));
                  return [...prev];
                } else {
                  createFileTree([...prev, ...forms].map((x) => x.form)).then((res) => setFileTree(res));
                  return [...prev, ...forms];
                }
              } else {
                createFileTree([...forms].map((x) => x.form)).then((res) => setFileTree(res));
                return [...forms];
              }
            });

            setAraYuzFormDefault((prev) => {
              if ([...prev].length > 0) {
                if ([...prev].map(x => x.form.id).some(y => [...forms].map(x => x.form.id).includes(y))) {
                  setFormCount([...prev].length);
                  return [...prev];
                } else {
                  setFormCount([...prev, ...forms].length);
                  return [...prev, ...forms];
                }
              } else {
                setFormCount([...forms].length);
                return [...forms];
              }
            });

            if (data.length < 100) {
              setLoadTab2(false);
            }
            setHataAraYuz(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataAraYuz(true);
              }
            }
            setLoadTab2(false);
          }).finally(() => {
            setLoadingAraYuz(false);
          })
        }, 300);
        return () => {
          clearTimeout(debounce);
        }
      } else {
        setAraYuzForm([]);
        setFormCount(0);
        setPage(1);
        setFileTree([]);
        setHataAraYuz(null);
        setLoadingAraYuz(false);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, page, loadTab2]);

  useLayoutEffect(() => {
    if (loadTab3) {
      setLoadingDosya(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/sablonstaticfile`).then(({ data }) => {
            const forms = data.map((data) => (
              {
                form: {
                  id: data.id,
                  dosya: {
                    dosya: data.dosya,
                    dosya_ismi: data.dosya.split('/').pop()
                  },
                  dosya_tip: String(data.dosya_tip),
                  mobil_web: data.mobil_web
                },
                errors: null
              }
            ));

            const dosyaforms = forms.filter((x) => x.form.dosya_tip === '0' || x.form.dosya_tip === '1' || x.form.dosya_tip === '7');
            const logoform = forms.find((x) => x.form.dosya_tip === '3');
            const favicoform = forms.find((x) => x.form.dosya_tip === '4');
            const fotoyokform = forms.find((x) => x.form.dosya_tip === '5');
            const mobillogoform = forms.find((x) => x.form.dosya_tip === '6');
            let logoforms = [];
            logoforms.push(logoform ? logoform : LOGO_FORM_DEFAULT[0]);
            logoforms.push(favicoform ? favicoform : LOGO_FORM_DEFAULT[1]);
            logoforms.push(fotoyokform ? fotoyokform : LOGO_FORM_DEFAULT[2]);
            logoforms.push(mobillogoform ? mobillogoform : LOGO_FORM_DEFAULT[3]);
            const digerforms = forms.filter((x) => x.form.dosya_tip === '2');
            setLogoForm(logoforms);
            setLogoFormDefault(logoforms);
            setDosyaForm(dosyaforms);
            setDosyaFormDefault(dosyaforms);
            setDigerForm(digerforms);
            setDigerFormDefault(digerforms);
            setHatDosya(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHatDosya(true);
              }
            }
          }).finally(() => {
            setLoadingDosya(false);
            setLoadTab3(false);
          })
        }, 300);
        return () => {
          clearTimeout(debounce);
        }
      } else {
        setLogoForm([]);
        setLogoFormDefault([]);
        setDosyaForm([]);
        setDosyaFormDefault([]);
        setDigerForm([]);
        setDigerFormDefault([]);
        setHatDosya(null);
        setLoadingDosya(false);
        setLoadTab3(false);
      }
    }
  }, [ID, IS_ADD, loadTab3]);

  useLayoutEffect(() => {
    if (loadTab4) {
      setLoadingMailSablon(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/sablonmail`).then(({ data }) => {
            const forms = data.map((mail) => ({
              form: {
                id: mail.id,
                tanim: mail.tanim,
                tip: mail.tip,
                diller: mail.diller
              },
              errors: null
            }
            ));
            setMailSablonForm(forms);
            setMailSablonFormDefault(forms);
            setMailIcerikDiller(forms.map((x) => x.form.diller));
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataSablonMail(true);
              }
            }
          }).finally(() => {
            setLoadingMailSablon(false);
            setMailAdd(true);
            setLoadTab4(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoadingMailSablon(false);
        };
      } else {
        setMailSablonForm([]);
        setMailSablonFormDefault([]);
        setMailIcerikDiller([]);
        setHataSablonMail(null);
        setLoadingMailSablon(false);
        setLoadTab4(false);
      }

    }
  }, [ID, IS_ADD, loadTab4]);

  useLayoutEffect(() => {
    django(`${PATH}/${ID}/sablonmail/email_tip`).then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setMailTipDic(dict);
      setMailTip(data);
    });
  }, [ID, lastUpdateTime]);

  useLayoutEffect(() => {
    setLoadingDil(true);
    const setDil = async () => {
      let diller = [];
      mailIcerikDiller.forEach((dil) => {
        createIceriklerStates(dilMiniList, dil).then((states) => diller.push(states));
      })
      return diller;
    }
    setDil().then((state) => {
      setMailIcerikForm(state);
      setMailIcerikFormDefault(state);
    }).finally(() => setLoadingDil(false));

  }, [dilMiniList, mailIcerikDiller]);

  useLayoutEffect(() => {
    django('dil/mini_list').then(({ data }) => setDilMiniList(data)).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataDil(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
    if (property === "site" & value.length === 0) {
      handleInputErrors('', { ...genelForm, site: [] }, genelFormDefault, handleErrors, add, 'site', true, false, false, '', '', '');
    }
  };

  const handleAraYuzChangeForm = async (index, property, value, geticerik) => {
    if (totalCount === formCount) {
      const idx = araYuzForm.findIndex(x => x.form.index === index);
      if (geticerik) {
        const cekildimi = araYuzForm[idx].form.cekildimi;
        if (!cekildimi) {
          const id = araYuzForm[idx].form.id;
          await django(`${PATH}/${ID}/arayuzsablon/${id}`).then(({ data }) => {
            setAraYuzForm((prev) => {
              return immer(prev, (next) => {
                next[idx].form[property] = data.icerik;
                next[idx].form["cekildimi"] = true;
              });
            });
            setAraYuzFormDefault((prev) => {
              return immer(prev, (next) => {
                next[idx].form[property] = data.icerik;
                next[idx].form["cekildimi"] = true;
              });
            });
          }).catch(() => {
            setAraYuzForm((prev) => {
              return immer(prev, (next) => {
                next[idx].form[property] = "Veri getirilirken bir hata oluştu!";
                next[idx].form["cekildimi"] = false;
              });
            });
            setAraYuzFormDefault((prev) => {
              return immer(prev, (next) => {
                next[idx].form[property] = "Veri getirilirken bir hata oluştu!";
                next[idx].form["cekildimi"] = false;
              });
            });
          }).finally(() => setSablonCekLoad(false))
        } else {
          setSablonCekLoad(false)
        }
      } else {
        setAraYuzForm((prev) => {
          return immer(prev, (next) => {
            next[idx].form[property] = value;
          });
        });
        if (property === "icerik") {
          const handleErr = (err) => { handleErrorsArayuz(err, idx) }
          handleInputErrors2(araYuzForm[idx].form, 'icerik', handleErr);
        }
      }
    }
  };

  const handleAraYuzAddNewTab = (tanim, alternatif_tanim) => {
    let versiyon = '0';
    const newIndex = araYuzForm.reduce((max, curr) => (curr.form.index > max ? curr.form.index : max), araYuzForm[0].form.index);
    const existList = araYuzForm.filter((x) => x.form.tanim === tanim);
    if (existList.length > 0) {
      const last_version = existList.reduce((x, y) => x = x > Number(y.form.versiyon) ? x : Number(y.form.versiyon), 0);
      versiyon = String(last_version + 1);
    }
    setAraYuzForm((prev) => immer(prev, (next) => {
      next.push({ form: { ...ARAYUZ_SABLON_FORM_DEFAULT.form, tanim: tanim, alternatif_tanim: alternatif_tanim, versiyon: versiyon, index: newIndex + 1 }, errors: null });
    }));
    createFileTree([...araYuzForm, { form: { ...ARAYUZ_SABLON_FORM_DEFAULT.form, tanim: tanim, alternatif_tanim: alternatif_tanim, versiyon: versiyon, index: newIndex + 1 }, errors: null }].map((x) => x.form)).then((res) => setFileTree(res));
  }

  const handleAraYuzAddNewBase = async () => {
    const data = {
      tanim: `${genelForm.tanim}/base_site.html`,
      alternatif_tanim: 'base/base_site.html',
      yayina_baslama_zamani: null,
      yayini_bitirme_zamani: null,
      versiyon: '0',
      icerik: icerik,
      aktif: true,
      cekildimi: true
    }
    const request = {
      config: {
        method: 'POST',
        url: `sablon_paket/${ID}/arayuzsablon`,
        data: data,
      },
      successMessage: `Şablon ekleme başarıyla tamamlandı`,
      errorMessageUnexpected: `Şablon eklenirken beklenmeyen bir hata oluştu`,
    }
    await django(request.config).then(() => {
      enqueueSnackbar(request.successMessage, { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
    }).finally(() => setLastUpdateTime(Date.now()))
  }

  const handleGetActive = (obj) => {
    const existList = araYuzForm.filter((x) => x.form.tanim === obj.tanim);
    if (existList.length > 0) {
      existList.forEach((x) => {
        if (x.form.index !== obj.index) {
          if (x.form.yayina_baslama_zamani === null) {
            setAraYuzForm((prev) => {
              return immer(prev, (next) => {
                next[x.form.index].form['aktif'] = false;
              });
            });
          }
        }
      })
    }
  }

  const handleDosyaChangeForm = (index, property, property2, value) => {
    if (property2 !== '') {
      if (property2 === "mobil") {
        setDosyaForm((prev) => {
          return immer(prev, (next) => {
            const val = value === true ? [...next[index].form[property], 1] : [...next[index].form[property]].filter(x => x !== 1);
            next[index].form[property] = val;
          });
        });
      } else if (property2 === "web") {
        setDosyaForm((prev) => {
          return immer(prev, (next) => {
            const val = value === true ? [...next[index].form[property], 0] : [...next[index].form[property]].filter(x => x !== 0);
            next[index].form[property] = val;
          });
        });
      } else {
        setDosyaForm((prev) => {
          return immer(prev, (next) => {
            next[index].form[property][property2] = value;
          });
        });
        if (property2 === "dosya") {
          const handleErr = (err) => { handleErrorsDosya(err, index) }
          handleInputErrors2(dosyaForm[index].form, 'dosya', handleErr, 'dosya');
        }
      }
    } else {
      setDosyaForm((prev) => {
        return immer(prev, (next) => {
          next[index].form[property] = value;
        });
      });
    }
  };

  const handleOpenFiles = async (index, value) => {
    setDosyaFormDefault((prev) => {
      return immer(prev, (next) => {
        next[index].form['dosya']['dosya'] = value;
      });
    });
    setDosyaCekLoad(false);
  }

  const handleDosyaAddNewTab = () => {
    setDosyaAdd(false);
    setDosyaForm((prev) => immer(prev, (next) => {
      next.push({ ...DOSYA_FORM_DEFAULT });
    }));
  }

  const handleDigerChangeForm = (index, property, property2, value) => {
    if (property2 !== '') {
      if (property2 === "mobil") {
        setDigerForm((prev) => {
          return immer(prev, (next) => {
            const val = value === true ? [...next[index].form[property], 1] : [...next[index].form[property]].filter(x => x !== 1);
            next[index].form[property] = val;
          });
        });
      } else if (property2 === "web") {
        setDigerForm((prev) => {
          return immer(prev, (next) => {
            const val = value === true ? [...next[index].form[property], 0] : [...next[index].form[property]].filter(x => x !== 0);
            next[index].form[property] = val;
          });
        });
      } else {
        setDigerForm((prev) => {
          return immer(prev, (next) => {
            next[index].form[property][property2] = value;
          });
        });
      }
    } else {
      setDigerForm((prev) => {
        return immer(prev, (next) => {
          next[index].form[property] = value;
        });
      });
    }
  };

  const handleDigerAddNewTab = () => {
    setDigerAdd(false);
    setDigerForm((prev) => immer(prev, (next) => {
      next.push({ ...DİGER_FORM_DEFAULT });
    }));
  }

  const handleLogoChangeForm = (index, property, value) => {
    setLogoForm((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      });
    });
  };

  const handleChangeFormMail = (index, property, value) => {
    setMailSablonForm((prev) => {
      return immer(prev, (next) => {
        next[index]["form"][property] = value;
      });
    });
  };

  const handleChangeFormMailIcerik = (index, index2, property, value) => {
    setMailIcerikForm((prev) => {
      return immer(prev, (next) => {
        next[index][index2].form[property] = value;
      });
    });
    if (property === "sablon") {
      const handleErr = (err) => { handleErrorsMailDil(err, index, index2) }
      handleInputErrors2(mailIcerikForms[index][index2].form, 'sablon', handleErr);
    }
  }

  const handleMailAddNewTab = () => {
    setMailAdd(false);
    setMailSablonForm((prev) => immer(prev, (next) => {
      next.push({ ...SABLON_MAIL_DEFAULT });
    }));
    setMailIcerikDiller((prev) => immer(prev, (next) => {
      next.push([]);
    }));
  }

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setAraYuzForm([]);
    setAraYuzFormDefault([]);
    setFileTree([]);
    setDosyaForm([]);
    setDosyaFormDefault([]);
    setLogoForm([]);
    setLogoFormDefault([]);
    setDigerForm([]);
    setDigerFormDefault([]);
    setMailSablonForm([]);
    setMailSablonFormDefault([]);
    setMailIcerikForm(null);
    setMailIcerikFormDefault(null);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
        next[3].hasError = false;
        next[4].hasError = false;
        next[5].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setAraYuzForm([]);
    setAraYuzFormDefault([]);
    setFormCount(0);
    setPage(1);
    setLastUpdateTime(Date.now());
    setDosyaAdd(true);
    setDigerAdd(true);
    setMailAdd(true);
    setLoadTab1(true);
    setLoadTab2(true);
    setLoadTab3(true);
    setLoadTab4(true);
    setTabs(TABS_DEFAULT);
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    setAraYuzForm(araYuzFormDefault);
    createFileTree(araYuzFormDefault.map((x) => x.form)).then((res) => setFileTree(res));
    setDosyaForm(dosyaFormDefault);
    setLogoForm(logoFormDefault);
    setDigerForm(digerFormDefault);
    setMailSablonForm(mailSablonFormDefault);
    setMailIcerikForm(mailIcerikFormsDefault);
    setDosyaAdd(true);
    setDigerAdd(true);
    setMailAdd(true);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
        next[3].hasError = false;
        next[4].hasError = false;
        next[5].hasError = false;
      });
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.sablon_paket.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteDetail = () => {
    setIsDeletingDetail(true);
    setIsDeletingErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/sablonmail/${detailDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetail(false);
        setIsDeletingErrorMessageDetail(null);
      } else {
        setIsDeletingErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingDetail(false);
      setLoadTab4(true);
    });
  }

  const handleDeleteDetail2 = () => {
    setIsDeletingDetail2(true);
    setIsDeletingErrorMessageDetail2(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/sablonstaticfile/${detailDeleteId2}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetail2(false);
        setIsDeletingErrorMessageDetail2(null);
      } else {
        setIsDeletingErrorMessageDetail2(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetail2(errorMessage);
    }).finally(() => {
      setIsDeletingDetail2(false);
      setLoadTab3(true);
    });
  }

  const handleDeleteDetail3 = () => {
    setIsDeletingDetail3(true);
    setIsDeletingErrorMessageDetail3(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/arayuzsablon/${detailDeleteId3[0]}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetail3(false);
        setIsDeletingErrorMessageDetail3(null);
      } else {
        setIsDeletingErrorMessageDetail3(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetail3(errorMessage);
    }).finally(() => {
      setIsDeletingDetail3(false);
      setAraYuzForm([]);
      setAraYuzFormDefault([]);
      setFormCount(0);
      setPage(1);
      setLoadTab2(true);
    });
  }

  const handleDeleteDetail3WithoutId = (idx) => {
    const index = araYuzForm.findIndex(x => x.form.index === idx);
    let newList = [...araYuzForm];
    newList.splice(index, 1);
    setAraYuzForm((prev) => immer(prev, (next) => {
      next.splice(index, 1);
    }));
    createFileTree([...newList].map((x) => x.form)).then((res) => setFileTree(res));
  }

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Şablon Tanım', pagename: 'şablon', prop2: '' },
      { prop: 'site', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ]
    let errors = [];
    await checkAllList('sablon_paket', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {

    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (add ? _isEqual(genelForm, FORM_DEFAULT) : _isEqual(genelForm, genelFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            const data = { ...genelForm, site: genelForm.site?.map((m) => m.id) }
            reqopt = {
              data: data,
              method: add ? 'POST' : 'PUT',
              id: null,
              successMessage: `Tema şablon ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Tema şablon ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabAraYuz = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = araYuzFormDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'tanim', prop2: '' },
          { prop: 'icerik', prop2: '' },
        ]
        await checkAllTabs(next.form, (err) => handleErrorsArayuz(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const copydata = { ...next.form };
            if (isHas) {
              if (!devMode) {
                const existList = araYuzForm.filter((x) => x.form.tanim === next.form.tanim);
                if (existList.length > 0) {
                  const last_version = existList.reduce((x, y) => x = x > Number(y.form.versiyon) ? x : Number(y.form.versiyon), 0);
                  const data = { ...copydata, versiyon: last_version + 1 }
                  reqopt = {
                    data: data,
                    method: 'POST',
                    id: null,
                    successMessage: `Şablon ekleme başarıyla tamamlandı`,
                    errorMessageUnexpected: `Şablon eklenirken beklenmeyen bir hata oluştu`,
                  }
                }
              } else {
                const data = { ...copydata, versiyon: Number(copydata.versiyon) };
                reqopt = {
                  data: data,
                  method: 'PUT',
                  id: data.id,
                  successMessage: `Şablon ekleme başarıyla tamamlandı`,
                  errorMessageUnexpected: `Şablon eklenirken beklenmeyen bir hata oluştu`,
                }
              }
            } else {
              const data = { ...copydata, versiyon: Number(copydata.versiyon) };
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `Şablon ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Şablon eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabDosya = async (tip, next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      let prev = null;
      switch (tip) {
        case "dosya":
          prev = dosyaFormDefault.find(x => x.form.id === next.form.id);
          break;
        case "logo":
          prev = logoFormDefault.find(x => x.form.id === next.form.id);
          break;
        case "diger":
          prev = digerFormDefault.find(x => x.form.id === next.form.id);
          break;
        default:
          break;
      }
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'dosya', prop2: 'dosya_ismi' },
          { prop: 'dosya', prop2: 'dosya' },
        ]
        await checkAllTabs(next.form, (err) => tip === "dosya" ? handleErrorsDosya(err, index) : tip === "logo" ? handleErrorsLogo(err, index) : handleErrorsDiger(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const copydata = { ...next.form };
            let data = { ...copydata, dosya_tip: Number(copydata.dosya_tip), dosya: { dosya_ismi: copydata.dosya.dosya_ismi, dosya: copydata.dosya.dosya } };

            if (tip === 'dosya') {
              if (copydata.dosya_tip === "7") {
                let dosya = '';
                let dosya_icerik = copydata.dosya.dosya.split('base64,')[1];
                let dosya_tipi = `data:application/x-font-${copydata.dosya.dosya_ismi.split('.').slice(-1)[0]};base64,`;
                dosya = dosya_tipi + dosya_icerik;
                data = { ...copydata, dosya_tip: Number(copydata.dosya_tip), dosya: { dosya_ismi: copydata.dosya.dosya_ismi, dosya: dosya } };
              } else {
                let dosya = '';
                const dosya_icerik = copydata.dosya.dosya;
                const dosya_tipi = copydata.dosya_tip === "0" ? 'data:text/css;base64,' : 'data:text/javascript;base64,';
                //const base64Data = window.btoa(unescape(encodeURIComponent(dosya_icerik)));
                function b64EncodeUnicode(str) {
                  return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
                    return String.fromCharCode('0x' + p1);
                  }));
                }
                const base64Data = b64EncodeUnicode(dosya_icerik);
                dosya = dosya_tipi + base64Data;
                let dosya_ismi = copydata.dosya.dosya_ismi;
                if (copydata.dosya_tip === '0') {
                  let split = dosya_ismi.split('.')[0];
                  dosya_ismi = split + '.css';
                } else {
                  let split = dosya_ismi.split('.')[0];
                  dosya_ismi = split + '.js';
                }
                data = { ...copydata, dosya_tip: Number(copydata.dosya_tip), dosya: { dosya_ismi: dosya_ismi, dosya: dosya } };
              }
            }
            if (!isHas) {
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `Dosya ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Dosya eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            if (isHas) {
              reqopt = {
                data: data,
                method: 'PUT',
                id: next.form.id,
                successMessage: `Dosya güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Dosya güncellenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            if (tip === "logo" && !isHas) {
              err = null;
            } else {
              err = res;
            }
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabMail = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = mailSablonFormDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next) && _isEqual(mailIcerikForms[index], mailIcerikFormsDefault[index]);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'tanim', prop2: '' },
        ]
        await checkAllTabs(next.form, (err) => handleErrorsMail(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const diller = mailIcerikForms[index].filter((dil) => (!_isEmpty(dil.form.baslik) && !_isEmpty(dil.form.konu) && !_isEmpty(dil.form.sablon) && !_isEmpty(dil.form.yazi_sablon))).map((d) => d.form);
            const data = { ...next.form, diller: diller }
            if (isHas) {
              reqopt = {
                data: data,
                method: 'PUT',
                id: next.form.id,
                successMessage: `${next.form.tanim} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.tanim} güncellenirken beklenmeyen bir hata oluştu`,
              }
            }
            if (!isHas) {
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `${next.form.tanim} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.tanim} eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    let errors = [[], [], [], [], [], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'arayuz', request: [], error: [] },
        { index: 2, name: 'dosya', request: [], error: [] },
        { index: 3, name: 'logo', request: [], error: [] },
        { index: 4, name: 'diger', request: [], error: [] },
        { index: 5, name: 'mail', request: [], error: [] },
      ];

      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in araYuzForm) {
        const arayuzstate = [...araYuzForm];
        const res = await createRequestOptionsTabAraYuz(arayuzstate[i], i)
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      for (const i in dosyaForm) {
        const dosyastate = [...dosyaForm];
        const res = await createRequestOptionsTabDosya('dosya', dosyastate[i], i)
        tabs[2].request.push(res[0]);
        tabs[2].error.push(res[1]);
      }

      for (const i in logoForm) {
        const logostate = [...logoForm];
        const res = await createRequestOptionsTabDosya('logo', logostate[i], i)
        tabs[3].request.push(res[0]);
        tabs[3].error.push(res[1]);
      }

      for (const i in digerForm) {
        const dosyastate = [...digerForm];
        const res = await createRequestOptionsTabDosya('diger', dosyastate[i], i)
        tabs[4].request.push(res[0]);
        tabs[4].error.push(res[1]);
      }

      for (const i in mailSablonForm) {
        const mailstate = [...mailSablonForm];
        const res = await createRequestOptionsTabMail(mailstate[i], i)
        tabs[5].request.push(res[0]);
        tabs[5].error.push(res[1]);
      }

      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "sablon_paket" : `sablon_paket/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.sablon_paket.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (tab.name === 'arayuz') {
              setAraYuzForm((prev) => {
                return immer(prev, (next) => {
                  next[requestIndex].errors = null;
                })
              });
              await django({
                method: request.method,
                data: request.data,
                url: request.id ? `sablon_paket/${SAVEID}/arayuzsablon/${request.id}` : `sablon_paket/${SAVEID}/arayuzsablon`
              }).then(() => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  setAraYuzForm((prev) => {
                    return immer(prev, (next) => {
                      next[requestIndex].errors = error.response.data;
                    })
                  });
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (tab.name === 'dosya') {
              setDosyaForm((prev) => {
                return immer(prev, (next) => {
                  next[requestIndex].errors = null;
                })
              });
              await django({
                method: request.method,
                data: request.data,
                url: request.id ? `sablon_paket/${SAVEID}/sablonstaticfile/${request.id}` : `sablon_paket/${SAVEID}/sablonstaticfile`
              }).then(() => {
                setDosyaAdd(true);
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setDosyaAdd(false);
                setSelectedTabIndex(tab.index);
                setDosyaSelectedTabIndex(requestIndex);
                if (error.response) {
                  setDosyaForm((prev) => {
                    return immer(prev, (next) => {
                      next[requestIndex].errors = error.response.data;
                    })
                  });
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (tab.name === 'logo') {
              setLogoForm((prev) => {
                return immer(prev, (next) => {
                  next[requestIndex].errors = null;
                })
              });
              await django({
                method: request.method,
                data: request.data,
                url: request.id ? `sablon_paket/${SAVEID}/sablonstaticfile/${request.id}` : `sablon_paket/${SAVEID}/sablonstaticfile`
              }).then(() => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  setLogoForm((prev) => {
                    return immer(prev, (next) => {
                      next[requestIndex].errors = error.response.data;
                    })
                  });
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (tab.name === 'diger') {
              setDigerForm((prev) => {
                return immer(prev, (next) => {
                  next[requestIndex].errors = null;
                })
              });
              await django({
                method: request.method,
                data: request.data,
                url: request.id ? `sablon_paket/${SAVEID}/sablonstaticfile/${request.id}` : `sablon_paket/${SAVEID}/sablonstaticfile`
              }).then(() => {
                setDigerAdd(true);
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setDigerAdd(false);
                setSelectedTabIndex(tab.index);
                setDigerSelectedTabIndex(requestIndex);
                if (error.response) {
                  setDigerForm((prev) => {
                    return immer(prev, (next) => {
                      next[requestIndex].errors = error.response.data;
                    })
                  });
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (tab.name === 'mail') {
              setMailSablonForm((prev) => {
                return immer(prev, (next) => {
                  next[requestIndex].errors = null;
                })
              });
              await django({
                method: request.method,
                data: request.data,
                url: request.id ? `sablon_paket/${SAVEID}/sablonmail/${request.id}` : `sablon_paket/${SAVEID}/sablonmail`
              }).then(() => {
                setMailAdd(true);
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setMailAdd(false);
                setSelectedTabIndex(tab.index);
                setMailSablonSelectedTabIndex(requestIndex);
                if (error.response) {
                  setMailSablonForm((prev) => {
                    return immer(prev, (next) => {
                      next[requestIndex].errors = error.response.data;
                    })
                  });
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }
    await start();
    if (SAVEID !== null) {
      if (!add) {
        if (!errors[0].includes("err")) {
          setLoadTab1(true);
        }
        if (!errors[1].includes("err")) {
          setAraYuzForm([]);
          setAraYuzFormDefault([]);
          setFormCount(0);
          setPage(1);
          setLoadTab2(true);
        }
        if (!errors[2].includes("err") || !errors[3].includes("err") || !errors[4].includes("err")) {
          setLoadTab3(true);
        }
        if (!errors[5].includes("err")) {
          setLoadTab4(true);
        }
      } else {
        setSaveErrors([errors[0], errors[1], [errors[2], errors[3], errors[4]], errors[5]]);
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
      await checkAll();
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? false : true : true;
        next[1].hasError = !errors[1].includes("err") ? false : true;
        next[2].hasError = !errors[2].includes("err") ? false : true;
        next[3].hasError = !errors[3].includes("err") ? false : true;
        next[4].hasError = !errors[4].includes("err") ? false : true;
        next[5].hasError = !errors[5].includes("err") ? false : true;
      });
    });
  };

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsArayuz = (errors, index) => {
    setAraYuzForm((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsDosya = (errors, index) => {
    setDosyaForm((prev) => {
      let errobj = { ...prev[index]["errors"], ...errors };
      let err = {};
      if (_has(errobj, 'dosya')) {
        const dosya = _pickBy(errobj['dosya'], _identity);
        if (_isEmpty(dosya)) {
          err = _pickBy({ ...errobj, 'dosya': null }, _identity);
        } else {
          err = _pickBy({ ...errobj, 'dosya': dosya }, _identity);
        }
      } else {
        err = _pickBy(errobj, _identity);
      }
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsLogo = (errors, index) => {
    setLogoForm((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsDiger = (errors, index) => {
    setDigerForm((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsMail = (errors, index) => {
    setMailSablonForm((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsMailDil = (errors, index, index2) => {
    setMailIcerikForm((prev) => {
      const err = _pickBy({ ...prev[index][index2]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index][index2]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteConfirmDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}

        {selectedTabIndex === 1 && (
          <TabArayuz
            add={add}
            ID={ID}
            loading={loadingAraYuz}
            hata={hataAraYuz}
            forms={araYuzForm}
            onChangeForm={handleAraYuzChangeForm}
            onAddNewTab={handleAraYuzAddNewTab}
            fileTree={fileTree}
            handleGetActive={handleGetActive}
            openDeleteForm={setShowDeleteDialogDetail3}
            setDeleteId={setDeleteIdDetail3}
            setDeleteNewAdd={handleDeleteDetail3WithoutId}
            devMode={devMode}
            setDevMode={(value) => setDevMode(value)}
            addNewSablon={handleAraYuzAddNewBase}
            onCheckErrors={(errors, index) => handleErrorsArayuz(errors, index)}
            setCek={setSablonCekLoad}
          />
        )}

        {selectedTabIndex === 2 && (
          <TabDosya
            loading={loadingDosya}
            hata={hataDosya}
            handleHata={(bool) => setHatDosya(bool)}
            selectedTabIndex={dosyaSelectedTabIndex}
            forms={dosyaForm}
            onChangeForm={handleDosyaChangeForm}
            onAddNewTab={handleDosyaAddNewTab}
            kosulAdd={dosyaAdd}
            openFiles={handleOpenFiles}
            setDeleteId={setDeleteIdDetail2}
            openDeleteForm={setShowDeleteDialogDetail2}
            onCheckErrors={(errors, index) => handleErrorsDosya(errors, index)}
            setCek={setDosyaCekLoad}
          />
        )}

        {selectedTabIndex === 3 && (
          <TabLogo
            loading={loadingDosya}
            hata={hataDosya}

            forms={logoForm}
            onChangeForm={handleLogoChangeForm}

            onCheckErrors={(errors, index) => handleErrorsLogo(errors, index)}
          />
        )}
        {selectedTabIndex === 4 && (
          <TabDiger
            loading={loadingDosya}
            hata={hataDosya}

            selectedTabIndex={digerSelectedTabIndex}
            forms={digerForm}
            onChangeForm={handleDigerChangeForm}
            onAddNewTab={handleDigerAddNewTab}
            digerAdd={digerAdd}

            setDeleteId={setDeleteIdDetail2}
            openDeleteForm={setShowDeleteDialogDetail2}

            onCheckErrors={(errors, index) => handleErrorsDiger(errors, index)}
          />
        )}
        {selectedTabIndex === 5 && (
          <TabMail
            loading={loadingMailSablon}
            loadingIcerik={loadingDil}
            hata={hataSablonMail}
            hataIcerik={hataDil}

            selectedTabIndex={mailSablonSelectedTabIndex}
            forms={mailSablonForm}
            onChangeForm={handleChangeFormMail}

            selectedTabIndexDil={mailIcerikSelectedTabIndex}
            dilform={mailIcerikForms}
            onChangeDilForm={handleChangeFormMailIcerik}
            onAddNewTab={handleMailAddNewTab}
            mailAdd={mailAdd}
            tip={mailTip}
            tipDic={mailTipDic}

            setDeleteId={setDeleteIdDetail}
            openDeleteForm={setShowDeleteDialogDetail}

            onCheckErrors={(errors, index) => handleErrorsMail(errors, index)}

            onCheckErrorsDil={(errors, index, index2) => handleErrorsMailDil(errors, index, index2)}
          />
        )}
      </LayoutDetail>

      {showDeleteConfirmDialog &&
        <DialogExtraConfirm
          title="Onay"
          message="Bu temayı silmeniz halinde, temayı kullanan site bu işlemden geri dönüşü olmayacak şekilde etkilenecektir."
          cancelText="VAZGEÇ"
          submitText="ONAYLA"
          confirmText="Okudum,anlamdım,onaylıyorum."
          submitButtonProps={{ color: 'secondary' }}
          onSubmit={() => {
            setDeleteConfirm(false);
            setShowDeleteConfirmDialog(false);
            setShowDeleteDialog(true);
          }}
          onCancel={() => {
            setDeleteConfirm(false);
            setShowDeleteConfirmDialog(false);
          }}
          onConfirm={() => setDeleteConfirm(!deleteConfirm)}
          isConfirm={deleteConfirm}
        ></DialogExtraConfirm>
      }


      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Tema şablon siliniyor lütfen bekleyin'
                : 'Bu tema şablonu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetail &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetail
              ? isDeletingErrorMessageDetail
              : isDeletingDetail
                ? 'Eposta şablonu siliniyor lütfen bekleyin'
                : 'Bu eposta şablonunu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetail}
          onSubmit={handleDeleteDetail}
          onCancel={() => setShowDeleteDialogDetail(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetail2 &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetail2
              ? isDeletingErrorMessageDetail2
              : isDeletingDetail2
                ? 'Dosya siliniyor lütfen bekleyin'
                : 'Bu dosyayı silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetail2 ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetail2}
          onSubmit={handleDeleteDetail2}
          onCancel={() => setShowDeleteDialogDetail2(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetail3 &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetail3
              ? isDeletingErrorMessageDetail3
              : isDeletingDetail3
                ? `${detailDeleteId3[1]} dosyası siliniyor lütfen bekleyin`
                : `Bu ${detailDeleteId3[1]} dosyasını silmek istiyor musunuz?`
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetail3 ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetail3}
          onSubmit={handleDeleteDetail3}
          onCancel={() => setShowDeleteDialogDetail3(false)}
        ></DialogConfirm>
      }
    </>
  );
}

export default SablonDetail;