import React, { useMemo, useState, useLayoutEffect } from 'react';

import VerticalListX from '../../../components/VerticalListX_List';
import URLS from "urls";

import django from '../../../api/django';

import { useHistory } from "react-router-dom";


import Detail from './detail';

export const KategoriIndex = (props) => {
  const [ids, setIds] = useState([]);
  const [targets, setTargets] = useState([]);
  const [tanims, setTanims] = useState([]);

  const history = useHistory()

  const params = useMemo(() => props.match.params, [props]);

  useLayoutEffect(() => {
    const idler = params.id?.split('/') || [];
    setIds(idler);
  }, [params]);

  const handleTargets = (idler) => {
    const targetlar = [];
    idler.forEach((id, index) => {
      if (index === 0) {
        targetlar.push(`${URLS.tanimlar.kategori.list}${id}`)
      } else {
        targetlar[index] = `${targetlar[index - 1]}/${id}`
      }
    })
    return targetlar;
  }


  useLayoutEffect(() => {
    const idstates = handleTargets(ids);
    setTargets(idstates);
  }, [ids]);



  useLayoutEffect(() => {
    const handleTitle = async (idler) => {
      let basliklar = [];
      for (let i = 0; i < idler.length; i++) {
        await django(`kategori/${ids[i]}`).then(({ data }) => {
          basliklar.push(data.tanim);
        });
      }
      setTanims(basliklar);
    }
    handleTitle(ids);
  }, [ids]);

  const HandleClose = (i) => {
    const path = targets[i - 1];
    if (path) {
      history.push(path);
    } else {
      history.push(URLS.tanimlar.kategori.list)
    }
  }


  return (
    <>
      <div className="relative w-full h-full flex flex-col rounded bg-palette-background-default border-2 border-palette-action-disabledBackground">
        <nav className="relative w-full h-10 flex items-center justify-between bg-palette-action-hover border-b border-palette-action-selected z-10">
          <div className="flex items-center justify-start space-x-2 px-2">
            <h1 className="flex items-center justify-start m-0">
              <span className="text-sm font-medium leading-none">KATEGORİLER</span>
            </h1>
          </div>
        </nav>
        <div className="relative w-full h-full flex overflow-y-hidden bg-palette-action-hover">
          <VerticalListX
            path="kategori"
            title="Kategoriler"
            replace_key=":id+"
            target={URLS.tanimlar.kategori.detail}
            detail={Detail}
            ustId="null"
            canClose={false}
            onClose={HandleClose}
            emptyText="Alt kategori bulunmamaktadır"
            singledetail="tanimlar/kategoridetay"
          />
          {ids.length > 0 && (ids.map((item, index) => (
            <VerticalListX
              key={index + 1}
              path={`kategori/${item}/alt`}
              title={tanims[index]}
              target={targets[index]}
              detail={Detail}
              ustId={item}
              canClose={true}
              onClose={() => HandleClose(index)}
              emptyText="Alt kategori bulunmamaktadır"
              singledetail="tanimlar/kategoridetay"
            />
          )))
          }
        </div>
      </div>
    </>
  );
}

