import React, { useMemo, useState, useLayoutEffect } from 'react';
import queryString from 'query-string';
import { QUERY_STRING_OPTIONS } from '../constants/options';
import {
  has as _has,
  result as _result,
  isEmpty as _isEmpty,
  isNil as _isNil,
  isArray as _isArray
} from 'lodash';
import moment from 'moment';

import {
  TextField,
  Button,
  IconButton,
  Paper,
  AppBar,
  Toolbar,
  CircularProgress,
  Tooltip,
  Checkbox,
} from '@material-ui/core';

import {
  Close as IconClose,
} from '@material-ui/icons';

import { useSnackbar } from 'notistack';

import immer from 'immer';
import django from '../api/django';
import { makeStyles } from '@material-ui/styles';
import { Hata } from '../pages/base/Hata';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    rowItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& + &': {
        marginTop: 5,
      },
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.primary.main}`
      },
    },
    rowLabel: {
      minWidth: 150,
      maxWidth: 150,
    },
    ozellikRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.primary.main}`
      },
    },
    ozellikRowLabel: {
      minWidth: 100,
      maxWidth: 100,
    },
    button: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
    },
  };
});

const ManuelIslemDialog = ({ filters, selectedfilter, close, ustId, filterList }) => {
  const [loading, setLoading] = useState(false);
  const [hata, setHata] = useState(null);

  const [filter, setFilter] = useState([]);
  const [islemForm, setIslemForm] = useState({ islem_tipi: "0", ne_zaman_yapsin: moment(new Date()).format('YYYY-MM-DD[T]HH:mm'), aktif: false, hangi_filtreler_uygulansin: selectedfilter });
  const [islemError, setIslemError] = useState(null);

  const [entIslemTip, setEntIslemTip] = useState([]);
  const [entIslemTipDic, setEntIslemTipDic] = useState({});

  const FILTERS = useMemo(() => selectedfilter ?? [], [selectedfilter]);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useLayoutEffect(() => {
    if (FILTERS.length > 0) {
      setLoading(true);
      async function setFilters() {
        try {
          let filterArray = [];
          if (filters) {
            const q = queryString.parse(filters, QUERY_STRING_OPTIONS);
            for (let k in q) {
              const obj = filterList.find(x => k.includes(x.key));
              if (obj.url) {
                if (obj.type === "entcat") {
                  const query = q[k];
                  let datas = [];
                  if (_isArray(q[k])) {
                    for (let id of q[k]) {
                      const res = (await django(`${obj.url}/${id}`)).data;
                      datas = [...datas, res];
                    }
                  } else {
                    const res = (await django(`${obj.url}/${query}`)).data;
                    datas = [...datas, res];
                  }
                  filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: [...datas].map(x => x[obj.valueKey]).join(', ') })
                } else if (obj.type === "select") {
                  const datas = (await django(`${obj.url}`)).data;
                  const value = datas.find(x => String(x[obj.propKey]) === q[k]);
                  filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: value[obj.valueKey] });
                } else if (obj.type === "two_step") {
                  let list = [];
                  const datas1 = (await django(`${obj.url}`)).data;
                  for (let v of datas1) {
                    const datas = (await django(`${obj.url}/${v.id}/${obj.url2}`)).data;
                    const newDatas = datas.map((x) => ({
                      id: x.id,
                      tanim: `${v[obj.valueKeyU]} - ${x[obj.valueKey]}`
                    }))
                    list = [...list, ...newDatas];
                  }
                  const value = [...list].filter(x => q[k].some(item => item === String(x.id))).map(x => x.tanim).join(', ');
                  filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: value })
                } else {
                  const query = _isArray(q[k]) ? q[k].join('&id=') : q[k];
                  const datas = (await django(`${obj.url}?id=${query}`)).data;
                  filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: [...datas].map(x => x[obj.valueKey]).join(', ') })
                }
              } else {
                if (obj.type === 'text' || obj.type === 'bool') {
                  filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: q[k] })
                }
                if (obj.type === 'ozellik') {
                  const liste = _isArray(q[k]) ? q[k].join(' - ') : q[k];
                  const ozellikObj = { title: k.split('.')[1].replace(/([A-Z])/g, ' $1').replace(/^[\s_]+|[\s_]+$/g, '').replace(/[_\s]+/g, ' '), value: liste }
                  const isExist = filterArray.findIndex(x => x.key === obj.key);
                  if (isExist > -1) {
                    filterArray[isExist].value.push(ozellikObj);
                  } else {
                    filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: [ozellikObj] })
                  }
                }
              }
            }
          }
          setFilter(filterArray);
          setLoading(false);
        } catch (error) {
          setHata(true);
          setLoading(false);
        }
      }
      setFilters();
    }
  }, [filters, filterList, FILTERS]);

  useLayoutEffect(() => {
    django('enttanim/islem_tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setEntIslemTipDic(dict);
      setEntIslemTip(data);
    });
  }, []);

  const handleChange = (property, value) => {
    setIslemForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  }

  const handleSave = async () => {
    setLoading(true);
    const requestoptions = {
      config: {
        method: 'POST',
        url: `enttanim/${ustId}/manuelis`,
        data: { ...islemForm, hangi_filtreler_uygulansin: islemForm.hangi_filtreler_uygulansin.map(x => x.id) }
      },
      successMessage: `Manuel işlem ekleme başarıyla tamamlandı`,
      errorMessageUnexpected: `Manuel işlem eklenirken beklenmeyen bir hata oluştu`,
    }

    django(requestoptions.config).then(() => {
      enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
        } else {
          setIslemError(error.response.data);
        }
      } else {
        enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
      }
    }).finally(() => {
      setLoading(false);
      close();
    });
  }

  return (
    <>
      <Paper className="flex flex-col w-full h-full overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">Manuel İşlem</h3>
            <Tooltip title="Kapat">
              <IconButton
                edge="end"
                color="inherit"
                size="small"
                onClick={close}
              ><IconClose /></IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <main className="relative flex flex-grow flex-col overflow-hidden">
          <div className="h-full flex-grow">
            <div className="relative flex flex-col w-full h-full items-start overflow-hidden p-4">
              {!loading && (
                <>
                  {hata && (
                    <Hata />
                  )}
                  {!hata && (
                    <div className="flex flex-col w-full">
                      <div className={classes.layoutFormList}>
                        <div className={classes.layoutFormItem}>
                          <label className={classes.layoutFormItemLabel} htmlFor="islem_tipi">İşlem Tipi</label>
                          <Tooltip title={islemError?.islem_tipi ? islemError.islem_tipi[0] : _isNil(islemForm?.islem_tipi) ? '' : entIslemTipDic ? entIslemTipDic[islemForm.islem_tipi] : ''}>
                            <TextField
                              name="islem_tipi"
                              variant="outlined"
                              size="small"
                              value={_result(islemForm, 'islem_tipi')}
                              error={_has(islemError, 'islem_tipi')}
                              fullWidth={true}
                              onChange={(e) => handleChange('islem_tipi', e.target.value)}
                              select
                              SelectProps={{
                                native: true,
                              }}
                            >
                              {entIslemTip?.map((et) => (
                                <option key={et.key} value={et.key}>{et.value}</option>
                              ))}
                            </TextField>
                          </Tooltip>
                        </div>
                        <div className={classes.layoutFormItem}>
                          <label htmlFor="ne_zaman_yapsin" className={classes.layoutFormItemLabel}>Ne Zaman Yapılsın</label>
                          <Tooltip title={islemError?.ne_zaman_yapsin ? islemError.ne_zaman_yapsin[0] : _isNil(islemForm?.ne_zaman_yapsin) ? '' : moment(islemForm.ne_zaman_yapsin).format('DD/MM/YYYY, HH.mm')}>
                            <TextField
                              name="ne_zaman_yapsin"
                              variant="outlined"
                              size="small"
                              value={_result(islemForm, 'ne_zaman_yapsin')}
                              error={_has(islemError, 'ne_zaman_yapsin')}
                              fullWidth={true}
                              onChange={(e) => handleChange('ne_zaman_yapsin', e.target.value)}
                              type="datetime-local"
                            ></TextField>
                          </Tooltip>
                        </div>

                        <div className={classes.layoutFormItem}>
                          <label htmlFor="aktif" className={classes.layoutFormItemLabel}>Aktif</label>
                          <Checkbox
                            name="aktif"
                            color="primary"
                            checked={_result(islemForm, 'aktif', false)}
                            onChange={(e, checked) => handleChange('aktif', checked)}
                          ></Checkbox>
                        </div>

                        {FILTERS.length > 0 && (
                          <>
                            <div className={classes.layoutFormItem}>
                              <label className={classes.layoutFormItemLabel}>Filtreler</label>
                              <div>
                                {selectedfilter.map(x => x.tanim).join(' ,')}
                              </div>
                            </div>
                            <div className='w-full flex flex-col'>
                              {filter.map((k) => (
                                <div className={classes.rowItem} key={k.key}>
                                  <div className={classes.rowLabel}>{k.title}</div>
                                  {k.type !== "ozellik" && (
                                    <div>{k.value}</div>
                                  )}
                                  {k.type === "ozellik" && (
                                    <div className='w-full flex flex-col'>
                                      {k.value.map((o) => (
                                        <div key={o.title} className={classes.ozellikRow}>
                                          <div className={classes.ozellikRowLabel}>{o.title}</div>
                                          <div>{o.value}</div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </>
                        )}

                        {FILTERS.length === 0 && (
                          <div className={classes.layoutFormItem}>
                            <label className={classes.layoutFormItemLabel}>Uyarı</label>
                            <div>“Filtre boş bırakılırsa aktif olan tüm filtreler uygulanır.”</div>
                          </div>
                        )}
                      </div>

                      <div className="flex w-full mt-3 justify-center">
                        <Button
                          className={classes.button}
                          onClick={() => handleSave()}
                          disabled={!_isEmpty(islemError)}
                        >KAYDET</Button>
                      </div>
                    </div>
                  )}
                </>
              )}
              {loading && (
                <div className="flex w-full mt-3 justify-center">
                  <CircularProgress color="primary" />
                </div>
              )}
            </div>
          </div>
        </main>
      </Paper>
    </>
  );
};

export default ManuelIslemDialog;