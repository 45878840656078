/* eslint-disable no-unused-vars */
import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';
import moment from 'moment';

import {
  has as _has,
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
  isArray as _isArray
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from 'helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './EntegrasyonDetailTab_Genel';
import TabIslemLog from './EntegrasyonDetailTab_Islem/IslemList';
import TabUrun from './EntegrasyonDetailTab_Urun/UrunList';
import TabKategori from './EntegrasyonDetailTab_Kategori';
import TabZamanlama from './EntegrasyonDetailTab_Zamanlama';
import TabFiltreManuel from './EntegrasyonDetailTab_ManuelFiltre';


const PATH = 'enttanim';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'İŞLEMLER', disabled: false, hasError: false },
  { label: 'ÜRÜNLER', disabled: false, hasError: false },
  { label: 'KATEGORİ EŞLEŞTİRME', disabled: false, hasError: false },
  { label: 'ZAMANLAYICI', disabled: false, hasError: false },
  { label: 'MANUEL İŞLEMLER', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  tanim: '',
  ent_tip: '0',
  isleyici_tip: '0',
  ozel_ayar: {
    firma_kodu: '',
    min_zaman: moment("0000", "hmm").format('HH:mm')
  },
  import_ayar: {
    fiyat_detaydan_calis: "false",
    fiyat_oran: "0",
    fiyat_detaylar: []
  },
  tedarikci: null,
  magazalar: [],
  magaza: null,
  depo: null,
  kategori: null,
  dil: null,
  siralama: '0',
  aktarim: false,
  aktif: true,
};

const FORM_DEFAULT_ZAMANLAYICI = {
  form: {
    islem_tipi: "0",
    periyot_tip: "0",
    zaman_araligi: "",
    saat: "",
    dakika: "",
    saatdakika: ""
  },
  errors: null
};

const FORM_DEFAULT_FILTRE_MANUEL = {
  form: {
    islem_tipi: "0",
    ne_zaman_yapsin: null,
    aktif: true,
    hangi_filtreler_uygulansin: [],
    hemen_yap: "1",
    is_basladi: false
  },
  errors: null
};

const SECENEK_DEFAULT = [
  { key: "Fiyat_SKullanici", value: "Kullanıcı Fiyat" },
  { key: "Fiyat_Bayi", value: "Bayi Fiyat" },
  { key: "Fiyat_Ozel", value: "Özel Fiyat" }
];

const createFiyatListe = async (entFiyatList) => {
  const states = [];
  SECENEK_DEFAULT.forEach((x) => {
    const data = {
      baslik: {
        key: x.key,
        value: x.value
      },
      form: {
        secilmis_fiyat: x.key,
        fiyat_tip: '-1',
        fiyat_detay: '-1',
        fiyat_oran: ''
      }
    }
    const entFiyat = entFiyatList?.find((ef) => ef.secilmis_fiyat === x.key);
    if (entFiyat) {
      data.form.fiyat_tip = entFiyat.fiyat_tip;
      data.form.fiyat_detay = entFiyat.fiyat_detay;
      data.form.fiyat_oran = entFiyat.fiyat_oran;
    }
    states.push(data);
  });
  return states;
}

const mapCategory = (cat) => {
  if (!cat) {
    return null;
  }
  return {
    id: cat.id,
    tanim: cat.tanim,
    parent: cat.parent,
    alt: cat.alt,
    altkategoriler_url: cat.altkategoriler_url,
    altkategoriler_count: cat?.altkategoriler_count ?? 0,
  };
};

const convertTime = (val, type) => {
  if (type === "time_to_number") {
    let int = val.split(':');
    return (int[0] * 1) * 60 + (int[1] * 1);
  } else {
    const hours = String(Math.trunc(val / 60)).padStart(2, '0');
    const mins = String(val % 60).padStart(2, '0');
    return moment(`${hours}${mins}`, "hmm").format("HH:mm");
  }
}

const PER_PAGE = 100;

const TableDetailExampleDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingZamanlayicilar, setLoadingZamanlayicilar] = useState(true);
  const [loadingFiltrelerManuel, setLoadingFiltrelerManuel] = useState(true);
  const [loadingFiyat, setLoadingFiyat] = useState(true);
  const [loadingKategori, setLoadingKategori] = useState(false);
  const [loadingKategoriAlt, setLoadingKategoriAlt] = useState(false);
  const [loadingKategoriMore, setLoadingKategoriMore] = useState(false);
  const [loadingKategoriTemp, setLoadingKategoriTemp] = useState(false);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataZamanlayicilar, setHataZamanlayicilar] = useState(null);
  const [hataFiltreManuel, setHataFiltreManuel] = useState(null);
  const [hataFiyat, setHataFiyat] = useState(null);
  const [hataKategori, setHataKategori] = useState(null);
  const [hataKategoriDetail, setHataKategoriDetail] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [tabs, setTabs] = useState(TABS_DEFAULT);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [genelFormDefault, setGenelFormDefault] = useState(FORM_DEFAULT);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [genelFiyatFormAdd, setGenelFiyatFormAdd] = useState([]);
  const [genelFiyatForm, setGenelFiyatForm] = useState([]);
  const [genelFiyatFormDefault, setGenelFiyatFormDefault] = useState([]);
  const [entFiyat, setEntFiyat] = useState([]);

  const [allEntCategories, setAllEntCategories] = useState([]);
  const [firsEntCategoriesCount, setFirstEntCategoriesCount] = useState(0);
  const [selectedEntParentCategoryId, setSelectedEntParentCategoryId] = useState(null);
  const [selectedEntParentCategory, setSelectedEntParentCategory] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [shownEntCategories, setShownEntCategories] = useState([]);
  const [selectedEntCategoryDetail, setSelectedEntCategoryDetail] = useState(null);

  const [zamanlayicilarSelectedTabIndex, setZamanlayicilarSelectedTabIndex] = useState(0);
  const [zamanlayicilarFormsDefault, setZamanlayicilarFormsDefault] = useState([]);
  const [zamanlayicilarForms, setZamanlayicilarForms] = useState([]);
  const [zamanlayicilarAdd, setZamanlayicilarAdd] = useState(true);

  const [filtrelerManuelSelectedTabIndex, setFiltrelerManuelSelectedTabIndex] = useState(0);
  const [filtrelerFormsManuelDefault, setFiltrelerFormsManuelDefault] = useState([]);
  const [filtrelerFormsManuel, setFiltrelerFormsManuel] = useState([]);
  const [filtrelerManuelAdd, setFiltrelerManuelAdd] = useState(true);

  const [entTip, setEntTip] = useState([]);
  const [entTipDic, setEntTipDic] = useState({});
  const [entIsleyiciTip, setEntIsleyiciTip] = useState([]);
  const [entIsleyiciTipDic, setEntIsleyiciTipDic] = useState({});
  const [entIslemTip, setEntIslemTip] = useState([]);
  const [entIslemTipDic, setEntIslemTipDic] = useState({});
  const [entPeriyotTip, setEntPeriyotTip] = useState([]);
  const [entPeriyotTipDic, setEntPeriyotTipDic] = useState({});
  const [fiyatTanimList, setFiyatTanimList] = useState([]);
  const [fiyatTanimListDic, setFiyatTanimListDic] = useState({});
  const [kategoriIslemTipList, setkategoriIslemTipList] = useState([]);
  const [kategoriIslemTipListDic, setkategoriIslemTipListDic] = useState({});

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [zamanlayiciDeleteId, setZamanlayiciDeleteId] = useState(null);
  const [showZamanlayiciDeleteDialogDetail, setShowZamanlayiciDeleteDialogDetail] = useState(false);
  const [isDeletingZamanlayici, setIsDeletingZamanlayici] = useState(false);
  const [isDeletingZamanlayiciErrorMessageDetail, setIsDeletingZamanlayiciErrorMessageDetail] = useState(null);

  const [filtreManuelDeleteId, setFiltreManuelDeleteId] = useState(null);
  const [showFiltreManuelDeleteDialogDetail, setShowFiltreManuelDeleteDialogDetail] = useState(false);
  const [isDeletingFiltreManuelManuel, setIsDeletingFiltreManuel] = useState(false);
  const [isDeletingFiltreManuelErrorMessageDetail, setIsDeletingFiltreManuelErrorMessageDetail] = useState(null);

  const [type, setType] = useState(props.eventType ?? 0);
  const [loadDone, setLoadDone] = useState(false);
  const [loadTab1, setLoadTab1] = useState(false);
  const [loadTab4, setLoadTab4] = useState(false);
  const [loadTab5, setLoadTab5] = useState(false);
  const [loadTab6, setLoadTab6] = useState(false);
  const [equal1, setEqual1] = useState(true);
  const [equal5, setEqual5] = useState(true);
  const [equal6, setEqual6] = useState(true);
  const [saveErrors, setSaveErrors] = useState([[], [], []]);


  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(genelFiyatForm, genelFiyatFormAdd) && _isEqual(zamanlayicilarForms, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualFiyatlar = _isEqual(genelFiyatForm, genelFiyatFormDefault);
    const isEqualFiltrelerManuel = _isEqual(filtrelerFormsManuel, filtrelerFormsManuelDefault);
    const isEqualZamanlayicilar = _isEqual(zamanlayicilarForms, zamanlayicilarFormsDefault);
    return isEqualGenel && isEqualFiltrelerManuel && isEqualZamanlayicilar && isEqualFiyatlar;
  }, [add, genelForm, genelFormDefault, filtrelerFormsManuel, filtrelerFormsManuelDefault, zamanlayicilarForms, zamanlayicilarFormsDefault, genelFiyatForm, genelFiyatFormDefault, genelFiyatFormAdd]);

  // #region pageload
  useLayoutEffect(() => {
    setAdd(false);
    setLoadDone(false);
  }, [ID]);

  useLayoutEffect(() => {
    if (loadDone) {
      setType(2);
    } else {
      setType(props.eventType ?? 0);
    }
  }, [props.eventType, loadDone]);

  useLayoutEffect(() => {
    if (type === 0) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setGenelFiyatForm(null);
      setGenelFiyatFormDefault(null);

      setAllEntCategories([]);
      setFirstEntCategoriesCount(0);
      setSelectedEntParentCategoryId(null);
      setBreadcrumbs([]);
      setSelectedEntCategoryDetail(null);

      setZamanlayicilarForms([]);
      setZamanlayicilarFormsDefault([]);

      setFiltrelerFormsManuel([]);
      setFiltrelerFormsManuelDefault([]);

      setTabs(TABS_DEFAULT);
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 3:
          setLoadTab1(true);
          setLoadTab4(true);
          break;
        case 4:
          setLoadTab1(true);
          setLoadTab5(true);
          break;
        case 5:
          setLoadTab1(true);
          setLoadTab6(true);
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else if (type === 1) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setGenelFiyatForm(null);
      setGenelFiyatFormDefault(null);

      if (!saveErrors[1].includes("err")) {
        setZamanlayicilarForms([]);
        setZamanlayicilarFormsDefault([]);
      }
      if (!saveErrors[2].includes("err")) {
        setFiltrelerFormsManuel([]);
        setFiltrelerFormsManuelDefault([]);
      }
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 3:
          setLoadTab1(true);
          setLoadTab4(true);
          break;
        case 4:
          setLoadTab1(true);
          if (!saveErrors[1].includes("err")) {
            setLoadTab5(true);
          }
          break;
        case 5:
          setLoadTab1(true);
          if (!saveErrors[2].includes("err")) {
            setLoadTab6(true);
          }
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else {
      if (!add) {
        switch (selectedTabIndex) {
          case 0:
            if (equal1) {
              setLoadTab1(true);
            }
            break;
          case 3:
            setLoadTab4(true);
            break;
          case 4:
            if (equal5) {
              setLoadTab5(true);
            }
            break;
          case 5:
            if (equal6) {
              setLoadTab6(true);
            }
            break;
          default:
            break;
        }
      } else {
        setLoading(false);
        setLoadingKategori(false);
        setLoadingZamanlayicilar(false);
        setLoadingFiltrelerManuel(false);
        setLoadTab1(false);
        setLoadTab4(false);
        setLoadTab5(false);
        setLoadTab6(false);
      }
    }
  }, [type, selectedTabIndex, saveErrors, equal1, equal5, equal6, add]);

  useLayoutEffect(() => {
    if (add) {
      setEqual1(_isEqual(genelForm, FORM_DEFAULT) && _isEqual(genelFiyatForm, genelFiyatFormAdd));
    } else {
      setEqual1(_isEqual(genelForm, genelFormDefault) && _isEqual(genelFiyatForm, genelFiyatFormDefault));
    }
  }, [add, genelForm, genelFormDefault, genelFiyatForm, genelFiyatFormDefault, genelFiyatFormAdd]);

  useLayoutEffect(() => {
    if (add) {
      setEqual5(_isEqual(zamanlayicilarForms, []));
    } else {
      setEqual5(_isEqual(zamanlayicilarForms, zamanlayicilarFormsDefault));
    }
  }, [add, zamanlayicilarForms, zamanlayicilarFormsDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual6(_isEqual(filtrelerFormsManuel, []));
    } else {
      setEqual6(_isEqual(filtrelerFormsManuel, filtrelerFormsManuelDefault));
    }
  }, [add, filtrelerFormsManuel, filtrelerFormsManuelDefault]);
  // #endregion

  // #region page1
  useLayoutEffect(() => {
    if (loadTab1) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              isleyici_tip: String(data.isleyici_tip.key),
              ent_tip: String(data.ent_tip.key),
              ozel_ayar: {
                firma_kodu: data.ozel_ayar?.firma_kodu ?? '',
                min_zaman: convertTime(data.ozel_ayar?.min_zaman ?? 0, "number_to_time"),
              },
              import_ayar: {
                fiyat_detaydan_calis: String(data.import_ayar?.fiyat_detaydan_calis ?? false),
                fiyat_oran: String(data.import_ayar?.fiyat_oran ?? 0),
                fiyat_detaylar: data.import_ayar?.fiyat_detaylar ?? []
              },
              tedarikci: data.tedarikci ?? null,
              magazalar: data.magazalar,
              magaza: data.magaza ?? null,
              depo: data.depo ?? null,
              kategori: data.kategori ?? null,
              dil: data.dil ?? null,
              siralama: String(data.siralama),
              aktarim: data.aktarim,
              aktif: data.aktif,
            };
            setGenelFormDefault(form);
            setEntFiyat(form.import_ayar.fiyat_detaylar)
            setGenelForm(form);
            setHataGenel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setLoadTab1(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DEFAULT);
        setGenelFormDefault(FORM_DEFAULT);
        setEntFiyat([]);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
        setLoadTab1(false);
      }
    }
  }, [ID, IS_ADD, loadTab1]);

  useLayoutEffect(() => {
    setLoadingFiyat(true);
    createFiyatListe(entFiyat).then((states) => {
      setGenelFiyatFormDefault(states);
      setGenelFiyatForm(states);
      setHataFiyat(null);
    }).catch((error) => {
      setHataFiyat(true);
    }).finally(() => {
      setLoadingFiyat(false);
    });
  }, [entFiyat]);

  useLayoutEffect(() => {
    createFiyatListe([]).then((states) => {
      setGenelFiyatFormAdd(states);
    });
  }, []);

  useLayoutEffect(() => {
    django('enttanim/tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setEntTipDic(dict);
      setEntTip(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django('enttanim/isleyici_tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setEntIsleyiciTipDic(dict);
      setEntIsleyiciTip(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    async function setList() {
      const liste = [];
      const res = (await django('fiyattanim/mini_list')).data;
      for (const listItem of res) {
        const res2 = (await django(`fiyattanim/${listItem.id}/fiyatdetaylari/mini_list`)).data;
        for (const listDetailItem of res2) {
          liste.push({ id: listDetailItem.id, tanim: `${listItem.tanim} - ${listDetailItem.tanim}` });
        }
      }
      return liste;
    }
    setList().then((liste) => {
      let dict = Object.assign({}, ...liste.map((x) => ({ [x.id]: x.tanim })));
      dict = Object.assign(dict, { [-1]: '' })
      setFiyatTanimList(liste);
      setFiyatTanimListDic(dict);
    })
  }, [lastUpdateTime]);
  // #endregion

  // #region page4
  useLayoutEffect(() => {
    if (loadTab4) {
      if (!IS_ADD) {
        setLoadingKategori(true);
        const debounce = setTimeout(() => {
          const req_parent_cat = django(`enttanim/${ID}/ent-kategoritemp/`);
          const req_parent_cat_count = django(`enttanim/${ID}/ent-kategoritemp/count`);
          Promise.all([req_parent_cat, req_parent_cat_count]).then(([res_parent_cat, res_parent_cat_count]) => {
            const mappedCategories = res_parent_cat.data.map((category) => mapCategory(category));
            setAllEntCategories(mappedCategories);
            setFirstEntCategoriesCount(res_parent_cat_count.data);
            setHataKategori(null);
          }).catch(() => {
            setHataKategori(true);
          }).finally(() => {
            setLoadingKategori(false);
            setLoadTab4(false);
            setSelectedEntParentCategoryId(null);
            setBreadcrumbs([]);
            setSelectedEntCategoryDetail(null);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setAllEntCategories([]);
        setFirstEntCategoriesCount(0);
        setSelectedEntParentCategoryId(null);
        setBreadcrumbs([]);
        setSelectedEntCategoryDetail(null);
        setLoadingKategori(false);
        setLoadTab4(false);
        setHataKategori(null);
      }
    }
  }, [ID, IS_ADD, loadTab4]);

  useLayoutEffect(() => {
    if (selectedEntParentCategoryId !== null) {
      const findSelectedParentCategory = allEntCategories.find((c) => c.id === selectedEntParentCategoryId);
      setSelectedEntParentCategory(findSelectedParentCategory);
    } else {
      setSelectedEntParentCategory(null);
    }
  }, [allEntCategories, selectedEntParentCategoryId]);

  useLayoutEffect(() => {
    setShownEntCategories(allEntCategories.filter((category) => category.parent === selectedEntParentCategoryId));
  }, [allEntCategories, selectedEntParentCategoryId]);

  useLayoutEffect(() => {
    if (!loadingKategoriAlt && selectedEntParentCategory) {
      const childCategories = allEntCategories.filter((category) => category.parent === selectedEntParentCategory.id);
      if (childCategories.length === 0) {
        setLoadingKategoriAlt(true);
        const request_kategori_alt = django(`${selectedEntParentCategory.altkategoriler_url}`);
        const request_kategori_alt_count = django(`${selectedEntParentCategory.altkategoriler_url}count`);
        Promise.all([request_kategori_alt, request_kategori_alt_count]).then(([response_kategori_alt, response_kategori_alt_count]) => {
          setAllEntCategories((prevAllCategories) => {
            const copyPrevAllCategories = [...prevAllCategories].map((category) => {
              if (category.id === selectedEntParentCategory.id) {
                return {
                  ...category,
                  altkategoriler_count: response_kategori_alt_count.data,
                };
              }
              return category;
            });

            const mappedNewCategories = response_kategori_alt.data.map((category) => mapCategory(category));
            setLoadingKategoriAlt(false);
            return [...copyPrevAllCategories, ...mappedNewCategories];
          });
        }).catch(() => {
          setLoadingKategoriAlt(false);
          setHataKategori(true);
        });
      }
    }
  }, [allEntCategories, selectedEntParentCategory, loadingKategoriAlt]);

  useLayoutEffect(() => {
    django('enttanim/kategori_islem').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setkategoriIslemTipListDic(dict);
      setkategoriIslemTipList(data);
    });
  }, [lastUpdateTime]);

  const pageFirst = allEntCategories.filter(cat => cat.parent === null).length > 0 ? Math.ceil(allEntCategories.filter(cat => cat.parent === null).length / PER_PAGE) : 1;
  const countPagesFirst = firsEntCategoriesCount ? Math.ceil(firsEntCategoriesCount / PER_PAGE) : 1;

  const page = shownEntCategories.length > 0 ? Math.ceil(shownEntCategories.length / PER_PAGE) : 1;
  const countPages = selectedEntParentCategory ? Math.ceil(selectedEntParentCategory.altkategoriler_count / PER_PAGE) : 1;
  // #endregion

  // #region page5
  useLayoutEffect(() => {
    if (loadTab5) {
      setLoadingZamanlayicilar(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/zamanlama`).then(({ data }) => {
            const forms = data.map((zaman) => (
              {
                form: {
                  id: zaman.id,
                  islem_tipi: String(zaman.islem_tipi),
                  periyot_tip: String(zaman.periyot_tip),
                  zaman_araligi: zaman.zaman_araligi,
                  saat: zaman.saat,
                  dakika: zaman.dakika,
                  saatdakika: zaman.saat + ":" + zaman.dakika
                },
                errors: null
              }
            ));
            setZamanlayicilarFormsDefault(forms);
            setZamanlayicilarForms(forms);
            setHataZamanlayicilar(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataZamanlayicilar(true);
              }
            }
          }).finally(() => {
            setLoadingZamanlayicilar(false);
            setLoadTab5(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setZamanlayicilarFormsDefault([]);
        setZamanlayicilarForms([]);
        setHataZamanlayicilar(null);
        setLoadingZamanlayicilar(false);
        setLoadTab5(false);
      }
    }
  }, [ID, IS_ADD, loadTab5]);

  useLayoutEffect(() => {
    django('enttanim/islem_tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setEntIslemTipDic(dict);
      setEntIslemTip(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django('enttanim/periyot_tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setEntPeriyotTipDic(dict);
      setEntPeriyotTip(data);
    });
  }, [lastUpdateTime]);

  // #endregion

  // #region page6
  useLayoutEffect(() => {
    if (loadTab6) {
      setLoadingFiltrelerManuel(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/manuelis`).then(({ data }) => {
            const forms = data.map((filtre) => (
              {
                form: {
                  id: filtre.id,
                  islem_tipi: String(filtre.islem_tipi),
                  ne_zaman_yapsin: filtre.ne_zaman_yapsin ? moment(filtre.ne_zaman_yapsin).format('YYYY-MM-DD[T]HH:mm') : null,
                  aktif: filtre.aktif,
                  hangi_filtreler_uygulansin: filtre.hangi_filtreler_uygulansin,
                  hemen_yap: "0",
                  is_basladi: filtre.is_basladi
                },
                errors: null
              }
            ));
            setFiltrelerFormsManuelDefault(forms);
            setFiltrelerFormsManuel(forms);
            setHataFiltreManuel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataFiltreManuel(true);
              }
            }
          }).finally(() => {
            setLoadingFiltrelerManuel(false);
            setLoadTab6(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setFiltrelerFormsManuelDefault([]);
        setFiltrelerFormsManuel([]);
        setHataFiltreManuel(null);
        setLoadingFiltrelerManuel(false);
        setLoadTab6(false);
      }
    }
  }, [ID, IS_ADD, loadTab6]);

  // #endregion

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  // #region change

  // #region Genel
  const handleGenelChangeForm = (property, property2, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        if (property2) {
          next[property][property2] = value;
        } else {
          next[property] = value;
        }
      });
    });
  };

  const handleChangeGenelFormFiyat = (index, property, value) => {
    setGenelFiyatForm((prev) => {
      return immer(prev, (next) => {
        next[index]["form"][property] = value;
      });
    });
  }
  // #endregion

  // #region kategori
  const handleClickCategoryItem = (id, catname) => {
    setLoadingKategoriTemp(true);
    django(`enttanim-kategoritempkategori/?kategori_temp.id=${id}`).then(({ data }) => {
      const mapData = data.map((x) => ({
        form: {
          id: x.id,
          islem_tip: String(x.islem_tip.key),
          kategori: x.kategori,
          kategori_temp: x.kategori_temp,
          old_kategori: x.kategori,
          old_islem_tip: String(x.islem_tip.key),
          kategori_temp_name: catname,
        },
        errors: null
      }));
      if (mapData.length > 0) {
        setSelectedEntCategoryDetail(mapData[0]);
      } else {
        const detail = {
          form: {
            islem_tip: "0",
            kategori: null,
            kategori_temp: id,
            old_kategori: null,
            old_islem_tip: "0",
            kategori_temp_name: catname,
          },
          errors: null
        }
        setSelectedEntCategoryDetail(detail);
      }
    }).catch(() => {
      setHataKategoriDetail(true);
    }).finally(() => {
      setLoadingKategoriTemp(false);
    })
  }

  const handleClickCategoryItemArrow = (category, mainContainerRef,) => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    }
    const index = breadcrumbs.findIndex((crumb) => crumb.id === category.id);
    if (index > - 1) {
      const copyBreadcrumbs = [...breadcrumbs];
      copyBreadcrumbs.splice(index + 1);
      const lastParentId = copyBreadcrumbs[copyBreadcrumbs.length - 1].id;
      setSelectedEntParentCategoryId(lastParentId);
      setBreadcrumbs(copyBreadcrumbs);
    } else {
      setSelectedEntParentCategoryId(category.id);
      setBreadcrumbs([...breadcrumbs, category]);
    }
  };

  const handleGetMoreEntCategoriesFirst = (page, mainContainerRef) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setLoadingKategoriMore(true);
    django(`enttanim/${ID}/ent-kategoritemp/`, { params: { page } }).then(({ data }) => {
      const newCategories = data.map((category) => mapCategory(category));
      setAllEntCategories((prev) => {
        return [...prev, ...newCategories];
      });
    }).catch((error) => {
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setLoadingKategoriMore(false);
    });
  };

  const handleGetMoreEntCategoriesAlt = (url, page, mainContainerRef) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setLoadingKategoriMore(true);
    django(`${url}`, { params: { page } }).then(({ data }) => {
      const newCategories = data.map((category) => mapCategory(category));
      setAllEntCategories((prev) => {
        return [...prev, ...newCategories];
      });
    }).catch((error) => {
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setLoadingKategoriMore(false);
    });
  };

  const handleGoToTop = () => {
    setSelectedEntParentCategoryId(null);
    setSelectedEntParentCategory(null);
    setBreadcrumbs([]);
  }

  const handleChangeKategori = (property, value) => {
    setSelectedEntCategoryDetail((prev) => {
      return immer(prev, (next) => {
        next.form[property] = value;
      });
    });
  }

  const handleSaveKategori = () => {
    const data = { ...selectedEntCategoryDetail.form, islem_tip: Number(selectedEntCategoryDetail.form.islem_tip), kategori: selectedEntCategoryDetail.form.kategori.id };
    delete data.old_kategori;
    delete data.old_islem_tip;
    delete data.kategori_temp_name;

    const isHas = _has(data, 'id');

    const requestoptions = {
      config: {
        method: isHas ? 'PUT' : 'POST',
        url: isHas ? `enttanim-kategoritempkategori/${data.id}` : 'enttanim-kategoritempkategori',
        data: data
      },
      successMessage: `${selectedEntCategoryDetail.form.kategori_temp_name} eşleştirme başarıyla tamamlandı`,
      errorMessageUnexpected: `${selectedEntCategoryDetail.form.kategori_temp_name} eşleştirilirken beklenmeyen bir hata oluştu`,
    }
    const start = async () => {
      await django(requestoptions.config).then(({ data }) => {
        const form = {
          form: {
            id: data.id,
            islem_tip: String(data.islem_tip.key),
            kategori: data.kategori,
            kategori_temp: data.kategori_temp,
            old_kategori: data.kategori,
            old_islem_tip: String(data.islem_tip.key),
            kategori_temp_name: selectedEntCategoryDetail.form.kategori_temp_name,
          },
          errors: null
        }
        setSelectedEntCategoryDetail(form);
        enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
          } else {
            enqueueSnackbar(error.response.data, { variant: 'error' });
          }
        } else {
          enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
        }
      });
    }
    start();
  }
  // #endregion

  // #region zamanlayıcı
  const handleZamanlayicilarChangeForm = (index, property, value) => {
    setZamanlayicilarForms((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
        if (property === "saatdakika") {
          const saatdakika = value.split(':');
          next[index].form["saat"] = saatdakika[0];
          next[index].form["dakika"] = saatdakika[1];
        }
        if (property === "periyot_tip") {
          next[index].form["saat"] = "";
          next[index].form["dakika"] = "";
          next[index].form["saatdakika"] = "";
          next[index].form["zaman_araligi"] = "";
        }
      });
    });
  };

  const handleZamanlayicilarAddNewTab = () => {
    setZamanlayicilarAdd(false);
    setZamanlayicilarForms((prev) => immer(prev, (next) => {
      next.push({ ...FORM_DEFAULT_ZAMANLAYICI });
    }));
  };

  // #endregion


  // #region manuel
  const handleFiltrelerManuelChangeForm = (index, property, value) => {
    setFiltrelerFormsManuel((prev) => {
      return immer(prev, (next) => {
        if (!next[index].form["is_basladi"]) {
          next[index].form[property] = value;
        }
      });
    });
  };

  const handleFiltrelerManuelAddNewTab = () => {
    setFiltrelerManuelAdd(false);
    setFiltrelerFormsManuel((prev) => immer(prev, (next) => {
      next.push({ ...FORM_DEFAULT_FILTRE_MANUEL });
    }));
  };
  // #endregion

  // #endregion

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DEFAULT);
    setGenelFiyatForm(genelFiyatFormAdd)
    setGenelError(null);

    setAllEntCategories([]);
    setFirstEntCategoriesCount(0);
    setSelectedEntParentCategoryId(null);
    setSelectedEntParentCategory(null);
    setBreadcrumbs([]);
    setShownEntCategories([]);
    setSelectedEntCategoryDetail(null);

    setZamanlayicilarForms([]);
    setFiltrelerFormsManuel([]);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
        next[3].hasError = false;
        next[4].hasError = false;
        next[5].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
    setLoadTab1(true);
    setLoadTab4(true);
    setLoadTab5(true);
    setLoadTab6(true);
    setZamanlayicilarAdd(true);
    setFiltrelerManuelAdd(true);
    setTabs(TABS_DEFAULT);
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelFiyatForm(genelFiyatFormDefault);
    setGenelError(null);

    setAllEntCategories([]);
    setFirstEntCategoriesCount(0);
    setSelectedEntParentCategoryId(null);
    setSelectedEntParentCategory(null);
    setBreadcrumbs([]);
    setShownEntCategories([]);
    setSelectedEntCategoryDetail(null);

    setZamanlayicilarForms(zamanlayicilarFormsDefault);
    setZamanlayicilarAdd(true);
    setFiltrelerFormsManuel(filtrelerFormsManuelDefault);
    setFiltrelerManuelAdd(true);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
        next[3].hasError = false;
        next[4].hasError = false;
        next[5].hasError = false;
      });
    });
  };

  // #region save
  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Entegrasyon Tanım', pagename: 'enttanim', prop2: '' },
      { prop: 'dil', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ]
    let errors = [];
    await checkAllList('enttanim', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {

    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (add ? _isEqual(genelForm, FORM_DEFAULT) && _isEqual(genelFiyatForm, genelFiyatFormAdd) : _isEqual(genelForm, genelFormDefault) && _isEqual(genelFiyatForm, genelFiyatFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            const data = { ...genelForm, magazalar: genelForm.magazalar?.map((m) => m.id), isleyici_tip: Number(genelForm.isleyici_tip), ent_tip: Number(genelForm.ent_tip), depo: genelForm.depo?.id ?? null, kategori: genelForm.kategori?.id ?? null, dil: genelForm.dil?.id ?? null, tedarikci: genelForm.tedarikci?.id ?? null, magaza: genelForm.magaza?.id ?? null, siralama: genelForm.siralama ? Number(genelForm.siralama) : null, ozel_ayar: { firma_kodu: genelForm.ozel_ayar.firma_kodu, min_zaman: convertTime(genelForm.ozel_ayar.min_zaman, "time_to_number") }, import_ayar: { fiyat_detaydan_calis: genelForm.import_ayar.fiyat_detaydan_calis === "true" ? true : false, fiyat_oran: Number(genelForm.import_ayar.fiyat_oran), fiyat_detaylar: genelFiyatForm.filter(x => x.form.fiyat_detay !== '-1').map(x => x.form) } }
            reqopt = {
              data: data,
              method: add ? 'POST' : 'PUT',
              id: null,
              successMessage: `Entegrasyon ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Entegrasyon ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsZamanlama = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = zamanlayicilarFormsDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        let list = [];
        if (next.form.periyot_tip !== '2') {
          list = [
            { prop: 'zaman_araligi', prop2: '' },
          ];
        } else {
          list = [
            { prop: 'saat', prop2: '' },
            { prop: 'dakika', prop2: '' },
          ];
        }
        await checkAllTabs(next.form, (err) => handleErrorsZamanlayici(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const name = entPeriyotTipDic[next.form.periyot_tip] + ' - ' + (next.form.periyot_tip === '2' ? next.form.saatdakika : next.form.zaman_araligi)
            const data = { ...next.form, islem_tipi: Number(next.form.islem_tipi), periyot_tip: Number(next.form.periyot_tip), zaman_araligi: next.form.zaman_araligi ? Number(next.form.zaman_araligi) : null, saat: next.form.saat ? Number(next.form.saat) : null, dakika: next.form.dakika ? Number(next.form.dakika) : null }
            delete data.saatdakika;
            if (isHas) {
              reqopt = {
                data: data,
                method: 'PUT',
                id: next.form.id,
                successMessage: `${name} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${name} güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `${name} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${name} eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabFiltreManuel = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = filtrelerFormsManuelDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const data = { ...next.form, islem_tipi: Number(next.form.islem_tipi), hangi_filtreler_uygulansin: next.form.hangi_filtreler_uygulansin?.map((hf) => hf.id), ne_zaman_yapsin: next.form.hemen_yap === "1" ? null : next.form.ne_zaman_yapsin }
        delete data.hemen_yap;
        if (isHas) {
          reqopt = {
            data: data,
            method: 'PUT',
            id: next.form.id,
            successMessage: `Manuel filtre güncelleme başarıyla tamamlandı`,
            errorMessageUnexpected: `Manuel filtre güncellenirken beklenmeyen bir hata oluştu`,
          }
        } else {
          reqopt = {
            data: data,
            method: 'POST',
            id: null,
            successMessage: `Manuel filtre ekleme başarıyla tamamlandı`,
            errorMessageUnexpected: `Manuel filtre eklenirken beklenmeyen bir hata oluştu`,
          }
        }
        err = null;
      }
      return [reqopt, err]
    }

    let errors = [[], [], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'zamanlama', request: [], error: [] },
        { index: 2, name: 'filtremanuel', request: [], error: [] },
      ];
      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in zamanlayicilarForms) {
        const zamanlama = [...zamanlayicilarForms];
        const res = await createRequestOptionsZamanlama(zamanlama[i], i);
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      for (const i in filtrelerFormsManuel) {
        const filtremanuel = [...filtrelerFormsManuel];
        const res = await createRequestOptionsTabFiltreManuel(filtremanuel[i], i);
        tabs[2].request.push(res[0]);
        tabs[2].error.push(res[1]);
      }


      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "enttanim" : `enttanim/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.entegrasyon.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (SAVEID !== null) {
              if (tab.name === 'zamanlama') {
                setZamanlayicilarForms((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `enttanim/${SAVEID}/zamanlama/${request.id}` : `enttanim/${SAVEID}/zamanlama`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  setZamanlayicilarAdd(true);
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  setZamanlayicilarSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setZamanlayicilarForms((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'filtremanuel') {
                setFiltrelerFormsManuel((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `enttanim/${SAVEID}/manuelis/${request.id}` : `enttanim/${SAVEID}/manuelis`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  setFiltrelerManuelAdd(true);
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  setFiltrelerManuelSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setFiltrelerFormsManuel((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }

    await start();
    if (SAVEID !== null) {
      if (!add) {
        if (!errors[0].includes("err")) {
          setLoadTab1(true);
        }
        if (!errors[1].includes("err")) {
          setLoadTab5(true);
        }
        if (!errors[2].includes("err")) {
          setLoadTab6(true);
        }
      } else {
        setSaveErrors(errors);
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
      await checkAll();
    }

    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? (SAVEID ? false : true) : true;
        next[4].hasError = !errors[1].includes("err") ? false : true;
        next[5].hasError = !errors[2].includes("err") ? false : true;
      });
    });
  }
  // #endregion

  // #region delete
  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.cari.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteZamanlayici = () => {
    setIsDeletingZamanlayici(true);
    setIsDeletingZamanlayiciErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/zamanlama/${zamanlayiciDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setShowZamanlayiciDeleteDialogDetail(false);
        setIsDeletingZamanlayiciErrorMessageDetail(null);
      } else {
        setIsDeletingZamanlayiciErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingZamanlayiciErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingZamanlayici(false);
      setLoadTab5(true);
    });
  }

  const handleDeleteFiltrelerManuel = () => {
    setIsDeletingFiltreManuel(true);
    setIsDeletingFiltreManuelErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/manuelis/${filtreManuelDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setShowFiltreManuelDeleteDialogDetail(false);
        setIsDeletingFiltreManuelErrorMessageDetail(null);
      } else {
        setIsDeletingFiltreManuelErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingFiltreManuelErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingFiltreManuel(false);
      setLoadTab6(true);
    });
  }
  // #endregion

  // #region errors
  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsKategori = (errors) => {
    setSelectedEntCategoryDetail((prev) => {
      const err = _pickBy({ ...prev["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsZamanlayici = (errors, index) => {
    setZamanlayicilarForms((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsFiltrelerManuel = (errors, index) => {
    setZamanlayicilarForms((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }
  // #endregion
  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add}
        disableReloadButton={loading || add}
        disableCancelButton={!add && (loading || IS_EQUAL)}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add}
        disablePreviousButton={props.disablePreviousButton || add}
        disableNextButton={props.disableNextButton || add}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            loadingFiyat={loadingFiyat}
            hata={hataGenel}
            hataFiyat={hataFiyat}
            add={add}
            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}
            onCheckErrors={(errors) => handleErrors(errors)}
            entTip={entTip}
            entTipDic={entTipDic}
            entIsleyiciTip={entIsleyiciTip}
            entIsleyiciTipDic={entIsleyiciTipDic}
            fiyatTanimList={fiyatTanimList}
            fiyatTanimListDic={fiyatTanimListDic}
            fiyatlar={genelFiyatForm}
            onChangeFormFiyat={handleChangeGenelFormFiyat}
          />
        )}

        {selectedTabIndex === 1 && (
          <TabIslemLog
            ID={add ? null : ID}
          />
        )}

        {selectedTabIndex === 2 && (
          <TabUrun
            ID={add ? null : ID}
          />
        )}

        {selectedTabIndex === 3 && (
          <TabKategori
            loading={loadingKategori}
            loadingMore={loadingKategoriMore}
            loadingDetail={loadingKategoriTemp}
            hata={hataKategori}
            hataDetail={hataKategoriDetail}
            kategoriIslemTipList={kategoriIslemTipList}
            kategoriIslemTipListDic={kategoriIslemTipListDic}

            pageFirst={pageFirst}
            countPagesFirst={countPagesFirst}
            page={page}
            countPages={countPages}
            perPage={PER_PAGE}

            breadcrumbs={breadcrumbs}
            shownEntCategories={shownEntCategories}
            selectedEntParentCategory={selectedEntParentCategory}
            selectedEntParentCategoryId={selectedEntParentCategoryId}
            handleClickCategoryItem={handleClickCategoryItem}
            handleClickCategoryItemArrow={handleClickCategoryItemArrow}
            handleGetMoreEntCategoriesFirst={handleGetMoreEntCategoriesFirst}
            handleGetMoreEntCategoriesAlt={handleGetMoreEntCategoriesAlt}
            handleGoToTop={handleGoToTop}

            selectedEntCategoryDetail={selectedEntCategoryDetail}
            onChange={handleChangeKategori}
            onCheckErrors={(errors) => { handleErrorsKategori(errors) }}
            onSave={handleSaveKategori}
          />
        )}

        {selectedTabIndex === 4 && (
          <TabZamanlama
            loading={loadingZamanlayicilar}
            hata={hataZamanlayicilar}
            defaultSelectedTabIndex={zamanlayicilarSelectedTabIndex}
            forms={zamanlayicilarForms}
            zamanAdd={zamanlayicilarAdd}
            onAddNewTab={handleZamanlayicilarAddNewTab}
            onChangeForm={handleZamanlayicilarChangeForm}
            setDeleteId={setZamanlayiciDeleteId}
            openDeleteForm={setShowZamanlayiciDeleteDialogDetail}
            onCheckErrors={(errors, index) => { handleErrorsZamanlayici(errors, index) }}
            entIslemTip={entIslemTip}
            entIslemTipDic={entIslemTipDic}
            entPeriyotTip={entPeriyotTip}
            entPeriyotTipDic={entPeriyotTipDic}
          />
        )}
        {selectedTabIndex === 5 && (
          <TabFiltreManuel
            loading={loadingFiltrelerManuel}
            hata={hataFiltreManuel}
            ID={ID}
            defaultSelectedTabIndex={filtrelerManuelSelectedTabIndex}
            forms={filtrelerFormsManuel}
            onChangeForm={handleFiltrelerManuelChangeForm}
            filtreManuelAdd={filtrelerManuelAdd}
            onAddNewTab={handleFiltrelerManuelAddNewTab}
            setDeleteId={setFiltreManuelDeleteId}
            openDeleteForm={setShowFiltreManuelDeleteDialogDetail}
            onCheckErrors={(errors, index) => { handleErrorsFiltrelerManuel(errors, index) }}
            entIslemTip={entIslemTip}
            entIslemTipDic={entIslemTipDic}
          />
        )}
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Entegrasyon siliniyor lütfen bekleyin'
                : 'Bu entegrasyonu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }

      {showZamanlayiciDeleteDialogDetail &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingZamanlayiciErrorMessageDetail
              ? isDeletingZamanlayiciErrorMessageDetail
              : isDeletingZamanlayici
                ? 'Zamanlayıcı siliniyor lütfen bekleyin'
                : 'Bu zamanyalıcıyı gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingZamanlayiciErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingZamanlayici}
          onSubmit={handleDeleteZamanlayici}
          onCancel={() => setShowZamanlayiciDeleteDialogDetail(false)}
        ></DialogConfirm>
      }

      {showFiltreManuelDeleteDialogDetail &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingFiltreManuelErrorMessageDetail
              ? isDeletingFiltreManuelErrorMessageDetail
              : isDeletingFiltreManuelManuel
                ? 'Manuel Filtre siliniyor lütfen bekleyin'
                : 'Bu manuel filtreyi gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingFiltreManuelErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingFiltreManuelManuel}
          onSubmit={handleDeleteFiltrelerManuel}
          onCancel={() => setShowFiltreManuelDeleteDialogDetail(false)}
        ></DialogConfirm>
      }
    </>
  );
};

export default TableDetailExampleDetail;
