import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import {
  Search as IconSearch
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import CategorySelectorBesidePanelCat from '../../../../components/CategorySelectorBesidePanelCat';
import { Hata } from '../../../base/Hata';


import MultipleSelectList from '../../../../components/MultipleSelectList';
import JsonConvert from '../../../../components/JsonConvert';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    layoutFormItemBox2: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 600,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    treeList: {
      marginTop: 10,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    list: {
      marginBottom: 10
    },
    title: {
      position: 'relative',
      display: 'inline-block',
      color: palette.primary.main,
      fontSize: 15,
      fontWeight: '500'
    },
    ul: {
      position: 'relative',
      paddingLeft: '3em',
      '&::before': {
        position: 'absolute',
        top: 0,
        bottom: 7,
        left: '3em',
        display: 'block',
        width: 0,
        borderLeft: '2px solid #eacfea',
        content: '""',
      }
    },
    li: {
      padding: '15px 0 0 40px',
      position: 'relative',
      lineHeight: '15px',
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        height: 25,
        width: 30,
        borderBottom: '2px solid #eacfea',
        borderLeft: '2px solid #eacfea',
        borderRadius: '0 0 0 5px',
        content: '""',
      }
    },
  };
};


const UrunDetailXTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [openSelectorKategori, setOpenSelectorKategori] = useState(false);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form[name], `${name2}`) || defaultValue,
        error: _has(props.error?.[name], `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form, `${name}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    }
  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="urun">
                      <label className={classes.layoutFormItemLabel} htmlFor="urun">Panel Ürün</label>
                      <Tooltip title={_isNil(props.form?.urun) ? '' : props.form.urun.tanim}>
                        <TextField
                          {...createTextFieldProps('urun', 'tanim')}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="gmerchant_category">
                      <label className={classes.layoutFormItemLabel} htmlFor="gmerchant_category">Gmerchant Kategori</label>
                      <Tooltip title={props.error?.gmerchant_category ? props.error.gmerchant_category[0] : _isNil(props.form?.gmerchant_category) ? '' : props.form.urun.gmerchant_category}>
                        <TextField
                          {...createTextFieldProps('gmerchant_category', 'parents')}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                size="small"
                                className={classes.button}
                                disableRipple
                                onClick={() => setOpenSelectorKategori(true)}
                              ><IconSearch /></IconButton>
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="gmerchant_shopping_description">
                      <label className={classes.layoutFormItemLabel} htmlFor="gmerchant_shopping_description">Üretici Kodu</label>
                      <Tooltip title={props.error?.gmerchant_shopping_description ? props.error.gmerchant_shopping_description[0] : _isNil(props.form?.gmerchant_shopping_description) ? '' : props.form.gmerchant_shopping_description}>
                        <TextField
                          {...createTextFieldProps('gmerchant_shopping_description', '')}
                          multiline
                          rows={3}
                          onChange={(e) => props.onChangeForm('gmerchant_shopping_description', e.target.value)}
                        ></TextField>
                      </Tooltip>
                    </li>

                    <li className={classes.layoutFormItem} id="publish_google_shopping">
                      <label className={classes.layoutFormItemLabel} htmlFor="publish_google_shopping">Ürün Verisi Gönderildi</label>
                      <Checkbox
                        name="publish_google_shopping"
                        color="primary"
                        checked={_result(props.form, 'publish_google_shopping', false)}
                      ></Checkbox>
                    </li>
                    <li className={classes.layoutFormItemBox}>
                      <MultipleSelectList
                        headername="ÜRÜN EKSİK BİLGİLER"
                        name="Ürün Eksik Bilgi"
                        name2="Ürün eksik bilgi"
                        form={props.form}
                        list={props.form?.urun_eksik_bilgi ?? []}
                        listlength={props?.form?.urun_eksik_bilgi?.length ?? 0}
                        keyProp="key"
                        valueProp="value"
                        notEdit={true}
                        loading={props.loading}
                      />
                    </li>
                  </ul>
                </div>
                <div className={classes.layoutContainer}>
                  <div className={classes.layoutFormItemBox2}>
                    <JsonConvert
                      headername="SON GÖNDERİLEN ÜRÜN VERİSİ"
                      name="Son gönderilen ürün verisi"
                      name2="Göndirilen ürün verisi"
                      jsonlist={props.form.product_data}
                      loading={props.loading}
                    />
                  </div>
                </div>
                <div className={classes.layoutContainer}>
                  <div className={classes.layoutFormItemBox2}>
                    <JsonConvert
                      headername="GOOGLE API DÖNEN CEVAP"
                      name="Google Api dönen cevap"
                      name2="Google Api dönen cevap"
                      jsonlist={props.form.google_result}
                      loading={props.loading}
                    />
                  </div>
                </div>
              </div>
              {openSelectorKategori &&
                <Dialog
                  open={openSelectorKategori}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorKategori(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <CategorySelectorBesidePanelCat
                      path={`gmerhantaccount/${props.ustId}/gmerhantcategory`}
                      selected={props.form.gmerchant_category}
                      onChange={(items) => props.onChangeForm('gmerchant_category', items)}
                      onClose={() => setOpenSelectorKategori(false)}
                      single={true}
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default UrunDetailXTabGenel;