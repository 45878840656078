/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useLayoutEffect, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import { makeStyles } from '@material-ui/styles';
import RightMenu from '../helpers/rightMenu';

import {
  Button,
  Checkbox,
  Radio,
  Paper,
  CircularProgress,
  LinearProgress,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Tooltip,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
  Search as IconSearch,
  KeyboardArrowRight as IconKeyboardArrowRight,
  KeyboardArrowLeft as IconKeyboardArrowLeft,
  AccountTree as IconListTree,
  Delete as IconDelete,
  Refresh as IconRefresh,
} from '@material-ui/icons';

import django from '../api/django';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    },
    page: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: '50% 50%'
    },
    paper: {
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      height: '100%',
      marginLeft: '1%',
    },
    list: {
      marginBottom: 10
    },
    title: {
      position: 'relative',
      display: 'inline-block',
      color: '#976797',
      fontSize: 15,
      fontWeight: '500'
    },
    ul: {
      position: 'relative',
      paddingLeft: '3em',
      '&::before': {
        position: 'absolute',
        top: 0,
        bottom: 7,
        left: '3em',
        display: 'block',
        width: 0,
        borderLeft: '2px solid #eacfea',
        content: '""',
      }
    },
    li: {
      padding: '15px 0 0 40px',
      position: 'relative',
      lineHeight: '15px',
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        height: 25,
        width: 30,
        borderBottom: '2px solid #eacfea',
        borderLeft: '2px solid #eacfea',
        borderRadius: '0 0 0 5px',
        content: '""',
      }
    },
  };
});


const PER_PAGE = 100;
const ROW_HEIGHT = 34;

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text, submitText, onSubmit }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>

      <p className="font-medium mb-2">{text}</p>

      {onSubmit &&
        <Button
          color="primary"
          onClick={onSubmit}
        >{submitText ?? 'TEKRAR DENE'}</Button>
      }
    </div>
  );
};

const ListItemMain = ({ item, searchQuery, select, onClick }) => {
  return (
    <div
      key={`${item.key}${item.value}`}
      className={clsx('w-full flex items-center justify-between hover:text-primary px-4 space-x-3 select-none cursor-pointer')}
      style={{ height: ROW_HEIGHT }}
    >
      <Highlighter
        textToHighlight={`${item.value}`}
        searchWords={[searchQuery ?? '']}
        className=""
        activeClassName=""
        highlightClassName="leading-none"
        unhighlightClassName="leading-none"
      />
      <span className="w-12 flex items-center justify-center">
        <IconButton
          size="small"
          disableRipple
          onClick={() => onClick(item)}
        >
          <IconKeyboardArrowRight />
        </IconButton>
        {select && (
          <span>*</span>
        )}
      </span>
    </div>
  );
};

const ListItemCheckbox = ({ item, checked, searchQuery, onClick }) => {
  return (
    <div
      key={`${item.key}${item.value}`}
      className={clsx('w-full flex items-center justify-start hover:text-primary px-4 space-x-3 select-none cursor-pointer')}
      style={{ height: ROW_HEIGHT }}
      onClick={() => onClick(!checked)}
    >
      <Checkbox
        color="primary"
        checked={checked}
        onChange={(e, checked) => onClick(checked)}
        style={{ padding: 0 }}
      ></Checkbox>

      <Highlighter
        textToHighlight={`${item.value}`}
        searchWords={[searchQuery ?? '']}
        className=""
        activeClassName=""
        highlightClassName="leading-none"
        unhighlightClassName="leading-none"
      />
    </div>
  );
};
const ListItemRadio = ({ item, selected, searchQuery, onClick }) => {
  return (
    <div
      key={`${item.key}${item.value}`}
      className={clsx('w-full flex items-center justify-start hover:text-primary px-4 space-x-3 select-none cursor-pointer')}
      style={{ height: ROW_HEIGHT }}
      onClick={() => onClick(item)}
    >
      <Radio
        color="primary"
        checked={selected.key === item.key}
        value={item.key}
        onChange={() => onClick(item)}
        style={{ padding: 0 }}
      ></Radio>
      <Highlighter
        textToHighlight={`${item.value}`}
        searchWords={[searchQuery ?? '']}
        className=""
        activeClassName=""
        highlightClassName="leading-none"
        unhighlightClassName="leading-none"
      />
    </div>
  );

};

const ListSelectorTwoStep = ({ title, mainItemsPath, mainItemsCountPath, mainDefaultItems, mainMultiple, mainKeyProp, mainValueProp, listPath, listCountPath, listMultiple, keyProp, valueProp, keyUId, valueUV, defaultItems, onChangeItems, onClose, onDetayListClose, detayListOpen, detayList, url, confirmButton, confirmEnable, onConfirm, confirmBtnTitle, openSelectedMain }) => {

  const classes = useStyles();

  const [mainItems, setMainItems] = useState([]);
  const [mainItemsLoading, setMainItemsLoading] = useState(false);
  const [mainItemsErrorMessage, setMainItemsErrorMessage] = useState(null);
  const [mainItemsTotalCount, setMainItemsTotalCount] = useState(0);
  const [moreMainItemsLoading, setMoreMainItemsLoading] = useState(false);

  const [items, setItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsErrorMessage, setItemsErrorMessage] = useState(null);
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);

  const [searchQueryMain, setSearchQueryMain] = useState('');
  const [searchMainItems, setSearchMainItems] = useState([]);
  const [searchMainItemsLoading, setSearchMainItemsLoading] = useState(false);
  const [searchMainItemsLoadingErrorMessage, setSearchMainItemsLoadingErrorMessage] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchItems, setSearchItems] = useState([]);
  const [searchItemsLoading, setSearchItemsLoading] = useState(false);
  const [searchItemsLoadingErrorMessage, setSearchItemsLoadingErrorMessage] = useState(null);

  const [selectedMainItem, setSelectedMainItem] = useState(null);
  const [listOpen, setListOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const MAINPAGE = useMemo(() => mainItems.length > 0 ? Math.ceil(mainItems.length / PER_PAGE) : 1, [mainItems.length]);

  const MAINPAGE_COUNT = useMemo(() => Math.ceil(mainItemsTotalCount / PER_PAGE), [mainItemsTotalCount]);

  const PAGE = useMemo(() => items.length > 0 ? Math.ceil(items.length / PER_PAGE) : 1, [items.length]);

  const PAGE_COUNT = useMemo(() => Math.ceil(itemsTotalCount / PER_PAGE), [itemsTotalCount]);

  const treeListOpen = useMemo(() => detayListOpen, [detayListOpen]);

  const mainContainerRef = useRef();

  const MAPPED_DEFAULT_ITEMS_MAIN = useMemo(() => {
    if (mainMultiple) {
      return mainDefaultItems.map((item) => ({
        key: item[mainKeyProp],
        value: item[mainValueProp],
      }));
    } else {
      if (mainDefaultItems) {
        return {
          key: mainDefaultItems[mainKeyProp],
          value: mainDefaultItems[mainValueProp]
        }
      } else {
        return {}
      }
    }
  }, [mainDefaultItems, mainKeyProp, mainValueProp, mainMultiple]);

  const MAPPED_DEFAULT_ITEMS = useMemo(() => {
    if (listMultiple) {
      return defaultItems.map((item) => ({
        key: item[keyProp],
        value: item[valueProp],
        keyUId: item[keyUId],
        valueUV: item[valueUV]
      }));
    } else {
      if (defaultItems) {
        return {
          key: defaultItems[keyProp],
          value: defaultItems[valueProp],
          keyUId: defaultItems[keyUId],
          valueUV: defaultItems[valueUV]
        }
      } else {
        return {}
      }
    }
  }, [defaultItems, keyProp, valueProp, keyUId, valueUV, listMultiple]);

  const TREE_ITEMS = useMemo(() => detayList, [detayList])

  const mapItem = useCallback((item) => {
    return {
      key: item[mainKeyProp],
      value: item[mainValueProp],
    };
  }, [mainKeyProp, mainValueProp]);

  const mapItem2 = useCallback((item) => {
    return {
      key: item[keyProp],
      value: item[valueProp],
      keyUId: selectedMainItem['key'],
      valueUV: selectedMainItem['value']
    };
  }, [keyProp, valueProp, selectedMainItem]);

  useLayoutEffect(() => {
    setMainItemsLoading(true);
    django(mainItemsPath, { params: { size: PER_PAGE, sort: mainValueProp } })
      .then(({ data }) => {
        const mainmapped = data.map(mapItem);
        setMainItems(mainmapped);
      })
      .catch(() => {
        setMainItemsErrorMessage('Beklenmeyen bir hata oluştu')
      })
      .finally(() => {
        setMainItemsLoading(false);
      })
  }, [mainItemsPath, mapItem, mainValueProp, lastUpdateTime]);

  useLayoutEffect(() => {
    if (openSelectedMain) {
      setSelectedMainItem(openSelectedMain);
      setListOpen(true);
      setSearchQueryMain('');
      setSearchMainItemsLoading(false);
      setSearchMainItemsLoadingErrorMessage(null);
      setSearchQuery('');
      setSearchItemsLoading(false);
      setSearchItemsLoadingErrorMessage(null);
    }
  }, [openSelectedMain])

  useLayoutEffect(() => {
    if (selectedMainItem) {
      setItemsLoading(true);
      django(`${mainItemsPath}/${selectedMainItem.key}/${listPath}`, { params: { size: PER_PAGE, sort: valueProp } })
        .then(({ data }) => {
          const mapped = data.map(mapItem2);
          setItems(mapped);
        })
        .catch(() => {
          setItemsErrorMessage('Beklenmeyen bir hata oluştu')
        })
        .finally(() => {
          setItemsLoading(false);
        })
    }
  }, [mainItemsPath, listPath, mapItem2, valueProp, selectedMainItem, lastUpdateTime]);

  useLayoutEffect(() => {
    django(`${mainItemsCountPath}`).then(({ data }) => {
      setMainItemsTotalCount(data)
    })
  }, [mainItemsCountPath, lastUpdateTime]);

  useLayoutEffect(() => {
    if (selectedMainItem) {
      django(`${mainItemsPath}/${selectedMainItem.key}/${listCountPath}`).then(({ data }) => {
        setItemsTotalCount(data)
      })
    }
  }, [mainItemsPath, listCountPath, selectedMainItem, lastUpdateTime]);

  const SHOW_LINEAR_PROGRESS_MAIN = useMemo(() => {
    if (mainItemsLoading || moreMainItemsLoading || searchMainItemsLoading) {
      return true;
    }
    return false;
  }, [mainItemsLoading, moreMainItemsLoading, searchMainItemsLoading]);

  const SHOW_LINEAR_PROGRESS = useMemo(() => {
    if (itemsLoading || moreItemsLoading || searchItemsLoading) {
      return true;
    }
    return false;
  }, [itemsLoading, moreItemsLoading, searchItemsLoading]);

  const LOADING_MESSAGE_MAIN = useMemo(() => {
    if (mainItemsLoading) {
      return 'Seçenekler yükleniyor';
    }
    if (searchMainItemsLoading) {
      return 'Arama yapılıyor';
    }
    return null;
  }, [mainItemsLoading, searchMainItemsLoading]);

  const LOADING_MESSAGE = useMemo(() => {
    if (itemsLoading) {
      return 'Seçenekler yükleniyor';
    }
    if (searchItemsLoading) {
      return 'Arama yapılıyor';
    }
    return null;
  }, [itemsLoading, searchItemsLoading]);

  const ERROR_MESSAGE_MAIN = useMemo(() => {
    if (mainItemsErrorMessage) {
      return mainItemsErrorMessage;
    }
    if (searchMainItemsLoadingErrorMessage) {
      return searchMainItemsLoadingErrorMessage;
    }
    return null;
  }, [mainItemsErrorMessage, searchMainItemsLoadingErrorMessage]);

  const ERROR_MESSAGE = useMemo(() => {
    if (itemsErrorMessage) {
      return itemsErrorMessage;
    }
    if (searchItemsLoadingErrorMessage) {
      return searchItemsLoadingErrorMessage;
    }
    return null;
  }, [itemsErrorMessage, searchItemsLoadingErrorMessage]);

  const FILTERED_ITEMS_MAIN = useMemo(() => {
    if (searchQueryMain !== '') {
      return searchMainItems;
    }
    return mainItems;
  }, [mainItems, searchMainItems, searchQueryMain]);

  const FILTERED_ITEMS = useMemo(() => {
    if (searchQuery !== '') {
      return searchItems;
    }
    return items;
  }, [items, searchItems, searchQuery]);

  useEffect(() => {
    const handleGet = ({ url, config }) => {
      setSearchMainItems([]);
      setSearchMainItemsLoading(true);
      setSearchMainItemsLoadingErrorMessage(null);

      django(url, config).then(({ data }) => {
        const mainmapped = data.map(mapItem);
        setSearchMainItems(mainmapped);
      }).catch((error) => {
        if (django?.isCancel(error)) {
        } else {
          setSearchMainItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu')
        }
      }).finally(() => {
        setSearchMainItemsLoading(false);
      });
    };

    if (searchQueryMain === '') {
      setSearchMainItems([]);
      setSearchMainItemsLoading(false);
      setSearchMainItemsLoadingErrorMessage(null);
    }

    if (searchQueryMain !== '') {
      setSearchMainItems([]);
      setSearchMainItemsLoading(true);
      setSearchMainItemsLoadingErrorMessage(null);

      const CancelToken = django.CancelToken;
      const cancelTokenSource = CancelToken.source();

      const config = {
        params: {
          size: PER_PAGE,
          [mainValueProp]: `*${searchQueryMain}*`,
        },
        cancelToken: cancelTokenSource.token,
      };

      const debounce = setTimeout(() => handleGet({ url: mainItemsPath, config }), 300);

      return () => {
        cancelTokenSource.cancel();

        clearTimeout(debounce);

        setSearchMainItemsLoading(false);
      };
    }
  }, [mapItem, searchQueryMain, mainItemsPath, mainValueProp, lastUpdateTime]);

  useEffect(() => {
    if (selectedMainItem) {
      const handleGet = ({ url, config }) => {
        setSearchItems([]);
        setSearchItemsLoading(true);
        setSearchItemsLoadingErrorMessage(null);

        django(url, config).then(({ data }) => {
          const mapped = data.map(mapItem2);
          setSearchItems(mapped);
        }).catch((error) => {
          if (django?.isCancel(error)) {
          } else {
            setSearchItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu')
          }
        }).finally(() => {
          setSearchItemsLoading(false);
        });
      };

      if (searchQuery === '') {
        setSearchMainItems([]);
        setSearchMainItemsLoading(false);
        setSearchItemsLoadingErrorMessage(null);
      }

      if (searchQuery !== '') {
        setSearchMainItems([]);
        setSearchMainItemsLoading(true);
        setSearchItemsLoadingErrorMessage(null);

        const CancelToken = django.CancelToken;
        const cancelTokenSource = CancelToken.source();

        const config = {
          params: {
            size: PER_PAGE,
            [valueProp]: `*${searchQuery}*`,
          },
          cancelToken: cancelTokenSource.token,
        };

        const debounce = setTimeout(() => handleGet({ url: `${mainItemsPath}/${selectedMainItem.key}/${listPath}`, config }), 300);
        return () => {
          cancelTokenSource.cancel();
          clearTimeout(debounce);
          setSearchItemsLoading(false);
        };
      }
    }
  }, [mapItem2, searchQuery, mainItemsPath, listPath, valueProp, selectedMainItem, lastUpdateTime]);

  const handleGoToList = (item) => {
    setSelectedMainItem(item);
    setListOpen(true);

    setSearchQueryMain('');
    setSearchMainItemsLoading(false);
    setSearchMainItemsLoadingErrorMessage(null);
    setSearchQuery('');
    setSearchItemsLoading(false);
    setSearchItemsLoadingErrorMessage(null);
  }

  const handleGoBackToMain = () => {
    setSelectedMainItem(null);
    setListOpen(false);

    setSearchQueryMain('');
    setSearchMainItemsLoading(false);
    setSearchMainItemsLoadingErrorMessage(null);
    setSearchQuery('');
    setSearchItemsLoading(false);
    setSearchItemsLoadingErrorMessage(null);
  }

  const handleCheckItem = (item, checked) => {
    if (listMultiple) {
      let copyMappedDefaultItems = [...MAPPED_DEFAULT_ITEMS];
      if (checked) {
        if (!mainMultiple && copyMappedDefaultItems.length > 0) {
          const indexcontrol = copyMappedDefaultItems.findIndex(x => x.keyUId !== item.keyUId);
          if (indexcontrol > -1) {
            copyMappedDefaultItems = [item];
          } else {
            copyMappedDefaultItems.push(item);
          }
        } else {
          copyMappedDefaultItems.push(item);
        }
      } else {
        const index = copyMappedDefaultItems.findIndex((item2) => item2.key === item.key);
        if (index > -1) {
          copyMappedDefaultItems.splice(index, 1);
        }
      }

      copyMappedDefaultItems = copyMappedDefaultItems.map((item) => ({
        [keyProp]: item.key,
        [valueProp]: item.value,
        [keyUId]: item.keyUId,
        [valueUV]: item.valueUV,
      }));
      copyMappedDefaultItems = copyMappedDefaultItems.sort((a, b) => a[valueProp].localeCompare(b[valueProp]));
      onChangeItems(copyMappedDefaultItems);
    } else {
      let copyMappedDefaultItem = item;
      copyMappedDefaultItem = {
        [keyProp]: item.key,
        [valueProp]: item.value,
        [keyUId]: item.keyUId,
        [valueUV]: item.valueUV,
      };
      onChangeItems(copyMappedDefaultItem);
    }
  }

  const handleRemoveItems = () => {
    if (listMultiple) {
      onChangeItems([]);
    } else {
      onChangeItems(null);
    }
  }

  const handleGetMoreOptionsMain = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;

    setMoreMainItemsLoading(true);
    django(mainItemsPath, { params: { size: PER_PAGE, page, sort: mainValueProp } }).then(({ data }) => {
      const mainmapped = data.map(mapItem);
      setMainItems((prev) => [...prev, ...mainmapped]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setMoreMainItemsLoading(false);
    });
  }

  const handleGetMoreOptions = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;

    setMoreItemsLoading(true);
    django(`${mainItemsPath}/${selectedMainItem.key}/${listPath}`, { params: { size: PER_PAGE, page, sort: valueProp } }).then(({ data }) => {
      const mapped = data.map(mapItem);
      setItems((prev) => [...prev, ...mapped]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setMoreItemsLoading(false);
    });
  }

  const mainRowRenderer = ({ index, style }) => {
    const item = FILTERED_ITEMS_MAIN[index];

    const select = mainMultiple ? MAPPED_DEFAULT_ITEMS_MAIN.findIndex((item2) => item2.key === item.key) > -1 : MAPPED_DEFAULT_ITEMS_MAIN.key === item.key;
    return (
      <div key={item.key} style={style}
        onContextMenu={(e) => {
          e.preventDefault();
          setSelectedItem(item);
          setSelectedId("null")
          setSelectedPosition({ top: e.clientY, left: e.clientX });
        }}
      >
        {index !== 0 && <Divider />}
        <ListItemMain
          item={item}
          select={select}
          searchQuery={searchQueryMain}
          onClick={() => { handleGoToList(item) }}
        />
      </div>
    )
  }

  const rowRenderer = ({ index, style }) => {
    const item = FILTERED_ITEMS[index];
    if (listMultiple) {
      const checked = MAPPED_DEFAULT_ITEMS.findIndex((item2) => item2.key === item.key) > -1;
      return (
        <div key={item.key} style={style}
          onContextMenu={(e) => {
            e.preventDefault();
            setSelectedItem(item);
            setSelectedId(selectedMainItem.key)
            setSelectedPosition({ top: e.clientY, left: e.clientX });
          }}
        >
          {index !== 0 && <Divider />}
          <ListItemCheckbox
            item={item}
            checked={checked}
            searchQuery={searchQuery}
            onClick={(checked) => handleCheckItem(item, checked)}
          />
        </div>
      )
    } else {
      const selected = MAPPED_DEFAULT_ITEMS;
      return (
        <div key={item.key} style={style}
          onContextMenu={(e) => {
            e.preventDefault();
            setSelectedItem(item);
            setSelectedId(selectedMainItem.key)
            setSelectedPosition({ top: e.clientY, left: e.clientX });
          }}
        >
          {index !== 0 && <Divider />}
          <ListItemRadio
            item={item}
            selected={selected}
            searchQuery={searchQuery}
            onClick={(checked) => handleCheckItem(item, checked)}
          />
        </div>
      )
    }

  }

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  return (
    <>
      <div className="w-full h-full flex justify-between">
        <Paper className="w-full h-full flex flex-col overflow-hidden">
          <AppBar
            className="border-b border-palette-action-selected"
            position="sticky"
            color="transparent"
            elevation={0}
          >
            <Toolbar
              className={classes.toolbar}
              variant="dense"
              disableGutters
            >
              <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">{!listOpen ? title : selectedMainItem.value}</h3>
              <div>
                <Tooltip title="Yenile">
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={handleReload}
                  ><IconRefresh /></IconButton>
                </Tooltip>
                <Tooltip title="Kapat">
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={onClose}
                  ><IconClose /></IconButton>
                </Tooltip>
                {!treeListOpen && (
                  <Tooltip title="Detayı Aç">
                    <IconButton
                      edge="end"
                      color="inherit"
                      size="small"
                      onClick={() => onDetayListClose(true)}
                    ><IconListTree /></IconButton>
                  </Tooltip>
                )}
              </div>

            </Toolbar>

            <nav className="w-full flex-1 flex items-center justify-between bg-palette-background-default py-2 px-4 space-x-4">
              <span className="relative w-full h-8 leading-none bg-palette-background-paper apperance-none">
                <input
                  className={clsx([
                    'absolute inset-0 w-full h-full px-10 bg-transparent rounded border border-palette-action-selected',
                    'focus:outline-none focus:border-primary-300 focus:ring-2 focus:ring-primary-200',
                  ])}
                  value={!listOpen ? searchQueryMain ?? '' : searchQuery ?? ''}
                  placeholder={!listOpen ? title + ' içinde ara' : selectedMainItem.value + ' içinde ara'}
                  onChange={(e) => {
                    if (!listOpen) {
                      setSearchQueryMain(e.target.value)
                    } else {
                      setSearchQuery(e.target.value)
                    }
                  }}
                />

                <span className="absolute top-2 left-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                  <IconSearch />
                </span>

                {!listOpen && searchQueryMain.length > 0 &&
                  <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSearchQueryMain('');
                      }}
                    >
                      <IconClose />
                    </IconButton>
                  </span>
                }
                {listOpen && searchQuery.length > 0 &&
                  <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSearchQuery('');
                      }}
                    >
                      <IconClose />
                    </IconButton>
                  </span>
                }
              </span>

              <span>
                <Tooltip title={listMultiple ? "Tüm seçimleri kaldır" : "Seçimi kaldır"}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleRemoveItems();
                    }}
                  >
                    <IconDelete />
                  </IconButton>
                </Tooltip>
              </span>

            </nav>
            {!listOpen && (
              <span className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', { 'hidden': !SHOW_LINEAR_PROGRESS_MAIN })}>
                <LinearProgress style={{ height: 2 }} />
              </span>
            )}
            {listOpen && (
              <span className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', { 'hidden': !SHOW_LINEAR_PROGRESS })}>
                <LinearProgress style={{ height: 2 }} />
              </span>
            )}

          </AppBar>

          <main className="relative flex flex-grow flex-col overflow-hidden" ref={mainContainerRef}>
            {!listOpen && (
              <div className="h-full flex-grow">
                {LOADING_MESSAGE_MAIN && (
                  <LoadingMessage text={LOADING_MESSAGE_MAIN} />
                )}
                {ERROR_MESSAGE_MAIN && (
                  <ErrorMessage text={ERROR_MESSAGE_MAIN} />
                )}
                {(!LOADING_MESSAGE_MAIN && !ERROR_MESSAGE_MAIN && FILTERED_ITEMS_MAIN.length === 0) && (
                  <ErrorMessage text="Seçenek bulunamadı" />
                )}
                {(!LOADING_MESSAGE_MAIN && !ERROR_MESSAGE_MAIN && FILTERED_ITEMS_MAIN.length > 0) && (
                  <InfiniteLoader
                    minimumBatchSize={PER_PAGE}
                    threshold={PER_PAGE}
                    isRowLoaded={({ index }) => !!FILTERED_ITEMS_MAIN[index]}
                    loadMoreRows={({ startIndex, stopIndex }) => {
                      if (mainItemsTotalCount > 0 && MAINPAGE_COUNT > MAINPAGE && !moreMainItemsLoading) {
                        handleGetMoreOptionsMain(MAINPAGE + 1);
                      }
                    }}
                    rowCount={mainItemsTotalCount > 0 ? mainItemsTotalCount : PER_PAGE * 2}
                  >
                    {({ onRowsRendered, registerChild }) => (
                      <AutoSizer>
                        {({ width, height }) => (
                          <List
                            width={width}
                            height={height}
                            rowHeight={ROW_HEIGHT + 1}
                            rowCount={FILTERED_ITEMS_MAIN.length}
                            estimatedRowSize={MAINPAGE_COUNT > 0 ? MAINPAGE_COUNT * (ROW_HEIGHT + 1) : undefined}
                            rowRenderer={mainRowRenderer}

                            onRowsRendered={onRowsRendered}
                            ref={registerChild}
                            onScroll={() => setSelectedItem(null)}
                          />
                        )}
                      </AutoSizer>
                    )}
                  </InfiniteLoader>
                )}
              </div>
            )}
            {listOpen && (
              <>
                {(!LOADING_MESSAGE && !ERROR_MESSAGE && !openSelectedMain) && (
                  <div>
                    <button
                      className="w-full h-10 flex items-center justify-between bg-palette-background-default hover:bg-palette-action-selected cursor-pointer focus:outline-none px-5"
                      onClick={() => handleGoBackToMain()}
                    >
                      <span className="flex items-center justify-start font-medium">Listeye Geri Dön</span>
                      <IconKeyboardArrowLeft />
                    </button>
                  </div>
                )}
                <div className="h-full flex-grow">
                  {LOADING_MESSAGE && (
                    <LoadingMessage text={LOADING_MESSAGE} />
                  )}
                  {ERROR_MESSAGE && (
                    <ErrorMessage text={ERROR_MESSAGE} />
                  )}

                  {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length === 0) && (
                    <ErrorMessage text="Seçenek bulunamadı" />
                  )}
                  {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length > 0) && (
                    <InfiniteLoader
                      minimumBatchSize={PER_PAGE}
                      threshold={PER_PAGE}
                      isRowLoaded={({ index }) => !!FILTERED_ITEMS[index]}
                      loadMoreRows={({ startIndex, stopIndex }) => {
                        if (itemsTotalCount > 0 && PAGE_COUNT > PAGE && !moreItemsLoading) {
                          handleGetMoreOptions(PAGE + 1);
                        }
                      }}
                      rowCount={itemsTotalCount > 0 ? itemsTotalCount : PER_PAGE * 2}
                    >
                      {({ onRowsRendered, registerChild }) => (
                        <AutoSizer>
                          {({ width, height }) => (
                            <List
                              width={width}
                              height={height}
                              rowHeight={ROW_HEIGHT + 1}
                              rowCount={FILTERED_ITEMS.length}
                              estimatedRowSize={PAGE_COUNT > 0 ? PAGE_COUNT * (ROW_HEIGHT + 1) : undefined}
                              rowRenderer={rowRenderer}

                              onRowsRendered={onRowsRendered}
                              ref={registerChild}
                              onScroll={() => setSelectedItem(null)}
                            />
                          )}
                        </AutoSizer>
                      )}
                    </InfiniteLoader>
                  )}
                </div>
              </>
            )}
            {selectedItem && (
              <RightMenu
                url={url}
                itemId={selectedItem?.key}
                ustId={selectedId}
                position={selectedPosition}
              ></RightMenu>
            )}
            {confirmButton && (
              <div className="flex items-center justify-between border-t border-palette-action-selected p-2">
                <span></span>
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disableRipple
                    disabled={!confirmEnable}
                    onClick={onConfirm}
                  >{confirmBtnTitle}</Button>
                </span>
              </div>
            )}
          </main>
        </Paper>

        <Paper className={classes.paper} style={{ display: treeListOpen ? 'block' : 'none' }}>
          <AppBar
            className="border-b border-palette-action-selected"
            position="sticky"
            color="transparent"
            elevation={0}
          >
            <Toolbar
              className={classes.toolbar}
              variant="dense"
              disableGutters
            >
              <Tooltip title="Detayı Kapat">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={() => onDetayListClose(false)}
                ><IconClose /></IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>

          <main className="relative flex flex-grow flex-col overflow-y-auto p-4">
            {TREE_ITEMS?.length > 0 && (
              <>
                {TREE_ITEMS.map((item, index) => (
                  <div key={index} className={classes.list}>
                    <div className={classes.title}>{item.title}</div>
                    <div className={classes.ul}>
                      {item.list.map((v) => (
                        <div key={v.key} className={classes.li}>{v.value}</div>
                      ))}
                    </div>
                  </div>
                ))}
              </>
            )}
          </main>
        </Paper>
      </div>
    </>
  );
};


export default ListSelectorTwoStep;
