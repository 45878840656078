import React, { useMemo, useState } from 'react';
import {
  result as _result
} from 'lodash';

import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  // Typography,
  IconButton
} from '@material-ui/core';

import {
  HelpOutline as IconHelp
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import ListSelector from '../../../components/ListSelector';
import CategorySelector from '../../../components/CategorySelector';
import { Hata } from '../../base/Hata';

import MultipleSelectList from '../../../components/MultipleSelectList';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1, 1, 1, 4),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      position: 'relative',
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemCaption: {
      display: 'flex',
      marginBottom: 5,
      minWidth: 360,
      maxWidth: "100%",
    },
    layoutFormItemCaptionText: {
      fontStyle: 'italic'
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
  };
};

const BilesenDetailTabFiltre = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const [openSelectorKategori, setOpenSelectorKategori] = useState(false);
  const [openSelectorOzellikDeger, setOpenSelectorOzellikDeger] = useState(false);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  const handleRemoveKategoriById = (id) => {
    const copykategoriler = [...props.form.kategoriler];
    const index = copykategoriler.findIndex((c) => c.id === id);
    if (index > -1) {
      copykategoriler.splice(index, 1);
      props.onChangeForm('kategoriler', copykategoriler);
    }
  }

  const handleRemoveOzellikiById = (id) => {
    const copyozellikler = [...props.form.ozellik_basliklar];
    const index = copyozellikler.findIndex((c) => c.id === id);
    if (index > -1) {
      copyozellikler.splice(index, 1);
      props.onChangeForm('ozellik_basliklar', copyozellikler);
    }
  }


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItemBox} id="kategoriler">
                    <MultipleSelectList
                      headername="KATEGORİLER"
                      name="Kategoriler"
                      name2="Kategori"
                      form={props.form}
                      list={props.form?.kategoriler ?? []}
                      listlength={props?.form?.kategoriler?.length ?? 0}
                      opendialog={setOpenSelectorKategori}
                      keyProp="id"
                      valueProp="parents"
                      removefonk={handleRemoveKategoriById}
                      loading={props.loading}
                      error={props.error?.kategoriler ? props.error.kategoriler[0] : null}
                      newPath="tanimlar/kategoridetay"
                      ustId={true}
                    />
                  </li>

                  <li className={classes.layoutFormItemBox} id="ozellik_basliklar">
                    <MultipleSelectList
                      headername="ÖZELLİKLER"
                      name="Özellikler"
                      name2="Özellik"
                      form={props.form}
                      list={props.form?.ozellik_basliklar.map(x => ({ id: x.id, tanim: x.tanim, parent: "null" })) ?? []}
                      listlength={props?.form?.ozellik_basliklar?.length ?? 0}
                      opendialog={setOpenSelectorOzellikDeger}
                      keyProp="id"
                      valueProp="tanim"
                      removefonk={handleRemoveOzellikiById}
                      loading={props.loading}
                      error={props.error?.ozellik_basliklar ? props.error.ozellik_basliklar[0] : null}
                      newPath="tanimlar/ozellikdetay"
                      ustId={true}
                    />
                  </li>

                  <li className={classes.layoutFormItem} id="ozellik_baslik_tum">
                    <label className={classes.layoutFormItemLabel} htmlFor="ozellik_baslik_tum">Tüm Özellikler</label>
                    <Checkbox
                      name="ozellik_baslik_tum"
                      color="primary"
                      checked={_result(props.form, 'ozellik_baslik_tum', false)}
                      onChange={(e, checked) => props.onChangeForm('ozellik_baslik_tum', checked)}
                    ></Checkbox>
                    <IconButton
                      size="small"
                      className={classes.button2}
                      disableRipple
                      onClick={() => {
                        setHelpText("“Sitenizin ürün filtresine, tanımlı olan tüm aktif özelliklerinizin gelmesini istiyorsanız bu alanı işaretleyin.”");
                        setHelpOpen(true)
                      }}
                    ><IconHelp /></IconButton>
                  </li>
                </ul>
              </div>

              {openSelectorKategori &&
                <Dialog
                  open={openSelectorKategori}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorKategori(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <CategorySelector
                      selected={props.form.kategoriler}
                      onChange={(items) => props.onChangeForm('kategoriler', items)}
                      onClose={() => setOpenSelectorKategori(false)}
                      single={false}
                      parentselect={false}
                    />
                  </DialogContent>
                </Dialog>
              }

              {openSelectorOzellikDeger &&
                <Dialog
                  open={openSelectorOzellikDeger}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorOzellikDeger(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Özellikler'
                      listPath='ozellikbaslik?aktif=true'
                      listCountPath='ozellikbaslik/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.ozellik_basliklar}
                      onChangeItems={(items) => props.onChangeForm('ozellik_basliklar', items)}
                      onClose={() => setOpenSelectorOzellikDeger(false)}
                      newPath='tanimlar/ozellikdetay'
                      ustId="null"
                    />
                  </DialogContent>
                </Dialog>
              }
              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default BilesenDetailTabFiltre;
