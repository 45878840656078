import React from 'react';

import {
  IconButton
} from '@material-ui/core';

import {
  Add as IconAdd,
  KeyboardArrowRight as IconArrowRight,
  KeyboardArrowDown as IconArrowDown,
  Delete as IconDelete
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';


const styles = ({ palette, spacing }) => {
  return {
    ul: {
      padding: spacing(1, 2, 1, 2),
      margin: 0
    },
    li: {
      listStyle: 'none',
      cursor: 'pointer'
    },
    color1: {
      color: palette.primary.main,
      fontWeight: 500
    }
  };
};

const DetailVerticalTabsSub = withStyles(styles)(({ classes, ...props }) => {


  const treeSet = (list) => {
    return list.map((item) => {
      if (item.children.length === 0) {
        if (item.form.id) {
          return (
            <li key={item.form.index} className={classes.li} >
              <div onClick={() => props.onChangeTabIndex(item.form.index)}>
                <IconButton
                  size="small"
                  disableRipple
                  onClick={() => props.addNew(item.form.id, item.form.index)}
                >
                  <IconAdd />
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  onClick={() => props.delete(item.form.index)}
                >
                  <IconDelete />
                </IconButton>
                <span className={props.selectedTabIndex === item.form.index ? classes.color1 : ''}>{item.form.tanim}</span>
              </div>
            </li >
          )
        } else {
          return (
            <li key={item.form.index} className={classes.li}>
              <div onClick={() => props.onChangeTabIndex(item.form.index)}>
                <IconButton
                  size="small"
                  disableRipple
                  onClick={() => props.delete(item.form.index)}
                >
                  <IconDelete />
                </IconButton>
                <span className={props.selectedTabIndex === item.form.index ? classes.color1 : ''}>{item.form.tanim}</span>
              </div>
            </li>
          )
        }
      } else {
        return (
          <li key={item.form.index} className={classes.li} >
            <div onClick={() => props.onChangeTabIndex(item.form.index)} className="flex w-full">
              <IconButton
                size="small"
                disableRipple
                onClick={() => props.addNew(item.form.id, item.form.index)}
              >
                <IconAdd />
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                onClick={() => props.delete(item.form.index)}
              >
                <IconDelete />
              </IconButton>
              <div className="flex w-full items-center justify-between">
                <span className={props.selectedTabIndex === item.form.index ? classes.color1 : ''}>{item.form.tanim}</span>
                <IconButton
                  size="small"
                  disableRipple
                  onClick={() => props.toggle(item.form.index)}
                >
                  {props.isActive.indexOf(item.form.index) !== -1 ? <IconArrowDown /> : <IconArrowRight />}
                </IconButton>
              </div>
            </div>
            <ul style={{ display: props.isActive.indexOf(item.form.index) !== -1 ? 'block' : 'none' }} className={classes.ul}>{treeSet(item.children)}</ul>
          </li>
        )
      }
    })
  }

  return (
    <>
      <ul className={classes.ul}>
        {treeSet(props.treeList)}
      </ul>
    </>
  );
});

export default DetailVerticalTabsSub;
