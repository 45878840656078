import React from 'react';
import { useParams } from 'react-router-dom';

import SatisSiparistenFaturaDetail from './SatisSiparistenFaturaDetail';

const PageSatisSiparistenFaturaDetail = () => {
  const params = useParams();

  return (
    <SatisSiparistenFaturaDetail
      id={params?.id ? params.id === "yeni" ? null : params.id : params.id}
      addnew={params?.addnew ? params.addnew === "yeni" ? true : false : false}
      single={true}
    />
  );
};

export default PageSatisSiparistenFaturaDetail;