import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip
} from '@material-ui/core';

import {
  Search as IconSearch
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import ListSelector from '../../../components/ListSelector';
import ListSelectorSingle from '../../../components/ListSelectorSingle';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

import MultipleSelectList from '../../../components/MultipleSelectList';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
  };
};

const EklentiDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const [openSelectorSite, setOpenSelectorSite] = useState(false);
  const [openSelectorMenu, setOpenSelectorMenu] = useState(false);
  const add = useMemo(() => props.add, [props.add]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    if (name === 'menu') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form.menu, `tanim`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form, `${name}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    }
  }, [props.error, props.form]);


  const handleRemoveSiteById = (id) => {
    const copysiteler = [...props.form.siteler];
    const index = copysiteler.findIndex((c) => c.id === id);
    if (index > -1) {
      copysiteler.splice(index, 1);
      props.onChangeForm('siteler', copysiteler);
    }
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="tanim">
                    <label className={classes.layoutFormItemLabel} htmlFor="tanim">Eklenti Tanım *</label>
                    <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                      <TextField
                        {...createTextFieldProps('tanim')}
                        onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                        onBlur={() => handleInputErrors('eklenti', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Eklenti Tanım', 'eklenti')}
                        inputProps={{
                          maxLength: 250,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="temp">
                    <label className={classes.layoutFormItemLabel} htmlFor="temp">Template</label>
                    <Tooltip title={props.error?.temp ? props.error.temp[0] : (props.form?.temp === "-1" || _isNil(props.form?.temp)) ? '' : props.form.temp}>
                      <TextField
                        {...createTextFieldProps('temp')}
                        onChange={(e) => props.onChangeForm('temp', e.target.value)}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option key='-1' value='-1'>Seçilmedi</option>
                        {props.tempList.map((t) => (
                          <option key={t.id} value={t.tanim}>{t.tanim}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="menu">
                    <label className={classes.layoutFormItemLabel} htmlFor="menu">Menü *</label>
                    <Tooltip title={props.error?.menu ? props.error.menu[0] : _isNil(props.form?.menu?.tanim) ? '' : props.form?.menu?.tanim}>
                      <TextField
                        {...createTextFieldProps('menu')}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button}
                              disableRipple
                              onClick={() => setOpenSelectorMenu(true)}
                            ><IconSearch /></IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="siralama">
                    <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                    <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                      <TextField
                        {...createTextFieldProps('siralama')}
                        onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                        type="number"
                        inputProps={{
                          min: -2147483648,
                          max: 2147483647,
                          step: 1,
                          style: { textAlign: 'right' }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="aktif">
                    <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                    <Checkbox
                      name="aktif"
                      color="primary"
                      checked={_result(props.form, 'aktif', false)}
                      onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                    ></Checkbox>
                  </li>
                </ul>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItemBox} id="siteler">
                    <MultipleSelectList
                      headername="SİTELER *"
                      name="Siteler"
                      name2="Site"
                      form={props.form}
                      list={props.form?.siteler ?? []}
                      listlength={props?.form?.siteler?.length ?? 0}
                      opendialog={setOpenSelectorSite}
                      keyProp="id"
                      valueProp="name"
                      removefonk={handleRemoveSiteById}
                      loading={props.loading}
                      error={props.error?.siteler ? props.error.siteler[0] : null}
                      newPath="tanimlar/site"
                      ustId={false}
                    />
                  </li>
                </ul>
              </div>

              {openSelectorSite &&
                <Dialog
                  open={openSelectorSite}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'siteler', true, false, false, '', '', '');
                    setOpenSelectorSite(false);
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Siteler'
                      listPath='site/mini_list?aktif=true'
                      listCountPath='site/count?aktif=true'
                      keyProp='id'
                      valueProp='name'
                      defaultItems={props.form.siteler}
                      onChangeItems={(items) => props.onChangeForm('siteler', items)}
                      onClose={() => {
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'siteler', true, false, false, '', '', '');
                        setOpenSelectorSite(false);
                      }}
                      newPath='tanimlar/site'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorMenu &&
                <Dialog
                  open={openSelectorMenu}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorMenu(false);
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'menu', true, false, false, '', '', '')
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Menuler'
                      listPath='menu/mini_list?aktif=true'
                      listCountPath='menu/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.menu}
                      onChangeItems={(items) => props.onChangeForm('menu', items)}
                      onClose={() => {
                        setOpenSelectorMenu(false);
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'menu', true, false, false, '', '', '')
                      }}
                      newPath='tanimlar/menu'
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default EklentiDetailTabGenel;
