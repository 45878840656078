import React from "react";
import { makeStyles, Collapse, Divider, ListItem, ListSubheader, List } from "@material-ui/core";
import { ArrowDropDown, ArrowRight } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { EmptyIcon, slugify } from "helpers/helpers";

import sideBarItems from "../constants/sidebar-items";

const useStyles = makeStyles(theme => ({
  sidebar: {
    maxHeight: 'calc(100vh - 45px)'
  },
  listItem: {
    width: '100%',
    '& .star-icon': { display: 'none' },
    '&:hover .star-icon': {
      display: 'inline-block'
    }
  },
  activeNavLink: {
    borderRight: "6px solid" + theme.palette.primary.main,
    background: theme.palette.primary.pale,
    display: 'block',
    width: '100%'
  },
  navLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  },
  arrows: { color: theme.palette.primary },
  listWrapper: {
    overflowY: 'auto',
    height: "calc(100vh - 45px)"
  },
  sikkullanilanlar: {
    position: "initial"
  }
}));

function SidebarItem({ depthStep = 10, depth = 0, expanded, item, ...rest }) {
  const [collapsed, setCollapsed] = React.useState(true);
  const { to, label, items } = item;
  const classes = useStyles();

  function toggleCollapse() {
    setCollapsed(prevValue => {
      if (prevValue) {
        // TODO: wire menu status to localStorage
        item.active = true;
      } else {
        delete item.active;
      }
      return !prevValue
    });
  }

  const listItemRef = React.createRef();

  function onClick(e) {
    if (Array.isArray(items)) {
      toggleCollapse();
    }
  }

  const hasSubMenu = Array.isArray(items) && items.length;
  let expandIcon;

  if (hasSubMenu) {
    expandIcon = collapsed ? <ArrowRight className={classes.arrows} /> : <ArrowDropDown className={classes.arrows} />;
  } else {
    expandIcon = <EmptyIcon />;
  }

  return (
    <>
      {!hasSubMenu ? (
        <NavLink
          activeClassName={classes.activeNavLink}
          title={label}
          className={classes.navLink}
          to={to ? to : `/page/${slugify(label)}`}>
          <ListItem
            ref={listItemRef}
            className={classes.listItem}
            onClick={onClick}
            button
            dense
            {...rest}
          >
            {expandIcon}
            <div style={{ paddingLeft: depth * depthStep, width: '100%' }}>
              {label}
            </div>
          </ListItem>
        </NavLink>
      ) : (
        <ListItem
          className={classes.listItem}
          onClick={onClick}
          title={label}
          button
          dense
          {...rest}
        >
          {expandIcon}
          <div style={{ paddingLeft: depth * depthStep, width: '100%' }}>
            {label}
          </div>
        </ListItem>
      )}

      <Collapse in={!collapsed} timeout={300} unmountOnExit>
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem, index) => (
              <React.Fragment key={`${subItem.name}${index}`}>
                {subItem === "divider" ? (
                  <Divider style={{ margin: "6px 0" }} />
                ) : (
                  <SidebarItem
                    depth={depth + 1}
                    depthStep={depthStep}
                    item={subItem}
                  />
                )}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  );
}


function Sidebar(props) {
  const classes = useStyles();
  const { depthStep, depth, expanded } = props;

  const items = sideBarItems;

  return (
    <div className={classes.sidebar}>
      <List className={classes.listWrapper} disablePadding dense>
        <ListSubheader component="div">
          Menü
        </ListSubheader>
        {items.map((sidebarItem, index) => (
          <React.Fragment key={`${sidebarItem.name}${index}`}>
            {sidebarItem === "divider" ? (
              <Divider style={{ margin: "6px 0" }} />
            ) : (
              <SidebarItem
                depthStep={depthStep}
                depth={depth}
                expanded={expanded}
                item={sidebarItem}
              />
            )}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

export default Sidebar;
