import {
  createTheme
} from '@material-ui/core';

import {
  grey,
} from '@material-ui/core/colors';

import {
  merge as _merge,
} from 'lodash';

// Theming Documentation and All options
// https://material-ui.com/customization/theming/#theme-configuration-variables

const commonThemeOptions = {
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '.75rem',
      },
    },
  },
  palette: {
    primary: {
      // main: '#3f51b5' // mavi
      main: '#976797', // lila
      //main: '#b1b4b4'
    },
    secondary: {
      main: '#ec407a',
    },
    backdrop: {
      zIndex: 1200 + 1,
      background: 'rgba(255, 255, 255, 0.3)',
      color: 'rgba(0, 0, 0, 0.3)',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  props: {
    MuiList: {
      dense: true,
    },
  },
};

const Themes = {
  light: createTheme(_merge({
    palette: {
      type: 'light',
      background: {
        paper: 'rgb(255, 255, 255)',
        default: 'rgb(240, 240, 240)',
      },
      toolbar: '#b4b1b1',
    },
  }, commonThemeOptions)),
  dark: createTheme(_merge({
    palette: {
      type: 'dark',
      background: {
        paper: grey['900'],
        default: grey['800'],
      },
      toolbar: grey['900'],
      backdrop: {
        background: 'rgba(0, 0, 0, 0.3)',
        color: 'rgba(255, 255, 255, 0.3)',
      },

    },
  }, commonThemeOptions)),
};

export default Themes;
