import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
} from '@material-ui/core';

import {
  Add as IconAdd,
  Delete as IconDelete,
  Search as IconSearch
} from '@material-ui/icons';


import { withStyles } from '@material-ui/core/styles';
import { Hata } from '../../base/Hata';
import { handleInputErrors, handleInputErrors2 } from '../../base/InputErrors';
import ListSelectorSingle from '../../../components/ListSelectorSingle';

const styles = ({ spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    tablehead: {
      display: 'flex',
      borderBottom: '1px solid #976797',
      marginBottom: spacing(0.5)
    },
    tablebody: {
      display: 'flex',
      flexDirection: 'column',
    },
    tablerow: {
      display: 'flex',
      padding: spacing(0.5, 1, 0.5, 1),
      alignItems: "center"
    },
    tablecell: {
      display: 'flex',
      padding: spacing(0, 0.5, 0, 0.5),
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    }
  };
};

const GrupDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const LOADINGVARYANTOB = useMemo(() => props.loadingOb, [props.loadingOb]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATAVARYANTOB = useMemo(() => props.hataOb, [props.hataOb]);
  const add = useMemo(() => props.add, [props.add]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(null);
  const [openSelectorOzellik, setOpenSelectorOzellik] = useState(false);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const handleChangeForm = (property, value) => {
    props.onChangeFormVaryantOb(selectedTabIndex, property, value);
  };

  const handleSetErrors = (errors) => {
    props.onCheckErrorsVaryantOb(errors, selectedTabIndex);
  }

  const handleDetailDelete = (index) => {
    if (props.varyantOb[index].form.id) {
      props.openDeleteForm(true);
      props.setDeleteId(props.varyantOb[index].form.id);
    } else {
      props.deleteDetailWithoutId(index);
    }
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Varyant Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim')}
                          onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                          onBlur={() => handleInputErrors('varyant-grup', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Varyant Tanım', 'varyant')}
                          inputProps={{
                            maxLength: 250,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="siralama">
                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                      <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                        <TextField
                          {...createTextFieldProps('siralama')}
                          onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                          type="number"
                          inputProps={{
                            min: -2147483648,
                            max: 2147483647,
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                      ></Checkbox>
                    </li>
                  </ul>
                </div>

                <div className={classes.layoutContainer}>
                  {!LOADINGVARYANTOB && (
                    <>
                      {HATAVARYANTOB && (
                        <Hata />
                      )}
                      {!HATAVARYANTOB && (
                        <div className={classes.table}>
                          <div className={classes.tablehead}>
                            <div className={classes.tablerow}>
                              <div className={classes.tablecell}>
                                <Tooltip title="Özellik Başlık Ekle">
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    onClick={props.onAddNewTab}
                                  >
                                    <IconAdd />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div className={classes.tablecell} style={{ minWidth: 240, maxWidth: 240 }}>Özellik Başlık</div>
                              <div className={classes.tablecell} style={{ minWidth: 240, maxWidth: 240, justifyContent: "center" }}>Ürün Olarak Getir</div>
                              <div className={classes.tablecell} style={{ minWidth: 240, maxWidth: 240, justifyContent: "center" }}>Veryant Değeri Ürün Adına Eklensin</div>
                            </div>
                          </div>
                          <div className={classes.tablebody}>
                            {props.varyantOb && props.varyantOb.map((baslik, index) => (
                              <div className={classes.tablerow} key={`secim-${index}`} onClick={() => setSelectedTabIndex(index)}>
                                <div className={classes.tablecell}>
                                  <Tooltip title="Sil">
                                    <IconButton
                                      size="small"
                                      disableRipple
                                      onClick={() => handleDetailDelete(index)}
                                    >
                                      <IconDelete />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <div className={classes.tablecell} style={{ minWidth: 240, maxWidth: 240 }}>
                                  <Tooltip title={baslik.errors?.ozellikbaslik ? baslik.errors.ozellikbaslik[0] : _isNil(baslik.form.ozellikbaslik) ? '' : baslik.form.ozellikbaslik.tanim}>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      fullWidth={true}
                                      value={baslik.form.ozellikbaslik?.tanim || ""}
                                      error={_has(baslik.errors, "ozellikbaslik")}
                                      InputProps={{
                                        endAdornment:
                                          <IconButton
                                            size="small"
                                            className={classes.button}
                                            disableRipple
                                            onClick={() => {
                                              setSelectedTabIndex(index)
                                              setOpenSelectorOzellik(true)
                                            }}
                                          ><IconSearch /></IconButton>
                                      }}
                                    >
                                    </TextField>
                                  </Tooltip>
                                </div>
                                <div className={classes.tablecell} style={{ minWidth: 240, maxWidth: 240, justifyContent: "center" }}>
                                  <Checkbox
                                    name="urun_olarak_getir"
                                    color="primary"
                                    checked={_result(baslik?.form, 'urun_olarak_getir', false)}
                                    onChange={(e, checked) => {
                                      setSelectedTabIndex(index)
                                      props.onChangeFormVaryantOb(index, 'urun_olarak_getir', checked)
                                    }}
                                  ></Checkbox>
                                </div>
                                <div className={classes.tablecell} style={{ minWidth: 240, maxWidth: 240, justifyContent: "center" }}>
                                  <Checkbox
                                    name="deger_urun_adina"
                                    color="primary"
                                    checked={_result(baslik?.form, 'deger_urun_adina', false)}
                                    onChange={(e, checked) => {
                                      setSelectedTabIndex(index)
                                      props.onChangeFormVaryantOb(index, 'deger_urun_adina', checked)
                                    }}
                                  ></Checkbox>
                                </div>
                              </div>
                            ))}
                          </div>

                        </div>

                      )}
                      {openSelectorOzellik &&
                        <Dialog
                          open={openSelectorOzellik}
                          PaperProps={{
                            style: {
                              width: '480px',
                              height: '600px',
                            },
                          }}
                          onClose={() => {
                            handleInputErrors2(props.varyantOb[selectedTabIndex]?.form, 'ozellikbaslik', handleSetErrors);
                            setOpenSelectorOzellik(false);
                          }}
                        >
                          <DialogContent style={{ padding: 0 }}>
                            <ListSelectorSingle
                              title='Özellikler'
                              listPath='ozellikbaslik/mini_list?aktif=true'
                              listCountPath='ozellikbaslik/count?aktif=true'
                              keyProp='id'
                              valueProp='tanim'
                              defaultItem={props.varyantOb?.[selectedTabIndex]?.form?.ozellikbaslik}
                              onChangeItems={(items) => handleChangeForm('ozellikbaslik', items)}
                              onClose={() => {
                                handleInputErrors2(props.varyantOb[selectedTabIndex]?.form, 'ozellikbaslik', handleSetErrors);
                                setOpenSelectorOzellik(false);
                              }}
                              newPath='tanimlar/ozellikbaslik'
                              notEmpty={true}
                            />
                          </DialogContent>
                        </Dialog>
                      }
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      }
    </>
  );

});

export default GrupDetailTabGenel;
