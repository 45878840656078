import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  isEmpty as _isEmpty
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';

import VerticalListXDetail from '../../../components/VerticalListX_Detail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList } from '../../base/InputErrors';
import { asyncForEach } from '../../../helpers/helpers';

import TabGenel from './UlkeSehirIlceDetailTab_Genel';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
];

const FORM_DEFAULT_ULKE = {
  tanim: '',
  kodu: '',
  telefon_kodu: '',
  siralama: '0',
  aktif: true,
  alternatif_kelimeler: []
};

const FORM_DEFAULT_SEHIR = {
  tanim: '',
  plaka: '',
  siralama: '0',
  aktif: true,
  alternatif_kelimeler: []
};

const FORM_DEFAULT_ILCE = {
  tanim: '',
  siralama: '0',
  aktif: true,
  alternatif_kelimeler: []
};


const UlkeSehirIlceDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [hataGenel, setHataGenel] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [extraTanim, setExtraTanim] = useState([]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const PATH = useMemo(() => props.ustId === "null" ? 'ulke' : props.ustId.split("-").length === 1 ? `ulke/${props.ustId.split("-")[0]}/sehir` : `ulke/${props.ustId.split("-")[0]}/sehir/${props.ustId.split("-")[1]}/ilce`, [props]);
  const IS_ADD = useMemo(() => props.isAdd, [props]);
  const STATE = useMemo(() => props.state, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const LOCATION = useMemo(() => {
    if (PATH.includes('ilce')) {
      return "ilce"
    } else if (PATH.includes('sehir') && !PATH.includes('ilce')) {
      return "sehir"
    } else {
      return "ulke"
    }
  }, [PATH]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      switch (LOCATION) {
        case "ulke":
          return _isEqual(genelForm, FORM_DEFAULT_ULKE);
        case "sehir":
          return _isEqual(genelForm, FORM_DEFAULT_SEHIR);

        case "ilce":
          return _isEqual(genelForm, FORM_DEFAULT_ILCE);
        default:
          break;
      }
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    return isEqualGenel;
  }, [add, genelForm, genelFormDefault, LOCATION]);

  useLayoutEffect(() => {
    setLoading(true);
    setTabs(TABS_DEFAULT);
    if (!IS_ADD) {
      const debounce = setTimeout(() => {
        django(`${PATH}/${ID}`).then(({ data }) => {
          let form = {};
          switch (LOCATION) {
            case "ulke":
              form = {
                tanim: data.tanim,
                kodu: data.kodu,
                telefon_kodu: data.telefon_kodu,
                alternatif_kelimeler: data.alternatif_kelimeler ?? [],
                siralama: String(data.siralama),
                aktif: data.aktif,
              };
              break;
            case "sehir":
              form = {
                tanim: data.tanim,
                plaka: data.plaka,
                alternatif_kelimeler: data.alternatif_kelimeler ?? [],
                siralama: String(data.siralama),
                aktif: data.aktif,
              };
              break;
            case "ilce":
              form = {
                tanim: data.tanim,
                alternatif_kelimeler: data.alternatif_kelimeler ?? [],
                siralama: String(data.siralama),
                aktif: data.aktif,
              };
              break;
            default:
              break;
          }
          setGenelFormDefault(form);
          setGenelForm(form);
          setHataGenel(null);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataGenel(true);
            }
          }
        }).finally(() => {
          setLoading(false);
          setGenelError(null);
          setAdd(false);
        });
      }, 300);
      return () => {
        clearTimeout(debounce);
        setLoading(false);
      };
    } else {
      switch (LOCATION) {
        case "ulke":
          setGenelForm(FORM_DEFAULT_ULKE);
          setGenelFormDefault(FORM_DEFAULT_ULKE);
          break;
        case "sehir":
          setGenelForm(FORM_DEFAULT_SEHIR);
          setGenelFormDefault(FORM_DEFAULT_SEHIR);
          break;
        case "ilce":
          setGenelForm(FORM_DEFAULT_ILCE);
          setGenelFormDefault(FORM_DEFAULT_ILCE);
          break;
        default:
          break;
      }
      setGenelError(null);
      setAdd(true);
      setSelectedTabIndex(0);
      setLoading(false);
    }
  }, [ID, lastUpdateTime, IS_ADD, PATH, LOCATION]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  useLayoutEffect(() => {
    if (STATE !== undefined) {
      let path = 'iliskilitanim?tamamlandi=false';
      const ust_idler = STATE.ust_id.length > 0 ? STATE.ust_id.join(',') : null;
      const kullanim_alani_tip = STATE.tip;
      path = path + '&kullanim_alani_tip=' + kullanim_alani_tip;
      path = ust_idler ? path + '&ust_idler' + ust_idler : path;
      django(path).then(({ data }) => {
        const form = data.map((item) => ({
          id: item.id,
          tanim: item.tanim,
          tamamlandi: item.tamamlandi,
          kullanim_alani_tip: item.kullanim_alani_tip
        }));
        setExtraTanim(form);
      })
    }
  }, [STATE, lastUpdateTime])

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    switch (LOCATION) {
      case "ulke":
        setGenelForm(FORM_DEFAULT_ULKE);
        break;
      case "sehir":
        setGenelForm(FORM_DEFAULT_SEHIR);
        break;
      case "ilce":
        setGenelForm(FORM_DEFAULT_ILCE);
        break;
      default:
        break;
    }
    setGenelError(null);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const checkAll = async (list) => {
    let errors = [];
    await checkAllList(PATH, genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const handleSaveNotifications = async () => {
    const createRequset = () => {
      const req = [];
      if (!_isEmpty(extraTanim)) {
        const a_tanimlar = [...extraTanim].filter((item) => genelForm.alternatif_kelimeler.includes(item.tanim) || genelForm.tanim === item.tanim);
        if (!_isEmpty(a_tanimlar)) {
          a_tanimlar.forEach((item) => {
            const reqopt = {
              config: {
                method: 'PUT',
                url: `iliskilitanim/${item.id}`,
                data: { ...item, tamamlandi: true },
              },
            }
            req.push(reqopt);
          })
        }
      }
      return req;
    }

    const start = async () => {
      const req = createRequset();
      await asyncForEach(req, async (request) => {
        await django(request.config)
      })
    }
    start();
  }

  const handleSave = () => {
    let list = [];
    if (LOCATION === "ulke") {
      list = [
        { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Ülke Tanım', pagename: 'ülke', prop2: '' },
        { prop: 'kodu', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
        { prop: 'telefon_kodu', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      ]
    } else if (LOCATION === "sehir") {
      list = [
        { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Şehir Tanım', pagename: 'şehir', prop2: '' },
        { prop: 'plaka', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' }
      ]
    } else {
      list = [
        { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'İlçe Tanım', pagename: 'ilçe', prop2: '' },
      ]
    }
    checkAll(list).then((res) => {
      if (_isEmpty(res)) {
        let requestoptions = {};
        const data = { ...genelForm, siralama: genelForm.siralama ? Number(genelForm.siralama) : 0 }
        if (IS_EQUAL) {
          requestoptions = null;
        } else {
          requestoptions = {
            config: {
              method: add ? 'POST' : 'PUT',
              url: add ? `${PATH}` : `${PATH}/${ID}`,
              data: data
            },
            successMessage: `${data.tanim} ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
            errorMessageUnexpected: `${data.tanim} ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
          }
        }
        const start = async () => {
          let errorCounts = 0;
          if (requestoptions !== null) {
            setGenelError(null);
            await django(requestoptions.config).then(({ data }) => {
              enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
              if (!props.single && !add) {
                props.onUpdated();
              }
              if (props.single && add) {
                history.push(URLS.tanimlar.ulkesehirilcedetay.detail(data.id, props.ustId,"detay"));
              }
              if (!props.single && add) {
                props.onAdded(data);
              }
            }).catch((error) => {
              setSelectedTabIndex(selectedTabIndex);
              if (error.response) {
                if (error.response.status === 500) {
                  enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
                } else {
                  setGenelError(error.response.data);
                }
              } else {
                enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
              }
              ++errorCounts;
            });
            if (errorCounts === 0) {
              handleSaveNotifications().then(() => {
                setLastUpdateTime(Date.now());
              })
            } else {
              setTabs((prev) => {
                return immer(prev, (next) => {
                  next[0].hasError = true;
                });
              });
            }
          }
        }
        start();
      } else {
        setTabs((prev) => {
          return immer(prev, (next) => {
            next[0].hasError = true;
          });
        });
      }
    })
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.ulkesehirilce.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  return (
    <>
      <VerticalListXDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${window.location.origin}/tanimlar/ulkesehirilcedetay/${props.ustId}/yeni/yeni`, '_blank')
          } else {
            window.open(`${window.location.origin}/tanimlar/ulkesehirilcedetay/${props.ustId}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}
            location={LOCATION}
            path={PATH}
            extraTanim={extraTanim}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}
      </VerticalListXDetail>
      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Vergi siliniyor lütfen bekleyin'
                : 'Bu vergiyi gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
    </>
  )
}

export default UlkeSehirIlceDetail;