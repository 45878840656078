import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';


import {
  has as _has,
  result as _result,
  isEmpty as _isEmpty,
  isNil as _isNil
} from 'lodash';

import immer from 'immer';
import moment from 'moment';
import {
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  Tooltip,
  Checkbox,
  Switch,
  Typography
} from '@material-ui/core';

import {
  Folder as IconFolder,
  Description as IconFile,
  Add as IconOpen,
  Remove as IconClose,
  PostAdd as IconPostAdd,
  CheckCircle as IconCheckCircle,
  Delete as IconDelete
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import { Hata } from '../../base/Hata';


const styles = ({ palette, spacing }) => {
  return {
    containerout: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflow: 'hidden',
    },
    side: {
      height: '100%',
      maxWidth: '100%',
      //minWidth: 200,
      padding: spacing(2, 0, 2, 0),
      overflowY: 'auto'
    },
    main: {
      width: '100%',
      height: '100%',
      flex: 1,
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'flex-start',
      padding: spacing(1),
      borderLeft: `1px solid ${palette.action.selected}`,
      overflowY: 'auto',
      position: 'relative',
      flexDirection: 'column',
    },
    ul: {
      padding: spacing(1, 2, 1, 2),
    },
    ul2: {
      padding: spacing(1, 2, 1, 4),
    },
    li: {
      listStyle: 'none',
      cursor: 'pointer'
    },
    column: {
      display: 'flex',
      '& + &': {
        margin: spacing(4, 0, 0, 0),
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    column2: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 360,
      '& + &': {
        margin: 5,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: spacing(0, 0, 4, 0),
      padding: 0,
    },
    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    editor: {
      display: 'flex',
      width: '100%',
      height: '100%',
      '& .CodeMirror': {
        height: '100%',
      }
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: spacing(2, 4, 2, 4),
      padding: 0,
    },
    dialogbutton: {
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& + &': {
        marginLeft: 10,
      },
    },
  };
};

const SablonDetailTabAraYuz = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(null);

  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);
  const TREE = useMemo(() => props.fileTree ?? [], [props.fileTree]);

  const [addItemPath, setAddItemPath] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [addItem, setAddItem] = useState({ tanim: '', alternatif_tanim: '' });
  const [isActive, setActive] = useState([]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const handleChangeForm = (property, value, geticerik = false) => {
    props.onChangeForm(selectedTabIndex, property, value, geticerik);
  };

  useLayoutEffect(() => {
    setActive([]);
  }, [LOADING]);

  const handleAddInputChange = (property, value) => {
    setAddItem((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  }
  const toggle = (i) => {
    if (isActive.indexOf(i) === -1) {
      setActive([...isActive, i]);
    } else {
      const index = isActive.indexOf(i);
      setActive((prev) => {
        return immer(prev, (next) => {
          next.splice(index, 1)
        });
      });
    }
  };

  const handleAdd = () => {
    // const tanim = addItemPath + '/' + addItem.tanim.split('.')[0] + '.html'
    // const alternatif_tanim = !_isEmpty(addItem.alternatif_tanim) ? addItem.alternatif_tanim.split('.')[0] + '.html' : '';
    const tanim = addItemPath + '/' + (addItem.tanim.includes('.') ? addItem.tanim : addItem.tanim) + '.html'
    const alternatif_tanim = !_isEmpty(addItem.alternatif_tanim) ? (addItem.alternatif_tanim.includes('.') ? addItem.alternatif_tanim : addItem.alternatif_tanim )+ '.html' : '';
    props.onAddNewTab(tanim, alternatif_tanim);
    setAddItem({ tanim: '', alternatif_tanim: '' });
    setOpenAddDialog(false);
    setSelectedTabIndex(null);
  }

  const handleDelete = (obj) => {
    setSelectedTabIndex(null);
    if (obj.id) {
      props.openDeleteForm(true);
      props.setDeleteId([obj.id, obj.tanim]);
    } else {
      props.setDeleteNewAdd(obj.index);
    }
  }

  const handleGetIcerik = (index) => {
    props.setCek(true);
    props.onChangeForm(index, 'icerik', null, true);
  }

  const TreeSet = (list) => {
    return list.map((item) => {
      if (item.children.length === 0) {
        return (
          <li key={`${item.key}`} className={classes.li}>
            <div>
              <Tooltip title="Sil">
                <IconButton
                  size="small"
                  disableRipple
                  onClick={() => handleDelete(item.object)}
                >
                  <IconDelete />
                </IconButton>
              </Tooltip>
              <IconButton
                size="small"
                disableRipple
              >
                <IconFile />
              </IconButton>
              <span
                onClick={() => {
                  if (_has(item, 'object')) {
                    setSelectedTabIndex(item.object.index);
                    handleGetIcerik(item.object.index);
                  }
                }}
              >{item.title}</span>
              {item.multi && (
                <IconButton
                  size="small"
                  disableRipple
                  onClick={() => props.handleGetActive(item.object)}
                >
                  <IconCheckCircle />
                </IconButton>
              )}
            </div>
          </li>
        )
      } else {
        if (item.path.indexOf('.html') < 0) {
          return (
            <li key={`${item.key}`} className={classes.li}>
              <div>
                <IconButton
                  size="small"
                  disableRipple
                  onClick={() => toggle(item.key)}
                >
                  {isActive.indexOf(item.key) !== -1 ? <IconClose /> : <IconOpen />}
                </IconButton>
                <Tooltip title="Yeni Ekle">
                  <IconButton
                    size="small"
                    disableRipple
                    onClick={() => {
                      setAddItemPath(item.path);
                      setOpenAddDialog(true);
                    }}
                  >
                    <IconPostAdd />
                  </IconButton>
                </Tooltip>
                <IconButton
                  size="small"
                  disableRipple
                >
                  <IconFolder />
                </IconButton>
                <span>{item.title}</span>
              </div>
              <ul className={classes.ul} style={{ display: isActive.indexOf(item.key) !== -1 ? 'block' : 'none' }} >{TreeSet(item.children)}</ul>
            </li>
          )
        } else {
          return (
            <li key={`${item.key}`} className={classes.li}>
              <div>
                <IconButton
                  size="small"
                  disableRipple
                  onClick={() => toggle(item.key)}
                >
                  {isActive.indexOf(item.key) !== -1 ? <IconClose /> : <IconOpen />}
                </IconButton>
                <Tooltip title="Sil">
                  <IconButton
                    size="small"
                    disableRipple
                    onClick={() => handleDelete(item.object)}
                  >
                    <IconDelete />
                  </IconButton>
                </Tooltip>
                <IconButton
                  size="small"
                  disableRipple
                >
                  <IconFile />
                </IconButton>
                <span
                  onClick={() => {
                    if (_has(item, 'object')) {
                      setSelectedTabIndex(item.object.index);
                      handleGetIcerik(item.object.index);
                    }
                  }}
                >{item.title}</span>
                <IconButton
                  size="small"
                  disableRipple
                  onClick={() => props.handleGetActive(item.object)}
                >
                  <IconCheckCircle />
                </IconButton>
              </div>
              <ul className={classes.ul2} style={{ display: isActive.indexOf(item.key) !== -1 ? 'block' : 'none' }} >{TreeSet(item.children)}</ul>
            </li>
          )
        }
      }
    })
  }


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.containerout}>
                <div className={classes.container}>
                  <aside className={classes.side}>
                    {!props.add && props.ID &&
                      <div className="flex items-center justify-center px-4">
                        <Tooltip title="Bu mod açıkken yaptığınız değişiklikler yeni versiyon olarak kaydedilmez">
                          <span>Geliştirici Mod</span>
                        </Tooltip>
                        <Switch
                          checked={props.devMode}
                          onChange={(e, checked) => props.setDevMode(checked)}
                          name="devmode"
                          color="primary"
                        ></Switch>
                      </div>
                    }
                    {TREE.length === 0 && !props.add && props.ID && (
                      <div className="flex items-center justify-center px-4 py-2">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          disableElevation
                          onClick={props.addNewSablon}
                        >YENİ ŞABLON EKLE</Button>
                      </div>
                    )}

                    <ul className={classes.ul}>
                      {TREE.length > 0 && (
                        TreeSet(TREE)
                      )}
                    </ul>
                  </aside>
                  <main className={classes.main}>
                    {FORM && (
                      <>
                        <div className={classes.column}>
                          <ul className={classes.formList}>

                            <li className={classes.formItem} id="tanim">
                              <label className={classes.formItemLabel} htmlFor="tanim">İsim *</label>
                              <Tooltip title={ERROR?.tanim ? ERROR.tanim[0] : _isNil(FORM?.tanim) ? '' : FORM.tanim}>
                                <TextField
                                  {...createTextFieldProps('tanim')}
                                ></TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="alternatif_tanim">
                              <label className={classes.formItemLabel} htmlFor="alternatif_tanim">Liste Harici Yeni İsim</label>
                              <Tooltip title={ERROR?.alternatif_tanim ? ERROR.alternatif_tanim[0] : _isNil(FORM?.alternatif_tanim) ? '' : FORM.alternatif_tanim}>
                                <TextField
                                  {...createTextFieldProps('alternatif_tanim')}
                                  onChange={(e) => handleChangeForm('alternatif_tanim', e.target.value)}
                                ></TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="yayina_baslama_zamani">
                              <label className={classes.formItemLabel} htmlFor="yayina_baslama_zamani">Yayına Başlama Zamanı</label>
                              <Tooltip title={ERROR?.yayina_baslama_zamani ? ERROR.yayina_baslama_zamani[0] : _isNil(FORM?.yayina_baslama_zamani) ? '' : moment(FORM.yayina_baslama_zamani).format('DD/MM/YYYY, HH.mm')}>
                                <TextField
                                  {...createTextFieldProps('yayina_baslama_zamani')}
                                  onChange={(e) => handleChangeForm('yayina_baslama_zamani', e.target.value)}
                                  type="datetime-local"
                                ></TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="yayini_bitirme_zamani">
                              <label className={classes.formItemLabel} htmlFor="yayini_bitirme_zamani">Yayını Bitirme Zamanı</label>
                              <Tooltip title={ERROR?.yayini_bitirme_zamani ? ERROR.yayini_bitirme_zamani[0] : _isNil(FORM?.yayini_bitirme_zamani) ? '' : moment(FORM.yayini_bitirme_zamani).format('DD/MM/YYYY, HH.mm')}>
                                <TextField
                                  {...createTextFieldProps('yayini_bitirme_zamani')}
                                  onChange={(e) => handleChangeForm('yayini_bitirme_zamani', e.target.value)}
                                  type="datetime-local"
                                ></TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="versiyon">
                              <label className={classes.formItemLabel} htmlFor="versiyon">Versiyon</label>
                              <Tooltip title={ERROR?.versiyon ? ERROR.versiyon[0] : _isNil(FORM?.versiyon) ? '' : FORM.versiyon}>
                                <TextField
                                  {...createTextFieldProps('versiyon')}
                                  //onChange={(e) => handleChangeForm('versiyon', e.target.value)}
                                  inputProps={{
                                    style: { textAlign: "right" }
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>
                            <li className={classes.formItem} id="aktif">
                              <label className={classes.formItemLabel} htmlFor="aktif">Aktif</label>
                              <Checkbox
                                name="aktif"
                                color="primary"
                                checked={_result(FORM, 'aktif', false)}
                              ></Checkbox>
                            </li>
                          </ul>
                        </div>
                        <div className={classes.column2} style={{ borderWidth: 1, borderStyle: 'solid', borderColor: _has(ERROR, `icerik`) ? 'red' : 'transparent' }}>
                          {_has(ERROR, `icerik`) && (
                            <div className={classes.editor}>
                              <Typography variant="caption" color="error">{_result(ERROR, `icerik[0]`)}</Typography>
                            </div>
                          )}
                          <div className={classes.editor}>
                            <CodeMirror
                              value={_result(FORM, 'icerik')}
                              options={{
                                mode: 'html',
                                theme: 'juejin',
                                lineNumbers: true,
                              }}
                              className="h-full"
                              onBeforeChange={(editor, data, value) => {
                                handleChangeForm('icerik', value)
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </main>
                </div>
              </div>
              {openAddDialog &&
                <Dialog
                  open={openAddDialog}
                  PaperProps={{
                    style: {
                      height: '200px',
                    },
                  }}
                  fullWidth
                  onClose={() => {
                    setOpenAddDialog(false);
                    setAddItem({ tanim: '', alternatif_tanim: '' });
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <div className={classes.dialogContent} >
                      <div>
                        <ul className={classes.formList}>
                          <li className={classes.formItem}>
                            <label className={classes.formItemLabel}>İsim *</label>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth={true}
                              value={_result(addItem, 'tanim') || ''}
                              onChange={(e) => handleAddInputChange('tanim', e.target.value)}
                            ></TextField>
                          </li>
                          <li className={classes.formItem}>
                            <label className={classes.formItemLabel}>Liste Harici Yeni İsim</label>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth={true}
                              value={_result(addItem, 'alternatif_tanim') || ''}
                              onChange={(e) => handleAddInputChange('alternatif_tanim', e.target.value)}
                            ></TextField>
                          </li>
                        </ul>
                        <div className={classes.dialogbutton}>
                          <Button disabled={_isEmpty(addItem.tanim)} className={classes.button} onClick={() => handleAdd()}>EKLE</Button>
                          <Button className={classes.button} onClick={() => {
                            setOpenAddDialog(false);
                            setAddItem({ tanim: '', alternatif_tanim: '' });
                          }}>İPTAL</Button>
                        </div>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              }

            </>
          )}
        </>
      }

    </>
  );
});

export default SablonDetailTabAraYuz;
