import React, { useState, useEffect, useLayoutEffect, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';

import {
  Button,
  Checkbox,
  Radio,
  Paper,
  CircularProgress,
  LinearProgress,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Tooltip,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
  Search as IconSearch,
  Delete as IconDelete,
  Refresh as IconRefresh,
} from '@material-ui/icons';

import {
  isEmpty as _isEmpty
} from 'lodash'

import django from '../api/django';
import { makeStyles } from '@material-ui/styles';
import RightMenu from '../helpers/rightMenu';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    }
  };
});

const PER_PAGE = 100;
const ROW_HEIGHT = 34;

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text, submitText, onSubmit }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>

      <p className="font-medium mb-2">{text}</p>

      {onSubmit &&
        <Button
          color="primary"
          onClick={onSubmit}
        >{submitText ?? 'TEKRAR DENE'}</Button>
      }
    </div>
  );
};

const ListItem = ({ item, selectVal, disabled, searchQuery, onClick, url, ustId }) => {

  let text = '';
  const val2 = typeof (item.value2) === "object" ? item.value2?.tanim : item.value2;
  const val3 = typeof (item.value3) === "object" ? item.value3?.tanim : item.value3;

  if (item.value2 && item.value3) {
    text = item.value + " - " + val2 + " - " + val3
  } else if (item.value2 && !item.value3) {
    text = item.value + " - " + val2
  } else {
    text = item.value
  }

  return (
    <Tooltip title={text}>
      <div
        key={`${item.key}${item.value}`}
        className={clsx('w-full flex items-center justify-start hover:text-primary px-4 space-x-3 select-none')}
        style={{ height: ROW_HEIGHT }}
      >
        <Radio
          color="primary"
          disabled={disabled}
          checked={selectVal === item.key}
          value={item.key}
          onChange={() => onClick(item)}
          style={{ padding: 0, cursor: "pointer" }}
        ></Radio>
        <Highlighter
          textToHighlight={`${text}`}
          searchWords={[searchQuery ?? '']}
          className="whitespace-nowrap overflow-ellipsis overflow-hidden w-11/12"
          activeClassName=""
          highlightClassName="leading-none"
          unhighlightClassName="leading-none"
        />
      </div>
    </Tooltip>
  );
};



const ListSelectorSingle = ({ title, listPath, listCountPath, keyProp, valueProp, valueProp2, valueProp3, disabledProp, extraVal, extraVal2, defaultItem, onChangeItems, onClose, notEmpty, newPath, ustId, confirmButton, confirmEnable, onConfirm, confirmBtnTitle }) => {
  const [items, setItems] = useState([]);
  const [itemsLoadingCount, setItemsLoadingCount] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsLoadingErrorMessage, setItemsLoadingErrorMessage] = useState(null);

  const [itemsTotalCount, setItemsTotalCount] = useState(0);

  const [moreItemsLoading, setMoreItemsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchItems, setSearchItems] = useState([]);
  const [searchItemsLoading, setSearchItemsLoading] = useState(false);
  const [searchItemsLoadingErrorMessage, setSearchItemsLoadingErrorMessage] = useState(null);

  const [selectVal, setSelectVal] = useState(null);

  const [showSelectedItems, setShowSelectedItems] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const classes = useStyles();

  const mainContainerRef = useRef();

  const MAPPED_DEFAULT_ITEM = useMemo(() => {
    if (defaultItem) {
      if (valueProp2 && !valueProp3) {
        if (disabledProp) {
          return {
            key: defaultItem[keyProp],
            value: defaultItem[valueProp],
            value2: defaultItem[valueProp2],
            disabledvalue: defaultItem[disabledProp]
          };
        } else {
          return {
            key: defaultItem[keyProp],
            value: defaultItem[valueProp],
            value2: defaultItem[valueProp2],
          };
        }
      } else if (valueProp2 && valueProp3) {
        if (disabledProp) {
          return {
            key: defaultItem[keyProp],
            value: defaultItem[valueProp],
            value2: defaultItem[valueProp2],
            value3: defaultItem[valueProp3],
            disabledvalue: defaultItem[disabledProp]
          };
        } else {
          return {
            key: defaultItem[keyProp],
            value: defaultItem[valueProp],
            value2: defaultItem[valueProp2],
            value3: defaultItem[valueProp3],
          };
        }
      } else {
        if (disabledProp) {
          return {
            key: defaultItem[keyProp],
            value: defaultItem[valueProp],
            disabledvalue: defaultItem[disabledProp]
          };
        } else {
          return {
            key: defaultItem[keyProp],
            value: defaultItem[valueProp],
          };
        }
      }
    } else {
      return {};
    }
  }, [defaultItem, keyProp, valueProp, valueProp2, valueProp3, disabledProp]);

  const PAGE = useMemo(() => items.length > 0 ? Math.ceil(items.length / PER_PAGE) : 1, [items.length]);
  const PAGES_COUNT = useMemo(() => Math.ceil(itemsTotalCount / PER_PAGE), [itemsTotalCount]);

  const SHOW_LINEAR_PROGRESS = useMemo(() => {
    if (itemsLoading || moreItemsLoading || searchItemsLoading || itemsLoadingCount) {
      return true;
    }
    return false;
  }, [itemsLoading, moreItemsLoading, searchItemsLoading, itemsLoadingCount]);

  const LOADING_MESSAGE = useMemo(() => {
    if (itemsLoading || itemsLoadingCount) {
      return 'Seçenekler yükleniyor';
    }
    if (searchItemsLoading || itemsLoadingCount) {
      return 'Arama yapılıyor';
    }
    return null;
  }, [itemsLoading, searchItemsLoading, itemsLoadingCount]);

  const ERROR_MESSAGE = useMemo(() => {
    if (itemsLoadingErrorMessage) {
      return itemsLoadingErrorMessage;
    }
    if (searchItemsLoadingErrorMessage) {
      return searchItemsLoadingErrorMessage;
    }
    return null;
  }, [itemsLoadingErrorMessage, searchItemsLoadingErrorMessage]);

  const FILTERED_ITEMS = useMemo(() => {
    if (showSelectedItems) {
      if (!_isEmpty(MAPPED_DEFAULT_ITEM)) {
        return [MAPPED_DEFAULT_ITEM];
      } else {
        return [];
      }
    }
    if (searchQuery !== '') {
      return searchItems;
    }
    return items;
  }, [MAPPED_DEFAULT_ITEM, showSelectedItems, items, searchItems, searchQuery]);

  useLayoutEffect(() => {
    setSelectVal(MAPPED_DEFAULT_ITEM.key);
  }, [MAPPED_DEFAULT_ITEM]);

  const mapItem = useCallback((item) => {
    if (valueProp2 && !valueProp3) {
      if (disabledProp) {
        return {
          key: item[keyProp],
          value: item[valueProp],
          value2: item[valueProp2],
          disabledvalue: item[disabledProp],
          extraVal: item[extraVal] ? item[extraVal] : null,
          extraVal2: item[extraVal2] ? item[extraVal2] : null,
        };
      } else {
        return {
          key: item[keyProp],
          value: item[valueProp],
          value2: item[valueProp2],
          extraVal: item[extraVal] ? item[extraVal] : null,
          extraVal2: item[extraVal2] ? item[extraVal2] : null,
        };
      }
    } else if (valueProp2 && valueProp3) {
      if (disabledProp) {
        return {
          key: item[keyProp],
          value: item[valueProp],
          value2: item[valueProp2],
          value3: item[valueProp3],
          disabledvalue: item[disabledProp],
          extraVal: item[extraVal] ? item[extraVal] : null,
          extraVal2: item[extraVal2] ? item[extraVal2] : null,
        };
      } else {
        return {
          key: item[keyProp],
          value: item[valueProp],
          value2: item[valueProp2],
          value3: item[valueProp3],
          extraVal: item[extraVal] ? item[extraVal] : null,
          extraVal2: item[extraVal2] ? item[extraVal2] : null,
        };
      }
    } else {
      if (disabledProp) {
        return {
          key: item[keyProp],
          value: item[valueProp],
          disabledvalue: item[disabledProp],
          extraVal: item[extraVal] ? item[extraVal] : null,
          extraVal2: item[extraVal2] ? item[extraVal2] : null,
        };
      } else {
        return {
          key: item[keyProp],
          value: item[valueProp],
          extraVal: item[extraVal] ? item[extraVal] : null,
          extraVal2: item[extraVal2] ? item[extraVal2] : null,
        };
      }
    }
  }, [keyProp, valueProp, valueProp2, valueProp3, disabledProp, extraVal, extraVal2]);

  // set items
  useLayoutEffect(() => {
    setItemsLoading(true);
    django(listPath, { params: { size: PER_PAGE, sort: valueProp } }).then(({ data }) => {
      const mapped = data.map(mapItem);

      setItems(mapped);
    }).catch(() => {
      setItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
    }).finally(() => {
      setItemsLoading(false);
    });
  }, [listPath, mapItem, valueProp, lastUpdateTime]);

  // set options count
  useLayoutEffect(() => {
    setItemsLoadingCount(true);
    let url = listCountPath;
    if (listCountPath === undefined) {
      url = `${listPath}/count`;
    }
    django(url).then(({ data }) => {
      setItemsTotalCount(data);
    }).finally(() => {
      setItemsLoadingCount(false);
    });
  }, [listPath, listCountPath, lastUpdateTime]);

  // handle search
  useEffect(() => {
    const handleGet = ({ url, config }) => {
      setSearchItems([]);
      setSearchItemsLoading(true);
      setSearchItemsLoadingErrorMessage(null);

      django(url, config).then(({ data }) => {
        const mapped = data.map(mapItem);

        setSearchItems(mapped);
      }).catch(function (error) {
        if (django?.isCancel(error)) {
        } else {
          setSearchItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
        }
      }).finally(() => {
        setSearchItemsLoading(false);
      });
    };

    if (searchQuery === '') {
      setSearchItems([]);
      setSearchItemsLoading(false);
      setSearchItemsLoadingErrorMessage(null);
    }

    if (searchQuery !== '') {
      setSearchItems([]);
      setSearchItemsLoading(true);
      setSearchItemsLoadingErrorMessage(null);

      const CancelToken = django.CancelToken;
      const cancelTokenSource = CancelToken.source();

      const config = {
        params: {
          size: PER_PAGE,
          [valueProp]: `*${searchQuery}*`,
        },
        cancelToken: cancelTokenSource.token,
      };

      const debounce = setTimeout(() => handleGet({ url: listPath, config }), 300);

      return () => {
        cancelTokenSource.cancel();

        clearTimeout(debounce);

        setSearchItemsLoading(false);
      };
    }
  }, [mapItem, searchQuery, listPath, valueProp, lastUpdateTime]);

  const handleCheckItem = (item) => {
    let copyMappedDefaultItem = item;
    if (disabledProp) {
      if (!item.disabledvalue) {
        if (valueProp2 && !valueProp3) {
          copyMappedDefaultItem = {
            [keyProp]: item.key,
            [valueProp]: item.value,
            [valueProp2]: item.value2,
          };
        } else if (valueProp2 && valueProp3) {
          copyMappedDefaultItem = {
            [keyProp]: item.key,
            [valueProp]: item.value,
            [valueProp2]: item.value2,
            [valueProp3]: item.value3,
          };
        } else {
          copyMappedDefaultItem = {
            [keyProp]: item.key,
            [valueProp]: item.value,
          };
        }
        copyMappedDefaultItem = extraVal ? Object.assign(copyMappedDefaultItem, { [extraVal]: item.extraVal }) : copyMappedDefaultItem;
        copyMappedDefaultItem = extraVal2 ? Object.assign(copyMappedDefaultItem, { [extraVal2]: item.extraVal2 }) : copyMappedDefaultItem;
        onChangeItems(copyMappedDefaultItem);
      }
    } else {
      if (valueProp2 && !valueProp3) {
        copyMappedDefaultItem = {
          [keyProp]: item.key,
          [valueProp]: item.value,
          [valueProp2]: item.value2,
        };
      } else if (valueProp2 && valueProp3) {
        copyMappedDefaultItem = {
          [keyProp]: item.key,
          [valueProp]: item.value,
          [valueProp2]: item.value2,
          [valueProp3]: item.value3,
        };
      } else {
        copyMappedDefaultItem = {
          [keyProp]: item.key,
          [valueProp]: item.value,
        };
      }
      copyMappedDefaultItem = extraVal ? Object.assign(copyMappedDefaultItem, { [extraVal]: item.extraVal }) : copyMappedDefaultItem;
      copyMappedDefaultItem = extraVal2 ? Object.assign(copyMappedDefaultItem, { [extraVal2]: item.extraVal2 }) : copyMappedDefaultItem;
      onChangeItems(copyMappedDefaultItem);
    }
  };

  const handleRemoveItems = () => {
    onChangeItems(null);
  }

  const handleGetMoreOptions = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;

    setMoreItemsLoading(true);

    django(listPath, { params: { size: PER_PAGE, page, sort: valueProp } }).then(({ data }) => {
      const mapped = data.map(mapItem);

      setItems((prev) => [...prev, ...mapped]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });

      setMoreItemsLoading(false);
    });
  };

  const rowRenderer = ({ index, style }) => {
    const item = FILTERED_ITEMS[index];
    const disabled = disabledProp ? item.disabledvalue : false
    return (
      <div key={item.key} style={style}
        onContextMenu={(e) => {
          e.preventDefault();
          setSelectedItem(item);
          setSelectedPosition({ top: e.clientY, left: e.clientX });
        }}
      >
        {index !== 0 && <Divider />}
        <ListItem
          item={item}
          selectVal={selectVal}
          disabled={disabled}
          searchQuery={!showSelectedItems ? searchQuery : ''}
          onClick={() => handleCheckItem(item)}
        ></ListItem>
      </div>
    );
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  return (
    <>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">{title ? title : 'Filtrele'}</h3>
            <span>
              <Tooltip title="Yenile">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={handleReload}
                ><IconRefresh /></IconButton>
              </Tooltip>
              <Tooltip title="Kapat">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={onClose}
                ><IconClose /></IconButton>
              </Tooltip>
            </span>
          </Toolbar>

          <nav className="w-full flex-1 flex items-center justify-between bg-palette-background-default py-2 px-4 space-x-4">
            <span className="relative w-full h-8 leading-none bg-palette-background-paper apperance-none">
              {!showSelectedItems && (
                <input
                  className={clsx([
                    'absolute inset-0 w-full h-full px-10 bg-transparent rounded border border-palette-action-selected',
                    'focus:outline-none focus:border-primary-300 focus:ring-2 focus:ring-primary-200',
                  ])}
                  value={searchQuery ?? ''}
                  placeholder={'Tüm ' + title + ' içinde ara'}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              )}

              <span className="absolute top-2 left-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                <IconSearch />
              </span>

              {searchQuery.length > 0 &&
                <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchQuery('');
                    }}
                  >
                    <IconClose />
                  </IconButton>
                </span>
              }
            </span>

            {!notEmpty && (
              <span>
                <Tooltip title="Seçimi kaldır">
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleRemoveItems();
                    }}
                  >
                    <IconDelete />
                  </IconButton>
                </Tooltip>
              </span>
            )}

            <span>
              <Tooltip title="Sadece seçili seçeneği göster">
                <Checkbox
                  color="primary"
                  checked={showSelectedItems}
                  style={{ padding: 0 }}
                  disableRipple
                  onChange={(e, checked) => setShowSelectedItems(checked)}
                ></Checkbox>
              </Tooltip>
            </span>
          </nav>

          <span className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', { 'hidden': !SHOW_LINEAR_PROGRESS })}>
            <LinearProgress style={{ height: 2 }} />
          </span>
        </AppBar>

        <main className="relative flex flex-grow flex-col overflow-hidden" ref={mainContainerRef}>
          <div className="h-full flex-grow">
            {LOADING_MESSAGE && (
              <LoadingMessage text={LOADING_MESSAGE} />
            )}

            {ERROR_MESSAGE && (
              <ErrorMessage text={ERROR_MESSAGE} />
            )}

            {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length === 0) && (
              <ErrorMessage text="Seçenek bulunamadı" />
            )}

            {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length > 0) && (
              <InfiniteLoader
                minimumBatchSize={PER_PAGE}
                threshold={PER_PAGE}
                isRowLoaded={({ index }) => !!FILTERED_ITEMS[index]}
                loadMoreRows={({ startIndex, stopIndex }) => {
                  if (itemsTotalCount > 0 && PAGES_COUNT > PAGE && !moreItemsLoading && !showSelectedItems) {
                    handleGetMoreOptions(PAGE + 1);
                  }
                }}
                rowCount={itemsTotalCount > 0 ? itemsTotalCount : PER_PAGE * 2}
              >
                {({ onRowsRendered, registerChild }) => (
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        width={width}
                        height={height}
                        rowHeight={ROW_HEIGHT + 1}
                        rowCount={FILTERED_ITEMS.length}
                        estimatedRowSize={PAGES_COUNT > 0 ? PAGES_COUNT * (ROW_HEIGHT + 1) : undefined}
                        rowRenderer={rowRenderer}

                        onRowsRendered={onRowsRendered}
                        ref={registerChild}
                        onScroll={() => setSelectedItem(null)}
                      />
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            )}
          </div>
          {selectedItem && (
            <RightMenu
              url={newPath}
              itemId={selectedItem?.key}
              ustId={ustId}
              position={selectedPosition}
            ></RightMenu>
          )}
          {confirmButton && (
            <div className="flex items-center justify-between border-t border-palette-action-selected p-2">
              <span></span>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disableRipple
                  disabled={!confirmEnable}
                  onClick={onConfirm}
                >{confirmBtnTitle}</Button>
              </span>
            </div>
          )}
        </main>
      </Paper>
    </>
  );
};

ListSelectorSingle.propTypes = {
  title: PropTypes.string.isRequired,
  listPath: PropTypes.string.isRequired,
  listCountPath: PropTypes.string,
  keyProp: PropTypes.string,
  valueProp: PropTypes.string,
  defaultItem: PropTypes.object,
  onChangeItems: PropTypes.func,
  onClose: PropTypes.func,
};

ListSelectorSingle.defaultTypes = {
  listCountPath: undefined,
  keyProp: 'id',
  valueProp: 'tanim',
  defaultItem: {},
  onChangeItems: () => { },
  onClose: () => { },
};

export default ListSelectorSingle;
