import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  TableContainer,
  Table,
  TableBody,
  ClickAwayListener,
} from '@material-ui/core';

import { ThemeProvider } from '@material-ui/core/styles';

import Store from '../libs/store'
import Themes from '../themes/index';

import GridTableXTableBodyRow from './GridTableX_TableBodyRow';
import GridTableXTableHead from './GridTableX_TableHead';

import { Hata } from '../pages/base/Hata';

// main app theme configs
const appTheme = Themes[Store.get('themeChoice')?.theme ?? 'light'];

// in-component theme configs
const theme = {
  overrides: {
    MuiTable: {
      root: {
        '& tbody tr:hover td': {
          backgroundColor: appTheme.palette.action.hover,
        },

        '& tbody tr.Mui-selected, & tbody tr.Mui-selected:hover': {
          //backgroundColor: appTheme.palette.action.hover,
          backgroundColor: '#eacfea'
        },

        '& tbody tr.Mui-selected td, & tbody tr.Mui-selected:hover td': {
          //backgroundColor: appTheme.palette.action.hover,
          backgroundColor: '#eacfea',
          color: appTheme === 'dark' ? '#000' : 'rgba(0, 0, 0, 0.87)'
        },
        '& tbody tr.Mui-selected td svg, & tbody tr.Mui-selected:hover td svg': {
          color: appTheme === 'dark' ? '#000' : 'rgba(0, 0, 0, 0.87)'
        },

        '& tr th, & tr td': {
          lineHeight: 1,
          padding: 0,
        },

        '& tbody tr.no-hover:hover td': {
          backgroundColor: 'transparent',
        },

        '& tr th + th, & tr td + td': {
          borderLeft: `1px solid ${appTheme.palette.action.selected}`,
        },

        '& tbody tr td': {
          fontSize: "0.75rem !important"
        }
      },
    },
  },
};

const GridTableX = (props) => {
  const tableContainerRef = useRef();
  const tableBodyRef = useRef();

  // add keydown event to table body
  useEffect(() => {
    const el = tableBodyRef?.current;

    const callback = (e) => {
      const validKeys = [
        'ArrowUp',
        'ArrowDown',
        'Enter',
        'Escape',
      ];

      if (validKeys.indexOf(e.key) > -1) {
        e.preventDefault();

        if (props.selectedRowIndex !== null && props.selectedRowIndex > -1) {
          if (e.key === 'ArrowUp' && props.selectedRowIndex > -1) {
            props.onSelectPreviousRow();
          }

          if (e.key === 'ArrowDown' && props.rows.length > (props.selectedRowIndex)) {
            props.onSelectNextRow();
          }

          if (e.key === 'Enter') {
            props.onKeyupEnter(props.selectedRowIndex, props.rows[props.selectedRowIndex]);
          }

          if (e.key === 'Escape') {
            props.onKeyupEsc(props.selectedRowIndex, props.rows[props.selectedRowIndex]);
          }
        }
      }
    };

    if (el) {
      el.addEventListener('keydown', callback);

      return () => {
        el.removeEventListener('keydown', callback);
      }
    }
  }, [props]);

  // seçili satır ekran dışındaysa görünür olarak scroll et
  useEffect(() => {
    // seçilen satır null değilse
    // seçilen satır en az sıfırıncı satır ise
    // seçilen satır toplam satır sayısı altında ise
    if (props.selectedRowIndex !== null && props.selectedRowIndex > -1 && props.rows.length > props.selectedRowIndex) {
      // bu değişkenler adlarından anlaşılır
      const tableContainerEl = tableContainerRef.current;
      const tableHeight = tableContainerEl.offsetHeight;

      const tableHeadFirstRow = tableContainerEl.querySelector('table thead tr');
      const tableHeadFirstRowHeight = tableHeadFirstRow.offsetHeight;

      const tableBodyFirstRow = tableContainerEl.querySelector('table tbody tr');
      const tableBodyFirstRowHeight = tableBodyFirstRow.offsetHeight;

      // seçilen satırın görünen tablodaki üst satır (header) ile arasındaki boşluk
      const rowOffsetTop = props.selectedRowIndex * tableBodyFirstRowHeight - tableContainerEl.scrollTop;
      // seçilen satırın görünen tablodaki alt sınır ile arasındaki boşluk
      const rowOffsetBottom = tableHeight - rowOffsetTop - tableBodyFirstRowHeight - tableHeadFirstRowHeight;

      // seçilen satır tablonun alt tarafında kalıyorsa
      if (rowOffsetTop > (tableHeight / 2) - tableBodyFirstRowHeight) {
        // seçilen satır ile alt sınır arasında bir satır yüksekliği + 1px boşluk yoksa
        if ((tableBodyFirstRowHeight * 2) > rowOffsetBottom) {
          tableContainerEl.scrollTo(tableContainerEl.scrollLeft, tableContainerEl.scrollTop + (tableBodyFirstRowHeight * 2) - rowOffsetBottom);
        }
      } else {
        // seçilen satır ile header bir satır yüksekliği + 1px boşluk yoksa
        if ((tableBodyFirstRowHeight * 2) > rowOffsetTop) {
          tableContainerEl.scrollTo(tableContainerEl.scrollLeft, tableContainerEl.scrollTop - (tableBodyFirstRowHeight * 2) + rowOffsetTop);
        }
      }
    }
  }, [props]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <TableContainer
          className="relative w-full h-full bg-palette-background-paper overflow-y-auto"
          ref={tableContainerRef}
        >
          <Table
            className="focus:outline-none"
            tabIndex="0"
            stickyHeader
          >
            <GridTableXTableHead
              columns={props.columns}

              filter={props.filter}

              varyant={props.varyant}
              showTreeArrow={props.showTreeArrow}
              showFilterRow={props.showFilterRow}
              showCheckBoxCell={props.showCheckBoxCell}

              isCheckedRows={props.rows.length === props.checkedRowIndexes.length}
              isIndeterminateCheckedRows={props.checkedRowIndexes.length > 0 && props.rows.length !== props.checkedRowIndexes.length}
              onCheckedRows={props.onCheckedRows}

              onChangeColumnSort={(columnName) => props.onChangeColumnSort(columnName)}
              onChangeFilterValue={(columnName, filterValue) => props.onChangeFilterValue(columnName, filterValue)}
            ></GridTableXTableHead>

            {props.rows.length > 0 && (
              <ClickAwayListener onClickAway={props.onClickTableBodyAway}>
                <TableBody
                  className="focus:outline-none"
                  tabIndex="1"
                  ref={tableBodyRef}
                >
                  {props.rows.map((row, index) =>
                    <GridTableXTableBodyRow
                      key={row.id}
                      index={index}
                      columns={props.columns}
                      filter={props.filter}
                      functions={props.functions ?? null}
                      row={row}
                      path={props.path}

                      isSelected={index === props.selectedRowIndex}

                      varyant={props.varyant}
                      showTreeArrow={props.showTreeArrow}
                      showCheckBoxCell={props.showCheckBoxCell}

                      isCheckedRow={props.checkedRowIndexes.indexOf(index) > -1}
                      onCheckedRow={(checked) => props.onCheckedRow(index, checked)}

                      onClickTreeArrow={() => props.onClickTreeArrow(row, index)}

                      onTableRowClick={(index, row) => props.onTableRowClick(index, row)}
                      onTableRowDoubleClick={(index, row) => props.onTableRowDoubleClick(index, row)}

                      onRightClickMenuOpenNewTab={props.onRightClickMenuOpenNewTab}
                      onRightClickMenuShowDetail={props.onRightClickMenuShowDetail}
                      onRightClickMenuDelete={props.onRightClickMenuDelete}

                      tableContainerEl={tableContainerRef.current}
                    ></GridTableXTableBodyRow>
                  )}
                </TableBody>
              </ClickAwayListener>
            )}
          </Table>

          {props.rows.length === 0 && props.loadError === false && (
            <div className="p-4">Aradığınız kriterlere uygun içerik bulunamadı.</div>
          )}
          {props.rows.length === 0 && props.loadError === true && (
            <Hata />
          )}
        </TableContainer>
      </ThemeProvider>
    </>
  );
};

GridTableX.propTypes = {
  selectedRowIndex: PropTypes.number,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  checkedRowIndexes: PropTypes.array,

  filter: PropTypes.object,

  showFilterRow: PropTypes.bool,
  showCheckBoxCell: PropTypes.bool,

  onCheckedRow: PropTypes.func,
  onCheckedRows: PropTypes.func,

  onChangeColumnSort: PropTypes.func,
  onChangeFilterValue: PropTypes.func,

  onClickTableBodyAway: PropTypes.func,

  onTableRowClick: PropTypes.func,
  onTableRowDoubleClick: PropTypes.func,

  onRightClickMenuOpenNewTab: PropTypes.func,
  onRightClickMenuShowDetail: PropTypes.func,
  onRightClickMenuDelete: PropTypes.func,
};

GridTableX.defaultProps = {
  selectedRowIndex: null,
  // rows: [],
  // columns: [],
  checkedRowIndexes: [],

  filter: null,

  showFilterRow: false,
  showCheckBoxCell: false,

  onCheckedRow: () => { },
  onCheckedRows: () => { },

  onChangeColumnSort: () => { },
  onChangeFilterValue: () => { },

  onClickTableBodyAway: () => { },

  onTableRowClick: () => { },
  onTableRowDoubleClick: () => { },

  onRightClickMenuOpenNewTab: () => { },
  onRightClickMenuShowDetail: () => { },
  onRightClickMenuDelete: () => { },
};

export default GridTableX;
