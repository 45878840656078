import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import DetailVerticalTabsSub from '../../components/DetailVerticalTabsSub';

const styles = ({ palette, spacing }) => {
  return {
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflow: 'hidden',
    },

    side: {
      height: '100%',
      maxWidth: '100%',
      //minWidth: 200,
      padding: spacing(2, 0, 2, 0),
      overflowY: 'auto'
    },

    main: {
      width: '100%',
      height: '100%',
      flex: 1,
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'flex-start',
      //padding: spacing(2, 3, 2, 3),
      borderLeft: `1px solid ${palette.action.selected}`,
      overflowY: 'auto',
      position: 'relative',
    },
    buttonCointainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      display: 'flex',
      justifyContent: 'center',
      padding: spacing(0, 1, 2, 1)
    },
  };
};

const LayoutDetailVerticalTabSub = withStyles(styles)(({ classes, ...props }) => {

  return (
    <>
      <div className={classes.container}>

        {props.treeList.length > 0 && (
          <aside className={classes.side}>
            <div className={classes.buttonCointainer}>
              <div className={classes.button}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disableElevation
                  onClick={() => props.addNew(null)}
                >{props.buttonText}</Button>
              </div>
            </div>
            <DetailVerticalTabsSub
              treeList={props.treeList}
              addNew={props.addNew}
              delete={props.delete}
              selectedTabIndex={props.selectedTabIndex}
              onChangeTabIndex={props.onChangeTabIndex}
              loading={props.loading}
              toggle={props.toggle}
              isActive={props.isActive}
            />
          </aside>
        )}

        <main className={classes.main} style={{ flexDirection: props.flexDirection ? props.flexDirection : 'row' }}>
          {props.children}
        </main>

      </div>
    </>
  );
});

LayoutDetailVerticalTabSub.propTypes = {
  children: PropTypes.node.isRequired,
  tabs: PropTypes.array,
  selectedTabIndex: PropTypes.number,
  onChangeTabIndex: PropTypes.func,
};

LayoutDetailVerticalTabSub.defaultProps = {
  tabs: [],
  selectedTabIndex: 0,
  onChangeTabIndex: () => { },
};

export default LayoutDetailVerticalTabSub;