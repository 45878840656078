import React, { useCallback, useMemo, useRef } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';
import { useSnackbar } from 'notistack';

import {
  TextField,
  Tooltip,
  Button
} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& + &': {
        marginLeft: 10,
      },
    },
    button2: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
  };
};

const GmerchantDetailTabHesap = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const inputRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const dosyaEkle = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = () => {
        let file = '';
        try {
          file = JSON.parse(reader.result);
          props.onChangeForm('private_key_file_json', JSON.stringify(file))
        } catch (error) {
          enqueueSnackbar('Dosyanızı kontrol edin!', { variant: 'error' })
        }
      }
    }
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="application_name">
                    <label className={classes.layoutFormItemLabel} htmlFor="application_name">Application Name *</label>
                    <Tooltip title={props.error?.application_name ? props.error.application_name[0] : _isNil(props.form?.application_name) ? '' : props.form.application_name}>
                      <TextField
                        {...createTextFieldProps('application_name')}
                        onChange={(e) => props.onChangeForm('application_name', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'application_name', true, false, false, '', '', '')}
                        inputProps={{
                          maxLength: 128,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="private_key_file_json">
                    <label className={classes.layoutFormItemLabel} htmlFor="private_key_file_json">Google Merchant Key File</label>
                    <Button
                      className={classes.button}
                      component="label"
                    >
                      DOSYA YÜKLE
                      <input
                        type="file"
                        hidden
                        ref={inputRef}
                        onClick={() => inputRef.current.value = ""}
                        onChange={(e) => dosyaEkle(e)}
                      />
                    </Button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </>
      }
    </>
  );
});

export default GmerchantDetailTabHesap;
