import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  IconButton,
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  Tooltip
} from '@material-ui/core';

import {
  Search as IconSearch,
  HelpOutline as IconHelp
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import ListSelector from '../../../components/ListSelector';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors, handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import MultipleSelectList from '../../../components/MultipleSelectList';
import ListSelectorTwoStep from '../../../components/ListSelectorTwoStep';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: 0,
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1, 1, 1, 4),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    layoutFormItemCaption: {
      display: 'flex',
      position: 'relative',
      height: 16,
      marginBottom: 10,
    },
    layoutFormItemCaptionText: {
      fontStyle: 'italic',
      fontSize: "11px !important",
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
  };
};

const KargoDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATADIL = useMemo(() => props.hataDil, [props.hataDil]);
  const add = useMemo(() => props.add, [props.add]);

  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);
  const [openSelectorVergi, setOpenSelectorVergi] = useState(false);
  const [detayListOpen, setDetayListOpen] = useState(true);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  const COUNT_TABS = useMemo(() => props?.dilform?.length ?? 0, [props]);
  const FORM = useMemo(() => props?.dilform?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.dilform?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);
  // const declareText = `{kargo_fiyat}`

  useLayoutEffect(() => {
    if (props.dilform?.length > 0) {
      const copyForms = [...props.dilform];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.dilform]);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form?.[name], `${name2}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form, `${name}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    }
  }, [props.error, props.form]);

  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const handleChangeForm = (property, value) => {
    props.onChangeDilForm(selectedTabIndex, property, value);
  };

  const handleSetErrors = (errors) => {
    props.onCheckErrorsDil(errors, selectedTabIndex);
  }

  const handleRemoveMagazaById = (id) => {
    const copymagazalar = [...props.form.magazalar];
    const index = copymagazalar.findIndex((c) => c.id === id);
    if (index > -1) {
      copymagazalar.splice(index, 1);
      props.onChangeForm('magazalar', copymagazalar);
    }
  }

  const setValidLimit = (prop) => {
    let errors = {};
    if (prop === 'desi_alt_limit') {
      if (props.form.desi_alt_limit > props.form.desi_ust_limit) {
        errors[prop] = ['Kargo desi alt limit üst limitten büyük olamaz.']
      } else {
        errors['desi_ust_limit'] = null;
        errors[prop] = null;
      }
    } else {
      if (props.form.desi_ust_limit < props.form.desi_alt_limit) {
        errors[prop] = ['Kargo desi üst limit alt limitten küçük olamaz.']
      } else {
        errors['desi_ust_limit'] = null;
        errors[prop] = null;
      }
    }
    props.onCheckErrors(errors);
  }

  const handleDetailDelete = (index) => {
    props.deleteDetailWithIndex(index);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Kargo Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim', '')}
                          onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                          onBlur={() => handleInputErrors('kargo', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Kargo Tanım', 'kargo')}
                          inputProps={{
                            maxLength: 250,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="calisma_tip">
                      <label className={classes.layoutFormItemLabel} htmlFor="calisma_tip">Çalışma Tip *</label>
                      <Tooltip title={props.error?.calisma_tip ? props.error.calisma_tip[0] : _isNil(props.form?.calisma_tip) ? '' : props.calismaTiplerDic ? props.calismaTiplerDic[props.form.calisma_tip] : ''}>
                        <TextField
                          {...createTextFieldProps('calisma_tip', '')}
                          onChange={(e) => props.onChangeForm('calisma_tip', e.target.value)}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'calisma_tip', true, false, false, '', '', '')}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          {props?.calismaTipler.map((tip) => (
                            <option key={tip.key} value={tip.key}>{tip.value}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="para_birim">
                      <label className={classes.layoutFormItemLabel} htmlFor="para_birim">Para Birimi *</label>
                      <Tooltip title={props.error?.para_birim ? props.error.para_birim[0] : (props.form?.para_birim === "-1" || _isNil(props.form?.para_birim)) ? '' : props.paraBirimDic ? props.paraBirimDic[props.form.para_birim] : ''}>
                        <TextField
                          {...createTextFieldProps('para_birim', '')}
                          onChange={(e) => props.onChangeForm('para_birim', e.target.value)}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'para_birim', true, false, false, '', '', '')}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option disabled key='-1' value='-1'>Seçilmedi</option>
                          {props?.paraBirim.map((tip) => (
                            <option key={tip.id} value={tip.id}>{tip.tanim}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="fiyat">
                      <label className={classes.layoutFormItemLabel} htmlFor="fiyat">Sabit Fiyat *</label>
                      <Tooltip title={props.error?.fiyat ? props.error.fiyat[0] : _isNil(props.form?.fiyat) ? '' : props.form.fiyat}>
                        <TextField
                          {...createTextFieldProps('fiyat', '')}
                          onChange={(e) => props.onChangeForm('fiyat', e.target.value)}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'fiyat', true, false, false, '', '', '')}
                          type="number"
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="vergi">
                      <label className={classes.layoutFormItemLabel} htmlFor="vergi">Vergi *</label>
                      <Tooltip title={props.error?.vergi ? props.error.vergi[0] : _isNil(props.form?.vergi) ? '' : props.form.vergi.tanim}>
                        <TextField
                          {...createTextFieldProps('vergi', 'tanim')}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                size="small"
                                className={classes.button}
                                disableRipple
                                onClick={() => setOpenSelectorVergi(true)}
                              ><IconSearch /></IconButton>
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="siralama">
                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                      <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                        <TextField
                          {...createTextFieldProps('siralama', '')}
                          onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                          type="number"
                          inputProps={{
                            min: -2147483648,
                            max: 2147483647,
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                      ></Checkbox>
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="desi_alt_limit">
                      <label className={classes.layoutFormItemLabel} htmlFor="desi_alt_limit">Kargo Desi Alt Limit </label>
                      <Tooltip title={props.error?.desi_alt_limit ? props.error.desi_alt_limit[0] : _isNil(props.form?.desi_alt_limit) ? '' : props.form.desi_alt_limit}>
                        <TextField
                          {...createTextFieldProps('desi_alt_limit', '')}
                          onChange={(e) => props.onChangeForm('desi_alt_limit', e.target.value)}
                          onBlur={() => setValidLimit('desi_alt_limit')}
                          type="number"
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText("“Kargo firmasının taşıma yapacağı alt desi limiti, desi biçimli çalışıyorsanız sepetdeki ürünler bu limitin altında ise çıktığında kargo seçimlerine gelmez.”");
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                    <li className={classes.layoutFormItem} id="desi_ust_limit">
                      <label className={classes.layoutFormItemLabel} htmlFor="desi_ust_limit">Kargo Desi Üst Limit</label>
                      <Tooltip title={props.error?.desi_ust_limit ? props.error.desi_ust_limit[0] : _isNil(props.form?.desi_ust_limit) ? '' : props.form.desi_ust_limit}>
                        <TextField
                          {...createTextFieldProps('desi_ust_limit', '')}
                          onChange={(e) => props.onChangeForm('desi_ust_limit', e.target.value)}
                          onBlur={() => setValidLimit('desi_ust_limit')}
                          type="number"
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText("“Kargo firmasının taşıma yapacağı üst desi limiti, desi biçimli çalışıyorsanız sepetdeki ürünler bu limitin üzerine çıktığında kargo seçimlerine gelmez.”");
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                    <li className={classes.layoutFormItem} id="ucretsiz_kargo_deger">
                      <label className={classes.layoutFormItemLabel} htmlFor="ucretsiz_kargo_deger">Ücretsiz Kargo Değer *</label>
                      <Tooltip title={props.error?.ucretsiz_kargo_deger ? props.error.ucretsiz_kargo_deger[0] : _isNil(props.form?.ucretsiz_kargo_deger) ? '' : props.form.ucretsiz_kargo_deger}>
                        <TextField
                          {...createTextFieldProps('ucretsiz_kargo_deger', '')}
                          onChange={(e) => props.onChangeForm('ucretsiz_kargo_deger', e.target.value)}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'ucretsiz_kargo_deger', true, false, false, '', '', '')}
                          type="number"
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText("“Ücretsiz kargo için yapılması gereken minimum sipariş tutarıdır.”");
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                    <li className={classes.layoutFormItem} id="tahmini_teslim_suresi">
                      <label className={classes.layoutFormItemLabel} htmlFor="tahmini_teslim_suresi">Tahmini Teslim Süresi *</label>
                      <Tooltip title={props.error?.tahmini_teslim_suresi ? props.error.tahmini_teslim_suresi[0] : _isNil(props.form?.tahmini_teslim_suresi) ? '' : props.form.tahmini_teslim_suresi}>
                        <TextField
                          {...createTextFieldProps('tahmini_teslim_suresi', '')}
                          onChange={(e) => props.onChangeForm('tahmini_teslim_suresi', e.target.value)}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'tahmini_teslim_suresi', true, false, false, '', '', '')}
                          type="number"
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText("“Gün cinsinden, tahmini kargo teslimat süresidir.”");
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItemBox} id="magazalar">
                      <MultipleSelectList
                        headername="MAĞAZALAR *"
                        name="Mağazalar"
                        name2="Mağaza"
                        form={props.form}
                        list={props.form?.magazalar ?? []}
                        listlength={props?.form?.magazalar?.length ?? 0}
                        opendialog={setOpenSelectorMagaza}
                        keyProp="id"
                        valueProp="tanim"
                        removefonk={handleRemoveMagazaById}
                        loading={props.loading}
                        error={props.error?.magazalar ? props.error.magazalar[0] : null}
                        newPath="tanimlar/magaza"
                        ustId={false}
                      />
                    </li>
                  </ul>
                </div>
                <div className={classes.layoutContainerTab}>
                  <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                  <>
                    {HATADIL && (
                      <Hata />
                    )}
                    {!HATADIL && (
                      <LayoutDetailVerticalTab
                        tabs={tabs}
                        selectedTabIndex={selectedTabIndex}
                        onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                        buttonShow={false}
                        delete={handleDetailDelete}
                        deleteButtonShow={true}
                      >
                        {COUNT_TABS === 0 && (
                          <div className="flex flex-col items-start justify-start p-4 space-y-4">
                            <Typography variant="body1">
                              Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                          </div>
                        )}
                        {COUNT_TABS > 0 && (
                          <div className={classes.tabDiv}>
                            <div className={classes.column}>
                              <ul className={classes.layoutFormList}>
                                <li className={classes.layoutFormItem} id="tanim">
                                  <label className={classes.layoutFormItemLabel} htmlFor="tanim">Tanım</label>
                                  <Tooltip title={ERROR?.tanim ? ERROR.tanim[0] : FORM?.tanim == null ? '' : FORM.tanim}>
                                    <TextField
                                      {...createTextFieldProps2('tanim')}
                                      onChange={(e) => handleChangeForm('tanim', e.target.value)}
                                      onBlur={() => handleInputErrors2(FORM, 'tanim', handleSetErrors)}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="ucretsiz_etiket">
                                  <label className={classes.layoutFormItemLabel} htmlFor="ucretsiz_etiket">Ücretsiz Etiket</label>
                                  <Tooltip title={ERROR?.ucretsiz_etiket ? ERROR.ucretsiz_etiket[0] : FORM?.ucretsiz_etiket == null ? '' : FORM.ucretsiz_etiket}>
                                    <TextField
                                      {...createTextFieldProps2('ucretsiz_etiket')}
                                      onChange={(e) => handleChangeForm('ucretsiz_etiket', e.target.value)}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="fiyat_sablon">
                                  <label className={classes.layoutFormItemLabel} htmlFor="fiyat_sablon">Fiyat Şablon</label>
                                  <Tooltip title={ERROR?.fiyat_sablon ? ERROR.fiyat_sablon[0] : FORM?.fiyat_sablon == null ? '' : FORM.fiyat_sablon}>
                                    <TextField
                                      {...createTextFieldProps2('fiyat_sablon')}
                                      onChange={(e) => handleChangeForm('fiyat_sablon', e.target.value)}
                                    ></TextField>
                                  </Tooltip>
                                  <IconButton
                                    size="small"
                                    className={classes.button2}
                                    disableRipple
                                    onClick={() => {
                                      setHelpText('“{kargo_fiyat} kalıbı kullanımı şablon için zorunludur.Aksi halde kargo ücreti görünmez.ÖRN. (Kargo ücreti: {kargo_fiyat}) şeklindeki fiyat şablonunun görüntüsü (Kargo ücreti: 9.99 ₺) şeklinde olacaktır.”');
                                      setHelpOpen(true)
                                    }}
                                  ><IconHelp /></IconButton>
                                </li>

                                <li className={classes.layoutFormItem} id="ucretsiz_limit_sablon">
                                  <label className={classes.layoutFormItemLabel} htmlFor="ucretsiz_limit_sablon">Ücretsiz Kargo Limit Şablon</label>
                                  <Tooltip title={ERROR?.ucretsiz_limit_sablon ? ERROR.ucretsiz_limit_sablon[0] : FORM?.ucretsiz_limit_sablon == null ? '' : FORM.ucretsiz_limit_sablon}>
                                    <TextField
                                      {...createTextFieldProps2('ucretsiz_limit_sablon')}
                                      onChange={(e) => handleChangeForm('ucretsiz_limit_sablon', e.target.value)}
                                      multiline
                                      rows={3}
                                    ></TextField>
                                  </Tooltip>
                                  <IconButton
                                    size="small"
                                    className={classes.button2}
                                    disableRipple
                                    onClick={() => {
                                      setHelpText('“{ucretsiz_kargo_deger} kalıbı cümlede ücretsiz kargo bedelinin kullanılmak istendiği yere, {kalan_tutar} ise kargonun ücretsiz olması için gereken kalan meblağnın kullanılmak istendiği yere etiket olarak bırakılarak kullanılabilir. ÖRN1. ({ucretsiz_kargo_deger} üzeri kargolar ücretsizdir) şeklindeki fiyat şablonunun görüntüsü (250 ₺ üzeri kargolar ücretsizdir ) şeklinde olacaktır. ÖRN2. (Sepetinize {kalan_tutar} TL lik daha ürün ekleyin KARGONUZ BEDAVA olsun) şeklindeki fiyat şablonunun görüntüsü (Sepetinize 25 TL lik daha ürün ekleyin KARGONUZ BEDAVA olsun ) şeklinde olacaktır.”');
                                      setHelpOpen(true)
                                    }}
                                  ><IconHelp /></IconButton>
                                </li>

                              </ul>
                            </div>
                          </div>
                        )
                        }
                      </LayoutDetailVerticalTab>
                    )}
                  </>
                </div>
              </div>
              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'magazalar', true, false, false, '', '', '')
                    setOpenSelectorMagaza(false)
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Mağazalar'
                      listPath='magaza/mini_list?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.magazalar}
                      onChangeItems={(items) => props.onChangeForm('magazalar', items)}
                      onClose={() => {
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'magazalar', true, false, false, '', '', '')
                        setOpenSelectorMagaza(false)
                      }}
                      newPath='tanimlar/magaza'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorVergi &&
                <Dialog
                  open={openSelectorVergi}
                  PaperProps={{
                    style: {
                      background: 'transparent',
                      height: 600,
                      width: detayListOpen ? '100%' : 480
                    },
                  }}
                  fullWidth
                  maxWidth={detayListOpen ? 'md' : 'sm'}
                  onClose={() => {
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'vergi', true, false, false, '', '', '')
                    setOpenSelectorVergi(false)
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorTwoStep
                      title="Vergiler"
                      mainItemsPath='vergi_tip'
                      mainItemsCountPath='vergi_tip/count'
                      mainDefaultItems={props.form.vergi_baslik}
                      mainMultiple={false}
                      mainKeyProp='id'
                      mainValueProp='tanim'
                      listPath='vergi'
                      listCountPath='vergi/count'
                      listMultiple={false}
                      keyProp='id'
                      valueProp='tanim'
                      keyUId='baslik_id'
                      valueUV='baslik_tanim'
                      defaultItems={props.form.vergi}
                      onChangeItems={(items) => props.onChangeForm('vergi', items)}
                      onClose={() => {
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'vergi', true, false, false, '', '', '')
                        setOpenSelectorVergi(false);
                      }}
                      onDetayListClose={(res) => setDetayListOpen(res)}
                      detayListOpen={detayListOpen}
                      detayList={props.form.vergi_tree_list}
                      url="tanimlar/vergidetay"
                    />
                  </DialogContent>
                </Dialog>
              }
              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default KargoDetailTabGenel;
