import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import {
  Search as IconSearch
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import ListSelectorSingle from '../../../../components/ListSelectorSingle';
import RightMenuDetail from '../../../../components/RightMenuDetail';
import { Hata } from '../../../base/Hata';


import MultipleSelectList from '../../../../components/MultipleSelectList';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    layoutFormItemBox2: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 600,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },

    tableContainer: {
      border: "1px solid",
      borderColor: palette.primary.main,
      borderRadius: 4,
    },
    table: {
      '&': {
        whiteSpace: 'nowrap'
      },
      '& tr th:first-child, & tr td:first-child': {
        padding: spacing(0, 0, 0, 1),
      },
      '& tr th, & tr td': {
        lineHeight: 1,
        padding: 0,
      },
      '& tbody tr:hover td': {
        backgroundColor: palette.action.hover,
      },
      '& tbody tr:last-child td': {
        borderBottomWidth: 0,
      },
    },
    tableCell: {
      height: 40
    },
    toolbar: {
      backgroundColor: palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    }
  };
};


const UrunDetailXTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [openSelectorUrun, setOpenSelectorUrun] = useState(false);
  const [openSelectorGrup, setOpenSelectorGrup] = useState(false);
  const [openSelectorKategori, setOpenSelectorKategori] = useState(false);
  const [grupItem, setGrupItem] = useState(null);
  const [kategoriItem, setKategoriItem] = useState(null);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form[name], `${name2}`) || defaultValue,
        error: _has(props.error?.[name], `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form, `${name}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    }
  }, [props.error, props.form]);



  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>

                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim', '')}
                        ></TextField>
                      </Tooltip>
                    </li>

                    <li className={classes.layoutFormItem} id="kod">
                      <label className={classes.layoutFormItemLabel} htmlFor="kod">Kod *</label>
                      <Tooltip title={props.error?.kod ? props.error.kod[0] : _isNil(props.form?.kod) ? '' : props.form.kod}>
                        <TextField
                          {...createTextFieldProps('kod', '')}
                        ></TextField>
                      </Tooltip>
                    </li>

                    <li className={classes.layoutFormItem} id="barkod">
                      <label className={classes.layoutFormItemLabel} htmlFor="barkod">Barkod *</label>
                      <Tooltip title={props.error?.barkod ? props.error.barkod[0] : _isNil(props.form?.barkod) ? '' : props.form.barkod}>
                        <TextField
                          {...createTextFieldProps('barkod', '')}
                        ></TextField>
                      </Tooltip>
                    </li>

                    <li className={classes.layoutFormItem} id="uretici_kodu">
                      <label className={classes.layoutFormItemLabel} htmlFor="uretici_kodu">Üretici Kodu</label>
                      <Tooltip title={props.error?.uretici_kodu ? props.error.uretici_kodu[0] : _isNil(props.form?.uretici_kodu) ? '' : props.form.uretici_kodu}>
                        <TextField
                          {...createTextFieldProps('uretici_kodu', '')}
                        ></TextField>
                      </Tooltip>
                    </li>

                    <li className={classes.layoutFormItem} id="desi">
                      <label className={classes.layoutFormItemLabel} htmlFor="desi">Desi</label>
                      <Tooltip title={props.error?.desi ? props.error.desi[0] : _isNil(props.form?.desi) ? '' : props.form.desi}>
                        <TextField
                          {...createTextFieldProps('desi', '')}
                          type="number"
                          inputProps={{
                            style: { textAlign: "right" }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>

                    <li className={classes.layoutFormItem} id="urun">
                      <label className={classes.layoutFormItemLabel} htmlFor="urun">Panel Ürün</label>
                      <Tooltip title={props.error?.urun ? props.error.urun[0] : _isNil(props.form?.urun) ? '' : props.form.urun.tanim}>
                        <TextField
                          {...createTextFieldProps('urun', 'tanim')}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                size="small"
                                className={classes.button}
                                disableRipple
                                onClick={() => setOpenSelectorUrun(true)}
                              ><IconSearch /></IconButton>
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>

                    <li className={classes.layoutFormItem} id="fiyati_isle">
                      <label className={classes.layoutFormItemLabel} htmlFor="fiyati_isle">Fiyat Entegrasyondan Gelsin</label>
                      <Checkbox
                        name="fiyati_isle"
                        color="primary"
                        checked={_result(props.form, 'fiyati_isle', false)}
                      ></Checkbox>
                    </li>
                  </ul>

                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItemBox}>
                      <MultipleSelectList
                        headername="ÜRÜN GRUPLARI"
                        name="Ürün Grupları"
                        name2="Ürün grup"
                        form={props.form}
                        list={props.form?.gruplar ?? []}
                        listlength={props?.form?.gruplar?.length ?? 0}
                        keyProp="id"
                        valueProp="tanim"
                        notEdit={true}
                        loading={props.loading}
                        newPath={null}
                        openModalInside={true}
                        setModalInside={(item) => {
                          setGrupItem(item);
                          setOpenSelectorGrup(true);
                        }}
                      />
                    </li>
                    <li className={classes.layoutFormItemBox}>
                      <MultipleSelectList
                        headername="KATEGORİLER"
                        name="Kategoriler"
                        name2="Kategori"
                        form={props.form}
                        list={props.form?.kategoriler ?? []}
                        listlength={props?.form?.kategoriler?.length ?? 0}
                        keyProp="id"
                        valueProp="tanim"
                        notEdit={true}
                        loading={props.loading}
                        newPath={null}
                        openModalInside={true}
                        setModalInside={(item) => {
                          setKategoriItem(item);
                          setOpenSelectorKategori(true);
                        }}
                      />
                    </li>
                  </ul>
                </div>
                {props.form?.ozellikler_list?.length > 0 && (
                  <div className={classes.layoutContainer}>
                    <ul className={classes.layoutFormList}>
                      <li className={classes.layoutFormItemBox2}>
                        <TableContainer className={classes.tableContainer}>
                          <Table className={classes.table} size="small" stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell component="th" scope="row" className={classes.tableCell}>Özellik Başlık</TableCell>
                                <TableCell component="th" scope="row" className={classes.tableCell}>Özellik Değer(ler)</TableCell>
                                <TableCell component="th" scope="row" />
                              </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                              {props.form.ozellikler_list.map((item, index) => (
                                <TableRow>
                                  <TableCell className={classes.tableCell}>{item.title}</TableCell>
                                  <TableCell className={classes.tableCell}>{item.list.join(',')}</TableCell>
                                  <TableCell />
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {openSelectorUrun &&
                <Dialog
                  open={openSelectorUrun}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorUrun(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Ürünler'
                      listPath='urun/mini_list?aktif=true'
                      listCountPath='urun/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.urun}
                      onChangeItems={(items) => props.onChangeForm(items)}
                      onClose={() => {
                        setOpenSelectorUrun(false);
                      }}
                      newPath='tanimlar/urun'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorGrup &&
                <Dialog
                  open={openSelectorGrup}
                  PaperProps={{
                    style: {
                      width: '480px',
                    },
                  }}
                  onClose={() => setOpenSelectorGrup(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <RightMenuDetail
                      title="Grup Detay"
                      url={`enttanim-gruptemp/${grupItem.key}`}
                      itemList={[
                        { key: "grup", title: "Eşleştirilmiş Grup", value: null, key2: "tanim" },
                      ]}
                      onClose={() => setOpenSelectorGrup(false)}
                      baseData={[
                        { key: "tanim", title: "Entegrasyon Tanım", value: grupItem.value },
                      ]}
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorKategori &&
                <Dialog
                  open={openSelectorKategori}
                  PaperProps={{
                    style: {
                      width: '480px',
                    },
                  }}
                  onClose={() => setOpenSelectorKategori(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <RightMenuDetail
                      title="Kategori Detay"
                      url={`enttanim-kategoritempkategori/?kategori_temp.id=${kategoriItem.key}`}
                      itemList={[
                        { key: "kategori", title: "Eşleştirilmiş Kategori", value: null, key2: "tanim" },
                        { key: "islem_tip", title: "İşlem Tip", value: null, key2: "value" },
                      ]}
                      onClose={() => setOpenSelectorKategori(false)}
                      baseData={[
                        { key: "tanim", title: "Entegrasyon Tanım", value: kategoriItem.value },
                      ]}
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default UrunDetailXTabGenel;