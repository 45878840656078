import React from "react";
import { Route, Switch } from "react-router-dom";
import URLS from "./urls";

import Login from "./pages/Login";
import PasswordReset from "./pages/PasswordReset";
import Dashboard from "./pages/Dashboard";
import Page from "./pages/Page";
import NotFound from "./pages/NotFound";
import DashboardBase from "./pages/base/DashboardBase";

import AlisFaturaList from "./pages/evrak/alisfatura/AlisFaturaList";
import AlisFaturaDetail from "./pages/evrak/alisfatura/Detail";

import AlisIadeFaturaList from "./pages/evrak/alisiadefatura/AlisIadeFaturaList";
import AlisIadeFaturaDetail from "./pages/evrak/alisiadefatura/Detail";

import AlisIrsaliyeList from "./pages/evrak/alisirsaliye/AlisIrsaliyeList";
import AlisIrsaliyeDetail from "./pages/evrak/alisirsaliye/Detail";

import AlisIadeIrsaliyeList from "./pages/evrak/alisiadeirsaliye/AlisIadeIrsaliyeList";
import AlisIadeIrsaliyeDetail from "./pages/evrak/alisiadeirsaliye/Detail";

import AlisSiparisList from "./pages/evrak/alissiparis/AlisSiparisList";
import AlisSiparisDetail from "./pages/evrak/alissiparis/Detail";

import AlisSiparistenFaturaList from "./pages/evrak/alissiparistenfatura/AlisSiparistenFaturaList";
import AlisSiparistenFaturaDetail from "./pages/evrak/alissiparistenfatura/Detail";

import AlisSiparistenIrsaliyeList from "./pages/evrak/alissiparistenirsaliye/AlisSiparistenIrsaliyeList";
import AlisSiparistenIrsaliyeDetail from "./pages/evrak/alissiparistenirsaliye/Detail";

import AlisIrsaliyedenFaturaList from "./pages/evrak/alisirsaliyedenfatura/AlisIrsaliyedenFaturaList";
import AlisIrsaliyedenFaturaDetail from "./pages/evrak/alisirsaliyedenfatura/Detail";

import AlisOnSiparisList from "./pages/evrak/alisonsiparis/AlisOnSiparisList";
import AlisOnSiparisDetail from "./pages/evrak/alisonsiparis/Detail";

import AlisOnSiparisOnayList from "./pages/evrak/alisonsiparisonay/AlisOnSiparisOnayList";
import AlisOnSiparisOnayDetail from "./pages/evrak/alisonsiparisonay/Detail";

import BankaHesapList from "./pages/tanimlar/bankahesap/BankaHesapList";
import BankaHesapDetail from "./pages/tanimlar/bankahesap/Detail";

import BilesenList from './pages/tanimlar/bilesen/BilesenList';
import BilesenDetail from './pages/tanimlar/bilesen/Detail';

import BirimList from "./pages/tanimlar/birim/BirimList";
import BirimDetail from "./pages/tanimlar/birim/Detail";

import CariList from "./pages/tanimlar/cari/List";
import CariDetail from "./pages/tanimlar/cari/Detail";

import DepartmanList from './pages/tanimlar/departman/DepartmanList';
import DepartmanDetail from './pages/tanimlar/departman/Detail';

import DepoList from './pages/tanimlar/depo/DepoList';
import DepoDetail from './pages/tanimlar/depo/Detail';

import DepoCikisList from "./pages/evrak/depocikis/DepoCikisList";
import DepoCikisDetail from "./pages/evrak/depocikis/Detail";

import DepoGirisList from "./pages/evrak/depogiris/DepoGirisList";
import DepoGirisDetail from "./pages/evrak/depogiris/Detail";

import DilList from './pages/tanimlar/dil/DilList';
import DilDetail from './pages/tanimlar/dil/Detail';

import EkAlanList from './pages/tanimlar/ek_alan/EkAlanList';
import EkAlanDetail from './pages/tanimlar/ek_alan/Detail';

import EklentiList from './pages/tanimlar/eklenti/EklentiList';
import EklentiDetail from './pages/tanimlar/eklenti/Detail';

import EntegrasyonList from './pages/tanimlar/entegrasyon/EntegrasyonList';
import EntegrasyonDetail from './pages/tanimlar/entegrasyon/Detail';
import EntegrasyonUrunDetail from './pages/tanimlar/entegrasyon/EntegrasyonDetailTab_Urun/Detail';
import EntegrasyonIslemDetail from './pages/tanimlar/entegrasyon/EntegrasyonDetailTab_Islem/Detail';
import EntegrasyonEslestirme from './pages/tanimlar/entegrasyon_esleme/List';

import EpostaAuthList from './pages/tanimlar/eposta_auth/EpostaAuthList';
import EpostaAuthDetail from './pages/tanimlar/eposta_auth/Detail';

import EvrakDurumTanim from "./pages/evrak/durumtanim/EvrakDurumTanimList";
import EvrakDurumTanimDetail from "./pages/evrak/durumtanim/Detail";

import FiyatTanimList from './pages/tanimlar/fiyat-tanimlari/FiyatTanimList';
import FiyatTanimDetail from './pages/tanimlar/fiyat-tanimlari/Detail';

import FormTanimList from "./pages/tanimlar/form-tanimlari/FormTanimList";
import FormTanimDetail from "./pages/tanimlar/form-tanimlari/Detail";

import FotoList from "./pages/tanimlar/foto/FotoList";
import FotoDetail from "./pages/tanimlar/foto/Detail";

import FotoAyarList from "./pages/tanimlar/fotoayar/FotoAyarList";
import FotoAyarDetail from "./pages/tanimlar/fotoayar/Detail";

import GmerchantList from './pages/tanimlar/gmerchant/GmerchantList';
import GmerchantDetail from './pages/tanimlar/gmerchant/Detail';
import GmerchantUrunDetail from './pages/tanimlar/gmerchant/GmerchantDetailTab_Urun/Detail';

import GrupList from './pages/tanimlar/grup/GrupList';
import GrupDetail from './pages/tanimlar/grup/Detail';

import HatirlaticiList from './pages/tanimlar/hatirlatici/HatirlaticiList';
import HatirlaticiDetail from './pages/tanimlar/hatirlatici/Detail';

import IcerikSablonList from './pages/tanimlar/icerik_sablon/IcerikSablonList';
import IcerikSablonDetail from './pages/tanimlar/icerik_sablon/Detail';

import { KategoriIndex } from "./pages/tanimlar/kategori";
import KategoriDetail from "./pages/tanimlar/kategori/DetailSingle";

import KampanyaList from './pages/tanimlar/kampanya/KampanyaList';
import KampanyaDetail from './pages/tanimlar/kampanya/Detail';

import KargoList from './pages/tanimlar/kargo/KargoList';
import KargoDetail from './pages/tanimlar/kargo/Detail';

import KodSeriList from './pages/tanimlar/kod_seri/KodSeriList';
import KodSeriDetail from './pages/tanimlar/kod_seri/Detail';

import KullaniciGrupList from './pages/tanimlar/kullanici_grup/KullaniciGrupList';
import KullaniciGrupDetail from './pages/tanimlar/kullanici_grup/Detail';

import KullaniciOdemeLogList from './pages/tanimlar/kullanici_odeme_logları/KullaniciOdemeLogList';
import KullaniciOdemeLogDetail from './pages/tanimlar/kullanici_odeme_logları/Detail';

import KurDetail from "./pages/tanimlar/kurlar/Detail";
import KurList from "./pages/tanimlar/kurlar/KurList";

import MagazaList from './pages/tanimlar/magaza/MagazaList';
import MagazaDetail from './pages/tanimlar/magaza/Detail';

import MenuList from './pages/tanimlar/menu/MenuList';
import MenuDetail from './pages/tanimlar/menu/Detail';

import MesajList from './pages/tanimlar/mesaj/MesajList';
import MesajDetail from './pages/tanimlar/mesaj/Detail';

import OdemeServisList from './pages/tanimlar/odeme_servis/OdemeServisList';
import OdemeServisDetail from './pages/tanimlar/odeme_servis/Detail';

import OdemeSetList from './pages/tanimlar/odeme_set/List';
import OdemeSetDetail from './pages/tanimlar/odeme_set/Detail';

import OdemeTanimList from './pages/tanimlar/odeme_tanim/OdemeTanimList';
import OdemeTanimDetail from './pages/tanimlar/odeme_tanim/Detail';

import OdemeList from "./pages/evrak/odeme/OdemeList";
import OdemeDetail from "./pages/evrak/odeme/Detail";

import { OzellikIndex } from "./pages/tanimlar/ozellikler";
import OzellikDetail from "./pages/tanimlar/ozellikler/DetailSingle";

import ParaBirimList from "./pages/tanimlar/para-birimleri/ParaBirimList";
import ParaBirimDetail from "./pages/tanimlar/para-birimleri/Detail";

import RedirectList from './pages/tanimlar/redirect/RedirectList';
import RedirectDetail from './pages/tanimlar/redirect/Detail';

import SabitSayfaList from './pages/tanimlar/sabit_sayfa/SabitSayfaList';
import SabitSayfaDetail from './pages/tanimlar/sabit_sayfa/Detail';

import SablonPaketList from "./pages/tanimlar/sablon/List";
import SablonPaketDetail from "./pages/tanimlar/sablon/Detail";

import SatisBirimGrupDetail from "./pages/tanimlar/satisbirim/Detail";
import SatisBirimGrupList from "./pages/tanimlar/satisbirim/SatisBirimList";

import SatisIrsaliyeList from "./pages/evrak/satisirsaliye/SatisIrsaliyeList";
import SatisIrsaliyeDetail from "./pages/evrak/satisirsaliye/Detail";

import SatisIadeIrsaliyeList from "./pages/evrak/satisiadeirsaliye/SatisIadeIrsaliyeList";
import SatisIadeIrsaliyeDetail from "./pages/evrak/satisiadeirsaliye/Detail";

import SatisFaturaList from "./pages/evrak/satisfatura/SatisFaturaList";
import SatisFaturaDetail from "./pages/evrak/satisfatura/Detail";

import SatisIadeFaturaList from "./pages/evrak/satisiadefatura/SatisIadeFaturaList";
import SatisIadeFaturaDetail from "./pages/evrak/satisiadefatura/Detail";

import SatisSiparisList from "./pages/evrak/satissiparis/SatisSiparisList";
import SatisSiparisDetail from "./pages/evrak/satissiparis/Detail";

import SatisSiparistenSevkEmriList from "./pages/evrak/satissiparistensevkemri/SatisSiparistenSevkEmriList";
import SatisSiparistenSevkEmriDetail from "./pages/evrak/satissiparistensevkemri/Detail";

import SatisSiparistenFaturaList from "./pages/evrak/satissiparistenfatura/SatisSiparistenFaturaList";
import SatisSiparistenFaturaDetail from "./pages/evrak/satissiparistenfatura/Detail";

import SatisSiparistenIrsaliyeList from "./pages/evrak/satissiparistenirsaliye/SatisSiparistenIrsaliyeList";
import SatisSiparistenIrsaliyeDetail from "./pages/evrak/satissiparistenirsaliye/Detail";

import SatisIrsaliyesindenFaturaList from "./pages/evrak/satisirsaliyedenfatura/SatisIrsaliyesindenFaturaList";
import SatisIrsaliyesindenFaturaDetail from "./pages/evrak/satisirsaliyedenfatura/Detail";

import SatisOnSiparisList from "./pages/evrak/satisonsiparis/SatisOnSiparisList";
import SatisOnSiparisDetail from "./pages/evrak/satisonsiparis/Detail";

import SatisOnSiparisOnayList from "./pages/evrak/satisonsiparisonay/SatisOnSiparisOnayList";
import SatisOnSiparisOnayDetail from "./pages/evrak/satisonsiparisonay/Detail";

import SevkEmriList from "./pages/evrak/sevkemri/SevkEmriList";
import SevkemriDetail from "./pages/evrak/sevkemri/Detail";

import SevkEmrindenSatisFaturaList from "./pages/evrak/sevkemrindensatisfatura/SevkEmrindenSatisFaturaList";
import SevkEmrindenSatisFaturaDetail from "./pages/evrak/sevkemrindensatisfatura/Detail";

import SevkEmrindenSatisIrsaliyeList from "./pages/evrak/sevkemrindensatisirsaliye/SevkEmrindenSatisIrsaliyeList";
import SevkEmrindenSatisIrsaliyeDetail from "./pages/evrak/sevkemrindensatisirsaliye/Detail";

import SiteList from './pages/tanimlar/site/SiteList';
import SiteDetail from './pages/tanimlar/site/Detail';

import SozlesmeList from "./pages/tanimlar/sozlesme/SozlesmeList";
import SozlesmeDetail from "./pages/tanimlar/sozlesme/Detail";

import TahsilatList from "./pages/evrak/tahsilat/TahsilatList";
import TahsilatDetail from "./pages/evrak/tahsilat/Detail";

import { UlkeIndex } from "./pages/tanimlar/ulke";
import UlkeDetail from "./pages/tanimlar/ulke/DetailSingle";

import UrunList from './pages/tanimlar/urun/List';
import UrunDetail from './pages/tanimlar/urun/Detail';

import UrunListTanimList from './pages/tanimlar/urunlisttanim/UrunListTanimList';
import UrunListTanimDetail from './pages/tanimlar/urunlisttanim/Detail';

import UyeDetail from "./pages/tanimlar/uye/Detail";
import UyeList from "./pages/tanimlar/uye/List";

import VaryantDetail from "./pages/tanimlar/varyant/Detail";
import VaryantList from "./pages/tanimlar/varyant/VaryantList";

import { VergiIndex } from "./pages/tanimlar/vergiler";
import VergiDetail from "./pages/tanimlar/vergiler/DetailSingle";

import YoneticiList from "./pages/tanimlar/yoneticiler/List";
import YoneticiDetail from "./pages/tanimlar/yoneticiler/Detail";

// import VergiDetail from './pages/tanimlar/vergi/Detail';
// import VergiList from './pages/tanimlar/vergi/VergiList';
// import UlkeIndex from "./pages/tanimlar/ulke";
import PrivateRoute from "./components/PrivateRoute";

import Palettes from './pages/Palettes';

export default function Routes() {
  return (
    <Switch>

      <Route exact path={URLS.user.login} component={Login} />
      <Route exact path={URLS.user.password_reset} component={PasswordReset} />

      <DashboardBase>

        <PrivateRoute exact path="/palettes" component={Palettes} />

        <PrivateRoute exact path={URLS.root} component={Dashboard} />

        <PrivateRoute exact path={URLS.page.detail()} component={Page} />

        <PrivateRoute exact path={URLS.evrak.alisfatura.list} component={AlisFaturaList} />
        <PrivateRoute exact path={URLS.evrak.alisfatura.detail()} component={AlisFaturaDetail} />

        <PrivateRoute exact path={URLS.evrak.alisiadefatura.list} component={AlisIadeFaturaList} />
        <PrivateRoute exact path={URLS.evrak.alisiadefatura.detail()} component={AlisIadeFaturaDetail} />

        <PrivateRoute exact path={URLS.evrak.alisirsaliye.list} component={AlisIrsaliyeList} />
        <PrivateRoute exact path={URLS.evrak.alisirsaliye.detail()} component={AlisIrsaliyeDetail} />

        <PrivateRoute exact path={URLS.evrak.alisiadeirsaliye.list} component={AlisIadeIrsaliyeList} />
        <PrivateRoute exact path={URLS.evrak.alisiadeirsaliye.detail()} component={AlisIadeIrsaliyeDetail} />

        <PrivateRoute exact path={URLS.evrak.alissiparis.list} component={AlisSiparisList} />
        <PrivateRoute exact path={URLS.evrak.alissiparis.detail()} component={AlisSiparisDetail} />

        <PrivateRoute exact path={URLS.evrak.alissiparistenfatura.list} component={AlisSiparistenFaturaList} />
        <PrivateRoute exact path={URLS.evrak.alissiparistenfatura.detail()} component={AlisSiparistenFaturaDetail} />

        <PrivateRoute exact path={URLS.evrak.alissiparistenirsaliye.list} component={AlisSiparistenIrsaliyeList} />
        <PrivateRoute exact path={URLS.evrak.alissiparistenirsaliye.detail()} component={AlisSiparistenIrsaliyeDetail} />

        <PrivateRoute exact path={URLS.evrak.alisirsaliyedenfatura.list} component={AlisIrsaliyedenFaturaList} />
        <PrivateRoute exact path={URLS.evrak.alisirsaliyedenfatura.detail()} component={AlisIrsaliyedenFaturaDetail} />

        <PrivateRoute exact path={URLS.evrak.alisonsiparis.list} component={AlisOnSiparisList} />
        <PrivateRoute exact path={URLS.evrak.alisonsiparis.detail()} component={AlisOnSiparisDetail} />

        <PrivateRoute exact path={URLS.evrak.alisonsiparisonay.list} component={AlisOnSiparisOnayList} />
        <PrivateRoute exact path={URLS.evrak.alisonsiparisonay.detail()} component={AlisOnSiparisOnayDetail} />

        <PrivateRoute exact path={URLS.tanimlar.bankahesap.list} component={BankaHesapList} />
        <PrivateRoute exact path={URLS.tanimlar.bankahesap.detail()} component={BankaHesapDetail} />

        <PrivateRoute exact path={URLS.tanimlar.bilesen.list} component={BilesenList} />
        <PrivateRoute exact path={URLS.tanimlar.bilesen.detail()} component={BilesenDetail} />

        <PrivateRoute exact path={URLS.tanimlar.birim.list} component={BirimList} />
        <PrivateRoute exact path={URLS.tanimlar.birim.detail()} component={BirimDetail} />

        <PrivateRoute exact path={URLS.tanimlar.cari.list} component={CariList} />
        <PrivateRoute exact path={URLS.tanimlar.cari.detail()} component={CariDetail} />

        <PrivateRoute exact path={URLS.tanimlar.departman.list} component={DepartmanList} />
        <PrivateRoute exact path={URLS.tanimlar.departman.detail()} component={DepartmanDetail} />

        <PrivateRoute exact path={URLS.tanimlar.depo.list} component={DepoList} />
        <PrivateRoute exact path={URLS.tanimlar.depo.detail()} component={DepoDetail} />

        <PrivateRoute exact path={URLS.evrak.depocikis.list} component={DepoCikisList} />
        <PrivateRoute exact path={URLS.evrak.depocikis.detail()} component={DepoCikisDetail} />

        <PrivateRoute exact path={URLS.evrak.depogiris.list} component={DepoGirisList} />
        <PrivateRoute exact path={URLS.evrak.depogiris.detail()} component={DepoGirisDetail} />

        <PrivateRoute exact path={URLS.tanimlar.dil.list} component={DilList} />
        <PrivateRoute exact path={URLS.tanimlar.dil.detail()} component={DilDetail} />

        <PrivateRoute exact path={URLS.tanimlar.ekalan.list} component={EkAlanList} />
        <PrivateRoute exact path={URLS.tanimlar.ekalan.detail()} component={EkAlanDetail} />

        <PrivateRoute exact path={URLS.tanimlar.eklenti.list} component={EklentiList} />
        <PrivateRoute exact path={URLS.tanimlar.eklenti.detail()} component={EklentiDetail} />

        <PrivateRoute exact path={URLS.tanimlar.entegrasyon.list} component={EntegrasyonList} />
        <PrivateRoute exact path={URLS.tanimlar.entegrasyon.detail()} component={EntegrasyonDetail} />
        <PrivateRoute exact path={URLS.tanimlar.entegrasyon.urunDetail()} component={EntegrasyonUrunDetail} />
        <PrivateRoute exact path={URLS.tanimlar.entegrasyon.islemDetail()} component={EntegrasyonIslemDetail} />
        <PrivateRoute exact path={URLS.tanimlar.entegrasyon_eslestirme.list} component={EntegrasyonEslestirme} />

        <PrivateRoute exact path={URLS.tanimlar.epostaauth.list} component={EpostaAuthList} />
        <PrivateRoute exact path={URLS.tanimlar.epostaauth.detail()} component={EpostaAuthDetail} />

        <PrivateRoute exact path={URLS.evrak.evrakdurumtanim.list} component={EvrakDurumTanim} />
        <PrivateRoute exact path={URLS.evrak.evrakdurumtanim.detail()} component={EvrakDurumTanimDetail} />

        <PrivateRoute exact path={URLS.tanimlar.fiyat_tanim.list} component={FiyatTanimList} />
        <PrivateRoute exact path={URLS.tanimlar.fiyat_tanim.detail()} component={FiyatTanimDetail} />

        <PrivateRoute exact path={URLS.tanimlar.foto.list} component={FotoList} />
        <PrivateRoute exact path={URLS.tanimlar.foto.detail()} component={FotoDetail} />

        <PrivateRoute exact path={URLS.tanimlar.fotoayar.list} component={FotoAyarList} />
        <PrivateRoute exact path={URLS.tanimlar.fotoayar.detail()} component={FotoAyarDetail} />

        <PrivateRoute exact path={URLS.tanimlar.formtanim.list} component={FormTanimList} />
        <PrivateRoute exact path={URLS.tanimlar.formtanim.detail()} component={FormTanimDetail} />

        <PrivateRoute exact path={URLS.tanimlar.gmerchant.list} component={GmerchantList} />
        <PrivateRoute exact path={URLS.tanimlar.gmerchant.detail()} component={GmerchantDetail} />
        <PrivateRoute exact path={URLS.tanimlar.gmerchant.urunDetail()} component={GmerchantUrunDetail} />

        <PrivateRoute exact path={URLS.tanimlar.grup.list} component={GrupList} />
        <PrivateRoute exact path={URLS.tanimlar.grup.detail()} component={GrupDetail} />

        <PrivateRoute exact path={URLS.tanimlar.hatirlatici.list} component={HatirlaticiList} />
        <PrivateRoute exact path={URLS.tanimlar.hatirlatici.detail()} component={HatirlaticiDetail} />

        <PrivateRoute exact path={URLS.tanimlar.icerik_sablon.list} component={IcerikSablonList} />
        <PrivateRoute exact path={URLS.tanimlar.icerik_sablon.detail()} component={IcerikSablonDetail} />

        <PrivateRoute exact path={URLS.tanimlar.kategori.list} component={KategoriIndex} />
        <PrivateRoute exact path={URLS.tanimlar.kategori.detail} component={KategoriIndex} />
        <PrivateRoute exact path={URLS.tanimlar.kategoridetay.detail()} component={KategoriDetail} />

        <PrivateRoute exact path={URLS.tanimlar.kampanya.list} component={KampanyaList} />
        <PrivateRoute exact path={URLS.tanimlar.kampanya.detail()} component={KampanyaDetail} />

        <PrivateRoute exact path={URLS.tanimlar.kargo.list} component={KargoList} />
        <PrivateRoute exact path={URLS.tanimlar.kargo.detail()} component={KargoDetail} />

        <PrivateRoute exact path={URLS.tanimlar.kodseri.list} component={KodSeriList} />
        <PrivateRoute exact path={URLS.tanimlar.kodseri.detail()} component={KodSeriDetail} />

        <PrivateRoute exact path={URLS.tanimlar.kullanici_grup.list} component={KullaniciGrupList} />
        <PrivateRoute exact path={URLS.tanimlar.kullanici_grup.detail()} component={KullaniciGrupDetail} />

        <PrivateRoute exact path={URLS.tanimlar.kullanici_odeme_log.list} component={KullaniciOdemeLogList} />
        <PrivateRoute exact path={URLS.tanimlar.kullanici_odeme_log.detail()} component={KullaniciOdemeLogDetail} />

        <PrivateRoute exact path={URLS.tanimlar.kurlar.list} component={KurList} />
        <PrivateRoute exact path={URLS.tanimlar.kurlar.detail()} component={KurDetail} />

        <PrivateRoute exact path={URLS.tanimlar.magaza.list} component={MagazaList} />
        <PrivateRoute exact path={URLS.tanimlar.magaza.detail()} component={MagazaDetail} />

        <PrivateRoute exact path={URLS.tanimlar.menu.list} component={MenuList} />
        <PrivateRoute exact path={URLS.tanimlar.menu.detail()} component={MenuDetail} />

        <PrivateRoute exact path={URLS.tanimlar.mesaj.list} component={MesajList} />
        <PrivateRoute exact path={URLS.tanimlar.mesaj.detail()} component={MesajDetail} />

        <PrivateRoute exact path={URLS.tanimlar.odeme_servis.list} component={OdemeServisList} />
        <PrivateRoute exact path={URLS.tanimlar.odeme_servis.detail()} component={OdemeServisDetail} />

        <PrivateRoute exact path={URLS.tanimlar.odeme_set.list} component={OdemeSetList} />
        <PrivateRoute exact path={URLS.tanimlar.odeme_set.detail()} component={OdemeSetDetail} />

        <PrivateRoute exact path={URLS.tanimlar.odeme_tanim.list} component={OdemeTanimList} />
        <PrivateRoute exact path={URLS.tanimlar.odeme_tanim.detail()} component={OdemeTanimDetail} />

        <PrivateRoute exact path={URLS.evrak.odeme.list} component={OdemeList} />
        <PrivateRoute exact path={URLS.evrak.odeme.detail()} component={OdemeDetail} />

        <PrivateRoute exact path={URLS.tanimlar.ozellik.list} component={OzellikIndex} />
        <PrivateRoute exact path={URLS.tanimlar.ozellik.detail} component={OzellikIndex} />
        <PrivateRoute exact path={URLS.tanimlar.ozellikdetay.detail()} component={OzellikDetail} />

        <PrivateRoute exact path={URLS.tanimlar.para_birimleri.list} component={ParaBirimList} />
        <PrivateRoute exact path={URLS.tanimlar.para_birimleri.detail()} component={ParaBirimDetail} />

        <PrivateRoute exact path={URLS.tanimlar.redirect.list} component={RedirectList} />
        <PrivateRoute exact path={URLS.tanimlar.redirect.detail()} component={RedirectDetail} />

        <PrivateRoute exact path={URLS.tanimlar.sabit_sayfa.list} component={SabitSayfaList} />
        <PrivateRoute exact path={URLS.tanimlar.sabit_sayfa.detail()} component={SabitSayfaDetail} />

        <PrivateRoute exact path={URLS.tanimlar.sablon_paket.list} component={SablonPaketList} />
        <PrivateRoute exact path={URLS.tanimlar.sablon_paket.detail()} component={SablonPaketDetail} />

        <PrivateRoute exact path={URLS.tanimlar.satisbirimgrup.list} component={SatisBirimGrupList} />
        <PrivateRoute exact path={URLS.tanimlar.satisbirimgrup.detail()} component={SatisBirimGrupDetail} />

        <PrivateRoute exact path={URLS.evrak.satisirsaliye.list} component={SatisIrsaliyeList} />
        <PrivateRoute exact path={URLS.evrak.satisirsaliye.detail()} component={SatisIrsaliyeDetail} />

        <PrivateRoute exact path={URLS.evrak.satisiadeirsaliye.list} component={SatisIadeIrsaliyeList} />
        <PrivateRoute exact path={URLS.evrak.satisiadeirsaliye.detail()} component={SatisIadeIrsaliyeDetail} />

        <PrivateRoute exact path={URLS.evrak.satisfatura.list} component={SatisFaturaList} />
        <PrivateRoute exact path={URLS.evrak.satisfatura.detail()} component={SatisFaturaDetail} />

        <PrivateRoute exact path={URLS.evrak.satisiadefatura.list} component={SatisIadeFaturaList} />
        <PrivateRoute exact path={URLS.evrak.satisiadefatura.detail()} component={SatisIadeFaturaDetail} />

        <PrivateRoute exact path={URLS.evrak.satissiparis.list} component={SatisSiparisList} />
        <PrivateRoute exact path={URLS.evrak.satissiparis.detail()} component={SatisSiparisDetail} />

        <PrivateRoute exact path={URLS.evrak.satissiparistensevkemri.list} component={SatisSiparistenSevkEmriList} />
        <PrivateRoute exact path={URLS.evrak.satissiparistensevkemri.detail()} component={SatisSiparistenSevkEmriDetail} />

        <PrivateRoute exact path={URLS.evrak.satissiparistenfatura.list} component={SatisSiparistenFaturaList} />
        <PrivateRoute exact path={URLS.evrak.satissiparistenfatura.detail()} component={SatisSiparistenFaturaDetail} />

        <PrivateRoute exact path={URLS.evrak.satissiparistenirsaliye.list} component={SatisSiparistenIrsaliyeList} />
        <PrivateRoute exact path={URLS.evrak.satissiparistenirsaliye.detail()} component={SatisSiparistenIrsaliyeDetail} />

        <PrivateRoute exact path={URLS.evrak.satisirsaliyedenfatura.list} component={SatisIrsaliyesindenFaturaList} />
        <PrivateRoute exact path={URLS.evrak.satisirsaliyedenfatura.detail()} component={SatisIrsaliyesindenFaturaDetail} />

        <PrivateRoute exact path={URLS.evrak.satisonsiparis.list} component={SatisOnSiparisList} />
        <PrivateRoute exact path={URLS.evrak.satisonsiparis.detail()} component={SatisOnSiparisDetail} />

        <PrivateRoute exact path={URLS.evrak.satisonsiparisonay.list} component={SatisOnSiparisOnayList} />
        <PrivateRoute exact path={URLS.evrak.satisonsiparisonay.detail()} component={SatisOnSiparisOnayDetail} />

        <PrivateRoute exact path={URLS.evrak.sevkemri.list} component={SevkEmriList} />
        <PrivateRoute exact path={URLS.evrak.sevkemri.detail()} component={SevkemriDetail} />

        <PrivateRoute exact path={URLS.evrak.sevkemrindensatisfatura.list} component={SevkEmrindenSatisFaturaList} />
        <PrivateRoute exact path={URLS.evrak.sevkemrindensatisfatura.detail()} component={SevkEmrindenSatisFaturaDetail} />

        <PrivateRoute exact path={URLS.evrak.sevkemrindensatisirsaliye.list} component={SevkEmrindenSatisIrsaliyeList} />
        <PrivateRoute exact path={URLS.evrak.sevkemrindensatisirsaliye.detail()} component={SevkEmrindenSatisIrsaliyeDetail} />

        <PrivateRoute exact path={URLS.tanimlar.site.list} component={SiteList} />
        <PrivateRoute exact path={URLS.tanimlar.site.detail()} component={SiteDetail} />

        <PrivateRoute exact path={URLS.tanimlar.sozlesme.list} component={SozlesmeList} />
        <PrivateRoute exact path={URLS.tanimlar.sozlesme.detail()} component={SozlesmeDetail} />

        <PrivateRoute exact path={URLS.evrak.tahsilat.list} component={TahsilatList} />
        <PrivateRoute exact path={URLS.evrak.tahsilat.detail()} component={TahsilatDetail} />

        <PrivateRoute exact path={URLS.tanimlar.ulkesehirilce.list} component={UlkeIndex} />
        <PrivateRoute exact path={URLS.tanimlar.ulkesehirilce.detail} component={UlkeIndex} />
        <PrivateRoute exact path={URLS.tanimlar.ulkesehirilcedetay.detail()} component={UlkeDetail} />

        <PrivateRoute exact path={URLS.tanimlar.urun.list} component={UrunList} />
        <PrivateRoute exact path={URLS.tanimlar.urun.detail()} component={UrunDetail} />

        <PrivateRoute exact path={URLS.tanimlar.urunlisttanim.list} component={UrunListTanimList} />
        <PrivateRoute exact path={URLS.tanimlar.urunlisttanim.detail()} component={UrunListTanimDetail} />

        <PrivateRoute exact path={URLS.tanimlar.uye.list} component={UyeList} />
        <PrivateRoute exact path={URLS.tanimlar.uye.detail()} component={UyeDetail} />

        <PrivateRoute exact path={URLS.tanimlar.varyant.list} component={VaryantList} />
        <PrivateRoute exact path={URLS.tanimlar.varyant.detail()} component={VaryantDetail} />

        <PrivateRoute exact path={URLS.tanimlar.vergiler.list} component={VergiIndex} />
        <PrivateRoute exact path={URLS.tanimlar.vergiler.detail} component={VergiIndex} />
        <PrivateRoute exact path={URLS.tanimlar.vergidetay.detail()} component={VergiDetail} />

        <PrivateRoute exact path={URLS.tanimlar.yonetici.list} component={YoneticiList} />
        <PrivateRoute exact path={URLS.tanimlar.yonetici.detail()} component={YoneticiDetail} />

        {/* <PrivateRoute exact path={URLS.tanimlar.ulke.list} component={UlkeIndex} />
        <PrivateRoute exact path={URLS.tanimlar.sehir.list()} component={UlkeIndex} />
        <PrivateRoute exact path={URLS.tanimlar.ilce.list()} component={UlkeIndex} /> */}

      </DashboardBase>

      <Route component={NotFound} />

    </Switch>
  );
}
