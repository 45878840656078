import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import moment from 'moment';

import {
  Button,
  TextField,
  Typography,
  Checkbox,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import {
  HelpOutline as IconHelp,
  Add as IconAdd,
  Delete as IconDelete,
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 1, 1, 4),
    },
    tabDiv2: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 1, 1, 1),
    },
    column: {
      display: 'flex',
      '& + &': {
        marginTop: 32,
      },

      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },

    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },

    formItem: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: 0,
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    tablehead: {
      display: 'flex',
      borderBottom: '1px solid #976797',
      marginBottom: spacing(0.5)
    },
    tablebody: {
      display: 'flex',
      flexDirection: 'column',
    },
    tablerow: {
      display: 'flex',
      padding: spacing(0.5, 1, 0.5, 1),
      alignItems: "center"
    },
    tablecell: {
      display: 'flex',
      padding: spacing(0, 0.5, 0, 0.5),
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    }

  };
};

const FormEleman = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATADIL = useMemo(() => props.hataDil, [props.hataDil]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const [tabsDil, setTabsDil] = useState([]);
  const [selectedTabIndexDil, setSelectedTabIndexDil] = useState(props.selectedTabIndexDil);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);


  const COUNT_TABS_DİL = useMemo(() => props?.dilform.length ?? 0, [props]);
  const FORM_DIL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.form ?? null, [props, selectedTabIndex, selectedTabIndexDil]);
  const ERROR_DIL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.errors ?? null, [props, selectedTabIndex, selectedTabIndexDil]);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  const FORM_SECİM = useMemo(() => props?.secimler?.[selectedTabIndex] ?? null, [props, selectedTabIndex]);


  useLayoutEffect(() => {
    if (props.dilform?.length > 0) {
      const copyForms = [...props.dilform];
      const newTabs = copyForms[selectedTabIndex]?.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabsDil(newTabs);
    } else {
      setTabsDil([]);
    }

  }, [props.dilform, selectedTabIndex]);


  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((eleman) => {
        return {
          label: eleman.form.tanim,
          hasError: eleman.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);

  useLayoutEffect(() => {
    if (!props.elemanAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.elemanAdd, COUNT_TABS]);

  const SetDogrulama = useCallback((index) => {
    let text = ["", "", ""];
    switch (FORM.tip) {
      case "0":
      case "3":
      case "4":
        text = ["Minimum Harf Sayısı *", "Maksimum Harf Sayısı *", "Varsayılan Yazı *"]
        break;
      case "1":
        text = ["Minimum Değer *", "Maksimum Değer *", "Varsayılan Değer *"]
        break;
      case "5":
        text = ["Minimum Tarih Değeri *", "Maksimum Tarih Değeri *", "Varsayılan Tarih Değeri *"]
        break;
      case "6":
        text = ["Minimum Tarih Saat Değeri *", "Maksimum Tarih Saat Değeri *", "Varsayılan Tarih Saat Değeri *"]
        break;
      case "7":
        text = ["Minimum Saat Değeri *", "Maksimum Saat Değeri *", "Varsayılan Saat Değeri *"]
        break;
      default:
        break;
    }
    return text[index];

  }, [FORM]);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(FORM?.[name], `${name2}`) || defaultValue,
        error: _has(ERROR?.[name], `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(FORM, `${name}`) || defaultValue,
        error: _has(ERROR, `${name}`),
        fullWidth: true,
      };
    }

  }, [FORM, ERROR]);


  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM_DIL, `${name}`) || defaultValue,
      error: _has(ERROR_DIL, `${name}`),
      fullWidth: true,
    };
  }, [FORM_DIL, ERROR_DIL]);

  const handleChangeForm = (property, value, property2 = "") => {
    props.onChangeForm(selectedTabIndex, property, value, property2);
  };

  const handleChangeFormDil = (property, value) => {
    props.onChangeDilForm(selectedTabIndex, selectedTabIndexDil, property, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }

  const handleSetErrorsDil = (errors) => {
    props.onCheckErrorsDil(errors, selectedTabIndex, selectedTabIndexDil);
  }

  const handleChangeFormSecim = (index, index2, value) => {
    props.onChangeSecimForm(selectedTabIndex, index, index2, value);
  };

  const handleDilDelete = (index) => {
    props.deleteDil(selectedTabIndex, index);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <LayoutDetailVerticalTab
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                  add={props.onAddNewTab}
                  buttonText={"YENİ ELEMAN EKLE"}
                  buttonShow={props.elemanAdd}
                  delete={handleDetailDelete}
                  flexDirection='column'
                >
                  {COUNT_TABS === 0 && (
                    <div className="flex flex-col items-start justify-start p-4 space-y-4">
                      <Typography variant="body1">
                        Form tanımına ait eleman bulunamadı.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disableElevation
                        onClick={props.onAddNewTab}
                      >YENİ ELEMAN EKLE</Button>
                    </div>
                  )}
                  {COUNT_TABS > 0 &&
                    <>
                      <div className={classes.tabDiv}>
                        <div className={classes.column}>
                          <ul className={classes.formList}>

                            <li className={classes.formItem} id="tanim">
                              <label className={classes.formItemLabel} htmlFor="tanim">Eleman Tanım *</label>
                              <Tooltip title={ERROR?.tanim ? ERROR.tanim[0] : _isNil(FORM?.tanim) ? '' : FORM.tanim}>
                                <TextField
                                  {...createTextFieldProps('tanim', '')}
                                  onChange={(e) => handleChangeForm('tanim', e.target.value)}
                                  onBlur={() => handleInputErrors2(FORM, 'tanim', handleSetErrors)}
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="tip">
                              <label className={classes.formItemLabel} htmlFor="tip">Tip *</label>
                              <Tooltip title={ERROR?.tip ? ERROR.tip[0] : _isNil(FORM?.tip) ? '' : props.tipDic ? props.tipDic[FORM.tip] : ''}>
                                <TextField
                                  {...createTextFieldProps('tip', '')}
                                  onChange={(e) => handleChangeForm('tip', e.target.value)}
                                  onBlur={() => handleInputErrors2(FORM, 'tip', handleSetErrors)}
                                  select
                                  SelectProps={{ native: true }}
                                >
                                  {props?.tip.map((tip) => (
                                    <option key={tip.key} value={tip.key}>{tip.value}</option>
                                  ))}
                                </TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="siralama">
                              <label className={classes.formItemLabel} htmlFor="siralama">Sıralama</label>
                              <Tooltip title={ERROR?.siralama ? ERROR.siralama[0] : _isNil(FORM?.siralama) ? '' : FORM.siralama}>
                                <TextField
                                  {...createTextFieldProps('siralama', '')}
                                  onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                                  type="number"
                                  inputProps={{
                                    min: -2147483648,
                                    max: 2147483647,
                                    step: 1,
                                    style: { textAlign: 'right' }
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>

                            <li className={classes.formItem} id="zorunlu">
                              <label className={classes.formItemLabel} htmlFor="zorunlu">Zorunlu Alan</label>
                              <Checkbox
                                name="zorunlu"
                                color="primary"
                                checked={_result(FORM, 'zorunlu', false)}
                                onChange={(e, checked) => handleChangeForm('zorunlu', checked)}
                              ></Checkbox>
                              <IconButton
                                size="small"
                                className={classes.button2}
                                disableRipple
                                onClick={() => {
                                  setHelpText('“Bu alanın bu form için zorunlu bilgi olup olmadığını belirler, zorunlu ise kullanıcı doldurmadan, form kaydedilmez.”');
                                  setHelpOpen(true)
                                }}
                              ><IconHelp /></IconButton>
                            </li>

                            <li className={classes.formItem} id="secim_oto_tamamlamali_calissin">
                              <label className={classes.formItemLabel} htmlFor="secim_oto_tamamlamali_calissin">Otomatik Tamamlama</label>
                              <Checkbox
                                name="secim_oto_tamamlamali_calissin"
                                color="primary"
                                checked={_result(FORM, 'secim_oto_tamamlamali_calissin', false)}
                                onChange={(e, checked) => handleChangeForm('secim_oto_tamamlamali_calissin', checked)}
                              ></Checkbox>
                              <IconButton
                                size="small"
                                className={classes.button2}
                                disableRipple
                                onClick={() => {
                                  setHelpText('“Seçim kutusu yerine, oto tamamlama kutusu ile çalışması istendiğinde bu alan seçilebilir, çok veri olan modeller için ideal çözümdür.”');
                                  setHelpOpen(true)
                                }}
                              ><IconHelp /></IconButton>
                            </li>


                            <li className={classes.formItem} id="aktif">
                              <label className={classes.formItemLabel} htmlFor="aktif">Aktif</label>
                              <Checkbox
                                name="aktif"
                                color="primary"
                                checked={_result(FORM, 'aktif', false)}
                                onChange={(e, checked) => handleChangeForm('aktif', checked)}
                              ></Checkbox>
                            </li>
                          </ul>

                          <ul className={classes.formList}>
                            {FORM && FORM.tip !== "2" && (
                              <>
                                {(FORM.tip === "0" || FORM.tip === "1" || FORM.tip === "3" || FORM.tip === "4") && (
                                  <>
                                    <li className={classes.formItem} id="dogrulama">
                                      <label className={classes.formItemLabel} htmlFor="dogrulama">{SetDogrulama(0)}</label>
                                      <Tooltip title={ERROR?.dogrulama?.min_val ? ERROR.dogrulama.min_val[0] : _isNil(FORM?.dogrulama?.min_val) ? '' : FORM.dogrulama.min_val}>
                                        <TextField
                                          {...createTextFieldProps('dogrulama', 'min_val')}
                                          onChange={(e) => handleChangeForm('dogrulama', e.target.value, 'min_val')}
                                          onBlur={() => handleInputErrors2(FORM, 'dogrulama', handleSetErrors, 'min_val')}
                                          type="number"
                                          inputProps={{
                                            step: 1,
                                            style: { textAlign: 'right' }
                                          }}
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                    <li className={classes.formItem} id="dogrulama">
                                      <label className={classes.formItemLabel} htmlFor="dogrulama">{SetDogrulama(1)}</label>
                                      <Tooltip title={ERROR?.dogrulama?.max_val ? ERROR.dogrulama.max_val[0] : _isNil(FORM?.dogrulama?.max_val) ? '' : FORM.dogrulama.max_val}>
                                        <TextField
                                          {...createTextFieldProps('dogrulama', 'max_val')}
                                          onChange={(e) => handleChangeForm('dogrulama', e.target.value, 'max_val')}
                                          onBlur={() => handleInputErrors2(FORM, 'dogrulama', handleSetErrors, 'max_val')}
                                          type="number"
                                          inputProps={{
                                            step: 1,
                                            style: { textAlign: 'right' }
                                          }}
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                  </>
                                )}
                                {FORM.tip === "5" && (
                                  <>
                                    <li className={classes.formItem} id="dogrulama">
                                      <label className={classes.formItemLabel} htmlFor="dogrulama">{SetDogrulama(0)}</label>
                                      <Tooltip title={ERROR?.dogrulama?.min_val ? ERROR.dogrulama.min_val[0] : _isNil(FORM?.dogrulama?.min_val) ? '' : moment(FORM.dogrulama.min_val).format('DD/MM/YYYY')}>
                                        <TextField
                                          {...createTextFieldProps('dogrulama', 'min_val')}
                                          onChange={(e) => handleChangeForm('dogrulama', e.target.value, 'min_val')}
                                          onBlur={() => handleInputErrors2(FORM, 'dogrulama', handleSetErrors, 'min_val')}
                                          type='date'
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                    <li className={classes.formItem} id="dogrulama">
                                      <label className={classes.formItemLabel} htmlFor="dogrulama">{SetDogrulama(1)}</label>
                                      <Tooltip title={ERROR?.dogrulama?.max_val ? ERROR.dogrulama.max_val[0] : _isNil(FORM?.dogrulama?.max_val) ? '' : moment(FORM.dogrulama.max_val).format('DD/MM/YYYY')}>
                                        <TextField
                                          {...createTextFieldProps('dogrulama', 'max_val')}
                                          onChange={(e) => handleChangeForm('dogrulama', e.target.value, 'max_val')}
                                          onBlur={() => handleInputErrors2(FORM, 'dogrulama', handleSetErrors, 'max_val')}
                                          type='date'
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                  </>
                                )}
                                {FORM.tip === "6" && (
                                  <>
                                    <li className={classes.formItem} id="dogrulama">
                                      <label className={classes.formItemLabel} htmlFor="dogrulama">{SetDogrulama(0)}</label>
                                      <Tooltip title={ERROR?.dogrulama?.min_val ? ERROR.dogrulama.min_val[0] : _isNil(FORM?.dogrulama?.min_val) ? '' : moment(FORM.dogrulama.min_val).format('DD/MM/YYYY, HH.mm')}>
                                        <TextField
                                          {...createTextFieldProps('dogrulama', 'min_val')}
                                          onChange={(e) => handleChangeForm('dogrulama', e.target.value, 'min_val')}
                                          onBlur={() => handleInputErrors2(FORM, 'dogrulama', handleSetErrors, 'min_val')}
                                          type="datetime-local"
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                    <li className={classes.formItem} id="dogrulama">
                                      <label className={classes.formItemLabel} htmlFor="dogrulama">{SetDogrulama(1)}</label>
                                      <Tooltip title={ERROR?.dogrulama?.max_val ? ERROR.dogrulama.max_val[0] : _isNil(FORM?.dogrulama?.max_val) ? '' : moment(FORM.dogrulama.max_val).format('DD/MM/YYYY, HH.mm')}>
                                        <TextField
                                          {...createTextFieldProps('dogrulama', 'max_val')}
                                          onChange={(e) => handleChangeForm('dogrulama', e.target.value, 'max_val')}
                                          onBlur={() => handleInputErrors2(FORM, 'dogrulama', handleSetErrors, 'max_val')}
                                          type="datetime-local"
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                  </>
                                )}
                                {FORM.tip === "7" && (
                                  <>
                                    <li className={classes.formItem} id="dogrulama">
                                      <label className={classes.formItemLabel} htmlFor="dogrulama">{SetDogrulama(0)}</label>
                                      <Tooltip title={ERROR?.dogrulama?.min_val ? ERROR.dogrulama.min_val[0] : _isNil(FORM?.dogrulama?.min_val) ? '' : moment(FORM.dogrulama.min_val).format('HH/MM')}>
                                        <TextField
                                          {...createTextFieldProps('dogrulama', 'min_val')}
                                          onChange={(e) => handleChangeForm('dogrulama', e.target.value, 'min_val')}
                                          onBlur={() => handleInputErrors2(FORM, 'dogrulama', handleSetErrors, 'min_val')}
                                          type="time"
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                    <li className={classes.formItem} id="dogrulama">
                                      <label className={classes.formItemLabel} htmlFor="dogrulama">{SetDogrulama(1)}</label>
                                      <Tooltip title={ERROR?.dogrulama?.max_val ? ERROR.dogrulama.max_val[0] : _isNil(FORM?.dogrulama?.max_val) ? '' : moment(FORM.dogrulama.max_val).format('HH/MM')}>
                                        <TextField
                                          {...createTextFieldProps('dogrulama', 'max_val')}
                                          onChange={(e) => handleChangeForm('dogrulama', e.target.value, 'max_val')}
                                          onBlur={() => handleInputErrors2(FORM, 'dogrulama', handleSetErrors, 'max_val')}
                                          type="time"
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                  </>
                                )}
                                <li className={classes.formItem} id="dogrulama">
                                  <label className={classes.formItemLabel} htmlFor="dogrulama">{SetDogrulama(2)}</label>
                                  <Tooltip title={ERROR?.dogrulama?.default ? ERROR.dogrulama.default[0] : _isNil(FORM?.dogrulama?.default) ? '' : FORM.dogrulama.default}>
                                    <TextField
                                      {...createTextFieldProps('dogrulama', 'default')}
                                      onChange={(e) => handleChangeForm('dogrulama', e.target.value, 'default')}
                                      onBlur={() => handleInputErrors2(FORM, 'dogrulama', handleSetErrors, 'default')}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                              </>
                            )}


                            {FORM && FORM.tip === "2" && (
                              <li className={classes.formItem} id="secim_modeli">
                                <label className={classes.formItemLabel} htmlFor="secim_modeli">Veri Modeli</label>
                                <Tooltip title={ERROR?.secim_modeli ? ERROR.secim_modeli[0] : (FORM?.secim_modeli === "-1" || _isNil(FORM?.secim_modeli)) ? '' : props.secimModelDic ? props.secimModelDic[FORM.secim_modeli] : ''}>
                                  <TextField
                                    {...createTextFieldProps('secim_modeli', '')}
                                    onChange={(e) => handleChangeForm('secim_modeli', e.target.value)}
                                    select
                                    SelectProps={{ native: true }}
                                  >
                                    <option key="-1" value="-1">Seçilmedi</option>
                                    {props?.secimModel.map((tip) => (
                                      <option key={tip.key} value={tip.key}>{tip.value}</option>
                                    ))}
                                  </TextField>
                                </Tooltip>
                              </li>
                            )}
                          </ul>

                        </div>
                        {FORM && FORM.tip === "2" && (
                          <div className={classes.column} style={{ width: "100%" }}>
                            <div className={classes.table}>
                              <div className={classes.tablehead}>
                                <div className={classes.tablerow}>
                                  <div className={classes.tablecell}>
                                    <Tooltip title="Seçenek Ekle">
                                      <IconButton
                                        size="small"
                                        disableRipple
                                        onClick={() => props.onAddNewSecim(selectedTabIndex)}
                                      >
                                        <IconAdd />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                  <div className={classes.tablecell}>Seçenek Değer</div>
                                </div>
                              </div>
                              <div className={classes.tablebody}>
                                {FORM_SECİM && FORM_SECİM.map((secim, index) => (
                                  <div className={classes.tablerow} key={`secim-${index}`}>
                                    <div className={classes.tablecell}>
                                      <Tooltip title="Sil">
                                        <IconButton
                                          size="small"
                                          disableRipple
                                          onClick={() => props.onRemoveSecim(selectedTabIndex, index)}
                                        >
                                          <IconDelete />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                    <div className={classes.tablecell}>
                                      <Tooltip title={_isNil(secim?.deger) ? "" : secim.deger}>
                                        <TextField
                                          variant="outlined"
                                          size="small"
                                          fullWidth={true}
                                          value={secim.deger}
                                          onChange={(e) => handleChangeFormSecim(index, "", e.target.value)}
                                        >
                                        </TextField>
                                      </Tooltip>
                                    </div>
                                    {secim.diller.map((dil, index2) =>
                                      <div key={`secenek-${index}-${index2}`} className={classes.tablecell}>
                                        <Tooltip title={_isNil(dil?.form?.deger) ? "" : dil.form.deger}>
                                          <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth={true}
                                            value={dil.form.deger}
                                            onChange={(e) => handleChangeFormSecim(index, index2, e.target.value)}
                                            label={dil.dil.tanim}
                                            InputLabelProps={{ shrink: true }}
                                          ></TextField>
                                        </Tooltip>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}

                      </div>

                      <div className={classes.layoutContainerTab}>
                        <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                        <>
                          {HATADIL && (
                            <Hata />
                          )}
                          {!HATADIL && (
                            <LayoutDetailVerticalTab
                              tabs={tabsDil}
                              selectedTabIndex={selectedTabIndexDil}
                              onChangeTabIndex={(index) => setSelectedTabIndexDil(index)}
                              buttonShow={false}
                              delete={handleDilDelete}
                              deleteButtonShow={true}
                            >
                              {COUNT_TABS_DİL === 0 && (
                                <div className="flex flex-col items-start justify-start p-4 space-y-4">
                                  <Typography variant="body1">
                                    Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                                </div>
                              )}
                              {COUNT_TABS_DİL > 0 && (
                                <div className={classes.tabDiv2}>
                                  <ul className={classes.formList}>
                                    <li className={classes.formItem} id="baslik">
                                      <label className={classes.formItemLabel} htmlFor="baslik">Başlık</label>
                                      <Tooltip title={ERROR_DIL?.baslik ? ERROR_DIL.baslik[0] : _isNil(FORM_DIL?.baslik) ? '' : FORM_DIL.baslik}>
                                        <TextField
                                          {...createTextFieldProps2('baslik')}
                                          onChange={(e) => handleChangeFormDil('baslik', e.target.value)}
                                          onBlur={() => handleInputErrors2(FORM_DIL, 'baslik', handleSetErrorsDil)}
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                    <li className={classes.formItem} id="yardim">
                                      <label className={classes.formItemLabel} htmlFor="yardim">Yardım</label>
                                      <Tooltip title={ERROR_DIL?.yardim ? ERROR_DIL.yardim[0] : _isNil(FORM_DIL?.yardim) ? '' : FORM_DIL.yardim}>
                                        <TextField
                                          {...createTextFieldProps2('yardim')}
                                          onChange={(e) => handleChangeFormDil('yardim', e.target.value)}
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                  </ul>
                                </div>
                              )
                              }
                            </LayoutDetailVerticalTab>
                          )}
                        </>
                      </div>
                    </>
                  }
                </LayoutDetailVerticalTab>
              </div>
              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default FormEleman;
