/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useCallback } from 'react';

import {
  result as _result,
} from 'lodash';

import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton
} from '@material-ui/core';

import {
  Delete as IconDelete
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import ListSelector from '../../../components/ListSelector';
import CategorySelector from '../../../components/CategorySelector';
import { Hata } from '../../base/Hata';
import MultipleSelectList from '../../../components/MultipleSelectList';
import ListSelectorTwoStep from '../../../components/ListSelectorTwoStep';

const styles = ({ spacing, palette }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1, 1, 1, 1),
    },
    container: {
      position: 'relative',
      width: 'fit-content',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      gap: spacing(4),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      padding: spacing(1, 1, 1, 1),
      gap: spacing(2),
      border: `1px solid ${palette.divider}`,
      borderRadius: 4
    },
    layoutFormListHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      flexDirection: 'row',
    },
    layoutFormListHeaderItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      gap: spacing(1),
      minWidth: 620,
      maxWidth: 620,
    },
    layoutFormListHeaderLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormListDetail: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: spacing(2),
    },
    layoutFormListDetailBoxList: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: spacing(4),
      alignItems: 'flex-start',
    },
    layoutFormListDetailBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      borderTop: `1px solid ${palette.divider}`,
      alignItems: 'center',
      padding: spacing(2, 0, 2, 0),
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    treeList: {
      marginTop: 10,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    list: {
      marginBottom: 10
    },
    title: {
      position: 'relative',
      display: 'inline-block',
      color: palette.primary.main,
      fontSize: 15,
      fontWeight: '500'
    },
    ul: {
      position: 'relative',
      paddingLeft: '3em',
      '&::before': {
        position: 'absolute',
        top: 0,
        bottom: 7,
        left: '3em',
        display: 'block',
        width: 0,
        borderLeft: '2px solid #eacfea',
        content: '""',
      }
    },
    li: {
      padding: '15px 0 0 40px',
      position: 'relative',
      lineHeight: '15px',
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        height: 25,
        width: 30,
        borderBottom: '2px solid #eacfea',
        borderLeft: '2px solid #eacfea',
        borderRadius: '0 0 0 5px',
        content: '""',
      }
    },
  };
};

const KampanyaKosul = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [selectedTabIndex, setselectedTabIndex] = useState(null);
  const [selectedTabIndex2, setselectedTabIndex2] = useState(null);
  const [openSelectorGrup, setOpenSelectorGrup] = useState(false);
  const [openSelectorKullaniciGrup, setOpenSelectorKullaniciGrup] = useState(false);
  const [openSelectorKategori, setOpenSelectorKategori] = useState(false);
  const [openSelectorUrun, setOpenSelectorUrun] = useState(false);
  const [openSelectorOzellik, setOpenSelectorOzellik] = useState(false);
  const [detayListOpen, setDetayListOpen] = useState(true);

  const createTextFieldProps = useCallback((item, name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(item, `${name}`) || defaultValue,
      fullWidth: true,
    };
  }, []);

  const handleDetailDeleteKosulGrup = (index) => {
    const id = props.forms[index]?.id;
    if (id) {
      props.openDeleteFormGrup(true);
      props.setDeleteIdDetailIdGrup(id);
    } else {
      props.openDeleteFormGrupWithoutId(index);
    }
  }
  const handleDetailDeleteKosulGrupKosul = (index, index2) => {
    const id = props.forms[index].kosullar[index2].form?.id;
    if (id) {
      props.openDeleteFormKosul(true);
      props.setDeleteIdDetailIdKosul(id);
    } else {
      props.openDeleteFormKosulWithoutId(index, index2);
    }
  }

  const handleRemoveGrupById = (index, index2, id) => {
    const copymagazalar = [...props.forms[index].kosullar[index2].form.gruplar];
    const indexD = copymagazalar.findIndex((c) => c.id === id);
    if (indexD > -1) {
      copymagazalar.splice(indexD, 1);
      props.onChangeForm(index, index2, 'gruplar', copymagazalar);
    }
  }

  const handleRemoveKullaniciKullaniciGrupById = (index, index2, id) => {
    const copygruplar = [...props.forms[index].kosullar[index2].form.kullanici_gruplari];
    const indexD = copygruplar.findIndex((c) => c.id === id);
    if (indexD > -1) {
      copygruplar.splice(indexD, 1);
      props.onChangeForm(index, index2, 'kullanici_gruplari', copygruplar);
    }
  }

  const handleRemoveKullaniciKategoriById = (index, index2, id) => {
    const copygruplar = [...props.forms[index].kosullar[index2].form.kategoriler];
    const indexD = copygruplar.findIndex((c) => c.id === id);
    if (indexD > -1) {
      copygruplar.splice(indexD, 1);
      props.onChangeForm(index, index2, 'kategoriler', copygruplar);
    }
  }

  const handleRemoveUrunById = (index, index2, id) => {
    const copygruplar = [...props.forms[index].kosullar[index2].form.urunler];
    const indexD = copygruplar.findIndex((c) => c.id === id);
    if (indexD > -1) {
      copygruplar.splice(indexD, 1);
      props.onChangeForm(index, index2, 'urunler', copygruplar);
    }
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <div className={classes.container}>
                  {props.forms.length > 0 && (
                    <>
                      {props.forms.map((grup, index) => (
                        <div key={`kosulgrup-${index}`} className={classes.layoutFormList}>

                          <div className={classes.layoutFormListHeader}>
                            {props.forms.length > 1 &&
                              <span>
                                <Tooltip title="Koşul Grubunu Sil">
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    onClick={() => handleDetailDeleteKosulGrup(index)}
                                  >
                                    <IconDelete />
                                  </IconButton>
                                </Tooltip>
                              </span>
                            }
                            <div className={classes.layoutFormListHeaderItem}>
                              <span className={classes.layoutFormListHeaderLabel}>Aşağıdaki koşulların</span>
                              <Tooltip title={grup?.operator === "0" ? "Hepsi" : "Herhangi biri"}>
                                <TextField
                                  {...createTextFieldProps(grup, 'operator')}
                                  onChange={(e) => props.onChangeForm(index, null, 'operator', e.target.value)}
                                  select
                                  SelectProps={{ native: true }}
                                >
                                  <option key="0" value="0">Hepsi</option>
                                  <option key="1" value="1">Herhangi biri</option>
                                </TextField>
                              </Tooltip>

                              <Tooltip title={grup?.t_or_f === "true" ? 'Sağlandığında' : 'Sağlanmadığında'}>
                                <TextField
                                  {...createTextFieldProps(grup, 't_or_f')}
                                  onChange={(e) => props.onChangeForm(index, null, 't_or_f', e.target.value)}
                                  select
                                  SelectProps={{ native: true }}
                                >
                                  <option key="1" value="true">Sağlandığında</option>
                                  <option key="2" value="false">Sağlanmadığında</option>
                                </TextField>
                              </Tooltip>
                            </div>
                          </div>

                          <div className={classes.layoutFormListDetail}>
                            {grup.kosullar.length > 0 && (
                              <>
                                {grup.kosullar.map((kosul, index2) => (
                                  <div className={classes.layoutFormListDetailBox} key={`kosulgrup-${index}-kosul-${index2}`}>
                                    {/* <span>
                                      <Tooltip title="Koşulu Sil">
                                        <IconButton
                                          size="small"
                                          disableRipple
                                          onClick={() => handleDetailDeleteKosulGrupKosul(index, index2)}
                                        >
                                          <IconDelete />
                                        </IconButton>
                                      </Tooltip>
                                    </span> */}
                                    <div className={classes.layoutFormListDetailBoxList}>
                                      <div className={classes.layoutFormItemBox}>
                                        <MultipleSelectList
                                          headername="ÜRÜN GRUPLARI"
                                          name="Ürün Grupları"
                                          name2="Ürün Grubu"
                                          form={kosul.form}
                                          list={kosul.form?.gruplar ?? []}
                                          listlength={kosul.form?.gruplar?.length ?? 0}
                                          opendialog={(res) => {
                                            setselectedTabIndex(index);
                                            setselectedTabIndex2(index2);
                                            setOpenSelectorGrup(res);
                                          }}
                                          keyProp="id"
                                          valueProp="tanim"
                                          removefonk={(id) => handleRemoveGrupById(index, index2, id)}
                                          loading={props.loading}
                                          error={kosul.errors?.gruplar ? kosul.errors.gruplar[0] : null}
                                          newPath="tanimlar/gruplar"
                                          ustId={false}
                                        />
                                      </div>
                                      <div className={classes.layoutFormItemBox}>
                                        <MultipleSelectList
                                          headername="CARİ/ÜYE GRUPLARI"
                                          name="Cari/Üye Grupları"
                                          name2="Cari/Üye Grubu"
                                          form={kosul.form}
                                          list={kosul.form?.kullanici_gruplari ?? []}
                                          listlength={kosul.form?.kullanici_gruplari?.length ?? 0}
                                          opendialog={(res) => {
                                            setselectedTabIndex(index);
                                            setselectedTabIndex2(index2);
                                            setOpenSelectorKullaniciGrup(res);
                                          }}
                                          keyProp="id"
                                          valueProp="name"
                                          removefonk={(id) => handleRemoveKullaniciKullaniciGrupById(index, index2, id)}
                                          loading={props.loading}
                                          error={kosul.errors?.kullanici_gruplari ? kosul.errors.kullanici_gruplari[0] : null}
                                          newPath="tanimlar/kullanici_grup"
                                          ustId={false}
                                        />
                                      </div>
                                      <div className={classes.layoutFormItemBox}>
                                        <MultipleSelectList
                                          headername="KATEGORİLER"
                                          name="Kategoriler"
                                          name2="Kategori"
                                          form={kosul.form}
                                          list={kosul.form?.kategoriler ?? []}
                                          listlength={kosul.form?.kategoriler?.length ?? 0}
                                          opendialog={(res) => {
                                            setselectedTabIndex(index);
                                            setselectedTabIndex2(index2);
                                            setOpenSelectorKategori(res);
                                          }}
                                          keyProp="id"
                                          valueProp="parents"
                                          removefonk={(id) => handleRemoveKullaniciKategoriById(index, index2, id)}
                                          loading={props.loading}
                                          error={kosul.errors?.kategoriler ? kosul.errors.kategoriler[0] : null}
                                          newPath="tanimlar/kategoridetay"
                                          ustId={false}
                                        />
                                      </div>
                                      <div className={classes.layoutFormItemBox}>
                                        <MultipleSelectList
                                          headername="ÜRÜNLER"
                                          name="Ürünler"
                                          name2="Ürün"
                                          form={kosul.form}
                                          list={kosul.form?.urunler ?? []}
                                          listlength={kosul.form?.urunler?.length ?? 0}
                                          opendialog={(res) => {
                                            setselectedTabIndex(index);
                                            setselectedTabIndex2(index2);
                                            setOpenSelectorUrun(res);
                                          }}
                                          keyProp="id"
                                          valueProp="tanim"
                                          removefonk={(id) => handleRemoveUrunById(index, index2, id)}
                                          loading={props.loading}
                                          error={kosul.errors?.urunler ? kosul.errors.urunler[0] : null}
                                          newPath="tanimlar/urun"
                                          ustId={false}
                                        />
                                      </div>
                                      <div className="flex flex-col justify-start">
                                        <div className={classes.layoutFormItemBox}>
                                          <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                                            <header className="flex items-center justify-between py-2 px-4">
                                              <Button
                                                className="pointer-events-none"
                                                color="inherit"
                                              >ÖZELLİKLER</Button>
                                              <Button
                                                className={classes.button}
                                                variant="text"
                                                color="default"
                                                size="small"
                                                onClick={() => {
                                                  setselectedTabIndex(index);
                                                  setselectedTabIndex2(index2);
                                                  setOpenSelectorOzellik(true)
                                                }}
                                              >SEÇ</Button>
                                            </header>
                                          </section>
                                        </div>
                                        <div className={classes.treeList}>
                                          <>
                                            {kosul.form?.ozellik_tree_list?.length > 0 && (
                                              <>
                                                {kosul.form.ozellik_tree_list.map((item, index) => (
                                                  <div key={index} className={classes.list}>
                                                    <div className={classes.title}>{item.title}</div>
                                                    <div className={classes.ul}>
                                                      {item.list.map((v) => (
                                                        <div key={v.key} className={classes.li}>{v.value}</div>
                                                      ))}
                                                    </div>
                                                  </div>
                                                ))}
                                              </>
                                            )}
                                          </>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}

                            {grup.kosullar.length === 0 && (
                              <div className="flex flex-col items-start justify-start">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  disableElevation
                                  onClick={() => props.onAddNewKosul(index)}
                                >YENİ KOŞUL EKLE</Button>
                              </div>
                            )}
                          </div>


                        </div>
                      ))}
                    </>
                  )}

                  <div className="flex flex-col items-start justify-start">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      onClick={props.onAddNewGrup}
                    >YENİ KOŞUL GRUBU EKLE</Button>
                  </div>
                </div>
              </div>

              {openSelectorGrup &&
                <Dialog
                  open={openSelectorGrup}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorGrup(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Ürün Grupları'
                      listPath='grup/mini_list?aktif=true'
                      listCountPath='grup/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.forms[selectedTabIndex].kosullar[selectedTabIndex2].form.gruplar}
                      onChangeItems={(items) => props.onChangeForm(selectedTabIndex, selectedTabIndex2, 'gruplar', items)}
                      onClose={() => setOpenSelectorGrup(false)}
                      newPath='tanimlar/gruplar'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorKullaniciGrup &&
                <Dialog
                  open={openSelectorKullaniciGrup}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorKullaniciGrup(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Cari/Üye Grupları'
                      listPath='kullanici-grup'
                      listCountPath='kullanici-grup/count'
                      keyProp='id'
                      valueProp='name'
                      defaultItems={props.forms[selectedTabIndex].kosullar[selectedTabIndex2].form.kullanici_gruplari}
                      onChangeItems={(items) => props.onChangeForm(selectedTabIndex, selectedTabIndex2, 'kullanici_gruplari', items)}
                      onClose={() => setOpenSelectorKullaniciGrup(false)}
                      newPath='tanimlar/kullanici_grup'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorKategori &&
                <Dialog
                  open={openSelectorKategori}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorKategori(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <CategorySelector
                      selected={props.forms[selectedTabIndex].kosullar[selectedTabIndex2].form.kategoriler}
                      onChange={(items) => props.onChangeForm(selectedTabIndex, selectedTabIndex2, 'kategoriler', items)}
                      onClose={() => setOpenSelectorKategori(false)}
                      single={false}
                      parentselect={false}
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorUrun &&
                <Dialog
                  open={openSelectorUrun}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorUrun(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Ürünler'
                      listPath='urun/mini_list?aktif=true'
                      listCountPath='urun/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.forms[selectedTabIndex].kosullar[selectedTabIndex2].form.urunler}
                      onChangeItems={(items) => props.onChangeForm(selectedTabIndex, selectedTabIndex2, 'urunler', items)}
                      onClose={() => setOpenSelectorUrun(false)}
                      newPath='tanimlar/urun'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorOzellik &&
                <Dialog
                  open={openSelectorOzellik}
                  PaperProps={{
                    style: {
                      background: 'transparent',
                      height: 600,
                      width: detayListOpen ? '100%' : 480
                    },
                  }}
                  fullWidth
                  maxWidth={detayListOpen ? 'md' : 'sm'}
                  onClose={() => setOpenSelectorOzellik(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorTwoStep
                      title="Özellikler"
                      mainItemsPath='ozellikbaslik'
                      mainItemsCountPath='ozellikbaslik/count'
                      mainDefaultItems={props.forms[selectedTabIndex].kosullar[selectedTabIndex2].form.ozellik_basliklar}
                      mainMultiple={true}
                      mainKeyProp='id'
                      mainValueProp='tanim'
                      listPath='ozellikdeger'
                      listCountPath='ozellikdeger/count'
                      listMultiple={true}
                      keyProp='id'
                      valueProp='tanim'
                      keyUId='odeme_tanim_id'
                      valueUV='ozellik_baslik_tanim'
                      defaultItems={props.forms[selectedTabIndex].kosullar[selectedTabIndex2].form.ozellik_degerler}
                      onChangeItems={(items) => props.onChangeForm(selectedTabIndex, selectedTabIndex2, 'ozellik_degerler', items)}
                      onClose={() => {
                        setOpenSelectorOzellik(false);
                      }}
                      onDetayListClose={(res) => setDetayListOpen(res)}
                      detayListOpen={detayListOpen}
                      detayList={props.forms[selectedTabIndex].kosullar[selectedTabIndex2].form.ozellik_tree_list}
                      url="tanimlar/ozellikdetay"
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }

    </>
  );
});

export default KampanyaKosul;
