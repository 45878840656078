import React, { useState, useLayoutEffect } from 'react';
import django from 'api/django';
import Layout from '../../../pages/base/LayoutTableDetail';
import Detail from './OdemeServisDetail';


const COLUMNS_DEFAULT = [
  { id: 'tanim',       tableProp: 'tanim',             filterProp: 'tanim',       label: 'Ödeme Servis Tanım', element: 'input',  type: 'text',     arrayTableProp: null, show: true, sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'servis_tipi', tableProp: 'servis_tipi.value', filterProp: 'servis_tipi', label: 'Servis Tipi',        element: 'select', type: 'number',   arrayTableProp: null, show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'odeme_kurum', tableProp: 'odeme_kurum.value', filterProp: 'odeme_kurum', label: 'Ödeme Kurum',        element: 'select', type: 'number',   arrayTableProp: null, show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'para_birimi', tableProp: 'para_birimi.value', filterProp: 'para_birimi', label: 'Para Birimi',        element: 'select', type: 'number',   arrayTableProp: null, show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'test',        tableProp: 'test',              filterProp: 'test',        label: 'Test Aşamasında',    element: 'select', type: 'boolean',  arrayTableProp: null, show: true, sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null,  selectKey:'key',  selectValue:'value'},
];

const PageOdemeServisList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        return column;
      });
    });
  }, []);

  useLayoutEffect(() => {
    django('odemeservis/odeme_kurum').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'odeme_kurum') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  useLayoutEffect(() => {
    django('odemeservis/para_birimi').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'para_birimi') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  return (
    <>
      <Layout
        title="ÖDEME SERVİS"
        path="odemeservis"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="odemeservis"
      />
    </>
  );
};

export default PageOdemeServisList;
