import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../base/LayoutTableDetail';
import Detail from './FiyatTanimDetail';


const COLUMNS_DEFAULT = [
  { id: 'tanim',                        tableProp: 'tanim',                         filterProp: 'tanim',                        label: 'Fiyat Tanım',                   element: 'input',             type: 'text',     arrayTableProp: null, show: true,   sortable: true,  highlightable: true,   alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'aktif',                        tableProp: 'aktif',                         filterProp: 'aktif',                        label: 'Aktif',                         element: 'select',            type: 'boolean',  arrayTableProp: null, show: true,   sortable: false, highlightable: false,  alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'siralama',                     tableProp: 'siralama',                      filterProp: 'siralama',                     label: 'Sıralama',                      element: 'input',             type: 'number',   arrayTableProp: null, show: true,   sortable: true,  highlightable: false,  alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'gecerlilik_baslangic_tarihi',  tableProp: 'gecerlilik_baslangic_tarihi',   filterProp: 'gecerlilik_baslangic_tarihi',  label: 'Geçerlilik Başlangıç Tarihi',   element: 'input',             type: 'datetime', arrayTableProp: null, show: true,   sortable: true,  highlightable: false,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'gecerlilik_bitis_tarihi',      tableProp: 'gecerlilik_bitis_tarihi',       filterProp: 'gecerlilik_bitis_tarihi',      label: 'Geçerlilik Bitiş Tarihi',       element: 'input',             type: 'datetime', arrayTableProp: null, show: true,   sortable: true,  highlightable: false,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'olusturan_kullanici',          tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',       label: 'Oluşturan Kullanıcı',           element: 'select-multiple',   type: 'number',   arrayTableProp: null, show: true,   sortable: true,  highlightable: false,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistiren_kullanici',         tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',      label: 'Değiştiren Kullanıcı',          element: 'select-multiple',   type: 'number',   arrayTableProp: null, show: true,   sortable: true,  highlightable: false,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'olusturma_zamani',             tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',             label: 'Oluşturma',                     element: 'input',             type: 'datetime', arrayTableProp: null, show: true,   sortable: true,  highlightable: false,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistirme_zamani',            tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',            label: 'Son Değişiklik',                element: 'input',             type: 'datetime', arrayTableProp: null, show: true,   sortable: true,  highlightable: false,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
];

const PageFiyatTanimList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        return column;
      });
    });
  }, []);

  return (
    <>
      <Layout
        title="FİYAT LİSTELERİ"
        path="fiyattanim"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="fiyattanim"
      />
    </>
  );
};

export default PageFiyatTanimList;
