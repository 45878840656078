/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState, useRef } from 'react';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';
import immer from 'immer';
import {
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  CircularProgress,
  Typography,
  Divider
} from '@material-ui/core';

import {
  Search as IconSearch,
  Close as IconClose,
  Check as IconCheck
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import { Hata } from '../../../base/Hata';
import ReadOnlyList from '../../../../components/ReadOnlyList';


const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    listHeader: {
      display: "grid",
      gridTemplateColumns: "200px auto",
      padding: spacing(2, 0, 1, 0),
      marginBottom: spacing(2),
      borderBottom: `1px solid ${palette.primary.main}`
    },
    headerCol: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "left",
      width: 200
    }
  };
};

const def = [
  { key: "mesaj", type: "text", keyProp: null, title: "Mesaj" },
  { key: "bolumu", type: "dic", keyProp: null, title: "Bölümü" },
  { key: "hata", type: "bool", keyProp: null, title: "Hata" },
];


const UrunDetailXTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const [selected, setSelected] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);

  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const LOADINGDETAY = useMemo(() => props.loadingDetay, [props.loadingDetay]);
  const LOADINGMORE = useMemo(() => props.loadingMore, [props.loadingMore]);
  const HATADETAY = useMemo(() => props.hataDetay, [props.hataDetay]);
  const LISTE = useMemo(() => props.liste, [props.liste]);
  const PAGE = useMemo(() => props.page, [props.page]);
  const PAGES_COUNT = useMemo(() => props.pagesCount, [props.pagesCount]);
  const PATH = useMemo(() => props.path, [props.path]);

  const [searchOpen, setSearchOpen] = useState([false, false]);
  const [searchQuery, setSearchQuery] = useState([{ key: "kodu", value: "" }, { key: "mesaj", value: "" }]);

  const mainContainerRef = useRef();

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      fullWidth: true,
    };
  }, [props.form]);

  const rowRenderer = ({ index, style }) => {
    const item = LISTE[index];
    return (
      <div key={item.id} style={style}
        className={item.kodu ? 'cursor-pointer' : ''}
        onClick={() => {
          if (item.kodu) {
            setSelected(item);
            setOpenDetail(true);
          }
        }}
      >
        {index !== 0 && <Divider />}
        <div className="grid items-center" style={{ height: 34, gridTemplateColumns: "200px auto" }}>
          <span>{item.kodu}</span>
          <span>{item.mesaj}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>

                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="bitis_zamani">
                      <label className={classes.layoutFormItemLabel} htmlFor="bitis_zamani">İşlemin Bitiş Zamanı</label>
                      <Tooltip title={_isNil(props.form?.bitis_zamani) ? '' : props.form.bitis_zamani}>
                        <TextField
                          {...createTextFieldProps('bitis_zamani')}
                          disabled
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="islem_tipi">
                      <label className={classes.layoutFormItemLabel} htmlFor="islem_tipi">İşlem Tipi</label>
                      <Tooltip title={_isNil(props.form?.islem_tipi) ? '' : props.entIslemTipDic ? props.entIslemTipDic[props.form.islem_tipi] : ''}>
                        <TextField
                          {...createTextFieldProps('islem_tipi', '')}
                          disabled
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          {props?.entIslemTip?.map((et) => (
                            <option key={et.key} value={et.key}>{et.value}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="hata">
                      <label className={classes.layoutFormItemLabel} htmlFor="hata">Hata Oluşmuş</label>
                      <Checkbox
                        name="hata"
                        color="primary"
                        checked={_result(props.form, 'hata', false)}
                        disabled
                      ></Checkbox>
                    </li>
                  </ul>
                </div>

                <div className={classes.layoutContainer} style={{ height: "100%", overflow: "hidden" }}>

                  <main className="relative flex flex-grow flex-col overflow-hidden h-full" ref={mainContainerRef}>
                    <div className="flex flex-col h-full">
                      {LOADINGDETAY && (
                        <div className="flex w-full justify-center items-center">
                          <CircularProgress color="primary" />
                          <Typography variant="caption">Yükleniyor...</Typography>
                        </div>
                      )}
                      {!LOADINGDETAY && (
                        <>
                          {HATADETAY && (
                            <Hata />
                          )}
                          {!HATADETAY && (
                            <>
                              <div className={classes.listHeader}>
                                <div>
                                  {searchOpen[0] && (
                                    <div className={classes.headerCol}>
                                      <TextField
                                        variant="outlined"
                                        size='small'
                                        fullWidth={true}
                                        label="İşlem Detay Kodu"
                                        value={searchQuery[0].value}
                                        onChange={(e) => {
                                          const val = e.target.value;
                                          setSearchQuery((prev) => {
                                            return immer(prev, (next) => {
                                              next[0].value = val;
                                            });
                                          });
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                          endAdornment:
                                            <IconButton
                                              size="small"
                                              disableRipple
                                              onClick={() => props.searchListSet(searchQuery)}
                                            >
                                              <IconCheck />
                                            </IconButton>
                                        }}
                                      >
                                      </TextField>
                                      <IconButton
                                        size="small"
                                        disableRipple
                                        onClick={() => {
                                          setSearchQuery((prev) => {
                                            return immer(prev, (next) => {
                                              next[0].value = "";
                                              props.searchListSet(next)
                                            });
                                          });
                                          setSearchOpen((prev) => {
                                            return immer(prev, (next) => {
                                              next[0] = false;
                                            });
                                          });
                                        }}
                                      >
                                        <IconClose />
                                      </IconButton>
                                    </div>
                                  )}
                                  {!searchOpen[0] && (
                                    <div className={classes.headerCol}>
                                      <span>İşlem Detay Kodu</span>
                                      <IconButton
                                        size="small"
                                        disableRipple
                                        onClick={() => {
                                          setSearchOpen((prev) => {
                                            return immer(prev, (next) => {
                                              next[0] = true;
                                            });
                                          });
                                        }}
                                      >
                                        <IconSearch />
                                      </IconButton>
                                    </div>
                                  )}
                                </div>
                                <div>
                                  {searchOpen[1] && (
                                    <div className={classes.headerCol}>
                                      <TextField
                                        variant="outlined"
                                        size='small'
                                        fullWidth={true}
                                        label="İşlem Mesajı"
                                        value={searchQuery[1].value}
                                        onChange={(e) => {
                                          const val = e.target.value;
                                          setSearchQuery((prev) => {
                                            return immer(prev, (next) => {
                                              next[1].value = val;
                                            });
                                          });
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                          endAdornment:
                                            <IconButton
                                              size="small"
                                              disableRipple
                                              onClick={() => props.searchListSet(searchQuery)}
                                            >
                                              <IconCheck />
                                            </IconButton>
                                        }}
                                      >
                                      </TextField>
                                      <IconButton
                                        size="small"
                                        disableRipple
                                        onClick={() => {
                                          setSearchQuery((prev) => {
                                            return immer(prev, (next) => {
                                              next[1].value = "";
                                              props.searchListSet(next)
                                            });
                                          });
                                          setSearchOpen((prev) => {
                                            return immer(prev, (next) => {
                                              next[1] = false;
                                            });
                                          });
                                        }}
                                      >
                                        <IconClose />
                                      </IconButton>
                                    </div>
                                  )}
                                  {!searchOpen[1] && (
                                    <div className={classes.headerCol}>
                                      <span>İşlem Mesajı</span>
                                      <IconButton
                                        size="small"
                                        disableRipple
                                        onClick={() => {
                                          setSearchOpen((prev) => {
                                            return immer(prev, (next) => {
                                              next[1] = true;
                                            });
                                          });
                                        }}
                                      >
                                        <IconSearch />
                                      </IconButton>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="h-full flex-grow">
                                <InfiniteLoader
                                  minimumBatchSize={100}
                                  threshold={100}
                                  isRowLoaded={({ index }) => !!LISTE[index]}
                                  loadMoreRows={({ startIndex, stopIndex }) => {
                                    if (props.listeCount > 0 && PAGES_COUNT > PAGE && !LOADINGMORE) {
                                      props.getMore(PAGE + 1, mainContainerRef, searchQuery);
                                    }
                                  }}
                                  rowCount={props.listeCount > 0 ? props.listeCount : props.rowCount * 2}
                                >
                                  {({ onRowsRendered, registerChild }) => (
                                    <AutoSizer>
                                      {({ width, height }) => (
                                        <List
                                          width={width}
                                          height={height}
                                          rowHeight={35}
                                          rowCount={LISTE.length}
                                          estimatedRowSize={PAGES_COUNT > 0 ? PAGES_COUNT * (35) : undefined}
                                          rowRenderer={rowRenderer}
                                          onRowsRendered={onRowsRendered}
                                          ref={registerChild}
                                        />
                                      )}
                                    </AutoSizer>
                                  )}
                                </InfiniteLoader>
                              </div>
                            </>
                          )}
                        </>
                      )}

                    </div>
                  </main>
                </div>

              </div>

              {openDetail && (
                <Dialog
                  open={openDetail}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenDetail(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ReadOnlyList
                      title='Detay Log'
                      listPath={`${PATH}/${selected.id}/islemdetaylog`}
                      listCountPath={`${PATH}/${selected.id}/islemdetaylog/count`}
                      searchKeyProp="mesaj"
                      searchKeyTitle="İşlem Detay Log mesajlarına"
                      defaultObj={def}
                      dics={{ bolumu: props.entIslemBolumDic }}
                      onClose={() => setOpenDetail(false)}
                      grid="70fr 20fr 10fr"
                    />
                  </DialogContent>
                </Dialog>
              )}

            </>
          )}
        </>
      }
    </>
  );
});

export default UrunDetailXTabGenel;
