import React, { useLayoutEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import arrayMove from 'array-move';

import {
  sortableContainer,
  sortableElement,
} from 'react-sortable-hoc';

import {
  Popover,
  Checkbox,
  Button,
} from '@material-ui/core';

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className="m-0 p-0">{children}</ul>;
});

const SortableItem = sortableElement(({ column, countVisibleColumns, onChangeVisibility, onChangeColumnLabel }) => {
  const [value, setValue] = useState('');
  const [showInput, setShowInput] = useState(false);

  const inputRef = useRef();

  useLayoutEffect(() => {
    setValue(column.label);
  }, [column.label]);

  return (
    <li
      className={clsx('flex items-center justify-start border-t border-b border-palette-action-hover cursor-grab space-x-3 -mt-px px-3', {
        'text-palette-text-disabled': !column.show,
      })}
      style={{
        zIndex: 1789,
      }}
      onDoubleClick={(e) => {
        e.preventDefault();

        if (!showInput) {
          setShowInput(true);

          setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }, 5);
        }
      }}
    >
      <span className="w-8 h-8 flex items-center justify-center text-palette-text-secondary">
        <Checkbox
          color="primary"
          checked={column.show}
          onChange={((e, checked) => onChangeVisibility(checked))}
          disabled={countVisibleColumns < 2 && column.show}
          style={{
            padding: 5,
          }}
        />
      </span>

      {!showInput && (
        <span className="w-full h-8 flex-1 flex items-center justify-start">{column.label}</span>
      )}

      {showInput && (
        <input
          className="h-6 flex items-center justify-center bg-palette-action-hover focus:outline-none px-2"
          value={value}
          onBlur={() => setShowInput(false)}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();

              onChangeColumnLabel(value);
              setShowInput(false);
            }
          }}
          ref={inputRef}
        />
      )}
    </li>
  );
});

const GridTableXColumnEditor = ({ anchorEl, columns, onUpdatedColumns, onResetColumns, onClose }) => {
  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const copyColumns = [...columns];
    copyColumns[oldIndex].order = newIndex;

    if (oldIndex !== newIndex) {
      onUpdatedColumns(arrayMove(copyColumns, oldIndex, newIndex));
    }
  };

  const handleShowColumn = (index, show) => {
    const copyColumns = [...columns];

    copyColumns[index].show = show;

    onUpdatedColumns(copyColumns);
  };

  const handleChangeColumnName = (index, label) => {
    const copyColumns = [...columns];

    copyColumns[index].label = label;

    onUpdatedColumns(copyColumns);
  };

  if (!anchorEl) {
    return null;
  }

  return (
    <>
      <Popover
        open
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <section className="min-w-60 max-h-80">
          <header className="py-3 px-5">
            <h3 className="m-0 p-0">
              <span className="text-palette-text-secondary text-sm tracking-wide uppercase">Sütunları Düzenle</span>
            </h3>
          </header>

          <main>
            <SortableContainer
              helperClass="bg-palette-action-hover cursor-grabbing"
              distance={1}
              transitionDuration={0}
              lockAxis="y"
              onSortEnd={handleSortEnd}
            >
              {columns.map((column, index) => (
                <React.Fragment key={column.id}>
                  <SortableItem
                    index={index}
                    column={column}
                    countVisibleColumns={columns.filter((col) => col.show).length}
                    onChangeVisibility={(show) => handleShowColumn(index, show)}
                    onChangeColumnLabel={(label) => handleChangeColumnName(index, label)}
                  />
                </React.Fragment>
              ))}
            </SortableContainer>
          </main>

          <footer className="py-2 px-3">
            <Button
              color="primary"
              variant="text"
              size="small"
              disableRipple
              onClick={onResetColumns}
            >SIFIRLA</Button>
          </footer>
        </section>
      </Popover>
    </>
  );
};

export default GridTableXColumnEditor;
