import React, { useState, useLayoutEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import DatePickerTR from 'date-fns/locale/tr';
import 'react-datepicker/dist/react-datepicker.css';

import {
  has as _has,
  startsWith as _startsWith,
  endsWith as _endsWith,
  trimStart as _trimStart,
  trimEnd as _trimEnd,
  isArray as _isArray,
} from 'lodash';

import {
  Button,
  IconButton,
  Popover,
  Checkbox,
  Dialog,
  DialogContent,
} from '@material-ui/core';

import {
  Tune as IconTune,
  Done as IconDone,
  Clear as IconClear,
} from '@material-ui/icons';

import MultipleFilterSelector from '../components/MultipleFilterSelector';
import MultipleFilterSelectorTwoStep from './MultipleFilterSelectorTwoStep';
import CategorySelector from '../components/CategorySelector';
import CategorySelectorBesidePanelCat from './CategorySelectorBesidePanelCat';

const FILTER_TYPES = {
  text: [
    { key: '', label: 'Eşit' },
    { key: 'contain', label: 'İçeren', prefix: '*', postfix: '*' },
    { key: 'startWith', label: 'İle Başlayan', prefix: '*' },
    { key: 'endWith', label: 'İle Biten', postfix: '*' },
  ],

  number: [
    { key: '', label: 'Eşit' },
    { key: 'greater', label: 'Büyük', prefix: '>' },
    { key: 'less', label: 'Küçük', prefix: '<' },
    { key: 'between', label: 'Arasında', prefix: '>', postfix: '<' },
  ],

  datetime: [
    { key: '', label: 'Eşit' },
    { key: 'greater', label: 'Sonra', prefix: '>' },
    { key: 'less', label: 'Önce', prefix: '<' },
    { key: 'between', label: 'Arasında', prefix: '>', postfix: '<' },
  ],
};

const fixedValue = (filter, column) => {

  if ((filter === undefined || column === undefined || filter[column.filterProp] === undefined) && column.openFilter === undefined) {
    if (column.multipleSelectOptionsPath === 'kategori' || column.isEnt) {
      return [];
    } else {
      return '';
    }
  }
  let value = filter[column.filterProp];

  if (filter[column.filterProp] === undefined && column.openFilter !== undefined) {
    value = column.openFilter;
  }

  if (column.element === 'select-multiple' && !_isArray(value)) {
    value = [value];
  }

  if (column.multipleSelectOptionsPath === 'kategori' || column.isEnt) {
    if (!_isArray(value)) {
      return Number(value)
    } else {
      return value.map((x) => Number(x));
    }
  } else {
    return value;
  }
};

const GridTableXTableHeadCellFilter = (props) => {
  const [filterType, setFilterType] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const [showFilterTypePopover, setShowFilterTypePopover] = useState(false);
  const [showSelectMultipleDialog, setShowSelectMultipleDialog] = useState(false);
  const [showSelectMultipleTwoStepDialog, setShowSelectMultipleTwoStepDialog] = useState(false);

  const filterTypePopoverRef = React.useRef();

  useLayoutEffect(() => {
    const fValue = fixedValue(props.filter, props.column);

    setFilterValue(fValue);

    if (props.column.type === 'text') {
      setFilterType('contain');

      if (_startsWith(fValue, '*') && _endsWith(fValue, '*')) {
        setFilterType('contain');
        setFilterValue(_trimEnd(_trimStart(fValue, '*'), '*'));
      }

      else if (_startsWith(fValue, '*')) {
        setFilterType('endWith');
        setFilterValue(_trimStart(fValue, '*'));
      }

      else if (_endsWith(fValue, '*')) {
        setFilterType('startWith');
        setFilterValue(_trimEnd(fValue, '*'));
      }
    }

    if ((props.column.type === 'number' || props.column.type === 'datetime') && _isArray(fValue)) {
      const firstValue = fValue[0];
      const secondValue = fValue[1];

      if (_startsWith(firstValue, '<') && _startsWith(secondValue, '>')) {
        setFilterType('between');
        setFilterValue([_trimStart(firstValue, '<'), _trimStart(secondValue, '>')]);
      }

      else if (_startsWith(firstValue, '>') && _startsWith(secondValue, '<')) {
        setFilterType('between');
        setFilterValue([_trimStart(firstValue, '>'), _trimStart(secondValue, '<')]);
      }
    }

    if ((props.column.type === 'number' || props.column.type === 'datetime') && !_isArray(fValue)) {
      if (_startsWith(fValue, '>')) {
        setFilterType('greater');
        setFilterValue(_trimStart(fValue, '>'));
      }

      else if (_startsWith(fValue, '<')) {
        setFilterType('less');
        setFilterValue(_trimStart(fValue, '<'));
      }
    }
  }, [props.filter, props.column]);

  const handleSubmitFilter = (e) => {
    e.preventDefault();

    if (filterValue === '') {
      props.onSubmit('');
      return;
    }

    const filterValueString = _isArray(filterValue) ? filterValue[0] : filterValue;
    let searchValue = filterValue;



    if (props.column.element === 'select-multiple') {
      searchValue = filterValue;
    }

    else if (filterType === 'greater') {
      searchValue = `>${filterValueString}`;
    }

    else if (filterType === 'less') {
      searchValue = `<${filterValueString}`;
    }

    else if (filterType === 'between') {
      searchValue = [`>${filterValue[0]}`, `<${filterValue[1]}`];
    }

    else if (filterType === 'contain') {
      searchValue = `*${filterValueString}*`;
    }

    else if (filterType === 'startWith') {
      searchValue = `${filterValueString}*`;
    }

    else if (filterType === 'endWith') {
      searchValue = `*${filterValueString}`;
    }

    props.onSubmit(searchValue);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();

    setFilterType('');
    setFilterValue('');

    setTimeout(() => {
      props.onSubmit('');
    }, 5);
  };

  const handleCloseSelectMultipleDialog = (e) => {
    e.preventDefault();

    const fValue = fixedValue(props.filter, props.column);

    setFilterValue(fValue);
    setShowSelectMultipleDialog(false);
  };

  const handleCloseSelectMultipleTwoStepDialog = (e) => {
    e.preventDefault();

    const fValue = fixedValue(props.filter, props.column);

    setFilterValue(fValue);
    setShowSelectMultipleTwoStepDialog(false);
  };

  return (
    <>
      <div className="w-full flex items-center justify-center space-x-1">
        {props.column.element === 'select' && (
          <>
            <select
              className={clsx([
                'w-full min-w-32 h-7 bg-palette-background-paper rounded border border-palette-action-selected text-palette-text-primary font-roboto px-2',
                'focus:outline-none',
              ])}
              value={filterValue}
              onChange={(e) => props.onSubmit(e.target.value)}
            >
              <option value={''}>Tümü</option>

              {props.column.selectOptions?.map((option) => {
                return <option key={option[props.column.selectKey]} value={option[props.column.selectKey]}>{option[props.column.selectValue]}</option>;
              })}
            </select>
          </>
        )}

        {props.column.element === 'select-multiple' && (
          <>
            {props.column.multipleSelectOptionsPath === 'kategori' && (
              <Dialog
                open={showSelectMultipleDialog}
                PaperProps={{
                  style: {
                    width: '480px',
                    height: '600px',
                  },
                }}
                onClose={handleCloseSelectMultipleDialog}
              >
                <DialogContent style={{ padding: 0 }}>
                  <CategorySelector
                    selected={filterValue}
                    onChange={(keys) => setFilterValue(keys)}
                    onClose={handleCloseSelectMultipleDialog}
                    single={false}
                    parentselect={true}
                    onSubmit={(e) => {
                      handleSubmitFilter(e);
                      setShowSelectMultipleDialog(false);
                    }}
                  />
                </DialogContent>
              </Dialog>
            )}
            {props.column.isEnt && (
              <Dialog
                open={showSelectMultipleDialog}
                PaperProps={{
                  style: {
                    width: '480px',
                    height: '600px',
                  },
                }}
                onClose={handleCloseSelectMultipleDialog}
              >
                <DialogContent style={{ padding: 0 }}>
                  <CategorySelectorBesidePanelCat
                    path={props.column.multipleSelectOptionsPath}
                    selected={filterValue}
                    onChange={(keys) => setFilterValue(keys)}
                    onClose={handleCloseSelectMultipleDialog}
                    onSubmit={(e) => {
                      handleSubmitFilter(e);
                      setShowSelectMultipleDialog(false);
                    }}
                  />
                </DialogContent>
              </Dialog>
            )}
            {(props.column.multipleSelectOptionsPath !== 'kategori' && !props.column.isEnt) && (
              <Dialog
                open={showSelectMultipleDialog}
                PaperProps={{
                  style: {
                    width: '480px',
                    height: '600px',
                  },
                }}
                onClose={handleCloseSelectMultipleDialog}
              >
                <DialogContent style={{ padding: 0 }}>
                  <MultipleFilterSelector
                    title={props.column.label}
                    optionsPath={props.column.multipleSelectOptionsPath}
                    filter={props.column.multipleSelectOptionExtra ?? false}
                    filterProp={props.column.multipleSelectOptionFilterProp}
                    sortProp={props.column.multipleSelectOptionSortProp}
                    viewProp={props.column.multipleSelectOptionViewProp}
                    selectedOptionKeys={filterValue}
                    onSetSelectedOptionKeys={(keys) => setFilterValue(keys)}
                    onClose={handleCloseSelectMultipleDialog}
                    onSubmit={(e) => {
                      handleSubmitFilter(e);
                      setShowSelectMultipleDialog(false);
                    }}
                    newpath={props.column.newpath}
                    ustId={props.column.ustId}
                    staticpage={props.column.staticpage}
                  />
                </DialogContent>
              </Dialog>
            )}

            <Button
              className="h-7"
              variant="text"
              color="default"
              size="small"
              onClick={() => setShowSelectMultipleDialog(!showSelectMultipleDialog)}
            >{filterValue.length > 0 ? `SEÇ (${filterValue.length})` : 'SEÇ'}</Button>

            {filterValue.length > 0 && (
              <IconButton
                size="small"
                disableRipple
                onClick={handleClearFilter}
              >
                <IconClear style={{ fontSize: 18 }} />
              </IconButton>
            )}

            {/* <pre className="m-0 p-0">{JSON.stringify(props.column.type)}</pre> */}
            {/* <pre className="m-0 p-0">{JSON.stringify(filterValue)}</pre> */}
          </>
        )}

        {props.column.element === 'select-multiple-step' && (
          <>
            <Dialog
              open={showSelectMultipleTwoStepDialog}
              PaperProps={{
                style: {
                  width: '480px',
                  height: '600px',
                },
              }}
              onClose={handleCloseSelectMultipleTwoStepDialog}
            >
              <DialogContent style={{ padding: 0 }}>
                <MultipleFilterSelectorTwoStep
                  title={props.column.label}
                  firstOptionsPath={props.column.firstOptionsPath}
                  optionsPath={props.column.multipleSelectOptionsPath}
                  firstOptionViewProp={props.column.firstOptionViewProp}
                  filterProp={props.column.multipleSelectOptionFilterProp}
                  sortProp={props.column.multipleSelectOptionSortProp}
                  viewProp={props.column.multipleSelectOptionViewProp}
                  selectedOptionKeys={filterValue}
                  onSetSelectedOptionKeys={(keys) => setFilterValue(keys)}
                  onClose={handleCloseSelectMultipleTwoStepDialog}
                  onSubmit={(e) => {
                    handleSubmitFilter(e);
                    setShowSelectMultipleTwoStepDialog(false);
                  }}
                  newpath={props.column.newpath}
                />
              </DialogContent>
            </Dialog>

            <Button
              className="h-7"
              variant="text"
              color="default"
              size="small"
              onClick={() => setShowSelectMultipleTwoStepDialog(!showSelectMultipleTwoStepDialog)}
            >{filterValue.length > 0 ? `SEÇ (${filterValue.length})` : 'SEÇ'}</Button>

            {filterValue.length > 0 && (
              <IconButton
                size="small"
                disableRipple
                onClick={handleClearFilter}
              >
                <IconClear style={{ fontSize: 18 }} />
              </IconButton>
            )}
          </>
        )}

        {props.column.element === 'input' && (
          <>
            {props.column.type === 'text' && (
              <input
                className={clsx([
                  'w-full min-w-24 h-7 bg-palette-background-paper rounded border border-palette-action-selected text-palette-text-primary font-roboto px-2',
                  'focus:outline-none',
                ])}
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleSubmitFilter(e);
                  }
                }}
              />
            )}

            {props.column.type === 'number' && filterType !== 'between' && (
              <input
                type="number"
                className={clsx([
                  'w-full min-w-24 h-7 bg-palette-background-paper rounded border border-palette-action-selected text-palette-text-primary font-roboto px-2',
                  'focus:outline-none',
                ])}
                value={_isArray(filterValue) ? filterValue[0] : filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleSubmitFilter(e);
                  }
                }}
              />
            )}

            {props.column.type === 'number' && filterType === 'between' && (
              <div className="flex items-center justify-start space-x-1">
                <input
                  type="number"
                  className={clsx([
                    'w-full min-w-24 h-7 bg-palette-background-paper rounded border border-palette-action-selected text-palette-text-primary font-roboto px-2',
                    'focus:outline-none',
                  ])}
                  value={_isArray(filterValue) ? filterValue[0] : filterValue}
                  onChange={(e) => setFilterValue([e.target.value, filterValue[1]])}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleSubmitFilter(e);
                    }
                  }}
                />

                <input
                  type="number"
                  className={clsx([
                    'w-full min-w-24 h-7 bg-palette-background-paper rounded border border-palette-action-selected text-palette-text-primary font-roboto px-2',
                    'focus:outline-none',
                  ])}
                  value={_isArray(filterValue) ? filterValue[1] : ''}
                  onChange={(e) => {
                    if (_isArray(filterValue)) {
                      setFilterValue([filterValue[0], e.target.value])
                    } else {
                      setFilterValue([filterValue, e.target.value])
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleSubmitFilter(e);
                    }
                  }}
                />
              </div>
            )}

            {props.column.type === 'datetime' && filterType !== 'between' && (
              <div className="min-w-24">
                <DatePicker
                  className="w-full min-w-28 h-7 bg-palette-background-paper rounded border border-palette-action-selected text-palette-text-primary font-roboto px-2 focus:outline-none"
                  locale={DatePickerTR}
                  dateFormat="dd/MM/yyyy"
                  selected={filterValue ? moment(filterValue, 'YYYYMMDD').toDate() : ''}
                  onChange={(date) => {
                    if (moment(date).isValid()) {
                      setFilterValue(moment(date).format('YYYYMMDD'));
                    }
                  }}
                />
              </div>
            )}

            {props.column.type === 'datetime' && filterType === 'between' && (
              <div className="flex items-center justify-start space-x-1">
                <div className="min-w-24">
                  <DatePicker
                    className="w-full min-w-28 h-7 bg-palette-background-paper rounded border border-palette-action-selected text-palette-text-primary font-roboto px-2 focus:outline-none"
                    locale={DatePickerTR}
                    dateFormat="dd/MM/yyyy"
                    selected={_isArray(filterValue) ? filterValue[0] ? moment(filterValue[0], 'YYYYMMDD').toDate() : '' : filterValue ? moment(filterValue, 'YYYYMMDD').toDate() : ''}
                    maxDate={_isArray(filterValue) ? filterValue[1] ? moment(filterValue[1], 'YYYYMMDD').toDate() : '' : filterValue ? moment(filterValue, 'YYYYMMDD').toDate() : ''}
                    onChange={(date) => {
                      if (moment(date).isValid()) {
                        setFilterValue([moment(date).format('YYYYMMDD'), filterValue[1]]);
                      }
                    }}
                  />
                </div>

                <div className="min-w-24">
                  <DatePicker
                    className="w-full min-w-28 h-7 bg-palette-background-paper rounded border border-palette-action-selected text-palette-text-primary font-roboto px-2 focus:outline-none"
                    locale={DatePickerTR}
                    dateFormat="dd/MM/yyyy"
                    minDate={_isArray(filterValue) ? filterValue[0] ? moment(filterValue[0], 'YYYYMMDD').toDate() : '' : filterValue ? moment(filterValue, 'YYYYMMDD').toDate() : ''}
                    selected={_isArray(filterValue) ? filterValue[1] ? moment(filterValue[1], 'YYYYMMDD').toDate() : '' : filterValue ? moment(filterValue, 'YYYYMMDD').toDate() : ''}
                    onChange={(date) => {
                      if (moment(date).isValid()) {
                        if (_isArray(filterValue)) {
                          setFilterValue([filterValue[0], moment(date).format('YYYYMMDD')])
                        } else {
                          setFilterValue([filterValue, moment(date).format('YYYYMMDD')])
                        }
                      }
                    }}
                  />
                </div>
              </div>
            )}

            {props.column.type === 'array' && (
              <input
                className={clsx([
                  'w-full min-w-24 h-7 bg-palette-background-paper rounded border border-palette-action-selected text-palette-text-primary font-roboto px-2',
                  'focus:outline-none',
                ])}
                value={filterValue}
                onChange={(e) => {
                  const str = e.target.value;
                  let list = str.split(',').map((x) => x.replace(/^(-)|[^0-9.,]+/g, '')).join(',');
                  setFilterValue(list)
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleSubmitFilter(e);
                  }
                }}
              />
            )}
            {props.column.type !== 'array' && (
              <IconButton
                size="small"
                disableRipple
                ref={filterTypePopoverRef}
                onClick={() => setShowFilterTypePopover(!showFilterTypePopover)}
              >
                <IconTune style={{ fontSize: 18 }} />
              </IconButton>
            )}
            <IconButton
              size="small"
              disableRipple
              onClick={handleSubmitFilter}
            >
              <IconDone style={{ fontSize: 18 }} />
            </IconButton>

            {filterValue !== '' && (
              <IconButton
                size="small"
                disableRipple
                onClick={handleClearFilter}
              >
                <IconClear style={{ fontSize: 18 }} />
              </IconButton>
            )}
          </>
        )}
      </div>

      <Popover
        open={showFilterTypePopover}
        anchorEl={filterTypePopoverRef?.current}
        onClose={() => setShowFilterTypePopover(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ul className="min-w-52 p-0 m-0 overflow-hidden">
          {_has(FILTER_TYPES, props.column.type) && FILTER_TYPES[props.column.type].map((type) =>
            <li key={type.key} className="flex items-center justify-start space-x-2 border-t border-palette-action-selected">
              <Checkbox
                color="primary"
                checked={filterType === type.key}
                onClick={() => setFilterType(type.key)}
              />

              <span>{type.label}</span>
            </li>
          )}
        </ul>
      </Popover>
    </>
  );
};

export default GridTableXTableHeadCellFilter;
