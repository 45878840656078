/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState, useEffect, useRef, createRef } from 'react';
import {
  has as _has,
  result as _result,
  isEmpty as _isEmpty,
  isNil as _isNil
} from 'lodash';
import immer from 'immer';
import moment from 'moment';
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  CircularProgress,
  Typography,
  Checkbox,
  Paper,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import { NumericFormat } from 'react-number-format';
import {
  Add as IconAdd,
  Delete as IconDelete,
  ArrowDropDown as IconArrowDropDown,
  ArrowDropUp as IconArrowDropUp,
  Search as IconSearch,
  Close as IconClose,
  Check as IconCheck,
  TrendingDown as IconSale,
  PlaylistAdd as IconAdditionalInfo
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import ListSelectorSingle from '../../../components/ListSelectorSingle';
import SearchListDrop from '../../../components/SearchListDrop';
import Calc from '../../../components/Calc';
import { List, AutoSizer } from 'react-virtualized';
import { turkishToLower } from '../../../helpers/helpers';
import { MoneyFormatConvert } from '../../../helpers/helpers'


const styles = ({ spacing, palette }) => {
  return {
    layoutContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
      whiteSpace: 'nowrap',
      width: 'max-content',
      minWidth: "100%",
    },
    borderLayout: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      width: 'max-content',
      minWidth: "100%",
      padding: spacing(1),
      border: "1px solid",
      borderColor: palette.primary.main,
      borderRadius: 4,
      height: '100%'
    },
    detailOpen: {
      position: 'absolute',
      left: 0,
      background: "transparent",
      '& .MuiIconButton-root': {
        color: palette.primary.main
      }
    },
    detailClose: {
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      background: "transparent",
      '& .MuiIconButton-root': {
        color: palette.primary.main
      }
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      position: 'relative',
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
      "& .MuiInputBase-multiline": {
        height: "100%"
      }
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
      whiteSpace: 'initial',
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button3: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      fontSize: '0.75rem',
    },
    button3Err: {
      minWidth: 40,
      backgroundColor: 'red',
      color: '#fff',
      '&:hover': {
        backgroundColor: 'red',
        color: '#fff',
      },
      fontSize: '0.75rem',
    },
    adresFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 240,
      position: 'relative',
      height: "100%",
      '& + &': {
        marginTop: 5,
      },
      "& .MuiInputBase-multiline": {
        height: "100%"
      }
    },
    adresFormList: {
      border: '1px solid #eacfea',
      padding: spacing(2, 1, 1),
      borderRadius: 4,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      height: '100%',
      '& + &': {
        marginTop: 10,
      }
    },
    ekAlanList: {
      border: '1px solid #eacfea',
      padding: spacing(3.25, 1, 1),
      borderRadius: 4,
      position: 'relative',
    },
    ekAlanLayout: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateRows: 'repeat(7, 1fr)',
      gridColumnGap: 32,
      gridRowGap: 5
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    formListTable: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: "relative"
    },
    tableHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 35,
      borderBottom: `1px solid ${palette.divider}`,
    },
    tableHeaderItem: {
      marginRight: 5,
      fontWeight: 500,
      textAlign: "right",
      width: 120,
      whiteSpace: "normal"
    },
    tableFormRow: {
      borderBottom: `1px solid ${palette.divider}`,
      display: "flex",
      '&:hover': {
        backgroundColor: palette.action.hover,
      },
      '&:last-child': {
        borderBottomWidth: 0,
      }
    },
    tableFormItem: {
      marginRight: 5,
      width: 120,
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    selectedRow: {
      border: '1px solid #eacfea !important',
    },
    tableMatrah: {
      '&': {
        whiteSpace: 'nowrap'
      },
      '& tbody tr:hover td': {
        backgroundColor: 'transparent',
      },
      '& tbody tr.no-hover:hover td': {
        backgroundColor: 'transparent',
      },
      '& tbody tr:last-child td': {
        borderBottomWidth: 0,
      }
    },
    toolbar: {
      backgroundColor: palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    },
  }
};

const AlisOnSiparisOnayDetailTab_Genel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const LOADING_EK = useMemo(() => props.loadingEkAlan, [props.loadingEkAlan]);
  const LOADINGURUN = useMemo(() => props.loadingUrun, [props.loadingUrun]);
  const LOADINGCALC = useMemo(() => props.loadingCalc, [props.loadingCalc]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATAURUN = useMemo(() => props.hataUrun, [props.hataUrun]);
  const add = useMemo(() => props.add, [props.add]);

  const URUNLISTE = useMemo(() => props?.urunler.length > 0 ? props.urunler : [], [props]);
  const LISTELENGTH = useMemo(() => props?.urunler.length > 0 ? props.urunler.length : 0, [props]);
  const vergibaslik = useMemo(() => props?.vergibaslik.length > 0 ? props.vergibaslik : null, [props]);
  const iskontobaslik = useMemo(() => props?.iskontobaslik.length > 0 ? props.iskontobaslik : null, [props]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowSearch, setSelectedRowSearch] = useState(null);
  const [lastRow, setLastRow] = useState(null);
  const [blockedAdd, setBlockedAdd] = useState(true);
  const MAGAZA_PARA_BIRIM = useMemo(() => (props.paraKoduList[props.form?.['magaza_para_birimi']] ?? ''), [props.form, props.paraKoduList]);
  const EVRAK_PARA_BIRIM = useMemo(() => (props.paraKoduList[props.form?.['para_birimi']] ?? ''), [props.form, props.paraKoduList]);

  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);
  const [openSelectorCikisDepo, setOpenSelectorCikisDepo] = useState(false);
  const [openSelectorCari, setOpenSelectorCari] = useState(false);
  const [openSelectorSaleManager, setOpenSelectorSaleManager] = useState(false);
  const [openSelectorSaleManager2, setOpenSelectorSaleManager2] = useState(false);
  const [openSelectorUrun, setOpenSelectorUrun] = useState(false);
  const [openSelectorKargo, setOpenSelectorKargo] = useState(false);
  const [openSelectorDurum, setOpenSelectorDurum] = useState(false);
  const [openSelectorSatirEkBilgi, setOpenSelectorSatirEkBilgi] = useState(false);

  const [topOpen, setTopOpen] = useState(true);
  const [bottomOpen, setBottomOpen] = useState(false);
  const [scrollNew, setScrollNew] = useState(0);

  const [filtredList, setFiltredList] = useState([]);
  const [codeSearchOpen, setCodeSearchOpen] = useState(false);
  const [nameSearchOpen, setNameSearchOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [queryCode, setQueryCode] = useState("");
  const [queryName, setQueryName] = useState("");

  const [searchItems, setSearchItems] = useState([]);

  const tableRef = useRef();
  const elementRef = URUNLISTE.map(() => createRef());
  const inputRef = URUNLISTE.map(() => createRef());

  useEffect(() => {
    setSelectedRow(null);
    setLastRow(null);
  }, [props.ID, add]);

  useEffect(() => {
    if (props.form && props.adresler) {
      if (!props.form.evrak_kullanici || !props.form.magaza || !props.form.cikis_depo) {
        setBlockedAdd(true);
      } else {
        if (codeSearchOpen || nameSearchOpen) {
          setBlockedAdd(true);
        } else {
          if (LISTELENGTH > 0) {
            if (lastRow !== null) {
              if (LISTELENGTH > lastRow) {
                if (URUNLISTE[lastRow]?.urun?.id && URUNLISTE[lastRow]?.urun_doviz_kur !== '') {
                  setBlockedAdd(false);
                } else {
                  setBlockedAdd(true);
                }
              } else {
                setLastRow(null);
                setBlockedAdd(false);
              }
            } else {
              setBlockedAdd(false);
            }
          } else {
            setBlockedAdd(false);
          }
        }
      }
    }
  }, [props.form, props.adresler, props.kurlar, lastRow, URUNLISTE, LISTELENGTH, codeSearchOpen, nameSearchOpen]);

  const createTextFieldPropsGenel = useCallback((name, name2, defaultValue = '') => {
    switch (name) {
      case 'evrak_durum':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form[name]?.[name2], 'tanim') || defaultValue,
          error: _has(props.error?.evrak?.[name], `${name2}`),
          fullWidth: true,
        };
      case 'magaza':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form[name], `${name2}`, defaultValue) + ' ' + props.paraKoduList[props.form?.['magaza_para_birimi'] ?? '-1'],
          error: _has(props.error?.evrak, `${name}`),
          fullWidth: true,
        };
      case 'cikis_depo':
      case 'evrak_kullanici':
      case 'durum':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form[name], `${name2}`) || defaultValue,
          error: _has(props.error?.evrak, `${name}`),
          fullWidth: true,
        };
      case 'secilen_kargo':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form[name], `${name2}`) || defaultValue,
          error: _has(props.error?.kargo?.[0], 'kargo'),
          fullWidth: true,
        };
      case 'evrak_kullanici_adi':
        return {
          name2,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form, 'evrak_kullanici.first_name', defaultValue) + ' ' + _result(props.form, 'evrak_kullanici.last_name', defaultValue),
          error: _has(props.error?.evrak, `${name2}`),
          fullWidth: true,
        };
      default:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(props.form, `${name}`) || defaultValue,
          error: _has(props.error?.evrak, `${name}`),
          fullWidth: true,
        };
    }
  }, [props.error, props.form, props.paraKoduList]);

  const createTextFieldPropsAdres = useCallback((name, index, defaultValue = '') => {
    if (defaultValue === 'uzunadres') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.adresler[index], `${name}.adres`, '') + " \n" + _result(props.adresler[index], `${name}.ilce`, '') + _result(props.adresler[index], `${name}.sehir`, '') + _result(props.adresler[index], `${name}.ulke`, '') || '',
        error: _has(props.error?.adresler, `${name}`),
        helperText: _result(props.error?.adres?.[index], `${name}[0]`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.adresler[index], `${name}`) || defaultValue,
        error: _has(props.error?.adresler, `${name}`),
        helperText: _result(props.error?.adres?.[index], `${name}[0]`),
        fullWidth: true,
      };
    }
  }, [props.error, props.adresler]);

  const createTextFieldPropsUrun = useCallback((index, name, name2, u, defaultValue = '') => {
    switch (name) {
      case 'birim_carpan':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(u, `${name}`) + ' ' + _result(u, `${name2}`) || defaultValue,
          error: _has(props.error?.urun?.[index], `${name}`),
          fullWidth: true,
        };
      case 'urun':
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(u[name], `${name2}`) || defaultValue,
          error: _has(props.error?.urun?.[index], `${name}`),
          fullWidth: true,
        };
      default:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(u, `${name}`) || defaultValue,
          error: _has(props.error?.urun?.[index], `${name}`),
          fullWidth: true,
        };
    }
  }, [props.error]);

  const createTextFieldPropsEkAlan = useCallback((item, name, type, index) => {
    switch (type) {
      case 0:
      case 3:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
        }
      case 2:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
          select: true,
          SelectProps: {
            native: true,
            readOnly: item.disabled
          }
        }
      case 1:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
          type: "number",
          inputProps: {
            style: { textAlign: 'right' }
          }
        }
      case 4:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
          rows: 3,
          multiline: true
        }
      case 5:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
          type: "date",
        }
      case 6:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
          type: "datetime-local"
        }
      case 7:
        return {
          name,
          variant: 'outlined',
          size: 'small',
          value: _result(item, "veri") || "",
          error: _has(props.error?.ek_bilgi?.[index], "veri"),
          fullWidth: true,
          type: "time"
        }
      default:
        break;
    }
  }, [props.error]);

  const paralist = useMemo(() => {
    const list = [<option value="-1" key="-1" disabled>Seçilmedi</option>];
    props.paraBirimList.forEach((pb) => {
      list.push(<option value={pb.id} key={pb.id}>{pb.tanim}</option>)
    });
    return list;
  }, [props.paraBirimList]);

  const handleAddNewRow = () => {
    const res = props.addNew();
    if (res === true) {
      const index = LISTELENGTH;
      setSelectedRow(index);
      setLastRow(index);
      setScrollNew(index);
    }
  }

  const handleDeleteRow = (di) => {
    const index = selectedRow;
    const length = LISTELENGTH;

    const deleteRow = async () => {
      if (codeSearchOpen || nameSearchOpen) {
        const sindex = filtredList.findIndex(x => x.index === di);
        setFiltredList((prev) => immer(prev, (next) => {
          next.splice(sindex, 1);
        }));
      }
      props.deleteRow(di);
    }
    const setRows = () => {
      if (codeSearchOpen || nameSearchOpen) {
        setSelectedRow(null);
        setLastRow(null);
      } else {
        if (length > 1) {
          setSelectedRow(index - 1);
          setLastRow(length - 2);
        } else if (length === 1) {
          setSelectedRow(null);
          setLastRow(null);
        } else {
          setSelectedRow(null);
          setLastRow(null);
        }
      }
    }
    deleteRow().then(() => setRows());
  }

  const handleErrors = (errors) => {
    props.onCheckErrors("evrak", errors, null, null, null, null, null);
  }

  const handleErrors2 = (errors, name2 = null, index = null, length = null) => {
    props.onCheckErrors("urun", errors, selectedRow, LISTELENGTH, name2, index, length)
  }

  const handleErrors3 = (name, errors, index, length) => {
    props.onCheckErrors(name, errors, index, length, null, null, null)
  }

  const searchList = (code = queryCode, tanim = queryName) => {
    let filter = [];
    const mainList = [...URUNLISTE];

    if (code !== '' && tanim !== '') {
      setOpenFilter(true);
      filter = mainList.filter((x) => x.urun && (turkishToLower(x.urun.kodu).includes(turkishToLower(code)) && turkishToLower(x.urun.tanim).includes(turkishToLower(tanim))))
    }
    if (code !== '' && tanim === '') {
      setOpenFilter(true);
      filter = mainList.filter((x) => x.urun && (turkishToLower(x.urun.kodu).includes(turkishToLower(code))))
    }
    if (code === '' && tanim !== '') {
      setOpenFilter(true);
      filter = mainList.filter((x) => x.urun && (turkishToLower(x.urun.tanim).includes(turkishToLower(tanim))))
    }
    if (code === '' && tanim === '') {
      setOpenFilter(false);
      filter = [];
    }
    setFiltredList(filter);
  }

  const handleSearch = (index, val) => {
    let arr = [...searchItems];
    arr[index] = val;
    setSearchItems(arr);
  }

  const ekBilgiRender = (ek_bilgi, index) => {
    const magazaVar = ek_bilgi.alan_magazalar.includes(props.form.magaza.id);
    const tipVar = ek_bilgi.alan_iliski_tip.includes(Number(props.form.tip)) || ek_bilgi.alan_iliski_tip.includes(Number(props.form.old_tip));
    if (magazaVar && tipVar) {
      switch (ek_bilgi.alan_tip) {
        case 0:
        case 3:
          return (
            <li className={classes.layoutFormItem} key={`ekbilgi-${ek_bilgi.ek_alan}`} id={ek_bilgi.tanim}>
              <label className={classes.layoutFormItemLabel} htmlFor={ek_bilgi.tanim}>{ek_bilgi.tanim}</label>
              <Tooltip title={props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index]?.veri ? props.error.urun[index].ek_bilgi[ek_bilgi.index].veri[0] : _isNil(ek_bilgi.veri) ? '' : ek_bilgi.veri}>
                <TextField
                  name='ek_bilgi'
                  variant='outlined'
                  size='small'
                  fullWidth={true}
                  value={ek_bilgi.veri || ''}
                  error={_has(props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index], "veri")}
                  onChange={(e) => props.onChangeUrun(index, 'ek_bilgi', e.target.value, false, ek_bilgi.index)}
                  onBlur={() => {
                    if (ek_bilgi.zorunlu) {
                      handleInputErrors('', ek_bilgi, ek_bilgi, (errors) => handleErrors2(errors, "ek_bilgi", ek_bilgi.index, ek_bilgi.length), add, 'veri', true, false, false, '', '', '');
                    }
                  }}
                  InputProps={{
                    readOnly: ek_bilgi.disabled
                  }}
                ></TextField>
              </Tooltip>
            </li>
          )
        case 2:
          return (
            <li className={classes.layoutFormItem} key={`ekbilgi-${ek_bilgi.ek_alan}`} id={ek_bilgi.tanim}>
              <label className={classes.layoutFormItemLabel} htmlFor={ek_bilgi.tanim}>{ek_bilgi.tanim}</label>
              <Tooltip title={props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index]?.veri ? props.error.urun[index].ek_bilgi[ek_bilgi.index].veri[0] : _isNil(ek_bilgi.veri) ? '' : ek_bilgi.veri}>
                <TextField
                  name='ek_bilgi'
                  variant='outlined'
                  size='small'
                  fullWidth={true}
                  value={ek_bilgi.veri || ''}
                  error={_has(props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index], "veri")}
                  //onChange={(e) => props.onChangeUrun(index, 'ek_bilgi', e.target.value, false, ek_bilgi.index)}
                  onBlur={() => {
                    if (ek_bilgi.zorunlu) {
                      handleInputErrors('', ek_bilgi, ek_bilgi, (errors) => handleErrors2(errors, "ek_bilgi", ek_bilgi.index, ek_bilgi.length), add, 'veri', true, false, false, '', '', '');
                    }
                  }}
                  select
                  SelectProps={{
                    native: true,
                    readOnly: ek_bilgi.disabled
                  }}
                >
                  {ek_bilgi.secim_degerleri?.map((sd) => (
                    <option value={sd} key={sd}>{sd}</option>
                  ))}
                </TextField>
              </Tooltip>
            </li>
          )
        case 1:
          return (
            <li className={classes.layoutFormItem} key={`ekbilgi-${ek_bilgi.ek_alan}`} id={ek_bilgi.tanim}>
              <label className={classes.layoutFormItemLabel} htmlFor={ek_bilgi.tanim}>{ek_bilgi.tanim}</label>
              <Tooltip title={props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index]?.veri ? props.error.urun[index].ek_bilgi[ek_bilgi.index].veri[0] : _isNil(ek_bilgi.veri) ? '' : ek_bilgi.veri}>
                <TextField
                  name='ek_bilgi'
                  variant='outlined'
                  size='small'
                  fullWidth={true}
                  value={ek_bilgi.veri || ''}
                  error={_has(props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index], "veri")}
                  type="number"
                  inputProps={{
                    style: { textAlign: 'right' }
                  }}
                  onChange={(e) => props.onChangeUrun(index, 'ek_bilgi', e.target.value, false, ek_bilgi.index)}
                  onBlur={() => {
                    if (ek_bilgi.zorunlu) {
                      handleInputErrors('', ek_bilgi, ek_bilgi, (errors) => handleErrors2(errors, "ek_bilgi", ek_bilgi.index, ek_bilgi.length), add, 'veri', true, false, false, '', '', '');
                    }
                  }}
                  InputProps={{
                    readOnly: ek_bilgi.disabled
                  }}
                ></TextField>
              </Tooltip>
            </li>
          )
        case 4:
          return (
            <li className={classes.layoutFormItem} key={`ekbilgi-${ek_bilgi.ek_alan}`} id={ek_bilgi.tanim}>
              <label className={classes.layoutFormItemLabel} htmlFor={ek_bilgi.tanim}>{ek_bilgi.tanim}</label>
              <Tooltip title={props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index]?.veri ? props.error.urun[index].ek_bilgi[ek_bilgi.index].veri[0] : _isNil(ek_bilgi.veri) ? '' : ek_bilgi.veri}>
                <TextField
                  name='ek_bilgi'
                  variant='outlined'
                  size='small'
                  fullWidth={true}
                  value={ek_bilgi.veri || ''}
                  error={_has(props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index], "veri")}
                  multiline
                  rows={3}
                  onChange={(e) => props.onChangeUrun(index, 'ek_bilgi', e.target.value, false, ek_bilgi.index)}
                  onBlur={() => {
                    if (ek_bilgi.zorunlu) {
                      handleInputErrors('', ek_bilgi, ek_bilgi, (errors) => handleErrors2(errors, "ek_bilgi", ek_bilgi.index, ek_bilgi.length), add, 'veri', true, false, false, '', '', '');
                    }
                  }}
                  InputProps={{
                    readOnly: ek_bilgi.disabled
                  }}
                ></TextField>
              </Tooltip>
            </li>
          )
        case 5:
          return (
            <li className={classes.layoutFormItem} key={`ekbilgi-${ek_bilgi.ek_alan}`} id={ek_bilgi.tanim}>
              <label className={classes.layoutFormItemLabel} htmlFor={ek_bilgi.tanim}>{ek_bilgi.tanim}</label>
              <Tooltip title={props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index]?.veri ? props.error.urun[index].ek_bilgi[ek_bilgi.index].veri[0] : _isNil(ek_bilgi.veri) ? '' : moment(ek_bilgi.veri).format('DD/MM/YYYY')}>
                <TextField
                  name='ek_bilgi'
                  variant='outlined'
                  size='small'
                  fullWidth={true}
                  value={ek_bilgi.veri || ''}
                  error={_has(props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index], "veri")}
                  type="date"
                  onChange={(e) => props.onChangeUrun(index, 'ek_bilgi', e.target.value, false, ek_bilgi.index)}
                  onBlur={() => {
                    if (ek_bilgi.zorunlu) {
                      handleInputErrors('', ek_bilgi, ek_bilgi, (errors) => handleErrors2(errors, "ek_bilgi", ek_bilgi.index, ek_bilgi.length), add, 'veri', true, false, false, '', '', '');
                    }
                  }}
                  InputProps={{
                    readOnly: ek_bilgi.disabled
                  }}
                ></TextField>
              </Tooltip>
            </li>
          )
        case 6:
          return (
            <li className={classes.layoutFormItem} key={`ekbilgi-${ek_bilgi.ek_alan}`} id={ek_bilgi.tanim}>
              <label className={classes.layoutFormItemLabel} htmlFor={ek_bilgi.tanim}>{ek_bilgi.tanim}</label>
              <Tooltip title={props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index]?.veri ? props.error.urun[index].ek_bilgi[ek_bilgi.index].veri[0] : _isNil(ek_bilgi.veri) ? '' : moment(ek_bilgi.veri).format('DD/MM/YYYY, HH.mm')}>
                <TextField
                  name='ek_bilgi'
                  variant='outlined'
                  size='small'
                  fullWidth={true}
                  value={ek_bilgi.veri || ''}
                  error={_has(props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index], "veri")}
                  type="datetime-local"
                  onChange={(e) => props.onChangeUrun(index, 'ek_bilgi', e.target.value, false, ek_bilgi.index)}
                  onBlur={() => {
                    if (ek_bilgi.zorunlu) {
                      handleInputErrors('', ek_bilgi, ek_bilgi, (errors) => handleErrors2(errors, "ek_bilgi", ek_bilgi.index, ek_bilgi.length), add, 'veri', true, false, false, '', '', '');
                    }
                  }}
                  InputProps={{
                    readOnly: ek_bilgi.disabled
                  }}
                ></TextField>
              </Tooltip>
            </li>
          )
        case 7:
          return (
            <li className={classes.layoutFormItem} key={`ekbilgi-${ek_bilgi.ek_alan}`} id={ek_bilgi.tanim}>
              <label className={classes.layoutFormItemLabel} htmlFor={ek_bilgi.tanim}>{ek_bilgi.tanim}</label>
              <Tooltip title={props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index]?.veri ? props.error.urun[index].ek_bilgi[ek_bilgi.index].veri[0] : _isNil(ek_bilgi.veri) ? '' : moment(ek_bilgi.veri).format('HH/MM')}>
                <TextField
                  name='ek_bilgi'
                  variant='outlined'
                  size='small'
                  fullWidth={true}
                  value={ek_bilgi.veri || ''}
                  error={_has(props.error?.urun?.[index]?.ek_bilgi?.[ek_bilgi.index], "veri")}
                  type="time"
                  onChange={(e) => props.onChangeUrun(index, 'ek_bilgi', e.target.value, false, ek_bilgi.index)}
                  onBlur={() => {
                    if (ek_bilgi.zorunlu) {
                      handleInputErrors('', ek_bilgi, ek_bilgi, (errors) => handleErrors2(errors, "ek_bilgi", ek_bilgi.index, ek_bilgi.length), add, 'veri', true, false, false, '', '', '');
                    }
                  }}
                  InputProps={{
                    readOnly: ek_bilgi.disabled
                  }}
                ></TextField>
              </Tooltip>
            </li>
          )
        default:
          break;
      }
    }
  }

  const rowRenderer = ({ index, key, style }) => {
    const item = URUNLISTE[index];
    return (
      <div key={key} style={style} className={`${classes.tableFormRow} ${selectedRow === index ? classes.selectedRow : ''}`} onClick={() => {
        setSelectedRow(index);
        if (lastRow === null) {
          setLastRow(index)
        }
      }}>
        <div className="flex justify-start items-center w-max">
          {/* <div className={classes.tableFormItem} style={{ width: 25 }}>
            <Tooltip title="Sil">
              <IconButton
                size="small"
                disableRipple
                onClick={() => handleDeleteRow(index)}
              >
                <IconDelete />
              </IconButton>
            </Tooltip>
          </div> */}
          <div style={{ position: "relative", width: 200 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={searchItems[index] ? '' : props.error?.urun?.[index]?.urun ? props.error.urun[index].urun[0] : _isNil(item.urun?.kodu) ? '' : item.urun.kodu}>
                <TextField
                  {...createTextFieldPropsUrun(index, 'urun', 'kodu', item)}
                  // onChange={(e) => {
                  //   props.onChangeUrun(index, 'urun', e.target.value, true)
                  //   handleSearch(index, e.target.value)
                  // }}
                  // InputProps={{
                  //   endAdornment:
                  //     <IconButton
                  //       size="small"
                  //       className={classes.button}
                  //       disableRipple
                  //       onClick={() => setOpenSelectorUrun(true)}
                  //     ><IconSearch /></IconButton>
                  // }}
                  // autoComplete="off"
                  // onKeyDown={(e) => {
                  //   if (e.keyCode === 40 && searchItems[index]) {
                  //     const el = elementRef[index].current
                  //     if (el) {
                  //       elementRef[index].current.focus()
                  //     }
                  //   }
                  // }}
                  // inputRef={inputRef[index]}
                  InputProps={{
                    readOnly: true
                  }}
                ></TextField>
              </Tooltip>
            </div>
          </div>
          <div style={{ width: 200 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.urun ? props.error.urun[index].urun[0] : _isNil(item.urun?.tanim) ? '' : item.urun.tanim}>
                <TextField
                  {...createTextFieldPropsUrun(index, 'urun', 'tanim', item)}
                  InputProps={{
                    readOnly: true
                  }}
                ></TextField>
              </Tooltip>
            </div>
          </div>
          <div style={{ width: 100 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.birim ? props.error.urun[index].birim[0] : _isNil(item?.birim) ? '' : item?.birimDic[item.birim]}>
                <TextField
                  {...createTextFieldPropsUrun(index, 'birim', '', item)}
                  // onChange={(e) => props.onChangeUrun(index, 'birim', e.target.value, false)}
                  //onClick={() => props.getUrunBirimList(index, item.urun?.id, item.birim_cekildi)}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                >
                  {item.birimliste?.map((b) => (
                    <option value={b.id} key={b.id}>{b.tanim}</option>
                  ))}
                </TextField>
              </Tooltip>
            </div>
          </div>
          <div style={{ width: 100 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.birim_carpan ? props.error.urun[index].birim_carpan[0] : _isNil(item?.birim_carpan) ? '' : item.birim_carpan + " " + item.birim_kodu}>
                <TextField
                  {...createTextFieldPropsUrun(index, 'birim_carpan', 'birim_kodu', item)}
                  inputProps={{
                    style: { textAlign: 'right' }
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                ></TextField>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.miktar ? props.error.urun[index].miktar[0] : _isNil(item?.miktar) ? '' : MoneyFormatConvert(item.miktar, "money") + " " + item.miktar_kodu}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(index, 'miktar', '', item)}
                    onValueChange={(e) => props.onChangeUrun(index, 'miktar', e.value, false)}
                    onBlur={() => handleInputErrors('', item, item, handleErrors2, add, 'miktar', true, false, false, '', '', '')}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography variant="inherit" style={{ pointerEvents: "none", paddingLeft: 2 }} >{' ' + item.miktar_kodu || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          {vergibaslik?.map((b, index2) => (
            <div style={{ width: 60 }} className={classes.tableFormItem} key={`vergi-${index2}`}>
              <div className="flex items-center">
                <Tooltip title={props.error?.urun?.[index]?.urun_detay_vergi ? props.error.urun[index].urun_detay_vergi[0] : item.urun_detay_vergi.find((v) => (v.vergi.vergi_tip.tanim + ' ' + v.vergi.tanim) === b.vergi_adi) ? item.urun_detay_vergi.find((v) => (v.vergi.vergi_tip.tanim + ' ' + v.vergi.tanim) === b.vergi_adi).vergi_oran : ''}>
                  <TextField
                    name='vergi'
                    variant='outlined'
                    size='small'
                    fullWidth={true}
                    value={item.urun_detay_vergi.find((v) => (v.vergi.vergi_tip.tanim + ' ' + v.vergi.tanim) === b.vergi_adi) ? item.urun_detay_vergi.find((v) => (v.vergi.vergi_tip.tanim + ' ' + v.vergi.tanim) === b.vergi_adi).vergi_oran : ''}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      readOnly: true
                    }}
                  ></TextField>
                </Tooltip>
              </div>
            </div>
          ))
          }
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.birim_fiyat ? props.error.urun[index].birim_fiyat[0] : _isNil(item?.birim_fiyat) ? '' : MoneyFormatConvert(item.birim_fiyat, "money") + " " + props.paraKoduList[item.para_birimi ?? '-1']}>
                <span className="flex w-full">
                  <NumericFormat
                    {...createTextFieldPropsUrun(index, 'birim_fiyat', '', item)}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[item.para_birimi ?? '-1'] || ''}</Typography>,
                      readOnly: true
                    }}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.satis_fiyat ? props.error.urun[index].satis_fiyat[0] : _isNil(item?.satis_fiyat) ? '' : MoneyFormatConvert(item.satis_fiyat, "money") + " " + props.paraKoduList[item.satis_para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(index, 'satis_fiyat', '', item)}
                    onValueChange={(e) => props.onChangeUrun(index, 'satis_fiyat', e.value, false)}
                    onBlur={() => handleInputErrors('', item, item, handleErrors2, add, 'satis_fiyat', true, false, false, '', '', '')}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[item.satis_para_birimi] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          {iskontobaslik?.map((_, index2) => (
            <div style={{ width: 70 }} className={classes.tableFormItem} key={`iskonto-${index2}`}>
              <div className="flex items-center">
                <Tooltip title={props.error?.urun?.[index]?.urun_detay_iskonto ? props.error.urun[index].urun_detay_iskonto[0] : item.urun_detay_iskonto[index2] ? item.urun_detay_iskonto[index2].iskonto_tutar !== '0.0000' ? MoneyFormatConvert(item.urun_detay_iskonto[index2].iskonto_tutar, "money") + " " + props.paraKoduList[item.satis_para_birimi] : item.urun_detay_iskonto[index2].iskonto_oran + ' %' : ""}>
                  <TextField
                    name='vergi'
                    variant='outlined'
                    size='small'
                    fullWidth={true}
                    value={item.urun_detay_iskonto[index2] ? item.urun_detay_iskonto[index2].iskonto_tutar !== '0.0000' ? MoneyFormatConvert(item.urun_detay_iskonto[index2].iskonto_tutar, "money") + " " + props.paraKoduList[item.satis_para_birimi] : item.urun_detay_iskonto[index2].iskonto_oran + ' %' : ""}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      readOnly: true
                    }}
                  ></TextField>
                </Tooltip>
              </div>
            </div>
          ))
          }
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.iskontolu_satis_fiyat ? props.error.urun[index].iskontolu_satis_fiyat[0] : _isNil(item?.iskontolu_satis_fiyat) ? '' : MoneyFormatConvert(item.iskontolu_satis_fiyat, "money") + " " + props.paraKoduList[item.satis_para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(index, 'iskontolu_satis_fiyat', '', item)}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[item.satis_para_birimi ?? '-1'] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.satis_para_birimi ? props.error.urun[index].satis_para_birimi[0] : (_isNil(item?.satis_para_birimi) || item?.satis_para_birimi === '-1') ? '' : props.paraBirimDic[item.satis_para_birimi]}>
                <TextField
                  {...createTextFieldPropsUrun(index, 'satis_para_birimi', '', item)}
                  // onChange={(e) => props.onChangeUrun(index, 'satis_para_birimi', e.target.value, false)}
                  // onBlur={() => handleInputErrors('', item, item, handleErrors2, add, 'satis_para_birimi', true, false, false, '', '', '')}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                >{paralist}
                </TextField>
              </Tooltip>
            </div>
          </div>
          <div style={{ width: 100 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.urun_doviz_kur ? props.error.urun[index].urun_doviz_kur[0] : _isNil(item?.urun_doviz_kur) ? '' : MoneyFormatConvert(item.urun_doviz_kur, "money") + " " + props.paraKoduList[props.form?.magaza_para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(index, 'urun_doviz_kur', '', item)}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form?.magaza_para_birimi ?? "-1"] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div style={{ width: 100 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.cari_doviz_kur ? props.error.urun[index].cari_doviz_kur[0] : _isNil(item?.cari_doviz_kur) ? '' : MoneyFormatConvert(item.cari_doviz_kur, "money") + " " + props.paraKoduList[props.form?.magaza_para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(index, 'cari_doviz_kur', '', item)}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form?.magaza_para_birimi ?? "-1"] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.net_satis_fiyat ? props.error.urun[index].net_satis_fiyat[0] : _isNil(item?.net_satis_fiyat) ? '' : MoneyFormatConvert(item.net_satis_fiyat, "money") + " " + props.paraKoduList[props.form?.para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(index, 'net_satis_fiyat', '', item)}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form?.para_birimi ?? "-1"] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.net_tutar ? props.error.urun[index].net_tutar[0] : _isNil(item?.net_tutar) ? '' : MoneyFormatConvert(item.net_tutar, "money") + " " + props.paraKoduList[props.form?.para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(index, 'net_tutar', '', item)}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form?.para_birimi ?? "-1"] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[index]?.tutar ? props.error.urun[index].tutar[0] : _isNil(item?.tutar) ? '' : MoneyFormatConvert(item.tutar, "money") + " " + props.paraKoduList[props.form?.magaza_para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(index, 'tutar', '', item)}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form?.magaza_para_birimi ?? "-1"] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem} style={{ width: 50 }}>
            <div className="flex items-center justify-center">
              <Tooltip title={props.error?.urun?.[index]?.ek_bilgi ? "Bu alanda eksik veri var." : "Satır Ek Bilgi"}>
                <IconButton
                  size="small"
                  className={props.error?.urun?.[index]?.ek_bilgi ? classes.button3Err : classes.button3}
                  disableRipple
                  onClick={() => setOpenSelectorSatirEkBilgi(true)}
                >
                  <IconAdditionalInfo />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {/* <div className={classes.tableFormItem} style={{ width: 50 }}>
            <div className="flex items-center justify-center">
              <Tooltip title="Satır İskonto">
                <IconButton
                  size="small"
                  className={classes.button3}
                  disableRipple
                  onClick={() => setOpenSelectorSaleManager(true)}
                >
                  <IconSale />
                </IconButton>
              </Tooltip>
            </div>
          </div> */}
        </div>
      </div >
    )
  }

  const rowRendererSearch = ({ index, key, style }) => {
    const searchitem = filtredList[index];
    const item = URUNLISTE.find(x => x.index === searchitem.index);
    return (
      <div key={key} style={style} className={`${classes.tableFormRow} ${selectedRow === item.index ? classes.selectedRow : ''}`} onClick={() => {
        setSelectedRowSearch(index);
        setSelectedRow(item.index);
        if (lastRow === null) {
          setLastRow(item.index)
        }
      }}>
        <div className="flex justify-start items-center w-max">
          {/* <div className={classes.tableFormItem} style={{ width: 25 }}>
            <Tooltip title="Sil">
              <IconButton
                size="small"
                disableRipple
                onClick={() => handleDeleteRow(item.index)}
              >
                <IconDelete />
              </IconButton>
            </Tooltip>
          </div> */}
          <div style={{ position: "relative", width: 200 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.urun ? props.error.urun[item.index].urun[0] : _isNil(item.urun?.kodu) ? '' : item.urun.kodu}>
                <TextField
                  {...createTextFieldPropsUrun(item.index, 'urun', 'kodu', item)}
                  // onChange={(e) => {
                  //   props.onChangeUrun(item.index, 'urun', e.target.value, true)
                  //   handleSearch(item.index, e.target.value)
                  // }}
                  // InputProps={{
                  //   endAdornment:
                  //     <IconButton
                  //       size="small"
                  //       className={classes.button}
                  //       disableRipple
                  //       onClick={() => setOpenSelectorUrun(true)}
                  //     ><IconSearch /></IconButton>
                  // }}
                  // autoComplete="off"
                  // onKeyDown={(e) => {
                  //   if (e.keyCode === 40 && searchItems[item.index]) {
                  //     const el = elementRef[item.index].current
                  //     if (el) {
                  //       elementRef[item.index].current.focus()
                  //     }
                  //   }
                  // }}
                  // inputRef={inputRef[item.index]}
                  InputProps={{
                    readOnly: true
                  }}
                ></TextField>
              </Tooltip>
            </div>
          </div>
          <div style={{ width: 200 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.urun ? props.error.urun[item.index].urun[0] : _isNil(item.urun?.tanim) ? '' : item.urun.tanim}>
                <TextField
                  {...createTextFieldPropsUrun(item.index, 'urun', 'tanim', item)}
                  InputProps={{
                    readOnly: true
                  }}
                ></TextField>
              </Tooltip>
            </div>
          </div>
          <div style={{ width: 100 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.birim ? props.error.urun[item.index].birim[0] : _isNil(item?.birim) ? '' : item?.birimDic[item.birim]}>
                <TextField
                  {...createTextFieldPropsUrun(item.index, 'birim', '', item)}
                  // onChange={(e) => props.onChangeUrun(item.index, 'birim', e.target.value, false)}
                  //onClick={() => props.getUrunBirimList(index, item.urun?.id, item.birim_cekildi)}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                >
                  {item.birimliste?.map((b) => (
                    <option value={b.id} key={b.id}>{b.tanim}</option>
                  ))}
                </TextField>
              </Tooltip>
            </div>
          </div>
          <div style={{ width: 100 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.birim_carpan ? props.error.urun[item.index].birim_carpan[0] : _isNil(item?.birim_carpan) ? '' : item.birim_carpan + " " + item.birim_kodu}>
                <TextField
                  {...createTextFieldPropsUrun(item.index, 'birim_carpan', 'birim_kodu', item)}
                  inputProps={{
                    style: { textAlign: 'right' }
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                ></TextField>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.miktar ? props.error.urun[item.index].miktar[0] : _isNil(item?.miktar) ? '' : MoneyFormatConvert(item.miktar, "money") + " " + item.miktar_kodu}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(item.index, 'miktar', '', item)}
                    onValueChange={(e) => props.onChangeUrun(item.index, 'miktar', e.value, false)}
                    onBlur={() => handleInputErrors('', item, item, handleErrors2, add, 'miktar', true, false, false, '', '', '')}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography variant="inherit" style={{ pointerEvents: "none", paddingLeft: 2 }} >{' ' + item.miktar_kodu || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          {vergibaslik?.map((b, index) => (
            <div style={{ width: 60 }} className={classes.tableFormItem} key={`vergi-${index}`}>
              <div className="flex items-center">
                <Tooltip title={props.error?.urun?.[item.index]?.urun_detay_vergi ? props.error.urun[item.index].urun_detay_vergi[0] : item.urun_detay_vergi.find((v) => (v.vergi.vergi_tip.tanim + ' ' + v.vergi.tanim) === b.vergi_adi) ? item.urun_detay_vergi.find((v) => (v.vergi.vergi_tip.tanim + ' ' + v.vergi.tanim) === b.vergi_adi).vergi_oran : ''}>
                  <TextField
                    name='vergi'
                    variant='outlined'
                    size='small'
                    fullWidth={true}
                    value={item.urun_detay_vergi.find((v) => (v.vergi.vergi_tip.tanim + ' ' + v.vergi.tanim) === b.vergi_adi) ? item.urun_detay_vergi.find((v) => (v.vergi.vergi_tip.tanim + ' ' + v.vergi.tanim) === b.vergi_adi).vergi_oran : ''}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                  ></TextField>
                </Tooltip>
              </div>
            </div>
          ))
          }
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.birim_fiyat ? props.error.urun[item.index].birim_fiyat[0] : _isNil(item?.birim_fiyat) ? '' : MoneyFormatConvert(item.birim_fiyat, "money") + " " + props.paraKoduList[item.para_birimi ?? '-1']}>
                <span className="flex w-full">
                  <NumericFormat
                    {...createTextFieldPropsUrun(item.index, 'birim_fiyat', '', item)}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[item.para_birimi ?? '-1'] || ''}</Typography>,
                      readOnly: true
                    }}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.satis_fiyat ? props.error.urun[item.index].satis_fiyat[0] : _isNil(item?.satis_fiyat) ? '' : MoneyFormatConvert(item.satis_fiyat, "money") + " " + props.paraKoduList[item.satis_para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(item.index, 'satis_fiyat', '', item)}
                    onValueChange={(e) => props.onChangeUrun(item.index, 'satis_fiyat', e.value, false)}
                    onBlur={() => handleInputErrors('', item, item, handleErrors2, add, 'satis_fiyat', true, false, false, '', '', '')}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[item.satis_para_birimi] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          {iskontobaslik?.map((_, index) => (
            <div style={{ width: 70 }} className={classes.tableFormItem} key={`iskonto-${index}`}>
              <div className="flex items-center">
                <Tooltip title={props.error?.urun?.[item.index]?.urun_detay_iskonto ? props.error.urun[item.index].urun_detay_iskonto[0] : item.urun_detay_iskonto[index] ? item.urun_detay_iskonto[index].iskonto_tutar !== '0.0000' ? MoneyFormatConvert(item.urun_detay_iskonto[index].iskonto_tutar, "money") + " " + props.paraKoduList[item.satis_para_birimi] : item.urun_detay_iskonto[index].iskonto_oran + ' %' : ""}>
                  <TextField
                    name='vergi'
                    variant='outlined'
                    size='small'
                    fullWidth={true}
                    value={item.urun_detay_iskonto[index] ? item.urun_detay_iskonto[index].iskonto_tutar !== '0.0000' ? MoneyFormatConvert(item.urun_detay_iskonto[index].iskonto_tutar, "money") + " " + props.paraKoduList[item.satis_para_birimi] : item.urun_detay_iskonto[index].iskonto_oran + ' %' : ""}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                  ></TextField>
                </Tooltip>
              </div>
            </div>
          ))
          }
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.iskontolu_satis_fiyat ? props.error.urun[item.index].iskontolu_satis_fiyat[0] : _isNil(item?.iskontolu_satis_fiyat) ? '' : MoneyFormatConvert(item.iskontolu_satis_fiyat, "money") + " " + props.paraKoduList[item.satis_para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(item.index, 'iskontolu_satis_fiyat', '', item)}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[item.satis_para_birimi ?? '-1'] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.satis_para_birimi ? props.error.urun[item.index].satis_para_birimi[0] : (_isNil(item?.satis_para_birimi) || item?.satis_para_birimi === '-1') ? '' : props.paraBirimDic[item.satis_para_birimi]}>
                <TextField
                  {...createTextFieldPropsUrun(item.index, 'satis_para_birimi', '', item)}
                  // onChange={(e) => props.onChangeUrun(item.index, 'satis_para_birimi', e.target.value, false)}
                  // onBlur={() => handleInputErrors('', item, item, handleErrors2, add, 'satis_para_birimi', true, false, false, '', '', '')}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                >{paralist}
                </TextField>
              </Tooltip>
            </div>
          </div>
          <div style={{ width: 100 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.urun_doviz_kur ? props.error.urun[item.index].urun_doviz_kur[0] : _isNil(item?.urun_doviz_kur) ? '' : MoneyFormatConvert(item.urun_doviz_kur, "money") + " " + props.paraKoduList[props.form?.magaza_para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(item.index, 'urun_doviz_kur', '', item)}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form?.magaza_para_birimi ?? "-1"] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div style={{ width: 100 }} className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.cari_doviz_kur ? props.error.urun[item.index].cari_doviz_kur[0] : _isNil(item?.cari_doviz_kur) ? '' : MoneyFormatConvert(item.cari_doviz_kur, "money") + " " + props.paraKoduList[props.form?.magaza_para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(item.index, 'cari_doviz_kur', '', item)}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form?.magaza_para_birimi ?? "-1"] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.net_satis_fiyat ? props.error.urun[item.index].net_satis_fiyat[0] : _isNil(item?.net_satis_fiyat) ? '' : MoneyFormatConvert(item.net_satis_fiyat, "money") + " " + props.paraKoduList[props.form?.para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(item.index, 'net_satis_fiyat', '', item)}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form?.para_birimi ?? "-1"] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.net_tutar ? props.error.urun[item.index].net_tutar[0] : _isNil(item?.net_tutar) ? '' : MoneyFormatConvert(item.net_tutar, "money") + " " + props.paraKoduList[props.form?.para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(item.index, 'net_tutar', '', item)}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form?.para_birimi ?? "-1"] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem}>
            <div className="flex items-center">
              <Tooltip title={props.error?.urun?.[item.index]?.tutar ? props.error.urun[item.index].tutar[0] : _isNil(item?.tutar) ? '' : MoneyFormatConvert(item.tutar, "money") + " " + props.paraKoduList[props.form?.magaza_para_birimi ?? '-1']}>
                <span className='flex w-full'>
                  <NumericFormat
                    {...createTextFieldPropsUrun(item.index, 'tutar', '', item)}
                    inputProps={{
                      style: { textAlign: 'right' }
                    }}
                    InputProps={{
                      endAdornment: <Typography style={{ pointerEvents: "none", paddingLeft: 2 }} variant="inherit">{' ' + props.paraKoduList[props.form?.magaza_para_birimi ?? "-1"] || ''}</Typography>,
                      readOnly: true
                    }}
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=','
                    valueIsNumericString={true}
                  ></NumericFormat>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className={classes.tableFormItem} style={{ width: 50 }}>
            <div className="flex items-center justify-center">
              <Tooltip title={props.error?.urun?.[item.index]?.ek_bilgi ? "Bu alanda eksik veri var." : "Satır Ek Bilgi"}>
                <IconButton
                  size="small"
                  className={props.error?.urun?.[item.index]?.ek_bilgi ? classes.button3Err : classes.button3}
                  disableRipple
                  onClick={() => setOpenSelectorSatirEkBilgi(true)}
                >
                  <IconAdditionalInfo />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {/* <div className={classes.tableFormItem} style={{ width: 50 }}>
            <div className="flex items-center justify-center">
              <Tooltip title="Satır İskonto">
                <IconButton
                  size="small"
                  className={classes.button3}
                  disableRipple
                  onClick={() => setOpenSelectorSaleManager(true)}
                >
                  <IconSale />
                </IconButton>
              </Tooltip>
            </div>
          </div> */}
        </div>
      </div >
    )
  }

  return (
    <>
      {!LOADING && !LOADING_EK &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className="flex flex-col h-full w-full">
              <div className={classes.layoutContainer}>
                <div className={classes.borderLayout}>
                  {topOpen && (
                    <>
                      <ul className={classes.layoutFormList}>
                        <li className={classes.layoutFormItem} id="magaza">
                          <label className={classes.layoutFormItemLabel} htmlFor="magaza">Mağaza *</label>
                          <Tooltip title={props.error?.evrak?.magaza ? props.error.evrak.magaza[0] : _isNil(props.form?.magaza) ? '' : props.form.magaza.tanim}>
                            <TextField
                              {...createTextFieldPropsGenel('magaza', 'tanim')}
                              // InputProps={{
                              //   endAdornment:
                              //     <IconButton
                              //       size="small"
                              //       className={classes.button}
                              //       disableRipple
                              //       onClick={() => setOpenSelectorMagaza(true)}
                              //     ><IconSearch /></IconButton>
                              // }}
                              InputProps={{
                                readOnly: true
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="cikis_depo">
                          <label className={classes.layoutFormItemLabel} htmlFor="cikis_depo">Depo *</label>
                          <Tooltip title={props.error?.evrak?.cikis_depo ? props.error.evrak.cikis_depo[0] : _isNil(props.form?.cikis_depo) ? '' : props.form.cikis_depo.tanim}>
                            <TextField
                              {...createTextFieldPropsGenel('cikis_depo', 'tanim')}
                              // InputProps={{
                              //   endAdornment:
                              //     <IconButton
                              //       size="small"
                              //       className={classes.button}
                              //       disableRipple
                              //       onClick={() => setOpenSelectorCikisDepo(true)}
                              //     ><IconSearch /></IconButton>
                              // }}
                              InputProps={{
                                readOnly: true
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="versiyon">
                          <label className={classes.layoutFormItemLabel} htmlFor="versiyon">Versiyon</label>
                          <Tooltip title={props.error?.evrak?.versiyon ? props.error.evrak.versiyon[0] : _isNil(props.form?.versiyon) ? '' : props.form.versiyon}>
                            <TextField
                              {...createTextFieldPropsGenel('versiyon', '')}
                              InputProps={{
                                readOnly: true
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="old_kodu">
                          <label className={classes.layoutFormItemLabel} htmlFor="old_kodu">Ön Sipariş Evrak No *</label>
                          <Tooltip title={_isNil(props.form?.old_kodu) ? '' : props.form.old_kodu}>
                            <TextField
                              {...createTextFieldPropsGenel('old_kodu', '')}
                              InputProps={{
                                readOnly: true
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="kodu">
                          <label className={classes.layoutFormItemLabel} htmlFor="kodu">Sipariş Evrak No *</label>
                          <Tooltip title={props.error?.evrak?.kodu ? props.error.evrak.kodu[0] : _isNil(props.form?.kodu) ? '' : props.form.kodu}>
                            <TextField
                              {...createTextFieldPropsGenel('kodu', '')}
                              onChange={(e) => props.onChangeForm('kodu', e.target.value)}
                              onBlur={() => handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'kodu', true, true, false, '', 'Evrak No', 'evrak')}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="evrak_tarihi">
                          <label className={classes.layoutFormItemLabel} htmlFor="evrak_tarihi">Evrak Tarihi *</label>
                          <Tooltip title={props.error?.evrak?.evrak_tarihi ? props.error.evrak.evrak_tarihi[0] : _isNil(props.form?.evrak_tarihi) ? '' : moment(props.form.evrak_tarihi).format('DD/MM/YYYY, HH.mm')}>
                            <TextField
                              {...createTextFieldPropsGenel('evrak_tarihi', '')}
                              type="datetime-local"
                              // onChange={(e) => props.onChangeForm('evrak_tarihi', e.target.value)}
                              // onBlur={() => handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'evrak_tarihi', true, false, '', '')}
                              InputProps={{
                                readOnly: true
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="tip">
                          <label className={classes.layoutFormItemLabel} htmlFor="tip">Evrak Tipi *</label>
                          <Tooltip title={'Alış Ön Sipariş'}>
                            <TextField
                              {...createTextFieldPropsGenel('tip', '')}
                              select
                              SelectProps={{
                                native: true,
                              }}
                              InputProps={{
                                readOnly: true
                              }}
                            >
                              <option key='6' value='6'>Alış Ön Sipariş</option>
                            </TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="secilen_kargo">
                          <label className={classes.layoutFormItemLabel} htmlFor="secilen_kargo">Teslimat Şekli</label>
                          <Tooltip title={props.error?.kargo?.[0]?.kargo ? props.error.kargo[0].kargo[0] : _isNil(props.form?.secilen_kargo) ? '' : props.form.secilen_kargo.tanim}>
                            <TextField
                              {...createTextFieldPropsGenel('secilen_kargo', 'tanim')}
                              // InputProps={{
                              //   endAdornment:
                              //     <IconButton
                              //       size="small"
                              //       className={classes.button}
                              //       disableRipple
                              //       onClick={() => setOpenSelectorKargo(true)}
                              //     ><IconSearch /></IconButton>
                              // }}
                              InputProps={{
                                readOnly: true
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="evrak_durum.durum">
                          <label className={classes.layoutFormItemLabel} htmlFor="evrak_durum.durum">Evrak Durum</label>
                          <Tooltip title={props.error?.evrak?.evrak_durum?.durum ? props.error.evrak.evrak_durum.durum[0] : _isNil(props.form?.evrak_durum?.durum) ? '' : props.form.evrak_durum.durum.tanim}>
                            <TextField
                              {...createTextFieldPropsGenel('evrak_durum', 'durum')}
                              InputProps={{
                                endAdornment:
                                  <IconButton
                                    size="small"
                                    className={classes.button}
                                    disableRipple
                                    onClick={() => setOpenSelectorDurum(true)}
                                  ><IconSearch /></IconButton>
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                      </ul>

                      <ul className={classes.layoutFormList}>
                        <li className={classes.layoutFormItem} id="evrak_kullanici">
                          <label className={classes.layoutFormItemLabel} htmlFor="evrak_kullanici">Cari Kod *</label>
                          <Tooltip title={props.error?.evrak?.evrak_kullanici ? props.error.evrak.evrak_kullanici[0] : _isNil(props.form?.evrak_kullanici) ? '' : props.form.evrak_kullanici.kodu}>
                            <TextField
                              {...createTextFieldPropsGenel('evrak_kullanici', 'kodu')}
                              // InputProps={{
                              //   endAdornment:
                              //     <IconButton
                              //       size="small"
                              //       className={classes.button}
                              //       disableRipple
                              //       onClick={() => setOpenSelectorCari(true)}
                              //     ><IconSearch /></IconButton>
                              // }}
                              InputProps={{
                                readOnly: true
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="evrak_kullanici">
                          <label className={classes.layoutFormItemLabel} htmlFor="evrak_kullanici">Cari Hesap Adı *</label>
                          <Tooltip title={props.error?.evrak?.evrak_kullanici ? props.error.evrak.evrak_kullanici[0] : _isNil(props.form?.evrak_kullanici) ? '' : props.form.evrak_kullanici?.first_name + ' ' + props.form.evrak_kullanici?.last_name}>
                            <TextField
                              {...createTextFieldPropsGenel('evrak_kullanici_adi', 'evrak_kullanici')}
                              InputProps={{
                                readOnly: true
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="para_birimi">
                          <label className={classes.layoutFormItemLabel} htmlFor="para_birimi">C/H Döviz *</label>
                          <Tooltip title={props.error?.evrak?.para_birimi ? props.error.evrak.para_birimi[0] : (_isNil(props.form?.para_birimi) || props.form?.para_birimi === '-1') ? '' : props.paraBirimDic[props.form.para_birimi]}>
                            <TextField
                              {...createTextFieldPropsGenel('para_birimi', '')}
                              // onChange={(e) => props.onChangeForm('para_birimi', e.target.value)}
                              select
                              SelectProps={{
                                native: true,
                              }}
                              InputProps={{
                                readOnly: true
                              }}
                            >
                              {paralist}
                            </TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="evrak_kur">
                          <label className={classes.layoutFormItemLabel} htmlFor="evrak_kur">C/H Döviz Kur *</label>
                          <Tooltip title={props.error?.evrak?.evrak_kur ? props.error.evrak.evrak_kur[0] : _isNil(props.form?.evrak_kur) ? '' : MoneyFormatConvert(props.form.evrak_kur, "money")}>
                            <span className='flex w-full'>
                              <NumericFormat
                                {...createTextFieldPropsGenel('evrak_kur', '')}
                                customInput={TextField}
                                thousandSeparator="."
                                decimalSeparator=","
                                inputProps={{
                                  style: { textAlign: 'right' },
                                }}
                                InputProps={{
                                  readOnly: true
                                }}
                                valueIsNumericString={true}
                              ></NumericFormat>
                            </span>
                          </Tooltip>
                        </li>
                        <li className={classes.layoutFormItem} id="aciklama" style={{ height: "92.5px" }}>
                          <label className={classes.layoutFormItemLabel} htmlFor="aciklama">Evrak Not</label>
                          <Tooltip title={props.error?.evrak?.aciklama ? props.error.evrak.aciklama[0] : _isNil(props.form?.aciklama) ? '' : props.form.aciklama}>
                            <TextField
                              {...createTextFieldPropsGenel('aciklama', '')}
                              style={{
                                height: "100%"
                              }}
                              multiline
                              rows={4}
                              // onChange={(e) => props.onChangeForm('aciklama', e.target.value)}
                              InputProps={{
                                readOnly: true
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        {props.form.evrak_durum !== null &&
                          <>
                            <li className={classes.layoutFormItem} id="evrak_durum.mail_gondersin">
                              <label className={classes.layoutFormItemLabel} htmlFor="evrak_durum.mail_gondersin">Eposta gönderilsin</label>
                              <Checkbox
                                name="evrak_durum.mail_gondersin"
                                color="primary"
                                checked={_result(props.form?.evrak_durum, 'mail_gondersin', false)}
                                onChange={(e, checked) => props.onChangeFormDurum('mail_gondersin', checked)}
                              ></Checkbox>
                            </li>
                            <li className={classes.layoutFormItem} id="evrak_durum.kullaniciya_mail_gondersin">
                              <label className={classes.layoutFormItemLabel} htmlFor="evrak_durum.kullaniciya_mail_gondersin">Evrak Kullanıcısına Eposta gönderilsin</label>
                              <Checkbox
                                name="evrak_durum.kullaniciya_mail_gondersin"
                                color="primary"
                                checked={_result(props.form?.evrak_durum, 'kullaniciya_mail_gondersin', false)}
                                onChange={(e, checked) => props.onChangeFormDurum('kullaniciya_mail_gondersin', checked)}
                              ></Checkbox>
                            </li>
                          </>
                        }
                      </ul>

                      <ul className={classes.layoutFormList}>
                        <div className={classes.adresFormList}>
                          <div className="absolute bg-palette-background-paper" style={{ top: -8, margin: 2 }}>Fatura Adresi</div>
                          <div className={classes.adresFormItem} id="adres">
                            <Tooltip title={props.error?.adres?.[1]?.adres ? props.error.adres[1].adres[0] : !props.adresler?.[1]?.adres ? '' : !_isEmpty(props.adressDic) ? props.adressDic[props.adresler[1].adres]?.tanim : ''}>
                              <TextField
                                {...createTextFieldPropsAdres('adres', 1)}
                                // onChange={(e) => props.onChangeFormAdres('adres', 1, e.target.value)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                                InputProps={{
                                  readOnly: true
                                }}
                              >
                                {props.adresList?.map((pb) => (
                                  <option value={pb.adres} key={pb.adres + 'fatura'}>{pb.tanim}</option>
                                ))}
                              </TextField>
                            </Tooltip>
                          </div>
                          <div className={classes.adresFormItem} id="adres_json">
                            <Tooltip title={props.error?.adres?.[1]?.adres ? props.error.adres[1].adres[0] : !props.adresler?.[1]?.adres ? '' : !_isEmpty(props.adressDic) ? props.adressDic[props.adresler[1].adres]?.adres : ''}>
                              <TextField
                                {...createTextFieldPropsAdres('adres_json', 1, 'uzunadres')}
                                multiline
                                rows={2}
                                style={{
                                  height: "100%"
                                }}
                                InputProps={{
                                  readOnly: true
                                }}
                              ></TextField>
                            </Tooltip>
                          </div>
                        </div>
                        <div className={classes.adresFormList}>
                          <div className="absolute bg-palette-background-paper" style={{ top: -8, margin: 2 }}>Teslimat Adresi</div>
                          <div className={classes.adresFormItem} id="adres">
                            <Tooltip title={props.error?.adres?.[0]?.adres ? props.error.adres[0].adres[0] : !props.adresler?.[0]?.adres ? '' : !_isEmpty(props.adressDic) ? props.adressDic[props.adresler[0].adres]?.tanim : ''}>
                              <TextField
                                {...createTextFieldPropsAdres('adres', 0)}
                                // onChange={(e) => props.onChangeFormAdres('adres', 0, e.target.value)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                                InputProps={{
                                  readOnly: true
                                }}
                              >
                                {props.adresList?.map((pb) => (
                                  <option value={pb.adres} key={pb.adres + 'teslimat'}>{pb.tanim}</option>
                                ))}
                              </TextField>
                            </Tooltip>
                          </div>
                          <div className={classes.adresFormItem} id="adres_json">
                            <Tooltip title={props.error?.adres?.[0]?.adres ? props.error.adres[0].adres[0] : !props.adresler?.[0]?.adres ? '' : !_isEmpty(props.adressDic) ? props.adressDic[props.adresler[0].adres]?.adres : ''}>
                              <TextField
                                {...createTextFieldPropsAdres('adres_json', 0, 'uzunadres')}
                                multiline
                                rows={2}
                                style={{
                                  height: "100%"
                                }}
                                InputProps={{
                                  readOnly: true
                                }}
                              ></TextField>
                            </Tooltip>
                          </div>
                        </div>
                      </ul>
                      {props.form?.ek_bilgiler.length > 0 &&
                        <ul className={classes.layoutFormList}>
                          <div className={classes.ekAlanList}>
                            <div className="absolute bg-palette-background-paper" style={{ top: -8, margin: 2 }}>Ek Alanlar</div>
                            <div className={classes.ekAlanLayout}>
                              {props.form?.ek_bilgiler?.map((item, index) => (
                                <li className={classes.layoutFormItem} style={{ marginTop: 0 }} id={item.tanim} key={item.tanim}>
                                  <label className={classes.layoutFormItemLabel} htmlFor={item.tanim}>{item.tanim}</label>
                                  <Tooltip title={props.error?.ek_bilgi?.[index]?.veri ? props.error.ek_bilgi[index].veri[0] : _isNil(item.veri) ? "" : item.alan_tip === 5 ? moment(item.veri).format('DD/MM/YYYY') : item.type === 6 ? moment(item.veri).format('DD/MM/YYYY, HH.mm') : item.alan_tip === 7 ? moment(item.veri).format('HH/MM') : item.veri}>
                                    <TextField
                                      {...createTextFieldPropsEkAlan(item, item.tanim, item.alan_tip, index)}
                                      onChange={(e) => props.onChangeForm('ek_bilgiler', e.target.value, index)}
                                      onBlur={() => {
                                        if (item.zorunlu) {
                                          handleInputErrors('', item, item, (errors) => handleErrors3("ek_bilgi", errors, index, props.form.ek_bilgiler.length), add, 'veri', true, false, false, '', '', '')
                                        }
                                      }}
                                      InputProps={{
                                        readOnly: item.disabled
                                      }}
                                    >
                                      {item.alan_tip === 2 && item.secim_degerleri?.map((sd) => (
                                        <option value={sd} key={sd}>{sd}</option>
                                      ))}
                                    </TextField>
                                  </Tooltip>
                                </li>
                              ))}
                            </div>
                          </div>
                        </ul>
                      }
                    </>
                  )}
                  {!topOpen && (
                    <div>
                      <span className="font-medium truncate text-base mr-10">Evrak Tarihi : {_result(props.form, 'evrak_tarihi') || ''}</span>
                      <span className="font-medium truncate text-base mr-10">Evrak No : {_result(props.form, 'kodu') || ''}</span>
                      <span className="font-medium truncate text-base mr-10">Mağaza : {_result(props.form?.magaza, 'tanim') || ''}</span>
                      <span className="font-medium truncate text-base mr-10">Cari Kod : {_result(props.form?.evrak_kullanici, 'kodu') || ''}</span>
                      <span className="font-medium truncate text-base mr-10">C/H Döviz : {props.paraKoduList[props.form?.para_birimi ?? '-1']}</span>
                    </div>
                  )}
                </div>
                <div className={topOpen ? classes.detailOpen : classes.detailClose}>
                  <IconButton
                    style={{ width: "1rem", height: "1rem", padding: 0 }}
                    disableRipple
                    onClick={() => setTopOpen(!topOpen)}
                  >
                    {topOpen ? <IconArrowDropUp style={{ fontSize: "2rem" }} /> : <IconArrowDropDown style={{ fontSize: "2rem" }} />}
                  </IconButton>
                </div>
              </div>

              <div className={classes.layoutTabHeader} style={{ width: tableRef?.current ? tableRef.current.offsetParent.scrollWidth : '100%' }}>
                <span className="font-medium truncate text-white text-base mr-10">Toplam Borç :</span>
                <span className="font-medium truncate text-white text-base mr-10">Toplam Alacak :</span>
                <span className="font-medium truncate text-white text-base mr-10">Bakiye :</span>
                <span className="font-medium truncate text-white text-base mr-10">Risk Limiti :</span>
              </div>

              {!LOADINGURUN && (
                <>
                  {HATAURUN && (
                    <Hata />
                  )}
                  {!HATAURUN && (
                    <div className={classes.layoutContainer} style={{ height: tableRef?.current ? tableRef.current?.offsetParent.scrollHeight < (LISTELENGTH * 35 + 70) ? '100%' : (LISTELENGTH * 35 + 70) : '100%', minHeight: tableRef?.current ? tableRef.current?.offsetParent.scrollHeight < (LISTELENGTH * 35 + 70) ? '100%' : (LISTELENGTH * 35 + 70) : '100%' }}>
                      <div className={classes.borderLayout} ref={tableRef}>
                        <div className={classes.formListTable} >
                          <div className={classes.tableHeader}>
                            {/* <div className={classes.tableHeaderItem} style={{ width: 25 }}>
                              <IconButton
                                size="small"
                                disabled={blockedAdd}
                                disableRipple
                                onClick={() => handleAddNewRow()}
                              >
                                <IconAdd />
                              </IconButton>
                            </div> */}
                            {!codeSearchOpen && (
                              <div className={classes.tableHeaderItem} style={{ width: 200, textAlign: "left", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <span>ÜRÜN KODU</span>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  onClick={() => setCodeSearchOpen(true)}
                                >
                                  <IconSearch />
                                </IconButton>
                              </div>
                            )}
                            {codeSearchOpen && (
                              <div className={classes.tableHeaderItem} style={{ width: 200, textAlign: "left", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <TextField
                                  variant="outlined"
                                  size='small'
                                  fullWidth={true}
                                  label="ÜRÜN KODU"
                                  value={queryCode}
                                  onChange={(e) => setQueryCode(e.target.value)}
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    endAdornment:
                                      <IconButton
                                        size="small"
                                        disableRipple
                                        onClick={() => searchList()}
                                      >
                                        <IconCheck />
                                      </IconButton>
                                  }}
                                ></TextField>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  onClick={() => {
                                    setCodeSearchOpen(false);
                                    setQueryCode("");
                                    searchList('', queryName);
                                  }}
                                >
                                  <IconClose />
                                </IconButton>
                              </div>
                            )}

                            {!nameSearchOpen && (
                              <div className={classes.tableHeaderItem} style={{ width: 200, textAlign: "left", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <span>ÜRÜN ADI</span>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  onClick={() => setNameSearchOpen(true)}
                                >
                                  <IconSearch />
                                </IconButton>
                              </div>
                            )}
                            {nameSearchOpen && (
                              <div className={classes.tableHeaderItem} style={{ width: 200, textAlign: "left", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <TextField
                                  variant="outlined"
                                  size='small'
                                  fullWidth={true}
                                  label="ÜRÜN ADI"
                                  value={queryName}
                                  onChange={(e) => setQueryName(e.target.value)}
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    endAdornment:
                                      <IconButton
                                        size="small"
                                        disableRipple
                                        onClick={() => searchList()}
                                      >
                                        <IconCheck />
                                      </IconButton>
                                  }}
                                ></TextField>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  onClick={() => {
                                    setNameSearchOpen(false);
                                    setQueryName("");
                                    searchList(queryCode, '');
                                  }}
                                >
                                  <IconClose />
                                </IconButton>
                              </div>
                            )}
                            <div className={classes.tableHeaderItem} style={{ textAlign: "left", width: 100 }}>SATIŞ BİRİM</div>
                            <div style={{ width: 100 }} className={classes.tableHeaderItem}>ÇARPAN</div>
                            <div className={classes.tableHeaderItem}>MİKTAR</div>
                            {vergibaslik &&
                              vergibaslik.map((v, index) => (
                                <div key={`vergib-${index}`} className={classes.tableHeaderItem} style={{ width: 60 }} >{v.vergi_adi}</div>
                              ))
                            }
                            <div className={classes.tableHeaderItem}>LİSTE BİRİM FİYAT</div>
                            <div className={classes.tableHeaderItem}>SATIŞ BİRİM FİYAT</div>
                            {iskontobaslik &&
                              iskontobaslik.map((i, index) => (
                                <div key={`iskontob-${index}`} className={classes.tableHeaderItem} style={{ width: 70 }}>{i.baslik}</div>
                              ))
                            }
                            <div className={classes.tableHeaderItem}>İSK.LU SATIŞ FİYAT</div>
                            <div className={classes.tableHeaderItem}>SATIŞ PARA BİRİMİ</div>
                            <div style={{ width: 100 }} className={classes.tableHeaderItem}>KUR</div>
                            <div style={{ width: 100 }} className={classes.tableHeaderItem}>C/H KUR</div>
                            <div className={classes.tableHeaderItem}>C/H FİYAT	</div>
                            <div className={classes.tableHeaderItem}>C/H TUTAR</div>
                            <div className={classes.tableHeaderItem}>MAĞAZA TUTAR</div>
                            <div style={{ width: 50 }} className={classes.tableHeaderItem}></div>
                            {/* <div style={{ width: 50 }} className={classes.tableHeaderItem}></div> */}
                          </div>
                          {(!openFilter) && (
                            <>
                              <div style={{ flex: '1 1 auto' }}>
                                <AutoSizer>
                                  {({ height, width }) => (
                                    <List
                                      height={height}
                                      rowCount={LISTELENGTH}
                                      rowHeight={35}
                                      rowRenderer={rowRenderer}
                                      width={width}
                                      scrollToIndex={scrollNew}
                                    />
                                  )}
                                </AutoSizer>
                              </div>
                              {searchItems[selectedRow] && (
                                <div style={{ width: 300, height: 300, position: "absolute", left: 0, top: ((selectedRow + 2) * 35), zIndex: 100 }}>
                                  <SearchListDrop
                                    listPath={`urun/mini_list/?magazalar.magaza.id=${props.form.magaza?.id}&aktif=true`}
                                    listCountPath={`urun/count?magazalar.magaza.id=${props.form.magaza?.id}&aktif=true`}
                                    searchQuery={searchItems[selectedRow]}
                                    keyProp='id'
                                    valueProp='tanim'
                                    valueProp2='kodu'
                                    onChageItem={(item) => {
                                      if (inputRef[selectedRow].current) {
                                        inputRef[selectedRow].current.focus();
                                      }
                                      props.onChangeUrun(selectedRow, 'urun', item, false);
                                      handleSearch(selectedRow, "");
                                    }}
                                    onClickAway={() => {
                                      handleSearch(selectedRow, "")
                                      props.onChangeUrun(selectedRow, 'urun', null, false)
                                    }}
                                    childRef={elementRef[selectedRow]}
                                  />
                                </div>
                              )}
                            </>
                          )}
                          {(openFilter) && (
                            <>
                              <div style={{ flex: '1 1 auto' }}>
                                <AutoSizer>
                                  {({ width, height }) => (
                                    <List
                                      width={width}
                                      height={height}
                                      rowHeight={35}
                                      rowCount={filtredList.length}
                                      rowRenderer={rowRendererSearch}
                                      estimatedRowSize={filtredList.length * 35}
                                      scrollToIndex={scrollNew}
                                    />
                                  )}
                                </AutoSizer>
                              </div>
                              {searchItems[selectedRow] && (
                                <div style={{ width: 300, height: 300, position: "absolute", left: 0, top: ((selectedRowSearch + 2) * 35), zIndex: 100 }}>
                                  <SearchListDrop
                                    listPath={`urun/mini_list/?magazalar.magaza.id=${props.form.magaza?.id}&aktif=true`}
                                    listCountPath={`urun/count?magazalar.magaza.id=${props.form.magaza?.id}&aktif=true`}
                                    searchQuery={searchItems[selectedRow]}
                                    keyProp='id'
                                    valueProp='tanim'
                                    valueProp2='kodu'
                                    onChageItem={(item) => {
                                      if (inputRef[selectedRow].current) {
                                        inputRef[selectedRow].current.focus();
                                      }
                                      props.onChangeUrun(selectedRow, 'urun', item, false)
                                      handleSearch(selectedRow, "")
                                    }}
                                    onClickAway={() => {
                                      handleSearch(selectedRow, "")
                                      props.onChangeUrun(selectedRow, 'urun', null, false)
                                    }}
                                    childRef={elementRef[selectedRow]}
                                  />
                                </div>
                              )}
                            </>
                          )}

                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {LOADINGCALC && (
                <div className={classes.layoutContainer}>
                  <div className="flex w-full justify-center items-center">
                    <CircularProgress color="primary" />
                    <Typography variant="caption">Hesaplanıyor...</Typography>
                  </div>
                </div>
              )}

              {LOADINGURUN && (
                <div className={classes.layoutContainer}>
                  <div className="flex w-full justify-center items-center">
                    <CircularProgress color="primary" />
                    <Typography variant="caption">Yükleniyor...</Typography>
                  </div>
                </div>
              )}

              <div className={classes.layoutContainer}>
                <div className={classes.borderLayout}>
                  {bottomOpen && (
                    <div className="flex flex-row w-full">
                      <div className="mr-4 min-h-52 flex" style={{ minWidth: 600 }}>
                        <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                          <header className="flex items-center justify-between py-2 px-4">
                            <Button
                              className="pointer-events-none"
                              color="inherit"
                            >VERGİ MATRAHLARI</Button>
                          </header>
                          <main className="border-t border-palette-action-hover py-1">
                            <Table className={classes.tableMatrah} size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Tipi</TableCell>
                                  <TableCell>Adı</TableCell>
                                  <TableCell align="right">Oran</TableCell>
                                  <TableCell align="right">Matrah</TableCell>
                                  <TableCell align="right">Vergi</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {props.vergiMatrahlar?.length > 0 && props.vergiMatrahlar.map((m, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{m.vergi_tip.tanim}</TableCell>
                                    <TableCell>{m.vergi.tanim}</TableCell>
                                    <TableCell align="right">{m.vergi_oran}</TableCell>
                                    <TableCell align="right">{MoneyFormatConvert(m.matrah, "money") + " " + EVRAK_PARA_BIRIM}</TableCell>
                                    <TableCell align="right">{MoneyFormatConvert(m.vergi_tutar, "money") + " " + EVRAK_PARA_BIRIM}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </main>
                        </section>
                      </div>

                      <div className="min-w-96 mx-4 min-h-52 flex">
                        <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                          <header className="flex items-center justify-between py-2 px-4">
                            <Button
                              className="pointer-events-none"
                              color="inherit"
                            >İSKONTOLAR</Button>
                            {/* <Button
                              className={classes.button3}
                              variant="text"
                              color="default"
                              size="small"
                              onClick={() => setOpenSelectorSaleManager2(true)}
                            > İSKONTO</Button> */}
                          </header>
                          <main className="border-t border-palette-action-hover py-1">
                            <ul className="flex flex-col list-none m-0 p-0">
                              {props.form?.iskontolar?.length > 0 && props.form.iskontolar.map((i, index) => (
                                <li key={index} className="flex items-center justify-between py-1 pl-4 pr-8 space-x-2">
                                  <label>İskonto {index + 1} {i.iskonto_tutar !== "0.0000" ? 'T' : '%'}:</label>
                                  <span>{i.iskonto_tutar !== "0.0000" ? MoneyFormatConvert(i.iskonto_tutar, "money") + " " + EVRAK_PARA_BIRIM : i.iskonto_oran + " %"}</span>
                                </li>
                              ))}
                            </ul>
                          </main>
                        </section>
                      </div>

                      <div className="min-w-96 ml-4 min-h-52 flex">
                        <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                          <header className="flex items-center justify-between py-2 px-4">
                            <Button
                              className="pointer-events-none"
                              color="inherit"
                            >TOPLAMLAR</Button>
                          </header>
                          <main className="border-t border-palette-action-hover py-1">
                            {props.form.para_birimi !== '-1' && (
                              <>
                                <Table className={classes.tableMatrah} size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell></TableCell>
                                      <TableCell>C/H TOPLAM</TableCell>
                                      <TableCell>MAĞAZA TOPLAM</TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    <TableRow>
                                      <TableCell>Toplam</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.toplam, "money") + ' ' + EVRAK_PARA_BIRIM}</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.magaza_toplam, "money") + ' ' + MAGAZA_PARA_BIRIM}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Satır İskonto</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.kalem_iskonto, "money") + ' ' + EVRAK_PARA_BIRIM}</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.magaza_kalem_iskonto, "money") + ' ' + MAGAZA_PARA_BIRIM}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Dip İskonto</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.iskonto, "money") + ' ' + EVRAK_PARA_BIRIM}</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.magaza_iskonto, "money") + ' ' + MAGAZA_PARA_BIRIM}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Ara Toplam</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.ara_toplam, "money") + ' ' + EVRAK_PARA_BIRIM}</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.magaza_ara_toplam, "money") + ' ' + MAGAZA_PARA_BIRIM}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Kargo</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.kargo, "money") + ' ' + EVRAK_PARA_BIRIM}</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.magaza_kargo, "money") + ' ' + MAGAZA_PARA_BIRIM}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Vergi Toplam</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.vergi_toplam, "money") + ' ' + EVRAK_PARA_BIRIM}</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.magaza_vergi_toplam, "money") + ' ' + MAGAZA_PARA_BIRIM}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>GENEL TOPLAM</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.genel_toplam, "money") + ' ' + EVRAK_PARA_BIRIM}</TableCell>
                                      <TableCell align="right">{MoneyFormatConvert(props.form?.magaza_genel_toplam, "money") + ' ' + MAGAZA_PARA_BIRIM}</TableCell>
                                    </TableRow>
                                  </TableBody>

                                </Table>
                              </>
                            )}
                          </main>
                        </section>
                      </div>

                    </div>
                  )}
                  {!bottomOpen && (
                    <div>
                      <span className="font-medium truncate text-base mr-10">Toplam : {MoneyFormatConvert(props.form?.toplam, "money") + ' ' + EVRAK_PARA_BIRIM}</span>
                      <span className="font-medium truncate text-base mr-10">Satır İskonto : {MoneyFormatConvert(props.form?.kalem_iskonto, "money") + ' ' + EVRAK_PARA_BIRIM}</span>
                      <span className="font-medium truncate text-base mr-10">Dip İskonto : {MoneyFormatConvert(props.form?.iskonto, "money") + ' ' + EVRAK_PARA_BIRIM}</span>
                      <span className="font-medium truncate text-base mr-10">Ara Toplam : {MoneyFormatConvert(props.form?.ara_toplam, "money") + ' ' + EVRAK_PARA_BIRIM}</span>
                      <span className="font-medium truncate text-base mr-10">Kargo : {MoneyFormatConvert(props.form?.kargo, "money") + ' ' + EVRAK_PARA_BIRIM}</span>
                      <span className="font-medium truncate text-base mr-10">Vergi Toplam : {MoneyFormatConvert(props.form?.vergi_toplam, "money") + ' ' + EVRAK_PARA_BIRIM}</span>
                      <span className="font-medium truncate text-base mr-10">GENEL TOPLAM : {MoneyFormatConvert(props.form?.genel_toplam, "money") + ' ' + EVRAK_PARA_BIRIM}</span>
                    </div>
                  )}
                </div>

                <div className={bottomOpen ? classes.detailOpen : classes.detailClose}>
                  <IconButton
                    style={{ width: "1rem", height: "1rem", padding: 0 }}
                    //disableRipple
                    onClick={() => setBottomOpen(!bottomOpen)}
                  >
                    {bottomOpen ? <IconArrowDropUp style={{ fontSize: "2rem" }} /> : <IconArrowDropDown style={{ fontSize: "2rem" }} />}

                  </IconButton>
                </div>
              </div>

              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorMagaza(false);
                    handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'magaza', true, false, false, '', '', '')
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Mağazalar'
                      listPath='magaza?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      valueProp2='para_birim'
                      extraVal="magaza_cikis_depo"
                      extraVal2="ulke"
                      notEmpty={true}
                      defaultItem={props.form.magaza}
                      onChangeItems={(items) => props.onChangeForm('magaza', items)}
                      onClose={() => {
                        setOpenSelectorMagaza(false);
                        handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'magaza', true, false, false, '', '', '');
                      }}
                      newPath="tanimlar/magaza"
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorKargo &&
                <Dialog
                  open={openSelectorKargo}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => { setOpenSelectorKargo(false) }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Teslimat'
                      listPath='kargo?aktif=true'
                      listCountPath='kargo/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.secilen_kargo}
                      onChangeItems={(items) => props.onChangeForm('secilen_kargo', items)}
                      onClose={() => { setOpenSelectorKargo(false) }}
                      newPath="tanimlar/kargo"
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorCikisDepo &&
                <Dialog
                  open={openSelectorCikisDepo}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorCikisDepo(false);
                    handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'cikis_depo', true, false, false, '', '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Depolar'
                      listPath='depo/mini_list?aktif=true'
                      listCountPath='depo/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.cikis_depo}
                      onChangeItems={(items) => props.onChangeForm('cikis_depo', items)}
                      onClose={() => {
                        setOpenSelectorCikisDepo(false);
                        handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'cikis_depo', true, false, false, '', '', '');
                      }}
                      newPath="tanimlar/depolar"
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorCari &&
                <Dialog
                  open={openSelectorCari}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorCari(false);
                    handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'evrak_kullanici', true, false, false, '', '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Cariler'
                      listPath='kullanici/?cari=true&is_staff=false&is_active=true'
                      listCountPath='kullanici/count/?cari=true&is_staff=false&is_active=true'
                      keyProp='id'
                      valueProp='kodu'
                      valueProp2='first_name'
                      valueProp3='last_name'
                      defaultItem={props.form.evrak_kullanici}
                      notEmpty={true}
                      onChangeItems={(items) => props.onChangeForm('evrak_kullanici', items)}
                      onClose={() => {
                        setOpenSelectorCari(false);
                        handleInputErrors('evrak', props.form, props.formdefault, handleErrors, add, 'evrak_kullanici', true, false, false, '', '', '');
                      }}
                      newPath="tanimlar/cari"
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorDurum &&
                <Dialog
                  open={openSelectorDurum}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorDurum(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Durumlar'
                      listPath={`evrakdurumtanim/?aktif=true&evrak_tip=${props.form.tip}&magazalar.magaza.id=${props.form?.magaza?.id}`}
                      listCountPath={`evrakdurumtanim/count/?aktif=true&evrak_tip=${props.form.tip}&magazalar.magaza.id=${props.form?.magaza?.id}`}
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.evrak_durum?.durum ?? null}
                      onChangeItems={(items) => props.onChangeFormDurum('durum', items)}
                      onClose={() => setOpenSelectorDurum(false)}
                      newPath="evrak/durumtanim"
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorSaleManager &&
                <Dialog
                  open={openSelectorSaleManager}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorSaleManager(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <Calc
                      fiyat={props.urunler[selectedRow]?.satis_fiyat}
                      iskontolar={props.urunler[selectedRow]?.urun_detay_iskonto}
                      onChange={(value) => props.onChangeUrun(selectedRow, 'urun_detay_iskonto', value, false)}
                      onClose={() => setOpenSelectorSaleManager(false)}
                      pb={props.paraKoduList[props.urunler[selectedRow].satis_para_birimi]}
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorUrun &&
                <Dialog
                  open={openSelectorUrun}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorUrun(false);
                    handleInputErrors('evrak', props.urunler[selectedRow], props.urunler[selectedRow], handleErrors2, add, 'urun', true, false, false, '', '', '');
                    handleInputErrors('evrak', props.urunler[selectedRow], props.urunler[selectedRow], handleErrors2, add, 'birim', true, false, false, '', '', '');
                    handleInputErrors('evrak', props.urunler[selectedRow], props.urunler[selectedRow], handleErrors2, add, 'birim_kodu', true, false, false, '', '', '');
                    handleInputErrors('evrak', props.urunler[selectedRow], props.urunler[selectedRow], handleErrors2, add, 'birim_carpan', true, false, false, '', '', '');
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Ürünler'
                      listPath={`urun/mini_list/?magazalar.magaza.id=${props.form.magaza?.id}&aktif=true`}
                      listCountPath={`urun/count?magazalar.magaza.id=${props.form.magaza?.id}&aktif=true`}
                      keyProp='id'
                      valueProp='tanim'
                      valueProp2='kodu'
                      defaultItem={URUNLISTE[selectedRow]?.urun}
                      onChangeItems={(items) => { props.onChangeUrun(selectedRow, 'urun', items, false) }}
                      onClose={() => {
                        setOpenSelectorUrun(false);
                        handleInputErrors('evrak', props.urunler[selectedRow], props.urunler[selectedRow], handleErrors2, add, 'urun', true, false, false, '', '', '');
                        handleInputErrors('evrak', props.urunler[selectedRow], props.urunler[selectedRow], handleErrors2, add, 'birim', true, false, false, '', '', '');
                        handleInputErrors('evrak', props.urunler[selectedRow], props.urunler[selectedRow], handleErrors2, add, 'birim_kodu', true, false, false, '', '', '');
                        handleInputErrors('evrak', props.urunler[selectedRow], props.urunler[selectedRow], handleErrors2, add, 'birim_carpan', true, false, false, '', '', '');
                      }}
                      newPath="tanimlar/urun"
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorSatirEkBilgi &&
                <Dialog
                  open={openSelectorSatirEkBilgi}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorSatirEkBilgi(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <Paper className="w-full h-full flex flex-col overflow-hidden">
                      <AppBar
                        className="border-b border-palette-action-selected"
                        position="sticky"
                        color="transparent"
                        elevation={0}
                      >
                        <Toolbar
                          className={classes.toolbar}
                          variant="dense"
                          disableGutters
                        >
                          <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">Satır Ek Bilgi</h3>
                          <span>
                            <Tooltip title="Kapat">
                              <IconButton
                                edge="end"
                                color="inherit"
                                size="small"
                                onClick={() => setOpenSelectorSatirEkBilgi(false)}
                              ><IconClose /></IconButton>
                            </Tooltip>
                          </span>
                        </Toolbar>
                      </AppBar>
                      <main className="relative flex flex-grow flex-col overflow-hidden">
                        <div className="h-full flex-grow p-1">
                          <ul className={classes.layoutFormList}>
                            {props.urunler[selectedRow]?.ek_bilgi.map((e) => ekBilgiRender(e, selectedRow))}
                          </ul>
                        </div>
                      </main>
                    </Paper>
                  </DialogContent>
                </Dialog>
              }
              {openSelectorSaleManager2 &&
                <Dialog
                  open={openSelectorSaleManager2}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorSaleManager2(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <Calc
                      fiyat={props.form.toplam}
                      iskontolar={props.form.iskontolar}
                      onChange={(value) => props.onChangeForm('iskontolar', value)}
                      onClose={() => setOpenSelectorSaleManager2(false)}
                      pb={props.paraKoduList[props.form.para_birimi]}
                    />
                  </DialogContent>
                </Dialog>
              }
            </div>
          )}
        </>
      }
    </>
  );
});

export default AlisOnSiparisOnayDetailTab_Genel;
