import React from 'react';
import clsx from 'clsx';

import {
  has as _has,
} from 'lodash';

import {
  Tooltip,
  Checkbox,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';

import {
  ArrowDownward as IconArrowDownward,
  ArrowUpward as IconArrowUpward
} from '@material-ui/icons';

import GridTableXTableHeadCellFilter from './GridTableX_TableHeadCellFilter';

const TABLE_HEAD_ROW_HEIGHT = 30;

const GridTableXTableHead = (props) => {
  return (
    <TableHead>
      <TableRow
        className="whitespace-pre"
      >
        {props.showCheckBoxCell && (
          <TableCell width={1} align="center">
            <div style={{ width: 40 }}>
              <Checkbox
                color="default"
                size="small"
                disableRipple
                style={{
                  padding: 5,
                }}
                indeterminate={props.isIndeterminateCheckedRows}
                checked={props.isCheckedRows}
                onChange={(e, checked) => props.onCheckedRows(checked)}
              ></Checkbox>
            </div>
          </TableCell>
        )}

        {(props.varyant && props.showTreeArrow) && (
          <TableCell width={1} align="center">
            <div style={{ width: 30 }}>
            </div>
          </TableCell>
        )}

        {props.columns && props.columns.map((column, columnIndex) => {
          if (!column.show) {
            return null;
          }

          const isSortedASC = _has(props.filter, 'sort') && props.filter.sort.indexOf(column.id) > -1;
          const isSortedDESC = _has(props.filter, 'sort') && props.filter.sort.indexOf(`-${column.id}`) > -1;
          const isSorted = isSortedASC || isSortedDESC;
          const isFiltered = _has(props.filter, column.filterProp);

          return (
            <TableCell
              className={clsx('border-palette-action-selected')}
              key={column.id}
              component="th"
              scope="row"
              width={column.width}
              style={{
                verticalAlign: 'top',
              }}
            >
              <div className="w-full h-full flex flex-col select-none">
                <div
                  className={clsx('flex items-center px-2 space-x-1', {
                    'justify-center': column.alignment === 1,
                    'justify-end': column.alignment === 2,
                    'cursor-pointer active:bg-palette-action-hover': column.sortable,
                    'bg-palette-action-selected': isSorted,
                  })}
                  style={{
                    height: TABLE_HEAD_ROW_HEIGHT
                  }}
                  onClick={() => {
                    if (column.sortable) {
                      props.onChangeColumnSort(column.id);
                    }
                  }}
                >
                  {column.shortDescription && (
                    <Tooltip title={column.shortDescription}><span>{column.label}</span></Tooltip>
                  )}

                  {!column.shortDescription && (
                    <span>{column.label}</span>
                  )}

                  {isSortedASC && (
                    <IconArrowDownward style={{ fontSize: 16, opacity: .75 }} />
                  )}

                  {isSortedDESC && (
                    <IconArrowUpward style={{ fontSize: 16, opacity: .75 }} />
                  )}
                </div>

                {props.showFilterRow && (
                  <div
                    className={clsx('flex items-center border-t border-palette-action-selected overflow-hidden py-1 px-2', {
                      'bg-palette-action-selected': isFiltered,
                    })}
                  >
                    <GridTableXTableHeadCellFilter
                      filter={props.filter}
                      column={column}
                      onSubmit={(value) => props.onChangeFilterValue(column.filterProp, value)}
                    ></GridTableXTableHeadCellFilter>
                  </div>
                )}
              </div>
            </TableCell>
          );
        })}

        <TableCell
          className="border-l border-palette-action-selected"
          component="th"
          scope="row"
        ></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default GridTableXTableHead;
