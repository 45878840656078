import React, { useCallback, useMemo } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
};

const BankaHesapDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const createTextFieldProps = useCallback((name, name2 = '', defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form[name], `${name2}`) || defaultValue,
        error: _has(props.error?.[name], `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form, `${name}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    }
  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.layoutContainer}>
              <ul className={classes.layoutFormList}>
                <li className={classes.layoutFormItem} id="tanim">
                  <label className={classes.layoutFormItemLabel} htmlFor="tanim">Banka Hesap Tanım *</label>
                  <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                    <TextField
                      {...createTextFieldProps('tanim')}
                      onChange={(e) => props.onChangeForm('tanim', '', e.target.value)}
                      onBlur={() => handleInputErrors('bankahesap', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Banka Hesap Tanım', 'banka hesap')}
                      inputProps={{
                        minLength: 1,
                        maxLength: 250,
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="banka_hesap.banka_adi">
                  <label className={classes.layoutFormItemLabel} htmlFor="banka_hesap.banka_adi">Banka Adı *</label>
                  <Tooltip title={props.error?.banka_hesap?.banka_adi ? props.error.banka_hesap.banka_adi[0] : _isNil(props.form?.banka_hesap?.banka_adi) ? '' : props.form.banka_hesap.banka_adi}>
                    <TextField
                      {...createTextFieldProps('banka_hesap', 'banka_adi')}
                      onChange={(e) => props.onChangeForm('banka_hesap', 'banka_adi', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'banka_hesap', true, false, false, '', '', '', 'banka_adi')}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="banka_hesap.hesap_adi">
                  <label className={classes.layoutFormItemLabel} htmlFor="banka_hesap.hesap_adi">Hesap Adı *</label>
                  <Tooltip title={props.error?.banka_hesap?.hesap_adi ? props.error.banka_hesap.hesap_adi[0] : _isNil(props.form?.banka_hesap?.hesap_adi) ? '' : props.form.banka_hesap.hesap_adi}>
                    <TextField
                      {...createTextFieldProps('banka_hesap', 'hesap_adi')}
                      onChange={(e) => props.onChangeForm('banka_hesap', 'hesap_adi', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'banka_hesap', true, false, false, '', '', '', 'hesap_adi')}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="banka_hesap.sube">
                  <label className={classes.layoutFormItemLabel} htmlFor="banka_hesap.sube">Şube *</label>
                  <Tooltip title={props.error?.banka_hesap?.sube ? props.error.banka_hesap.sube[0] : _isNil(props.form?.banka_hesap?.sube) ? '' : props.form.banka_hesap.sube}>
                    <TextField
                      {...createTextFieldProps('banka_hesap', 'sube')}
                      onChange={(e) => props.onChangeForm('banka_hesap', 'sube', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'banka_hesap', true, false, false, '', '', '', 'sube')}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="banka_hesap.iban">
                  <label className={classes.layoutFormItemLabel} htmlFor="banka_hesap.iban">Iban *</label>
                  <Tooltip title={props.error?.banka_hesap?.iban ? props.error.banka_hesap.iban[0] : _isNil(props.form?.banka_hesap?.iban) ? '' : props.form.banka_hesap.iban}>
                    <TextField
                      {...createTextFieldProps('banka_hesap', 'iban')}
                      onChange={(e) => props.onChangeForm('banka_hesap', 'iban', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'banka_hesap', true, false, false, '', '', '', 'iban')}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="banka_hesap.sube_kodu">
                  <label className={classes.layoutFormItemLabel} htmlFor="banka_hesap.sube_kodu">Şube Kodu *</label>
                  <Tooltip title={props.error?.banka_hesap?.sube_kodu ? props.error.banka_hesap.sube_kodu[0] : _isNil(props.form?.banka_hesap?.sube_kodu) ? '' : props.form.banka_hesap.sube_kodu}>
                    <TextField
                      {...createTextFieldProps('banka_hesap', 'sube_kodu')}
                      onChange={(e) => props.onChangeForm('banka_hesap', 'sube_kodu', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'banka_hesap', true, false, false, '', '', '', 'sube_kodu')}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="banka_hesap.hesap_no">
                  <label className={classes.layoutFormItemLabel} htmlFor="banka_hesap.hesap_no">Hesap Numarası *</label>
                  <Tooltip title={props.error?.banka_hesap?.hesap_no ? props.error.banka_hesap.hesap_no[0] : _isNil(props.form?.banka_hesap?.hesap_no) ? '' : props.form.banka_hesap.hesap_no}>
                    <TextField
                      {...createTextFieldProps('banka_hesap', 'hesap_no')}
                      onChange={(e) => props.onChangeForm('banka_hesap', 'hesap_no', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'banka_hesap', true, false, false, '', '', '', 'hesap_no')}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="para_birim">
                  <label className={classes.layoutFormItemLabel} htmlFor="para_birim">Para Birimi *</label>
                  <Tooltip title={props.error?.para_birim ? props.error.para_birim[0] : (props.form?.para_birim === "-1" || _isNil(props.form?.para_birim)) ? '' : props.paraKoduList[props.form.para_birim]}>
                    <TextField
                      {...createTextFieldProps('para_birim', '', '-1')}
                      onChange={(e) => props.onChangeForm('para_birim', '', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'para_birim', true, false, false, '', '', '')}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="-1" disabled>Seçilmedi</option>
                      {props.paraBirim?.map((pb) => (
                        <option key={pb.id} value={pb.id}>{pb.tanim}</option>
                      ))}
                    </TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="siralama">
                  <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                  <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                    <TextField
                      {...createTextFieldProps('siralama')}
                      onChange={(e) => props.onChangeForm('siralama', '', e.target.value)}
                      type="number"
                      inputProps={{
                        min: -2147483648,
                        max: 2147483647,
                        step: 1,
                        style: { textAlign: "right" }
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="aktif">
                  <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                  <Checkbox
                    name="aktif"
                    color="primary"
                    checked={_result(props.form, 'aktif', false)}
                    onChange={(e, checked) => props.onChangeForm('aktif', '', checked)}
                  ></Checkbox>
                </li>
              </ul>
            </div>
          )}
        </>
      }
    </>
  );
});

export default BankaHesapDetailTabGenel;
