import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
	isEqual as _isEqual,
	pickBy as _pickBy,
	identity as _identity,
	isEmpty as _isEmpty,
	trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './IcerikSablonDetailTab_Genel';

const PATH = 'iceriksablon';

const TABS_DEFAULT = [
	{ label: 'GENEL', disabled: false, hasError: false }
];

const FORM_DEFAULT = {
	tanim: '',
	aktif: true,
	siralama: '0',
	tip: '2',
	diller: [],
};

const ICERIK_FORM_DEFAULT = {
	dil_id: '',
	konu: '',
	sablon: '',
	yazi_sablon: '',
	pdf_sablon: ''
}

const createIceriklerStates = async (diller, icerikler) => {
	const states = [];
	diller.sort((a, b) => a.id - b.id).forEach((dil) => {
		const data = {
			dil: {
				id: dil.id,
				tanim: dil.tanim,
			},
			form: {
				...ICERIK_FORM_DEFAULT,
				dil_id: dil.id,
			},
			errors: null
		}
		const sablonicerik = icerikler?.find((i) => i.dil_id === dil.id);
		if (sablonicerik) {
			data.form.dil_id = sablonicerik.dil_id;
			data.form.konu = sablonicerik.konu;
			data.form.sablon = sablonicerik.sablon;
			data.form.yazi_sablon = sablonicerik.yazi_sablon;
			data.form.pdf_sablon = sablonicerik.pdf_sablon;
		}
		states.push(data);
	});
	return states;
};

const IcerikSablonDetail = (props) => {
	const [loading, setLoading] = useState(true);
	const [loadingDil, setLoadingDil] = useState(true);

	const [hataGenel, setHataGenel] = useState(null);
	const [hataDil, setHataDil] = useState(null);

	const [lastUpdateTime, setLastUpdateTime] = useState(0);
	const [add, setAdd] = useState(false);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [tabs, setTabs] = useState(TABS_DEFAULT);

	const [genelFormDefault, setGenelFormDefault] = useState(null);
	const [genelForm, setGenelForm] = useState(null);
	const [genelError, setGenelError] = useState(null);
	const [tipList] = useState([{ key: 2, value: 'Evrak Mail' }, { key: 3, value: 'Pdf' }]);
	const [tipListDic] = useState({ 2: 'Evrak Mail', 3: 'Pdf' });

	const [icerikSelectedTabIndex] = useState(0);
	const [iceriklerFormDefault, setIceriklerFormDefault] = useState([]);
	const [iceriklerForm, setIceriklerForm] = useState([]);
	const [iceriklerFormAdd, setIceriklerFormAdd] = useState([]);
	const [dilMiniList, setDilMiniList] = useState([]);
	const [icerikDiller, setIcerikDiller] = useState([]);

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const ID = useMemo(() => props.id, [props]);
	const IS_ADD = useMemo(() => props.addnew, [props]);

	const TITLE = useMemo(() => {
		if (add) {
			return 'Yeni Ekle';
		}
		return genelForm?.tanim;
	}, [add, genelForm]);

	const IS_EQUAL = useMemo(() => {
		if (add) {
			return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(iceriklerForm, iceriklerFormAdd);
		}
		const isEqualGenel = _isEqual(genelForm, genelFormDefault);
		const isEqualIcerik = _isEqual(iceriklerForm, iceriklerFormDefault);
		return isEqualGenel && isEqualIcerik;
	}, [add, genelForm, genelFormDefault, iceriklerForm, iceriklerFormDefault, iceriklerFormAdd]);

	const LOADING = useMemo(() => {
		if (!loading && !loadingDil) {
			return false;
		}
		return true;
	}, [loading, loadingDil]);

	useLayoutEffect(() => {
		setLoading(true);
		setTabs(TABS_DEFAULT);
		if (!IS_ADD) {
			const debounce = setTimeout(() => {
				django(`${PATH}/${ID}`).then(({ data }) => {
					const form = {
						tanim: data.tanim,
						diller: data.diller,
						aktif: data.aktif,
						siralama: String(data.siralama),
						tip: String(data.tip.key),
					};
					setGenelFormDefault(form);
					setGenelForm(form);
					setIcerikDiller(form.diller);
					setHataGenel(null);
				}).catch((error) => {
					if (error.response) {
						if (error.response.status === 500) {
							setHataGenel(true);
						}
					}
				}).finally(() => {
					setLoading(false);
					setGenelError(null);
					setAdd(false);
				});
			}, 300);
			return () => {
				clearTimeout(debounce);
				setLoading(false);
			};
		} else {
			setGenelForm(FORM_DEFAULT);
			setGenelFormDefault(FORM_DEFAULT);
			setIcerikDiller([]);
			setGenelError(null);
			setHataGenel(null);
			setAdd(true);
			setLoading(false);
		}
	}, [ID, lastUpdateTime, IS_ADD]);

	// useLayoutEffect(() => {
	// 	django('iceriksablon/tip').then(({ data }) => {
	// 		const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
	// 		console.log(data, dict)
	// 		setTiplistDic(dict);
	// 		setTiplist(data);
	// 	});
	// }, [lastUpdateTime]);

	useLayoutEffect(() => {
		django('dil/mini_list').then(({ data }) => setDilMiniList(data)).catch((error) => {
			if (error.response) {
				if (error.response.status === 500) {
					setHataDil(true);
				}
			}
		});
	}, [lastUpdateTime]);

	useLayoutEffect(() => {
		setLoadingDil(true);
		createIceriklerStates(dilMiniList, icerikDiller).then((states) => {
			setIceriklerFormDefault(states);
			setIceriklerForm(states);
		}).finally(() => setLoadingDil(false));
	}, [dilMiniList, icerikDiller]);

	useLayoutEffect(() => {
		createIceriklerStates(dilMiniList, []).then((states) => {
			setIceriklerFormAdd(states);
		})
	}, [dilMiniList]);

	useLayoutEffect(() => {
		if (!props.single) {
			props.onDisableClose(!IS_EQUAL);
		}
	}, [props, IS_EQUAL]);

	const handleGenelChangeForm = (property, value) => {
		setGenelForm((prev) => {
			return immer(prev, (next) => {
				next[property] = value;
			});
		});
	};

	const handleIceriklerChangeForm = (index, property, value) => {
		setIceriklerForm((prev) => {
			return immer(prev, (next) => {
				next[index].form[property] = value;
			});
		});
	}

	const handleAdd = () => {
		setAdd(true);
		setGenelForm(FORM_DEFAULT);
		setGenelError(null);
		setIceriklerForm(iceriklerFormAdd);
		setTabs((prev) => {
			return immer(prev, (next) => {
				next[0].hasError = false;
			});
		});
	};

	const handleReload = () => {
		setLastUpdateTime(Date.now());
	};

	const handleCancel = () => {
		setGenelForm(genelFormDefault);
		setGenelError(null);
		setIceriklerForm(iceriklerFormDefault);
		if (!IS_ADD) {
			setAdd(false);
		}
		setTabs((prev) => {
			return immer(prev, (next) => {
				next[0].hasError = false;
			});
		});
	};

	const handleDelete = () => {
		setIsDeleting(true);
		setIsDeletingErrorMessage(null);
		const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
		django.delete(`${PATH}/${ID}`).then(({ status }) => {
			if (status === 204) {
				setShowDeleteDialog(false);
				setIsDeletingErrorMessage(null);
				setTimeout(() => {
					if (props.single) {
						history.push(URLS.tanimlar.icerik_sablon.list);
					} else {
						props.onDeleted();
					}
				}, 100);
			} else {
				setIsDeletingErrorMessage(errorMessage);
			}
		}).catch(() => {
			setIsDeletingErrorMessage(errorMessage);
		}).finally(() => {
			setIsDeleting(false);
		});
	};

	const handleDeleteDetailIndex = (index) => {
		setIceriklerForm((prev) => {
			return immer(prev, (next) => {
				next[index].form = { ...ICERIK_FORM_DEFAULT, dil_id: next[index].form.dil_id };
				next[index].errors = null;
			})
		});
	}

	const checkAll = async () => {
		const list = [
			{ prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Şablon Tanım', pagename: 'şablon', prop2: '' },
		]
		const list2 = [
			{ prop: 'konu', prop2: '' },
			{ prop: 'yazi_sablon', prop2: '' },
			{ prop: 'sablon', prop2: '' },
		];
		const list3 = [
			{ prop: 'pdf_sablon', prop2: '' },
		];
		let errors = [];
		await checkAllList('iceriksablon', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);

		for (const i in iceriklerForm) {
			if (!_isEqual(iceriklerForm[i].form, iceriklerFormDefault[i].form)) {
				if (genelForm.tip === "2") {
					const res = await checkAllList2(iceriklerForm[i].form, (err) => handleErrorsDetail(err, i), list2);
					if (!_isEmpty(res)) {
						errors = [...errors, res];
					}
				} else {
					const res = await checkAllList2(iceriklerForm[i].form, (err) => handleErrorsDetail(err, i), list3);
					if (!_isEmpty(res)) {
						errors = [...errors, res];
					}
				}
			}
		}
		return errors;
	};
	const handleSave = () => {
		checkAll().then((res) => {
			if (_isEmpty(res)) {
				let requestoptions = {};
				const icerik = iceriklerForm.filter(icerik => {
					if(genelForm.tip==="2"){
						return !_isEmpty(icerik.form.konu) && !_isEmpty(icerik.form.sablon) && !_isEmpty(icerik.form.yazi_sablon)
					}else{
						return !_isEmpty(icerik.form.pdf_sablon)
					}
				}).map(icerik => icerik.form);
				const data = { ...genelForm, diller: icerik, siralama: genelForm.siralama ? Number(genelForm.siralama) : 0, tip: Number(genelForm.tip) }
				if (IS_EQUAL) {
					requestoptions = null;
				} else {
					requestoptions = {
						config: {
							method: add ? 'POST' : 'PUT',
							url: add ? 'iceriksablon' : `iceriksablon/${ID}`,
							data: data
						},
						successMessage: `İçerik şablonu ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
						errorMessageUnexpected: `İçerik şablonu ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
					}
				}
				const start = async () => {
					let errorCounts = 0;
					if (requestoptions !== null) {
						setGenelError(null);
						await django(requestoptions.config).then(({ data }) => {
							enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
							if (!add) {
								props.onUpdated();
							}
							if (props.single && add) {
								history.push(URLS.tanimlar.icerik_sablon.detail(data.id, "detay"));
							}
							if (!props.single && add) {
								props.onAdded(data);
							}
						}).catch((error) => {
							if (error.response) {
								if (error.response.status === 500) {
									enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
								} else {
									setGenelError(error.response.data);
								}
							} else {
								enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
							}
							++errorCounts;
						});
						if (errorCounts === 0) {
							setLastUpdateTime(Date.now());
						} else {
							setTabs((prev) => {
								return immer(prev, (next) => {
									next[0].hasError = true;
								});
							});
						}
					}
				}
				start();
			} else {
				setTabs((prev) => {
					return immer(prev, (next) => {
						next[0].hasError = true;
					});
				});
			}
		})
	};

	const handleErrors = (errors) => {
		setGenelError((prev) => {
			const next = _pickBy({ ...prev, ...errors }, _identity);
			if (Object.keys(next).length === 0) {
				return null;
			}
			return next;
		});
	}

	const handleErrorsDetail = (errors, index) => {
		setIceriklerForm((prev) => {
			const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
			return immer(prev, (next) => {
				next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
			});
		});
	}

	return (
		<>
			<LayoutDetail
				loading={loading}
				title={TITLE}

				single={props.single}

				tabs={tabs}
				selectedTabIndex={selectedTabIndex}
				onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

				disableCloseButton={loading}
				disableAddButton={loading || add || IS_ADD}
				disableReloadButton={loading || add || IS_ADD}
				disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
				disableSaveButton={loading || IS_EQUAL}
				disableDeleteButton={loading || add || IS_ADD}
				disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
				disableNextButton={props.disableNextButton || add || IS_ADD}

				onClickCloseButton={props.onClose}
				onClickAddButton={handleAdd}
				onClickReloadButton={handleReload}
				onClickCancelButton={handleCancel}
				onClickSaveButton={handleSave}
				onClickDeleteButton={() => setShowDeleteDialog(true)}
				onClickPreviousButton={props.onGoPrevious}
				onClickNextButton={props.onGoNext}
				onOpenInNewTab={() => {
					if (IS_ADD) {
						window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
					} else {
						window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
					}
				}}
			>
				{selectedTabIndex === 0 && (
					<TabGenel
						loading={LOADING}
						hata={hataGenel}
						hataDil={hataDil}
						add={add}
						form={genelForm}
						formdefault={genelFormDefault}
						error={genelError}
						tipList={tipList}
						tipListDic={tipListDic}
						onChangeForm={handleGenelChangeForm}
						selectedTabIndex={icerikSelectedTabIndex}
						dilform={iceriklerForm}
						onChangeDilForm={handleIceriklerChangeForm}
						deleteDetailWithIndex={handleDeleteDetailIndex}
						onCheckErrors={(errors) => handleErrors(errors)}
						onCheckErrorsDil={(errors, index) => handleErrorsDetail(errors, index)}
					/>
				)}
			</LayoutDetail>

			{showDeleteDialog &&
				<DialogConfirm
					title="Sil"
					message={
						isDeletingErrorMessage
							? isDeletingErrorMessage
							: isDeleting
								? 'İçerik şablon siliniyor lütfen bekleyin'
								: 'Bu icerik şablonu gerçekten silmek istiyor musunuz?'
					}
					cancelText="VAZGEÇ"
					submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
					submittingText="SİLİNİYOR"
					submitButtonProps={{ color: 'secondary' }}
					isSubmitting={isDeleting}
					onSubmit={handleDelete}
					onCancel={() => setShowDeleteDialog(false)}
				></DialogConfirm>
			}
		</>
	);
}
export default IcerikSablonDetail;