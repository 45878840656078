import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../../pages/base/LayoutTableDetail';
import Detail from './GmerchantDetail';


const COLUMNS_DEFAULT = [
  { id: 'account_name',           tableProp: 'account_name',                  filterProp: 'account_name',           label: 'Hesap Adı',               element: 'input',             type: 'text',     arrayTableProp: null,     show: true, sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'merchant_id',            tableProp: 'merchant_id',                   filterProp: 'merchant_id',            label: 'Hesap Id',                element: 'input',             type: 'text',     arrayTableProp: null,     show: true, sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'magaza',                 tableProp: 'magaza.tanim',                  filterProp: 'magaza.id',              label: 'Mağaza',                  element: 'select-multiple',   type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: 'magaza',     multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null,  selectKey: null,  selectValue: null  , newpath:"tanimlar/magaza" },
  { id: 'dil',                    tableProp: 'dil.tanim',                     filterProp: 'dil.id',                 label: 'Dil',                     element: 'select-multiple',   type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'dil',        multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null,  selectKey: null,  selectValue: null, newpath: "tanimlar/dil" },
  { id: 'katalog_iliskili_calis', tableProp: 'katalog_iliskili_calis',        filterProp: 'katalog_iliskili_calis', label: 'Katalog İlişkili Çalış',  element: 'select',            type: 'boolean',  arrayTableProp: null,     show: true, sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'olusturan_kullanici',    tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id', label: 'Oluşturan Kullanıcı',     element: 'select-multiple',   type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistiren_kullanici',   tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',label: 'Değiştiren Kullanıcı',    element: 'select-multiple',   type: 'number',   arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'olusturma_zamani',       tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',       label: 'Oluşturma',               element: 'input',             type: 'datetime', arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistirme_zamani',      tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',      label: 'Son Değişiklik',          element: 'input',             type: 'datetime', arrayTableProp: null,     show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
];

const PageGmerchantList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        return column;
      });
    });
  }, []);

  return (
    <>
      <Layout
        title="GOOGLE MERCHANT"
        path="gmerchantaccount"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="gmerchantaccount"
      />
    </>
  );
};

export default PageGmerchantList;
