import React, { useState, useLayoutEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual
} from 'lodash';

import {
  Slide,
  LinearProgress,
  CircularProgress,
  IconButton,
  Divider,
  Tooltip,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogContent,
  Paper,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Checkbox
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

import {
  VerticalSplit as IconVerticalSplit,
  HorizontalSplit as IconHorizontalSplit,
  Refresh as IconRefresh,
  LockOutlined as IconLockOutlined,
  Add as IconAdd,
  Close as IconClose,
  Search as IconSearch,
  Save as IconSave,
  Edit as IconEdit
} from '@material-ui/icons';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import django from 'api/django';
import Store from '../../../libs/store';
import Themes from '../../../themes/index';
import ListSelectorSingle from '../../../components/ListSelectorSingle';
import DialogConfirm from '../../../components/DialogConfirm';
import { Hata } from '../../base/Hata';
const appTheme = Themes[Store.get('themeChoice')?.theme ?? 'light'];

const PAGE_SETTINGS_DEFAULT = {
  tableOnLeft: true,
  tableWidth: 480,
  tableHeight: 480,
};

const theme = {
  overrides: {
    MuiTable: {
      root: {
        '& tr td': {
          lineHeight: 1,
          padding: 0,
          borderBottomWidth: 0,
          verticalAlign: "top"
        },
        '& tr td + td': {
          borderLeft: `1px solid ${appTheme.palette.action.disabledBackground}`,
        },
        '& tr': {
          background: `${appTheme.palette.action.hover}`,
        },
      },
    },
  },
};

const styles = ({ spacing, palette }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      minWidth: 'min-content',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    gridLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: "center",
      padding: spacing(0, 1, 0, 1),
      background: `${appTheme.palette.action.disabledBackground}`,
    },
    gridClouds: {
      display: 'flex',
      padding: spacing(1),
      minHeight: 100,
    },
    gridCloud: {
      position: "relative",
      border: "1px solid #eacfea",
      borderRadius: 4,
      cursor: "pointer",
      width: "max-content",
      margin: spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
    gridCloudTitle: {
      display: 'flex',
      alignItems: "center",
      justifyContent: 'space-between',
      gap: spacing(4),
      fontSize: 12,
      margin: spacing(-1, 1, 0, 1),
      '& span': {
        background: palette.background.paper,
        border: "1px solid #eacfea",
      },
      '& span:first-child': {
        borderRadius: 4,
        padding: spacing(0, 0.25)
      },
      '& span:last-child': {
        borderRadius: '50%',
      }
    },
    gridCloudInside: {
      display: "flex",
      alignItems: "center",
      justifyContent: 'space-between',
      padding: spacing(1),
      gap: spacing(4),
    },
    gridCloudActions: {
      display: "flex",
      alignItems: "center",
      gap: spacing(1),
    },
    // gridCloudClose: {
    //   border: "1px solid #eacfea",
    //   borderRadius: '50%',
    // }
  };
};

const BOLUM_DIC = {
  "0": "Üstün Üstü",
  "1": "Altın Üstü",
  "2": "Sol",
  "3": "Sağ",
  "4": "Orta",
  "5": "Üstün Altı",
  "6": "Altın Altı",
}

const GridLayoutDetail = withStyles(styles)(({ classes, ...props }) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [showProgress, setShowProgress] = useState(true);
  const [showProgress2, setShowProgress2] = useState(true);
  const [showProgress3, setShowProgress3] = useState(true);
  const [loadError, setLoadError] = useState(false);

  const [settings, setSettings] = useState(PAGE_SETTINGS_DEFAULT);
  const [count, setCount] = useState(0);

  const [rowsLastUpdateTime, setRowsLastUpdateTime] = useState(0);

  const [disableCloseDetail, setDisableCloseDetail] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [sayfaTip, setSayfaTip] = useState([]);
  const [sayfaTipDic, setSayfaTipDic] = useState([]);
  const [site, setSite] = useState([]);
  const [siteDic, setSiteDic] = useState([]);
  const [bilesenTipDic, setBilesenTipDic] = useState([]);

  const [bolumler, setBolumler] = useState([]);
  const [bilesenMini, setBilesenMini] = useState([]);
  const [bilesenler, setBilesenler] = useState([]);

  const [bilesenparts, setBilesenParts] = useState([[], [], [], [], [], [], []]);
  const [bilesenpartsDefault, setBilesenPartsDefault] = useState([[], [], [], [], [], [], []]);
  const [bilesenpartsCount, setBilesenPartsCount] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [bilesenpartsCanSave, setBilesenPartsCanSave] = useState([false, false, false, false, false, false, false]);

  const [addnew, setAddNew] = useState(false);
  const [addInfo, setAddInfo] = useState(null);

  const [selectedBox, setSelectedBox] = useState(null);
  const [selectedBoxId, setSelectedBoxId] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedSayfaTip, setSelectedSayfaTip] = useState(null);
  const [selectedType, setSelectedType] = useState("0");
  const [selectedNames, setSelectedNames] = useState(["", "", "Web"]);

  const [openSelectorBilesen, setOpenSelectorBilesen] = useState(false);
  const [selectedBilesentoAdd, setSelectedBilesentoAdd] = useState(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const tableContainerRef = useRef();
  const detailRef = useRef();

  const LOADING = useMemo(() => {
    if (!showProgress && !showProgress2 && !showProgress3) {
      return false;
    }
    return true;
  }, [showProgress, showProgress2, showProgress3]);

  const PAGE_SETTINGS_STORAGE_KEY = useMemo(() => `egan:saved-page-settings-bilesenbolum`, []);

  const TABLE_CONTAINER_WIDTH = useMemo(
    () => settings.tableOnLeft && showDetail ? settings.tableWidth : '100%',
    [settings.tableOnLeft, showDetail, settings.tableWidth]
  );

  const TABLE_CONTAINER_HEIGHT = useMemo(
    () => !settings.tableOnLeft && showDetail ? settings.tableHeight : '100%',
    [settings.tableOnLeft, showDetail, settings.tableHeight]
  );

  useLayoutEffect(() => {
    const storedSettings = Store.get(PAGE_SETTINGS_STORAGE_KEY);
    if (storedSettings) {
      setSettings(storedSettings);
    } else {
      setSettings(PAGE_SETTINGS_DEFAULT)
    }
  }, [PAGE_SETTINGS_STORAGE_KEY]);

  useLayoutEffect(() => {
    const debounce = setTimeout(() => {
      Store.set(PAGE_SETTINGS_STORAGE_KEY, settings);
    }, 500);

    return () => {
      clearTimeout(debounce);
    };
  }, [PAGE_SETTINGS_STORAGE_KEY, settings]);

  useLayoutEffect(() => {
    django('bilesen/bilesen_tip').then(({ data }) => {
      setLoadError(false);
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setBilesenTipDic(dict);
    }).catch(() => {
      setLoadError(true);
    });
  }, []);

  useLayoutEffect(() => {
    django('site/mini_list').then(({ data }) => {
      setLoadError(false);
      const dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.name })));
      setSiteDic(dict);
      setSite(data);
      setSelectedSite(data[0].id);
      setSelectedNames((prev) => {
        return immer(prev, (next) => {
          next[0] = data[0].name;
        });
      });

    }).catch(() => {
      setLoadError(true);
    });
  }, []);

  useLayoutEffect(() => {
    django('bolum/sayfa_tipler').then(({ data }) => {
      setLoadError(false);
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setSayfaTipDic(dict);
      setSayfaTip(data);
      setSelectedSayfaTip(data[0].key);
      setSelectedNames((prev) => {
        return immer(prev, (next) => {
          next[1] = data[0].value;
        });
      });
    }).catch(() => {
      setLoadError(true);
    });
  }, []);

  useLayoutEffect(() => {
    setShowProgress2(true);
    if (selectedSite !== null && selectedSayfaTip !== null && selectedType !== null) {
      django(`bolum`, { params: { "sayfa_tip": Number(selectedSayfaTip), "site.id": Number(selectedSite) } }).then(({ data }) => {
        setLoadError(false);
        const form = data.filter(x => x.site.id === Number(selectedSite))
          .filter((x) => x.tanim.includes(selectedType === "0" ? "Web" : "Mobil"))
          .map((x) => ({
            id: x.id,
            sayfa_tip: x.sayfa_tip.key,
            sayfa_bolum: x.sayfa_bolum.key,
            site: x.site.id
          }));
        setBolumler(form);
      }).catch(() => {
        setLoadError(true);
      }).finally(() => setShowProgress2(false));
    }
  }, [rowsLastUpdateTime, selectedSite, selectedSayfaTip, selectedType]);

  useLayoutEffect(() => {
    // const params = bolumler.map(x => {
    //   if (selectedType === "0") {
    //     return `bolumler.bilesenbolum.id=${x.id}`
    //   } else {
    //     return `mobil_bolumler.bilesenbolum.id=${x.id}`
    //   }
    // }).join('&');

    const params = bolumler.map(x => x.id).join(',');

    setShowProgress(true);
    setLoadError(false);
    if (params !== "") {
      django(`bilesen?${selectedType === "0" ? "bolumler.bilesenbolum.id=" + params : "mobil_bolumler.bilesenbolum.id=" + params}`).then(({ data }) => {
        const form = data.map((x) => ({
          id: x.id,
          tanim: x.tanim,
          bolumler: x.bolumler.map((x) => x.id),
          mobil_bolumler: x.mobil_bolumler.map((x) => x.id),
          bilesen_tip: x.bilesen_tip.key,
          baslik: x.baslik,
          temp: x.temp,
          mobile_temp: x.mobile_temp
        }));
        setBilesenMini(form);
        setCount(form.length);
        if (selectedBoxId !== null) {
          const selected = form.find(x => x.id === selectedBoxId);
          setSelectedBox(selected);
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            setLoadError(true);
          }
        }
      }).finally(() => {
        if (rowsLastUpdateTime === 0) {
          setPageLoading(false);
        }
        setShowProgress(false);
      });
    } else {
      if (rowsLastUpdateTime === 0) {
        setPageLoading(false);
      }
      setShowProgress(false);
      setBilesenMini([]);
      setCount(0);
      setSelectedBox(null);
    }
  }, [rowsLastUpdateTime, selectedBoxId, selectedType, bolumler]);

  useLayoutEffect(() => {
    const getEkBilgi = async () => {
      setShowProgress3(true);
      const bilesenler = [];
      for (let bilesen of bilesenMini) {
        const res = (await django(`bilesen/${bilesen.id}/bilesen-ek-bilgi`)).data;
        const ekbilgi = res.map(x => ({
          id: x.id,
          bolum: x.bolum?.id ?? null,
          mobil_bolum: x.mobil_bolum?.id ?? null,
          bolum_sira: x.bolum_sira,
          satir_sonu: x.satir_sonu,
          bilesen: x.bilesen.id
        }));
        bilesenler.push({ ...bilesen, ekbilgi: ekbilgi ?? [] })
      }
      setBilesenler(bilesenler);
      setShowProgress3(false);
    }
    getEkBilgi();
  }, [bilesenMini]);

  useLayoutEffect(() => {
    if (LOADING) {
      const netListe = [[], [], [], [], [], [], []];
      const netCount = [0, 0, 0, 0, 0, 0, 0];
      for (let bolum = 0; bolum < 7; bolum++) {
        const bolumId = bolumler.find(x => x.sayfa_bolum === bolum)?.id;
        if (bolumId) {
          switch (selectedType) {
            case "0":
              let liste = bilesenler.filter(x => x.bolumler.includes(bolumId));
              if (liste.length > 0) {
                liste = liste.map((x) => ({ ...x, ekbilgi: x.ekbilgi.find(y => y.bolum === bolumId) })).sort((x, y) => x.ekbilgi.bolum_sira - y.ekbilgi.bolum_sira);
              }
              netListe[bolum] = liste;
              netCount[bolum] = liste.length;
              break;
            case "1":
              let liste_mobil = bilesenler.filter(x => x.mobil_bolumler.includes(bolumId));
              if (liste_mobil.length > 0) {
                liste_mobil = liste_mobil.map((x) => ({ ...x, ekbilgi: x.ekbilgi.find(y => y.mobil_bolum === bolumId) })).sort((x, y) => x.ekbilgi.bolum_sira - y.ekbilgi.bolum_sira);
              }
              netListe[bolum] = liste_mobil;
              netCount[bolum] = liste_mobil.length;
              break;
            default:
              break;
          }
        }
      }
      setBilesenParts(netListe);
      setBilesenPartsDefault(netListe);
      setBilesenPartsCount(netCount);
    }
  }, [bilesenler, selectedType, bolumler, LOADING]);

  const confirmCloseDetail = (func) => {
    if (!disableCloseDetail || (disableCloseDetail && window.confirm('Yaptığınız değişiklikler iptal edilecek'))) {
      func();
      setDisableCloseDetail(false);
      setAddNew(false);
      setAddInfo(null);
      setSelectedBox(null);
    }
  };

  const confirmOpenDetail = (func) => {
    if (!bilesenpartsCanSave.includes(true) || (bilesenpartsCanSave.includes(true) && window.confirm('Yaptığınız değişiklikler iptal edilecek'))) {
      func();
      setBilesenParts(bilesenpartsDefault);
      setBilesenPartsCanSave([false, false, false, false, false, false, false]);
    }
  }

  const handleChangeSetting = (key, value) => {
    setSettings({
      ...settings,
      [key]: value,
    });
  };

  const handleResizerDragStop = (e) => {
    e.preventDefault();
    document.onmousemove = null;
    document.onmouseup = null;
  };

  const handleResizerDrag = (e) => {
    e.preventDefault();
    if (tableContainerRef.current) {
      const tableContainerRect = tableContainerRef.current.getBoundingClientRect();
      if (settings.tableOnLeft) {
        handleChangeSetting('tableWidth', e.clientX - tableContainerRect.x);
      } else {
        handleChangeSetting('tableHeight', e.clientY - tableContainerRect.y);
      }
    }
  };

  const handleResize = (e) => {
    e.preventDefault();
    document.onmousemove = handleResizerDrag;
    document.onmouseup = handleResizerDragStop;
  };

  const handleChangeSelects = (name, value, text) => {
    switch (name) {
      case "site":
        setSelectedSite(value);
        setSelectedNames((prev) => {
          return immer(prev, (next) => {
            next[0] = text;
          });
        });
        break;
      case "sayfa":
        setSelectedSayfaTip(value)
        setSelectedNames((prev) => {
          return immer(prev, (next) => {
            next[1] = text;
          });
        });
        break;
      case "tip":
        setSelectedType(value)
        setSelectedNames((prev) => {
          return immer(prev, (next) => {
            next[2] = text;
          });
        });
        break;
      default:
        break;
    }
    setShowDetail(false);
    setSelectedBox(null);
    setAddInfo(null);
    setAddNew(false);
  }

  const handleOnContextMenu = (e) => {
    e.preventDefault();
    if (!menuPosition) {
      setMenuPosition({
        top: e.clientY,
        left: e.clientX,
      });
    }
  };

  const handleMenuEvent = (e, selected) => {
    e.stopPropagation();
    if (selected === 'OPEN_NEW_TAB') {
      window.open(`${window.location.origin}/tanimlar/bilesen/${selectedBox.id}/detay`, '_blank');
    } else if (selected === 'SHOW_DETAIL') {
      setAddNew(false);
      setShowDetail(true);
    } else if (selected === 'DELETE') {
      setShowDeleteDialog(true);
    }
    setTimeout(() => {
      setMenuPosition(null);
    }, 100);
  };

  const getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? '#eacfea' : 'inherit',
    display: 'flex',
    // flexDirection: 'column',
    // width: '100%',
    //flexWrap: 'wrap',
    overflow: 'auto',
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: "#976797"
    }),
  });

  const handleSaveBolumAyar = async (bolum, list = null) => {
    let errorCounts = [];
    let array = list ? list : bilesenparts[bolum];
    for (let index = 0; index < array.length; index++) {
      const bilesen = array[index];
      const bilesendefault = bilesenpartsDefault[bolum].find(x => x.id === bilesen.id);
      if (!_isEqual(bilesen, bilesendefault)) {
        await django({
          method: "PUT",
          data: { ...bilesen.ekbilgi },
          url: `bilesen/${bilesen.id}/bilesen-ek-bilgi/${bilesen.ekbilgi.id}`
        }).catch(() => {
          errorCounts.push("err");
        });
      }
    }
    if (errorCounts.length !== 0) {
      enqueueSnackbar("Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin", { variant: 'error' });
    } else {
      enqueueSnackbar(`${BOLUM_DIC[String(bolum)]} ayarları güncelleme başarıyla tamamlandı`, { variant: 'success' });
      setBilesenPartsDefault((prev) => {
        return immer(prev, (next) => {
          next[bolum] = array;
        });
      });
      setBilesenPartsCanSave((prev) => {
        return immer(prev, (next) => {
          next[bolum] = false;
        });
      });
    }
  }

  const createBilesenFields = (bolum, tip, title, colspan, direction) => {
    const onDragEnd = (result) => {
      let copyBN = [...bilesenparts[bolum]];
      if (!result.destination || !result.source) {
        return;
      }
      const eleman = copyBN[result.source.index];
      copyBN.splice(result.source.index, 1);
      copyBN.splice(result.destination.index, 0, eleman);
      copyBN = copyBN.map((x, index) => ({ ...x, ekbilgi: { ...x.ekbilgi, bolum_sira: index + 1 } }))
      setBilesenParts((prev) => {
        return immer(prev, (next) => {
          next[bolum] = copyBN;
        });
      });
      setBilesenPartsCanSave((prev) => {
        return immer(prev, (next) => {
          next[bolum] = !_isEqual(copyBN, bilesenpartsDefault[bolum]);
        });
      });
    }

    const changeSatirSonu = (id, value) => {
      let copyBN = [...bilesenparts[bolum]].map(x => {
        if (x.id === id) {
          return { ...x, ekbilgi: { ...x.ekbilgi, satir_sonu: value } }
        }
        return x
      })
      setBilesenParts((prev) => {
        return immer(prev, (next) => {
          next[bolum] = copyBN;
        });
      });
      setBilesenPartsCanSave((prev) => {
        return immer(prev, (next) => {
          next[bolum] = !_isEqual(copyBN, bilesenpartsDefault[bolum]);
        });
      });
    }

    return (
      <TableCell colSpan={colspan}>
        <div className={classes.gridLabel}>
          <span>{title}</span>
          <div>
            <Tooltip title="Listeden Seç">
              <span>
                <IconButton
                  size="small"
                  className={classes.Icons}
                  disableRipple
                  onClick={() => {
                    handleAddNew(bolum, bilesenpartsCount[bolum], false)
                    setOpenSelectorBilesen(true)
                  }}
                ><IconSearch /></IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Yeni Ekle">
              <span>
                <IconButton
                  size="small"
                  className={classes.Icons}
                  disableRipple
                  onClick={() => {
                    handleAddNew(bolum, bilesenpartsCount[bolum])
                  }}
                >
                  <IconAdd />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Kaydet">
              <span>
                <IconButton
                  size="small"
                  className={classes.Icons}
                  disableRipple
                  disabled={!bilesenpartsCanSave[bolum]}
                  onClick={() => {
                    handleSaveBolumAyar(bolum)
                  }}
                  style={{ color: !bilesenpartsCanSave[bolum] ? "" : "green" }}
                >
                  <IconSave />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </div>

        <div className={classes.gridClouds} style={{ width: colspan * 400 }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {bilesenparts[bolum].length > 0 && (
                    bilesenparts[bolum].map((x, index) => (
                      <Draggable key={String(x.id)} draggableId={String(x.id)} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            snapshot={snapshot}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              className={classes.gridCloud}
                              onContextMenu={(e) => {
                                setSelectedBox(x)
                                handleOnContextMenu(e)
                              }}
                            >
                              <div className={classes.gridCloudTitle}>
                                <span>{`${bilesenTipDic[x.bilesen_tip]} bileşen - ${x.ekbilgi.bolum_sira}. sıra`}</span>
                                <Tooltip title="Kaldır">
                                  <span className={classes.gridCloudClose}>
                                    <IconButton
                                      style={{ width: "16px", height: "16px", fontSize: "16px", padding: 0 }}
                                      size="small"
                                      disableRipple
                                      onClick={(e) => {
                                        confirmOpenDetail(() => {
                                          handleRemoveBilesen(e, x, bolum, tip)
                                        });
                                      }}
                                    >
                                      <IconClose
                                        style={{ fontSize: "16px" }}
                                      />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </div>

                              <div className={classes.gridCloudInside}>
                                <span>{x.tanim}</span>
                                <div className={classes.gridCloudActions}>
                                  <Tooltip title="Düzenle">
                                    <span>
                                      <IconButton
                                        style={{ width: "16px", height: "16px", fontSize: "16px", padding: 0 }}
                                        size="small"
                                        disableRipple

                                        onClick={() => {
                                          confirmOpenDetail(() => {
                                            setAddInfo({ eklemeTip: selectedType })
                                            openDetail(x);
                                          });
                                        }}
                                      >
                                        <IconEdit
                                          style={{ fontSize: "16px" }}
                                        />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                  <Tooltip title="Satır Sonu">
                                    <span>
                                      <Checkbox
                                        style={{ padding: 0 }}
                                        name="satir_sonu"
                                        color="primary"
                                        checked={x.ekbilgi.satir_sonu || false}
                                        onChange={(e, checked) => changeSatirSonu(x.id, checked)}
                                      ></Checkbox>
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>

                              <div>
                                {menuPosition &&
                                  <ClickAwayListener mouseEvent="onClick" onClickAway={() => setMenuPosition(null)}>
                                    <Paper className="fixed" style={menuPosition}>
                                      <MenuList className="focus:outline-none">
                                        <MenuItem onClick={(e) => handleMenuEvent(e, 'OPEN_NEW_TAB')}>Yeni Sekmede Aç</MenuItem>
                                        <MenuItem onClick={(e) => handleMenuEvent(e, 'SHOW_DETAIL')}>Detay Göster</MenuItem>
                                        <Divider />
                                        <MenuItem onClick={(e) => handleMenuEvent(e, 'DELETE')}>Sil</MenuItem>
                                      </MenuList>
                                    </Paper>
                                  </ClickAwayListener>
                                }
                              </div>

                            </div>
                          </div>
                        )}
                      </Draggable>

                    ))
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>

        </div>
      </TableCell >
    )
  };

  const handleAddNew = (bolum, count, opendetail = true) => {
    const bolumId = bolumler.find(x => x.sayfa_bolum === bolum)?.id;
    if (bolumId) {
      setAddInfo({ bolumId: bolumId, eklemeTip: selectedType, count: count })
    } else {
      setAddInfo({ bolumId: null, sayfaTip: selectedSayfaTip, sayfaBolum: bolum, site: selectedSite, tanim: selectedNames[0] + "-" + selectedNames[1] + "-" + selectedNames[2] + "-" + BOLUM_DIC[bolum], eklemeTip: selectedType, count: 0 })
    }
    setSelectedBox(null);
    setAddNew(true);
    if (opendetail) {
      setShowDetail(true)
    } else {
      setShowDetail(false);
    }
  }

  const addBolum = async () => {
    let newBolumId = null;
    if (!addInfo.bolumId) {
      const data = { tanim: addInfo.tanim, sayfa_tip: addInfo.sayfaTip, sayfa_bolum: addInfo.sayfaBolum, site: addInfo.site, aktif: true };
      await django({ method: "POST", url: "bolum", data: data }).then(({ data }) => {
        newBolumId = data.id;
      }).catch(() => {
        enqueueSnackbar('Bileşen eklenirken beklenmeyen bir hata oluştu', { variant: 'error' });
      });
    } else {
      newBolumId = addInfo.bolumId;
    }
    return newBolumId;
  }

  const handleAddEkBilgi = async (id, bolum) => {
    let data = {
      bolum_sira: addInfo.count + 1,
      satir_sonu: false,
      bolum: selectedType === "0" ? bolum : null,
      mobil_bolum: selectedType === "1" ? bolum : null,
      bilesen: id
    };
    await django({
      method: "POST",
      data: data,
      url: `bilesen/${id}/bilesen-ek-bilgi`
    })
  }

  const handleAddNewFromListe = async () => {
    const newbolumid = await addBolum();
    const bilesen = selectedBilesentoAdd;
    const data = selectedType === "0" ? { bolumler: bilesen.bolumler ? [...bilesen.bolumler, newbolumid] : [newbolumid] } : { mobil_bolumler: bilesen.mobil_bolumler ? [...bilesen.mobil_bolumler, newbolumid] : [newbolumid] }
    await django({ method: "PATCH", url: `bilesen/${bilesen.id}`, data: data }).then(({ data }) => {
      handleAddEkBilgi(data.id, newbolumid);
      enqueueSnackbar('Bileşen ekleme başarıyla tamamlandı', { variant: 'success' });
      setRowsLastUpdateTime(Date.now());
      setOpenSelectorBilesen(false);
      setSelectedBilesentoAdd(null);
    }).catch(() => {
      enqueueSnackbar('Bileşen eklenirken beklenmeyen bir hata oluştu', { variant: 'error' });
    })
  }

  const openDetail = (item) => {
    setAddNew(false);
    setSelectedBox(item);
    setShowDetail(true);
  }

  const handleRemoveBilesen = async (e, bilesen, bolum, tip) => {
    const bolumId = bolumler.find(x => x.sayfa_bolum === bolum)?.id;
    e.stopPropagation();
    let newBilesen = {};
    switch (tip) {
      case "0":
        newBilesen = { ...bilesen, bolumler: bilesen.bolumler.filter(x => x !== bolumId) };
        break;
      case "1":
        newBilesen = { ...bilesen, mobil_bolumler: bilesen.mobil_bolumler.filter(x => x !== bolumId) };
        break;
      default:
        break;
    }
    delete newBilesen.ekbilgi;

    await django({ method: "PUT", url: `bilesen/${newBilesen.id}`, data: newBilesen }).then(() => {
      enqueueSnackbar('Bileşen bölümnden kaldırıldı', { variant: 'success' });
      django.delete(`bilesen/${bilesen.id}/bilesen-ek-bilgi/${bilesen.ekbilgi.id}`)
      if (bilesen === selectedBox) {
        setShowDetail(false);
        setAddInfo(null);
        setSelectedBox(null);
      }
      let newList = [...bilesenparts[bolum]].filter(x => x.id !== bilesen.id);
      newList = newList.map((x, index) => ({ ...x, ekbilgi: { ...x.ekbilgi, bolum_sira: index + 1 } }))
      handleSaveBolumAyar(bolum, newList).then(() => setRowsLastUpdateTime(Date.now()));
    }).catch(() => {
      enqueueSnackbar('Bileşen bölümnden kaldırılamadı', { variant: 'error' });
    })
  }

  const handleDeleteBilesen = (e) => {
    e.preventDefault();
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    django.delete(`bilesen/${selectedBox.id}`).then(() => {
      setShowDeleteDialog(false);
      setRowsLastUpdateTime(Date.now());
      setSelectedBox(null);
    }).catch(() => {
      setIsDeletingErrorMessage('Silme işleminde beklenmeyen bir hata oluştu.');
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleControl = (bolum) => {
    const bolumId = bolumler.find(x => x.sayfa_bolum === bolum)?.id;
    if (bolumId) {
      const count = bilesenler.filter(x => {
        if (selectedType === "0") {
          return x.bolumler.includes(bolumId)
        } else {
          return x.mobil_bolumler.includes(bolumId)
        }
      }).length;
      setAddInfo({ bolumId: bolumId, eklemeTip: selectedType, count: count });
      return bolumId
    } else {
      setAddInfo({ bolumId: null, sayfaTip: selectedSayfaTip, sayfaBolum: bolum, site: selectedSite, tanim: selectedNames[0] + "-" + selectedNames[1] + "-" + selectedNames[2] + "-" + BOLUM_DIC[bolum], eklemeTip: selectedType, count: 0 });
      return null;
    }

  }

  if (pageLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center overflow-hidden">
        <div className="flex items-center justify-center">
          <CircularProgress size={72} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="relative w-full h-full flex flex-col rounded bg-palette-background-default border-2 border-palette-action-disabledBackground">
        <nav className="relative w-full h-10 flex items-center justify-between bg-palette-action-hover border-b border-palette-action-selected z-10">
          <span className={clsx('absolute left-0 top-0 w-full h-auto', { 'hidden': !LOADING })}>
            <LinearProgress />
          </span>
          <div className="flex items-center justify-start space-x-2 px-2">
            {props.title && (
              <h1 className="flex items-center justify-start m-0">
                <span className="text-sm font-medium leading-none">{props.title}</span>
              </h1>
            )}
          </div>
          <div className="flex items-center justify-end space-x-2 px-4">
            {disableCloseDetail && (
              <Tooltip title="Detay içerisinde değişiklik yapıldı">
                <IconButton
                  component="span"
                  size="small"
                  style={{
                    backgroundColor: 'rgb(241 196 15 / 20%)',
                    cursor: 'help'
                  }}
                  disableFocusRipple
                  disableRipple
                ><IconLockOutlined /></IconButton>
              </Tooltip>
            )}
            <Divider orientation="vertical" flexItem></Divider>
            <Tooltip title={!showDetail ? 'Detay göster' : settings.tableOnLeft ? 'Detay altta' : 'Detay sağda'}>
              <span>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (showDetail) {
                      handleChangeSetting('tableOnLeft', !settings.tableOnLeft)
                    } else {
                      setShowDetail(true);
                    }
                  }}
                  disabled={selectedBox === null}
                >
                  {settings.tableOnLeft ? <IconHorizontalSplit /> : <IconVerticalSplit />}
                </IconButton>
              </span>
            </Tooltip>

            <Divider orientation="vertical" flexItem></Divider>

            <Tooltip title="Listeyi yenile">
              <IconButton
                size="small"
                onClick={() => {
                  setRowsLastUpdateTime(Date.now())
                  setBilesenPartsCanSave([false, false, false, false, false, false, false]);
                }}
              >
                <IconRefresh />
              </IconButton>
            </Tooltip>
          </div>
        </nav>

        <div className="relative w-full h-full flex overflow-hidden">
          <div
            className={clsx('relative w-full h-full flex overflow-hidden', {
              'flex-row': settings.tableOnLeft,
              'flex-col': !settings.tableOnLeft,
            })}
          >
            <div
              className={clsx('flex flex-col items-start', {
                'border-r border-palette-action-hover': showDetail,
              })}
              style={{
                width: TABLE_CONTAINER_WIDTH,
                height: TABLE_CONTAINER_HEIGHT,
              }}
              ref={tableContainerRef}
            >

              <div className="relative w-full h-full bg-palette-background-paper overflow-y-auto">
                <>
                  <div className={classes.layoutContainer}>
                    <ul className={classes.layoutFormList}>
                      <li className={classes.layoutFormItem}>
                        <label className={classes.layoutFormItemLabel}>Site </label>
                        <Tooltip title={selectedSite === null ? "" : siteDic ? siteDic[selectedSite] : ""}>
                          <TextField
                            name="type"
                            variant="outlined"
                            size="small"
                            value={selectedSite}
                            fullWidth={true}
                            select
                            SelectProps={{
                              native: true,
                            }}
                            onChange={(e) => {
                              confirmOpenDetail(() => {
                                handleChangeSelects("site", e.target.value, e.target.options[e.target.selectedIndex].text)
                              });
                            }}
                          >
                            {site.map((s) => (
                              <option key={s.id} value={s.id}>{s.name}</option>
                            ))}
                          </TextField>
                        </Tooltip>
                      </li>
                      <li className={classes.layoutFormItem}>
                        <label className={classes.layoutFormItemLabel}>Sayfa Tip </label>
                        <Tooltip title={selectedSayfaTip === null ? "" : sayfaTipDic ? sayfaTipDic[selectedSayfaTip] : ""}>
                          <TextField
                            name="type"
                            variant="outlined"
                            size="small"
                            value={selectedSayfaTip}
                            fullWidth={true}
                            select
                            SelectProps={{
                              native: true,
                            }}
                            onChange={(e) => {
                              confirmOpenDetail(() => {
                                handleChangeSelects("sayfa", e.target.value, e.target.options[e.target.selectedIndex].text)
                              });
                            }}
                          >
                            {sayfaTip.map((s) => (
                              <option key={s.key} value={s.key}>{s.value}</option>
                            ))}
                          </TextField>
                        </Tooltip>
                      </li>
                      <li className={classes.layoutFormItem}>
                        <label className={classes.layoutFormItemLabel}>Web/Mobil </label>
                        <Tooltip title={selectedType === "0" ? "Web" : "Mobile"}>
                          <TextField
                            name="type"
                            variant="outlined"
                            size="small"
                            value={selectedType}
                            fullWidth={true}
                            select
                            SelectProps={{
                              native: true,
                            }}
                            onChange={(e) => {
                              confirmOpenDetail(() => {
                                handleChangeSelects("tip", e.target.value, e.target.options[e.target.selectedIndex].text)
                              });
                            }}
                          >
                            <option key="0" value="0">Web</option>
                            <option key="1" value="1">Mobil</option>
                          </TextField>
                        </Tooltip>
                      </li>
                    </ul>
                  </div>

                  <div className={classes.layoutContainer}>
                    <div className='flex flex-col w-auto h-full'>
                      <ThemeProvider theme={theme}>
                        <TableContainer component={Paper} className='border-palette-action-disabledBackground'>
                          {loadError === false && (
                            <Table>
                              <TableBody>
                                {selectedType === "0" && !LOADING && (
                                  <>
                                    <TableRow>{createBilesenFields(0, selectedType, "Üstün Üstü", 3)}</TableRow>
                                    <TableRow>{createBilesenFields(5, selectedType, "Üstün Altı", 3)}</TableRow>
                                    <TableRow>
                                      {selectedSayfaTip * 1 === 0 && (
                                        <>
                                          {createBilesenFields(4, selectedType, "Orta", 3)}
                                        </>
                                      )}
                                      {selectedSayfaTip * 1 !== 0 && (
                                        <>
                                          {createBilesenFields(2, selectedType, "Sol", 1)}
                                          {createBilesenFields(4, selectedType, "Orta", 1)}
                                          {createBilesenFields(3, selectedType, "Sağ", 1)}
                                        </>
                                      )}

                                    </TableRow>
                                    <TableRow>{createBilesenFields(1, selectedType, "Altın Üstü", 3)}</TableRow>
                                    <TableRow>{createBilesenFields(6, selectedType, "Altın Altı", 3)}</TableRow>
                                  </>
                                )}
                                {selectedType === "1" && !LOADING && (
                                  <>
                                    <TableRow>
                                      {createBilesenFields(0, selectedType, "Üstün Solu", 1)}
                                      {createBilesenFields(5, selectedType, "Üstün Sağı", 1)}
                                    </TableRow>
                                    <TableRow>{createBilesenFields(4, selectedType, "Orta", 2)}</TableRow>
                                    <TableRow>{createBilesenFields(1, selectedType, "Altın Üstü", 2)}</TableRow>
                                    <TableRow>{createBilesenFields(6, selectedType, "Altın Altı", 2)}</TableRow>
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          )}
                          {loadError === true && (
                            <Hata />
                          )}
                        </TableContainer>
                      </ThemeProvider>
                    </div>
                  </div>
                </>
              </div>
              <footer className="w-full flex items-center justify-between bg-palette-action-hover border-t border-palette-action-selected py-1 px-4">
                <span>{count === 0 ? 'Kayıt bulunamadı' : `Toplam kayıt ${count}`} </span>
              </footer>
            </div>

            {showDetail && (
              <>
                <div
                  className={clsx('relative flex items-center justify-center', {
                    'w-4 h-full cursor-col-resize space-x-2px flex-row rounded-r-full': settings.tableOnLeft,
                    'h-4 w-full cursor-row-resize space-y-2px flex-col rounded-b-full': !settings.tableOnLeft,
                  })}
                  onMouseDown={handleResize}
                >
                  {[0, 1].map((x) =>
                    <span
                      key={x}
                      className={clsx('block bg-palette-action-disabled', {
                        'w-px h-6': settings.tableOnLeft,
                        'h-px w-6': !settings.tableOnLeft,
                      })}
                    />
                  )}
                </div>

                {/* detail.container */}
                <Slide in direction={settings.tableOnLeft ? 'left' : 'up'} mountOnEnter unmountOnExit ref={detailRef}>
                  <main
                    className={clsx('flex-1 w-full h-full overflow-hidden pr-4 pb-4 relative', {
                      'pt-4': settings.tableOnLeft,
                      'pl-4': !settings.tableOnLeft,
                    })}
                  >
                    <props.detailComponent
                      id={selectedBox?.id}
                      addnew={addnew}
                      addNewInfo={addInfo}

                      controlBolum={(bolum) => handleControl(bolum)}

                      reloadPage={() => setRowsLastUpdateTime(Date.now())}

                      onAdded={(id) => {
                        setRowsLastUpdateTime(Date.now());
                        setAddNew(false);
                        setSelectedBoxId(id);
                      }}

                      onUpdated={(id) => {
                        setRowsLastUpdateTime(Date.now());
                        setAddNew(false);
                        setSelectedBoxId(id);
                      }}

                      onDeleted={() => {
                        setShowDetail(false);
                        setSelectedBoxId(null);
                        setSelectedBox(null);
                        setRowsLastUpdateTime(Date.now());
                      }}

                      onDisableClose={(status) => setDisableCloseDetail(status)}

                      onClose={() => {
                        confirmCloseDetail(() => {
                          setShowDetail(false);
                          setAddNew(false);
                        });
                      }}

                    />
                    <div
                      className={clsx('absolute tracking-widest text-xs pointer-events-none', {
                        'top-2/4 -left-4 transform rotate-90 -translate-y-2/4': settings.tableOnLeft && detailRef.current?.clientWidth === 16,
                        'top-0 left-2/4 transform -translate-x-2/4': !settings.tableOnLeft && detailRef.current?.clientHeight === 16,
                        'hidden': (settings.tableOnLeft && detailRef.current?.clientWidth !== 16) || (!settings.tableOnLeft && detailRef.current?.clientHeight !== 16),
                      })}
                    >
                      DETAY
                    </div>
                  </main>
                </Slide>
              </>
            )}
          </div>
        </div>
      </div>

      {openSelectorBilesen &&
        <Dialog
          open={openSelectorBilesen}
          PaperProps={{
            style: {
              width: '480px',
              height: '600px',
            },
          }}
          onClose={() => {
            setOpenSelectorBilesen(false);
            setSelectedBilesentoAdd(null);
          }}
        >
          <DialogContent style={{ padding: 0 }}>
            <ListSelectorSingle
              title='Bilesenler'
              listPath='bilesen/?aktif=true'
              listCountPath='bilesen/count?aktif=true'
              keyProp='id'
              valueProp='tanim'
              defaultItem={selectedBilesentoAdd}
              onChangeItems={(items) => setSelectedBilesentoAdd({ ...items, bolumler: items.bolumler.map(x => x.id), mobil_bolumler: items.mobil_bolumler.map(x => x.id) })}
              onClose={() => {
                setOpenSelectorBilesen(false);
                setSelectedBilesentoAdd(null);
              }}
              confirmButton={true}
              confirmEnable={true}
              onConfirm={() => {
                confirmOpenDetail(() => {
                  handleAddNewFromListe()
                });
              }}
              confirmBtnTitle="Uygula"
              newPath='tanimlar/bilesen'
              extraVal="bolumler"
              extraVal2="mobil_bolumler"
            />
          </DialogContent>
        </Dialog>
      }
      {showDeleteDialog && (
        <DialogConfirm
          title='Sil'
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Seçtiğiniz satır siliniyor lütfen bekleyin'
                : 'Bu satırı gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={(e) => handleDeleteBilesen(e)}
          onCancel={() => setTimeout(() => setShowDeleteDialog(false))}
        ></DialogConfirm>
      )}
    </>
  );


});
export default GridLayoutDetail;