import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  Tooltip,
} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';

import ListSelector from '../../../components/ListSelector';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

import MultipleSelectList from '../../../components/MultipleSelectList';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
  };
};

const SozlesmeDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);
  const add = useMemo(() => props.add, [props.add]);



  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);


  const handleRemoveMagazaById = (id) => {
    const copymagazalar = [...props.form.magazalar];
    const index = copymagazalar.findIndex((c) => c.id === id);
    if (index > -1) {
      copymagazalar.splice(index, 1);
      props.onChangeForm('magazalar', copymagazalar);
    }
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="tanim">
                    <label className={classes.layoutFormItemLabel} htmlFor="tanim">Sözleşme Tanım *</label>
                    <Tooltip title={props.error?.tanim ? props.error.tanim[0] :  _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                      <TextField
                        {...createTextFieldProps('tanim')}
                        onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                        onBlur={() => handleInputErrors('sozlesme', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Sözleşme Tanım', 'sözleşme')}
                        inputProps={{
                          maxLength: 250,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="kodu">
                    <label className={classes.layoutFormItemLabel} htmlFor="tanim">Sözleşme Kodu *</label>
                    <Tooltip title={props.error?.kodu ? props.error.kodu[0] : _isNil(props.form?.kodu) ? '' : props.form.kodu}>
                      <TextField
                        {...createTextFieldProps('kodu')}
                        onChange={(e) => props.onChangeForm('kodu', e.target.value)}
                        onBlur={() => handleInputErrors('sozlesme', props.form, props.formdefault, props.onCheckErrors, add, 'kodu', true, true, false, '', 'Sözleşme Kodu', 'sözleşme')}
                        inputProps={{
                          maxLength: 250,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="tip">
                    <label className={classes.layoutFormItemLabel} htmlFor="tip">Sözleşme Tip *</label>
                    <Tooltip title={props.error?.tip ? props.error.tip[0] : _isNil(props.form?.tip) ? '' : props.tipDic ? props.tipDic[props.form.tip] : ''}>
                      <TextField
                        {...createTextFieldProps('tip')}
                        onChange={(e) => props.onChangeForm('tip', e.target.value)}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        {props.tip.map((bt) => (
                          <option key={bt.key} value={bt.key}>{bt.value}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="siralama">
                    <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                    <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                      <TextField
                        {...createTextFieldProps('siralama')}
                        onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                        type="number"
                        inputProps={{
                          min: -2147483648,
                          max: 2147483647,
                          step: 1,
                          style: { textAlign: 'right' }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="aktif">
                    <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                    <Checkbox
                      name="aktif"
                      color="primary"
                      checked={_result(props.form, 'aktif', false)}
                      onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                    ></Checkbox>
                  </li>
                </ul>

                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItemBox} id="magazalar">
                    <MultipleSelectList
                      headername="MAĞAZALAR"
                      name="Mağazalar"
                      name2="Mağaza"
                      form={props.form}
                      list={props.form?.magazalar ?? []}
                      listlength={props?.form?.magazalar?.length ?? 0}
                      opendialog={setOpenSelectorMagaza}
                      keyProp="id"
                      valueProp="tanim"
                      removefonk={handleRemoveMagazaById}
                      loading={props.loading}
                      error={props.error?.magazalar ? props.error.magazalar[0] : null}
                      newPath="tanimlar/magaza"
                      ustId={false}
                    />
                  </li>

                </ul>
              </div>

              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => { setOpenSelectorMagaza(false) }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Mağazalar'
                      listPath='magaza/mini_list?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.magazalar}
                      onChangeItems={(items) => props.onChangeForm('magazalar', items)}
                      onClose={() => { setOpenSelectorMagaza(false) }}
                      newPath="tanimlar/magaza"
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default SozlesmeDetailTabGenel;
