import React from 'react';
import { useParams } from 'react-router-dom';

import DilDetail from './DilDetail';

const PageDilDetail = () => {
  const params = useParams();

  return (
    <DilDetail
      id={params?.id ? params.id === "yeni" ? null : params.id : params.id}
      addnew={params?.addnew ? params.addnew === "yeni" ? true : false : false}
      single={true}
    />
  );
};

export default PageDilDetail;