import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Countdown from 'react-countdown';

import {
  CssBaseline,
  Divider,
  IconButton,
  Tooltip,
  Slide,
  Dialog,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import {
  isEmpty as _isEmpty
} from 'lodash';

import {
  Brightness2 as IconBrightness2,
  ExitToApp as IconExitToApp,
  Menu as IconMenu,
  Close as IconClose,
  Person as IconPerson,
  WbSunnyOutlined as IconWbSunnyOutlined,
  Fullscreen as IconFullscreen,
  FullscreenExit as IconFullscreenExit,
  Notifications as IconNotificationsNoneIcon
} from '@material-ui/icons';

import { ThemeProvider } from '@material-ui/core/styles';

import URLS from '../../urls';
import Themes from '../../themes/index';
import Store from '../../libs/store';
import UIContext from '../../contexts/UIContext';
import { parseJwt } from "../../helpers/helpers";

import MenuSearchInput from '../../components/MenuSearchInput';
import SideBar2 from '../../components/Sidebar2';
import DropList from './DropList';

import LOGO from '../../styles/img/eganlogo.png';
import LOGO_WHITE from '../../styles/img/eganlogo-white.png';

import django from '../../api/django';

const SIDEBAR_WIDTH = 240;

const DashboardBase = (props) => {
  const [uiContext, setUIContext] = useContext(UIContext);
  const [themeChoice, setThemeChoice] = useState('light');

  const [showFullscreen, setShowFullscreen] = useState(false);

  const [openNotifications, setOpenNotifications] = useState(false);
  const [openNotificationsConfirm, setOpenNotificationsConfirm] = useState(false);
  const [firstCounct, setFirstCount] = useState(0);
  const [lastId, setLastId] = useState(null);
  const [unDoneNotifications, setUndoneNotifications] = useState(0);
  const [notificationsText, setNotificationsText] = useState("");
  const [timeState, setTimeState] = useState(false);
  const [warnTime, setWarnTime] = useState(0);


  const history = useHistory();

  // toggle fullscreen status
  useEffect(() => {
    if (document.documentElement && document.fullscreenEnabled) {
      if (showFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    }
  }, [showFullscreen]);

  // toggle dark mode class for html tag
  useEffect(() => {
    if (themeChoice === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [themeChoice]);

  useEffect(() => {
    django('iliskilitanim/count?tamamlandi=false').then(({ data }) => {
      setFirstCount(data);
      setUndoneNotifications(data)
    });
    django('iliskilitanim/?tamamlandi=false').then(({ data }) => {
      if (data.length > 0) {
        const id = data.reduce((prev, current) => (prev.id > current.id) ? prev : current)?.id;
        setLastId(id);
      }
    });
  }, []);

  useEffect(() => {
    if (lastId !== null) {
      const interval = setInterval(() => {
        django('iliskilitanim', { params: { id: `>${lastId}`, tamamlandi: false } }).then(({ data }) => {
          if (data) {
            const arr = data.filter(x => x.id !== lastId);
            if (!_isEmpty(arr)) {
              const id = arr.reduce((prev, current) => (prev.id > current.id) ? prev : current)?.id;
              setLastId(id);
              setUndoneNotifications((prev) => prev + arr.length)
            }
          }
        })
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [firstCounct, lastId]);

  useEffect(() => {
    const token = Store.get('auth').access;
    const { exp } = parseJwt(token);
    const date = new Date(exp * 1000);
    date.setMinutes(date.getMinutes() - 5);
    const time = date.getTime() - new Date().getTime();
    if (time < 300000) {
      setTimeState(true);
      setWarnTime(Math.abs(time))
    } else {
      const debounce = setTimeout(() => {
        setTimeState(true);
        setWarnTime(300000);
      }, time);
      return () => {
        clearTimeout(debounce);
      }
    }
  }, []);

  const handleSwitchTheme = () => {
    const theme = themeChoice === 'light' ? 'dark' : 'light';
    Store.set('themeChoice', { theme });
    setThemeChoice(theme);
  };

  const handleLogout = () => {
    Store.delete('auth');
    history.push(URLS.user.login);
  }

  const handleClick = (item) => {
    let text = "";
    switch (item.kullanim_alani_tip) {
      case 0:
        text = "ülke ";
        break;
      case 1:
        text = "şehir";
        break;
      case 2:
        text = "ilçe";
        break;
      default:
        break;
    }
    setNotificationsText(text)
    if (item.tamamlandi === false) {
      if (item.ust_idler.length > 0) {
        const path = URLS.tanimlar.ulkesehirilce.list + item.ust_idler.join('/');
        history.push(path, { tip: item.kullanim_alani_tip, ust_id: item.ust_idler });
      } else {
        if (item.kullanim_alani_tip === 0) {
          history.push(URLS.tanimlar.ulkesehirilce.list, { tip: item.kullanim_alani_tip, ust_id: item.ust_idler })
        }
      }
    }
  }

  const renderer = ({ minutes, seconds }) => {
    return (
      <span className="flex items-center">
        <span className="flex justify-center items-center border border-palette-action-selected bg-palette-background-paper rounded p-0.5 w-5">{minutes}</span>
        <span className="px-1">:</span>
        <span className="flex justify-center items-center border border-palette-action-selected bg-palette-background-paper rounded p-0.5 w-5">{seconds}</span>
      </span>
    );
  };


  const theme = useMemo(() => Themes[themeChoice], [themeChoice]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {/* full screen container */}
      <div className="fixed w-screen h-screen inset-0 flex flex-col overflow-hidden">

        {/* navbar */}
        <nav className="relative w-full flex items-center justify-between bg-palette-background-paper border-b border-palette-action-disabledBackground px-4">

          {/* navbar left */}
          <span className="flex items-center justify-start space-x-2">
            <IconButton
              size="small"
              edge="start"
              disableRipple
              onClick={() => {
                setUIContext({
                  sideBarStatus: !uiContext.sideBarStatus,
                });
              }}
            >
              {uiContext.sideBarStatus ? <IconClose /> : <IconMenu />}
            </IconButton>

            <Link
              className="block w-24 focus:outline-none focus:ring-2 ring-primary-300 rounded-sm p-1"
              to={URLS.root}
            >
              <img
                style={{
                  display: 'block',
                  width: '100%',
                  height: 'auto',
                }}
                src={themeChoice === 'dark' ? LOGO_WHITE : LOGO}
                alt=""
              />
            </Link>

            <MenuSearchInput />
          </span>

          {/* navbar right */}
          <span className="flex items-center justify-end space-x-2">

            {timeState && (
              <Tooltip title="Oturumun kapanmasına kalan süre">
                <div className="cursor-pointer border border-palette-action-selected bg-palette-background-default p-0.5"><Countdown date={Date.now() + warnTime} renderer={renderer} /></div>
              </Tooltip>
            )}

            <Tooltip title="Bildirimler">
              <IconButton size="small" onClick={() => setOpenNotifications(!openNotifications)}>
                <IconNotificationsNoneIcon />
                {unDoneNotifications > 0 && (
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      fontSize: 10,
                      background: "red",
                      color: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      width: 15,
                      height: 15
                    }}
                  >{unDoneNotifications}</span>
                )}
              </IconButton>
            </Tooltip>

            <Tooltip title={themeChoice === 'light' ? "Karanlık Moda Geç" : "Aydınlık Moda Geç"}>
              <IconButton size="small" onClick={handleSwitchTheme}>
                {themeChoice === 'light' ? <IconBrightness2 /> : <IconWbSunnyOutlined />}
              </IconButton>
            </Tooltip>

            <Tooltip title={showFullscreen ? 'Tam Ekran Modundan Çık' : 'Tam Ekran Moduna Geç'}>
              <IconButton size="small" onClick={() => setShowFullscreen(!showFullscreen)}>
                {showFullscreen && <IconFullscreenExit />}
                {!showFullscreen && <IconFullscreen />}
              </IconButton>
            </Tooltip>

            <Divider orientation="vertical" flexItem />

            <Tooltip title="Kullanıcı Hesabı">
              <IconButton size="small">
                <IconPerson />
              </IconButton>
            </Tooltip>

            <Tooltip title="Çıkış">
              <IconButton size="small" onClick={handleLogout}>
                <IconExitToApp />
              </IconButton>
            </Tooltip>

          </span>

          {openNotifications && (
            <span style={{ width: 300, height: 300, position: "absolute", right: 0, top: "100%", zIndex: 9999 }}>
              <DropList
                onClickAway={() => setOpenNotifications(false)}
                onClickItem={(item) => {
                  if (item.tamamlandi === false) {
                    setOpenNotificationsConfirm(true);
                    handleClick(item);
                  }
                }}
              />
            </span>
          )}

        </nav>

        {/* contain */}
        <span className="w-full h-full flex flex-row flex-grow overflow-hidden">

          {/* sidebar */}
          <Slide in={uiContext.sideBarStatus} direction="right" mountOnEnter unmountOnExit>
            <aside
              className="h-full bg-palette-background-paper"
              style={{
                width: SIDEBAR_WIDTH,
              }}
            >
              <SideBar2 />
            </aside>
          </Slide>

          {/* main */}
          <main className="w-full h-full flex-1 overflow-auto p-4">
            {props.children}
          </main>

        </span>
      </div>
      {openNotificationsConfirm &&
        <Dialog
          open={openNotificationsConfirm}
          PaperProps={{
            style: {
              width: '480px',
              height: '140px',
            },
          }}
          onClose={() => setOpenNotificationsConfirm(false)}
        >
          <DialogContent>
            <DialogContentText>
              <span className="text-center text-sm text-palette-text-primary">
                {`Bu ${notificationsText} tanımını eklemeden önce ${notificationsText} listesinde daha önce eklenmiş olup olmadığını kontrol ediniz.Eğer varsa ${notificationsText} düzenleden,yoksa yeni ${notificationsText} ekleden , ilişkili tanımlardan yararlanarak ekleyebilirsiniz.`}
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      }
    </ThemeProvider>
  );
};

export default DashboardBase;
