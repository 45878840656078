import React, { useMemo } from 'react';
import {
  result as _result,
} from 'lodash';
import { useSnackbar } from 'notistack';

import {
  Button,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(2, 2, 2, 2),
    },

    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,

      '& + &': {
        marginLeft: 32,
      },
    },

    layoutFormListItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,

      '& + &': {
        marginTop: 32,
      },
    },

    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 400,
      position: 'relative',
      '& + &': {
        marginTop: 10,
      },
    },
    layoutBorderDiv: {
      border: '1px solid #eacfea',
      position: 'relative',
      padding: spacing(2, 1)
    },
    button: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& + &': {
        marginLeft: 10,
      },
    },

    layoutFormItemLabel: {
      minWidth: 150,
      marginLeft: 10,
    },
    imagediv: {
      display: 'flex',
      width: 400,
      height: 400
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'scale-down',
      padding: spacing(4, 4, 4, 4)
    },
    error: {
      color: 'red',
      fontSize: 12
    }
  };
};

const SablonDetailTabLogo = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const { enqueueSnackbar } = useSnackbar();

  const dosyaEkle = (e, index) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const filetype = file.name.split('.').pop();
      const filesize = file.size;
      if (filesize > 256000) {
        enqueueSnackbar('Dosya boyutu 250 kb tan fazla olamaz!', { variant: 'error' })
      } else {
        if (index === 0 || index === 2 || index === 3) {
          if (filetype === 'jpg' || filetype === 'jpeg' || filetype === 'png') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
              props.onChangeForm(index, 'dosya', { dosya: reader.result, dosya_ismi: file.name })
            }
          } else {
            enqueueSnackbar('Sadece .jpeg,.jpg ve .png uzantılı dosyalar eklenebilir!', { variant: 'error' })
          }
        } else {
          if (filetype === 'ico' || filetype === 'png') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
              const image = new Image();
              image.src = reader.result;
              image.onload = () => {
                const width = image.width;
                const height = image.height;
                if (width === height) {
                  props.onChangeForm(index, 'dosya', { dosya: reader.result, dosya_ismi: file.name })
                } else {
                  enqueueSnackbar('Favicon resmi kare olmalıdır!', { variant: 'error' })
                }
              }
            }
          } else {
            enqueueSnackbar('Sadece .png ve .ico uzantılı dosyalar eklenebilir!', { variant: 'error' })
          }
        }
      }
    }
  }


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <div className={classes.layoutFormList}>

                  <div className={classes.layoutFormListItem}>
                    <div className={classes.layoutBorderDiv}>
                      <span className="absolute bg-palette-background-paper" style={{ top: -12, margin: 2, padding: "0 5px" }}>Logo</span>
                      <div className={classes.layoutFormItem}>
                        <div className={classes.butonDiv}>
                          <Button
                            className={classes.button}
                            component="label"
                          >LOGO EKLE<input type="file" accept=".jpeg,.jpg,.png" hidden onChange={(e) => dosyaEkle(e, 0)} />
                          </Button>
                        </div>
                        <label className={classes.layoutFormItemLabel} htmlFor="dosya_ismi">{_result(props.forms?.[0]?.form.dosya, `dosya_ismi`) || ''}</label>
                      </div>
                      <div className={classes.imagediv}>
                        {props.forms?.[0].form.dosya.dosya !== '' && (
                          <img className={classes.image} src={props.forms[0].form.dosya.dosya} alt="favico" />
                        )}
                      </div>
                      {props.forms?.[0].errors && (
                        <div className={classes.error}>{_result(props.forms[0].errors, 'dosya[0]')}</div>
                      )}
                    </div>
                  </div>

                  <div className={classes.layoutFormListItem}>
                    <div className={classes.layoutBorderDiv}>
                      <span className="absolute bg-palette-background-paper" style={{ top: -12, margin: 2, padding: "0 5px" }}>Favicon</span>
                      <div className={classes.layoutFormItem}>
                        <div className={classes.butonDiv}>
                          <Button
                            className={classes.button}
                            component="label"
                          >FAVICON EKLE<input type="file" accept=".png,.ico" hidden onChange={(e) => dosyaEkle(e, 1)} />
                          </Button>
                        </div>
                        <label className={classes.layoutFormItemLabel} htmlFor="dosya_ismi">{_result(props.forms?.[1]?.form.dosya, `dosya_ismi`) || ''}</label>
                      </div>
                      <div className={classes.imagediv}>
                        {props.forms?.[1].form.dosya.dosya !== '' && (
                          <img className={classes.image} src={props.forms[1].form.dosya.dosya} alt="logo" />
                        )}
                      </div>
                      {props.forms?.[1].errors && (
                        <div className={classes.error}>{_result(props.forms[1].errors, 'dosya[0]')}</div>
                      )}
                    </div>
                  </div>

                </div>

                <div className={classes.layoutFormList}>

                  <div className={classes.layoutFormListItem}>
                    <div className={classes.layoutBorderDiv}>
                      <span className="absolute bg-palette-background-paper" style={{ top: -12, margin: 2, padding: "0 5px" }}>Mobil Logo</span>
                      <div className={classes.layoutFormItem}>
                        <div className={classes.butonDiv}>
                          <Button
                            className={classes.button}
                            component="label"
                          >FOTOĞRAF EKLE<input type="file" accept=".jpeg,.jpg,.png" hidden onChange={(e) => dosyaEkle(e, 3)} />
                          </Button>
                        </div>
                        <label className={classes.layoutFormItemLabel} htmlFor="dosya_ismi">{_result(props.forms?.[3]?.form.dosya, `dosya_ismi`) || ''}</label>
                      </div>
                      <div className={classes.imagediv}>
                        {props.forms?.[3].form.dosya.dosya !== '' && (
                          <img className={classes.image} src={props.forms[3].form.dosya.dosya} alt="nonephoto" />
                        )}
                      </div>
                      {props.forms?.[3].errors && (
                        <div className={classes.error}>{_result(props.forms[3].errors, 'dosya[0]')}</div>
                      )}
                    </div>
                  </div>

                  <div className={classes.layoutFormListItem}>
                    <div className={classes.layoutBorderDiv}>
                      <span className="absolute bg-palette-background-paper" style={{ top: -12, margin: 2, padding: "0 5px" }}>Resim Yok Resmi
                      </span>
                      <div className={classes.layoutFormItem}>
                        <div className={classes.butonDiv}>
                          <Button
                            className={classes.button}
                            component="label"
                          >FOTOĞRAF EKLE<input type="file" accept=".jpeg,.jpg,.png" hidden onChange={(e) => dosyaEkle(e, 2)} />
                          </Button>
                        </div>
                        <label className={classes.layoutFormItemLabel} htmlFor="dosya_ismi">{_result(props.forms?.[2]?.form.dosya, `dosya_ismi`) || ''}</label>
                      </div>
                      <div className={classes.imagediv}>
                        {props.forms?.[2].form.dosya.dosya !== '' && (
                          <img className={classes.image} src={props.forms[2].form.dosya.dosya} alt="nonephoto" />
                        )}
                      </div>
                      {props.forms?.[2].errors && (
                        <div className={classes.error}>{_result(props.forms[2].errors, 'dosya[0]')}</div>
                      )}
                    </div>
                  </div>


                </div>

              </div>
            </>
          )}
        </>
      }
    </>
  );
});

export default SablonDetailTabLogo;
