import React, { useState, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import URLS from '../../../urls';
import django from '../../../api/django';
import Layout from '../../../pages/base/LayoutTableDetail';
import Detail from './EntegrasyonDetail';

const COLUMNS_DEFAULT = [
  { id: 'tanim',                tableProp: 'tanim',                         filterProp: 'tanim',                    label: 'Entegrasyon Tanım',         element: 'input',           type: 'text',     arrayTableProp: null,     show: true, sortable: true,   highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'ent_tip',              tableProp: 'ent_tip.value',                 filterProp: 'ent_tip',                  label: 'Entegrasyon Tipi',          element: 'select',          type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'isleyici_tip',         tableProp: 'isleyici_tip.value',            filterProp: 'isleyici_tip',             label: 'Entegrasyon İşleyici Tipi', element: 'select',          type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'magazalar',            tableProp: 'magazalar',                     filterProp: 'magazalar.magaza.id',      label: 'Mağazalar',                 element: 'select-multiple', type: 'array',    arrayTableProp: 'tanim',  show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'magaza',     multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null, selectKey: null,  selectValue: null, newpath: "tanimlar/magaza" },
  { id: 'tedarikci',            tableProp: 'tedarikci.username',            filterProp: 'tedarikci.id',             label: 'Tedarikçi',                 element: 'select',          type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'id',  selectValue: 'username' },
  { id: 'magaza',               tableProp: 'magaza.tanim',                  filterProp: 'magaza.id',                label: 'Ayar Mağaza',               element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'magaza',     multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null, selectKey: null,  selectValue: null, newpath: "tanimlar/magaza" },
  { id: 'depo',                 tableProp: 'depo.tanim',                    filterProp: 'depo.id',                  label: 'Depo',                      element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'depo',       multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null, selectKey: null,  selectValue: null, newpath: "tanimlar/depo" },
  { id: 'kategori',             tableProp: 'kategori.parents',              filterProp: 'kategori.id',              label: 'Kategori',                  element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kategori',   multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'parents',  multipleSelectOptionViewProp: 'parents',  shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'dil',                  tableProp: 'dil.tanim',                     filterProp: 'dil.id',                   label: 'Dil',                       element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'dil',        multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null, selectKey: null,  selectValue: null, newpath: "tanimlar/dil" },
  { id: 'aktarim',              tableProp: 'aktarim',                       filterProp: 'aktarim',                  label: 'Aktarıldı',                 element: 'select',          type: 'boolean',  arrayTableProp: null,     show: true, sortable: false,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'aktif',                tableProp: 'aktif',                         filterProp: 'aktif',                    label: 'Aktif',                     element: 'select',          type: 'boolean',  arrayTableProp: null,     show: true, sortable: false,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'siralama',             tableProp: 'siralama',                      filterProp: 'siralama',                 label: 'Sıralama',                  element: 'input',           type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'olusturan_kullanici',  tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',   label: 'Oluşturan Kullanıcı',       element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'degistiren_kullanici', tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',  label: 'Değiştiren Kullanıcı',      element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'olusturma_zamani',     tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',         label: 'Oluşturma',                 element: 'input',           type: 'datetime', arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'degistirme_zamani',    tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',        label: 'Son Değişiklik',            element: 'input',           type: 'datetime', arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
];

const PageEntegrasyonList = () => {

  const history = useHistory();
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        return column;
      });
    });
  }, []);

  useLayoutEffect(() => {
    django('enttanim/tip').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'ent_tip') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  useLayoutEffect(() => {
    django('enttanim/isleyici_tip').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'isleyici_tip') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  useLayoutEffect(() => {
    django('kullanici/tedarikci_mini_list').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'tedarikci') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  return (
    <>
      <Layout
        path="enttanim"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="enttanim"
        headerButton={true}
        headerList={[
          { title: "ENTEGRASYON", active: true, func: null, index: 0 },
          { title: "EŞLEŞTİRMELER", active: false, func: () => history.push(URLS.tanimlar.entegrasyon_eslestirme.list), index: 1 },
        ]}
      />
    </>
  );
};

export default PageEntegrasyonList;
