/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import clsx from 'clsx';
import {
  Paper,
  AppBar,
  Toolbar,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  CircularProgress,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/styles';

import {
  Delete as IconDelete,
  Save as IconSave,
  Restore as IconRestore,
  Refresh as IconRefresh,
  Add as IconAdd,
  Close as IconClose,
  Error as IconError,
  OpenInNew as IconOpenInNew
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      position: 'relative',
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.toolbar
    },
    toolbarIconButton: {
      color: 'white'
    },
    tabs: {
      backgroundColor: theme.palette.background.paper,
      minHeight: 36,
    },
    tabsScrollButton: {
      color: theme.palette.text.primary,
    },
    tab: {
      paddingTop: 0,
      paddingBottom: 0,
      minHeight: 36,
    },
  };
});


const VerticalListXDetail = (props) => {
  const classes = useStyles();

  const ICON_BUTTON_PROPS = useMemo(() => ({
    size: 'small',
    className: classes.toolbarIconButton,
    disableRipple: true,
  }), [classes]);

  return (
    <>
      <div className="flex w-full h-full">
        <Paper className="w-full h-full flex flex-col overflow-hidden">
          <AppBar className={classes.appBar} position="static" elevation={1}>
            <Toolbar variant="dense" className='border-b border-solid border-white'>
              <div className="w-full flex items-center justify-between text-white">
                <div className={clsx('flex items-center truncate', { '-ml-5': !props.single })}>
                  {!props.single &&
                    <>
                      <span
                        className={clsx('w-8 h-8 items-center justify-center', {
                          'flex': props.loading,
                          'hidden': !props.loading,
                        })}
                      ><CircularProgress color="inherit" size={16} /></span>
                      <span
                        className={clsx('w-16 h-8 items-center justify-center', {
                          'flex': !props.loading,
                          'hidden': props.loading,
                        })}
                      >
                        <Tooltip title="Kapat">
                          <IconButton
                            size="small"
                            className={classes.toolbarIconButton}
                            onClick={props.onClickCloseButton}
                          >
                            <IconClose />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Yeni Sekmede Aç">
                          <IconButton
                            size="small"
                            className={classes.toolbarIconButton}
                            onClick={props.onOpenInNewTab}
                          ><IconOpenInNew /></IconButton>
                        </Tooltip>
                      </span>
                    </>
                  }
                  <h1 className="font-medium truncate text-base m-0">{props.title}</h1>
                </div>

                <div className="flex items-center justify-end space-x-2">
                  <Tooltip title="Yeni">
                    <span>
                      <IconButton
                        {...ICON_BUTTON_PROPS}
                        onClick={props.onClickAddButton}
                        disabled={props.disableAddButton}
                      >
                        <IconAdd />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Yenile">
                    <span>
                      <IconButton
                        {...ICON_BUTTON_PROPS}
                        onClick={props.onClickReloadButton}
                        disabled={props.disableReloadButton}
                      >
                        <IconRefresh />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Değişiklikleri İptal Et">
                    <span>
                      <IconButton
                        {...ICON_BUTTON_PROPS}
                        onClick={props.onClickCancelButton}
                        disabled={props.disableCancelButton}
                      >
                        <IconRestore />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Değişiklikleri Kaydet">
                    <span>
                      <IconButton
                        {...ICON_BUTTON_PROPS}
                        onClick={props.onClickSaveButton}
                        disabled={props.disableSaveButton}
                        style={{ color: props.disableSaveButton ? "" : "green" }}
                      >
                        <IconSave />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Sil">
                    <span>
                      <IconButton
                        {...ICON_BUTTON_PROPS}
                        onClick={props.onClickDeleteButton}
                        disabled={props.disableDeleteButton}
                        style={{ color: props.disableDeleteButton ? "" : "red" }}
                      >
                        <IconDelete />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </Toolbar>
            {props.tabs.length > 0 && (
              <Tabs
                className={classes.tabs}
                value={props.selectedTabIndex}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                TabScrollButtonProps={{ className: classes.tabsScrollButton }}
                onChange={(_e, value) => props.onChangeSelectedTabIndex(value)}
              >
                {props.tabs.map((tab, tabIndex) => {
                  return (
                    <Tab
                      key={`${tab.label}-${tabIndex}`}
                      className={classes.tab}
                      label={
                        <span className="flex items-center justify-center space-x-2">
                          <span>{tab.label}</span>

                          {tab?.hasError && (
                            <IconError style={{ fontSize: 16, color: 'red' }} />
                          )}
                        </span>
                      }
                      disabled={tab.disabled}
                      style={{ display: tab?.display === true ? 'none' : 'flex' }}
                    />
                  )
                })}
              </Tabs>
            )}
          </AppBar>
          <main className='w-full h-full overflow-auto'>
            {props.children}
          </main>
        </Paper>
      </div>

    </>
  )
}

export default VerticalListXDetail;