import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { handleInputErrors, checkAllList } from '../../base/InputErrors';

import TabGenel from './EpostaAuthDetailTab_Genel';

const PATH = 'epostaauth';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  host: '',
  username: '',
  password: '',
  port: '0',
  use_tls: true,
  magazalar: []
};

const EpostaAuthDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [hataGenel, setHataGenel] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.host;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    return isEqualGenel;
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    setLoading(true);
    setTabs(TABS_DEFAULT);
    if (!IS_ADD) {
      const debounce = setTimeout(() => {
        django(`${PATH}/${ID}`).then(({ data }) => {
          const form = {
            host: data.host,
            username: data.username,
            password: data.password,
            port: String(data.port),
            use_tls: data.use_tls,
            magazalar: data.magazalar,
          };
          setGenelFormDefault(form);
          setGenelForm(form);
          setHataGenel(null);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataGenel(true);
            }
          }
        }).finally(() => {
          setLoading(false);
          setGenelError(null);
          setAdd(false);
        });
      }, 300);
      return () => {
        clearTimeout(debounce);
        setLoading(false);
      };
    } else {
      setGenelForm(FORM_DEFAULT);
      setGenelFormDefault(FORM_DEFAULT);
      setGenelError(null);
      setHataGenel(null);
      setAdd(true);
      setLoading(false);
    }
  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
    if (property === "magazalar" & value.length === 0) {
      handleInputErrors('', { ...genelForm, magazalar: [] }, genelFormDefault, handleErrors, add, 'magazalar', true, false, false, '', '', '');
    }
  };

  const handleAdd = () => {
    setAdd(true);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const checkAll = async () => {
    const list = [
      { prop: 'username', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Eposta Hesap Adı', pagename: 'eposta', prop2: '' },
      { prop: 'host', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'password', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'magazalar', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ]
    let errors = [];
    await checkAllList('epostaauth', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = () => {
    checkAll().then((res) => {
      if (_isEmpty(res)) {
        let requestoptions = {};
        const data = { ...genelForm, port: genelForm.port ? Number(genelForm.port) : null, magazalar: genelForm.magazalar?.map((x) => x.id) };
        if (IS_EQUAL) {
          requestoptions = null;
        } else {
          requestoptions = {
            config: {
              method: add ? 'POST' : 'PUT',
              url: add ? 'epostaauth' : `epostaauth/${ID}`,
              data: data
            },
            successMessage: `Eposta ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
            errorMessageUnexpected: `Eposta ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
          }
        }
        const start = async () => {
          let errorCounts = 0;
          if (requestoptions !== null) {
            setGenelError(null);
            await django(requestoptions.config).then(({ data }) => {
              enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
              if (!add) {
                props.onUpdated();
              }
              if (props.single && add) {
                history.push(URLS.tanimlar.epostaauth.detail(data.id,"detay"));
              }
              if (!props.single && add) {
                props.onAdded(data);
              }
            }).catch((error) => {
              if (error.response) {
                if (error.response.status === 500) {
                  enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
                } else {
                  setGenelError(error.response.data);
                }
              } else {
                enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
              }
              ++errorCounts;
            });
            if (errorCounts === 0) {
              setLastUpdateTime(Date.now());
            } else {
              setTabs((prev) => {
                return immer(prev, (next) => {
                  next[0].hasError = true;
                });
              });
            }
          }
        }
        start();
      } else {
        setTabs((prev) => {
          return immer(prev, (next) => {
            next[0].hasError = true;
          });
        });
      }
    })
  }

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.epostaauth.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Eposta siliniyor lütfen bekleyin'
                : 'Bu epostayı gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
    </>
  );
}

export default EpostaAuthDetail;