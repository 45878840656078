import React from 'react';
import { Typography } from '@material-ui/core';

export const Hata = () => {
  return (
    <div className="flex flex-col items-start justify-start p-4 space-y-4">
      <Typography variant="body1" color="error">
        Veri getirilirken beklenmeyen bir hata oluştu.Lütfen Egan Yazılım ile iletişime geçiniz.
      </Typography>
    </div>
  )
}