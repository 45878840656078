import React from 'react';
import { useParams } from 'react-router-dom';

import MagazaDetail from './MagazaDetail';

const PageMagazaDetaill = () => {
	const params = useParams();

	return (
		<MagazaDetail
			id={params?.id ? params.id === "yeni" ? null : params.id : params.id}
			addnew={params?.addnew ? params.addnew === "yeni" ? true : false : false}
			single={true}
		/>
	);
};

export default PageMagazaDetaill;