import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  isEmpty as _isEmpty,
  assign as _assign,
  trimEnd as _trimEnd,
  groupBy as _groupBy,
  isNil as _isNil
} from 'lodash';

import moment from 'moment';

import django from '../../../api/django';
import URLS from '../../../urls';

import LayoutDetail from '../../base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';
import { asyncForEach } from '../../../helpers/helpers';

import TabGenel from './AlisIrsaliyeDetailTab_Genel';
import TabTahsilat from './AlisIrsaliyeDetailTab_Odeme';
import TabEposta from './AlisIrsaliyeDetalTab_Eposta';

const PATH = 'evrak';
const EVRAKTIP = 8;

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'ÖDEME', disabled: false, hasError: false },
  { label: 'EPOSTA', disabled: false, hasError: false },
]
const FORM_DEFAULT =
{
  tip: '8',
  kodu: '',
  versiyon: '0',
  evrak_durum: null,
  toplam: '0.0000',
  iskonto: '0.0000',
  kalem_iskonto: '0.0000',
  ara_toplam: '0.0000',
  kargo: '0.0000',
  vergi_toplam: '0.0000',
  genel_toplam: '0.0000',
  magaza_toplam: '0.0000',
  magaza_iskonto: '0.0000',
  magaza_kalem_iskonto: '0.0000',
  magaza_ara_toplam: '0.0000',
  magaza_kargo: '0.0000',
  magaza_vergi_toplam: '0.0000',
  magaza_genel_toplam: '0.0000',
  magaza_kargo_tax: '0.0000',
  magaza_ara_toplam_tax: '0.0000',
  magaza_toplam_tax: '0.0000',
  evrak_tarihi: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
  para_birimi: '-1',
  magaza_para_birimi: '-1',
  aciklama: '',
  evrak_kur: '',
  ust: [],
  evrak_kullanici: null,
  magaza: null,
  cikis_depo: null,
  iskontolar: [],
  evrak_kargo: {
    kargo: {
      id: null,
      tanim: "",
      vergi: null
    },
    kargo_fiyat: "0.0000",
    kargo_vergi_oran: "0.00",
    kargo_adi: "",
    kargo_durum: null,
    kargo_takip_no: null
  },
  secilen_kargo: null,
  evrak_ek_bilgiler: [],
  ek_bilgiler: []
}

const ADRES_FORM_DEFAULT = [
  {
    tip: '0',
    adres_json: {},
    adres: '-1'
  },
  {
    tip: '1',
    adres_json: {},
    adres: '-1'
  }
];

const URUNFORMDEFAULT = {
  urun: null,
  birim: null,
  birim_carpan: '',
  birim_kodu: '',
  miktar: '0',
  birim_fiyat: '0.0000',
  satis_fiyat: '0.0000',
  net_satis_fiyat: '0.0000',
  tutar: '0.0000',
  net_tutar: '0.0000',
  para_birimi: '-1',
  satis_para_birimi: '-1',
  urun_doviz_kur: '0.0000',
  cari_doviz_kur: '0.0000',
  iskontolu_satis_fiyat: '0.0000',
  urun_detay_vergi: [],
  urun_detay_iskonto: [],
  birimliste: [],
  birimDic: {},
  miktar_kodu: '',
  ek_bilgi: [],
}

const getAdresList = async (id) => {
  let adresler = [];
  if (id) {
    await django(`kullanici/${id}/adresler`).then(({ data }) => {
      const adresses = data.map((a) => (
        {
          adres: String(a.id),
          tanim: a.tanim,
          adres_json: {
            adres: a.adres,
            ilce: a.ilce.tanim + '/',
            sehir: a.sehir.tanim + '/',
            ulke: a.ulke.tanim,
            ulkeId: a.ulke.id,
          }
        }
      ));
      adresler = adresses;
    });
  }
  return adresler;
}

const getDesiliKargo = async (kargolar) => {
  const kargoList = [];
  for (const kargo of kargolar) {
    if (kargo.calisma_tip.key === 1) {
      const desiList = (await django(`kargo/${kargo.id}/desi`)).data;
      kargoList.push({ cargo: kargo, desi: desiList })
    } else {
      kargoList.push({ cargo: kargo });
    }
  }
  return kargoList;
}

const getVergiList = async (vergiTipler) => {
  let vergiList = [];
  for (const vergi of vergiTipler) {
    const list = (await django(`vergi_tip/${vergi.id}/vergi`)).data;
    for (const v of list) {
      const listoran = (await django(`vergi_tip/${vergi.id}/vergi/${v.id}/vergioran`)).data;
      vergiList.push(listoran);
    }
  }
  vergiList = vergiList.length > 0 ? [].concat.apply([], vergiList) : vergiList;
  return vergiList;
}

const getEkAlan = async (magaza, ekalanlar) => {
  const liste = [];
  if (magaza) {
    const list = (await django(`ek_alan_tanim/mini_list/?magazalar.magaza.id=${magaza}&alan_iliski_tip=${EVRAKTIP}&detay=false`, { params: { sort: 'siralama' } })).data;
    if (list.length > 0) {
      for (const e of list) {
        const elemanlar = (await django(`ek_alan_tanim/${e.id}/ek_alan_alan/`, { params: { sort: 'siralama' } })).data;
        elemanlar.forEach((eleman) => {
          const data = {
            ek_alan: eleman.id,
            alan_tip: eleman.alan_tip.key,
            zorunlu: eleman.zorunlu,
            tanim: eleman.tanim,
            secim_degerleri: eleman.secim_degerleri.map(sd => sd.deger),
            veri: eleman.alan_tip.key === 2 ? eleman.secim_degerleri[0].deger : ""
          };
          const ek_alan = ekalanlar?.find((ea) => ea.ek_alan === eleman.id);
          if (ek_alan) {
            data.veri = ek_alan.veri;
            data.id = ek_alan.id;
          }
          liste.push(data);
        });
      }
    }
  }
  return liste;
}

const getEkAlanUrun = (ekAlan, urunEkAlan) => {
  const liste = [];
  let list = [].concat.apply([], ekAlan.map((x) => { return x.ek_alanlar.map((y) => ({ ...y, alan_iliski_tip: x.alan_iliski_tip.map(ai => ai.key), alan_magazalar: x.magazalar.map(am => am.id) })) }));
  for (const i in list) {
    const x = list[i];
    const form = {
      ek_alan: x.id,
      alan_tip: x.alan_tip.key,
      zorunlu: x.zorunlu,
      tanim: x.tanim,
      secim_degerleri: x.secim_degerleri.map(sd => sd.deger),
      veri: x.alan_tip.key === 2 ? x.secim_degerleri[0].deger : "",
      index: i,
      alan_iliski_tip: x.alan_iliski_tip,
      alan_magazalar: x.alan_magazalar,
    }
    const u_ek_alan = urunEkAlan.find((ea) => ea.ek_alan === x.id);
    if (u_ek_alan) {
      form.veri = u_ek_alan.veri;
    }
    liste.push(form);
  }
  return liste;
}

const createTahsilat = async (ids) => {
  const liste = [];
  for (const id of ids) {
    const formdata = (await django(`evrak/${id}/get_tahsilat`)).data;
    const kullanici = formdata.evrak_kullanici;
    delete kullanici.username;
    const form = {
      tip: formdata.tip,
      para_birimi: formdata.para_birimi,
      para_birimi_id: String(formdata.para_birimi.id),
      evrak_kullanici: kullanici,
      kodu: formdata.kodu,
      genel_toplam: formdata.genel_toplam,
      evrak_tarihi: moment(formdata.evrak_tarihi).format('DD.MM.YYYY, HH.mm'),
      aciklama: formdata.aciklama ?? '',
      magaza: formdata.magaza,
      evrak_tahsilat: formdata.evrak_tahsilat.map((x) => ({
        id: x.id,
        tahsilat_vade: x.tahsilat_vade.map((vade) => ({
          satir_no: vade.satir_no,
          vade: moment(vade.vade).format('DD.MM.YYYY'),
          tutar: vade.tutar,
          tahsilat_tarihi: moment(vade.tahsilat_tarihi).format('DD.MM.YYYY'),
          satir_kodu: vade.satir_kodu,
          status: vade.status,
          kimin: vade.kimin,
          firma: vade.firma,
          aciklama: vade.aciklama ?? '',
          cek_banka_bilgi: x.tip === 3 ? vade.cek_banka_bilgi :
            {
              adi: vade.cek_banka_bilgi.adi,
              sube_kodu: vade.cek_banka_bilgi.sube_kodu,
              hesap_kodu: vade.cek_banka_bilgi.hesap_kodu,
              iban: vade.cek_banka_bilgi.iban,
              cek_sira_no: vade.cek_banka_bilgi.cek_sira_no,
            },
          kefil_bilgi: x.tip === 3 ? vade.kefil_bilgi :
            {
              ad_soyad: vade.kefil_bilgi.ad_soyad,
              telefon: vade.kefil_bilgi.telefon,
              adres: vade.kefil_bilgi.adres,
              kimlik_no: vade.kefil_bilgi.kimlik_no,
            }
        })),
        tip: String(x.tip),
        tahsil_edildi: x.tahsil_edildi,
        kur: x.kur,
        cari_hesap_kur: x.cari_hesap_kur,
        aciklama: x.aciklama,
        tutar: x.tutar,
        net_tutar: x.net_tutar,
        para_birimi: x.para_birimi,
        para_birimi_id: x.para_birimi.id,
        odeme_tanim: x.odeme_tanim,
        banka_hesap: x.banka_hesap,
        odeme_vade: x.odeme_vade,
        cari_hesap_para_birim: x.cari_hesap_para_birim,
        cari_hesap_para_birim_id: x.cari_hesap_para_birim.id,
      }))
    }
    liste.push(form);
  }
  return liste;
}

const createOdemesizTahsilat = async (odemesizForm, odemeTanim, bankalar, paraBirimList, kurlar) => {
  const calcKur = (para1, para2, kurlist = kurlar) => {
    let kur = '0.0000';
    if (paraBirimList.length === 1) {
      kur = '1.0000';
    } else {
      if (kurlist.length > 0) {
        if (para1 !== '-1' && para2 !== '-1') {
          if (para1 === para2) {
            kur = '1.0000';
          } else {
            if (kurlist.findIndex((x) => x.parabirim.id === (para1 * 1) && x.hedef_parabirim.id === (para2 * 1)) !== -1) {
              kur = kurlist.find((x) => x.parabirim.id === (para1 * 1) && x.hedef_parabirim.id === (para2 * 1)).deger;
            }
          }
        }
      } else {
        if (para1 !== '-1' && para2 !== '-1') {
          if (para1 === para2) {
            kur = '1.0000';
          } else {
            // enqueueSnackbar('Evrak tarihine ait kur bulunmamaktadır!Devam etmek için kur tanımlayınız.', { variant: 'error' });
          }
        }
      }
    }
    return kur;
  }

  const formGenel = odemesizForm.evrak;
  const arr = odemesizForm.detay;
  const kodu = (await django(`evrak/evrak_kod/${formGenel.magaza.id}/12`)).data;
  let toplam = 0;
  let form = {
    evrak: {
      tip: formGenel.tip,
      para_birimi: formGenel.para_birimi,
      evrak_kullanici: formGenel.evrak_kullanici,
      kodu: kodu?.value,
      genel_toplam: formGenel.genel_toplam,
      evrak_tarihi: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
      aciklama: "",
      evrak_kur: "1.0000",
      versiyon: "0",
      ort_vade_gun: '0',
      ort_vade_tarih: moment(Date.now()).format('YYYY-MM-DD'),
      magaza: formGenel.magaza,
      iliski: formGenel.iliski
    },
    tahsilat_detay: []
  }
  for (const item of arr) {
    const odemetanimresponse = odemeTanim;
    const bankahesapresponse = bankalar;
    let odemetanimlist = [];
    let bankalist = [];
    let parabirimlist = [];
    odemetanimlist = odemetanimresponse.filter((x) => x.tip.key === item.tip.key);
    const odemetanim = odemetanimlist.find((x) => x.id === item.odeme_tanim.id);
    bankalist = odemetanim.banka_hesaplari;
    const banka = bankahesapresponse.filter((x) => x.id === (item?.banka_hesap?.id ?? 1));
    parabirimlist = [banka[0].para_birim];
    let tutar = '';
    const kur = calcKur(formGenel.para_birimi, String(item?.banka_hesap?.para_birimi?.id ?? 1));
    if (String(item?.banka_hesap?.para_birimi?.id ?? 1) === formGenel.para_birimi) {
      tutar = Number(item.net_tutar).toFixed(4);
    } else {
      tutar = (Number(item.net_tutar) * (Number('1.0000') / Number(kur))).toFixed(4);
    }
    const row = {
      vade: null,
      tip: String(item.tip.key),
      tahsil_edildi: true,
      aciklama: '',
      tutar: tutar,
      net_tutar: item.net_tutar,
      ortalama_vade_gun: '0',
      ortalama_vade_tarih: moment(Date.now()).format('YYYY-MM-DD'),
      tahsilat_tarih: moment(Date.now()).format('YYYY-MM-DD'),
      para_birimi: String(item?.banka_hesap?.para_birimi?.id ?? 1),
      odeme_tanim: String(item.odeme_tanim.id),
      banka_hesap: String(item?.banka_hesap?.id ?? 1),
      odeme_vade: null,
      cari_hesap_para_birim: formGenel.para_birimi,
      sezon: null,
      kur: kur,
      cari_hesap_kur: '1.0000',
      odemetanimlist: odemetanimlist,
      bankalist: bankalist,
      parabirimlist: parabirimlist
    }
    toplam += (Number(tutar) * Number(kur));
    form.tahsilat_detay.push(row);
  }
  form.evrak.genel_toplam = toplam.toFixed(4);
  return form;
}

const createEvrakDurum = async (durum) => {
  const res = (await (django(`evrakdurumtanim/${durum.durum.id}`))).data;
  const form = {
    id: durum.id,
    mail_sablon: String(durum.mail_sablon?.id ?? -1),
    mail_sablonlar: res.mail_sablonlar,
    mail_sablonlar_dic: Object.assign({}, ...res.mail_sablonlar.map((x) => ({ [x.id]: x.tanim }))),
    mail_sablon_site: String(durum.mail_sablon_site?.id ?? -1),
    mail_sablonlar_site: res.mail_sablonlar_site,
    mail_sablonlar_site_dic: Object.assign({}, ...res.mail_sablonlar_site.map((x) => ({ [x.id]: x.tanim }))),
    pdf_sablon: String(durum.pdf_sablon?.id ?? -1),
    pdf_sablonlar: res.pdf_sablonlar,
    pdf_sablonlar_dic: Object.assign({}, ...res.pdf_sablonlar.map((x) => ({ [x.id]: x.tanim }))),
    alici_mailleri: durum.alici_mailleri ?? [],
    durum: durum.durum,
    mail_gondersin: durum.mail_gondersin,
    kullaniciya_mail_gondersin: durum.kullaniciya_mail_gondersin,
  }
  return form;
}


const AlisIrsaliyeDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingEkAlan, setLoadingEkAlan] = useState(true);
  const [loadingUrun, setLoadingUrun] = useState(true);
  const [loadingKur, setLoadingKur] = useState(true);
  const [loadingKurTahsilat, setLoadingKurTahsilat] = useState(true);
  const [loadingCalc, setLoadingCalc] = useState(false);
  const [loadingTahsilat, setLoadingTahsilat] = useState(true);
  const [loadingTahsilat2, setLoadingTahsilat2] = useState(true);
  const [loadingEvrakDurum, setLoadingEvrakDurum] = useState(false);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataUrun, setHataUrun] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [paraBirimList, setParaBirimList] = useState([]);
  const [paraKoduList, setParaKoduList] = useState({});
  const [paraBirimDic, setParaBirimDic] = useState({});
  const [kurlar, setKurlar] = useState([]);
  const [kurlarTahsilatBase, setKurlarTahsilatBase] = useState([]);
  const [kurlarTahsilat, setKurlarTahsilat] = useState([]);
  const [evrakTarih, setEvrakTarih] = useState(null);
  const [evrakTarihTahsilat, setEvrakTarihTahsilat] = useState(null);

  const [genelFormDefault, setGenelFormDefault] = useState(FORM_DEFAULT);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [genelFormEkAlan, setGeneFormEkAlan] = useState(null);
  const [genelFormEvrakDurum, setGenelFormEvrakDurum] = useState(null);

  const [adreslerDefault, setAdreslerDefault] = useState(ADRES_FORM_DEFAULT);
  const [adresler, setAdresler] = useState(null);
  const [adrestip, setAdresTip] = useState([]);
  const [adresList, setAdresList] = useState([]);
  const [adresListDefault, setAdresListDefault] = useState([]);
  const [adressDic, setAdressDic] = useState({});
  const [adressDicDefault, setAdressDicDefault] = useState({});

  const [formCount, setFormCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [urunler, setUrunler] = useState([]);
  const [urunlerDefault, setUrunlerDefault] = useState([]);
  const [vergiBaslik, setVergiBaslik] = useState([]);
  const [iskontoBaslik, setIskontoBaslik] = useState(0);
  const [vergiMatrahlar, setVergiMatrahlar] = useState([]);
  const [vergiMatrahlarDefault, setVergiMatrahlarDefault] = useState([]);

  const [kargolar, setKargolar] = useState([]);
  const [vergiler, setVergiler] = useState([]);

  const [odemeTanimaLoad, setOdemeTanimLoad] = useState(true);
  const [bankaLoad, setBankaLoad] = useState(true);
  const [paraBirimLoad, setParaBirimLoad] = useState(true);

  const [odemeTip, setOdemeTip] = useState([]);
  const [odemeTipDic, setOdemeTipDic] = useState({});
  const [odemeTanim, setOdemeTanim] = useState([]);
  const [odemeTanimDic, setOdemeTanimDic] = useState([]);
  const [bankalar, setBankalar] = useState([]);
  const [bankalarDic, setBankalarDic] = useState({});

  const [tahsilatOlustur, setTahsilatOlustur] = useState(true);
  const [odemesizForm, setOdemesizForm] = useState(null);
  const [odemesizTahsilat, setOdemesizTahsilat] = useState(null);
  const [odemesizTahsilatDefault, setOdemesizTahsilatDefault] = useState(null);
  const [genelError2, setGenelError2] = useState(null);

  const [tahsilatIds, setTahsilatIds] = useState([]);
  const [tahsilatForm, setTahsilatForm] = useState([]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.kodu;
  }, [add, genelForm]);

  const IS_ADD = useMemo(() => props.addnew, [props]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, { ...FORM_DEFAULT }) && _isEqual(adresler, ADRES_FORM_DEFAULT) && _isEqual(urunler, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualAdres = _isEqual(adresler, adreslerDefault);
    const isEqualUrunler = _isEqual(urunler, urunlerDefault);
    return isEqualGenel && isEqualAdres && isEqualUrunler;
  }, [add, genelForm, genelFormDefault, adresler, adreslerDefault, urunler, urunlerDefault]);

  const IS_EQUAL_ODEMESIZ = useMemo(() => {
    if (odemesizForm) {
      if (!tahsilatOlustur) {
        return false
      }
    }
    return true
  }, [odemesizForm, tahsilatOlustur]);

  useLayoutEffect(() => {
    setUrunlerDefault([]);
    setUrunler([]);
    setFormCount(0);
    setPage(1);
    setLastPage(1);
  }, [ID]);

  useLayoutEffect(() => {
    setLoading(true);
    const debounce = setTimeout(() => {
      if (!IS_ADD) {
        django(`${PATH}/${ID}/get_siparis`).then(({ data }) => {
          const kullanici = data.evrak_kullanici;
          delete kullanici.username;
          const form = {
            id: data.id,
            tip: String(data.tip.key),
            kodu: data.kodu,
            versiyon: String(data.versiyon),
            toplam: data.toplam,
            iskonto: data.iskonto,
            kalem_iskonto: data.kalem_iskonto,
            ara_toplam: data.ara_toplam,
            vergi_toplam: data.vergi_toplam,
            genel_toplam: data.genel_toplam,
            magaza_toplam: data.magaza_toplam,
            magaza_iskonto: data.magaza_iskonto,
            magaza_kalem_iskonto: data.magaza_kalem_iskonto,
            magaza_ara_toplam: data.magaza_ara_toplam,
            magaza_vergi_toplam: data.magaza_vergi_toplam,
            magaza_genel_toplam: data.magaza_genel_toplam,
            magaza_kargo_tax: data.magaza_kargo_tax,
            magaza_ara_toplam_tax: data.magaza_ara_toplam_tax,
            magaza_toplam_tax: data.magaza_toplam_tax,
            magaza_para_birimi: String(data.magaza_para_birimi),
            evrak_tarihi: moment(data.evrak_tarihi).format('YYYY-MM-DD[T]HH:mm'),
            para_birimi: String(data.para_birimi),
            aciklama: data.aciklama,
            evrak_kur: data.evrak_kur,
            ust: data.ust.map(x => x.id),
            evrak_kullanici: kullanici,
            magaza: data.magaza,
            cikis_depo: data.cikis_depo,
            iskontolar: data.iskontolar,
            evrak_kargo: data.evrak_kargo?.[0] ?? null,
            kargo: data.kargo,
            magaza_kargo: data.magaza_kargo,
            secilen_kargo: data.evrak_kargo?.[0] ? { id: data.evrak_kargo[0].kargo.id, tanim: data.evrak_kargo[0].kargo.tanim } : null,
            evrak_ek_bilgiler: data.evrak_ek_bilgiler ?? [],
            iliski: data.iliski.map(x => x.id),
            evrak_odemesiz_tahsilat: data.evrak_odemesiz_tahsilat,
            ek_bilgiler: [],
            evrak_durum: null,
            kaynak: data.kaynak.key
          };

          setEvrakTarih(moment(data.evrak_tarihi).format('YYYYMMDD'));

          const teslim = data.evrak_adresler.filter((x => x.tip === 0))?.map((a) => (
            {
              adres: String(a.adres),
              tip: String(a.tip),
              adres_json: {
                adres: a.adres_json.address,
                ilce: a.adres_json.state + '/',
                sehir: a.adres_json.city + '/',
                ulke: a.adres_json.country,
                ulkeId: a.adres_json.country_id,
              },
            }
          ));
          const fatura = data.evrak_adresler.filter((x => x.tip === 1))?.map((a) => (
            {
              adres: String(a.adres),
              tip: String(a.tip),
              adres_json: {
                adres: a.adres_json.address,
                ilce: a.adres_json.state + '/',
                sehir: a.adres_json.city + '/',
                ulke: a.adres_json.country,
                ulkeId: a.adres_json.country_id,
              },
            }
          ));
          const adresForm = [teslim[0], fatura[0]];

          getAdresList(data.evrak_kullanici.id).then((res) => {
            const dict = Object.assign({}, ...res.map((x) => ({
              [x.adres]: {
                tanim: x.tanim, adres:
                  x.adres_json.adres + " \n" + x.adres_json.ilce + x.adres_json.sehir + x.adres_json.ulke
              }
            })));
            setAdressDic(dict);
            setAdressDicDefault(dict);
            setAdresList(res);
            setAdresListDefault(res);
          });

          const matrahList = data.matrahlar.map((x) => (
            {
              vergi_tip: { id: x.vergi.vergi_tip.id, tanim: x.vergi.vergi_tip.tanim },
              vergi: { id: x.vergi.id, tanim: x.vergi.tanim },
              vergi_oran: x.vergi_oran,
              vergi_tutar: x.vergi_tutar,
              matrah: x.matrah
            }
          ));

          setVergiMatrahlar(matrahList);
          setVergiMatrahlarDefault(matrahList);

          if (form.iliski.length > 0) {
            setTahsilatIds(form.iliski);
            setOdemesizForm(null);
            setEvrakTarihTahsilat(null);
            setTahsilatOlustur(false);
          } else {
            if (form.evrak_odemesiz_tahsilat.length > 0) {
              const f = {
                evrak: {
                  tip: { key: 16, value: 'Ödeme' },
                  para_birimi: String(data.para_birimi),
                  evrak_kullanici: kullanici,
                  kodu: '',
                  genel_toplam: '0.0000',
                  evrak_tarihi: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
                  aciklama: "",
                  evrak_kur: "1.0000",
                  versiyon: 0,
                  ort_vade_gun: '0',
                  ort_vade_tarih: moment(Date.now()).format('YYYY-MM-DD'),
                  magaza: data.magaza,
                  iliski: [data.id]
                },
                detay: form.evrak_odemesiz_tahsilat
              }
              setOdemesizForm(f);
              setEvrakTarihTahsilat(moment(Date.now()).format('YYYYMMDD'));
              setTahsilatOlustur(true);
            } else {
              setOdemesizForm(null);
              setEvrakTarihTahsilat(null);
            }
            setTahsilatIds([]);
          }
          setGeneFormEkAlan({ magaza: data.magaza.id, tip: String(data.tip.key), ekbilgi: data?.evrak_ek_bilgiler ?? [] });
          setGenelFormEvrakDurum(data.evrak_durum[0]);
          setGenelFormDefault(form);
          setGenelForm(form);
          setAdresler(adresForm);
          setAdreslerDefault(adresForm);
          setHataGenel(null);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataGenel(true);
            }
          }
        }).finally(() => {
          setTabs(TABS_DEFAULT);
          setLoading(false);
          setGenelError(null);
          setAdd(false);
        });
      } else {
        setTabs(TABS_DEFAULT);
        setGenelForm(FORM_DEFAULT);
        setAdresler(ADRES_FORM_DEFAULT);
        setGeneFormEkAlan(null);
        setGenelFormEvrakDurum(null);
        setTahsilatForm([]);
        setOdemesizForm(null);
        setEvrakTarihTahsilat(null);
        setEvrakTarih(moment(Date.now()).format('YYYYMMDD'));
        setVergiMatrahlar([]);
        setVergiMatrahlarDefault([]);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
      }
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoading(false);
    };
  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    const debounce = setTimeout(() => {
      if (genelFormEkAlan) {
        setLoadingEkAlan(true);
        getEkAlan(genelFormEkAlan.magaza, genelFormEkAlan.ekbilgi).then((res) => {
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next["ek_bilgiler"] = res;
            });
          });
          setGenelFormDefault((prev) => {
            return immer(prev, (next) => {
              next["ek_bilgiler"] = res;
            });
          });
        }).finally(() => setLoadingEkAlan(false));
      } else {
        setLoadingEkAlan(false);
      }
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoadingEkAlan(false);
    };
  }, [genelFormEkAlan]);

  useLayoutEffect(() => {
    const debounce = setTimeout(() => {
      if (genelFormEvrakDurum) {
        setLoadingEvrakDurum(true);
        createEvrakDurum(genelFormEvrakDurum).then((res) => {
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next["evrak_durum"] = res;
            });
          });
          setGenelFormDefault((prev) => {
            return immer(prev, (next) => {
              next["evrak_durum"] = res;
            });
          });
        }).finally(() => setLoadingEvrakDurum(false));
      } else {
        setLoadingEvrakDurum(false);
      }
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoadingEvrakDurum(false);
    };
  }, [genelFormEvrakDurum]);

  useLayoutEffect(() => {
    const debounce = setTimeout(() => {
      if (!IS_ADD) {
        django(`${PATH}/${ID}/count_urunler`).then(({ data }) => {
          setTotalCount(data);
          setLastPage(Math.ceil(data / 100));
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataUrun(true);
            }
          }
        });
      }
    }, 300);
    return () => {
      clearTimeout(debounce);
    }
  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    setPage((prev) => immer(prev, (next) => {
      if (formCount > 0) {
        if (totalCount > formCount) {
          if (Math.ceil(formCount / 100) === next) {
            return next + 1
          } else {
            return Math.ceil(formCount / 100)
          }
        }
      }
    }));
  }, [totalCount, formCount]);

  useLayoutEffect(() => {
    setLoadingUrun(true);
    const debounce = setTimeout(() => {
      if (!IS_ADD) {
        django(`${PATH}/${ID}/urunler`, { params: { size: 100, page: page } }).then(({ data }) => {
          const forms = data.map((urun, index) => ({
            index: page === 1 ? index : (page - 1) * 100 + index,
            id: urun.id,
            urun: urun.urun,
            birim: String(urun.birim.id),
            birim_carpan: urun.birim_carpan,
            birim_kodu: urun.birim_kodu,
            miktar: urun.miktar,
            birim_fiyat: urun.birim_fiyat,
            satis_fiyat: urun.satis_fiyat,
            iskontolu_satis_fiyat: urun.iskontolu_satis_fiyat,
            net_satis_fiyat: urun.net_satis_fiyat,
            tutar: urun.tutar,
            net_tutar: urun.net_tutar,
            para_birimi: String(urun.para_birimi ?? -1),
            satis_para_birimi: String(urun.satis_para_birimi),
            urun_doviz_kur: urun.urun_doviz_kur,
            cari_doviz_kur: urun.cari_doviz_kur,
            urun_detay_vergi: urun.detay_vergiler?.map((v) => ({ vergi: v.vergi, vergi_oran: v.vergi_oran, vergi_tutar: v.vergi_tutar })),
            urun_detay_iskonto: urun.detay_iskontolar?.map((i) => ({ ...i, iskonto_tip: i.iskonto_tip.key })),
            birimliste: [{ id: String(urun.birim.id), kodu: urun.birim_kodu, tanim: urun.birim.tanim, carpan: urun.birim_carpan }],
            birimDic: { [urun.birim.id]: urun.birim.tanim },
            miktar_kodu: urun.birim_kodu,
            birim_cekildi: false,
            ek_bilgi: getEkAlanUrun(urun.urun.ek_alan_tanimlar, urun.detay_ek_bilgiler),
          }));
          setUrunler((prev) => immer(prev, (next) => {
            next.push.apply(next, forms);
          }));

          setUrunlerDefault((prev) => immer(prev, (next) => {
            setFormCount([...prev, ...forms].length);
            next.push.apply(next, forms);
          }));

          setHataUrun(false);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataUrun(true);
            }
          }
        }).finally(() => {
          if (lastPage === page) {
            setLoadingUrun(false);
          }
        });
      } else {
        setHataUrun(false);
        setLoadingUrun(false);
      }
    }, 300);

    return () => {
      clearTimeout(debounce);
      setLoadingUrun(false);
    };
  }, [ID, lastUpdateTime, IS_ADD, page, lastPage]);

  useLayoutEffect(() => {
    setParaBirimLoad(true);
    django('parabirim/mini_list').then(({ data }) => {
      let pkDict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.uluslar_arasi_kod })));
      let pkDict2 = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      pkDict = Object.assign(pkDict, { [-1]: '' })
      setParaKoduList(pkDict);
      setParaBirimDic(pkDict2);
      setParaBirimList(data);
      setParaBirimLoad(false);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (evrakTarih !== null) {
      setLoadingKur(true);
      django(`kurlar/?tarih=${evrakTarih}`).then(({ data }) => {
        const list = data.reduce((r, e) => {
          const index = r.findIndex(x => x.parabirim.id === e.parabirim.id && x.hedef_parabirim.id === e.hedef_parabirim.id)
          if (index < 0) {
            r.push(e);
          } else {
            if (r[index].id < e.id) {
              r[index] = e;
            }
          }
          return r;
        }, []);
        setKurlar(list.map((x) => ({ parabirim: x.parabirim, hedef_parabirim: x.hedef_parabirim, deger: x.deger })));
      }).finally(() => {
        setLoadingKur(false);
      })
    }
  }, [lastUpdateTime, evrakTarih]);

  useLayoutEffect(() => {
    if (evrakTarihTahsilat !== null) {
      setLoadingKurTahsilat(true);
      django(`kurlar/?tarih=${evrakTarihTahsilat}`).then(({ data }) => {
        const list = data.reduce((r, e) => {
          const index = r.findIndex(x => x.parabirim.id === e.parabirim.id && x.hedef_parabirim.id === e.hedef_parabirim.id)
          if (index < 0) {
            r.push(e);
          } else {
            if (r[index].id < e.id) {
              r[index] = e;
            }
          }
          return r;
        }, []).map((x) => ({ parabirim: x.parabirim, hedef_parabirim: x.hedef_parabirim, deger: x.deger }));
        setKurlarTahsilat(list);
        setKurlarTahsilatBase(list);
      }).finally(() => {
        setLoadingKurTahsilat(false);
      })
    }
  }, [lastUpdateTime, evrakTarihTahsilat]);

  useLayoutEffect(() => {
    django('evrak/adres_tip').then(({ data }) => setAdresTip(data));
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    let vergiler = [];
    if (urunler?.length > 0) {
      urunler.forEach((data) => {
        if (data.urun_detay_vergi.length > 0) {
          data.urun_detay_vergi.forEach(v => {
            if (vergiler.some(i => i.vergi_adi === (v.vergi.vergi_tip.tanim + ' ' + v.vergi.tanim)) === false) {
              vergiler.push({ vergi_adi: v.vergi.vergi_tip.tanim + ' ' + v.vergi.tanim });
            }
          });
        }
      });
    }
    setVergiBaslik(vergiler);
  }, [urunler]);

  useLayoutEffect(() => {
    let iskontolar = [];
    let iskontobaslik = [];
    if (urunler?.length > 0) {
      urunler.forEach((data) => {
        if (data.urun_detay_iskonto.length > 0) {
          iskontolar.push(data.urun_detay_iskonto.length);
        }
      });
    }
    const iskontosayisi = Math.max(...iskontolar);
    for (let i = 0; i < iskontosayisi; i++) {
      iskontobaslik.push({ baslik: `İSK. ${i + 1}` })
    }
    setIskontoBaslik(iskontobaslik);
  }, [urunler]);

  useLayoutEffect(() => {
    django('kargo').then(({ data }) => {
      getDesiliKargo(data).then((res) => setKargolar(res));
    });

  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django('vergi_tip').then(({ data }) => {
      getVergiList(data).then((res) => setVergiler(res));
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setOdemeTanimLoad(true);
    django('odemetanim').then(({ data }) => {
      let dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      setOdemeTanim(data);
      setOdemeTanimDic(dict);
      setOdemeTanimLoad(false);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setBankaLoad(true);
    django('bankahesap').then(({ data }) => {
      let dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      setBankalarDic(dict);
      setBankalar(data);
      setBankaLoad(false);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django('evrak/odeme_tip').then(({ data }) => {
      let dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setOdemeTipDic(dict);
      setOdemeTip(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (odemesizForm) {
      setLoadingTahsilat(true);
      if (!odemeTanimaLoad && !bankaLoad && !paraBirimLoad && !loadingKurTahsilat) {
        createOdemesizTahsilat(odemesizForm, odemeTanim, bankalar, paraBirimList, kurlarTahsilatBase).then((data) => {
          setOdemesizTahsilatDefault(data);
          setOdemesizTahsilat(data);
        }).finally(() => setLoadingTahsilat(false));
      }
    } else {
      setOdemesizTahsilatDefault(null);
      setOdemesizTahsilat(null);
      setLoadingTahsilat(false);
    }
  }, [odemesizForm, odemeTanim, bankalar, odemeTanimaLoad, bankaLoad, paraBirimLoad, loadingKurTahsilat, paraBirimList, kurlarTahsilatBase]);

  useLayoutEffect(() => {
    if (tahsilatIds.length > 0) {
      setLoadingTahsilat2(true);
      createTahsilat(tahsilatIds).then((data) => {
        setTahsilatForm(data);
      }).finally(() => setLoadingTahsilat2(false));
    } else {
      setTahsilatForm([]);
      setLoadingTahsilat2(false);
    }
  }, [tahsilatIds]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const calcKur = (para1, para2, kurlist = kurlar) => {
    let kur = '0.0000';
    if (paraBirimList.length === 1) {
      kur = '1.0000';
    } else {
      if (kurlist.length > 0) {
        if (para1 !== '-1' && para2 !== '-1') {
          if (para1 === para2) {
            kur = '1.0000';
          } else {
            if (kurlist.findIndex((x) => x.parabirim.id === (para1 * 1) && x.hedef_parabirim.id === (para2 * 1)) !== -1) {
              kur = kurlist.find((x) => x.parabirim.id === (para1 * 1) && x.hedef_parabirim.id === (para2 * 1)).deger;
            }
          }
        }
      } else {
        if (para1 !== '-1' && para2 !== '-1') {
          if (para1 === para2) {
            kur = '1.0000';
          }
        }
      }
    }
    return kur;
  }

  const getKurList = async (evrakTarih) => {
    setLoadingKur(true);
    let liste = [];
    await django(`kurlar/?tarih=${evrakTarih}`).then(({ data }) => {
      const list = data.reduce((r, e) => {
        const index = r.findIndex(x => x.parabirim.id === e.parabirim.id && x.hedef_parabirim.id === e.hedef_parabirim.id)
        if (index < 0) {
          r.push(e);
        } else {
          if (r[index].id < e.id) {
            r[index] = e;
          }
        }
        return r;
      }, []);
      liste = list.map((x) => ({ parabirim: x.parabirim, hedef_parabirim: x.hedef_parabirim, deger: x.deger }))
    }).finally(() => {
      setLoadingKur(false);
    })
    return liste;
  }

  const controlEvrakDurumKullanici = (evrakdurumform, kullanici) => {
    let form = evrakdurumform;
    const copymails = [...form.alici_mailleri];
    if (form.kullaniciya_mail_gondersin) {
      if (!_isNil(kullanici.email)) {
        if (!copymails.includes(kullanici.email)) {
          copymails.push(kullanici.email);
        }
      } else {
        enqueueSnackbar("Evrak kullanıcısına ait bir eposta adresi bulunmamaktadır!", { variant: 'error' });
      }
    } else {
      const findex = copymails.findIndex(x => x === kullanici.email);
      if (findex > -1) {
        copymails.splice(findex, 1);
      }
    }
    form = { ...form, alici_mailleri: copymails };
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next["evrak_durum"] = form;
      });
    });
  }

  const getEvrakDurumları = async (value, kullanici) => {
    setLoadingEvrakDurum(true);
    if (value) {
      const res = (await (django(`evrakdurumtanim/${value.id}`))).data;
      if (res) {
        let form = {
          mail_sablon: String(res.mail_sablon.id),
          mail_sablonlar: res.mail_sablonlar,
          mail_sablonlar_dic: Object.assign({}, ...res.mail_sablonlar.map((x) => ({ [x.id]: x.tanim }))),
          mail_sablon_site: String(res.mail_sablon_site ? res.mail_sablon_site.id : res.mail_sablonlar_site?.[0] ? res.mail_sablonlar_site[0].id : "-1"),
          mail_sablonlar_site: res.mail_sablonlar_site,
          mail_sablonlar_site_dic: Object.assign({}, ...res.mail_sablonlar_site.map((x) => ({ [x.id]: x.tanim }))),
          pdf_sablon: String(res.pdf_sablon ? res.pdf_sablon.id : res.pdf_sablonlar?.[0] ? res.pdf_sablonlar[0].id : "-1"),
          pdf_sablonlar: res.pdf_sablonlar,
          pdf_sablonlar_dic: Object.assign({}, ...res.pdf_sablonlar.map((x) => ({ [x.id]: x.tanim }))),
          alici_mailleri: res.alici_mailleri,
          durum: value,
          mail_gondersin: res.mail_gondersin,
          kullaniciya_mail_gondersin: res.kullaniciya_mail_gondersin,
        }
        controlEvrakDurumKullanici(form, kullanici);
      } else {
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next["evrak_durum"] = value;
          });
        });
      }
    } else {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next["evrak_durum"] = value;
        });
      });
    }
    setLoadingEvrakDurum(false);
  }

  const getEvrakDurumVarsayilan = (magaza, kullanici) => {
    setLoadingEvrakDurum(true);
    django(`evrakdurumtanim/?aktif=true&magazalar.magaza.id=${magaza.id}&evrak_tip=${EVRAKTIP}&varsayilan=true`).then(({ data }) => {
      if (data.length > 0) {
        const res = data[0];
        let form = {
          mail_sablon: String(res.mail_sablon.id),
          mail_sablonlar: res.mail_sablonlar,
          mail_sablonlar_dic: Object.assign({}, ...res.mail_sablonlar.map((x) => ({ [x.id]: x.tanim }))),
          mail_sablon_site: String(res.mail_sablon_site ? res.mail_sablon_site.id : res.mail_sablonlar_site?.[0] ? res.mail_sablonlar_site[0].id : "-1"),
          mail_sablonlar_site: res.mail_sablonlar_site,
          mail_sablonlar_site_dic: Object.assign({}, ...res.mail_sablonlar_site.map((x) => ({ [x.id]: x.tanim }))),
          pdf_sablon: String(res.pdf_sablon ? res.pdf_sablon.id : res.pdf_sablonlar?.[0] ? res.pdf_sablonlar[0].id : "-1"),
          pdf_sablonlar: res.pdf_sablonlar,
          pdf_sablonlar_dic: Object.assign({}, ...res.pdf_sablonlar.map((x) => ({ [x.id]: x.tanim }))),
          alici_mailleri: res.alici_mailleri,
          durum: { id: res.id, tanim: res.tanim },
          mail_gondersin: res.mail_gondersin,
          kullaniciya_mail_gondersin: res.kullaniciya_mail_gondersin,
        }
        controlEvrakDurumKullanici(form, kullanici);
      } else {
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next["evrak_durum"] = null;
          });
        });
      }
    })
    setLoadingEvrakDurum(false);
  }

  const handleChangeForm = async (property, value, index = null) => {
    if (!loadingKur && page === lastPage) {
      if (property === 'evrak_kullanici') {
        if (genelForm.magaza) {
          if (!genelForm.evrak_durum) {
            getEvrakDurumVarsayilan(genelForm.magaza, value);
          } else {
            let form = genelForm.evrak_durum;
            controlEvrakDurumKullanici(form, value);
          }
        }
        getAdresList(value?.id).then((res) => {
          const dict = Object.assign({}, ...res.map((x) => ({
            [x.adres]: {
              tanim: x.tanim, adres:
                x.adres_json.adres + " \n" + x.adres_json.ilce + x.adres_json.sehir + x.adres_json.ulke
            }
          })));
          setAdressDic(dict);
          setAdresList(res);
          if (res.length > 0) {
            setAdresler((prev) => {
              return immer(prev, (next) => {
                next[0]['adres'] = res[0]['adres'];
                next[1]['adres'] = res[0]['adres'];
                next[0]['adres_json'] = res[0]['adres_json'];
                next[1]['adres_json'] = res[0]['adres_json'];
              });
            });
          } else {
            setAdresler((prev) => {
              return immer(prev, (next) => {
                next[0]['adres'] = '';
                next[1]['adres'] = '';
                next[0]['adres_json'] = {};
                next[1]['adres_json'] = {};
              });
            });
          }
        });
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next[property] = value;
          });
        });
      } else if (property === 'iskontolar') {
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next[property] = value['iskonto'];
            next['iskonto'] = value['indirimtutar'];
          });
        });
        calcTotal({ ...genelForm, iskonto: value['indirimtutar'], iskontolar: value['iskonto'] }, urunler);
      } else if (property === 'kodu') {
        if (add) {
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next[property] = value;
            });
          });
        }
      } else if (property === 'magaza') {
        if (genelForm.evrak_kullanici) {
          getEvrakDurumVarsayilan(value, genelForm.evrak_kullanici);
        }
        if (add && value) {
          django(`evrak/evrak_kod/${value.id}/${EVRAKTIP}`).then(({ data }) => {
            if (data?.value === '') {
              let error = { kodu: ['Evrak için seri ve no tanımlamalısınız!'] }
              handleErrors("evrak", error, null, null, null, null, null)
            }
            setGenelForm((prev) => {
              return immer(prev, (next) => {
                next["kodu"] = data?.value;
              });
            });
          })
        }
        const parabirim = genelForm.para_birimi !== '-1' ? genelForm.para_birimi : String(value ? value['para_birim']['id'] : -1);
        const magazaparabirimi = String(value ? value['para_birim']['id'] : -1);
        const kur = calcKur(String(value ? value['para_birim']['id'] : -1), parabirim);
        const depo = value ? value.magaza_cikis_depo : null;
        if (depo) {
          delete depo.kodu;
        }
        if ((kur * 1) === 0 && kurlar.length === 0) {
          enqueueSnackbar('Evrak tarihine ait kur bulunmamaktadır!Devam etmek için kur tanımlayınız.', { variant: 'error' });
        } else {
          const cikis_depo = genelForm.cikis_depo !== null ? genelForm.cikis_depo : depo;
          getEkAlan(value.id, genelForm.evrak_ek_bilgiler).then((res) => {
            setGenelForm((prev) => {
              return immer(prev, (next) => {
                next[property] = value;
                next["para_birimi"] = parabirim;
                next["evrak_kur"] = kur;
                next["cikis_depo"] = cikis_depo;
                next["magaza_para_birimi"] = magazaparabirimi;
                next["ek_bilgiler"] = res;
              });
            });
          })

          if (urunler.length > 0) {
            let list = [];
            for (let index = 0; index < urunler.length; index++) {
              const row = urunler[index];
              const listrow = await calcRowPrice(row, { ...genelForm, magaza: value, para_birimi: parabirim, evrak_kur: kur, cikis_depo: cikis_depo });
              list.push(listrow);
            }
            await calcTotal({ ...genelForm, magaza: value, para_birimi: parabirim, evrak_kur: kur, cikis_depo: cikis_depo, magaza_para_birimi: magazaparabirimi }, list);
          };
        }

      } else if (property === 'para_birimi') {
        const kur = calcKur(genelForm.magaza_para_birimi, value);
        if ((kur * 1) === 0 && kurlar.length === 0) {
          enqueueSnackbar('Evrak tarihine ait kur bulunmamaktadır!Devam etmek için kur tanımlayınız.', { variant: 'error' });
        } else {
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next[property] = value;
              next["evrak_kur"] = kur;
            });
          });

          if (urunler.length > 0) {
            let list = [];
            for (let index = 0; index < urunler.length; index++) {
              const row = urunler[index];
              const listrow = await calcRowPrice(row, { ...genelForm, para_birimi: value, evrak_kur: kur });
              list.push(listrow);
            }
            await calcTotal({ ...genelForm, para_birimi: value, evrak_kur: kur }, list);
          };
        }

      } else if (property === 'evrak_tarihi') {
        if (genelForm.para_birimi && genelForm.magaza && genelForm.magaza_para_birimi) {
          const _kurlist = await getKurList(moment(value).format('YYYYMMDD'));
          setKurlar(_kurlist);
          const _kur = calcKur(genelForm.magaza_para_birimi, genelForm.para_birimi, _kurlist);
          if ((_kur * 1) === 0 && _kurlist.length === 0) {
            enqueueSnackbar('Evrak tarihine ait kur bulunmamaktadır!Devam etmek için kur tanımlayınız.', { variant: 'error' });
          } else {
            setGenelForm((prev) => {
              return immer(prev, (next) => {
                next[property] = value;
                next["evrak_kur"] = _kur;
              });
            });
            if (urunler.length > 0) {
              let list = [];
              for (let index = 0; index < urunler.length; index++) {
                const row = urunler[index];
                const listrow = await calcRowPrice(row, { ...genelForm, evrak_tarihi: value, evrak_kur: _kur }, _kurlist);
                list.push(listrow);
              }
              await calcTotal({ ...genelForm, evrak_tarihi: value, evrak_kur: _kur }, list);
            };
          }
        } else {
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next[property] = value;
            });
          });
        }
      } else if (property === 'secilen_kargo') {
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next[property] = value;
          });
        });
        calcTotal({ ...genelForm, secilen_kargo: value })
      } else if (property === "ek_bilgiler") {
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next["ek_bilgiler"][index]["veri"] = value;
          });
        });
      } else {
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next[property] = value;
          });
        });
      }
    }
  };

  const handleChangeFormEvrakDurum = (property, value) => {
    if (property === "durum") {
       getEvrakDurumları(value,genelForm.evrak_kullanici);
    } else if (property === "kullaniciya_mail_gondersin") {
      controlEvrakDurumKullanici({ ...genelForm.evrak_durum, kullaniciya_mail_gondersin: value }, genelForm.evrak_kullanici)
    } else {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next["evrak_durum"][property] = value;
        });
      });
    }
  }

  const handleChangeFormAdres = (property, index, value) => {
    setAdresler((prev) => {
      return immer(prev, (next) => {
        next[index]['adres_json'] = adresList.find((x) => x.adres === value).adres_json;
        next[index][property] = value;
      });
    });
  };

  const handleChangeFormUrun = async (index, property, value, search, index2 = null) => {
    if (genelForm.magaza && genelForm.evrak_kullanici) {
      const ui = urunler.findIndex(x => x.index === index);
      if (!loadingKur && page === lastPage) {
        if (property === 'birim') {
          const secili = urunler[index].birimliste.find((x) => x.id === value);
          const carpan = secili.carpan;
          const miktarkod = secili.kodu;
          const listrow = await calcRowPrice({ ...urunler[ui], birim: value, birim_carpan: carpan, miktar_kodu: miktarkod });
          if (listrow) {
            const liste = [...urunler];
            liste[ui] = listrow;
            calcTotal(genelForm, liste);
          }
        } else if (property === 'urun_detay_iskonto') {
          const listrow = await calcRowPrice({ ...urunler[ui], urun_detay_iskonto: value['iskonto'], iskontolu_satis_fiyat: value['fiyat'] });
          if (listrow) {
            const liste = [...urunler];
            liste[ui] = listrow;
            calcTotal(genelForm, liste);
          }
        } else if (property === 'urun') {
          if (search) {
            setUrunler((prev) => {
              return immer(prev, (next) => {
                next[ui] = {
                  ...URUNFORMDEFAULT,
                  index: next[ui].index,
                  urun: {
                    id: null,
                    tanim: null,
                    kodu: value,
                    desi: null
                  },
                };
              });
            });
          } else {
            if (value) {
              handleGetUrunData(value).then((res) => {
                setUrunler((prev) => {
                  return immer(prev, (next) => {
                    next[ui] = { ...res, index: next[ui].index };
                  });
                });
              });
            } else {
              setUrunler((prev) => {
                return immer(prev, (next) => {
                  next[ui] = { ...URUNFORMDEFAULT, index: next[ui].index };
                });
              });
            }
          }
        } else if (property === 'satis_fiyat') {
          if (urunler[ui]['urun']['id']) {
            let iskontoFiyat = value;
            urunler[ui].urun_detay_iskonto.forEach((x) => {
              if (x.iskonto_tutar !== '0.0000') {
                iskontoFiyat = (Number(iskontoFiyat) - Number(x.iskonto_tutar)).toFixed(4);
              } else if (x.iskonto_oran !== '0.00') {
                iskontoFiyat = (Number(iskontoFiyat) * ((100 - Number(x.iskonto_oran)) / 100)).toFixed(4);
              }
            })
            const listrow = await calcRowPrice({ ...urunler[ui], satis_fiyat: value, iskontolu_satis_fiyat: iskontoFiyat });
            if (listrow) {
              const liste = [...urunler];
              liste[ui] = listrow;
              calcTotal(genelForm, liste);
            }
          }
        } else if (property === 'satis_para_birimi') {
          if (urunler[index]['urun']['id']) {
            const listrow = await calcRowPrice({ ...urunler[ui], satis_para_birimi: value });
            if (listrow) {
              const liste = [...urunler];
              liste[ui] = listrow;
              calcTotal(genelForm, liste);
            }
          }
        } else if (property === 'miktar') {
          if (urunler[ui]['urun']['id']) {
            const listrow = await calcRowPrice({ ...urunler[ui], miktar: value });
            if (listrow) {
              const liste = [...urunler];
              liste[ui] = listrow;
              calcTotal(genelForm, liste);
            }
          }
        } else if (property === 'ek_bilgi') {
          if (urunler[ui]['urun']['id']) {
            setUrunler((prev) => {
              return immer(prev, (next) => {
                next[ui][property][index2]["veri"] = value;
              });
            });
          }
        }
      }
    } else {
      enqueueSnackbar('Evraktaki zorunlu alanları doldurunuz', { variant: 'error' });
    }
  }

  const handleGetUrunData = async (urun) => {
    const urunId = urun.id;
    const userId = genelForm['evrak_kullanici'].id;
    const magazaId = genelForm['magaza'].id;
    const ulkeId = genelForm['magaza']['ulke'].id;
    const magazapara = genelForm.magaza_para_birimi;
    const chpara = genelForm.para_birimi;

    const response = (await django(`evrak/urun/${userId}/${magazaId}/${urunId}/${ulkeId}`)).data;
    if (response.tax.id && response.unit.id !== 0 && response.price) {
      const urun_satis_para = String((response.price.sell_price?.real?.currency_id ?? response.price.sell_price?.currency_id) ?? -1);
      const urun_doviz_kur = calcKur(magazapara, urun_satis_para);
      const cari_doviz_kur = calcKur(magazapara, chpara);
      const urun_satis_kur = calcKur(chpara, urun_satis_para);
      const form = {
        urun: {
          id: response.id,
          tanim: response.name,
          kodu: response.code,
          desi: response.desi
        },
        birim: String(response.unit.id),
        birim_carpan: String(response.unit.factor),
        birim_kodu: response.unit.code,
        miktar: '0',
        birim_fiyat: Number((response.price.price?.real?.without_tax ?? response.price.price?.without_tax) ?? 0).toFixed(4),
        satis_fiyat: Number((response.price.sell_price?.real?.without_tax ?? response.price.sell_price?.without_tax) ?? 0).toFixed(4),
        iskontolu_satis_fiyat: Number((response.price.sell_price?.real?.without_tax ?? response.price.sell_price?.without_tax) ?? 0).toFixed(4),
        net_satis_fiyat: (Number((response.price.sell_price?.real?.without_tax ?? response.price.sell_price?.without_tax) ?? 0) * (urun_satis_kur * 1)).toFixed(4),
        tutar: '0.0000',
        net_tutar: '0.0000',
        para_birimi: String((response.price.price?.real?.currency_id ?? response.price.price?.currency_id) ?? -1),
        satis_para_birimi: urun_satis_para,
        urun_doviz_kur: urun_doviz_kur,
        cari_doviz_kur: cari_doviz_kur,
        urun_detay_vergi: [
          {
            vergi: { id: response.tax.id, tanim: response.tax.name, vergi_tip: { id: response.tax.tax_type.id, tanim: response.tax.tax_type.name } },
            vergi_tutar: ((Number(response.price.sell_price?.real?.price ?? response.price.sell_price?.price) ?? 0) - (Number(response.price.sell_price?.real?.without_tax ?? response.price.sell_price?.without_tax) ?? 0)).toFixed(4),
            vergi_oran: response.price.sell_price?.tax_rate ?? 0,
          }
        ],
        urun_detay_iskonto: [],
        birimliste: [{ id: String(response.unit.id), kodu: response.unit.code, tanim: response.unit.name, carpan: String(response.unit.factor) }],
        birimDic: { [response.unit.id]: response.unit.name },
        miktar_kodu: response.unit.code,
        birim_cekildi: false,
        ek_bilgi: response.urun_ek_bilgi ? getEkAlanUrun(response.urun_ek_bilgi, []) : []
      }
      return form;
    } else {
      let text = '';
      text = response.tax.id === null ? text.concat('vergisi ') : text;
      text = response.unit.id === 0 ? text.concat('birimi ') : text;
      text = !response.price ? text.concat('fiyatı ') : text;
      enqueueSnackbar(`Ürün ${text} tanımlanmamış!Ürün bilgilerini tanımlayınız.`, { variant: 'error' });
      return URUNFORMDEFAULT;
    }
  }

  const handleCalcCargo = (ulkeId, cargoId, toplamfiyat, evrakpb, urun) => {
    let kargo_ucreti = 0;
    let kargovergi = 0;
    const kargo = kargolar.find((x) => x.cargo.id === cargoId);
    if (kargo) {
      const kargo_kur_evrak = calcKur(evrakpb, String(kargo.cargo.para_birim));
      const vergi = vergiler.find((x) => x.vergi.id === kargo.cargo.vergi.id && x.ulke === ulkeId)?.oran;

      if (urun.length > 0) {
        if (kargo.cargo.calisma_tip.key === 1) {
          const desi = urun.reduce((x, y) => x + (y.desi * 1), 0);
          if (kargo.cargo.desi_alt_limit <= desi <= kargo.cargo.desi_ust_limit) {
            const desiler = kargo.desi.filter((x) => x.min_desi <= desi <= x.max_desi);
            if (desiler.length > 0) {
              kargo_ucreti = (desiler[0].fiyat * 1) * ((100 - (vergi * 1)) / 100);
              kargovergi = (desiler[0].fiyat * 1) * ((vergi * 1) / 100);
            }
            if (desiler.length > 1) {
              enqueueSnackbar('Birden fazla desi eşleşmesi oluştu.Kargo desilerini kontrol ediniz.', { variant: 'error' });
            }
          }
        }
        if (kargo.cargo.calisma_tip.key === 0) {
          const kargo_ucretsiz = (kargo.cargo.ucretsiz_kargo_deger * 1) * (kargo_kur_evrak * 1);
          if (toplamfiyat >= kargo_ucretsiz && (kargo.cargo.ucretsiz_kargo_deger * 1) !== 0) {
            kargo_ucreti = 0;
            kargovergi = 0;
          } else if (toplamfiyat < kargo_ucretsiz && (kargo.cargo.ucretsiz_kargo_deger * 1) !== 0) {
            kargo_ucreti = (kargo.cargo.fiyat * 1) * ((100 - (vergi * 1)) / 100);
            kargovergi = (kargo.cargo.fiyat * 1) * ((vergi * 1) / 100);
          } else if ((kargo.cargo.ucretsiz_kargo_deger * 1) === 0) {
            kargo_ucreti = (kargo.cargo.fiyat * 1) * ((100 - (vergi * 1)) / 100);
            kargovergi = (kargo.cargo.fiyat * 1) * ((vergi * 1) / 100);
          }
        }
      }

      return [{
        kargo: {
          id: kargo.cargo.id,
          tanim: kargo.cargo.tanim,
          vergi: kargo.cargo.vergi,
        },
        kargo_fiyat: (kargo_ucreti * (kargo_kur_evrak * 1)).toFixed(4),
        kargo_vergi_oran: vergi,
        kargo_adi: kargo.cargo.tanim,
        kargo_durum: null,
        kargo_takip_no: null
      }, { kargo_ucreti: kargo_ucreti, pb: String(kargo.cargo.para_birim), kargo_vergi: kargovergi }]
    } else {
      return [{
        kargo: {
          id: null,
          tanim: '',
          vergi: null,
        },
        kargo_fiyat: (0).toFixed(4),
        kargo_vergi_oran: "0.00",
        kargo_adi: '',
        kargo_durum: null,
        kargo_takip_no: null
      }, { kargo_ucreti: 0, pb: '-1', kargo_vergi: 0 }]
    }
  }

  const getUrunBirimList = async (index, urnId, get) => {
    let uid = null;
    const ui = urunler.findIndex(x => x.index === index);
    if (ui.id) {
      uid = urunlerDefault.findIndex(x => x.id === ui.id);
    }
    if (get === false) {
      const kullaniciId = genelForm.evrak_kullanici.id;
      const magazaId = genelForm.magaza.id;
      const ulkeId = genelForm.magaza.ulke.id;
      const response = (await django(`evrak/urun/${kullaniciId}/${magazaId}/${urnId}/${ulkeId}`)).data;
      const birimliste = [{ id: String(response.unit.id), kodu: response.unit.code, tanim: response.unit.name, carpan: String(response.unit.factor) }];
      const dict = Object.assign({}, ...birimliste.map((x) => ({ [x.id]: x.tanim })));
      setUrunler((prev) => {
        return immer(prev, (next) => {
          next[ui]['birimliste'] = birimliste;
          next[ui]['birimDic'] = dict;
          next[ui]['birim_cekildi'] = true;
        });
      });
      if (uid) {
        setUrunlerDefault((prev) => {
          return immer(prev, (next) => {
            next[ui]['birimliste'] = birimliste;
            next[ui]['birimDic'] = dict;
            next[ui]['birim_cekildi'] = true;
          });
        });
      }
    }

  }

  const handleAddNewRow = () => {
    setUrunler((prev) => immer(prev, (next) => {
      next.push({ ...URUNFORMDEFAULT, index: urunler.length })
    }));
    return true;
  }

  const handleDeleteRow = (index) => {
    const dindex = urunler.findIndex(x => x.index === index);
    setUrunler((prev) => immer(prev, (next) => {
      next.splice(dindex, 1);
    }));
    const urunList = [...urunler];
    urunList.splice(dindex, 1);
    if (urunList.length > 0) {
      calcTotal(genelForm, urunList);
    } else {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next['toplam'] = '0.0000';
          next['iskonto'] = '0.0000';
          next['kalem_iskonto'] = '0.0000';
          next['iskontolar'] = [];
          next['ara_toplam'] = '0.0000';
          next['kargo'] = '0.0000';
          next['vergi_toplam'] = '0.0000';
          next['genel_toplam'] = '0.0000';

          next['magaza_toplam'] = '0.0000';
          next['magaza_iskonto'] = '0.0000';
          next['magaza_kalem_iskonto'] = '0.0000';
          next['magaza_ara_toplam'] = '0.0000';
          next['magaza_kargo'] = '0.0000';
          next['magaza_vergi_toplam'] = '0.0000';
          next['magaza_genel_toplam'] = '0.0000';

          next['magaza_kargo_tax'] = '0.0000';
          next['magaza_ara_toplam_tax'] = '0.0000';
          next['magaza_toplam_tax'] = '0.0000';
        })
      });
      setVergiMatrahlar([]);
    }
  }

  const calcRowPrice = async (row, form = genelForm, kurlist = kurlar) => {
    const evrak_para = form.para_birimi;
    const magaza_para = form.magaza_para_birimi;
    const birim_carpan = (row.birim_carpan * 1);
    const miktar = (row.miktar * 1);
    const urun_satis_para = row.satis_para_birimi;
    const urun_doviz_kur = calcKur(magaza_para, urun_satis_para, kurlist);
    const cari_doviz_kur = calcKur(magaza_para, evrak_para, kurlist);
    const urun_satis_kur_ch = calcKur(evrak_para, urun_satis_para, kurlist);
    const urun_net_satis_fiyat = ((row.iskontolu_satis_fiyat * 1) * (urun_satis_kur_ch * 1)).toFixed(4);
    const urun_net_tutar = (((row.iskontolu_satis_fiyat * 1) * birim_carpan * miktar) * (urun_satis_kur_ch * 1)).toFixed(4);
    const urun_tutar = (((row.iskontolu_satis_fiyat * 1) * birim_carpan * miktar) * (urun_doviz_kur * 1)).toFixed(4);
    if (((urun_doviz_kur * 1) === 0 || (cari_doviz_kur * 1) === 0 || (urun_satis_kur_ch * 1) === 0) && kurlist.length === 0) {
      enqueueSnackbar('Evrak tarihine ait kur bulunmamaktadır!Devam etmek için kur tanımlayınız.', { variant: 'error' });
      return null
    } else {
      const urundata = { ...row, urun_doviz_kur: urun_doviz_kur, cari_doviz_kur: cari_doviz_kur, net_satis_fiyat: urun_net_satis_fiyat, tutar: urun_tutar, net_tutar: urun_net_tutar }
      return urundata;
    }
  }

  const calcTotal = async (form = genelForm, urunList = urunler) => {
    setLoadingCalc(true);
    const ulkeId = genelForm.magaza.ulke.id;
    const iskontolar = form.iskontolar;
    let toplam = 0;
    let iskonto = 0;
    let kalem_iskonto = 0;
    let ara_toplam = (form.ara_toplam * 1);
    let vergi_toplam = (form.vergi_toplam * 1);
    let genel_toplam = (form.genel_toplam * 1);
    let magaza_toplam = 0;
    let magaza_iskonto = 0;
    let magaza_kalem_iskonto = 0;
    let magaza_ara_toplam = (form.magaza_ara_toplam * 1);
    let magaza_vergi_toplam = (form.magaza_vergi_toplam * 1);
    let magaza_genel_toplam = (form.magaza_genel_toplam * 1);
    let vergi_matrah = [];
    const evrak_kur = (form.evrak_kur * 1);

    urunList.forEach((x) => {
      let urun_evrak_kur = calcKur(form.para_birimi, x.satis_para_birimi);
      let urun_magaza_kur = calcKur(form.magaza_para_birimi, x.satis_para_birimi);
      let satiriskonto = (Number(x.satis_fiyat) - Number(x.iskontolu_satis_fiyat)) * (x.birim_carpan * 1) * (x.miktar * 1);
      toplam += Number(x.net_tutar);
      magaza_toplam += Number(x.tutar);
      kalem_iskonto += satiriskonto * (urun_evrak_kur * 1);
      magaza_kalem_iskonto += satiriskonto * (urun_magaza_kur * 1);
    });

    const _kargo = handleCalcCargo(ulkeId, form.secilen_kargo?.id, toplam, form.para_birimi, urunList);
    const evrak_k = _kargo[0];
    const magaza_k = _kargo[1];

    if (urunList.length > 0) {
      let copyToplam = toplam;
      if (iskontolar.length > 0) {
        iskontolar.forEach((i) => {
          if (i.iskonto_tutar !== '0.0000') {
            copyToplam = copyToplam - (i.iskonto_tutar * 1);
          } else if (i.iskonto_oran !== '0.00') {
            copyToplam = copyToplam * ((100 - (i.iskonto_oran * 1)) / 100);
          }
        });
        iskonto = toplam - copyToplam;
        magaza_iskonto = iskonto * evrak_kur;
      }
      urunList.forEach((u, index) => {
        let matrah = toplam !== 0 ? ((u.net_tutar * 1) - (iskonto * ((u.net_tutar * 1) / toplam))) : 0;
        const vergiler = [];
        u.urun_detay_vergi.forEach((v) => {
          vergiler.push({ ...v, vergi_tutar: (matrah * (v.vergi_oran * 1) / 100).toFixed(4) });
          vergi_matrah.push({ vergi_tip: { id: v.vergi.vergi_tip.id, tanim: v.vergi.vergi_tip.tanim }, vergi: { id: v.vergi.id, tanim: v.vergi.tanim }, vergi_oran: v.vergi_oran, vergi_tutar: (matrah * (v.vergi_oran * 1) / 100).toFixed(4), matrah: matrah.toFixed(4) });
        });
        u = { ...u, urun_detay_vergi: vergiler }
        setUrunler((prev) => {
          return immer(prev, (next) => {
            next[index] = u;
          });
        });
      });
      const magaza_ara_toplam_tax = vergi_matrah.map((x) => Number(x.vergi_tutar)).reduce((a, b) => a + b) * evrak_kur;
      vergi_matrah.push({ vergi_tip: { id: evrak_k.kargo.vergi?.vergi_tip.id, tanim: evrak_k.kargo.vergi?.vergi_tip.tanim }, vergi: { id: evrak_k.kargo.vergi?.id, tanim: evrak_k.kargo.vergi?.tanim }, vergi_oran: evrak_k.kargo_vergi_oran, vergi_tutar: (evrak_k.kargo_fiyat * ((evrak_k.kargo_vergi_oran * 1) / 100)).toFixed(4), matrah: evrak_k.kargo_fiyat });
      const mlist = calcMatrah(vergi_matrah.filter((x) => (x.matrah * 1) !== 0));
      setVergiMatrahlar(mlist);
      const last_toplam = toplam + kalem_iskonto;
      ara_toplam = (last_toplam - iskonto - kalem_iskonto);
      const kargo = (evrak_k.kargo_fiyat * 1);
      vergi_toplam = vergi_matrah.map((x) => Number(x.vergi_tutar)).reduce((a, b) => a + b);
      genel_toplam = ara_toplam + vergi_toplam + kargo;
      const last_magaza_toplam = magaza_toplam + magaza_kalem_iskonto;
      magaza_ara_toplam = last_magaza_toplam - magaza_iskonto - magaza_kalem_iskonto;
      const magaza_kargo = (magaza_k.kargo_ucreti * 1) * (calcKur(form.magaza_para_birimi, magaza_k.pb) * 1);
      const magaza_kargo_tax = (magaza_k.kargo_vergi * 1) * (calcKur(form.magaza_para_birimi, magaza_k.pb) * 1);
      magaza_vergi_toplam = vergi_toplam * evrak_kur;
      magaza_genel_toplam = magaza_ara_toplam + magaza_vergi_toplam + magaza_kargo;



      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next['toplam'] = last_toplam.toFixed(4);
          next['iskonto'] = iskonto.toFixed(4);
          next['kalem_iskonto'] = kalem_iskonto.toFixed(4);
          next['ara_toplam'] = ara_toplam.toFixed(4);
          next['kargo'] = kargo.toFixed(4);
          next['vergi_toplam'] = vergi_toplam.toFixed(4);
          next['genel_toplam'] = genel_toplam.toFixed(4);
          next['evrak_kargo'] = evrak_k;
          next['magaza_toplam'] = last_magaza_toplam.toFixed(4);
          next['magaza_iskonto'] = magaza_iskonto.toFixed(4);
          next['magaza_kalem_iskonto'] = magaza_kalem_iskonto.toFixed(4);
          next['magaza_ara_toplam'] = magaza_ara_toplam.toFixed(4);
          next['magaza_kargo'] = magaza_kargo.toFixed(4);
          next['magaza_vergi_toplam'] = magaza_vergi_toplam.toFixed(4);
          next['magaza_genel_toplam'] = magaza_genel_toplam.toFixed(4);
          next['magaza_kargo_tax'] = magaza_kargo_tax.toFixed(4);
          next['magaza_ara_toplam_tax'] = magaza_ara_toplam_tax.toFixed(4);
          next['magaza_toplam_tax'] = magaza_vergi_toplam.toFixed(4);
        })
      });
    } else {
      setVergiMatrahlar([]);
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next['toplam'] = '0.0000';
          next['iskonto'] = '0.0000';
          next['kalem_iskonto'] = '0.0000';
          next['iskontolar'] = [];
          next['ara_toplam'] = '0.0000';
          next['kargo'] = '0.0000';
          next['vergi_toplam'] = '0.0000';
          next['genel_toplam'] = '0.0000';
          next['magaza_toplam'] = '0.0000';
          next['magaza_iskonto'] = '0.0000';
          next['magaza_kalem_iskonto'] = '0.0000';
          next['magaza_ara_toplam'] = '0.0000';
          next['magaza_kargo'] = '0.0000';
          next['magaza_vergi_toplam'] = '0.0000';
          next['magaza_genel_toplam'] = '0.0000';
          next['evrak_kargo'] = evrak_k;
          next['magaza_kargo_tax'] = '0.0000';
          next['magaza_ara_toplam_tax'] = '0.0000';
          next['magaza_toplam_tax'] = '0.0000';
        })
      });
    }
    setLoadingCalc(false);
  }

  const calcMatrah = (list) => {
    const vergiListe = [];
    if (list.length > 0) {
      list.forEach((v) => {
        const vergi = vergiListe.find(vl => vl.vergi.id === v.vergi.id);
        if (!vergi) {
          vergiListe.push(v)
        } else {
          vergi.vergi_tutar = ((vergi.vergi_tutar * 1) + (v.vergi_tutar * 1)).toFixed(4);
          vergi.matrah = ((vergi.matrah * 1) + (v.matrah * 1)).toFixed(4);
        }
      })
    }
    return vergiListe;
  }

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm({ ...FORM_DEFAULT });
    setAdresler(ADRES_FORM_DEFAULT);
    setAdresList([]);
    setAdressDic({});
    setGenelError(null);
    setUrunler([]);
    setVergiMatrahlar([]);
    setTahsilatOlustur(true);
  };

  const handleReload = () => {
    setUrunler([]);
    setUrunlerDefault([]);
    setFormCount(0);
    setPage(1);
    setLastUpdateTime(Date.now());
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setAdresList(adresListDefault);
    setAdressDic(adressDicDefault);
    setAdresler(adreslerDefault);
    setUrunler(urunlerDefault);
    setOdemesizTahsilat(odemesizTahsilatDefault);
    setTahsilatOlustur(true);
    setVergiMatrahlar(vergiMatrahlarDefault);
    getKurList(moment(genelFormDefault.evrak_tarihi).format('YYYYMMDD')).then((res) => {
      setKurlar(res);
    });
    if (odemesizForm) {
      getKurList(moment(Date.now()).format('YYYYMMDD')).then((res) => {
        setKurlarTahsilat(res);
      });
    }
    setGenelError(null);
    setGenelError2(null);
    if (!IS_ADD) {
      setAdd(false);
    }
  };

  const checkAll = async () => {
    let list = [
      { prop: 'magaza', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'cikis_depo', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'tip', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'kodu', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'evrak_tarihi', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'tip', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'evrak_kullanici', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'para_birimi', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'magaza_para_birimi', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ];

    if (genelForm.evrak_durum) {
      list.push({ prop: 'evrak_durum', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'mail_sablon' })
    }

    const list2 = [
      { prop: 'veri', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ];
    const listurun = [
      { prop: 'urun', prop2: '' },
      { prop: 'birim', prop2: '' },
      { prop: 'birim_kodu', prop2: '' },
      { prop: 'birim_carpan', prop2: '' },
      // { prop: 'para_birimi', prop2: '' },
      { prop: 'satis_para_birimi', prop2: '' },
      { prop: 'miktar', prop2: '' },
      { prop: 'tutar', prop2: '' },
      { prop: 'net_tutar', prop2: '' },
      { prop: 'urun_doviz_kur', prop2: '' },
      { prop: 'cari_doviz_kur', prop2: '' },
      { prop: 'birim_fiyat', prop2: '' },
      { prop: 'satis_fiyat', prop2: '' },
      { prop: 'net_satis_fiyat', prop2: '' },
      { prop: 'iskontolu_satis_fiyat', prop2: '' },
    ];

    const listurun2 = [
      { prop: 'veri', prop2: '' },
    ];

    let errors = [];
    await checkAllList('evrak', genelForm, genelFormDefault, (err) => handleErrors("evrak", err, null, null, null, null, null), add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);

    for (const i in genelForm.ek_bilgiler) {
      const ek_bilgi = genelForm.ek_bilgiler[i];
      if (ek_bilgi.zorunlu) {
        const res = await checkAllList('', ek_bilgi, ek_bilgi, (err) => handleErrors("ek_bilgi", err, i, genelForm.ek_bilgiler.length, null, null, null), add, list2);
        if (!_isEmpty(res)) {
          errors = [...errors, res];
        }
      }
    }

    const length = urunler.length;
    for (const i in urunler) {
      const res = await checkAllList2(urunler[i], (err) => handleErrors("urun", err, i, length, null, null, null), listurun);
      if (!_isEmpty(res)) {
        errors = [...errors, res];
      }
      for (const j of urunler[i].ek_bilgi) {
        const magazaVar = j.alan_magazalar.includes(genelForm.magaza.id);
        const tipVar = j.alan_iliski_tip.includes(Number(genelForm.tip));
        if (j.zorunlu && magazaVar && tipVar) {
          const res2 = await checkAllList2(j, (err) => handleErrors("urun", err, i, length, "ek_bilgi", j.index, urunler[i].ek_bilgi.length), listurun2);
          if (!_isEmpty(res2)) {
            errors = [...errors, res2];
          }
        }
      }
    }
    return errors;
  }

  const checkAllOdemesiz = async () => {
    const list = [
      { prop: 'evrak', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'magaza' },
      { prop: 'evrak', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'tip' },
      { prop: 'evrak', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'kodu' },
      { prop: 'evrak', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'evrak_tarihi' },
      { prop: 'evrak', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'evrak_kullanici' },
      { prop: 'evrak', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'para_birimi' },
      { prop: 'evrak', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'genel_toplam' },
    ];
    const listdetay = [
      { prop: 'tutar', prop2: '' },
      { prop: 'net_tutar', prop2: '' },
      { prop: 'kur', prop2: '' },
      { prop: 'cari_hesap_kur', prop2: '' },
      { prop: 'para_birimi', prop2: '' },
      { prop: 'cari_hesap_para_birim', prop2: '' },
      { prop: 'tip', prop2: '' },
    ];

    let errors = [];
    await checkAllList('evrak', odemesizTahsilat, odemesizTahsilatDefault, (err) => handleErrors2(err, null), add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);

    const length = odemesizTahsilat.tahsilat_detay.length;
    for (const i in odemesizTahsilat.tahsilat_detay) {
      const res = await checkAllList2(odemesizTahsilat.tahsilat_detay[i], (err) => handleErrors2(err, i, length), listdetay);
      if (!_isEmpty(res)) {
        errors = [...errors, res];
      }
    }
    return errors;
  }

  const handleSave = async () => {
    const createRequestOptionsTabGenel = async () => {
      let requestoptions = null;
      let err = null;
      if (IS_EQUAL) {
        requestoptions = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            let evrak = { ...genelForm, tip: Number(genelForm.tip), versiyon: add ? 0 : Number(genelForm.versiyon) + 1, evrak_kullanici: genelForm.evrak_kullanici ? genelForm.evrak_kullanici.id : null, para_birimi: genelForm.para_birimi !== '-1' ? Number(genelForm.para_birimi) : null, magaza_para_birimi: genelForm.magaza_para_birimi !== '-1' ? Number(genelForm.magaza_para_birimi) : null, magaza: genelForm.magaza ? genelForm.magaza.id : null, cikis_depo: genelForm.cikis_depo ? genelForm.cikis_depo.id : null, aciklama: genelForm.aciklama?.length <= 0 ? null : genelForm.aciklama };
            delete evrak.iskontolar;
            delete evrak.evrak_kargo;
            delete evrak.secilen_kargo;
            delete evrak.ek_bilgiler;

            let evrak_durum = genelForm.evrak_durum;
            if (evrak_durum) {
              evrak_durum = {
                ...evrak_durum,
                mail_sablon: Number(evrak_durum.mail_sablon),
                mail_sablon_site: evrak_durum.mail_sablon_site !== "-1" ? Number(evrak_durum.mail_sablon_site) : null,
                pdf_sablon: evrak_durum.pdf_sablon !== "-1" ? Number(evrak_durum.pdf_sablon) : null,
                mail_gondersin: evrak_durum.mail_gondersin,
                kullaniciya_mail_gondersin: evrak_durum.kullaniciya_mail_gondersin,
                alici_mailleri: evrak_durum.alici_mailleri,
                durum: evrak_durum.durum.id,
              }
              delete evrak_durum.mail_sablonlar;
              delete evrak_durum.mail_sablonlar_dic;
              delete evrak_durum.mail_sablonlar_site;
              delete evrak_durum.mail_sablonlar_site_dic;
              delete evrak_durum.pdf_sablonlar;
              delete evrak_durum.pdf_sablonlar_dic;
              evrak = { ...evrak, evrak_durum: [evrak_durum] }
            } else {
              evrak = { ...evrak, evrak_durum: [] }
            }

            const adres = adresler.map((a) => ({
              adres: Number(a.adres),
              tip: Number(a.tip),
            }));

            const iskonto = genelForm.iskontolar;

            const ek_bilgi = genelForm.ek_bilgiler.map((x) => ({
              ek_alan: x.ek_alan,
              veri: x.veri
            })).filter((y) => y.veri !== "");

            const urun = urunler.map((d) => ({
              ...d,
              urun: d.urun.id,
              birim: d.birim ? Number(d.birim) : null,
              para_birimi: d.para_birimi !== "-1" ? Number(d.para_birimi) : null,
              satis_para_birimi: Number(d.satis_para_birimi),
              urun_detay_vergi: d.urun_detay_vergi.map((v) => ({ vergi: v.vergi.id, vergi_oran: v.vergi_oran, vergi_tutar: v.vergi_tutar }))
            }));

            urun.forEach((x) => {
              x.ek_bilgi = x.ek_bilgi.filter((z) => z.veri !== "" && z.alan_iliski_tip.includes(Number(genelForm.tip)) && z.alan_magazalar.includes(genelForm.magaza.id)).map((y) => ({ ek_alan: y.ek_alan, veri: y.veri }));
              delete x.birimliste;
              delete x.birimDic;
              delete x.birim_cekildi;
              delete x.miktar_kodu;
              delete x.index
            });

            const matrah = vergiMatrahlar.map((v) => ({
              vergi_oran: v.vergi_oran,
              vergi_tutar: v.vergi_tutar,
              matrah: v.matrah,
              vergi: v.vergi.id
            }));

            const kargo = genelForm.evrak_kargo?.kargo?.id ? [{
              kargo: genelForm.evrak_kargo.kargo.id,
              kargo_fiyat: genelForm.evrak_kargo.kargo_fiyat,
              kargo_vergi_oran: genelForm.evrak_kargo.kargo_vergi_oran,
              kargo_adi: genelForm.evrak_kargo.kargo_adi,
              kargo_durum: genelForm.evrak_kargo.kargo_durum,
              kargo_takip_no: genelForm.evrak_kargo.kargo_takip_no,
            }] : [];

            const data = { evrak: evrak, adres: adres, iskonto: iskonto, urun: urun, matrah: matrah, kargo: kargo, ek_bilgi: ek_bilgi };
            requestoptions = {
              config: {
                method: 'POST',
                url: 'evrak/siparis',
                data: data
              },
              successMessage: `Evrak ekleme başarıyla tamamlandı`,
              errorMessageUnexpected: `Evrak eklenirken beklenmeyen bir hata oluştu`,
            }
            err = null;
          } else {
            requestoptions = null;
            err = res;
          }
        })
      }
      return [requestoptions, err];
    }

    const createRequestOptionsTabTahsilat = async () => {
      let requestoptions = null;
      let err = null;
      if (tahsilatOlustur) {
        requestoptions = null;
        err = null;
      } else {
        if (IS_EQUAL_ODEMESIZ) {
          requestoptions = null;
          err = null;
        } else {
          await checkAllOdemesiz().then((res) => {
            if (_isEmpty(res)) {
              const evrak_form = { ...odemesizTahsilat.evrak };
              const evrak_detay_form = [...odemesizTahsilat.tahsilat_detay];
              const evrak = { ...evrak_form, tip: Number(evrak_form.tip.key), versiyon: Number(evrak_form.versiyon), evrak_kullanici: evrak_form.evrak_kullanici ? evrak_form.evrak_kullanici.id : null, para_birimi: evrak_form.para_birimi !== '-1' ? Number(evrak_form.para_birimi) : null, magaza: evrak_form.magaza ? evrak_form.magaza.id : null, aciklama: evrak_form.aciklama?.length <= 0 ? null : evrak_form.aciklama, evrak_kur: "1.0000" };

              const detay = evrak_detay_form.map((d) => ({
                vade: d.vade,
                tip: Number(d.tip),
                aciklama: d.aciklama?.length <= 0 ? null : d.aciklama,
                tutar: d.tutar,
                net_tutar: d.net_tutar,
                ortalama_vade_gun: Number(d.ortalama_vade_gun),
                ortalama_vade_tarih: d.ortalama_vade_tarih,
                tahsilat_tarih: d.tahsilat_tarih,
                para_birimi: d.para_birimi !== '-1' ? Number(d.para_birimi) : null,
                odeme_tanim: d.odeme_tanim !== '-1' ? Number(d.odeme_tanim) : null,
                banka_hesap: d.banka_hesap !== '-1' ? Number(d.banka_hesap) : null,
                odeme_vade: d.odeme_vade,
                cari_hesap_para_birim: Number(d.cari_hesap_para_birim),
                sezon: d.sezon,
                kur: d.kur,
                cari_hesap_kur: d.cari_hesap_kur
              }));

              const data = { evrak: evrak, tahsilat_detay: detay };

              requestoptions = {
                config: {
                  method: 'POST',
                  url: 'evrak/tahsilat',
                  data: data
                },
                successMessage: `Evrak tahsilat ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Evrak tahsilat eklenirken beklenmeyen bir hata oluştu`,
              }
              err = null;
            } else {
              requestoptions = null;
              err = res;
            }
          })
        }
      }
      return [requestoptions, err];
    }

    let errors = [[], []];

    const start = async () => {
      const tabs = [
        { index: 0, name: 'tahsilat', request: [], error: [] },
        { index: 1, name: 'genel', request: [], error: [] },
      ];

      const res2 = await createRequestOptionsTabTahsilat();
      tabs[0].request.push(res2[0]);
      tabs[0].error.push(res2[1]);

      const res = await createRequestOptionsTabGenel();
      tabs[1].request.push(res[0]);
      tabs[1].error.push(res[1]);

      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django(request.config).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (props.single) {
                  history.push(URLS.evrak.alisirsaliye.detail(data.evrak.id, "detay"));
                }
                if (!props.single) {
                  props.onAdded();
                }
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (tab.name === 'tahsilat') {
              setGenelError2(null);
              await django(request.config).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                handleReload();
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError2(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }
    await start();
    if (!errors[1].includes("err")) {
      setUrunlerDefault([]);
      setUrunler([]);
      setFormCount(0);
      setPage(1);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[1].hasError = !errors[0].includes("err") ? false : true;
        next[0].hasError = !errors[1].includes("err") ? false : true;;
      });
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.evrak.alisirsaliye.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleErrors = (name, errors, index, length, name2, index2, length2) => {
    let obj = {}
    let next = {};
    setGenelError((prev) => {
      if (index === null) {
        if (_has({ ...prev }, name)) {
          if (name2 !== null) {
            if (_has({ ...prev[name] }, name2)) {
              prev[name][name2] = _assign(prev[name][name2], errors[name2]);
            } else {
              prev[name] = { ...prev[name], ...errors }
            }
          } else {
            _assign(prev[name], errors);
          }
          obj = { ...prev }
        } else {
          obj = { ...prev, [name]: { ...errors } }
        }
      } else {
        if (_has({ ...prev }, name)) {
          prev[name].length = length;
          if (index2 !== null) {
            if (_has({ ...prev[name][index] }, name2)) {
              prev[name][index][name2].length = length2;
              prev[name][index][name2][index2] = { ...prev[name][index][name2][index2], ...errors };
            } else {
              let errobj = new Array(length2).fill({});
              errobj[index2] = { ...errors }
              prev[name][index] = { ...prev[name][index], [name2]: errobj };
            }
          } else {
            prev[name][index] = { ...prev[name][index], ...errors };
          }
          obj = { ...prev }
        } else {
          let errobj2 = new Array(length).fill({});
          if (index2 !== null) {
            let errobj3 = new Array(length2).fill({});
            errobj3[index2] = { ...errors };
            errobj2[index] = { ...errobj2[index], [name2]: errobj3 };
          } else {
            errobj2[index] = { ...errors }
          }
          obj = { ...prev, [name]: errobj2 }
        }
      }



      if (_has(obj, "evrak")) {
        let evrak = _pickBy(obj["evrak"], _identity);
        if (_has(evrak, "evrak_durum")) {
          const evrakdurum = _pickBy({ ...evrak.evrak_durum }, _identity);
          evrak = _pickBy({ ...evrak, evrak_durum: evrakdurum }, _identity);
        }
        if (_isEmpty(evrak)) {
          obj = _pickBy({ ...obj, evrak: null }, _identity);
          next = _pickBy({ ...obj, evrak: null }, _identity);
        } else {
          obj = _pickBy({ ...obj, evrak: evrak }, _identity);
          next = _pickBy({ ...obj, evrak: evrak }, _identity);
        }
      }

      if (_has(obj, "adres")) {
        let err = obj["adres"];
        if (err.length > 0) {
          for (let i = 0; i < err.length; i++) {
            err[i] = _pickBy(err[i], _identity);
          }
        }
        const list = err.filter(x => !_isEmpty(x));
        if (_isEmpty(list)) {
          obj = _pickBy({ ...obj, adres: null }, _identity);
          next = _pickBy({ ...obj, adres: null }, _identity);
        } else {
          obj = _pickBy({ ...obj, adres: err }, _identity);
          next = _pickBy({ ...obj, adres: err }, _identity);
        }
      }

      if (_has(obj, "ek_bilgi")) {
        let err = obj["ek_bilgi"];
        if (err.length > 0) {
          for (let i = 0; i < err.length; i++) {
            err[i] = _pickBy(err[i], _identity);
          }
        }
        const list = err.filter(x => !_isEmpty(x));
        if (_isEmpty(list)) {
          obj = _pickBy({ ...obj, ek_bilgi: null }, _identity);
          next = _pickBy({ ...obj, ek_bilgi: null }, _identity);
        } else {
          obj = _pickBy({ ...obj, ek_bilgi: err }, _identity);
          next = _pickBy({ ...obj, ek_bilgi: err }, _identity);
        }
      }

      if (_has(obj, "iskonto")) {
        let err = obj["iskonto"];
        if (err.length > 0) {
          for (let i = 0; i < err.length; i++) {
            err[i] = _pickBy(err[i], _identity);
          }
        }
        const list = err.filter(x => !_isEmpty(x));
        if (_isEmpty(list)) {
          obj = _pickBy({ ...obj, iskonto: null }, _identity);
          next = _pickBy({ ...obj, iskonto: null }, _identity);
        } else {
          obj = _pickBy({ ...obj, iskonto: err }, _identity);
          next = _pickBy({ ...obj, iskonto: err }, _identity);
        }
      }

      if (_has(obj, "urun")) {
        let err = obj["urun"];
        if (err.length > 0) {
          for (let i = 0; i < err.length; i++) {
            if (err[i]["ek_bilgi"]) {
              for (let j = 0; j < err[i]["ek_bilgi"].length; j++) {
                err[i]["ek_bilgi"][j] = _pickBy(err[i]["ek_bilgi"][j], _identity)
              }
              const list = err[i]["ek_bilgi"].filter(x => !_isEmpty(x));
              if (_isEmpty(list)) {
                err[i] = { ...err[i], ek_bilgi: null }
              } else {
                err[i] = { ...err[i], ek_bilgi: err[i]["ek_bilgi"] }
              }
            }
            err[i] = _pickBy(err[i], _identity);
          }
        }
        const list = err.filter(x => !_isEmpty(x));
        if (_isEmpty(list)) {
          obj = _pickBy({ ...obj, urun: null }, _identity);
          next = _pickBy({ ...obj, urun: null }, _identity);
        } else {
          obj = _pickBy({ ...obj, urun: err }, _identity);
          next = _pickBy({ ...obj, urun: err }, _identity);
        }
      }

      if (_has(obj, "kargo")) {
        let err = obj["kargo"];
        if (err.length > 0) {
          for (let i = 0; i < err.length; i++) {
            err[i] = _pickBy(err[i], _identity);
          }
        }
        const list = err.filter(x => !_isEmpty(x));
        if (_isEmpty(list)) {
          obj = _pickBy({ ...obj, kargo: null }, _identity);
          next = _pickBy({ ...obj, kargo: null }, _identity);
        } else {
          obj = _pickBy({ ...obj, kargo: err }, _identity);
          next = _pickBy({ ...obj, kargo: err }, _identity);
        }
      }

      if (_has(obj, "matrah")) {
        let err = obj["matrah"];
        if (err.length > 0) {
          for (let i = 0; i < err.length; i++) {
            err[i] = _pickBy(err[i], _identity);
          }
        }
        const list = err.filter(x => !_isEmpty(x));
        if (_isEmpty(list)) {
          obj = _pickBy({ ...obj, matrah: null }, _identity);
          next = _pickBy({ ...obj, matrah: null }, _identity);
        } else {
          obj = _pickBy({ ...obj, matrah: err }, _identity);
          next = _pickBy({ ...obj, matrah: err }, _identity);
        }
      }

      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const calcCariHesapNetTutar = async (row, index, evrakPb = null, kurlist = null) => {
    const tutar = row.tutar;
    const para_birimi = row.para_birimi;
    const kur = evrakPb === null ? row.kur : kurlist !== null ? calcKur(evrakPb, para_birimi, kurlist) : calcKur(evrakPb, para_birimi);
    const cari_hesap_para_birim = row.cari_hesap_para_birim;
    const cari_hesap_kur = evrakPb === null ? row.cari_hesap_kur : kurlist !== null ? calcKur(evrakPb, cari_hesap_para_birim, kurlist) : calcKur(evrakPb, cari_hesap_para_birim);
    let net_tutar = '';
    if (tutar !== '') {
      if (para_birimi === cari_hesap_para_birim) {
        if (para_birimi !== '-1' && cari_hesap_para_birim !== '-1') {
          net_tutar = Number(tutar).toFixed(4);
        }
      } else {
        if (para_birimi !== '-1' && cari_hesap_para_birim !== '-1') {
          if (kur !== '' && Number(kur) !== 0 && cari_hesap_kur !== '' && Number(cari_hesap_kur) !== 0) {
            net_tutar = (Number(tutar) * (Number(kur) / Number(cari_hesap_kur))).toFixed(4);
          }
        }
      }
    }

    const newrow = { ...row, kur: kur, cari_hesap_kur: cari_hesap_kur, net_tutar: net_tutar }
    setOdemesizTahsilat((prev) => {
      return immer(prev, (next) => {
        next["tahsilat_detay"][index] = newrow;
        next["evrak"]["genel_toplam"] = calcEvrakTotal(next["tahsilat_detay"]);
      });
    });
  }

  const calcEvrakTotal = (form) => {
    const detayGrupByPB = _groupBy(form, item => item.para_birimi);
    let total = 0;
    if (Object.keys(detayGrupByPB).length === 1) {
      if (Object.keys(detayGrupByPB)[0] !== '-1') {
        total = (Object.values(detayGrupByPB)[0]).map((x) => Number(x.tutar) * Number(x.kur)).reduce((x, y) => x + y);
      }
    }
    if (Object.keys(detayGrupByPB).length > 1) {
      Object.values(detayGrupByPB).forEach((x) => {
        if (x[0].para_birimi !== '-1') {
          const xtotal = x.map((a) => Number(a.tutar) * Number(a.kur)).reduce((a, b) => a + b);
          total += xtotal;
        }
      })
    }
    return total.toFixed(4);
  }

  const handleSelectListChange = async (item) => {
    const odemetanimresponse = odemeTanim;
    const bankahesapresponse = bankalar;

    let odemetanimlist = [];
    let bankalist = [];
    let parabirimlist = [];

    odemetanimlist = odemetanimresponse.filter((x) => x.tip.key === Number(item.tip));
    const odemetanim = odemetanimlist.find((x) => x.id === Number(item.odeme_tanim));
    bankalist = odemetanim.banka_hesaplari;
    if (item.banka_hesap !== '-1') {
      const banka = bankahesapresponse.filter((x) => x.id === Number(item.banka_hesap));
      parabirimlist = [banka[0].para_birim];
    } else {
      bankalist.forEach((x) => {
        const existbank = bankahesapresponse.find((y) => y.id === x.id);
        if (parabirimlist.some(p => p.id === existbank.para_birim.id) === false) {
          parabirimlist.push(existbank.para_birim);
        }
      });
    }
    return [odemetanimlist, bankalist, parabirimlist];
  }

  const handleChangeOdemesizTahsilat = async (property, property2, index, value) => {
    if (property === "evrak") {
      switch (property2) {
        case "evrak_tarihi":
          const _kurlist = await getKurList(moment(value).format('YYYYMMDD'));
          setKurlarTahsilat(_kurlist);
          if (_kurlist.length === 0) {
            enqueueSnackbar('Evrak tarihine ait kur bulunmamaktadır!Devam etmek için kur tanımlayınız.', { variant: 'error' });
          }
          setOdemesizTahsilat((prev) => {
            return immer(prev, (next) => {
              next[property][property2] = value;
              odemesizTahsilat["tahsilat_detay"].forEach((row, index) => {
                calcCariHesapNetTutar(row, index, odemesizTahsilat["evrak"]["para_birimi"], _kurlist);
              })
            });
          });
          break;
        case "aciklama":
        case "kodu":
          setOdemesizTahsilat((prev) => {
            return immer(prev, (next) => {
              next[property][property2] = value;
            });
          });
          break;
        default:
          break;
      }
    } else {
      switch (property2) {
        case "odeme_tanim":
          setOdemesizTahsilat((prev) => {
            return immer(prev, (next) => {
              next[property][index] = { ...prev[property][index], odeme_tanim: value, banka_hesap: '-1', para_birimi: '-1', kur: '', net_tutar: '0' };
              handleSelectListChange({ ...prev[property][index], odeme_tanim: value, banka_hesap: '-1', para_birimi: '-1' }).then((res) => {
                setOdemesizTahsilat((prev) => {
                  return immer(prev, (next) => {
                    next[property][index]["odemetanimlist"] = res[0];
                    next[property][index]["bankalist"] = res[1];
                    next[property][index]["parabirimlist"] = res[2];
                  });
                });
              })
            });
          });
          break;
        case 'banka_hesap':
          setOdemesizTahsilat((prev) => {
            return immer(prev, (next) => {
              next[property][index] = { ...prev[property][index], banka_hesap: value, para_birimi: '-1', kur: '', net_tutar: '0' };
              handleSelectListChange({ ...prev[property][index], banka_hesap: value, para_birimi: '-1' }).then((res) => {
                setOdemesizTahsilat((prev) => {
                  return immer(prev, (next) => {
                    next[property][index]["odemetanimlist"] = res[0];
                    next[property][index]["bankalist"] = res[1];
                    next[property][index]["parabirimlist"] = res[2];
                  });
                });
              })
            });
          });
          break;
        case 'tutar':
          calcCariHesapNetTutar({ ...odemesizTahsilat[property][index], tutar: value }, index, odemesizTahsilat["evrak"]["para_birimi"], kurlarTahsilat);
          break;
        case 'para_birimi':
          const evrak_pb = odemesizTahsilat["evrak"].para_birimi;
          const kur = calcKur(evrak_pb, value, kurlarTahsilat);
          if (kur !== '0.0000') {
            calcCariHesapNetTutar({ ...odemesizTahsilat[property][index], para_birimi: value, kur: kur }, index, odemesizTahsilat["evrak"]["para_birimi"], kurlarTahsilat);
          } else {
            enqueueSnackbar('Evrak tarihine ait kur bulunamadı.Evrak tarihinde kur tanımlayınız.', { variant: 'error' });
            setOdemesizTahsilat((prev) => {
              return immer(prev, (next) => {
                next[property][index][property2] = value;
                next[property][index]['kur'] = kur;
                next[property][index]['net_tutar'] = '0';
              });
            });
          }
          break;
        case 'kur':
          calcCariHesapNetTutar({ ...odemesizTahsilat[property][index], kur: value }, index, odemesizTahsilat["evrak"]["para_birimi"], kurlarTahsilat);
          break;
        case 'cari_hesap_para_birim':
          const evrak_parabirim = odemesizTahsilat["evrak"].para_birimi;
          const cari_hesap_kur = calcKur(evrak_parabirim, value, kurlarTahsilat);
          if (cari_hesap_kur !== '0.0000') {
            calcCariHesapNetTutar({ ...odemesizTahsilat[property][index], cari_hesap_para_birim: value, cari_hesap_kur: cari_hesap_kur }, index, odemesizTahsilat["evrak"]["para_birimi"], kurlarTahsilat);
          } else {
            enqueueSnackbar('Evrak tarihine ait kur bulunamadı.Evrak tarihinde kur tanımlayınız.', { variant: 'error' });
            setOdemesizTahsilat((prev) => {
              return immer(prev, (next) => {
                next[property][index][property2] = value;
                next[property][index]['cari_hesap_kur'] = cari_hesap_kur;
                next[property][index]['net_tutar'] = '0';
              });
            });
          }
          break;
        case 'cari_hesap_kur':
          calcCariHesapNetTutar({ ...odemesizTahsilat[property][index], cari_hesap_kur: value }, index, odemesizTahsilat["evrak"]["para_birimi"], kurlarTahsilat);
          break;
        default:
          setOdemesizTahsilat((prev) => {
            return immer(prev, (next) => {
              next[property][index][property2] = value;
            });
          });
          break;
      }
    }
  }

  const handleErrors2 = (errors, index, length) => {
    let obj = {}
    let next = {};
    setGenelError2((prev) => {
      if (index === null) {
        if (_has({ ...prev }, 'evrak')) {
          _assign(prev['evrak'], errors);
          obj = { ...prev }
        } else {
          obj = { ...prev, evrak: { ...errors } }
        }
      } else {
        if (_has({ ...prev }, "tahsilat_detay")) {
          prev["tahsilat_detay"].length = length;
          prev["tahsilat_detay"][index] = { ...prev["tahsilat_detay"][index], ...errors };
          obj = { ...prev }
        } else {
          let errobj2 = new Array(length).fill({});
          errobj2[index] = { ...errors }
          obj = { ...prev, tahsilat_detay: errobj2 }
        }
      }
      if (_has(obj, "evrak")) {
        const evrak = _pickBy(obj["evrak"], _identity);
        if (_isEmpty(evrak)) {
          next = _pickBy({ ...obj, 'evrak': null }, _identity);
        } else {
          next = _pickBy({ ...obj, 'evrak': evrak }, _identity);
        }
      }
      if (_has(obj, "tahsilat_detay")) {
        let err = obj["tahsilat_detay"];
        if (err.length > 0) {
          for (let i = 0; i < err.length; i++) {
            err[i] = _pickBy(err[i], _identity);
          }
        }
        const list = err.filter(x => !_isEmpty(x));
        if (_isEmpty(list)) {
          next = _pickBy({ ...obj, tahsilat_detay: null }, _identity);
        } else {
          next = _pickBy({ ...obj, tahsilat_detay: err }, _identity);
        }
      }
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add}
        disableReloadButton={loading || add}
        disableCancelButton={!add && (loading || (IS_EQUAL && IS_EQUAL_ODEMESIZ))}
        disableSaveButton={loading || (IS_EQUAL && IS_EQUAL_ODEMESIZ)}
        disableDeleteButton={loading || add}
        disablePreviousButton={props.disablePreviousButton || add}
        disableNextButton={props.disableNextButton || add}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            add={add}
            loading={loading}
            loadingEkAlan={loadingEkAlan}
            loadingUrun={loadingUrun}
            loadingKur={loadingKur}
            loadingCalc={loadingCalc}
            hata={hataGenel}
            hataUrun={hataUrun}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleChangeForm}
            onChangeFormDurum={handleChangeFormEvrakDurum}

            vergiMatrahlar={vergiMatrahlar}

            adresTip={adrestip}
            adresler={adresler}
            adresList={adresList}
            adressDic={adressDic}
            onChangeFormAdres={handleChangeFormAdres}

            urunler={urunler}
            vergibaslik={vergiBaslik}
            iskontobaslik={iskontoBaslik}
            onChangeUrun={handleChangeFormUrun}
            addNew={handleAddNewRow}
            deleteRow={handleDeleteRow}
            getUrunBirimList={getUrunBirimList}

            paraBirimList={paraBirimList}
            paraKoduList={paraKoduList}
            paraBirimDic={paraBirimDic}
            kurlar={kurlar}

            onCheckErrors={handleErrors}
          />
        )}
        {selectedTabIndex === 1 && (
          <TabTahsilat
            add={add}
            id={ID}
            loading={loadingTahsilat && loadingTahsilat2}
            hata={hataGenel}

            odemeTip={odemeTip}
            odemeTipDic={odemeTipDic}
            odemeTanim={odemeTanim}
            odemeTanimDic={odemeTanimDic}
            bankalar={bankalar}
            bankalarDic={bankalarDic}
            paraBirimList={paraBirimList}
            paraKoduList={paraKoduList}
            paraBirimDic={paraBirimDic}
            kurlar={kurlar}

            tahsilatForm={tahsilatForm}
            form={odemesizTahsilat}
            formdefault={odemesizTahsilatDefault}
            tahsilatOlustur={tahsilatOlustur}
            funcTahsilatOlustur={() => setTahsilatOlustur(false)}
            error={genelError2}

            onChangeForm={handleChangeOdemesizTahsilat}
            onCheckErrors={handleErrors2}
          />
        )}
        {selectedTabIndex === 2 && (
          <TabEposta
            loading={loading && loadingEvrakDurum}
            hata={hataGenel}
            form={genelForm}
            error={genelError}
            onChangeForm={handleChangeFormEvrakDurum}
            onCheckErrors={handleErrors}
          />
        )}
      </LayoutDetail>



      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Alış irsaliye siliniyor lütfen bekleyin'
                : 'Bu alış irsaliyesini gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
    </>
  )
}
export default AlisIrsaliyeDetail;