import React, { useCallback, useMemo, useState, useRef } from 'react';
import {
  has as _has,
  result as _result,
} from 'lodash';

import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import {
  Delete as IconDelete
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import ListPhotoSelector from '../../../components/ListPhotoSelector';
import FotoAddDialog from '../../../components/FotoAddDialog';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import ModalImage from "react-modal-image";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      overflow: 'hidden',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& + &': {
        marginTop: 5,
      }
    },
    imageDiv: {
      width: 110,
      height: 110,
      display: "flex",
      justifyContent: 'center',
      borderRadius: 4,
      border: '1px solid #eacfea',
      marginRight: 20
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      cursor: "pointer"
    },
    inputDiv: {
      display: "flex",
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    inputDivList: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
    },
    inputDivLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    addNew: {
      display: "flex",
      marginBottom: 10,
    },
    button: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& + &': {
        marginLeft: 10,
      },
    },
  };
};



const OdemeTanimDetailTabFoto = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const RESIMLER = useMemo(() => props.resimler, [props.resimler]);

  const [openSelectorResim, setOpenSelectorResim] = useState(false);
  const [openSelector, setOpenSelector] = useState(false);
  const [uploadImageFile, setUploadImageFile] = useState('');
  const [uploadImageTypeAndName, setUploadImageTypeAndName] = useState(['', '']);

  const inputRef = useRef();

  const createTextFieldProps = useCallback((name, index, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(RESIMLER[index].form, `${name}`) || defaultValue,
      error: _has(RESIMLER[index].errors, `${name}`),
      fullWidth: true,
    };
  }, [RESIMLER]);

  const resimEkle = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const filetype = file.name.split('.').pop();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setUploadImageFile(reader.result);
        setUploadImageTypeAndName([filetype, file.name]);
      }
      setOpenSelectorResim(true);
    }
  }
  const CloseSelectorResim = () => {
    setUploadImageFile('');
    setOpenSelectorResim(false);
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedIndex);
  }

  const handleChangeResim = (type, res) => {
    if (type === "single") {
      let copyresimler = [...RESIMLER];
      copyresimler.push({ form: { title: res.tanim, harici_link: '', siralama: String(copyresimler.length), foto: res }, errors: null });
      props.onChangeFormData('null', '', copyresimler);
    } else {
      let copyresimler = [...RESIMLER];
      const urunfotolist = [];
      res.forEach((foto, index) => {
        let exist = copyresimler.findIndex(x => x.form.foto.id === foto.id);
        if (exist > -1) {
          urunfotolist.push({ form: { ...copyresimler[exist].form, siralama: String(index) }, errors: null })
        } else {
          urunfotolist.push({ form: { title: foto.tanim, harici_link: '', siralama: String(index), foto: foto }, errors: null })
        }
      });
      props.onChangeFormData('null', '', urunfotolist);
    }
  }


  const handleRemovePhotoById = (id, index) => {
    let copyresimler = [...RESIMLER];
    if (copyresimler[index].form.id) {
      props.openDeleteForm(true);
      props.setDeleteId(copyresimler[index].form.id);
    } else {
      copyresimler = copyresimler.filter(item => item.form.foto.id !== id);
      copyresimler = copyresimler.map((x) => {
        if (x.form.siralama > index) {
          x = { form: { ...x.form, siralama: x.form.siralama - 1 }, errors: x.errors }
        }
        return x
      });
      props.onChangeFormData('null', '', copyresimler);
    }
  }

  const onDragEnd = (result) => {
    let copyresimler = [...RESIMLER];
    if (!result.destination || !result.source) {
      return;
    }
    const eleman = copyresimler[result.source.index];
    copyresimler.splice(result.source.index, 1);
    copyresimler.splice(result.destination.index, 0, eleman);
    copyresimler = copyresimler.map((x, index) => {
      x = { form: { ...x.form, siralama: String(index) }, errors: x.errors }
      return x;
    })
    props.onChangeFormData('null', '', copyresimler);
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: "#976797"
    }),
  });

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <div className={classes.layoutFormList}>
                  <div className={classes.addNew}>
                    <Button
                      className={classes.button}
                      onClick={() => setOpenSelector(true)}
                    >GALERİDEN EKLE</Button>
                    <Button
                      className={classes.button}
                      component="label"
                    >
                      YENİ EKLE
                      <input
                        type="file"
                        hidden
                        accept=".jpeg,.jpg,.png"
                        ref={inputRef}
                        onClick={() => inputRef.current.value = ""}
                        onChange={(e) => resimEkle(e)}
                      />
                    </Button>
                  </div>
                </div>
                <ul className={classes.layoutFormList}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {RESIMLER && RESIMLER.map((item, index) => (
                            <Draggable key={String(item.form.foto.id)} draggableId={String(item.form.foto.id)} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  snapshot={snapshot}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  className={classes.dragItem}
                                >
                                  <li className={classes.layoutFormItem} key={index} onClick={() => setSelectedIndex(index)}>
                                    <IconButton
                                      size="small"
                                      disableRipple
                                      onClick={() => handleRemovePhotoById(item.form.foto.id, item.form.siralama)}
                                    >
                                      <IconDelete />
                                    </IconButton>
                                    <Tooltip title="Büyük Resim">
                                      <div className={classes.imageDiv}>
                                        <ModalImage
                                          className={classes.img}
                                          small={item.form.foto.thumbnail}
                                          large={item.form.foto.image}
                                          alt={item.form.foto.tanim}
                                          showRotate={true}
                                          hideDownload={true}
                                          hideZoom={true}
                                        />
                                      </div>
                                    </Tooltip>
                                    <div className={classes.inputDiv}>
                                      <div className={classes.inputDivList}>
                                        <label className={classes.inputDivLabel} htmlFor="title">Fotoğraf Tanım *</label>
                                        <span>{item.form.foto.tanim}</span>
                                      </div>
                                      <div className={classes.inputDivList}>
                                        <label className={classes.inputDivLabel} htmlFor="title">Fotoğraf Alt Tag *</label>
                                        <Tooltip title={RESIMLER?.[index]?.errors?.title ? RESIMLER?.[index]?.errors?.title[0] : RESIMLER?.[index]?.form?.title == null ? '' : RESIMLER?.[index].form.title}>
                                          <TextField
                                            {...createTextFieldProps('title', index)}
                                            onChange={(e) => props.onChangeFormData(index, 'title', e.target.value)}
                                            onBlur={() => handleInputErrors2(item.form, 'title', handleSetErrors)}
                                          ></TextField>
                                        </Tooltip>
                                      </div>
                                      <div className={classes.inputDivList}>
                                        <label className={classes.inputDivLabel} htmlFor="siralama">Sıralama *</label>
                                        <Tooltip title={RESIMLER?.[index]?.errors?.siralama ? RESIMLER?.[index]?.errors?.siralama[0] : RESIMLER?.[index]?.form?.siralama == null ? '' : RESIMLER?.[index].form.siralama}>
                                          <span>{item.form.siralama}</span>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}

                    </Droppable>
                  </DragDropContext>
                </ul>
              </div>

              {openSelectorResim &&
                <Dialog
                  open={openSelectorResim}
                  PaperProps={{
                    style: {
                      width: '600px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorResim(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <FotoAddDialog
                      image={uploadImageFile}
                      onChange={(res) => handleChangeResim("single", res)}
                      close={CloseSelectorResim}
                      fototip={1}
                      type={uploadImageTypeAndName[0]}
                      name={uploadImageTypeAndName[1]}
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelector &&
                <Dialog
                  open={openSelector}
                  PaperProps={{
                    style: {
                      width: '600px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelector(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListPhotoSelector
                      defaultItems={RESIMLER?.map(item => item.form.foto)}
                      onClose={() => setOpenSelector(false)}
                      onChangeItems={(items) => handleChangeResim("list", items)}
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}

        </>
      }
    </>
  );
});

export default OdemeTanimDetailTabFoto;

