import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  IconButton,
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  Tooltip,
  Typography
} from '@material-ui/core';

import {
  Search as IconSearch,
  HelpOutline as IconHelp
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import ListSelectorSingle from '../../../components/ListSelectorSingle';
import CategorySelector from '../../../components/CategorySelector';
import { handleInputErrors } from '../../base/InputErrors';
import MultipleSelectList from '../../../components/MultipleSelectList';
import ListSelector from '../../../components/ListSelector';
import { Hata } from '../../base/Hata';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1, 1, 1, 4),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 0, 1, 0),
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        marginTop: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '&': {
        marginTop: 5,
      },
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    }
  };
};

const EntegrasyonDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const LOADING_FIYAT = useMemo(() => props.loadingFiyat, [props.loadingFiyat]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATA_FIYAT = useMemo(() => props.hataFiyat, [props.hataFiyat]);
  const add = useMemo(() => props.add, [props.add]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const COUNT_TABS = useMemo(() => props?.fiyatlar?.length ?? 0, [props]);
  const FORM = useMemo(() => props?.fiyatlar?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  const [openSelectorTedarikci, setOpenSelectorTedarikci] = useState(false);
  const [openSelectorDepo, setOpenSelectorDepo] = useState(false);
  const [openSelectorKategori, setOpenSelectorKategori] = useState(false);
  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);
  const [openSelectorDil, setOpenSelectorDil] = useState(false);
  const [openSelectorMagazalar, setOpenSelectorMagazalar] = useState(false);

  useLayoutEffect(() => {
    if (props.fiyatlar?.length > 0) {
      const copyForms = [...props.fiyatlar];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.baslik.value,
          hasError: false,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.fiyatlar]);


  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form?.[name], `${name2}`) || defaultValue,
        error: (name2 === "tanim" || name2 === "username" || name2 === "parents") ? _has(props.error, `${name}`) : _has(props.error?.[name], `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form, `${name}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    }
  }, [props.error, props.form]);

  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      fullWidth: true,
    };
  }, [FORM]);


  const handleRemoveMagazalarById = (id) => {
    const copyMagazalar = [...props.form.magazalar];
    const index = copyMagazalar.findIndex((c) => c.id === id);
    if (index > -1) {
      copyMagazalar.splice(index, 1);
      props.onChangeForm('magazalar', copyMagazalar);
    }
  };

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Entegrasyon Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim', '')}
                          onChange={(e) => props.onChangeForm('tanim', null, e.target.value)}
                          onBlur={() => handleInputErrors('enttanim', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Entegrasyon Tanım', 'entegrasyon')}
                          inputProps={{
                            maxLength: 250,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="ent_tip">
                      <label className={classes.layoutFormItemLabel} htmlFor="ent_tip">Entegrasyon Tipi</label>
                      <Tooltip title={props.error?.ent_tip ? props.error.ent_tip[0] : _isNil(props.form?.ent_tip) ? '' : props.entTipDic ? props.entTipDic[props.form.ent_tip] : ''}>
                        <TextField
                          {...createTextFieldProps('ent_tip', '')}
                          onChange={(e) => props.onChangeForm('ent_tip', null, e.target.value)}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          {props?.entTip?.map((et) => (
                            <option key={et.key} value={et.key}>{et.value}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="isleyici_tip">
                      <label className={classes.layoutFormItemLabel} htmlFor="isleyici_tip">Entegrasyon İşleyici Tipi</label>
                      <Tooltip title={props.error?.isleyici_tip ? props.error.isleyici_tip[0] : _isNil(props.form?.isleyici_tip) ? '' : props.entIsleyiciTipDic ? props.entIsleyiciTipDic[props.form.isleyici_tip] : ''}>
                        <TextField
                          {...createTextFieldProps('isleyici_tip', '')}
                          onChange={(e) => props.onChangeForm('isleyici_tip', null, e.target.value)}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          {props?.entIsleyiciTip?.map((et) => (
                            <option key={et.key} value={et.key}>{et.value}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="tedarikci">
                      <label className={classes.layoutFormItemLabel} htmlFor="tedarikci">Tedarikçi</label>
                      <Tooltip title={props.error?.tedarikci ? props.error.tedarikci[0] : _isNil(props.form?.tedarikci) ? '' : props.form.tedarikci.username}>
                        <TextField
                          {...createTextFieldProps('tedarikci', 'username')}
                          onChange={(e) => props.onChangeForm('tedarikci', null, e.target.value)}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                size="small"
                                className={classes.button}
                                disableRipple
                                onClick={() => setOpenSelectorTedarikci(true)}
                              ><IconSearch /></IconButton>
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="depo">
                      <label className={classes.layoutFormItemLabel} htmlFor="depo">Depo</label>
                      <Tooltip title={props.error?.depo ? props.error.depo[0] : _isNil(props.form?.depo) ? '' : props.form.depo.tanim}>
                        <TextField
                          {...createTextFieldProps('depo', 'tanim')}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                size="small"
                                className={classes.button}
                                disableRipple
                                onClick={() => setOpenSelectorDepo(true)}
                              ><IconSearch /></IconButton>
                          }}
                        ></TextField>
                      </Tooltip>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText("“Miktarlar bu depoya işlenir.”");
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="kategori">
                      <label className={classes.layoutFormItemLabel} htmlFor="kategori">Kategori</label>
                      <Tooltip title={props.error?.kategori ? props.error.kategori[0] : _isNil(props.form?.kategori) ? '' : props.form.kategori.parents}>
                        <TextField
                          {...createTextFieldProps('kategori', 'parents')}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                size="small"
                                className={classes.button}
                                disableRipple
                                onClick={() => setOpenSelectorKategori(true)}
                              ><IconSearch /></IconButton>
                          }}
                        ></TextField>
                      </Tooltip>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText("“Kategori ağacının nereye bağlanacağının seçimidir.”");
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                    <li className={classes.layoutFormItem} id="firma_kodu">
                      <label className={classes.layoutFormItemLabel} htmlFor="firma_kodu">Firma Kodu</label>
                      <Tooltip title={props.error?.ozel_ayar?.firma_kodu ? props.error.ozel_ayar.firma_kodu[0] : _isNil(props.form?.ozel_ayar?.firma_kodu) ? '' : props.form.ozel_ayar.firma_kodu}>
                        <TextField
                          {...createTextFieldProps('ozel_ayar', 'firma_kodu')}
                          onChange={(e) => props.onChangeForm('ozel_ayar', 'firma_kodu', e.target.value)}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="min_zaman">
                      <label className={classes.layoutFormItemLabel} htmlFor="min_zaman">Dosya için Zaman Aralığı</label>
                      <Tooltip title={props.error?.ozel_ayar?.min_zaman ? props.error.ozel_ayar.min_zaman[0] : _isNil(props.form?.ozel_ayar?.min_zaman) ? '' : props.form.ozel_ayar.min_zaman}>
                        <TextField
                          {...createTextFieldProps('ozel_ayar', 'min_zaman')}
                          onChange={(e) => props.onChangeForm('ozel_ayar', 'min_zaman', e.target.value)}
                          type="time"
                        ></TextField>
                      </Tooltip>
                    </li>

                    <li className={classes.layoutFormItem} id="fiyat_oran">
                      <label className={classes.layoutFormItemLabel} htmlFor="fiyat_oran">Fiyat Oran</label>
                      <Tooltip title={props.error?.import_ayar?.fiyat_oran ? props.error.import_ayar.fiyat_oran[0] : _isNil(props.form?.import_ayar?.fiyat_oran) ? '' : props.form.import_ayar.fiyat_oran}>
                        <TextField
                          {...createTextFieldProps('import_ayar', 'fiyat_oran')}
                          onChange={(e) => props.onChangeForm('import_ayar', 'fiyat_oran', e.target.value)}
                          disabled={props.form?.import_ayar?.fiyat_detaydan_calis === "true" ? true : false}
                        ></TextField>
                      </Tooltip>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText("“Fiyat Detaydan Çalış seçili ise listelerdeki oranlar,değilse hepsine burdaki oran uygulanır.”");
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                    <li className={classes.layoutFormItem} id="fiyat_detaydan_calis">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktarim">Fiyat Oluşturma Biçimi</label>
                      <Tooltip title={props.error?.import_ayar?.fiyat_detaydan_calis ? props.error.import_ayar.fiyat_detaydan_calis[0] : _isNil(props.form?.import_ayar?.fiyat_detaydan_calis) ? '' : props.form.import_ayar.fiyat_detaydan_calis === "true" ? "Manuel eşleştirerek" : "Entegrasyondan"}>
                        <TextField
                          {...createTextFieldProps('import_ayar', 'fiyat_detaydan_calis')}
                          onChange={(e) => props.onChangeForm('import_ayar', 'fiyat_detaydan_calis', e.target.value)}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option key="true" value="true">Manuel eşleştirerek</option>
                          <option key="false" value="false">Entegrasyondan</option>
                        </TextField>
                      </Tooltip>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText(props.form.import_ayar.fiyat_detaydan_calis === "true" ? "“Fiyat listeleriyle eşleştirmeler yapılır ve her liste için tanımlanmış oranlar kullanılır.”" : "“Fiyat entegrasyondan gelir ve fiyat oranda tanımlanmış oran kullanılır.”");
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                  </ul>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="magaza">
                      <label className={classes.layoutFormItemLabel} htmlFor="magaza">Ayar Mağaza</label>
                      <Tooltip title={props.error?.magaza ? props.error.magaza[0] : _isNil(props.form?.magaza) ? '' : props.form.magaza.tanim}>
                        <TextField
                          {...createTextFieldProps('magaza', 'tanim')}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                size="small"
                                className={classes.button}
                                disableRipple
                                onClick={() => setOpenSelectorMagaza(true)}
                              ><IconSearch /></IconButton>
                          }}
                        ></TextField>
                      </Tooltip>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText("“Veriler üzerinde bu mağaza üzerinde uygulanmış ayarlar, kampanyalar vs uygulanır.”");
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                    <li className={classes.layoutFormItem} id="dil">
                      <label className={classes.layoutFormItemLabel} htmlFor="dil">Dil</label>
                      <Tooltip title={props.error?.dil ? props.error.dil[0] : _isNil(props.form?.dil) ? '' : props.form.dil.tanim}>
                        <TextField
                          {...createTextFieldProps('dil', 'tanim')}
                          InputProps={{
                            endAdornment:
                              <IconButton
                                size="small"
                                className={classes.button}
                                disableRipple
                                onClick={() => setOpenSelectorDil(true)}
                              ><IconSearch /></IconButton>
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="siralama">
                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                      <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                        <TextField
                          {...createTextFieldProps('siralama', '')}
                          onChange={(e) => props.onChangeForm('siralama', null, e.target.value)}
                          type="number"
                          inputProps={{
                            min: -2147483648,
                            max: 2147483647,
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="aktarim">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktarim">Aktarım</label>
                      <Checkbox
                        name="aktarim"
                        color="primary"
                        checked={_result(props.form, 'aktarim', false)}
                        onChange={(e, checked) => props.onChangeForm('aktarim', null, checked)}
                      ></Checkbox>
                      <IconButton
                        size="small"
                        className={classes.button2}
                        disableRipple
                        onClick={() => {
                          setHelpText("“Entegrasyon datası oluşmadan bu alan çek edilemez.”");
                          setHelpOpen(true)
                        }}
                      ><IconHelp /></IconButton>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', null, checked)}
                      ></Checkbox>
                    </li>
                  </ul>

                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItemBox} id="magazalar">
                      <MultipleSelectList
                        headername="MAĞAZALAR"
                        name="Mağazalar"
                        name2="Mağaza"
                        form={props.form}
                        list={props?.form?.magazalar ?? []}
                        listlength={props?.form?.magazalar?.length ?? 0}
                        opendialog={setOpenSelectorMagazalar}
                        keyProp="id"
                        valueProp="tanim"
                        removefonk={handleRemoveMagazalarById}
                        loading={props.loading}
                        error={props.error?.magazalar ? props.error.magazalar[0] : null}
                        newPath="tanimlar/magaza"
                        ustId={false}
                      />
                    </li>
                  </ul>

                </div>
                {props?.form?.import_ayar?.fiyat_detaydan_calis === "true" && (
                  <div className={classes.layoutContainerTab}>
                    <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">FİYATLAR</span></div>
                    <>
                      {!LOADING_FIYAT && (
                        <>
                          {HATA_FIYAT && (
                            <Hata />
                          )}
                          {!HATA_FIYAT && (
                            <LayoutDetailVerticalTab
                              tabs={tabs}
                              selectedTabIndex={selectedTabIndex}
                              onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                              buttonShow={false}
                              deleteButtonShow={false}
                            >
                              {COUNT_TABS === 0 && (
                                <div className="flex flex-col items-start justify-start p-4 space-y-4">
                                  <Typography variant="body1">Panele ait tanımlanmış bir fiyat bulunamadı.Lütfen önce bir fiyat tanımlayın.</Typography>
                                </div>
                              )}
                              {COUNT_TABS > 0 && (
                                <div className={classes.tabDiv}>
                                  <div className={classes.column}>
                                    <ul className={classes.layoutFormList}>
                                      <li className={classes.layoutFormItem} id="fiyat_detay">
                                        <label className={classes.layoutFormItemLabel} htmlFor="fiyat_detay">Fiyat Detay</label>
                                        <Tooltip title={_isNil(FORM?.fiyat_detay) ? '' : props.fiyatTanimListDic ? props.fiyatTanimListDic[FORM.fiyat_detay] : ''}>
                                          <TextField
                                            {...createTextFieldProps2('fiyat_detay', '')}
                                            onChange={(e) => props.onChangeFormFiyat(selectedTabIndex, 'fiyat_detay', e.target.value)}
                                            select
                                            SelectProps={{
                                              native: true,
                                            }}
                                          >
                                            <option key="-1" value="-1" disabled>Seçilmedi</option>
                                            {props.fiyatTanimList?.map((ft) => (
                                              <option value={ft.id} key={ft.id}>{ft.tanim}</option>
                                            ))}
                                          </TextField>
                                        </Tooltip>
                                      </li>
                                      <li className={classes.layoutFormItem} id="fiyat_tip">
                                        <label className={classes.layoutFormItemLabel} htmlFor="fiyat_tip">Fiyat Tip</label>
                                        <Tooltip title={(_isNil(FORM?.fiyat_tip) || FORM?.fiyat_tip === "-1") ? '' : FORM.fiyat_tip === "liste_fiyat" ? "Liste Fiyat" : 'İndirimli Fiyat'}>
                                          <TextField
                                            {...createTextFieldProps2('fiyat_tip', '')}
                                            onChange={(e) => props.onChangeFormFiyat(selectedTabIndex, 'fiyat_tip', e.target.value)}
                                            select
                                            SelectProps={{
                                              native: true,
                                            }}
                                          >
                                            <option key="-1" value="-1" disabled>Seçilmedi</option>
                                            <option value="liste_fiyat" key="liste_fiyat">Liste Fiyat</option>
                                            <option value="deger" key="deger">İndirimli Fiyat</option>
                                          </TextField>
                                        </Tooltip>
                                      </li>
                                      <li className={classes.layoutFormItem} id="fiyat_oran">
                                        <label className={classes.layoutFormItemLabel} htmlFor="fiyat_oran">Fiyat Oran</label>
                                        <Tooltip title={_isNil(FORM?.fiyat_oran) ? '' : FORM?.fiyat_oran}>
                                          <TextField
                                            {...createTextFieldProps2('fiyat_oran', '')}
                                            onChange={(e) => props.onChangeFormFiyat(selectedTabIndex, 'fiyat_oran', e.target.value)}
                                            type="number"
                                            inputProps={{
                                              min: 0,
                                              max: 100,
                                              step: 1,
                                              style: { textAlign: 'right' }
                                            }}
                                          ></TextField>
                                        </Tooltip>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </LayoutDetailVerticalTab>
                          )}
                        </>
                      )}
                    </>
                  </div>
                )}
              </div>
              {openSelectorTedarikci &&
                <Dialog
                  open={openSelectorTedarikci}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorTedarikci(false);
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Tedarikçiler'
                      listPath='kullanici/tedarikci_mini_list'
                      listCountPath='kullanici/count'
                      keyProp='id'
                      valueProp='username'
                      defaultItem={props.form.tedarikci}
                      onChangeItems={(items) => props.onChangeForm('tedarikci', null, items)}
                      onClose={() => {
                        setOpenSelectorTedarikci(false);
                      }}
                      newPath='tanimlar/cari'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorDepo &&
                <Dialog
                  open={openSelectorDepo}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorDepo(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Depolar'
                      listPath='depo/mini_list?aktif=true'
                      listCountPath='depo/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.depo}
                      onChangeItems={(items) => props.onChangeForm('depo', null, items)}
                      onClose={() => {
                        setOpenSelectorDepo(false);
                      }}
                      newPath='tanimlar/depolar'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorKategori &&
                <Dialog
                  open={openSelectorKategori}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorKategori(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <CategorySelector
                      selected={props.form.kategori}
                      onChange={(items) => props.onChangeForm('kategori', null, items)}
                      onClose={() => setOpenSelectorKategori(false)}
                      single={true}
                      parentselect={false}
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorDil &&
                <Dialog
                  open={openSelectorDil}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorDil(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Diller'
                      listPath='dil/mini_list?aktif=true'
                      listCountPath='dil/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.dil}
                      onChangeItems={(items) => props.onChangeForm('dil', null, items)}
                      onClose={() => {
                        setOpenSelectorDil(false);
                      }}
                      newPath='tanimlar/diller'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorMagaza(false);
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Mağazalar'
                      listPath='magaza/mini_list?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={props.form.magaza}
                      onChangeItems={(items) => props.onChangeForm('magaza', null, items)}
                      onClose={() => {
                        setOpenSelectorMagaza(false);
                      }}
                      newPath='tanimlar/magaza'
                    />
                  </DialogContent>
                </Dialog>
              }
              {openSelectorMagazalar &&
                <Dialog
                  open={openSelectorMagazalar}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorMagazalar(false)
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Mağazalar'
                      listPath='magaza/mini_list?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.magazalar}
                      onChangeItems={(items) => props.onChangeForm('magazalar', null, items)}
                      onClose={() => {
                        setOpenSelectorMagazalar(false)
                      }}
                      newPath='tanimlar/magaza'
                    />
                  </DialogContent>
                </Dialog>
              }
              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default EntegrasyonDetailTabGenel;
