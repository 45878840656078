import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Typography,
  Checkbox,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors, handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ palette, spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 0, 1, 0),
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    layoutFormItemCaptionText: {
      fontStyle: 'italic',
      fontSize: "11px !important",
    },
  };
};

const SatisBirimGrupTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const LOADINGCARPAN = useMemo(() => props.loadingCarpan, [props.loadingCarpan]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATACARPAN = useMemo(() => props.hataCarpan, [props.hataCarpan]);
  const add = useMemo(() => props.add, [props.add]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.defaultSelectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.carpanForms.length ?? 0, [props.carpanForms]);
  const FORM = useMemo(() => props?.carpanForms?.[selectedTabIndex]?.form ?? null, [props.carpanForms, selectedTabIndex]);
  const ERROR = useMemo(() => props?.carpanForms?.[selectedTabIndex]?.errors ?? null, [props.carpanForms, selectedTabIndex]);

  useLayoutEffect(() => {
    const copyForms = [...props.carpanForms];
    const newTabs = copyForms.map((form) => {
      return {
        label: props.birimDic[form.form.birim] + ' --> ' + form.form.katsayi + ' ' + props.birimDic[props.form.birim],
        hasError: form.errors,
      };
    });
    setTabs(newTabs);
  }, [props.carpanForms, props.form, props.birimDic]);

  useLayoutEffect(() => {
    if (!props.carpanAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.carpanAdd, COUNT_TABS]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const createTextFieldPropsCarpan = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };

  }, [FORM, ERROR]);

  const handleChangeForm = (property, value) => {
    props.onChangeFormCarpan(selectedTabIndex, property, value);
  };

  const handleSetErrors = (errors) => {
    props.onCheckErrorsCarpan(errors, selectedTabIndex);
  }

  const handleDetailDelete = (index) => {
    if (props.carpanForms[index].form.id) {
      props.openDeleteForm(true);
      props.setDeleteId(props.carpanForms[index].form.id);
    } else {
      props.deleteDetailWithIndex(index);
    }
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Satış Birim Grup Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim')}
                          onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                          onBlur={() => handleInputErrors('satisbirimgrup', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Satış Birim Grup Tanım', 'satış birim grup')}
                          inputProps={{
                            maxLength: 250,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="birim">
                      <label className={classes.layoutFormItemLabel} htmlFor="kodu">Birim *</label>
                      <Tooltip title={props.error?.birim ? props.error.birim[0] : _isNil(props.form?.birim) || props.form?.birim === '-1' ? '' : props.birimDic ? props.birimDic[props.form.birim] : ''}>
                        <TextField
                          {...createTextFieldProps('birim')}
                          onChange={(e) => props.onChangeForm('birim', e.target.value)}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'birim', true, false, false, '', '', '')}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option value='-1'>Seçilmedi</option>
                          {props?.birim?.map((birim) => (
                            <option key={birim.id} value={birim.id}>{birim.tanim}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="siralama">
                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                      <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                        <TextField
                          {...createTextFieldProps('siralama')}
                          onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                          type="number"
                          inputProps={{
                            min: -2147483648,
                            max: 2147483647,
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                      ></Checkbox>
                    </li>
                  </ul>
                </div>

                <div className={classes.layoutContainerTab}>
                  <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">ÇARPANLAR</span></div>
                  {!LOADINGCARPAN && (
                    <>
                      {HATACARPAN && (
                        <Hata />
                      )}
                      {!HATACARPAN && (
                        <LayoutDetailVerticalTab
                          tabs={tabs}
                          selectedTabIndex={selectedTabIndex}
                          onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                          add={props.onAddNewTab}
                          buttonText={"YENİ ÇARPAN EKLE"}
                          buttonShow={props.carpanAdd}
                          delete={handleDetailDelete}
                        >
                          {COUNT_TABS === 0 && (
                            <div className="flex flex-col items-start justify-start p-4 space-y-4">
                              <Typography variant="body1">Satış birim grup çarpana ait bir çarpan bulunamadı.</Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                disableElevation
                                onClick={props.onAddNewTab}
                              >YENİ ÇARPAN EKLE</Button>
                            </div>
                          )}
                          {COUNT_TABS > 0 && (
                            <div className={classes.layoutContainer}>
                              <div className={classes.column}>
                                <ul className={classes.layoutFormList}>
                                  <li className={classes.layoutFormItem} id="birim">
                                    <label className={classes.layoutFormItemLabel} htmlFor="kodu">Birim *</label>
                                    <Tooltip title={ERROR?.birim ? ERROR.birim[0] : _isNil(FORM?.birim) || FORM?.birim === "-1" ? '' : props.birimDic ? props.birimDic[FORM.birim] : ''}>
                                      <TextField
                                        {...createTextFieldPropsCarpan('birim')}
                                        onChange={(e) => handleChangeForm('birim', e.target.value)}
                                        onBlur={() => handleInputErrors2(FORM, 'birim', handleSetErrors)}
                                        select
                                        SelectProps={{
                                          native: true,
                                        }}
                                      >
                                        <option value='-1' disabled>Seçilmedi</option>
                                        {props?.birim?.filter((x) => x.id !== Number(props.form?.birim)).map((birim, index) => (
                                          <option key={index} value={birim.id}>{birim.tanim}</option>
                                        ))}
                                      </TextField>
                                    </Tooltip>
                                  </li>
                                  <li className={classes.layoutFormItem} id="katsayi">
                                    <label className={classes.layoutFormItemLabel} htmlFor="katsayi">Katsayı *</label>
                                    <Tooltip title={ERROR?.katsayi ? ERROR.katsayi[0] : _isNil(FORM?.katsayi) ? '' : FORM.katsayi}>
                                      <TextField
                                        {...createTextFieldPropsCarpan('katsayi')}
                                        onChange={(e) => handleChangeForm('katsayi', e.target.value)}
                                        onBlur={() => handleInputErrors2(FORM, 'katsayi', handleSetErrors)}
                                        type="number"
                                        inputProps={{
                                          step: 1,
                                          style: { textAlign: 'right' }
                                        }}
                                      ></TextField>
                                    </Tooltip>
                                  </li>
                                  <li className={classes.layoutFormItem} id="siralama">
                                    <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                                    <Tooltip title={ERROR?.siralama ? ERROR.siralama[0] : _isNil(FORM?.siralama) ? '' : FORM.siralama}>
                                      <TextField
                                        {...createTextFieldPropsCarpan('siralama')}
                                        onChange={(e) => handleChangeForm('siralama', e.target.value)}
                                        type="number"
                                        inputProps={{
                                          min: -2147483648,
                                          max: 2147483647,
                                          step: 1,
                                          style: { textAlign: 'right' }
                                        }}
                                      ></TextField>
                                    </Tooltip>
                                  </li>
                                  <li className={classes.layoutFormItem} id="aktif">
                                    <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                                    <Checkbox
                                      name="aktif"
                                      color="primary"
                                      checked={_result(FORM, 'aktif', false)}
                                      onChange={(e, checked) => handleChangeForm('aktif', checked)}
                                    ></Checkbox>
                                  </li>
                                  <li className={classes.layoutFormItem} id="varsayilan">
                                    <label className={classes.layoutFormItemLabel} htmlFor="varsayilan">Varsayılan</label>
                                    <Checkbox
                                      name="varsayilan"
                                      color="primary"
                                      checked={_result(FORM, 'varsayilan', false)}
                                      onChange={(e, checked) => handleChangeForm('varsayilan', checked)}
                                    ></Checkbox>
                                  </li>
                                  {ERROR?.non_field_errors && (
                                    <li className={classes.layoutFormItem} id="non_field_errors">
                                      <Typography variant="caption" color="textPrimary" className={classes.layoutFormItemCaptionText}>“{ERROR.non_field_errors}”</Typography>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        </LayoutDetailVerticalTab>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      }
    </>
  );
});

export default SatisBirimGrupTabGenel;
