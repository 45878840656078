/* eslint-disable no-unused-vars */
import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil,
} from 'lodash';
import moment from 'moment'

import {
  TextField,
  Typography,
  Tooltip,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import ListSelector from '../../../components/ListSelector';
import MultipleSelectList from '../../../components/MultipleSelectList';


const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },
    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
    layoutFormItemCaptionText: {
      fontStyle: 'italic',
      fontSize: "11px !important",
    },
  };
};

const EntFiltre = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const UST_ID = useMemo(() => props.ID, [props.ID]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.defaultSelectedTabIndex);
  const [openSelectorFiltre, setOpenSelectorFiltre] = useState(false);
  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.forms.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.form.ne_zaman_yapsin ? moment(form.form.ne_zaman_yapsin).format('DD/MM/YYYY, HH.mm') : "Belirsiz Zaman",
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);

  useLayoutEffect(() => {
    if (!props.filtreManuelAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.filtreManuelAdd, COUNT_TABS]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const handleChangeForm = (property, value) => {
    props.onChangeForm(selectedTabIndex, property, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }

  const handleRemoveFiltrelerById = (id) => {
    const copyFiltreler = [...FORM.hangi_filtreler_uygulansin];
    const index = copyFiltreler.findIndex((c) => c.id === id);
    if (index > -1) {
      copyFiltreler.splice(index, 1);
      handleChangeForm('hangi_filtreler_uygulansin', copyFiltreler);
    }
  };

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <LayoutDetailVerticalTab
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                add={props.onAddNewTab}
                buttonText={"YENİ MANUEL İŞLEM EKLE"}
                buttonShow={props.filtreManuelAdd}
                delete={handleDetailDelete}
              >
                {COUNT_TABS === 0 && (
                  <div className="flex flex-col items-start justify-start p-4 space-y-4">
                    <Typography variant="body1">
                      Entegrasyona ait bir manuel işlem bulunamadı.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      onClick={props.onAddNewTab}
                    >YENİ MANUEL İŞLEM EKLE</Button>
                  </div>
                )}

                {COUNT_TABS > 0 && (
                  <>
                    <div className={classes.tabDiv}>
                      <div className={classes.column}>
                        <ul className={classes.formList}>
                          <li className={classes.formItem} id="islem_tipi">
                            <label className={classes.formItemLabel} htmlFor="islem_tipi">İşlem Tipi</label>
                            <Tooltip title={ERROR?.islem_tipi ? ERROR.islem_tipi[0] : _isNil(FORM?.islem_tipi) ? '' : props.entIslemTipDic ? props.entIslemTipDic[FORM.islem_tipi] : ''}>
                              <TextField
                                {...createTextFieldProps('islem_tipi')}
                                onChange={(e) => handleChangeForm('islem_tipi', e.target.value)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                {props?.entIslemTip?.map((et) => (
                                  <option key={et.key} value={et.key}>{et.value}</option>
                                ))}
                              </TextField>
                            </Tooltip>
                          </li>
                          {!FORM.is_basladi && (
                            <li className={classes.formItem} id="hemen_yap">
                              <label className={classes.formItemLabel} htmlFor="hemen_yap">Ne Zaman Yapılsın</label>
                              <Tooltip title={_isNil(FORM?.hemen_yap) ? '' : FORM.hemen_yap === "0" ? "İleri bir tarih seç" : "Hemen"}>
                                <TextField
                                  {...createTextFieldProps('hemen_yap')}
                                  onChange={(e) => handleChangeForm('hemen_yap', e.target.value)}
                                  select
                                  SelectProps={{
                                    native: true,
                                  }}
                                >
                                  <option key="1" value="1">Hemen</option>
                                  <option key="0" value="0">İleri bir tarih seç</option>
                                </TextField>
                              </Tooltip>
                            </li>
                          )}
                          {FORM.hemen_yap === "0" && (
                            <li className={classes.formItem} id="ne_zaman_yapsin">
                              <label className={classes.formItemLabel} htmlFor="ne_zaman_yapsin">İşlem Başlangıç Zamanı</label>
                              <Tooltip title={ERROR?.ne_zaman_yapsin ? ERROR.ne_zaman_yapsin[0] : _isNil(FORM?.ne_zaman_yapsin) ? '' : FORM.ne_zaman_yapsin}>
                                <TextField
                                  {...createTextFieldProps('ne_zaman_yapsin')}
                                  onChange={(e) => handleChangeForm('ne_zaman_yapsin', e.target.value)}
                                  type="datetime-local"
                                ></TextField>
                              </Tooltip>
                            </li>
                          )}
                          <li className={classes.formItem} id="aktif">
                            <label className={classes.formItemLabel} htmlFor="aktif">Aktif</label>
                            <Checkbox
                              name="aktif"
                              color="primary"
                              checked={_result(FORM, 'aktif', false)}
                              onChange={(e, checked) => handleChangeForm('aktif', checked)}
                            ></Checkbox>
                          </li>
                        </ul>
                        <ul className={classes.formList}>
                          <li className={classes.layoutFormItemBox} id="tanim">
                            <MultipleSelectList
                              headername="FİLTRELER"
                              name="Filtreler"
                              name2="Filtre"
                              form={FORM}
                              list={FORM?.hangi_filtreler_uygulansin ?? []}
                              listlength={FORM?.hangi_filtreler_uygulansin?.length ?? 0}
                              opendialog={setOpenSelectorFiltre}
                              keyProp="id"
                              valueProp="tanim"
                              removefonk={handleRemoveFiltrelerById}
                              loading={props.loading}
                              error={props.error?.hangi_filtreler_uygulansin ? props.error.hangi_filtreler_uygulansin[0] : null}
                              ustId={false}
                              helper={true}
                              helperOpen={() => setHelpOpen(true)}
                              helperSet={() => setHelpText("“Boş bırakılırsa aktif olan tüm filtreler uygulanır.”")}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                    {openSelectorFiltre && (
                      <Dialog
                        open={openSelectorFiltre}
                        PaperProps={{
                          style: {
                            width: '480px',
                            height: '600px',
                          },
                        }}
                        onClose={() => {
                          setOpenSelectorFiltre(false)
                        }}
                      >
                        <DialogContent style={{ padding: 0 }}>
                          <ListSelector
                            title='Filtreler'
                            listPath={`enttanim/${UST_ID}/satirkosul`}
                            listCountPath={`enttanim/${UST_ID}/satirkosul/count`}
                            keyProp='id'
                            valueProp='tanim'
                            defaultItems={FORM.hangi_filtreler_uygulansin}
                            onChangeItems={(items) => handleChangeForm('hangi_filtreler_uygulansin', items)}
                            onClose={() => {
                              setOpenSelectorFiltre(false)
                            }}
                            newPath='tanimlar/magaza'
                          />
                        </DialogContent>
                      </Dialog>
                    )}
                    {helpOpen &&
                      <Dialog
                        open={helpOpen}
                        onClose={() => setHelpOpen(false)}
                      >
                        <DialogContent>
                          <DialogContentText>
                            <span className="text-center text-sm text-palette-text-primary">
                              {helpText}
                            </span>
                          </DialogContentText>
                        </DialogContent>
                      </Dialog>
                    }
                  </>
                )}
              </LayoutDetailVerticalTab>
            </div>
          )}
        </>
      }
    </>
  );

});

export default EntFiltre;
