import React, { useState, useLayoutEffect } from 'react';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import { turkishToLower } from '../helpers/helpers'

import {
  Button,
  Checkbox,
  Radio,
  Paper,
  CircularProgress,
  LinearProgress,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Tooltip,
} from '@material-ui/core';

import {
  Close as IconClose,
  Search as IconSearch,
  KeyboardArrowUp as IconKeyboardArrowUp,
  KeyboardArrowRight as IconKeyboardArrowRight,
  ErrorOutline as IconErrorOutline,
  Delete as IconDelete,
  Refresh as IconRefresh,
} from '@material-ui/icons';

import django from '../api/django';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    }
  };
});

const PER_PAGE = 100;
const ROW_HEIGHT = 32;

const mapCategory = (category) => {
  if (!category) {
    return null;
  }
  return {
    id: category.id,
    alt: category.alt,
    parent: category.parent,
    tanim: category.tanim,
    aktif: category.aktif,
    altkategoriler_count: category?.altkategoriler_count ?? 0,
  };
};

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text, submitText, onSubmit }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>

      <p className="font-medium mb-2">{text}</p>

      {onSubmit &&
        <Button
          color="primary"
          onClick={onSubmit}
        >{submitText ?? 'TEKRAR DENE'}</Button>
      }
    </div>
  );
};

const CategoryItem = ({ category, isSelected, isParent, isLastParent, searchQuery, treeLevel, onChange, onClick, single }) => {
  return (
    <div
      className={clsx('flex items-center justify-start', {
        'bg-palette-background-default': isParent,
      })}
      style={{
        height: ROW_HEIGHT,
        paddingLeft: treeLevel * 20,
      }}
    >
      <span className="w-16 flex items-center justify-center">
        {!single &&
          <Checkbox
            color="primary"
            checked={isSelected}
            onChange={(e, checked) => {
              onChange(category.id, category.tanim, checked);
            }}
          ></Checkbox>
        }
        {single &&
          <Radio
            color="primary"
            checked={isSelected === category.id}
            value={category.id}
            onChange={(e, checked) => {
              onChange(category.id, category.tanim, checked);
            }}
          ></Radio>
        }
      </span>
      <div
        className={clsx('flex-1 flex items-center justify-between self-stretch hover:text-primary text-left pl-2 -ml-2', {
          'cursor-pointer': !isLastParent && category.alt,
        })}
        onClick={() => {
          if (!isLastParent && category.alt) {
            onClick();
          }
        }}
      >
        <Highlighter
          textToHighlight={category.tanim}
          searchWords={[searchQuery ?? '']}
        />
        {category.alt &&
          <span className="w-12 flex items-center justify-center">
            {isParent && !isLastParent && <IconKeyboardArrowUp />}
            {!isParent && <IconKeyboardArrowRight />}
          </span>
        }
      </div>
    </div>
  );
};

const CategorySelector = ({ path, selected, onChange, onClose, onSubmit = null, single }) => {
  const [allCategories, setAllCategories] = useState([]);
  const [firstCount, setFirstCount] = useState(0);
  const [allCategoriesLoading, setAllCategoriesLoading] = useState(false);
  const [allCategoriesLoadingErrorMessage, setAllCategoriesLoadingErrorMessage] = useState(null);

  const [selectedParentCategoryId, setSelectedParentCategoryId] = useState(null);
  const [selectedParentCategory, setSelectedParentCategory] = useState(null);

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  // search
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [selectedSearchResult, setSelectedSearchResult] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchingErrorMessage, setIsSearchingErrorMessage] = useState(null);

  // selected categories
  const [showSelectedCategories, setShowSelectedCategories] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoriesLoading, setSelectedCategoriesLoading] = useState(false);
  const [selectedCategoriesLoadingErrorMessage, setSelectedCategoriesLoadingErrorMessage] = useState(null);
  const [selectedCategoriesLastUpdate, setSelectedCategoriesLastUpdate] = useState(0);

  const [shownCategories, setShownCategories] = useState([]);

  const [moreCategoriesLoading, setMoreCategoriesLoading] = useState(false);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const classes = useStyles();
  const mainContainerRef = React.useRef();

  useLayoutEffect(() => {
    setAllCategoriesLoading(true);
    const req_parent_cat = django(path);
    const req_parent_cat_count = django(`${path}/count`);
    Promise.all([req_parent_cat, req_parent_cat_count]).then(([res_parent_cat, res_parent_cat_count]) => {
      const mappedCategories = res_parent_cat.data.map((category) => mapCategory(category));
      setAllCategories(mappedCategories);
      setFirstCount(res_parent_cat_count.data);
    }).catch(() => {
      setAllCategoriesLoadingErrorMessage('Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.');
    }).finally(() => {
      setAllCategoriesLoading(false);
    });
  }, [lastUpdateTime, path]);

  useLayoutEffect(() => {
    if (selectedParentCategoryId !== null) {
      const findSelectedParentCategory = allCategories.find((c) => c.id === selectedParentCategoryId);
      setSelectedParentCategory(findSelectedParentCategory);
    } else {
      setSelectedParentCategory(null);
    }
  }, [allCategories, selectedParentCategoryId]);

  useLayoutEffect(() => {
    setShownCategories(allCategories.filter((category) => category.parent === selectedParentCategoryId));
  }, [allCategories, selectedParentCategoryId]);

  useLayoutEffect(() => {
    if (selectedParentCategory) {
      const childCategories = allCategories.filter((category) => category.parent === selectedParentCategory.id);
      if (childCategories.length === 0) {
        const request_kategori_alt = django(`${path}/${selectedParentCategory.id}/alt`, { params: { sort: 'tanim' } });
        const request_kategori_alt_count = django(`${path}/${selectedParentCategory.id}/alt/count`);
        Promise.all([request_kategori_alt, request_kategori_alt_count]).then(([response_kategori_alt, response_kategori_alt_count]) => {
          setAllCategories((prevAllCategories) => {
            const copyPrevAllCategories = [...prevAllCategories].map((category) => {
              if (category.id === selectedParentCategory.id) {
                return {
                  ...category,
                  altkategoriler_count: response_kategori_alt_count.data,
                };
              }
              return category;
            });
            const mappedNewCategories = response_kategori_alt.data.map((category) => mapCategory(category));
            return [...copyPrevAllCategories, ...mappedNewCategories];
          });
        }).catch((error) => {
          alert('Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.');
        });
      }
    }
  }, [path, allCategories, selectedParentCategory, lastUpdateTime]);

  // handle search
  useLayoutEffect(() => {
    if (!showSelectedCategories) {
      const handleSearch = ({ url, config }) => {
        django(url, config).then(({ data }) => {
          const mappedCategories = data.map((category) => {
            if (!category) {
              return null;
            }
            return {
              id: category.id,
              alt: false,
              parent: null,
              tanim: category.parents,
              aktif: true,
            };
          });
          setSearchResult(mappedCategories);
        }).catch(function (error) {
          if (django?.isCancel(error)) {
          } else {
            setIsSearchingErrorMessage('Beklenmeyen bir hata oluştu');
          }
        }).finally(() => {
          setIsSearching(false);
        });
      };
      if (searchQuery !== '') {
        setSearchResult([]);
        setIsSearching(true);
        setIsSearchingErrorMessage(null);
        const CancelToken = django.CancelToken;
        const source = CancelToken.source();
        const url = path;
        const config = {
          params: {
            tanim: searchQuery,
            sort: 'tanim',
          },
          cancelToken: source.token,
        };
        const debounce = setTimeout(() => handleSearch({ url, config }), 300);
        return () => {
          source.cancel();
          clearTimeout(debounce);
        };
      }
    } else {
      const handleGet = (searchQuery) => {
        const list = [...selectedCategories];
        const filterList = list.filter((item) => turkishToLower(item.tanim).includes(turkishToLower(searchQuery)));
        setSelectedSearchResult(filterList);
        setIsSearching(false);
      }

      if (searchQuery === '') {
        setSelectedSearchResult([]);
        setIsSearching(false);
        setIsSearchingErrorMessage(null);
      }
      if (searchQuery !== '') {
        setSelectedSearchResult([]);
        setIsSearching(true);
        setIsSearchingErrorMessage(null);
        const debounce = setTimeout(() => handleGet(searchQuery), 300);
        return () => {
          clearTimeout(debounce);
          setIsSearching(false);
        };
      }
    }
  }, [path, searchQuery, showSelectedCategories, selectedCategories, lastUpdateTime]);

  // set selected categories
  useLayoutEffect(() => {
    const getSelectedCategories = () => {
      if (!single) {
        if (selected.length === 0) {
          setSelectedCategories([]);
        }
        if (showSelectedCategories && selected.length > 0) {
          setSelectedCategoriesLoading(true);
          setSelectedCategoriesLoadingErrorMessage(null);
          let ids = [];
          if (onSubmit) {
            ids = selected.map((s) => s);
          } else {
            ids = selected.map((s) => s.id);
          }
          django(path, { params: { id: ids, sort: 'tanim' } }).then((response) => {
            setSelectedCategories(response.data.map((c) => ({ id: c.id, tanim: c.tanim })));
          }).catch((error) => {
            setSelectedCategoriesLoadingErrorMessage('Seçili kategoriler alınırken beklenmeyen bir hata oluştu.');
          }).finally(() => {
            setSelectedCategoriesLoading(false);
          });
        }
      } else {
        if (selected) {
          setSelectedCategories([]);
        }
        if (showSelectedCategories && selected) {
          setSelectedCategoriesLoading(true);
          setSelectedCategoriesLoadingErrorMessage(null);
          const id = selected.id;
          django(path, { params: { id: id, sort: 'tanim' } }).then((response) => {
            setSelectedCategories(response.data.map((c) => ({ id: c.id, tanim: c.tanim })));
          }).catch((error) => {
            setSelectedCategoriesLoadingErrorMessage('Seçili kategoriler alınırken beklenmeyen bir hata oluştu.');
          }).finally(() => {
            setSelectedCategoriesLoading(false);
          });
        }
      }
    };
    const debounce = setTimeout(getSelectedCategories, 300);
    return () => {
      clearTimeout(debounce);
    };
  }, [path, showSelectedCategories, selected, selectedCategoriesLastUpdate, onSubmit, single, lastUpdateTime]);

  const handleSetCategories = (e) => {
    e.preventDefault();
    setAllCategoriesLoading(true);
    setAllCategoriesLoadingErrorMessage(null);
    const req_parent_cat = django(path);
    const req_parent_cat_count = django(`${path}/count`);
    Promise.all([req_parent_cat, req_parent_cat_count]).then(([res_parent_cat, res_parent_cat_count]) => {
      const mappedCategories = res_parent_cat.data.map((category) => mapCategory(category));
      setAllCategories(mappedCategories);
      setFirstCount(res_parent_cat_count.data);
    }).catch(() => {
      setAllCategoriesLoadingErrorMessage('Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.');
    }).finally(() => {
      setAllCategoriesLoading(false);
    });
  };

  const handleClickCategoryItem = (category) => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    }
    const index = breadcrumbs.findIndex((crumb) => crumb.id === category.id);
    if (index > - 1) {
      const copyBreadcrumbs = [...breadcrumbs];
      copyBreadcrumbs.splice(index + 1);
      const lastParentId = copyBreadcrumbs[copyBreadcrumbs.length - 1].id;
      setSelectedParentCategoryId(lastParentId);
      setBreadcrumbs(copyBreadcrumbs);
    }
    else {
      setSelectedParentCategoryId(category.id);
      setBreadcrumbs([...breadcrumbs, category]);
    }
  };

  const handleChange = (id, tanim, checked) => {
    if (!single) {
      if (!checked) {
        const newSelected = [...selected];
        if (!onSubmit) {
          const index = newSelected.findIndex((c) => c.id === id);
          if (index > -1) {
            newSelected.splice(index, 1);
          }
        } else {
          const index = newSelected.findIndex((c) => c === id);
          if (index > -1) {
            newSelected.splice(index, 1);
          }
        }
        onChange(newSelected);
      } else {
        if (onSubmit) {
          onChange([...selected, id]);
        } else {
          onChange([...selected, { id, tanim }]);
        }
      }
    } else {
      onChange({ id, tanim });
    }
  };

  const handleRemoveAllItems = () => {
    if (!single) {
      onChange([]);
    } else {
      onChange(null);
    }
  }

  const handleGetMoreCategoriesFirst = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setMoreCategoriesLoading(true);
    django(`${path}`, { params: { page } }).then(({ data }) => {
      const newCategories = data.map((category) => mapCategory(category));
      setAllCategories((prev) => {
        return [...prev, ...newCategories];
      });
    }).catch((error) => {
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setMoreCategoriesLoading(false);
    });
  };

  const handleGetMoreCategories = (id, page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setMoreCategoriesLoading(true);
    django(`${path}/${id}/alt`, { params: { page } }).then(({ data }) => {
      const newCategories = data.map((category) => mapCategory(category));
      setAllCategories((prev) => {
        return [...prev, ...newCategories];
      });
    }).catch((error) => {
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setMoreCategoriesLoading(false);
    });
  };

  const pageFirst = allCategories.filter(cat => cat.parent === null).length > 0 ? Math.ceil(allCategories.filter(cat => cat.parent === null).length / PER_PAGE) : 1;
  const countPagesFirst = firstCount ? Math.ceil(firstCount / PER_PAGE) : 1;

  const page = shownCategories.length > 0 ? Math.ceil(shownCategories.length / PER_PAGE) : 1;
  const countPages = selectedParentCategory ? Math.ceil(selectedParentCategory.altkategoriler_count / PER_PAGE) : 1;

  const rowRenderer = ({ key, index, style }) => {
    const category = shownCategories[index];
    let isSelected = false;
    if (!single) {
      if (!onSubmit) {
        isSelected = selected.findIndex((c) => c.id === category.id) > -1;
      } else {
        isSelected = selected.findIndex((c) => c === category.id) > -1;
      }
    } else {
      isSelected = selected?.id ?? false
    }
    return (
      <div key={key} style={style}>
        {index !== 0 && <Divider />}
        <CategoryItem
          category={category}
          isSelected={isSelected}
          treeLevel={breadcrumbs.findIndex((crumb) => crumb.id === selectedParentCategoryId) + 1}
          onClick={() => handleClickCategoryItem(category)}
          onChange={handleChange}
          single={single}
        ></CategoryItem>
      </div>
    );
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  return (
    <>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <AppBar position="sticky" color="transparent" elevation={1}>
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <span>
              <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">Kategoriler</h3>
            </span>

            <span>
              <Tooltip title="Yenile">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={handleReload}
                ><IconRefresh /></IconButton>
              </Tooltip>
              <Tooltip title="Kapat">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={onClose}
                ><IconClose /></IconButton>
              </Tooltip>
            </span>
          </Toolbar>

          <nav className="w-full flex-1 flex items-center justify-between bg-palette-background-default py-2 px-4 space-x-4">
            <span className="relative w-full h-8 leading-none bg-palette-background-paper apperance-none">
              <input
                className={clsx([
                  'absolute inset-0 w-full h-full px-10 bg-transparent rounded border border-palette-action-selected',
                  'focus:outline-none focus:border-primary-300 focus:ring-2 focus:ring-primary-200',
                ])}
                value={searchQuery ?? ''}
                placeholder={showSelectedCategories ? 'Seçimlerde ara' : 'Tüm kategoriler içinde ara'}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <span className="absolute top-2 left-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                <IconSearch />
              </span>

              {searchQuery.length > 2 &&
                <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchQuery('');
                    }}
                  >
                    <IconClose />
                  </IconButton>
                </span>
              }
            </span>

            <span>
              <Tooltip title={!single ? "Tüm seçimleri kaldır" : "Seçimi kaldır"}>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleRemoveAllItems();
                  }}
                >
                  <IconDelete />
                </IconButton>
              </Tooltip>
            </span>

            <span>
              <Tooltip title="Sadece seçili kategorileri göster">
                <Checkbox
                  color="primary"
                  checked={showSelectedCategories}
                  onChange={(e, checked) => setShowSelectedCategories(checked)}
                  disableRipple
                  style={{
                    padding: 5,
                  }}
                ></Checkbox>
              </Tooltip>
            </span>
          </nav>
          <span
            className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', {
              'hidden': !selectedCategoriesLoading && !moreCategoriesLoading && shownCategories.length > 0,
            })}
          >
            <LinearProgress style={{ height: 2 }} />
          </span>
        </AppBar>

        <main className="relative flex flex-grow flex-col overflow-hidden" ref={mainContainerRef}>

          {showSelectedCategories && searchQuery === '' && (
            <div className="flex flex-col h-full">
              {(selectedCategoriesLoading && selectedCategories.length === 0) && (
                <LoadingMessage text="Seçili kategoriler alınıyor." />
              )}

              {(!selectedCategoriesLoading && selectedCategoriesLoadingErrorMessage) && (
                <ErrorMessage
                  text={selectedCategoriesLoadingErrorMessage}
                  submitText="Tekrar Dene"
                  onSubmit={() => setSelectedCategoriesLastUpdate(Date.now())}
                />
              )}

              {(!selectedCategoriesLoadingErrorMessage && selectedCategories.length > 0) && (
                <div className="flex flex-col overflow-y-auto h-full">
                  {selectedCategories.map((category, index) => (
                    <div key={category.id}>
                      {index !== 0 && <Divider />}
                      <CategoryItem
                        category={category}
                        isSelected={single !== true ? selected.findIndex((c) => onSubmit !== null ? c === category.id : c.id === category.id) > -1 : selected?.id ?? false}
                        treeLevel={0}
                        onClick={() => handleClickCategoryItem(category)}
                        onChange={handleChange}
                        single={single}
                      ></CategoryItem>
                    </div>
                  ))}
                </div>
              )}

              {(!selectedCategoriesLoadingErrorMessage && selectedCategories.length > 0) && (
                <div className="flex items-center justify-between border-t border-palette-action-selected p-2">
                  <span></span>
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableRipple
                      onClick={onSubmit}
                    >FİLTRELE</Button>
                  </span>
                </div>
              )}
            </div>
          )}

          {showSelectedCategories && searchQuery !== '' &&
            <div className="flex flex-col h-full">
              {isSearching &&
                <LoadingMessage text="Arama yapılıyor..." />
              }

              {!isSearching &&
                <>
                  {isSearchingErrorMessage &&
                    <ErrorMessage text={isSearchingErrorMessage} />
                  }

                  {selectedSearchResult.length === 0 &&
                    <ErrorMessage text="Girdiğiniz değere uygun kategori bulunamadı" />
                  }

                  {selectedSearchResult.length > 0 && (
                    <div className="flex flex-col overflow-y-auto h-full">
                      {selectedSearchResult.map((category, index) => (
                        <div key={category.id}>
                          {index !== 0 && <Divider />}
                          <CategoryItem
                            category={category}
                            isSelected={single !== true ? selected.findIndex((c) => onSubmit !== null ? c === category.id : c.id === category.id) > -1 : selected?.id ?? false}
                            treeLevel={0}
                            onClick={() => handleClickCategoryItem(category)}
                            onChange={handleChange}
                            searchQuery={searchQuery}
                            single={single}
                          ></CategoryItem>
                        </div>
                      ))}
                    </div>
                  )}

                  {selectedSearchResult.length > 0 && (
                    <div className="flex items-center justify-between border-t border-palette-action-selected p-2">
                      <span></span>
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          disableRipple
                          onClick={onSubmit}
                        >FİLTRELE</Button>
                      </span>
                    </div>
                  )}
                </>
              }
            </div>
          }

          {!showSelectedCategories && searchQuery !== '' &&
            <div className="flex flex-col h-full">
              {isSearching &&
                <LoadingMessage text="Arama yapılıyor..." />
              }

              {!isSearching &&
                <>
                  {isSearchingErrorMessage &&
                    <ErrorMessage text={isSearchingErrorMessage} />
                  }

                  {searchResult.length === 0 &&
                    <ErrorMessage text="Girdiğiniz değere uygun kategori bulunamadı" />
                  }

                  {searchResult.length > 0 && (
                    <div className="flex flex-col overflow-y-auto h-full">
                      {searchResult.map((category, index) => (
                        <div key={category.id}>
                          {index !== 0 && <Divider />}
                          <CategoryItem
                            category={category}
                            isSelected={single !== true ? selected.findIndex((c) => onSubmit !== null ? c === category.id : c.id === category.id) > -1 : selected?.id ?? false}
                            treeLevel={0}
                            onClick={() => handleClickCategoryItem(category)}
                            onChange={handleChange}
                            searchQuery={searchQuery}
                            single={single}
                          ></CategoryItem>
                        </div>
                      ))}
                    </div>
                  )}

                  {searchResult.length > 0 && (
                    <div className="flex items-center justify-between border-t border-palette-action-selected p-2">
                      <span></span>

                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          disableRipple
                          onClick={onSubmit}
                        >FİLTRELE</Button>
                      </span>
                    </div>
                  )}
                </>
              }
            </div>
          }

          {!showSelectedCategories && searchQuery === '' &&
            <>
              {allCategoriesLoading && <LoadingMessage text="Kategoriler yükleniyor..." />}

              {!allCategoriesLoading &&
                <>
                  {allCategoriesLoadingErrorMessage && <ErrorMessage text={allCategoriesLoadingErrorMessage} onSubmit={handleSetCategories} />}

                  {(shownCategories.length > 0 && breadcrumbs.length > 0) &&
                    <div className="block">
                      <button
                        className="w-full h-10 flex items-center justify-between bg-palette-background-default hover:bg-palette-action-selected cursor-pointer focus:outline-none px-5"
                        onClick={() => {
                          setSelectedParentCategoryId(null);
                          setSelectedParentCategory(null);
                          setBreadcrumbs([]);
                        }}
                      >
                        <span className="flex items-center justify-start font-medium">En Üste Git</span>
                        <IconKeyboardArrowUp />
                      </button>
                      <Divider />
                      {breadcrumbs.map((category, index) =>
                        <div key={category.id}>
                          <CategoryItem
                            category={category}
                            isSelected={single !== true ? selected.findIndex((c) => onSubmit !== null ? c === category.id : c.id === category.id) > -1 : selected?.id ?? false}
                            treeLevel={index}
                            isParent
                            isLastParent={(breadcrumbs.length - 1) === index}
                            onClick={() => handleClickCategoryItem(category)}
                            onChange={handleChange}
                            single={single}
                          ></CategoryItem>
                          <Divider />
                        </div>
                      )}
                    </div>
                  }

                  {shownCategories.length > 0 &&
                    <div className="h-full flex-grow">
                      <InfiniteLoader
                        minimumBatchSize={100}
                        threshold={50}
                        isRowLoaded={({ index }) => !!shownCategories[index]}
                        loadMoreRows={({ startIndex, stopIndex }) => {
                          if (selectedParentCategory) {
                            if (countPages > page && !moreCategoriesLoading) {
                              handleGetMoreCategories(selectedParentCategory.id, page + 1);
                            }
                          } else {
                            if (countPagesFirst > pageFirst && !moreCategoriesLoading) {
                              handleGetMoreCategoriesFirst(pageFirst + 1);
                            }
                          }
                        }}
                        rowCount={selectedParentCategory ? selectedParentCategory.altkategoriler_count * (ROW_HEIGHT + 1) : firstCount * (ROW_HEIGHT + 1)}
                      >
                        {({ onRowsRendered, registerChild }) => (
                          <AutoSizer>
                            {({ width, height }) => (
                              <List
                                width={width}
                                height={height}
                                rowHeight={ROW_HEIGHT + 1}
                                rowCount={shownCategories.length}
                                estimatedRowSize={selectedParentCategory ? selectedParentCategory.altkategoriler_count * (ROW_HEIGHT + 1) : firstCount * (ROW_HEIGHT + 1)}
                                rowRenderer={rowRenderer}
                                onRowsRendered={onRowsRendered}
                                ref={registerChild}
                              />
                            )}
                          </AutoSizer>
                        )}
                      </InfiniteLoader>
                    </div>
                  }
                  <div className="flex items-center justify-between border-t border-palette-action-selected p-2">
                    <span></span>

                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disableRipple
                        onClick={onSubmit}
                      >FİLTRELE</Button>
                    </span>
                  </div>
                </>
              }
            </>
          }
        </main>
      </Paper>
    </>
  );
};

export default CategorySelector;
