import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Checkbox,
  Typography,
  Dialog,
  DialogContent,
  Tooltip
} from '@material-ui/core';

import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';

import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import ListSelector from '../../../components/ListSelector';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import MultipleSelectList from '../../../components/MultipleSelectList';

const styles = ({ spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },
    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
  };
};


const FiyatTanimDetailTabDetay = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  const MINDATE = useMemo(() => props?.minDate, [props]);
  const MAXDATE = useMemo(() => props?.maxDate, [props]);

  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);
  const [openSelectorGrup, setOpenSelectorGrup] = useState(false);


  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.form.tanim,
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);

  useLayoutEffect(() => {
    if (!props.fiyatlarAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.fiyatlarAdd, COUNT_TABS]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const setValidDate = (prop) => {
    let errors = {};
    if (prop === 'indirim_gecerlilik_baslangic_tarihi') {
      if (FORM.indirim_gecerlilik_baslangic_tarihi > FORM.indirim_gecerlilik_bitis_tarihi) {
        errors[prop] = ['Başlangıç tarihi bitiş tarihinden sonra olamaz.']
      } else {
        errors['indirim_gecerlilik_bitis_tarihi'] = null;
        errors = null;
      }
    } else {
      if (FORM.indirim_gecerlilik_bitis_tarihi < FORM.indirim_gecerlilik_baslangic_tarihi) {
        errors[prop] = ['Bitiş tarihi başlangıç tarihinden önce olamaz.']
      } else {
        errors['indirim_gecerlilik_baslangic_tarihi'] = null;
        errors = null;
      }
    }
    props.onCheckErrors(errors, selectedTabIndex);
  }

  const handleChangeForm = (property, value) => {
    props.onChangeForm(selectedTabIndex, property, value);
  };

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleRemoveMagazaById = (id) => {
    const copymagazalar = [...FORM.magazalar];
    const index = copymagazalar.findIndex((c) => c.id === id);
    if (index > -1) {
      copymagazalar.splice(index, 1);
      handleChangeForm('magazalar', copymagazalar);
    }
  }

  const handleRemoveGrupById = (id) => {
    const copygruplar = [...FORM.kullanici_gruplari];
    const index = copygruplar.findIndex((c) => c.id === id);
    if (index > -1) {
      copygruplar.splice(index, 1);
      handleChangeForm('kullanici_gruplari', copygruplar);
    }
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <>
                <LayoutDetailVerticalTab
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                  add={props.onAddNewTab}
                  buttonText={"YENİ FİYAT EKLE"}
                  buttonShow={props.fiyatlarAdd}
                  delete={handleDetailDelete}
                >
                  {COUNT_TABS === 0 && (
                    <div className="flex flex-col items-start justify-start p-4 space-y-4">
                      <Typography variant="body1">
                        Listeye ait bir fiyat bulunamadı.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disableElevation
                        onClick={props.onAddNewTab}
                      >YENİ FİYAT EKLE</Button>
                    </div>
                  )}

                  {COUNT_TABS > 0 && (
                    <>
                      <div className={classes.tabDiv}>
                        <div className={classes.column}>
                          <ul className={classes.formList}>
                            <li className={classes.formItem} id="tanim">
                              <label className={classes.formItemLabel} htmlFor="tanim">Tanım *</label>
                              <Tooltip title={ERROR?.tanim ? ERROR.tanim[0] : _isNil(FORM?.tanim) ? '' : FORM.tanim}>
                                <TextField
                                  {...createTextFieldProps('tanim')}
                                  onChange={(e) => handleChangeForm('tanim', e.target.value)}
                                  onBlur={() => handleInputErrors2(FORM, 'tanim', handleSetErrors)}
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>
                            <li className={classes.formItem} id="indirim_gecerlilik_baslangic_tarihi">
                              <label className={classes.formItemLabel} htmlFor="indirim_gecerlilik_baslangic_tarihi">İndirim Başlangıç</label>
                              <Tooltip title={ERROR?.indirim_gecerlilik_baslangic_tarihi ? ERROR.indirim_gecerlilik_baslangic_tarihi[0] : _isNil(FORM?.indirim_gecerlilik_baslangic_tarihi) ? '' : moment(FORM.indirim_gecerlilik_baslangic_tarihi).format('DD/MM/YYYY, HH.mm')}>
                                <TextField
                                  {...createTextFieldProps('indirim_gecerlilik_baslangic_tarihi')}
                                  onChange={(e) => handleChangeForm('indirim_gecerlilik_baslangic_tarihi', e.target.value)}
                                  type="datetime-local"
                                  inputProps={{ min: moment(MINDATE).format('YYYY-MM-DD[T]HH:mm'), max: moment(MAXDATE).format('YYYY-MM-DD[T]HH:mm') }}
                                  onBlur={() => setValidDate('indirim_gecerlilik_baslangic_tarihi')}
                                ></TextField>
                              </Tooltip>
                            </li>
                            <li className={classes.formItem} id="indirim_gecerlilik_bitis_tarihi">
                              <label className={classes.formItemLabel} htmlFor="indirim_gecerlilik_bitis_tarihi">İndirim Bitiş</label>
                              <Tooltip title={ERROR?.indirim_gecerlilik_bitis_tarihi ? ERROR.indirim_gecerlilik_bitis_tarihi[0] : _isNil(FORM?.indirim_gecerlilik_bitis_tarihi) ? '' : moment(FORM.indirim_gecerlilik_bitis_tarihi).format('DD/MM/YYYY, HH.mm')}>
                                <TextField
                                  {...createTextFieldProps('indirim_gecerlilik_bitis_tarihi')}
                                  onChange={(e) => handleChangeForm('indirim_gecerlilik_bitis_tarihi', e.target.value)}
                                  type="datetime-local"
                                  inputProps={{ min: moment(MINDATE).format('YYYY-MM-DD[T]HH:mm'), max: moment(MAXDATE).format('YYYY-MM-DD[T]HH:mm') }}
                                  onBlur={() => setValidDate('indirim_gecerlilik_bitis_tarihi')}
                                ></TextField>
                              </Tooltip>
                            </li>
                            <li className={classes.formItem} id="vergi_dahil">
                              <label className={classes.formItemLabel} htmlFor="vergi_dahil">Vergi Dahil</label>
                              <Checkbox
                                name="vergi_dahil"
                                color="primary"
                                checked={_result(FORM, 'vergi_dahil', false)}
                                onChange={(e, checked) => handleChangeForm('vergi_dahil', checked)}
                              ></Checkbox>
                            </li>
                            <li className={classes.formItem} id="varsayilan">
                              <label className={classes.formItemLabel} htmlFor="varsayilan">Varsayılan</label>
                              <Checkbox
                                name="varsayilan"
                                color="primary"
                                checked={_result(FORM, 'varsayilan', false)}
                                onChange={(e, checked) => handleChangeForm('varsayilan', checked)}
                              ></Checkbox>
                            </li>
                          </ul>
                        </div>
                        <div className={classes.column}>
                          <ul className={classes.formList}>
                            <li className={classes.layoutFormItemBox} id="magazalar">
                              <MultipleSelectList
                                headername="MAĞAZALAR"
                                name="Mağazalar"
                                name2="Mağaza"
                                form={props.forms}
                                list={FORM?.magazalar ?? []}
                                listlength={FORM?.magazalar?.length ?? 0}
                                opendialog={setOpenSelectorMagaza}
                                keyProp="id"
                                valueProp="tanim"
                                removefonk={handleRemoveMagazaById}
                                loading={props.loading}
                                error={ERROR?.magazalar ? ERROR.magazalar[0] : null}
                                newPath="tanimlar/magaza"
                                ustId={false}
                              />
                            </li>

                            <li className={classes.layoutFormItemBox} id="kullanici_gruplari">
                              <MultipleSelectList
                                headername="CARİ/ÜYE GRUPLARI"
                                name="Cari/Üye Grupları"
                                name2="Cari/Üye Grup"
                                form={props.forms}
                                list={FORM?.kullanici_gruplari ?? []}
                                listlength={FORM?.kullanici_gruplari?.length ?? 0}
                                opendialog={setOpenSelectorGrup}
                                keyProp="id"
                                valueProp="name"
                                removefonk={handleRemoveGrupById}
                                loading={props.loading}
                                error={ERROR?.kullanici_gruplari ? ERROR.kullanici_gruplari[0] : null}
                                newPath="tanimlar/kullanici_grup"
                                ustId={false}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  )}
                </LayoutDetailVerticalTab>
                {openSelectorMagaza &&
                  <Dialog
                    open={openSelectorMagaza}
                    PaperProps={{
                      style: {
                        width: '480px',
                        height: '600px',
                      },
                    }}
                    onClose={() => setOpenSelectorMagaza(false)}
                  >
                    <DialogContent style={{ padding: 0 }}>
                      <ListSelector
                        title='Mağazalar'
                        listPath='magaza/mini_list?aktif=true'
                        listCountPath='magaza/count?aktif=true'
                        keyProp='id'
                        valueProp='tanim'
                        defaultItems={FORM.magazalar}
                        onChangeItems={(items) => handleChangeForm('magazalar', items)}
                        onClose={() => setOpenSelectorMagaza(false)}
                        newPath='tanimlar/magaza'
                      />
                    </DialogContent>
                  </Dialog>
                }
                {openSelectorGrup &&
                  <Dialog
                    open={openSelectorGrup}
                    PaperProps={{
                      style: {
                        width: '480px',
                        height: '600px',
                      },
                    }}
                    onClose={() => setOpenSelectorGrup(false)}
                  >
                    <DialogContent style={{ padding: 0 }}>
                      <ListSelector
                        title='Cari/Üye Grupları'
                        listPath='kullanici-grup'
                        listCountPath='kullanici-grup/count'
                        keyProp='id'
                        valueProp='name'
                        defaultItems={FORM.kullanici_gruplari}
                        onChangeItems={(items) => handleChangeForm('kullanici_gruplari', items)}
                        onClose={() => setOpenSelectorGrup(false)}
                        newPath='tanimlar/kullanici_grup'
                      />
                    </DialogContent>
                  </Dialog>
                }
              </>
            </div>
          )}
        </>
      }
    </>
  );
});

export default FiyatTanimDetailTabDetay;
