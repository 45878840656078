import React, { useMemo } from 'react';

import {
  TextField,
  Tooltip,
} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
};

const KampantaDetailTabHareket = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const handleChangeYuzde = (value) => {
    const validValue = value.split('+').map(x => x.replace(/^(-)|[^0-9.,]+/g, '').replace(',', '.')).join('+');
    props.onChangeForm(validValue);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.layoutContainer}>
              <ul className={classes.layoutFormList}>
                <li className={classes.layoutFormItem} id="yuzde">
                  <label className={classes.layoutFormItemLabel} htmlFor="yuzde">Yüzde</label>
                  <Tooltip title={props.hareket == null ? '' : props.hareket}>
                    <TextField
                      name='yuzde'
                      variant='outlined'
                      size='small'
                      value={props.hareket || ""}
                      fullWidth={true}
                      onChange={(e) => handleChangeYuzde(e.target.value)}
                    ></TextField>
                  </Tooltip>
                </li>
              </ul>
            </div>
          )}
        </>
      }
    </>
  );
});

export default KampantaDetailTabHareket;
