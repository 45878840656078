import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
  has as _has,
  assign as _assign
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './OdemeServisDetailTab_Genel';
import TabBinkod from './OdemeServisDetailTab_Binkod';

const PATH = 'odemeservis';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'BİN KODU', disabled: false, hasError: false },
];

const SERVIS_ALANLAR = [
  { key: "0", value: "Yapı Kredi", tip: "0", tanimlar: {} },
  { key: "1", value: "Garanti", tip: "0", tanimlar: {} },
  { key: "2", value: "Akbank", tip: "0", tanimlar: {} },
  { key: "4", value: "Halk Bank", tip: "0", tanimlar: {} },
  { key: "5", value: "Hsbc", tip: "0", tanimlar: {} },
  { key: "6", value: "Deniz Bank", tip: "0", tanimlar: {} },
  { key: "7", value: "Teb", tip: "0", tanimlar: {} },
  { key: "8", value: "İş Bankası", tip: "0", tanimlar: {} },
  { key: "9", value: "Ing Bank", tip: "0", tanimlar: {} },
  { key: "10", value: "Ziraat Bankası", tip: "0", tanimlar: {} },
  { key: "11", value: "Vakıf Bankası", tip: "0", tanimlar: {} },
  { key: "12", value: "Paynet", tip: "1", tanimlar: {} },
  { key: "13", value: "Ipara", tip: "1", tanimlar: {} },
  { key: "14", value: "Payu", tip: "1", tanimlar: {} },
  { key: "15", value: "Iyzico", tip: "1", tanimlar: {} },
  { key: "16", value: "Kuveyt Türk", tip: "0", tanimlar: {} },
  { key: "17", value: "QNB Finans Bank", tip: "0", tanimlar: { KullaniciAdi: "", Sifre: "", MagazaKodu: "", UcDAnahtari: "", ParaBirimi: "", UcDTipi: "", TerminalNo: "" } },
  { key: "18", value: "PayTR", tip: "1", tanimlar: { merchant_id: "", merchant_key: "", merchant_salt: "" } },
];

const FORM_DEFAULT = {
  tanim: '',
  tanimlar: {},
  odeme_kurum: '-1',
  para_birimi: '-1',
  servis_tipi: '0',
  test: true,
};

const FORM_BINCODE = {
  form: {
    bincode: "",
    debit: true,
    ticari: true,
    aktif: true,
  },
  errors: null
}

const OdemeServisDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingBincode, setLoadingBincode] = useState(true);
  const [hataGenel, setHataGenel] = useState(null);
  const [hataBincode, setHataBincode] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [binCodeSelectedTabIndex, setBinCodeSelectedTabIndex] = useState(0);
  const [binCodes, setBinCodes] = useState([]);
  const [binCodesDefault, setBinCodesDefault] = useState([]);
  const [binCodeAdd, setBinCodeAdd] = useState(true);

  const [odemeKurum, setOdemeKurum] = useState([]);
  const [odemeKurumDic, setOdemeKurumDic] = useState({});
  const [paraBirim, setParaBirim] = useState([]);
  const [paraBirimDic, setParaBirimDic] = useState({});
  const [servisTip, setServisTip] = useState([]);
  const [servisTipDic, setServisTipDic] = useState({});
  const [evrakTip, setEvrakTip] = useState([]);
  const [evrakTipDic, setEvrakTipDic] = useState({});
  const [odemeTanim, setOdemeTanim] = useState({ hedef_evrak_tip: "0", odeme_3d_bicim: "0" });

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteId, setDeleteIdDetail] = useState(null);
  const [isDeletingDetail, setIsDeletingDetail] = useState(false);
  const [isDeletingErrorMessageDetail, setIsDeletingErrorMessageDetail] = useState(null);
  const [binCodeShowDeleteDialogDetail, setBinCodeShowDeleteDialogDetail] = useState(false);

  const [type, setType] = useState(props.eventType ?? 0);
  const [loadDone, setLoadDone] = useState(false);
  const [loadTab1, setLoadTab1] = useState(false);
  const [loadTab2, setLoadTab2] = useState(false);
  const [equal1, setEqual1] = useState(true);
  const [equal2, setEqual2] = useState(true);
  const [saveErrors, setSaveErrors] = useState([[], []]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(binCodes, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualBinCodes = _isEqual(binCodes, binCodesDefault);
    return isEqualGenel && isEqualBinCodes;
  }, [add, genelForm, genelFormDefault, binCodes, binCodesDefault]);

  useLayoutEffect(() => {
    setAdd(false);
    setLoadDone(false);
  }, [ID]);

  useLayoutEffect(() => {
    if (loadDone) {
      setType(2);
    } else {
      setType(props.eventType ?? 0);
    }
  }, [props.eventType, loadDone]);

  useLayoutEffect(() => {
    if (type === 0) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setBinCodes([]);
      setBinCodesDefault([]);
      setTabs(TABS_DEFAULT);
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          setLoadTab2(true);
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else if (type === 1) {
      setGenelForm(null);
      setGenelFormDefault(null);
      if (!saveErrors[1].includes("err")) {
        setBinCodes([]);
        setBinCodesDefault([]);
      }
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          if (!saveErrors[1].includes("err")) {
            setLoadTab2(true);
          }
          break;
        default:
          break;
      }      
      setLoadDone(true);
    } else {
      if (!add) {
        switch (selectedTabIndex) {
          case 0:
            if (equal1) {
              setLoadTab1(true);
            }
            break;
          case 1:
            if (equal2) {
              setLoadTab2(true);
            }
            break;
          default:
            break;
        }
      } else {
        setLoading(false);
        setLoadingBincode(false);
        setLoadTab1(false);
        setLoadTab2(false);
      }
    }
  }, [type, selectedTabIndex, saveErrors, equal1, equal2, add]);

  useLayoutEffect(() => {
    if (add) {
      setEqual1(_isEqual(genelForm, FORM_DEFAULT));
    } else {
      setEqual1(_isEqual(genelForm, genelFormDefault));
    }
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual2(_isEqual(binCodes, []));
    } else {
      setEqual2(_isEqual(binCodes, binCodesDefault));
    }
  }, [add, binCodes, binCodesDefault]);

  useLayoutEffect(() => {
    if (loadTab1) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              tanimlar: data.tanimlar,
              odeme_kurum: String(data.odeme_kurum.key),
              servis_tipi: String(data.servis_tipi.key),
              para_birimi: String(data.para_birimi.key),
              test: data.test,
            };
            setGenelForm(form);
            setGenelFormDefault(form);
            setHataGenel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setLoadTab1(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DEFAULT);
        setGenelFormDefault(FORM_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
        setLoadTab1(false);
      }
    }
  }, [ID, IS_ADD, loadTab1]);

  useLayoutEffect(() => {
    if (loadTab2) {
      setLoadingBincode(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/binkodlar`).then(({ data }) => {
            const forms = data.map((bin) => (
              {
                form: {
                  id: bin.id,
                  bincode: bin.bincode,
                  debit: bin.debit,
                  ticari: bin.ticari,
                  aktif: bin.aktif,
                },
                errors: null
              }
            ));
            setBinCodesDefault(forms);
            setBinCodes(forms);
            setHataBincode(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataBincode(true);
              }
            }
          }).finally(() => {
            setLoadingBincode(false);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        }
      } else {
        setBinCodes([]);
        setBinCodesDefault([]);
        setHataBincode(null);
        setLoadingBincode(false);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, loadTab2]);

  useLayoutEffect(() => {
    django('odemeservis/servis_tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setServisTipDic(dict);
      setServisTip(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    // django('odemeservis/odeme_kurum').then(({ data }) => {
    //   const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
    //   setOdemeKurumDic(dict);
    //   setOdemeKurum(data)
    // });
    const dict = Object.assign({}, ...SERVIS_ALANLAR.map((x) => ({ [x.key]: x.value })));
    setOdemeKurumDic(dict);
    setOdemeKurum(SERVIS_ALANLAR);
  }, []);

  useLayoutEffect(() => {
    django('evrak/evrak_tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setEvrakTipDic(dict);
      setEvrakTip(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django('odemeservis/para_birimi').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setParaBirimDic(dict);
      setParaBirim(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, property2, value) => {
    if (property2 !== '') {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next[property][property2] = value;
        });
      });
    } else {
      if (property === "odeme_kurum") {
        const tanimlar = SERVIS_ALANLAR.find(x => x.key === value);
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next[property] = value;
            next["tanimlar"] = tanimlar.tanimlar;
          });
        });
      } else if (property === "servis_tipi") {
        const tanimlar = SERVIS_ALANLAR.find(x => x.key === (value === "0" ? "0" : "12"));
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next[property] = value;
            next["odeme_kurum"] = value === "0" ? "0" : "12";
            next["tanimlar"] = tanimlar.tanimlar;
          });
        });
      } else {
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next[property] = value;
          });
        });
      }
    }
  };

  const handleOdemeTanimChangeForm = (property, value) => {
    setOdemeTanim((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  }

  const handleBinCodeChangeForm = (index, property, value) => {
    setBinCodes((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      });
    });
  };

  const handleBinCodeAddNewTab = () => {
    setBinCodeAdd(false);
    setBinCodes((prev) => immer(prev, (next) => {
      next.push({ ...FORM_BINCODE });
    }));
  }

  const handleAdd = () => {
    setAdd(true);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setBinCodes([]);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
    setLoadTab1(true);
    setLoadTab2(true);
    setBinCodeAdd(true);
    setTabs(TABS_DEFAULT);
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    setBinCodes(binCodesDefault);
    setBinCodeAdd(true);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
      });
    });
  };

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Ödeme Servis Tanım', pagename: 'ödeme servis', prop2: '' },
      { prop: 'odeme_kurum', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'para_birimi', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'servis_tipi', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ]

    const keys = Object.keys(genelForm.tanimlar);
    if (keys.length > 0) {
      for (let k of keys) {
        list.push({ prop: 'tanimlar', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: k })
      }
    }
    let errors = [];
    await checkAllList('odemeservis', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {
    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (add ? _isEqual(genelForm, FORM_DEFAULT) : _isEqual(genelForm, genelFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            const data = { ...genelForm, odeme_kurum: Number(genelForm.odeme_kurum), para_birimi: Number(genelForm.para_birimi), odeme_servis: Number(genelForm.odeme_servis) }
            reqopt = {
              data: data,
              method: add ? 'POST' : 'PUT',
              id: null,
              successMessage: `Ödeme servis ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Ödeme servis ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }
    const createRequestOptionsTabListe = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = binCodesDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);

      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'bincode', prop2: '' },
        ];
        await checkAllTabs(next.form, (err) => handleErrorsDetail(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const form = { ...next.form };
            if (isHas) {
              reqopt = {
                data: form,
                method: 'PUT',
                id: next.form.id,
                successMessage: `${form.bincode} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${form.bincode} güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: form,
                method: 'POST',
                id: null,
                successMessage: `${form.bincode} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${form.bincode} eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    let errors = [[], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'bincode', request: [], error: [] },
      ];
      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in binCodes) {
        const codestate = [...binCodes];
        const res = await createRequestOptionsTabListe(codestate[i], i);
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "odemeservis" : `odemeservis/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.odeme_servis.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }

            if (tab.name === 'bincode') {
              setBinCodes((prev) => {
                return immer(prev, (next) => {
                  next[requestIndex].errors = null;
                })
              });
              if (SAVEID !== null) {
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `odemeservis/${SAVEID}/binkodlar/${request.id}` : `odemeservis/${SAVEID}/binkodlar`
                }).then(() => {
                  setBinCodeAdd(true);
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setBinCodeAdd(false);
                  setSelectedTabIndex(tab.index);
                  setBinCodeSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setBinCodes((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }
    await start();
    if (SAVEID !== null) {
      if (!add) {
        if (!errors[0].includes("err")) {
          setLoadTab1(true);
        }
        if (!errors[1].includes("err")) {
          setLoadTab2(true);
        }
      } else {
        setSaveErrors(errors);
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
      await checkAll();
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? false : true : true;
        next[1].hasError = !errors[1].includes("err") ? false : true;
      });
    });
  };

  const handleSaveOdemeTanim = async () => {
    let requestoptions = {
      config: {
        method: 'GET',
        url: `odemeservis/servisten_odemeleri_olustur/${ID}/${odemeTanim.hedef_evrak_tip}/${odemeTanim.odeme_3d_bicim}/`,
      },
      successMessage: `Ödeme tanım ekleme başarıyla tamamlandı`,
      errorMessageUnexpected: `Ödeme tanım eklenirken beklenmeyen bir hata oluştu`,
    }
    await django(requestoptions.config).then(({ data }) => {
      enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
    }).catch((error) => {
      enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
    });
  }

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.odeme_servis.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDesiDeleteDetail = () => {
    setIsDeletingDetail(true);
    setIsDeletingErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/binkodlar/${detailDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setBinCodeShowDeleteDialogDetail(false);
        setIsDeletingErrorMessageDetail(null);
      } else {
        setIsDeletingErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingDetail(false);
      setLoadTab2(true);
    });
  }

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      let obj = {}
      let next = {};
      if (Object.keys(errors)[0] === 'tanimlar') {
        if (_has({ ...prev }, Object.keys(errors)[0])) {
          _assign(prev[Object.keys(errors)[0]], errors[Object.keys(errors)[0]])
          obj = { ...prev }
        } else {
          obj = { ...prev, ...errors }
        }
      } else {
        obj = { ...prev, ...errors }
      }

      if (_has(obj, "tanimlar")) {
        const tanimlar = _pickBy(obj["tanimlar"], _identity);
        if (_isEmpty(tanimlar)) {
          next = _pickBy({ ...obj, 'tanimlar': null }, _identity);
        } else {
          next = _pickBy({ ...obj, 'tanimlar': tanimlar }, _identity);
        }
      } else {
        next = _pickBy(obj, _identity);
      }
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsDetail = (errors, index) => {
    setBinCodes((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            odemeKurum={odemeKurum}
            odemeKurumDic={odemeKurumDic}
            paraBirim={paraBirim}
            paraBirimDic={paraBirimDic}
            servisTip={servisTip}
            servisTipDic={servisTipDic}
            evrakTip={evrakTip}
            evrakTipDic={evrakTipDic}
            onChangeForm={handleGenelChangeForm}

            odemeTanim={odemeTanim}
            onChangeFormOdeme={handleOdemeTanimChangeForm}
            onSave={handleSaveOdemeTanim}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}
        {selectedTabIndex === 1 && (
          <TabBinkod
            loading={loadingBincode}
            hata={hataBincode}

            selectedTabIndex={binCodeSelectedTabIndex}
            forms={binCodes}
            onChangeForm={handleBinCodeChangeForm}
            onAddNewTab={handleBinCodeAddNewTab}
            binCodeAdd={binCodeAdd}

            setDeleteId={setDeleteIdDetail}
            openDeleteForm={setBinCodeShowDeleteDialogDetail}

            onCheckErrors={(errors, index) => handleErrorsDetail(errors, index)}
          />
        )}
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Ödeme servis siliniyor lütfen bekleyin'
                : 'Bu ödeme servisi gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
      {binCodeShowDeleteDialogDetail &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetail
              ? isDeletingErrorMessageDetail
              : isDeletingDetail
                ? 'Binkod siliniyor lütfen bekleyin'
                : 'Bu binkodu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetail}
          onSubmit={handleDesiDeleteDetail}
          onCancel={() => setBinCodeShowDeleteDialogDetail(false)}
        ></DialogConfirm>
      }
    </>
  );
};

export default OdemeServisDetail;

