import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  Tooltip
} from '@material-ui/core';

import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import { handleInputErrors } from '../../base/InputErrors';
import MultipleSelectList from '../../../components/MultipleSelectList';
import ListSelector from '../../../components/ListSelector';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },

    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },

    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      position: 'relative',
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
  };
};

const SablonDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const [openSelectorSite, setOpenSelectorSite] = useState(false);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const handleRemoveSiteById = (id) => {
    const copySablonSiteler = [...props.form.site];
    const index = copySablonSiteler.findIndex((c) => c.id === id);
    if (index > -1) {
      copySablonSiteler.splice(index, 1);
      props.onChangeForm('site', copySablonSiteler);
    }
  };

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="tanim">
                    <label className={classes.layoutFormItemLabel} htmlFor="tanim">Şablon Paket Tanım *</label>
                    <Tooltip title={props.error?.tanim ? props.error.tanim[0] :_isNil(props.form?.tanim) ? '' : props.form.tanim}>
                      <TextField
                        {...createTextFieldProps('tanim')}
                        onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                        onBlur={() => handleInputErrors('sablon_paket', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Şablon Tanım', 'şablon')}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="yayina_baslama_zamani">
                    <label className={classes.layoutFormItemLabel} htmlFor="yayina_baslama_zamani">Yayına Başlama Zamanı</label>
                    <Tooltip title={props.error?.yayina_baslama_zamani ? props.error.yayina_baslama_zamani[0] : _isNil(props.form?.yayina_baslama_zamani) ? '' : moment(props.form.yayina_baslama_zamani).format('DD/MM/YYYY, HH.mm')}>
                      <TextField
                        {...createTextFieldProps('yayina_baslama_zamani')}
                        onChange={(e) => props.onChangeForm('yayina_baslama_zamani', e.target.value)}
                        type="datetime-local"
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="yayini_bitirme_zamani">
                    <label className={classes.layoutFormItemLabel} htmlFor="yayini_bitirme_zamani">Yayını Bitirme Zamanı</label>
                    <Tooltip title={props.error?.yayini_bitirme_zamani ? props.error.yayini_bitirme_zamani[0] : _isNil(props.form?.yayini_bitirme_zamani) ? '' : moment(props.form.yayini_bitirme_zamani).format('DD/MM/YYYY, HH.mm')}>
                      <TextField
                        {...createTextFieldProps('yayini_bitirme_zamani')}
                        onChange={(e) => props.onChangeForm('yayini_bitirme_zamani', e.target.value)}
                        type="datetime-local"
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="aktif">
                    <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                    <Checkbox
                      name="aktif"
                      color="primary"
                      checked={_result(props.form, 'aktif', false)}
                      onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                    ></Checkbox>
                  </li>
                  <li className={classes.layoutFormItem} id="varsayilan">
                    <label className={classes.layoutFormItemLabel} htmlFor="varsayilan">Varsayılan</label>
                    <Checkbox
                      name="varsayilan"
                      color="primary"
                      checked={_result(props.form, 'varsayilan', false)}
                      onChange={(e, checked) => props.onChangeForm('varsayilan', checked)}
                    ></Checkbox>
                  </li>
                </ul>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItemBox} id="site">
                    <MultipleSelectList
                      headername="SİTELER *"
                      name="Siteler"
                      name2="Site"
                      form={props.form}
                      list={props?.form?.site ?? []}
                      listlength={props?.form?.site?.length ?? 0}
                      opendialog={setOpenSelectorSite}
                      keyProp="id"
                      valueProp="name"
                      removefonk={handleRemoveSiteById}
                      loading={props.loading}
                      error={props.error?.site ? props.error.site[0] : null}
                      newPath="tanimlar/site"
                      ustId={false}
                    />
                  </li>
                </ul>
              </div>
              {openSelectorSite &&
                <Dialog
                  open={openSelectorSite}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorSite(false);
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'site', true, false, false, '', '', '')
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Siteler'
                      listPath='site/mini_list?aktif=true'
                      listCountPath='site/count?aktif=true'
                      keyProp='id'
                      valueProp='name'
                      defaultItems={props.form.site}
                      onChangeItems={(items) => props.onChangeForm('site', items)}
                      onClose={() => {
                        setOpenSelectorSite(false);
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'site', true, false, false, '', '', '')
                      }}
                      newPath='tanimlar/site'
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default SablonDetailTabGenel;
