import React, { useState, useEffect, useRef } from 'react';
import { SnackbarProvider } from 'notistack';

import { Button } from '@material-ui/core';

import Routes from './Routes';
import { LanguageProvider } from './locale/LanguageProvider';
import UIContext from './contexts/UIContext';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [context, setContext] = useState({
    sideBarStatus: false
  });

  const snackbarRef = useRef();

  const handleDismissSnackbar = (key) => () => { 
    snackbarRef.current.closeSnackbar(key);
  };

  useEffect(() => {
    dummyAsyncCall().then(() => setLoading(false));
  }, [loading]);

  if (loading) {
    return null;
  }

  return (
    <SnackbarProvider
      ref={snackbarRef}
      disableWindowBlurListener={true}
      autoHideDuration={3000}
      action={(key) => (
        <Button
          onClick={handleDismissSnackbar(key)}
          style={{
            color: 'rgba(255, 255, 255, .9)',
          }}
        >KAPAT</Button>
      )}
      maxSnack={3}
    >
      <UIContext.Provider value={[context, setContext]}>
        <LanguageProvider>
          <Routes/>
        </LanguageProvider>
      </UIContext.Provider>
    </SnackbarProvider>
  );
};

function dummyAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 0));
};

export default App;
