import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },
    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
};

const CariDetailTab_BankaHesaplari = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.defaultSelectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((bankaHesabi) => {
        return {
          label: bankaHesabi.form.banka_hesap_bilgi.hesap_adi,
          hasError: bankaHesabi.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);

  useLayoutEffect(() => {
    if (!props.hesaplarAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.hesaplarAdd, COUNT_TABS]);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(FORM?.banka_hesap_bilgi, `${name2}`) || defaultValue,
        error: _has(ERROR?.banka_hesap_bilgi, `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(FORM, `${name}`) || defaultValue,
        error: _has(ERROR, `${name}`),
        fullWidth: true,
      };
    }
  }, [FORM, ERROR]);

  const handleChangeForm = (property, property2, value) => {
    props.onChangeForm(selectedTabIndex, property, property2, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <LayoutDetailVerticalTab
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                add={props.onAddNewTab}
                buttonText={"YENİ HESAP EKLE"}
                buttonShow={props.hesaplarAdd}
                delete={handleDetailDelete}
              >
                {COUNT_TABS === 0 && (
                  <div className="flex flex-col items-start justify-start p-4 space-y-4">
                    <Typography variant="body1">
                      Cariye ait bir banka hesabı bulunamadı.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      onClick={props.onAddNewTab}
                    >YENİ HESAP EKLE</Button>
                  </div>
                )}
                {COUNT_TABS > 0 &&
                  <>
                    <div className={classes.tabDiv}>
                      <div className={classes.column}>
                        <ul className={classes.formList}>
                          <li className={classes.formItem} id="hesap_adi">
                            <label className={classes.formItemLabel} htmlFor="hesap_adi">Hesap Adı *</label>
                            <Tooltip title={ERROR?.banka_hesap_bilgi?.hesap_adi ? ERROR.banka_hesap_bilgi.hesap_adi[0] : _isNil(FORM?.banka_hesap_bilgi?.hesap_adi) ? '' : FORM.banka_hesap_bilgi.hesap_adi}>
                              <TextField
                                {...createTextFieldProps('banka_hesap_bilgi', 'hesap_adi')}
                                onChange={(e) => handleChangeForm('banka_hesap_bilgi', 'hesap_adi', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'banka_hesap_bilgi', handleSetErrors, 'hesap_adi')}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="banka_adi">
                            <label className={classes.formItemLabel} htmlFor="banka_adi">Banka Adı</label>
                            <Tooltip title={ERROR?.banka_hesap_bilgi?.banka_adi ? ERROR.banka_hesap_bilgi.banka_adi[0] : _isNil(FORM?.banka_hesap_bilgi?.banka_adi) ? '' : FORM.banka_hesap_bilgi.banka_adi}>
                              <TextField
                                {...createTextFieldProps('banka_hesap_bilgi', 'banka_adi')}
                                onChange={(e) => handleChangeForm('banka_hesap_bilgi', 'banka_adi', e.target.value)}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="sube">
                            <label className={classes.formItemLabel} htmlFor="sube">Şube</label>
                            <Tooltip title={ERROR?.banka_hesap_bilgi?.sube ? ERROR.banka_hesap_bilgi.sube[0] : _isNil(FORM?.banka_hesap_bilgi?.sube) ? '' : FORM.banka_hesap_bilgi.sube}>
                              <TextField
                                {...createTextFieldProps('banka_hesap_bilgi', 'sube')}
                                onChange={(e) => handleChangeForm('banka_hesap_bilgi', 'sube', e.target.value)}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="sube_kodu">
                            <label className={classes.formItemLabel} htmlFor="sube_kodu">Şube Kodu</label>
                            <Tooltip title={ERROR?.banka_hesap_bilgi?.sube_kodu ? ERROR.banka_hesap_bilgi.sube_kodu[0] : _isNil(FORM?.banka_hesap_bilgi?.sube_kodu) ? '' : FORM.banka_hesap_bilgi.sube_kodu}>
                              <TextField
                                {...createTextFieldProps('banka_hesap_bilgi', 'sube_kodu')}
                                onChange={(e) => handleChangeForm('banka_hesap_bilgi', 'sube_kodu', e.target.value)}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="hesap_no">
                            <label className={classes.formItemLabel} htmlFor="hesap_no">Hesap No</label>
                            <Tooltip title={ERROR?.banka_hesap_bilgi?.hesap_no ? ERROR.banka_hesap_bilgi.hesap_no[0] : _isNil(FORM?.banka_hesap_bilgi?.hesap_no) ? '' : FORM.banka_hesap_bilgi.hesap_no}>
                              <TextField
                                {...createTextFieldProps('banka_hesap_bilgi', 'hesap_no')}
                                onChange={(e) => handleChangeForm('banka_hesap_bilgi', 'hesap_no', e.target.value)}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="iban">
                            <label className={classes.formItemLabel} htmlFor="iban">IBAN</label>
                            <Tooltip title={ERROR?.banka_hesap_bilgi?.iban ? ERROR.banka_hesap_bilgi.iban[0] : _isNil(FORM?.banka_hesap_bilgi?.iban) ? '' : FORM.banka_hesap_bilgi.iban}>
                              <TextField
                                {...createTextFieldProps('banka_hesap_bilgi', 'iban')}
                                onChange={(e) => handleChangeForm('banka_hesap_bilgi', 'iban', e.target.value)}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="para_birim">
                            <label className={classes.formItemLabel} htmlFor="para_birim">Para Birimi *</label>
                            <Tooltip title={ERROR?.para_birim ? ERROR.para_birim[0] : (FORM?.para_birim === "-1" || _isNil(FORM?.para_birim)) ? '' : props.paraBirimleriDic ? props.paraBirimleriDic[FORM.para_birim] : ''}>
                              <TextField
                                {...createTextFieldProps('para_birim', '')}
                                onChange={(e) => handleChangeForm('para_birim', '', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'para_birim', handleSetErrors)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                <option value='-1' disabled>Seçilmedi</option>
                                {props.paraBirimleri?.map((pb) => (
                                  <option key={pb.id} value={pb.id}>{pb.tanim}</option>
                                ))}
                              </TextField>
                            </Tooltip>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                }
              </LayoutDetailVerticalTab>
            </div>
          )}
        </>
      }
    </>
  );
});

export default CariDetailTab_BankaHesaplari;
