import URLS from 'urls';

const sideBarItems = [
  {
    label: 'Tanımlar', items: [
      {
        label: 'Genel Tanımlar', items: [
          { label: 'Banka Hesapları', to: URLS.tanimlar.bankahesap.list },
          { label: 'Depolar', to: URLS.tanimlar.depo.list },
          { label: 'Diller', to: URLS.tanimlar.dil.list },
          { label: 'Ek Alanlar', to: URLS.tanimlar.ekalan.list },
          { label: 'Evrak Durumları', to: URLS.evrak.evrakdurumtanim.list },
          { label: 'Fiyat Listeleri', to: URLS.tanimlar.fiyat_tanim.list },
          { label: 'Kargolar', to: URLS.tanimlar.kargo.list },
          { label: 'Kurlar', to: URLS.tanimlar.kurlar.list },
          { label: 'Mağazalar', to: URLS.tanimlar.magaza.list },
          { label: 'Para Birimleri', to: URLS.tanimlar.para_birimleri.list },
          { label: 'Seri ve No Tanımları', to: URLS.tanimlar.kodseri.list },
          { label: 'Ülke/Şehir/İlçe', to: URLS.tanimlar.ulkesehirilce.list },
          { label: 'Vergiler', to: URLS.tanimlar.vergiler.list },
        ]
      },
      {
        label: 'Stok Kartı Tanımları', items: [
          { label: 'Birimler', to: URLS.tanimlar.birim.list },
          { label: 'Satış Birim Grupları', to: URLS.tanimlar.satisbirimgrup.list },
          { label: 'Stok Özellik Tanımları', to: URLS.tanimlar.ozellik.list },
          { label: 'Ürün Kartları', to: URLS.tanimlar.urun.list },
          { label: 'Ürün Grupları', to: URLS.tanimlar.grup.list },
          { label: 'Varyant Grupları', to: URLS.tanimlar.varyant.list },
          { label: 'Kategoriler', to: URLS.tanimlar.kategori.list },
        ]
      },
      {
        label: 'Cari/Üye Tanımları', items: [
          { label: 'Cariler', to: URLS.tanimlar.cari.list },
          { label: 'Üyeler', to: URLS.tanimlar.uye.list },
          { label: 'Departmanlar', to: URLS.tanimlar.departman.list },
          { label: 'Üye/Cari Grupları', to: URLS.tanimlar.kullanici_grup.list },
        ]
      },
    ]
  },
  {
    label: 'Alış İşlemleri', items: [
      { label: 'Alış Ön Siparişler', to: URLS.evrak.alisonsiparis.list },
      { label: 'Alış Ön Siparişi Onayla', to: URLS.evrak.alisonsiparisonay.list },
      { label: 'Alış Siparişleri', to: URLS.evrak.alissiparis.list },
      { label: 'Alış İrsaliyeleri', to: URLS.evrak.alisirsaliye.list },
      { label: 'Alış İrsaliyesinden Fatura', to: URLS.evrak.alisirsaliyedenfatura.list },
      { label: 'Alış Siparişinden Fatura', to: URLS.evrak.alissiparistenfatura.list },
      { label: 'Alış Siparişinden İrsaliye', to: URLS.evrak.alissiparistenirsaliye.list },
      { label: 'Alış Faturaları', to: URLS.evrak.alisfatura.list },
      { label: 'Satıştan İade İrsaliyeleri', to: URLS.evrak.satisiadeirsaliye.list },
      { label: 'Satıştan İade Faturaları', to: URLS.evrak.satisiadefatura.list },
    ]
  },
  {
    label: 'Satış İşlemleri', items: [
      { label: 'Satış Ön Siparişler', to: URLS.evrak.satisonsiparis.list },
      { label: 'Satış Ön Siparişi Onayla', to: URLS.evrak.satisonsiparisonay.list },
      { label: 'Satış Siparişleri', to: URLS.evrak.satissiparis.list },
      { label: 'Satış Siparişinden Sevk Emri', to: URLS.evrak.satissiparistensevkemri.list },
      { label: 'Satış Siparişinden İrsaliye', to: URLS.evrak.satissiparistenirsaliye.list },
      { label: 'Satış Siparişinden Fatura', to: URLS.evrak.satissiparistenfatura.list },
      { label: 'Depo Sevk Emirleri', to: URLS.evrak.sevkemri.list },
      { label: 'Sevk Emrinden Satış İrsaliyesi', to: URLS.evrak.sevkemrindensatisirsaliye.list },
      { label: 'Sevk Emrinden Satış Faturası', to: URLS.evrak.sevkemrindensatisfatura.list },
      { label: 'Satış İrsaliyeleri', to: URLS.evrak.satisirsaliye.list },
      { label: 'Satış İrsaliyesinden Fatura', to: URLS.evrak.satisirsaliyedenfatura.list },
      { label: 'Satış Faturaları', to: URLS.evrak.satisfatura.list },
      { label: 'Alış İade İrsaliyeleri', to: URLS.evrak.alisiadeirsaliye.list },
      { label: 'Alış İade Faturaları', to: URLS.evrak.alisiadefatura.list },
    ]
  },
  {
    label: 'Finans İşlemleri', items: [
      { label: 'Sanal Pos Logları', to: URLS.tanimlar.kullanici_odeme_log.list },
      { label: 'Tahsilatlar', to: URLS.evrak.tahsilat.list },
      { label: 'Ödemeler', to: URLS.evrak.odeme.list },
      { label: 'Ödeme Servisleri', to: URLS.tanimlar.odeme_servis.list },
      { label: 'Ödeme Setleri', to: URLS.tanimlar.odeme_set.list },
      { label: 'Ödeme Tanımları', to: URLS.tanimlar.odeme_tanim.list },
    ]
  },
  {
    label: 'Depo İşlemleri', items: [
      { label: 'Depo Giriş', to: URLS.evrak.depogiris.list },
      { label: 'Depo Çıkış', to: URLS.evrak.depocikis.list },
    ]
  },
  {
    label: 'E-Ticaret İşlemleri', items: [
      {
        label: 'İçerik Yönetimi', items: [
          { label: 'Bileşenler', to: URLS.tanimlar.bilesen.list },
          { label: 'Eklentiler', to: URLS.tanimlar.eklenti.list },
          { label: 'Form Tanımları', to: URLS.tanimlar.formtanim.list },
          { label: 'Fotoğraflar', to: URLS.tanimlar.foto.list },
          { label: 'İçerik Şablonları', to: URLS.tanimlar.icerik_sablon.list },
          { label: 'Menüler', to: URLS.tanimlar.menu.list },
          { label: 'Mesajlar', to: URLS.tanimlar.mesaj.list },
          { label: 'Sabit Sayfalar', to: URLS.tanimlar.sabit_sayfa.list },
          { label: 'Sözleşmeler', to: URLS.tanimlar.sozlesme.list },
          { label: 'Temalar ve Tema Ayarları', to: URLS.tanimlar.sablon_paket.list },
          { label: 'Ürün Liste Tanımları', to: URLS.tanimlar.urunlisttanim.list },
        ]
      },
      {
        label: 'Entegrasyonlar', items: [
          { label: 'Api/Xml Entegrasyonları', to: URLS.tanimlar.entegrasyon.list },
        ]
      },
      {
        label: 'Ayarlar', items: [
          { label: 'E-posta Yönetimi', to: URLS.tanimlar.epostaauth.list },
          { label: 'Fotoğraf Ayarları', to: URLS.tanimlar.fotoayar.list },
          { label: 'Google Merchant', to: URLS.tanimlar.gmerchant.list },
          { label: 'Hatırlatıcılar', to: URLS.tanimlar.hatirlatici.list },
          { label: 'Siteler ve Site Ayarları', to: URLS.tanimlar.site.list },
          { label: 'Yönlendirme', to: URLS.tanimlar.redirect.list },
        ]
      },
    ]
  },
  {
    label: 'Yönetim', items: [
      { label: 'Yönetim Kullanıcıları', to: URLS.tanimlar.yonetici.list },
    ]
  },
]

export default sideBarItems;
