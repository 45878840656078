import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  Tooltip
} from '@material-ui/core';

import moment from 'moment';

import ListSelector from '../../../components/ListSelector';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

import { withStyles } from '@material-ui/core/styles';
import MultipleSelectList from '../../../components/MultipleSelectList';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
  };
};

const KampanyaDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);
  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);

  const setValidDate = (prop) => {
    let errors = {};
    if (prop === 'baslangic_tarihi') {
      if (props.form.baslangic_tarihi > props.form.bitis_tarihi) {
        errors[prop] = ['Başlangıç tarihi bitiş tarihinden sonra olamaz.']
      } else {
        errors['bitis_tarihi'] = null;
        errors[prop] = null;
      }
    } else {
      if (props.form.bitis_tarihi < props.form.baslangic_tarihi) {
        errors[prop] = ['Bitiş tarihi başlangıç tarihinden önce olamaz.']
      } else {
        errors['baslangic_tarihi'] = null;
        errors[prop] = null;
      }
    }
    props.onCheckErrors(errors);
  }

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const handleRemoveMagazaById = (id) => {
    const copyKampanyaMagazalar = [...props.form.magazalar];
    const index = copyKampanyaMagazalar.findIndex((c) => c.id === id);
    if (index > -1) {
      copyKampanyaMagazalar.splice(index, 1);
      props.onChangeForm('magazalar', copyKampanyaMagazalar)
    }
  };

  const setGunler = (key, checked) => {
    let copyGunler = [...props.form.hangi_gunler];
    if (checked) {
      copyGunler.push(key);
    } else {
      const index = copyGunler.findIndex((g) => g === key);
      if (index > -1) {
        copyGunler.splice(index, 1);
      }
    }
    props.onChangeForm('hangi_gunler', copyGunler);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="tanim">
                    <label className={classes.layoutFormItemLabel} htmlFor="tanim">Kampanya Tanım *</label>
                    <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                      <TextField
                        {...createTextFieldProps('tanim')}
                        onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                        onBlur={() => handleInputErrors('kampanya', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Kampanya Tanım', 'kampanya')}
                        inputProps={{
                          maxLength: 250,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="baslangic_tarihi">
                    <label className={classes.layoutFormItemLabel} htmlFor="baslangic_tarihi">Başlangıç Tarihi *</label>
                    <Tooltip title={props.error?.baslangic_tarihi ? props.error.baslangic_tarihi[0] : _isNil(props.form?.baslangic_tarihi) ? '' : moment(props.form.baslangic_tarihi).format('DD/MM/YYYY, HH.mm')}>
                      <TextField
                        {...createTextFieldProps('baslangic_tarihi', Date.now())}
                        onChange={(e) => props.onChangeForm('baslangic_tarihi', e.target.value)}
                        type="datetime-local"
                        onBlur={() => setValidDate('baslangic_tarihi')}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="bitis_tarihi">
                    <label className={classes.layoutFormItemLabel} htmlFor="bitis_tarihi">Bitiş Tarihi *</label>
                    <Tooltip title={props.error?.bitis_tarihi ? props.error.bitis_tarihi[0] : _isNil(props.form?.bitis_tarihi) ? '' : moment(props.form.bitis_tarihi).format('DD/MM/YYYY, HH.mm')}>
                      <TextField
                        {...createTextFieldProps('bitis_tarihi', Date.now())}
                        onChange={(e) => props.onChangeForm('bitis_tarihi', e.target.value)}
                        type="datetime-local"
                        onBlur={() => setValidDate('bitis_tarihi')}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="hareket_tip">
                    <label className={classes.layoutFormItemLabel} htmlFor="hareket_tip">Kampanya Tip</label>
                    <Tooltip title={props.error?.hareket_tip ? props.error.hareket_tip[0] : _isNil(props.form?.hareket_tip) ? '' : props.kampanyaTipDic ? props.kampanyaTipDic[props.form.hareket_tip] : ''}>
                      <TextField
                        {...createTextFieldProps('hareket_tip')}
                        onChange={(e) => props.onChangeForm('hareket_tip', e.target.value)}
                        select
                        SelectProps={{ native: true }}
                      >
                        {props?.kampanyaTip.map((tip) => (
                          <option key={tip.key} value={tip.key}>{tip.value}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="siralama">
                    <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                    <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                      <TextField
                        {...createTextFieldProps('siralama')}
                        onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                        type="number"
                        inputProps={{
                          min: -2147483648,
                          max: 2147483647,
                          step: 1,
                          style: { textAlign: 'right' }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="aktif">
                    <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                    <Checkbox
                      name="aktif"
                      color="primary"
                      checked={_result(props.form, 'aktif', false)}
                      onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                    ></Checkbox>
                  </li>
                </ul>

                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItemBox}>
                    <MultipleSelectList
                      headername="MAĞAZALAR"
                      name="Mağazalar"
                      name2="Mağaza"
                      form={props.form}
                      list={props.form?.magazalar ?? []}
                      listlength={props?.form?.magazalar?.length ?? 0}
                      opendialog={setOpenSelectorMagaza}
                      keyProp="id"
                      valueProp="tanim"
                      removefonk={handleRemoveMagazaById}
                      loading={props.loading}
                      error={props.error?.magazalar ? props.error.magazalar[0] : null}
                      newPath="tanimlar/magaza"
                      ustId={false}
                    />
                  </li>
                </ul>

                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItemBox}>
                    <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                      <header className="flex items-center justify-between py-2 px-4">
                        <Button
                          className="pointer-events-none"
                          color="inherit"
                        >GÜNLER</Button>
                      </header>
                      <main className="border-t border-palette-action-hover py-1">
                        {props.haftaGunler?.length > 0 &&
                          <ul className="flex flex-col list-none m-0 p-0">
                            {props.haftaGunler.map((gun) => (
                              <li
                                key={gun.key}
                                className="flex items-center justify-start py-1 pl-4 pr-8 space-x-2"
                              >
                                <Checkbox
                                  name={gun.value}
                                  color="primary"
                                  checked={props.form?.hangi_gunler?.length > 0 ? props.form.hangi_gunler.some((s) => s === gun.key) : false}
                                  onChange={(e, checked) => setGunler(gun.key, checked)}
                                ></Checkbox>
                                <span>{gun.value}</span>
                              </li>
                            ))
                            }
                          </ul>
                        }
                      </main>
                    </section>
                  </li>
                </ul>
              </div>
              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelectorMagaza(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Mağazalar'
                      listPath='magaza/mini_list?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.magazalar}
                      onChangeItems={(items) => props.onChangeForm('magazalar', items)}
                      onClose={() => setOpenSelectorMagaza(false)}
                      newPath='tanimlar/magaza'
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default KampanyaDetailTabGenel;
