import axios from 'axios';
import Store from "../libs/store";
import SwaggerParser from "@apidevtools/swagger-parser";
import URLS from "../urls";
import { parseJwt } from "../helpers/helpers";
import queryString from 'query-string';
import { QUERY_STRING_OPTIONS } from '../constants/options';

let baseURL = '//egandev5.egan.com.tr/api';
//const DEBUG = process.env.NODE_ENV === 'development' && (process.env?.REACT_APP_DJANGO_SERVER === 'local');
const DEBUG = process.env.NODE_ENV === 'development' && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "");

if (DEBUG) {
  baseURL = 'https:' + baseURL;
  //baseURL = 'http://127.0.0.1:8000/api'
}

const apiOptions = {
  baseURL
}

const auth = Store.get('auth');
if (auth && auth.access && auth.refresh) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${auth.access}`;
}

const django = axios.create(apiOptions);

django.CancelToken = axios.CancelToken;
django.isCancel = axios.isCancel;

django.defaults.paramsSerializer = (params) => queryString.stringify(params, QUERY_STRING_OPTIONS);

django.interceptors.request.use((config) => {
  const isHasQuery = config.url.indexOf('?') > -1;
  const isIncludedSlash = config.url.slice(-1) === '/';

  if (!isHasQuery && !isIncludedSlash) {
    return {
      ...config,
      url: config.url + '/',
    };
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

// django.interceptors.response.use(
//   res => res,
//   err => {
//     if (err?.response?.status && [401, 403].includes(err.response.status)) {
//       Store.delete('auth');

//       const target = `${URLS.user.login}?next=${window.location.pathname}`
//       window.location.href = target;
//     } else {
//       throw err;
//     }
//   }
// )

django.interceptors.response.use(
  res => res,
  err => {
    const originalRequest = err.config;
    if (err?.response?.status && [401, 403].includes(err.response.status)) {
      const refresh = Store.get('auth').refresh;
      console.log("StoreRefresh",refresh);
      return django.post('/token/refresh/', { refresh }).then(res => {
        console.log("ApiRefreshCallResponse",res);
        if (res.status === 201) {
          const data = { "access": res.data.refresh, "refresh": refresh }
          Store.set('auth', data);
          axios.defaults.headers.common['Authorization'] = `Bearer ${auth.access}`;
          return axios(originalRequest);
        } else {
          Store.delete('auth');
          const target = `${URLS.user.login}?next=${window.location.pathname}`
          window.location.href = target;
        }
      })
    } else {
      throw err;
    }
  }
)


django.get_schema = async (path, type = 'post') => {
  // TODO: cache set
  let response = null;
  let apiResponse = null;

  // IMPORTANT do not uncomment or delete these lines
  // if (Store.get(`apiSchema`)) {
  //   apiResponse = Store.get(`apiSchema`);
  // } else {
  apiResponse = await django.get('/swagger/?format=openapi', { headers: django.getAuthToken() });
  // Store.set(`apiSchema`, apiResponse)
  // }

  const apiSchema = apiResponse.data;

  const validated_schama = await SwaggerParser.validate(apiSchema);

  if (type === 'post') {
    response = validated_schama.paths[path][type].responses['201'].schema
  }

  if (type === 'put') {
    response = validated_schama.paths[path][type].responses['200'].schema
  }

  return response

}

django.authenticate = (data) => {
  return !!Store.set('auth', data)
}

django.isAuthenticated = () => {
  if (!Store.get('auth')) return false;

  const token = Store.get('auth').access;
  const refreshToken = Store.get('auth').refresh;
  try {
    parseJwt(token);
    const { exp } = parseJwt(refreshToken);
    if (exp < (new Date().getTime() + 1) / 1000) {
      return false;
    }
  } catch (err) {
    return false;
  }
  return true;
}

django.getAuthToken = () => {
  const auth = Store.get('auth');
  if (auth && auth.access && auth.refresh) {
    return { "Authorization": `Bearer ${auth.access}` }
  }
  return {}
}

django.getUser = async () => {
  const response = await django.get('/kullanici/')
  return response.data[0]
}

window.django = django;
export default django;
