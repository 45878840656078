import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  groupBy as _groupBy,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList } from '../../base/InputErrors';

import TabGenel from './OdemeSetDetailTab_Genel';

const PATH = 'odemeset';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  tanim: '',
  min_tutar: '',
  magazalar: [],
  odeme_tanimlari: [],
  odeme_vadeleri: [],
  kullanici_gruplari: [],
  aktif: true,
  siralama: '0',
  odeme_gruplar: [],
  tip: '0',
  ozellik_degerleri: [],
  ozellik_basliklar: [],
  ozellik_tree_list: [],
  yuzdeler: "",
  gruplar: []
};

const groupVade = (list) => {
  const vade_grup_list = [];
  const vade_grup = _groupBy(list, item => item.odeme_tanim_tanim);
  if (Object.keys(vade_grup).length > 0) {
    Object.keys(vade_grup).forEach((v, i) => {
      vade_grup_list.push({
        tanim: v, vadeler: Object.values(vade_grup)[i].map((l) => ({
          id: l.id,
          tanim: l.tanim
        }))
      });
    });
  }
  return vade_grup_list;
}

const createOzellikListeler = (list) => {
  const ozellik_basliklar = [];
  const ozellik_tree_list = [];
  const ozellik_group = _groupBy(list, item => item.ozellik_baslik_tanim);
  if (Object.keys(ozellik_group).length > 0) {
    Object.keys(ozellik_group).forEach((o, i) => {
      ozellik_tree_list.push({
        title: o,
        list: Object.values(ozellik_group)[i].map((l) => ({
          key: l.id,
          value: l.tanim
        }))
      })
    })
  }
  list.forEach((l) => {
    if (ozellik_basliklar.some(p => p.id === l.odeme_tanim_id) === false) {
      ozellik_basliklar.push({ id: l.odeme_tanim_id, tanim: l.ozellik_baslik_tanim })
    }
  });
  return [ozellik_basliklar, ozellik_tree_list];
}

const OdemeSetDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [hataGenel, setHataGenel] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [tip, setTip] = useState([]);
  const [tipDic, setTipDic] = useState({});

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    return isEqualGenel;
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    setLoading(true);
    setTabs(TABS_DEFAULT);
    if (!IS_ADD) {
      const debounce = setTimeout(() => {
        django(`${PATH}/${ID}`).then(({ data }) => {
          const grup = groupVade(data.odeme_vadeleri);
          const res = createOzellikListeler(data.ozellik_degerleri);
          const form = {
            tanim: data.tanim,
            min_tutar: data.min_tutar,
            max_tutar: data.max_tutar,
            magazalar: data.magazalar,
            odeme_tanimlari: data.odeme_tanimlari,
            odeme_vadeleri: data.odeme_vadeleri,
            kullanici_gruplari: data.kullanici_gruplari,
            aktif: data.aktif,
            siralama: String(data.siralama),
            ozellik_degerleri: data.ozellik_degerleri,
            ozellik_basliklar: res[0],
            ozellik_tree_list: res[1],
            odeme_gruplar: grup,
            yuzdeler: data.yuzdeler ? data.yuzdeler.join('+') : '',
            tip: String(data.tip),
            gruplar: data.gruplar
          };
          setGenelFormDefault(form);
          setGenelForm(form);
          setHataGenel(null);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataGenel(true);
            }
          }
        }).finally(() => {
          setLoading(false);
          setGenelError(null);
          setAdd(false);
        });
      }, 300);
      return () => {
        clearTimeout(debounce);
        setLoading(false);
      };
    } else {
      setGenelForm(FORM_DEFAULT);
      setGenelFormDefault(FORM_DEFAULT);
      setGenelError(null);
      setHataGenel(null);
      setAdd(true);
      setLoading(false);
    }
  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    django(`${PATH}/tip/`).then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setTipDic(dict);
      setTip(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    if (property === 'odeme_vadeleri') {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next['odeme_gruplar'] = value;
        });
      });
    } else if (property === "yuzdeler") {
      const str = value;
      let list = str.split('+').map((x) => x.replace(/^(-)|[^0-9.,]+/g, '').replace(',', '.')).join('+');
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next['yuzdeler'] = list;
        });
      });
    } else if (property === "ozellik_degerleri") {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          const res = createOzellikListeler(value);
          next[property] = value;
          next['ozellik_basliklar'] = res[0];
          next['ozellik_tree_list'] = res[1];
        });
      });
    } else {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next[property] = value;
        });
      });
    }
  };

  const handleAdd = () => {
    setAdd(true);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Ödeme Set Tanım', pagename: 'ödeme', prop2: '' },
      { prop: 'min_tutar', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ]
    let errors = [];
    await checkAllList('odemeset', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const handleSave = () => {
    checkAll().then((res) => {
      if (_isEmpty(res)) {
        let requestoptions = {};
        const vadeArr = [];
        genelForm.odeme_gruplar.forEach((item) => {
          item.vadeler.forEach((v) => vadeArr.push(v.id))
        });
        const data = {
          ...genelForm,
          siralama: genelForm.siralama ? Number(genelForm.siralama) : 0,
          magazalar: genelForm.magazalar?.map((data) => data.id),
          odeme_tanimlari: genelForm.odeme_tanimlari?.map((data) => data.id),
          odeme_vadeleri: vadeArr,
          kullanici_gruplari: genelForm.kullanici_gruplari?.map((data) => data.id),
          ozellik_degerleri: genelForm.ozellik_degerleri?.map((m) => m.id),
          tip: Number(genelForm.tip),
          yuzdeler: genelForm.tip === '1' ? genelForm.yuzdeler.split('+').filter((x) => x !== '') : [],
          gruplar: genelForm.gruplar?.map((data) => data.id),
        };
        delete data.odeme_gruplar;
        delete data.ozellik_basliklar;
        delete data.ozellik_tree_list;
        if (IS_EQUAL) {
          requestoptions = null;
        } else {
          requestoptions = {
            config: {
              method: add ? 'POST' : 'PUT',
              url: add ? 'odemeset' : `odemeset/${ID}`,
              data: data
            },
            successMessage: `Ödeme set ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
            errorMessageUnexpected: `Ödeme set ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
          }
        }
        const start = async () => {
          let errorCounts = 0;
          if (requestoptions !== null) {
            setGenelError(null);
            await django(requestoptions.config).then(({ data }) => {
              enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
              if (!add) {
                props.onUpdated();
              }
              if (props.single && add) {
                history.push(URLS.tanimlar.odeme_set.detail(data.id,"detay"));
              }
              if (!props.single && add) {
                props.onAdded(data);
              }
            }).catch((error) => {
              setSelectedTabIndex(selectedTabIndex);
              if (error.response) {
                if (error.response.status === 500) {
                  enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
                } else {
                  setGenelError(error.response.data);
                }
              } else {
                enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
              }
              ++errorCounts;
            });
            if (errorCounts === 0) {
              setLastUpdateTime(Date.now());
            } else {
              setTabs((prev) => {
                return immer(prev, (next) => {
                  next[0].hasError = true;
                });
              });
            }
          }
        }
        start();
      } else {
        setTabs((prev) => {
          return immer(prev, (next) => {
            next[0].hasError = true;
          });
        });
      }
    })
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.odeme_set.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }


  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}
            tip={tip}
            tipDic={tipDic}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Ödeme set siliniyor lütfen bekleyin'
                : 'Bu ödeme seti gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
    </>
  );
}

export default OdemeSetDetail;