import React, { useMemo, useState, useLayoutEffect } from 'react';

import VerticalListX from '../../../components/VerticalListX_List';
import URLS from "urls";

import django from '../../../api/django';

import { useHistory } from "react-router-dom";


import Detail from './Detail';

export const UlkeIndex = (props) => {
  const [ids, setIds] = useState([]);
  const [targets, setTargets] = useState([]);
  const [tanims, setTanims] = useState([]);

  const history = useHistory()

  const params = useMemo(() => props.match.params, [props]);
  const state = useMemo(() => props.location.state, [props]);

  useLayoutEffect(() => {
    const idler = params.id?.split('/') || [];
    setIds(idler);
  }, [params]);

  const handleTargets = (idler) => {
    const targetlar = [];
    idler.forEach((id, index) => {
      if (index === 0) {
        targetlar.push(`${URLS.tanimlar.ulkesehirilce.list}${id}`)
      } else {
        targetlar[index] = `${targetlar[index - 1]}/${id}`
      }
    })
    return targetlar;
  }

  useLayoutEffect(() => {
    const idstates = handleTargets(ids);
    setTargets(idstates);
  }, [ids]);



  useLayoutEffect(() => {
    const handleTitle = async (idler) => {
      let basliklar = [];
      for (let i = 0; i < idler.length; i++) {
        switch (i) {
          case 0:
            await django(`ulke/${ids[0]}`).then(({ data }) => {
              basliklar.push(data.tanim);
            });
            break;
          case 1:
            await django(`ulke/${ids[0]}/sehir/${ids[1]}`).then(({ data }) => {
              basliklar.push(data.tanim);
            });
            break;
          default:
            break;
        }
      }
      setTanims(basliklar);
    }
    handleTitle(ids);
  }, [ids]);

  const HandleClose = (i) => {
    const path = targets[i - 1];
    if (path) {
      history.push(path);
    } else {
      history.push(URLS.tanimlar.ulkesehirilce.list)
    }
  }

  return (
    <>
      <div className="relative w-full h-full flex flex-col rounded bg-palette-background-default border-2 border-palette-action-disabledBackground">
        <nav className="relative w-full h-10 flex items-center justify-between bg-palette-action-hover border-b border-palette-action-selected z-10">
          <div className="flex items-center justify-start space-x-2 px-2">
            <h1 className="flex items-center justify-start m-0">
              <span className="text-sm font-medium leading-none">ÜLKE/ŞEHİR/İLÇE</span>
            </h1>
          </div>
        </nav>
        <div className="relative w-full h-full flex overflow-y-hidden bg-palette-action-hover">
          <VerticalListX
            path="ulke"
            title="Ülke"
            replace_key=":id+"
            target={URLS.tanimlar.ulkesehirilce.detail}
            detail={Detail}
            ustId="null"
            canClose={false}
            onClose={HandleClose}
            emptyText="Tanımlanmış ülke bulunmamaktadır"
            state={state}
            singledetail="tanimlar/ulkesehirilcedetay"
          />
          {ids.length > 0 && ids.length === 1 && (
            <VerticalListX
              key={1}
              path={`ulke/${ids[0]}/sehir`}
              title={tanims[0]}
              target={targets[0]}
              detail={Detail}
              ustId={ids[0]}
              canClose={true}
              onClose={() => HandleClose(0)}
              emptyText="Tanımlanmış şehir bulunmamaktadır"
              state={state}
              singledetail="tanimlar/ulkesehirilcedetay"
            />
          )}
          {ids.length > 0 && ids.length === 2 && (
            <>
              <VerticalListX
                key={1}
                path={`ulke/${ids[0]}/sehir`}
                title={tanims[0]}
                target={targets[0]}
                detail={Detail}
                ustId={ids[0]}
                canClose={true}
                onClose={() => HandleClose(0)}
                emptyText="Tanımlanmış şehir bulunmamaktadır"
                state={state}
                singledetail="tanimlar/ulkesehirilcedetay"
              />
              <VerticalListX
                key={2}
                path={`ulke/${ids[0]}/sehir/${ids[1]}/ilce`}
                title={tanims[1]}
                target={targets[1]}
                detail={Detail}
                ustId={`${ids[0]}-${ids[1]}`}
                canClose={true}
                onClose={() => HandleClose(1)}
                emptyText="Tanımlanmış ilçe bulunmamaktadır"
                link={false}
                state={state}
                singledetail="tanimlar/ulkesehirilcedetay"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

