import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import ListSelector from '../../../components/ListSelector';
import { handleInputErrors } from '../../base/InputErrors';
import MultipleSelectList from '../../../components/MultipleSelectList';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
  };
};

const MagazaDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const [openSelectorMagaza, setOpenSelectorMagaza] = useState(false);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const handleRemoveMagazaById = (id) => {
    const copyMagazalar = [...props.form.magazalar];
    const index = copyMagazalar.findIndex((c) => c.id === id);
    if (index > -1) {
      copyMagazalar.splice(index, 1);
      props.onChangeForm('magazalar', copyMagazalar);
    }
  };

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>

                  <li className={classes.layoutFormItem} id="host">
                    <label className={classes.layoutFormItemLabel} htmlFor="host">Mail Server Adresi *</label>
                    <Tooltip title={props.error?.host ? props.error.host[0] : _isNil(props.form?.host) ? '' : props.form.host}>
                      <TextField
                        {...createTextFieldProps('host')}
                        onChange={(e) => props.onChangeForm('host', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'host', true, false, false, '', '', '')}
                        inputProps={{
                          maxLength: 240,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="username">
                    <label className={classes.layoutFormItemLabel} htmlFor="username">Eposta Hesap Adı *</label>
                    <Tooltip title={props.error?.username ? props.error.username[0] : _isNil(props.form?.username) ? '' : props.form.username}>
                      <TextField
                        {...createTextFieldProps('username')}
                        onChange={(e) => props.onChangeForm('username', e.target.value)}
                        onBlur={() => handleInputErrors('epostaauth', props.form, props.formdefault, props.onCheckErrors, add, 'username', true, true, false, '', 'Eposta Hesap Adı', 'eposta')}
                        inputProps={{
                          maxLength: 255,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="password">
                    <label className={classes.layoutFormItemLabel} htmlFor="password">Eposta Hesabı Şifresi *</label>
                    <Tooltip title={props.error?.password ? props.error.password[0] : _isNil(props.form?.password) ? '' : 'şifre'}>
                      <TextField
                        {...createTextFieldProps('password')}
                        onChange={(e) => props.onChangeForm('password', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'password', true, false, false, '', '', '')}
                        inputProps={{
                          maxLength: 50,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="port">
                    <label className={classes.layoutFormItemLabel} htmlFor="port">Bağlantı Portu</label>
                    <Tooltip title={props.error?.port ? props.error.port[0] : _isNil(props.form?.port) ? '' : props.form.port}>
                      <TextField
                        {...createTextFieldProps('port')}
                        onChange={(e) => props.onChangeForm('port', e.target.value)}
                        type="number"
                        inputProps={{
                          min: -2147483648,
                          max: 2147483647,
                          step: 1,
                          style: { textAlign: 'right' }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="use_tls">
                    <label className={classes.layoutFormItemLabel} htmlFor="use_tls">Bağlantı İçin TLS Kullan</label>
                    <Checkbox
                      name="use_tls"
                      color="primary"
                      checked={_result(props.form, 'use_tls', false)}
                      onChange={(e, checked) => props.onChangeForm('use_tls', checked)}
                    ></Checkbox>
                  </li>
                </ul>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItemBox} id="magazalar">
                    <MultipleSelectList
                      headername="MAĞAZALAR *"
                      name="Mağazalar"
                      name2="Mağaza"
                      form={props.form}
                      list={props?.form.magazalar ?? []}
                      listlength={props?.form?.magazalar?.length ?? 0}
                      opendialog={setOpenSelectorMagaza}
                      keyProp="id"
                      valueProp="tanim"
                      removefonk={handleRemoveMagazaById}
                      loading={props.loading}
                      error={props.error?.magazalar ? props.error.magazalar[0] : null}
                      newPath="tanimlar/magaza"
                      ustId={false}
                    />
                  </li>
                </ul>
              </div>
              {openSelectorMagaza &&
                <Dialog
                  open={openSelectorMagaza}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'magazalar', true, false, false, '', '', '')
                    setOpenSelectorMagaza(false)
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title='Mağazalar'
                      listPath='magaza?aktif=true'
                      listCountPath='magaza/count?aktif=true'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={props.form.magazalar}
                      onChangeItems={(items) => props.onChangeForm('magazalar', items)}
                      onClose={() => {
                        handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'magazalar', true, false, false, '', '', '')
                        setOpenSelectorMagaza(false)
                      }}
                      newPath='tanimlar/magaza'
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default MagazaDetailTabGenel;
