import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';

import { Editor } from "@tinymce/tinymce-react";
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },

      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },

    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },

    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: 0,
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    formItemEditor: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: 5,
    },
  };
};

const SozlesmeDetailTabVersiyon = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATADIL = useMemo(() => props.hataDil, [props.hataDil]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const [tabsDil, setTabsDil] = useState([]);
  const [selectedTabIndexDil, setSelectedTabIndexDil] = useState(props.selectedTabIndexDil);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);


  const COUNT_TABS_DİL = useMemo(() => props?.dilform.length ?? 0, [props]);
  const FORM_DIL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.form ?? null, [props, selectedTabIndex, selectedTabIndexDil]);
  const ERROR_DIL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.errors ?? null, [props, selectedTabIndex, selectedTabIndexDil]);

  const ACIKLAMA = useMemo(() => _result(FORM_DIL, 'sablon', ''), [FORM_DIL]);

  useLayoutEffect(() => {
    if (props.dilform?.length > 0) {
      const copyForms = [...props.dilform];
      const newTabs = copyForms[selectedTabIndex]?.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabsDil(newTabs);
    } else {
      setTabsDil([]);
    }

  }, [props.dilform, selectedTabIndex]);


  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((versiyon) => {
        return {
          label: "versiyon " + versiyon.form.versiyon,
          hasError: versiyon.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);

  useLayoutEffect(() => {
    if (!props.versiyonAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.versiyonAdd, COUNT_TABS]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM_DIL, `${name}`) || defaultValue,
      error: _has(ERROR_DIL, `${name}`),
      fullWidth: true,
    };
  }, [FORM_DIL, ERROR_DIL]);

  const handleChangeForm = (property, value) => {
    props.onChangeForm(selectedTabIndex, property, value);
  };

  const handleChangeFormDil = (property, value) => {
    props.onChangeDilForm(selectedTabIndex, selectedTabIndexDil, property, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }

  const handleSetErrorsDil = (errors) => {
    props.onCheckErrorsDil(errors, selectedTabIndex, selectedTabIndexDil);
  }

  const handleDetailDeleteDil = (index) => {
    props.deleteDetailDilWithIndex(selectedTabIndex, index);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <LayoutDetailVerticalTab
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                add={props.onAddNewTab}
                buttonText={"YENİ VERSİYON EKLE"}
                buttonShow={props.versiyonAdd}
                delete={handleDetailDelete}
                flexDirection='column'
              >
                {COUNT_TABS === 0 && (
                  <div className="flex flex-col items-start justify-start p-4 space-y-4">
                    <Typography variant="body1">
                      Sözleşmeye ait versiyon bulunamadı.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      onClick={props.onAddNewTab}
                    >YENİ VERSİYON EKLE</Button>
                  </div>
                )}
                {COUNT_TABS > 0 &&
                  <>
                    <div className={classes.tabDiv}>
                      <div className={classes.column}>
                        <ul className={classes.formList}>

                          <li className={classes.formItem} id="gecerlilik_baslangic">
                            <label className={classes.formItemLabel} htmlFor="gecerlilik_baslangic">Geçerlilik Başlangıç Tarihi *</label>
                            <Tooltip title={ERROR?.gecerlilik_baslangic ? ERROR.gecerlilik_baslangic[0] : _isNil(FORM?.gecerlilik_baslangic) ? '' : moment(FORM.gecerlilik_baslangic).format('DD/MM/YYYY, HH.mm')}>
                              <TextField
                                {...createTextFieldProps('gecerlilik_baslangic')}
                                onChange={(e) => handleChangeForm('gecerlilik_baslangic', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'gecerlilik_baslangic', handleSetErrors)}
                                type="datetime-local"
                              ></TextField>
                            </Tooltip>
                          </li>

                        </ul>

                      </div>
                    </div>
                    <div className={classes.layoutContainerTab}>
                      <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                      <>
                        {HATADIL && (
                          <Hata />
                        )}
                        {!HATADIL && (
                          <LayoutDetailVerticalTab
                            tabs={tabsDil}
                            selectedTabIndex={selectedTabIndexDil}
                            onChangeTabIndex={(index) => setSelectedTabIndexDil(index)}
                            buttonShow={false}
                            delete={handleDetailDeleteDil}
                            deleteButtonShow={true}
                          >
                            {COUNT_TABS_DİL === 0 && (
                              <div className="flex flex-col items-start justify-start p-4 space-y-4">
                                <Typography variant="body1">
                                  Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                              </div>
                            )}
                            {COUNT_TABS_DİL > 0 && (
                              <div className={classes.tabDiv}>
                                <ul className={classes.formList}>

                                  <li className={classes.formItem} id="baslik">
                                    <label className={classes.formItemLabel} htmlFor="baslik">Başlık *</label>
                                    <Tooltip title={ERROR_DIL?.baslik ? ERROR_DIL.baslik[0] : _isNil(FORM_DIL?.baslik) ? '' : FORM_DIL.baslik}>
                                      <TextField
                                        {...createTextFieldProps2('baslik')}
                                        onChange={(e) => handleChangeFormDil('baslik', e.target.value)}
                                        onBlur={() => handleInputErrors2(FORM_DIL, 'baslik', handleSetErrorsDil)}
                                      ></TextField>
                                    </Tooltip>
                                  </li>

                                  <li className={classes.formItemEditor} id="sablon">
                                    <label className={classes.formItemLabel} htmlFor="sablon">Şablon</label>
                                    <div style={{ borderWidth: 1, borderStyle: 'solid', borderColor: _has(ERROR_DIL, `sablon`) ? 'red' : 'transparent' }}>
                                      <Editor
                                        initialValue={ACIKLAMA}
                                        init={{
                                          plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                          ],
                                          toolbar: 'undo redo copy cut paste remove removeformat selectall | formatselect | fontselect | fontsizeselect |' +
                                            'bold italic underline lineheight backcolor forecolor blockquote | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | code | help',
                                          language: 'tr',
                                          valid_elements: '+*[*]',
                                          valid_children: "+body[style]",
                                          verify_html: false
                                        }}
                                        onChange={(e) => handleChangeFormDil('sablon', e.target.getContent())}
                                        onBlur={() => handleInputErrors2(FORM_DIL, 'sablon', handleSetErrorsDil)}
                                      />
                                    </div>
                                  </li>
                                  {_has(ERROR_DIL, `sablon`) && (
                                    <li className={classes.formItem}>
                                      <label className={classes.formItemLabel}></label>
                                      <Typography variant="caption" color="error">{_result(ERROR_DIL, `sablon[0]`)}</Typography>
                                    </li>
                                  )}


                                </ul>
                              </div>
                            )
                            }
                          </LayoutDetailVerticalTab>
                        )}
                      </>
                    </div>


                  </>
                }
              </LayoutDetailVerticalTab>
            </div>
          )}

        </>

      }
    </>
  );
});

export default SozlesmeDetailTabVersiyon;
