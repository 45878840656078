import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';


const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        margin: spacing(0, 0, 0, 4),
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    column2: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 360,
      '& + &': {
        margin: 5,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: spacing(0, 0, 4, 0),
      padding: 0,
    },

    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    editor: {
      display: 'flex',
      width: '100%',
      height: '100%',
      '& .CodeMirror': {
        height: '100%',
      }
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(2, 0, 2, 0),
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
  };
};

const MailSablon = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATADIL = useMemo(() => props.hataDil, [props.hataDil]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const [tabsDil, setTabsDil] = useState([]);
  const [selectedTabIndexDil, setSelectedTabIndexDil] = useState(props.selectedTabIndexDil);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);


  const COUNT_TABS_DİL = useMemo(() => props?.dilform.length ?? 0, [props]);
  const FORM_DIL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.form ?? null, [props, selectedTabIndex, selectedTabIndexDil]);
  const ERROR_DIL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.errors ?? null, [props, selectedTabIndex, selectedTabIndexDil]);


  useLayoutEffect(() => {
    if (props.dilform?.length > 0) {
      const copyForms = [...props.dilform];
      const newTabs = copyForms[selectedTabIndex]?.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabsDil(newTabs);
    } else {
      setTabsDil([]);
    }

  }, [props.dilform, selectedTabIndex]);


  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((mail) => {
        return {
          label: mail.form.tanim,
          hasError: mail.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);

  useLayoutEffect(() => {
    if (!props.mailAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.mailAdd, COUNT_TABS]);


  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM_DIL, `${name}`) || defaultValue,
      error: _has(ERROR_DIL, `${name}`),
      fullWidth: true,
    };
  }, [FORM_DIL, ERROR_DIL]);

  const handleChangeForm = (property, value) => {
    props.onChangeForm(selectedTabIndex, property, value);
  };

  const handleChangeFormDil = (property, value) => {
    props.onChangeDilForm(selectedTabIndex, selectedTabIndexDil, property, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }

  const handleSetErrorsDil = (errors) => {
    props.onCheckErrorsDil(errors, selectedTabIndex, selectedTabIndexDil);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <LayoutDetailVerticalTab
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                add={props.onAddNewTab}
                buttonText={"YENİ ŞABLON EKLE"}
                buttonShow={props.mailAdd}
                delete={handleDetailDelete}
                flexDirection='column'
              >
                {COUNT_TABS === 0 && (
                  <div className="flex flex-col items-start justify-start p-4 space-y-4">
                    <Typography variant="body1">
                      Eposta şablonu bulunamadı.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      onClick={props.onAddNewTab}
                    >YENİ ŞABLON EKLE</Button>
                  </div>
                )}
                {COUNT_TABS > 0 &&
                  <>
                    <div className={classes.tabDiv}>
                      <div className={classes.column}>
                        <ul className={classes.formList}>
                          <li className={classes.formItem} id="tanim">
                            <label className={classes.formItemLabel} htmlFor="tanim">Tanım *</label>
                            <Tooltip title={ERROR?.tanim ? ERROR.tanim[0] : _isNil(FORM?.tanim) ? '' : FORM.tanim}>
                              <TextField
                                {...createTextFieldProps('tanim')}
                                onChange={(e) => handleChangeForm('tanim', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'tanim', handleSetErrors)}
                                inputProps={{
                                  maxLength: 100,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="tip">
                            <label className={classes.formItemLabel} htmlFor="tip">Tip</label>
                            <Tooltip title={ERROR?.tip ? ERROR.tip[0] : _isNil(FORM?.tip) ? '' : props.tipDic ? props.tipDic[FORM.tip] : ''}>
                              <TextField
                                {...createTextFieldProps('tip')}
                                onChange={(e) => handleChangeForm('tip', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'tip', handleSetErrors)}
                                select
                                SelectProps={{ native: true }}
                              >
                                {props?.tip.map((tip) => (
                                  <option key={tip.key} value={tip.key}>{tip.value}</option>
                                ))}
                              </TextField>
                            </Tooltip>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={classes.layoutContainerTab}>
                      <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                      <>
                        {HATADIL && (
                          <Hata />
                        )}
                        {!HATADIL && (
                          <LayoutDetailVerticalTab
                            tabs={tabsDil}
                            selectedTabIndex={selectedTabIndexDil}
                            onChangeTabIndex={(index) => setSelectedTabIndexDil(index)}
                            buttonShow={false}
                          >
                            {COUNT_TABS_DİL === 0 && (
                              <div className="flex flex-col items-start justify-start p-4 space-y-4">
                                <Typography variant="body1">
                                  Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                              </div>
                            )}
                            {COUNT_TABS_DİL > 0 && (
                              <div className={classes.tabDiv}>
                                <ul className={classes.formList}>

                                  <li className={classes.formItem} id="baslik">
                                    <label className={classes.formItemLabel} htmlFor="baslik">Başlık *</label>
                                    <Tooltip title={ERROR_DIL?.baslik ? ERROR_DIL.baslik[0] : _isNil(FORM_DIL?.baslik) ? '' : FORM_DIL.baslik}>
                                      <TextField
                                        {...createTextFieldProps2('baslik')}
                                        onChange={(e) => handleChangeFormDil('baslik', e.target.value)}
                                        onBlur={() => handleInputErrors2(FORM_DIL, 'baslik', handleSetErrorsDil)}
                                      ></TextField>
                                    </Tooltip>
                                  </li>
                                  <li className={classes.formItem} id="konu">
                                    <label className={classes.formItemLabel} htmlFor="tanim">Konu *</label>
                                    <Tooltip title={ERROR_DIL?.konu ? ERROR_DIL.konu[0] : _isNil(FORM_DIL?.konu) ? '' : FORM_DIL.konu}>
                                      <TextField
                                        {...createTextFieldProps2('konu')}
                                        onChange={(e) => handleChangeFormDil('konu', e.target.value)}
                                        onBlur={() => handleInputErrors2(FORM_DIL, 'konu', handleSetErrorsDil)}
                                      ></TextField>
                                    </Tooltip>
                                  </li>
                                  <li className={classes.formItem} id="yazi_sablon">
                                    <label className={classes.formItemLabel} htmlFor="yazi_sablon">Yazı Şablon *</label>
                                    <Tooltip title={ERROR_DIL?.yazi_sablon ? ERROR_DIL.yazi_sablon[0] : _isNil(FORM_DIL?.yazi_sablon) ? '' : FORM_DIL.yazi_sablon}>
                                      <TextField
                                        {...createTextFieldProps2('yazi_sablon')}
                                        onChange={(e) => handleChangeFormDil('yazi_sablon', e.target.value)}
                                        onBlur={() => handleInputErrors2(FORM_DIL, 'yazi_sablon', handleSetErrorsDil)}
                                        multiline
                                        rows={3}
                                      ></TextField>
                                    </Tooltip>
                                  </li>

                                  <li className={classes.formItem} id="sablon">
                                    <label className={classes.formItemLabel} htmlFor="sablon">Şablon *</label>
                                    <div className={classes.column2} style={{ borderWidth: 1, borderStyle: 'solid', borderColor: _has(ERROR_DIL, `sablon`) ? 'red' : 'transparent' }}>
                                      {_has(ERROR_DIL, `sablon`) && (
                                        <div className={classes.editor}>
                                          <Typography variant="caption" color="error">{_result(ERROR_DIL, `sablon[0]`)}</Typography>
                                        </div>
                                      )}
                                      <div className={classes.editor}>
                                        <CodeMirror
                                          value={_result(FORM_DIL, 'sablon')}
                                          options={{
                                            mode: 'html',
                                            theme: 'juejin',
                                            lineNumbers: true,
                                          }}
                                          className="h-full"
                                          onBeforeChange={(editor, data, value) => {
                                            handleChangeFormDil('sablon', value)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </li>

                                </ul>
                              </div>
                            )
                            }
                          </LayoutDetailVerticalTab>
                        )}
                      </>
                    </div>


                  </>
                }
              </LayoutDetailVerticalTab>
            </div>
          )}

        </>

      }
    </>
  );
});

export default MailSablon;
