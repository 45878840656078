import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import DialogConfirm from '../../../components/DialogConfirm';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

import django from '../../../api/django';

const styles = ({ spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },
    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
};

const CariDetailTab_Yetkililer = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.defaultSelectedTabIndex);

  const yetkiliId = useMemo(() => props?.yetkiliId ?? null, [props]);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  const [showDetailDeleteDialog, setShowDetailDeleteDialog] = useState(false);
  const [isDetailDeleting, setIsDetailDeleting] = useState(false);
  const [isDetailDeletingErrorMessage, setIsDetailDeletingErrorMessage] = useState(null);

  const handleDetailDelete = () => {
    setShowDetailDeleteDialog(true);
  }

  const deleteDetail = (index) => {
    setIsDetailDeleting(true);
    setIsDetailDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    let copyforms = [...props.forms];
    copyforms.splice(index, 1);
    const deleted = async () => {
      props.yetkiliDelete(index);
    }
    if (yetkiliId !== null) {
      deleted().then(() => {
        const data = { yetkililer: copyforms.map((d) => d.form) };
        django.put(`${props.PATH}/${props.ID}/yetkililer/${yetkiliId}`, data).then(() => {
          setShowDetailDeleteDialog(false);
          setIsDetailDeletingErrorMessage(null);
        }).catch(() => {
          setIsDetailDeletingErrorMessage(errorMessage);
        }).finally(() => {
          setIsDetailDeleting(false);
        });
      })
    }
  }


  useLayoutEffect(() => {
    const copyForms = [...props.forms];
    const newTabs = copyForms.map((yetkili) => {
      return {
        label: yetkili.form.adi,
        hasError: yetkili.errors,
      };
    });
    setTabs(newTabs);
  }, [props.forms]);

  useLayoutEffect(() => {
    if (!props.yetkiliAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.yetkiliAdd, COUNT_TABS]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const handleChangeForm = (property, value) => {
    props.onChangeForm(selectedTabIndex, property, value);
  };
  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }
  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <LayoutDetailVerticalTab
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                add={props.onAddNewTab}
                buttonText={"YENİ YETKİLİ EKLE"}
                buttonShow={props.yetkiliAdd}
                delete={handleDetailDelete}
              >
                {COUNT_TABS === 0 && (
                  <div className="flex flex-col items-start justify-start p-4 space-y-4">
                    <Typography variant="body1">
                      Üyeye ait bir yetkili bulunamadı.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      onClick={props.onAddNewTab}
                    >YENİ YETKİLİ EKLE</Button>
                  </div>
                )}

                {COUNT_TABS > 0 &&
                  <>
                    <div className={classes.tabDiv}>
                      <div className={classes.column}>
                        <ul className={classes.formList}>
                          <li className={classes.formItem} id="adi">
                            <label className={classes.formItemLabel} htmlFor="adi">Adı *</label>
                            <Tooltip title={ERROR?.adi ? ERROR.adi[0] : _isNil(FORM?.adi) ? '' : FORM.adi}>
                              <TextField
                                {...createTextFieldProps('adi')}
                                onChange={(e) => handleChangeForm('adi', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'adi', handleSetErrors)}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="tel">
                            <label className={classes.formItemLabel} htmlFor="tel">Telefon *</label>
                            <Tooltip title={ERROR?.tel ? ERROR.tel[0] : _isNil(FORM?.tel) ? '' : FORM.tel}>
                              <TextField
                                {...createTextFieldProps('tel')}
                                onChange={(e) => handleChangeForm('tel', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'tel', handleSetErrors)}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="unvan">
                            <label className={classes.formItemLabel} htmlFor="unvan">Ünvan *</label>
                            <Tooltip title={ERROR?.unvan ? ERROR.unvan[0] : _isNil(FORM?.unvan) ? '' : FORM.unvan}>
                              <TextField
                                {...createTextFieldProps('unvan')}
                                onChange={(e) => handleChangeForm('unvan', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'unvan', handleSetErrors)}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="eposta">
                            <label className={classes.formItemLabel} htmlFor="eposta">E-Posta</label>
                            <Tooltip title={ERROR?.eposta ? ERROR.eposta[0] : _isNil(FORM?.eposta) ? '' : FORM.eposta}>
                              <TextField
                                {...createTextFieldProps('eposta')}
                                onChange={(e) => handleChangeForm('eposta', e.target.value)}
                              ></TextField>
                            </Tooltip>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {showDetailDeleteDialog &&
                      <DialogConfirm
                        title="Sil"
                        message={
                          isDetailDeletingErrorMessage
                            ? isDetailDeletingErrorMessage
                            : isDetailDeleting
                              ? `Yetkili siliniyor lütfen bekleyin`
                              : `Bu yetkiliyi gerçekten silmek istiyor musunuz?`
                        }
                        cancelText="VAZGEÇ"
                        submitText={isDetailDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
                        submittingText="SİLİNİYOR"
                        submitButtonProps={{ color: 'secondary' }}
                        isSubmitting={isDetailDeleting}
                        onSubmit={deleteDetail}
                        onCancel={() => setShowDetailDeleteDialog(false)}
                      ></DialogConfirm>
                    }
                  </>
                }
              </LayoutDetailVerticalTab>
            </div>
          )}
        </>
      }
    </>
  );
});

export default CariDetailTab_Yetkililer;
