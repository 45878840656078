import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Paper,
  AppBar,
  Toolbar,
  CircularProgress,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
} from '@material-ui/core'

import {
  Delete as IconDelete,
  Save as IconSave,
  Restore as IconRestore,
  Refresh as IconRefresh,
  Add as IconAdd,
  Close as IconClose,
  KeyboardArrowRight as IconKeyboardArrowRight,
  KeyboardArrowLeft as IconKeyboardArrowLeft,
  Error as IconError,
  OpenInNew as IconOpenInNew
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      position: 'relative',
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.toolbar
    },
    toolbarIconButton: {
      color: 'white'
    },
    tabs: {
      backgroundColor: theme.palette.background.paper,
      minHeight: 36,
    },
    tabsScrollButton: {
      color: theme.palette.text.primary,
    },
    tab: {
      paddingTop: 0,
      paddingBottom: 0,
      minHeight: 36,
    },
  };
});

const LayoutDetail = (props) => {
  const classes = useStyles();
  const ICON_BUTTON_PROPS = useMemo(() => ({
    size: 'small',
    className: classes.toolbarIconButton,
    disableRipple: true,
  }), [classes]);

  return (
    <>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <AppBar className={classes.appBar} position="static" elevation={1}>
          <Toolbar variant="dense" className='border-b border-solid border-white' >
            <div className="w-full flex items-center justify-between text-white">
              <div className={clsx('flex items-center truncate', { '-ml-5': !props.single })}>
                {!props.single &&
                  <>
                    <span
                      className={clsx('w-8 h-8 items-center justify-center', {
                        'flex': props.loading,
                        'hidden': !props.loading,
                      })}
                    ><CircularProgress color="inherit" size={16} /></span>

                    <span
                      className={clsx('w-16 h-8 items-center justify-center', {
                        'flex': !props.loading,
                        'hidden': props.loading,
                      })}
                    >
                      <Tooltip title="Kapat">
                        <IconButton
                          size="small"
                          className={classes.toolbarIconButton}
                          onClick={props.onClickCloseButton}
                        ><IconClose /></IconButton>
                      </Tooltip>
                      <Tooltip title="Yeni Sekmede AÇ">
                        <IconButton
                          size="small"
                          className={classes.toolbarIconButton}
                          onClick={props.onOpenInNewTab}
                        ><IconOpenInNew /></IconButton>
                      </Tooltip>
                    </span>
                  </>
                }

                <h1 className="font-medium truncate text-base m-0">{props.title}</h1>
              </div>

              <div className="flex items-center justify-end space-x-2">
                {!props.hideAddButton && (
                  <Tooltip title="Yeni">
                    <span>
                      <IconButton
                        {...ICON_BUTTON_PROPS}
                        onClick={props.onClickAddButton}
                        disabled={props.disableAddButton}
                      >
                        <IconAdd />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                <Tooltip title="Yenile">
                  <span>
                    <IconButton
                      {...ICON_BUTTON_PROPS}
                      onClick={props.onClickReloadButton}
                      disabled={props.disableReloadButton}
                    >
                      <IconRefresh />
                    </IconButton>
                  </span>
                </Tooltip>
                {!props.hideCancelButton && (
                  <Tooltip title="Değişiklikleri İptal Et">
                    <span>
                      <IconButton
                        {...ICON_BUTTON_PROPS}
                        onClick={props.onClickCancelButton}
                        disabled={props.disableCancelButton}
                      >
                        <IconRestore />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {!props.hideSaveButton && (
                  <Tooltip title="Değişiklikleri Kaydet">
                    <span>
                      <IconButton
                        {...ICON_BUTTON_PROPS}
                        onClick={props.onClickSaveButton}
                        disabled={props.disableSaveButton}
                        style={{ color: props.disableSaveButton ? "" : "green" }}
                      >
                        <IconSave />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {!props.hideDeleteButton && (
                  <Tooltip title="Sil">
                    <span>
                      <IconButton
                        {...ICON_BUTTON_PROPS}
                        onClick={props.onClickDeleteButton}
                        disabled={props.disableDeleteButton}
                        style={{ color: props.disableDeleteButton ? "" : "red" }}
                      >
                        <IconDelete />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}

                {!props.single && !props.hidePrevNextAddButton && (
                  <>
                    <Tooltip title="Öncekine Git">
                      <span>
                        <IconButton
                          {...ICON_BUTTON_PROPS}
                          onClick={props.onClickPreviousButton}
                          disabled={props.disablePreviousButton}
                        >
                          <IconKeyboardArrowLeft />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title="Sonrakine Git">
                      <span>
                        <IconButton
                          {...ICON_BUTTON_PROPS}
                          onClick={props.onClickNextButton}
                          disabled={props.disableNextButton}
                        ><IconKeyboardArrowRight /></IconButton>
                      </span>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          </Toolbar>

          {props.tabs.length > 0 && (
            <Tabs
              className={classes.tabs}
              value={props.selectedTabIndex}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              TabScrollButtonProps={{ className: classes.tabsScrollButton }}
              onChange={(_e, value) => props.onChangeSelectedTabIndex(value)}
            >
              {props.tabs.map((tab, tabIndex) => {
                return (
                  <Tab
                    key={`${tab.label}-${tabIndex}`}
                    className={classes.tab}
                    label={
                      <span className="flex items-center justify-center space-x-2">
                        <span>{tab.label}</span>

                        {tab?.hasError && (
                          <IconError style={{ fontSize: 16, color: 'red' }} />
                        )}
                      </span>
                    }
                    disabled={tab.disabled}
                    style={{ display: tab?.display === true ? 'none' : 'flex' }}
                  />
                );
              })}
            </Tabs>
          )}
        </AppBar>

        <div className="flex-1 overflow-y-auto">
          {props.children}
        </div>
      </Paper>
    </>
  );
};

LayoutDetail.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,

  single: PropTypes.bool,

  tabs: PropTypes.array,
  selectedTabIndex: PropTypes.number,
  onChangeSelectedTabIndex: PropTypes.func,

  disableCloseButton: PropTypes.bool,
  disableAddButton: PropTypes.bool,
  disableReloadButton: PropTypes.bool,
  disableCancelButton: PropTypes.bool,
  disableSaveButton: PropTypes.bool,
  disableDeleteButton: PropTypes.bool,
  disablePreviousButton: PropTypes.bool,
  disableNextButton: PropTypes.bool,
};

LayoutDetail.defaultProps = {
  loading: false,
  title: 'Yükleniyor',

  single: false,

  tabs: [],
  selectedTabIndex: 0,
  onChangeSelectedTabIndex: () => { },

  disableCloseButton: false,
  disableAddButton: false,
  disableReloadButton: false,
  disableCancelButton: false,
  disableSaveButton: false,
  disableDeleteButton: false,
  disablePreviousButton: false,
  disableNextButton: false,

  onClickCloseButton: () => { },
  onClickAddButton: () => { },
  onClickReloadButton: () => { },
  onClickCancelButton: () => { },
  onClickSaveButton: () => { },
  onClickDeleteButton: () => { },
  onClickPreviousButton: () => { },
  onClickNextButton: () => { },
};

export default LayoutDetail;

