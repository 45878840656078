import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
	isEqual as _isEqual,
	pickBy as _pickBy,
	identity as _identity,
	has as _has,
	isEmpty as _isEmpty,
	trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './OdemeTanimDetailTab_Genel';
import TabVade from './OdemeTanimDetailTab_Vade';
import TabFoto from './OdemeTanimDetailTab_Foto';

const PATH = 'odemetanim';

const TABS_DEFAULT = [
	{ label: 'GENEL', disabled: false, hasError: false },
	{ label: 'VADE', disabled: false, hasError: false, display: true },
	{ label: 'FOTOĞRAF', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
	tanim: '',
	aktif: true,
	siralama: '0',
	tip: '0',
	banka_hesaplari: [],
	odeme_servis: '-1',
	hedef_evrak_tip: '-1',
	odeme_3d_bicim: '0',
	binkodlar: []
};

const DILLER_FORM_DEFAULT = {
	dil_id: '',
	tanim: '',
	kisa_tanim: ''
};

const VADE_FORM_DEFAULT = {
	form: {
		tanim: 'Tanımsız',
		aktif: true,
		siralama: '0',
		ay: '0',
		ek_taksit: '0',
		vade_farki: '',
		hesaba_gecis_vade_gunu: '0',
		farki_ilk_aya_uygula: true,
		diller: []
	},
	errors: null
}

const createDillerStates = async (diller, odemediller) => {
	const states = [];
	diller.sort((a, b,) => a.id - b.id).forEach((dil) => {
		const data = {
			dil: {
				id: dil.id,
				tanim: dil.tanim,
			},
			form: {
				...DILLER_FORM_DEFAULT,
				dil_id: dil.id,
			},
			errors: null,
		};
		const odemedil = odemediller?.find((bd) => bd.dil_id === dil.id);
		if (odemedil) {
			data.form.dil_id = odemedil.dil_id;
			data.form.tanim = odemedil.tanim;
			data.form.kisa_tanim = odemedil.kisa_tanim;
		}
		states.push(data);
	});
	return states;
};

const createDillerStatesVade = async (diller, vadediller) => {
	const states = [];
	diller.sort((a, b,) => a.id - b.id).forEach((dil) => {
		const data = {
			dil: {
				id: dil.id,
				tanim: dil.tanim,
			},
			form: {
				...DILLER_FORM_DEFAULT,
				dil_id: dil.id,
			},
			errors: null,
		};
		const vadedil = vadediller?.find((vd) => vd.dil_id === dil.id);
		if (vadedil) {
			data.form.dil_id = vadedil.dil_id;
			data.form.tanim = vadedil.tanim;
			data.form.kisa_tanim = vadedil.kisa_tanim;
		}
		states.push(data);
	});
	return states;
};

const OdemeTanimDetail = (props) => {
	const [loading, setLoading] = useState(true);
	const [loadingDil, setLoadingDil] = useState(true);
	const [loadingVade, setLoadingVade] = useState(true);
	const [loadingDilVade, setLoadingDilVade] = useState(true);
	const [loadingFoto, setLoadingFoto] = useState(true);

	const [hataGenel, setHataGenel] = useState(null);
	const [hataDil, setHataDil] = useState(null);
	const [hataVade, setHataVade] = useState(null);
	const [hataFoto, setHataFoto] = useState(null);

	const [lastUpdateTime, setLastUpdateTime] = useState(0);
	const [add, setAdd] = useState(false);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [tabs, setTabs] = useState(TABS_DEFAULT);

	const [genelFormDefault, setGenelFormDefault] = useState(FORM_DEFAULT);
	const [genelForm, setGenelForm] = useState(null);
	const [genelError, setGenelError] = useState(null);

	const [tip, setTip] = useState([]);
	const [tipDic, setTipDic] = useState({});
	const [servis, setServis] = useState([]);
	const [servisDic, setServisDic] = useState({});
	const [evrakTip, setEvrakTip] = useState([]);
	const [evrakTipDic, setEvrakTipDic] = useState({});

	const [dillerSelectedTabIndex, setDillerSelectedTabIndex] = useState(0);
	const [dillerFormDefault, setDillerFormDefault] = useState(null);
	const [dillerForm, setDillerForm] = useState(null);
	const [dillerFormAdd, setDillerFormAdd] = useState(null);

	const [dillerSelectedTabIndexVade] = useState(0);
	const [dillerFormDefaultVade, setDillerFormDefaultVade] = useState(null);
	const [dillerFormVade, setDillerFormVade] = useState(null);

	const [dilMiniList, setDilMiniList] = useState([]);
	const [odemeDiller, setOdemeDiller] = useState([]);
	const [vadeDiller, setVadeDiller] = useState([]);

	const [vadeSelectedTabIndex, setVadeSelectedTabIndex] = useState(0);
	const [vadeForm, setVadeForm] = useState([]);
	const [vadeFormDefault, setVadeFormDefault] = useState([]);
	const [vadeAdd, setVadeAdd] = useState(true);

	const [odemeResimler, setOdemeResimler] = useState([]);
	const [odemeResimlerDefault, setOdemeResimlerDefault] = useState([]);

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

	const [detailDeleteId, setDeleteIdDetail] = useState(null);
	const [showDeleteDialogDetail, setShowDeleteDialogDetail] = useState(false);
	const [isDeletingDetail, setIsDeletingDetail] = useState(false);
	const [isDeletingErrorMessageDetail, setIsDeletingErrorMessageDetail] = useState(null);

	const [detailDeleteIdFoto, setDeleteIdDetailFoto] = useState(null);
	const [showDeleteDialogDetailFoto, setShowDeleteDialogDetailFoto] = useState(false);
	const [isDeletingDetailFoto, setIsDeletingDetailFoto] = useState(false);
	const [isDeletingErrorMessageDetailFoto, setIsDeletingErrorMessageDetailFoto] = useState(null);

	const [type, setType] = useState(props.eventType ?? 0);
	const [loadDone, setLoadDone] = useState(false);
	const [loadTab1, setLoadTab1] = useState(false);
	const [loadTab2, setLoadTab2] = useState(false);
	const [loadTab3, setLoadTab3] = useState(false);
	const [equal1, setEqual1] = useState(true);
	const [equal2, setEqual2] = useState(true);
	const [equal3, setEqual3] = useState(true);
	const [saveErrors, setSaveErrors] = useState([[], [], []]);

	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const ID = useMemo(() => props.id, [props.id]);
	const IS_ADD = useMemo(() => props.addnew, [props.addnew]);

	const TITLE = useMemo(() => {
		if (add) {
			return 'Yeni Ekle';
		}
		return genelForm?.tanim;
	}, [add, genelForm]);

	const IS_EQUAL = useMemo(() => {
		if (add) {
			return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(dillerForm, dillerFormAdd) && _isEqual(vadeForm, []) && _isEqual(odemeResimler, []);
		}
		const isEqualGenel = _isEqual(genelForm, genelFormDefault);
		const isEqualDiller = _isEqual(dillerForm, dillerFormDefault);
		const isEqualVade = _isEqual(vadeForm, vadeFormDefault);
		const isEqualVadeDiller = _isEqual(dillerFormVade, dillerFormDefaultVade);
		const isEqualResimler = _isEqual(odemeResimler, odemeResimlerDefault);
		return isEqualGenel && isEqualDiller && isEqualVade && isEqualVadeDiller && isEqualResimler;
	}, [add, genelForm, genelFormDefault, dillerForm, dillerFormDefault, dillerFormAdd, vadeForm, vadeFormDefault, dillerFormVade, dillerFormDefaultVade, odemeResimler, odemeResimlerDefault]);

	const LOADING = useMemo(() => {
		if (!loading && !loadingDil) {
			return false;
		}
		return true;
	}, [loading, loadingDil]);

	const LOADINGVADE = useMemo(() => {
		if (!loadingVade && !loadingDilVade) {
			return false;
		}
		return true;
	}, [loadingVade, loadingDilVade]);

	useLayoutEffect(() => {
		setAdd(false);
		setLoadDone(false);
	}, [ID]);

	useLayoutEffect(() => {
		if (loadDone) {
			setType(2);
		} else {
			setType(props.eventType ?? 0);
		}
	}, [props.eventType, loadDone]);

	useLayoutEffect(() => {
		if (type === 0) {
			setGenelForm(null);
			setGenelFormDefault(null);
			setDillerForm(null);
			setDillerFormDefault(null);
			setVadeForm([]);
			setVadeFormDefault([]);
			setDillerFormVade(null);
			setDillerFormDefaultVade(null);
			setOdemeResimler([]);
			setOdemeResimlerDefault([]);
			setTabs(TABS_DEFAULT);
			switch (selectedTabIndex) {
				case 0:
					setLoadTab1(true);
					break;
				case 1:
					setLoadTab1(true);
					setLoadTab2(true);
					break;
				case 2:
					setLoadTab1(true);
					setLoadTab3(true);
					break;
				default:
					break;
			}
			setLoadDone(true);
		} else if (type === 1) {
			setGenelForm(null);
			setGenelFormDefault(null);
			setDillerForm(null);
			setDillerFormDefault(null);
			if (!saveErrors[1].includes("err")) {
				setVadeForm([]);
				setVadeFormDefault([]);
				setDillerFormVade(null);
				setDillerFormDefaultVade(null);
			}
			if (!saveErrors[2].includes("err")) {
				setOdemeResimler([]);
				setOdemeResimlerDefault([]);
			}
			switch (selectedTabIndex) {
				case 0:
					setLoadTab1(true);
					break;
				case 1:
					setLoadTab1(true);
					if (!saveErrors[1].includes("err")) {
						setLoadTab2(true);
					}
					break;
				case 2:
					setLoadTab1(true);
					if (!saveErrors[2].includes("err")) {
						setLoadTab3(true);
					}
					break;
				default:
					break;
			}
			setLoadDone(true);
		} else {
			if (!add) {
				switch (selectedTabIndex) {
					case 0:
						if (equal1) {
							setLoadTab1(true);
						}
						break;
					case 1:
						if (equal2) {
							setLoadTab2(true);
						}
						break;
					case 2:
						if (equal3) {
							setLoadTab3(true);
						}
						break;
					default:
						break;
				}
			} else {
				setLoading(false);
				setLoadingDil(false);
				setLoadingVade(false);
				setLoadingDilVade(false);
				setLoadingFoto(false);
				setLoadTab1(false);
				setLoadTab2(false);
				setLoadTab3(false);
			}
		}
	}, [type, selectedTabIndex, saveErrors, equal1, equal2, equal3, add]);

	useLayoutEffect(() => {
		if (add) {
			setEqual1(_isEqual(genelForm, FORM_DEFAULT) && _isEqual(dillerForm, dillerFormAdd));
		} else {
			setEqual1(_isEqual(genelForm, genelFormDefault) && _isEqual(dillerForm, dillerFormDefault));
		}
	}, [add, genelForm, genelFormDefault, dillerForm, dillerFormDefault, dillerFormAdd]);

	useLayoutEffect(() => {
		if (add) {
			setEqual2(_isEqual(vadeForm, []));
		} else {
			setEqual2(_isEqual(vadeForm, vadeFormDefault) && _isEqual(dillerFormVade, dillerFormDefaultVade));
		}
	}, [add, vadeForm, vadeFormDefault, dillerFormVade, dillerFormDefaultVade]);

	useLayoutEffect(() => {
		if (add) {
			setEqual3(_isEqual(odemeResimler, []));
		} else {
			setEqual3(_isEqual(odemeResimler, odemeResimlerDefault));
		}
	}, [add, odemeResimler, odemeResimlerDefault]);

	useLayoutEffect(() => {
		if (loadTab1) {
			setLoading(true);
			if (!IS_ADD) {
				const debounce = setTimeout(() => {
					django(`${PATH}/${ID}`).then(({ data }) => {
						const form = {
							tanim: data.tanim,
							aktif: data.aktif,
							siralama: String(data.siralama),
							tip: String(data.tip.key),
							banka_hesaplari: data.banka_hesaplari,
							diller: data.diller,
							odeme_servis: data.odeme_servis ? String(data.odeme_servis.id) : '-1',
							hedef_evrak_tip: String(data.hedef_evrak_tip.key),
							odeme_3d_bicim: String(data.odeme_3d_bicim),
							binkodlar: data.binkodlar
						};
						setOdemeDiller(data.diller);
						setGenelFormDefault(form);
						setGenelForm(form);
						if (String(data.tip.key) === '3') {
							setTabs((prev) => {
								return immer(prev, (next) => {
									next[1].display = false;
								});
							});
						} else {
							setSelectedTabIndex(0);
						}
					}).catch((error) => {
						if (error.response) {
							if (error.response.status === 500) {
								setHataGenel(true);
							}
						}
					}).finally(() => {
						setLoading(false);
						setGenelError(null);
						setLoadTab1(false);
					});
				}, 300);
				return () => {
					clearTimeout(debounce);
					setLoading(false);
				};
			} else {
				setGenelForm(FORM_DEFAULT);
				setGenelFormDefault(FORM_DEFAULT);
				setOdemeDiller([]);
				setGenelError(null);
				setAdd(true);
				setSelectedTabIndex(0);
				setLoading(false);
				setLoadTab1(false);
			}
		}
	}, [ID, IS_ADD, loadTab1]);

	useLayoutEffect(() => {
		if (loadTab2) {
			setLoadingVade(true);
			if (!IS_ADD) {
				const debounce = setTimeout(() => {
					django(`${PATH}/${ID}/odemevade`).then(({ data }) => {
						const forms = data.map((vade) => (
							{
								form: {
									id: vade.id,
									tanim: vade.tanim,
									aktif: vade.aktif,
									siralama: String(vade.siralama),
									ay: String(vade.ay),
									ek_taksit: String(vade.ek_taksit),
									vade_farki: vade.vade_farki,
									hesaba_gecis_vade_gunu: String(vade.hesaba_gecis_vade_gunu),
									farki_ilk_aya_uygula: vade.farki_ilk_aya_uygula,
									diller: vade.diller,
								},
								errors: null
							}
						))
						setVadeForm(forms);
						setVadeFormDefault(forms);
						setVadeDiller(forms.map((vade) => vade.form.diller));
					}).catch((error) => {
						if (error.response) {
							if (error.response.status === 500) {
								setHataVade(true);
							}
						}
					}).finally(() => {
						setLoadingVade(false);
						setLoadTab2(false);
						setVadeAdd(true);
					});
				}, 300);
				return () => {
					clearTimeout(debounce);
				};
			} else {
				setVadeForm([]);
				setVadeFormDefault([]);
				setVadeDiller([]);
				setHataVade(null);
				setLoadingVade(false);
				setLoadTab2(false);
			}
		}
	}, [ID, IS_ADD, loadTab2]);

	useLayoutEffect(() => {
		if (loadTab3) {
			setLoadingFoto(true);
			if (!IS_ADD) {
				const debounce = setTimeout(() => {
					if (!IS_ADD) {
						django(`${PATH}/${ID}/foto`).then(({ data }) => {
							const formData = data.length > 0 ? data : [];
							const forms = formData.map((foto) => (
								{
									form: {
										id: foto.id,
										title: foto.title,
										harici_link: foto.harici_link,
										siralama: String(foto.siralama),
										foto: foto.foto,
									},
									errors: null
								}
							));
							const orderedForm = forms.sort((a, b) => a.form.siralama - b.form.siralama);
							setOdemeResimler(orderedForm);
							setOdemeResimlerDefault(orderedForm);
							setHataFoto(null);
						}).catch((error) => {
							if (error.response) {
								if (error.response.status === 500) {
									setHataFoto(true);
								}
							}
						}).finally(() => {
							setLoadingFoto(false);
							setLoadTab3(false);
						})
					}
				}, 300);
				return () => {
					clearTimeout(debounce);
				};
			} else {
				setOdemeResimler([]);
				setOdemeResimlerDefault([]);
				setHataFoto(null);
				setLoadingFoto(false);
				setLoadTab3(false);
			}
		}
	}, [ID, IS_ADD, loadTab3]);

	useLayoutEffect(() => {
		django('odemetanim/tip').then(({ data }) => {
			const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
			setTipDic(dict);
			setTip(data)
		});
	}, [lastUpdateTime]);

	useLayoutEffect(() => {
		django('dil/mini_list').then(({ data }) => setDilMiniList(data)).catch((error) => {
			if (error.response) {
				if (error.response.status === 500) {
					setHataDil(true);
				}
			}
		});
	}, [lastUpdateTime]);

	useLayoutEffect(() => {
		django('odemeservis/mini_list').then(({ data }) => {
			const dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
			setServisDic(dict);
			setServis(data);
		});
	}, [lastUpdateTime]);

	useLayoutEffect(() => {
		django('evrak/evrak_tip').then(({ data }) => {
			const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
			setEvrakTipDic(dict);
			setEvrakTip(data);
		});
	}, [lastUpdateTime]);

	useLayoutEffect(() => {
		setLoadingDil(true);
		createDillerStates(dilMiniList, odemeDiller).then((states) => {
			setDillerFormDefault(states);
			setDillerForm(states);
		}).finally(() => setLoadingDil(false));
	}, [dilMiniList, odemeDiller]);

	useLayoutEffect(() => {
		createDillerStates(dilMiniList, []).then((states) => {
			setDillerFormAdd(states);
		})
	}, [dilMiniList]);

	useLayoutEffect(() => {
		setLoadingDilVade(true);
		const setDil = async () => {
			let diller = [];
			vadeDiller.forEach((dil) => {
				createDillerStatesVade(dilMiniList, dil).then((states) => diller.push(states));
			})
			return diller;
		}
		setDil().then((state) => {
			setDillerFormVade(state);
			setDillerFormDefaultVade(state);
		}).finally(() => setLoadingDilVade(false));

	}, [dilMiniList, vadeDiller]);

	useLayoutEffect(() => {
		if (!props.single) {
			props.onDisableClose(!IS_EQUAL);
		}
	}, [props, IS_EQUAL]);

	const handleGenelChangeForm = (property, value) => {
		setGenelForm((prev) => {
			return immer(prev, (next) => {
				next[property] = value;
			});
		});
		if (property === "tip") {
			if (value === "3") {
				setTabs((prev) => {
					return immer(prev, (next) => {
						next[1].display = false;
					});
				});
			} else {
				setTabs((prev) => {
					return immer(prev, (next) => {
						next[1].display = true;
					});
				});
			}
		}
	};

	const handleDillerChangeForm = (index, property, value) => {
		setDillerForm((prev) => {
			return immer(prev, (next) => {
				next[index].form[property] = value;
			});
		});
	}

	const handleVadeChangeForm = (index, property, value) => {
		setVadeForm((prev) => {
			return immer(prev, (next) => {
				next[index]["form"][property] = value;
			});
		});
	};

	const handleDillerChangeFormVade = (index, index2, property, value) => {
		setDillerFormVade((prev) => {
			return immer(prev, (next) => {
				next[index][index2].form[property] = value;
			});
		});
	}

	const handleVadeAddNewTab = () => {
		setVadeAdd(false);
		setVadeForm((prev) => immer(prev, (next) => {
			next.push({ ...VADE_FORM_DEFAULT });
		}));
		setVadeDiller((prev) => immer(prev, (next) => {
			next.push([]);
		}));
	}

	const handleTabResimlerChangeForm = (index, property, value) => {
		if (index !== 'null') {
			setOdemeResimler((prev) => {
				return immer(prev, (next) => {
					next[index].form[property] = value;
				});
			});
		} else {
			setOdemeResimler(value);
		}
	}

	const handleAdd = () => {
		setAdd(true);
		setSelectedTabIndex(0);
		setGenelForm(FORM_DEFAULT);
		setGenelError(null);
		setDillerForm(dillerFormAdd);
		setVadeForm([]);
		setVadeDiller([]);
		setOdemeResimler([]);
		setTabs((prev) => {
			return immer(prev, (next) => {
				next[1].display = true;
				next[0].hasError = false;
				next[1].hasError = false;
				next[2].hasError = false;
			});
		});
	};

	const handleReload = () => {
		setLastUpdateTime(Date.now());
		setLoadTab1(true);
		setLoadTab2(true);
		setLoadTab3(true);
		setVadeAdd(true);
		if (String(genelForm.tip.key) === '3') {
			setTabs([
				{ label: 'GENEL', disabled: false, hasError: false },
				{ label: 'VADE', disabled: false, hasError: false, display: false },
				{ label: 'FOTOĞRAF', disabled: false, hasError: false },
			])
		} else {
			setSelectedTabIndex(0);
			setTabs(TABS_DEFAULT);
		}
	};

	const handleCancel = () => {
		setGenelForm(genelFormDefault);
		setGenelError(null);
		setDillerForm(dillerFormDefault);
		setVadeForm(vadeFormDefault);
		setVadeDiller(dillerFormDefaultVade.map((vade) => vade.form.diller));
		setOdemeResimler(odemeResimlerDefault);
		if (genelFormDefault.tip === "3") {
			setTabs((prev) => {
				return immer(prev, (next) => {
					next[1].display = true;
					next[0].hasError = false;
					next[1].hasError = false;
					next[2].hasError = false;
				});
			});
		} else {
			setSelectedTabIndex(0);
			setTabs((prev) => {
				return immer(prev, (next) => {
					next[1].display = false;
					next[0].hasError = false;
					next[1].hasError = false;
					next[2].hasError = false;
				});
			});
		}
		if (!IS_ADD) {
			setAdd(false);
		}
	};

	const handleDelete = () => {
		setIsDeleting(true);
		setIsDeletingErrorMessage(null);
		const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
		django.delete(`${PATH}/${ID}`).then(({ status }) => {
			if (status === 204) {
				setShowDeleteDialog(false);
				setIsDeletingErrorMessage(null);
				setTimeout(() => {
					if (props.single) {
						history.push(URLS.tanimlar.odeme_tanim.list);
					} else {
						props.onDeleted();
					}
				}, 100);
			} else {
				setIsDeletingErrorMessage(errorMessage);
			}
		}).catch(() => {
			setIsDeletingErrorMessage(errorMessage);
		}).finally(() => {
			setIsDeleting(false);
		});
	};

	const handleDeleteDilIndex = (index) => {
		setDillerForm((prev) => {
			return immer(prev, (next) => {
				next[index].form = { ...DILLER_FORM_DEFAULT, dil_id: next[index].form.dil_id };
				next[index].errors = null;
			})
		});
	}

	const handleDeleteDetail = () => {
		setIsDeletingDetail(true);
		setIsDeletingErrorMessageDetail(null);
		const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
		django.delete(`${PATH}/${ID}/odemevade/${detailDeleteId}`).then(({ status }) => {
			if (status === 204) {
				setShowDeleteDialogDetail(false);
				setIsDeletingErrorMessageDetail(null);
			} else {
				setIsDeletingErrorMessageDetail(errorMessage);
			}
		}).catch(() => {
			setIsDeletingErrorMessageDetail(errorMessage);
		}).finally(() => {
			setIsDeletingDetail(false);
			setLoadTab2(true);
		});
	}

	const handleDeleteDetailDilIndex = (index, index2) => {
		setDillerFormVade((prev) => {
			return immer(prev, (next) => {
				next[index][index2].form = { ...DILLER_FORM_DEFAULT, dil_id: next[index][index2].form.dil_id };
				next[index][index2].errors = null;
			})
		});
	}


	const handleDeleteDetailFoto = () => {
		setIsDeletingDetailFoto(true);
		setIsDeletingErrorMessageDetailFoto(null);
		const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
		django.delete(`${PATH}/${ID}/fotolar/${detailDeleteIdFoto}`).then(({ status }) => {
			if (status === 204) {
				setShowDeleteDialogDetailFoto(false);
				setIsDeletingErrorMessageDetailFoto(null);
			} else {
				setIsDeletingErrorMessageDetailFoto(errorMessage);
			}
		}).catch(() => {
			setIsDeletingErrorMessageDetailFoto(errorMessage);
		}).finally(() => {
			setIsDeletingDetailFoto(false);
			setLoadTab3(true);
		});
	}

	const checkAll = async () => {
		const list = [
			{ prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Ödeme Tanım', pagename: 'ödeme', prop2: '' },
			{ prop: 'tip', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
			{ prop: 'hedef_evrak_tip', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
		];
		if (genelForm.tip === '0') {
			list.push({ prop: 'banka_hesaplari', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },)
		}
		if (genelForm.tip === '3') {
			list.push({ prop: 'odeme_servis', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },)
		}

		let errors = [];
		await checkAllList('odemetanim', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
		return errors;
	};

	const checkAllTabs = async (form, func, list) => {
		let errors = [];
		await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
		return errors;
	}

	const handleSave = async () => {
		const createRequestOptionsTabGenel = async () => {
			let reqopt = null;
			let err = null;
			if (add ? _isEqual(genelForm, FORM_DEFAULT) && _isEqual(dillerForm, dillerFormAdd) : _isEqual(genelForm, genelFormDefault) && _isEqual(dillerForm, dillerFormDefault)) {
				reqopt = null;
				err = null;
			} else {
				await checkAll().then((res) => {
					if (_isEmpty(res)) {
						const diller = dillerForm.filter((dil) => (!_isEmpty(dil.form.tanim))).map((d) => d.form);
						const bankalar = genelForm.banka_hesaplari.length > 0 && genelForm.tip === '0' ? genelForm.banka_hesaplari.map((b) => b.id) : [];
						const binkodlar = genelForm.binkodlar.length > 0 && genelForm.tip === '3' ? genelForm.binkodlar.map((b) => b.id) : [];
						const data = { ...genelForm, siralama: genelForm.siralama ? Number(genelForm.siralama) : 0, tip: Number(genelForm.tip), odeme_3d_bicim: Number(genelForm.odeme_3d_bicim), odeme_servis: genelForm.odeme_servis !== '-1' ? Number(genelForm.odeme_servis) : null, diller: diller, banka_hesaplari: bankalar, binkodlar: binkodlar, hedef_evrak_tip: genelForm.hedef_evrak_tip !== '-1' ? Number(genelForm.hedef_evrak_tip) : null }
						reqopt = {
							data: data,
							method: add ? 'POST' : 'PUT',
							id: null,
							successMessage: `Ödeme Tanım ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
							errorMessageUnexpected: `Ödeme Tanım ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
						};
						err = null;
					} else {
						reqopt = null;
						err = res;
					}
				})
			}
			return [reqopt, err]
		}

		const createRequestOptionsTabListe = async (next, index) => {
			let reqopt = null;
			let err = null;
			const isHas = _has(next.form, 'id');
			const prev = vadeFormDefault.find(x => x.form.id === next.form.id);
			const isEqual = !isHas ? false : _isEqual(prev, next) && _isEqual(dillerFormVade[index], dillerFormDefaultVade[index]);
			if (isEqual) {
				reqopt = null;
				err = null;
			} else {
				const list = [
					{ prop: 'tanim', prop2: '' },
					{ prop: 'ay', prop2: '' },
					{ prop: 'vade_farki', prop2: '' },
				];
				await checkAllTabs(next.form, (err) => handleErrorsVade(err, index), list).then((res) => {
					if (_isEmpty(res)) {
						const diller = dillerFormVade[index].filter((dil) => (!_isEmpty(dil.form.tanim))).map((d) => d.form);
						const data = { ...next.form, ay: Number(next.form.ay), ek_taksit: Number(next.form.ek_taksit), hesaba_gecis_vade_gunu: Number(next.form.hesaba_gecis_vade_gunu), siralama: next.form.siralama ? Number(next.form.siralama) : 0, diller: diller }
						if (isHas) {
							reqopt = {
								data: data,
								method: 'PUT',
								id: next.form.id,
								successMessage: `${next.form.tanim} güncelleme başarıyla tamamlandı`,
								errorMessageUnexpected: `${next.form.tanim} güncellenirken beklenmeyen bir hata oluştu`,
							}
						} else {
							reqopt = {
								data: data,
								method: 'POST',
								id: null,
								successMessage: `${next.form.tanim} ekleme başarıyla tamamlandı`,
								errorMessageUnexpected: `${next.form.tanim} eklenirken beklenmeyen bir hata oluştu`,
							}
						}
						err = null;
					} else {
						reqopt = null;
						err = res;
					}
				})
			}
			return [reqopt, err]
		}

		const createFotoRequest = async (next, index) => {
			let reqopt = null;
			let err = null;
			const isHas = _has(next.form, 'id');
			const prev = odemeResimlerDefault.find(x => x.form.id === next.form.id);
			const isEqual = !isHas ? false : _isEqual(prev, next);
			if (isEqual) {
				reqopt = null;
				err = null;
			} else {
				const list = [
					{ prop: 'title', prop2: '' },
					{ prop: 'siralama', prop2: '' },
				];
				await checkAllTabs(next.form, (err) => handleErrorsFoto(err, index), list).then((res) => {
					if (_isEmpty(res)) {
						const form = { ...next.form, siralama: Number(next.form.siralama), foto: next.form.foto.id };
						if (isHas) {
							reqopt = {
								data: form,
								method: 'PUT',
								id: form.id,
								successMessage: `${form.title} resim ekleme başarıyla tamamlandı`,
								errorMessageUnexpected: `${form.title} resim eklenirken beklenmeyen bir hata oluştu`,
							}
						} else {
							reqopt = {
								data: form,
								method: 'POST',
								id: null,
								successMessage: `${form.title} resim ekleme başarıyla tamamlandı`,
								errorMessageUnexpected: `${form.title} resim eklenirken beklenmeyen bir hata oluştu`,
							}
						}
						err = null;
					} else {
						reqopt = null;
						err = res;
					}
				})
			}
			return [reqopt, err];
		}

		let errors = [[], [], []];

		let SAVEID = add ? null : ID;

		const start = async () => {
			const tabs = [
				{ index: 0, name: 'genel', request: [], error: [] },
				{ index: 1, name: 'liste', request: [], error: [] },
				{ index: 1, name: 'foto', request: [], error: [] },
			];
			const res = await createRequestOptionsTabGenel();
			tabs[0].request.push(res[0]);
			tabs[0].error.push(res[1]);

			for (const i in vadeForm) {
				const vadestate = [...vadeForm];
				const res = await createRequestOptionsTabListe(vadestate[i], i)
				tabs[1].request.push(res[0]);
				tabs[1].error.push(res[1]);
			}

			for (const i in odemeResimler) {
				const resimstate = [...odemeResimler];
				const res = await createFotoRequest(resimstate[i], i)
				tabs[2].request.push(res[0]);
				tabs[2].error.push(res[1]);
			}

			await asyncForEach(tabs, async (tab) => {
				await asyncForEach(tab.request, async (request, requestIndex) => {
					if (request !== null) {
						if (tab.name === 'genel') {
							setGenelError(null);
							await django({
								method: request.method,
								data: request.data,
								url: add ? "odemetanim" : `odemetanim/${SAVEID}`
							}).then(({ data }) => {
								enqueueSnackbar(request.successMessage, { variant: 'success' });
								if (!add) {
									props.onUpdated();
								}
								if (props.single && add) {
									history.push(URLS.tanimlar.odeme_tanim.detail(data.id, "detay"));
								}
								if (!props.single && add) {
									props.onAdded(data);
								}
								SAVEID = data.id;
								errors[tab.index].splice(requestIndex, 0, null);
							}).catch((error) => {
								setSelectedTabIndex(tab.index);
								setDillerSelectedTabIndex(dillerSelectedTabIndex)
								if (error.response) {
									if (error.response.status === 500) {
										enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
									} else {
										setGenelError(error.response.data);
									}
								} else {
									enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
								}
								errors[tab.index].splice(requestIndex, 0, "err");
							});
						}
						if (SAVEID !== null) {
							if (tab.name === 'liste') {
								setVadeForm((prev) => {
									return immer(prev, (next) => {
										next[requestIndex].errors = null;
									})
								});
								await django({
									method: request.method,
									data: request.data,
									url: request.id ? `odemetanim/${SAVEID}/odemevade/${request.id}` : `odemetanim/${SAVEID}/odemevade`
								}).then(() => {
									setVadeAdd(true);
									enqueueSnackbar(request.successMessage, { variant: 'success' });
									errors[tab.index].splice(requestIndex, 0, null);
								}).catch((error) => {
									setVadeAdd(false);
									setSelectedTabIndex(tab.index);
									setVadeSelectedTabIndex(requestIndex);
									if (error.response) {
										setVadeForm((prev) => {
											return immer(prev, (next) => {
												next[requestIndex].errors = error.response.data;
											})
										});
									} else {
										enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
									}
									errors[tab.index].splice(requestIndex, 0, "err");
								});
							}
						}
						if (SAVEID !== null) {
							if (tab.name === 'foto') {
								await django({
									method: request.method,
									data: request.data,
									url: request.id ? `odemetanim/${SAVEID}/foto/${request.id}` : `odemetanim/${SAVEID}/foto`
								}).then(() => {
									enqueueSnackbar(request.successMessage, { variant: 'success' });
									errors[tab.index].splice(requestIndex, 0, null);
								}).catch((error) => {
									setSelectedTabIndex(tab.index);
									if (error.response) {
										let resimler = [...odemeResimler];
										const index = resimler.findIndex(f => f.form.foto.id === request.config.data.foto);
										resimler[index].errors = error.response.data;
										setOdemeResimler(resimler);
									} else {
										enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
									}
									errors[tab.index].splice(requestIndex, 0, "err");
								})
							}
						}
					} else {
						if (!_isEmpty(tab.error[requestIndex])) {
							errors[tab.index].splice(requestIndex, 0, "err");
						} else {
							errors[tab.index].splice(requestIndex, 0, null);
						}
					}
				});
			});
		}
		await start();
		if (SAVEID !== null) {
			if (!add) {
				if (!errors[0].includes("err")) {
					setLoadTab1(true);
				}
				if (!errors[1].includes("err")) {
					setLoadTab2(true);
				}
				if (!errors[2].includes("err")) {
					setLoadTab3(true);
				}
			} else {
				setSaveErrors(errors);
			}
		} else {
			enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
			await checkAll();
		}
		setTabs((prev) => {
			return immer(prev, (next) => {
				next[0].hasError = !errors[0].includes("err") ? SAVEID ? false : true : true;
				next[1].hasError = !errors[1].includes("err") ? false : true;
				next[2].hasError = !errors[2].includes("err") ? false : true;
			});
		});
	};

	const handleErrors = (errors) => {
		setGenelError((prev) => {
			const next = _pickBy({ ...prev, ...errors }, _identity);
			if (Object.keys(next).length === 0) {
				return null;
			}
			return next;
		});
	}

	const handleErrorsDil = (errors, index) => {
		setDillerForm((prev) => {
			const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
			return immer(prev, (next) => {
				next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
			});
		});
	}

	const handleErrorsVade = (errors, index) => {
		setVadeForm((prev) => {
			const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
			return immer(prev, (next) => {
				next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
			});
		});
	}

	const handleErrorsVadeDil = (errors, index, index2) => {
		setDillerFormVade((prev) => {
			const err = _pickBy({ ...prev[index][index2]["errors"], ...errors }, _identity);
			return immer(prev, (next) => {
				next[index][index2]["errors"] = Object.keys(err).length === 0 ? null : err;
			});
		});
	}

	const handleErrorsFoto = (errors, index) => {
		setOdemeResimler((prev) => {
			const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
			return immer(prev, (next) => {
				next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
			});
		});
	}

	return (
		<>
			<LayoutDetail
				loading={loading}
				title={TITLE}

				single={props.single}

				tabs={tabs}
				selectedTabIndex={selectedTabIndex}
				onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

				disableCloseButton={loading}
				disableAddButton={loading || add || IS_ADD}
				disableReloadButton={loading || add || IS_ADD}
				disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
				disableSaveButton={loading || IS_EQUAL}
				disableDeleteButton={loading || add || IS_ADD}
				disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
				disableNextButton={props.disableNextButton || add || IS_ADD}

				onClickCloseButton={props.onClose}
				onClickAddButton={handleAdd}
				onClickReloadButton={handleReload}
				onClickCancelButton={handleCancel}
				onClickSaveButton={handleSave}
				onClickDeleteButton={() => setShowDeleteDialog(true)}
				onClickPreviousButton={props.onGoPrevious}
				onClickNextButton={props.onGoNext}
				onOpenInNewTab={() => {
					if (IS_ADD) {
						window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
					} else {
						window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
					}
				}}
			>
				{selectedTabIndex === 0 && (
					<TabGenel
						loading={LOADING}
						hata={hataGenel}
						hataDil={hataDil}
						add={add}

						form={genelForm}
						formdefault={genelFormDefault}
						error={genelError}
						tip={tip}
						tipDic={tipDic}
						servis={servis}
						servisDic={servisDic}
						evrakTip={evrakTip}
						evrakTipDic={evrakTipDic}
						onChangeForm={handleGenelChangeForm}
						selectedTabIndex={dillerSelectedTabIndex}
						dilform={dillerForm}
						onChangeDilForm={handleDillerChangeForm}
						deleteDilWithIndex={handleDeleteDilIndex}
						onCheckErrors={(errors) => handleErrors(errors)}
						onCheckErrorsDil={(errors, index) => handleErrorsDil(errors, index)}
					/>
				)}

				{selectedTabIndex === 1 && (
					<TabVade
						loading={LOADINGVADE}
						hata={hataVade}
						hataDil={hataDil}
						selectedTabIndex={vadeSelectedTabIndex}
						forms={vadeForm}
						onChangeForm={handleVadeChangeForm}
						selectedTabIndexDil={dillerSelectedTabIndexVade}
						dilform={dillerFormVade}
						onChangeDilForm={handleDillerChangeFormVade}
						onAddNewTab={handleVadeAddNewTab}
						vadeAdd={vadeAdd}
						setDeleteId={setDeleteIdDetail}
						openDeleteForm={setShowDeleteDialogDetail}
						deleteDetailDilWithIndex={handleDeleteDetailDilIndex}
						onCheckErrors={(errors, index) => handleErrorsVade(errors, index)}
						onCheckErrorsDil={(errors, index, index2) => handleErrorsVadeDil(errors, index, index2)}
					/>
				)}
				{selectedTabIndex === 2 &&
					<TabFoto
						loading={loadingFoto}
						hata={hataFoto}
						resimler={odemeResimler}
						onChangeFormData={handleTabResimlerChangeForm}
						setDeleteId={setDeleteIdDetailFoto}
						openDeleteForm={setShowDeleteDialogDetailFoto}
						onCheckErrors={(errors, index) => handleErrorsFoto(errors, index)}
					/>
				}

			</LayoutDetail>

			{showDeleteDialog &&
				<DialogConfirm
					title="Sil"
					message={
						isDeletingErrorMessage
							? isDeletingErrorMessage
							: isDeleting
								? 'Ödeme tanımı siliniyor lütfen bekleyin'
								: 'Bu ödeme tanımını gerçekten silmek istiyor musunuz?'
					}
					cancelText="VAZGEÇ"
					submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
					submittingText="SİLİNİYOR"
					submitButtonProps={{ color: 'secondary' }}
					isSubmitting={isDeleting}
					onSubmit={handleDelete}
					onCancel={() => setShowDeleteDialog(false)}
				></DialogConfirm>
			}
			{showDeleteDialogDetail &&
				<DialogConfirm
					title="Sil"
					message={
						isDeletingErrorMessageDetail
							? isDeletingErrorMessageDetail
							: isDeletingDetail
								? 'Vade siliniyor lütfen bekleyin'
								: 'Bu vadeyi gerçekten silmek istiyor musunuz?'
					}
					cancelText="VAZGEÇ"
					submitText={isDeletingErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
					submittingText="SİLİNİYOR"
					submitButtonProps={{ color: 'secondary' }}
					isSubmitting={isDeletingDetail}
					onSubmit={handleDeleteDetail}
					onCancel={() => setShowDeleteDialogDetail(false)}
				></DialogConfirm>
			}
			{showDeleteDialogDetailFoto &&
				<DialogConfirm
					title="Sil"
					message={
						isDeletingErrorMessageDetailFoto
							? isDeletingErrorMessageDetailFoto
							: isDeletingDetailFoto
								? 'Fotoğraf siliniyor lütfen bekleyin'
								: 'Bu fotoğrafı gerçekten silmek istiyor musunuz?'
					}
					cancelText="VAZGEÇ"
					submitText={isDeletingErrorMessageDetailFoto ? 'TEKRAR DENE' : 'SİL'}
					submittingText="SİLİNİYOR"
					submitButtonProps={{ color: 'secondary' }}
					isSubmitting={isDeletingDetailFoto}
					onSubmit={handleDeleteDetailFoto}
					onCancel={() => setShowDeleteDialogDetailFoto(false)}
				></DialogConfirm>
			}
		</>
	);
}

export default OdemeTanimDetail;