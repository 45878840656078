import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import DetailVerticalTabs from '../../components/DetailVerticalTabs';

const styles = ({ palette, spacing }) => {
  return {
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflow: 'hidden',
    },

    side: {
      height: '100%',
      // maxWidth: '100%',
      // minWidth: 200,
      //width: 250,
      maxWidth: 250,
      padding: spacing(2, 0, 2, 0),
      overflowY: 'auto'
    },

    main: {
      width: '100%',
      height: '100%',
      flex: 1,
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'flex-start',
      // padding: spacing(2, 3, 2, 3),
      borderLeft: `1px solid ${palette.action.selected}`,
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
    },
    buttonCointainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    buttonDiv: {
      display: 'flex',
      justifyContent: 'center',
      padding: spacing(0, 1, 2, 1),
    },
    button: {
      backgroundColor: '#eacfea',
      '&:hover': {
        background: palette.primary.main,
        color: '#fff'
      }
    },
  };
};

const LayoutDetailVerticalTab = withStyles(styles)(({ classes, ...props }) => {

  return (
    <>
      <div className={classes.container}>

        {props.tabs.length > 0 && (
          <aside className={classes.side}>
            {props.buttonShow &&
              <div className={classes.buttonCointainer}>
                <div className={classes.buttonDiv}>
                  <Button
                    className={classes.button}
                    variant="text"
                    color="default"
                    size="small"
                    disableElevation
                    onClick={props.add}
                  >{props.buttonText}</Button>
                </div>
              </div>
            }
            <DetailVerticalTabs
              tabs={props.tabs}
              selectedTabIndex={props.selectedTabIndex}
              onChangeTabIndex={props.onChangeTabIndex}
              delete={props.delete}
              buttonShow={props.buttonShow}
              deleteButtonShow={props.deleteButtonShow}
            />
          </aside>
        )}

        <main className={classes.main} style={{ flexDirection: props.flexDirection ? props.flexDirection : 'row' }}>
          {props.children}
        </main>

      </div>
    </>
  );
});

LayoutDetailVerticalTab.propTypes = {
  children: PropTypes.node.isRequired,
  tabs: PropTypes.array,
  selectedTabIndex: PropTypes.number,
  onChangeTabIndex: PropTypes.func,
};

LayoutDetailVerticalTab.defaultProps = {
  tabs: [],
  selectedTabIndex: 0,
  onChangeTabIndex: () => { },
};

export default LayoutDetailVerticalTab;
