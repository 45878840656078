/* eslint-disable no-unused-vars */
import React, { useLayoutEffect, useState, useMemo } from 'react';
import moment from 'moment';
import django from '../../../../api/django';
import LayoutDetailWithoutTabs from '../../../base/LayoutDetailWithoutTabs';

import TabGenel from './IslemDetailTab_Genel';

const PER_PAGE = 100;

const IslemDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingDetay, setLoadingDetay] = useState(true);
  const [loadingDetayMore, setLoadingDetayMore] = useState(false);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataDetay, setHataDetay] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const [genelForm, setGenelForm] = useState(null);
  const [genelFormListe, setGenelFormListe] = useState([]);
  const [genelFormListeCount, setGenelFormListeCount] = useState([]);

  const [entIslemTip, setEntIslemTip] = useState([]);
  const [entIslemTipDic, setEntIslemTipDic] = useState({});
  const [entIslemBolum, setEntIslemBolum] = useState([]);
  const [entIslemBolumDic, setEntIslemBolumDic] = useState({});

  const ID = useMemo(() => props.id, [props]);
  const PATH = useMemo(() => props.path, [props]);
  const TITLE = useMemo(() => genelForm?.bitis_zamani ? moment(genelForm?.bitis_zamani).format('DD/MM/YYYY, HH.mm') + " -TARİHLİ İŞLEM  DETAY" : "İŞLEM DEVAM EDİYOR", [genelForm]);

  const PAGE = useMemo(() => genelFormListe.length > 0 ? Math.ceil(genelFormListe.length / PER_PAGE) : 1, [genelFormListe.length]);
  const PAGES_COUNT = useMemo(() => Math.ceil(genelFormListeCount / PER_PAGE), [genelFormListeCount]);

  useLayoutEffect(() => {
    setLoading(true);
    const debounce = setTimeout(() => {
      django(`${PATH}/${ID}`).then(({ data }) => {
        const form = {
          islem_tipi: String(data.islem_tipi),
          bitis_zamani: data.bitis_zamani ? moment(data.bitis_zamani).format('YYYY-MM-DD[T]HH:mm') : null,
          hata: data.hata,
        };
        setGenelForm(form);
        setHataGenel(null);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            setHataGenel(true);
          }
        }
      }).finally(() => {
        setLoading(false);
      });
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoading(false);
    };
  }, [PATH, ID, lastUpdateTime]);

  useLayoutEffect(() => {
    django(`${PATH}/${ID}/islemdetay/count`).then(({ data }) => {
      setGenelFormListeCount(data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataDetay(true);
        }
      }
    });
  }, [PATH, ID, lastUpdateTime]);

  useLayoutEffect(() => {
    setLoadingDetay(true);
    const debounce = setTimeout(() => {
      django(`${PATH}/${ID}/islemdetay`, { params: { size: PER_PAGE } }).then(({ data }) => {
        const forms = data.map((detay) => (
          {
            id: detay.id,
            kodu: detay.kodu,
            mesaj: detay.mesaj,
          }
        ));
        setGenelFormListe(forms);
        setHataDetay(null);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            setHataDetay(true);
          }
        }
      }).finally(() => {
        setLoadingDetay(false);
      });
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoadingDetay(false);
    };
  }, [PATH, ID, lastUpdateTime]);

  useLayoutEffect(() => {
    django('enttanim/islem_tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setEntIslemTipDic(dict);
      setEntIslemTip(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django('enttanim/is_bolumu').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setEntIslemBolumDic(dict);
      setEntIslemBolum(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(false);
    }
  }, [props]);

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  const handleGetMoreList = (page, mainContainerRef, query) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setLoadingDetayMore(true);
    const url = `${PATH}/${ID}/islemdetay`
    const config = {
      params: {
        size: PER_PAGE,
        page
      },
    };
    for (let index = 0; index < query.length; index++) {
      const element = query[index];
      if (element.value !== "") {
        config.params[element.key] = `*${element.value}*`
      }
    }
    django(url, config).then(({ data }) => {
      const datas = data.map((detay) => ({ id: detay.id, kodu: detay.kodu, mesaj: detay.mesaj }));
      setGenelFormListe((prev) => [...prev, ...datas]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setLoadingDetayMore(false);
    });
  };

  const searchListSet = (query) => {
    let url = `${PATH}/${ID}/islemdetay`;
    let urlCount = `${PATH}/${ID}/islemdetay/count`;
    const config = {
      params: {
        size: PER_PAGE,
      },
    };
    const configCount = {
      params: {}
    }
    for (let index = 0; index < query.length; index++) {
      const element = query[index];
      if (element.value !== "") {
        config.params[element.key] = `*${element.value}*`;
        configCount.params[element.key] = `*${element.value}*`;
      }
    }
    const handleGet = ({ url, config }) => {
      setLoadingDetay(true);
      django(url, config).then(({ data }) => {
        const forms = data.map((detay) => (
          {
            id: detay.id,
            kodu: detay.kodu,
            mesaj: detay.mesaj,
          }
        ));
        setGenelFormListe(forms);
        setHataDetay(null);
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            setHataDetay(true);
          }
        }
      }).finally(() => {
        setLoadingDetay(false);
      });
    };
    const handleGetCount = ({ urlCount, configCount }) => {
      django(urlCount, configCount).then(({ data }) => {
        setGenelFormListeCount(data);
        setHataDetay(null);
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            setHataDetay(true);
          }
        }
      })
    };
    handleGetCount({ urlCount, config });
    handleGet({ url, config });
  }

  return (
    <>
      <LayoutDetailWithoutTabs
        loading={loading}
        title={TITLE}
        single={props.single}

        canClose={true}
        canOpenNew={true}
        canAdd={false}
        canReload={true}
        canSave={false}
        canCancel={false}
        canDelete={false}
        canPrevNext={true}
        disableCloseButton={loading}
        disableReloadButton={loading}
        disablePreviousButton={props.disablePreviousButton}
        disableNextButton={props.disableNextButton}

        onClickCloseButton={props.onClose}
        onClickReloadButton={handleReload}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => { window.open(`${window.location.origin}/${props.singledetail}/${props.ustId}/${ID}/`, '_blank'); }}
      >
        <TabGenel
          loading={loading}
          loadingDetay={loadingDetay}
          loadingMore={loadingDetayMore}
          hata={hataGenel}
          hataDetay={hataDetay}

          form={genelForm}

          liste={genelFormListe}
          listeCount={genelFormListeCount}
          page={PAGE}
          pagesCount={PAGES_COUNT}
          getMore={handleGetMoreList}
          rowCount={PER_PAGE}
          path={`${PATH}/${ID}/islemdetay`}
          searchListSet={searchListSet}

          entIslemTip={entIslemTip}
          entIslemTipDic={entIslemTipDic}
          entIslemBolum={entIslemBolum}
          entIslemBolumDic={entIslemBolumDic}
        />
      </LayoutDetailWithoutTabs>
    </>
  );
}

export default IslemDetail;