import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  assign as _assign,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList } from '../../base/InputErrors';

import TabGenel from './EvrakDurumTanimDetailTab_Genel';

const PATH = 'evrakdurumtanim';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
];

const bos_mail = {
  baslik: '',
  email: ''
}

const FORM_DEFAULT = {
  tanim: '',
  siralama: '0',
  aktif: true,
  varsayilan: true,
  mail_sablon: "-1",
  mail_sablon_adi: "",
  mail_sablon_site: "-1",
  mail_sablon_site_adi: "",
  pdf_sablon: "-1",
  pdf_sablon_adi: "",
  mail_sablonlar: [],
  mail_sablonlar_site: [],
  magazalar: [],
  evrak_tip: '-1',
  gonderen_mail: bos_mail,
  mail_gondersin: true,
  kullaniciya_mail_gondersin: true,
  alici_mailleri: [],
  pdf_sablonlar: [],
};

const EvrakDurumTanimDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingMailAdd, setLoadingMailAdd] = useState(false);
  const [hataGenel, setHataGenel] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [lastUpdateTimeList, setLastUpdateTimeList] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(FORM_DEFAULT);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [tempListSiparis, setTempListSiparis] = useState([]);
  const [tempListOdeme, setTempListOdeme] = useState([]);

  const [tipList, setTipList] = useState([]);
  const [tipListDic, setTipListDic] = useState({});

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_ADD = useMemo(() => props.addnew, [props]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    return isEqualGenel;
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    setLoading(true);
    const debounce = setTimeout(() => {
      if (!IS_ADD) {
        django(`${PATH}/${ID}`).then(({ data }) => {
          const form = {
            tanim: data.tanim,
            siralama: String(data.siralama),
            aktif: data.aktif,
            varsayilan: data.varsayilan,
            mail_sablon: String(data.mail_sablon?.id ?? "-1"),
            mail_sablon_adi: data.mail_sablon?.tanim ?? "",
            mail_sablon_site: String(data.mail_sablon_site?.id ?? "-1"),
            mail_sablonlar: data.mail_sablonlar,
            mail_sablonlar_site: data.mail_sablonlar_site,
            mail_sablon_site_adi: data.mail_sablon_site?.tanim ?? "",
            magazalar: data.magazalar,
            evrak_tip: String(data.evrak_tip.key),
            gonderen_mail: data.gonderen_mail ?? bos_mail,
            mail_gondersin: data.mail_gondersin,
            kullaniciya_mail_gondersin: data.kullaniciya_mail_gondersin,
            alici_mailleri: data.alici_mailleri ?? [],
            pdf_sablon: String(data.pdf_sablon?.id ?? "-1"),
            pdf_sablon_adi: data.pdf_sablon?.tanim ?? "",
            pdf_sablonlar: data.pdf_sablonlar,
          };
          setGenelFormDefault(form);
          setGenelForm(form);
          setHataGenel(null);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataGenel(true);
            }
          }
        }).finally(() => {
          setTabs(TABS_DEFAULT);
          setLoading(false);
          setGenelError(null);
          setAdd(false);
        });
      } else {
        setTabs(TABS_DEFAULT);
        setGenelForm(FORM_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
      }
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoading(false);
    };
  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    const setList = async (list) => {
      let tempListSiparis = [];
      let tempListOdeme = [];
      if (list.length > 0) {
        for (const li of list) {
          const res = (await django(`sablon_paket/${li.id}/sablonmail/mini_list?tip=0`)).data;
          const res2 = (await django(`sablon_paket/${li.id}/sablonmail/mini_list?tip=5`)).data;
          tempListSiparis = [...tempListSiparis, ...res];
          tempListOdeme = [...tempListOdeme, ...res2];
        }
      }
      return [tempListSiparis, tempListOdeme];
    }
    django('sablon_paket/mini_list').then(({ data }) => {
      setList(data).then((res) => {
        setTempListSiparis(res[0]);
        setTempListOdeme(res[1]);
      })
    })
  }, [lastUpdateTimeList]);

  useLayoutEffect(() => {
    django('evrak/evrak_tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setTipListDic(dict);
      setTipList(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, property2, value) => {
    if (property !== "evrak_tip" && genelForm.evrak_tip === "-1") {
      enqueueSnackbar("Önce Evrak Tipi Seçin", { variant: 'error' });
    } else {
      if (property2) {
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next[property][property2] = value;
          });
        });
      } else {
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next[property] = value;
            if (property === "mail_sablonlar") {
              const isExist = value.find(x => x.id === Number(genelForm.mail_sablon));
              if (!isExist) {
                next["mail_sablon"] = "-1";
                next["mail_sablon_adi"] = "";
              }
            }
            if (property === "mail_sablon") {
              const val = genelForm.mail_sablonlar.find(x => x.id === Number(value)).tanim;
              next["mail_sablon_adi"] = val;
            }
            if (property === "mail_sablonlar_site") {
              const isExist = value.find(x => x.id === Number(genelForm.mail_sablon_site));
              if (!isExist) {
                next["mail_sablon_site"] = "-1";
                next["mail_sablon_site_adi"] = "";
              }
            }
            if (property === "mail_sablon_site") {
              const val = genelForm.mail_sablonlar_site.find(x => x.id === Number(value)).tanim;
              next["mail_sablon_site_adi"] = val;
            }
            if (property === "pdf_sablonlar") {
              const isExist = value.find(x => x.id === Number(genelForm.pdf_sablon));
              if (!isExist) {
                next["pdf_sablon"] = "-1";
                next["pdf_sablon_adi"] = "";
              }
            }
            if (property === "pdf_sablon") {
              const val = genelForm.pdf_sablonlar.find(x => x.id === Number(value)).tanim;
              next["pdf_sablon_adi"] = val;
            }
            if (property === "evrak_tip") {
              next["mail_sablon"] = "-1";
              next["mail_sablon_adi"] = "";
              next["mail_sablonlar"] = [];
              next["mail_sablon_site"] = "-1";
              next["mail_sablon_site_adi"] = "";
              next["mail_sablonlar_site"] = [];
              next["pdf_sablon"] = "-1";
              next["pdf_sablon_adi"] = "";
              next["pdf_sablonlar"] = [];
            }
          });
        });
      }
    }
  };

  const handleGetKullaniciFromDepartman = async (items) => {
    setLoadingMailAdd(true);
    const copymails = [...genelForm.alici_mailleri];
    if (items.length > 0) {
      const kullanicilist = [...new Set([...[].concat.apply([], items.map(x => x.kullanicilar))])];
      for (const id of kullanicilist) {
        const res = (await django(`kullanici/${id}`)).data;
        if (res.email && !copymails.includes(res.email)) {
          copymails.push(res.email)
        }
      }
    }
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next["alici_mailleri"] = copymails;
      });
    });
    setLoadingMailAdd(false);
  }

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);

    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
    setLastUpdateTimeList(Date.now());
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);

    if (!IS_ADD) {
      setAdd(false);
    }
  };

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Durum Tanım', pagename: 'durum', prop2: '' },
      { prop: 'mail_sablon', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'mail_sablonlar', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'magazalar', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'evrak_tip', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'gonderen_mail', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'email' },
    ];
    let errors = [];
    await checkAllList('evrakdurumtanim', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = () => {
    checkAll().then((res) => {
      if (_isEmpty(res)) {
        let requestoptions = {};
        const data = {
          ...genelForm,
          siralama: genelForm.siralama ? Number(genelForm.siralama) : 0,
          mail_sablon: genelForm.mail_sablon !== "-1" ? Number(genelForm.mail_sablon) : null,
          mail_sablonlar: genelForm.mail_sablonlar?.map((m) => m.id),
          mail_sablon_site: genelForm.mail_sablon_site !== "-1" ? Number(genelForm.mail_sablon_site) : null,
          mail_sablonlar_site: genelForm.mail_sablonlar_site?.map((m) => m.id),
          pdf_sablon: genelForm.pdf_sablon !== "-1" ? Number(genelForm.pdf_sablon) : null,
          pdf_sablonlar: genelForm.pdf_sablonlar?.map((m) => m.id),
          magazalar: genelForm.magazalar?.map((m) => m.id),
          evrak_tip: genelForm.evrak_tip !== '-1' ? Number(genelForm.evrak_tip) : null,
          alici_mailleri: genelForm.alici_mailleri,
        }
        delete data.mail_sablon_adi;
        delete data.mail_sablon_site_adi;
        delete data.pdf_sablon_adi;
        if (IS_EQUAL) {
          requestoptions = null;
        } else {
          requestoptions = {
            config: {
              method: add ? 'POST' : 'PUT',
              url: add ? 'evrakdurumtanim' : `evrakdurumtanim/${ID}`,
              data: data
            },
            successMessage: `Durum ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
            errorMessageUnexpected: `Durum ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
          }
        }
        const start = async () => {
          let errorCounts = 0;
          if (requestoptions !== null) {
            setGenelError(null);
            await django(requestoptions.config).then(({ data }) => {
              enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
              if (!add) {
                props.onUpdated();
              }
              if (props.single && add) {
                history.push(URLS.evrak.evrakdurumtanim.detail(data.id, "detay"));
              }
              if (!props.single && add) {
                props.onAdded(data);
              }
            }).catch((error) => {
              setSelectedTabIndex(selectedTabIndex);
              if (error.response) {
                if (error.response.status === 500) {
                  enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
                } else {
                  setGenelError(error.response.data);
                }
              } else {
                enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
              }
              ++errorCounts;
            });
            if (errorCounts === 0) {
              setLastUpdateTime(Date.now());
              setLastUpdateTimeList(Date.now());
            }
          }
        }
        start();
      }
    })
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.evrak.evrakdurumtanim.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      let obj = {}
      let next = {};
      if (Object.keys(errors)[0] === 'gonderen_mail') {
        if (_has({ ...prev }, Object.keys(errors)[0])) {
          _assign(prev[Object.keys(errors)[0]], errors[Object.keys(errors)[0]])
          obj = { ...prev }
        } else {
          obj = { ...prev, ...errors }
        }
      } else {
        obj = { ...prev, ...errors }
      }
      if (_has(obj, "gonderen_mail")) {
        const gonderen_mail = _pickBy(obj["gonderen_mail"], _identity);
        if (_isEmpty(gonderen_mail)) {
          next = _pickBy({ ...obj, 'gonderen_mail': null }, _identity);
        } else {
          next = _pickBy({ ...obj, 'gonderen_mail': gonderen_mail }, _identity);
        }
      } else {
        next = _pickBy(obj, _identity);
      }
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            loadingMailAdd={loadingMailAdd}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}
            tempListSiparis={tempListSiparis}
            tempListOdeme={tempListOdeme}
            tipList={tipList}
            tipListDic={tipListDic}
            setLastUpdateTimeList={() => setLastUpdateTimeList(Date.now())}
            handleGetKullaniciFromDepartman={handleGetKullaniciFromDepartman}

            onCheckErrors={(errors) => handleErrors(errors)}

          />
        )}

      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Durum siliniyor lütfen bekleyin'
                : 'Bu durumu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
    </>
  );
};

export default EvrakDurumTanimDetail;

