import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import {
  isArray as _isArray,
  has as _has,
  pickBy as _pickBy,
} from 'lodash';

import { QUERY_STRING_OPTIONS } from '../constants/options';

const useFilter = () => {
  const [filter, setFilter] = useState(null);

  const location = useLocation();

  // set filter search
  useEffect(() => {
    if (location.search !== null) {
      const q = queryString.parse(location.search, QUERY_STRING_OPTIONS);

      if (_has(q, 'sort') && !_isArray(q.sort)) {
        q.sort = [q.sort];
      }

      setFilter(_pickBy(q));
    }
  }, [location.search]);

  return filter;
};

export default useFilter;
