import React, { useCallback, useMemo } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Tooltip
} from '@material-ui/core';

import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';

import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
};

const ParaBirimDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.layoutContainer}>
              <ul className={classes.layoutFormList}>
                <li className={classes.layoutFormItem} id="parabirim">
                  <label className={classes.layoutFormItemLabel} htmlFor="parabirim">Para Birim *</label>
                  <Tooltip title={props.error?.parabirim ? props.error.parabirim[0] : (props.form?.parabirim === "-1" || _isNil(props.form?.parabirim)) ? '' : props.paraBirimDic ? props.paraBirimDic[props.form.parabirim] : ''}>
                    <TextField
                      {...createTextFieldProps('parabirim')}
                      onChange={(e) => props.onChangeForm('parabirim', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'parabirim', true, false, false, '', '', '')}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="-1" disabled>Seçilmedi</option>
                      {props.paraBirim?.map((pb) => (
                        <option key={pb.id} value={pb.id}>{pb.tanim}</option>
                      ))}
                    </TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="hedef_parabirim">
                  <label className={classes.layoutFormItemLabel} htmlFor="hedef_parabirim">Hedef Para Birim *</label>
                  <Tooltip title={props.error?.hedef_parabirim ? props.error.hedef_parabirim[0] : (props.form?.hedef_parabirim === "-1" || _isNil(props.form?.hedef_parabirim)) ? '' : props.paraBirimDic ? props.paraBirimDic[props.form.hedef_parabirim] : ''}>
                    <TextField
                      {...createTextFieldProps('hedef_parabirim')}
                      onChange={(e) => props.onChangeForm('hedef_parabirim', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'hedef_parabirim', true, false, false, '', '', '')}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="-1" disabled>Seçilmedi</option>
                      {props.paraBirim?.map((pb) => (
                        <option key={pb.id} value={pb.id}>{pb.tanim}</option>
                      ))}
                    </TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="tarih">
                  <label className={classes.layoutFormItemLabel} htmlFor="tarih">Tarih</label>
                  <Tooltip title={props.error?.tarih ? props.error.tarih[0] : _isNil(props.form?.tarih) ? '' : moment(props.form.tarih).format('DD/MM/YYYY, HH.mm')}>
                    <TextField
                      {...createTextFieldProps('tarih')}
                      type="datetime-local"
                      onChange={(e) => props.onChangeForm('tarih', e.target.value)}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="deger">
                  <label className={classes.layoutFormItemLabel} htmlFor="deger">Değer *</label>
                  <Tooltip title={props.error?.deger ? props.error.deger[0] : _isNil(props.form?.deger) ? '' : props.form.deger}>
                    <TextField
                      {...createTextFieldProps('deger')}
                      onChange={(e) => props.onChangeForm('deger', e.target.value)}
                      onBlur={() => handleInputErrors('kurlar', props.form, props.formdefault, props.onCheckErrors, add, 'deger', true, false, false, '', '', '')}
                      inputProps={{
                        style: { textAlign: 'right' }
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
              </ul>
            </div>
          )}
        </>
      }
    </>
  );
});

export default ParaBirimDetailTabGenel;
