import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../../pages/base/LayoutTableDetail';
import Detail from './HatirlaticiDetail';


const COLUMNS_DEFAULT = [
  { id: 'tanim',            tableProp: 'tanim',            filterProp: 'tanim',            label: 'Hatırlatıcı Tanım',      element: 'input',  type: 'text',     arrayTableProp: null, show: true, sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'bitti_mi',         tableProp: 'bitti_mi',         filterProp: 'bitti_mi',         label: 'Tamamla',                element: 'select', type: 'boolean',  arrayTableProp: null, show: true, sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'olusturma_zamani', tableProp: 'olusturma_zamani', filterProp: 'olusturma_zamani', label: 'Oluşturma',              element: 'input',  type: 'datetime', arrayTableProp: null, show: true, sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null, multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null, multipleSelectOptionViewProp: null, shortDescription: null,  selectKey: null,  selectValue: null  },
];

const PageHatirlaticiList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        return column;
      });
    });
  }, []);

  return (
    <>
      <Layout
        title="HATIRLATICILAR"
        path="hatirlatici"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="hatirlatici"
      />
    </>
  );
};

export default PageHatirlaticiList;
