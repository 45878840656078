import React, { useState, useMemo, useLayoutEffect } from 'react';
import clsx from 'clsx';

import {
  isNil as _isNil
} from 'lodash';

import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import ListSelector from '../../../components/ListSelector';
import { Hata } from '../../base/Hata';
import MultipleSelectList from '../../../components/MultipleSelectList';

const styles = ({ spacing }) => {
  return {
    formContainer: {
      position: 'relative',
      width: '100%',
      //height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    form2Column: {
      // display: 'grid',
      // gridTemplateColumns: '50% 50%'
      display: 'flex',
      flexWrap: 'wrap'
    },
    formColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: 300,
      '&': {
        marginRight: 32,
        marginBottom: 5,
      },
    },

  };
};

const UrunDetailXTabOzellikler = withStyles(styles)((props) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const LOADING2 = useMemo(() => props.loading2, [props.loading2]);
  const HATA2 = useMemo(() => props.hata2, [props.hata2]);
  const [openSelector, setOpenSelector] = useState(false);

  const LISTE = useMemo(() => props?.ozelliklist ?? null, [props.ozelliklist]);
  const LISTE2 = useMemo(() => props?.ozelliklist2 ?? null, [props.ozelliklist2]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [path, setPath] = useState(null);

  useLayoutEffect(() => {
    if (LISTE.length > 0) {
      setPath({ ozellik_baslik: LISTE[selectedIndex].ozellikBaslik, default: LISTE[selectedIndex].array });
    }
  }, [selectedIndex, LISTE]);

  const getOpenSelector = (index) => {
    setSelectedIndex(index);
    setOpenSelector(true);
  }

  const handleRemoveById = (id, selectindex) => {
    const copylist = [...LISTE[selectindex].array];
    const index = copylist.findIndex((c) => c.id === id);
    if (index > -1) {
      copylist.splice(index, 1);
      props.onChangeFormData(copylist, LISTE[selectindex].ozellikBaslik)
    }
  }

  const handleChangeForm = (index, index2, value) => {
    props.onChangeFormData2(index, index2, value);
  };

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={clsx(props.classes.formContainer)}>
                <div className={clsx(props.classes.form2Column)}>
                  {LISTE && LISTE.map((l, index) => (
                    <div key={`${index}-ozellik`} className={clsx(props.classes.formColumn)}>
                      <MultipleSelectList
                        headername={l.ozellikBaslik.tanim}
                        name={l.ozellikBaslik.tanim}
                        name2={l.ozellikBaslik.tanim}
                        form={props.ozelliklist}
                        list={l.array ?? []}
                        listlength={l.array?.length ?? 0}
                        opendialog={getOpenSelector}
                        keyProp="id"
                        valueProp="tanim"
                        removefonk={handleRemoveById}
                        loading={props.loading}
                        index={index}
                        multiple={true}
                        newPath="tanimlar/ozellikdetay"
                        ustId={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {openSelector &&
                <Dialog
                  open={openSelector}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => setOpenSelector(false)}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelector
                      title={path.ozellik_baslik.tanim}
                      listPath={`ozellikbaslik/${path.ozellik_baslik.id}/ozellikdeger/mini_list?aktif=true`}
                      listCountPath={`ozellikbaslik/${path.ozellik_baslik.id}/ozellikdeger/count?aktif=true`}
                      keyProp='id'
                      valueProp='tanim'
                      defaultItems={path.default}
                      onChangeItems={(items) => props.onChangeFormData(items, path.ozellik_baslik)}
                      onClose={() => setOpenSelector(false)}
                      newPath="tanimlar/ozellikdetay"
                      ustId={path.ozellik_baslik.id}
                    />
                  </DialogContent>
                </Dialog>
              }

            </>
          )}
        </>
      }
      {!LOADING2 &&
        <>
          {HATA2 && (
            <Hata />
          )}
          {!HATA2 && (
            <>
              <div className={clsx(props.classes.formContainer)}>
                <div className={clsx(props.classes.form2Column)}>
                  {LISTE2 && LISTE2.map((l, index) => (
                    <div key={`${index}-ozellikmetin`} className={clsx(props.classes.formColumn)}>
                      <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                        <header className="flex items-center justify-between py-2 px-4">
                          <Button
                            className="pointer-events-none"
                            color="inherit"
                          >{l.title}</Button>
                        </header>
                        <main className="border-t border-palette-action-hover py-1 max-h-48 overflow-y-auto">
                          <ul className="flex flex-col list-none m-0 p-0">
                            {l.diller.map((dil, index2) => (
                              <li className="flex items-center justify-start py-1 pl-4 pr-8 space-x-2" key={`${index2}-ozellikmetindil`}>
                                <Tooltip title={_isNil(dil?.form?.tanim) ? "" : dil.form.tanim}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth={true}
                                    value={dil.form.tanim}
                                    onChange={(e) => handleChangeForm(index, index2, e.target.value)}
                                    label={dil.dil.tanim}
                                    InputLabelProps={{ shrink: true }}
                                  ></TextField>
                                </Tooltip>
                              </li>
                            ))}
                          </ul>
                        </main>
                      </section>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      }
    </>
  );
});

export default UrunDetailXTabOzellikler;
