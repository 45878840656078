import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../base/LayoutTableDetail';
import Detail from './KullaniciOdemeLogDetail';


const COLUMNS_DEFAULT = [
  { id: 'tutar',                tableProp: 'tutar',                         filterProp: 'tutar',                    label: 'Ödeme Tutarı',                element: 'input',                 type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,           multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'result',               tableProp: 'result',                        filterProp: 'result',                   label: 'Ödeme Gerçekleşti mi',        element: 'select',                type: 'boolean',  arrayTableProp: null,       show: true,   sortable: false,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,           multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },  
  { id: 'odeme_son_durum',      tableProp: 'odeme_son_durum',               filterProp: 'odeme_son_durum',          label: 'Ödeme Akışındaki Son Mesaj',  element: 'input',                 type: 'text',     arrayTableProp: null,       show: true,   sortable: true,   highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,           multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null }, 
  { id: 'evrak',                tableProp: 'evrak.kodu',                    filterProp: 'evrak.id',                 label: 'Evrak',                       element: 'select-multiple',       type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'evrak',        multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'kodu',     multipleSelectOptionViewProp: 'kodu',     shortDescription: null, selectKey: null,  selectValue: null, newpath: null },  
  { id: 'odeme_tanim',          tableProp: 'odeme_tanim.tanim',             filterProp: 'odeme_tanim.id',           label: 'Ödeme Tanım',                 element: 'select-multiple',       type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'odemetanim',   multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null, selectKey: null,  selectValue: null, newpath: 'tanimlar/odeme_tanim' },  
  { id: 'olusturan_kullanici',  tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',   label: 'Oluşturan Kullanıcı',         element: 'select-multiple',       type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',    multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'degistiren_kullanici', tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',  label: 'Değiştiren Kullanıcı',        element: 'select-multiple',       type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',    multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'olusturma_zamani',     tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',         label: 'Oluşturma',                   element: 'input',                 type: 'datetime', arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,           multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'degistirme_zamani',    tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',        label: 'Son Değişiklik',              element: 'input',                 type: 'datetime', arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,           multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
];

const PageOdemeLogList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        return column;
      });
    });
  }, []);

  return (
    <>
      <Layout
        title="SANAL POS LOGLARI"
        path="kullaniciodemelog"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="kullaniciodemelog"
        disableAdd={true}
      />
    </>
  );
};

export default PageOdemeLogList;
