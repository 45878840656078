import './styles/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';

import App from './App';
import Store from "./libs/store";


Store.set('themeChoice', {theme: 'light'})
ReactDOM.render(
  <Router>
    <App/>
  </Router>,
  document.getElementById('root')
);
