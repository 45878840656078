import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import {
  HelpOutline as IconHelp,
} from '@material-ui/icons';

import { Editor } from "@tinymce/tinymce-react";
import { withStyles } from '@material-ui/core/styles';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 1, 1, 4),
    },
    column: {
      '& + &': {
        marginTop: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },

    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    formItem: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
    layoutFormItemEditor: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: 5,
    },
  };
};

const FormIsleyiciAyar = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const LOADINGAYAR = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATAAYAR = useMemo(() => props.hata, [props.hata]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);
  const [selectedTabIndexAyar, setSelectedTabIndexAyar] = useState(props.selectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  // const FORM_AYAR = useMemo(() => props?.ayarform?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  // const ERROR_AYAR = useMemo(() => props?.ayarform?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  const FORM_AYAR = useMemo(() => props?.ayarform?.[selectedTabIndexAyar]?.form ?? null, [props, selectedTabIndexAyar]);
  const ERROR_AYAR = useMemo(() => props?.ayarform?.[selectedTabIndexAyar]?.errors ?? null, [props, selectedTabIndexAyar]);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  useLayoutEffect(() => {
    const islemciId = props.forms[selectedTabIndex]?.form?.id ?? null;
    const ayarIndex = props.ayarform?.findIndex((x) => x.form.isleyici === islemciId);
    setSelectedTabIndexAyar(ayarIndex);
  }, [props, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((eleman, index) => {
        return {
          label: (index + 1) + "- " + props.tipDic[eleman.form.tip],
          hasError: eleman.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms, props.tipDic]);

  useLayoutEffect(() => {
    if (!props.isleyiciAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.isleyiciAdd, COUNT_TABS]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM_AYAR, `${name}`) || defaultValue,
      error: _has(ERROR_AYAR, `${name}`),
      fullWidth: true,
    };
  }, [FORM_AYAR, ERROR_AYAR]);

  const handleChangeForm = (value) => {
    props.onChangeForm(selectedTabIndex, value);
  };

  const handleChangeFormAyar = (property, value) => {
    props.onChangeAyarForm(selectedTabIndex, property, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleSetErrorsAyar = (errors) => {
    props.onCheckErrorsAyar(errors, selectedTabIndex);
  }





  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <LayoutDetailVerticalTab
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                  add={props.onAddNewTab}
                  buttonText={"YENİ İŞLEYİCİ EKLE"}
                  buttonShow={props.isleyiciAdd}
                  delete={handleDetailDelete}
                  flexDirection='column'
                >
                  {COUNT_TABS === 0 && (
                    <div className="flex flex-col items-start justify-start p-4 space-y-4">
                      <Typography variant="body1">
                        Form tanımına ait işleyici bulunamadı.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disableElevation
                        onClick={props.onAddNewTab}
                      >YENİ İŞLEYİCİ EKLE</Button>
                    </div>
                  )}
                  {COUNT_TABS > 0 &&
                    <>
                      <div className={classes.tabDiv}>
                        <div className={classes.column}>
                          <ul className={classes.formList}>

                            <li className={classes.formItem} id="tip">
                              <label className={classes.formItemLabel} htmlFor="tip">Tip *</label>
                              <Tooltip title={ERROR?.tip ? ERROR.tip[0] : _isNil(FORM?.tip) ? '' : props.tipDic ? props.tipDic[FORM.tip] : ''}>
                                <TextField
                                  {...createTextFieldProps('tip', '')}
                                  onChange={(e) => handleChangeForm(e.target.value)}
                                  select
                                  SelectProps={{ native: true }}
                                >
                                  {props?.tip.map((tip) => (
                                    <option key={tip.key} value={tip.key}>{tip.value}</option>
                                  ))}
                                </TextField>
                              </Tooltip>
                            </li>
                            {!LOADINGAYAR && (
                              <>
                                {HATAAYAR && (
                                  <Hata />
                                )}
                                {!HATAAYAR && (
                                  <>
                                    {FORM_AYAR && FORM.tip === "1" && (
                                      <>
                                        <li className={classes.formItem} id="gonderecek_mail_adresi">
                                          <label className={classes.formItemLabel} htmlFor="gonderecek_mail_adresi">Gönderecek Mail Adresi</label>
                                          <Tooltip title={ERROR_AYAR?.gonderecek_mail_adresi ? ERROR_AYAR.gonderecek_mail_adresi[0] : _isNil(FORM_AYAR?.gonderecek_mail_adresi) ? '' : FORM_AYAR.gonderecek_mail_adresi}>
                                            <TextField
                                              {...createTextFieldProps2('gonderecek_mail_adresi', '')}
                                              onChange={(e) => handleChangeFormAyar('gonderecek_mail_adresi', e.target.value)}
                                              inputProps={{
                                                maxLength: 254,
                                              }}
                                            ></TextField>
                                          </Tooltip>
                                          <IconButton
                                            size="small"
                                            className={classes.button2}
                                            disableRipple
                                            onClick={() => {
                                              setHelpText('“Maili gönderecek mail adresini özel olarak belirtmek isterseniz bu alanı kullanın, varsayılan olarak site magazasının mail adres bilgisi kullanılır.”');
                                              setHelpOpen(true)
                                            }}
                                          ><IconHelp /></IconButton>
                                        </li>
                                        <li className={classes.formItem} id="gonderilecek_mail_adresi">
                                          <label className={classes.formItemLabel} htmlFor="gonderilecek_mail_adresi">Gönderilecek Mail Adresi</label>
                                          <Tooltip title={ERROR_AYAR?.gonderilecek_mail_adresi ? ERROR_AYAR.gonderilecek_mail_adresi[0] : _isNil(FORM_AYAR?.gonderecek_mail_adresi) ? '' : FORM_AYAR.gonderilecek_mail_adresi}>
                                            <TextField
                                              {...createTextFieldProps2('gonderilecek_mail_adresi', '')}
                                              onChange={(e) => handleChangeFormAyar('gonderilecek_mail_adresi', e.target.value)}
                                              onBlur={() => handleInputErrors2(FORM_AYAR, 'gonderilecek_mail_adresi', handleSetErrorsAyar)}
                                              inputProps={{
                                                maxLength: 254,
                                              }}
                                            ></TextField>
                                          </Tooltip>
                                        </li>
                                        <li className={classes.formItem} id="konu">
                                          <label className={classes.formItemLabel} htmlFor="konu">Tarafınıza Gelecek Mailin Konusu</label>
                                          <Tooltip title={ERROR_AYAR?.konu ? ERROR_AYAR.konu[0] : _isNil(FORM_AYAR?.konu) ? '' : FORM_AYAR.konu}>
                                            <TextField
                                              {...createTextFieldProps2('konu', '')}
                                              onChange={(e) => handleChangeFormAyar('konu', e.target.value)}
                                              onBlur={() => handleInputErrors2(FORM_AYAR, 'konu', handleSetErrorsAyar)}
                                              inputProps={{
                                                maxLength: 255,
                                              }}
                                            ></TextField>
                                          </Tooltip>
                                        </li>
                                      </>
                                    )}

                                    {FORM_AYAR && FORM.tip === "2" && (
                                      <li className={classes.formItem} id="gonderilecek_adres">
                                        <label className={classes.formItemLabel} htmlFor="gonderilecek_adres">Gönderilecek Form Adresi</label>
                                        <Tooltip title={ERROR_AYAR?.gonderilecek_adres ? ERROR_AYAR.gonderilecek_adres[0] : _isNil(FORM_AYAR?.gonderilecek_adres) ? '' : FORM_AYAR.gonderilecek_adres}>
                                          <TextField
                                            {...createTextFieldProps2('gonderilecek_adres', '')}
                                            onChange={(e) => handleChangeFormAyar('gonderilecek_adres', e.target.value)}
                                            onBlur={() => handleInputErrors2(FORM_AYAR, 'gonderilecek_adres', handleSetErrorsAyar)}
                                            inputProps={{
                                              maxLength: 200,
                                            }}
                                          ></TextField>
                                        </Tooltip>
                                      </li>
                                    )}

                                    {FORM_AYAR && FORM.tip === "3" && (
                                      <>
                                        <li className={classes.formItem} id="gonderecek_mail_adresi">
                                          <label className={classes.formItemLabel} htmlFor="gonderecek_mail_adresi">Gönderecek Mail Adresi</label>
                                          <Tooltip title={ERROR_AYAR?.gonderecek_mail_adresi ? ERROR_AYAR.gonderecek_mail_adresi[0] : _isNil(FORM_AYAR?.gonderecek_mail_adresi) ? '' : FORM_AYAR.gonderecek_mail_adresi}>
                                            <TextField
                                              {...createTextFieldProps2('gonderecek_mail_adresi', '')}
                                              onChange={(e) => handleChangeFormAyar('gonderecek_mail_adresi', e.target.value)}
                                              inputProps={{
                                                maxLength: 254,
                                              }}
                                            ></TextField>
                                          </Tooltip>
                                          <IconButton
                                            size="small"
                                            className={classes.button2}
                                            disableRipple
                                            onClick={() => {
                                              setHelpText('“Maili gönderecek mail adresini özel olarak belirtmek isterseniz bu alanı kullanın, varsayılan olarak site magazasının mail adres bilgisi kullanılır.”');
                                              setHelpOpen(true)
                                            }}
                                          ><IconHelp /></IconButton>
                                        </li>
                                        <li className={classes.formItem} id="konu">
                                          <label className={classes.formItemLabel} htmlFor="konu">Gönderilecek Mailin Konusu</label>
                                          <Tooltip title={ERROR_AYAR?.konu ? ERROR_AYAR.konu[0] : _isNil(FORM_AYAR?.konu) ? '' : FORM_AYAR.konu}>
                                            <TextField
                                              {...createTextFieldProps2('konu', '')}
                                              onChange={(e) => handleChangeFormAyar('konu', e.target.value)}
                                              onBlur={() => handleInputErrors2(FORM_AYAR, 'konu', handleSetErrorsAyar)}
                                              inputProps={{
                                                maxLength: 255,
                                              }}
                                            ></TextField>
                                          </Tooltip>
                                        </li>
                                        <li className={classes.layoutFormItemEditor} id="mail_sablon">
                                          <label className={classes.formItemLabel} htmlFor="mail_sablon">Gönderilecek Mail Şablon</label>
                                          <div style={{ borderWidth: 1, borderStyle: 'solid', borderColor: _has(ERROR_AYAR, "mail_sablon") ? 'red' : 'transparent' }}>
                                            <Editor
                                              initialValue={_result(FORM_AYAR, 'mail_sablon') || ""}
                                              init={{
                                                plugins: [
                                                  'advlist autolink lists link image charmap print preview anchor',
                                                  'searchreplace visualblocks code fullscreen',
                                                  'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo copy cut paste remove removeformat selectall | formatselect | fontselect | fontsizeselect |' +
                                                  'bold italic underline lineheight backcolor forecolor blockquote | alignleft aligncenter ' +
                                                  'alignright alignjustify | bullist numlist outdent indent | ' +
                                                  'removeformat | code | help',
                                                language: 'tr',
                                                valid_elements: '+*[*]',
                                                valid_children: "+body[style]",
                                                verify_html: false
                                              }}
                                              onChange={(e) => handleChangeFormAyar('mail_sablon', e.target.getContent())}
                                            />
                                          </div>
                                        </li>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </>
                  }
                </LayoutDetailVerticalTab>
              </div>
              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default FormIsleyiciAyar;
