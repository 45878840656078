import React, { useMemo, useState, useLayoutEffect } from 'react';
import queryString from 'query-string';
import { QUERY_STRING_OPTIONS } from '../constants/options';
import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  isEmpty as _isEmpty,
  has as _has,
  isNil as _isNil,
  result as _result,
  isArray as _isArray
} from 'lodash';

import {
  TextField,
  Button,
  IconButton,
  Paper,
  AppBar,
  Toolbar,
  CircularProgress,
  Tooltip,
  Checkbox,
} from '@material-ui/core';

import {
  Close as IconClose,
} from '@material-ui/icons';

import { handleInputErrors, checkAllList } from '../pages/base/InputErrors';
import { Hata } from '../pages/base/Hata';
import { useSnackbar } from 'notistack';
import immer from 'immer';
import django from '../api/django';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    rowItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& + &': {
        marginTop: 5,
      },
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.primary.main}`
      },
    },
    rowLabel: {
      minWidth: 150,
      maxWidth: 150,
    },
    ozellikRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.primary.main}`
      },
    },
    ozellikRowLabel: {
      minWidth: 100,
      maxWidth: 100,
    },
    button: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
    },
  };
});



const FilterAddDialog = ({ filters, close, listPath, editablePropList, filterList, openFilters }) => {
  const [loading, setLoading] = useState(false);
  const [hata, setHata] = useState(null);

  const [filtreForm, setFiltreForm] = useState([]);
  const [filtreFormDefault, setFiltreFormDefault] = useState([]);
  const [filtreError, setFiltreError] = useState(null);
  const [filter, setFilter] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const IS_EQUAL = useMemo(() => {
    return _isEqual(filtreForm, filtreFormDefault)
  }, [filtreForm, filtreFormDefault]);

  useLayoutEffect(() => {
    let form = [];
    for (let i = 0; i < editablePropList.length; i++) {
      switch (editablePropList[i].type) {
        case "text":
          form.push({ key: editablePropList[i].key, type: editablePropList[i].type, label: editablePropList[i].label, required: editablePropList[i].required, propname: editablePropList[i].propname ?? '', pagename: editablePropList[i].pagename ?? '', [editablePropList[i].key]: "" });
          break;
        case "bool":
          form.push({ key: editablePropList[i].key, type: editablePropList[i].type, label: editablePropList[i].label, [editablePropList[i].key]: false });
          break;
        case "kosul":
          form.push({ key: editablePropList[i].key, type: editablePropList[i].type, label: editablePropList[i].label, [editablePropList[i].key]: filters });
          break;
        case "required":
          form.push({ key: editablePropList[i].key, type: editablePropList[i].type, label: editablePropList[i].label, [editablePropList[i].key]: editablePropList[i].value });
          break;
        default:
          break;
      }
    }
    setFiltreForm(form);
    setFiltreFormDefault(form);
    setFiltreError(null);
  }, [editablePropList, filters]);

  useLayoutEffect(() => {
    setLoading(true);
    async function setFilters() {
      try {
        let filterArray = [];
        if (filters) {
          const q = queryString.parse(filters, QUERY_STRING_OPTIONS);
          for (let k in q) {
            const obj = filterList.find(x => k.includes(x.key));
            if (obj.url) {
              if (obj.type === "entcat") {
                const query = q[k];
                let datas = [];
                if (_isArray(q[k])) {
                  for (let id of q[k]) {
                    const res = (await django(`${obj.url}/${id}`)).data;
                    datas = [...datas, res];
                  }
                } else {
                  const res = (await django(`${obj.url}/${query}`)).data;
                  datas = [...datas, res];
                }
                filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: [...datas].map(x => x[obj.valueKey]).join(', ') })
              } else if (obj.type === "select") {
                const datas = (await django(`${obj.url}`)).data;
                const value = datas.find(x => String(x[obj.propKey]) === q[k]);
                filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: value[obj.valueKey] });
              } else if (obj.type === "two_step") {
                let list = [];
                const datas1 = (await django(`${obj.url}`)).data;
                for (let v of datas1) {
                  const datas = (await django(`${obj.url}/${v.id}/${obj.url2}`)).data;
                  const newDatas = datas.map((x) => ({
                    id: x.id,
                    tanim: `${v[obj.valueKeyU]} - ${x[obj.valueKey]}`
                  }))
                  list = [...list, ...newDatas];
                }
                const value = [...list].filter(x => q[k].some(item => item === String(x.id))).map(x => x.tanim).join(', ');
                filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: value })
              } else {
                const query = _isArray(q[k]) ? q[k].join('&id=') : q[k];
                const datas = (await django(`${obj.url}?id=${query}`)).data;
                filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: [...datas].map(x => x[obj.valueKey]).join(', ') })
              }
            } else {
              if (obj.type === 'text' || obj.type === 'bool') {
                filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: q[k] })
              }
              if (obj.type === 'ozellik') {
                const liste = _isArray(q[k]) ? q[k].join(' - ') : q[k];
                const ozellikObj = { title: k.split('.')[1].replace(/([A-Z])/g, ' $1').replace(/^[\s_]+|[\s_]+$/g, '').replace(/[_\s]+/g, ' '), value: liste }
                const isExist = filterArray.findIndex(x => x.key === obj.key);
                if (isExist > -1) {
                  filterArray[isExist].value.push(ozellikObj);
                } else {
                  filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: [ozellikObj] })
                }
              }
            }
          }
        }
        setFilter(filterArray);
        setLoading(false);
      } catch (error) {
        setHata(true);
        setLoading(false);
      }
    }
    setFilters();
  }, [filters, filterList]);

  const handleChange = (index, property, value) => {
    setFiltreForm((prev) => {
      return immer(prev, (next) => {
        next[index][property] = value;
      });
    });
  }

  const handleErrors = (errors) => {
    setFiltreError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const checkAll = async (form) => {
    const list = [];
    for (let i = 0; i < editablePropList.length; i++) {
      if (editablePropList[i].required) {
        list.push({ prop: editablePropList[i].key, empty: true, unique: true, multiple: false, multipletext: '', propname: editablePropList[i].propname, pagename: editablePropList[i].pagename, prop2: '' },)
      }
    }
    let errors = [];
    await checkAllList(`${listPath}`, form, {}, handleErrors, true, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {
    setLoading(true);
    let data = {};
    for (let i = 0; i < filtreForm.length; i++) {
      data[filtreForm[i].key] = filtreForm[i][filtreForm[i].key];
    }
    checkAll(data).then((res) => {
      if (_isEmpty(res)) {
        const requestoptions = {
          config: {
            method: 'POST',
            url: `${listPath}`,
            data: data
          },
          successMessage: `Ekleme başarıyla tamamlandı`,
          errorMessageUnexpected: `Eklenirken beklenmeyen bir hata oluştu`,
        }
        setFiltreError(null);
        django(requestoptions.config).then(({ data }) => {
          enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
          close();
          openFilters();
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
            } else {
              setFiltreError(error.response.data);
            }
          } else {
            enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
          }
        }).finally(() => {
          setLoading(false);
        });
      }
    })
  }

  return (
    <>
      <Paper className="flex flex-col w-full h-full overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">Filtre</h3>
            <Tooltip title="Kapat">
              <IconButton
                edge="end"
                color="inherit"
                size="small"
                onClick={close}
              ><IconClose /></IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <main className="relative flex flex-grow flex-col overflow-hidden">
          <div className="h-full flex-grow">
            <div className="relative flex flex-col w-full h-full items-start overflow-hidden p-4">

              {!loading && (
                <>
                  {hata && (
                    <Hata />
                  )}
                  {!hata && (
                    <div className="flex flex-col w-full">
                      <div className={classes.layoutFormList}>
                        {filtreForm.map((item, index) => (
                          <>
                            {item.type === "text" && (
                              <div key={index} className={classes.layoutFormItem}>
                                <label className={classes.layoutFormItemLabel} htmlFor={item.key}>{item.label}</label>
                                <Tooltip title={filtreError?.[item.key] ? filtreError[item.key][0] : _isNil(item?.[item.key]) ? '' : item[item.key]}>
                                  <TextField
                                    name={item.key}
                                    variant='outlined'
                                    size='small'
                                    fullWidth={true}
                                    value={_result(item, item.key, "")}
                                    error={_has(filtreError, `${item.key}`)}
                                    onChange={(e) => handleChange(index, item.key, e.target.value)}
                                    onBlur={() => {
                                      if (item.required) {
                                        handleInputErrors(listPath, item, setFiltreFormDefault[index], handleErrors, false, [item.key], true, true, false, '', item.propname, item.pagename)
                                      }
                                    }}
                                    inputProps={{
                                      maxLength: 250
                                    }}
                                  ></TextField>
                                </Tooltip>
                              </div>
                            )}
                            {item.type === "bool" && (
                              <div key={index} className={classes.layoutFormItem}>
                                <label className={classes.layoutFormItemLabel} htmlFor={item.key}>{item.label}</label>
                                <Checkbox
                                  name={item.key}
                                  color="primary"
                                  checked={_result(item, item.key, false)}
                                  onChange={(e, checked) => handleChange(index, item.key, checked)}
                                ></Checkbox>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                      <div className='w-full flex flex-col'>
                        {filter.map((k) => (
                          <div className={classes.rowItem} key={k.key}>
                            <div className={classes.rowLabel}>{k.title}</div>
                            {k.type !== "ozellik" && (
                              <div>{k.value}</div>
                            )}
                            {k.type === "ozellik" && (
                              <div className='w-full flex flex-col'>
                                {k.value.map((o) => (
                                  <div key={o.title} className={classes.ozellikRow}>
                                    <div className={classes.ozellikRowLabel}>{o.title}</div>
                                    <div>{o.value}</div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="flex w-full mt-3 justify-center">
                        <Button
                          className={classes.button}
                          onClick={() => handleSave()}
                          disabled={!_isEmpty(filtreError) || IS_EQUAL}
                        >KAYDET</Button>
                      </div>
                    </div>
                  )}
                </>
              )}
              {loading && (
                <div className="flex w-full mt-3 justify-center">
                  <CircularProgress color="primary" />
                </div>
              )}
            </div>
          </div>
        </main>
      </Paper>
    </>
  );
};

export default FilterAddDialog;