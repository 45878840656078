import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';
import moment from 'moment';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './SozlesmeDetailTab_Genel';
import TabIcerik from './SozlesmeDetailTab_Versiyon';

const PATH = 'sozlesme';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'VERSİYONLAR', disabled: false, hasError: false },
];

const FORM_DATA_DEFAULT = {
  tanim: '',
  kodu: '',
  tip: '0',
  magazalar: [],
  aktif: true,
  siralama: '0'
};

const VERSIYON_DATA_DEFAULT = {
  form: {
    versiyon: '0',
    diller: [],
    gecerlilik_baslangic: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
  },
  errors: null
};

const DILLER_FORM_DEFAULT = {
  dil_id: '',
  baslik: '',
  sablon: '',
};

const createDillerStatesİcerik = async (diller, icerikdiller) => {
  const states = [];
  diller.sort((a, b,) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...DILLER_FORM_DEFAULT,
        dil_id: dil.id,
      },
      errors: null,
    };
    const icerikdil = icerikdiller?.find((vd) => vd.dil_id === dil.id);
    if (icerikdil) {
      data.form.dil_id = icerikdil.dil_id;
      data.form.baslik = icerikdil.baslik;
      data.form.sablon = icerikdil.sablon;
    }
    states.push(data);
  });
  return states;
};

const SozlesmeDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingVersiyon, setLoadingVersiyon] = useState(true);
  const [loadingVersiyonDil, setLoadingVersiyonDil] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataVersiyonDil, setHataVersiyonDil] = useState(null);
  const [hataVersiyon, setHataVersiyon] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(FORM_DATA_DEFAULT);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [tip, setTip] = useState([]);
  const [tipDic, setTipDic] = useState({});

  const [dillerSelectedTabIndexVersiyon] = useState(0);
  const [dillerFormDefaultVersiyon, setDillerFormDefaultVersiyon] = useState(null);
  const [dillerFormVersiyon, setDillerFormVersiyon] = useState(null);

  const [dilMiniList, setDilMiniList] = useState([]);
  const [versiyonDiller, setVersiyonDiller] = useState([]);

  const [versiyonSelectedTabIndex, setVersiyonSelectedTabIndex] = useState(0);
  const [versiyonForm, setVersiyonForm] = useState([]);
  const [versiyonFormDefault, setVersiyonFormDefault] = useState([]);
  const [versiyonAdd, setVersiyonAdd] = useState(true);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteId, setDeleteIdDetail] = useState(null);
  const [showDeleteDialogDetail, setShowDeleteDialogDetail] = useState(false);
  const [isDeletingDetail, setIsDeletingDetail] = useState(false);
  const [isDeletingErrorMessageDetail, setIsDeletingErrorMessageDetail] = useState(null);

  const [type, setType] = useState(props.eventType ?? 0);
  const [loadDone, setLoadDone] = useState(false);
  const [loadTab1, setLoadTab1] = useState(false);
  const [loadTab2, setLoadTab2] = useState(false);
  const [equal1, setEqual1] = useState(true);
  const [equal2, setEqual2] = useState(true);
  const [saveErrors, setSaveErrors] = useState([[], []]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DATA_DEFAULT) && _isEqual(versiyonForm, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualVersiyon = _isEqual(versiyonForm, versiyonFormDefault);
    const isEqualVersiyonDiller = _isEqual(dillerFormVersiyon, dillerFormDefaultVersiyon);
    return isEqualGenel && isEqualVersiyon && isEqualVersiyonDiller;
  }, [add, genelForm, genelFormDefault, versiyonForm, versiyonFormDefault, dillerFormVersiyon, dillerFormDefaultVersiyon]);

  useLayoutEffect(() => {
    setAdd(false);
    setLoadDone(false);
  }, [ID]);

  useLayoutEffect(() => {
    if (loadDone) {
      setType(2);
    } else {
      setType(props.eventType ?? 0);
    }
  }, [props.eventType, loadDone]);

  useLayoutEffect(() => {
    if (type === 0) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setTabs(TABS_DEFAULT);
      if (selectedTabIndex === 0) {
        setLoadTab1(true);
      } else {
        setLoadTab1(true);
        setLoadTab2(true);
      }
      setLoadDone(true);
    } else if (type === 1) {
      if (selectedTabIndex === 0) {
        setGenelForm(null);
        setGenelFormDefault(null);
        setLoadTab1(true);
      } else {
        setGenelForm(null);
        setGenelFormDefault(null);
        setLoadTab1(true);
        if (!saveErrors[1].includes("err")) {
          setLoadTab2(true);
        }
      }
      setLoadDone(true);
    } else {
      if (!add) {
        if (selectedTabIndex === 0) {
          if (equal1) {
            setLoadTab1(true);
          }
        } else {
          if (equal2) {
            setLoadTab2(true);
          }
        }
      } else {
        setLoading(false);
        setLoadingVersiyon(false);
        setLoadingVersiyonDil(false);
        setLoadTab1(false);
        setLoadTab2(false);
      }
    }
  }, [type, selectedTabIndex, saveErrors, equal1, equal2, add]);

  useLayoutEffect(() => {
    if (add) {
      setEqual1(_isEqual(genelForm, FORM_DATA_DEFAULT));
    } else {
      setEqual1(_isEqual(genelForm, genelFormDefault));
    }
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual2(_isEqual(versiyonForm, []));
    } else {
      setEqual2(_isEqual(versiyonForm, versiyonFormDefault) && _isEqual(dillerFormVersiyon, dillerFormDefaultVersiyon));
    }
  }, [add, versiyonForm, versiyonFormDefault, dillerFormVersiyon, dillerFormDefaultVersiyon]);

  const LOADING = useMemo(() => {
    if (!loading) {
      return false;
    }
    return true;
  }, [loading]);

  const LOADINGVERSIYON = useMemo(() => {
    if (!loadingVersiyon && !loadingVersiyonDil) {
      return false;
    }
    return true;
  }, [loadingVersiyon, loadingVersiyonDil]);

  useLayoutEffect(() => {
    if (loadTab1) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              kodu: data.kodu,
              aktif: data.aktif,
              siralama: String(data.siralama),
              tip: String(data.tip.key),
              magazalar: data.magazalar
            };
            setGenelFormDefault(form);
            setGenelForm(form);
            setHataGenel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setLoadTab1(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DATA_DEFAULT);
        setGenelFormDefault(FORM_DATA_DEFAULT);
        setGenelError(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
        setLoadTab1(false);
      }
    }
  }, [ID, IS_ADD, loadTab1]);

  useLayoutEffect(() => {
    if (loadTab2) {
      if (!IS_ADD) {
        setLoadingVersiyon(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/sozlesmeversiyon`).then(({ data }) => {
            const forms = data.map((versiyon) => (
              {
                form: {
                  id: versiyon.id,
                  versiyon: versiyon.versiyon,
                  gecerlilik_baslangic: moment(versiyon.gecerlilik_baslangic).format('YYYY-MM-DD[T]HH:mm'),
                  diller: versiyon.diller,
                },
                errors: null
              }
            ))
            setVersiyonForm(forms);
            setVersiyonFormDefault(forms);
            setVersiyonDiller(forms.map((ver) => ver.form.diller));
            setHataVersiyon(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataVersiyon(true);
              }
            }
          }).finally(() => {
            setLoadingVersiyon(false);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setVersiyonForm([]);
        setVersiyonFormDefault([]);
        setVersiyonDiller([]);
        setHataVersiyon(null);
        setLoadingVersiyon(false);
        setLoadTab2(false);
      }
    }

  }, [ID, IS_ADD, loadTab2]);

  useLayoutEffect(() => {
    django('sozlesme/sozlesme_tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setTipDic(dict);
      setTip(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django('dil/mini_list').then(({ data }) => setDilMiniList(data)).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataVersiyonDil(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setLoadingVersiyonDil(true);
    const setDil = async () => {
      let diller = [];
      versiyonDiller.forEach((dil) => {
        createDillerStatesİcerik(dilMiniList, dil).then((states) => diller.push(states));
      })
      return diller;
    }
    setDil().then((state) => {
      setDillerFormVersiyon(state);
      setDillerFormDefaultVersiyon(state);
    }).finally(() => setLoadingVersiyonDil(false));
  }, [dilMiniList, versiyonDiller]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleVersiyonChangeForm = (index, property, value) => {
    setVersiyonForm((prev) => {
      return immer(prev, (next) => {
        next[index]["form"][property] = value;
      });
    });
  };

  const handleDillerChangeFormVersiyon = (index, index2, property, value) => {
    setDillerFormVersiyon((prev) => {
      return immer(prev, (next) => {
        next[index][index2].form[property] = value;
      });
    });
  }

  const handleVadeAddNewTab = () => {
    setVersiyonAdd(false);
    let versiyon = '0';
    const last_version = versiyonForm.reduce((x, y) => x > Number(y.form.versiyon) ? x : Number(y.form.versiyon), 0);
    versiyon = String(last_version + 1);
    setVersiyonForm((prev) => immer(prev, (next) => {
      next.push({ form: { ...VERSIYON_DATA_DEFAULT.form, versiyon: versiyon }, errors: null });
    }));
    setVersiyonDiller((prev) => immer(prev, (next) => {
      next.push([]);
    }));
  }

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DATA_DEFAULT);
    setGenelError(null);
    setVersiyonForm([]);
    setVersiyonDiller([]);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
    setLoadTab1(true);
    setLoadTab2(true);
    setVersiyonAdd(true);
    setTabs(TABS_DEFAULT);
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    setVersiyonForm(versiyonFormDefault);
    setVersiyonDiller(versiyonFormDefault.map((ver) => ver.form.diller));
    setVersiyonAdd(true);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
      });
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.sozlesme.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteDetail = () => {
    setIsDeletingDetail(true);
    setIsDeletingErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/sozlesmeversiyon/${detailDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetail(false);
        setIsDeletingErrorMessageDetail(null);
      } else {
        setIsDeletingErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingDetail(false);
      setLoadTab2(true);
    });
  }

  const handleDeleteDetailIndex = (index, index2) => {
    setDillerFormVersiyon((prev) => {
      return immer(prev, (next) => {
        next[index][index2].form = { ...DILLER_FORM_DEFAULT, dil_id: next[index][index2].form.dil_id };
        next[index][index2].errors = null;
      })
    });
  }

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Sözleşme Tanım', pagename: 'sözleşme', prop2: '' },
      { prop: 'kodu', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Sözleşme Kodu', pagename: 'sözleşme', prop2: '' },
    ];
    let errors = [];
    await checkAllList('sozlesme', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {
    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (add ? _isEqual(genelForm, FORM_DATA_DEFAULT) : _isEqual(genelForm, genelFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            const magazalar = genelForm.magazalar.length > 0 ? genelForm.magazalar.map((b) => b.id) : [];
            const data = { ...genelForm, siralama: genelForm.siralama ? Number(genelForm.siralama) : 0, tip: Number(genelForm.tip), magazalar: magazalar }
            reqopt = {
              data: data,
              method: add ? 'POST' : 'PUT',
              id: null,
              successMessage: `Sözleşme ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Sözleşme ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabListe = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = versiyonFormDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next) && _isEqual(dillerFormVersiyon[index], dillerFormDefaultVersiyon[index]);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'gecerlilik_baslangic', prop2: '' },
        ];
        await checkAllTabs(next.form, (err) => handleErrorsVersiyon(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const isEqualDate = isHas ? _isEqual(next.form.gecerlilik_baslangic, prev.form.gecerlilik_baslangic) : false;

            const diller = dillerFormVersiyon[index].filter((dil) => (!_isEmpty(dil.form.sablon))).map((d) => d.form);

            let l_versiyon = '0';

            const last_version = versiyonForm.reduce((x, y) => x > Number(y.form.versiyon) ? x : Number(y.form.versiyon), 0);
            l_versiyon = Number(last_version + 1);

            if (isHas & isEqualDate) {
              reqopt = {
                data: { ...next.form, versiyon: Number(next.form.versiyon), diller: diller },
                method: 'PUT',
                id: next.form.id,
                successMessage: `${next.form.versiyon} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.versiyon} güncellenirken beklenmeyen bir hata oluştu`,
              }
            }

            if (isHas & !isEqualDate) {
              reqopt = {
                data: { ...next.form, versiyon: l_versiyon, diller: diller },
                method: 'POST',
                id: null,
                successMessage: `${next.form.versiyon} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.versiyon} ekleme beklenmeyen bir hata oluştu`,
              }
            }

            if (!isHas) {
              reqopt = {
                data: { ...next.form, versiyon: Number(next.form.versiyon), diller: diller },
                method: 'POST',
                id: null,
                successMessage: `${next.form.versiyon} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.versiyon} eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }


    let errors = [[], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'liste', request: [], error: [] },
      ];
      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in versiyonForm) {
        const versiyonstate = [...versiyonForm];
        const res = await createRequestOptionsTabListe(versiyonstate[i], i);
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }


      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "sozlesme" : `sozlesme/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.odeme_tanim.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (SAVEID !== null) {
              if (tab.name === 'liste') {
                setVersiyonForm((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `sozlesme/${SAVEID}/sozlesmeversiyon/${request.id}` : `sozlesme/${SAVEID}/sozlesmeversiyon`
                }).then(() => {
                  setVersiyonAdd(true);
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setVersiyonAdd(false);
                  setSelectedTabIndex(tab.index);
                  setVersiyonSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setVersiyonForm((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }
    await start();
    if (SAVEID !== null) {
      if (!add) {
        if (!errors[0].includes("err")) {
          setLoadTab1(true);
        }
        if (!errors[1].includes("err")) {
          setLoadTab2(true);
        }
      } else {
        setSaveErrors(errors);
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
      await checkAll();
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? false : true : true;
        next[1].hasError = !errors[1].includes("err") ? false : true;
      });
    });
  };

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsVersiyon = (errors, index) => {
    setVersiyonForm((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsVersiyonDil = (errors, index, index2) => {
    setDillerFormVersiyon((prev) => {
      const err = _pickBy({ ...prev[index][index2]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index][index2]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={LOADING}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            tip={tip}
            tipDic={tipDic}
            onChangeForm={handleGenelChangeForm}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}

        {selectedTabIndex === 1 && (
          <TabIcerik
            loading={LOADINGVERSIYON}
            hata={hataVersiyon}
            hataDil={hataVersiyonDil}

            selectedTabIndex={versiyonSelectedTabIndex}
            forms={versiyonForm}
            onChangeForm={handleVersiyonChangeForm}

            selectedTabIndexDil={dillerSelectedTabIndexVersiyon}
            dilform={dillerFormVersiyon}
            onChangeDilForm={handleDillerChangeFormVersiyon}
            onAddNewTab={handleVadeAddNewTab}
            versiyonAdd={versiyonAdd}

            setDeleteId={setDeleteIdDetail}
            openDeleteForm={setShowDeleteDialogDetail}
            deleteDetailDilWithIndex={handleDeleteDetailIndex}
            onCheckErrors={(errors, index) => handleErrorsVersiyon(errors, index)}

            onCheckErrorsDil={(errors, index, index2) => handleErrorsVersiyonDil(errors, index, index2)}
          />
        )}

      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Sözleşme siliniyor lütfen bekleyin'
                : 'Bu sözleşmeyi gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetail &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetail
              ? isDeletingErrorMessageDetail
              : isDeletingDetail
                ? 'Versiyon siliniyor lütfen bekleyin'
                : 'Bu versiyonu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetail}
          onSubmit={handleDeleteDetail}
          onCancel={() => setShowDeleteDialogDetail(false)}
        ></DialogConfirm>
      }
    </>
  );
}

export default SozlesmeDetail;