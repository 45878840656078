/* eslint-disable no-unused-vars */
import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  groupBy as _groupBy,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList } from '../../base/InputErrors';

import TabGenel from './GmerchantDetailTab_Genel';
import TabHesap from './GmerchantDetailTab_Hesap';
import TabKosul from './GmerchantDetailTab_Kosul';
import TabUrun from './GmerchantDetailTab_Urun/Urunlist';


const PATH = 'gmerhantaccount';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'HESAP', disabled: false, hasError: false },
  { label: 'KOŞUL GRUPLARI', disabled: false, hasError: false },
  { label: 'ÜRÜN', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  account_name: '',
  merchant_id: '',
  katalog_iliskili_calis: true,
  marka_basliklari: [],
  magaza: null,
  dil: null,
  ozellik_degerler: [],
  ozellik_basliklar: [],
  ozellik_tree_list: [],
};

const FORM_DEFAULT_KIMLIK = {
  application_name: '',
  private_key_file_json: null,
};

const KOSUL_GRUP_DEFAULT = {
  operator: '0',
  t_or_f: "true",
  kosullar: [{
    form: {
      gruplar: [],
      kullanici_gruplari: [],
      kategoriler: [],
      urunler: [],
      ozellik_degerler: [],
      ozellik_basliklar: [],
      ozellik_tree_list: [],
      kampanya_kosul_grup: null
    },
    errors: null
  }]
}

const KOSUL_DEFAULT = {
  form: {
    gruplar: [],
    kullanici_gruplari: [],
    kategoriler: [],
    urunler: [],
    ozellik_degerler: [],
    ozellik_basliklar: [],
    ozellik_tree_list: [],
    kampanya_kosul_grup: null
  },
  errors: null
};

const createOzellikListeler = (list) => {
  const ozellik_basliklar = [];
  const ozellik_tree_list = [];
  const ozellik_group = _groupBy(list, item => item.ozellik_baslik_tanim);
  if (Object.keys(ozellik_group).length > 0) {
    Object.keys(ozellik_group).forEach((o, i) => {
      ozellik_tree_list.push({
        title: o,
        list: Object.values(ozellik_group)[i].map((l) => ({
          key: l.id,
          value: l.tanim
        }))
      })
    })
  }
  list.forEach((l) => {
    if (ozellik_basliklar.some(p => p.id === l.ozellik_baslik_id) === false) {
      ozellik_basliklar.push({ id: l.ozellik_baslik_id, tanim: l.ozellik_baslik_tanim })
    }
  });
  return [ozellik_basliklar, ozellik_tree_list];
}

const createKosulGrupListeler = async (id, kosulgrup) => {
  const states = [];
  for (const grup of kosulgrup) {
    const res = (await django(`gmerhantaccount/${id}/gmerhant_kosul_grup/${grup.id}/gmerhantkosul`)).data;
    const forms = [];
    res.forEach((kosul) => {
      const res2 = createOzellikListeler(kosul.ozellik_degerler);
      const form = {
        form: {
          id: kosul.id,
          tanim: kosul.tanim,
          gruplar: kosul.gruplar,
          kullanici_gruplari: kosul.kullanici_gruplari,
          kategoriler: kosul.kategoriler,
          urunler: kosul.urunler,
          ozellik_degerler: kosul.ozellik_degerler,
          ozellik_basliklar: res2[0],
          ozellik_tree_list: res2[1],
        },
        errors: null
      }
      forms.push(form);
    });
    const data = {
      id: grup.id,
      operator: grup.operator,
      t_or_f: String(grup.t_or_f),
      kosullar: forms
    }
    states.push(data);
  }
  return states;
}

const KampanyaDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingKimlik, setLoadingKimlik] = useState(true);
  const [loadingKosul, setLoadingKosul] = useState(true);
  const [loadingKosul2, setLoadingKosul2] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataKimlik, setHataKimlik] = useState(null);
  const [hataKosul, setHataKosul] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [kimlikFormDefault, setKimlikFormDefault] = useState(FORM_DEFAULT_KIMLIK);
  const [kimlikForm, setKimlikForm] = useState(FORM_DEFAULT_KIMLIK);
  const [kimlikError, setKimlikError] = useState(null);

  const [grup, setGrup] = useState([]);
  const [kosulGrup, setKosulGrup] = useState([]);
  const [kosulGrupDefault, setKosulGrupDefault] = useState([]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteIdGrup, setDeleteIdDetailGrup] = useState(null);
  const [showDeleteDialogDetailGrup, setShowDeleteDialogDetailGrup] = useState(false);
  const [isDeletingDetailGrup, setIsDeletingDetailGrup] = useState(false);
  const [isDeletingErrorMessageDetailGrup, setIsDeletingErrorMessageDetailGrup] = useState(null);

  const [detailDeleteIdKosul, setDeleteIdDetailKosul] = useState(null);
  const [showDeleteDialogDetailKosul, setShowDeleteDialogDetailKosul] = useState(false);
  const [isDeletingDetailKosul, setIsDeletingDetailKosul] = useState(false);
  const [isDeletingErrorMessageDetailKosul, setIsDeletingErrorMessageDetailKosul] = useState(null);

  const [type, setType] = useState(props.eventType ?? 0);
  const [loadDone, setLoadDone] = useState(false);
  const [loadTab1, setLoadTab1] = useState(false);
  const [loadTab2, setLoadTab2] = useState(false);
  const [loadTab3, setLoadTab3] = useState(false);
  const [equal1, setEqual1] = useState(true);
  const [equal2, setEqual2] = useState(true);
  const [equal3, setEqual3] = useState(true);
  const [saveErrors, setSaveErrors] = useState([[], [], []]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props.id]);
  const IS_ADD = useMemo(() => props.addnew, [props.addnew]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(kimlikForm, FORM_DEFAULT_KIMLIK) && _isEqual(kosulGrup, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualKimlik = _isEqual(kimlikForm, kimlikFormDefault);
    const isEqualKosul = _isEqual(kosulGrup, kosulGrupDefault);
    return isEqualGenel && isEqualKimlik && isEqualKosul;
  }, [add, genelForm, genelFormDefault, kimlikForm, kimlikFormDefault, kosulGrup, kosulGrupDefault]);

  const LOADINGKOSUL = useMemo(() => {
    if (!loadingKosul && !loadingKosul2) {
      return false;
    }
    return true;
  }, [loadingKosul, loadingKosul2]);

  useLayoutEffect(() => {
    setAdd(false);
    setLoadDone(false);
  }, [ID]);

  useLayoutEffect(() => {
    if (loadDone) {
      setType(2);
    } else {
      setType(props.eventType ?? 0);
    }
  }, [props.eventType, loadDone]);

  useLayoutEffect(() => {
    if (type === 0) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setKimlikForm(FORM_DEFAULT_KIMLIK);
      setKimlikFormDefault(FORM_DEFAULT_KIMLIK);
      setKosulGrup([]);
      setKosulGrupDefault([]);
      setTabs(TABS_DEFAULT);
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          setLoadTab2(true);
          break;
        case 2:
          setLoadTab1(true);
          setLoadTab3(true);
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else if (type === 1) {
      setGenelForm(null);
      setGenelFormDefault(null);
      if (!saveErrors[1].includes("err")) {
        setKimlikForm(FORM_DEFAULT_KIMLIK);
        setKimlikFormDefault(FORM_DEFAULT_KIMLIK);
      }
      if (!saveErrors[2].includes("err")) {
        setKosulGrup([]);
        setKosulGrupDefault([]);
      }
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          if (!saveErrors[1].includes("err")) {
            setLoadTab2(true);
          }
          break;
        case 2:
          setLoadTab1(true);
          if (!saveErrors[2].includes("err")) {
            setLoadTab3(true);
          }
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else {
      if (!add) {
        switch (selectedTabIndex) {
          case 0:
            if (equal1) {
              setLoadTab1(true);
            }
            break;
          case 1:
            if (equal2) {
              setLoadTab2(true);
            }
            break;
          case 2:
            if (equal3) {
              setLoadTab3(true);
            }
            break;
          default:
            break;
        }
      } else {
        setLoading(false);
        setLoadingKimlik(false);
        setLoadingKosul(false);
        setLoadingKosul2(false);
        setLoadTab1(false);
        setLoadTab2(false);
        setLoadTab3(false);
      }
    }
  }, [type, selectedTabIndex, saveErrors, equal1, equal2, equal3, add]);

  useLayoutEffect(() => {
    if (add) {
      setEqual1(_isEqual(genelForm, FORM_DEFAULT));
    } else {
      setEqual1(_isEqual(genelForm, genelFormDefault));
    }
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual2(_isEqual(kimlikForm, FORM_DEFAULT_KIMLIK));
    } else {
      setEqual2(_isEqual(kimlikForm, kimlikFormDefault));
    }
  }, [add, kimlikForm, kimlikFormDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual3(_isEqual(kosulGrup, []));
    } else {
      setEqual3(_isEqual(kosulGrup, kosulGrupDefault));
    }
  }, [add, kosulGrup, kosulGrupDefault]);

  useLayoutEffect(() => {

    if (loadTab1) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              account_name: data.account_name,
              merchant_id: data.merchant_id,
              katalog_iliskili_calis: data.katalog_iliskili_calis,
              marka_basliklari: data.marka_basliklari,
              magaza: data.magaza,
              dil: data.dil,
              ozellik_degerler: [],
              ozellik_basliklar: [],
              ozellik_tree_list: [],
            };
            setGenelFormDefault(form);
            setGenelForm(form);
            setHataGenel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setLoadTab1(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DEFAULT);
        setGenelFormDefault(FORM_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
        setLoadTab1(false);
      }
    }
  }, [ID, IS_ADD, loadTab1]);

  useLayoutEffect(() => {
    if (loadTab2) {
      setLoadingKimlik(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/gmerhantcredentials`).then(({ data }) => {
            const form = {
              id: data.id,
              application_name: data.application_name,
              private_key_file_json: data.private_key_file_json,
            };
            setKimlikFormDefault(form);
            setKimlikForm(form);
            setHataKimlik(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataKimlik(true);
              }
            }
          }).finally(() => {
            setLoadingKimlik(false);
            setKimlikError(null);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoadingKimlik(false);
        };
      } else {
        setKimlikForm(FORM_DEFAULT_KIMLIK);
        setKimlikFormDefault(FORM_DEFAULT_KIMLIK);
        setKimlikError(null);
        setHataKimlik(null);
        setLoadingKimlik(false);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, loadTab2]);

  useLayoutEffect(() => {
    if (loadTab3) {
      setLoadingKosul(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/gmerhant_kosul_grup`).then(({ data }) => {
            setGrup(data);
            setHataKosul(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataKosul(true);
              }
            }
          }).finally(() => {
            setLoadingKosul(false);
            setLoadTab3(false);
          })
        }, 300);
        return () => {
          clearTimeout(debounce);
        }
      } else {
        setGrup([]);
        setHataKosul(null);
        setLoadingKosul(false);
        setLoadTab3(false);
      }
    }
  }, [ID, IS_ADD, loadTab3]);

  useLayoutEffect(() => {
    setLoadingKosul2(true);
    createKosulGrupListeler(ID, grup).then((states) => {
      setKosulGrupDefault(states);
      setKosulGrup(states);
      setHataKosul(null);
    }).catch((error) => {
      setHataKosul(true);
    }).finally(() => {
      setLoadingKosul2(false);
    })
  }, [grup, ID]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        if (property === 'marka_basliklari') {
          const res = createOzellikListeler(value);
          next['ozellik_basliklar'] = res[0];
          next['ozellik_tree_list'] = res[1];
          next['ozellik_degerler'] = value;
          next[property] = value.map(x => x.tanim);
        } else {
          next[property] = value;
        }
      });
    });
  };

  const handleKimlikChangeForm = (property, value) => {
    setKimlikForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleKosulChangeForm = (index, index2, property, value) => {
    if (index2 !== null) {
      setKosulGrup((prev) => {
        return immer(prev, (next) => {
          if (property === 'ozellik_degerler') {
            const res = createOzellikListeler(value);
            next[index].kosullar[index2].form['ozellik_basliklar'] = res[0];
            next[index].kosullar[index2].form['ozellik_tree_list'] = res[1];
          }
          next[index].kosullar[index2].form[property] = value;
        });
      });
    } else {
      setKosulGrup((prev) => {
        return immer(prev, (next) => {
          next[index][property] = value;
        });
      });
    }
  };

  const handleKosulGrupAddNewTab = () => {
    setKosulGrup((prev) => immer(prev, (next) => {
      next.push({ ...KOSUL_GRUP_DEFAULT });
    }));
  }

  const handleKosulAddNewTab = (index) => {
    setKosulGrup((prev) => immer(prev, (next) => {
      next[index]["kosullar"].push({ ...KOSUL_DEFAULT });
    }));
  }

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DEFAULT);
    setKimlikForm(FORM_DEFAULT_KIMLIK);
    setKosulGrup([]);
    setGenelError(null);
    setKimlikError(null);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
        next[3].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
    setLoadTab1(true);
    setLoadTab2(true);
    setLoadTab3(true);
    setTabs(TABS_DEFAULT);
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setKimlikForm(kimlikFormDefault);
    setKosulGrup(kosulGrupDefault);
    setGenelError(null);
    setKimlikError(null);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
        next[3].hasError = false;
      });
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.kampanya.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteDetailGrup = () => {
    setIsDeletingDetailGrup(true);
    setIsDeletingErrorMessageDetailGrup(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/gmerhant_kosul_grup/${detailDeleteIdGrup}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetailGrup(false);
        setIsDeletingErrorMessageDetailGrup(null);
      } else {
        setIsDeletingErrorMessageDetailGrup(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetailGrup(errorMessage);
    }).finally(() => {
      setIsDeletingDetailGrup(false);
      setLoadTab2(true);
    });
  }

  const handleDeleteDetailGrupWithoutId = (index) => {
    setKosulGrup((prev) => immer(prev, (next) => {
      next.splice(index, 1);
    }));
  }

  const handleDeleteDetailKosul = () => {
    setIsDeletingDetailKosul(true);
    setIsDeletingErrorMessageDetailKosul(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/gmerhant_kosul_grup/${detailDeleteIdGrup}/gmerhantkosul/${detailDeleteIdKosul}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetailKosul(false);
        setIsDeletingErrorMessageDetailKosul(null);
      } else {
        setIsDeletingErrorMessageDetailKosul(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetailKosul(errorMessage);
    }).finally(() => {
      setIsDeletingDetailKosul(false);
      setLoadTab2(true);
    });
  }

  const handleDeleteDetailKosulWithoutId = (index, index2) => {
    setKosulGrup((prev) => immer(prev, (next) => {
      next[index].kosullar.splice(index2, 1);
    }));
  }

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Kampanya Tanım', pagename: 'kampanya', prop2: '' },
    ]
    let errors = [];
    await checkAllList('kampanya', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const handleSave = async () => {

    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (add ? _isEqual(genelForm, FORM_DEFAULT) : _isEqual(genelForm, genelFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            const data = { ...genelForm, magaza: genelForm.magaza ? genelForm.magaza.id : null, dil: genelForm.dil ? genelForm.dil.id : null, }
            reqopt = {
              data: data,
              method: add ? "POST" : "PUT",
              id: null,
              successMessage: `Google merchant ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Google merchant ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabKimlik = async () => {
      let reqopt = null;
      let err = null;
      const isHas = _has(kimlikForm, 'id');
      if (!isHas ? _isEqual(kimlikForm, FORM_DEFAULT_KIMLIK) : _isEqual(kimlikForm, kimlikFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            const data = { ...kimlikForm }
            reqopt = {
              data: data,
              method: isHas ? "PUT" : "POST",
              id: null,
              successMessage: `Google merchant kimlik ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Google merchant kimlik ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabKosulGrup = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next, 'id');
      const prev = kosulGrupDefault.find(x => x.id === next.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        await createRequestOptionsTabKosul(index).then((res) => {
          const data = { ...next };
          delete data.kosullar;
          if (isHas) {
            reqopt = {
              data: data,
              method: "PUT",
              id: next.id,
              successMessage: `Koşul grup güncelleme başarıyla tamamlandı`,
              errorMessageUnexpected: `Koşul grup güncellenirken beklenmeyen bir hata oluştu`,
              kosullar: res,
              index: index
            }
          } else {
            reqopt = {
              data: data,
              method: "POST",
              id: null,
              successMessage: `Koşul grup ekleme başarıyla tamamlandı`,
              errorMessageUnexpected: `Koşul grup eklenirken beklenmeyen bir hata oluştu`,
              kosullar: res,
              index: index
            }
          }
          err = null;
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabKosul = async (index) => {
      const liste = [];
      for (const i in kosulGrup[index].kosullar) {
        const next = kosulGrup[index].kosullar[i];
        const isHas = _has(kosulGrup[index].kosullar[i].form, 'id');
        const prev = kosulGrupDefault[index]?.kosullar.find(x => x.form.id === kosulGrup[index].kosullar[i].form.id);
        const isEqual = !isHas ? false : _isEqual(prev, next);

        const copydata = { ...next.form };
        const gruplarid = copydata.gruplar?.map((m) => m.id);
        const kullanicigruplarid = copydata.kullanici_gruplari?.map((k) => k.id);
        const kategorilerid = copydata.kategoriler?.map((m) => m.id);
        const urunlerid = copydata.urunler?.map((m) => m.id);
        const ozellikid = copydata.ozellik_degerler?.map((m) => m.id);
        const data = { ...next.form, gruplar: gruplarid, kullanici_gruplari: kullanicigruplarid, kategoriler: kategorilerid, urunler: urunlerid, ozellik_degerler: ozellikid };

        if (isEqual) {
          liste.push(null)
        } else {
          if (isHas) {
            liste.push({
              data: data,
              method: "PUT",
              id: next.form.id,
              successMessage: `Koşul güncelleme başarıyla tamamlandı`,
              errorMessageUnexpected: `Koşul güncellenirken beklenmeyen bir hata oluştu`,
            })
          } else {
            liste.push({
              data: data,
              method: "POST",
              id: null,
              successMessage: `Koşul ekleme başarıyla tamamlandı`,
              errorMessageUnexpected: `Koşul eklenirken beklenmeyen bir hata oluştu`,
            })
          }
        }
      }
      return liste;
    }

    let errors = [[], [], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'kimlik', request: [], error: [] },
        { index: 2, name: 'kosul', request: [], error: [] },
      ];

      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      const res2 = await createRequestOptionsTabKimlik();
      tabs[1].request.push(res2[0]);
      tabs[1].error.push(res2[1]);

      for (const i in kosulGrup) {
        const kosulstate = [...kosulGrup];
        const res = await createRequestOptionsTabKosulGrup(kosulstate[i], i)
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "gmerhantaccount" : `gmerhantaccount/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.gmerchant.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded();
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }

            if (tab.name === 'genel') {
              if (SAVEID !== null) {
                setKimlikError(null);
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `gmerhantaccount/${SAVEID}/gmerhantcredentials/${request.id}` : `gmerhantaccount/${SAVEID}/gmerhantcredentials`
                }).then(({ data }) => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  if (error.response) {
                    if (error.response.status === 500) {
                      enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                    } else {
                      setKimlikError(error.response.data);
                    }
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }

            if (tab.name === 'kosul') {
              if (SAVEID !== null) {
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `gmerhantaccount/${SAVEID}/gmerhant_kosul_grup/${request.id}` : `gmerhantaccount/${SAVEID}/gmerhant_kosul_grup`
                }).then(({ data }) => {
                  asyncForEach(request.kosullar, async (kosul, kosulIndex) => {
                    if (kosul !== null) {
                      setKosulGrup((prev) => {
                        return immer(prev, (next) => {
                          next[requestIndex].kosullar[kosulIndex].errors = null;
                        })
                      });
                      await django({
                        method: kosul.method,
                        data: { ...kosul.data },
                        url: kosul.id ? `gmerhantaccount/${SAVEID}/gmerhant_kosul_grup/${data.id}/gmerhantkosul/${kosul.id}` : `gmerhantaccount/${SAVEID}/gmerhant_kosul_grup/${data.id}/gmerhantkosul`
                      }).then(() => {
                        enqueueSnackbar(kosul.successMessage, { variant: 'success' });
                        errors[tab.index].splice(requestIndex, 0, null);
                      }).catch((error) => {
                        setSelectedTabIndex(tab.index);
                        if (error.response) {
                          setKosulGrup((prev) => {
                            return immer(prev, (next) => {
                              next[requestIndex].kosullar[kosulIndex].errors = error.response.data;
                            })
                          });
                        } else {
                          enqueueSnackbar(kosul.errorMessageUnexpected, { variant: 'error' });
                        }
                        errors[tab.index].splice(requestIndex, 0, "err");
                      });
                    }
                  })
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }

          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }
    await start();
    if (SAVEID !== null) {
      if (!add) {
        if (!errors[0].includes("err")) {
          setLoadTab1(true);
        }
        if (!errors[1].includes("err")) {
          setLoadTab2(true);
        }
        if (!errors[2].includes("err")) {
          setLoadTab3(true);
        }
      } else {
        setSaveErrors(errors);
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
      await checkAll();
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? false : true : true;
        next[1].hasError = !errors[1].includes("err") ? false : true;
        next[2].hasError = !errors[2].includes("err") ? false : true;
      });
    });
  };

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsKimlik = (errors) => {
    setKimlikError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsDetail = (errors, index, index2) => {
    setKosulGrup((prev) => {
      const err = _pickBy({ ...prev[index][index2]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index][index2]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}

        {selectedTabIndex === 1 && (
          <TabHesap
            loading={loadingKimlik}
            hata={hataKimlik}
            add={add}

            form={kimlikForm}
            formdefault={kimlikFormDefault}
            error={kimlikError}
            onChangeForm={handleKimlikChangeForm}

            onCheckErrors={(errors) => handleErrorsKimlik(errors)}
          />
        )}

        {selectedTabIndex === 2 && (
          <TabKosul
            loading={LOADINGKOSUL}
            hata={hataKosul}

            forms={kosulGrup}
            onChangeForm={handleKosulChangeForm}
            onAddNewGrup={handleKosulGrupAddNewTab}
            onAddNewKosul={handleKosulAddNewTab}

            setDeleteIdDetailIdGrup={setDeleteIdDetailGrup}
            setDeleteIdDetailIdKosul={setDeleteIdDetailKosul}
            openDeleteFormGrup={setShowDeleteDialogDetailGrup}
            openDeleteFormKosul={setShowDeleteDialogDetailKosul}
            openDeleteFormGrupWithoutId={handleDeleteDetailGrupWithoutId}
            openDeleteFormKosulWithoutId={handleDeleteDetailKosulWithoutId}
            onCheckErrors={(errors, index, index2) => handleErrorsDetail(errors, index, index2)}
          />
        )}

        {selectedTabIndex === 3 && (
          <TabUrun
            ID={ID}
          />
        )}
      </LayoutDetail>


      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Google merchant siliniyor lütfen bekleyin'
                : 'Bu google merchant gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetailGrup &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetailGrup
              ? isDeletingErrorMessageDetailGrup
              : isDeletingDetailGrup
                ? 'Koşul grubu siliniyor lütfen bekleyin'
                : 'Bu koşul grubunu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetailGrup ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetailGrup}
          onSubmit={handleDeleteDetailGrup}
          onCancel={() => setShowDeleteDialogDetailGrup(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetailKosul &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetailKosul
              ? isDeletingErrorMessageDetailKosul
              : isDeletingDetailKosul
                ? 'Koşul siliniyor lütfen bekleyin'
                : 'Bu koşulu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetailKosul ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetailKosul}
          onSubmit={handleDeleteDetailKosul}
          onCancel={() => setShowDeleteDialogDetailKosul(false)}
        ></DialogConfirm>
      }
    </>
  );
}

export default KampanyaDetail;