/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState, useRef } from 'react';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import {
  has as _has,
  result as _result,
  isNil as _isNil,
  isEqual as _isEqual
} from 'lodash';
import immer from 'immer';
import {
  TextField,
  Dialog,
  DialogContent,
  IconButton,
  CircularProgress,
  Typography,
  Divider
} from '@material-ui/core';

import {
  Search as IconSearch,
  Close as IconClose,
  Check as IconCheck
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import { Hata } from '../../base/Hata';
import ListSelectorTwoStep from '../../../components/ListSelectorTwoStep';


const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: '240px auto',
      alignItems: 'start',
      padding: spacing(1),
      overflow: 'hidden',
    },
    layoutContainerSide: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflow: 'hidden',
      paddingRight: spacing(1)
    },
    layoutContainerMain: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflow: 'hidden',
      borderLeft: `1px solid ${palette.action.selected}`,
      paddingLeft: spacing(1)
    },
    listHeader: {
      display: "grid",
      gridTemplateColumns: "240px auto",
      padding: spacing(2, 0, 1, 0),
      marginBottom: spacing(2),
      borderBottom: `1px solid ${palette.primary.main}`
    },
    headerCol: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "left",
      width: 240
    },
    row: {
      display: "grid",
      gridTemplateColumns: "240px auto",
      alignItems: "center",
      height: 34,
    },
    input: {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 240,
      maxWidth: 240,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    selectedTab: {
      display: "flex",
      width: "100%",
      height: "100%",
      alignItems: "center",
      border: `1px solid ${palette.primary.main}`,
      borderRadius: 4,
      '& + &': {
        marginBottom: 5,
      },
      '& span': {
        padding: spacing(0.5, 1),
        fontWeight: 600,
        color: `${palette.primary.main}`
      }
    },
    tab: {
      display: "flex",
      width: "100%",
      height: "100%",
      alignItems: "center",
      border: `1px solid transparent`,
      borderRadius: 4,
      '& + &': {
        marginBottom: 5,
      },
      '& span': {
        padding: spacing(0.5, 1),
        fontWeight: 600
      }
    }
  };
};

const OzellikDegerDetailInside = withStyles(styles)(({ classes, ...props }) => {
  const [selectedBaslik, setSelectedBaslik] = useState(null);
  const [selectedBaslikValue, setSelectedBaslikValue] = useState(null);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedFirstVal, setSelectedFirstVal] = useState(null);
  const [openSelector, setOpenSelector] = useState(false);
  const [detayListOpen, setDetayListOpen] = useState(true);

  const LOADING_BASLIK = useMemo(() => props.loadingBaslik, [props.loadingBaslik]);
  const LOADINGMORE_BASLIK = useMemo(() => props.loadingBaslikMore, [props.loadingBaslikMore]);
  const HATA_BASLIK = useMemo(() => props.hataBaslik, [props.hataBaslik]);

  const LOADING_DEGER = useMemo(() => props.loadingDeger, [props.loadingDeger]);
  const LOADINGMORE_DEGER = useMemo(() => props.loadingDegerMore, [props.loadingDegerMore]);
  const HATA_DEGER = useMemo(() => props.hataDeger, [props.hataDeger]);

  const BASLIK_LISTE = useMemo(() => props.baslik, [props.baslik]);
  const BASLIK_LISTE_COUNT = useMemo(() => props.baslikCount, [props.baslikCount]);
  const BASLIK_PAGE = useMemo(() => props.baslikPage, [props.baslikPage]);
  const BASLIK_PAGES_COUNT = useMemo(() => props.baslikPagesCount, [props.baslikPagesCount]);

  const DEGER_LISTE = useMemo(() => props.deger, [props.deger]);
  const DEGER_LISTE_COUNT = useMemo(() => props.degerCount, [props.degerCount]);
  const DEGER_PAGE = useMemo(() => props.degerPage, [props.degerPage]);
  const DEGER_PAGES_COUNT = useMemo(() => props.degerPagesCount, [props.degerPagesCount]);

  const SELECTED_ITEM = useMemo(() => props?.deger?.[selectedIndex] ?? null, [props.deger, selectedIndex]);

  const [searchOpen, setSearchOpen] = useState([false, false]);
  const [searchQuery, setSearchQuery] = useState([{ key: "tanim", value: "" }, { key: "ozellik_deger", value: "" }]);

  const sideContainerRef = useRef();
  const mainContainerRef = useRef();

  const handleChangeForm = (value) => {
    props.onChange(selectedIndex, value)
  }

  const rowRendererBaslik = ({ index, style }) => {
    const item = BASLIK_LISTE[index];
    return (
      <div key={item.id} style={style}
        className="cursor-pointer"
        onClick={() => {
          setSelectedBaslik(index);
          setSelectedBaslikValue(item.ozellik_baslik)
          props.selectBaslik(item);
        }}
      >
        <div className={index === selectedBaslik ? classes.selectedTab : classes.tab}>
          <span>{item.tanim}</span>
        </div>
      </div>
    );
  };

  const rowRendererDeger = ({ index, style }) => {
    const item = DEGER_LISTE[index];
    return (
      <div key={item.id} style={style}
        className="cursor-pointer"
        onClick={() => {
          setSelectedIndex(index);
          setSelectedFirstVal(item);
        }}
      >
        {index !== 0 && <Divider />}
        <div className={classes.row}>
          <span>{item.tanim}</span>
          <span className={classes.input}>
            <TextField
              name="tanim"
              variant="outlined"
              size="small"
              fullWidth={true}
              value={item.ozellik_deger?.tanim ?? ''}
              InputProps={{
                endAdornment:
                  <IconButton
                    size="small"
                    className={classes.button}
                    disableRipple
                    onClick={() => setOpenSelector(true)}
                  ><IconSearch /></IconButton>
              }}
            ></TextField>
          </span>
        </div>
      </div>
    );
  };


  return (
    <>
      {!LOADING_BASLIK &&
        <>
          {HATA_BASLIK && (
            <Hata />
          )}
          {!HATA_BASLIK && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <div className={classes.layoutContainerSide}>
                    <main className="relative flex flex-grow flex-col overflow-hidden h-full" ref={sideContainerRef}>
                      <div className="h-full flex-grow">
                        <InfiniteLoader
                          minimumBatchSize={100}
                          threshold={100}
                          isRowLoaded={({ index }) => !!BASLIK_LISTE[index]}
                          loadMoreRows={({ startIndex, stopIndex }) => {
                            if (BASLIK_LISTE_COUNT > 0 && BASLIK_PAGES_COUNT > BASLIK_PAGE && !LOADINGMORE_BASLIK) {
                              props.getMoreBaslik(BASLIK_PAGE + 1, sideContainerRef);
                            }
                          }}
                          rowCount={BASLIK_LISTE_COUNT > 0 ? BASLIK_LISTE_COUNT : props.rowCount * 2}
                        >
                          {({ onRowsRendered, registerChild }) => (
                            <AutoSizer>
                              {({ width, height }) => (
                                <List
                                  width={width}
                                  height={height}
                                  rowHeight={35}
                                  rowCount={BASLIK_LISTE.length}
                                  estimatedRowSize={BASLIK_PAGES_COUNT > 0 ? BASLIK_PAGES_COUNT * (35) : undefined}
                                  rowRenderer={rowRendererBaslik}
                                  onRowsRendered={onRowsRendered}
                                  ref={registerChild}
                                />
                              )}
                            </AutoSizer>
                          )}
                        </InfiniteLoader>
                      </div>
                    </main>
                  </div>

                  <div className={classes.layoutContainerMain} >
                    {!LOADING_DEGER && (
                      <>
                        {HATA_DEGER && (
                          <Hata />
                        )}
                        {!HATA_DEGER && (
                          <div className="relative flex flex-grow flex-col h-full overflow-x-auto overflow-y-hidden">
                            {(selectedBaslik !== null && DEGER_LISTE_COUNT === 0) && (
                              <div className="flex w-full h-full justify-center items-center">
                                <Typography variant="caption">Alt özellik listesi bulunmamaktadır.</Typography>
                              </div>
                            )}
                            {DEGER_LISTE_COUNT > 0 && (
                              <>
                                <div className={classes.listHeader}>
                                  <div>
                                    {searchOpen[0] && (
                                      <div className={classes.headerCol}>
                                        <TextField
                                          variant="outlined"
                                          size='small'
                                          fullWidth={true}
                                          label="Entegrasyon Tanım"
                                          value={searchQuery[0].value}
                                          onChange={(e) => {
                                            const val = e.target.value;
                                            setSearchQuery((prev) => {
                                              return immer(prev, (next) => {
                                                next[0].value = val;
                                              });
                                            });
                                          }}
                                          InputLabelProps={{ shrink: true }}
                                          InputProps={{
                                            endAdornment:
                                              <IconButton
                                                size="small"
                                                disableRipple
                                                onClick={() => props.searchDegerSet(searchQuery)}
                                              >
                                                <IconCheck />
                                              </IconButton>
                                          }}
                                        >
                                        </TextField>
                                        <IconButton
                                          size="small"
                                          disableRipple
                                          onClick={() => {
                                            setSearchQuery((prev) => {
                                              return immer(prev, (next) => {
                                                next[0].value = "";
                                                props.searchDegerSet(next)
                                              });
                                            });
                                            setSearchOpen((prev) => {
                                              return immer(prev, (next) => {
                                                next[0] = false;
                                              });
                                            });
                                          }}
                                        >
                                          <IconClose />
                                        </IconButton>
                                      </div>
                                    )}
                                    {!searchOpen[0] && (
                                      <div className={classes.headerCol}>
                                        <span>Entegrasyon Tanım</span>
                                        <IconButton
                                          size="small"
                                          disableRipple
                                          onClick={() => {
                                            setSearchOpen((prev) => {
                                              return immer(prev, (next) => {
                                                next[0] = true;
                                              });
                                            });
                                          }}
                                        >
                                          <IconSearch />
                                        </IconButton>
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    {searchOpen[1] && (
                                      <div className={classes.headerCol}>
                                        <TextField
                                          variant="outlined"
                                          size='small'
                                          fullWidth={true}
                                          label="Eşleştirilen Özellik Değer"
                                          value={searchQuery[1].value}
                                          onChange={(e) => {
                                            const val = e.target.value;
                                            setSearchQuery((prev) => {
                                              return immer(prev, (next) => {
                                                next[1].value = val;
                                              });
                                            });
                                          }}
                                          InputLabelProps={{ shrink: true }}
                                          InputProps={{
                                            endAdornment:
                                              <IconButton
                                                size="small"
                                                disableRipple
                                                onClick={() => props.searchDegerSet(searchQuery)}
                                              >
                                                <IconCheck />
                                              </IconButton>
                                          }}
                                        >
                                        </TextField>
                                        <IconButton
                                          size="small"
                                          disableRipple
                                          onClick={() => {
                                            setSearchQuery((prev) => {
                                              return immer(prev, (next) => {
                                                next[1].value = "";
                                                props.searchDegerSet(next)
                                              });
                                            });
                                            setSearchOpen((prev) => {
                                              return immer(prev, (next) => {
                                                next[1] = false;
                                              });
                                            });
                                          }}
                                        >
                                          <IconClose />
                                        </IconButton>
                                      </div>
                                    )}
                                    {!searchOpen[1] && (
                                      <div className={classes.headerCol}>
                                        <span>Eşleştirilen Özellik Değer</span>
                                        <IconButton
                                          size="small"
                                          disableRipple
                                          onClick={() => {
                                            setSearchOpen((prev) => {
                                              return immer(prev, (next) => {
                                                next[1] = true;
                                              });
                                            });
                                          }}
                                        >
                                          <IconSearch />
                                        </IconButton>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <main className="relative flex flex-grow flex-col overflow-hidden h-full" ref={mainContainerRef}>
                                  <div className="h-full flex-grow">
                                    <InfiniteLoader
                                      minimumBatchSize={100}
                                      threshold={100}
                                      isRowLoaded={({ index }) => !!DEGER_LISTE[index]}
                                      loadMoreRows={({ startIndex, stopIndex }) => {
                                        if (DEGER_LISTE_COUNT > 0 && DEGER_PAGES_COUNT > DEGER_PAGE && !LOADINGMORE_DEGER) {
                                          props.getMoreDeger(DEGER_PAGE + 1, mainContainerRef, searchQuery);
                                        }
                                      }}
                                      rowCount={DEGER_LISTE_COUNT > 0 ? DEGER_LISTE_COUNT : props.rowCount * 2}
                                    >
                                      {({ onRowsRendered, registerChild }) => (
                                        <AutoSizer>
                                          {({ width, height }) => (
                                            <List
                                              width={width}
                                              height={height}
                                              rowHeight={35}
                                              rowCount={DEGER_LISTE.length}
                                              estimatedRowSize={DEGER_PAGES_COUNT > 0 ? DEGER_PAGES_COUNT * (35) : undefined}
                                              rowRenderer={rowRendererDeger}
                                              onRowsRendered={onRowsRendered}
                                              ref={registerChild}
                                            />
                                          )}
                                        </AutoSizer>
                                      )}
                                    </InfiniteLoader>
                                  </div>
                                </main>
                              </>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {LOADING_DEGER &&
                      <div className="flex w-full h-full justify-center items-center">
                        <CircularProgress color="primary" />
                        <Typography variant="caption">Yükleniyor...</Typography>
                      </div>
                    }
                  </div>
                </div>
              </div>

              {openSelector &&
                <Dialog
                  open={openSelector}
                  PaperProps={{
                    style: {
                      background: 'transparent',
                      height: 600,
                      width: detayListOpen ? '100%' : 480
                    },
                  }}
                  fullWidth
                  maxWidth={detayListOpen ? 'md' : 'sm'}
                  onClose={() => {
                    setOpenSelector(false);
                    handleChangeForm(selectedFirstVal.ozellik_deger);
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorTwoStep
                      title="Özellikler"
                      mainItemsPath='ozellikbaslik'
                      mainItemsCountPath='ozellikbaslik/count'
                      mainDefaultItems={SELECTED_ITEM.ozellik_baslik}
                      mainMultiple={false}
                      mainKeyProp='id'
                      mainValueProp='tanim'
                      listPath='ozellikdeger'
                      listCountPath='ozellikdeger/count'
                      listMultiple={false}
                      keyProp='id'
                      valueProp='tanim'
                      keyUId='ozellik_baslik_id'
                      valueUV='ozellik_baslik_tanim'
                      defaultItems={SELECTED_ITEM.ozellik_deger}
                      onChangeItems={(items) => handleChangeForm(items)}
                      onClose={() => {
                        setOpenSelector(false);
                        handleChangeForm(selectedFirstVal.ozellik_deger);
                      }}
                      onDetayListClose={(res) => setDetayListOpen(res)}
                      detayListOpen={detayListOpen}
                      detayList={SELECTED_ITEM.treeList}
                      url="tanimlar/ozellikdetay"

                      confirmButton={true}
                      confirmEnable={!_isEqual(selectedFirstVal, SELECTED_ITEM)}
                      onConfirm={() => {
                        props.onSave(SELECTED_ITEM);
                        setOpenSelector(false);
                      }}
                      confirmBtnTitle="Kaydet"
                      openSelectedMain={selectedBaslikValue ? { key: selectedBaslikValue.id, value: selectedBaslikValue.tanim } : null}
                    />

                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
      {LOADING_BASLIK &&
        <div className="flex w-full h-full justify-center items-center">
          <CircularProgress color="primary" />
          <Typography variant="caption">Yükleniyor...</Typography>
        </div>
      }
    </>
  );
});

export default OzellikDegerDetailInside;
