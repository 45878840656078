import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';
import moment from 'moment';


import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  assign as _assign,
  isEmpty as _isEmpty,
  groupBy as _groupBy,
  trimEnd as _trimEnd,
  isNil as _isNil
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';

import LayoutDetail from '../../base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './OdemeDetailTab_Genel';
import TabEposta from './OdemeDetailTab_Eposta';

const PATH = 'evrak';
const EVRAKTIP = 16;

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'EPOSTA', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  tip: '16',
  kodu: '',
  versiyon: '0',
  evrak_durum: null,
  genel_toplam: "0.0000",
  evrak_tarihi: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
  para_birimi: '-1',
  aciklama: '',
  evrak_kur: '',
  ust: [],
  evrak_kullanici: null,
  magaza: null,
  ort_vade_gun: '0',
  ort_vade_tarih: moment(Date.now()).format('YYYY-MM-DD'),
  evrak_ek_bilgiler: [],
  ek_bilgiler: []
};

const VADE_DEFAULT = [
  {
    satir_no: 1,
    vade: moment(Date.now()).format('YYYY-MM-DD'),
    tutar: '',
    tahsilat_tarihi: moment(Date.now()).format('YYYY-MM-DD'),
    satir_kodu: '',
    status: 0,
    kimin: '',
    firma: false,
    aciklama: '',
    cek_banka_bilgi: {
      adi: '',
      sube_kodu: '',
      hesap_kodu: '',
      iban: '',
      cek_sira_no: '',
    },
    kefil_bilgi: {
      ad_soyad: '',
      telefon: '',
      adres: '',
      kimlik_no: '',
    }
  }
];

const DETAY_DEFAULT = {
  vade: [
    {
      satir_no: 1,
      vade: moment(Date.now()).format('YYYY-MM-DD'),
      tutar: '',
      tahsilat_tarihi: moment(Date.now()).format('YYYY-MM-DD'),
      satir_kodu: '',
      status: 0,
      kimin: '',
      firma: false,
      aciklama: '',
      cek_banka_bilgi: {
        adi: '',
        sube_kodu: '',
        hesap_kodu: '',
        iban: '',
        cek_sira_no: '',
      },
      kefil_bilgi: {
        ad_soyad: '',
        telefon: '',
        adres: '',
        kimlik_no: '',
      }
    }
  ],
  tip: '0',
  tahsil_edildi: true,
  aciklama: '',
  tutar: '',
  net_tutar: '',
  ortalama_vade_gun: '0',
  ortalama_vade_tarih: moment(Date.now()).format('YYYY-MM-DD'),
  tahsilat_tarih: moment(Date.now()).format('YYYY-MM-DD'),
  para_birimi: '-1',
  odeme_tanim: '-1',
  banka_hesap: '-1',
  odeme_vade: '-1',
  cari_hesap_para_birim: '-1',
  sezon: '-1',
  kur: '0',
  cari_hesap_kur: '0'
};

const createTahsilatDetay = async (list, odemeTanim, bankalar, paraBirim) => {
  const detay = [];
  const odemetanimresponse = odemeTanim;
  const bankahesapresponse = bankalar;
  const parabirimresponse = paraBirim;

  for (const item of list) {
    let odemetanimlist = [];
    let bankalist = [];
    let parabirimlist = [];
    let odemevadelist = [];
    let odemevadelistdic = {};

    odemetanimlist = odemetanimresponse.filter((x) => x.tip.key === Number(item.tip));
    if (item.odeme_tanim !== '-1') {
      const odemetanim = odemetanimlist.find((x) => x.id === Number(item.odeme_tanim));
      if (item.tip === '0') {
        bankalist = odemetanim.banka_hesaplari;
        if (item.banka_hesap !== '-1') {
          const banka = bankahesapresponse.filter((x) => x.id === Number(item.banka_hesap));
          parabirimlist = [banka[0].para_birim];
        } else {
          bankalist.forEach((x) => {
            const existbank = bankahesapresponse.find((y) => y.id === x.id);
            if (parabirimlist.some(p => p.id === existbank.para_birim.id) === false) {
              parabirimlist.push(existbank.para_birim);
            }
          });
        }
      } else if (item.tip === '3') {
        const odemeservisresponse = (await django(`odemeservis/${odemetanim.odeme_servis.id}`)).data;
        const odemevaderesponse = (await django(`odemetanim/${odemetanim.id}/odemevade/mini_list`)).data;
        bankalist = [{ id: odemeservisresponse.odeme_kurum.key, tanim: odemeservisresponse.odeme_kurum.value }];
        parabirimlist = parabirimresponse.filter(x => x.uluslar_arasi_kod === odemeservisresponse.para_birimi.value);
        odemevadelist = odemevaderesponse;
        odemevadelistdic = Object.assign({}, ...odemevadelist.map((x) => ({ [x.id]: x.tanim })));

      } else {
        bankalist = bankahesapresponse;
        if (item.banka_hesap !== '-1') {
          const banka = bankalist.find((x) => x.id === Number(item.banka_hesap));
          parabirimlist = [banka.para_birim];
        } else {
          parabirimlist = parabirimresponse;
        }
      }
    } else {
      if (item.tip !== '3') {
        bankalist = bankahesapresponse;
        if (item.banka_hesap !== '-1') {
          const banka = bankalist.find((x) => x.id === Number(item.banka_hesap));
          parabirimlist = [banka.para_birim];
        } else {
          parabirimlist = parabirimresponse;
        }
      } else {
        bankalist = [];
        parabirimlist = [];
      }
    }

    const tahsilat_detay = {
      vade: item.vade,
      tip: item.tip,
      tahsil_edildi: item.tahsil_edildi,
      aciklama: item.aciklama,
      tutar: item.tutar,
      net_tutar: item.net_tutar,
      ortalama_vade_gun: item.ortalama_vade_gun,
      ortalama_vade_tarih: item.ortalama_vade_tarih,
      tahsilat_tarih: item.tahsilat_tarih,
      para_birimi: item.para_birimi,
      odeme_tanim: item.odeme_tanim,
      banka_hesap: item.banka_hesap,
      odeme_vade: item.odeme_vade,
      cari_hesap_para_birim: item.cari_hesap_para_birim,
      cari_hesap_kur: item.cari_hesap_kur,
      sezon: item.sezon,
      kur: item.kur,
      odemetanimlist: odemetanimlist,
      bankalist: bankalist,
      parabirimlist: parabirimlist,
      odemevadelist: odemevadelist,
      odemevadelistdic: odemevadelistdic,
    }
    detay.push(tahsilat_detay);
  }
  return detay;
}

const getEkAlan = async (magaza, ekalanlar) => {
  const liste = [];
  if (magaza) {
    const list = (await django(`ek_alan_tanim/mini_list/?magazalar.magaza.id=${magaza}&alan_iliski_tip=${EVRAKTIP}&detay=false`, { params: { sort: 'siralama' } })).data;
    if (list.length > 0) {
      for (const e of list) {
        const elemanlar = (await django(`ek_alan_tanim/${e.id}/ek_alan_alan/`, { params: { sort: 'siralama' } })).data;
        elemanlar.forEach((eleman) => {
          const data = {
            ek_alan: eleman.id,
            alan_tip: eleman.alan_tip.key,
            zorunlu: eleman.zorunlu,
            tanim: eleman.tanim,
            secim_degerleri: eleman.secim_degerleri.map(sd => sd.deger),
            veri: eleman.alan_tip.key === 2 ? eleman.secim_degerleri[0].deger : ""
          };
          const ek_alan = ekalanlar?.find((ea) => ea.ek_alan === eleman.id);
          if (ek_alan) {
            data.veri = ek_alan.veri;
            data.id = ek_alan.id;
          }
          liste.push(data);
        });
      }
    }
  }
  return liste;
}

const createEvrakDurum = async (durum) => {
  const res = (await (django(`evrakdurumtanim/${durum.durum.id}`))).data;
  const form = {
    id: durum.id,
    mail_sablon: String(durum.mail_sablon?.id ?? -1),
    mail_sablonlar: res.mail_sablonlar,
    mail_sablonlar_dic: Object.assign({}, ...res.mail_sablonlar.map((x) => ({ [x.id]: x.tanim }))),
    mail_sablon_site: String(durum.mail_sablon_site?.id ?? -1),
    mail_sablonlar_site: res.mail_sablonlar_site,
    mail_sablonlar_site_dic: Object.assign({}, ...res.mail_sablonlar_site.map((x) => ({ [x.id]: x.tanim }))),
    pdf_sablon: String(durum.pdf_sablon?.id ?? -1),
    pdf_sablonlar: res.pdf_sablonlar,
    pdf_sablonlar_dic: Object.assign({}, ...res.pdf_sablonlar.map((x) => ({ [x.id]: x.tanim }))),
    alici_mailleri: durum.alici_mailleri ?? [],
    durum: durum.durum,
    mail_gondersin: durum.mail_gondersin,
    kullaniciya_mail_gondersin: durum.kullaniciya_mail_gondersin,
  }
  return form;
}

const OdemeDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingEkAlan, setLoadingEkAlan] = useState(true);
  const [loadingdetay, setLoadingDetay] = useState(true);
  const [loadingKur, setLoadingKur] = useState(false);
  const [loadingEvrakDurum, setLoadingEvrakDurum] = useState(false);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataDetay, setHataDetay] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(FORM_DEFAULT);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);
  const [tahsilatDetay, setTahsilatDetay] = useState([]);

  const [genelFormEkAlan, setGeneFormEkAlan] = useState(null);
  const [genelFormEvrakDurum, setGenelFormEvrakDurum] = useState(null);

  const [detayFormDefault, setDetayFormDefault] = useState([]);
  const [detayForm, setDetayForm] = useState([]);

  const [odemeTanimaLoad, setOdemeTanimLoad] = useState(true);
  const [bankaLoad, setBankaLoad] = useState(true);
  const [paraBirimLoad, setParaBirimLoad] = useState(true);

  const [odemeTip, setOdemeTip] = useState([]);
  const [odemeTipDic, setOdemeTipDic] = useState({});
  const [odemeTanim, setOdemeTanim] = useState([]);
  const [odemeTanimDic, setOdemeTanimDic] = useState([]);
  const [bankalar, setBankalar] = useState([]);
  const [bankalarDic, setBankalarDic] = useState({});
  const [cekSenetDurum, setCekSenetDurum] = useState([]);
  const [cekSenetDurumDic, setCekSenetDurumDic] = useState({});

  const [paraBirimList, setParaBirimList] = useState([]);
  const [paraKoduList, setParaKoduList] = useState({});
  const [paraBirimDic, setParaBirimDic] = useState({});
  const [kurlar, setKurlar] = useState([]);
  const [evrakTarih, setEvrakTarih] = useState(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.kodu;
  }, [add, genelForm]);

  const IS_ADD = useMemo(() => props.addnew, [props]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(detayForm, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualDetay = _isEqual(detayForm, detayFormDefault);
    return isEqualGenel && isEqualDetay;
  }, [add, genelForm, genelFormDefault, detayForm, detayFormDefault]);

  useLayoutEffect(() => {
    setLoading(true);
    const debounce = setTimeout(() => {
      if (!IS_ADD) {
        django(`${PATH}/${ID}/get_tahsilat`).then(({ data }) => {
          const kullanici = data.evrak_kullanici;
          delete kullanici.username;
          const form = {
            tip: String(data.tip.key),
            kodu: data.kodu,
            versiyon: String(data.versiyon),
            genel_toplam: data.genel_toplam,
            evrak_tarihi: moment(data.evrak_tarihi).format('YYYY-MM-DD[T]HH:mm'),
            para_birimi: String(data.para_birimi.id),
            aciklama: data.aciklama,
            evrak_kur: data.evrak_kur,
            ust: data.ust.map(x => x.id),
            evrak_kullanici: kullanici,
            magaza: data.magaza,
            ort_vade_gun: data.ort_vade_gun ? String(data.ort_vade_gun) : '0',
            ort_vade_tarih: data.ort_vade_tarih ? moment(data.ort_vade_tarih).format('YYYY-MM-DD') : moment(Date.now()).format('YYYY-MM-DD'),
            evrak_durum: null,
            evrak_ek_bilgiler: data.evrak_ek_bilgiler ?? [],
            ek_bilgiler: [],
            kaynak: data.kaynak.key
          };
          const tahsilat_detay = data.evrak_tahsilat.map((x) => (
            {
              id: x.id,
              tip: String(x.tip),
              tahsil_edildi: x.tahsil_edildi,
              aciklama: x.aciklama,
              tutar: x.tutar,
              net_tutar: x.net_tutar,
              ortalama_vade_gun: String(x.ortalama_vade_gun),
              ortalama_vade_tarih: moment(x.ortalama_vade_tarih).format('YYYY-MM-DD'),
              tahsilat_tarih: moment(x.tahsilat_tarih).format('YYYY-MM-DD'),
              para_birimi: String(x.para_birimi?.id ?? -1),
              odeme_tanim: String(x.odeme_tanim?.id ?? -1),
              banka_hesap: String(x.banka_hesap?.id ?? -1),
              odeme_vade: String(x.odeme_vade?.id ?? -1),
              cari_hesap_para_birim: String(x.cari_hesap_para_birim?.id ?? -1),
              cari_hesap_kur: x.cari_hesap_kur ?? "0.0000",
              sezon: String(x.sezon ?? -1),
              kur: x.kur ?? "0.0000",
              evrak: x.evrak,
              vade: _isEmpty(x.tahsilat_vade) ? x.tahsilat_vade : x.tahsilat_vade.map((vade) => (
                {
                  satir_no: vade.satir_no,
                  vade: moment(vade.vade).format('YYYY-MM-DD'),
                  tutar: vade.tutar,
                  tahsilat_tarihi: moment(vade.tahsilat_tarihi).format('YYYY-MM-DD'),
                  satir_kodu: vade.satir_kodu,
                  status: vade.status,
                  kimin: vade.kimin,
                  firma: vade.firma,
                  aciklama: vade.aciklama,
                  cek_banka_bilgi: x.tip === 3 ? vade.cek_banka_bilgi : {
                    adi: vade.cek_banka_bilgi.adi,
                    sube_kodu: vade.cek_banka_bilgi.sube_kodu,
                    hesap_kodu: vade.cek_banka_bilgi.hesap_kodu,
                    iban: vade.cek_banka_bilgi.iban,
                    cek_sira_no: vade.cek_banka_bilgi.cek_sira_no,
                  },
                  kefil_bilgi: x.tip === 3 ? vade.kefil_bilgi : {
                    ad_soyad: vade.kefil_bilgi.ad_soyad,
                    telefon: vade.kefil_bilgi.telefon,
                    adres: vade.kefil_bilgi.adres,
                    kimlik_no: vade.kefil_bilgi.kimlik_no,
                  },
                }
              ))
            }
          ));
          setEvrakTarih(moment(data.evrak_tarihi).format('YYYYMMDD'));
          setGeneFormEkAlan({ magaza: data.magaza.id, tip: String(data.tip.key), ekbilgi: data?.evrak_ek_bilgiler ?? [] });
          setGenelFormEvrakDurum(data.evrak_durum[0]);
          setGenelFormDefault(form);
          setGenelForm(form);
          setTahsilatDetay(tahsilat_detay);
          setGenelError(null);
          setHataGenel(null);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataGenel(true);
            }
          }
        }).finally(() => {
          setTabs(TABS_DEFAULT);
          setLoading(false);
          setGenelError(null);
          setAdd(false);
        });
      } else {
        setGenelForm(FORM_DEFAULT);
        setTahsilatDetay([]);
        setEvrakTarih(moment(Date.now()).format('YYYYMMDD'));
        setGeneFormEkAlan(null);
        setGenelFormEvrakDurum(null);
        setGenelError(null);
        setTabs(TABS_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setLoading(false);
      }
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoading(false);
    };
  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    const debounce = setTimeout(() => {
      if (genelFormEkAlan) {
        setLoadingEkAlan(true);
        getEkAlan(genelFormEkAlan.magaza, genelFormEkAlan.ekbilgi).then((res) => {
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next["ek_bilgiler"] = res;
            });
          });
          setGenelFormDefault((prev) => {
            return immer(prev, (next) => {
              next["ek_bilgiler"] = res;
            });
          });
        }).finally(() => setLoadingEkAlan(false));
      } else {
        setLoadingEkAlan(false);
      }
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoadingEkAlan(false);
    };
  }, [genelFormEkAlan]);

  useLayoutEffect(() => {
    const debounce = setTimeout(() => {
      if (genelFormEvrakDurum) {
        setLoadingEvrakDurum(true);
        createEvrakDurum(genelFormEvrakDurum).then((res) => {
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next["evrak_durum"] = res;
            });
          });
          setGenelFormDefault((prev) => {
            return immer(prev, (next) => {
              next["evrak_durum"] = res;
            });
          });
        }).finally(() => setLoadingEvrakDurum(false));
      } else {
        setLoadingEvrakDurum(false);
      }
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoadingEvrakDurum(false);
    };
  }, [genelFormEvrakDurum]);

  useLayoutEffect(() => {
    setParaBirimLoad(true);
    django('parabirim/mini_list').then(({ data }) => {
      let pkDict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.uluslar_arasi_kod })));
      let pkDict2 = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      pkDict = Object.assign(pkDict, { [-1]: '' })
      setParaKoduList(pkDict);
      setParaBirimDic(pkDict2);
      setParaBirimList(data);
      setParaBirimLoad(false);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (evrakTarih !== null) {
      django(`kurlar/?tarih=${evrakTarih}`).then(({ data }) => {
        const list = data.reduce((r, e) => {
          const index = r.findIndex(x => x.parabirim.id === e.parabirim.id && x.hedef_parabirim.id === e.hedef_parabirim.id)
          if (index < 0) {
            r.push(e);
          } else {
            if (r[index].id < e.id) {
              r[index] = e;
            }
          }
          return r;
        }, []);
        setKurlar(list.map((x) => ({ parabirim: x.parabirim, hedef_parabirim: x.hedef_parabirim, deger: x.deger })));
      });
    }
  }, [lastUpdateTime, evrakTarih]);

  useLayoutEffect(() => {
    setOdemeTanimLoad(true);
    django('odemetanim').then(({ data }) => {
      let dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      setOdemeTanim(data);
      setOdemeTanimDic(dict);
      setOdemeTanimLoad(false);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setBankaLoad(true);
    django('bankahesap').then(({ data }) => {
      let dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      setBankalarDic(dict);
      setBankalar(data);
      setBankaLoad(false);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setLoadingDetay(true);
    if (tahsilatDetay.length > 0) {
      if (!odemeTanimaLoad && !bankaLoad && !paraBirimLoad) {
        createTahsilatDetay(tahsilatDetay, odemeTanim, bankalar, paraBirimList).then((data) => {
          setDetayFormDefault(data);
          setDetayForm(data);
          setHataDetay(null);
        }).catch(() => setHataDetay(true)).finally(() => setLoadingDetay(false));
      }
    } else {
      setDetayFormDefault([]);
      setDetayForm([]);
      setLoadingDetay(false);
    }
  }, [tahsilatDetay, odemeTanim, bankalar, paraBirimList, odemeTanimaLoad, bankaLoad, paraBirimLoad]);

  useLayoutEffect(() => {
    django('evrak/odeme_tip').then(({ data }) => {
      let dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setOdemeTipDic(dict);
      setOdemeTip(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django('evrak/cek_senet_durum').then(({ data }) => {
      let dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setCekSenetDurumDic(dict);
      setCekSenetDurum(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const calcKur = (para1, para2, kurlist = kurlar) => {
    let kur = '0.0000';
    if (paraBirimList.length === 1) {
      kur = '1.0000';
    } else {
      if (kurlist.length > 0) {
        if (para1 !== '-1' && para2 !== '-1') {
          if (para1 === para2) {
            kur = '1.0000';
          } else {
            if (kurlist.findIndex((x) => x.parabirim.id === (para1 * 1) && x.hedef_parabirim.id === (para2 * 1)) !== -1) {
              kur = kurlist.find((x) => x.parabirim.id === (para1 * 1) && x.hedef_parabirim.id === (para2 * 1)).deger;
            }
          }
        }
      } else {
        if (para1 !== '-1' && para2 !== '-1') {
          if (para1 === para2) {
            kur = '1.0000';
          }
        }
      }
    }
    return kur;
  }

  const getKurList = async (evrakTarih) => {
    setLoadingKur(true);
    let liste = [];
    await django(`kurlar/?tarih=${evrakTarih}`).then(({ data }) => {
      const list = data.reduce((r, e) => {
        const index = r.findIndex(x => x.parabirim.id === e.parabirim.id && x.hedef_parabirim.id === e.hedef_parabirim.id)
        if (index < 0) {
          r.push(e);
        } else {
          if (r[index].id < e.id) {
            r[index] = e;
          }
        }
        return r;
      }, []);
      liste = list.map((x) => ({ parabirim: x.parabirim, hedef_parabirim: x.hedef_parabirim, deger: x.deger }))
    }).finally(() => {
      setLoadingKur(false);
    })
    return liste;
  }

  const controlEvrakDurumKullanici = (evrakdurumform, kullanici) => {
    let form = evrakdurumform;
    const copymails = [...form.alici_mailleri];
    if (form.kullaniciya_mail_gondersin) {
      if (!_isNil(kullanici.email)) {
        if (!copymails.includes(kullanici.email)) {
          copymails.push(kullanici.email);
        }
      } else {
        enqueueSnackbar("Evrak kullanıcısına ait bir eposta adresi bulunmamaktadır!", { variant: 'error' });
      }
    } else {
      const findex = copymails.findIndex(x => x === kullanici.email);
      if (findex > -1) {
        copymails.splice(findex, 1);
      }
    }
    form = { ...form, alici_mailleri: copymails };
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next["evrak_durum"] = form;
      });
    });
  }

  const getEvrakDurumları = async (value, kullanici) => {
    setLoadingEvrakDurum(true);
    if (value) {
      const res = (await (django(`evrakdurumtanim/${value.id}`))).data;
      if (res) {
        let form = {
          mail_sablon: String(res.mail_sablon.id),
          mail_sablonlar: res.mail_sablonlar,
          mail_sablonlar_dic: Object.assign({}, ...res.mail_sablonlar.map((x) => ({ [x.id]: x.tanim }))),
          mail_sablon_site: String(res.mail_sablon_site ? res.mail_sablon_site.id : res.mail_sablonlar_site?.[0] ? res.mail_sablonlar_site[0].id : "-1"),
          mail_sablonlar_site: res.mail_sablonlar_site,
          mail_sablonlar_site_dic: Object.assign({}, ...res.mail_sablonlar_site.map((x) => ({ [x.id]: x.tanim }))),
          pdf_sablon: String(res.pdf_sablon ? res.pdf_sablon.id : res.pdf_sablonlar?.[0] ? res.pdf_sablonlar[0].id : "-1"),
          pdf_sablonlar: res.pdf_sablonlar,
          pdf_sablonlar_dic: Object.assign({}, ...res.pdf_sablonlar.map((x) => ({ [x.id]: x.tanim }))),
          alici_mailleri: res.alici_mailleri,
          durum: value,
          mail_gondersin: res.mail_gondersin,
          kullaniciya_mail_gondersin: res.kullaniciya_mail_gondersin,
        }
        controlEvrakDurumKullanici(form, kullanici);
      } else {
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next["evrak_durum"] = value;
          });
        });
      }
    } else {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next["evrak_durum"] = value;
        });
      });
    }
    setLoadingEvrakDurum(false);
  }

  const getEvrakDurumVarsayilan = (magaza, kullanici) => {
    setLoadingEvrakDurum(true);
    django(`evrakdurumtanim/?aktif=true&magazalar.magaza.id=${magaza.id}&evrak_tip=${EVRAKTIP}&varsayilan=true`).then(({ data }) => {
      if (data.length > 0) {
        const res = data[0];
        let form = {
          mail_sablon: String(res.mail_sablon.id),
          mail_sablonlar: res.mail_sablonlar,
          mail_sablonlar_dic: Object.assign({}, ...res.mail_sablonlar.map((x) => ({ [x.id]: x.tanim }))),
          mail_sablon_site: String(res.mail_sablon_site ? res.mail_sablon_site.id : res.mail_sablonlar_site?.[0] ? res.mail_sablonlar_site[0].id : "-1"),
          mail_sablonlar_site: res.mail_sablonlar_site,
          mail_sablonlar_site_dic: Object.assign({}, ...res.mail_sablonlar_site.map((x) => ({ [x.id]: x.tanim }))),
          pdf_sablon: String(res.pdf_sablon ? res.pdf_sablon.id : res.pdf_sablonlar?.[0] ? res.pdf_sablonlar[0].id : "-1"),
          pdf_sablonlar: res.pdf_sablonlar,
          pdf_sablonlar_dic: Object.assign({}, ...res.pdf_sablonlar.map((x) => ({ [x.id]: x.tanim }))),
          alici_mailleri: res.alici_mailleri,
          durum: { id: res.id, tanim: res.tanim },
          mail_gondersin: res.mail_gondersin,
          kullaniciya_mail_gondersin: res.kullaniciya_mail_gondersin,
        }
        controlEvrakDurumKullanici(form, kullanici);
      } else {
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next["evrak_durum"] = null;
          });
        });
      }
    })
    setLoadingEvrakDurum(false);
  }

  const handleKKVadeList = async (index, id, value = 0) => {
    const vaderesponse = (await django(`odemetanim/${detayForm[index]["odeme_tanim"]}/odemevade/${id}`)).data;
    const taksitsayisi = vaderesponse.ay + vaderesponse.ek_taksit;
    const hesabagecis = vaderesponse.hesaba_gecis_vade_gunu;
    const vadefarki = (vaderesponse.vade_farki * 1);
    const ilkayauygula = vaderesponse.farki_ilk_aya_uygula;

    const tutar = Math.round(((value === 0 ? detayForm[index].tutar : value) * ((100 + vadefarki) / 100)) * 100) / 100;
    const taksit = Math.trunc((tutar / taksitsayisi) * 100) / 100;
    const fark = ((tutar * 100) - ((taksit * taksitsayisi) * 100)) / 100;
    const vade = [];
    for (let i = 0; i < taksitsayisi; i++) {
      const element = {
        satir_no: i + 1,
        vade: moment(Date.now()).add(i, 'M').format('YYYY-MM-DD'),
        tutar: String(taksit.toFixed(2)),
        tahsilat_tarihi: moment(Date.now()).add(i, 'M').add(hesabagecis, 'days').format('YYYY-MM-DD'),
        satir_kodu: '',
        status: 0,
        kimin: '',
        firma: false,
        aciklama: '',
        cek_banka_bilgi: {
          adi: '',
          sube_kodu: '',
          hesap_kodu: '',
          iban: '',
          cek_sira_no: '',
        },
        kefil_bilgi: {
          ad_soyad: '',
          telefon: '',
          adres: '',
          kimlik_no: '',
        }
      }
      vade.push(element);
    }
    if (ilkayauygula) {
      vade[0]["tutar"] = String((((taksit * 100) + (fark * 100)) / 100).toFixed(2));
    } else {
      vade[taksitsayisi - 1]["tutar"] = String((((taksit * 100) + (fark * 100)) / 100).toFixed(2));
    }

    return [vade, String(tutar)]
  }

  const handleSelectListChange = async (item) => {
    const odemetanimresponse = odemeTanim;
    const bankahesapresponse = bankalar;
    const parabirimresponse = paraBirimList;

    let odemetanimlist = [];
    let bankalist = [];
    let parabirimlist = [];
    let odemevadelist = [];
    let odemevadelistdic = {};

    odemetanimlist = odemetanimresponse.filter((x) => x.tip.key === Number(item.tip));
    if (item.odeme_tanim !== '-1') {
      const odemetanim = odemetanimlist.find((x) => x.id === Number(item.odeme_tanim));
      if (item.tip === '0') {
        bankalist = odemetanim.banka_hesaplari;
        if (item.banka_hesap !== '-1') {
          const banka = bankahesapresponse.filter((x) => x.id === Number(item.banka_hesap));
          parabirimlist = [banka[0].para_birim];
        } else {
          bankalist.forEach((x) => {
            const existbank = bankahesapresponse.find((y) => y.id === x.id);
            if (parabirimlist.some(p => p.id === existbank.para_birim.id) === false) {
              parabirimlist.push(existbank.para_birim);
            }
          });
        }
      } else if (item.tip === '3') {
        const odemeservisresponse = (await django(`odemeservis/${odemetanim.odeme_servis.id}`)).data;
        const odemevaderesponse = (await django(`odemetanim/${odemetanim.id}/odemevade/mini_list`)).data;
        bankalist = [{ id: odemeservisresponse.odeme_kurum.key, tanim: odemeservisresponse.odeme_kurum.value }];
        parabirimlist = parabirimresponse.filter(x => x.uluslar_arasi_kod === odemeservisresponse.para_birimi.value);
        odemevadelist = odemevaderesponse;
        odemevadelistdic = Object.assign({}, ...odemevadelist.map((x) => ({ [x.id]: x.tanim })));
      } else {
        bankalist = bankahesapresponse;
        if (item.banka_hesap !== '-1') {
          const banka = bankalist.find((x) => x.id === Number(item.banka_hesap));
          parabirimlist = [banka.para_birim];
        } else {
          parabirimlist = parabirimresponse;
        }
      }
    } else {
      if (item.tip !== '3') {
        bankalist = bankahesapresponse;
        if (item.banka_hesap !== '-1') {
          const banka = bankalist.find((x) => x.id === Number(item.banka_hesap));
          parabirimlist = [banka.para_birim];
        } else {
          parabirimlist = parabirimresponse;
        }
      } else {
        bankalist = [];
        parabirimlist = [];
      }
    }
    return [odemetanimlist, bankalist, parabirimlist, odemevadelist, odemevadelistdic];
  }

  const calcCariHesapNetTutar = async (row, index, evrakPb = null, kurlist = null) => {
    const tutar = row.tutar;
    const para_birimi = row.para_birimi;
    const kur = evrakPb === null ? row.kur : kurlist !== null ? calcKur(evrakPb, para_birimi, kurlist) : calcKur(evrakPb, para_birimi);
    const cari_hesap_para_birim = row.cari_hesap_para_birim;
    const cari_hesap_kur = evrakPb === null ? row.cari_hesap_kur : kurlist !== null ? calcKur(evrakPb, cari_hesap_para_birim, kurlist) : calcKur(evrakPb, cari_hesap_para_birim);
    let net_tutar = '';
    if (tutar !== '') {
      if (para_birimi === cari_hesap_para_birim) {
        if (para_birimi !== '-1' && cari_hesap_para_birim !== '-1') {
          net_tutar = (tutar * 1).toFixed(4);
        }
      } else {
        if (para_birimi !== '-1' && cari_hesap_para_birim !== '-1') {
          if (kur !== '' && (kur * 1) !== 0 && cari_hesap_kur !== '' && (cari_hesap_kur * 1) !== 0) {
            net_tutar = ((tutar * 1) * ((kur * 1) / (cari_hesap_kur * 1))).toFixed(4);
          }
        }
      }
    }
    const newrow = { ...row, kur: kur, cari_hesap_kur: cari_hesap_kur, net_tutar: net_tutar }
    setDetayForm((prev) => {
      return immer(prev, (next) => {
        next[index] = newrow;
        calcEvrakTotal(next);
      });
    });
  }

  const calcEvrakTotal = (form) => {
    const detayGrupByPB = _groupBy(form, item => item.para_birimi);
    let total = 0;
    if (Object.keys(detayGrupByPB).length === 1) {
      if (Object.keys(detayGrupByPB)[0] !== '-1') {
        total = (Object.values(detayGrupByPB)[0]).map((x) => Number(x.tutar) * Number(x.kur)).reduce((x, y) => x + y);
      }
    }
    if (Object.keys(detayGrupByPB).length > 1) {
      Object.values(detayGrupByPB).forEach((x) => {
        if (x[0].para_birimi !== '-1') {
          const xtotal = x.map((a) => Number(a.tutar) * Number(a.kur)).reduce((a, b) => a + b);
          total += xtotal;
        }
      })
    }
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next["genel_toplam"] = total.toFixed(4);
      });
    });
  }

  const handleGenelChangeForm = async (property, value, index = null) => {
    if (!loadingKur) {
      switch (property) {
        case 'evrak_kullanici':
          if (genelForm.magaza) {
            if (!genelForm.evrak_durum) {
              getEvrakDurumVarsayilan(genelForm.magaza, value);
            } else {
              let form = genelForm.evrak_durum;
              controlEvrakDurumKullanici(form, value);
            }
          }
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next[property] = value;
            });
          });
          break;
        case 'kodu':
          if (add) {
            setGenelForm((prev) => {
              return immer(prev, (next) => {
                next[property] = value;
              });
            });
          }
          break;
        case 'magaza':
          if (genelForm.evrak_kullanici) {
            getEvrakDurumVarsayilan(value, genelForm.evrak_kullanici);
          }
          if (add && value) {
            django(`evrak/evrak_kod/${value.id}/${genelForm.tip}`).then(({ data }) => {
              setGenelForm((prev) => {
                return immer(prev, (next) => {
                  next["kodu"] = data?.value;
                });
              });
            });
          }
          const parabirim = genelForm.para_birimi !== '-1' ? genelForm.para_birimi : String(value ? value['para_birim']['id'] : -1);
          getEkAlan(value.id, genelForm.evrak_ek_bilgiler).then((res) => {
            setGenelForm((prev) => {
              return immer(prev, (next) => {
                next[property] = value;
                next["para_birimi"] = parabirim;
                next["ek_bilgiler"] = res;
              });
            });
          })
          if (detayForm.length > 0) {
            detayForm.forEach((row, index) => {
              calcCariHesapNetTutar(row, index, parabirim);
            })
          }
          break;
        case 'para_birimi':
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next[property] = value;
            });
          });
          if (detayForm.length > 0) {
            detayForm.forEach((row, index) => {
              calcCariHesapNetTutar(row, index, value);
            })
          }
          break;
        case 'ek_bilgiler':
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next["ek_bilgiler"][index]["veri"] = value;
            });
          });
          break;
        case 'evrak_tarihi':
          if (genelForm.para_birimi) {
            const _kurlist = await getKurList(moment(value).format('YYYYMMDD'));
            setKurlar(_kurlist);
            if (_kurlist.length === 0) {
              enqueueSnackbar('Evrak tarihine ait kur bulunmamaktadır!Devam etmek için kur tanımlayınız.', { variant: 'error' });
            }
            setGenelForm((prev) => {
              return immer(prev, (next) => {
                next[property] = value;
                if (detayForm.length > 0) {
                  detayForm.forEach((row, index) => {
                    calcCariHesapNetTutar(row, index, genelForm.para_birimi, _kurlist);
                  })
                }
              });
            });
          } else {
            setGenelForm((prev) => {
              return immer(prev, (next) => {
                next[property] = value;
              });
            });
          }
          break;
        default:
          setGenelForm((prev) => {
            return immer(prev, (next) => {
              next[property] = value;
            });
          });
          break;
      }
    }
  };

  const handleChangeFormEvrakDurum = (property, value) => {
    if (property === "durum") {
      getEvrakDurumları(value, genelForm.evrak_kullanici);
    } else if (property === "kullaniciya_mail_gondersin") {
      controlEvrakDurumKullanici({ ...genelForm.evrak_durum, kullaniciya_mail_gondersin: value }, genelForm.evrak_kullanici)
    } else {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next["evrak_durum"][property] = value;
        });
      });
    }
  }

  const handleDetayChangeForm = (index, property, property2, property3, index2, value) => {
    switch (property) {
      case 'vade':
        if (property3 !== '') {
          setDetayForm((prev) => {
            return immer(prev, (next) => {
              next[index]["vade"][index2][property2][property3] = value;
            });
          });
        } else {
          setDetayForm((prev) => {
            return immer(prev, (next) => {
              next[index]["vade"][index2][property2] = value;
            });
          });
        }
        break;
      case 'tip':
        setDetayForm((prev) => {
          return immer(prev, (next) => {
            next[index] = { ...prev[index], tip: value, tutar: '', odeme_tanim: '-1', banka_hesap: '-1', para_birimi: '-1', kur: '', odeme_vade: '-1', net_tutar: '0', vade: VADE_DEFAULT };
            handleSelectListChange({ ...prev[index], tip: value, odeme_tanim: '-1', banka_hesap: '-1', para_birimi: '-1', kur: '' }).then((res) => {
              setDetayForm((prev) => {
                return immer(prev, (next) => {
                  next[index]["odemetanimlist"] = res[0];
                  next[index]["bankalist"] = res[1];
                  next[index]["parabirimlist"] = res[2];
                });
              });
            })
          });
        });
        break;
      case 'odeme_tanim':
        setDetayForm((prev) => {
          return immer(prev, (next) => {
            next[index] = { ...prev[index], odeme_tanim: value, banka_hesap: '-1', para_birimi: '-1', odeme_vade: '-1', kur: '', net_tutar: '0' };
            handleSelectListChange({ ...prev[index], odeme_tanim: value, banka_hesap: '-1', para_birimi: '-1' }).then((res) => {
              setDetayForm((prev) => {
                if (prev[index]["tip"] === '3') {
                  return immer(prev, (next) => {
                    next[index]["odemetanimlist"] = res[0];
                    next[index]["bankalist"] = res[1];
                    next[index]["parabirimlist"] = res[2];
                    next[index]["odemevadelist"] = res[3];
                    next[index]["odemevadelistdic"] = res[4];
                    next[index]["banka_hesap"] = String(res[1][0]['id']);
                  });
                } else {
                  return immer(prev, (next) => {
                    next[index]["odemetanimlist"] = res[0];
                    next[index]["bankalist"] = res[1];
                    next[index]["parabirimlist"] = res[2];
                  });
                }
              });
            })
          });
        });
        break;
      case 'banka_hesap':
        setDetayForm((prev) => {
          return immer(prev, (next) => {
            next[index] = { ...prev[index], banka_hesap: value, para_birimi: '-1', odeme_vade: '-1', kur: '', net_tutar: '0' };
            handleSelectListChange({ ...prev[index], banka_hesap: value, para_birimi: '-1' }).then((res) => {
              setDetayForm((prev) => {
                return immer(prev, (next) => {
                  next[index]["odemetanimlist"] = res[0];
                  next[index]["bankalist"] = res[1];
                  next[index]["parabirimlist"] = res[2];
                });
              });
            })
          });
        });
        break;
      case 'odeme_vade':
        if (detayForm[index]['tip'] === '3') {
          handleKKVadeList(index, value).then((response) => {
            calcCariHesapNetTutar({ ...detayForm[index], tutar: response[1], vade: response[0], odeme_vade: value }, index);
          })
        }
        break;
      case 'tutar':
        let row = { ...detayForm[index], tutar: value }
        if (detayForm[index]['tip'] === '3' && detayForm[index]['odeme_vade'] !== '-1') {
          row = { ...row, odeme_vade: '-1' }
        }
        if (detayForm[index]['tip'] === '2' || detayForm[index]['tip'] === '4') {
          let vade = [...detayForm[index].vade];
          vade[0] = { ...vade[0], tutar: value };
          row = { ...row, vade: vade }
        }
        calcCariHesapNetTutar(row, index);
        break;
      case 'para_birimi':
        const evrak_pb = genelForm.para_birimi;
        const kur = calcKur(evrak_pb, value);
        if (kur !== '0.0000') {
          calcCariHesapNetTutar({ ...detayForm[index], para_birimi: value, kur: kur }, index);
        } else {
          enqueueSnackbar('Evrak tarihine ait kur bulunamadı.Evrak tarihinde kur tanımlayınız.', { variant: 'error' });
          setDetayForm((prev) => {
            return immer(prev, (next) => {
              next[index][property] = value;
              next[index]['kur'] = kur;
              next[index]['net_tutar'] = '0';
            });
          });
        }
        break;
      case 'kur':
        calcCariHesapNetTutar({ ...detayForm[index], kur: value }, index);
        break;
      case 'cari_hesap_para_birim':
        const evrak_parabirim = genelForm.para_birimi;
        const cari_hesap_kur = calcKur(evrak_parabirim, value);
        if (cari_hesap_kur !== '0.0000') {
          calcCariHesapNetTutar({ ...detayForm[index], cari_hesap_para_birim: value, cari_hesap_kur: cari_hesap_kur }, index);
        } else {
          enqueueSnackbar('Evrak tarihine ait kur bulunamadı.Evrak tarihinde kur tanımlayınız.', { variant: 'error' });
          setDetayForm((prev) => {
            return immer(prev, (next) => {
              next[index][property] = value;
              next[index]['cari_hesap_kur'] = cari_hesap_kur;
              next[index]['net_tutar'] = '0';
            });
          });
        }
        break;
      case 'cari_hesap_kur':
        calcCariHesapNetTutar({ ...detayForm[index], cari_hesap_kur: value }, index);
        break;
      default:
        setDetayForm((prev) => {
          return immer(prev, (next) => {
            next[index][property] = value;
          });
        });
        break;
    }
  }

  const handleAddNewDetay = () => {
    let additem = { ...DETAY_DEFAULT }
    handleSelectListChange({ ...DETAY_DEFAULT }).then((res) => {
      additem = { ...additem, odemetanimlist: res[0], bankalist: res[1], parabirimlist: res[2], para_birimi: '-1', kur: '', cari_hesap_para_birim: genelForm.para_birimi ?? "-1", cari_hesap_kur: '1.0000' }
    }).then(() => {
      setDetayForm((prev) => immer(prev, (next) => {
        next.push({ ...additem })
      }));
    })
  }

  const handleDetayFormDelete = (index) => {
    setDetayForm((prev) => immer(prev, (next) => {
      next.splice(index, 1);
      calcEvrakTotal(next);
    }));

  }

  const handleAddVade = (index, tip) => {
    let sIndex = detayForm[index]["vade"].length + 1;
    if (tip === '4') {
      setDetayForm((prev) => immer(prev, (next) => {
        next[index].vade.push(
          {
            satir_no: sIndex,
            vade: moment(Date.now()).format('YYYY-MM-DD'),
            tutar: '',
            tahsilat_tarihi: moment(Date.now()).format('YYYY-MM-DD'),
            satir_kodu: '',
            status: 0,
            kimin: '',
            firma: false,
            aciklama: '',
            cek_banka_bilgi: {
              adi: '',
              sube_kodu: '',
              hesap_kodu: '',
              iban: '',
              cek_sira_no: '',
            },
            kefil_bilgi: {
              ad_soyad: '',
              telefon: '',
              adres: '',
              kimlik_no: '',
            }
          }
        )
      }))
    }
  }

  const handleDeleteVade = (index, index2, tip) => {
    if (tip === '4') {
      setDetayForm((prev) => immer(prev, (next) => {
        let newVade = [...prev[index].vade];
        newVade.splice(index2, 1);
        newVade = newVade.map((v, i) => ({ ...v, satir_no: i + 1 }))
        next[index].vade = newVade;
      }))
    }
  }

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DEFAULT);
    setDetayForm([]);
    setGenelError(null);
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    if (!IS_ADD) {
      setAdd(false);
      setDetayForm(detayFormDefault);
    } else {
      setDetayForm([]);
    }
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.evrak.odeme.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const checkAll = async () => {
    const list = [
      { prop: 'magaza', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'tip', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'kodu', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'evrak_tarihi', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'evrak_kullanici', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'para_birimi', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'genel_toplam', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ];

    if (genelForm.evrak_durum) {
      list.push({ prop: 'evrak_durum', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'mail_sablon' })
    }
    const list2 = [
      { prop: 'veri', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ];

    const listdetay = [
      { prop: 'tutar', prop2: '' },
      { prop: 'net_tutar', prop2: '' },
      { prop: 'kur', prop2: '' },
      { prop: 'cari_hesap_kur', prop2: '' },
      { prop: 'para_birimi', prop2: '' },
      { prop: 'cari_hesap_para_birim', prop2: '' },
      { prop: 'tip', prop2: '' },
    ];

    let errors = [];
    await checkAllList('evrak', genelForm, genelFormDefault, (err) => handleErrors("evrak", err, null, null, null, null, null), add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);

    for (const i in genelForm.ek_bilgiler) {
      const ek_bilgi = genelForm.ek_bilgiler[i];
      if (ek_bilgi.zorunlu) {
        const res = await checkAllList('', ek_bilgi, ek_bilgi, (err) => handleErrors("ek_bilgi", err, i, genelForm.ek_bilgiler.length, null, null, null), add, list2);
        if (!_isEmpty(res)) {
          errors = [...errors, res];
        }
      }
    }

    const length = detayForm.length;
    for (const i in detayForm) {
      const res = await checkAllList2(detayForm[i], (err) => handleErrors("tahsilat_detay", err, i, length, null, null, null), listdetay);
      if (!_isEmpty(res)) {
        errors = [...errors, res];
      }
    }
    return errors;
  }

  const handleSave = () => {
    checkAll().then((res) => {
      if (_isEmpty(res)) {
        let requestoptions = {};
        let evrak = { ...genelForm, tip: Number(genelForm.tip), versiyon: add ? 0 : Number(genelForm.versiyon) + 1, evrak_kullanici: genelForm.evrak_kullanici ? genelForm.evrak_kullanici.id : null, para_birimi: genelForm.para_birimi !== '-1' ? Number(genelForm.para_birimi) : null, magaza: genelForm.magaza ? genelForm.magaza.id : null, aciklama: genelForm.aciklama?.length <= 0 ? null : genelForm.aciklama, evrak_kur: "1.0000" };
        delete evrak.ek_bilgiler;

        let evrak_durum = genelForm.evrak_durum;
        if (evrak_durum) {
          evrak_durum = {
            ...evrak_durum,
            mail_sablon: Number(evrak_durum.mail_sablon),
            mail_sablon_site: evrak_durum.mail_sablon_site !== "-1" ? Number(evrak_durum.mail_sablon_site) : null,
            pdf_sablon: evrak_durum.pdf_sablon !== "-1" ? Number(evrak_durum.pdf_sablon) : null,
            mail_gondersin: evrak_durum.mail_gondersin,
            kullaniciya_mail_gondersin: evrak_durum.kullaniciya_mail_gondersin,
            alici_mailleri: evrak_durum.alici_mailleri,
            durum: evrak_durum.durum.id,
          }
          delete evrak_durum.mail_sablonlar;
          delete evrak_durum.mail_sablonlar_dic;
          delete evrak_durum.mail_sablonlar_site;
          delete evrak_durum.mail_sablonlar_site_dic;
          delete evrak_durum.pdf_sablonlar;
          delete evrak_durum.pdf_sablonlar_dic;
          evrak = { ...evrak, evrak_durum: [evrak_durum] }
        } else {
          evrak = { ...evrak, evrak_durum: [] }
        }

        const ek_bilgi = genelForm.ek_bilgiler.map((x) => ({
          ek_alan: x.ek_alan,
          veri: x.veri
        })).filter((y) => y.veri !== "");

        const detay = detayForm.map((d) => ({
          vade: (d.tip === '2' || d.tip === '3' || d.tip === '4') ?
            d.vade.map((v) => ({
              ...v,
              kimin: d.tip === '3' ? null : v.kimin,
            })) : null,
          tip: Number(d.tip),
          aciklama: d.aciklama?.length <= 0 ? null : d.aciklama,
          tutar: d.tutar,
          net_tutar: d.net_tutar,
          ortalama_vade_gun: Number(d.ortalama_vade_gun),
          ortalama_vade_tarih: d.ortalama_vade_tarih,
          tahsilat_tarih: d.tahsilat_tarih,
          //para_birimi: d.tip === '3' ? null : d.para_birimi !== '-1' ? Number(d.para_birimi) : null,
          para_birimi: d.para_birimi !== '-1' ? Number(d.para_birimi) : null,
          odeme_tanim: d.odeme_tanim !== '-1' ? Number(d.odeme_tanim) : null,
          banka_hesap: (d.tip === '1' || d.tip === '3' || d.tip === '4' || d.tip === '5') ? null : d.banka_hesap !== '-1' ? Number(d.banka_hesap) : null,
          odeme_vade: d.tip !== '3' ? null : (d.tip === '3') ? Number(d.odeme_vade) : null,
          cari_hesap_para_birim: Number(d.cari_hesap_para_birim),
          sezon: d.sezon !== '-1' ? Number(d.sezon) : null,
          kur: d.kur,
          cari_hesap_kur: d.cari_hesap_kur,
          tahsil_edildi: d.tahsil_edildi
        }));

        const data = { evrak: { ...evrak, ek_bilgi: ek_bilgi }, tahsilat_detay: detay };

        if (IS_EQUAL) {
          requestoptions = null;
        } else {
          requestoptions = {
            config: {
              method: 'POST',
              url: 'evrak/tahsilat',
              data: data
            },
            successMessage: `Evrak ekleme başarıyla tamamlandı`,
            errorMessageUnexpected: `Evrak eklenirken beklenmeyen bir hata oluştu`,
          }
        }

        const start = async () => {
          let errorCounts = 0;
          if (requestoptions !== null) {
            setGenelError(null);
            await django(requestoptions.config).then(({ data }) => {
              enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
              if (props.single) {
                history.push(URLS.evrak.odeme.detail(data.id, "detay"));
              }
              if (!props.single) {
                props.onAdded();
              }
            }).catch((error) => {
              if (error.response) {
                if (error.response.status === 500) {
                  enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
                } else {
                  setGenelError(error.response.data);
                }
              } else {
                enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
              }
              ++errorCounts;
            });
            if (errorCounts === 0) {
              setLastUpdateTime(Date.now());
            }
          }
        }
        start();
      }
    })
  };

  const handleErrors = (name, errors, index, length, name2, index2, length2) => {
    let obj = {}
    let next = {};
    setGenelError((prev) => {
      if (index === null) {
        if (_has({ ...prev }, name)) {
          if (name2 !== null) {
            if (_has({ ...prev[name] }, name2)) {
              prev[name][name2] = _assign(prev[name][name2], errors[name2]);
            } else {
              prev[name] = { ...prev[name], ...errors }
            }
          } else {
            _assign(prev[name], errors);
          }
          obj = { ...prev }
        } else {
          obj = { ...prev, [name]: { ...errors } }
        }
      } else {
        if (_has({ ...prev }, name)) {
          prev[name].length = length;
          if (index2 !== null) {
            if (_has({ ...prev[name][index] }, name2)) {
              prev[name][index][name2].length = length2;
              prev[name][index][name2][index2] = { ...prev[name][index][name2][index2], ...errors };
            } else {
              let errobj = new Array(length2).fill({});
              errobj[index2] = { ...errors }
              prev[name][index] = { ...prev[name][index], [name2]: errobj };
            }
          } else {
            prev[name][index] = { ...prev[name][index], ...errors };
          }
          obj = { ...prev }
        } else {
          let errobj2 = new Array(length).fill({});
          if (index2 !== null) {
            let errobj3 = new Array(length2).fill({});
            errobj3[index2] = { ...errors };
            errobj2[index] = { ...errobj2[index], [name2]: errobj3 };
          } else {
            errobj2[index] = { ...errors }
          }
          obj = { ...prev, [name]: errobj2 }
        }
      }



      if (_has(obj, "evrak")) {
        let evrak = _pickBy(obj["evrak"], _identity);
        if (_has(evrak, "evrak_durum")) {
          const evrakdurum = _pickBy({ ...evrak.evrak_durum }, _identity);
          evrak = _pickBy({ ...evrak, evrak_durum: evrakdurum }, _identity);
        }
        if (_isEmpty(evrak)) {
          obj = _pickBy({ ...obj, evrak: null }, _identity);
          next = _pickBy({ ...obj, evrak: null }, _identity);
        } else {
          obj = _pickBy({ ...obj, evrak: evrak }, _identity);
          next = _pickBy({ ...obj, evrak: evrak }, _identity);
        }
      }

      if (_has(obj, "ek_bilgi")) {
        let err = obj["ek_bilgi"];
        if (err.length > 0) {
          for (let i = 0; i < err.length; i++) {
            err[i] = _pickBy(err[i], _identity);
          }
        }
        const list = err.filter(x => !_isEmpty(x));
        if (_isEmpty(list)) {
          obj = _pickBy({ ...obj, ek_bilgi: null }, _identity);
          next = _pickBy({ ...obj, ek_bilgi: null }, _identity);
        } else {
          obj = _pickBy({ ...obj, ek_bilgi: err }, _identity);
          next = _pickBy({ ...obj, ek_bilgi: err }, _identity);
        }
      }

      if (_has(obj, "tahsilat_detay")) {
        let err = obj["tahsilat_detay"];
        if (err.length > 0) {
          for (let i = 0; i < err.length; i++) {
            if (err[i]["vade"]) {
              for (let j = 0; j < err[i]["vade"].length; j++) {
                err[i]["vade"][j] = _pickBy(err[i]["vade"][j], _identity)
              }
              const list = err[i]["vade"].filter(x => !_isEmpty(x));
              if (_isEmpty(list)) {
                err[i] = { ...err[i], vade: null }
              } else {
                err[i] = { ...err[i], vade: err[i]["vade"] }
              }
            }
            err[i] = _pickBy(err[i], _identity);
          }
        }
        const list = err.filter(x => !_isEmpty(x));
        if (_isEmpty(list)) {
          obj = _pickBy({ ...obj, tahsilat_detay: null }, _identity);
          next = _pickBy({ ...obj, tahsilat_detay: null }, _identity);
        } else {
          obj = _pickBy({ ...obj, tahsilat_detay: err }, _identity);
          next = _pickBy({ ...obj, tahsilat_detay: err }, _identity);
        }
      }

      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            loadingEkAlan={loadingEkAlan}
            loadingdetay={loadingdetay}
            hata={hataGenel}
            hatadetay={hataDetay}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}
            onChangeFormDurum={handleChangeFormEvrakDurum}

            detay={detayForm}
            onChangeFormDetay={handleDetayChangeForm}

            paraBirimList={paraBirimList}
            paraKoduList={paraKoduList}
            paraBirimDic={paraBirimDic}
            kurlar={kurlar}

            odemeTip={odemeTip}
            odemeTipDic={odemeTipDic}
            odemeTanim={odemeTanim}
            odemeTanimDic={odemeTanimDic}
            bankalar={bankalar}
            bankalarDic={bankalarDic}
            cekSenetDurum={cekSenetDurum}
            cekSenetDurumDic={cekSenetDurumDic}

            addNew={handleAddNewDetay}
            deleteRow={handleDetayFormDelete}
            addVade={handleAddVade}
            deleteVade={handleDeleteVade}

            onCheckErrors={handleErrors}
          />
        )}
        {selectedTabIndex === 1 && (
          <TabEposta
            loading={loading && loadingEvrakDurum}
            hata={hataGenel}
            form={genelForm}
            error={genelError}
            onChangeForm={handleChangeFormEvrakDurum}
            onCheckErrors={handleErrors}
          />
        )}
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Tahsilat siliniyor lütfen bekleyin'
                : 'Bu tahsilatı gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
    </>
  );
}

export default OdemeDetail;