import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Tooltip,
  Dialog,
  DialogContent,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import ListSelector from '../../../components/ListSelector';
import MultipleSelectList from '../../../components/MultipleSelectList';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 300,
      '& + &': {
        marginTop: 5,
      },
    },
  };
};

const DepartmanDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const [openSelectorKullanici, setOpenSelectorKullanici] = useState(false);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const handleRemoveKullaniciById = (id) => {
    const copyKullanicilar = [...props.form.kullanicilar];
    const index = copyKullanicilar.findIndex((c) => c.id === id);
    if (index > -1) {
      copyKullanicilar.splice(index, 1);
      props.onChangeForm('kullanicilar', copyKullanicilar)
    }
  };

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.layoutContainer}>
              <ul className={classes.layoutFormList}>
                <li className={classes.layoutFormItem} id="tanim">
                  <label className={classes.layoutFormItemLabel} htmlFor="tanim">Departman Tanım *</label>
                  <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                    <TextField
                      {...createTextFieldProps('tanim')}
                      onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                      onBlur={() => handleInputErrors('departman', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Departman Tanım', 'departman')}
                      inputProps={{
                        maxLength: 250,
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="departman_tip">
                  <label className={classes.layoutFormItemLabel} htmlFor="departman_tip">Tip *</label>
                  <Tooltip title={props.error?.departman_tip ? props.error.departman_tip[0] : _isNil(props.form?.departman_tip) ? '' : props.tipListDic ? props.tipListDic[props.form.departman_tip] : ''}>
                    <TextField
                      {...createTextFieldProps('departman_tip')}
                      onChange={(e) => props.onChangeForm('departman_tip', e.target.value)}
                      onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'departman_tip', true, false, false, '', '', '')}
                      select
                      SelectProps={{ native: true }}
                    >
                      {props.tipList?.map((tip) => (
                        <option key={tip.key} value={tip.key}>{tip.value}</option>
                      ))}
                    </TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="siralama">
                  <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                  <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                    <TextField
                      {...createTextFieldProps('siralama')}
                      onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                      type="number"
                      inputProps={{
                        min: -2147483648,
                        max: 2147483647,
                        step: 1,
                        style: { textAlign: 'right' }
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="aktif">
                  <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                  <Checkbox
                    name="aktif"
                    color="primary"
                    checked={_result(props.form, 'aktif', false)}
                    onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                  ></Checkbox>
                </li>
              </ul>
              <ul className={classes.layoutFormList}>
                <li className={classes.layoutFormItemBox}>
                  <MultipleSelectList
                    headername="KULLANICILAR"
                    name="Kullanıcılar"
                    name2="Kullanıcı"
                    form={props.form}
                    list={props.form?.kullanicilar ?? []}
                    listlength={props?.form?.kullanicilar?.length ?? 0}
                    opendialog={setOpenSelectorKullanici}
                    keyProp="id"
                    valueProp="username"
                    removefonk={handleRemoveKullaniciById}
                    loading={props.loading}
                    error={props.error?.kullanicilar ? props.error.kullanicilar[0] : null}
                    ustId={false}
                    newPath='tanimlar/yonetici'
                  />
                </li>
              </ul>
            </div>
          )}
        </>
      }
      {openSelectorKullanici &&
        <Dialog
          open={openSelectorKullanici}
          PaperProps={{
            style: {
              width: '480px',
              height: '600px',
            },
          }}
          onClose={() => {
            setOpenSelectorKullanici(false);
            handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'kullanicilar', true, false, false, '', '', '')
          }}
        >
          <DialogContent style={{ padding: 0 }}>
            <ListSelector
              title='Kullanıcılar'
              listPath='kullanici/mini_list?is_staff=true&aktif=true'
              listCountPath='kullanici/count?is_staff=true&aktif=true'
              keyProp='id'
              valueProp='username'
              defaultItems={props.form.kullanicilar}
              onChangeItems={(items) => props.onChangeForm('kullanicilar', items)}
              onClose={() => {
                setOpenSelectorKullanici(false);
                handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'kullanicilar', true, false, false, '', '', '')
              }}
              newPath='tanimlar/yönetici'
            />
          </DialogContent>
        </Dialog>
      }
    </>
  );
});

export default DepartmanDetailTabGenel;
