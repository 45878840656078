import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import { makeStyles } from '@material-ui/styles';
import RightMenu from '../helpers/rightMenu';
import {
  Button,
  Checkbox,
  Paper,
  CircularProgress,
  LinearProgress,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
  Search as IconSearch,
  KeyboardArrowRight as IconKeyboardArrowRight,
  KeyboardArrowLeft as IconKeyboardArrowLeft,
  AccountTree as IconListTree,
  Refresh as IconRefresh,
} from '@material-ui/icons';

import django from '../api/django';

const useStyles = makeStyles((theme) => {
  return {
    page: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: '50% 50%'
    },
    paper: {
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      height: '100%',
      marginLeft: '1%',
    },
    list: {
      marginBottom: 10
    },
    title: {
      position: 'relative',
      display: 'inline-block',
      color: '#976797',
      fontSize: 15,
      fontWeight: '500'
    },
    ul: {
      position: 'relative',
      paddingLeft: '3em',
      '&::before': {
        position: 'absolute',
        top: 0,
        bottom: 7,
        left: '3em',
        display: 'block',
        width: 0,
        borderLeft: '2px solid #eacfea',
        content: '""',
      }
    },
    li: {
      padding: '15px 0 0 40px',
      position: 'relative',
      lineHeight: '15px',
      '&::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        height: 25,
        width: 30,
        borderBottom: '2px solid #eacfea',
        borderLeft: '2px solid #eacfea',
        borderRadius: '0 0 0 5px',
        content: '""',
      }
    },
  };
});


const PER_PAGE = 100;
const ROW_HEIGHT = 34;

const turkishToLower = (string) => {
  const letters = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
  string = string.replace(/(([İIŞĞÜÇÖ]))/g, (letter) => {
    return letters[letter];
  })
  return string.toLowerCase();
}

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text, submitText, onSubmit }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>

      <p className="font-medium mb-2">{text}</p>

      {onSubmit &&
        <Button
          color="primary"
          onClick={onSubmit}
        >{submitText ?? 'TEKRAR DENE'}</Button>
      }
    </div>
  );
};

const ListItemMain = ({ item, searchQuery, state, goList, checkAll }) => {
  return (
    <div
      key={`${item.tanim}`}
      className={clsx('w-full flex items-center justify-start hover:bg-palette-background-default active:bg-palette-action-hover px-4 space-x-3 select-none cursor-pointer')}
      style={{ height: ROW_HEIGHT }}
    >

      {state !== 'half' && (
        <Checkbox
          color="primary"
          checked={state === 'full' ? true : false}
          onChange={(e, checked) => checkAll(checked)}
          style={{ padding: 0 }}
        ></Checkbox>
      )}

      {state === 'half' && (
        <Checkbox
          color="primary"
          onChange={() => checkAll(true)}
          checked={state === 'half' ? true : false}
          indeterminate={state === 'half' ? true : false}
          style={{ padding: 0 }}
        ></Checkbox>
      )}

      <Highlighter
        textToHighlight={`${item.tanim}`}
        searchWords={[searchQuery ?? '']}
        className=""
        activeClassName=""
        highlightClassName="leading-none"
        unhighlightClassName="leading-none"
      />
      {item?.vadeler?.length > 0 && (
        <span className="absolute right-0 w-12 flex items-center justify-center hover:bg-transparent">
          <IconButton
            size="small"
            disableRipple
            onClick={() => goList(item)}
          >
            <IconKeyboardArrowRight />
          </IconButton>
        </span>
      )}
    </div>
  );
};

const ListItem = ({ item, checked, searchQuery, onClick }) => {
  return (
    <div
      key={`${item.id}${item.tanim}`}
      className={clsx('w-full flex items-center justify-start hover:bg-palette-background-default active:bg-palette-action-hover px-4 space-x-3 select-none cursor-pointer')}
      style={{ height: ROW_HEIGHT }}
      onClick={() => onClick(!checked)}
    >
      <Checkbox
        color="primary"
        checked={checked}
        onChange={(e, checked) => onClick(checked)}
        style={{ padding: 0 }}
      ></Checkbox>

      <Highlighter
        textToHighlight={`${item.tanim}`}
        searchWords={[searchQuery ?? '']}
        className=""
        activeClassName=""
        highlightClassName="leading-none"
        unhighlightClassName="leading-none"
      />
    </div>
  );
};

const ListSelectorTwoStep = ({ defaultItems, onChangeItems, onClose, detayListOpen, onListClose }) => {

  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsErrorMessage, setItemsErrorMessage] = useState(null);
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchItems, setSearchItems] = useState([]);
  const [searchItemsLoading, setSearchItemsLoading] = useState(false);
  const [searchItemsLoadingErrorMessage, setSearchItemsLoadingErrorMessage] = useState(null);

  const [searchQueryList, setSearchQueryList] = useState('');
  const [searchItemsList, setSearchItemsList] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [listOpen, setListOpen] = useState(false);

  const [selectedRItem, setSelectedRItem] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const PAGE = useMemo(() => items.length > 0 ? Math.ceil(items.length / PER_PAGE) : 1, [items.length]);

  const PAGE_COUNT = useMemo(() => Math.ceil(itemsTotalCount / PER_PAGE), [itemsTotalCount]);

  const mainContainerRef = useRef();

  const DEFAULT_ITEMS = useMemo(() => defaultItems, [defaultItems]);

  const treeListOpen = useMemo(() => detayListOpen, [detayListOpen]);

  useLayoutEffect(() => {
    setItemsLoading(true);
    django('odemetanim/mini_list?tip=3&aktif=true', { params: { size: PER_PAGE, sort: 'tanim' } })
      .then(({ data }) => {
        const list = data.map((item) => ({
          id: item.id,
          tanim: item.tanim,
          vadeler: item.vadeler.map((x) => ({ id: x.id, tanim: x.tanim }))
        }))
        setItems(list);
      })
      .catch(() => {
        setItemsErrorMessage('Beklenmeyen bir hata oluştu')
      })
      .finally(() => {
        setItemsLoading(false);
      })
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django(`odemetanim/count?tip=3&aktif=true`).then(({ data }) => {
      setItemsTotalCount(data)
    })
  }, [lastUpdateTime]);

  const SHOW_LINEAR_PROGRESS = useMemo(() => {
    if (PAGE_COUNT === 0 || itemsLoading || moreItemsLoading || searchItemsLoading) {
      return true;
    }
    return false;
  }, [PAGE_COUNT, itemsLoading, moreItemsLoading, searchItemsLoading]);


  const LOADING_MESSAGE = useMemo(() => {
    if (PAGE_COUNT === 0 || itemsLoading) {
      return 'Seçenekler yükleniyor';
    }
    if (searchItemsLoading) {
      return 'Arama yapılıyor';
    }
    return null;
  }, [PAGE_COUNT, itemsLoading, searchItemsLoading]);


  const ERROR_MESSAGE = useMemo(() => {
    if (itemsErrorMessage) {
      return itemsErrorMessage;
    }
    if (searchItemsLoadingErrorMessage) {
      return searchItemsLoadingErrorMessage;
    }
    return null;
  }, [itemsErrorMessage, searchItemsLoadingErrorMessage]);


  const FILTERED_ITEMS = useMemo(() => {
    if (searchQuery !== '') {
      return searchItems;
    }
    return items;
  }, [items, searchItems, searchQuery]);

  const FILTERED_ITEMS_LIST = useMemo(() => {
    if (searchQueryList !== '') {
      return searchItemsList;
    }
    return selectedItem?.vadeler;
  }, [selectedItem, searchItemsList, searchQueryList]);


  useEffect(() => {
    const handleGet = ({ url, config }) => {
      setSearchItems([]);
      setSearchItemsLoading(true);
      setSearchItemsLoadingErrorMessage(null);

      django(url, config).then(({ data }) => {
        setSearchItems(data);
      }).catch((error) => {
        if (django?.isCancel(error)) {
        } else {
          setSearchItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu')
        }
      }).finally(() => {
        setSearchItemsLoading(false);
      });
    };

    if (searchQuery === '') {
      setSearchItems([]);
      setSearchItemsLoading(false);
      setSearchItemsLoadingErrorMessage(null);
    }

    if (searchQuery !== '') {
      setSearchItems([]);
      setSearchItemsLoading(true);
      setSearchItemsLoadingErrorMessage(null);

      const CancelToken = django.CancelToken;
      const cancelTokenSource = CancelToken.source();

      const config = {
        params: {
          size: PER_PAGE,
          tanim: `*${searchQuery}*`,
        },
        cancelToken: cancelTokenSource.token,
      };

      const debounce = setTimeout(() => handleGet({ url: 'odemetanim/mini_list?tip=3', config }), 300);

      return () => {
        cancelTokenSource.cancel();

        clearTimeout(debounce);

        setSearchItemsLoading(false);
      };
    }
  }, [searchQuery, lastUpdateTime]);

  useEffect(() => {
    if (selectedItem) {
      const handleGet = () => {
        const vadeler = [...selectedItem.vadeler];
        let arrFiltered = vadeler.filter(item => turkishToLower(item.tanim).includes(turkishToLower(searchQueryList)));
        setSearchItemsList(arrFiltered);
      }
      if (searchQueryList === '') {
        setSearchItemsList([]);
      }
      if (searchQueryList !== '') {
        setSearchItemsList([]);
        handleGet();
      }
    }
  }, [searchQueryList, selectedItem])

  const handleGoToList = (item) => {
    setSelectedItem(item);
    setListOpen(true);
    setSearchQuery('');
    setSearchItemsLoading(false);
    setSearchItemsLoadingErrorMessage(null);

    setSearchQueryList('');

  }

  const handleGoBackToMain = () => {
    setSelectedItem(null);
    setListOpen(false);
    setSearchQuery('');
    setSearchItemsLoading(false);
    setSearchItemsLoadingErrorMessage(null);

    setSearchQueryList('');
  }

  const handleCheckItem = (item, checked) => {
    let copyMappedDefaultItems = [...DEFAULT_ITEMS];
    const defaultItemIndex = copyMappedDefaultItems.findIndex((x) => x.tanim === selectedItem.tanim);
    if (checked) {
      if (defaultItemIndex > -1) {
        let vadeler = [...copyMappedDefaultItems[defaultItemIndex].vadeler, item];
        copyMappedDefaultItems[defaultItemIndex] = { ...copyMappedDefaultItems[defaultItemIndex], vadeler: vadeler };
      } else {
        copyMappedDefaultItems.push({ tanim: selectedItem.tanim, vadeler: [item] })
      }
    } else {
      let vadeler = [...copyMappedDefaultItems[defaultItemIndex].vadeler]
      const index = vadeler.findIndex((item2) => item2.id === item.id);
      if (index > -1) {
        vadeler.splice(index, 1);
        if (vadeler.length > 0) {
          copyMappedDefaultItems[defaultItemIndex] = { ...copyMappedDefaultItems[defaultItemIndex], vadeler: vadeler };
        } else {
          copyMappedDefaultItems.splice(defaultItemIndex, 1);
        }
      }
    }
    copyMappedDefaultItems = copyMappedDefaultItems.sort((a, b) => a['tanim'].localeCompare(b['tanim']));
    onChangeItems(copyMappedDefaultItems);
  }

  const handleCheckAll = (item, checked) => {
    let copyMappedDefaultItems = [...DEFAULT_ITEMS];
    const defaultItemIndex = copyMappedDefaultItems.findIndex((x) => x.tanim === item.tanim);
    if (checked) {
      if (defaultItemIndex > -1) {
        copyMappedDefaultItems[defaultItemIndex] = item;
      } else {
        copyMappedDefaultItems.push(item);
      }
    } else {
      if (defaultItemIndex > -1) {
        copyMappedDefaultItems.splice(defaultItemIndex, 1);
      }
    }
    copyMappedDefaultItems = copyMappedDefaultItems.sort((a, b) => a['tanim'].localeCompare(b['tanim']));
    onChangeItems(copyMappedDefaultItems);
  }

  const handleGetMoreOptionsMain = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setMoreItemsLoading(true);
    django('odemetanim/mini_list?tip=3', { params: { size: PER_PAGE, page, sort: 'tanim' } }).then(({ data }) => {
      setItems((prev) => [...prev, ...data]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setMoreItemsLoading(false);
    });
  }

  const mainRowRenderer = ({ index, style }) => {
    const item = FILTERED_ITEMS[index];
    const def_item = DEFAULT_ITEMS?.find((x) => x.tanim === item.tanim);
    let state = '';
    if (def_item) {
      if (def_item.vadeler.length > 0) {
        if (def_item.vadeler.length === item.vadeler.length) {
          state = 'full'
        } else {
          state = 'half'
        }
      } else if (def_item.vadeler.length === 0) {
        state = 'empty'
      }
    } else {
      state = 'empty'
    }
    return (
      <div key={index} style={style}
        onContextMenu={(e) => {
          e.preventDefault();
          setSelectedRItem(item);
          setSelectedPosition({ top: e.clientY, left: e.clientX });
        }}
      >
        {index !== 0 && <Divider />}
        <ListItemMain
          item={item}
          state={state}
          searchQuery={searchQuery}
          goList={() => { handleGoToList(item) }}
          checkAll={(checked) => handleCheckAll(item, checked)}
        />
      </div>
    )
  }


  const rowRenderer = (item, index) => {
    if (selectedItem) {
      const def_item = DEFAULT_ITEMS.find((x) => x.tanim === selectedItem.tanim);
      const checked = def_item ? def_item.vadeler.findIndex((item2) => item2.id === item.id) > -1 : false;
      return (
        <div key={item.id}>
          {index !== 0 && <Divider />}
          <ListItem
            item={item}
            checked={checked}
            searchQuery={searchQuery}
            onClick={(checked) => { handleCheckItem(item, checked) }}
          />
        </div>
      )
    }
  }

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  return (
    <>
      <div className="w-full h-full flex justify-between">
        <Paper className="w-full h-full flex flex-col overflow-hidden">
          <AppBar
            className="border-b border-palette-action-selected"
            position="sticky"
            color="transparent"
            elevation={0}
          >
            <Toolbar
              className="flex items-center justify-between bg-primary text-palette-background-paper px-4"
              variant="dense"
              disableGutters
            >
              <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">{!listOpen ? 'Vade' : selectedItem.tanim}</h3>
              <div>
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={handleReload}
                ><IconRefresh /></IconButton>
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={onClose}
                ><IconClose /></IconButton>
                {!treeListOpen && (
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={() => onListClose(true)}
                  ><IconListTree /></IconButton>
                )}
              </div>
            </Toolbar>

            <nav className="w-full flex-1 flex items-center justify-between bg-palette-background-default py-2 px-4 space-x-4">
              <span className="relative w-full h-8 leading-none bg-palette-background-paper apperance-none">
                <input
                  className={clsx([
                    'absolute inset-0 w-full h-full px-10 bg-transparent rounded border border-palette-action-selected',
                    'focus:outline-none focus:border-primary-300 focus:ring-2 focus:ring-primary-200',
                  ])}
                  value={!listOpen ? searchQuery ?? '' : searchQueryList ?? ''}
                  onChange={(e) => {
                    if (!listOpen) {
                      setSearchQuery(e.target.value)
                    } else {
                      setSearchQueryList(e.target.value)
                    }
                  }}
                />

                <span className="absolute top-2 left-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                  <IconSearch />
                </span>

                {!listOpen && searchQuery.length > 0 &&
                  <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSearchQuery('');
                      }}
                    >
                      <IconClose />
                    </IconButton>
                  </span>
                }
                {listOpen && searchQuery.length > 0 &&
                  <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSearchItemsList('');
                      }}
                    >
                      <IconClose />
                    </IconButton>
                  </span>
                }
              </span>
            </nav>
            {!listOpen && (
              <span className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', { 'hidden': !SHOW_LINEAR_PROGRESS })}>
                <LinearProgress style={{ height: 2 }} />
              </span>
            )}
            {listOpen && (
              <span className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', { 'hidden': !SHOW_LINEAR_PROGRESS })}>
                <LinearProgress style={{ height: 2 }} />
              </span>
            )}

          </AppBar>

          <main className="relative flex flex-grow flex-col overflow-hidden" ref={mainContainerRef}>
            {!listOpen && (
              <div className="h-full flex-grow">
                {LOADING_MESSAGE && (
                  <LoadingMessage text={LOADING_MESSAGE} />
                )}
                {ERROR_MESSAGE && (
                  <ErrorMessage text={ERROR_MESSAGE} />
                )}
                {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length === 0) && (
                  <ErrorMessage text="Seçenek bulunamadı" />
                )}
                {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length > 0) && (
                  <InfiniteLoader
                    minimumBatchSize={PER_PAGE}
                    threshold={PER_PAGE}
                    isRowLoaded={({ index }) => !!FILTERED_ITEMS[index]}
                    loadMoreRows={({ startIndex, stopIndex }) => {
                      if (itemsTotalCount > 0 && PAGE_COUNT > PAGE && !moreItemsLoading) {
                        handleGetMoreOptionsMain(PAGE + 1);
                      }
                    }}
                    rowCount={itemsTotalCount > 0 ? itemsTotalCount : PER_PAGE * 2}
                  >
                    {({ onRowsRendered, registerChild }) => (
                      <AutoSizer>
                        {({ width, height }) => (
                          <List
                            width={width}
                            height={height}
                            rowHeight={ROW_HEIGHT + 1}
                            rowCount={FILTERED_ITEMS.length}
                            estimatedRowSize={PAGE_COUNT > 0 ? PAGE_COUNT * (ROW_HEIGHT + 1) : undefined}
                            rowRenderer={mainRowRenderer}

                            onRowsRendered={onRowsRendered}
                            ref={registerChild}
                            onScroll={() => setSelectedRItem(null)}
                          />
                        )}
                      </AutoSizer>
                    )}
                  </InfiniteLoader>
                )}
              </div>
            )}
            {listOpen && (
              <div className="h-full flex-grow">
                <div>
                  <button
                    className="w-full h-10 flex items-center justify-between bg-palette-background-default hover:bg-palette-action-selected cursor-pointer focus:outline-none px-5"
                    onClick={() => handleGoBackToMain()}
                  >
                    <span className="flex items-center justify-start font-medium">Listeye Geri Dön</span>
                    <IconKeyboardArrowLeft />
                  </button>
                </div>
                {FILTERED_ITEMS?.length > 0 && (
                  <div>
                    {FILTERED_ITEMS_LIST.map((item, index) => (
                      rowRenderer(item, index)
                    ))}
                  </div>
                )}
              </div>
            )}
            {selectedRItem && (
              <RightMenu
                url="tanimlar/odeme_tanim"
                itemId={selectedRItem?.id}
                position={selectedPosition}
              ></RightMenu>
            )}
          </main>
        </Paper>

        <Paper className={classes.paper} style={{ display: treeListOpen ? 'block' : 'none' }}>
          <AppBar
            className="border-b border-palette-action-selected"
            position="sticky"
            color="transparent"
            elevation={0}
          >
            <Toolbar
              className="flex items-center justify-end bg-primary text-palette-background-paper px-4"
              variant="dense"
              disableGutters
            >
              <IconButton
                edge="end"
                color="inherit"
                size="small"
                onClick={() => onListClose(false)}
              ><IconClose /></IconButton>
            </Toolbar>
          </AppBar>

          <main className="relative flex flex-grow flex-col overflow-y-auto p-4">
            {DEFAULT_ITEMS?.length > 0 && (
              <>
                {DEFAULT_ITEMS.map((item, index) => (
                  <div key={index} className={classes.list}>
                    <div className={classes.title}>{item.tanim}</div>
                    <div className={classes.ul}>
                      {item.vadeler.map((v) => (
                        <div key={v.id} className={classes.li}>{v.tanim}</div>
                      ))}
                    </div>
                  </div>
                ))}
              </>
            )}
          </main>
        </Paper>
      </div>
    </>
  );
};


export default ListSelectorTwoStep;
