import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import RightMenu from '../helpers/rightMenu';
import { turkishToLower } from '../helpers/helpers';

import {
  Button,
  Checkbox,
  Paper,
  CircularProgress,
  LinearProgress,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Tooltip,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
  Search as IconSearch,
  Refresh as IconRefresh,
} from '@material-ui/icons';

import django from '../api/django';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
      minHeight: '36px !important'
    }
  };
});

const PER_PAGE = 100;
const ROW_HEIGHT = 34;

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text, submitText, onSubmit }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>

      <p className="font-medium mb-2">{text}</p>

      {onSubmit &&
        <Button
          color="primary"
          onClick={onSubmit}
        >{submitText ?? 'TEKRAR DENE'}</Button>
      }
    </div>
  );
};

const OptionItem = ({ option, isChecked, viewProp, highlightQuery, onClick }) => {
  return (
    <div
      key={`${option.id}${option[viewProp]}`}
      className={clsx('w-full flex items-center justify-start hover:text-primary px-4 space-x-3 select-none cursor-pointer')}
      style={{
        height: ROW_HEIGHT,
      }}
      onClick={() => onClick(!isChecked)}
    >
      <Checkbox
        color="primary"
        checked={isChecked}
        onChange={(e, checked) => onClick(checked)}
        style={{
          padding: 0,
        }}
      ></Checkbox>

      <Highlighter
        textToHighlight={`${option[viewProp]}`}
        searchWords={[highlightQuery ?? '']}
        className=""
        activeClassName=""
        highlightClassName="leading-none"
        unhighlightClassName="leading-none"
      />
    </div>
  );
};

const MultipleFilterSelector = ({ title, optionsPath, filter, filterProp, sortProp, viewProp, selectedOptionKeys, onSetSelectedOptionKeys, onSubmit, onClose, newpath, ustId, staticpage }) => {
  const [options, setOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [optionsLoadingErrorMessage, setOptionsLoadingErrorMessage] = useState(null);

  const [optionsCount, setOptionsCount] = useState(0);

  const [moreOptionsLoading, setMoreOptionsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchOptionsLoading, setSearchOptionsLoading] = useState(false);
  const [searchOptionsLoadingErrorMessage, setSearchOptionsLoadingErrorMessage] = useState(null);

  const [showSelectedOptions, setShowSelectedOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsLoading, setSelectedOptionsLoading] = useState(false);
  const [selectedOptionsLoadingErrorMessage, setSelectedOptionsLoadingErrorMessage] = useState(null);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const classes = useStyles();

  const mainContainerRef = useRef();

  // set options
  useLayoutEffect(() => {
    setOptionsLoading(true);
    django(staticpage ? `${optionsPath}` : filter ? `${optionsPath}/mini_list${filter}` : `${optionsPath}/mini_list`, { params: { sort: sortProp } }).then(({ data }) => {
      setOptions(data);
      if (staticpage) {
        setOptionsCount(data.length)
      }
    }).catch((error) => {
      setOptionsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
    }).finally(() => {
      setOptionsLoading(false);
    });
  }, [optionsPath, sortProp, lastUpdateTime, filter, staticpage]);

  // set options count
  useLayoutEffect(() => {
    if (!staticpage) {
      django(filter ? `${optionsPath}/count${filter}` : `${optionsPath}/count`).then(({ data }) => {
        setOptionsCount(data);
      });
    }
  }, [optionsPath, lastUpdateTime, filter, staticpage]);

  // handle search
  useEffect(() => {
    if (staticpage) {
      const handleGet = (searchQuery) => {
        const list = [...options];
        const filterList = list.filter((item) => turkishToLower(item.value).includes(turkishToLower(searchQuery)));
        setSearchOptions(filterList);
        setSearchOptionsLoading(false);
      }
      if (searchQuery !== '') {
        setSearchOptions([]);
        setSearchOptionsLoading(true);
        setSearchOptionsLoadingErrorMessage(null);
        const debounce = setTimeout(() => handleGet(searchQuery), 500);
        return () => {
          clearTimeout(debounce);
          setSearchOptionsLoading(false);
        };
      }
    } else {
      const handleGet = ({ url, config }) => {
        setSearchOptions([]);
        setSearchOptionsLoading(true);
        setSearchOptionsLoadingErrorMessage(null);

        django(url, config).then(({ data }) => {
          setSearchOptions(data);
        }).catch(function (error) {
          if (django?.isCancel(error)) {
          } else {
            setSearchOptionsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
          }
        }).finally(() => {
          setSearchOptionsLoading(false);
        });
      };

      if (searchQuery !== '') {
        const CancelToken = django.CancelToken;
        const cancelTokenSource = CancelToken.source();

        const config = {
          params: {
            tanim: `*${searchQuery}*`,
          },
          cancelToken: cancelTokenSource.token,
        };
        const path = filter ? optionsPath + filter : optionsPath;
        const debounce = setTimeout(() => handleGet({ url: path, config }), 500);

        return () => {
          cancelTokenSource.cancel();

          clearTimeout(debounce);
        };
      }
    }
  }, [optionsPath, searchQuery, lastUpdateTime, filter, staticpage, options]);

  // handle selected options
  useEffect(() => {
    if (showSelectedOptions) {
      if (filterProp === 'id') {
        // seçili idleri arrayde birleştirelim
        const id = [...selectedOptionKeys].map((id) => id);

        setSelectedOptionsLoading(true);

        django(`${optionsPath}/mini_list`, { params: { id, sort: sortProp } }).then(({ data }) => {
          setSelectedOptions(data);
        }).catch(function (error) {
          if (django?.isCancel(error)) {
          } else {
            setSelectedOptionsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
          }
        }).finally(() => {
          setSelectedOptionsLoading(false);
        });
      } else {
        const options = [...selectedOptionKeys].map(((value, index) => ({ id: index, [viewProp]: value })));
        setSelectedOptions(options);
      }
    }
  }, [showSelectedOptions, selectedOptionKeys, filterProp, sortProp, viewProp, optionsPath, lastUpdateTime, staticpage]); // optionsPath

  const handleCheckOption = (option, checked) => {
    // url'den array geldiği zaman id'ler string olarak geliyor
    // onları Number'a çevirelim
    const copySelectedOptionKeys = [...selectedOptionKeys].map((k) => (filterProp === 'id' ? Number(k) : k));

    if (checked) {
      copySelectedOptionKeys.push(option[filterProp]);
    } else {
      const index = copySelectedOptionKeys.findIndex((key) => key === option[filterProp]);

      if (index > -1) {
        copySelectedOptionKeys.splice(index, 1);
      }
    }

    onSetSelectedOptionKeys(copySelectedOptionKeys);
  };

  const handleGetMoreOptions = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;

    setMoreOptionsLoading(true);

    django(optionsPath, { params: { page, sort: sortProp } }).then(({ data }) => {
      setOptions((prev) => {
        return [...prev, ...data];
      });
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });

      setMoreOptionsLoading(false);
    });
  };

  const PAGE = useMemo(() => options.length > 0 ? Math.ceil(options.length / PER_PAGE) : 1, [options.length]);
  const PAGES_COUNT = useMemo(() => Math.ceil(optionsCount / PER_PAGE), [optionsCount]);

  const SHOW_LINEAR_PROGRESS = useMemo(() => {
    if (optionsLoading || moreOptionsLoading || searchOptionsLoading || selectedOptionsLoading) {
      return true;
    }

    return false;
  }, [optionsLoading, moreOptionsLoading, searchOptionsLoading, selectedOptionsLoading]);

  const LOADING_MESSAGE = useMemo(() => {
    if (optionsLoading) {
      return 'Seçenekler yükleniyor';
    }

    if (searchOptionsLoading) {
      return 'Arama yapılıyor';
    }

    if (selectedOptions.length === 0 && selectedOptionsLoading) {
      return 'Seçilen seçenekler yükleniyor';
    }

    return null;
  }, [optionsLoading, searchOptionsLoading, selectedOptions, selectedOptionsLoading]);

  const ERROR_MESSAGE = useMemo(() => {
    if (optionsLoadingErrorMessage) {
      return optionsLoadingErrorMessage;
    }

    if (searchOptionsLoadingErrorMessage) {
      return searchOptionsLoadingErrorMessage;
    }

    if (selectedOptionsLoadingErrorMessage) {
      return selectedOptionsLoadingErrorMessage;
    }

    return null;
  }, [optionsLoadingErrorMessage, searchOptionsLoadingErrorMessage, selectedOptionsLoadingErrorMessage]);

  const FILTERED_OPTIONS = useMemo(() => {
    if (showSelectedOptions) {
      return selectedOptions;
    }

    if (searchQuery !== '') {
      return searchOptions;
    }

    return options;
  }, [showSelectedOptions, selectedOptions, options, searchOptions, searchQuery]);

  const rowRenderer = ({ index, style }) => {
    const option = FILTERED_OPTIONS[index];
    const key = option[filterProp];

    // url'den array geldiği zaman id'ler string olarak geliyor
    // onları Number'a çevirelim
    const copySelectedOptionKeys = [...selectedOptionKeys].map((k) => (filterProp === 'id' ? Number(k) : k));

    const isChecked = copySelectedOptionKeys.indexOf(key) > -1;

    return (
      <div key={key} style={style}
        onContextMenu={(e) => {
          e.preventDefault();
          setSelectedItem(option);
          setSelectedPosition({ top: e.clientY, left: e.clientX });
        }}
      >
        {index !== 0 && <Divider />}

        <OptionItem
          option={option}
          isChecked={isChecked}
          viewProp={viewProp}
          highlightQuery={!showSelectedOptions ? searchQuery : ''}
          onClick={(checked) => handleCheckOption(option, checked)}
        ></OptionItem>
      </div>
    );
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  return (
    <>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">{title ? title : 'Filtrele'}</h3>
            <span>
              <Tooltip title="Yenile">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={handleReload}
                ><IconRefresh /></IconButton>
              </Tooltip>
              <Tooltip title="Kapat">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={onClose}
                ><IconClose /></IconButton>
              </Tooltip>
            </span>
          </Toolbar>

          <nav className="w-full flex-1 flex items-center justify-between bg-palette-background-default py-2 px-4 space-x-4">
            <span className="relative w-full h-8 leading-none bg-palette-background-paper apperance-none">
              <input
                className={clsx([
                  'absolute inset-0 w-full h-full px-10 bg-transparent rounded border border-palette-action-selected',
                  'focus:outline-none focus:border-primary-300 focus:ring-2 focus:ring-primary-200',
                ])}
                value={searchQuery ?? ''}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <span className="absolute top-2 left-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                <IconSearch />
              </span>

              {searchQuery.length > 0 &&
                <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchQuery('');
                    }}
                  >
                    <IconClose />
                  </IconButton>
                </span>
              }
            </span>

            <span>
              <Tooltip title="Sadece seçili seçenekleri göster">
                <Checkbox
                  color="primary"
                  checked={showSelectedOptions}
                  onChange={(e, checked) => setShowSelectedOptions(checked)}
                  disableRipple
                  style={{
                    padding: 0,
                  }}
                ></Checkbox>
              </Tooltip>
            </span>
          </nav>

          <span className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', { 'hidden': !SHOW_LINEAR_PROGRESS })}>
            <LinearProgress style={{ height: 2 }} />
          </span>

          {/* <pre className="m-0 p-0">selectedOptionIds {JSON.stringify(selectedOptionIds, null, 2)}</pre> */}
        </AppBar>

        <main className="relative flex flex-grow flex-col overflow-hidden" ref={mainContainerRef}>

          <div className="h-full flex-grow">
            {LOADING_MESSAGE && (
              <LoadingMessage text={LOADING_MESSAGE} />
            )}

            {ERROR_MESSAGE && (
              <ErrorMessage text={ERROR_MESSAGE} />
            )}

            {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_OPTIONS.length === 0) && (
              <ErrorMessage text="Seçenek bulunamadı" />
            )}

            {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_OPTIONS.length > 0) && (
              <InfiniteLoader
                minimumBatchSize={100}
                threshold={50}
                isRowLoaded={({ index }) => !!FILTERED_OPTIONS[index]}
                loadMoreRows={({ startIndex, stopIndex }) => {
                  if (PAGES_COUNT > PAGE && !moreOptionsLoading && !showSelectedOptions) {
                    handleGetMoreOptions(PAGE + 1);
                  }
                }}
                rowCount={optionsCount > 0 ? optionsCount : 1000}
              >
                {({ onRowsRendered, registerChild }) => (
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        width={width}
                        height={height}
                        rowHeight={ROW_HEIGHT + 1}
                        rowCount={FILTERED_OPTIONS.length}
                        estimatedRowSize={PAGES_COUNT > 0 ? PAGES_COUNT * (ROW_HEIGHT + 1) : undefined}
                        rowRenderer={rowRenderer}

                        onRowsRendered={onRowsRendered}
                        ref={registerChild}
                        onScroll={() => setSelectedItem(null)}
                      />
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            )}
          </div>
          {selectedItem && !staticpage &&(
            <RightMenu
              url={newpath}
              itemId={selectedItem?.id}
              ustId={ustId}
              position={selectedPosition}
            ></RightMenu>
          )}

          <div className="flex items-center justify-between border-t border-palette-action-selected p-2">
            <span></span>
            <span>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disableRipple
                onClick={onSubmit}
              >FİLTRELE</Button>
            </span>
          </div>

        </main>
      </Paper>

      {/* <div className="fixed right-0 bottom-0 p-2">
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">options {JSON.stringify(options.length)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">optionsLoading {JSON.stringify(optionsLoading)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">optionsLoadingErrorMessage {JSON.stringify(optionsLoadingErrorMessage)}</pre>

        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">optionsCount {JSON.stringify(optionsCount)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">optionsCountLoading {JSON.stringify(optionsCountLoading)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">optionsCountLoadingErrorMessage {JSON.stringify(optionsCountLoadingErrorMessage)}</pre>

        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">moreOptionsLoading {JSON.stringify(moreOptionsLoading)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">moreOptionsLoadingErrorMessage {JSON.stringify(moreOptionsLoadingErrorMessage)}</pre>

        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">searchQuery {JSON.stringify(searchQuery)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">searchOptions {JSON.stringify(searchOptions.length)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">searchOptionsLoading {JSON.stringify(searchOptionsLoading)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">searchOptionsLoadingErrorMessage {JSON.stringify(searchOptionsLoadingErrorMessage)}</pre>

        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">showSelectedOptions {JSON.stringify(showSelectedOptions)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">selectedOptions {JSON.stringify(selectedOptions.length)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">selectedOptionsLoading {JSON.stringify(selectedOptionsLoading)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">selectedOptionsLoadingErrorMessage {JSON.stringify(selectedOptionsLoadingErrorMessage)}</pre>

        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">FILTERED_OPTIONS {JSON.stringify(FILTERED_OPTIONS.length)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">SHOW_LINEAR_PROGRESS {JSON.stringify(SHOW_LINEAR_PROGRESS)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">LOADING_MESSAGE {JSON.stringify(LOADING_MESSAGE)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">ERROR_MESSAGE {JSON.stringify(ERROR_MESSAGE)}</pre>

        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">PAGE {JSON.stringify(PAGE)}</pre>
        <pre className="text-sm bg-palette-background-paper border border-palette-action-selected m-0 p-1">PAGES_COUNT {JSON.stringify(PAGES_COUNT)}</pre>
      </div> */}
    </>
  );
};

export default MultipleFilterSelector;
