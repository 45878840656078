import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  assign as _assign,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList } from '../../base/InputErrors';

import TabGenel from './BankaHesapDetailTab_Genel';

const PATH = 'bankahesap';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  tanim: '',
  aktif: true,
  siralama: '0',
  para_birim: '-1',
  banka_hesap: {
    banka_adi: '',
    hesap_adi: '',
    sube: '',
    iban: '',
    sube_kodu: '',
    hesap_no: '',
  }
};

const BankaHesapDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [hataGenel, setHataGenel] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [paraBirim, setParaBirimMini] = useState([]);
  const [paraKoduList, setParaKoduList] = useState({});

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    return isEqualGenel;
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    setLoading(true);
    setTabs(TABS_DEFAULT);
    if (!IS_ADD) {
      const debounce = setTimeout(() => {
        django(`${PATH}/${ID}`).then(({ data }) => {
          const form = {
            tanim: data.tanim,
            aktif: data.aktif,
            siralama: String(data.siralama),
            para_birim: String(data.para_birim.id),
            banka_hesap: {
              banka_adi: data.banka_hesap.banka_adi,
              hesap_adi: data.banka_hesap.hesap_adi,
              sube: data.banka_hesap.sube,
              iban: data.banka_hesap.iban,
              sube_kodu: data.banka_hesap.sube_kodu,
              hesap_no: data.banka_hesap.hesap_no,
            }
          };
          setGenelFormDefault(form);
          setGenelForm(form);
          setHataGenel(null);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataGenel(true);
            }
          }
        }).finally(() => {
          setLoading(false);
          setGenelError(null);
          setAdd(false);
        });
      }, 300);
      return () => {
        clearTimeout(debounce);
        setLoading(false);
      };
    } else {
      setGenelForm(FORM_DEFAULT);
      setGenelFormDefault(FORM_DEFAULT);
      setGenelError(null);
      setHataGenel(null);
      setAdd(true);
      setLoading(false);
    }

  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    django('parabirim/mini_list?aktif=true').then(({ data }) => {
      let pkDict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      pkDict = Object.assign(pkDict, { [-1]: 'Seçilmedi' })
      setParaKoduList(pkDict);
      setParaBirimMini(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, property2, value) => {
    if (property === 'banka_hesap') {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next.banka_hesap[property2] = value;
        });
      });
    } else {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next[property] = value;
        });
      });
    }
  };

  const handleAdd = () => {
    setAdd(true);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Banka Hesap Tanım', pagename: 'banka hesap', prop2: '' },
      { prop: 'banka_hesap', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'banka_adi' },
      { prop: 'banka_hesap', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'hesap_adi' },
      { prop: 'banka_hesap', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'sube' },
      { prop: 'banka_hesap', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'sube_kodu' },
      { prop: 'banka_hesap', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'iban' },
      { prop: 'banka_hesap', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'hesap_no' },
      { prop: 'para_birim', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' }
    ]
    let errors = [];
    await checkAllList('bankahesap', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {
    checkAll().then((res) => {
      if (_isEmpty(res)) {
        let requestoptions = {};
        const data = { ...genelForm, siralama: genelForm.siralama ? Number(genelForm.siralama) : 0, para_birim: Number(genelForm.para_birim) };
        if (IS_EQUAL || genelError) {
          requestoptions = null;
        } else {
          requestoptions = {
            config: {
              method: add ? 'POST' : 'PUT',
              url: add ? 'bankahesap' : `bankahesap/${ID}`,
              data: data
            },
            successMessage: `Banka hesap ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
            errorMessageUnexpected: `Banka hesap ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
          }
        }
        const start = async () => {
          let errorCounts = 0;
          if (requestoptions !== null) {
            setGenelError(null);
            await django(requestoptions.config).then(({ data }) => {
              enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
              if (!add) {
                props.onUpdated();
              }
              if (props.single && add) {
                history.push(URLS.tanimlar.bankahesap.detail(data.id, "detay"));
              }
              if (!props.single && add) {
                props.onAdded(data);
              }
            }).catch((error) => {
              if (error.response) {
                if (error.response.status === 500) {
                  enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
                } else {
                  setGenelError(error.response.data);
                }
              } else {
                enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
              }
              ++errorCounts;
            });
            if (errorCounts === 0) {
              setLastUpdateTime(Date.now());
            } else {
              setTabs((prev) => {
                return immer(prev, (next) => {
                  next[0].hasError = true;
                });
              });
            }
          }
        }
        start();
      } else {
        setTabs((prev) => {
          return immer(prev, (next) => {
            next[0].hasError = true;
          });
        });
      }
    })
  }

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.bankahesap.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      let obj = {}
      let next = {};
      if (Object.keys(errors)[0] === 'banka_hesap') {
        if (_has({ ...prev }, Object.keys(errors)[0])) {
          _assign(prev[Object.keys(errors)[0]], errors[Object.keys(errors)[0]])
          obj = { ...prev }
        } else {
          obj = { ...prev, ...errors }
        }
      } else {
        obj = { ...prev, ...errors }
      }

      if (_has(obj, "banka_hesap")) {
        const bankahesap = _pickBy(obj["banka_hesap"], _identity);
        if (_isEmpty(bankahesap)) {
          next = _pickBy({ ...obj, 'banka_hesap': null }, _identity);
        } else {
          next = _pickBy({ ...obj, 'banka_hesap': bankahesap }, _identity);
        }
      } else {
        next = _pickBy(obj, _identity);
      }
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            paraBirim={paraBirim}
            paraKoduList={paraKoduList}
            onChangeForm={handleGenelChangeForm}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Banka Hesabı siliniyor lütfen bekleyin'
                : 'Bu banka hesabını gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
    </>
  );
}

export default BankaHesapDetail;