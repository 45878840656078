import React, { useCallback, useMemo,useState } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import {
  Visibility as IconVisibility,
  VisibilityOff as IconVisibilityOff
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
  };
};

const YoneticiDetailTab_Genel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading])
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const controlPassword = () => {
    let errors = {};
    if (props.form.password !== props.form.password2) {
      errors['password2'] = ['Şifreler uyuşmuyor'];
    } else {
      errors['password2'] = null;
    }
    props.onCheckErrors(errors);
  }


  return (
    <>
      {!LOADING &&
        <>
          {HATA &&
            <Hata />
          }
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="kodu">
                    <label className={classes.layoutFormItemLabel} htmlFor="kodu">Yönetici Kodu *</label>
                    <Tooltip title={props.error?.kodu ? props.error.kodu[0] : _isNil(props.form?.kodu) ? '' : props.form.kodu}>
                      <TextField
                        {...createTextFieldProps('kodu')}
                        onChange={(e) => props.onChangeForm('kodu', e.target.value)}
                        onBlur={() => handleInputErrors('kullanici', props.form, props.formdefault, props.onCheckErrors, add, 'kodu', true, true, false, '', 'Yönetici Kodu', 'kullanıcı')}
                        inputProps={{
                          maxLength: 30,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="first_name">
                    <label className={classes.layoutFormItemLabel} htmlFor="first_name">Adı</label>
                    <Tooltip title={props.error?.first_name ? props.error.first_name[0] : _isNil(props.form?.first_name) ? '' : props.form.first_name}>
                      <TextField
                        {...createTextFieldProps('first_name')}
                        onChange={(e) => props.onChangeForm('first_name', e.target.value)}
                        inputProps={{
                          maxLength: 150,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="last_name">
                    <label className={classes.layoutFormItemLabel} htmlFor="last_name">Soyadı</label>
                    <Tooltip title={props.error?.last_name ? props.error.last_name[0] : _isNil(props.form?.last_name) ? '' : props.form.last_name}>
                      <TextField
                        {...createTextFieldProps('last_name')}
                        onChange={(e) => props.onChangeForm('last_name', e.target.value)}
                        inputProps={{
                          maxLength: 150,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="email">
                    <label className={classes.layoutFormItemLabel} htmlFor="email">E-Posta</label>
                    <Tooltip title={props.error?.email ? props.error.email[0] : _isNil(props.form?.email) ? '' : props.form.email}>
                      <TextField
                        {...createTextFieldProps('email')}
                        onChange={(e) => props.onChangeForm('email', e.target.value)}
                        inputProps={{
                          maxLength: 254,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="username">
                    <label className={classes.layoutFormItemLabel} htmlFor="username">Kullanıcı Adı *</label>
                    <Tooltip title={props.error?.username ? props.error.username[0] : _isNil(props.form?.username) ? '' : props.form.username}>
                      <TextField
                        {...createTextFieldProps('username')}
                        onChange={(e) => props.onChangeForm('username', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'username', true, false, false, '', '', '')}
                        inputProps={{
                          maxLength: 150,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="password">
                    <label className={classes.layoutFormItemLabel} htmlFor="password">Parola</label>
                    <Tooltip title={props.error?.password ? props.error.password[0] : _isNil(props.form?.password) ? '' : 'parola'}>
                      <TextField
                        {...createTextFieldProps('password')}
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => props.onChangeForm('password', e.target.value)}
                        autoComplete="new-password"
                        inputProps={{
                          maxLength: 128,
                        }}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button}
                              disableRipple
                              onClick={() => setShowPassword(!showPassword)}
                            >{showPassword ? <IconVisibility /> : <IconVisibilityOff />}</IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="password2">
                    <label className={classes.layoutFormItemLabel} htmlFor="password2">Parola Tekrar</label>
                    <Tooltip title={props.error?.password2 ? props.error.password2[0] : _isNil(props.form?.password2) ? '' : 'parola'}>
                      <TextField
                        {...createTextFieldProps('password2')}
                        type={showPassword2 ? "text" : "password"}
                        onChange={(e) => props.onChangeForm('password2', e.target.value)}
                        autoComplete="new-password"
                        inputProps={{
                          maxLength: 128,
                        }}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button}
                              disableRipple
                              onClick={() => setShowPassword2(!showPassword2)}
                            >{showPassword2 ? <IconVisibility /> : <IconVisibilityOff />}</IconButton>
                        }}
                        onBlur={() => controlPassword()}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="entegrasyon">
                    <label className={classes.layoutFormItemLabel} htmlFor="entegrasyon">Entegrasyon Kullanıcısı</label>
                    <Checkbox
                      name="entegrasyon"
                      color="primary"
                      checked={_result(props.form, 'entegrasyon', false)}
                      onChange={(e, checked) => props.onChangeForm('entegrasyon', checked)}
                    ></Checkbox>
                  </li>
                  <li className={classes.layoutFormItem} id="is_active">
                    <label className={classes.layoutFormItemLabel} htmlFor="is_active">Aktif</label>
                    <Checkbox
                      name="is_active"
                      color="primary"
                      checked={_result(props.form, 'is_active', false)}
                      onChange={(e, checked) => props.onChangeForm('is_active', checked)}
                    ></Checkbox>
                  </li>
                </ul>
              </div>
            </>
          )}
        </>
      }
    </>
  );
});

export default YoneticiDetailTab_Genel;
