import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';
import moment from 'moment';
import {
  has as _has,
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from 'helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './CariDetailTab_Genel';
import TabAdresler from './CariDetailTab_Adresler';
import TabBankaHesaplari from './CariDetailTab_BankaHesaplari';
import TabYetkililer from './CariDetailTab_Yetkililer';
import TabSepet from './CariDetailTab_Sepet';
import TabOdemeLog from './CariDetailTab_OdemeLog';


const PATH = 'kullanici';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'ADRESLER', disabled: false, hasError: false },
  { label: 'BANKA HESAPLARI', disabled: false, hasError: false },
  { label: 'YETKİLİLER', disabled: false, hasError: false },
  { label: 'SEPET', disabled: false, hasError: false },
  { label: 'ÖDEME LOGLARI', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  kodu: '',
  cari_tip: '0',
  first_name: '',
  last_name: '',
  email: '',
  username: '',
  password: '',
  password2: '',
  is_active: true,
  cari: true,
  groups: [],
  kaynak: 1
};

const FORM_ADRES_DEFAULT = {
  form: {
    tanim: 'Tanımsız',
    adres_tip: '0',
    adres: '',
    posta_kodu: '',
    adres_kodu: '',
    ulke: '-1',
    sehir: '-1',
    ilce: '-1',
    mahalle: '',
    tarifi: '',
    vergi_dairesi: '',
    vergi_no: '',
    ad_soyad: '',
    firma_adi: '',
    email: '',
    web_sitesi: '',
    tel_no: '',
    gsm_no: '',
    siralama: '0',
    aktif: true,
  },
  errors: null
};

const FORM_BANKA_HESAP_DEFAULT = {
  form: {
    banka_hesap_bilgi: {
      banka_adi: '',
      hesap_adi: 'Tanımsız',
      sube: '',
      iban: '',
      sube_kodu: '',
      hesap_no: '',
    },
    para_birim: '-1'
  },
  errors: null
};

const FORM_YETKILILER_DEFAULT = {
  form: {
    adi: 'Tanımsız',
    tel: '',
    unvan: '',
    eposta: ''
  },
  errors: null
};

const createSepet = (list) => {
  let liste = [];
  for (let item of list) {
    const index = liste.findIndex(x => x.magaza === item.magaza)
    if (index > -1) {
      liste[index] = { ...liste[index], sepetdetaylar: [...item.sepetdetaylar] }
    } else {
      liste.push({ magaza: item.magaza, sepetdetaylar: item.sepetdetaylar })
    }
  }
  return liste;
}

const TableDetailExampleDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingAdres, setLoadingAdres] = useState(true);
  const [loadingBankaHesap, setLoadingBankaHesap] = useState(true);
  const [loadingYetkili, setLoadingYetkili] = useState(true);
  const [loadingSepet, setLoadingSepet] = useState(true);
  const [loadingOdemeLog, setLoadingOdemeLog] = useState(true);
  const [loadingOdemeLogMore, setLoadingOdemeLogMore] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataAdres, setHataAdres] = useState(null);
  const [hataBankaHesap, setHataBankaHesap] = useState(null);
  const [hataYetkili, setHataYetkili] = useState(null);
  const [hataSepet, setHataSepet] = useState(null);
  const [hataOdemeLog, setHataOdemeLog] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [tabs, setTabs] = useState(TABS_DEFAULT);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [genelFormDefault, setGenelFormDefault] = useState(FORM_DEFAULT);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [adreslerSelectedTabIndex, setAdreslerSelectedTabIndex] = useState(0);
  const [adreslerFormsDefault, setAdreslerFormsDefault] = useState([]);
  const [adreslerForms, setAdreslerForms] = useState([]);
  const [adreslerAdd, setAdreslerAdd] = useState(true);
  const [ulkeler, setUlkeMiniList] = useState([]);
  const [ulkelerDic, setUlkeMiniListDic] = useState([]);

  const [bankaHesaplariSelectedTabIndex, setBankaHesaplariSelectedTabIndex] = useState(0);
  const [bankaHesaplariFormsDefault, setBankaHesaplariFormsDefault] = useState([]);
  const [bankaHesaplariForms, setBankaHesaplariForms] = useState([]);
  const [hesaplarAdd, setHesaplarAdd] = useState(true);
  const [paraBirimleri, setParaBirimleri] = useState([]);
  const [paraBirimleriDic, setParaBirimleriDic] = useState([]);

  const [yetkililerSelectedTabIndex, setYetkililerSelectedTabIndex] = useState(0);
  const [yetkililerFormsDefault, setYetkililerFormsDefault] = useState([]);
  const [yetkililerForms, setYetkililerForms] = useState([]);
  const [yetkiliAdd, setYetkiliAdd] = useState([]);
  const [yetkiliId, setYetkiliId] = useState(null);

  const [sepet, setSepet] = useState([]);
  const [sepetDefault, setSepetDefault] = useState([]);

  const [odemeLog, setOdemeLog] = useState([]);
  const [odemeLogDefault, setOdemeLogDefault] = useState([]);
  const [odemeLogCount, setOdemeLogCount] = useState(0);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [adresDeleteId, setAdresDeleteId] = useState(null);
  const [showAdresDeleteDialogDetail, setShowAdresDeleteDialogDetail] = useState(false);
  const [isDeletingAdres, setIsDeletingAdres] = useState(false);
  const [isDeletingAdresErrorMessageDetail, setIsDeletingAdresErrorMessageDetail] = useState(null);

  const [bankaDeleteId, setBankaDeleteId] = useState(null);
  const [showBankaDeleteDialogDetail, setShowBankaDeleteDialogDetail] = useState(false);
  const [isDeletingBanka, setIsDeletingBanka] = useState(false);
  const [isDeletingBankaErrorMessageDetail, setIsDeletingBankaErrorMessageDetail] = useState(null);

  const [type, setType] = useState(props.eventType ?? 0);
  const [loadDone, setLoadDone] = useState(false);
  const [loadTab1, setLoadTab1] = useState(false);
  const [loadTab2, setLoadTab2] = useState(false);
  const [loadTab3, setLoadTab3] = useState(false);
  const [loadTab4, setLoadTab4] = useState(false);
  const [loadTab5, setLoadTab5] = useState(false);
  const [loadTab6, setLoadTab6] = useState(false);
  const [equal1, setEqual1] = useState(true);
  const [equal2, setEqual2] = useState(true);
  const [equal3, setEqual3] = useState(true);
  const [equal4, setEqual4] = useState(true);
  const [saveErrors, setSaveErrors] = useState([[], [], [], []]);


  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const PAGE_ODEME_LOG = useMemo(() => odemeLog.length > 0 ? Math.ceil(odemeLog.length / 100) : 1, [odemeLog.length]);
  const PAGES_COUNT_ODEME_LOG = useMemo(() => Math.ceil(odemeLogCount / 100), [odemeLogCount]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.kodu;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(adreslerForms, []) && _isEqual(bankaHesaplariForms, []) && _isEqual(yetkililerForms, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualAdresler = _isEqual(adreslerForms, adreslerFormsDefault);
    const isEqualBankaHesaplari = _isEqual(bankaHesaplariForms, bankaHesaplariFormsDefault);
    const isEqualYetkililer = _isEqual(yetkililerForms, yetkililerFormsDefault);
    return isEqualGenel && isEqualAdresler && isEqualBankaHesaplari && isEqualYetkililer;
  }, [add, genelForm, genelFormDefault, adreslerForms, adreslerFormsDefault, bankaHesaplariForms, bankaHesaplariFormsDefault, yetkililerForms, yetkililerFormsDefault]);

  useLayoutEffect(() => {
    setAdd(false);
    setLoadDone(false);
  }, [ID]);

  useLayoutEffect(() => {
    if (loadDone) {
      setType(2);
    } else {
      setType(props.eventType ?? 0);
    }
  }, [props.eventType, loadDone]);

  useLayoutEffect(() => {
    if (type === 0) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setAdreslerForms([]);
      setAdreslerFormsDefault([]);
      setBankaHesaplariForms([]);
      setBankaHesaplariFormsDefault([]);
      setYetkililerForms([]);
      setYetkililerFormsDefault([]);
      setTabs(TABS_DEFAULT);
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          setLoadTab2(true);
          break;
        case 2:
          setLoadTab1(true);
          setLoadTab3(true);
          break;
        case 3:
          setLoadTab1(true);
          setLoadTab4(true);
          break;
        case 4:
          setLoadTab1(true);
          setLoadTab5(true);
          break;
        case 5:
          setLoadTab1(true);
          setLoadTab6(true);
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else if (type === 1) {
      setGenelForm(null);
      setGenelFormDefault(null);
      if (!saveErrors[1].includes("err")) {
        setAdreslerForms([]);
        setAdreslerFormsDefault([]);
      }
      if (!saveErrors[2].includes("err")) {
        setBankaHesaplariForms([]);
        setBankaHesaplariFormsDefault([]);
      }
      if (!saveErrors[3].includes("err")) {
        setYetkililerForms([]);
        setYetkililerFormsDefault([]);
      }
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          if (!saveErrors[1].includes("err")) {
            setLoadTab2(true);
          }
          break;
        case 2:
          setLoadTab1(true);
          if (!saveErrors[2].includes("err")) {
            setLoadTab3(true);
          }
          break;
        case 3:
          setLoadTab1(true);
          if (!saveErrors[3].includes("err")) {
            setLoadTab4(true);
          }
          break;
        case 4:
          setLoadTab1(true);
          setLoadTab5(true);
          break;
        case 5:
          setLoadTab1(true);
          setLoadTab6(true);
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else {
      if (!add) {
        switch (selectedTabIndex) {
          case 0:
            if (equal1) {
              setLoadTab1(true);
            }
            break;
          case 1:
            if (equal2) {
              setLoadTab2(true);
            }
            break;
          case 2:
            if (equal3) {
              setLoadTab3(true);
            }
            break;
          case 3:
            if (equal4) {
              setLoadTab4(true);
            }
            break;
          case 4:
            setLoadTab5(true);
            break;
          case 5:
            setLoadTab6(true);
            break;
          default:
            break;
        }
      } else {
        setLoading(false);
        setLoadingAdres(false);
        setLoadingBankaHesap(false);
        setLoadingYetkili(false);
        setLoadingSepet(false);
        setLoadingOdemeLog(false);
        setLoadTab1(false);
        setLoadTab2(false);
        setLoadTab3(false);
        setLoadTab4(false);
        setLoadTab5(false);
        setLoadTab6(false);
      }
    }
  }, [type, selectedTabIndex, saveErrors, equal1, equal2, equal3, equal4, add]);

  useLayoutEffect(() => {
    if (add) {
      setEqual1(_isEqual(genelForm, FORM_DEFAULT));
    } else {
      setEqual1(_isEqual(genelForm, genelFormDefault));
    }
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual2(_isEqual(adreslerForms, []));
    } else {
      setEqual2(_isEqual(adreslerForms, adreslerFormsDefault));
    }
  }, [add, adreslerForms, adreslerFormsDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual3(_isEqual(bankaHesaplariForms, []));
    } else {
      setEqual3(_isEqual(bankaHesaplariForms, bankaHesaplariFormsDefault));
    }
  }, [add, bankaHesaplariForms, bankaHesaplariFormsDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual4(_isEqual(yetkililerForms, []));
    } else {
      setEqual4(_isEqual(yetkililerForms, yetkililerFormsDefault));
    }
  }, [add, yetkililerForms, yetkililerFormsDefault]);

  useLayoutEffect(() => {
    if (loadTab1) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              kodu: data.kodu,
              cari_tip: String(data.cari_tip),
              first_name: data.first_name,
              last_name: data.last_name,
              email: data.email,
              username: data.username,
              password: data.password,
              password2: data.password,
              is_active: data.is_active,
              cari: data.cari,
              groups: data.groups,
              kaynak: data.kaynak.key.key
            };
            setGenelFormDefault(form);
            setGenelForm(form);
            setHataGenel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setLoadTab1(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DEFAULT);
        setGenelFormDefault(FORM_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
        setLoadTab1(false);
      }
    }
  }, [ID, IS_ADD, loadTab1]);

  useLayoutEffect(() => {
    if (loadTab2) {
      setLoadingAdres(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/adresler`).then(({ data }) => {
            const forms = data.map((adres) => (
              {
                form: {
                  id: adres.id,
                  tanim: adres.tanim,
                  adres_tip: String(adres.adres_tip),
                  adres: adres.adres,
                  posta_kodu: adres.posta_kodu,
                  adres_kodu: adres.adres_kodu,
                  ulke: String(adres.ulke.id),
                  sehir: String(adres.sehir.id),
                  ilce: String(adres.ilce.id),
                  mahalle: adres.mahalle,
                  tarifi: adres.tarifi,
                  vergi_dairesi: adres.vergi_dairesi,
                  vergi_no: adres.vergi_no,
                  ad_soyad: adres.ad_soyad,
                  email: adres.email,
                  web_sitesi: adres.web_sitesi,
                  tel_no: adres.tel_no,
                  gsm_no: adres.gsm_no,
                  siralama: String(adres.siralama),
                  aktif: adres.aktif,
                },
                errors: null
              }
            ));
            setAdreslerFormsDefault(forms);
            setAdreslerForms(forms);
            setHataAdres(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataAdres(true);
              }
            }
          }).finally(() => {
            setLoadingAdres(false);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setAdreslerFormsDefault([]);
        setAdreslerForms([]);
        setHataAdres(null);
        setLoadingAdres(false);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, loadTab2]);

  useLayoutEffect(() => {
    django("ulke/mini_list").then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      setUlkeMiniListDic(dict);
      setUlkeMiniList(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (loadTab3) {
      if (!IS_ADD) {
        setLoadingBankaHesap(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/banka-hesaplari`).then(({ data }) => {
            const forms = data.map((banka) => (
              {
                form: {
                  id: banka.id ?? null,
                  banka_hesap_bilgi: {
                    banka_adi: banka.banka_hesap_bilgi.banka_adi,
                    hesap_adi: banka.banka_hesap_bilgi.hesap_adi,
                    sube: banka.banka_hesap_bilgi.sube,
                    iban: banka.banka_hesap_bilgi.iban,
                    sube_kodu: banka.banka_hesap_bilgi.sube_kodu,
                    hesap_no: banka.banka_hesap_bilgi.hesap_no,
                  },
                  para_birim: String(banka.para_birim),
                },
                errors: null
              }
            ));
            setBankaHesaplariFormsDefault(forms);
            setBankaHesaplariForms(forms);
            setHataBankaHesap(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataBankaHesap(true);
              }
            }
          }).finally(() => {
            setLoadingBankaHesap(false);
            setLoadTab3(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setBankaHesaplariFormsDefault([]);
        setBankaHesaplariForms([]);
        setHataBankaHesap(null);
        setLoadingBankaHesap(false);
        setLoadTab3(false);
      }
    }
  }, [ID, IS_ADD, loadTab3]);

  useLayoutEffect(() => {
    if (loadTab4) {
      if (!IS_ADD) {
        setLoadingYetkili(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/yetkililer`).then(({ data }) => {
            if (data.length > 0) {
              const id = data?.[0].kullanici;
              const forms = data?.[0].yetkililer.map((y) => (
                {
                  form: {
                    adi: y.adi,
                    tel: y.tel,
                    unvan: y.unvan,
                    eposta: y.eposta
                  },
                  errors: null
                }
              ));
              setYetkililerFormsDefault(forms);
              setYetkililerForms(forms);
              setYetkiliId(id);
              setHataYetkili(null);
            } else {
              setYetkililerFormsDefault([]);
              setYetkililerForms([]);
              setYetkiliId(null);
              setHataYetkili(null);
            }
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataYetkili(true);
              }
            }
          }).finally(() => {
            setLoadingYetkili(false);
            setLoadTab4(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setYetkililerFormsDefault([]);
        setYetkililerForms([]);
        setYetkiliId(null);
        setHataYetkili(null);
        setLoadingYetkili(false);
        setLoadTab4(false);
      }
    }

  }, [ID, IS_ADD, loadTab4]);

  useLayoutEffect(() => {
    if (loadTab5) {
      if (!IS_ADD) {
        setLoadingSepet(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/sepet`).then(({ data }) => {
            if (data.length > 0) {
              const forms = data.map((y) => ({ sepetdetaylar: y.sepetdetaylar, magaza: y.magaza }));
              setSepet(createSepet(forms));
              setSepetDefault(createSepet(forms));
              setHataSepet(null);
            } else {
              setSepet([]);
              setSepetDefault([]);
              setHataSepet(null);
            }
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataSepet(true);
              }
            }
          }).finally(() => {
            setLoadingSepet(false);
            setLoadTab5(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setSepet([]);
        setSepetDefault([]);
        setHataSepet(null);
        setLoadingSepet(false);
        setLoadTab5(false);
      }
    }
  }, [ID, IS_ADD, loadTab5]);

  useLayoutEffect(() => {
    if (loadTab6) {
      if (!IS_ADD) {
        django(`kullaniciodemelog/count/?olusturan_kullanici.id=${ID}`).then(({ data }) => {
          setOdemeLogCount(data);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataOdemeLog(true);
            }
          }
        })
      } else {
        setOdemeLogCount(0);
        setHataOdemeLog(null);
      }
    }
  }, [ID, IS_ADD, loadTab6]);

  useLayoutEffect(() => {
    if (loadTab6) {
      if (!IS_ADD) {
        setLoadingOdemeLog(true);
        const debounce = setTimeout(() => {
          django(`kullaniciodemelog/?olusturan_kullanici.id=${ID}`).then(({ data }) => {
            if (data.length > 0) {
              const forms = data.map((y) => ({
                id: y.id,
                olusturan_kullanici: y.olusturan_kullanici?.username ?? '',
                olusturma_zamani: moment(y.olusturma_zamani).format('DD.MM.YYYY, HH.mm'),
                tutar: String(y.tutar),
                odeme_son_durum: y.odeme_son_durum,
                sepet: y.sepet,
                evrak: y.evrak?.kodu ?? '',
                odeme_tanim: y.odeme_tanim?.tanim ?? '',
                result: y.result,
              }));
              setOdemeLog(forms);
              setOdemeLogDefault(forms);
              setHataOdemeLog(null);
            } else {
              setOdemeLog([]);
              setOdemeLogDefault([]);
              setHataOdemeLog(null);
            }
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataOdemeLog(true);
              }
            }
          }).finally(() => {
            setLoadingOdemeLog(false);
            setLoadTab6(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setOdemeLog([]);
        setOdemeLogDefault([]);
        setHataOdemeLog(null);
        setLoadingOdemeLog(false);
        setLoadTab6(false);
      }
    }
  }, [ID, IS_ADD, loadTab6]);

  const handleGetMoreList = (page, mainContainerRef) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setLoadingOdemeLogMore(true);
    const url = `kullaniciodemelog/?olusturan_kullanici.id=${ID}`
    const config = {
      params: {
        size: 100,
        page
      },
    };
    django(url, config).then(({ data }) => {
      const forms = data.map((y) => ({
        id: y.id,
        olusturan_kullanici: y.olusturan_kullanici?.username ?? '',
        olusturma_zamani: moment(y.olusturma_zamani).format('DD.MM.YYYY, HH.mm'),
        tutar: String(y.tutar),
        odeme_son_durum: y.odeme_son_durum,
        sepet: y.sepet,
        evrak: y.evrak?.kodu ?? '',
        odeme_tanim: y.odeme_tanim?.tanim ?? '',
        result: y.result,
      }));
      setOdemeLog((prev) => [...prev, ...forms]);
      setOdemeLogDefault((prev) => [...prev, ...forms]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setLoadingOdemeLogMore(false);
    });
  };

  useLayoutEffect(() => {
    django('parabirim/mini_list').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      setParaBirimleriDic(dict);
      setParaBirimleri(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleAdreslerChangeForm = (index, property, value) => {
    setAdreslerForms((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      });
    });
  };

  const handleAdreslerAddNewTab = () => {
    setAdreslerAdd(false);
    setAdreslerForms((prev) => immer(prev, (next) => {
      next.push({ ...FORM_ADRES_DEFAULT });
    }));
  };

  const handleBankaBilgileriChangeForm = (index, property, property2, value) => {
    if (property2 !== '') {
      setBankaHesaplariForms((prev) => {
        return immer(prev, (next) => {
          next[index].form[property][property2] = value;
        });
      });
    } else {
      setBankaHesaplariForms((prev) => {
        return immer(prev, (next) => {
          next[index].form[property] = value;
        });
      });
    }
  };

  const handleHesaplarAddNewTab = () => {
    setHesaplarAdd(false);
    setBankaHesaplariForms((prev) => immer(prev, (next) => {
      next.push({ ...FORM_BANKA_HESAP_DEFAULT });
    }));
  };

  const handleYetkililerChangeForm = (index, property, value) => {
    setYetkililerForms((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      });
    });
  };

  const handleYetkililerAddNewTab = () => {
    setYetkiliAdd(false);
    setYetkililerForms((prev) => immer(prev, (next) => {
      next.push({ ...FORM_YETKILILER_DEFAULT });
    }));
  };

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setAdreslerForms([]);
    setBankaHesaplariForms([]);
    setYetkililerForms([]);
    setSepet([]);
    setOdemeLog([]);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
        next[3].hasError = false;
        next[4].hasError = false;
        next[5].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
    setLoadTab1(true);
    setLoadTab2(true);
    setLoadTab3(true);
    setLoadTab4(true);
    setLoadTab5(true);
    setLoadTab6(true);
    setAdreslerAdd(true);
    setHesaplarAdd(true);
    setYetkiliAdd(true);
    setTabs(TABS_DEFAULT);
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    setAdreslerForms(adreslerFormsDefault);
    setBankaHesaplariForms(bankaHesaplariFormsDefault);
    setYetkililerForms(yetkililerFormsDefault);
    setSepet(sepetDefault);
    setOdemeLog(odemeLogDefault);
    setAdreslerAdd(true);
    setHesaplarAdd(true);
    setYetkiliAdd(true);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
        next[3].hasError = false;
        next[4].hasError = false;
        next[5].hasError = false;
      });
    });
  };

  const checkAll = async () => {
    const list = [
      { prop: 'kodu', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Cari Kodu', pagename: 'kullanıcı', prop2: '' },
    ]
    let errors = [];
    await checkAllList('kullanici', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    if (genelForm.password !== genelForm.password2) {
      handleErrors({ password2: ['Şifreler uyuşmuyor'] })
      errors = [...errors, { password2: ['Şifreler uyuşmuyor'] }]
    }
    return errors;
  };

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {

    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (add ? _isEqual(genelForm, FORM_DEFAULT) : _isEqual(genelForm, genelFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            const data = { ...genelForm, groups: genelForm.groups?.map((m) => m.id), cari_tip: Number(genelForm.cari_tip) }
            delete data.password2;
            reqopt = {
              data: data,
              method: add ? 'POST' : 'PUT',
              id: null,
              successMessage: `Cari ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Cari ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabAdres = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = adreslerFormsDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'tanim', prop2: '' },
          { prop: 'adres', prop2: '' },
          { prop: 'ulke', prop2: '' },
          { prop: 'sehir', prop2: '' },
          { prop: 'ilce', prop2: '' },
          { prop: 'ad_soyad', prop2: '' },
        ]
        if (next.form.adres_tip === "1") {
          list.push({ prop: 'firma_adi', prop2: '' })
        }
        await checkAllTabs(next.form, (err) => handleErrorsAdres(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            if (isHas) {
              reqopt = {
                data: { ...next.form, adres_tip: Number(next.form.adres_tip), ulke: Number(next.form.ulke), sehir: Number(next.form.sehir), ilce: Number(next.form.ilce), siralama: next.form.siralama ? Number(next.form.siralama) : 0 },
                method: 'PUT',
                id: next.form.id,
                successMessage: `${next.form.tanim} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.tanim} güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: { ...next.form, adres_tip: Number(next.form.adres_tip), ulke: Number(next.form.ulke), sehir: Number(next.form.sehir), ilce: Number(next.form.ilce), siralama: next.form.siralama ? Number(next.form.siralama) : 0 },
                method: 'POST',
                id: null,
                successMessage: `${next.form.tanim} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.tanim} eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabBanka = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = bankaHesaplariFormsDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'banka_hesap_bilgi', prop2: 'hesap_adi' },
          { prop: 'para_birim', prop2: '' },
        ];
        await checkAllTabs(next.form, (err) => handleErrorsBankaHesap(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            if (isHas) {
              reqopt = {
                data: { banka_hesap_bilgi: { ...next.form.banka_hesap_bilgi }, para_birim: Number(next.form.para_birim) },
                method: 'PUT',
                id: next.form.id,
                successMessage: `${next.form.banka_hesap_bilgi.banka_adi} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.banka_hesap_bilgi.banka_adi} güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: { banka_hesap_bilgi: { ...next.form.banka_hesap_bilgi }, para_birim: Number(next.form.para_birim) },
                method: 'POST',
                id: null,
                successMessage: `${next.form.banka_hesap_bilgi.banka_adi} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.banka_hesap_bilgi.banka_adi} eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabDetayYetkili = async (prev, next) => {
      let reqopt = null;
      let err = null;
      const isEqual = _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        let errors = [];
        const list = [
          { prop: 'adi', prop2: '' },
          { prop: 'tel', prop2: '' },
          { prop: 'unvan', prop2: '' },
        ];
        for (const i in next) {
          await checkAllTabs(next[i].form, (err) => handleErrorsYetkili(err, i), list).then((res) => {
            if (!_isEmpty(res)) {
              errors.push(res);
            }
          })
        }
        if (_isEmpty(errors)) {
          const data = { yetkililer: next.map((y) => y.form) };
          if (add) {
            reqopt = {
              data: data,
              method: 'POST',
              id: null,
              successMessage: `Yetkili ekleme başarıyla tamamlandı`,
              errorMessageUnexpected: `Yetkili eklenirken beklenmeyen bir hata oluştu`,
            }
          } else {
            if (yetkiliId) {
              reqopt = {
                data: data,
                method: 'PUT',
                id: yetkiliId,
                successMessage: `Yetkili güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Yetkili güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `Yetkili ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Yetkili eklenirken beklenmeyen bir hata oluştu`,
              }
            }
          }

          err = null;
        } else {
          reqopt = null;
          err = errors;
        }
      }
      return [reqopt, err]
    }

    let errors = [[], [], [], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'adres', request: [], error: [] },
        { index: 2, name: 'bankahesabı', request: [], error: [] },
        { index: 3, name: 'yetkili', request: [], error: [] },
      ];
      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in adreslerForms) {
        const adresler = [...adreslerForms];
        const res = await createRequestOptionsTabAdres(adresler[i], i)
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      for (const i in bankaHesaplariForms) {
        const hesaplar = [...bankaHesaplariForms];
        const res = await createRequestOptionsTabBanka(hesaplar[i], i);
        tabs[2].request.push(res[0]);
        tabs[2].error.push(res[1]);
      }

      const res2 = await createRequestOptionsTabDetayYetkili(yetkililerFormsDefault, yetkililerForms);
      tabs[3].request.push(res2[0]);
      tabs[3].error.push(res2[1]);

      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "kullanici" : `kullanici/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.cari.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (SAVEID !== null) {
              if (tab.name === 'adres') {
                setAdreslerForms((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `kullanici/${SAVEID}/adresler/${request.id}` : `kullanici/${SAVEID}/adresler`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  setAdreslerAdd(true);
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  setAdreslerSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setAdreslerForms((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'bankahesabı') {
                setBankaHesaplariForms((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `kullanici/${SAVEID}/banka-hesaplari/${request.id}` : `kullanici/${SAVEID}/banka-hesaplari`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  setHesaplarAdd(true);
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  setBankaHesaplariSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setBankaHesaplariForms((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'yetkili') {
                setYetkililerForms((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `kullanici/${SAVEID}/yetkililer/${request.id}` : `kullanici/${SAVEID}/yetkililer`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  setYetkiliAdd(true);
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  setYetkililerSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setYetkililerForms((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }

    await start();
    if (SAVEID !== null) {
      if (!add) {
        if (!errors[0].includes("err")) {
          setLoadTab1(true);
        }
        if (!errors[1].includes("err")) {
          setLoadTab2(true);
        }
        if (!errors[2].includes("err")) {
          setLoadTab3(true);
        }
        if (!errors[3].includes("err")) {
          setLoadTab4(true);
        }
      } else {
        setSaveErrors(errors);
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
      await checkAll();
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? false : true : true;
        next[1].hasError = !errors[1].includes("err") ? false : true;
        next[2].hasError = !errors[2].includes("err") ? false : true;
        next[3].hasError = !errors[3].includes("err") ? false : true;
      });
    });
  }

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.cari.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteAdres = () => {
    setIsDeletingAdres(true);
    setIsDeletingAdresErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/adresler/${adresDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setShowAdresDeleteDialogDetail(false);
        setIsDeletingAdresErrorMessageDetail(null);
      } else {
        setIsDeletingAdresErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingAdresErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingAdres(false);
      setLoadTab2(true);
    });
  }

  const handleDeleteBanka = () => {
    setIsDeletingBanka(true);
    setIsDeletingBankaErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/banka-hesaplari/${bankaDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setShowBankaDeleteDialogDetail(false);
        setIsDeletingBankaErrorMessageDetail(null);
      } else {
        setIsDeletingBankaErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingBankaErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingBanka(false);
      setLoadTab3(true);
    });
  }

  const handleYetkililerDelete = (index) => {
    setYetkililerForms((prev) => immer(prev, (next) => {
      next.splice(index, 1);
    }));
  }

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsAdres = (errors, index, remove) => {
    if (remove) {
      setAdreslerForms((prev) => {
        const err = _pickBy({ ...prev[index]["errors"] }, _identity);
        delete err?.[`${remove}`];
        return immer(prev, (next) => {
          next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
        });
      });
    } else {
      setAdreslerForms((prev) => {
        const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
        return immer(prev, (next) => {
          next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
        });
      });
    }
  }

  const handleErrorsBankaHesap = (errors, index) => {
    setBankaHesaplariForms((prev) => {
      let errobj = { ...prev[index]["errors"], ...errors };
      let err = {};
      if (_has(errobj, 'banka_hesap_bilgi')) {
        const banka_hesap_bilgi = _pickBy(errobj['banka_hesap_bilgi'], _identity);
        if (_isEmpty(banka_hesap_bilgi)) {
          err = _pickBy({ ...errobj, 'banka_hesap_bilgi': null }, _identity);
        } else {
          err = _pickBy({ ...errobj, 'banka_hesap_bilgi': banka_hesap_bilgi }, _identity);
        }
      } else {
        err = _pickBy(errobj, _identity);
      }
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsYetkili = (errors, index) => {
    setYetkililerForms((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add}
        disableReloadButton={loading || add}
        disableCancelButton={!add && (loading || IS_EQUAL)}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add}
        disablePreviousButton={props.disablePreviousButton || add}
        disableNextButton={props.disableNextButton || add}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}

        {selectedTabIndex === 1 && (
          <TabAdresler
            loading={loadingAdres}
            hata={hataAdres}

            defaultSelectedTabIndex={adreslerSelectedTabIndex}
            forms={adreslerForms}
            ulkeler={ulkeler}
            ulkelerDic={ulkelerDic}
            onAddNewTab={handleAdreslerAddNewTab}
            adreslerAdd={adreslerAdd}
            onChangeForm={handleAdreslerChangeForm}

            setDeleteId={setAdresDeleteId}
            openDeleteForm={setShowAdresDeleteDialogDetail}

            onCheckErrors={(errors, index, remove) => { handleErrorsAdres(errors, index, remove) }}
          />
        )}

        {selectedTabIndex === 2 && (
          <TabBankaHesaplari
            loading={loadingBankaHesap}
            hata={hataBankaHesap}

            defaultSelectedTabIndex={bankaHesaplariSelectedTabIndex}
            forms={bankaHesaplariForms}
            paraBirimleri={paraBirimleri}
            paraBirimleriDic={paraBirimleriDic}
            onAddNewTab={handleHesaplarAddNewTab}
            hesaplarAdd={hesaplarAdd}
            onChangeForm={handleBankaBilgileriChangeForm}

            setDeleteId={setBankaDeleteId}
            openDeleteForm={setShowBankaDeleteDialogDetail}

            onCheckErrors={(errors, index) => { handleErrorsBankaHesap(errors, index) }}
          />
        )}

        {selectedTabIndex === 3 && (
          <TabYetkililer
            loading={loadingYetkili}
            hata={hataYetkili}

            defaultSelectedTabIndex={yetkililerSelectedTabIndex}
            forms={yetkililerForms}
            defaultforms={yetkililerFormsDefault}
            onAddNewTab={handleYetkililerAddNewTab}
            yetkiliAdd={yetkiliAdd}
            yetkiliDelete={handleYetkililerDelete}
            onChangeForm={handleYetkililerChangeForm}

            onCheckErrors={(errors, index) => { handleErrorsYetkili(errors, index) }}

            PATH={PATH}
            ID={ID}
            yetkiliId={yetkiliId}
          />
        )}

        {selectedTabIndex === 4 && (
          <TabSepet
            loading={loadingSepet}
            hata={hataSepet}
            sepet={sepet}
          />
        )}

        {selectedTabIndex === 5 && (
          <TabOdemeLog
            loading={loadingOdemeLog}
            loadingMore={loadingOdemeLogMore}
            hata={hataOdemeLog}

            liste={odemeLog}
            listeCount={odemeLogCount}
            page={PAGE_ODEME_LOG}
            pagesCount={PAGES_COUNT_ODEME_LOG}
            getMore={handleGetMoreList}
            rowCount={100}
          />
        )}
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Cari siliniyor lütfen bekleyin'
                : 'Bu cariyi gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }

      {showAdresDeleteDialogDetail &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingAdresErrorMessageDetail
              ? isDeletingAdresErrorMessageDetail
              : isDeletingAdres
                ? 'Adres siliniyor lütfen bekleyin'
                : 'Bu adresi gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingAdresErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingAdres}
          onSubmit={handleDeleteAdres}
          onCancel={() => setShowAdresDeleteDialogDetail(false)}
        ></DialogConfirm>
      }

      {showBankaDeleteDialogDetail &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingBankaErrorMessageDetail
              ? isDeletingBankaErrorMessageDetail
              : isDeletingBanka
                ? 'Banka hesabı siliniyor lütfen bekleyin'
                : 'Bu banka hesabını gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingBankaErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingBanka}
          onSubmit={handleDeleteBanka}
          onCancel={() => setShowBankaDeleteDialogDetail(false)}
        ></DialogConfirm>
      }
    </>
  );
};

export default TableDetailExampleDetail;
