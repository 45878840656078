import React, { useEffect, useRef, useState } from 'react';
import django from "api/django";

export const useComponentWillMount = (func) => {
  const willMount = useRef(true)
  if (willMount.current) func()
  willMount.current = false
}

export const GetSingleton = ({ url, id, target }) => {
  const [object, setObject] = useState({});

  useEffect(() => {
    django.get(`${url}${id}/`)
      .then(response => setObject(response.data))
  }, [id, url])

  return (
    <React.Fragment>{object[target]}</React.Fragment>
  )

}


function rgbValueToHex(c) {
  let hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(rgbString) {
  const [r, g, b] = rgbString.replace('rgb(', '').replace(')', '').split(',')
  return "#" + rgbValueToHex(parseInt(r)) + rgbValueToHex(parseInt(g)) + rgbValueToHex(parseInt(b));
}

export const brightness = (col, amt) => {
  col = rgbToHex(col)
  let usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00FF) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000FF) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

}

export const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "ıöüçşğİŞÇÖÜ·/_,:;";
  const to = "ioucsgiscou------";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export const turkishToLower = (string) => {
  const letters = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
  string = string.replace(/(([İIŞĞÜÇÖ]))/g, (letter) => {
    return letters[letter];
  })
  return string.toLowerCase();
}

export const EmptyIcon = () => {
  return <span style={{ width: 27, height: 24, display: 'inline-block' }} />
}

export const flattenObject = (object, subtractedItem) => {
  let results = [];

  const recurse = function (list) {
    list.filter(function (item) {
      if (!Array.isArray(item[subtractedItem])) {
        results.push(item)
      } else {
        recurse(item[subtractedItem])
      }
      return true;
    })
  }

  recurse(object)
  return results
}

export const dotParse = (obj, is, fallback) => {
  // Usage:
  // obj = {a:{b:{etc:5}}}
  // index(obj,'a.b.etc')
  // 5
  // index(obj,['a','b','etc'])   #works with both strings and lists
  // 5
  // index(obj,'a.b.etc', 123)    #setter-mode - third argument (possibly poor form)
  // 123

  if (typeof is == 'string')
    return dotParse(obj, is.split('.'), fallback);
  else if (is.length === 1 && fallback !== undefined)
    return obj[is[0]] = fallback;
  else if (is.length === 0)
    return obj;
  else
    return dotParse(obj[is[0]], is.slice(1), fallback);
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};


export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const filterSchemaFields = (response, fields) => {
  const properties = {}
  // eslint-disable-next-line array-callback-return
  Object.keys(response.properties).map(item => {
    if (fields.includes(item)) {
      properties[item] = response.properties[item]
    }
  })

  response.properties = properties;
  return response
}

/**
 * 
 * @source https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
 */
export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const SlugMaker = (text) => {
  let str = text.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "áäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to = "aaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export const MoneyFormatConvert = (money, type) => {
  if (type === "money") {
    let parts = money.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  } else {
    let number = money.replaceAll(".", "").replaceAll(",", ".");
    return number;
  }

}
