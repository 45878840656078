import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil,
  isEmpty as _isEmpty,
} from 'lodash';

import {
  TextField,
  Checkbox,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';

import { handleInputErrors } from '../../base/InputErrors'
import { Hata } from '../../base/Hata';

import { withStyles } from '@material-ui/core/styles';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
};

const OdemeServisDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const [odemeTanimOpen, setOdemeTanimOpen] = useState(false);


  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form[name], `${name2}`) || defaultValue,
        error: _has(props.error?.[name], `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form, `${name}`) || defaultValue,
        error: _has(props.error, `${name}`),
        fullWidth: true,
      };
    }
  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="tanim">
                    <label className={classes.layoutFormItemLabel} htmlFor="KullaniciAdi">Ödeme Servis Tanım *</label>
                    <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                      <TextField
                        {...createTextFieldProps('tanim', '')}
                        onChange={(e) => props.onChangeForm('tanim', '', e.target.value)}
                        onBlur={() => handleInputErrors('odemeservis', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Ödeme Servis Tanım', 'ödeme servis')}
                        inputProps={{
                          maxLength: 250,
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="servis_tipi">
                    <label className={classes.layoutFormItemLabel} htmlFor="servis_tipi">Servis Tipi *</label>
                    <Tooltip title={props.error?.servis_tipi ? props.error.servis_tipi[0] : (props.form?.servis_tipi === "-1" || _isNil(props.form?.servis_tipi)) ? '' : props.servisTipDic ? props.servisTipDic[props.form.servis_tipi] : ''}>
                      <TextField
                        {...createTextFieldProps('servis_tipi', '', '')}
                        onChange={(e) => props.onChangeForm('servis_tipi', '', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'servis_tipi', true, false, false, '', '', '')}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        {props.servisTip.map((pb) => (
                          <option key={pb.key} value={pb.key}>{pb.value}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="odeme_kurum">
                    <label className={classes.layoutFormItemLabel} htmlFor="odeme_kurum">Ödeme Kurum *</label>
                    <Tooltip title={props.error?.odeme_kurum ? props.error.odeme_kurum[0] : (props.form?.odeme_kurum === "-1" || _isNil(props.form?.odeme_kurum)) ? '' : props.odemeKurumDic ? props.odemeKurumDic[props.form.odeme_kurum] : ''}>
                      <TextField
                        {...createTextFieldProps('odeme_kurum', '')}
                        onChange={(e) => props.onChangeForm('odeme_kurum', '', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'odeme_kurum', true, false, false, '', '', '')}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option disabled key="-1" value="-1">Seçilmedi</option>
                        {props.odemeKurum.filter(x => x.tip === props.form?.servis_tipi).map((k) => (
                          <option key={k.key} value={k.key}>{k.value}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="para_birimi">
                    <label className={classes.layoutFormItemLabel} htmlFor="para_birimi">Para Birimi *</label>
                    <Tooltip title={props.error?.para_birimi ? props.error.para_birimi[0] : (props.form?.para_birimi === "-1" || _isNil(props.form?.para_birimi)) ? '' : props.paraBirimDic ? props.paraBirimDic[props.form.para_birimi] : ''}>
                      <TextField
                        {...createTextFieldProps('para_birimi', '', '')}
                        onChange={(e) => props.onChangeForm('para_birimi', '', e.target.value)}
                        onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'para_birimi', true, false, false, '', '', '')}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option key="-1" value="-1">Seçilmedi</option>
                        {props.paraBirim.map((pb) => (
                          <option key={pb.key} value={pb.key}>{pb.value}</option>
                        ))}
                      </TextField>
                    </Tooltip>
                  </li>

                  {!_isEmpty(props.form?.tanimlar) && Object.keys(props.form?.tanimlar).map(key => (
                    <li className={classes.layoutFormItem} id={key} key={key}>
                      <label className={classes.layoutFormItemLabel} htmlFor={key}>{key}</label>
                      <Tooltip title={props.error?.tanimlar?.[key] ? props.error.tanimlar[key][0] : _isNil(props.form?.tanimlar?.[key]) ? '' : props.form.tanimlar[key]}>
                        <TextField
                          {...createTextFieldProps('tanimlar', `${key}`)}
                          onChange={(e) => props.onChangeForm('tanimlar', `${key}`, e.target.value)}
                          onBlur={() => handleInputErrors('', props.form, props.formdefault, props.onCheckErrors, add, 'tanimlar', true, false, false, '', 'Ödeme ', '', `${key}`)}
                        ></TextField>
                      </Tooltip>
                    </li>
                  ))}

                  <li className={classes.layoutFormItem} id="test">
                    <label className={classes.layoutFormItemLabel} htmlFor="test">Test Aşaması</label>
                    <Checkbox
                      name="test"
                      color="primary"
                      checked={_result(props.form, 'test', false)}
                      onChange={(e, checked) => props.onChangeForm('test', '', checked)}
                    ></Checkbox>
                  </li>
                  {!add && (
                    <li className={classes.layoutFormItem}>
                      <div className="flex flex-col items-start justify-start">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          disableElevation
                          onClick={() => setOdemeTanimOpen(true)}
                        >ÖDEME TANIMI OLUŞTUR</Button>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
              {odemeTanimOpen &&
                <Dialog
                  open={odemeTanimOpen}
                  onClose={() => setOdemeTanimOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <div>
                        <ul className={classes.layoutFormList}>
                          <li className={classes.layoutFormItem}>
                            <label className={classes.layoutFormItemLabel} htmlFor="hedef_evrak_tip">Hedef Evrak Tip *</label>
                            <Tooltip title={_isNil(props.odemeTanim?.hedef_evrak_tip) ? '' : props.evrakTipDic ? props.evrakTipDic[props.odemeTanim.hedef_evrak_tip] : ''}>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                value={_result(props.odemeTanim, 'hedef_evrak_tip') || ''}
                                onChange={(e) => props.onChangeFormOdeme('hedef_evrak_tip', e.target.value)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                {props.evrakTip.map((pb) => (
                                  <option key={pb.key} value={pb.key}>{pb.value}</option>
                                ))}
                              </TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.layoutFormItem}>
                            <label className={classes.layoutFormItemLabel} htmlFor="odeme_3d_bicim">3d Ödeme</label>
                            <Tooltip title={_isNil(props.odemeTanim?.odeme_3d_bicim) ? '' : props.odemeTanim.odeme_3d_bicim === "0" ? "3d Ödeme Kullanma" : props.odemeTanim.odeme_3d_bicim === "1" ? "Kullanıcıya Sor" : "3d Ödeme Kullan"}>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                value={_result(props.odemeTanim, 'odeme_3d_bicim') || ''}
                                onChange={(e) => props.onChangeFormOdeme('odeme_3d_bicim', e.target.value)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                <option key="0" value="0">3d Ödeme Kullanma</option>
                                <option key="1" value="1">Kullanıcıya Sor</option>
                                <option key="2" value="2">3d Ödeme Kullan</option>
                              </TextField>
                            </Tooltip>
                          </li>
                        </ul>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions disableSpacing>
                    <Button
                      onClick={props.onSave}
                    >ONAYLA</Button>
                  </DialogActions>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default OdemeServisDetailTabGenel;
