import React from 'react';
import { useParams } from 'react-router-dom';

import UrunDetail from './UrunDetail';

const PageUrunDetail = () => {
  const params = useParams();

  return (
    <UrunDetail
      id={params?.id ? params.id === "yeni" ? null : params.id : params.id}
      path={`urun`}
      single={true}
    />
  );
};

export default PageUrunDetail;