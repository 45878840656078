import React, { useMemo, useState, useRef } from 'react';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import {
  isEqual as _isEqual
} from 'lodash';
import immer from 'immer';
import {
  TextField,
  Dialog,
  DialogContent,
  IconButton,
  CircularProgress,
  Typography,
  Divider
} from '@material-ui/core';

import {
  Search as IconSearch,
  Close as IconClose,
  Check as IconCheck
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import { Hata } from '../../base/Hata';
import ListSelectorSingle from '../../../components/ListSelectorSingle';


const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
      overflow: 'hidden',
    },
    listHeader: {
      display: "grid",
      gridTemplateColumns: "240px auto",
      padding: spacing(2, 0, 1, 0),
      marginBottom: spacing(2),
      borderBottom: `1px solid ${palette.primary.main}`
    },
    headerCol: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "left",
      width: 240
    },
    row: {
      display: "grid",
      gridTemplateColumns: "240px auto",
      alignItems: "center",
      height: 34,
    },
    input: {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 240,
      maxWidth: 240,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
  };
};

const OzellikBaslikDetailInside = withStyles(styles)(({ classes, ...props }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedFirstVal, setSelectedFirstVal] = useState(null);
  const [openSelector, setOpenSelector] = useState(false);

  const LOADING = useMemo(() => props.loading, [props.loading]);
  const LOADINGMORE = useMemo(() => props.loadingMore, [props.loadingMore]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const LISTE = useMemo(() => props.liste, [props.liste]);
  const LISTE_COUNT = useMemo(() => props.listeCount, [props.listeCount]);
  const PAGE = useMemo(() => props.page, [props.page]);
  const PAGES_COUNT = useMemo(() => props.pagesCount, [props.pagesCount]);
  //const PATH = useMemo(() => props.path, [props.path]);
  const SELECTED_ITEM = useMemo(() => props?.liste?.[selectedIndex] ?? null, [props.liste, selectedIndex]);

  const [searchOpen, setSearchOpen] = useState([false, false]);
  const [searchQuery, setSearchQuery] = useState([{ key: "tanim", value: "" }, { key: "ozellik_baslik", value: "" }]);

  const mainContainerRef = useRef();

  const handleChangeForm = (value) => {
    props.onChange(selectedIndex, value)
  }

  const rowRenderer = ({ index, style }) => {
    const item = LISTE[index];
    return (
      <div key={item.id} style={style}
        className="cursor-pointer"
        onClick={() => {
          setSelectedIndex(index);
          setSelectedFirstVal(item);
        }}
      >
        {index !== 0 && <Divider />}
        <div className={classes.row}>
          <span>{item.tanim}</span>
          <span className={classes.input}>
            <TextField
              name="tanim"
              variant="outlined"
              size="small"
              fullWidth={true}
              value={item.ozellik_baslik?.tanim ?? ''}
              InputProps={{
                endAdornment:
                  <IconButton
                    size="small"
                    className={classes.button}
                    disableRipple
                    onClick={() => setOpenSelector(true)}
                  ><IconSearch /></IconButton>
              }}
            ></TextField>
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  {LISTE_COUNT > 0 && (
                    <div className="relative flex flex-grow flex-col h-full overflow-x-auto overflow-y-hidden">
                      <div className={classes.listHeader}>
                        <div>
                          {searchOpen[0] && (
                            <div className={classes.headerCol}>
                              <TextField
                                variant="outlined"
                                size='small'
                                fullWidth={true}
                                label="Entegrasyon Tanım"
                                value={searchQuery[0].value}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  setSearchQuery((prev) => {
                                    return immer(prev, (next) => {
                                      next[0].value = val;
                                    });
                                  });
                                }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  endAdornment:
                                    <IconButton
                                      size="small"
                                      disableRipple
                                      onClick={() => props.searchListSet(searchQuery)}
                                    >
                                      <IconCheck />
                                    </IconButton>
                                }}
                              >
                              </TextField>
                              <IconButton
                                size="small"
                                disableRipple
                                onClick={() => {
                                  setSearchQuery((prev) => {
                                    return immer(prev, (next) => {
                                      next[0].value = "";
                                      props.searchListSet(next)
                                    });
                                  });
                                  setSearchOpen((prev) => {
                                    return immer(prev, (next) => {
                                      next[0] = false;
                                    });
                                  });
                                }}
                              >
                                <IconClose />
                              </IconButton>
                            </div>
                          )}
                          {!searchOpen[0] && (
                            <div className={classes.headerCol}>
                              <span>Entegrasyon Tanım</span>
                              <IconButton
                                size="small"
                                disableRipple
                                onClick={() => {
                                  setSearchOpen((prev) => {
                                    return immer(prev, (next) => {
                                      next[0] = true;
                                    });
                                  });
                                }}
                              >
                                <IconSearch />
                              </IconButton>
                            </div>
                          )}
                        </div>
                        <div>
                          {searchOpen[1] && (
                            <div className={classes.headerCol}>
                              <TextField
                                variant="outlined"
                                size='small'
                                fullWidth={true}
                                label="Eşleştirilen Özellik Başlık"
                                value={searchQuery[1].value}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  setSearchQuery((prev) => {
                                    return immer(prev, (next) => {
                                      next[1].value = val;
                                    });
                                  });
                                }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  endAdornment:
                                    <IconButton
                                      size="small"
                                      disableRipple
                                      onClick={() => props.searchListSet(searchQuery)}
                                    >
                                      <IconCheck />
                                    </IconButton>
                                }}
                              >
                              </TextField>
                              <IconButton
                                size="small"
                                disableRipple
                                onClick={() => {
                                  setSearchQuery((prev) => {
                                    return immer(prev, (next) => {
                                      next[1].value = "";
                                      props.searchListSet(next)
                                    });
                                  });
                                  setSearchOpen((prev) => {
                                    return immer(prev, (next) => {
                                      next[1] = false;
                                    });
                                  });
                                }}
                              >
                                <IconClose />
                              </IconButton>
                            </div>
                          )}
                          {!searchOpen[1] && (
                            <div className={classes.headerCol}>
                              <span>Eşleştirilen Özellik Başlık</span>
                              <IconButton
                                size="small"
                                disableRipple
                                onClick={() => {
                                  setSearchOpen((prev) => {
                                    return immer(prev, (next) => {
                                      next[1] = true;
                                    });
                                  });
                                }}
                              >
                                <IconSearch />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      </div>
                      <main className="relative flex flex-grow flex-col overflow-hidden h-full" ref={mainContainerRef}>
                        <div className="h-full flex-grow">
                          <InfiniteLoader
                            minimumBatchSize={100}
                            threshold={100}
                            isRowLoaded={({ index }) => !!LISTE[index]}
                            loadMoreRows={({ startIndex, stopIndex }) => {
                              if (LISTE_COUNT > 0 && PAGES_COUNT > PAGE && !LOADINGMORE) {
                                props.getMore(PAGE + 1, mainContainerRef, searchQuery);
                              }
                            }}
                            rowCount={LISTE_COUNT > 0 ? LISTE_COUNT : props.rowCount * 2}
                          >
                            {({ onRowsRendered, registerChild }) => (
                              <AutoSizer>
                                {({ width, height }) => (
                                  <List
                                    width={width}
                                    height={height}
                                    rowHeight={35}
                                    rowCount={LISTE.length}
                                    estimatedRowSize={PAGES_COUNT > 0 ? PAGES_COUNT * (35) : undefined}
                                    rowRenderer={rowRenderer}
                                    onRowsRendered={onRowsRendered}
                                    ref={registerChild}
                                  />
                                )}
                              </AutoSizer>
                            )}
                          </InfiniteLoader>
                        </div>
                      </main>
                    </div>
                  )}
                  {LISTE_COUNT === 0 && (
                    <div className="flex w-full h-full justify-center items-center">
                      <Typography variant="caption">Özellik başlık listesi bulunmamaktadır.</Typography>
                    </div>
                  )}
                </div>
              </div>
              {openSelector &&
                <Dialog
                  open={openSelector}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelector(false);
                    handleChangeForm(selectedFirstVal.ozellik_baslik);
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Özellik Başlıklar'
                      listPath='ozellikbaslik/mini_list'
                      listCountPath='ozellikbaslik/count'
                      keyProp='id'
                      valueProp='tanim'
                      defaultItem={SELECTED_ITEM.ozellik_baslik}
                      onChangeItems={(items) => handleChangeForm(items)}
                      onClose={() => {
                        setOpenSelector(false);
                        handleChangeForm(selectedFirstVal.ozellik_baslik);
                      }}
                      newPath='tanimlar/ozellikbaslik'
                      confirmButton={true}
                      confirmEnable={!_isEqual(selectedFirstVal, SELECTED_ITEM)}
                      onConfirm={() => {
                        props.onSave(SELECTED_ITEM);
                        setOpenSelector(false);
                      }}
                      confirmBtnTitle="Kaydet"
                    />
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
      {LOADING &&
        <div className="flex w-full justify-center items-center">
          <CircularProgress color="primary" />
          <Typography variant="caption">Yükleniyor...</Typography>
        </div>
      }
    </>
  );
});

export default OzellikBaslikDetailInside;
