import React, { useState, useEffect } from 'react';

import {
  MenuItem,
  Divider,
  MenuList,
  Paper,
  ClickAwayListener,
} from '@material-ui/core';

const RightMenu = (props) => {
  const [menuPosition, setMenuPosition] = useState(props.position);

  const handleMenuEvent = (e, selected) => {
    e.preventDefault();
    switch (selected) {
      case 0:
        if (props.ustId) {
          window.open(`${window.location.origin}/${props.url}/${props.ustId}/${props.itemId}/detay`, '_blank');
        } else {
          window.open(`${window.location.origin}/${props.url}/${props.itemId}/detay`, '_blank');
        }
        break;
      default:
        break;
    }
    setTimeout(() => {
      setMenuPosition(null);
    }, 100);
  };

  useEffect(() => {
    setMenuPosition(props.position);
  }, [props.position]);

  const MenuListesi = [
    { key: 0, title: "Yeni Sekmede Aç" },
  ]

  return (
    <>
      {menuPosition && (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => setMenuPosition(null)}>
          <Paper className="fixed z-10" style={menuPosition} >
            {!props.openModalInside &&
              <MenuList className="focus:outline-none">
                {MenuListesi.map((item, index) => (
                  <div key={item.title + index}>
                    <MenuItem onClick={(e) => handleMenuEvent(e, item.key)}>{item.title}</MenuItem>
                    {index !== 0 && <Divider />}
                  </div>
                ))}
              </MenuList>
            }
            {props.openModalInside &&
              <MenuList className="focus:outline-none">
                <MenuItem onClick={(e) => {
                  e.preventDefault();
                  props.setModalInsideItem(props.selectedItem)
                }}>Detay Göster</MenuItem>
              </MenuList>
            }
          </Paper>
        </ClickAwayListener>
      )}
    </>
  )

}

export default RightMenu;