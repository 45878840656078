import React, { useState, useLayoutEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import clsx from 'clsx';

import {
  has as _has,
  result as _result,
  pickBy as _pickBy,
  trimEnd as _trimEnd,
} from 'lodash';

import {
  Slide,
  LinearProgress,
  CircularProgress,
  IconButton,
  Button,
  Divider,
  Tooltip,
} from '@material-ui/core'

import {
  VerticalSplit as IconVerticalSplit,
  HorizontalSplit as IconHorizontalSplit,
  Refresh as IconRefresh,
  ViewColumn as IconViewColumn,
  List as IconList,
  LockOutlined as IconLockOutlined,
  Add as IconAdd
} from '@material-ui/icons';

import django from '../../api/django';
import Store from '../../libs/store';

import { QUERY_STRING_OPTIONS } from '../../constants/options';

import useFilter from '../../hooks/useFilter';

import { IconFilter, IconFilterClear } from '../../components/Icon';
import DialogConfirm from '../../components/DialogConfirm';
import DialogExtraConfirm from '../../components/DialogExtraConfirm';

import Table from '../../components/GridTableX';
import Pagination from '../../components/GridTableX_Pagination';
import ColumnEditor from '../../components/GridTableX_ColumnEditor';

const FILTER_SIZE = 30;

const PAGE_SETTINGS_DEFAULT = {
  tableOnLeft: true,
  tableWidth: 480,
  tableHeight: 480,
  showTableFilter: false,
  showFilterRow: false,
  showCheckboxCell: false,
  showColumnEditor: false,
  varyantTree: false
};

const LayoutTableDetail = (props) => {
  // #region states
  const [pageLoading, setPageLoading] = useState(true);
  const [showProgress, setShowProgress] = useState(true);
  const [loadError, setLoadError] = useState(false);

  const [columns, setColumns] = useState(props.columnsDefault)
  const [settings, setSettings] = useState(PAGE_SETTINGS_DEFAULT);

  const [rows, setRows] = useState([]);
  const [countTotalRows, setCountTotalRows] = useState(0);
  const [rowsLastUpdateTime, setRowsLastUpdateTime] = useState(0);

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [checkedRowIndexes, setCheckedRowIndexes] = useState([]);

  const [disableCloseDetail, setDisableCloseDetail] = useState(false);
  const [defaultSelectedIndex, setDefaultSelectedIndex] = useState(null);

  const [showColumnEditor, setShowColumnEditor] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [tempFilter, setTempFilter] = useState(null);

  const [addnew, setAddNew] = useState(false);
  const [eventType, setEventType] = useState(0);

  // delete process
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);
  // #endregion

  // #region static hooks
  const history = useHistory();

  const columnEditorButtonRef = useRef();
  const tableContainerRef = useRef();
  const detailRef = useRef();

  const filter = useFilter();
  // #endregion

  // #region constants
  const PATH = useMemo(() => props.path, [props]);
  const STOREKEY = useMemo(() => props.storekey, [props.storekey]);
  const PAGEFILTER = useMemo(() => props.pagefilter, [props]);
  const BLOCKEDFILTER = useMemo(() => props.blockedFilter, [props]);
  const PAGE_SETTINGS_STORAGE_KEY = useMemo(() => `egan:saved-page-settings-${STOREKEY}`, [STOREKEY]);
  const PAGE_COLUMNS_STORAGE_KEY = useMemo(() => `egan:saved-page-columns-${STOREKEY}`, [STOREKEY]);

  const PAGE = useMemo(() => Number(_result(filter, 'page', 1)), [filter]);
  const COUNT_PAGES = useMemo(() => Math.ceil(countTotalRows / FILTER_SIZE), [countTotalRows]);

  const IS_FILTERED = useMemo(() => filter ? Object.keys(filter).filter((f) => f !== 'page').length > 0 : false, [filter]);
  const IS_FIRST_PAGE = useMemo(() => PAGE === 1, [PAGE]);
  const IS_LAST_PAGE = useMemo(() => PAGE >= COUNT_PAGES, [PAGE, COUNT_PAGES]);

  const TABLE_CONTAINER_WIDTH = useMemo(
    () => settings.tableOnLeft && showDetail ? settings.tableWidth : '100%',
    [settings.tableOnLeft, showDetail, settings.tableWidth]
  );

  const TABLE_CONTAINER_HEIGHT = useMemo(
    () => !settings.tableOnLeft && showDetail ? settings.tableHeight : '100%',
    [settings.tableOnLeft, showDetail, settings.tableHeight]
  );

  const TABLE_VARYANT = useMemo(
    () => settings.varyantTree ? settings.varyantTree : false,
    [settings.varyantTree]
  );
  // #endregion

  // #region user hooks
  // get settings from localStorage
  useLayoutEffect(() => {
    const storedSettings = Store.get(PAGE_SETTINGS_STORAGE_KEY);
    const storedColumns = Store.get(PAGE_COLUMNS_STORAGE_KEY);
    if (storedSettings) {
      setSettings(storedSettings);
    } else {
      setSettings(PAGE_SETTINGS_DEFAULT)
    }
    if (storedColumns) {
      setColumns(storedColumns);
    } else {
      setColumns(props.columnsDefault)
    }
  }, [PAGE_SETTINGS_STORAGE_KEY, PAGE_COLUMNS_STORAGE_KEY, props.columnsDefault]);

  // save settings to localStorage
  useLayoutEffect(() => {
    const debounce = setTimeout(() => {
      Store.set(PAGE_SETTINGS_STORAGE_KEY, settings);
      Store.set(PAGE_COLUMNS_STORAGE_KEY, columns);
    }, 500);
    return () => {
      clearTimeout(debounce);
    };
  }, [PAGE_SETTINGS_STORAGE_KEY, PAGE_COLUMNS_STORAGE_KEY, settings, columns]);

  // set rows
  useLayoutEffect(() => {
    let path = PATH;
    let filters = { ...filter };
    if (PAGEFILTER) {
      if (BLOCKEDFILTER) {
        if (filter && !('tip' in filter)) {
          path = TABLE_VARYANT ? `${PATH}${PAGEFILTER}&ust.id=null` : `${PATH}${PAGEFILTER}`
        }
      } else {
        path = TABLE_VARYANT ? `${PATH}${PAGEFILTER}&ust.id=null` : `${PATH}${PAGEFILTER}`
      }
    } else {
      path = TABLE_VARYANT ? `${PATH}/?ust.id=null` : PATH;
    }
    columns.forEach((col) => {
      if (_has(col, 'openFilter')) {
        if (!_has(filter, `${col.filterProp}`)) {
          filters = Object.assign(filters, { [col.filterProp]: col.openFilter })
        }
      }
    });
    const handleGet = () => {
      django(path, { params: { ...filters, size: FILTER_SIZE } }).then(({ data }) => {
        if (TABLE_VARYANT) {
          const form = data.map((x) => ({ ...x, varyants_open: false }))
          setRows(form);
        } else {
          setRows(data);
        }
        setLoadError(false);
        if (defaultSelectedIndex !== null) {
          setSelectedRowIndex(defaultSelectedIndex);
          setSelectedRow(data[defaultSelectedIndex]);
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            setLoadError(true);
          }
        }
      }).finally(() => {
        if (rowsLastUpdateTime === 0) {
          setPageLoading(false);
          setAddNew(false);
        }
        setShowProgress(false);
      });
    };
    setShowProgress(true);
    const debounce = setTimeout(handleGet, 300);
    return () => {
      setShowProgress(false);
      clearTimeout(debounce);
    };
  }, [PATH, defaultSelectedIndex, filter, rowsLastUpdateTime, PAGEFILTER, BLOCKEDFILTER, columns, TABLE_VARYANT]);

  // set countRows
  useLayoutEffect(() => {
    let path = TABLE_VARYANT ? `${PATH}/count/?ust.id=null` : `${PATH}/count`;
    if (PAGEFILTER) {
      if (BLOCKEDFILTER) {
        if (filter && !('tip' in filter)) {
          path = TABLE_VARYANT ? `${PATH}/count/${PAGEFILTER}&ust.id=null` : `${PATH}/count/${PAGEFILTER}`;
        }
      } else {
        path = TABLE_VARYANT ? `${PATH}/count/${PAGEFILTER}&ust.id=null` : `${PATH}/count/${PAGEFILTER}`;
      }
    }
    const handleGet = () => {
      django(`${path}`, { params: filter }).then(({ data }) => {
        setCountTotalRows(Number(data));
        setLoadError(false);
      }).catch((error) => {
        setCountTotalRows(0);
        if (error.response) {
          if (error.response.status === 500) {
            setLoadError(true);
          }
        }
      });
    };
    const debounce = setTimeout(handleGet, 300);
    return () => {
      clearTimeout(debounce);
    };
  }, [PATH, filter, PAGEFILTER, BLOCKEDFILTER, TABLE_VARYANT]);

  // update temp filter
  useLayoutEffect(() => setTempFilter(filter), [filter]);
  // #endregion

  const confirmCloseDetail = (func) => {
    if (!disableCloseDetail || (disableCloseDetail && window.confirm('Yaptığınız değişiklikler iptal edilecek'))) {
      func();
      setDisableCloseDetail(false);
      setAddNew(false);
    }
  };

  const handleChangeSetting = (key, value) => {
    setSettings({
      ...settings,
      [key]: value,
    });
  };

  // #region row functions
  const handleCheckedRow = (tableIndex, checked) => {
    const copyCheckedRowIndexes = [...checkedRowIndexes];
    const arrayIndex = copyCheckedRowIndexes.indexOf(tableIndex);
    const has = arrayIndex > -1;
    // bu index daha önce eklenmiş ve kaldırılması isteniyor
    if (has && !checked) {
      copyCheckedRowIndexes.splice(arrayIndex, 1);
    }
    // bu index daha önce eklenmemiş ve eklenmesi isteniyor
    if (!has && checked) {
      copyCheckedRowIndexes.push(tableIndex);
    }
    setCheckedRowIndexes(copyCheckedRowIndexes);
  };

  const handleCheckedRows = (checked) => {
    if (checked) {
      const createIndex = [...new Array(rows.length).keys()];
      setCheckedRowIndexes(createIndex);
    } else {
      setCheckedRowIndexes([]);
    }
  };

  const handleRemoveRowByIndex = (index) => {
    const rowsCopy = [...rows];
    if (index > -1) {
      rowsCopy.splice(index, 1);
      setRows(rowsCopy);
      setCountTotalRows(countTotalRows - 1);
      setDefaultSelectedIndex(index);
      if (rowsCopy.length === 0) {
        setShowDetail(false);
      }
      setRowsLastUpdateTime(Date.now());
      setEventType(0);
    }
  };

  const handleDeleteSelectedRow = (e) => {
    e.preventDefault();
    if (props.path !== 'evrak') {
      setIsDeleting(true);
      setIsDeletingErrorMessage(null);
      django.delete(selectedRow.detay_url).then(() => {
        handleRemoveRowByIndex(selectedRowIndex);
        setShowDeleteDialog(false);
      }).catch(() => {
        setIsDeletingErrorMessage('Silme işleminde beklenmeyen bir hata oluştu.');
      }).finally(() => {
        setIsDeleting(false);
      });
    } else {
      setIsDeleting(true);
      setIsDeletingErrorMessage(null);
      const data = { ...selectedRow, versiyon: selectedRow.versiyon + 1, iptal: true }
      django.post(props.cancelpath, data).then(() => {
        setShowDeleteDialog(false);
      }).catch(() => {
        setIsDeletingErrorMessage('İptal işleminde beklenmeyen bir hata oluştu.');
      }).finally(() => {
        setIsDeleting(false);
      });
    }

  };

  const handleVaryantListOpenClose = (row, index) => {
    try {
      setShowProgress(true)
      if (row.varyants_open) {
        let liste = [...rows];
        const varyantSetf = (list, uid) => {
          [...list].filter(item => item.ust_id === uid).forEach(element => {
            if (element.alt) {
              varyantSetf(list, element.id)
            }
            liste.splice(liste.indexOf(element), 1);
          });
        }
        varyantSetf([...rows], row.id);
        liste[liste.indexOf(row)] = { ...liste[liste.indexOf(row)], varyants_open: false }
        setRows(liste);
        setShowProgress(false);
      } else {
        django(`${PATH}?ust.id=${row.id}`).then(({ data }) => {
          const form = data.map((x) => ({ ...x, varyants_open: false, left: row?.left ? (row.left * 2) : 7 }));
          const newRows = [...rows];
          newRows[index].varyants_open = true;
          newRows.splice(index + 1, 0, ...form);
          setRows(newRows);
          setShowProgress(false);
        });
      }
    } catch {
      setShowProgress(false)
      setLoadError(true);
    }
  }
  // #endregion

  // #region filter/sort
  const handleQuickSetSort = (columnName) => {
    const copyFilter = { ...filter };
    // sort diye bir prop var mı?
    if (_has(copyFilter, 'sort')) {
      const indexAsc = copyFilter.sort.indexOf(columnName);
      const indexDesc = copyFilter.sort.indexOf(`-${columnName}`);
      // asc varsa desc'ye çevir
      if (indexAsc > -1) {
        copyFilter.sort[indexAsc] = `-${columnName}`;
      }
      // desc varsa kaldır
      else if (indexDesc > -1) {
        copyFilter.sort.splice(indexDesc, 1);
      }
      // hiçbir şey yoksa asc'ye çevir
      else {
        copyFilter.sort.push(columnName);
      }
    } else {
      copyFilter.sort = [columnName];
    }
    const search = queryString.stringify(_pickBy({ ...copyFilter, page: '' }), QUERY_STRING_OPTIONS);
    history.replace({ pathname: window.location.pathname, search });
  };

  const handleSubmitTempFilter = (columnName, filterValue) => {
    if (columnName !== undefined && filterValue !== undefined) {
      const searchParams = {
        ...tempFilter,
        [columnName]: filterValue,
        page: null,
      };
      const search = queryString.stringify(_pickBy(searchParams), QUERY_STRING_OPTIONS);
      history.push({ pathname: window.location.pathname, search });
    }
  };

  const handleChangePage = (page) => {
    const searchParams = {
      ...tempFilter,
      page: page !== 1 ? page : null,
    };
    const search = queryString.stringify(_pickBy(searchParams), QUERY_STRING_OPTIONS);
    history.push({ pathname: window.location.pathname, search });
  };
  // #endregion

  // #region resizer
  const handleResizerDragStop = (e) => {
    e.preventDefault();
    document.onmousemove = null;
    document.onmouseup = null;
  };

  const handleResizerDrag = (e) => {
    e.preventDefault();
    if (tableContainerRef.current) {
      const tableContainerRect = tableContainerRef.current.getBoundingClientRect();
      if (settings.tableOnLeft) {
        handleChangeSetting('tableWidth', e.clientX - tableContainerRect.x);
      } else {
        handleChangeSetting('tableHeight', e.clientY - tableContainerRect.y);
      }
    }
  };

  const handleResize = (e) => {
    e.preventDefault();
    document.onmousemove = handleResizerDrag;
    document.onmouseup = handleResizerDragStop;
  };
  // #endregion

  // #region go next/previous
  const handleSelectPreviousRow = (e) => {
    confirmCloseDetail(() => {
      // ilk ürün değilse selectedRowIndex'i -1 artırıyoruz
      if (selectedRowIndex > 0) {
        setSelectedRowIndex(selectedRowIndex - 1);
        setSelectedRow(rows[selectedRowIndex - 1]);
      }
      // ilk ürünse sonraki sayfaya geçmece
      else {
        if (PAGE > 1) {
          handleChangePage(PAGE - 1);
          setDefaultSelectedIndex(FILTER_SIZE - 1);
        }
      }
      setEventType(0);
    });
  };

  const handleSelectNextRow = (e) => {
    confirmCloseDetail(() => {
      // son ürünse sonraki sayfaya geçmece
      if (rows.length === selectedRowIndex + 1 && COUNT_PAGES > PAGE) {
        handleChangePage(PAGE + 1);
        setDefaultSelectedIndex(0);
      }
      // son ürün değilse selectedRowIndex'i +1 artırıyoruz
      else if (rows.length > (selectedRowIndex + 1)) {
        setSelectedRowIndex(selectedRowIndex + 1);
        setSelectedRow(rows[selectedRowIndex + 1]);
      }
      setEventType(0);
    });
  };

  const handleAdd = () => {
    setAddNew(true);
    setShowDetail(true);
  }

  // #endregion

  // console.log('🚀');

  if (pageLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center overflow-hidden">
        <div className="flex items-center justify-center">
          <CircularProgress size={72} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="relative w-full h-full flex flex-col rounded bg-palette-background-default border-2 border-palette-action-disabledBackground">
        {/* top.navbar */}
        <nav className="relative w-full h-10 flex items-center justify-between bg-palette-action-hover border-b border-palette-action-selected z-10">
          <span className={clsx('absolute left-0 top-0 w-full h-auto', { 'hidden': !showProgress })}>
            <LinearProgress />
          </span>

          {/* top.navbar.left */}
          <div className="flex items-center justify-start space-x-2 px-2">
            {props.title && (
              <>
                <h1 className="flex items-center justify-start m-0">
                  <span className="text-sm font-medium leading-none">{props.title}</span>
                </h1>
                {settings.showCheckboxCell && checkedRowIndexes.length > 0 && (
                  <>
                    <Divider orientation="vertical" flexItem></Divider>

                    <Button
                      variant="text"
                      color="inherit"
                      size="small"
                    >{`${checkedRowIndexes.length} SATIR SEÇİLDİ`}</Button>
                  </>
                )}
              </>
            )}

            {props.headerButton && props.headerList.map((h) => (
              <div className='flex items-center justify-start space-x-2' key={h.index}>
                {h.index !== 0 &&
                  <Divider orientation="vertical" flexItem></Divider>
                }
                <h1 className="flex items-center justify-start m-0">
                  <span onClick={() => { if (h.func) { h.func() } }} className={clsx('text-sm font-medium leading-none p-1', { 'bg-primary text-palette-background-paper rounded': h.active }, { 'cursor-pointer': h.func })}>{h.title}</span>
                </h1>
                {h.index === 0 && settings.showCheckboxCell && checkedRowIndexes.length > 0 && (
                  <>
                    <Divider orientation="vertical" flexItem></Divider>

                    <Button
                      variant="text"
                      color="inherit"
                      size="small"
                    >{`${checkedRowIndexes.length} SATIR SEÇİLDİ`}</Button>
                  </>
                )}
              </div>
            ))}


          </div>

          {/* top.navbar.right */}
          <div className="flex items-center justify-end space-x-2 px-4">
            {props.varyant && (
              <>
                <Button
                  variant="text"
                  color="inherit"
                  size="small"
                  onClick={() => handleChangeSetting('varyantTree', !settings.varyantTree)}
                >{TABLE_VARYANT ? 'Varyantları Aç' : 'Varyantları Kapat'}</Button>
              </>
            )}

            {disableCloseDetail && (
              <Tooltip title="Detay içerisinde değişiklik yapıldı">
                <IconButton
                  component="span"
                  size="small"
                  style={{
                    backgroundColor: 'rgb(241 196 15 / 20%)',
                    cursor: 'help'
                  }}
                  disableFocusRipple
                  disableRipple
                ><IconLockOutlined /></IconButton>
              </Tooltip>
            )}

            {IS_FILTERED && (
              <Tooltip title="Filtreyi temizle">
                <IconButton
                  size="small"
                  style={{
                    width: 30,
                    height: 30,
                    backgroundColor: 'rgb(241 196 15 / 20%)',
                  }}
                  onClick={() => history.replace({ pathname: window.location.pathname })}
                ><IconFilterClear /></IconButton>
              </Tooltip>
            )}

            <Tooltip title={settings.showCheckboxCell ? 'Çoklu seçim sütununu kapat' : 'Çoklu seçim sütununu aç'}>
              <IconButton
                size="small"
                onClick={() => handleChangeSetting('showCheckboxCell', !settings.showCheckboxCell)}
              ><IconList /></IconButton>
            </Tooltip>

            <Tooltip title={settings.showFilterRow ? 'Filtre satırını gizle' : 'Filtre satırını göster'}>
              <IconButton
                size="small"
                onClick={() => handleChangeSetting('showFilterRow', !settings.showFilterRow)}
                style={{
                  width: 30,
                  height: 30,
                }}
              ><IconFilter /></IconButton>
            </Tooltip>

            <Tooltip title="Sütunları düzenle">
              <IconButton
                size="small"
                onClick={() => setShowColumnEditor(!showColumnEditor)}
                ref={columnEditorButtonRef}
              >
                <IconViewColumn />
              </IconButton>
            </Tooltip>

            <Divider orientation="vertical" flexItem></Divider>

            <Tooltip title={!showDetail ? 'Detay göster' : settings.tableOnLeft ? 'Detay altta' : 'Detay sağda'}>
              <span>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (showDetail) {
                      handleChangeSetting('tableOnLeft', !settings.tableOnLeft)
                    } else {
                      setShowDetail(true);
                    }
                  }}
                  disabled={selectedRowIndex === null}
                >
                  {settings.tableOnLeft ? <IconHorizontalSplit /> : <IconVerticalSplit />}
                </IconButton>
              </span>
            </Tooltip>

            <Divider orientation="vertical" flexItem></Divider>

            {!props.disableAdd && !showDetail &&
              <Tooltip title="Yeni">
                <IconButton
                  size="small"
                  onClick={handleAdd}
                >
                  <IconAdd />
                </IconButton>
              </Tooltip>
            }

            <Tooltip title="Listeyi yenile">
              <IconButton
                size="small"
                onClick={() => setRowsLastUpdateTime(Date.now())}
              >
                <IconRefresh />
              </IconButton>
            </Tooltip>
          </div>
        </nav>

        {/* main */}
        <div className="relative w-full h-full flex overflow-hidden">
          {/* main.left */}
          {/* {LeftFilterProps.show && (
            <aside className="w-80 h-full flex items-center justify-center border-r border-palette-action-selected bg-palette-background-paper">
              {LeftFilterChildren}
            </aside>
          )} */}

          {/* main.content */}
          <div
            className={clsx('relative w-full h-full flex overflow-hidden', {
              'flex-row': settings.tableOnLeft,
              'flex-col': !settings.tableOnLeft,
            })}
          >
            {/* table.container */}
            <div
              className={clsx('flex flex-col items-start', {
                'border-r border-palette-action-hover': showDetail,
              })}
              style={{
                width: TABLE_CONTAINER_WIDTH,
                height: TABLE_CONTAINER_HEIGHT,
              }}
              ref={tableContainerRef}
            >

              <Table
                loadError={loadError}
                path={props.path}
                rows={rows}
                columns={columns}
                functions={props.funcList ?? null}

                filter={filter}

                varyant={props.varyant}
                showTreeArrow={settings.varyantTree}
                showFilterRow={settings.showFilterRow}
                showCheckBoxCell={settings.showCheckboxCell}

                checkedRowIndexes={checkedRowIndexes}
                onCheckedRow={handleCheckedRow}
                onCheckedRows={handleCheckedRows}

                onChangeColumnSort={(colName) => handleQuickSetSort(colName)}
                onChangeFilterValue={(columnName, filterValue) => handleSubmitTempFilter(columnName, filterValue)}

                selectedRowIndex={selectedRowIndex ?? undefined}

                onSelectNextRow={handleSelectNextRow}
                onSelectPreviousRow={handleSelectPreviousRow}

                onClickTreeArrow={handleVaryantListOpenClose}

                onKeyupEnter={(index) => {
                  setSelectedRowIndex(index);
                  setSelectedRow(rows[index]);
                  setShowDetail(true);
                  setEventType(0);
                }}

                onKeyupEsc={() => {
                  if (showDetail) {
                    setSelectedRowIndex(null);
                    setSelectedRow(null);
                    setShowDetail(false);
                  }
                }}

                onTableRowClick={(index) => {
                  confirmCloseDetail(() => {
                    setSelectedRowIndex(index);
                    setSelectedRow(rows[index]);
                    setEventType(0);
                  });
                }}

                onTableRowDoubleClick={(index) => {
                  setShowDetail(true);
                  setSelectedRowIndex(index);
                  setSelectedRow(rows[index]);
                  setEventType(0);
                }}

                onRightClickMenuOpenNewTab={(index) => {
                  const row = rows[index];
                  window.open(`${_trimEnd(window.location.pathname, '/')}/${row.id}/detay`, '_blank');
                }}

                onRightClickMenuShowDetail={(index) => {
                  setSelectedRowIndex(index);
                  setSelectedRow(rows[index]);
                  setShowDetail(true);
                }}

                onRightClickMenuDelete={(index) => {
                  if (props.path !== 'sablon_paket') {
                    setShowDeleteDialog(true);
                  } else {
                    setShowDeleteConfirmDialog(true);
                  }
                }}
              ></Table>

              <footer className="w-full flex items-center justify-between bg-palette-action-hover border-t border-palette-action-selected py-1 px-4">
                <span>
                  {
                    countTotalRows > 0
                      ? `Toplam kayıt: ${countTotalRows.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
                      : 'Kayıt bulunamadı'
                  }
                </span>

                <Pagination
                  page={PAGE}
                  countPages={COUNT_PAGES}
                  onSetPage={(page) => handleChangePage(page)}
                />
              </footer>
            </div>

            {showDetail && (
              <>
                {/* main.resizer */}
                <div
                  className={clsx('relative flex items-center justify-center', {
                    'w-4 h-full cursor-col-resize space-x-2px flex-row rounded-r-full': settings.tableOnLeft,
                    'h-4 w-full cursor-row-resize space-y-2px flex-col rounded-b-full': !settings.tableOnLeft,
                  })}
                  onMouseDown={handleResize}
                >
                  {[0, 1].map((x) =>
                    <span
                      key={x}
                      className={clsx('block bg-palette-action-disabled', {
                        'w-px h-6': settings.tableOnLeft,
                        'h-px w-6': !settings.tableOnLeft,
                      })}
                    />
                  )}
                </div>

                {/* detail.container */}
                <Slide in direction={settings.tableOnLeft ? 'left' : 'up'} mountOnEnter unmountOnExit ref={detailRef}>
                  <main
                    className={clsx('flex-1 w-full h-full overflow-hidden pr-4 pb-4 relative', {
                      'pt-4': settings.tableOnLeft,
                      'pl-4': !settings.tableOnLeft,
                    })}
                  >
                    <props.detailComponent
                      id={selectedRow?.id}
                      addnew={addnew}

                      disablePreviousButton={showProgress || (IS_FIRST_PAGE && selectedRowIndex === 0)}
                      disableNextButton={showProgress || (IS_LAST_PAGE && selectedRowIndex === (rows.length - 1))}

                      onGoPrevious={handleSelectPreviousRow}
                      onGoNext={handleSelectNextRow}

                      onAdded={() => {
                        setRowsLastUpdateTime(Date.now());
                        setDefaultSelectedIndex(0);
                        handleChangePage(null);
                        setAddNew(false);
                        setEventType(1);
                        if (rows.length === 1) {
                          setShowDetail(false);
                        }
                      }}

                      onUpdated={() => {
                        setRowsLastUpdateTime(Date.now());
                        setDefaultSelectedIndex(selectedRowIndex);
                        setAddNew(false);
                      }}

                      onDeleted={() => handleRemoveRowByIndex(selectedRowIndex)}

                      onDisableClose={(status) => setDisableCloseDetail(status)}

                      onClose={() => {
                        confirmCloseDetail(() => {
                          setShowDetail(false);
                          setAddNew(false);
                        });
                      }}
                      eventType={eventType}
                    />
                    <div
                      className={clsx('absolute tracking-widest text-xs pointer-events-none', {
                        'top-2/4 -left-4 transform rotate-90 -translate-y-2/4': settings.tableOnLeft && detailRef.current?.clientWidth === 16,
                        'top-0 left-2/4 transform -translate-x-2/4': !settings.tableOnLeft && detailRef.current?.clientHeight === 16,
                        'hidden': (settings.tableOnLeft && detailRef.current?.clientWidth !== 16) || (!settings.tableOnLeft && detailRef.current?.clientHeight !== 16),
                      })}
                    >
                      DETAY
                    </div>
                  </main>
                </Slide>
                {/* <div
                  className={clsx('absolute tracking-widest text-xs pointer-events-none', {
                    'top-2/4 right-0 transform rotate-90 -translate-y-2/4': settings.tableOnLeft && detailRef.current?.clientWidth === 16,
                    'bottom-0 left-2/4 transform -translate-x-2/4': !settings.tableOnLeft && detailRef.current?.clientHeight === 16,
                    'flex-none': (settings.tableOnLeft && detailRef.current?.clientWidth !== 16) || (!settings.tableOnLeft && detailRef.current?.clientHeight !== 16),
                  })}
                >
                  DETAY
                </div> */}
              </>
            )}
          </div>
        </div>
      </div >

      {showDeleteConfirmDialog &&
        <DialogExtraConfirm
          title="Onay"
          message="Bu temayı silmeniz halinde, temayı kullanan site bu işlemden geri dönüşü olmayacak şekilde etkilenecektir."
          cancelText="VAZGEÇ"
          submitText="ONAYLA"
          confirmText="Okudum,anlamdım,onaylıyorum."
          submitButtonProps={{ color: 'secondary' }}
          onSubmit={() => {
            setDeleteConfirm(false);
            setShowDeleteConfirmDialog(false);
            setShowDeleteDialog(true);
          }}
          onCancel={() => {
            setDeleteConfirm(false);
            setShowDeleteConfirmDialog(false);
          }}
          onConfirm={() => setDeleteConfirm(!deleteConfirm)}
          isConfirm={deleteConfirm}
        ></DialogExtraConfirm>
      }

      {
        showDeleteDialog && (
          <DialogConfirm
            title={props.path !== 'evrak' ? 'Sil' : 'İptal'}
            message={
              isDeletingErrorMessage
                ? isDeletingErrorMessage
                : isDeleting
                  ? props.path !== 'evrak' ? 'Seçtiğiniz satır siliniyor lütfen bekleyin' : 'Seçtiğiniz satır iptal ediliyor lütfen bekleyin'
                  : props.path !== 'evrak' ? 'Bu satırı gerçekten silmek istiyor musunuz?' : 'Bu satırı gerçekten iptal etmek istiyor musunuz?'
            }
            cancelText="VAZGEÇ"
            submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : props.path !== 'evrak' ? 'SİL' : 'İPTAL ET'}
            submittingText={props.path !== 'evrak' ? "SİLİNİYOR" : 'İPTAL EDİLİYOR'}
            submitButtonProps={{ color: 'secondary' }}
            isSubmitting={isDeleting}
            onSubmit={handleDeleteSelectedRow}
            onCancel={() => setTimeout(() => setShowDeleteDialog(false))}
          ></DialogConfirm>
        )
      }

      {
        showColumnEditor && (
          <ColumnEditor
            anchorEl={columnEditorButtonRef?.current}
            columns={columns}
            onUpdatedColumns={(updatedColumns) => setColumns(updatedColumns)}
            onResetColumns={() => setColumns(props.columnsDefault)}
            onClose={() => setShowColumnEditor(!showColumnEditor)}
          >
          </ColumnEditor>
        )
      }
    </>
  );
};

LayoutTableDetail.propTypes = {
  //title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  pagefilter: PropTypes.string,
  blockedFilter: PropTypes.bool,
  columnsDefault: PropTypes.array,
  detailComponent: PropTypes.elementType.isRequired,
};

LayoutTableDetail.defaultProps = {
  columnsDefault: [],
};

export default LayoutTableDetail;
