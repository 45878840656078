import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../base/LayoutTableDetail';
import Detail from './OdemeDetail';
import django from '../../../api/django';


const COLUMNS_DEFAULT = [
  { id: 'evrak_tarihi',               tableProp: 'evrak_tarihi',                  filterProp: 'evrak_tarihi',               label: 'Evrak Tarihi',          element: 'input',           type: 'datetime', arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'kodu',                       tableProp: 'kodu',                          filterProp: 'kodu',                       label: 'Tahsilat No',           element: 'input',           type: 'text',     arrayTableProp: null, show: true, sortable: true,   highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'versiyon',                   tableProp: 'versiyon',                      filterProp: 'versiyon',                   label: 'Versiyon',              element: 'input',           type: 'number',   arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'tip',                        tableProp: 'tip.value',                     filterProp: 'tip',                        label: 'Evrak Tipi',            element: 'select',          type: 'number',   arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'iptal',                      tableProp: 'iptal',                         filterProp: 'iptal',                      label: 'İptal Durum',           element: 'select',          type: 'boolean',  arrayTableProp: null, show: true, sortable: false,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value', openFilter: false },
  { id: 'evrak_kullanici.first_name', tableProp: 'evrak_kullanici.first_name',    filterProp: 'evrak_kullanici.first_name', label: 'Cari Unvan/Adı',        element: 'input',           type: 'text',     arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'evrak_kullanici.last_name',  tableProp: 'evrak_kullanici.last_name',     filterProp: 'evrak_kullanici.last_name',  label: 'Cari Soyadı',           element: 'input',           type: 'text',     arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'genel_toplam',               tableProp: 'genel_toplam',                  filterProp: 'genel_toplam',               label: 'Genel Toplam',          element: 'input',           type: 'number',   arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'evrak_yili',                 tableProp: 'evrak_yili',                    filterProp: 'evrak_yili',                 label: 'Evrak Yılı',            element: 'input',           type: 'number',   arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'evrak_kur',                  tableProp: 'evrak_kur',                     filterProp: 'evrak_kur',                  label: 'Evrak Kuru',            element: 'input',           type: 'number',   arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'para_birimi',                tableProp: 'para_birimi.tanim',             filterProp: 'para_birimi.id',             label: 'Evrak Para Birimi',     element: 'select',          type: 'number',   arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'id', selectValue: 'tanim' },
  { id: 'olusturan_kullanici',        tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',     label: 'Oluşturan Kullanıcı',   element: 'select-multiple', type: 'number',   arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null, selectValue: null },
  { id: 'degistiren_kullanici',       tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',    label: 'Değiştiren Kullanıcı',  element: 'select-multiple', type: 'number',   arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null, selectValue: null },
  { id: 'olusturma_zamani',           tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',           label: 'Oluşturma',             element: 'input',           type: 'datetime', arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
  { id: 'degistirme_zamani',          tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',          label: 'Son Değişiklik',        element: 'input',           type: 'datetime', arrayTableProp: null, show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null, selectValue: null },
];


const TahsilatList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];
    const selectOptions2 = [
      { key: true, value: 'İptal' },
      { key: false, value: 'Aktif' },
    ];
    const selectOptions3 = [
      { key: 16, value: 'Ödeme' }
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean' && column.id !== 'iptal') {
          return {
            ...column,
            selectOptions: selectOptions,
          };
        }
        if (column.id === 'iptal') {
          return {
            ...column,
            selectOptions: selectOptions2,
          };
        }
        if (column.id === 'tip') {
          return {
            ...column,
            selectOptions: selectOptions3,
          };
        }
        return column;
      });
    });
  }, []);

  useLayoutEffect(() => {
    django('parabirim/mini_list').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'para_birimi') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  return (
    <>
      <Layout
        title="ÖDEMELER"
        path="evrak"
        columnsDefault={columnsDefault}
        pagefilter="?tip=16"
        blockedFilter={true}
        detailComponent={Detail}
        storekey="tahsilat"
        cancelpath="evrak/odeme"
      />
    </>
  )
}
export default TahsilatList;