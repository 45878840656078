import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
  assign as _assign
} from 'lodash';

import moment from 'moment';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';

import TabGenel from './BilesenDetailTab_Genel';
import TabMenu from './BilesenDetailTab_Menu';
import TabFiltre from './BilesenDetailTab_Filtre';
import TabUrun from './BilesenDetailTab_Urun';
import TabAfis from './BilesenDetailTab_Afis';
import TabAfisSet from './BilesenDetailTab_AfisSet';
import TabKategori from './BilesenDetailTab_Kategori';

const PATH = 'bilesen';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'BİLEŞEN', disabled: false, hasError: false },
  { label: 'BANNER SET', disabled: false, hasError: false, display: true }
];

const FORM_DATA_DEFAULT = {
  tanim: '',
  baslik: '',
  bilesen_tip: '0',
  temp: '-1',
  mobile_temp: '-1',
  bolumler: [],
  mobil_bolumler: [],
  siralama: '0',
  aktif: true,
};

const FORM_DATA_URUN = {
  urun_sayisi: '',
  kategoriye_gore: false,
  ozellikdegere_gore: false,
  karisik_siralama: false,
  bilesen_tip: '0',
  kategori: null,
  grup: null,
  ozellik_deger: null,
  ozellik_baslik: null,
  treeList: [],
  ayarlar: {
    auto_slide: true,
    display_type: '1',
    // effect_type:'0',
    wait_time: '',
    vertical_horizontal: '1',
    show_item_count: ''
  }
}

const FORM_DATA_MENU = {
  menu: null,
}

const FORM_DATA_FILTRE = {
  ozellik_baslik_tum: false,
  kategoriler: [],
  ozellik_basliklar: []
}

const FORM_DATA_KATEGORI = {
  bilesen_tip: "-1",
  urun_sayisi_goster: "-1"
}

const FORM_DATA_AFIS = {
  ayarlar: {
    auto_slide: true,
    banner_type: '1',
    // effect_type:'0',
    wait_time: '',
    vertical_horizontal: '1',
    show_item_count: ''
  }
}

const FORM_DATA_AFIS_SET = {
  form: {
    tanim: 'Tanımsız',
    yazi_tipi_ayarlari: {
      family_name: 'Arial, Helvetica, sans-serif',
      size: '12',
      type: 'normal',
      weight: '400'
    },
    dugme_ayarlari: {
      "color": '#000',
      "text_color": '#fff',
      "position": 'position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);'
    },
    baslangic_tarihi: null,
    bitis_tarihi: null,
    link: '',
    foto: {},
    diller: []
  },
  errors: null
}

const DILLER_FORM_DEFAULT = {
  dil_id: '',
  baslik: '',
  yazi: '',
  buton_yazi: ''
};

const FONT_FAMILY_LIST = [
  'Verdana, Geneva, sans-serif',
  'Georgia, "Times New Roman", Times, serif',
  '"Courier New", Courier, monospace',
  'Arial, Helvetica, sans-serif',
  'Tahoma, Geneva, sans-serif',
  '"Trebuchet MS", Arial, Helvetica, sans-serif',
  '"Arial Black", Gadget, sans-serif',
  '"Times New Roman", Times, serif',
  '"Palatino Linotype", "Book Antiqua", Palatino, serif',
  '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
  '"MS Serif", "New York", serif',
  '"Comic Sans MS", cursive',
  '"Ink Free", Times, serif',
  '"Segoe Print", Times, serif',
  '"Verdana", Times, serif',
  '"Gabriola", Times, serif'
];

const FONT_STYLE_LIST = [
  { key: 'normal', value: 'Normal' },
  { key: 'italic', value: 'İtalik' },
  { key: 'oblique', value: 'Eğik' },
];

const FONT_WEIGHT_LIST = ['100', '200', '300', '400', '500', '600', '700', '800', '900'];

const BUTTON_POSITION = [
  { key: 'position: absolute;left: 0;top: 0;', value: 'Sol-Üst' },
  { key: 'position: absolute;left: 0;top: 50%;transform: translateY(-50%);', value: 'Sol-Orta' },
  { key: 'position: absolute;left: 0;bottom: 0;', value: 'Sol-Alt' },
  { key: 'position: absolute;left: 50%;top: 0;transform: translateX(-50%);', value: 'Orta-Üst' },
  { key: 'position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);', value: 'Orta-Orta' },
  { key: 'position: absolute;left: 50%;bottom: 0;transform: translateX(-50%);', value: 'Orta-Alt' },
  { key: 'position: absolute;right: 0;top: 0;', value: 'Sağ-Üst' },
  { key: 'position: absolute;right: 0;top: 50%;transform: translateY(-50%);', value: 'Sağ-Orta' },
  { key: 'position: absolute;right: 0;bottom: 0;', value: 'Sağ-Alt' },
];

const BUTTON_DIC = {
  'position: absolute;left: 0;top: 0;': 'Sol-Üst',
  'position: absolute;left: 0;top: 50%;transform: translateY(-50%);': 'Sol-Orta',
  'position: absolute;left: 0;bottom: 0;': 'Sol-Üst',
  'position: absolute;left: 50%;top: 0;transform: translateX(-50%);': 'Orta-Üst',
  'position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);': 'Orta-Orta',
  'position: absolute;left: 50%;bottom: 0;transform: translateX(-50%);': 'Orta-Alt',
  'position: absolute;right: 0;top: 0;': 'Sağ-Üst',
  'position: absolute;right: 0;top: 50%;transform: translateY(-50%);': 'Sağ-Orta',
  'position: absolute;right: 0;bottom: 0;': 'Sağ-Alt',
}

const createDillerStatesAfis = async (diller, afisdiller) => {
  const states = [];
  diller.sort((a, b,) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...DILLER_FORM_DEFAULT,
        dil_id: dil.id,
      },
      errors: null,
    };

    const afisdil = afisdiller?.find((ad) => ad.dil_id === dil.id);

    if (afisdil) {
      data.form.dil_id = afisdil.dil_id;
      data.form.baslik = afisdil.baslik;
      data.form.yazi = afisdil.yazi;
      data.form.buton_yazi = afisdil.buton_yazi;
    }
    states.push(data);
  });
  return states;
};

const BilesenDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingMenu, setLoadingMenu] = useState(true);
  const [loadingFiltre, setLoadingFiltre] = useState(true);
  const [loadingKategori, setLoadingKategori] = useState(true);
  const [loadingUrun, setLoadingUrun] = useState(true);
  const [loadingAfis, setLoadingAfis] = useState(true);
  const [loadingAfisSet, setLoadingAfisSet] = useState(true);
  const [loadingAfisDil, setLoadingAfisDil] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataMenu, setHataMenu] = useState(null);
  const [hataFiltre, setHataFiltre] = useState(null);
  const [hataKategori, setHataKategori] = useState(null);
  const [hataUrun, setHataUrun] = useState(null);
  const [hataAfis, setHataAfis] = useState(null);
  const [hataAfisSet, setHataAfisSet] = useState(null);
  const [hataAfisDil, setHataAfisDil] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [urunForm, setUrunForm] = useState(FORM_DATA_URUN);
  const [urunFormDefault, setUrunFormDefault] = useState(FORM_DATA_URUN);
  const [urunFormError, setUrunFormError] = useState(null);
  const [urunBilesenTip, seturunBilesenTip] = useState([]);
  const [urunBilesenTipDic, seturunBilesenTipDic] = useState([]);

  const [menuForm, setMenuForm] = useState(FORM_DATA_MENU);
  const [menuFormDefault, setMenuFormDefault] = useState(FORM_DATA_MENU);
  const [menuFormError, setMenuFormError] = useState(null);

  const [filtreForm, setFiltreForm] = useState(FORM_DATA_FILTRE);
  const [filtreFormDefault, setFiltreFormDefault] = useState(FORM_DATA_FILTRE);

  const [kategoriForm, setKategoriForm] = useState(FORM_DATA_KATEGORI);
  const [kategoriFormDefault, setKategoriFormDefault] = useState(FORM_DATA_KATEGORI);
  const [kategoriFormError, setKategoriFormError] = useState(null);
  const [kategoriBilesenTip, setKategoriBilesenTip] = useState([]);
  const [kategoriBilesenTipDic, setKategoriBilesenTipDic] = useState({});
  const [kategoriSayiTip, setKategoriSayiTip] = useState([]);
  const [kategoriSayiTipDic, setKategoriSayiTipDic] = useState({});

  const [afisForm, setAfisForm] = useState(FORM_DATA_AFIS);
  const [afisFormDefault, setAfisFormDefault] = useState(FORM_DATA_AFIS);
  const [afisFormError, setAfisFormError] = useState([]);

  const [afisSetSelectedTabIndex, setAfisSetFormSelectedTabIndex] = useState(0);
  const [afisSetForm, setAfisSetForm] = useState([]);
  const [afisSetFormDefault, setAfisSetFormDefault] = useState([]);
  const [afisSetAdd, setAfisSetAdd] = useState(true);

  const [afisDillerSelectedTabIndex] = useState(0);
  const [afisDillerFormDefault, setAfisDillerFormDefault] = useState(null);
  const [afisDillerForm, setAfisDillerForm] = useState(null);
  const [dilMiniList, setDilMiniList] = useState([]);
  const [afisSetDilleri, setAfisSetDilleri] = useState([]);

  const [bilesenTip, setBilesenTip] = useState([]);
  const [bilesenTipDic, setBilesenTipDic] = useState(null);
  const [tempList, setTemplist] = useState([]);
  const [tempListFilter, setTemplistFilter] = useState([]);
  const [tempListFilterMobil, setTemplistFilterMobil] = useState([]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteId, setDeleteIdDetail] = useState(null);
  const [showDeleteDialogDetail, setShowDeleteDialogDetail] = useState(false);
  const [isDeletingDetail, setIsDeletingDetail] = useState(false);
  const [isDeletingErrorMessageDetail, setIsDeletingErrorMessageDetail] = useState(null);

  const [type, setType] = useState(props.eventType ?? 0);
  const [loadDone, setLoadDone] = useState(false);
  const [loadTab1, setLoadTab1] = useState(false);
  const [loadTab2, setLoadTab2] = useState(false);
  const [loadTab3, setLoadTab3] = useState(false);
  const [equal1, setEqual1] = useState(true);
  const [equal2, setEqual2] = useState(true);
  const [equal3, setEqual3] = useState(true);
  const [saveErrors, setSaveErrors] = useState([[], [], []]);

  const BOLUMINFO = useMemo(() => props.addNewInfo, [props]);
  const ID = useMemo(() => props.id, [props]);
  const AFIS_ID = useMemo(() => afisForm?.id, [afisForm]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DATA_DEFAULT) && _isEqual(urunForm, FORM_DATA_URUN) && _isEqual(menuForm, FORM_DATA_MENU) && _isEqual(filtreForm, FORM_DATA_FILTRE) && _isEqual(kategoriForm, FORM_DATA_KATEGORI) && _isEqual(afisForm, FORM_DATA_AFIS) && _isEqual(afisSetForm, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualUrun = _isEqual(urunForm, urunFormDefault);
    const isEqualMenu = _isEqual(menuForm, menuFormDefault);
    const isEqualFiltre = _isEqual(filtreForm, filtreFormDefault);
    const isEqualKategori = _isEqual(kategoriForm, kategoriFormDefault);
    const isEqualAfis = _isEqual(afisForm, afisFormDefault);
    const isEqualAfisSet = _isEqual(afisSetForm, afisSetFormDefault);
    const isEqualAfisSetDiller = _isEqual(afisDillerForm, afisDillerFormDefault);

    return isEqualGenel && isEqualUrun && isEqualMenu && isEqualFiltre && isEqualKategori && isEqualAfis && isEqualAfisSet && isEqualAfisSetDiller;
  }, [add, genelForm, genelFormDefault, urunForm, urunFormDefault, menuForm, menuFormDefault, filtreForm, filtreFormDefault, kategoriForm, kategoriFormDefault, afisForm, afisFormDefault, afisSetForm, afisSetFormDefault, afisDillerForm, afisDillerFormDefault]);

  const LOADINGAFISSET = useMemo(() => {
    if (!loadingAfisSet && !loadingAfisDil) {
      return false;
    }
    return true;
  }, [loadingAfisSet, loadingAfisDil]);

  useLayoutEffect(() => {
    setAdd(false);
    setLoadDone(false);
  }, [ID]);

  useLayoutEffect(() => {
    if (loadDone) {
      setType(2);
    } else {
      setType(props.eventType ?? 0);
    }
  }, [props.eventType, loadDone]);

  useLayoutEffect(() => {
    if (type === 0) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setUrunForm(FORM_DATA_URUN);
      setUrunFormDefault(FORM_DATA_URUN);
      setMenuForm(FORM_DATA_MENU);
      setMenuFormDefault(FORM_DATA_MENU);
      setFiltreForm(FORM_DATA_FILTRE);
      setFiltreFormDefault(FORM_DATA_FILTRE);
      setKategoriForm(FORM_DATA_KATEGORI);
      setKategoriFormDefault(FORM_DATA_KATEGORI);
      setAfisForm(FORM_DATA_AFIS);
      setAfisFormDefault(FORM_DATA_AFIS);
      setAfisSetForm([]);
      setAfisSetFormDefault([]);
      setTabs(TABS_DEFAULT);
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          setLoadTab2(true);
          break;
        case 2:
          setLoadTab1(true);
          setLoadTab3(true);
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else if (type === 1) {
      setGenelForm(null);
      setGenelFormDefault(null);
      if (!saveErrors[1].includes("err")) {
        setUrunForm(FORM_DATA_URUN);
        setUrunFormDefault(FORM_DATA_URUN);
        setMenuForm(FORM_DATA_MENU);
        setMenuFormDefault(FORM_DATA_MENU);
        setFiltreForm(FORM_DATA_FILTRE);
        setFiltreFormDefault(FORM_DATA_FILTRE);
        setKategoriForm(FORM_DATA_KATEGORI);
        setKategoriFormDefault(FORM_DATA_KATEGORI);
        setAfisForm(FORM_DATA_AFIS);
        setAfisFormDefault(FORM_DATA_AFIS);
      }
      if (!saveErrors[2].includes("err")) {
        setAfisSetForm([]);
        setAfisSetFormDefault([]);
      }
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          if (!saveErrors[1].includes("err")) {
            setLoadTab2(true);
          }
          break;
        case 2:
          setLoadTab1(true);
          if (!saveErrors[2].includes("err")) {
            setLoadTab3(true);
          }
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else {
      if (!add) {
        switch (selectedTabIndex) {
          case 0:
            if (equal1) {
              setLoadTab1(true);
            }
            break;
          case 1:
            if (equal2) {
              setLoadTab2(true);
            }
            break;
          case 2:
            if (equal3) {
              setLoadTab3(true);
            }
            break;
          default:
            break;
        }
      } else {
        setLoading(false);
        setLoadingMenu(false);
        setLoadingFiltre(false);
        setLoadingKategori(false);
        setLoadingUrun(false);
        setLoadingAfis(false);
        setLoadingAfisSet(false);
        setLoadingAfisDil(false);
        setLoadTab1(false);
        setLoadTab2(false);
        setLoadTab3(false);
      }
    }
  }, [type, selectedTabIndex, saveErrors, equal1, equal2, equal3, add]);

  useLayoutEffect(() => {
    if (add) {
      setEqual1(_isEqual(genelForm, FORM_DATA_DEFAULT));
    } else {
      setEqual1(_isEqual(genelForm, genelFormDefault));
    }
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    if (add) {
      switch (genelForm?.bilesen_tip) {
        case "0":
          setEqual2(_isEqual(urunForm, FORM_DATA_URUN));
          break;
        case "2":
          setEqual2(_isEqual(menuForm, FORM_DATA_MENU));
          break;
        case "3":
          setEqual2(_isEqual(filtreForm, FORM_DATA_FILTRE));
          break;
        case "4":
          setEqual2(_isEqual(afisForm, FORM_DATA_AFIS));
          break;
        case "5":
          setEqual2(_isEqual(kategoriForm, FORM_DATA_KATEGORI));
          break;
        default:
          break;
      }
    } else {
      switch (genelForm?.bilesen_tip) {
        case "0":
          setEqual2(_isEqual(urunForm, urunFormDefault));
          break;
        case "2":
          setEqual2(_isEqual(menuForm, menuFormDefault));
          break;
        case "3":
          setEqual2(_isEqual(filtreForm, filtreFormDefault));
          break;
        case "4":
          setEqual2(_isEqual(afisForm, afisFormDefault));
          break;
        case "5":
          setEqual2(_isEqual(kategoriForm, kategoriFormDefault));
          break;
        default:
          break;
      }
    }
  }, [add, urunForm, urunFormDefault, menuForm, menuFormDefault, filtreForm, filtreFormDefault, afisForm, afisFormDefault, kategoriForm, kategoriFormDefault, genelForm]);

  useLayoutEffect(() => {
    if (add) {
      setEqual3(_isEqual(afisSetForm, []));
    } else {
      setEqual3(_isEqual(afisSetForm, afisSetFormDefault) && _isEqual(afisDillerForm, afisDillerFormDefault));
    }
  }, [add, afisSetForm, afisSetFormDefault, afisDillerForm, afisDillerFormDefault]);

  useLayoutEffect(() => {
    if (loadTab1) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              baslik: data.baslik,
              bilesen_tip: String(data.bilesen_tip.key),
              temp: data.temp ?? "-1",
              mobile_temp: data.mobile_temp ?? "-1",
              bolumler: data.bolumler.map(x => x.id),
              mobil_bolumler: data.mobil_bolumler.map(x => x.id),
              siralama: String(data.siralama),
              aktif: data.aktif,
            };
            setGenelFormDefault(form);
            setGenelForm(form);
            setHataGenel(null);
            if (String(data.bilesen_tip.key) === '4') {
              setTabs((prev) => {
                return immer(prev, (next) => {
                  next[2].display = false;
                });
              });
            } else {
              setSelectedTabIndex(0);
            }
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setLoadTab1(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DATA_DEFAULT);
        setGenelFormDefault(FORM_DATA_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
        setLoadTab1(false);
      }
    }
  }, [ID, IS_ADD, loadTab1]);

  useLayoutEffect(() => {
    django('bilesen/bilesen_tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setBilesenTipDic(dict);
      setBilesenTip(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (loadTab2 && genelForm?.bilesen_tip === '0') {
      setLoadingUrun(true);
      if (!IS_ADD || !add) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/urun-bilesen`).then(({ data }) => {
            if (data.length > 0) {
              const formdata = data[0];
              const form = {
                id: formdata.id,
                urun_sayisi: String(formdata.urun_sayisi),
                kategoriye_gore: formdata.kategoriye_gore,
                ozellikdegere_gore: formdata.ozellikdegere_gore,
                karisik_siralama: formdata.karisik_siralama,
                satilabilen_urunler_gelsin: formdata.satilabilen_urunler_gelsin,
                bilesen_tip: String(formdata.bilesen_tip.key),
                kategori: formdata.kategori,
                grup: formdata.grup,
                ozellik_deger: formdata.ozellik_deger ? { id: formdata.ozellik_deger.id, tanim: formdata.ozellik_deger.tanim, ozellik_baslik_id: formdata.ozellik_deger.ozellikbaslik.id, ozellik_baslik_tanim: formdata.ozellik_deger.ozellikbaslik.tanim } : null,
                ozellik_baslik: formdata.ozellik_deger ? formdata.ozellik_deger.ozellikbaslik : null,
                treeList: formdata.ozellik_deger ? [{ title: formdata.ozellik_deger.ozellikbaslik.tanim, list: [{ key: formdata.ozellik_deger.id, value: formdata.ozellik_deger.tanim }] }] : [],
                ayarlar: {
                  auto_slide: formdata.ayarlar.auto_slide ?? true,
                  // effect_type: formdata.ayarlar.effect_type??,
                  display_type: String(formdata.ayarlar.display_type ?? '1'),
                  wait_time: String(formdata.ayarlar.wait_time ?? ''),
                  vertical_horizontal: String(formdata.ayarlar.vertical_horizontal),
                  show_item_count: String(formdata.ayarlar.show_item_count ?? ''),
                },
              };

              setUrunForm(form);
              setUrunFormDefault(form);
            } else {
              setUrunForm(FORM_DATA_URUN);
              setUrunFormDefault(FORM_DATA_URUN);
            }
            setHataUrun(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataUrun(true);
              }
            }
          }).finally(() => {
            setLoadingUrun(false);
            setUrunFormError(null);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setUrunForm(FORM_DATA_URUN);
        setUrunFormDefault(FORM_DATA_URUN);
        setHataUrun(null);
        setUrunFormError(null);
        setLoadingUrun(false);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, genelForm, loadTab2, add]);

  useLayoutEffect(() => {
    django(`${PATH}/urun_bilesen_bilesen_tip`).then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      seturunBilesenTipDic(dict);
      seturunBilesenTip(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (loadTab2 && genelForm?.bilesen_tip === '2') {
      setLoadingMenu(true);
      if (!IS_ADD || !add) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/menu-bilesen`).then(({ data }) => {
            if (data.length > 0) {
              const formdata = data[0];
              const form = {
                id: formdata.id,
                menu: formdata.menu,
              };
              setMenuForm(form);
              setMenuFormDefault(form);
            } else {
              setMenuForm(FORM_DATA_MENU);
              setMenuFormDefault(FORM_DATA_MENU);
            }
            setHataMenu(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataMenu(true);
              }
            }
          }).finally(() => {
            setLoadingMenu(false);
            setMenuFormError(null);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setMenuForm(FORM_DATA_MENU);
        setMenuFormDefault(FORM_DATA_MENU);
        setHataMenu(null);
        setLoadingMenu(false);
        setMenuFormError(null);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, genelForm, loadTab2, add]);

  useLayoutEffect(() => {
    if (loadTab2 && genelForm?.bilesen_tip === '3') {
      setLoadingFiltre(true);
      if (!IS_ADD || !add) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/filtre-bilesen`).then(({ data }) => {
            if (data.length > 0) {
              const formdata = data[0];
              const form = {
                id: formdata.id,
                ozellik_baslik_tum: formdata.ozellik_baslik_tum,
                kategoriler: formdata.kategoriler,
                ozellik_basliklar: formdata.ozellik_basliklar,
              };
              setFiltreForm(form);
              setFiltreFormDefault(form);
            } else {
              setFiltreForm(FORM_DATA_FILTRE);
              setFiltreFormDefault(FORM_DATA_FILTRE);
            }
            setHataFiltre(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataFiltre(true);
              }
            }
          }).finally(() => {
            setLoadingFiltre(false);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setFiltreForm(FORM_DATA_FILTRE);
        setFiltreFormDefault(FORM_DATA_FILTRE);
        setHataFiltre(null);
        setLoadingFiltre(false);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, genelForm, loadTab2, add]);

  useLayoutEffect(() => {
    if (loadTab2 && genelForm?.bilesen_tip === '5') {
      setLoadingKategori(true);
      if (!IS_ADD || !add) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/kategori-bilesen`).then(({ data }) => {
            if (data.length > 0) {
              const formdata = data[0];
              const form = {
                id: formdata.id,
                bilesen_tip: String(formdata.bilesen_tip),
                urun_sayisi_goster: String(formdata.urun_sayisi_goster),
              };
              setKategoriForm(form);
              setKategoriFormDefault(form);
            } else {
              setKategoriForm(FORM_DATA_KATEGORI);
              setKategoriFormDefault(FORM_DATA_KATEGORI);
            }
            setHataKategori(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataKategori(true);
              }
            }
          }).finally(() => {
            setLoadingKategori(false);
            setKategoriFormError(null);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setKategoriForm(FORM_DATA_KATEGORI);
        setKategoriFormDefault(FORM_DATA_KATEGORI);
        setHataKategori(null);
        setLoadingKategori(false);
        setKategoriFormError(null);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, genelForm, loadTab2, add]);

  useLayoutEffect(() => {
    django(`${PATH}/kategori_bilesen_bilesen_tip`).then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setKategoriBilesenTip(data);
      setKategoriBilesenTipDic(dict)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django(`${PATH}/kategori_bilesen_urun_sayisi_goster_tip/`).then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setKategoriSayiTip(data);
      setKategoriSayiTipDic(dict)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (loadTab2 && genelForm?.bilesen_tip === '4') {
      setLoadingAfis(true);
      if (!IS_ADD || !add) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/afis_bilesen`).then(({ data }) => {
            if (data.length > 0) {
              const formdata = data[0];
              const form = {
                id: formdata.id,
                ayarlar: {
                  auto_slide: formdata.ayarlar.auto_slide,
                  // effect_type: formdata.ayarlar.effect_type??,
                  banner_type: String(formdata.ayarlar.banner_type ?? '1'),
                  wait_time: String(formdata.ayarlar.wait_time ?? ''),
                  vertical_horizontal: String(formdata.ayarlar.vertical_horizontal),
                  show_item_count: String(formdata.ayarlar.show_item_count ?? ''),
                },
              };
              setAfisForm(form);
              setAfisFormDefault(form);
            } else {
              setAfisForm(FORM_DATA_AFIS);
              setAfisFormDefault(FORM_DATA_AFIS);
            }
            setAfisFormError(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataAfis(true);
              }
            }
          }).finally(() => {
            setLoadingAfis(false);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setAfisForm(FORM_DATA_AFIS);
        setAfisFormDefault(FORM_DATA_AFIS);
        setHataAfis(true);
        setLoadingAfis(false);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, genelForm, loadTab2, add]);

  useLayoutEffect(() => {
    if (loadTab3) {
      setLoadingAfisSet(true);
      if (!IS_ADD || !add) {
        if (AFIS_ID) {
          const debounce = setTimeout(() => {
            django(`${PATH}/${ID}/afis_bilesen/${AFIS_ID}/afis`).then(({ data }) => {
              const forms = data.map((afis) => (
                {
                  form: {
                    id: afis.id,
                    tanim: afis.tanim,
                    diller: afis.diller,
                    yazi_tipi_ayarlari: afis.yazi_tipi_ayarlari,
                    dugme_ayarlari: afis.dugme_ayarlari,
                    baslangic_tarihi: afis.baslangic_tarihi ? moment(afis.baslangic_tarihi).format('YYYY-MM-DD[T]HH:mm') : null,
                    bitis_tarihi: afis.bitis_tarihi ? moment(afis.bitis_tarihi).format('YYYY-MM-DD[T]HH:mm') : null,
                    link: afis.link,
                    foto: afis.foto
                  },
                  errors: null
                }
              ))
              setAfisSetForm(forms);
              setAfisSetFormDefault(forms);
              setAfisSetDilleri(forms.map((afis) => afis.form.diller));
              setHataAfisSet(null);
            }).catch((error) => {
              if (error.response) {
                if (error.response.status === 500) {
                  setHataAfisSet(true);
                }
              }
            }).finally(() => {
              setLoadingAfisSet(false);
              setAfisSetAdd(true);
              setLoadTab3(false);
            });
          }, 300);
          return () => {
            clearTimeout(debounce);
          };
        }
      } else {
        setAfisSetForm([]);
        setAfisSetFormDefault([]);
        setHataAfisSet(null);
        setLoadingAfisSet(false);
        setLoadTab3(false);
      }
    }
  }, [ID, AFIS_ID, IS_ADD, loadTab3, add]);

  useLayoutEffect(() => {
    django('dil/mini_list?aktif=true').then(({ data }) => setDilMiniList(data)).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataAfisDil(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setLoadingAfisDil(true);
    const setDil = async () => {
      let diller = [];
      afisSetDilleri.forEach((dil) => {
        createDillerStatesAfis(dilMiniList, dil).then((states) => diller.push(states));
      })
      return diller;
    }
    setDil().then((state) => {
      setAfisDillerForm(state);
      setAfisDillerFormDefault(state);
    }).finally(() => setLoadingAfisDil(false));
  }, [dilMiniList, afisSetDilleri]);

  useLayoutEffect(() => {
    const getList = async (path, lastpage) => {
      let page = 1;
      let list = [];
      const data = (await django(`${path}?size=100&page=${page}&tanim=*component*`)).data;
      list = [...list, ...data];
      if (lastpage > page) {
        page++
        getList(path, lastpage);
      }
      return list;
    }
    const setList = async (list) => {
      let tempList = [];
      if (list.length > 0) {
        for (let li of list) {
          const count = (await django(`sablon_paket/${li.id}/arayuzsablon/count?tanim=*component*`)).data;
          if (count !== 0) {
            const res = await getList(`sablon_paket/${li.id}/arayuzsablon/mini_list`, Math.ceil(count / 100));
            const filtredList = res.filter((x) => x.tanim.includes(`${li.tanim}/component`) || x.tanim.includes(`${li.tanim}/mobile/component`));
            tempList = [...tempList, ...filtredList];
            tempList = tempList.filter((v, i, a) => a.findIndex(t => (t.tanim === v.tanim)) === i);
          }
        }
      }
      return tempList;
    }
    django('sablon_paket/mini_list?aktif=true').then(({ data }) => {
      setList(data).then((res) => {
        setTemplist(res);
      })
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataGenel(true);
        }
      }
    })
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (tempList.length > 0) {
      const tip = genelForm?.bilesen_tip;
      let list = [];
      let mobil_list = [];
      if (tip === '0') {
        list = tempList.filter((z) => z.tanim.includes('component/product') && !z.tanim.includes('mobile/component/product'));
        mobil_list = tempList.filter((z) => z.tanim.includes('mobile/component/product'));
      } else if (tip === '2') {
        list = tempList.filter((z) => z.tanim.includes('component/menu') && !z.tanim.includes('mobile/component/menu'));
        mobil_list = tempList.filter((z) => z.tanim.includes('mobile/component/menu'));
      } else if (tip === '3') {
        list = tempList.filter((z) => z.tanim.includes('component/filters') && !z.tanim.includes('mobile/component/filters'));
        mobil_list = tempList.filter((z) => z.tanim.includes('mobile/component/filters'));
      } else if (tip === '4') {
        list = tempList.filter((z) => z.tanim.includes('component/banner') && !z.tanim.includes('mobile/component/banner'));
        mobil_list = tempList.filter((z) => z.tanim.includes('mobile/component/banner'));
      } else {
        list = tempList.filter((z) => z.tanim.includes('component/categories') && !z.tanim.includes('mobile/component/categories'));
        mobil_list = tempList.filter((z) => z.tanim.includes('mobile/component/categories'));
      }
      setTemplistFilter(list);
      setTemplistFilterMobil(mobil_list);
    }
  }, [tempList, genelForm]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = async (property, value) => {
    if (property === 'bilesen_tip') {
      if (add) {
        if (value === '4') {
          setTabs((prev) => {
            return immer(prev, (next) => {
              next[2].display = false;
            });
          });
        } else {
          setTabs((prev) => {
            return immer(prev, (next) => {
              next[2].display = true;
            });
          });
        }
      }
    }
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleUrunChangeForm = (property, property2, value) => {
    if (property2 === '') {
      if (property === 'ozellik_deger') {
        setUrunForm((prev) => {
          return immer(prev, (next) => {
            next[property] = value;
            next['ozellik_baslik'] = value ? { id: value.ozellik_baslik_id, tanim: value.ozellik_baslik_tanim } : null;
            next['treeList'] = value ? [{ title: value.ozellik_baslik_tanim, list: [{ key: value.id, value: value.tanim }] }] : [];
          });
        });
      } else {
        setUrunForm((prev) => {
          return immer(prev, (next) => {
            next[property] = value;
          });
        });
      }
    } else {
      setUrunForm((prev) => {
        return immer(prev, (next) => {
          next[property][property2] = value;
        });
      });
    }
  };

  const handleMenuChangeForm = (property, value) => {
    setMenuForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleFiltreChangeForm = (property, value) => {
    setFiltreForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleKategoriChangeForm = (property, value) => {
    setKategoriForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleAfisChangeForm = (property, value) => {
    setAfisForm((prev) => {
      return immer(prev, (next) => {
        next['ayarlar'][property] = value;
      });
    });
  };

  const handleAfisSetChangeForm = (index, property, property2, value) => {
    if (property2 !== '') {
      setAfisSetForm((prev) => {
        return immer(prev, (next) => {
          next[index]["form"][property][property2] = value;
        });
      });
    } else {
      setAfisSetForm((prev) => {
        return immer(prev, (next) => {
          next[index]["form"][property] = value;
        });
      });
    }
  }

  const handleDillerChangeFormAfisSet = (index, index2, property, value) => {
    setAfisDillerForm((prev) => {
      return immer(prev, (next) => {
        next[index][index2].form[property] = value;
      });
    });
  }

  const handleAfisSetAddNewTab = () => {
    setAfisSetAdd(false);
    setAfisSetForm((prev) => immer(prev, (next) => {
      next.push({ ...FORM_DATA_AFIS_SET });
    }));
    setAfisSetDilleri((prev) => immer(prev, (next) => {
      next.push([]);
    }));
  }

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DATA_DEFAULT);
    setGenelError(null);
    setMenuForm(FORM_DATA_MENU);
    setMenuFormError(null);
    setUrunForm(FORM_DATA_URUN);
    setUrunFormError(null);
    setFiltreForm(FORM_DATA_FILTRE);
    setKategoriForm(FORM_DATA_KATEGORI);
    setKategoriFormError(null);
    setAfisForm(FORM_DATA_AFIS);
    setAfisFormError(null);
    setAfisSetForm([]);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[2].display = true;
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
    setLoadTab1(true);
    setLoadTab2(true);
    setLoadTab3(true);
    setAfisSetAdd(true);
    if (String(genelForm.bilesen_tip.key) === '4') {
      setTabs([
        { label: 'GENEL', disabled: false, hasError: false },
        { label: 'BİLEŞEN', disabled: false, hasError: false },
        { label: 'BANNER SET', disabled: false, hasError: false, display: false },
      ])
    } else {
      setSelectedTabIndex(0);
      setTabs(TABS_DEFAULT);
    }
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    setMenuForm(menuFormDefault);
    setMenuFormError(null);
    setUrunForm(urunFormDefault);
    setUrunFormError(null);
    setFiltreForm(filtreFormDefault);
    setKategoriForm(kategoriFormDefault);
    setKategoriFormError(null);
    setAfisForm(afisFormDefault);
    setAfisFormError(null);
    setAfisSetForm(afisSetFormDefault);
    setAfisSetDilleri(afisSetFormDefault.map((afis) => afis.form.diller));
    setAfisSetAdd(true);
    if (genelFormDefault.bilesen_tip === '4') {
      setTabs((prev) => {
        return immer(prev, (next) => {
          next[2].display = false;
          next[0].hasError = false;
          next[1].hasError = false;
          next[2].hasError = false;
        });
      });
    } else {
      setTabs((prev) => {
        return immer(prev, (next) => {
          next[2].display = true;
          next[0].hasError = false;
          next[1].hasError = false;
          next[2].hasError = false;
        });
      });
    }
    if (!IS_ADD) {
      setAdd(false);
    }
  };

  const checkAll = async (form, formdef, func, add, list) => {
    let errors = [];
    await checkAllList('bilesen', form, formdef, func, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }


  const addBolum = async () => {
    let newBolumId = null;
    if (IS_ADD) {
      if (!BOLUMINFO.bolumId) {
        const id = props.controlBolum(Number(BOLUMINFO.sayfaBolum));
        if (!id) {
          const data = { tanim: BOLUMINFO.tanim, sayfa_tip: BOLUMINFO.sayfaTip, sayfa_bolum: BOLUMINFO.sayfaBolum, site: BOLUMINFO.site, aktif: true };
          await django({ method: "POST", url: "bolum", data: data }).then(({ data }) => {
            newBolumId = data.id;
            props.reloadPage();
          }).catch(() => {
            enqueueSnackbar('Bileşen eklenirken beklenmeyen bir hata oluştu', { variant: 'error' });
          });
        } else {
          newBolumId = id;
        }
      } else {
        newBolumId = BOLUMINFO.bolumId;
      }
    }
    return newBolumId;
  }

  const addEkBilgi = async (id, newbolumid) => {
    let data = {
      bolum_sira: BOLUMINFO.count + 1,
      satir_sonu: false,
      bolum: BOLUMINFO.eklemeTip === "0" ? newbolumid : null,
      mobil_bolum: BOLUMINFO.eklemeTip === "1" ? newbolumid : null
    };
    await django({
      method: "POST",
      data: data,
      url: `bilesen/${id}/bilesen-ek-bilgi`
    });
  }

  const handleSave = async () => {
    const newbolumid = await addBolum();

    let list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Bileşen Tanım', pagename: 'bileşen', prop2: '' },
      { prop: 'baslik', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ];
    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      let form = { ...genelForm };
      if (newbolumid) {
        BOLUMINFO.eklemeTip === "0" ? form.bolumler = [...form.bolumler, newbolumid] : form.mobil_bolumler = [...form.mobil_bolumler, newbolumid]
      }
      if (BOLUMINFO.eklemeTip === "0") {
        list = [...list, { prop: 'temp', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' }];
      } else {
        list = [...list, { prop: 'mobile_temp', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' }];
      }

      if (add ? _isEqual(form, FORM_DATA_DEFAULT) : _isEqual(form, genelFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll(form, genelFormDefault, handleErrors, add, list).then((res) => {
          if (_isEmpty(res)) {
            const data = { ...form, siralama: form.siralama ? Number(form.siralama) : 0, bilesen_tip: Number(form.bilesen_tip), temp: form.temp !== '-1' ? form.temp : null, mobile_temp: form.mobile_temp !== '-1' ? form.mobile_temp : null, bolumler: form.bolumler, mobil_bolumler: form.mobil_bolumler }
            reqopt = {
              data: data,
              method: add ? 'POST' : 'PUT',
              id: null,
              successMessage: `Bilesen ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Bilesen ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabUrun = async () => {
      let reqopt = null;
      let err = null;
      const isHas = _has(urunForm, 'id');
      if (add ? _isEqual(urunForm, FORM_DATA_URUN) : _isEqual(urunForm, urunFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'urun_sayisi', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
          { prop: 'ayarlar', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'show_item_count' },
        ];
        await checkAll(urunForm, urunFormDefault, handleErrorsUrun, false, list).then((res) => {
          if (_isEmpty(res)) {
            const data = {
              ...urunForm, urun_sayisi: urunForm.urun_sayisi ? Number(urunForm.urun_sayisi) : null, bilesen_tip: Number(urunForm.bilesen_tip), kategori: urunForm.kategori?.id ?? null, grup: urunForm.grup?.id ?? null, ozellik_deger: urunForm.ozellik_deger?.id ?? null, ayarlar: {
                auto_slide: urunForm.ayarlar.auto_slide,
                display_type: Number(urunForm.ayarlar.display_type),
                // effect_type:afisForm.effect_type,
                wait_time: urunForm.ayarlar.wait_time ? Number(urunForm.ayarlar.wait_time) : null,
                vertical_horizontal: Number(urunForm.ayarlar.vertical_horizontal),
                show_item_count: urunForm.ayarlar.show_item_count ? Number(urunForm.ayarlar.show_item_count) : null,
              }
            };
            delete data.ozellik_baslik;
            delete data.treeList;
            if (isHas) {
              reqopt = {
                data: data,
                method: 'PUT',
                id: urunForm.id,
                successMessage: `Ürün bileşen güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Ürün bileşen güncellenirken beklenmeyen bir hata oluştu`,
              };
            } else {
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `Ürün bileşen ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Ürün bileşen eklenirken beklenmeyen bir hata oluştu`,
              };
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabMenu = async () => {
      let reqopt = null;
      let err = null;
      const isHas = _has(menuForm, 'id');
      if (add ? _isEqual(menuForm, FORM_DATA_MENU) : _isEqual(menuForm, menuFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'menu', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
        ];
        await checkAll(menuForm, menuFormDefault, handleErrorsMenu, false, list).then((res) => {
          if (_isEmpty(res)) {
            if (isHas) {
              reqopt = {
                data: { ...menuForm, menu: menuForm.menu?.id ?? null },
                method: 'PUT',
                id: menuForm.id,
                successMessage: `Menü bileşen güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Menü bileşen güncellenirken beklenmeyen bir hata oluştu`,
              };
            } else {
              reqopt = {
                data: { ...menuForm, menu: menuForm.menu?.id ?? null },
                method: 'POST',
                id: null,
                successMessage: `Menü bileşen ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Menü bileşen eklenirken beklenmeyen bir hata oluştu`,
              };
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabFiltre = async () => {
      let reqopt = null;
      let err = null;
      const isHas = _has(filtreForm, 'id');
      if (add ? _isEqual(filtreForm, FORM_DATA_FILTRE) : _isEqual(filtreForm, filtreFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        if (isHas) {
          reqopt = {
            data: { ...filtreForm, kategoriler: filtreForm.kategoriler?.map((data) => data.id), ozellik_basliklar: filtreForm.ozellik_basliklar?.map((data) => data.id) },
            method: 'PUT',
            id: filtreForm.id,
            successMessage: `Ürün filtre bileşen bileşen güncelleme başarıyla tamamlandı`,
            errorMessageUnexpected: `Ürün filtre bileşen bileşen güncellenirken beklenmeyen bir hata oluştu`,
          };
        } else {
          reqopt = {
            data: { ...filtreForm, kategoriler: filtreForm.kategoriler?.map((data) => data.id), ozellik_basliklar: filtreForm.ozellik_basliklar?.map((data) => data.id) },
            method: 'POST',
            id: null,
            successMessage: `Ürün filtre bileşen bileşen ekleme başarıyla tamamlandı`,
            errorMessageUnexpected: `Ürün filtre bileşen bileşen eklenirken beklenmeyen bir hata oluştu`,
          };
        }
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabKategori = async () => {
      let reqopt = null;
      let err = null;
      const isHas = _has(kategoriForm, 'id');
      if (add ? _isEqual(kategoriForm, FORM_DATA_KATEGORI) : _isEqual(kategoriForm, kategoriFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'bilesen_tip', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
          { prop: 'urun_sayisi_goster', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
        ];
        await checkAll(kategoriForm, kategoriFormDefault, handleErrorsMenu, false, list).then((res) => {
          if (_isEmpty(res)) {
            if (isHas) {
              reqopt = {
                data: { ...kategoriForm, bilesen_tip: kategoriForm.bilesen_tip !== "-1" ? Number(kategoriForm.bilesen_tip) : null, urun_sayisi_goster: kategoriForm.urun_sayisi_goster !== "-1" ? Number(kategoriForm.urun_sayisi_goster) : null },
                method: 'PUT',
                id: menuForm.id,
                successMessage: `Kategori bileşen güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Kategori bileşen güncellenirken beklenmeyen bir hata oluştu`,
              };
            } else {
              reqopt = {
                data: { ...kategoriForm, bilesen_tip: kategoriForm.bilesen_tip !== "-1" ? Number(kategoriForm.bilesen_tip) : null, urun_sayisi_goster: kategoriForm.urun_sayisi_goster !== "-1" ? Number(kategoriForm.urun_sayisi_goster) : null },
                method: 'POST',
                id: null,
                successMessage: `Kategori bileşen ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Kategori bileşen eklenirken beklenmeyen bir hata oluştu`,
              };
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabAfis = async () => {
      let reqopt = null;
      let err = null;
      const isHas = _has(afisForm, 'id');
      if (add ? _isEqual(afisForm, FORM_DATA_AFIS) : _isEqual(afisForm, afisFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'ayarlar', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: 'show_item_count' },
        ];
        await checkAll(afisForm, afisFormDefault, handleErrorsAfis, false, list).then((res) => {
          if (_isEmpty(res)) {
            if (isHas) {
              reqopt = {
                data: {
                  ayarlar: {
                    auto_slide: afisForm.ayarlar.auto_slide,
                    // effect_type:afisForm.effect_type,
                    banner_type: Number(afisForm.ayarlar.banner_type),
                    wait_time: afisForm.ayarlar.wait_time ? Number(afisForm.ayarlar.wait_time) : null,
                    vertical_horizontal: Number(afisForm.ayarlar.vertical_horizontal),
                    show_item_count: afisForm.ayarlar.show_item_count ? Number(afisForm.ayarlar.show_item_count) : null,
                  }
                },
                method: 'PUT',
                id: afisForm.id,
                successMessage: `Banner bileşen güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Banner bileşen güncellenirken beklenmeyen bir hata oluştu`,
              };
            } else {
              reqopt = {
                data: {
                  ayarlar: {
                    auto_slide: afisForm.ayarlar.auto_slide,
                    // effect_type:afisForm.effect_type,
                    banner_type: Number(afisForm.ayarlar.banner_type),
                    wait_time: afisForm.ayarlar.wait_time ? Number(afisForm.ayarlar.wait_time) : null,
                    vertical_horizontal: Number(afisForm.ayarlar.vertical_horizontal),
                    show_item_count: afisForm.ayarlar.show_item_count ? Number(afisForm.ayarlar.show_item_count) : null,
                  }
                },
                method: 'POST',
                id: null,
                successMessage: `Banner bileşen ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Banner bileşen eklenirken beklenmeyen bir hata oluştu`,
              };
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabAfisSet = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = afisSetFormDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next) && _isEqual(afisDillerForm[index], afisDillerFormDefault[index]);

      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'tanim', prop2: '' },
          { prop: 'foto', prop2: '' },
        ];
        await checkAllTabs(next.form, (err) => handleErrorsAfisSet(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const isHas = _has(next.form, 'id');
            const diller = afisDillerForm[index].filter((dil) => (!_isEmpty(dil.form.baslik) || !_isEmpty(dil.form.yazi) || !_isEmpty(dil.form.buton_yazi))).map((d) => d.form);
            const data = {
              ...next.form,
              diller: diller,
              foto: next.form.foto.id ?? null
            }
            if (isHas) {
              reqopt = {
                data: data,
                method: 'PUT',
                id: next.form.id,
                successMessage: `Banner set ${next.form.tanim} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Banner set ${next.form.tanim} güncellenirken beklenmeyen bir hata oluştu`,
              };
            } else {
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `Banner set ${next.form.tanim} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Banner set ${next.form.tanim} eklenirken beklenmeyen bir hata oluştu`,
              };
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    let errors = [[], [], []];

    let SAVEID = add ? null : ID;

    let SAVEAFISID = add ? null : AFIS_ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'bilesen', request: [], error: [] },
        { index: 2, name: 'bannerset', request: [], error: [] },
      ];

      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      if (genelForm.bilesen_tip === '0') {
        const res = await createRequestOptionsTabUrun();
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      } else if (genelForm.bilesen_tip === '2') {
        const res = await createRequestOptionsTabMenu();
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      } else if (genelForm.bilesen_tip === '3') {
        const res = await createRequestOptionsTabFiltre();
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      } else if (genelForm.bilesen_tip === '4') {
        const res = await createRequestOptionsTabAfis();
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
        for (const i in afisSetForm) {
          const afisstate = [...afisSetForm];
          const res = await createRequestOptionsTabAfisSet(afisstate[i], i)
          tabs[2].request.push(res[0]);
          tabs[2].error.push(res[1]);
        }
      } else {
        const res = await createRequestOptionsTabKategori();
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }



      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "bilesen" : `bilesen/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated(data.id);
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.bilesen.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data.id);
                }
                if (add) {
                  addEkBilgi(data.id, newbolumid);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (SAVEID !== null) {
              if (tab.name === 'bilesen') {
                if (genelForm.bilesen_tip === '0') {
                  setUrunFormError(null);
                } else if (genelForm.bilesen_tip === '2') {
                  setMenuFormError(null);
                } else if (genelForm.bilesen_tip === '4') {
                  setAfisFormError(null);
                } else if (genelForm.bilesen_tip === '5') {
                  setKategoriFormError(null);
                }
                await django({
                  method: request.method,
                  data: request.data,
                  url:
                    genelForm.bilesen_tip === '0' ? request.id ? `bilesen/${SAVEID}/urun-bilesen/${request.id}` : `bilesen/${SAVEID}/urun-bilesen` :
                      genelForm.bilesen_tip === '2' ? request.id ? `bilesen/${SAVEID}/menu-bilesen/${request.id}` : `bilesen/${SAVEID}/menu-bilesen` :
                        genelForm.bilesen_tip === '3' ? request.id ? `bilesen/${SAVEID}/filtre-bilesen/${request.id}` : `bilesen/${SAVEID}/filtre-bilesen` :
                          genelForm.bilesen_tip === '4' ? request.id ? `bilesen/${SAVEID}/afis_bilesen/${request.id}` : `bilesen/${SAVEID}/afis_bilesen` :
                            request.id ? `bilesen/${SAVEID}/kategori-bilesen/${request.id}` : `bilesen/${SAVEID}/kategori-bilesen`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  if (error.response) {
                    if (error.response.status === 500) {
                      enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                    } else {
                      if (genelForm.bilesen_tip === '0') {
                        setUrunFormError(error.response.data);
                      } else if (genelForm.bilesen_tip === '2') {
                        setMenuFormError(error.response.data);
                      } else if (genelForm.bilesen_tip === '4') {
                        setAfisFormError(error.response.data);
                      } else if (genelForm.bilesen_tip === '5') {
                        setKategoriFormError(error.response.data);
                      }
                    }
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
            if (SAVEID !== null && SAVEAFISID !== null) {
              if (tab.name === 'bannerset') {
                setAfisSetForm((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `bilesen/${SAVEID}/afis_bilesen/${SAVEAFISID}/afis/${request.id}` : `bilesen/${SAVEID}/afis_bilesen/${SAVEAFISID}/afis`
                }).then(() => {
                  setAfisSetAdd(true);
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setAfisSetAdd(false);
                  setSelectedTabIndex(tab.index);
                  setAfisSetFormSelectedTabIndex(requestIndex);
                  if (error.response) {
                    if (error.response.status === 500) {
                      enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                    } else {
                      setAfisSetForm((prev) => {
                        return immer(prev, (next) => {
                          next[requestIndex].errors = error.response.data;
                        })
                      });
                    }
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }

    await start();
    if (SAVEID !== null) {
      if (!add) {
        if (!errors[0].includes("err")) {
          setLoadTab1(true);
        }
        if (!errors[1].includes("err")) {
          setLoadTab2(true);
        }
        if (!errors[2].includes("err")) {
          setLoadTab3(true);
        }
      } else {
        setSaveErrors(errors);
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
      await checkAll(genelForm, genelFormDefault, handleErrors, add, list)
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? false : true : true;
        next[1].hasError = !errors[1].includes("err") ? false : true;
        next[2].hasError = !errors[2].includes("err") ? false : true;
      });
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);

    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';

    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);

        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.bilesen.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };
  const handleDeleteDetail = () => {
    setIsDeletingDetail(true);
    setIsDeletingErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/afis_bilesen/${AFIS_ID}/afis/${detailDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetail(false);
        setIsDeletingErrorMessageDetail(null);
      } else {
        setIsDeletingErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingDetail(false);
      setLoadTab3(true);
    });
  }

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }
  const handleErrorsUrun = (errors) => {
    setUrunFormError((prev) => {
      let obj = {}
      let next = {};
      if (Object.keys(errors)[0] === 'ayarlar') {
        if (_has({ ...prev }, Object.keys(errors)[0])) {
          _assign(prev[Object.keys(errors)[0]], errors[Object.keys(errors)[0]])
          obj = { ...prev }
        } else {
          obj = { ...prev, ...errors }
        }
      } else {
        obj = { ...prev, ...errors }
      }

      if (_has(obj, "ayarlar")) {
        const ayarlar = _pickBy(obj["ayarlar"], _identity);
        if (_isEmpty(ayarlar)) {
          next = _pickBy({ ...obj, 'ayarlar': null }, _identity);
        } else {
          next = _pickBy({ ...obj, 'ayarlar': ayarlar }, _identity);
        }
      } else {
        next = _pickBy(obj, _identity);
      }
      if (urunForm.kategori || urunForm.ozellik_deger || urunForm.grup) {
        delete next?.kategori;
        delete next?.ozellik_deger;
        delete next?.grup;
      }
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }
  const handleErrorsAfis = (errors) => {
    setAfisFormError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }
  const handleErrorsAfisSet = (errors, index) => {
    setAfisSetForm((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }
  const handleErrorsAfisSetDil = (errors, index, index2) => {
    setAfisDillerForm((prev) => {
      const err = _pickBy({ ...prev[index][index2]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index][index2]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }
  const handleErrorsMenu = (errors) => {
    setMenuFormError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }
  const handleErrorsKategori = (errors) => {
    setKategoriFormError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        hideAddButton={true}
        hidePrevNextAddButton={true}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            bilesenTip={bilesenTip}
            bilesenTipDic={bilesenTipDic}
            tempList={tempListFilter}
            tempListFilterMobil={tempListFilterMobil}
            onChangeForm={handleGenelChangeForm}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}

        {selectedTabIndex === 1 && genelForm?.bilesen_tip === '0' && (
          <TabUrun
            loading={loadingUrun}
            hata={hataUrun}

            form={urunForm}
            formdefault={urunFormDefault}
            error={urunFormError}
            urunBilesenTip={urunBilesenTip}
            urunBilesenTipDic={urunBilesenTipDic}
            onChangeForm={handleUrunChangeForm}

            onCheckErrors={(errors) => handleErrorsUrun(errors)}
          />
        )}

        {selectedTabIndex === 1 && genelForm?.bilesen_tip === '2' && (
          <TabMenu
            loading={loadingMenu}
            hata={hataMenu}

            form={menuForm}
            formdefault={menuFormDefault}
            error={menuFormError}
            onChangeForm={handleMenuChangeForm}

            onCheckErrors={(errors) => handleErrorsMenu(errors)}
          />
        )}

        {selectedTabIndex === 1 && genelForm?.bilesen_tip === '3' && (
          <TabFiltre
            loading={loadingFiltre}
            hata={hataFiltre}

            form={filtreForm}
            onChangeForm={handleFiltreChangeForm}
          />
        )}

        {selectedTabIndex === 1 && genelForm?.bilesen_tip === '5' && (
          <TabKategori
            loading={loadingKategori}
            hata={hataKategori}

            form={kategoriForm}
            formdefault={kategoriFormDefault}
            error={kategoriFormError}
            onChangeForm={handleKategoriChangeForm}
            kategoriBilesenTip={kategoriBilesenTip}
            kategoriBilesenTipDic={kategoriBilesenTipDic}
            kategoriSayiTip={kategoriSayiTip}
            kategoriSayiTipDic={kategoriSayiTipDic}

            onCheckErrors={(errors) => handleErrorsKategori(errors)}
          />
        )}

        {selectedTabIndex === 1 && genelForm?.bilesen_tip === '4' && (
          <TabAfis
            loading={loadingAfis}
            hata={hataAfis}

            form={afisForm}
            formdefault={afisFormDefault}
            error={afisFormError}
            onChangeForm={handleAfisChangeForm}

            onCheckErrors={(errors) => handleErrorsAfis(errors)}
          />
        )}

        {selectedTabIndex === 2 && genelForm?.bilesen_tip === '4' && (
          <TabAfisSet
            loading={LOADINGAFISSET}
            hata={hataAfisSet}
            hataDil={hataAfisDil}

            selectedTabIndex={afisSetSelectedTabIndex}
            forms={afisSetForm}
            onChangeForm={handleAfisSetChangeForm}

            selectedTabIndexDil={afisDillerSelectedTabIndex}
            dilform={afisDillerForm}
            onChangeDilForm={handleDillerChangeFormAfisSet}

            onAddNewTab={handleAfisSetAddNewTab}
            afisSetAdd={afisSetAdd}

            setDeleteId={setDeleteIdDetail}
            openDeleteForm={setShowDeleteDialogDetail}

            fontFamilyList={FONT_FAMILY_LIST}
            fontStyleList={FONT_STYLE_LIST}
            fontWeightList={FONT_WEIGHT_LIST}
            buttonPositonList={BUTTON_POSITION}
            buttonDic={BUTTON_DIC}

            onCheckErrors={(errors, index) => handleErrorsAfisSet(errors, index)}

            onCheckErrorsDil={(errors, index, index2) => handleErrorsAfisSetDil(errors, index, index2)}
          />
        )}
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Bileşen siliniyor lütfen bekleyin'
                : 'Bu bileşeni gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetail &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetail
              ? isDeletingErrorMessageDetail
              : isDeletingDetail
                ? 'Banner siliniyor lütfen bekleyin'
                : 'Bu banner ı gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetail}
          onSubmit={handleDeleteDetail}
          onCancel={() => setShowDeleteDialogDetail(false)}
        ></DialogConfirm>
      }
    </>
  );
}
export default BilesenDetail;