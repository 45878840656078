import React, { useCallback, useMemo } from 'react';

import {
  has as _has,
  result as _result,
} from 'lodash';


import {
  TextField,
  Checkbox,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
};

const HatirlaticiDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);


  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.layoutContainer}>
              <ul className={classes.layoutFormList}>
                <li className={classes.layoutFormItem} id="tanim">
                  <label className={classes.layoutFormItemLabel} htmlFor="tanim">Hatırlatıcı Tanim *</label>
                  <Tooltip title={props.error?.tanim ? props.error.tanim[0] : props.form?.tanim == null ? '' : props.form.tanim}>
                    <TextField
                      {...createTextFieldProps('tanim')}
                      onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                      onBlur={() => handleInputErrors('hatirlatici', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Hatırlatıcı Tanım', 'hatırlatıcı')}
                      inputProps={{
                        maxLength: 250,
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="bitti_mi">
                  <label className={classes.layoutFormItemLabel} htmlFor="bitti_mi">Tamamla</label>
                  <Checkbox
                    name="bitti_mi"
                    color="primary"
                    checked={_result(props.form, 'bitti_mi', false)}
                    onChange={(e, checked) => props.onChangeForm('bitti_mi', checked)}
                  ></Checkbox>
                </li>
              </ul>
            </div>
          )}
        </>
      }
    </>
  );
});

export default HatirlaticiDetailTabGenel;
