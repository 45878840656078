import React, { useMemo, useState, useRef } from 'react';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import clsx from 'clsx';
import {
  isNil as _isNil,
  has as _has
} from 'lodash';

import {
  TextField,
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Divider,
  Tooltip,
  IconButton,
  Button,
} from '@material-ui/core';

import {
  KeyboardArrowUp as IconKeyboardArrowUp,
  KeyboardArrowRight as IconKeyboardArrowRight,
  Search as IconSearch
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import { Hata } from '../../base/Hata';
import CategorySelector from '../../../components/CategorySelector';
import { handleInputErrors } from '../../base/InputErrors';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: '240px auto',
      alignItems: 'start',
      padding: spacing(1),
      overflow: 'hidden',
    },
    layoutContainerSide: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflow: 'hidden',
      paddingRight: spacing(1)
    },
    layoutContainerMain: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflow: 'hidden',
      borderLeft: `1px solid ${palette.action.selected}`,
      paddingLeft: spacing(1)
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
  };
};

const CategoryItem = ({ category, isParent, isLastParent, treeLevel, onArrowClick, onRowClick }) => {
  return (
    <div
      className={clsx('flex items-center justify-start', {
        'bg-palette-background-default': isParent,
      })}
      style={{
        height: 34,
        paddingLeft: treeLevel * 20,
      }}
    >
      <div className="flex-1 flex items-center justify-between self-stretch hover:text-primary text-left pl-2 -ml-2">
        <span
          className="cursor-pointer"
          onClick={() => onRowClick()}
        >{category.tanim}</span>
        {category.alt &&
          <span className="w-12 flex items-center justify-center cursor-pointer"
            onClick={() => {
              if (!isLastParent && category.alt) {
                onArrowClick();
              }
            }}
          >
            {isParent && !isLastParent && <IconKeyboardArrowUp />}
            {!isParent && <IconKeyboardArrowRight />}
          </span>
        }
      </div>
    </div>
  );
};

const KategoriDetailInside = withStyles(styles)(({ classes, ...props }) => {
  const [openSelector, setOpenSelector] = useState(false);

  const LOADING = useMemo(() => props.loading, [props.loading]);
  const LOADING_MORE = useMemo(() => props.loadingMore, [props.loadingMore]);
  const LOADING_DETAIL = useMemo(() => props.loadingDetail, [props.loadingDetail]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATA_DETAIL = useMemo(() => props.hataDetail, [props.hataDetail]);

  const PAGEFIRST = useMemo(() => props.pageFirst, [props.pageFirst]);
  const COUNTPAGEFIRST = useMemo(() => props.countPagesFirst, [props.countPagesFirst]);
  const PAGE = useMemo(() => props.page, [props.page]);
  const COUNTPAGES = useMemo(() => props.countPages, [props.countPages]);

  const BREADCRUMBS = useMemo(() => props.breadcrumbs, [props.breadcrumbs]);
  const SHOWN_ENTCATEGORIES = useMemo(() => props.shownEntCategories, [props.shownEntCategories]);
  const SELECTED_PARENT_CATEGORY = useMemo(() => props.selectedEntParentCategory, [props.selectedEntParentCategory]);
  const SELECTED_PARENT_CATEGORY_ID = useMemo(() => props.selectedEntParentCategoryId, [props.selectedEntParentCategoryId]);

  const SELECTED_CATEGORY_DETAIL = useMemo(() => props.selectedEntCategoryDetail?.form ?? null, [props.selectedEntCategoryDetail]);
  const SELECTED_CATEGORY_DETAIL_ERRORS = useMemo(() => props.selectedEntCategoryDetail?.errors ?? null, [props.selectedEntCategoryDetail]);


  const mainContainerRef = useRef();

  const rowRenderer = ({ key, index, style }) => {
    const category = SHOWN_ENTCATEGORIES[index];
    return (
      <div key={key} style={style}>
        {index !== 0 && <Divider />}
        <CategoryItem
          category={category}
          treeLevel={BREADCRUMBS.findIndex((crumb) => crumb.id === SELECTED_PARENT_CATEGORY_ID) + 1}
          onArrowClick={() => props.handleClickCategoryItemArrow(category, mainContainerRef)}
          onRowClick={() => props.handleClickCategoryItem(category.id, category.tanim)}
        ></CategoryItem>
      </div>
    );
  };

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <div className={classes.layoutContainerSide}>
                    <main className="relative flex flex-grow flex-col overflow-hidden h-full" ref={mainContainerRef}>
                      <>
                        {(SHOWN_ENTCATEGORIES.length > 0 && BREADCRUMBS.length > 0) && (
                          <div className="block">
                            <button
                              className="w-full h-10 flex items-center justify-between bg-palette-background-default hover:bg-palette-action-selected cursor-pointer focus:outline-none px-5"
                              onClick={props.handleGoToTop}
                            >
                              <span className="flex items-center justify-start font-medium">En Üste Git</span>
                              <IconKeyboardArrowUp />
                            </button>
                            <Divider />
                            {BREADCRUMBS.map((category, index) =>
                              <div key={category.id}>
                                <CategoryItem
                                  category={category}
                                  treeLevel={index}
                                  isParent
                                  isLastParent={(BREADCRUMBS.length - 1) === index}
                                  onArrowClick={() => props.handleClickCategoryItemArrow(category, mainContainerRef)}
                                  onRowClick={() => props.handleClickCategoryItem(category.id)}
                                ></CategoryItem>
                                <Divider />
                              </div>
                            )}
                          </div>
                        )}
                        {SHOWN_ENTCATEGORIES.length > 0 && (
                          <div className="h-full flex-grow">
                            <InfiniteLoader
                              minimumBatchSize={props.perPage}
                              threshold={props.perPage}
                              isRowLoaded={({ index }) => !!SHOWN_ENTCATEGORIES[index]}
                              loadMoreRows={({ startIndex, stopIndex }) => {
                                if (SELECTED_PARENT_CATEGORY) {
                                  if (COUNTPAGES > PAGE && !LOADING_MORE) {
                                    props.handleGetMoreEntCategoriesAlt();
                                  }
                                } else {
                                  if (COUNTPAGEFIRST > PAGEFIRST && !LOADING_MORE) {
                                    props.handleGetMoreEntCategoriesFirst();
                                  }
                                }
                              }}
                              rowCount={SELECTED_PARENT_CATEGORY ? SELECTED_PARENT_CATEGORY.altkategoriler_count * (35) : COUNTPAGEFIRST * (35)}
                            >
                              {({ onRowsRendered, registerChild }) => (
                                <AutoSizer>
                                  {({ width, height }) => (
                                    <List
                                      width={width}
                                      height={height}
                                      rowHeight={35}
                                      rowCount={SHOWN_ENTCATEGORIES.length}
                                      estimatedRowSize={SELECTED_PARENT_CATEGORY ? SELECTED_PARENT_CATEGORY.altkategoriler_count * (35) : COUNTPAGEFIRST * (35)}
                                      rowRenderer={rowRenderer}
                                      onRowsRendered={onRowsRendered}
                                      ref={registerChild}
                                    />
                                  )}
                                </AutoSizer>
                              )}
                            </InfiniteLoader>
                          </div>
                        )}
                      </>
                    </main>
                  </div>

                  <div className={classes.layoutContainerMain} >
                    {!LOADING_DETAIL && (
                      <>
                        {HATA_DETAIL && (
                          <Hata />
                        )}
                        {!HATA_DETAIL && SELECTED_CATEGORY_DETAIL && (
                          <>
                            <div className="relative flex flex-grow flex-col h-full overflow-x-auto overflow-y-hidden">
                              <ul className={classes.layoutFormList}>

                                <li className={classes.layoutFormItem} id="kategori_temp_name">
                                  <label className={classes.layoutFormItemLabel} htmlFor="kategori_temp_name">Entegrasyon Kategori</label>
                                  <Tooltip title={_isNil(SELECTED_CATEGORY_DETAIL?.kategori_temp_name) ? '' : SELECTED_CATEGORY_DETAIL.kategori_temp_name}>
                                    <TextField
                                      name="kategori_temp_name"
                                      variant="outlined"
                                      size="small"
                                      fullWidth={true}
                                      value={SELECTED_CATEGORY_DETAIL?.kategori_temp_name ?? ''}
                                    ></TextField>
                                  </Tooltip>
                                </li>

                                <li className={classes.layoutFormItem} id="kategori">
                                  <label className={classes.layoutFormItemLabel} htmlFor="kategori">Kategori *</label>
                                  <Tooltip title={SELECTED_CATEGORY_DETAIL_ERRORS?.kategori ? SELECTED_CATEGORY_DETAIL_ERRORS.kategori[0] : _isNil(SELECTED_CATEGORY_DETAIL?.kategori?.tanim) ? '' : SELECTED_CATEGORY_DETAIL?.kategori?.tanim}>
                                    <TextField
                                      name="kategori"
                                      variant="outlined"
                                      size="small"
                                      fullWidth={true}
                                      value={SELECTED_CATEGORY_DETAIL?.kategori?.tanim ?? ''}
                                      error={_has(SELECTED_CATEGORY_DETAIL_ERRORS, 'kategori')}
                                      InputProps={{
                                        endAdornment:
                                          <IconButton
                                            size="small"
                                            className={classes.button}
                                            disableRipple
                                            onClick={() => setOpenSelector(true)}
                                          ><IconSearch /></IconButton>
                                      }}
                                    ></TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="islem_tip">
                                  <label className={classes.layoutFormItemLabel} htmlFor="islem_tip">İşlem Tip</label>
                                  <Tooltip title={SELECTED_CATEGORY_DETAIL_ERRORS?.islem_tip ? SELECTED_CATEGORY_DETAIL_ERRORS.islem_tip[0] : _isNil(SELECTED_CATEGORY_DETAIL?.islem_tip) ? '' : props.kategoriIslemTipListDic ? props.kategoriIslemTipListDic[SELECTED_CATEGORY_DETAIL.islem_tip] : ''}>
                                    <TextField
                                      name="islem_tip"
                                      variant="outlined"
                                      size="small"
                                      fullWidth={true}
                                      value={SELECTED_CATEGORY_DETAIL?.islem_tip ?? ''}
                                      onChange={(e) => props.onChange('islem_tip', e.target.value)}
                                      select
                                      SelectProps={{
                                        native: true,
                                      }}
                                    >
                                      {props.kategoriIslemTipList?.map((it) => (
                                        <option key={it.key} value={it.key}>{it.value}</option>
                                      ))}
                                    </TextField>
                                  </Tooltip>
                                </li>
                                <li className={classes.layoutFormItem} id="kayit">
                                  <label className={classes.layoutFormItemLabel} htmlFor="kayit"></label>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disableElevation
                                    onClick={props.onSave}
                                    disabled={SELECTED_CATEGORY_DETAIL_ERRORS ? true : (((SELECTED_CATEGORY_DETAIL.kategori === SELECTED_CATEGORY_DETAIL.old_kategori) && (SELECTED_CATEGORY_DETAIL.islem_tip === SELECTED_CATEGORY_DETAIL.old_islem_tip)) || SELECTED_CATEGORY_DETAIL.kategori === null) ? true : false}
                                  >KAYDET</Button>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                        {openSelector &&
                          <Dialog
                            open={openSelector}
                            PaperProps={{
                              style: {
                                width: '480px',
                                height: '600px',
                              },
                            }}
                            onClose={() => {
                              handleInputErrors('', SELECTED_CATEGORY_DETAIL, SELECTED_CATEGORY_DETAIL, props.onCheckErrors, false, 'kategori', true, true, false, '', '', '')
                              setOpenSelector(false)
                            }}
                          >
                            <DialogContent style={{ padding: 0 }}>
                              <CategorySelector
                                selected={SELECTED_CATEGORY_DETAIL.kategori}
                                onChange={(category) => props.onChange('kategori', category)}
                                onClose={() => {
                                  handleInputErrors('', SELECTED_CATEGORY_DETAIL, SELECTED_CATEGORY_DETAIL, props.onCheckErrors, false, 'kategori', true, true, false, '', '', '')
                                  setOpenSelector(false)
                                }}
                                single={true}
                                parentselect={true}
                                keytanim={true}
                              />
                            </DialogContent>
                          </Dialog>
                        }
                      </>
                    )}
                    {LOADING_DETAIL &&
                      <div className="flex w-full h-full justify-center items-center">
                        <CircularProgress color="primary" />
                        <Typography variant="caption">Yükleniyor...</Typography>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      }

    </>
  );
});

export default KategoriDetailInside;
