import React, { useState, useLayoutEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import clsx from 'clsx';

import {
  has as _has,
  result as _result,
  pickBy as _pickBy,
  isArray as _isArray,
  uniq as _uniq
} from 'lodash';

import {
  Slide,
  LinearProgress,
  CircularProgress,
  IconButton,
  Button,
  Divider,
  Tooltip,
  Dialog,
  DialogContent,
} from '@material-ui/core'

import {
  VerticalSplit as IconVerticalSplit,
  HorizontalSplit as IconHorizontalSplit,
  Refresh as IconRefresh,
  ViewColumn as IconViewColumn,
  List as IconList,
  LockOutlined as IconLockOutlined
} from '@material-ui/icons';

import django from '../../api/django';
import Store from '../../libs/store';

import { QUERY_STRING_OPTIONS } from '../../constants/options';

import { IconFilter, IconFilterClear } from '../../components/Icon';
import DialogConfirm from '../../components/DialogConfirm';
import DialogExtraConfirm from '../../components/DialogExtraConfirm';
import FilterAddDialog from '../../components/FilterAddDialog';
import ManuelIslemDialog from '../../components/ManuelIslemDialog';
import FilterSelector from '../../components/FilterSelector';

import Table from '../../components/GridTableX';
import Pagination from '../../components/GridTableX_Pagination';
import ColumnEditor from '../../components/GridTableX_ColumnEditor';

const FILTER_SIZE = 30;

const PAGE_SETTINGS_DEFAULT = {
  tableOnLeft: true,
  tableWidth: 480,
  tableHeight: 480,
  showTableFilter: false,
  showFilterRow: false,
  showCheckboxCell: false,
  showColumnEditor: false
};

const LayoutTableDetailInside = (props) => {
  // #region states
  const [pageLoading, setPageLoading] = useState(true);
  const [showProgress, setShowProgress] = useState(true);
  const [loadError, setLoadError] = useState(false);

  const [columns, setColumns] = useState(props.columnsDefault)
  const [settings, setSettings] = useState(PAGE_SETTINGS_DEFAULT);

  const [rows, setRows] = useState([]);
  const [countTotalRows, setCountTotalRows] = useState(0);
  const [rowsLastUpdateTime, setRowsLastUpdateTime] = useState(0);

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [checkedRowIndexes, setCheckedRowIndexes] = useState([]);

  const [disableCloseDetail, setDisableCloseDetail] = useState(false);
  const [defaultSelectedIndex, setDefaultSelectedIndex] = useState(null);

  const [showColumnEditor, setShowColumnEditor] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [selectedTemFilter, setSelectedTempFilter] = useState([]);
  const [detayListOpen, setDetayListOpen] = useState(true);
  const [openSelectorFiltreler, setOpenSelectorFiltreler] = useState(false);
  const [openSelectorManuelIslem, setOpenSelectorManuelIslem] = useState(false);
  const [tempFilter, setTempFilter] = useState({});
  const [eventType, setEventType] = useState(0);

  // delete process
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  //filter process
  const [showAddFilter, setShowAddFilter] = useState(false);
  // #endregion

  // #region static hooks

  const columnEditorButtonRef = useRef();
  const tableContainerRef = useRef();
  const detailRef = useRef();

  // #endregion

  // #region constants
  const PATH = useMemo(() => props.path, [props]);
  const UST_ID = useMemo(() => props.ustId, [props]);
  const STOREKEY = useMemo(() => props.storekey, [props.storekey]);
  const PAGE_SETTINGS_STORAGE_KEY = useMemo(() => `egan:saved-page-settings-${STOREKEY}`, [STOREKEY]);
  const PAGE_COLUMNS_STORAGE_KEY = useMemo(() => `egan:saved-page-columns-${STOREKEY}`, [STOREKEY]);

  const PAGE = useMemo(() => Number(_result(tempFilter, 'page', 1)), [tempFilter]);
  const COUNT_PAGES = useMemo(() => Math.ceil(countTotalRows / FILTER_SIZE), [countTotalRows]);

  const IS_FILTERED = useMemo(() => tempFilter ? Object.keys(tempFilter).filter((f) => f !== 'page').length > 0 : false, [tempFilter]);

  const IS_FIRST_PAGE = useMemo(() => PAGE === 1, [PAGE]);
  const IS_LAST_PAGE = useMemo(() => PAGE >= COUNT_PAGES, [PAGE, COUNT_PAGES]);

  const TABLE_CONTAINER_WIDTH = useMemo(
    () => settings.tableOnLeft && showDetail ? settings.tableWidth : '100%',
    [settings.tableOnLeft, showDetail, settings.tableWidth]
  );

  const TABLE_CONTAINER_HEIGHT = useMemo(
    () => !settings.tableOnLeft && showDetail ? settings.tableHeight : '100%',
    [settings.tableOnLeft, showDetail, settings.tableHeight]
  );
  // #endregion

  // #region user hooks
  // get settings from localStorage
  useLayoutEffect(() => {
    const storedSettings = Store.get(PAGE_SETTINGS_STORAGE_KEY);
    const storedColumns = Store.get(PAGE_COLUMNS_STORAGE_KEY);
    if (storedSettings) {
      setSettings(storedSettings);
    } else {
      setSettings(PAGE_SETTINGS_DEFAULT)
    }
    if (storedColumns) {
      setColumns(storedColumns);
    } else {
      setColumns(props.columnsDefault)
    }
  }, [PAGE_SETTINGS_STORAGE_KEY, PAGE_COLUMNS_STORAGE_KEY, props.columnsDefault]);

  // save settings to localStorage
  useLayoutEffect(() => {
    const debounce = setTimeout(() => {
      Store.set(PAGE_SETTINGS_STORAGE_KEY, settings);
      Store.set(PAGE_COLUMNS_STORAGE_KEY, columns);
      //500 e setle
    }, 15500);
    return () => {
      clearTimeout(debounce);
    };
  }, [PAGE_SETTINGS_STORAGE_KEY, PAGE_COLUMNS_STORAGE_KEY, settings, columns]);

  // set rows
  useLayoutEffect(() => {
    if (PATH) {
      let path = PATH;
      let filters = { ...tempFilter };
      columns.forEach((col) => {
        if (_has(col, 'openFilter')) {
          if (!_has(tempFilter, `${col.filterProp}`)) {
            filters = Object.assign(filters, { [col.filterProp]: col.openFilter })
          }
        }
      });
      const handleGet = () => {
        django(path, { params: { ...filters, size: FILTER_SIZE } }).then(({ data }) => {
          setRows(data);
          setLoadError(false);
          if (defaultSelectedIndex !== null) {
            setSelectedRowIndex(defaultSelectedIndex);
            setSelectedRow(data[defaultSelectedIndex]);
          }
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setLoadError(true);
            }
          }
        }).finally(() => {
          if (rowsLastUpdateTime === 0) {
            setPageLoading(false);
          }
          setShowProgress(false);
        });
      };
      setShowProgress(true);
      const debounce = setTimeout(handleGet, 300);
      return () => {
        setShowProgress(false);
        clearTimeout(debounce);
      };
    } else {
      setPageLoading(false);
      setShowProgress(false);
    }
  }, [PATH, defaultSelectedIndex, tempFilter, rowsLastUpdateTime, columns]);

  // set countRows
  useLayoutEffect(() => {
    if (PATH) {
      let path = `${PATH}/count`;
      const handleGet = () => {
        django(`${path}`, { params: tempFilter }).then(({ data }) => {
          setCountTotalRows(Number(data));
          setLoadError(false);
        }).catch((error) => {
          setCountTotalRows(0);
          if (error.response) {
            if (error.response.status === 500) {
              setLoadError(true);
            }
          }
        });
      };
      const debounce = setTimeout(handleGet, 300);
      return () => {
        clearTimeout(debounce);
      };
    }
  }, [PATH, tempFilter]);

  // #endregion

  const confirmCloseDetail = (func) => {
    if (!disableCloseDetail || (disableCloseDetail && window.confirm('Yaptığınız değişiklikler iptal edilecek'))) {
      func();
      setDisableCloseDetail(false);
    }
  };

  const handleChangeSetting = (key, value) => {
    setSettings({
      ...settings,
      [key]: value,
    });
  };

  // #region row functions
  const handleCheckedRow = (tableIndex, checked) => {
    const copyCheckedRowIndexes = [...checkedRowIndexes];
    const arrayIndex = copyCheckedRowIndexes.indexOf(tableIndex);
    const has = arrayIndex > -1;
    // bu index daha önce eklenmiş ve kaldırılması isteniyor
    if (has && !checked) {
      copyCheckedRowIndexes.splice(arrayIndex, 1);
    }
    // bu index daha önce eklenmemiş ve eklenmesi isteniyor
    if (!has && checked) {
      copyCheckedRowIndexes.push(tableIndex);
    }
    setCheckedRowIndexes(copyCheckedRowIndexes);
  };

  const handleCheckedRows = (checked) => {
    if (checked) {
      const createIndex = [...new Array(rows.length).keys()];
      setCheckedRowIndexes(createIndex);
    } else {
      setCheckedRowIndexes([]);
    }
  };

  const handleRemoveRowByIndex = (index) => {
    const rowsCopy = [...rows];
    if (index > -1) {
      rowsCopy.splice(index, 1);
      setRows(rowsCopy);
      setCountTotalRows(countTotalRows - 1);
      setDefaultSelectedIndex(index);
      if (rowsCopy.length === 0) {
        setShowDetail(false);
      }
      setRowsLastUpdateTime(Date.now());
      setEventType(0);
    }
  };

  const handleDeleteSelectedRow = (e) => {
    e.preventDefault();
    if (props.path !== 'evrak') {
      setIsDeleting(true);
      setIsDeletingErrorMessage(null);
      django.delete(selectedRow.detay_url).then(() => {
        handleRemoveRowByIndex(selectedRowIndex);
        setShowDeleteDialog(false);
      }).catch(() => {
        setIsDeletingErrorMessage('Silme işleminde beklenmeyen bir hata oluştu.');
      }).finally(() => {
        setIsDeleting(false);
      });
    } else {
      setIsDeleting(true);
      setIsDeletingErrorMessage(null);
      const data = { ...selectedRow, versiyon: selectedRow.versiyon + 1, iptal: true }
      django.post(props.cancelpath, data).then(() => {
        setShowDeleteDialog(false);
      }).catch(() => {
        setIsDeletingErrorMessage('İptal işleminde beklenmeyen bir hata oluştu.');
      }).finally(() => {
        setIsDeleting(false);
      });
    }

  };
  // #endregion

  // #region filter/sort
  const handleQuickSetSort = (columnName) => {
    const copyFilter = { ...tempFilter };
    // sort diye bir prop var mı?
    if (_has(copyFilter, 'sort')) {
      const indexAsc = copyFilter.sort.indexOf(columnName);
      const indexDesc = copyFilter.sort.indexOf(`-${columnName}`);
      // asc varsa desc'ye çevir
      if (indexAsc > -1) {
        copyFilter.sort[indexAsc] = `-${columnName}`;
      }
      // desc varsa kaldır
      else if (indexDesc > -1) {
        copyFilter.sort.splice(indexDesc, 1);
      }
      // hiçbir şey yoksa asc'ye çevir
      else {
        copyFilter.sort.push(columnName);
      }
    } else {
      copyFilter.sort = [columnName];
    }
    const search = queryString.stringify(_pickBy({ ...copyFilter, page: '' }), QUERY_STRING_OPTIONS);
    if (search) {
      const q = queryString.parse(search, QUERY_STRING_OPTIONS);
      if (_has(q, 'sort') && !_isArray(q.sort)) {
        q.sort = [q.sort];
      }
      setTempFilter(_pickBy(q));
    } else {
      setTempFilter({})
    }
  };

  const handleSubmitTempFilter = (columnName, filterValue) => {
    if (columnName !== undefined && filterValue !== undefined) {
      const searchParams = {
        ...tempFilter,
        [columnName]: filterValue,
        page: null,
      };
      const search = queryString.stringify(_pickBy(searchParams), QUERY_STRING_OPTIONS);
      if (search) {
        const q = queryString.parse(search, QUERY_STRING_OPTIONS);
        if (_has(q, 'sort') && !_isArray(q.sort)) {
          q.sort = [q.sort];
        }
        setTempFilter(_pickBy(q));
      } else {
        setTempFilter({})
      }
      setSelectedTempFilter([]);
    }
  };

  const handleSelectFilter = (val) => {
    if (val) {
      setSelectedTempFilter(val);
      const totalval = val.map(x => x.kosul).join('&');
      const q = queryString.parse(totalval, { ...QUERY_STRING_OPTIONS, });
      if (_has(q, 'sort') && !_isArray(q.sort)) {
        q.sort = [q.sort];
      }
      Object.keys(q).forEach((key) => {
        if (_isArray(q[key])) {
          return q[key] = _uniq(q[key])
        }
      })
      setTempFilter(_pickBy(q));
      setSelectedTempFilter(val);
    } else {
      setTempFilter({});
      setSelectedTempFilter([]);
    }
  }

  const handleChangePage = (page) => {
    const searchParams = {
      ...tempFilter,
      page: page !== 1 ? page : null,
    };
    const search = queryString.stringify(_pickBy(searchParams), QUERY_STRING_OPTIONS);
    if (search) {
      const q = queryString.parse(search, QUERY_STRING_OPTIONS);
      if (_has(q, 'sort') && !_isArray(q.sort)) {
        q.sort = [q.sort];
      }
      setTempFilter(_pickBy(q));
    } else {
      setTempFilter({})
    }
  };
  // #endregion

  // #region resizer
  const handleResizerDragStop = (e) => {
    e.preventDefault();
    document.onmousemove = null;
    document.onmouseup = null;
  };

  const handleResizerDrag = (e) => {
    e.preventDefault();
    if (tableContainerRef.current) {
      const tableContainerRect = tableContainerRef.current.getBoundingClientRect();
      if (settings.tableOnLeft) {
        handleChangeSetting('tableWidth', e.clientX - tableContainerRect.x);
      } else {
        handleChangeSetting('tableHeight', e.clientY - tableContainerRect.y);
      }
    }
  };

  const handleResize = (e) => {
    e.preventDefault();
    document.onmousemove = handleResizerDrag;
    document.onmouseup = handleResizerDragStop;
  };
  // #endregion

  // #region go next/previous
  const handleSelectPreviousRow = (e) => {
    confirmCloseDetail(() => {
      // ilk ürün değilse selectedRowIndex'i -1 artırıyoruz
      if (selectedRowIndex > 0) {
        setSelectedRowIndex(selectedRowIndex - 1);
        setSelectedRow(rows[selectedRowIndex - 1]);
      }
      // ilk ürünse sonraki sayfaya geçmece
      else {
        if (PAGE > 1) {
          handleChangePage(PAGE - 1);
          setDefaultSelectedIndex(FILTER_SIZE - 1);
        }
      }
      setEventType(0);
    });
  };

  const handleSelectNextRow = (e) => {
    confirmCloseDetail(() => {
      // son ürünse sonraki sayfaya geçmece
      if (rows.length === selectedRowIndex + 1 && COUNT_PAGES > PAGE) {
        handleChangePage(PAGE + 1);
        setDefaultSelectedIndex(0);
      }
      // son ürün değilse selectedRowIndex'i +1 artırıyoruz
      else if (rows.length > (selectedRowIndex + 1)) {
        setSelectedRowIndex(selectedRowIndex + 1);
        setSelectedRow(rows[selectedRowIndex + 1]);
      }
      setEventType(0);
    });
  };

  // #endregion

  if (pageLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center overflow-hidden">
        <div className="flex items-center justify-center">
          <CircularProgress size={72} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="relative w-full h-full flex flex-col rounded bg-palette-background-default border-2 border-palette-action-disabledBackground">
        {/* top.navbar */}
        <nav className="relative w-full h-10 flex items-center justify-between bg-palette-action-hover border-b border-palette-action-selected z-10">
          <span className={clsx('absolute left-0 top-0 w-full h-auto', { 'hidden': !showProgress })}>
            <LinearProgress />
          </span>

          {/* top.navbar.left */}
          <div className="flex items-center justify-start space-x-2 px-2">
            {props.title && (
              <h1 className="flex items-center justify-start m-0">
                <span className="text-sm font-medium leading-none">{props.title}</span>
              </h1>
            )}

            {settings.showCheckboxCell && checkedRowIndexes.length > 0 && (
              <>
                <Divider orientation="vertical" flexItem></Divider>

                <Button
                  variant="text"
                  color="inherit"
                  size="small"
                >{`${checkedRowIndexes.length} SATIR SEÇİLDİ`}</Button>
              </>
            )}
          </div>

          {/* top.navbar.right */}
          <div className="flex items-center justify-end space-x-2 px-4">
            {disableCloseDetail && (
              <Tooltip title="Detay içerisinde değişiklik yapıldı">
                <IconButton
                  component="span"
                  size="small"
                  style={{
                    backgroundColor: 'rgb(241 196 15 / 20%)',
                    cursor: 'help'
                  }}
                  disableFocusRipple
                  disableRipple
                ><IconLockOutlined /></IconButton>
              </Tooltip>
            )}

            {props.filtreButtonShow && (
              <>
                {props.manuelIslem &&
                  <Tooltip title="Manuel İşlem Oluştur">
                    <Button
                      variant="text"
                      color="inherit"
                      size="small"
                      onClick={() => setOpenSelectorManuelIslem(true)}
                    >Manuel İşlem</Button>
                  </Tooltip>
                }
                {props.filter &&
                  <Tooltip title="Filtre Seç">
                    <Button
                      variant="text"
                      color="inherit"
                      size="small"
                      onClick={() => setOpenSelectorFiltreler(true)}
                    >Filtreler</Button>
                  </Tooltip>
                }
              </>
            )}

            {(IS_FILTERED && props.filtreButtonShow && selectedTemFilter.length === 0) && (
              <Tooltip title="Filtreyi Kaydet">
                <Button
                  variant="text"
                  color="inherit"
                  size="small"
                  onClick={() => setShowAddFilter(true)}
                >Filtreyi Kaydet</Button>
              </Tooltip>
            )}

            {IS_FILTERED && (
              <Tooltip title="Filtreyi temizle">
                <IconButton
                  size="small"
                  style={{
                    width: 30,
                    height: 30,
                    backgroundColor: 'rgb(241 196 15 / 20%)',
                  }}
                  onClick={() => {
                    setTempFilter({});
                    setSelectedTempFilter([]);
                  }}
                ><IconFilterClear /></IconButton>
              </Tooltip>
            )}

            <Tooltip title={settings.showCheckboxCell ? 'Çoklu seçim sütununu kapat' : 'Çoklu seçim sütununu aç'}>
              <IconButton
                size="small"
                onClick={() => handleChangeSetting('showCheckboxCell', !settings.showCheckboxCell)}
              ><IconList /></IconButton>
            </Tooltip>

            <Tooltip title={settings.showFilterRow ? 'Filtre satırını gizle' : 'Filtre satırını göster'}>
              <IconButton
                size="small"
                onClick={() => handleChangeSetting('showFilterRow', !settings.showFilterRow)}
                style={{
                  width: 30,
                  height: 30,
                }}
              ><IconFilter /></IconButton>
            </Tooltip>

            <Tooltip title="Sütunları düzenle">
              <IconButton
                size="small"
                onClick={() => setShowColumnEditor(!showColumnEditor)}
                ref={columnEditorButtonRef}
              >
                <IconViewColumn />
              </IconButton>
            </Tooltip>

            <Divider orientation="vertical" flexItem></Divider>

            <Tooltip title={!showDetail ? 'Detay göster' : settings.tableOnLeft ? 'Detay altta' : 'Detay sağda'}>
              <span>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (showDetail) {
                      handleChangeSetting('tableOnLeft', !settings.tableOnLeft)
                    } else {
                      setShowDetail(true);
                    }
                  }}
                  disabled={selectedRowIndex === null}
                >
                  {settings.tableOnLeft ? <IconHorizontalSplit /> : <IconVerticalSplit />}
                </IconButton>
              </span>
            </Tooltip>

            <Divider orientation="vertical" flexItem></Divider>

            <Tooltip title="Listeyi yenile">
              <IconButton
                size="small"
                onClick={() => setRowsLastUpdateTime(Date.now())}
              >
                <IconRefresh />
              </IconButton>
            </Tooltip>
          </div>
        </nav>

        {/* main */}
        <div className="relative w-full h-full flex overflow-hidden">
          {/* main.left */}
          {/* {LeftFilterProps.show && (
            <aside className="w-80 h-full flex items-center justify-center border-r border-palette-action-selected bg-palette-background-paper">
              {LeftFilterChildren}
            </aside>
          )} */}

          {/* main.content */}
          <div
            className={clsx('relative w-full h-full flex overflow-hidden', {
              'flex-row': settings.tableOnLeft,
              'flex-col': !settings.tableOnLeft,
            })}
          >
            {/* table.container */}
            <div
              className={clsx('flex flex-col items-start', {
                'border-r border-palette-action-hover': showDetail,
              })}
              style={{
                width: TABLE_CONTAINER_WIDTH,
                height: TABLE_CONTAINER_HEIGHT,
              }}
              ref={tableContainerRef}
            >

              <Table
                loadError={loadError}
                path={props.path}
                rows={rows}
                columns={columns}
                functions={props.funcList ?? null}

                filter={tempFilter}

                showFilterRow={settings.showFilterRow}
                showCheckBoxCell={settings.showCheckboxCell}

                checkedRowIndexes={checkedRowIndexes}
                onCheckedRow={handleCheckedRow}
                onCheckedRows={handleCheckedRows}

                onChangeColumnSort={(colName) => handleQuickSetSort(colName)}
                onChangeFilterValue={(columnName, filterValue) => handleSubmitTempFilter(columnName, filterValue)}

                selectedRowIndex={selectedRowIndex ?? undefined}

                onSelectNextRow={handleSelectNextRow}
                onSelectPreviousRow={handleSelectPreviousRow}

                onKeyupEnter={(index) => {
                  setSelectedRowIndex(index);
                  setSelectedRow(rows[index]);
                  setShowDetail(true);
                  setEventType(0);
                }}

                onKeyupEsc={() => {
                  if (showDetail) {
                    setSelectedRowIndex(null);
                    setSelectedRow(null);
                    setShowDetail(false);
                  }
                }}

                onTableRowClick={(index) => {
                  confirmCloseDetail(() => {
                    setSelectedRowIndex(index);
                    setSelectedRow(rows[index]);
                    setEventType(0);
                  });
                }}

                onTableRowDoubleClick={(index) => {
                  setShowDetail(true);
                  setSelectedRowIndex(index);
                  setSelectedRow(rows[index]);
                  setEventType(0);
                }}

                onRightClickMenuOpenNewTab={(index) => {
                  const row = rows[index];
                  window.open(`${window.location.origin}/${props.singledetail}/${props.ustId}/${row.id}/`, '_blank');
                }}

                onRightClickMenuShowDetail={(index) => {
                  setSelectedRowIndex(index);
                  setSelectedRow(rows[index]);
                  setShowDetail(true);
                }}

                onRightClickMenuDelete={(index) => {
                  if (props.path !== 'sablon_paket') {
                    setShowDeleteDialog(true);
                  } else {
                    setShowDeleteConfirmDialog(true);
                  }
                }}
              ></Table>

              <footer className="w-full flex items-center justify-between bg-palette-action-hover border-t border-palette-action-selected py-1 px-4">
                <span>
                  {
                    countTotalRows > 0
                      ? `Toplam kayıt: ${countTotalRows.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
                      : 'Kayıt bulunamadı'
                  }
                </span>

                <Pagination
                  page={PAGE}
                  countPages={COUNT_PAGES}
                  onSetPage={(page) => handleChangePage(page)}
                />
              </footer>
            </div>

            {showDetail && (
              <>
                {/* main.resizer */}
                <div
                  className={clsx('relative flex items-center justify-center', {
                    'w-4 h-full cursor-col-resize space-x-2px flex-row rounded-r-full': settings.tableOnLeft,
                    'h-4 w-full cursor-row-resize space-y-2px flex-col rounded-b-full': !settings.tableOnLeft,
                  })}
                  onMouseDown={handleResize}
                >
                  {[0, 1].map((x) =>
                    <span
                      key={x}
                      className={clsx('block bg-palette-action-disabled', {
                        'w-px h-6': settings.tableOnLeft,
                        'h-px w-6': !settings.tableOnLeft,
                      })}
                    />
                  )}
                </div>

                {/* detail.container */}
                <Slide in direction={settings.tableOnLeft ? 'left' : 'up'} mountOnEnter unmountOnExit ref={detailRef}>
                  <main
                    className={clsx('flex-1 w-full h-full overflow-hidden pr-4 pb-4 relative', {
                      'pt-4': settings.tableOnLeft,
                      'pl-4': !settings.tableOnLeft,
                    })}
                  >
                    <props.detailComponent
                      id={selectedRow?.id}
                      path={PATH}
                      ustId={UST_ID}
                      singledetail={props.singledetail}

                      disablePreviousButton={showProgress || (IS_FIRST_PAGE && selectedRowIndex === 0)}
                      disableNextButton={showProgress || (IS_LAST_PAGE && selectedRowIndex === (rows.length - 1))}

                      onGoPrevious={handleSelectPreviousRow}
                      onGoNext={handleSelectNextRow}

                      onAdded={() => {
                        setRowsLastUpdateTime(Date.now());
                        setDefaultSelectedIndex(0);
                        handleChangePage(null);
                        setEventType(1);
                      }}

                      onUpdated={() => {
                        setRowsLastUpdateTime(Date.now());
                        setDefaultSelectedIndex(selectedRowIndex);
                      }}

                      onDeleted={() => handleRemoveRowByIndex(selectedRowIndex)}

                      onDisableClose={(status) => setDisableCloseDetail(status)}

                      onClose={() => {
                        confirmCloseDetail(() => {
                          setShowDetail(false);
                        });
                      }}
                      eventType={eventType}
                    />
                    <div
                      className={clsx('absolute tracking-widest text-xs pointer-events-none', {
                        'top-2/4 -left-4 transform rotate-90 -translate-y-2/4': settings.tableOnLeft && detailRef.current?.clientWidth === 16,
                        'top-0 left-2/4 transform -translate-x-2/4': !settings.tableOnLeft && detailRef.current?.clientHeight === 16,
                        'hidden': (settings.tableOnLeft && detailRef.current?.clientWidth !== 16) || (!settings.tableOnLeft && detailRef.current?.clientHeight !== 16),
                      })}
                    >
                      DETAY
                    </div>
                  </main>
                </Slide>
                {/* <div
                  className={clsx('absolute tracking-widest text-xs pointer-events-none', {
                    'top-2/4 right-0 transform rotate-90 -translate-y-2/4': settings.tableOnLeft && detailRef.current?.clientWidth === 16,
                    'bottom-0 left-2/4 transform -translate-x-2/4': !settings.tableOnLeft && detailRef.current?.clientHeight === 16,
                    'flex-none': (settings.tableOnLeft && detailRef.current?.clientWidth !== 16) || (!settings.tableOnLeft && detailRef.current?.clientHeight !== 16),
                  })}
                >
                  DETAY
                </div> */}
              </>
            )}
          </div>
        </div>
      </div>

      {showDeleteConfirmDialog &&
        <DialogExtraConfirm
          title="Onay"
          message="Bu temayı silmeniz halinde, temayı kullanan site bu işlemden geri dönüşü olmayacak şekilde etkilenecektir."
          cancelText="VAZGEÇ"
          submitText="ONAYLA"
          confirmText="Okudum,anlamdım,onaylıyorum."
          submitButtonProps={{ color: 'secondary' }}
          onSubmit={() => {
            setDeleteConfirm(false);
            setShowDeleteConfirmDialog(false);
            setShowDeleteDialog(true);
          }}
          onCancel={() => {
            setDeleteConfirm(false);
            setShowDeleteConfirmDialog(false);
          }}
          onConfirm={() => setDeleteConfirm(!deleteConfirm)}
          isConfirm={deleteConfirm}
        ></DialogExtraConfirm>
      }

      {showDeleteDialog && (
        <DialogConfirm
          title={props.path !== 'evrak' ? 'Sil' : 'İptal'}
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? props.path !== 'evrak' ? 'Seçtiğiniz satır siliniyor lütfen bekleyin' : 'Seçtiğiniz satır iptal ediliyor lütfen bekleyin'
                : props.path !== 'evrak' ? 'Bu satırı gerçekten silmek istiyor musunuz?' : 'Bu satırı gerçekten iptal etmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : props.path !== 'evrak' ? 'SİL' : 'İPTAL ET'}
          submittingText={props.path !== 'evrak' ? "SİLİNİYOR" : 'İPTAL EDİLİYOR'}
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDeleteSelectedRow}
          onCancel={() => setTimeout(() => setShowDeleteDialog(false))}
        ></DialogConfirm>
      )}

      {showAddFilter &&
        <Dialog
          open={showAddFilter}
          PaperProps={{
            style: {
              width: '480px',
            },
          }}
          onClose={() => setShowAddFilter(false)}
        >
          <DialogContent style={{ padding: 0 }}>
            <FilterAddDialog
              filters={queryString.stringify(_pickBy(Object.keys(tempFilter).filter((f) => f !== 'page').reduce((obj, key) => { return { ...obj, [key]: tempFilter[key] } }, {})), QUERY_STRING_OPTIONS)}
              close={() => setShowAddFilter(false)}
              listPath={props.filterProps.listPath}
              editablePropList={props.filterProps.editablePropList}
              filterList={props.filterProps.filterList}
              openFilters={() => {
                setTempFilter({});
                setSelectedTempFilter([]);
                setOpenSelectorFiltreler(true);
              }}
            />
          </DialogContent>
        </Dialog>
      }

      {showColumnEditor && (
        <ColumnEditor
          anchorEl={columnEditorButtonRef?.current}
          columns={columns}
          onUpdatedColumns={(updatedColumns) => setColumns(updatedColumns)}
          onResetColumns={() => setColumns(props.columnsDefault)}
          onClose={() => setShowColumnEditor(!showColumnEditor)}
        >
        </ColumnEditor>
      )}

      {openSelectorFiltreler &&
        <Dialog
          open={openSelectorFiltreler}
          PaperProps={{
            style: {
              background: 'transparent',
              height: 600,
              width: detayListOpen ? '100%' : 480
            },
          }}
          fullWidth
          maxWidth={detayListOpen ? 'md' : 'sm'}
          onClose={() => {
            setOpenSelectorFiltreler(false)
          }}
        >
          <DialogContent style={{ padding: 0 }}>
            <FilterSelector
              title={props.filterProps.title}
              listPath={props.filterProps.listPath}
              listCountPath={props.filterProps.listCountPath}
              valueProp={props.filterProps.valueProp}
              defaultItems={selectedTemFilter}
              onSelectItems={(items) => handleSelectFilter(items)}
              onClose={() => {
                setOpenSelectorFiltreler(false)
              }}
              deleteM={props.filterProps.deleteM}
              deleteM2={props.filterProps.deleteM2}
              editablePropList={props.filterProps.editablePropList}
              filterList={props.filterProps.filterList}
              onDetayListClose={(res) => setDetayListOpen(res)}
              detayListOpen={detayListOpen}
            />
          </DialogContent>
        </Dialog>
      }

      {openSelectorManuelIslem &&
        <Dialog
          open={openSelectorManuelIslem}
          PaperProps={{
            style: {
              width: '480px',
            },
          }}
          onClose={() => setOpenSelectorManuelIslem(false)}
        >
          <DialogContent style={{ padding: 0 }}>
            <ManuelIslemDialog
              filters={queryString.stringify(_pickBy(Object.keys(tempFilter).filter((f) => f !== 'page').reduce((obj, key) => { return { ...obj, [key]: tempFilter[key] } }, {})), QUERY_STRING_OPTIONS)}
              selectedfilter={selectedTemFilter}
              ustId={props.ustId}
              close={() => setOpenSelectorManuelIslem(false)}
              filterList={props.filterProps.filterList}
            />
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

LayoutTableDetailInside.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  pagefilter: PropTypes.string,
  blockedFilter: PropTypes.bool,
  columnsDefault: PropTypes.array,
  detailComponent: PropTypes.elementType.isRequired,
};

LayoutTableDetailInside.defaultProps = {
  columnsDefault: [],
};

export default LayoutTableDetailInside;
