import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList } from '../../base/InputErrors';

import TabGenel from './FotoDetailTab_Genel';

const PATH = 'foto';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  tanim: '',
  image: '',
  thumbnail: '',
  gercek_image: '',
  foto_tip: '0'
};

const FotoDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [hataGenel, setHataGenel] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(FORM_DEFAULT);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [tip, setTip] = useState([]);
  const [tipDic, setTipDic] = useState([]);
  const [total, setTotal] = useState(0);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    return isEqualGenel;
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    setLoading(true);
    setTabs(TABS_DEFAULT);
    if (!IS_ADD) {
      const debounce = setTimeout(() => {
        django(`${PATH}/${ID}`).then(({ data }) => {
          const form = {
            tanim: data.tanim,
            image: data.image,
            thumbnail: data.thumbnail,
            gercek_image: data.gercek_image,
            foto_tip: String(data.foto_tip.key),
            old_image: true,
          };
          setGenelFormDefault(form);
          setGenelForm(form);
          setHataGenel(null);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataGenel(true);
            }
          }
        }).finally(() => {
          setLoading(false);
          setGenelError(null);
          setAdd(false);
        });
      }, 300);
      return () => {
        clearTimeout(debounce);
        setLoading(false);
      };
    } else {
      setGenelForm(FORM_DEFAULT);
      setGenelFormDefault(FORM_DEFAULT);
      setGenelError(null);
      setHataGenel(null);
      setAdd(true);
      setLoading(false);
    }
  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    django('foto/foto_tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setTipDic(dict);
      setTip(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (genelForm?.gercek_image && genelForm?.image && genelForm?.thumbnail) {
      if (genelForm.gercek_image.substr(0, 5) === 'data:' && genelForm.image.substr(0, 5) === 'data:' && genelForm.thumbnail.substr(0, 5) === 'data:') {
        const gercek = genelForm.gercek_image.length;
        const img = genelForm.image.length;
        const thumb = genelForm.thumbnail.length;
        const totalsize = img + gercek + thumb;
        setTotal(totalsize);
      }
    }
  }, [genelForm]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleAdd = () => {
    setAdd(true);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
      });
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.foto.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Fotoğraf Tanım', pagename: 'fotoğraf', prop2: '' },
      { prop: 'image', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ]
    let errors = [];
    await checkAllList('foto', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = () => {
    checkAll().then((res) => {
      if (_isEmpty(res)) {
        let requestoptions = {};
        const formdata = new FormData();
        formdata.append('image', genelForm.image);
        formdata.append('thumbnail', genelForm.thumbnail);
        formdata.append('gercek_image', genelForm.gercek_image);
        formdata.append('tanim', genelForm.tanim);
        formdata.append('foto_tip', Number(genelForm.foto_tip));
        if (total > 5242880) {
          requestoptions = null;
          return enqueueSnackbar('Dosya boyutunuz büyük!', { variant: 'error' });
        } else {
          if (IS_EQUAL) {
            requestoptions = null;
          } else {
            requestoptions = {
              config: {
                method: add ? 'POST' : 'PUT',
                url: add ? 'foto' : `foto/${ID}`,
                data: formdata,
                headers: { "Content-Type": "multipart/form-data" },
              },
              successMessage: `Fotoğraf ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Fotoğraf ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            }
          }

          const start = async () => {
            let errorCounts = 0;
            if (requestoptions !== null) {
              setGenelError(null);
              await django(requestoptions.config).then(({ data }) => {
                enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.foto.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
              }).catch((error) => {
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
                }
                ++errorCounts;
              });
              if (errorCounts === 0) {
                setLastUpdateTime(Date.now());
              } else {
                setTabs((prev) => {
                  return immer(prev, (next) => {
                    next[0].hasError = true;
                  });
                });
              }
            }
          }
          start();
        }
      } else {
        setTabs((prev) => {
          return immer(prev, (next) => {
            next[0].hasError = true;
          });
        });
      }
    })
  }

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            tip={tip}
            tipDic={tipDic}
            onChangeForm={handleGenelChangeForm}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Fotoğraf siliniyor lütfen bekleyin'
                : 'Bu fotoğrafı gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
    </>
  );
}

export default FotoDetail;