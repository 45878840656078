import React, { useState, useLayoutEffect } from 'react';
import django from '../../../api/django';
import Layout from '../../../pages/base/LayoutTableDetail';
import Detail from './EkAlanDetail';

const COLUMNS_DEFAULT = [
  { id: 'tanim',                tableProp: 'tanim',                         filterProp: 'tanim',                    label: 'Ek Alan Tanım',             element: 'input',           type: 'text',     arrayTableProp: null,     show: true, sortable: true,   highlightable: true,  alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: null,                            multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'alan_iliski_tip',      tableProp: 'alan_iliski_tip',               filterProp: 'alan_iliski_tip.key',      label: 'Ek Alan İlişki Tipi',       element: 'select-multiple', type: 'array',    arrayTableProp: 'value',  show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: 'ek_alan_tanim/alan_iliski_tip', multipleSelectOptionFilterProp: 'key',multipleSelectOptionSortProp: 'value',    multipleSelectOptionViewProp: 'value',    shortDescription: null, selectKey: null,  selectValue: null , newpath: null , staticpage: true},
  { id: 'magazalar',            tableProp: 'magazalar',                     filterProp: 'magazalar.magaza.id',      label: 'Mağazalar',                 element: 'select-multiple', type: 'array',    arrayTableProp: 'tanim',  show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: 'magaza',                        multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null, selectKey: null,  selectValue: null, newpath: "tanimlar/magaza" },
  { id: 'detay',                tableProp: 'detay',                         filterProp: 'detay',                    label: 'Detay',                     element: 'select',          type: 'boolean',  arrayTableProp: null,     show: true, sortable: false,  highlightable: false, alignment: 1, width: 1, selectOptions: null,  multipleSelectOptionsPath: null,                            multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'aktif',                tableProp: 'aktif',                         filterProp: 'aktif',                    label: 'Aktif',                     element: 'select',          type: 'boolean',  arrayTableProp: null,     show: true, sortable: false,  highlightable: false, alignment: 1, width: 1, selectOptions: null,  multipleSelectOptionsPath: null,                            multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: 'key', selectValue: 'value' },
  { id: 'siralama',             tableProp: 'siralama',                      filterProp: 'siralama',                 label: 'Sıralama',                  element: 'input',           type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 2, width: 1, selectOptions: null,  multipleSelectOptionsPath: null,                            multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'olusturan_kullanici',  tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',   label: 'Oluşturan Kullanıcı',       element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: 'kullanici',                     multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'degistiren_kullanici', tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',  label: 'Değiştiren Kullanıcı',      element: 'select-multiple', type: 'number',   arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: 'kullanici',                     multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'olusturma_zamani',     tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',         label: 'Oluşturma',                 element: 'input',           type: 'datetime', arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: null,                            multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
  { id: 'degistirme_zamani',    tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',        label: 'Son Değişiklik',            element: 'input',           type: 'datetime', arrayTableProp: null,     show: true, sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null,  multipleSelectOptionsPath: null,                            multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null, selectKey: null,  selectValue: null },
];

const PageEkAlanList = () => {

  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        } 
        return column;
      });
    });
  }, []);

  useLayoutEffect(() => {
    django('ek_alan_tanim/alan_iliski_tip').then(({ data }) => {
      setColumnsDefault((prev) => {
        return prev.map((column) => {
          if (column.id === 'alan_iliski_tip') {
            return {
              ...column,
              selectOptions: data,
            };
          }
          return column;
        });
      });
    })
  }, []);

  return (
    <>
      <Layout
        title="EK ALANLAR"
        path="ek_alan_tanim"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="ek_alan_tanim"
      />
    </>
  );
};

export default PageEkAlanList;
