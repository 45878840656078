import React, { useState, useEffect, useLayoutEffect, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import { turkishToLower } from '../helpers/helpers'
import RightMenu from '../helpers/rightMenu';

import {
  Button,
  Checkbox,
  Paper,
  CircularProgress,
  LinearProgress,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Tooltip,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
  Search as IconSearch,
  Delete as IconDelete,
  Refresh as IconRefresh,
} from '@material-ui/icons';

import django from '../api/django';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    }
  };
});

const PER_PAGE = 100;
const ROW_HEIGHT = 34;

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text, submitText, onSubmit }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>

      <p className="font-medium mb-2">{text}</p>

      {onSubmit &&
        <Button
          color="primary"
          onClick={onSubmit}
        >{submitText ?? 'TEKRAR DENE'}</Button>
      }
    </div>
  );
};

const ListItem = ({ item, checked, searchQuery, onClick }) => {
  return (
    <div
      key={`${item.id}${item.value}`}
      className={clsx('w-full flex items-center justify-start hover:text-primary px-4 space-x-3 select-none')}
      style={{ height: ROW_HEIGHT }}
    // onClick={() => onClick(!checked)}
    >
      <Checkbox
        color="primary"
        checked={checked}
        onChange={(e, checked) => onClick(checked)}
        style={{ padding: 0, cursor: "pointer" }}
      ></Checkbox>

      <Highlighter
        textToHighlight={`${item.value}`}
        searchWords={[searchQuery ?? '']}
        className=""
        activeClassName=""
        highlightClassName="leading-none"
        unhighlightClassName="leading-none"
      />
    </div>
  );
};

const ListSelector = ({ title, listPath, listCountPath, keyProp, valueProp, defaultItems, onChangeItems, onClose, newPath, ustId, extraProp, staticpage, staticdata, setLastUpdateTimeStaticdata, confirmButton, confirmEnable, onConfirm, confirmBtnTitle }) => {
  const [items, setItems] = useState([]);
  const [itemsLoadingCount, setItemsLoadingCount] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsLoadingErrorMessage, setItemsLoadingErrorMessage] = useState(null);

  const [itemsTotalCount, setItemsTotalCount] = useState(0);

  const [moreItemsLoading, setMoreItemsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchItems, setSearchItems] = useState([]);
  const [searchItemsLoading, setSearchItemsLoading] = useState(false);
  const [searchItemsLoadingErrorMessage, setSearchItemsLoadingErrorMessage] = useState(null);

  const [showSelectedItems, setShowSelectedItems] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const PAGE = useMemo(() => items.length > 0 ? Math.ceil(items.length / PER_PAGE) : 1, [items.length]);
  const PAGES_COUNT = useMemo(() => Math.ceil(itemsTotalCount / PER_PAGE), [itemsTotalCount]);

  const classes = useStyles();

  const mainContainerRef = useRef();

  const MAPPED_DEFAULT_ITEMS = useMemo(() => {
    if (extraProp) {
      return defaultItems.map((item) => ({
        key: item[keyProp],
        value: item[valueProp],
        extra: item[extraProp]
      }));
    } else {
      return defaultItems.map((item) => ({
        key: item[keyProp],
        value: item[valueProp]
      }));
    }
  }, [defaultItems, keyProp, valueProp, extraProp]);

  const mapItem = useCallback((item) => {
    if (extraProp) {
      return {
        key: item[keyProp],
        value: item[valueProp],
        extra: item[extraProp],
      };
    } else {
      return {
        key: item[keyProp],
        value: item[valueProp],
      };
    }
  }, [keyProp, valueProp, extraProp]);

  // set items
  useLayoutEffect(() => {
    setItemsLoading(true);
    if (staticdata) {
      const mapped = staticdata.map(mapItem);
      setItems(mapped);
      setItemsLoadingCount(true);
      setItemsTotalCount(staticdata.length);
      setItemsLoading(false);
      setItemsLoadingCount(false);
    } else {
      django(listPath, { params: { size: PER_PAGE, sort: valueProp } }).then(({ data }) => {
        const mapped = data.map(mapItem);
        setItems(mapped);
        if (staticpage) {
          setItemsLoadingCount(true);
          setItemsTotalCount(data.length);
        }
      }).catch(() => {
        setItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
      }).finally(() => {
        setItemsLoading(false);
        if (staticpage) {
          setItemsLoadingCount(false);
        }
      });
    }
  }, [listPath, mapItem, valueProp, lastUpdateTime, staticpage, staticdata]);

  // set options count
  useLayoutEffect(() => {
    if (!staticdata) {
      if (!staticpage) {
        setItemsLoadingCount(true);
        let url = listCountPath;
        if (listCountPath === undefined) {
          url = `${listPath}/count`;
        }
        django(url).then(({ data }) => {
          setItemsTotalCount(data);
        }).finally(() => {
          setItemsLoadingCount(false);
        });
      }
    }
  }, [listPath, listCountPath, lastUpdateTime, staticpage, staticdata]);

  const SHOW_LINEAR_PROGRESS = useMemo(() => {
    if (itemsLoading || moreItemsLoading || searchItemsLoading || itemsLoadingCount) {
      return true;
    }
    return false;
  }, [itemsLoading, itemsLoadingCount, moreItemsLoading, searchItemsLoading]);

  const LOADING_MESSAGE = useMemo(() => {
    if (itemsLoading || itemsLoadingCount) {
      return 'Seçenekler yükleniyor';
    }
    if (searchItemsLoading || itemsLoadingCount) {
      return 'Arama yapılıyor';
    }
    return null;
  }, [itemsLoading, itemsLoadingCount, searchItemsLoading]);

  const ERROR_MESSAGE = useMemo(() => {
    if (itemsLoadingErrorMessage) {
      return itemsLoadingErrorMessage;
    }
    if (searchItemsLoadingErrorMessage) {
      return searchItemsLoadingErrorMessage;
    }
    return null;
  }, [itemsLoadingErrorMessage, searchItemsLoadingErrorMessage]);

  const FILTERED_ITEMS = useMemo(() => {
    if (showSelectedItems && searchQuery === '') {
      return MAPPED_DEFAULT_ITEMS;
    }
    if (searchQuery !== '') {
      return searchItems;
    }
    return items;
  }, [MAPPED_DEFAULT_ITEMS, showSelectedItems, items, searchItems, searchQuery]);

  // handle search
  useEffect(() => {
    if (!staticpage) {
      if (!staticdata) {
        if (!showSelectedItems) {
          const handleGet = ({ url, config }) => {
            setSearchItems([]);
            setSearchItemsLoading(true);
            setSearchItemsLoadingErrorMessage(null);
            django(url, config).then(({ data }) => {
              const mapped = data.map(mapItem);

              setSearchItems(mapped);
            }).catch(function (error) {
              if (django?.isCancel(error)) {
              } else {
                setSearchItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
              }
            }).finally(() => {
              setSearchItemsLoading(false);
            });
          };

          if (searchQuery === '') {
            setSearchItems([]);
            setSearchItemsLoading(false);
            setSearchItemsLoadingErrorMessage(null);
          }

          if (searchQuery !== '') {
            setSearchItems([]);
            setSearchItemsLoading(true);
            setSearchItemsLoadingErrorMessage(null);

            const CancelToken = django.CancelToken;
            const cancelTokenSource = CancelToken.source();

            const config = {
              params: {
                size: PER_PAGE,
                [valueProp]: `*${searchQuery}*`,
              },
              cancelToken: cancelTokenSource.token,
            };

            const debounce = setTimeout(() => handleGet({ url: listPath, config }), 300);

            return () => {
              cancelTokenSource.cancel();

              clearTimeout(debounce);

              setSearchItemsLoading(false);
            };
          }
        } else {
          const handleGet = (searchQuery) => {
            const list = [...MAPPED_DEFAULT_ITEMS];
            const filterList = list.filter((item) => turkishToLower(item.value).includes(turkishToLower(searchQuery)));
            setSearchItems(filterList);
            setSearchItemsLoading(false);
          }

          if (searchQuery === '') {
            setSearchItems([]);
            setSearchItemsLoading(false);
            setSearchItemsLoadingErrorMessage(null);
          }
          if (searchQuery !== '') {
            setSearchItems([]);
            setSearchItemsLoading(true);
            setSearchItemsLoadingErrorMessage(null);
            const debounce = setTimeout(() => handleGet(searchQuery), 300);
            return () => {
              clearTimeout(debounce);
              setSearchItemsLoading(false);
            };
          }
        }
      }
    }
  }, [mapItem, searchQuery, listPath, valueProp, showSelectedItems, MAPPED_DEFAULT_ITEMS, lastUpdateTime, staticpage, staticdata]);

  // handle search staticpage
  useEffect(() => {
    if (staticpage || staticdata) {
      const handleGet = (searchQuery) => {
        const list = showSelectedItems ? [...MAPPED_DEFAULT_ITEMS] : [...items];
        const filterList = list.filter((item) => turkishToLower(item.value).includes(turkishToLower(searchQuery)));
        setSearchItems(filterList);
        setSearchItemsLoading(false);
      }

      if (searchQuery === '') {
        setSearchItems([]);
        setSearchItemsLoading(false);
        setSearchItemsLoadingErrorMessage(null);
      }
      if (searchQuery !== '') {
        setSearchItems([]);
        setSearchItemsLoading(true);
        setSearchItemsLoadingErrorMessage(null);
        const debounce = setTimeout(() => handleGet(searchQuery), 300);
        return () => {
          clearTimeout(debounce);
          setSearchItemsLoading(false);
        };
      }
    }
  }, [mapItem, searchQuery, showSelectedItems, MAPPED_DEFAULT_ITEMS, lastUpdateTime, staticpage, staticdata, items]);

  const handleCheckItem = (item, checked) => {
    let copyMappedDefaultItems = [...MAPPED_DEFAULT_ITEMS];

    if (checked) {
      copyMappedDefaultItems.push(item);
    } else {
      const index = copyMappedDefaultItems.findIndex((item2) => item2.key === item.key);

      if (index > -1) {
        copyMappedDefaultItems.splice(index, 1);
      }
    }

    // kullanıcının proplarına geri çeviriyoruz
    // copyMappedDefaultItems = copyMappedDefaultItems.map((item) => ({
    //   [keyProp]: item.key,
    //   [valueProp]: item.value,
    // }));

    copyMappedDefaultItems = copyMappedDefaultItems.map((item) => {
      if (extraProp) {
        return { [keyProp]: item.key, [valueProp]: item.value, [extraProp]: item.extra }
      } else {
        return { [keyProp]: item.key, [valueProp]: item.value }
      }
    });

    copyMappedDefaultItems = copyMappedDefaultItems.sort((a, b) => a[valueProp].localeCompare(b[valueProp]));

    onChangeItems(copyMappedDefaultItems);
  };

  const handleRemoveAllItems = () => {
    onChangeItems([]);
  }

  const handleGetMoreOptions = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setMoreItemsLoading(true);
    django(listPath, { params: { size: PER_PAGE, page, sort: valueProp } }).then(({ data }) => {
      const mapped = data.map(mapItem);
      setItems((prev) => [...prev, ...mapped]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setMoreItemsLoading(false);
    });
  };

  const rowRenderer = ({ index, style }) => {
    const item = FILTERED_ITEMS[index];
    const checked = MAPPED_DEFAULT_ITEMS.findIndex((item2) => item2.key === item.key) > -1;
    return (
      <div key={item.key} style={style}
        onContextMenu={(e) => {
          e.preventDefault();
          setSelectedItem(item);
          setSelectedPosition({ top: e.clientY, left: e.clientX });
        }}
      >
        {index !== 0 && <Divider />}
        <ListItem
          item={item}
          checked={checked}
          searchQuery={!showSelectedItems ? searchQuery : ''}
          onClick={(checked) => handleCheckItem(item, checked)}
        ></ListItem>
      </div>
    );
  };

  const handleReload = () => {
    if (staticdata) {
      setLastUpdateTimeStaticdata();
    } else {
      setLastUpdateTime(Date.now());
    }
  };

  return (
    <>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">{title ? title : 'Filtrele'}</h3>
            <span>
              <Tooltip title="Yenile">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={handleReload}
                ><IconRefresh /></IconButton>
              </Tooltip>
              <Tooltip title="Kapat">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={onClose}
                ><IconClose /></IconButton>
              </Tooltip>
            </span>
          </Toolbar>

          <nav className="w-full flex-1 flex items-center justify-between bg-palette-background-default py-2 px-4 space-x-4">
            <span className="relative w-full h-8 leading-none bg-palette-background-paper apperance-none">
              <input
                className={clsx([
                  'absolute inset-0 w-full h-full px-10 bg-transparent rounded border border-palette-action-selected',
                  'focus:outline-none focus:border-primary-300 focus:ring-2 focus:ring-primary-200',
                ])}
                value={searchQuery ?? ''}
                placeholder={showSelectedItems ? 'Seçimlerde ara' : 'Tüm ' + title + ' içinde ara'}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <span className="absolute top-2 left-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                <IconSearch />
              </span>

              {searchQuery.length > 0 &&
                <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchQuery('');
                    }}
                  >
                    <IconClose />
                  </IconButton>
                </span>
              }
            </span>

            <span>
              <Tooltip title="Tüm seçimleri kaldır">
                <IconButton
                  size="small"
                  onClick={() => {
                    handleRemoveAllItems();
                  }}
                >
                  <IconDelete />
                </IconButton>
              </Tooltip>
            </span>

            <span>
              <Tooltip title="Sadece seçili seçenekleri göster">
                <Checkbox
                  color="primary"
                  checked={showSelectedItems}
                  style={{ padding: 0 }}
                  disableRipple
                  onChange={(e, checked) => setShowSelectedItems(checked)}
                ></Checkbox>
              </Tooltip>
            </span>
          </nav>

          <span className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', { 'hidden': !SHOW_LINEAR_PROGRESS })}>
            <LinearProgress style={{ height: 2 }} />
          </span>
        </AppBar>

        <main className="relative flex flex-grow flex-col overflow-hidden" ref={mainContainerRef}>
          <div className="h-full flex-grow">
            {LOADING_MESSAGE && (
              <LoadingMessage text={LOADING_MESSAGE} />
            )}

            {ERROR_MESSAGE && (
              <ErrorMessage text={ERROR_MESSAGE} />
            )}

            {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length === 0) && (
              <ErrorMessage text="Seçenek bulunamadı" />
            )}

            {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length > 0) && (
              <InfiniteLoader
                minimumBatchSize={PER_PAGE}
                threshold={PER_PAGE}
                isRowLoaded={({ index }) => !!FILTERED_ITEMS[index]}
                loadMoreRows={({ startIndex, stopIndex }) => {
                  if (itemsTotalCount > 0 && PAGES_COUNT > PAGE && !moreItemsLoading && !showSelectedItems) {
                    handleGetMoreOptions(PAGE + 1);
                  }
                }}
                rowCount={itemsTotalCount > 0 ? itemsTotalCount : PER_PAGE * 2}
              >
                {({ onRowsRendered, registerChild }) => (
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        width={width}
                        height={height}
                        rowHeight={ROW_HEIGHT + 1}
                        rowCount={FILTERED_ITEMS.length}
                        estimatedRowSize={PAGES_COUNT > 0 ? PAGES_COUNT * (ROW_HEIGHT + 1) : undefined}
                        rowRenderer={rowRenderer}

                        onRowsRendered={onRowsRendered}
                        ref={registerChild}
                        onScroll={() => setSelectedItem(null)}
                      />
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            )}
          </div>
          {selectedItem && !staticpage && !staticdata && (
            <RightMenu
              url={newPath}
              itemId={selectedItem?.key}
              ustId={ustId}
              position={selectedPosition}
            ></RightMenu>
          )}
          {confirmButton && (
            <div className="flex items-center justify-between border-t border-palette-action-selected p-2">
              <span></span>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disableRipple
                  disabled={!confirmEnable}
                  onClick={onConfirm}
                >{confirmBtnTitle}</Button>
              </span>
            </div>
          )}
        </main>
      </Paper>
    </>
  );
};

ListSelector.propTypes = {
  title: PropTypes.string.isRequired,
  listPath: PropTypes.string.isRequired,
  listCountPath: PropTypes.string,
  keyProp: PropTypes.string,
  valueProp: PropTypes.string,
  defaultItems: PropTypes.array,
  onChangeItems: PropTypes.func,
  onClose: PropTypes.func,
};

ListSelector.defaultTypes = {
  listCountPath: undefined,
  keyProp: 'id',
  valueProp: 'tanim',
  defaultItems: [],
  onChangeItems: () => { },
  onClose: () => { },
};

export default ListSelector;
