import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../../pages/base/LayoutFullVerticalTab';
import immer from 'immer';
import { useHistory } from 'react-router-dom';
import URLS from '../../../urls';

import DetailBirim from './DetailBirim';
import DetailGrup from './DetailGrup';
import DetailParaBirim from './DetailParaBirim';
import DetailOzellikBaslik from './DetailOzellikBaslik';
import DetailOzellikDeger from './DetailOzellikDeger';


const TABS_DEFAULT = [
  { tabIndex: 0, label: "Birimler", detailId: DetailBirim, hasError: false },
  { tabIndex: 1, label: "Gruplar", detailId: DetailGrup, hasError: false },
  { tabIndex: 2, label: "Para Birimleri", detailId: DetailParaBirim, hasError: false },
  { tabIndex: 3, label: "Özellik Başlık", detailId: DetailOzellikBaslik, hasError: false },
  { tabIndex: 4, label: "Özellik Değer", detailId: DetailOzellikDeger, hasError: false },
];

const PageEntegrasyonEsleme = () => {
  const [tabs, setTabs] = useState(TABS_DEFAULT);
  const history = useHistory();
  useLayoutEffect(() => {
    setTabs((prev) => {
      return prev.map((tab) => {
        return {
          ...tab,
          detail: <tab.detailId setErrors={handleTabErrors} />,
        };
      });
    });
  }, []);

  const handleTabErrors = (index, value) => {
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[index].hasError = value;
      });
    });
  };

  return (
    <>
      <Layout
        tabs={tabs}
        headerButton={true}
        headerList={[
          { title: "ENTEGRASYON", active: false, func: () => history.push(URLS.tanimlar.entegrasyon.list), index: 0 },
          { title: "EŞLEŞTİRMELER", active: true, func: null, index: 1 },
        ]}
      />
    </>
  );
};

export default PageEntegrasyonEsleme;
