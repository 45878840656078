import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  Tooltip,
} from '@material-ui/core';

import {
  Search as IconSearch,
  HelpOutline as IconHelp
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import ListSelectorSingle from '../../../components/ListSelectorSingle';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1, 1, 1, 4),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button2: {
      position: "absolute",
      left: -24,
      top: '50%',
      transform: 'translateY(-50%)',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        color: '#976797'
      }
    },
  };
};

const MagazaDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const [openSelectorSite, setOpenSelectorSite] = useState(false);

  const [helpText, setHelpText] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 === 'site') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form[name], `${name2}.name`) || defaultValue,
        error: _has(props.error?.[name], `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(props.form[name], `${name2}`) || defaultValue,
        error: _has(props.error?.[name], `${name2}`),
        fullWidth: true,
      };
    }
  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="old_path">
                    <label className={classes.layoutFormItemLabel} htmlFor="old_path">Yönlendirilen Adres *</label>
                    <Tooltip title={props.error?.redirect?.old_path ? props.error.redirect.old_path[0] : _isNil(props.form?.redirect?.old_path) ? '' : props.form.redirect.old_path}>
                      <TextField
                        {...createTextFieldProps('redirect', 'old_path')}
                        onChange={(e) => props.onChangeForm('redirect', 'old_path', e.target.value)}
                        onBlur={() => handleInputErrors('redirect', props.form, props.formdefault, props.onCheckErrors, add, 'redirect', true, false, false, '', '', '', 'old_path')}
                        inputProps={{
                          maxLength: 200,
                        }}
                      ></TextField>
                    </Tooltip>
                    <IconButton
                      size="small"
                      className={classes.button2}
                      disableRipple
                      onClick={() => {
                        setHelpText("“Bu, bir tam yol olmalıdır, etki alanı adı kullanılmadan yazılmalı. Örnek: '/olaylar/arama/'.”");
                        setHelpOpen(true)
                      }}
                    ><IconHelp /></IconButton>
                  </li>
                  <li className={classes.layoutFormItem} id="new_path">
                    <label className={classes.layoutFormItemLabel} htmlFor="new_path">Yönlendirildiği Adres</label>
                    <Tooltip title={props.error?.redirect?.new_path ? props.error.redirect.new_path[0] : _isNil(props.form?.redirect?.new_path) ? '' : props.form.redirect.new_path}>
                      <TextField
                        {...createTextFieldProps('redirect', 'new_path')}
                        onChange={(e) => props.onChangeForm('redirect', 'new_path', e.target.value)}
                        inputProps={{
                          maxLength: 200,
                        }}
                      ></TextField>
                    </Tooltip>
                    <IconButton
                      size="small"
                      className={classes.button2}
                      disableRipple
                      onClick={() => {
                        setHelpText("“Bu, ya bir tam yol (yukarıdaki gibi) ya da “https://” gibi bir şema ile başlayan tam bir URL olabilir.”");
                        setHelpOpen(true)
                      }}
                    ><IconHelp /></IconButton>
                  </li>
                  <li className={classes.layoutFormItem} id="site">
                    <label className={classes.layoutFormItemLabel} htmlFor="site">Site *</label>
                    <Tooltip title={props.error?.redirect?.site ? props.error.redirect.site[0] : _isNil(props.form?.redirect?.site) ? '' : props.form.redirect.site.name}>
                      <TextField
                        {...createTextFieldProps('redirect', 'site')}
                        InputProps={{
                          endAdornment:
                            <IconButton
                              size="small"
                              className={classes.button}
                              disableRipple
                              onClick={() => setOpenSelectorSite(true)}
                            ><IconSearch /></IconButton>
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>
                </ul>
              </div>
              {openSelectorSite &&
                <Dialog
                  open={openSelectorSite}
                  PaperProps={{
                    style: {
                      width: '480px',
                      height: '600px',
                    },
                  }}
                  onClose={() => {
                    setOpenSelectorSite(false);
                    handleInputErrors('magaza', props.form, props.formdefault, props.onCheckErrors, add, 'site', true, false, '', '')
                  }}
                >
                  <DialogContent style={{ padding: 0 }}>
                    <ListSelectorSingle
                      title='Siteler'
                      listPath='site'
                      listCountPath='site/count'
                      keyProp='id'
                      valueProp='name'
                      defaultItem={props.form.redirect.site}
                      onChangeItems={(items) => props.onChangeForm('redirect', 'site', items)}
                      onClose={() => {
                        setOpenSelectorSite(false);
                        handleInputErrors('redirect', props.form, props.formdefault, props.onCheckErrors, add, 'redirect', true, false, false, '', '', '', 'site')
                      }}
                      newPath='tanimlar/site'
                    />
                  </DialogContent>
                </Dialog>
              }
              {helpOpen &&
                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                >
                  <DialogContent>
                    <DialogContentText>
                      <span className="text-center text-sm text-palette-text-primary">
                        {helpText}
                      </span>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              }
            </>
          )}
        </>
      }
    </>
  );
});

export default MagazaDetailTabGenel;
