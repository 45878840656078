import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox
} from '@material-ui/core';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const defaultTheme = createTheme();

const theme = {
  overrides: {
    MuiDialog: {
      paper: {
        width: '320px',
      },
    },

    MuiDialogTitle: {
      root: {
        padding: defaultTheme.spacing(2, 3, 1, 3),
      },
    },

    MuiDialogContentText: {
      root: {
        marginBottom: undefined,
      },
    },

    MuiDialogActions: {
      root: {
        padding: defaultTheme.spacing(1, 3, 2, 3),
      },
    },

    MuiButton: {
      root: {
        '&:nth-child(2)': {
          marginLeft: defaultTheme.spacing(3),
        },
      },
    },
  },
};

const DialogExtraConfirm = ({ title, message, cancelText, submitText, confirmText, onCancel, onSubmit, cancelButtonProps, submitButtonProps, isConfirm, onConfirm }) => {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={true}
        onClose={onCancel}
        //onEscapeKeyDown={onCancel}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <div className="flex items-center justify-center">
            <Checkbox
              name="aktif"
              color="primary"
              checked={isConfirm}
              onChange={(e, checked) => onConfirm(checked)}
            ></Checkbox>
            <DialogContentText>{confirmText}</DialogContentText>
          </div>
        </DialogContent>

        <DialogActions disableSpacing>

          <Button
            onClick={onCancel}
            {...cancelButtonProps}
          >{cancelText}</Button>

          <Button
            disabled={!isConfirm}
            onClick={onSubmit}
            {...submitButtonProps}
          >{submitText}</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

DialogExtraConfirm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  confirmText: PropTypes.string,
  isConfirm: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelButtonProps: PropTypes.object,
  submitButtonProps: PropTypes.object,
};

export default DialogExtraConfirm;
