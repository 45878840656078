import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors2 } from '../../base/InputErrors';

const styles = ({ palette, spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 0, 1, 0),
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
  };
};

const GrupDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const LOADINGDIL = useMemo(() => props.loadingdil, [props.loadingdil]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATADIL = useMemo(() => props.hataDil, [props.hataDil]);
  const add = useMemo(() => props.add, [props.add]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.dillerForm?.length ?? 0, [props.dillerForm]);
  const FORM = useMemo(() => props?.dillerForm?.[selectedTabIndex]?.form ?? null, [props.dillerForm, selectedTabIndex]);
  const ERROR = useMemo(() => props?.dillerForm?.[selectedTabIndex]?.errors ?? null, [props.dillerForm, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.dillerForm?.length > 0) {
      const copyForms = [...props.dillerForm];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.dillerForm]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const createTextFieldPropsDil = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(FORM?.[name], `${name2}`) || defaultValue,
        error: _has(ERROR?.[name], `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(FORM, `${name}`) || defaultValue,
        error: _has(ERROR, `${name}`),
        fullWidth: true,
      };
    }
  }, [FORM, ERROR]);

  const handleChangeForm = (property, property2, value) => {
    props.onChangeFormDiller(selectedTabIndex, property, property2, value);
  };

  const handleSetErrors = (errors) => {
    props.onCheckErrorsDiller(errors, selectedTabIndex);
  }

  const handleDetailDelete = (index) => {
    if (props.dillerForm[index].form.id) {
      props.openDeleteForm(true);
      props.setDeleteId(props.dillerForm[index].form.id);
    } else {
      props.deleteDetailWithIndex(index);
    }
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Grup Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim')}
                          onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                          onBlur={() => handleInputErrors('grup', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Grup Tanım', 'grup')}
                          inputProps={{
                            maxLength: 250,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="tip">
                      <label className={classes.layoutFormItemLabel} htmlFor="tip">Tip *</label>
                      <Tooltip title={props.error?.tip ? props.error.tip[0] : _isNil(props.form?.tip) ? '' : props.tipListDic ? props.tipListDic[props.form.tip] : ''}>
                        <TextField
                          {...createTextFieldProps('tip')}
                          onChange={(e) => props.onChangeForm('tip', e.target.value)}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          {props.tipList.map((tip) => (
                            <option key={tip.key} value={tip.key}>{tip.value}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="siralama">
                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                      <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                        <TextField
                          {...createTextFieldProps('siralama')}
                          onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                          type="number"
                          inputProps={{
                            min: -2147483648,
                            max: 2147483647,
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                      ></Checkbox>
                    </li>
                  </ul>
                </div>

                <div className={classes.layoutContainerTab}>
                  <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                  {!LOADINGDIL && (
                    <>
                      {HATADIL && (
                        <Hata />
                      )}
                      {!HATADIL && (
                        <LayoutDetailVerticalTab
                          tabs={tabs}
                          selectedTabIndex={selectedTabIndex}
                          onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                          buttonShow={false}
                          delete={handleDetailDelete}
                          deleteButtonShow={true}
                        >
                          {COUNT_TABS === 0 && (
                            <div className="flex flex-col items-start justify-start p-4 space-y-4">
                              <Typography variant="body1">Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                            </div>
                          )}
                          {COUNT_TABS > 0 && (
                            <div className={classes.layoutContainer}>
                              <div className={classes.column}>
                                <ul className={classes.layoutFormList}>
                                  <li className={classes.layoutFormItem} id="slug">
                                    <label className={classes.layoutFormItemLabel} htmlFor="slug">Slug(URL) *</label>
                                    <Tooltip title={ERROR?.slug ? ERROR.slug[0] : _isNil(FORM?.slug) ? '' : FORM.slug}>
                                      <TextField
                                        {...createTextFieldPropsDil('slug', '')}
                                      ></TextField>
                                    </Tooltip>
                                  </li>
                                  <li className={classes.layoutFormItem} id="dil_icerik.tanim">
                                    <label className={classes.layoutFormItemLabel} htmlFor="dil_icerik.tanim">Tanım *</label>
                                    <Tooltip title={ERROR?.dil_icerik?.tanim ? ERROR.dil_icerik.tanim[0] : _isNil(FORM?.dil_icerik?.tanim) ? '' : FORM.dil_icerik.tanim}>
                                      <TextField
                                        {...createTextFieldPropsDil('dil_icerik', 'tanim')}
                                        onChange={(e) => handleChangeForm('dil_icerik', 'tanim', e.target.value)}
                                        onBlur={() => {
                                          handleInputErrors2(FORM, 'dil_icerik', handleSetErrors, 'tanim');
                                        }}
                                        inputProps={{
                                          maxLength: 200,
                                        }}
                                      ></TextField>
                                    </Tooltip>
                                  </li>
                                  <li className={classes.layoutFormItem} id="dil_icerik.baslik">
                                    <label className={classes.layoutFormItemLabel} htmlFor="dil_iceril.baslik">Başlık *</label>
                                    <Tooltip title={ERROR?.dil_icerik?.baslik ? ERROR.dil_icerik.baslik[0] : _isNil(FORM?.dil_icerik?.baslik) ? '' : FORM.dil_icerik.baslik}>
                                      <TextField
                                        {...createTextFieldPropsDil('dil_icerik', 'baslik')}
                                        onChange={(e) => handleChangeForm('dil_icerik', 'baslik', e.target.value)}
                                        onBlur={() => handleInputErrors2(FORM, 'dil_icerik', handleSetErrors, 'baslik')}
                                      ></TextField>
                                    </Tooltip>
                                  </li>
                                  <li className={classes.layoutFormItem} id="dil_icerik.bilgilendirme">
                                    <label className={classes.layoutFormItemLabel} htmlFor="dil_icerik.bilgilendirme">Bilgilendirme</label>
                                    <Tooltip title={ERROR?.dil_icerik?.bilgilendirme ? ERROR.dil_icerik.bilgilendirme[0] : _isNil(FORM?.dil_icerik?.bilgilendirme) ? '' : FORM.dil_icerik.bilgilendirme}>
                                      <TextField
                                        {...createTextFieldPropsDil('dil_icerik', 'bilgilendirme')}
                                        onChange={(e) => handleChangeForm('dil_icerik', 'bilgilendirme', e.target.value)}
                                      ></TextField>
                                    </Tooltip>
                                  </li>
                                  <li className={classes.layoutFormItem} id="dil_icerik.anahtar_kelimeler">
                                    <label className={classes.layoutFormItemLabel} htmlFor="dil_icerik.anahtar_kelimeler">Anahtar Kelimeler</label>
                                    <Tooltip title={ERROR?.dil_icerik?.anahtar_kelimeler ? ERROR.dil_icerik.anahtar_kelimeler[0] : _isNil(FORM?.dil_icerik?.anahtar_kelimeler) ? '' : FORM.dil_icerik.anahtar_kelimeler}>
                                      <TextField
                                        {...createTextFieldPropsDil('dil_icerik', 'anahtar_kelimeler')}
                                        onChange={(e) => handleChangeForm('dil_icerik', 'anahtar_kelimeler', e.target.value)}
                                      ></TextField>
                                    </Tooltip>
                                  </li>
                                  <li className={classes.layoutFormItem} id="dil_icerik','kisa_aciklama">
                                    <label className={classes.layoutFormItemLabel} htmlFor="dil_icerik','kisa_aciklama">Kısa Açıklama</label>
                                    <Tooltip title={ERROR?.dil_icerik?.kisa_aciklama ? ERROR.dil_icerik.kisa_aciklama[0] : _isNil(FORM?.dil_icerik?.kisa_aciklama) ? '' : FORM.dil_icerik.kisa_aciklama}>
                                      <TextField
                                        {...createTextFieldPropsDil('dil_icerik', 'kisa_aciklama')}
                                        onChange={(e) => handleChangeForm('dil_icerik', 'kisa_aciklama', e.target.value)}
                                      ></TextField>
                                    </Tooltip>
                                  </li>
                                  <li className={classes.layoutFormItem} id="dil_icerik','aciklama">
                                    <label className={classes.layoutFormItemLabel} htmlFor="dil_icerik','aciklama">Açıklama</label>
                                    <Tooltip title={ERROR?.dil_icerik?.aciklama ? ERROR.dil_icerik.aciklama[0] : _isNil(FORM?.dil_icerik?.aciklama) ? '' : FORM.dil_icerik.aciklama}>
                                      <TextField
                                        {...createTextFieldPropsDil('dil_icerik', 'aciklama')}
                                        onChange={(e) => handleChangeForm('dil_icerik', 'aciklama', e.target.value)}
                                        multiline
                                        rows={4}
                                      ></TextField>
                                    </Tooltip>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </LayoutDetailVerticalTab>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      }
    </>
  );

});

export default GrupDetailTabGenel;
