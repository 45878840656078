import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';
import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './KargoDetailTab_Genel';
import TabDesi from './KargoDetailTab_Desi';

const PATH = 'kargo';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'DESİ', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  tanim: '',
  aktif: true,
  siralama: null,
  calisma_tip: 0,
  ucretsiz_kargo_deger: '0.00',
  tahmini_teslim_suresi: null,
  fiyat: '0.0000',
  magazalar: [],
  para_birim: '-1',
  desi_alt_limit: '0.00',
  desi_ust_limit: '0.00',
  vergi: null,
  vergi_baslik: null,
  vergi_tree_list: [],
};

const KARGO_DESI_DEFAULT = {
  form: {
    min_desi: '0.0000',
    max_desi: '0.0000',
    fiyat: '0.0000'
  },
  errors: null
};

const DILLER_FORM_DEFAULT = {
  dil_id: '',
  tanim: '',
  ucretsiz_etiket: '',
  fiyat_sablon: '',
  ucretsiz_limit_sablon: ''
};

const createDillerStates = async (diller, kargodiller) => {
  const states = [];
  diller.sort((a, b,) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...DILLER_FORM_DEFAULT,
        dil_id: dil.id,
      },
      errors: null,
    };
    const kargodil = kargodiller?.find((bd) => bd.dil_id === dil.id);
    if (kargodil) {
      data.form.dil_id = kargodil.dil_id;
      data.form.tanim = kargodil.tanim;
      data.form.ucretsiz_etiket = kargodil.ucretsiz_etiket;
      data.form.fiyat_sablon = kargodil.fiyat_sablon;
      data.form.ucretsiz_limit_sablon = kargodil.ucretsiz_limit_sablon;
    }
    states.push(data);
  });
  return states;
};

const KargoDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingDil, setLoadingDil] = useState(true);
  const [loadingDesi, setLoadingDesi] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataDil, setHataDil] = useState(null);
  const [hataDesi, setHataDesi] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [dillerSelectedTabIndex, setDillerSelectedTabIndex] = useState(0);
  const [dillerFormDefault, setDillerFormDefault] = useState(null);
  const [dillerForm, setDillerForm] = useState(null);
  const [dillerFormAdd, setDillerFormAdd] = useState(null);
  const [kargoDilleri, setKargoDilleri] = useState([]);

  const [desiSelectedTabIndex, setDesiSelectedTabIndex] = useState(0);
  const [desiFormsDefault, setDesiFormsDefault] = useState([]);
  const [desiForms, setDesiForms] = useState([]);
  const [desiAdd, setDesiAdd] = useState(true);

  const [calismaTipler, setCalismaTipler] = useState([]);
  const [calismaTiplerDic, setCalismaTiplerDic] = useState({});
  const [paraBirim, setParaBirim] = useState([]);
  const [paraBirimDic, setParaBirimDic] = useState({});
  const [dilMiniList, setDilMiniList] = useState([]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteId, setDeleteIdDetail] = useState(null);
  const [isDeletingDetail, setIsDeletingDetail] = useState(false);
  const [isDeletingErrorMessageDetail, setIsDeletingErrorMessageDetail] = useState(null);
  const [desiShowDeleteDialogDetail, setDesiShowDeleteDialogDetail] = useState(false);

  const [type, setType] = useState(props.eventType ?? 0);
  const [loadDone, setLoadDone] = useState(false);
  const [loadTab1, setLoadTab1] = useState(false);
  const [loadTab2, setLoadTab2] = useState(false);
  const [equal1, setEqual1] = useState(true);
  const [equal2, setEqual2] = useState(true);
  const [saveErrors, setSaveErrors] = useState([[], []]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props.id]);
  const IS_ADD = useMemo(() => props.addnew, [props.addnew]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(dillerForm, dillerFormAdd) && _isEqual(desiForms, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualDiller = _isEqual(dillerForm, dillerFormDefault);
    const isEqualDesiler = _isEqual(desiForms, desiFormsDefault);
    return isEqualGenel && isEqualDiller && isEqualDesiler;
  }, [add, genelForm, genelFormDefault, dillerForm, dillerFormDefault, desiForms, desiFormsDefault, dillerFormAdd]);

  useLayoutEffect(() => {
    setAdd(false);
    setLoadDone(false);
  }, [ID]);

  useLayoutEffect(() => {
    if (loadDone) {
      setType(2);
    } else {
      setType(props.eventType ?? 0);
    }
  }, [props.eventType, loadDone]);

  useLayoutEffect(() => {
    if (type === 0) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setKargoDilleri([]);
      setDesiForms([]);
      setDesiFormsDefault([]);
      setTabs(TABS_DEFAULT);
      if (selectedTabIndex === 0) {
        setLoadTab1(true);
      } else {
        setLoadTab1(true);
        setLoadTab2(true);
      }
      setLoadDone(true);
    } else if (type === 1) {
      if (selectedTabIndex === 0) {
        setGenelForm(null);
        setGenelFormDefault(null);
        setKargoDilleri([]);
        if (!saveErrors[1].includes("err")) {
          setDesiForms([]);
          setDesiFormsDefault([]);
        }
        setLoadTab1(true);
      } else {
        setGenelForm(null);
        setGenelFormDefault(null);
        setKargoDilleri([]);
        setLoadTab1(true);
        if (!saveErrors[1].includes("err")) {
          setDesiForms([]);
          setDesiFormsDefault([]);
          setLoadTab2(true);
        }
      }
      setLoadDone(true);
    } else {
      if (!add) {
        if (selectedTabIndex === 0) {
          if (equal1) {
            setLoadTab1(true);
          }
        } else {
          if (equal2) {
            setLoadTab2(true);
          }
        }
      } else {
        setLoading(false);
        setLoadingDil(false);
        setLoadingDesi(false);
        setLoadTab1(false);
        setLoadTab2(false);
      }
    }
  }, [type, selectedTabIndex, saveErrors, equal1, equal2, add]);

  useLayoutEffect(() => {
    if (add) {
      setEqual1(_isEqual(genelForm, FORM_DEFAULT) && _isEqual(dillerForm, dillerFormAdd));
    } else {
      setEqual1(_isEqual(genelForm, genelFormDefault) && _isEqual(dillerForm, dillerFormDefault));
    }
  }, [add, genelForm, genelFormDefault, dillerForm, dillerFormDefault, dillerFormAdd]);

  useLayoutEffect(() => {
    if (add) {
      setEqual2(_isEqual(desiForms, []));
    } else {
      setEqual2(_isEqual(desiForms, desiFormsDefault));
    }
  }, [add, desiForms, desiFormsDefault]);

  const LOADING = useMemo(() => {
    if (!loading && !loadingDil) {
      return false;
    }
    return true;
  }, [loading, loadingDil]);

  useLayoutEffect(() => {
    if (loadTab1) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              aktif: data.aktif,
              siralama: String(data.siralama),
              calisma_tip: String(data.calisma_tip.key),
              ucretsiz_kargo_deger: data.ucretsiz_kargo_deger,
              tahmini_teslim_suresi: String(data.tahmini_teslim_suresi),
              fiyat: data.fiyat,
              diller: data.diller,
              magazalar: data.magazalar,
              para_birim: String(data.para_birim),
              desi_alt_limit: data.desi_alt_limit,
              desi_ust_limit: data.desi_ust_limit,
              vergi: data.vergi ? { id: data.vergi.id, tanim: data.vergi.tanim, baslik_id: data.vergi.vergi_tip.id, baslik_tanim: data.vergi.vergi_tip.tanim } : null,
              vergi_baslik: data.vergi ? { id: data.vergi.vergi_tip.id, tanim: data.vergi.vergi_tip.tanim } : null,
              vergi_tree_list: data.vergi ? [{ title: data.vergi.vergi_tip.tanim, list: [{ key: data.vergi.id, value: data.vergi.tanim }] }] : [],
            };
            setGenelForm(form);
            setGenelFormDefault(form);
            setKargoDilleri(form.diller);
            setHataGenel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setLoadTab1(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DEFAULT);
        setGenelFormDefault(FORM_DEFAULT);
        setKargoDilleri([]);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
        setLoadTab1(false);
      }
    }
  }, [ID, IS_ADD, loadTab1]);

  useLayoutEffect(() => {
    django('dil/mini_list').then(({ data }) => setDilMiniList(data)).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataDil(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    createDillerStates(dilMiniList, []).then((states) => {
      setDillerFormAdd(states);
    })
  }, [dilMiniList]);

  useLayoutEffect(() => {
    django(`${PATH}/calisma_tip`).then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setCalismaTiplerDic(dict);
      setCalismaTipler(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django('parabirim/mini_list').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      setParaBirimDic(dict);
      setParaBirim(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setLoadingDil(true);
    createDillerStates(dilMiniList, kargoDilleri).then((states) => {
      setDillerFormDefault(states);
      setDillerForm(states);
    }).finally(() => {
      setLoadingDil(false);
    });
  }, [dilMiniList, kargoDilleri]);

  useLayoutEffect(() => {
    if (loadTab2) {
      setLoadingDesi(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/desi`).then(({ data }) => {
            const forms = data.map((desi) => (
              {
                form: {
                  id: desi.id,
                  min_desi: desi.min_desi,
                  max_desi: desi.max_desi,
                  fiyat: desi.fiyat
                },
                errors: null
              }
            ));
            setDesiFormsDefault(forms);
            setDesiForms(forms);
            setHataDesi(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataDesi(true);
              }
            }
          }).finally(() => {
            setLoadingDesi(false);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        }
      } else {
        setDesiForms([]);
        setDesiFormsDefault([]);
        setHataDesi(null);
        setLoadingDesi(false);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, loadTab2]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    if (property === 'vergi') {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next[property] = value;
          next["vergi_baslik"] = value ? { id: value.baslik_id, tanim: value.baslik_tanim } : null;
          next["vergi_tree_list"] = value ? [{ title: value.baslik_tanim, list: [{ key: value.id, value: value.tanim }] }] : [];
        })
      });
    } else {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next[property] = value;
        });
      });
    }
  };

  const handleDillerChangeForm = (index, property, value) => {
    setDillerForm((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      });
    });
  }

  const handleDesiChangeForm = (index, property, value) => {
    setDesiForms((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      });
    });
  };

  const handleDesiAddNewTab = () => {
    setDesiAdd(false);
    setDesiForms((prev) => immer(prev, (next) => {
      next.push({ ...KARGO_DESI_DEFAULT });
    }));
  }

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DEFAULT);
    setDillerForm(dillerFormAdd);
    setGenelError(null);
    setDesiForms([]);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
    setLoadTab1(true);
    setLoadTab2(true);
    setDesiAdd(true);
    setTabs(TABS_DEFAULT);
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setDillerForm(dillerFormDefault);
    setGenelError(null);
    setDesiForms(desiFormsDefault);
    setDesiAdd(true);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
      });
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.kargo.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteDilIndex = (index) => {
    setDillerForm((prev) => {
      return immer(prev, (next) => {
        next[index].form = { ...DILLER_FORM_DEFAULT, dil_id: next[index].form.dil_id };
        next[index].errors = null;
      })
    });
  }

  const handleDesiDeleteDetail = () => {
    setIsDeletingDetail(true);
    setIsDeletingErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/desi/${detailDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setDesiShowDeleteDialogDetail(false);
        setIsDeletingErrorMessageDetail(null);
      } else {
        setIsDeletingErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingDetail(false);
      setLoadTab2(true);
    });
  }

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Kargo Tanım', pagename: 'kargo', prop2: '' },
      { prop: 'para_birim', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'fiyat', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'vergi', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'ucretsiz_kargo_deger', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'tahmini_teslim_suresi', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'magazalar', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ];
    let errors = [];
    await checkAllList('kargo', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {
    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (add ? _isEqual(genelForm, FORM_DEFAULT) && _isEqual(dillerForm, dillerFormAdd) : _isEqual(genelForm, genelFormDefault) && _isEqual(dillerForm, dillerFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            const diller = dillerForm.filter((dil) => (!_isEmpty(dil.form.tanim))).map((d) => d.form);
            const data = { ...genelForm, diller: diller, magazalar: genelForm.magazalar?.map((m) => m.id), siralama: genelForm.siralama ? Number(genelForm.siralama) : 0, calisma_tip: Number(genelForm.calisma_tip), tahmini_teslim_suresi: Number(genelForm.tahmini_teslim_suresi), para_birim: genelForm.para_birim !== '-1' ? Number(genelForm.para_birim) : null, vergi: genelForm.vergi?.id ?? null, }
            reqopt = {
              data: data,
              method: add ? 'POST' : 'PUT',
              id: null,
              successMessage: `Kargo ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Kargo ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }
    const createRequestOptionsTabListe = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = desiFormsDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);

      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'min_desi', prop2: '' },
          { prop: 'max_desi', prop2: '' },
          { prop: 'fiyat', prop2: '' },
        ];
        await checkAllTabs(next.form, (err) => handleErrorsDetail(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const form = { ...next.form };
            if (isHas) {
              reqopt = {
                data: form,
                method: 'PUT',
                id: next.form.id,
                successMessage: `${form.tanim} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${form.tanim} güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: form,
                method: 'POST',
                id: null,
                successMessage: `${form.tanim} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${form.tanim} eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    let errors = [[], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'desi', request: [], error: [] },
      ];
      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in desiForms) {
        const desistate = [...desiForms];
        const res = await createRequestOptionsTabListe(desistate[i], i);
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "kargo" : `kargo/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.kargo.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                setDillerSelectedTabIndex(dillerSelectedTabIndex);
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }

            if (tab.name === 'desi') {
              setDesiForms((prev) => {
                return immer(prev, (next) => {
                  next[requestIndex].errors = null;
                })
              });
              if (SAVEID !== null) {
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `kargo/${SAVEID}/desi/${request.id}` : `kargo/${SAVEID}/desi`
                }).then(() => {
                  setDesiAdd(true);
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setDesiAdd(false);
                  setSelectedTabIndex(tab.index);
                  setDesiSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setDesiForms((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }
    await start();
    if (SAVEID !== null) {
      if (!add) {
        if (!errors[0].includes("err")) {
          setLoadTab1(true);
        }
        if (!errors[1].includes("err")) {
          setLoadTab2(true);
        }
      } else {
        setSaveErrors(errors);
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
      await checkAll();
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? false : true : true;
        next[1].hasError = !errors[1].includes("err") ? false : true;
      });
    });
  };

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsDil = (errors, index) => {
    setDillerForm((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsDetail = (errors, index) => {
    setDesiForms((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={LOADING}
            hata={hataGenel}
            hataDil={hataDil}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            calismaTipler={calismaTipler}
            calismaTiplerDic={calismaTiplerDic}
            onChangeForm={handleGenelChangeForm}
            paraBirim={paraBirim}
            paraBirimDic={paraBirimDic}

            selectedTabIndex={dillerSelectedTabIndex}
            dilform={dillerForm}
            onChangeDilForm={handleDillerChangeForm}
            deleteDetailWithIndex={handleDeleteDilIndex}
            onCheckErrors={(errors) => handleErrors(errors)}

            onCheckErrorsDil={(errors, index) => handleErrorsDil(errors, index)}
          />
        )}

        {selectedTabIndex === 1 && (
          <TabDesi
            loading={loadingDesi}
            hata={hataDesi}

            selectedTabIndex={desiSelectedTabIndex}
            forms={desiForms}
            onChangeForm={handleDesiChangeForm}
            onAddNewTab={handleDesiAddNewTab}
            desiAdd={desiAdd}

            setDeleteId={setDeleteIdDetail}
            openDeleteForm={setDesiShowDeleteDialogDetail}

            onCheckErrors={(errors, index) => handleErrorsDetail(errors, index)}
          />
        )}
      </LayoutDetail>


      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Kargo siliniyor lütfen bekleyin'
                : 'Bu kargoyu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
      {desiShowDeleteDialogDetail &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetail
              ? isDeletingErrorMessageDetail
              : isDeletingDetail
                ? 'Desi siliniyor lütfen bekleyin'
                : 'Bu desiyi gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetail}
          onSubmit={handleDesiDeleteDetail}
          onCancel={() => setDesiShowDeleteDialogDetail(false)}
        ></DialogConfirm>
      }

    </>
  );
}

export default KargoDetail;