import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Typography,
  Checkbox,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },
    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: 0,
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
  };
};

const OdemeVade = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATADIL = useMemo(() => props.hataDil, [props.hataDil]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const [tabsDil, setTabsDil] = useState([]);
  const [selectedTabIndexDil, setSelectedTabIndexDil] = useState(props.selectedTabIndexDil);

  const COUNT_TABS = useMemo(() => props?.forms?.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);


  const COUNT_TABS_DİL = useMemo(() => props?.dilform?.length ?? 0, [props]);
  const FORM_DIL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.form ?? null, [props, selectedTabIndex, selectedTabIndexDil]);
  const ERROR_DIL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.errors ?? null, [props, selectedTabIndex, selectedTabIndexDil]);


  useLayoutEffect(() => {
    if (props.dilform?.length > 0) {
      const copyForms = [...props.dilform];
      const newTabs = copyForms[selectedTabIndex]?.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabsDil(newTabs);
    } else {
      setTabsDil([]);
    }

  }, [props.dilform, selectedTabIndex]);


  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((vade) => {
        return {
          label: vade.form.tanim,
          hasError: vade.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);

  useLayoutEffect(() => {
    if (!props.vadeAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.vadeAdd, COUNT_TABS]);


  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM_DIL, `${name}`) || defaultValue,
      error: _has(ERROR_DIL, `${name}`),
      fullWidth: true,
    };
  }, [FORM_DIL, ERROR_DIL]);

  const handleChangeForm = (property, value) => {
    props.onChangeForm(selectedTabIndex, property, value);
  };

  const handleChangeFormDil = (property, value) => {
    props.onChangeDilForm(selectedTabIndex, selectedTabIndexDil, property, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }

  const handleSetErrorsDil = (errors) => {
    props.onCheckErrorsDil(errors, selectedTabIndex, selectedTabIndexDil);
  }

  const handleDetailDil = (index) => {
    props.deleteDetailDilWithIndex(selectedTabIndex, index);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <LayoutDetailVerticalTab
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                add={props.onAddNewTab}
                buttonText={"YENİ VADE EKLE"}
                buttonShow={props.vadeAdd}
                delete={handleDetailDelete}
                flexDirection='column'
              >
                {COUNT_TABS === 0 && (
                  <div className="flex flex-col items-start justify-start p-4 space-y-4">
                    <Typography variant="body1">
                      Ödeme tanımına ait vade bulunamadı.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      onClick={props.onAddNewTab}
                    >YENİ VADE EKLE</Button>
                  </div>
                )}
                {COUNT_TABS > 0 &&
                  <>
                    <div className={classes.tabDiv}>
                      <div className={classes.column}>
                        <ul className={classes.formList}>

                          <li className={classes.formItem} id="tanim">
                            <label className={classes.formItemLabel} htmlFor="tanim">Vade Tanım *</label>
                            <Tooltip title={ERROR?.tanim ? ERROR.tanim[0] : _isNil(FORM?.tanim) ? '' : FORM.tanim}>
                              <TextField
                                {...createTextFieldProps('tanim')}
                                onChange={(e) => handleChangeForm('tanim', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'tanim', handleSetErrors)}
                                inputProps={{
                                  maxLength: 250,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="ay">
                            <label className={classes.formItemLabel} htmlFor="ay">Ay *</label>
                            <Tooltip title={ERROR?.ay ? ERROR.ay[0] : _isNil(FORM?.ay) ? '' : FORM.ay}>
                              <TextField
                                {...createTextFieldProps('ay')}
                                onChange={(e) => handleChangeForm('ay', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'ay', handleSetErrors)}
                                type="number"
                                inputProps={{
                                  min: -2147483648,
                                  max: 2147483647,
                                  step: 1,
                                  style: { textAlign: 'right' }
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="ek_taksit">
                            <label className={classes.formItemLabel} htmlFor="ek_taksit">Ek Taksit</label>
                            <Tooltip title={ERROR?.ek_taksit ? ERROR.ek_taksit[0] : _isNil(FORM?.ek_taksit) ? '' : FORM.ek_taksit}>
                              <TextField
                                {...createTextFieldProps('ek_taksit')}
                                onChange={(e) => handleChangeForm('ek_taksit', e.target.value)}
                                type="number"
                                inputProps={{
                                  min: -2147483648,
                                  max: 2147483647,
                                  step: 1,
                                  style: { textAlign: 'right' }
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="vade_farki">
                            <label className={classes.formItemLabel} htmlFor="vade_farki">Vade Farkı *</label>
                            <Tooltip title={ERROR?.vade_farki ? ERROR.vade_farki[0] : _isNil(FORM?.vade_farki) ? '' : FORM.vade_farki}>
                              <TextField
                                {...createTextFieldProps('vade_farki')}
                                onChange={(e) => handleChangeForm('vade_farki', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'vade_farki', handleSetErrors)}
                                type="number"
                                inputProps={{
                                  style: { textAlign: 'right' }
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="hesaba_gecis_vade_gunu">
                            <label className={classes.formItemLabel} htmlFor="hesaba_gecis_vade_gunu">Hesaba Geçiş Vade Günü</label>
                            <Tooltip title={ERROR?.hesaba_gecis_vade_gunu ? ERROR.hesaba_gecis_vade_gunu[0] : _isNil(FORM?.hesaba_gecis_vade_gunu) ? '' : FORM.hesaba_gecis_vade_gunu}>
                              <TextField
                                {...createTextFieldProps('hesaba_gecis_vade_gunu')}
                                onChange={(e) => handleChangeForm('hesaba_gecis_vade_gunu', e.target.value)}
                                type="number"
                                inputProps={{
                                  min: -32768,
                                  max: 32767,
                                  step: 1,
                                  style: { textAlign: 'right' }
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="siralama">
                            <label className={classes.formItemLabel} htmlFor="siralama">Sıralama</label>
                            <Tooltip title={ERROR?.siralama ? ERROR.siralama[0] : _isNil(FORM?.siralama) ? '' : FORM.siralama}>
                              <TextField
                                {...createTextFieldProps('siralama')}
                                onChange={(e) => handleChangeForm('siralama', e.target.value)}
                                type="number"
                                inputProps={{
                                  min: -2147483648,
                                  max: 2147483647,
                                  step: 1,
                                  style: { textAlign: 'right' }
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="farki_ilk_aya_uygula">
                            <label className={classes.formItemLabel} htmlFor="farki_ilk_aya_uygula">Farkı İlk Aya Uygula</label>
                            <Checkbox
                              name="farki_ilk_aya_uygula"
                              color="primary"
                              checked={_result(FORM, 'farki_ilk_aya_uygula', false)}
                              onChange={(e, checked) => handleChangeForm('farki_ilk_aya_uygula', checked)}
                            ></Checkbox>
                          </li>

                          <li className={classes.formItem} id="aktif">
                            <label className={classes.formItemLabel} htmlFor="aktif">Aktif</label>
                            <Checkbox
                              name="aktif"
                              color="primary"
                              checked={_result(FORM, 'aktif', false)}
                              onChange={(e, checked) => handleChangeForm('aktif', checked)}
                            ></Checkbox>
                          </li>
                        </ul>

                      </div>
                    </div>
                    <div className={classes.layoutContainerTab}>
                      <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                      <>
                        {HATADIL && (
                          <Hata />
                        )}
                        {!HATADIL && (
                          <LayoutDetailVerticalTab
                            tabs={tabsDil}
                            selectedTabIndex={selectedTabIndexDil}
                            onChangeTabIndex={(index) => setSelectedTabIndexDil(index)}
                            buttonShow={false}
                            delete={handleDetailDil}
                            deleteButtonShow={true}
                          >
                            {COUNT_TABS_DİL === 0 && (
                              <div className="flex flex-col items-start justify-start p-4 space-y-4">
                                <Typography variant="body1">
                                  Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                              </div>
                            )}
                            {COUNT_TABS_DİL > 0 && (
                              <div className={classes.tabDiv}>
                                <ul className={classes.formList}>
                                  <li className={classes.formItem} id="tanim">
                                    <label className={classes.formItemLabel} htmlFor="tanim">Tanım</label>
                                    <Tooltip title={ERROR_DIL?.tanim ? ERROR_DIL.tanim[0] : _isNil(FORM_DIL?.tanim) ? '' : FORM_DIL.tanim}>
                                      <TextField
                                        {...createTextFieldProps2('tanim')}
                                        onChange={(e) => handleChangeFormDil('tanim', e.target.value)}
                                        onBlur={() => handleInputErrors2(FORM_DIL, 'tanim', handleSetErrorsDil)}
                                      ></TextField>
                                    </Tooltip>
                                  </li>
                                </ul>
                              </div>
                            )
                            }
                          </LayoutDetailVerticalTab>
                        )}
                      </>
                    </div>


                  </>
                }
              </LayoutDetailVerticalTab>
            </div>
          )}

        </>

      }
    </>
  );
});

export default OdemeVade;
