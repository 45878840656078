import React, {useState, createContext, useContext} from 'react';

import {languageOptions, dictionaryList} from './index';

// create the language context with default selected language
export const LanguageContext = createContext({
  language: languageOptions[0],
  dictionary: dictionaryList[languageOptions[0].id]
});

// it provides the language context to app
export const LanguageProvider = (props) => {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage) => {
      setLanguage(selectedLanguage);
      setDictionary(dictionaryList[selectedLanguage.id]);
    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {props.children}
    </LanguageContext.Provider>
  );
};


// get text according to id & current language
export const Trans = (props) => {

  // <Trans>xxx</Trans>  extractor (?<=(<Trans>))(\w|[.*?]| )+?(?=(</Trans>))
  // Trans("xxx") Extractor (?<=(Trans\(["|']))(\w|[']| )+?(?=(["|']\)))

  // For all
  // Check for positive look behind error on https://regexr.com/
  // (?<=(Trans\(["|']))(\w|['|"]| )+?(?=(["|']\)))|(?<=(<Trans>))(\w|['|"]| )+?(?=(</Trans>))

  const languageContext = useContext(LanguageContext);
  if (props.hasOwnProperty('children')) {
    const _string = languageContext.dictionary[props.children]
    return _string ? _string : props.children;
  } else {
    const _string = languageContext.dictionary[props]
    return _string ? _string : props;
  }

};