import React from 'react';
import { useParams } from 'react-router-dom';

import AlisSiparisDetail from './AlisSiparisDetail';

const PageAlisSiparisDetail = () => {
  const params = useParams();

  return (
    <AlisSiparisDetail
      id={params?.id ? params.id === "yeni" ? null : params.id : params.id}
      addnew={params?.addnew ? params.addnew === "yeni" ? true : false : false}
      single={true}
    />
  );
};

export default PageAlisSiparisDetail;