import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import Highlighter from 'react-highlight-words';

import {
  result as _result,
  startsWith as _startsWith,
  endsWith as _endsWith,
  trimEnd as _trimEnd,
  trimStart as _trimStart
} from 'lodash';

import {
  TableCell,
  TableRow,
  MenuItem,
  Divider,
  MenuList,
  Paper,
  Checkbox,
  ClickAwayListener,
  Button
} from '@material-ui/core';

import {
  Check as IconCheck,
  KeyboardArrowRight as IconKeyboardArrowRight,
  KeyboardArrowDown as IconKeyboardArrowDown
} from '@material-ui/icons';

const TABLE_BODY_ROW_HEIGHT = 30;

const GridTableXTableBodyRow = (props) => {
  const [menuPosition, setMenuPosition] = useState(null);

  // menu açıksa scroll edildiğinde ya da esc'ye basıldığında kapanması için event ekliyoruz
  useEffect(() => {
    const handleScroll = (e) => {
      setMenuPosition(null);
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();

        setMenuPosition(null);
      }
    };

    if (props.tableContainerEl && menuPosition) {
      props.tableContainerEl.addEventListener('scroll', handleScroll);
      props.tableContainerEl.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (props.tableContainerEl) {
        props.tableContainerEl.removeEventListener('scroll', handleScroll);
        props.tableContainerEl.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [menuPosition, props.tableContainerEl]);

  // filterdan query * veya arrowlu geliyor
  // onları temizleyip sorguluyoruz
  // daha iyi bir yöntem bulduğumuzda bunu kökten çözmeliyiz
  // şu an vaktimiz yok
  const cleanHighlighQuery = (query) => {
    const chars = ['<', '>', '*'];
    let trimmedQuery = query;

    chars.forEach((char) => {
      if (_startsWith(query, char) || _endsWith(query, char)) {
        trimmedQuery = _trimStart(_trimEnd(query, char), char);
      }
    });

    return trimmedQuery ?? '';
  };

  const handleMenuEvent = (e, selected) => {
    e.preventDefault();

    if (selected === 'OPEN_NEW_TAB') {
      props.onRightClickMenuOpenNewTab(props.index, props.row);
    } else if (selected === 'SHOW_DETAIL') {
      props.onRightClickMenuShowDetail(props.index, props.row);
    } else if (selected === 'DELETE') {
      props.onRightClickMenuDelete(props.index, props.row);
    }

    setTimeout(() => {
      setMenuPosition(null);
    }, 100);
  };

  const handleOnContextMenu = (e) => {
    e.preventDefault();

    if (!menuPosition) {
      props.onTableRowClick(props.index, props.row);

      setMenuPosition({
        top: e.clientY,
        left: e.clientX,
      });
    }
  };

  return (
    <>
      <TableRow
        className="cursor-pointer"
        selected={props.isSelected}
        onClick={() => props.onTableRowClick(props.index, props.row)}
        onDoubleClick={() => props.onTableRowDoubleClick(props.index, props.row)}
        onContextMenu={handleOnContextMenu}
      >
        {props.showCheckBoxCell && (
          <TableCell width={1} align="center">
            <div style={{ width: 40 }}>
              <Checkbox
                color="default"
                size="small"
                disableRipple
                checked={props.isCheckedRow}
                onChange={(e, checked) => props.onCheckedRow(checked)}
                style={{
                  padding: 5,
                }}
              ></Checkbox>
            </div>
          </TableCell>
        )}

        {props.varyant && props.showTreeArrow && (
          <TableCell width={1} align="center">
            <div style={{ width: 30 }}>
              {props.row.varyants_open && props.row.alt && (
                <span onClick={props.onClickTreeArrow}>
                  <IconKeyboardArrowDown style={{ fontSize: 20, opacity: .75 }} />
                </span>
              )}
              {!props.row.varyants_open && props.row.alt && (
                <span onClick={props.onClickTreeArrow}>
                  <IconKeyboardArrowRight style={{ fontSize: 20, opacity: .75 }} />
                </span>
              )}
            </div>
          </TableCell>
        )}

        {props.columns.map((column, columnIndex) => {
          let value = '';
          if (column.type !== 'button') {
            if (column.type === 'array') {
              if (column.arrayTableProp) {
                value = props.row[column.tableProp].map((x) => x[column.arrayTableProp]).join(", ");
              } else {
                value = _result(props.row, column.tableProp, '') !== '' ? [_result(props.row, column.tableProp, '')].join(", ") : '';
              }
            } else {
              value = _result(props.row, column.tableProp, '');
            }
          }

          // const isText = column.type === 'text';
          const isBoolean = column.type === 'boolean';
          // const isNumber = column.type === 'number';
          const isDateTime = column.type === 'datetime';
          const isButton = column.type === 'function';
          const isButtonText = column.type === 'booleantext';

          if (!column.show) {
            return null;
          }

          return (
            <TableCell
              className={clsx('text-palette-text-disabled')}
              key={column.id}
              width={column.width}
              height={TABLE_BODY_ROW_HEIGHT}
              style={{ paddingInlineStart: columnIndex === 0 ? props.row?.left ? props.row.left : 0 : 0 }}
            >
              <div
                className={clsx('block select-none px-2 truncate max-w-64', {
                  'text-center': column.alignment === 1,
                  'text-right': column.alignment === 2,
                  'pr-4': column.id === 'tanim',
                })}
                style={{
                  height: TABLE_BODY_ROW_HEIGHT,
                  lineHeight: `${TABLE_BODY_ROW_HEIGHT}px`,
                }}
              >
                {column.highlightable && (
                  <Highlighter
                    textToHighlight={value ?? ''}
                    searchWords={[column.type === 'text' ? cleanHighlighQuery(_result(props.filter, column.filterProp, '')) : '']}
                  />
                )}

                {!column.highlightable && (
                  <>
                    {(() => {
                      if (isBoolean && value === true) {
                        return <IconCheck className="text-palette-text-secondary" />;
                      }

                      else if (isDateTime) {
                        if (value === null || value === undefined) {
                          return null;
                        }

                        const isDateString = value.toString().slice(0, 10).match(/^\d{4}-\d{2}-\d{2}$/);

                        if (isDateString) {
                          const isValidDate = moment(value).isValid();

                          if (isValidDate) {
                            return moment(value).format('DD/MM/YYYY, HH.mm');
                          }
                        }

                        return value;
                      }
                      else if (isButton) {
                        const func = props.functions[column.buttonFunc];
                        return (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disableElevation
                            onClick={(e) => {
                              e.stopPropagation();
                              func(props.row)
                            }}
                            style={{ lineHeight: 1.2 }}
                            onDoubleClick={(e) => e.stopPropagation()}
                            onContextMenu={(e) => e.stopPropagation()}
                          >
                            {column.label}
                          </Button>
                        )
                      }
                      else if (isButtonText) {
                        return column.selectOptions?.find(o => o.key === value)?.value
                      }

                      return value;
                    })()}
                  </>
                )}
              </div>
            </TableCell>
          );
        })}

        <TableCell className="border-l border-palette-action-selected">
          {menuPosition &&
            <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => setMenuPosition(null)}>
              <Paper className="fixed z-10" style={menuPosition}>
                <MenuList className="focus:outline-none">
                  <MenuItem onClick={(e) => handleMenuEvent(e, 'OPEN_NEW_TAB')}>Yeni Sekmede Aç</MenuItem>
                  <MenuItem onClick={(e) => handleMenuEvent(e, 'SHOW_DETAIL')}>Detay Göster</MenuItem>
                  <Divider />
                  <MenuItem onClick={(e) => handleMenuEvent(e, 'DELETE')}>{props.path !== 'evrak' ? 'Sil' : 'İptal Et'}</MenuItem>
                </MenuList>
              </Paper>
            </ClickAwayListener>
          }
        </TableCell>
      </TableRow>
    </>
  );
};

export default GridTableXTableBodyRow;
