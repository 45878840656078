import React, { useCallback, useMemo } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Tooltip
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
    },
  };
};

const ParaBirimDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.layoutContainer}>
              <ul className={classes.layoutFormList}>
                <li className={classes.layoutFormItem} id="uluslar_arasi_kod">
                  <label className={classes.layoutFormItemLabel} htmlFor="uluslar_arasi_kod">Uluslar Arası Kodu *</label>
                  <Tooltip title={props.error?.uluslar_arasi_kod ? props.error.uluslar_arasi_kod[0] : _isNil(props.form?.uluslar_arasi_kod) ? '' : props.form.uluslar_arasi_kod}>
                    <TextField
                      {...createTextFieldProps('uluslar_arasi_kod')}
                      onChange={(e) => props.onChangeForm('uluslar_arasi_kod', e.target.value)}
                      onBlur={() => handleInputErrors('parabirim', props.form, props.formdefault, props.onCheckErrors, add, 'uluslar_arasi_kod', true, false, false, '', 'Uluslar Arası Kodu', 'para birimi')}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option key="-1" value="-1" disabled>Seçilmedi</option>
                      {props.paraBirim.map((pb) => (
                        <option key={pb.key} value={pb.key}>{pb.value}</option>
                      ))}
                    </TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="tanim">
                  <label className={classes.layoutFormItemLabel} htmlFor="tanim">Para Birim Tanım *</label>
                  <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                    <TextField
                      {...createTextFieldProps('tanim')}
                      onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                      onBlur={() => handleInputErrors('parabirim', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Para Birim Tanım', 'para birimi')}
                      inputProps={{
                        maxLength: 250,
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="sembol">
                  <label className={classes.layoutFormItemLabel} htmlFor="sembol">Sembol</label>
                  <Tooltip title={props.error?.sembol ? props.error.sembol[0] : _isNil(props.form?.sembol) ? '' : props.form.sembol}>
                    <TextField
                      {...createTextFieldProps('sembol')}
                      onChange={(e) => props.onChangeForm('sembol', e.target.value)}
                      inputProps={{
                        maxLength: 5,
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="siralama">
                  <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                  <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                    <TextField
                      {...createTextFieldProps('siralama')}
                      onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                      type="number"
                      inputProps={{
                        min: -2147483648,
                        max: 2147483647,
                        step: 1,
                        style: { textAlign: 'right' }
                      }}
                    ></TextField>
                  </Tooltip>
                </li>
                <li className={classes.layoutFormItem} id="aktif">
                  <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                  <Checkbox
                    name="aktif"
                    color="primary"
                    checked={_result(props.form, 'aktif', false)}
                    onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                  ></Checkbox>
                </li>
              </ul>
            </div>
          )}
        </>
      }
    </>
  );
});

export default ParaBirimDetailTabGenel;
