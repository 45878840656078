import React, { useState, useLayoutEffect, useCallback, useRef, useMemo, useEffect } from 'react';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import {
  Divider,
  CircularProgress,
  Paper,
  ClickAwayListener,
} from '@material-ui/core';
import {
  ErrorOutline as IconErrorOutline,
} from '@material-ui/icons';

import django from '../api/django';

const PER_PAGE = 100;
const ROW_HEIGHT = 34;

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>
      <p className="font-medium mb-2">{text}</p>
    </div>
  );
};

const ListItem = ({ item, searchQuery, onClick, selected }) => {

  return (
    <div
      key={`${item.id}${item.value}`}
      className={clsx('w-full flex flex-col items-start justify-start hover:bg-palette-background-default active:bg-palette-action-hover px-4 select-none cursor-pointer')}
      style={{ height: ROW_HEIGHT, background: selected ? "#eacfea" : "" }}
      onClick={() => onClick()}
      tabIndex="0"
    >
      <Highlighter
        textToHighlight={`${item.value}`}
        searchWords={[searchQuery ?? '']}
        highlightClassName="leading-none "
        unhighlightClassName="leading-none"
        className={clsx('truncate w-full')}
      />
      <Highlighter
        textToHighlight={`${item.value2}`}
        searchWords={[searchQuery ?? '']}
        highlightClassName="leading-none"
        unhighlightClassName="leading-none"
        highlightStyle={{ fontSize: 8 }}
        unhighlightStyle={{ fontSize: 8 }}
        className={clsx('truncate w-full')}
      />
    </div>
  );
};

const SearchListDrop = ({ listPath, listCountPath, searchQuery, keyProp, valueProp, valueProp2, onChageItem, onClickAway, childRef }) => {
  const [searchItems, setSearchItems] = useState([]);
  const [searchItemsLoading, setSearchItemsLoading] = useState(false);
  const [searchItemsLoadingErrorMessage, setSearchItemsLoadingErrorMessage] = useState(null);
  const [searchItemsTotalCount, setSearchItemsTotalCount] = useState(0);
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState(0);
  const [focus, setFocus] = useState(false);

  const mainContainerRef = useRef();

  const PAGE = useMemo(() => searchItems.length > 0 ? Math.ceil(searchItems.length / PER_PAGE) : 1, [searchItems.length]);
  const PAGES_COUNT = useMemo(() => Math.ceil(searchItemsTotalCount / PER_PAGE), [searchItemsTotalCount]);

  const LOADING_MESSAGE = useMemo(() => {
    if (searchItemsLoading) {
      return 'Seçenekler yükleniyor';
    }
    return null;
  }, [searchItemsLoading]);

  const ERROR_MESSAGE = useMemo(() => {
    if (searchItemsLoadingErrorMessage) {
      return searchItemsLoadingErrorMessage;
    }
    return null;
  }, [searchItemsLoadingErrorMessage]);

  const mapItem = useCallback((item) => {
    return {
      key: item[keyProp],
      value: item[valueProp],
      value2: item[valueProp2]
    };
  }, [keyProp, valueProp, valueProp2]);

  useEffect(() => {
    const handleGet = ({ url, config }) => {
      django(url, config).then(({ data }) => {
        const mapped = data.map(mapItem);
        setSearchItems(mapped);
      }).catch(function (error) {
        if (django?.isCancel(error)) {
        } else {
          setSearchItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
        }
      }).finally(() => {
        setSearchItemsLoading(false);
      });
    };
    if (searchQuery === '') {
      setSearchItems([]);
      setSearchItemsLoading(false);
      setSearchItemsLoadingErrorMessage(null);
    }
    if (searchQuery !== '') {
      setSearchItemsLoading(true);
      const CancelToken = django.CancelToken;
      const cancelTokenSource = CancelToken.source();
      const config = {
        params: {
          [valueProp + '__search']: `*${searchQuery}*`,
          [valueProp2 + '__search']: `*${searchQuery}*`,
          size: PER_PAGE,
        },
        cancelToken: cancelTokenSource.token,
      };
      const debounce = setTimeout(() => handleGet({ url: listPath, config }), 1000);
      return () => {
        cancelTokenSource.cancel();
        clearTimeout(debounce);
        setSearchItemsLoading(false);
      };
    }
  }, [listPath, mapItem, valueProp, valueProp2, searchQuery]);

  useLayoutEffect(() => {
    django(listCountPath).then(({ data }) => {
      setSearchItemsTotalCount(data);
    });
  }, [listCountPath]);

  useEffect(() => {
    if (focus) {
      const el = childRef?.current;
      const callback = (e) => {
        const validKeys = [
          'ArrowUp',
          'ArrowDown',
          'Enter'
        ];
        if (validKeys.indexOf(e.key) > -1) {
          e.preventDefault();
          if (selectedItem !== null && selectedItem > -1) {
            if (e.key === 'ArrowUp' && selectedItem > 0) {
              setSelectedItem(selectedItem - 1)
            }
            if (e.key === 'ArrowDown' && searchItems.length - 1 > (selectedItem)) {
              setSelectedItem(selectedItem + 1)
            }
            if (e.key === 'Enter' && searchItems.length > 0) {
              const item = searchItems[selectedItem];
              let copyMappedDefaultItem = item;
              copyMappedDefaultItem = {
                [keyProp]: item.key,
                [valueProp]: item.value,
                [valueProp2]: item.value2,
              }
              onChageItem(copyMappedDefaultItem);
            }
          }
        }
      };
      if (el) {
        el.addEventListener('keydown', callback);
        return () => {
          el.removeEventListener('keydown', callback);
        }
      }
    }
  }, [focus, selectedItem, searchItems, childRef, onChageItem, keyProp, valueProp, valueProp2])

  const handleGetMoreOptions = (page) => {
    if (searchQuery !== "") {
      const firstScrollTop = mainContainerRef?.current.scrollTop;
      const config = {
        params: {
          [valueProp]: `*${searchQuery}*`,
          [valueProp2]: `*${searchQuery}*`,
          size: PER_PAGE,
          page: page,
        },
      };
      setMoreItemsLoading(true);
      django(listPath, config).then(({ data }) => {
        const mapped = data.map(mapItem);
        setSearchItems((prev) => [...prev, ...mapped]);
      }).finally(() => {
        mainContainerRef.current.scrollTo({
          top: firstScrollTop,
          left: 0,
          behavior: 'auto',
        });
        setMoreItemsLoading(false);
      });
    }
  };

  const handleSetItem = (item) => {
    let copyMappedDefaultItem = item;
    copyMappedDefaultItem = {
      [keyProp]: item.key,
      [valueProp]: item.value,
      [valueProp2]: item.value2,
    }
    onChageItem(copyMappedDefaultItem);
  }

  const rowRenderer = ({ index, style }) => {
    const item = searchItems[index];
    return (
      <div key={item.key} style={style}>
        {index !== 0 && <Divider />}
        <ListItem
          item={item}
          searchQuery={searchQuery}
          onClick={() => handleSetItem(item)}
          selected={index === selectedItem ? true : false}
        ></ListItem>
      </div>
    );
  };

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => onClickAway()}>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <main className="relative flex flex-grow flex-col overflow-hidden" ref={mainContainerRef}>
          <div className="h-full flex-grow outline-none" tabIndex="1" ref={childRef}
            onFocus={() => setFocus(true)}
          >
            {LOADING_MESSAGE && (
              <LoadingMessage text={LOADING_MESSAGE} />
            )}

            {ERROR_MESSAGE && (
              <ErrorMessage text={ERROR_MESSAGE} />
            )}

            {(!LOADING_MESSAGE && !ERROR_MESSAGE && searchItems.length === 0) && (
              <ErrorMessage text="Seçenek bulunamadı" />
            )}

            {(!LOADING_MESSAGE && !ERROR_MESSAGE && searchItems.length > 0) && (
              <InfiniteLoader
                minimumBatchSize={PER_PAGE}
                threshold={PER_PAGE}
                isRowLoaded={({ index }) => !!searchItems[index]}
                loadMoreRows={({ startIndex, stopIndex }) => {
                  if (searchItemsTotalCount > 0 && PAGES_COUNT > PAGE && !moreItemsLoading) {
                    handleGetMoreOptions(PAGE + 1);
                  }
                }}
                rowCount={searchItemsTotalCount > 0 ? searchItemsTotalCount : PER_PAGE * 2}
              >
                {({ onRowsRendered, registerChild }) => (
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        width={width}
                        height={height}
                        rowHeight={ROW_HEIGHT + 1}
                        rowCount={searchItems.length}
                        estimatedRowSize={PAGES_COUNT > 0 ? PAGES_COUNT * (ROW_HEIGHT + 1) : undefined}
                        rowRenderer={rowRenderer}
                        onRowsRendered={onRowsRendered}
                        ref={registerChild}
                      />
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            )}
          </div>
        </main>
      </Paper>
    </ClickAwayListener>
  )


}

export default SearchListDrop;