import React, { useMemo } from 'react';
import moment from 'moment';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core';
import { Hata } from '../../base/Hata';

import { ThemeProvider } from '@material-ui/core/styles';

import Store from '../../../libs/store'
import Themes from '../../../themes/index';

// main app theme configs
const appTheme = Themes[Store.get('themeChoice')?.theme ?? 'light'];

// in-component theme configs
const theme = {
  overrides: {
    MuiTable: {
      root: {
        '& th:first-child, & td:first-child': {
          paddingLeft: appTheme.spacing(4),
        },

        '& th:last-child, & td:last-child': {
          paddingRight: appTheme.spacing(4),
        },

        '& tbody tr:hover td': {
          backgroundColor: appTheme.palette.action.hover,
        },
        '& td,th': {
          fontSize: '0.75rem'
        }
      },
    },
  },
};

const UrunDetailXTabMiktarlar = ({ miktarlar, hata, loading }) => {
  const LOADING = useMemo(() => loading, [loading]);
  const HATA = useMemo(() => hata, [hata]);
  const COUNT_TOTAL_MIKTAR = useMemo(() => miktarlar.reduce((previousValue, row) => {
    return previousValue + Number(row.miktar);
  }, 0).toFixed(4), [miktarlar]);
  const COUNT_TOTAL_MIKTAR_GIREN = useMemo(() => miktarlar.reduce((previousValue, row) => {
    return previousValue + Number(row.giren);
  }, 0).toFixed(4), [miktarlar]);
  const COUNT_TOTAL_MIKTAR_CIKAN = useMemo(() => miktarlar.reduce((previousValue, row) => {
    return previousValue + Number(row.cikan);
  }, 0).toFixed(4), [miktarlar]);
  const COUNT_TOTAL_MIKTAR_REZERV = useMemo(() => miktarlar.reduce((previousValue, row) => {
    return previousValue + Number(row.rezerv_miktar);
  }, 0).toFixed(4), [miktarlar]);
  const COUNT_TOTAL_SATILABILIR = useMemo(() => miktarlar.reduce((previousValue, row) => {
    return previousValue + (Number(row.miktar) - Number(row.rezerv_miktar));
  }, 0).toFixed(4), [miktarlar]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className="py-4 relative w-full h-full flex flex-row items-start overflow-x-auto">
              <ThemeProvider theme={theme}>
                {miktarlar.length > 0 &&
                  <>
                    <Table
                      className="focus:outline-none whitespace-nowrap"
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell component="th" scope="row" style={{ width: 1 }}>Depo Kodu</TableCell>
                          <TableCell component="th" scope="row" style={{ width: 1 }}>Depo Adı</TableCell>
                          <TableCell component="th" scope="row" align="right" style={{ width: 1 }}>Giren</TableCell>
                          <TableCell component="th" scope="row" align="right" style={{ width: 1 }}>Çıkan</TableCell>
                          <TableCell component="th" scope="row" align="right" style={{ width: 1 }}>Kalan</TableCell>
                          <TableCell component="th" scope="row" align="right" style={{ width: 1 }}>Rezerve</TableCell>
                          <TableCell component="th" scope="row" align="right" style={{ width: 1 }}>Satılabilir</TableCell>
                          <TableCell component="th" scope="row" align="right" style={{ width: 1 }}>Son Güncelleme</TableCell>
                          <TableCell component="th" scope="row" />
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {miktarlar.map((miktar) => {
                          const olusturma = moment(miktar.olusturma_zamani).format('DD/MM/YYYY, HH.mm');
                          const degistirme = moment(miktar.degistirme_zamani).format('DD/MM/YYYY, HH.mm');

                          return (
                            <TableRow
                              key={miktar.id}
                            >
                              <TableCell>{miktar.depo.kodu}</TableCell>
                              <TableCell>{miktar.depo.tanim}</TableCell>
                              <TableCell align="right">{miktar.giren}</TableCell>
                              <TableCell align="right">{miktar.cikan}</TableCell>
                              <TableCell align="right">{miktar.miktar}</TableCell>
                              <TableCell align="right">{miktar.rezerv_miktar}</TableCell>
                              <TableCell align="right">{(Number(miktar.miktar) - Number(miktar.rezerv_miktar)).toFixed(2)}</TableCell>
                              <TableCell align="right">
                                <Tooltip title={`Oluşturma: ${olusturma}`} placement="left">
                                  <span>{degistirme}</span>
                                </Tooltip>
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          );
                        })}

                        <TableRow>
                          <TableCell/>
                          <TableCell/>
                          <TableCell align="right">{COUNT_TOTAL_MIKTAR_GIREN}</TableCell>
                          <TableCell align="right">{COUNT_TOTAL_MIKTAR_CIKAN}</TableCell>
                          <TableCell align="right">{COUNT_TOTAL_MIKTAR}</TableCell>
                          <TableCell align="right">{COUNT_TOTAL_MIKTAR_REZERV}</TableCell>
                          <TableCell align="right" >{COUNT_TOTAL_SATILABILIR}</TableCell>
                          <TableCell/>
                          <TableCell/>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </>
                }

                {miktarlar.length === 0 &&
                  <div className="px-8">Bu ürüne ait miktar kaydı bulunamadı.</div>
                }
              </ThemeProvider>
            </div>
          )}
        </>
      }
    </>
  );
};

export default UrunDetailXTabMiktarlar;
