export const ROOT = process.env.PUBLIC_URL + '/';

export default {
  root: ROOT,

  user: {
    login: ROOT + 'kullanici/giris/',
    password_reset: ROOT + 'kullanici/sifre-sifirla/',
    cari_olustur: ROOT + 'kullanici/cariolustur/',
  },

  page: {
    detail: (slug = null) => {
      return slug
        ? `${ROOT}page/${slug}/`
        : ROOT + 'page/';
    },
  },

  evrak: {
    satisonsiparis: {
      list: ROOT + 'evrak/satisonsiparis/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/satisonsiparis/${id}/${addnew}/` : `${ROOT}evrak/satisonsiparis/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/satisonsiparis/:id/${addnew}/` : ROOT + 'evrak/satisonsiparis/:id/:addnew/';
      },
    },
    satissiparis: {
      list: ROOT + 'evrak/satissiparis/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/satissiparis/${id}/${addnew}/` : `${ROOT}evrak/satissiparis/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/satissiparis/:id/${addnew}/` : ROOT + 'evrak/satissiparis/:id/:addnew/';
      },
    },
    satisirsaliye: {
      list: ROOT + 'evrak/satisirsaliye/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/satisirsaliye/${id}/${addnew}/` : `${ROOT}evrak/satisirsaliye/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/satisirsaliye/:id/${addnew}/` : ROOT + 'evrak/satisirsaliye/:id/:addnew/';
      },
    },
    satisiadeirsaliye: {
      list: ROOT + 'evrak/satisiadeirsaliye/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/satisiadeirsaliye/${id}/${addnew}/` : `${ROOT}evrak/satisiadeirsaliye/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/satisiadeirsaliye/:id/${addnew}/` : ROOT + 'evrak/satisiadeirsaliye/:id/:addnew/';
      },
    },
    satisfatura: {
      list: ROOT + 'evrak/satisfatura/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/satisfatura/${id}/${addnew}/` : `${ROOT}evrak/satisfatura/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/satisfatura/:id/${addnew}/` : ROOT + 'evrak/satisfatura/:id/:addnew/';
      },
    },
    satisiadefatura: {
      list: ROOT + 'evrak/satisiadefatura/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/satisiadefatura/${id}/${addnew}/` : `${ROOT}evrak/satisiadefatura/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/satisiadefatura/:id/${addnew}/` : ROOT + 'evrak/satisiadefatura/:id/:addnew/';
      },
    },
    alisonsiparis: {
      list: ROOT + 'evrak/alisonsiparis/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/alisonsiparis/${id}/${addnew}/` : `${ROOT}evrak/alisonsiparis/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/alisonsiparis/:id/${addnew}/` : ROOT + 'evrak/alisonsiparis/:id/:addnew/';
      },
    },
    alissiparis: {
      list: ROOT + 'evrak/alissiparis/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/alissiparis/${id}/${addnew}/` : `${ROOT}evrak/alissiparis/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/alissiparis/:id/${addnew}/` : ROOT + 'evrak/alissiparis/:id/:addnew/';
      },
    },
    alisirsaliye: {
      list: ROOT + 'evrak/alisirsaliye/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/alisirsaliye/${id}/${addnew}/` : `${ROOT}evrak/alisirsaliye/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/alisirsaliye/:id/${addnew}/` : ROOT + 'evrak/alisirsaliye/:id/:addnew/';
      },
    },
    alisiadeirsaliye: {
      list: ROOT + 'evrak/alisiadeirsaliye/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/alisiadeirsaliye/${id}/${addnew}/` : `${ROOT}evrak/alisiadeirsaliye/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/alisiadeirsaliye/:id/${addnew}/` : ROOT + 'evrak/alisiadeirsaliye/:id/:addnew/';
      },
    },
    alisfatura: {
      list: ROOT + 'evrak/alisfatura/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/alisfatura/${id}/${addnew}/` : `${ROOT}evrak/alisfatura/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/alisfatura/:id/${addnew}/` : ROOT + 'evrak/alisfatura/:id/:addnew/';
      },
    },
    alisiadefatura: {
      list: ROOT + 'evrak/alisiadefatura/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/alisiadefatura/${id}/${addnew}/` : `${ROOT}evrak/alisiadefatura/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/alisiadefatura/:id/${addnew}/` : ROOT + 'evrak/alisiadefatura/:id/:addnew/';
      },
    },
    sevkemri: {
      list: ROOT + 'evrak/sevkemri/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/sevkemri/${id}/${addnew}/` : `${ROOT}evrak/sevkemri/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/sevkemri/:id/${addnew}/` : ROOT + 'evrak/sevkemri/:id/:addnew/';
      },
    },
    tahsilat: {
      list: ROOT + 'evrak/tahsilat/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/tahsilat/${id}/${addnew}/` : `${ROOT}evrak/tahsilat/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/tahsilat/:id/${addnew}/` : ROOT + 'evrak/tahsilat/:id/:addnew/';
      },
    },
    odeme: {
      list: ROOT + 'evrak/odeme/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/odeme/${id}/${addnew}/` : `${ROOT}evrak/odeme/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/odeme/:id/${addnew}/` : ROOT + 'evrak/odeme/:id/:addnew/';
      },
    },
    depogiris: {
      list: ROOT + 'evrak/depogiris/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/depogiris/${id}/${addnew}/` : `${ROOT}evrak/depogiris/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/depogiris/:id/${addnew}/` : ROOT + 'evrak/depogiris/:id/:addnew/';
      },
    },
    depocikis: {
      list: ROOT + 'evrak/depocikis/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/depocikis/${id}/${addnew}/` : `${ROOT}evrak/depocikis/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/depocikis/:id/${addnew}/` : ROOT + 'evrak/depocikis/:id/:addnew/';
      },
    },
    evrakdurumtanim: {
      list: ROOT + 'evrak/durumtanim/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/durumtanim/${id}/${addnew}/` : `${ROOT}evrak/durumtanim/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/durumtanim/:id/${addnew}/` : ROOT + 'evrak/durumtanim/:id/:addnew/';
      },
    },
    satisonsiparisonay: {
      list: ROOT + 'evrak/satisonsiparisonay/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/satisonsiparisonay/${id}/${addnew}/` : `${ROOT}evrak/satisonsiparisonay/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/satisonsiparisonay/:id/${addnew}/` : ROOT + 'evrak/satisonsiparisonay/:id/:addnew/';
      },
    },
    satissiparistensevkemri: {
      list: ROOT + 'evrak/satissiparistensevkemri/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/satissiparistensevkemri/${id}/${addnew}/` : `${ROOT}evrak/satissiparistensevkemri/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/satissiparistensevkemri/:id/${addnew}/` : ROOT + 'evrak/satissiparistensevkemri/:id/:addnew/';
      },
    },
    satissiparistenirsaliye: {
      list: ROOT + 'evrak/satissiparistenirsaliye/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/satissiparistenirsaliye/${id}/${addnew}/` : `${ROOT}evrak/satissiparistenirsaliye/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/satissiparistenirsaliye/:id/${addnew}/` : ROOT + 'evrak/satissiparistenirsaliye/:id/:addnew/';
      },
    },
    satissiparistenfatura: {
      list: ROOT + 'evrak/satissiparistenfatura/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/satissiparistenfatura/${id}/${addnew}/` : `${ROOT}evrak/satissiparistenfatura/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/satissiparistenfatura/:id/${addnew}/` : ROOT + 'evrak/satissiparistenfatura/:id/:addnew/';
      },
    },
    satisirsaliyedenfatura: {
      list: ROOT + 'evrak/satisirsaliyedenfatura/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/satisirsaliyedenfatura/${id}/${addnew}/` : `${ROOT}evrak/satisirsaliyedenfatura/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/satisirsaliyedenfatura/:id/${addnew}/` : ROOT + 'evrak/satisirsaliyedenfatura/:id/:addnew/';
      },
    },
    alisonsiparisonay: {
      list: ROOT + 'evrak/alisonsiparisonay/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/alisonsiparisonay/${id}/${addnew}/` : `${ROOT}evrak/alisonsiparisonay/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/alisonsiparisonay/:id/${addnew}/` : ROOT + 'evrak/alisonsiparisonay/:id/:addnew/';
      },
    },
    alissiparistenirsaliye: {
      list: ROOT + 'evrak/alissiparistenirsaliye/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/alissiparistenirsaliye/${id}/${addnew}/` : `${ROOT}evrak/alissiparistenirsaliye/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/alissiparistenirsaliye/:id/${addnew}/` : ROOT + 'evrak/alissiparistenirsaliye/:id/:addnew/';
      },
    },
    alissiparistenfatura: {
      list: ROOT + 'evrak/alissiparistenfatura/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/alissiparistenfatura/${id}/${addnew}/` : `${ROOT}evrak/alissiparistenfatura/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/alissiparistenfatura/:id/${addnew}/` : ROOT + 'evrak/alissiparistenfatura/:id/:addnew/';
      },
    },
    alisirsaliyedenfatura: {
      list: ROOT + 'evrak/alisirsaliyedenfatura/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/alisirsaliyedenfatura/${id}/${addnew}/` : `${ROOT}evrak/alisirsaliyedenfatura/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/alisirsaliyedenfatura/:id/${addnew}/` : ROOT + 'evrak/alisirsaliyedenfatura/:id/:addnew/';
      },
    },
    sevkemrindensatisfatura: {
      list: ROOT + 'evrak/sevkemrindensatisfatura/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/sevkemrindensatisfatura/${id}/${addnew}/` : `${ROOT}evrak/sevkemrindensatisfatura/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/sevkemrindensatisfatura/:id/${addnew}/` : ROOT + 'evrak/sevkemrindensatisfatura/:id/:addnew/';
      },
    },
    sevkemrindensatisirsaliye: {
      list: ROOT + 'evrak/sevkemrindensatisirsaliye/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}evrak/sevkemrindensatisirsaliye/${id}/${addnew}/` : `${ROOT}evrak/sevkemrindensatisirsaliye/${id}/:addnew/`
          : addnew ? `${ROOT}evrak/sevkemrindensatisirsaliye/:id/${addnew}/` : ROOT + 'evrak/sevkemrindensatisirsaliye/:id/:addnew/';
      },
    },
  },

  tanimlar: {
    bankahesap: {
      list: ROOT + 'tanimlar/bankahesap/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/bankahesap/${id}/${addnew}/` : `${ROOT}tanimlar/bankahesap/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/bankahesap/:id/${addnew}/` : ROOT + 'tanimlar/bankahesap/:id/:addnew/';
      },
    },
    bilesen: {
      list: ROOT + 'tanimlar/bilesen/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/bilesen/${id}/${addnew}/` : `${ROOT}tanimlar/bilesen/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/bilesen/:id/${addnew}/` : ROOT + 'tanimlar/bilesen/:id/:addnew/'
      },
    },
    birim: {
      list: ROOT + 'tanimlar/birimler/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/birimler/${id}/${addnew}/` : `${ROOT}tanimlar/birimler/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/birimler/:id/${addnew}/` : ROOT + 'tanimlar/birimler/:id/:addnew/'
      },
    },
    cari: {
      list: ROOT + 'tanimlar/cari/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/cari/${id}/${addnew}/` : `${ROOT}tanimlar/cari/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/cari/:id/${addnew}/` : ROOT + 'tanimlar/cari/:id/:addnew/'
      },
    },
    departman: {
      list: ROOT + 'tanimlar/departman/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/departman/${id}/${addnew}/` : `${ROOT}tanimlar/departman/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/departman/:id/${addnew}/` : ROOT + 'tanimlar/departman/:id/:addnew/'
      },
    },
    depo: {
      list: ROOT + 'tanimlar/depolar/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/depolar/${id}/${addnew}/` : `${ROOT}tanimlar/depolar/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/depolar/:id/${addnew}/` : ROOT + 'tanimlar/depolar/:id/:addnew/'
      },
    },
    dil: {
      list: ROOT + 'tanimlar/diller/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/diller/${id}/${addnew}/` : `${ROOT}tanimlar/diller/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/diller/:id/${addnew}/` : ROOT + 'tanimlar/diller/:id/:addnew/'
      },
    },
    eklenti: {
      list: ROOT + 'tanimlar/eklenti/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/eklenti/${id}/${addnew}/` : `${ROOT}tanimlar/eklenti/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/eklenti/:id/${addnew}/` : ROOT + 'tanimlar/eklenti/:id/:addnew/'
      },
    },
    ekalan: {
      list: ROOT + 'tanimlar/ek_alan_tanim/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/ek_alan_tanim/${id}/${addnew}/` : `${ROOT}tanimlar/ek_alan_tanim/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/ek_alan_tanim/:id/${addnew}/` : ROOT + 'tanimlar/ek_alan_tanim/:id/:addnew/'
      },
    },
    entegrasyon: {
      list: ROOT + 'tanimlar/entegrasyon/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/entegrasyon/${id}/${addnew}/` : `${ROOT}tanimlar/entegrasyon/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/entegrasyon/:id/${addnew}/` : ROOT + 'tanimlar/entegrasyon/:id/:addnew/'
      },
      urunDetail: (id = null, ustId = null) => {
        return id ? `${ROOT}tanimlar/entegrasyon-urun/${ustId}/${id}/` : `${ROOT}tanimlar/entegrasyon-urun/:ustId?/:id?/`
      },
      islemDetail: (id = null, ustId = null) => {
        return id ? `${ROOT}tanimlar/entegrasyon-islem/${ustId}/${id}/` : `${ROOT}tanimlar/entegrasyon-islem/:ustId?/:id?/`
      },
    },
    entegrasyon_eslestirme: {
      list: ROOT + 'tanimlar/entegrasyon-eslestirme/',
    },
    epostaauth: {
      list: ROOT + 'tanimlar/epostaauth/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/epostaauth/${id}/${addnew}/` : `${ROOT}tanimlar/epostaauth/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/epostaauth/:id/${addnew}/` : ROOT + 'tanimlar/epostaauth/:id/:addnew/'
      },
    },
    fiyat_tanim: {
      list: ROOT + 'tanimlar/fiyat-tanimlari/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/fiyat-tanimlari/${id}/${addnew}/` : `${ROOT}tanimlar/fiyat-tanimlari/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/fiyat-tanimlari/:id/${addnew}/` : ROOT + 'tanimlar/fiyat-tanimlari/:id/:addnew/'
      },
    },
    formtanim: {
      list: ROOT + 'tanimlar/formtanim/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/formtanim/${id}/${addnew}/` : `${ROOT}tanimlar/formtanim/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/formtanim/:id/${addnew}/` : ROOT + 'tanimlar/formtanim/:id/:addnew/'
      },
    },
    foto: {
      list: ROOT + 'tanimlar/foto/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/foto/${id}/${addnew}/` : `${ROOT}tanimlar/foto/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/foto/:id/${addnew}/` : ROOT + 'tanimlar/foto/:id/:addnew/'
      },
    },
    fotoayar: {
      list: ROOT + 'tanimlar/fotoayar/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/fotoayar/${id}/${addnew}/` : `${ROOT}tanimlar/fotoayar/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/fotoayar/:id/${addnew}/` : ROOT + 'tanimlar/fotoayar/:id/:addnew/'
      },
    },
    gmerchant: {
      list: ROOT + 'tanimlar/gmerchant/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/gmerchant/${id}/${addnew}/` : `${ROOT}tanimlar/gmerchant/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/gmerchant/:id/${addnew}/` : ROOT + 'tanimlar/gmerchant/:id/:addnew/'
      },
      urunDetail: (id = null, ustId = null) => {
        return id ? `${ROOT}tanimlar/gmerchant-urun/${ustId}/${id}/` : `${ROOT}tanimlar/gmerchant-urun/:ustId?/:id?/`
      },
    },
    grup: {
      list: ROOT + 'tanimlar/gruplar/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/gruplar/${id}/${addnew}/` : `${ROOT}tanimlar/gruplar/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/gruplar/:id/${addnew}/` : ROOT + 'tanimlar/gruplar/:id/:addnew/'
      },
    },
    hatirlatici: {
      list: ROOT + 'tanimlar/hatirlatici/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/hatirlatici/${id}/${addnew}/` : `${ROOT}tanimlar/hatirlatici/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/hatirlatici/:id/${addnew}/` : ROOT + 'tanimlar/hatirlatici/:id/:addnew/'
      },
    },
    icerik_sablon: {
      list: ROOT + 'tanimlar/icerik_sablon/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/icerik_sablon/${id}/${addnew}/` : `${ROOT}tanimlar/icerik_sablon/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/icerik_sablon/:id/${addnew}/` : ROOT + 'tanimlar/icerik_sablon/:id/:addnew/'
      },
    },
    kampanya: {
      list: ROOT + 'tanimlar/kampanya/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/kampanya/${id}/${addnew}/` : `${ROOT}tanimlar/kampanya/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/kampanya/:id/${addnew}/` : ROOT + 'tanimlar/kampanya/:id/:addnew/'
      },
    },
    kargo: {
      list: ROOT + 'tanimlar/kargo/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/kargo/${id}/${addnew}/` : `${ROOT}tanimlar/kargo/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/kargo/:id/${addnew}/` : ROOT + 'tanimlar/kargo/:id/:addnew/'
      },
    },
    kategori: {
      list: ROOT + 'tanimlar/kategoriler/',
      detail: ROOT + 'tanimlar/kategoriler/:id+',
    },
    kategoridetay: {
      detail: (id = null, ustId = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/kategoridetay/${ustId}/${id}/${addnew}/` : `${ROOT}tanimlar/kategoridetay/${ustId}/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/kategoridetay/:ustId?/:id?/${addnew}/` : `${ROOT}tanimlar/kategoridetay/:ustId?/:id?/:addnew/`;
      },
    },
    kodseri: {
      list: ROOT + 'tanimlar/kodseri/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/kodseri/${id}/${addnew}/` : `${ROOT}tanimlar/kodseri/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/kodseri/:id/${addnew}/` : ROOT + 'tanimlar/kodseri/:id/:addnew/'
      },
    },
    kullanici_grup: {
      list: ROOT + 'tanimlar/kullanici_grup/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/kullanici_grup/${id}/${addnew}/` : `${ROOT}tanimlar/kullanici_grup/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/kullanici_grup/:id/${addnew}/` : ROOT + 'tanimlar/kullanici_grup/:id/:addnew/'
      },
    },
    kullanici_odeme_log: {
      list: ROOT + 'tanimlar/kullanici_odeme_log/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/kullanici_odeme_log/${id}/${addnew}/` : `${ROOT}tanimlar/kullanici_odeme_log/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/kullanici_odeme_log/:id/${addnew}/` : ROOT + 'tanimlar/kullanici_odeme_log/:id/:addnew/'
      },
    },
    kurlar: {
      list: ROOT + 'tanimlar/kurlar/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/kurlar/${id}/${addnew}/` : `${ROOT}tanimlar/kurlar/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/kurlar/:id/${addnew}/` : ROOT + 'tanimlar/kurlar/:id/:addnew/'
      },
    },
    magaza: {
      list: ROOT + 'tanimlar/magaza/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/magaza/${id}/${addnew}/` : `${ROOT}tanimlar/magaza/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/magaza/:id/${addnew}/` : ROOT + 'tanimlar/magaza/:id/:addnew/'
      },
    },
    menu: {
      list: ROOT + 'tanimlar/menu/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/menu/${id}/${addnew}/` : `${ROOT}tanimlar/menu/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/menu/:id/${addnew}/` : ROOT + 'tanimlar/menu/:id/:addnew/'
      },
    },
    mesaj: {
      list: ROOT + 'tanimlar/mesaj/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/mesaj/${id}/${addnew}/` : `${ROOT}tanimlar/mesaj/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/mesaj/:id/${addnew}/` : ROOT + 'tanimlar/mesaj/:id/:addnew/'
      },
    },
    odeme_servis: {
      list: ROOT + 'tanimlar/odeme_servis/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/odeme_servis/${id}/${addnew}/` : `${ROOT}tanimlar/odeme_servis/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/odeme_servis/:id/${addnew}/` : ROOT + 'tanimlar/odeme_servis/:id/:addnew/'
      },
    },
    odeme_set: {
      list: ROOT + 'tanimlar/odeme_set/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/odeme_set/${id}/${addnew}/` : `${ROOT}tanimlar/odeme_set/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/odeme_set/:id/${addnew}/` : ROOT + 'tanimlar/odeme_set/:id/:addnew/'
      },
    },
    odeme_tanim: {
      list: ROOT + 'tanimlar/odeme_tanim/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/odeme_tanim/${id}/${addnew}/` : `${ROOT}tanimlar/odeme_tanim/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/odeme_tanim/:id/${addnew}/` : ROOT + 'tanimlar/odeme_tanim/:id/:addnew/'
      },
    },
    ozellik: {
      list: ROOT + 'tanimlar/ozellikbaslik/',
      detail: ROOT + 'tanimlar/ozellikbaslik/:id+',
    },
    ozellikdetay: {
      detail: (id = null, ustId = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/ozellikdetay/${ustId}/${id}/${addnew}/` : `${ROOT}tanimlar/ozellikdetay/${ustId}/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/ozellikdetay/:ustId?/:id?/${addnew}/` : `${ROOT}tanimlar/ozellikdetay/:ustId?/:id?/:addnew/`;
      },
    },
    para_birimleri: {
      list: ROOT + 'tanimlar/para-birimleri/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/para-birimleri/${id}/${addnew}/` : `${ROOT}tanimlar/para-birimleri/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/para-birimleri/:id/${addnew}/` : ROOT + 'tanimlar/para-birimleri/:id/:addnew/'
      },
    },
    redirect: {
      list: ROOT + 'tanimlar/redirect/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/redirect/${id}/${addnew}/` : `${ROOT}tanimlar/redirect/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/redirect/:id/${addnew}/` : ROOT + 'tanimlar/redirect/:id/:addnew/'
      },
    },
    sabit_sayfa: {
      list: ROOT + 'tanimlar/sabit_sayfa/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/sabit_sayfa/${id}/${addnew}/` : `${ROOT}tanimlar/sabit_sayfa/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/sabit_sayfa/:id/${addnew}/` : ROOT + 'tanimlar/sabit_sayfa/:id/:addnew/'
      },
    },
    sablon_paket: {
      list: ROOT + 'tanimlar/sablon_paket/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/sablon_paket/${id}/${addnew}/` : `${ROOT}tanimlar/sablon_paket/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/sablon_paket/:id/${addnew}/` : ROOT + 'tanimlar/sablon_paket/:id/:addnew/'
      },
    },
    satisbirimgrup: {
      list: ROOT + 'tanimlar/satisbirimgrup/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/satisbirimgrup/${id}/${addnew}/` : `${ROOT}tanimlar/satisbirimgrup/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/satisbirimgrup/:id/${addnew}/` : ROOT + 'tanimlar/satisbirimgrup/:id/:addnew/'
      },
    },
    site: {
      list: ROOT + 'tanimlar/site/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/site/${id}/${addnew}/` : `${ROOT}tanimlar/site/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/site/:id/${addnew}/` : ROOT + 'tanimlar/site/:id/:addnew/'
      },
    },
    sozlesme: {
      list: ROOT + 'tanimlar/sozlesme/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/sozlesme/${id}/${addnew}/` : `${ROOT}tanimlar/sozlesme/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/sozlesme/:id/${addnew}/` : ROOT + 'tanimlar/sozlesme/:id/:addnew/'
      },
    },
    ulkesehirilce: {
      list: ROOT + 'tanimlar/ulkesehirilce/',
      detail: ROOT + 'tanimlar/ulkesehirilce/:id+'
    },
    ulkesehirilcedetay: {
      detail: (id = null, ustId = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/ulkesehirilcedetay/${ustId}/${id}/${addnew}/` : `${ROOT}tanimlar/ulkesehirilcedetay/${ustId}/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/ulkesehirilcedetay/:ustId?/:id?/${addnew}/` : `${ROOT}tanimlar/ulkesehirilcedetay/:ustId?/:id?/:addnew/`;
      },
    },
    urun: {
      list: ROOT + 'tanimlar/urun/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/urun/${id}/${addnew}/` : `${ROOT}tanimlar/urun/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/urun/:id/${addnew}/` : ROOT + 'tanimlar/urun/:id/:addnew/'
      },
    },
    urunlisttanim: {
      list: ROOT + 'tanimlar/urunlisttanim/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/urunlisttanim/${id}/${addnew}/` : `${ROOT}tanimlar/urunlisttanim/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/urunlisttanim/:id/${addnew}/` : ROOT + 'tanimlar/urunlisttanim/:id/:addnew/'
      },
    },
    uye: {
      list: ROOT + 'tanimlar/uye/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/uye/${id}/${addnew}/` : `${ROOT}tanimlar/uye/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/uye/:id/${addnew}/` : ROOT + 'tanimlar/uye/:id/:addnew/'
      },
    },
    yonetici: {
      list: ROOT + 'tanimlar/yonetici/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/yonetici/${id}/${addnew}/` : `${ROOT}tanimlar/yonetici/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/yonetici/:id/${addnew}/` : ROOT + 'tanimlar/yonetici/:id/:addnew/'
      },
    },
    varyant: {
      list: ROOT + 'tanimlar/varyant-grup/',
      detail: (id = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/varyant-grup/${id}/${addnew}/` : `${ROOT}tanimlar/varyant-grup/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/varyant-grup/:id/${addnew}/` : ROOT + 'tanimlar/varyant-grup/:id/:addnew/'
      },
    },
    vergiler: {
      list: ROOT + 'tanimlar/vergiler/',
      detail: ROOT + 'tanimlar/vergiler/:id+'
    },
    vergidetay: {
      detail: (id = null, ustId = null, addnew = null) => {
        return id ?
          addnew ? `${ROOT}tanimlar/vergidetay/${ustId}/${id}/${addnew}/` : `${ROOT}tanimlar/vergidetay/${ustId}/${id}/:addnew/`
          : addnew ? `${ROOT}tanimlar/vergidetay/:ustId?/:id?/${addnew}/` : `${ROOT}tanimlar/vergidetay/:ustId?/:id?/:addnew/`;
      },
    },
    // ozellik: {
    //   list: ROOT+'tanimlar/ozellikbaslik/',
    //   detail: (id = null) => {
    //     return id
    //       ? `${ROOT}tanimlar/ozellikbaslik/${id}/`
    //       : ROOT+'tanimlar/ozellikbaslik/:id/';
    //   },
    // },

    // ulke: {
    //   list: ROOT + 'tanimlar/lokasyon/ulke/',
    // },

    // sehir: {
    //   list: (id = null) => {
    //     return id
    //       ? `${ROOT}tanimlar/lokasyon/ulke/${id}/sehir/`
    //       : ROOT + 'tanimlar/lokasyon/ulke/:ulke_id/sehir/';
    //   },
    // },

    // ilce: {
    //   list: (ulke_id = null, sehir_id = null) => {
    //     return (ulke_id && sehir_id)
    //       ? `${ROOT}tanimlar/lokasyon/ulke/${ulke_id}/sehir/${sehir_id}/ilce/`
    //       : ROOT + 'tanimlar/lokasyon/ulke/:ulke_id/sehir/:sehir_id/ilce/';
    //   },
    // },
  },
};
