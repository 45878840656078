import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../base/LayoutTableDetail';
import Detail from './OdemeSetDetail';


const COLUMNS_DEFAULT = [
  { id: 'tanim',                tableProp: 'tanim',                         filterProp: 'tanim',                                    label: 'Ödeme Set Tanım',       element: 'input',             type: 'text',     arrayTableProp: null,     show: true,   sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,             multipleSelectOptionFilterProp: null,    multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'magazalar',            tableProp: 'magazalar',                     filterProp: 'magazalar.magaza.id',                      label: 'Mağazalar',             element: 'select-multiple',   type: 'array',    arrayTableProp: 'tanim',  show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'magaza',         multipleSelectOptionFilterProp: 'id',    multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null,  selectKey: null,  selectValue: null  , newpath:"tanimlar/magaza" },
  { id: 'odeme_tanimlari',      tableProp: 'odeme_tanimlari',               filterProp: 'odeme_tanimlari.odemetanim.tanim',         label: 'Ödeme Tanımları',       element: 'select-multiple',   type: 'array',    arrayTableProp: 'tanim',  show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'odemetanim',     multipleSelectOptionFilterProp: 'tanim', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null,  selectKey: null,  selectValue: null  , newpath:"tanimlar/odeme_tanim" },
  { id: 'kullanici_gruplari',   tableProp: 'kullanici_gruplari',            filterProp: 'kullanici_gruplari.kullanicigrup.id',      label: 'Üye/Cari Grupları',     element: 'select-multiple',   type: 'array',    arrayTableProp: 'name',   show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici-grup', multipleSelectOptionFilterProp: 'id',    multipleSelectOptionSortProp: 'name',     multipleSelectOptionViewProp: 'name',     shortDescription: null,  selectKey: null,  selectValue: null  , newpath:"tanimlar/kullanici_grup" },
  { id: 'gruplar',              tableProp: 'gruplar',                       filterProp: 'gruplar.grup.id',                          label: 'Ürün Grupları',         element: 'select-multiple',   type: 'array',    arrayTableProp: 'tanim',  show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'grup',           multipleSelectOptionFilterProp: 'id',    multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null,  selectKey: null,  selectValue: null  , newpath:"tanimlar/gruplar" },
  { id: 'yuzdeler',             tableProp: 'yuzdeler',                      filterProp: 'yuzdeler',                                 label: 'Yüzdeler',              element: 'input',             type: 'array',    arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,             multipleSelectOptionFilterProp: null,    multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'min_tutar',            tableProp: 'min_tutar',                     filterProp: 'min_tutar',                                label: 'Minimum Tutar',         element: 'input',             type: 'number',   arrayTableProp: null,     show: true,   sortable: true,  highlightable: true,  alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,             multipleSelectOptionFilterProp: null,    multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'max_tutar',            tableProp: 'max_tutar',                     filterProp: 'max_tutar',                                label: 'Maksimum Tutar',        element: 'input',             type: 'number',   arrayTableProp: null,     show: true,   sortable: true,  highlightable: true,  alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,             multipleSelectOptionFilterProp: null,    multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'aktif',                tableProp: 'aktif',                         filterProp: 'aktif',                                    label: 'Aktif',                 element: 'select',            type: 'boolean',  arrayTableProp: null,     show: true,   sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,             multipleSelectOptionFilterProp: null,    multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'siralama',             tableProp: 'siralama',                      filterProp: 'siralama',                                 label: 'Sıralama',              element: 'input',             type: 'number',   arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 2, width: 1, selectOptions: null, multipleSelectOptionsPath: null,             multipleSelectOptionFilterProp: null,    multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'olusturan_kullanici',  tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',                   label: 'Oluşturan Kullanıcı',   element: 'select-multiple',   type: 'number',   arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',      multipleSelectOptionFilterProp: 'id',    multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistiren_kullanici', tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',                  label: 'Değiştiren Kullanıcı',  element: 'select-multiple',   type: 'number',   arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',      multipleSelectOptionFilterProp: 'id',    multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'olusturma_zamani',     tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',                         label: 'Oluşturma',             element: 'input',             type: 'datetime', arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,             multipleSelectOptionFilterProp: null,    multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistirme_zamani',    tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',                        label: 'Son Değişiklik',        element: 'input',             type: 'datetime', arrayTableProp: null,     show: true,   sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,             multipleSelectOptionFilterProp: null,    multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
];

const PageOdemeSetList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        return column;
      });
    });
  }, []);

  return (
    <>
      <Layout
        title="ÖDEME SETLERİ"
        path="odemeset"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="odemeset"
      />
    </>
  );
};

export default PageOdemeSetList;
