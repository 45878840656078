import React, { useState, useEffect, useLayoutEffect, useCallback, useRef, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import immer from 'immer';
import queryString from 'query-string';
import { QUERY_STRING_OPTIONS } from '../constants/options';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  isEmpty as _isEmpty,
  has as _has,
  isNil as _isNil,
  result as _result,
  isArray as _isArray
} from 'lodash';

import {
  Button,
  Checkbox,
  Paper,
  CircularProgress,
  LinearProgress,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Tooltip,
  TextField,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
  Search as IconSearch,
  AccountTree as IconListTree,
  Delete as IconDelete,
  Refresh as IconRefresh,
  Save as IconSave,
  Restore as IconRestore,
} from '@material-ui/icons';

import django from '../api/django';

import DialogConfirm from '../components/DialogConfirm';
import { handleInputErrors, checkAllList } from '../pages/base/InputErrors';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    },
    page: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: '50% 50%'
    },
    paper: {
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      height: '100%',
      marginLeft: '1%',
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    rowItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& + &': {
        marginTop: 5,
      },
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.primary.main}`
      },
    },
    rowLabel: {
      minWidth: 100,
      maxWidth: 100,
    },
    ozellikRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.primary.main}`
      },
    },
    ozellikRowLabel: {
      minWidth: 100,
      maxWidth: 100,
    }
  };
});


const PER_PAGE = 100;
const ROW_HEIGHT = 34;

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text, submitText, onSubmit }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>
      <p className="font-medium mb-2">{text}</p>
      {onSubmit &&
        <Button
          color="primary"
          onClick={onSubmit}
        >{submitText ?? 'TEKRAR DENE'}</Button>
      }
    </div>
  );
};

const ListItem = ({ item, checked, searchQuery, onSelect, onClick, value }) => {
  return (
    <div
      key={`${item.id}${item[value]}`}
      className={clsx('w-full flex items-center justify-start hover:text-primary px-4 space-x-3 select-none cursor-pointer')}
      style={{ height: ROW_HEIGHT }}
    >
      <Checkbox
        color="primary"
        checked={checked}
        onChange={(e, checked) => onSelect(checked)}
        style={{ padding: 0 }}
      ></Checkbox>
      <span
        onClick={onClick}
      >
        <Highlighter
          textToHighlight={`${item[value]}`}
          searchWords={[searchQuery ?? '']}
          className=""
          activeClassName=""
          highlightClassName="leading-none"
          unhighlightClassName="leading-none"
        />
      </span>
    </div>
  );
};


const ListSelectorTwoStep = ({ title, listPath, listCountPath, defaultItems, valueProp, onSelectItems, selectItemsEnable, onClose, onDetayListClose, detayListOpen, deleteM, deleteM2, editablePropList, filterList }) => {
  const [items, setItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsErrorMessage, setItemsErrorMessage] = useState(null);
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchItems, setSearchItems] = useState([]);
  const [searchItemsLoading, setSearchItemsLoading] = useState(false);
  const [searchItemsLoadingErrorMessage, setSearchItemsLoadingErrorMessage] = useState(null);

  const [detailLoading, setDetailLoading] = useState(false);
  const [detailErrorMessage, setDetailErrorMessage] = useState(null);

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedItemDefault, setSelectedItemDefault] = useState([]);
  const [selectedErrors, setSelectedErrors] = useState(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const classes = useStyles();
  const mainContainerRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const PAGE = useMemo(() => items.length > 0 ? Math.ceil(items.length / PER_PAGE) : 1, [items.length]);
  const PAGE_COUNT = useMemo(() => Math.ceil(itemsTotalCount / PER_PAGE), [itemsTotalCount]);

  const detailOpen = useMemo(() => detayListOpen, [detayListOpen]);

  const IS_EQUAL = useMemo(() => {
    return _isEqual(selectedItem, selectedItemDefault)
  }, [selectedItem, selectedItemDefault]);

  const mapItem = useCallback((item) => {
    let obj = { id: item.id };
    for (let i = 0; i < editablePropList.length; i++) {
      obj[editablePropList[i].key] = item[editablePropList[i].key];
    }
    return obj;
  }, [editablePropList]);

  useLayoutEffect(() => {
    const mainmapped = defaultItems.map(mapItem);
    setSelectedItems(mainmapped);
  }, [defaultItems, mapItem]);

  useLayoutEffect(() => {
    setItemsLoading(true);
    django(listPath, { params: { size: PER_PAGE, sort: valueProp } })
      .then(({ data }) => {
        const mainmapped = data.map(mapItem);
        setItems(mainmapped);
      })
      .catch(() => {
        setItemsErrorMessage('Beklenmeyen bir hata oluştu')
      })
      .finally(() => {
        setItemsLoading(false);
      })
  }, [listPath, mapItem, valueProp, lastUpdateTime]);

  useLayoutEffect(() => {
    django(`${listCountPath}`).then(({ data }) => {
      setItemsTotalCount(data)
    })
  }, [listCountPath, lastUpdateTime]);

  const SHOW_LINEAR_PROGRESS = useMemo(() => {
    if (itemsLoading || moreItemsLoading || searchItemsLoading) {
      return true;
    }
    return false;
  }, [itemsLoading, moreItemsLoading, searchItemsLoading]);

  const LOADING_MESSAGE = useMemo(() => {
    if (itemsLoading) {
      return 'Seçenekler yükleniyor';
    }
    if (searchItemsLoading) {
      return 'Arama yapılıyor';
    }
    return null;
  }, [itemsLoading, searchItemsLoading]);

  const ERROR_MESSAGE = useMemo(() => {
    if (itemsErrorMessage) {
      return itemsErrorMessage;
    }
    if (searchItemsLoadingErrorMessage) {
      return searchItemsLoadingErrorMessage;
    }
    return null;
  }, [itemsErrorMessage, searchItemsLoadingErrorMessage]);

  const LOADING_MESSAGE_DETAIL = useMemo(() => {
    if (detailLoading) {
      return 'Detay yükleniyor';
    }
    return null;
  }, [detailLoading]);

  const ERROR_MESSAGE_DETAIL = useMemo(() => {
    if (detailErrorMessage) {
      return detailErrorMessage;
    }
    return null;
  }, [detailErrorMessage,]);

  const FILTERED_ITEMS = useMemo(() => {
    if (searchQuery !== '') {
      return searchItems;
    }
    return items;
  }, [items, searchItems, searchQuery]);

  useEffect(() => {
    const handleGet = ({ url, config }) => {
      setSearchItems([]);
      setSearchItemsLoading(true);
      setSearchItemsLoadingErrorMessage(null);

      django(url, config).then(({ data }) => {
        const mainmapped = data.map(mapItem);
        setSearchItems(mainmapped);
      }).catch((error) => {
        if (django?.isCancel(error)) {
        } else {
          setSearchItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu')
        }
      }).finally(() => {
        setSearchItemsLoading(false);
      });
    };

    if (searchQuery === '') {
      setSearchItems([]);
      setSearchItemsLoading(false);
      setSearchItemsLoadingErrorMessage(null);
    }

    if (searchQuery !== '') {
      setSearchItems([]);
      setSearchItemsLoading(true);
      setSearchItemsLoadingErrorMessage(null);

      const CancelToken = django.CancelToken;
      const cancelTokenSource = CancelToken.source();

      const config = {
        params: {
          size: PER_PAGE,
          [valueProp]: `*${searchQuery}*`,
        },
        cancelToken: cancelTokenSource.token,
      };
      const debounce = setTimeout(() => handleGet({ url: listPath, config }), 300);
      return () => {
        cancelTokenSource.cancel();
        clearTimeout(debounce);
        setSearchItemsLoading(false);
      };
    }
  }, [mapItem, searchQuery, listPath, valueProp, lastUpdateTime]);

  const handleCheckItem = (item, checked) => {
    let copyMappedDefaultItems = [...selectedItems];
    if (checked) {
      copyMappedDefaultItems.push(item);
    } else {
      const index = copyMappedDefaultItems.findIndex((item2) => item2.id === item.id);
      if (index > -1) {
        copyMappedDefaultItems.splice(index, 1);
      }
    }
    setSelectedItems(copyMappedDefaultItems);
  }

  const onSubmitItems = () => {
    let copyMappedDefaultItems = [...selectedItems];
    copyMappedDefaultItems = copyMappedDefaultItems.sort((a, b) => a[valueProp].localeCompare(b[valueProp]));
    onSelectItems(copyMappedDefaultItems);
    onClose();
  }

  const handleRemoveItems = () => {
    setSelectedItems([]);
    onSelectItems([]);
  }

  const handleGetMoreOptions = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setMoreItemsLoading(true);
    django(listPath, { params: { size: PER_PAGE, page, sort: valueProp } }).then(({ data }) => {
      const mapped = data.map(mapItem);
      setItems((prev) => [...prev, ...mapped]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setMoreItemsLoading(false);
    });
  }

  //gelen filtreleri düzenledik
  const MapQueryString = async (query) => {
    let filterArray = [];
    if (query) {
      const q = queryString.parse(query, QUERY_STRING_OPTIONS);
      for (let k in q) {
        const obj = filterList.find(x => k.includes(x.key));
        if (obj.url) {
          if (obj.type === "entcat") {
            const query = q[k];
            let datas = [];
            if (_isArray(q[k])) {
              for (let id of q[k]) {
                const res = (await django(`${obj.url}/${id}`)).data;
                datas = [...datas, res];
              }
            } else {
              const res = (await django(`${obj.url}/${query}`)).data;
              datas = [...datas, res];
            }
            filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: [...datas].map(x => x[obj.valueKey]).join(', ') })
          } else if (obj.type === "select") {
            const datas = (await django(`${obj.url}`)).data;
            const value = datas.find(x => x[obj.propKey] === q[k]);
            filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: value[obj.valueProp] });
          } else if (obj.type === "two_step") {
            let list = [];
            const datas1 = (await django(`${obj.url}`)).data;
            for (let v of datas1) {
              const datas = (await django(`${obj.url}/${v.id}/${obj.url2}`)).data;
              const newDatas = datas.map((x) => ({
                id: x.id,
                tanim: `${v[obj.valueKeyU]} - ${x[obj.valueKey]}`
              }));
              list = [...list, ...newDatas];
            }
            filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: [...list].filter(x => q[k].includes(x.id)).map(x => x.tanim).join(', ') })
          } else {
            const query = _isArray(q[k]) ? q[k].join('&id=') : q[k];
            const datas = (await django(`${obj.url}?id=${query}`)).data;
            filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: [...datas].map(x => x[obj.valueKey]).join(', ') })
          }
        } else {
          if (obj.type === 'text' || obj.type === 'bool') {
            filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: q[k] })
          }
          if (obj.type === 'ozellik') {
            const liste = _isArray(q[k]) ? q[k].join(' - ') : q[k];
            const ozellikObj = { title: k.split('.')[1].replace(/([A-Z])/g, ' $1').replace(/^[\s_]+|[\s_]+$/g, '').replace(/[_\s]+/g, ' '), value: liste }
            const isExist = filterArray.findIndex(x => x.key === obj.key);
            if (isExist > -1) {
              filterArray[isExist].value.push(ozellikObj);
            } else {
              filterArray.push({ key: obj.key, title: obj.title, type: obj.type, value: [ozellikObj] })
            }
          }
        }
      }
    }
    return filterArray;
  }

  const handleGetDetail = async (item) => {
    setDetailLoading(true);
    if (item) {
      let form = [];
      for (let i = 0; i < editablePropList.length; i++) {
        if (editablePropList[i].type === "kosul") {
          await MapQueryString(item[editablePropList[i].key]).then((res) => {
            let obj = { key: editablePropList[i].key, type: editablePropList[i].type, label: editablePropList[i].label, [editablePropList[i].key]: res };
            form.push(obj);
          })
        } else {
          let obj = { key: editablePropList[i].key, type: editablePropList[i].type, label: editablePropList[i].label, required: editablePropList[i].required, propname: editablePropList[i].propname ?? '', pagename: editablePropList[i].pagename ?? '', [editablePropList[i].key]: item[editablePropList[i].key] };
          form.push(obj);
        }
      }
      setSelectedItemId(item.id);
      setSelectedItem(form);
      setSelectedItemDefault(form);
      setSelectedErrors(null);
      setDetailLoading(false);
    } else {
      setDetailErrorMessage('Beklenmeyen bir hata oluştu')
      setDetailLoading(false);
    }
  }

  const handleChangeDetail = (index, property, value) => {
    setSelectedItem((prev) => {
      return immer(prev, (next) => {
        next[index][property] = value;
      });
    });
  }

  const checkAll = async (form, formdef) => {
    const list = [];
    for (let i = 0; i < editablePropList.length; i++) {
      if (editablePropList[i].required) {
        list.push({ prop: editablePropList[i].key, empty: true, unique: true, multiple: false, multipletext: '', propname: editablePropList[i].propname, pagename: editablePropList[i].pagename, prop2: '' },)
      }
    }
    let errors = [];
    await checkAllList(`${listPath}`, form, formdef, handleErrors, false, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {
    setDetailLoading(true);
    let data = {};
    let dataDefault = {};
    for (let i = 0; i < selectedItem.length; i++) {
      if (selectedItem[i].type !== "kosul") {
        data[selectedItem[i].key] = selectedItem[i][selectedItem[i].key];
      }
    }
    for (let i = 0; i < selectedItemDefault.length; i++) {
      if (selectedItemDefault[i].type !== "kosul") {
        dataDefault[selectedItemDefault[i].key] = selectedItemDefault[i][selectedItemDefault[i].key];
      }
    }
    checkAll(data, dataDefault).then((res) => {
      if (_isEmpty(res)) {
        const requestoptions = {
          config: {
            method: 'PATCH',
            url: `${listPath}/${selectedItemId}`,
            data: data
          },
          successMessage: `Güncelleme başarıyla tamamlandı`,
          errorMessageUnexpected: `Güncellenirken beklenmeyen bir hata oluştu`,
        }
        setSelectedErrors(null);
        django(requestoptions.config).then(({ data }) => {
          enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
          setLastUpdateTime(Date.now());
          const mainmapped = mapItem(data);
          handleGetDetail(mainmapped);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
            } else {
              setSelectedErrors(error.response.data);
            }
          } else {
            enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
          }
        }).finally(() => {
          setDetailLoading(false);
        })
      }
    })
  }

  const handleDelete = async () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${listPath}/${selectedItemId}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setLastUpdateTime(Date.now());
        setSelectedItemId(null);
        setSelectedItem([]);
        setSelectedItemDefault([]);
        setSelectedErrors(null);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  }

  const handleCancel = () => {
    setSelectedItem(selectedItemDefault);
  }

  const handleErrors = (errors) => {
    setSelectedErrors((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const rowRenderer = ({ index, style }) => {
    const item = FILTERED_ITEMS[index];
    const checked = selectedItems.findIndex((x) => x.id === item.id) > -1;
    return (
      <div key={item.id} style={style} >
        {index !== 0 && <Divider />}
        <ListItem
          item={item}
          checked={checked}
          searchQuery={searchQuery}
          onSelect={(checked) => handleCheckItem(item, checked)}
          onClick={() => { handleGetDetail(item) }}
          value={valueProp}
        />
      </div>
    )
  }

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  return (
    <>
      <div className="w-full h-full flex justify-between">
        <Paper className="w-full h-full flex flex-col overflow-hidden">
          <AppBar
            className="border-b border-palette-action-selected"
            position="sticky"
            color="transparent"
            elevation={0}
          >
            <Toolbar
              className={classes.toolbar}
              variant="dense"
              disableGutters
            >
              <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">{title}</h3>
              <div>
                <Tooltip title="Yenile">
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={handleReload}
                  ><IconRefresh /></IconButton>
                </Tooltip>
                <Tooltip title="Kapat">
                  <IconButton
                    edge="end"
                    color="inherit"
                    size="small"
                    onClick={onClose}
                  ><IconClose /></IconButton>
                </Tooltip>
                {!detailOpen && (
                  <Tooltip title="Detayı Aç">
                    <IconButton
                      edge="end"
                      color="inherit"
                      size="small"
                      onClick={() => onDetayListClose(true)}
                    ><IconListTree /></IconButton>
                  </Tooltip>
                )}
              </div>
            </Toolbar>
            <nav className="w-full flex-1 flex items-center justify-between bg-palette-background-default py-2 px-4 space-x-4">
              <span className="relative w-full h-8 leading-none bg-palette-background-paper apperance-none">
                <input
                  className={clsx([
                    'absolute inset-0 w-full h-full px-10 bg-transparent rounded border border-palette-action-selected',
                    'focus:outline-none focus:border-primary-300 focus:ring-2 focus:ring-primary-200',
                  ])}
                  value={searchQuery}
                  placeholder={title + ' içinde ara'}
                  onChange={(e) => {
                    setSearchQuery(e.target.value)
                  }}
                />
                <span className="absolute top-2 left-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                  <IconSearch />
                </span>
                {searchQuery.length > 0 &&
                  <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSearchQuery('');
                      }}
                    >
                      <IconClose />
                    </IconButton>
                  </span>
                }
              </span>

              <span>
                <Tooltip title={"Tüm seçimleri kaldır"}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleRemoveItems();
                    }}
                  >
                    <IconDelete />
                  </IconButton>
                </Tooltip>
              </span>

            </nav>
            <span className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', { 'hidden': !SHOW_LINEAR_PROGRESS })}>
              <LinearProgress style={{ height: 2 }} />
            </span>
          </AppBar>

          <main className="relative flex flex-grow flex-col overflow-hidden" ref={mainContainerRef}>
            <div className="h-full flex-grow">
              {LOADING_MESSAGE && (
                <LoadingMessage text={LOADING_MESSAGE} />
              )}
              {ERROR_MESSAGE && (
                <ErrorMessage text={ERROR_MESSAGE} />
              )}
              {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length === 0) && (
                <ErrorMessage text="Seçenek bulunamadı" />
              )}
              {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length > 0) && (
                <InfiniteLoader
                  minimumBatchSize={PER_PAGE}
                  threshold={PER_PAGE}
                  isRowLoaded={({ index }) => !!FILTERED_ITEMS[index]}
                  loadMoreRows={({ startIndex, stopIndex }) => {
                    if (itemsTotalCount > 0 && PAGE_COUNT > PAGE && !moreItemsLoading) {
                      handleGetMoreOptions(PAGE + 1);
                    }
                  }}
                  rowCount={itemsTotalCount > 0 ? itemsTotalCount : PER_PAGE * 2}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                      {({ width, height }) => (
                        <List
                          width={width}
                          height={height}
                          rowHeight={ROW_HEIGHT + 1}
                          rowCount={FILTERED_ITEMS.length}
                          estimatedRowSize={PAGE_COUNT > 0 ? PAGE_COUNT * (ROW_HEIGHT + 1) : undefined}
                          rowRenderer={rowRenderer}
                          onRowsRendered={onRowsRendered}
                          ref={registerChild}
                        />
                      )}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              )}
            </div>

            <div className="flex items-center justify-between border-t border-palette-action-selected p-2">
              <span></span>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disableRipple
                  disabled={selectItemsEnable}
                  onClick={onSubmitItems}
                >UYGULA</Button>
              </span>
            </div>
          </main>
        </Paper>

        <Paper className={classes.paper} style={{ display: detailOpen ? 'block' : 'none' }}>
          <AppBar
            className="border-b border-palette-action-selected"
            position="sticky"
            color="transparent"
            elevation={0}
          >
            <Toolbar
              className={classes.toolbar}
              variant="dense"
              disableGutters
            >
              <div className="flex items-center">
                <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">DETAY</h3>
                <Tooltip title="Detayı Kapat">
                  <span>
                    <IconButton
                      edge="end"
                      color="inherit"
                      size="small"
                      onClick={() => onDetayListClose(false)}
                    ><IconClose /></IconButton>
                  </span>
                </Tooltip>
              </div>

              <div>
                <Tooltip title="Değişiklikleri İptal Et">
                  <span>
                    <IconButton
                      edge="end"
                      color="inherit"
                      size="small"
                      onClick={handleCancel}
                      disabled={IS_EQUAL}
                    ><IconRestore /></IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Değişiklikleri Kaydet">
                  <span>
                    <IconButton
                      edge="end"
                      color="inherit"
                      size="small"
                      onClick={handleSave}
                      disabled={IS_EQUAL}
                    ><IconSave /></IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Sil">
                  <span>
                    <IconButton
                      edge="end"
                      color="inherit"
                      size="small"
                      onClick={() => setShowDeleteDialog(true)}
                    ><IconDelete /></IconButton>
                  </span>
                </Tooltip>
              </div>
            </Toolbar>
          </AppBar >

          <main className="relative flex flex-grow flex-col overflow-y-auto p-4">
            <div className="h-full flex-grow">
              {LOADING_MESSAGE_DETAIL && (
                <LoadingMessage text={LOADING_MESSAGE_DETAIL} />
              )}
              {ERROR_MESSAGE_DETAIL && (
                <ErrorMessage text={ERROR_MESSAGE_DETAIL} />
              )}
              {(!LOADING_MESSAGE_DETAIL && !ERROR_MESSAGE_DETAIL && selectedItem.length === 0) && (
                <ErrorMessage text="Detay seçilmedi" />
              )}
              {(!LOADING_MESSAGE_DETAIL && !ERROR_MESSAGE_DETAIL && selectedItem.length > 0) && (
                <div className={classes.layoutFormList}>
                  {selectedItem.map((item, index) => (
                    <>
                      {item.type === "text" && (
                        <div key={item.key} className={classes.layoutFormItem} >
                          <label className={classes.layoutFormItemLabel} htmlFor={item.key}>{item.label}</label>
                          <Tooltip title={selectedErrors?.[item.key] ? selectedErrors[item.key][0] : _isNil(item?.[item.key]) ? '' : item[item.key]}>
                            <TextField
                              name={item.key}
                              variant='outlined'
                              size='small'
                              fullWidth={true}
                              value={_result(item, item.key, "")}
                              error={_has(selectedErrors, `${item.key}`)}
                              onChange={(e) => handleChangeDetail(index, item.key, e.target.value)}
                              onBlur={() => {
                                if (item.required) {
                                  handleInputErrors(listPath, item, selectedItemDefault[index], handleErrors, false, [item.key], true, true, false, '', item.propname, item.pagename)
                                }
                              }}
                              inputProps={{
                                maxLength: 250
                              }}
                            ></TextField>
                          </Tooltip>
                        </div>
                      )}
                      {item.type === "bool" && (
                        <div key={item.key} className={classes.layoutFormItem} >
                          <label className={classes.layoutFormItemLabel} htmlFor={item.key}>{item.label}</label>
                          <Checkbox
                            name={item.key}
                            color="primary"
                            checked={_result(item, item.key, false)}
                            onChange={(e, checked) => handleChangeDetail(index, item.key, checked)}
                          ></Checkbox>
                        </div>
                      )}
                      {item.type === "kosul" && (
                        <div key={item.key} className='w-full flex flex-col'>
                          {item[item.key].map((k) => (
                            <div className={classes.rowItem} key={k.key}>
                              <div className={classes.rowLabel}>{k.title}</div>
                              {k.type !== "ozellik" && (
                                <div>{k.value}</div>
                              )}
                              {k.type === "ozellik" && (
                                <div className='w-full flex flex-col'>
                                  {k.value.map((o) => (
                                    <div key={o.title} className={classes.ozellikRow}>
                                      <div className={classes.ozellikRowLabel}>{o.title}</div>
                                      <div>{o.value}</div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
          </main>
        </Paper >
      </div >
      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? deleteM
                : deleteM2
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
    </>
  );
};


export default ListSelectorTwoStep;
