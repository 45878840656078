import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';

import LayoutDetailWithoutTabs from '../../../pages/base/LayoutDetailWithoutTabs';
import { checkAllList } from '../../base/InputErrors';

import TabGenel from './MesajDetailTab_Genel';

const PATH = 'mesaj_baslik';

const FORM_DEFAULT = {
  baslik: '',
  departman: null,
  kime: null,
};

const PER_PAGE = 100;

const MesajDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [mesajLoading, setMesajLoading] = useState(false);
  const [hataGenel, setHataGenel] = useState(null);
  const [hataMesaj, setHataMesaj] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [mesajlar, setMesajlar] = useState([]);
  const [mesajlarDefault, setMesajlarDefault] = useState([]);
  const [mesajlarCount, setMesajlarCount] = useState(0);
  const [tumMesajlarCount, setTumMesajlarCount] = useState(0);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [done, setDone] = useState(false);

  const [mesaj, setMesaj] = useState(null);
  const [mesajDefault, setMesajDefault] = useState(null);
  const [mesajError, setMesajError] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.baslik;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    return isEqualGenel;
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    setMesajlar([]);
    setMesajlarDefault([]);
    setMesajlarCount(0);
    setPage(1);
    setLastPage(1);
  }, [ID]);

  useLayoutEffect(() => {
    setLoading(true);
    if (!IS_ADD) {
      const debounce = setTimeout(() => {
        django(`${PATH}/${ID}`).then(({ data }) => {
          const form = {
            baslik: data.baslik,
            departman: data.departman,
            kime: data.kime,
          };
          setGenelFormDefault(form);
          setGenelForm(form);
          setHataGenel(null);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataGenel(true);
            }
          }
        }).finally(() => {
          setLoading(false);
          setGenelError(null);
          setAdd(false);
        });

      }, 300);
      return () => {
        clearTimeout(debounce);
        setLoading(false);
      };
    } else {
      setGenelForm(FORM_DEFAULT);
      setGenelError(null);
      setHataGenel(null);
      setAdd(true);
      setLoading(false);
    }
  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    if (!IS_ADD) {
      const debounce = setTimeout(() => {
        django(`${PATH}/${ID}/mesaj/count`).then(({ data }) => {
          setTumMesajlarCount(data);
          setLastPage(Math.ceil(data / PER_PAGE));
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataMesaj(true);
            }
          }
        });
      }, 300);
      return () => {
        clearTimeout(debounce);
      };
    } else {
      setTumMesajlarCount(0);
      setLastPage(1);
    }
  }, [ID, lastUpdateTime, IS_ADD]);

  useLayoutEffect(() => {
    setPage((prev) => immer(prev, (next) => {
      if (mesajlarCount > 0) {
        if (tumMesajlarCount > mesajlarCount) {
          if (Math.ceil(mesajlarCount / PER_PAGE) === next) {
            return next + 1
          } else {
            return Math.ceil(mesajlarCount / PER_PAGE)
          }
        }
      }
    }));
  }, [tumMesajlarCount, mesajlarCount]);

  useLayoutEffect(() => {
    setDone(false);
    setMesajLoading(true);
    if (!IS_ADD) {
      const debounce = setTimeout(() => {
        django(`${PATH}/${ID}/mesaj`, { params: { size: PER_PAGE, page } }).then(({ data }) => {
          const form = data.map((x) => ({
            id: x.id,
            mesaj_baslik: x.mesaj_baslik.id,
            mesaj: x.mesaj,
            zaman: x.olusturma_zamani,
            yonetici: x.yonetici
          }));
          setMesajlar((prev) => immer(prev, (next) => {
            next.push.apply(next, form);
          }));
          setMesajlarDefault((prev) => immer(prev, (next) => {
            setMesajlarCount([...prev, ...form].length);
            next.push.apply(next, form);
          }));
          setMesaj({ mesaj: "", mesaj_baslik: ID });
          setMesajDefault({ mesaj: "", mesaj_baslik: ID });
          setMesajError(null);
          setHataMesaj(null);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataMesaj(true);
            }
          }
        }).finally(() => {
          setMesajError(null);
          if (lastPage === page) {
            setMesajLoading(false);
            setMesajlar((prev) => immer(prev, (next) => {
              next.reverse()
            }));
            setMesajlarDefault((prev) => immer(prev, (next) => {
              next.reverse()
            }));
            setDone(true);
          }
        });
      }, 300);
      return () => {
        clearTimeout(debounce);
        setMesajLoading(false);
      };
    } else {
      setMesajlar([]);
      setMesajlarDefault([]);
      setMesajlarCount(0);
      setMesaj({ mesaj: "", mesaj_baslik: null });
      setMesajDefault({ mesaj: "", mesaj_baslik: null });
      setMesajError(null);
      setHataMesaj(null);
      setMesajLoading(false);
    }
  }, [ID, lastUpdateTime, IS_ADD, page, lastPage]);

  useLayoutEffect(() => {
    if (done) {
      const control = setInterval(() => {
        django(`${PATH}/${ID}/mesaj/count`).then(({ data }) => {
          if (data > tumMesajlarCount) {
            handleReload();
          }
        })
      }, 10000)
      return () => {
        clearInterval(control);
      };
    }
  }, [done, ID, tumMesajlarCount])

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleChangeMesaj = (value) => {
    setMesaj((prev) => {
      return immer(prev, (next) => {
        next["mesaj"] = value;
      });
    });
  }

  const handleAdd = () => {
    setAdd(true);
    setGenelForm(FORM_DEFAULT);
    setMesajlar([]);
    setMesaj({ mesaj: "", mesaj_baslik: null });
    setGenelError(null);
    setMesajError(null);
  };

  const handleReload = () => {
    setMesajlar([]);
    setMesajDefault([]);
    setMesajlarCount(0);
    setPage(1);
    setLastUpdateTime(Date.now());
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setMesajlar(mesajlarDefault);
    setMesaj(mesajDefault);
    setGenelError(null);
    setMesajError(null);
    if (!IS_ADD) {
      setAdd(false);
    }
  };

  const checkAll = async () => {
    const list = [
      { prop: 'baslik', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'departman', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'kime', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ]
    let errors = [];
    await checkAllList('mesaj_baslik', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const checkAll2 = async () => {
    const list = [
      { prop: 'mesaj', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ]
    let errors = [];
    await checkAllList('', mesaj, mesajDefault, handleErrorsMesaj, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {
    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (!add) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            reqopt = {
              data: { ...genelForm, departman: genelForm.departman?.id ?? null, kime: genelForm.kime?.id ?? null },
              method: 'POST',
              id: null,
              errorMessageUnexpected: `Mesaj gönderilirken beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabMesaj = async () => {
      let reqopt = null;
      let err = null;
      await checkAll2().then((res) => {
        if (_isEmpty(res)) {
          reqopt = {
            data: mesaj,
            method: 'POST',
            successMessage: `Mesaj başarıyla gönderildi`,
            errorMessageUnexpected: `Mesaj gönderilirken beklenmeyen bir hata oluştu`,
          };
          err = null;
        } else {
          reqopt = null;
          err = res;
        }
      })
      return [reqopt, err]
    }

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'mesaj', request: [], error: [] },
      ];
      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      const res2 = await createRequestOptionsTabMesaj();
      tabs[1].request.push(res2[0]);
      tabs[1].error.push(res2[1]);
      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: "mesaj_baslik"
              }).then(({ data }) => {
                if (props.single && add) {
                  history.push(URLS.tanimlar.mesaj.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
              }).catch((error) => {
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
              });
            }
            if (SAVEID !== null) {
              if (tab.name === 'mesaj') {
                setMesajError(null);
                await django({
                  method: request.method,
                  data: request.data,
                  url: `mesaj_baslik/${SAVEID}/mesaj`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  setMesajlar([]);
                  setMesajDefault([]);
                  setMesajlarCount(0);
                  setPage(1);
                  setLastUpdateTime(Date.now());
                }).catch((error) => {
                  if (error.response) {
                    if (error.response.status === 500) {
                      enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                    } else {
                      setMesajError(error.response.data);
                    }
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                });
              }
            }
          }
        });
      });
    }
    await start();
  };


  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsMesaj = (errors) => {
    setMesajError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }
  return (
    <>
      <LayoutDetailWithoutTabs
        loading={loading}
        title={TITLE}
        single={props.single}

        canClose={true}
        canOpenNew={true}
        canAdd={true}
        canReload={true}
        canSave={false}
        canCancel={true}
        canDelete={false}
        canPrevNext={true}
        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        <TabGenel
          loading={loading}
          hata={hataGenel}
          add={add}
          mesajLoading={mesajLoading}
          hataMesaj={hataMesaj}

          form={genelForm}
          formdefault={genelFormDefault}
          error={genelError}
          onChangeForm={handleGenelChangeForm}
          onCheckErrors={(errors) => handleErrors(errors)}

          mesaj={mesaj}
          mesajDefault={mesajDefault}
          mesajError={mesajError}
          onChangeMesaj={handleChangeMesaj}
          onSendMessage={handleSave}

          mesajlar={mesajlar}
          mesajlarCount={mesajlarCount}
          tumMesajlarCount={tumMesajlarCount}
          perPage={PER_PAGE}
        />
      </LayoutDetailWithoutTabs>
    </>
  );
}

export default MesajDetail;