import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';

import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  Button,
  Checkbox,
  Paper,
  CircularProgress,
  LinearProgress,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Radio,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
  Search as IconSearch,
} from '@material-ui/icons';

import django from '../api/django';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  return {
    text: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
      padding: "0 5px",
      textAlign: "center"
    },
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
    }
  };
});

const PER_PAGE = 16;

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text, submitText, onSubmit }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>

      <p className="font-medium mb-2">{text}</p>

      {onSubmit &&
        <Button
          color="primary"
          onClick={onSubmit}
        >{submitText ?? 'TEKRAR DENE'}</Button>
      }
    </div>
  );
};




const ListPhotoSelector = ({ defaultItems, onChangeItems, onClose, single }) => {
  const [items, setItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsLoadingErrorMessage, setItemsLoadingErrorMessage] = useState(null);
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchItems, setSearchItems] = useState([]);
  const [searchItemsLoading, setSearchItemsLoading] = useState(false);
  const [searchItemsLoadingErrorMessage, setSearchItemsLoadingErrorMessage] = useState(null);
  const [showSelectedItems, setShowSelectedItems] = useState(false);

  const classes = useStyles();

  const DEFAULT_ITEMS = useMemo(() => defaultItems, [defaultItems]);
  const PAGE = useMemo(() => items.length > 0 ? Math.ceil(items.length / PER_PAGE) : 1, [items.length]);
  const PAGES_COUNT = useMemo(() => Math.ceil(itemsTotalCount / PER_PAGE), [itemsTotalCount]);

  const SHOW_LINEAR_PROGRESS = useMemo(() => {
    if (itemsLoading || moreItemsLoading || searchItemsLoading) {
      return true;
    }
    return false;
  }, [itemsLoading, moreItemsLoading, searchItemsLoading]);

  const LOADING_MESSAGE = useMemo(() => {
    if (itemsLoading) {
      return 'Seçenekler yükleniyor';
    }
    if (searchItemsLoading) {
      return 'Arama yapılıyor';
    }
    return null;
  }, [itemsLoading, searchItemsLoading]);

  const ERROR_MESSAGE = useMemo(() => {
    if (itemsLoadingErrorMessage) {
      return itemsLoadingErrorMessage;
    }
    if (searchItemsLoadingErrorMessage) {
      return searchItemsLoadingErrorMessage;
    }
    return null;
  }, [itemsLoadingErrorMessage, searchItemsLoadingErrorMessage]);

  const FILTERED_ITEMS = useMemo(() => {
    if (showSelectedItems) {
      if (single) {
        return [DEFAULT_ITEMS];
      } else {
        return DEFAULT_ITEMS;
      }
    }
    if (searchQuery !== '') {
      return searchItems;
    }
    return items;
  }, [DEFAULT_ITEMS, showSelectedItems, items, searchItems, searchQuery, single]);

  useLayoutEffect(() => {
    setItemsLoading(true);
    django('foto', { params: { size: PER_PAGE } }).then(({ data }) => {
      setItems(data.map(f => (
        {
          id: f.id,
          tanim: f.tanim,
          image: f.image,
          thumbnail: f.thumbnail
        }
      )));
    }).catch(() => {
      setItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
    }).finally(() => {
      setItemsLoading(false);
    });
  }, []);

  useLayoutEffect(() => {
    django('foto/count').then(({ data }) => {
      setItemsTotalCount(data);
    })
  }, []);


  useEffect(() => {
    const handleGet = ({ url, config }) => {
      setSearchItems([]);
      setSearchItemsLoading(true);
      setSearchItemsLoadingErrorMessage(null);
      django(url, config).then(({ data }) => {
        setSearchItems(data.map(f => (
          {
            id: f.id,
            tanim: f.tanim,
            image: f.image,
            thumbnail: f.thumbnail
          }
        )));
      }).catch(function (error) {
        if (django?.isCancel(error)) {
        } else {
          setSearchItemsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
        }
      }).finally(() => {
        setSearchItemsLoading(false);
      });
    };
    if (searchQuery === '') {
      setSearchItems([]);
      setSearchItemsLoading(false);
      setSearchItemsLoadingErrorMessage(null);
    }

    if (searchQuery !== '') {
      setSearchItems([]);
      setSearchItemsLoading(true);
      setSearchItemsLoadingErrorMessage(null);
      const CancelToken = django.CancelToken;
      const cancelTokenSource = CancelToken.source();
      const config = {
        params: {
          size: PER_PAGE,
          tanim: `*${searchQuery}*`,
        },
        cancelToken: cancelTokenSource.token,
      };
      const debounce = setTimeout(() => handleGet({ url: 'foto', config }), 300);
      return () => {
        cancelTokenSource.cancel();
        clearTimeout(debounce);
        setSearchItemsLoading(false);
      };
    }
  }, [searchQuery]);

  const handleCheckItem = (item, checked) => {
    if (single) {
      let copyDefaultItems = item;
      onChangeItems(copyDefaultItems);
    } else {
      let copyDefaultItems = [...DEFAULT_ITEMS];
      if (checked) {
        copyDefaultItems.push(item);
      } else {
        const index = copyDefaultItems.findIndex((item2) => item2.id === item.id);
        if (index > -1) {
          copyDefaultItems.splice(index, 1);
        }
      }
      onChangeItems(copyDefaultItems);
    }
  };

  const handleGetMoreOptions = (page) => {
    if (itemsTotalCount > 0 && PAGES_COUNT > PAGE && !moreItemsLoading && !showSelectedItems) {
      setMoreItemsLoading(true);
      django('foto', { params: { size: PER_PAGE, page } }).then(({ data }) => {
        setItems((prev) => [...prev, ...data]);
      }).finally(() => {
        setMoreItemsLoading(false);
      });
    }
  };

  return (
    <>
      <Paper className="flex flex-col w-full h-full overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">Galeri</h3>
            <Tooltip title="Kapat">
            <IconButton
              edge="end"
              color="inherit"
              size="small"
              onClick={onClose}
            ><IconClose /></IconButton>
            </Tooltip>
          </Toolbar>

          <nav className="w-full flex-1 flex items-center justify-between bg-palette-background-default py-2 px-4 space-x-4">
            <span className="relative w-full h-8 leading-none bg-palette-background-paper apperance-none">
              <input
                className={clsx([
                  'absolute inset-0 w-full h-full px-10 bg-transparent rounded border border-palette-action-selected',
                  'focus:outline-none focus:border-primary-300 focus:ring-2 focus:ring-primary-200',
                ])}
                value={searchQuery ?? ''}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <span className="absolute top-2 left-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                <IconSearch />
              </span>

              {searchQuery.length > 0 &&
                <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchQuery('');
                    }}
                  >
                    <IconClose />
                  </IconButton>
                </span>
              }
            </span>

            <span>
              <Tooltip title="Sadece seçili seçenekleri göster">
                <Checkbox
                  color="primary"
                  checked={showSelectedItems}
                  style={{ padding: 0 }}
                  disableRipple
                  onChange={(e, checked) => setShowSelectedItems(checked)}
                ></Checkbox>
              </Tooltip>
            </span>
          </nav>

          <span className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', { 'hidden': !SHOW_LINEAR_PROGRESS })}>
            <LinearProgress style={{ height: 2 }} />
          </span>
        </AppBar>
        <main className="relative flex flex-grow flex-col overflow-hidden">
          <div className="h-full flex-grow">
            {LOADING_MESSAGE && (
              <LoadingMessage text={LOADING_MESSAGE} />
            )}
            {ERROR_MESSAGE && (
              <ErrorMessage text={ERROR_MESSAGE} />
            )}
            {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length === 0) && (
              <ErrorMessage text="Seçenek bulunamadı" />
            )}
            {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_ITEMS.length > 0) && (
              <div id="scrollableDiv" className="h-full overflow-auto">
                <InfiniteScroll
                  dataLength={FILTERED_ITEMS.length}
                  next={() => handleGetMoreOptions(PAGE + 1)}
                  hasMore={true}
                  scrollableTarget="scrollableDiv"
                  style={{ display: "grid", gridTemplateColumns: "25% 25% 25% 25%" }}
                >
                  {FILTERED_ITEMS.map((item, index) => (
                    <div key={`${item.id}${item.tanim}`} className="flex flex-col items-center" style={{ marginTop: 20 }}>
                      <img src={item.thumbnail} alt={item.tanim} />
                      <Highlighter
                        textToHighlight={`${item.tanim}`}
                        searchWords={[searchQuery ?? '']}
                        className={classes.text}
                        activeClassName=""
                        highlightClassName="leading-none"
                        unhighlightClassName="leading-none"
                      />
                      {single && (
                        <Radio
                          color="primary"
                          checked={DEFAULT_ITEMS ? DEFAULT_ITEMS.id === item.id : false}
                          value={item.id}
                          onChange={() => handleCheckItem(item)}
                          style={{ padding: 0 }}
                        ></Radio>
                      )}
                      {!single && (
                        <Checkbox
                          color="primary"
                          checked={DEFAULT_ITEMS.findIndex((item2) => item2.id === item.id) > -1}
                          onChange={(e, checked) => handleCheckItem(item, checked)}
                          style={{ padding: 0 }}
                        ></Checkbox>
                      )}
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            )}
          </div>
        </main>

      </Paper>
    </>
  )
}

export default ListPhotoSelector;