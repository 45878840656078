import React, { useMemo, useState, useLayoutEffect } from 'react';
import clsx from 'clsx';
import {
  Tabs,
  Tab,
  Divider,
} from '@material-ui/core';

import {
  Error as IconError
} from '@material-ui/icons';

import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import Store from '../../libs/store'
import Themes from '../../themes/index';
const appTheme = Themes[Store.get('themeChoice')?.theme ?? 'light'];
const theme = {
  overrides: {
    MuiTabs: {
      root: {
        minWidth: undefined,
        overflow: 'visible',
        borderRight: undefined,
      },
    },
    MuiTab: {
      root: {
        padding: appTheme.spacing(.75),
        margin: appTheme.spacing(.75, 0, .75, 0),
        maxWidth: 'none',
        minHeight: 0,
        textTransform: undefined,
        [appTheme.breakpoints.up('sm')]: {
          minWidth: 0,
        },
      },
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        textAlign: 'left',
        flexDirection: undefined,
      },
      selected: {
        backgroundColor: appTheme.palette.primary.main,
        color: '#fff !important'
      }
    },
    PrivateTabIndicator: {
      vertical: {
        right: undefined,
        left: 0,
      },
    },

  },
};
const styles = ({ palette, spacing }) => {
  return {
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflow: 'hidden',
    },
    side: {
      height: '100%',
      width: 250,
      padding: spacing(2, 0, 2, 0),
      overflowY: 'auto'
    },
    main: {
      width: '100%',
      height: '100%',
      flex: 1,
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'flex-start',
      borderLeft: `1px solid ${palette.action.selected}`,
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      padding: spacing(2)
    },
  }
};

const FullTabList = withStyles(styles)(({ classes, ...props }) => {
  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const DETAIL = useMemo(() => tabs[selectedTabIndex]?.detail ?? null, [tabs, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.tabs.length > 0) {
      setTabs(props.tabs)
    } else {
      setTabs([]);
    }
  }, [props.tabs]);


  return (
    <>
      <div className="relative w-full h-full flex flex-col rounded bg-palette-background-default border-2 border-palette-action-disabledBackground">
        {/* top.navbar */}
        <nav className="relative w-full h-10 flex items-center justify-between bg-palette-action-hover border-b border-palette-action-selected z-10">
          <div className="flex items-center justify-start space-x-2 px-2">
            {props.title && (
              <h1 className="flex items-center justify-start m-0">
                <span className="text-sm font-medium leading-none">{props.title}</span>
              </h1>
            )}
            {props.headerButton && props.headerList.map((h) => (
              <div className='flex items-center justify-start space-x-2' key={h.index}>
                {h.index !== 0 &&
                  <Divider orientation="vertical" flexItem></Divider>
                }
                <h1 className="flex items-center justify-start m-0">
                  <span onClick={() => { if (h.func) { h.func() } }} className={clsx('text-sm font-medium leading-none p-1', { 'bg-primary text-palette-background-paper rounded': h.active }, { 'cursor-pointer': h.func })}>{h.title}</span>
                </h1>
              </div>
            ))}
          </div>
        </nav>
        {/* container.content */}
        <div className={classes.container}>
          {/* side.content */}
          <aside className={classes.side}>
            {tabs.length > 0 && (
              <ThemeProvider theme={theme}>
                <Tabs
                  orientation="vertical"
                  // indicatorColor="primary"
                  TabIndicatorProps={{ style: { display: 'none' } }}
                  textColor="primary"
                  variant="standard"
                  value={selectedTabIndex}
                  onChange={(e, index) => setSelectedTabIndex(index)}
                >
                  {tabs.map((tab, index) => (
                    <Tab
                      wrapped
                      key={index}
                      label={
                        <div>
                          <span>{tab.label}</span>
                          {tab.hasError && (<IconError style={{ fontSize: 16, color: 'red' }} />)}
                        </div>
                      }
                    />
                  ))}
                </Tabs>
              </ThemeProvider>
            )}
          </aside>
          {/* main.content */}
          <main className={classes.main}>
            {DETAIL}
          </main>
        </div>
      </div>
    </>
  );
});

export default FullTabList;

