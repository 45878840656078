import React, { useLayoutEffect, useState } from 'react';

import {
  Button,
  IconButton
} from '@material-ui/core';

import {
  isObject as _isObject,
  isArray as _isArray
} from 'lodash';

import {
  HelpOutline as IconHelp
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

const styles = ({ palette, spacing }) => {
  return {
    button: {
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    ul: {
      padding: spacing(1, 2, 1, 2),
      margin: 0
    },
    li: {
      listStyle: 'none',
      cursor: 'pointer'
    },
  };
};


const JsonConvert = withStyles(styles)(({ classes, headername, name, name2, jsonlist, loading, helper = false, helperOpen, helperSet }) => {
  const [list, setList] = useState([]);
  useLayoutEffect(() => {
    const createJsonToArray = (obj) => {
      let arr = [];
      for (let key in obj) {
        if (_isObject(obj[key])) {
          arr.push({ key: key, value: "", children: createJsonToArray(obj[key]) })
        } else {
          arr.push({ key: key, value: _isArray(obj[key]) ? obj[key].join(', ') : obj[key], children: [] })
        }
      }
      return arr;
    }
    setList(createJsonToArray(jsonlist))
  }, [jsonlist]);

  const treeSet = (liste) => {
    return liste.map((item) => {
      if (item.children.length === 0) {
        return (
          <li key={item.key} className={classes.li}>
            <span>{item.key} : {item.value}</span>
          </li>
        )
      } else {
        return (
          <li key={item.key} className={classes.li} >
            <span>{item.key} : {item.value}</span>
            <ul className={classes.ul}>{treeSet(item.children)}</ul>
          </li>
        )
      }
    })
  }

  return (
    <>
      <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
        <header className="flex items-center justify-between py-2 px-4">
          <Button
            className="pointer-events-none"
            color="inherit"
          >{headername}</Button>
          <span className="space-x-3">
            {helper && (
              <IconButton
                size="small"
                className={classes.button}
                disableRipple
                onClick={() => {
                  helperSet();
                  helperOpen();
                }}
              ><IconHelp /></IconButton>
            )}
          </span>
        </header>
        <main className="border-t border-palette-action-hover py-1 max-h-48 overflow-y-auto">
          {list.length > 0 &&
            <ul className={classes.ul}>
              {treeSet(list)}
            </ul>
          }
          {(loading && !jsonlist) && (
            <p className="py-1 px-6 m-0">{name} alınıyor.</p>
          )}
          {(!loading && list.length === 0) && (
            <p className="py-1 px-6 m-0">{name2} yok.</p>
          )}
        </main>
      </section>
    </>
  )
});

export default JsonConvert