/* eslint-disable no-unused-vars */
import React, { useLayoutEffect, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';
import moment from 'moment';

import {
  has as _has,
  isEqual as _isEqual,
  isEmpty as _isEmpty,
  pickBy as _pickBy,
  identity as _identity,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach, SlugMaker } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './UrunDetailTab_Genel';
import TabMiktarlar from './UrunDetailTab_Miktarlar';
import TabIcerikler from './UrunDetailTab_Icerikler';
import TabFiyatlar from './UrunDetailTab_Fiyatlar';
import TabOzellikler from './UrunDetailTab_Ozellikler';
import TabResimler from './UrunDetailTab_Resim';
import TabBarkodlar from './UrunDetailTab_Barkod';
import TabTedarikciler from './UrunDetailTab_Tedarikciler';
import TabEkOzellikler from './UrunDetailTab_EkOzellikler';
import TabYorumlar from './UrunDetailTab_Yorumlar'

const PATH = 'urun';

const YORUM_PER_PAGE = 100;

const FORM_DEFAULT = {
  tanim: '',
  kodu: '',
  tip: '0',
  barkod: '',
  tedarik_suresi: '',
  katalogta_gizle: false,
  desi: '',
  birim: '-1',
  satis_birim_grup: '-1',
  uretici: '-1',
  vergi: null,
  vergi_baslik: null,
  vergi_tree_list: [],
  kategoriler: [],
  magazalar: [],
  gruplar: [],
  webde_goster: false,
  aktif: true,
  varyant_var: false
};

const ICERIKLER_FORM_DATA_ITEM_DEFAULT = {
  dil_icerik: {
    baslik: '',
    aciklama: '',
    bilgilendirme: '',
    kisa_aciklama: '',
    anahtar_kelimeler: '',
    tanim: '',
  },
  slug: '',
  arama_kelimeleri: [],
  dil: 0,
};

const FIYATLAR_FORM_DATA_DEFAULT = {
  form: {
    deger: "",
    liste_fiyat: "",
    fiyat_detay: null,
    para_birimi: '-1',
    olusturan_kullanici: "",
    degistiren_kullanici: "",
    olusturma_zamani: "",
    degistirme_zamani: "",
    fiyattanim: ""
  },
  errors: null
}

const BARKOD_FORM_DATA_DEFAULT = {
  form: {
    barkod: 'tanımsız',
    birim: '-1'
  },
  errors: null
}

const EKOZELLIKLER_FORM_DATA_ITEM_DEFAULT = {
  dil_id: '',
  tanim: '',
};

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'MİKTARLAR', disabled: false, hasError: false },
  { label: 'İÇERİKLER', disabled: false, hasError: false },
  { label: 'FİYATLAR', disabled: false, hasError: false },
  { label: 'ÖZELLİKLER', disabled: false, hasError: false },
  { label: 'FOTOĞRAFLAR', disabled: false, hasError: false },
  { label: 'BARKODLAR', disabled: false, hasError: false },
  { label: 'TEDARİKÇİLER', disabled: false, hasError: false },
  { label: 'EK ÖZELLİKLER', disabled: false, hasError: false },
  { label: 'YORUMLAR', disabled: false, hasError: false },
];

const createIceriklerStates = async (diller, urunDiller) => {
  const states = [];
  diller.sort((a, b,) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...ICERIKLER_FORM_DATA_ITEM_DEFAULT,
        dil: dil.id,
      },
      errors: null,
    };
    const urundil = urunDiller?.find((ud) => ud.dil === dil.id);
    if (urundil) {
      data.form.id = urundil.id;
      data.form.dil_icerik = urundil.dil_icerik;
      data.form.slug = urundil.slug;
      data.form.arama_kelimeleri = urundil.arama_kelimeleri ?? [];
    }
    states.push(data);
  });
  return states;
};

const createFiyatStates = async (fiyatList, urunFiyatlar) => {
  const states = [];
  for (const liste of fiyatList) {
    const res = (await django(`fiyattanim/${liste.listeId}/fiyatdetaylari`)).data;
    const form = [];
    for (const f of res) {
      const formIcerik = {
        form: { ...FIYATLAR_FORM_DATA_DEFAULT.form, fiyat_detay: f.id, fiyattanim: f.tanim },
        errors: null
      };
      const urunFiyat = urunFiyatlar?.find((uf) => uf.fiyat_detay === f.id);
      if (urunFiyat) {
        formIcerik.form.id = urunFiyat.id;
        formIcerik.form.deger = urunFiyat.deger;
        formIcerik.form.liste_fiyat = urunFiyat.liste_fiyat;
        formIcerik.form.fiyat_detay = urunFiyat.fiyat_detay;
        formIcerik.form.para_birimi = String(urunFiyat.para_birimi);

        formIcerik.form.olusturan_kullanici = urunFiyat.olusturan_kullanici.username;
        formIcerik.form.degistiren_kullanici = urunFiyat.degistiren_kullanici.username;
        formIcerik.form.olusturma_zamani = moment(urunFiyat.olusturma_zamani).format('DD/MM/YYYY, HH.mm');
        formIcerik.form.degistirme_zamani = moment(urunFiyat.degistirme_zamani).format('DD/MM/YYYY, HH.mm');
      }
      form.push(formIcerik);
    }
    const data = {
      liste: {
        listeId: liste.listeId,
        listeTanim: liste.listeTanim,
      },
      form: form,
      errors: null
    }
    states.push(data);
  }
  return states;
}

const crateOzellikList = async (ozellikler, urunOzellikler) => {
  let ozellikList = [];
  ozellikler.forEach(o => {
    const data = {
      ozellikBaslik: {
        id: o.id,
        tanim: o.tanim
      },
      array: urunOzellikler.filter(u => u.ozellik_baslik.id === o.id).map(x =>
        ({ id: x.ozellik_deger.id, tanim: x.ozellik_deger.tanim, parent: o.id }))
    }
    ozellikList.push(data);
  });
  return ozellikList;
}

const createOzelliklerStates = async (diller, urunEkOzellikler) => {
  const states = [];
  diller.sort((a, b,) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...EKOZELLIKLER_FORM_DATA_ITEM_DEFAULT,
        dil_id: dil.id,
      },
      errors: null,
    };
    const urunekozdil = urunEkOzellikler?.find((ud) => ud.dil_id === dil.id);
    if (urunekozdil) {
      data.form.tanim = urunekozdil.tanim;
    }
    states.push(data);
  });
  return states;
};


const TableDetailExampleDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingMiktar, setLoadingMiktar] = useState(true);
  const [loadingIcerik, setLoadingIcerik] = useState(true);
  const [loadingIcerikSet, setLoadingIcerikSet] = useState(true);
  const [loadingFiyat, setLoadingFiyat] = useState(true);
  const [loadingFiyat2, setLoadingFiyat2] = useState(true);
  const [loadingOzellik, setLoadingOzellik] = useState(true);
  const [loadingFoto, setLoadingFoto] = useState(true);
  const [loadingBarkod, setLoadingBarkod] = useState(true);
  const [loadingTedarikci, setLoadingTedarikci] = useState(true);
  const [loadingEkOzellikler, setLoadingEkOzellikler] = useState(true);
  const [loadingEkOzelliklerDil, setLoadingEkOzelliklerDil] = useState(true);
  const [loadingMetinOzellikler, setLoadingMetinOzellikler] = useState(true);
  const [loadingMetinOzelliklerDil, setLoadingMetinOzelliklerDil] = useState(true);
  const [loadingYorum, setLoadingYorum] = useState(true);
  const [loadingYorumMore, setLoadingYorumMore] = useState(false);
  const [loadingKampanya, setLoadingKampanya] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataMiktar, setHataMiktar] = useState(null);
  const [hataIcerik, setHataIcerik] = useState(null);
  const [hataFiyat, setHataFiyat] = useState(null);
  const [hataOzellik, setHataOzellik] = useState(null);
  const [hataFoto, setHataFoto] = useState(null);
  const [hataBarkod, setHataBarkod] = useState(null);
  const [hataTedarikci, setHataTedarikci] = useState(null);
  const [hataEkOzellik, setHataEkOzellik] = useState(null);
  const [hataMetinOzellik, setHataMetinOzellik] = useState(null);
  const [hataYorum, setHataYorum] = useState(null);
  const [hataKampanya, setHataKampanya] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [varyant, setVaryant] = useState(null);
  const [varyantDefault, setVaryantDefault] = useState(null);
  const [genelError, setGenelError] = useState(null);
  const [urunTipleri, setUrunTipleri] = useState([]);
  const [urunTipleriDic, setUrunTipleriDic] = useState({});
  const [birimList, setbirimList] = useState([]);
  const [birimListDic, setbirimListDic] = useState([]);
  const [birim, setBirim] = useState({});
  const [satisBirimGrup, setsatisBirimGrup] = useState([]);
  const [satisBirimGrupDic, setsatisBirimGrupDic] = useState(null);
  const [carpanList, setcarpanList] = useState([]);
  const [ureticiList, setureticiList] = useState([]);
  const [ureticiListDic, setureticiListDic] = useState({});
  const [parabirimList, setParabirimList] = useState([]);
  const [parabirimListDic, setParabirimListDic] = useState({});

  const [miktarlar, setMiktarlar] = useState([]);
  const [miktarlarDefault, setMiktarlarDefault] = useState([]);

  const [iceriklerSelectedTabIndex, setIceriklerSelectedTabIndex] = useState(0);
  const [iceriklerFormDefault, setIceriklerFormDefault] = useState([]);
  const [iceriklerForm, setIceriklerForm] = useState([]);
  const [iceriklerFormAdd, setIceriklerFormAdd] = useState([]);
  const [dilMiniList, setDilMiniList] = useState([]);
  const [urunDilleri, setUrunDilleri] = useState([]);

  const [fiyatlarSelectedTabIndex, setFiyatlarSelectedTabIndex] = useState(0);
  const [fiyatlarFormDefault, setFiyatlarFormDefault] = useState([]);
  const [fiyatlarForm, setFiyatlarForm] = useState([]);
  const [fiyatlarFormAdd, setFiyatlarFormAdd] = useState([]);
  const [fiyatTanimList, setFiyatTanimList] = useState([]);
  const [urunFiyatlar, setUrunFiyatlar] = useState([]);

  const [urunOzellikler, setUrunOzellikler] = useState([]);
  const [urunOzelliklerDefault, setUrunOzelliklerDefault] = useState([]);
  const [ozellikList, setOzellikList] = useState([]);
  const [ozellikler, setOzellikler] = useState([]);
  const [filtredOzellikler, setFiltredOzellikler] = useState([]);
  const [urunKategoriler, setUrunKategoriler] = useState([]);

  const [urunResimler, setUrunResimler] = useState([]);
  const [urunResimlerDefault, setUrunResimlerDefault] = useState([]);

  const [urunBarkodlarSelectedTabIndex, setUrunBarkodlarSelectedTabIndex] = useState(0);
  const [urunBarkodlar, setUrunBarkodlar] = useState([]);
  const [urunBarkodlarDefault, setUrunBarkodlarDefault] = useState([]);
  const [barkodAdd, setBarkodAdd] = useState(true);
  const [barkodBirimList, setBarkodBirimList] = useState([]);
  const [barkodBirimListDic, setBarkodBirimListDic] = useState({});

  const [tedarikciler, setTedarikciler] = useState([]);
  const [tadarikcilerDefault, setTedarikcilerDefault] = useState([]);

  const [ekOzellikler, setEkOzellikler] = useState([]);
  const [urunEkOzellikler, setUrunEkOzellikler] = useState([]);
  const [urunEkOzelliklerDefault, setUrunEkOzelliklerDefault] = useState([]);

  const [ozellikUrunBaz, setOzellikUrunBaz] = useState([]);
  const [urunMetinOzellikler, setUrunMetinOzellikler] = useState([]);
  const [urunMetinOzelliklerDefault, setUrunMetinOzelliklerDefault] = useState([]);
  const [metinOzellikler, setMetinOzellikler] = useState([]);

  const [urunYorum, setUrunYorum] = useState([]);
  const [urunYorumDefault, setUrunYorumDefault] = useState([]);
  const [urunYorumCount, setUrunYorumCount] = useState(0);

  const [kampanya, setKampanya] = useState([]);
  const [kampanyaDefault, setKampanyaDefault] = useState([]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteIdIcerik, setDeleteIdDetailIcerik] = useState(null);
  const [showDeleteDialogDetailIcerik, setShowDeleteDialogDetailIcerik] = useState(false);
  const [isDeletingDetailIcerik, setIsDeletingDetailIcerik] = useState(false);
  const [isDeletingErrorMessageDetailIcerik, setIsDeletingErrorMessageDetailIcerik] = useState(null);

  const [detailDeleteIdBarkod, setDeleteIdDetailBarkod] = useState(null);
  const [showDeleteDialogDetailBarkod, setShowDeleteDialogDetailBarkod] = useState(false);
  const [isDeletingDetailBarkod, setIsDeletingDetailBarkod] = useState(false);
  const [isDeletingErrorMessageDetailBarkod, setIsDeletingErrorMessageDetailBarkod] = useState(null);

  const [detailDeleteIdFoto, setDeleteIdDetailFoto] = useState(null);
  const [showDeleteDialogDetailFoto, setShowDeleteDialogDetailFoto] = useState(false);
  const [isDeletingDetailFoto, setIsDeletingDetailFoto] = useState(false);
  const [isDeletingErrorMessageDetailFoto, setIsDeletingErrorMessageDetailFoto] = useState(null);

  const [detailDeleteIdEkOzellik, setDeleteIdDetailEkOzellik] = useState(null);
  const [showDeleteDialogDetailEkOzellik, setShowDeleteDialogDetailEkOzellik] = useState(false);
  const [isDeletingDetailEkOzellik, setIsDeletingDetailEkOzellik] = useState(false);
  const [isDeletingErrorMessageDetailEkOzellik, setIsDeletingErrorMessageDetailEkOzellik] = useState(null);

  const [type, setType] = useState(props.eventType ?? 0);
  const [loadDone, setLoadDone] = useState(false);
  const [loadTab1, setLoadTab1] = useState(false);
  const [loadTab2, setLoadTab2] = useState(false);
  const [loadTab3, setLoadTab3] = useState(false);
  const [loadTab4, setLoadTab4] = useState(false);
  const [loadTab5, setLoadTab5] = useState(false);
  const [loadTab6, setLoadTab6] = useState(false);
  const [loadTab7, setLoadTab7] = useState(false);
  const [loadTab8, setLoadTab8] = useState(false);
  const [loadTab9, setLoadTab9] = useState(false);
  const [loadTab10, setLoadTab10] = useState(false);
  const [loadTab11, setLoadTab11] = useState(false);
  const [loadTab12, setLoadTab12] = useState(false);
  const [equal1, setEqual1] = useState(true);
  const [equal3, setEqual3] = useState(true);
  const [equal4, setEqual4] = useState(true);
  const [equal5, setEqual5] = useState(true);
  const [equal6, setEqual6] = useState(true);
  const [equal7, setEqual7] = useState(true);
  const [equal9, setEqual9] = useState(true);
  const [equal10, setEqual10] = useState(true);
  const [equal11, setEqual11] = useState(true);
  const [equal12, setEqual12] = useState(true);
  const [saveErrors, setSaveErrors] = useState([[], [], [], [], [], [], [], [], [], [], [], []]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);

  const PAGEYORUM = useMemo(() => urunYorum.length > 0 ? Math.ceil(urunYorum.length / 100) : 1, [urunYorum.length]);
  const PAGES_COUNT_YORUM = useMemo(() => Math.ceil(urunYorumCount / 100), [urunYorumCount]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const BIRIM_ID = useMemo(() => {
    if (genelForm?.birim) {
      return genelForm.birim;
    }
    return null;
  }, [genelForm]);

  const CARPAN_ID = useMemo(() => {
    if (genelForm?.satis_birim_grup) {
      return Number(genelForm.satis_birim_grup)
    }
    return null;
  }, [genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(kampanya, []) && _isEqual(iceriklerForm, iceriklerFormAdd) && _isEqual(fiyatlarForm, fiyatlarFormAdd) && _isEqual(urunOzellikler, []) && _isEqual(urunResimler, []) && _isEqual(urunBarkodlar, []) && _isEqual(urunEkOzellikler, []) && _isEqual(urunMetinOzellikler, [] && _isEqual(urunYorum, []));
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualKampanya = _isEqual(kampanya, kampanyaDefault);
    const isEquaIcerik = _isEqual(iceriklerForm, iceriklerFormDefault);
    const isEqualFiyat = _isEqual(fiyatlarForm, fiyatlarFormDefault);
    const isEqualOzellik = _isEqual(urunOzellikler, urunOzelliklerDefault);
    const isEqualFoto = _isEqual(urunResimler, urunResimlerDefault);
    const isEqualBarkod = _isEqual(urunBarkodlar, urunBarkodlarDefault);
    const isEqualEkOzellik = _isEqual(urunEkOzellikler, urunEkOzelliklerDefault);
    const isEqualMetinOzellik = _isEqual(urunMetinOzellikler, urunMetinOzelliklerDefault);
    const isEqualYorum = _isEqual(urunYorum, urunYorumDefault);

    return isEqualGenel && isEqualKampanya && isEquaIcerik && isEqualFiyat && isEqualOzellik && isEqualFoto && isEqualBarkod && isEqualEkOzellik && isEqualMetinOzellik && isEqualYorum;
  }, [add, genelForm, genelFormDefault, kampanya, kampanyaDefault, iceriklerForm, iceriklerFormDefault, iceriklerFormAdd, fiyatlarForm, fiyatlarFormDefault, fiyatlarFormAdd, urunOzellikler, urunOzelliklerDefault, urunResimler, urunResimlerDefault, urunBarkodlar, urunBarkodlarDefault, urunEkOzellikler, urunEkOzelliklerDefault, urunMetinOzellikler, urunMetinOzelliklerDefault, urunYorum, urunYorumDefault]);

  // #region LOAD
  const LOADINGICERIK = useMemo(() => {
    if (!loadingIcerik && !loadingIcerikSet) {
      return false;
    }
    return true;
  }, [loadingIcerik, loadingIcerikSet]);

  const LOADINGFIYAT = useMemo(() => {
    if (!loadingFiyat && !loadingFiyat2) {
      return false;
    }
    return true;
  }, [loadingFiyat, loadingFiyat2]);

  const LOADINGEKOZELLIK = useMemo(() => {
    if (!loadingEkOzellikler && !loadingEkOzelliklerDil) {
      return false;
    }
    return true;
  }, [loadingEkOzellikler, loadingEkOzelliklerDil]);

  const LOADINGMETINOZELLIK = useMemo(() => {
    if (!loadingMetinOzellikler && !loadingMetinOzelliklerDil) {
      return false;
    }
    return true;
  }, [loadingMetinOzellikler, loadingMetinOzelliklerDil]);

  useLayoutEffect(() => {
    setAdd(false);
    setLoadDone(false);
  }, [ID]);

  useLayoutEffect(() => {
    if (loadDone) {
      setType(2);
    } else {
      setType(props.eventType ?? 0);
    }
  }, [props.eventType, loadDone]);

  useLayoutEffect(() => {
    if (type === 0) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setMiktarlar([]);
      setMiktarlarDefault([]);
      setIceriklerForm([]);
      setIceriklerFormDefault([]);
      setFiyatlarForm([]);
      setFiyatlarFormDefault([]);
      setFiyatlarForm([]);
      setFiyatlarFormDefault([]);
      setUrunOzellikler([]);
      setUrunOzelliklerDefault([]);
      setUrunResimler([]);
      setUrunResimlerDefault([]);
      setUrunBarkodlar([]);
      setUrunBarkodlarDefault([]);
      setTedarikciler([]);
      setTedarikcilerDefault([]);
      setUrunEkOzellikler([]);
      setUrunEkOzelliklerDefault([]);
      setUrunMetinOzellikler([]);
      setUrunMetinOzelliklerDefault([]);
      setUrunYorum([]);
      setUrunYorumDefault([]);
      setUrunYorumCount(0);
      setKampanya([]);
      setKampanyaDefault([]);
      setTabs(TABS_DEFAULT);
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          setLoadTab12(true);
          break;
        case 1:
          setLoadTab1(true);
          setLoadTab2(true);
          break;
        case 2:
          setLoadTab1(true);
          setLoadTab3(true);
          break;
        case 3:
          setLoadTab1(true);
          setLoadTab4(true);
          break;
        case 4:
          setLoadTab1(true);
          setLoadTab5(true);
          setLoadTab10(true);
          break;
        case 5:
          setLoadTab1(true);
          setLoadTab6(true);
          break;
        case 6:
          setLoadTab1(true);
          setLoadTab7(true);
          break;
        case 7:
          setLoadTab1(true);
          setLoadTab8(true);
          break;
        case 8:
          setLoadTab1(true);
          setLoadTab9(true);
          break;
        case 9:
          setLoadTab1(true);
          setLoadTab11(true);
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else if (type === 1) {
      setGenelForm(null);
      setGenelFormDefault(null);
      if (!saveErrors[1].includes("err")) {
        setMiktarlar([]);
        setMiktarlarDefault([]);
      }
      if (!saveErrors[2].includes("err")) {
        setIceriklerForm([]);
        setIceriklerFormDefault([]);
      }
      if (!saveErrors[3].includes("err")) {
        setFiyatlarForm([]);
        setFiyatlarFormDefault([]);
      }
      if (!saveErrors[4].includes("err")) {
        setUrunOzellikler([]);
        setUrunOzelliklerDefault([]);
      }
      if (!saveErrors[5].includes("err")) {
        setUrunResimler([]);
        setUrunResimlerDefault([]);
      }
      if (!saveErrors[6].includes("err")) {
        setUrunBarkodlar([]);
        setUrunBarkodlarDefault([]);
      }
      if (!saveErrors[7].includes("err")) {
        setTedarikciler([]);
        setTedarikcilerDefault([]);
      }
      if (!saveErrors[8].includes("err")) {
        setUrunEkOzellikler([]);
        setUrunEkOzelliklerDefault([]);
      }
      if (!saveErrors[9].includes("err")) {
        setUrunMetinOzellikler([]);
        setUrunMetinOzelliklerDefault([]);
      }
      if (!saveErrors[10].includes("err")) {
        setUrunYorum([]);
        setUrunYorumDefault([]);
        setUrunYorumCount(0);
      }
      if (!saveErrors[11].includes("err")) {
        setKampanya([]);
        setKampanyaDefault([]);
      }
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          if (!saveErrors[11].includes("err")) {
            setLoadTab12(true);
          }
          break;
        case 1:
          setLoadTab1(true);
          if (!saveErrors[1].includes("err")) {
            setLoadTab2(true);
          }
          break;
        case 2:
          setLoadTab1(true);
          if (!saveErrors[2].includes("err")) {
            setLoadTab3(true);
          }
          break;
        case 3:
          setLoadTab1(true);
          if (!saveErrors[3].includes("err")) {
            setLoadTab4(true);
          }
          break;
        case 4:
          setLoadTab1(true);
          if (!saveErrors[4].includes("err")) {
            setLoadTab5(true);
          }
          if (!saveErrors[9].includes("err")) {
            setLoadTab10(true);
          }
          break;
        case 5:
          setLoadTab1(true);
          if (!saveErrors[5].includes("err")) {
            setLoadTab6(true);
          }
          break;
        case 6:
          setLoadTab1(true);
          if (!saveErrors[6].includes("err")) {
            setLoadTab7(true);
          }
          break;
        case 7:
          setLoadTab1(true);
          if (!saveErrors[7].includes("err")) {
            setLoadTab8(true);
          }
          break;
        case 8:
          setLoadTab1(true);
          if (!saveErrors[8].includes("err")) {
            setLoadTab9(true);
          }
          break;
        case 9:
          setLoadTab1(true);
          if (!saveErrors[10].includes("err")) {
            setLoadTab11(true);
          }
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else {
      if (!add) {
        switch (selectedTabIndex) {
          case 0:
            if (equal1 && equal12) {
              setLoadTab1(true);
              setLoadTab12(true);
            }
            break;
          case 1:
            setLoadTab2(true);
            break;
          case 2:
            if (equal3) {
              setLoadTab3(true);
            }
            break;
          case 3:
            if (equal4) {
              setLoadTab4(true);
            }
            break;
          case 4:
            if (equal5 && equal10) {
              setLoadTab5(true);
              setLoadTab10(true);
            }
            break;
          case 5:
            if (equal6) {
              setLoadTab6(true);
            }
            break;
          case 6:
            if (equal7) {
              setLoadTab7(true);
            }
            break;
          case 7:
            setLoadTab8(true);
            break;
          case 8:
            if (equal9) {
              setLoadTab9(true);
            }
            break;
          case 9:
            if (equal11) {
              setLoadTab11(true);
            }
            break;
          default:
            break;
        }
      } else {
        setLoading(false);
        setLoadingMiktar(false);
        setLoadingIcerik(false);
        setLoadingFiyat(false);
        setLoadingFiyat2(false);
        setLoadingOzellik(false);
        setLoadingFoto(false);
        setLoadingBarkod(false);
        setLoadingTedarikci(false);
        setLoadingEkOzellikler(false);
        setLoadingEkOzelliklerDil(false);
        setLoadingMetinOzellikler(false);
        setLoadingMetinOzelliklerDil(false);
        setLoadingYorum(false);
        setLoadingKampanya(false);
        setLoadTab1(false);
        setLoadTab2(false);
        setLoadTab3(false);
        setLoadTab4(false);
        setLoadTab5(false);
        setLoadTab6(false);
        setLoadTab7(false);
        setLoadTab8(false);
        setLoadTab9(false);
        setLoadTab10(false);
        setLoadTab11(false);
        setLoadTab12(false);
      }
    }
  }, [type, selectedTabIndex, saveErrors, equal1, equal3, equal4, equal5, equal6, equal7, equal9, equal10, equal11, equal12, add]);

  useLayoutEffect(() => {
    if (add) {
      setEqual1(_isEqual(genelForm, FORM_DEFAULT));
    } else {
      setEqual1(_isEqual(genelForm, genelFormDefault));
    }
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual3(_isEqual(iceriklerForm, iceriklerFormAdd));
    } else {
      setEqual3(_isEqual(iceriklerForm, !saveErrors[2].includes("err") ? iceriklerFormDefault : iceriklerFormAdd));
    }
  }, [add, iceriklerForm, iceriklerFormDefault, iceriklerFormAdd, saveErrors]);

  useLayoutEffect(() => {
    if (add) {
      setEqual4(_isEqual(fiyatlarForm, fiyatlarFormAdd));
    } else {
      setEqual4(_isEqual(fiyatlarForm, !saveErrors[3].includes("err") ? fiyatlarFormDefault : fiyatlarFormAdd));
    }
  }, [add, fiyatlarForm, fiyatlarFormDefault, fiyatlarFormAdd, saveErrors]);

  useLayoutEffect(() => {
    if (add) {
      setEqual5(_isEqual(urunOzellikler, []));
    } else {
      setEqual5(_isEqual(urunOzellikler, !saveErrors[4].includes("err") ? urunOzelliklerDefault : []));
    }
  }, [add, urunOzellikler, urunOzelliklerDefault, saveErrors]);

  useLayoutEffect(() => {
    if (add) {
      setEqual6(_isEqual(urunResimler, []));
    } else {
      setEqual6(_isEqual(urunResimler, urunResimlerDefault));
    }
  }, [add, urunResimler, urunResimlerDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual7(_isEqual(urunBarkodlar, []));
    } else {
      setEqual7(_isEqual(urunBarkodlar, urunBarkodlarDefault));
    }
  }, [add, urunBarkodlar, urunBarkodlarDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual9(_isEqual(urunEkOzellikler, []));
    } else {
      setEqual9(_isEqual(urunEkOzellikler, urunEkOzelliklerDefault));
    }
  }, [add, urunEkOzellikler, urunEkOzelliklerDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual10(_isEqual(urunMetinOzellikler, []));
    } else {
      setEqual10(_isEqual(urunMetinOzellikler, urunMetinOzelliklerDefault));
    }
  }, [add, urunMetinOzellikler, urunMetinOzelliklerDefault]);

  useLayoutEffect(() => {
    if (!loadingYorumMore) {
      if (add) {
        setEqual11(_isEqual(urunYorum, []));
      } else {
        setEqual11(_isEqual(urunYorum, urunYorumDefault));
      }
    }
  }, [add, urunYorum, urunYorumDefault, loadingYorumMore]);

  useLayoutEffect(() => {
    if (add) {
      setEqual12(_isEqual(kampanya, []));
    } else {
      setEqual12(_isEqual(kampanya, kampanyaDefault));
    }
  }, [add, kampanya, kampanyaDefault]);

  // #endregion

  // #region GENEL
  useLayoutEffect(() => {
    if (loadTab1) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              kodu: data.kodu,
              tip: String(data.tip),
              barkod: data.barkod,
              tedarik_suresi: String(data.tedarik_suresi ?? ''),
              katalogta_gizle: data.katalogta_gizle,
              desi: data.desi,
              birim: data.birim ? String(data.birim.id) : '-1',
              satis_birim_grup: data.satis_birim_grup ? String(data.satis_birim_grup.id) : '-1',
              uretici: data.uretici ? String(data.uretici.id) : '-1',
              vergi: data.vergi ? { id: data.vergi.id, tanim: data.vergi.tanim, baslik_id: data.vergi.vergi_tip.id, baslik_tanim: data.vergi.vergi_tip.tanim } : null,
              vergi_baslik: data.vergi ? { id: data.vergi.vergi_tip.id, tanim: data.vergi.vergi_tip.tanim } : null,
              vergi_tree_list: data.vergi ? [{ title: data.vergi.vergi_tip.tanim, list: [{ key: data.vergi.id, value: data.vergi.tanim }] }] : [],
              kategoriler: data.kategoriler.map(c => ({ id: c.id, parents: c.parents })),
              magazalar: data.magazalar,
              gruplar: data.gruplar,
              webde_goster: data.webde_goster,
              aktif: data.aktif,
              varyant_var: data.varyant_grup ? true : false,
              miktar_kontrol_yapma: data.miktar_kontrol_yapma
            };
            const varyantForm = {
              varyant_grup: data.varyant_grup,
              varyants: data.varyants.varyant_list.map((x) => ({ baslik: { id: x.baslik_id, tanim: x.baslik }, degerler: x.degerler.map((y) => ({ id: y.ozellik_deger_id, tanim: y.ozellik_deger, disabled: true })) }))
            }
            setVaryant(varyantForm);
            setVaryantDefault(varyantForm);
            setGenelFormDefault(form);
            setGenelForm(form);
            setUrunKategoriler(data.kategoriler);
            setBirim(data.birim);
            setHataGenel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setLoadTab1(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DEFAULT);
        setGenelFormDefault(FORM_DEFAULT);
        setVaryant({ varyant_grup: null, varyants: [] });
        setVaryantDefault({ varyant_grup: null, varyants: [] });
        setBirim(null);
        setUrunKategoriler([]);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
        setLoadTab1(false);
      }
    }
  }, [ID, IS_ADD, loadTab1]);

  useLayoutEffect(() => {
    if (loadTab12) {
      if (!IS_ADD) {
        setLoadingKampanya(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/kampanya`).then(({ data }) => {
            const forms = data.map((kampanya) => (
              {
                form: {
                  id: kampanya.id,
                  kampanya: kampanya.kampanya,
                  urun_adedi: String(kampanya.urun_adedi === 0 ? "" : kampanya.urun_adedi),
                  kampanya_adetli_calissin: kampanya.kampanya_adetli_calissin,
                },
                errors: null
              }
            ));
            setKampanya(forms);
            setKampanyaDefault(forms);
            setHataKampanya(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataKampanya(true);
              }
            }
          }).finally(() => {
            setLoadingKampanya(false);
            setLoadTab12(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setKampanya([]);
        setKampanyaDefault([]);
        setHataKampanya(null);
        setLoadingKampanya(false);
        setLoadTab12(false);
      }
    }
  }, [ID, IS_ADD, loadTab12]);

  useEffect(() => {
    if (add) {
      django('urun/urun_kod').then(({ data }) => {
        setGenelForm((prev) => {
          return immer(prev, (next) => {
            next["kodu"] = data?.value;
          })
        });
      })
    }
  }, [add]);

  useLayoutEffect(() => {
    django('urun/tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setUrunTipleriDic(dict);
      setUrunTipleri(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django('birim/mini_list').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      setbirimListDic(dict);
      setbirimList(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (BIRIM_ID) {
      setsatisBirimGrup([]);
      setBarkodBirimList([]);
      setsatisBirimGrupDic(null);
      if (BIRIM_ID && BIRIM_ID > 0) {
        django(`satisbirimgrup/mini_list?birim.id=${BIRIM_ID}`).then(({ data }) => {
          const dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
          setsatisBirimGrupDic(dict);
          setsatisBirimGrup(data);
        });
      } else {
        setsatisBirimGrup([]);
        setBarkodBirimList([]);
        setsatisBirimGrupDic(null);
      }
    }
  }, [BIRIM_ID]);

  useLayoutEffect(() => {
    if (CARPAN_ID) {
      if (CARPAN_ID > 0) {
        django(`satisbirimgrup/${CARPAN_ID}/satisbirimgrupcarpan`).then(({ data }) => {
          setcarpanList(data);
        });
      } else {
        setcarpanList(null);
      }
    }
  }, [CARPAN_ID]);

  useLayoutEffect(() => {
    if (birim) {
      let list = [];
      const copybirim = { ...birim };
      const copylist = carpanList ? carpanList.map((x) => ({ id: x.birim.id, tanim: x.birim.tanim })) : [];
      list = [...list, copybirim];
      list = [...list, ...copylist];
      const dict = Object.assign({}, ...list.map((x) => ({ [x.id]: x.tanim })));
      setBarkodBirimListDic(dict);
      setBarkodBirimList(list);

    }
  }, [birim, carpanList]);

  useLayoutEffect(() => {
    django('kullanici/uretici_mini_list').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      setureticiListDic(dict);
      setureticiList(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django('parabirim/mini_list').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.id]: x.tanim })));
      setParabirimListDic(dict);
      setParabirimList(data);
    });
  }, [lastUpdateTime]);

  // #endregion
  // #region MIKTAR
  useLayoutEffect(() => {
    if (loadTab2) {
      setLoadingMiktar(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/miktarlar`).then(({ data }) => {
            setMiktarlar(data);
            setMiktarlarDefault(data);
            setHataMiktar(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataMiktar(true);
              }
            }
          }).finally(() => {
            setLoadingMiktar(false);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setMiktarlar([]);
        setMiktarlarDefault([]);
        setHataMiktar(null);
        setLoadingMiktar(false);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, loadTab2]);
  // #endregion
  // #region ICERIK
  useLayoutEffect(() => {
    if (loadTab3) {
      if (!IS_ADD) {
        setLoadingIcerik(true);
        const debounce = setTimeout(() => {
          django(`urun/${ID}/urundil`).then(({ data }) => {
            setUrunDilleri(data);
            setHataIcerik(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataIcerik(true);
              }
            }
          }).finally(() => {
            setLoadingIcerik(false);
            setLoadTab3(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setUrunDilleri([]);
        setHataIcerik(null);
        setLoadingIcerik(false);
        setLoadTab3(false);
      }
    }
  }, [ID, IS_ADD, loadTab3]);

  useLayoutEffect(() => {
    django('dil/mini_list?aktif=true').then(({ data }) => setDilMiniList(data)).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataIcerik(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setLoadingIcerikSet(true);
    createIceriklerStates(dilMiniList, urunDilleri).then((states) => {
      setIceriklerFormDefault(states);
      setIceriklerForm(states);
    }).finally(() => {
      setLoadingIcerikSet(false);
    })
  }, [dilMiniList, urunDilleri]);

  useLayoutEffect(() => {
    createIceriklerStates(dilMiniList, []).then((states) => {
      setIceriklerFormAdd(states);
    })
  }, [dilMiniList]);
  // #endregion
  // #region FIYATLAR
  useLayoutEffect(() => {
    if (loadTab4) {
      if (!IS_ADD) {
        setLoadingFiyat(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/fiyatlar`).then(({ data }) => {
            setUrunFiyatlar(data);
            setHataFiyat(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataFiyat(true);
              }
            }
          }).finally(() => {
            setLoadingFiyat(false);
            setLoadTab4(false);
          })
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setUrunFiyatlar([]);
        setHataFiyat(null);
        setLoadingFiyat(false);
        setLoadTab4(false);
      }
    }
  }, [ID, IS_ADD, loadTab4]);

  useLayoutEffect(() => {
    django('fiyattanim/mini_list').then(({ data }) => setFiyatTanimList(data.sort((a, b) => a.id - b.id).map((liste) => ({ listeId: liste.id, listeTanim: liste.tanim })))).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataFiyat(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setLoadingFiyat2(true);
    createFiyatStates(fiyatTanimList, urunFiyatlar).then((states) => {
      setFiyatlarFormDefault(states);
      setFiyatlarForm(states);
      setHataFiyat(null);
    }).catch((error) => {
      setHataFiyat(true);
    }).finally(() => {
      setLoadingFiyat2(false);
    })
  }, [fiyatTanimList, urunFiyatlar]);

  useLayoutEffect(() => {
    createFiyatStates(fiyatTanimList, []).then((states) => {
      setFiyatlarFormAdd(states);
    })
  }, [fiyatTanimList]);
  // #endregion
  // #region OZELLİK
  useLayoutEffect(() => {
    if (loadTab5) {
      if (!IS_ADD) {
        setLoadingOzellik(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/ozellikler`).then(({ data }) => {
            const mapData = data?.sort((a, b) => a.id - b.id).map(x => ({
              id: x.id,
              ozellik_baslik: x.ozellik_baslik,
              ozellik_deger: x.ozellik_deger,
            }));
            setUrunOzellikler(mapData);
            setUrunOzelliklerDefault(mapData);
            setHataOzellik(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataOzellik(true);
              }
            }
          }).finally(() => {
            setLoadingOzellik(false);
            setLoadTab5(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setUrunOzellikler([]);
        setUrunOzelliklerDefault([]);
        setHataOzellik(null);
        setLoadingOzellik(false);
        setLoadTab5(false);
      }
    }
  }, [ID, IS_ADD, loadTab5]);

  useLayoutEffect(() => {
    django('ozellikbaslik').then(({ data }) => {
      setOzellikler(data.filter(x => x.veri_tip.key !== 3));
      setOzellikUrunBaz(data.filter(x => x.veri_tip.key === 3));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataOzellik(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (ozellikler.length > 0) {
      if (urunKategoriler.length > 0) {
        const filteredList = ozellikler.filter((item) => (item.kategoriler.length > 0 ? item.kategoriler.some(k => urunKategoriler.some(uk => uk.parents.includes(k.parents))) : item));
        setFiltredOzellikler(filteredList);
      } else {
        setFiltredOzellikler(ozellikler);
      }
    }
  }, [urunKategoriler, ozellikler]);

  useLayoutEffect(() => {
    crateOzellikList(filtredOzellikler, urunOzellikler).then((states) => {
      setOzellikList(states);
    })
  }, [filtredOzellikler, urunOzellikler]);
  // #endregion
  // #region FOTO
  useLayoutEffect(() => {
    if (loadTab6) {
      if (!IS_ADD) {
        setLoadingFoto(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/fotolar`).then(({ data }) => {
            const formData = data.length > 0 ? data : [];
            const forms = formData.map((foto) => (
              {
                form: {
                  id: foto.id,
                  title: foto.title,
                  harici_link: foto.harici_link,
                  siralama: String(foto.siralama),
                  foto: foto.foto,
                },
                errors: null
              }
            ));
            const orderedForm = forms.sort((a, b) => a.form.siralama - b.form.siralama);
            setUrunResimler(orderedForm);
            setUrunResimlerDefault(orderedForm);
            setHataFoto(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataFoto(true);
              }
            }
          }).finally(() => {
            setLoadingFoto(false);
            setLoadTab6(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setUrunResimler([]);
        setUrunResimlerDefault([]);
        setHataFoto(null);
        setLoadingFoto(false);
        setLoadTab6(false);
      }
    }
  }, [ID, IS_ADD, loadTab6]);
  // #endregion
  // #region BARKOD
  useLayoutEffect(() => {
    if (loadTab7) {
      if (!IS_ADD) {
        setLoadingBarkod(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/barkodlar`).then(({ data }) => {
            const forms = data.map((barkod) => (
              {
                form: {
                  id: barkod.id,
                  barkod: barkod.barkod,
                  birim: String(barkod.birim),
                },
                errors: null
              }
            ));
            setUrunBarkodlar(forms);
            setUrunBarkodlarDefault(forms);
            setHataBarkod(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataBarkod(true);
              }
            }
          }).finally(() => {
            setLoadingBarkod(false);
            setLoadTab7(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setUrunBarkodlar([]);
        setUrunBarkodlarDefault([]);
        setHataBarkod(null);
        setLoadingBarkod(false);
        setLoadTab7(false);
      }
    }
  }, [ID, IS_ADD, loadTab7]);
  // #endregion
  // #region TEDARİKÇİ
  useLayoutEffect(() => {
    if (loadTab8) {
      setLoadingTedarikci(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/tedarikciler`).then(({ data }) => {
            setTedarikciler(data);
            setTedarikcilerDefault(data);
            setHataTedarikci(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataTedarikci(true);
              }
            }
          }).finally(() => {
            setLoadingTedarikci(false);
            setLoadTab8(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setTedarikciler([]);
        setTedarikcilerDefault([]);
        setHataTedarikci(null);
        setLoadingTedarikci(false);
        setLoadTab8(false);
      }
    }
  }, [ID, IS_ADD, loadTab8]);
  // #endregion
  // #region EKOZELLIK
  useLayoutEffect(() => {
    if (loadTab9) {
      setLoadingEkOzellikler(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/urunekozellik`).then(({ data }) => {
            const forms = data.map((ozellik) => (
              {
                id: ozellik.id,
                diller: ozellik.diller,
              }
            ))
            setEkOzellikler(forms);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataEkOzellik(true);
              }
            }
          }).finally(() => {
            setLoadingEkOzellikler(false);
            setLoadTab9(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setEkOzellikler([]);
        setHataEkOzellik(null);
        setLoadingEkOzellikler(false);
        setLoadTab9(false);
      }
    }
  }, [ID, IS_ADD, loadTab9]);

  useLayoutEffect(() => {
    setLoadingEkOzelliklerDil(true);
    const setDil = async () => {
      let ekoz = [];
      ekOzellikler.forEach((oz) => {
        createOzelliklerStates(dilMiniList, oz?.diller ?? []).then((states) => ekoz.push({
          form: { id: oz.id, diller: states },
          errors: null
        }));
      })
      return ekoz;
    }
    setDil().then((state) => {
      setUrunEkOzellikler(state);
      setUrunEkOzelliklerDefault(state);
    }).finally(() => setLoadingEkOzelliklerDil(false));

  }, [dilMiniList, ekOzellikler]);

  // #endregion
  // #region METINOZELLIK
  useLayoutEffect(() => {
    if (loadTab10) {
      setLoadingMetinOzellikler(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/urunmetinozellik`).then(({ data }) => {
            const forms = data.map((ozellik) => (
              {
                id: ozellik.id,
                diller: ozellik.diller,
                ozellik_baslik: ozellik.ozellik_baslik
              }
            ))
            setMetinOzellikler(forms);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataMetinOzellik(true);
              }
            }
          }).finally(() => {
            setLoadingMetinOzellikler(false);
            setLoadTab10(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setMetinOzellikler([]);
        setHataMetinOzellik(null);
        setLoadingMetinOzellikler(false);
        setLoadTab10(false);
      }
    }
  }, [ID, IS_ADD, loadTab10]);

  useLayoutEffect(() => {
    if (ozellikUrunBaz.length > 0) {
      setLoadingMetinOzelliklerDil(true);
      const setDil = async () => {
        let MetinOzellikler = [];
        ozellikUrunBaz.forEach((ozbas) => {
          const metinOz = metinOzellikler.find(x => x.ozellik_baslik === ozbas.id);
          if (metinOz) {
            createOzelliklerStates(dilMiniList, metinOz?.diller ?? []).then((states) => MetinOzellikler.push({ id: metinOz.id, title: ozbas.tanim, ozellik_baslik: ozbas.id, diller: states }));
          } else {
            createOzelliklerStates(dilMiniList, []).then((states) => MetinOzellikler.push({ title: ozbas.tanim, ozellik_baslik: ozbas.id, diller: states }));
          }
        })
        return MetinOzellikler;
      }
      setDil().then((state) => {
        setUrunMetinOzellikler(state);
        setUrunMetinOzelliklerDefault(state);
      }).finally(() => setLoadingMetinOzelliklerDil(false));
    }
  }, [dilMiniList, metinOzellikler, ozellikUrunBaz]);

  // #endregion
  // #region YORUM
  useLayoutEffect(() => {
    if (loadTab11) {
      if (!IS_ADD) {
        django(`${PATH}/${ID}/urunyorum/count`).then(({ data }) => {
          setUrunYorumCount(data);
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              setHataYorum(true);
            }
          }
        })
      } else {
        setUrunYorumCount(0);
        setHataYorum(null);
      }
    }
  }, [ID, IS_ADD, loadTab11]);

  useLayoutEffect(() => {
    if (loadTab11) {
      if (!IS_ADD) {
        setLoadingYorum(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/urunyorum/`, { params: { size: YORUM_PER_PAGE } }).then(({ data }) => {
            const forms = data.map((detay) => (
              {
                form: {
                  id: detay.id,
                  yorum_zamani: moment(detay.yorum_zamani).format('YYYY-MM-DD[T]HH:mm'),
                  ismimi_yayinla: detay.ismimi_yayinla,
                  yayin_onayi: detay.yayin_onayi,
                  puan: detay.puan,
                  yorum: detay.yorum,
                  onaylanmama_nedeni: detay.onaylanmama_nedeni,
                  kullanici: detay.kullanici,
                },
                errors: null
              }
            ));
            setUrunYorum(forms);
            setUrunYorumDefault(forms);
            setHataYorum(null)
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataYorum(true);
              }
            }
          }).finally(() => {
            setLoadingYorum(false);
            setLoadTab11(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setUrunYorum([]);
        setUrunYorumDefault([]);
        setHataYorum(null);
        setLoadingYorum(false);
        setLoadTab11(false);
      }
    }
  }, [ID, IS_ADD, loadTab11]);

  const handleGetMoreList = (page, mainContainerRef) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setLoadingYorumMore(true);
    const url = `${PATH}/${ID}/urunyorum`
    const config = {
      params: {
        size: YORUM_PER_PAGE,
        page
      },
    };
    django(url, config).then(({ data }) => {
      const datas = data.map((detay) => ({
        form: {
          id: detay.id,
          yorum_zamani: moment(detay.yorum_zamani).format('YYYY-MM-DD[T]HH:mm'),
          ismimi_yayinla: detay.ismimi_yayinla,
          yayin_onayi: detay.yayin_onayi,
          puan: detay.puan,
          yorum: detay.yorum,
          onaylanmama_nedeni: detay.onaylanmama_nedeni,
          kullanici: detay.kullanici,
        },
        errors: null
      }));
      setUrunYorum((prev) => [...prev, ...datas]);
      setUrunYorumDefault((prev) => [...prev, ...datas]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setLoadingYorumMore(false);
    });
  };

  // #endregion
  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  // #region change
  const handleTabGenelChangeForm = (property, value) => {
    if (property === 'birim') {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next[property] = value;
          next["satis_birim_grup"] = '-1';
        })
      });
      const birim = birimList.find(x => x.id === Number(value));
      setBirim(birim);
      if (!add) {
        if (urunBarkodlar.length > 0) {
          urunBarkodlar.forEach((_, index) => {
            setUrunBarkodlar((prev) => {
              return immer(prev, (next) => {
                next[index].form['birim'] = '-1';
              })
            })
          });
          enqueueSnackbar('Barkodlarınızı tekrar düzenleyin!', { variant: 'error' });
        }
      }
    } else if (property === 'magazalar') {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next[property] = value;
        })
      });
    } else if (property === 'satis_birim_grup') {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next[property] = value;
        })
      });
      if (!add) {
        if (urunBarkodlar.length > 0) {
          urunBarkodlar.forEach((_, index) => {
            setUrunBarkodlar((prev) => {
              return immer(prev, (next) => {
                next[index].form['birim'] = '-1';
              })
            })
          });
          enqueueSnackbar('Barkodlarınızı tekrar düzenleyin!', { variant: 'error' });
        }
      }
    } else if (property === 'vergi') {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next[property] = value;
          next["vergi_baslik"] = value ? { id: value.baslik_id, tanim: value.baslik_tanim } : null;
          next["vergi_tree_list"] = value ? [{ title: value.baslik_tanim, list: [{ key: value.id, value: value.tanim }] }] : [];
        })
      });
    } else {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next[property] = value;
        })
      });
    }
  };

  const handleChangeVaryants = (property, value) => {
    setVaryant((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      })
    })
  }

  const handleReloadVaryants = (liste, varyant) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next["varyant_var"] = true;
      })
    });
    setGenelFormDefault((prev) => {
      return immer(prev, (next) => {
        next["varyant_var"] = true;
      })
    });

    setVaryant((prev) => {
      return immer(prev, (next) => {
        next["varyants"] = liste;
      })
    })
    setVaryantDefault((prev) => {
      return immer(prev, (next) => {
        next["varyants"] = liste;
        next["varyant_grup"] = varyant;
      })
    })
    props.onUpdated();
  }

  const handleTabIceriklerChangeForm = (index, property, property2, value) => {
    if (property2 === 'tanim') {
      const slug = SlugMaker(value);
      setIceriklerForm((prev) => {
        return immer(prev, (next) => {
          next[index].form[property][property2] = value;
          next[index].form['slug'] = slug;
        })
      });
    } else if (property === 'arama_kelimeleri') {
      setIceriklerForm((prev) => {
        return immer(prev, (next) => {
          next[index].form[property] = value;
        })
      });
    } else {
      setIceriklerForm((prev) => {
        return immer(prev, (next) => {
          next[index].form[property][property2] = value;
        })
      });
    }
  };

  const handleTabFiyatlarChangeForm = (index, index2, property, value) => {
    setFiyatlarForm((prev) => {
      return immer(prev, (next) => {
        next[index].form[index2].form[property] = value;
      })
    });
  }

  const handleTabOzelliklerChangeForm = (list, ozellik_baslik) => {
    const setList = () => {
      const unchangelist = urunOzellikler.filter(x => x.ozellik_baslik.id !== ozellik_baslik.id);
      let newList = [...unchangelist];
      list.forEach(ol => {
        const e = urunOzelliklerDefault.find(x => x.ozellik_deger.id === ol.id);
        if (e) {
          newList.push(e);
        } else {
          newList.push({ ozellik_baslik: ozellik_baslik, ozellik_deger: { id: ol.id, tanim: ol.tanim } })
        }
      });
      setUrunOzellikler(newList.sort((a, b) => a.id - b.id));
    }
    setList();
  }

  const handleTabResimlerChangeForm = (index, property, value) => {
    if (index !== 'null') {
      setUrunResimler((prev) => {
        return immer(prev, (next) => {
          next[index].form[property] = value;
        });
      });
    } else {
      setUrunResimler(value);
    }
  }

  const handleBarkodChangeForm = (index, property, value) => {
    setUrunBarkodlar((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      })
    })
  }

  const handleBarkodAddNewTab = () => {
    setBarkodAdd(false);
    setUrunBarkodlar((prev) => immer(prev, (next) => {
      next.push({ ...BARKOD_FORM_DATA_DEFAULT });
    }));
  }

  const handleChangeFormEkOzellik = (index, index2, value) => {
    setUrunEkOzellikler((prev) => {
      return immer(prev, (next) => {
        next[index].form.diller[index2].form["tanim"] = value;
      });
    });
  }

  const handleEkOzellikAddNewRow = () => {
    createOzelliklerStates(dilMiniList, []).then((states) => {
      setUrunEkOzellikler((prev) => immer(prev, (next) => {
        next.push({ form: { diller: states }, errors: null });
      }));
    })
  }

  const handleMetinOzellikChangeForm = (index, index2, value) => {
    setUrunMetinOzellikler((prev) => {
      return immer(prev, (next) => {
        next[index].diller[index2].form["tanim"] = value;
      });
    });
  }

  const handleYorumChangeForm = (index, property, value) => {
    setUrunYorum((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      })
    })
  }

  const handleKampanyaChangeForm = (index, property, value) => {
    setKampanya((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
        if (property === "kampanya_adetli_calissin" && value === false) {
          next[index].errors = null;
        }
      })
    })
  }

  // #endregion

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DEFAULT);
    setVaryant({ varyant_grup: null, varyants: [] })
    setGenelError(null);
    setMiktarlar([]);
    setIceriklerForm(iceriklerFormAdd);
    setFiyatlarForm(fiyatlarFormAdd);
    setUrunKategoriler([]);
    setUrunOzellikler([]);
    setUrunResimler([]);
    setUrunBarkodlar([]);
    setTedarikciler([]);
    setUrunEkOzellikler([]);
    setUrunMetinOzellikler([]);
    setUrunYorum([]);
    setKampanya([]);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
        next[3].hasError = false;
        next[4].hasError = false;
        next[5].hasError = false;
        next[6].hasError = false;
        next[7].hasError = false;
        next[8].hasError = false;
        next[9].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
    setLoadTab1(true);
    setLoadTab2(true);
    setLoadTab3(true);
    setLoadTab4(true);
    setLoadTab5(true);
    setLoadTab6(true);
    setLoadTab7(true);
    setLoadTab8(true);
    setLoadTab9(true);
    setLoadTab10(true);
    setLoadTab11(true);
    setLoadTab12(true);
    setBarkodAdd(true);
    setTabs(TABS_DEFAULT);
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setVaryant(varyantDefault);
    setGenelError(null);
    setMiktarlar(miktarlarDefault);
    setIceriklerForm(iceriklerFormDefault);
    setFiyatlarForm(fiyatlarFormDefault);
    setUrunOzellikler(urunOzelliklerDefault);
    setUrunResimler(urunResimlerDefault);
    setUrunBarkodlar(urunBarkodlarDefault);
    setTedarikciler(tadarikcilerDefault);
    setUrunEkOzellikler(urunEkOzelliklerDefault);
    setUrunMetinOzellikler(urunMetinOzelliklerDefault);
    setUrunYorum(urunYorumDefault);
    setKampanya(kampanyaDefault);
    setBarkodAdd(true);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
        next[3].hasError = false;
        next[4].hasError = false;
        next[5].hasError = false;
        next[6].hasError = false;
        next[7].hasError = false;
        next[8].hasError = false;
        next[9].hasError = false;
      });
    });
  };

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Ürün Tanım', pagename: 'ürün', prop2: '' },
      { prop: 'kodu', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Ürün Kodu', pagename: 'ürün', prop2: '' },
      { prop: 'birim', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ]
    let errors = [];
    await checkAllList('urun', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {
    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (add ? _isEqual(genelForm, FORM_DEFAULT) : _isEqual(genelForm, genelFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            const data = { ...genelForm, barkod: genelForm.barkod !== '' ? genelForm.barkod : null, tip: Number(genelForm.tip), tedarik_suresi: genelForm.tedarik_suresi ? Number(genelForm.tedarik_suresi) : 0, birim: genelForm.birim !== '-1' ? Number(genelForm.birim) : null, desi: genelForm.desi !== '' ? genelForm.desi : null, satis_birim_grup: genelForm.satis_birim_grup !== '-1' ? Number(genelForm.satis_birim_grup) : null, uretici: genelForm.uretici !== '-1' ? Number(genelForm.uretici) : null, vergi: genelForm.vergi?.id ?? null, kategoriler: genelForm.kategoriler?.map((data) => data.id), magazalar: genelForm.magazalar?.map((data) => data.id), gruplar: genelForm.gruplar?.map((data) => data.id) }
            reqopt = {
              data: data,
              method: add ? 'POST' : 'PUT',
              id: null,
              successMessage: `Ürün ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Ürün ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createDilRequestOption = async (diller, dillerDefault, index) => {
      let reqopt = null;
      let err = null;
      const isEqual = _isEqual(diller, dillerDefault);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'slug', prop2: '' },
          { prop: 'dil_icerik', prop2: 'baslik' },
          { prop: 'dil_icerik', prop2: 'tanim' },
        ];
        await checkAllTabs(diller.form, (err) => handleErrorsIcerik(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const isHas = _has(diller.form, 'id');
            if (isHas) {
              reqopt = {
                data: diller.form,
                method: 'PUT',
                id: diller.form.id,
                successMessage: `${diller.dil.tanim} içerik güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${diller.dil.tanim} içerik güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: diller.form,
                method: 'POST',
                id: null,
                successMessage: `${diller.dil.tanim} içerik ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${diller.dil.tanim} içerik eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err];
    };

    const createFiyatRequestOption = async (fiyatState, fiyatStateDefault, index, index2, liste) => {
      let reqopt = null;
      let err = null;
      const isEqual = _isEqual(fiyatState, fiyatStateDefault);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'liste_fiyat', prop2: '' },
          { prop: 'para_birimi', prop2: '' },
        ];
        await checkAllTabs(fiyatState.form, (err) => handleErrorsFiyat(err, index, index2), list).then((res) => {
          if (_isEmpty(res)) {
            const isHas = _has(fiyatState.form, 'id');
            const data = { ...fiyatState.form, deger: fiyatState.form.deger !== "" ? fiyatState.form.deger : null, para_birimi: fiyatState.form.para_birimi !== '-1' ? Number(fiyatState.form.para_birimi) : null };
            if (isHas) {
              reqopt = {
                data: data,
                method: 'PUT',
                id: fiyatState.form.id,
                successMessage: `${liste} ${fiyatState.form.fiyattanim} fiyat güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${liste} ${fiyatState.form.fiyattanim} fiyat güncellenirken beklenmeyen bir hata oluştu`,
                index: index,
                index2: index2
              }
            } else {
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `${liste} ${fiyatState.form.fiyattanim} fiyat ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${liste} ${fiyatState.form.fiyattanim} fiyat eklenirken beklenmeyen bir hata oluştu`,
                index: index,
                index2: index2
              }
            }
            err = null;
          } else {
            const isHas = _has(fiyatState.form, 'id');
            const isEmpty = _isEmpty(fiyatState.form.deger) && _isEmpty(fiyatState.form.liste_fiyat);
            if (isHas && isEmpty) {
              reqopt = {
                data: null,
                method: 'DELETE',
                id: fiyatState.form.id,
                successMessage: `${liste} ${fiyatState.form.fiyattanim} fiyat silme başarıyla tamamlandı`,
                errorMessageUnexpected: `${liste} ${fiyatState.form.fiyattanim} fiyat silinirken beklenmeyen bir hata oluştu`,
                index: index,
                index2: index2
              }
              err = null;
            } else {
              reqopt = null;
              err = res;
            }
          }
        })
      }
      return [reqopt, err];
    };

    const createOzelliklerRequestOptions = async (ozellikState, ozellikDefaultState) => {
      let reqlist = [];
      let err = [];
      const isEqual = _isEqual(ozellikState, ozellikDefaultState);
      if (isEqual) {
        reqlist = [null];
        err = [null];
      } else {
        let deletestate = ozellikDefaultState.filter(o1 => ozellikState.some(o2 => o1.id === o2.id) === false);
        let poststate = ozellikState.filter(o => !o.id);
        if (deletestate) {
          deletestate.forEach(d => {
            let config = {
              data: null,
              method: 'DELETE',
              id: d.id,
              successMessage: `${d.ozellik_deger.tanim} özellik silme başarıyla tamamlandı`,
              errorMessageUnexpected: `${d.ozellik_deger.tanim} özellik silinirken beklenmeyen bir hata oluştu`,
            };
            reqlist.push(config);
            err.push(null);
          })
        }
        if (poststate) {
          poststate.forEach(d => {
            let send = { ozellik_deger: d.ozellik_deger.id, ozellik_baslik: d.ozellik_baslik.id }
            let config = {
              data: send,
              method: 'POST',
              id: null,
              successMessage: `${d.ozellik_deger.tanim} özellik ekleme başarıyla tamamlandı`,
              errorMessageUnexpected: `${d.ozellik_deger.tanim} özellik eklenirken beklenmeyen bir hata oluştu`,
            };
            reqlist.push(config);
            err.push(null);
          })
        }
      }
      return [reqlist, err];
    }

    const createFotoRequest = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = urunResimlerDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'title', prop2: '' },
          { prop: 'siralama', prop2: '' },
        ];
        await checkAllTabs(next.form, (err) => handleErrorsFoto(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const form = { ...next.form, siralama: Number(next.form.siralama), foto: next.form.foto.id };
            if (isHas) {
              reqopt = {
                data: form,
                method: 'PUT',
                id: form.id,
                successMessage: `${form.title} resim ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${form.title} resim eklenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: form,
                method: 'POST',
                id: null,
                successMessage: `${form.title} resim ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${form.title} resim eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err];
    }

    const createRequestOptionsTabBarkod = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = urunBarkodlarDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'barkod', prop2: '' },
          { prop: 'birim', prop2: '' },
        ];
        await checkAllTabs(next.form, (err) => handleErrorsBarkod(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const form = { ...next.form, birim: next.form.birim === '-1' ? null : Number(next.form.birim) };
            if (isHas) {
              reqopt = {
                data: form,
                method: 'PUT',
                id: form.id,
                successMessage: `${form.barkod} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: form,
                method: 'POST',
                id: null,
                successMessage: `${form.barkod} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err];
    }

    const createRequestOptionsTabEkOzellik = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = urunEkOzelliklerDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      const diller = next.form.diller.filter((dil) => (!_isEmpty(dil.form.tanim))).map((d) => d.form);
      const isEmpty = _isEmpty(diller);
      const data = { ...next.form, diller: diller }
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        if (isHas && !isEmpty) {
          reqopt = {
            data: data,
            method: 'PUT',
            id: data.id,
            successMessage: `Ek özellik güncelleme başarıyla tamamlandı`,
            errorMessageUnexpected: `Ek özellik güncellenirken beklenmeyen bir hata oluştu`,
          }
        }
        if (isHas && isEmpty) {
          reqopt = null;
        }
        if (!isHas && !isEmpty) {
          reqopt = {
            data: data,
            method: 'POST',
            id: null,
            successMessage: `Ek özellik ekleme başarıyla tamamlandı`,
            errorMessageUnexpected: `Ek özellik eklenirken beklenmeyen bir hata oluştu`,
          }
        }
        if (!isHas && isEmpty) {
          reqopt = null;
        }
        err = null;
      }
      return [reqopt, err];
    }

    const createRequestOptionsTabMetinOzellik = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next, 'id');
      const prev = urunMetinOzelliklerDefault.find(x => x.id === next.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      const diller = next.diller.filter((dil) => (!_isEmpty(dil.form.tanim))).map((d) => d.form);
      const isEmpty = _isEmpty(diller);
      const data = { ...next, ozellik_baslik: next.ozellik_baslik, diller: diller }
      delete data.title;
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        if (isHas && !isEmpty) {
          reqopt = {
            data: data,
            method: 'PUT',
            id: data.id,
            successMessage: `${next.title} metin özellik güncelleme başarıyla tamamlandı`,
            errorMessageUnexpected: `${next.title} metin özellik güncellenirken beklenmeyen bir hata oluştu`,
          }
        }
        if (isHas && isEmpty) {
          reqopt = {
            data: data,
            method: 'DELETE',
            id: data.id,
            successMessage: `${next.title} metin özellik silme başarıyla tamamlandı`,
            errorMessageUnexpected: `${next.title} metin özellik silinirken beklenmeyen bir hata oluştu`,
          }
        }
        if (!isHas && !isEmpty) {
          reqopt = {
            data: data,
            method: 'POST',
            id: null,
            successMessage: `${next.title} metin özellik ekleme başarıyla tamamlandı`,
            errorMessageUnexpected: `${next.title} metin özellik eklenirken beklenmeyen bir hata oluştu`,
          }
        }
        if (!isHas && isEmpty) {
          reqopt = null;
        }
        err = null;
      }
      return [reqopt, err];
    }

    const createRequestOptionsTabYorum = async (next) => {
      let reqopt = null;
      let err = null;
      const prev = urunYorumDefault.find(x => x.form.id === next.form.id);
      const isEqual = _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const form = { ...next.form };
        reqopt = {
          data: form,
          method: 'PUT',
          id: form.id,
          successMessage: `Ürün yorum güncelleme başarıyla tamamlandı`,
          errorMessageUnexpected: `Ürün yorum güncellenirken beklenmeyen bir hata oluştu`,
        }
        err = null;
      }
      return [reqopt, err];
    }

    const createRequestOptionsTabKampanya = async (next, index) => {
      let reqopt = null;
      let err = null;
      const prev = kampanyaDefault.find(x => x.form.id === next.form.id);
      const isEqual = _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        if (next.form.kampanya_adetli_calissin) {
          const list = [
            { prop: 'urun_adedi', prop2: '' },
          ];
          await checkAllTabs(next.form, (err) => handleErrorsKampanya(err, index), list).then((res) => {
            if (_isEmpty(res)) {
              const form = { ...next.form, kampanya: next.form.kampanya.id, urun_adedi: Number(next.form.urun_adedi) };
              reqopt = {
                data: form,
                method: 'PUT',
                id: form.id,
                successMessage: `Ürün kampanya miktar güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Ürün kampanya miktar güncellenirken beklenmeyen bir hata oluştu`,
              }
              err = null;
            } else {
              reqopt = null;
              err = res;
            }
          })
        } else {
          const form = { ...next.form, kampanya: next.form.kampanya.id, urun_adedi: Number(next.form.urun_adedi ?? 0) };
          reqopt = {
            data: form,
            method: 'PUT',
            id: form.id,
            successMessage: `Ürün kampanya miktar güncelleme başarıyla tamamlandı`,
            errorMessageUnexpected: `Ürün kampanya miktar güncellenirken beklenmeyen bir hata oluştu`,
          }
          err = null;
        }
      }
      return [reqopt, err];
    }

    let errors = [[], [null], [], [], [], [], [], [null], [], [], [], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', requests: [], error: [] },
        { index: 2, name: 'icerikler', requests: [], error: [] },
        { index: 3, name: 'fiyatlar', requests: [], error: [] },
        { index: 4, name: 'ozellikler', requests: [], error: [] },
        { index: 5, name: 'resimler', requests: [], error: [] },
        { index: 6, name: 'barkodlar', requests: [], error: [] },
        { index: 8, name: 'ekozellikler', requests: [], error: [] },
        { index: 9, name: 'metinozellikler', requests: [], error: [] },
        { index: 10, name: 'yorum', requests: [], error: [] },
        { index: 11, name: 'kampanya', requests: [], error: [] },
      ];
      const res = await createRequestOptionsTabGenel();
      tabs[0].requests.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in dilMiniList) {
        const diller = [...iceriklerForm];
        const dillerDefault = add ? [...iceriklerFormAdd] : !saveErrors[2].includes("err") ? [...iceriklerFormDefault] : [...iceriklerFormAdd];
        const res = await createDilRequestOption(diller[i], dillerDefault[i], i)
        tabs[1].requests.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      for (const index in fiyatlarForm) {
        for (const index2 in fiyatlarForm[index].form) {
          const fiyatState = fiyatlarForm[index].form[index2];
          const fiyatStateDefault = add ? fiyatlarFormAdd[index].form[index2] : !saveErrors[2].includes("err") ? fiyatlarFormDefault[index].form[index2] : fiyatlarFormAdd[index].form[index2];
          const res = await createFiyatRequestOption(fiyatState, fiyatStateDefault, index, index2, fiyatlarForm[index].liste.listeTanim)
          tabs[2].requests.push(res[0]);
          tabs[2].error.push(res[1]);
        }
      }

      const ozellikres = await createOzelliklerRequestOptions(urunOzellikler, urunOzelliklerDefault);
      tabs[3].requests = [...ozellikres[0]];
      tabs[3].error = [...ozellikres[1]];

      for (const i in urunResimler) {
        const resimstate = [...urunResimler];
        const res = await createFotoRequest(resimstate[i], i)
        tabs[4].requests.push(res[0]);
        tabs[4].error.push(res[1]);
      }

      for (const i in urunBarkodlar) {
        const barkodstate = [...urunBarkodlar];
        const res = await createRequestOptionsTabBarkod(barkodstate[i], i)
        tabs[5].requests.push(res[0]);
        tabs[5].error.push(res[1]);
      }

      for (const i in urunEkOzellikler) {
        const ekozellikstate = [...urunEkOzellikler];
        const res = await createRequestOptionsTabEkOzellik(ekozellikstate[i], i)
        tabs[6].requests.push(res[0]);
        tabs[6].error.push(res[1]);
      }

      for (const i in urunMetinOzellikler) {
        const metinozellikstate = [...urunMetinOzellikler];
        const res = await createRequestOptionsTabMetinOzellik(metinozellikstate[i], i)
        tabs[7].requests.push(res[0]);
        tabs[7].error.push(res[1]);
      }

      for (const i in urunYorum) {
        const urunyorumstate = [...urunYorum];
        const res = await createRequestOptionsTabYorum(urunyorumstate[i], i)
        tabs[8].requests.push(res[0]);
        tabs[8].error.push(res[1]);
      }

      for (const i in kampanya) {
        const kampanyastate = [...kampanya];
        const res = await createRequestOptionsTabKampanya(kampanyastate[i], i)
        tabs[9].requests.push(res[0]);
        tabs[9].error.push(res[1]);
      }



      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.requests, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "urun" : `urun/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.urun.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (SAVEID !== null) {
              if (tab.name === 'icerikler') {
                setIceriklerForm((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  });
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `urun/${SAVEID}/urundil/${request.id}` : `urun/${SAVEID}/urundil`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  setIceriklerSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setIceriklerForm((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = null;
                      });
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'fiyatlar') {
                setFiyatlarForm((prev) => {
                  return immer(prev, (next) => {
                    next[request.index].errors = null;
                    next[request.index].form[request.index2].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `urun/${SAVEID}/fiyatlar/${request.id}` : `urun/${SAVEID}/fiyatlar`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  setFiyatlarSelectedTabIndex(request.index);
                  if (error.response) {
                    setFiyatlarForm((prev) => {
                      return immer(prev, (next) => {
                        next[request.index].errors = true;
                        next[request.index].form[request.index2].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'ozellikler') {
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `urun/${SAVEID}/ozellikler/${request.id}` : `urun/${SAVEID}/ozellikler`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch(() => {
                  setSelectedTabIndex(tab.index);
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  errors[tab.index].splice(requestIndex, 0, "err");
                })
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'resimler') {
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `urun/${SAVEID}/fotolar/${request.id}` : `urun/${SAVEID}/fotolar`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  if (error.response) {
                    let resimler = [...urunResimler];
                    const index = resimler.findIndex(f => f.form.foto.id === request.config.data.foto);
                    resimler[index].errors = error.response.data;
                    setUrunResimler(resimler);
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                })
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'barkodlar') {
                setUrunBarkodlar((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `urun/${SAVEID}/barkodlar/${request.id}` : `urun/${SAVEID}/barkodlar`
                }).then(() => {
                  setBarkodAdd(true);
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setBarkodAdd(false);
                  setSelectedTabIndex(tab.index);
                  setUrunBarkodlarSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setUrunBarkodlar((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'ekozellikler') {
                setUrunEkOzellikler((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `urun/${SAVEID}/urunekozellik/${request.id}` : `urun/${SAVEID}/urunekozellik`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  setUrunBarkodlarSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setUrunEkOzellikler((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'metinozellikler') {
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `urun/${SAVEID}/urunmetinozellik/${request.id}` : `urun/${SAVEID}/urunmetinozellik`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(4);
                  if (error.response) {
                    enqueueSnackbar(error.response.data, { variant: 'error' });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'yorum') {
                setUrunYorum((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: `urun/${SAVEID}/urunyorum/${request.id}`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  if (error.response) {
                    setUrunYorum((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'kampanya') {
                setKampanya((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: `urun/${SAVEID}/kampanya/${request.id}`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  if (error.response) {
                    setKampanya((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }
    await start();
    if (SAVEID !== null) {
      if (!add) {
        if (!errors[0].includes("err")) {
          setLoadTab1(true);
        }
        if (!errors[1].includes("err")) {
          setLoadTab2(true);
        }
        if (!errors[2].includes("err")) {
          setLoadTab3(true);
        }
        if (!errors[3].includes("err")) {
          setLoadTab4(true);
        }
        if (!errors[4].includes("err")) {
          setLoadTab5(true);
        }
        if (!errors[5].includes("err")) {
          setLoadTab6(true);
        }
        if (!errors[6].includes("err")) {
          setLoadTab7(true);
        }
        if (!errors[7].includes("err")) {
          setLoadTab8(true);
        }
        if (!errors[8].includes("err")) {
          setLoadTab9(true);
        }
        if (!errors[9].includes("err")) {
          setLoadTab10(true);
        }
        if (!errors[10].includes("err")) {
          setLoadTab10(true);
        }
        if (!errors[11].includes("err")) {
          setLoadTab12(true);
        }
      } else {
        setSaveErrors(errors);
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
      await checkAll();
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? (SAVEID ? false : true) : (!errors[11].includes("err") ? false : true);
        next[1].hasError = !errors[1].includes("err") ? false : true;
        next[2].hasError = !errors[2].includes("err") ? false : true;
        next[3].hasError = !errors[3].includes("err") ? false : true;
        next[4].hasError = !errors[4].includes("err") ? (!errors[9].includes("err") ? false : true) : true;
        next[5].hasError = !errors[5].includes("err") ? false : true;
        next[6].hasError = !errors[6].includes("err") ? false : true;
        next[7].hasError = !errors[7].includes("err") ? false : true;
        next[8].hasError = !errors[8].includes("err") ? false : true;
        next[9].hasError = !errors[10].includes("err") ? false : true;
      });
    });
  };

  // #region DELETE
  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);

        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.urun.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteDetailIcerik = () => {
    setIsDeletingDetailIcerik(true);
    setIsDeletingErrorMessageDetailIcerik(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/urundil/${detailDeleteIdIcerik}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetailIcerik(false);
        setIsDeletingErrorMessageDetailIcerik(null);
      } else {
        setIsDeletingErrorMessageDetailIcerik(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetailIcerik(errorMessage);
    }).finally(() => {
      setIsDeletingDetailIcerik(false);
      setLoadTab3(true);
    });
  }
  const handleDeleteDetailIcerikWithIndex = (index) => {
    setIceriklerForm((prev) => {
      return immer(prev, (next) => {
        next[index].form = { ...ICERIKLER_FORM_DATA_ITEM_DEFAULT, dil: next[index].form.dil };
        next[index].errors = null;
      })
    });
  }

  const handleDeleteDetailFoto = () => {
    setIsDeletingDetailFoto(true);
    setIsDeletingErrorMessageDetailFoto(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/fotolar/${detailDeleteIdFoto}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetailFoto(false);
        setIsDeletingErrorMessageDetailFoto(null);
      } else {
        setIsDeletingErrorMessageDetailFoto(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetailFoto(errorMessage);
    }).finally(() => {
      setIsDeletingDetailFoto(false);
      setLoadTab6(true);
    });
  }

  const handleDeleteDetailBarkod = () => {
    setBarkodAdd(false);
    setIsDeletingDetailBarkod(true);
    setIsDeletingErrorMessageDetailBarkod(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/barkodlar/${detailDeleteIdBarkod}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetailBarkod(false);
        setIsDeletingErrorMessageDetailBarkod(null);
      } else {
        setIsDeletingErrorMessageDetailBarkod(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetailBarkod(errorMessage);
    }).finally(() => {
      setIsDeletingDetailBarkod(false);
      setLoadTab7(true);
    });
  }

  const handleDeleteEkOzellik = () => {
    setIsDeletingDetailEkOzellik(true);
    setIsDeletingErrorMessageDetailEkOzellik(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/urunekozellik/${detailDeleteIdEkOzellik}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetailEkOzellik(false);
        setIsDeletingErrorMessageDetailEkOzellik(null);
      } else {
        setIsDeletingErrorMessageDetailEkOzellik(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetailEkOzellik(errorMessage);
    }).finally(() => {
      setIsDeletingDetailEkOzellik(false);
      setLoadTab9(true);
    });
  }

  const handleDeleteDetailEkOzellikWithIndex = (index) => {
    setUrunEkOzellikler((prev) => {
      return immer(prev, (next) => {
        next.splice(index, 1);
      })
    });
  }
  // #endregion

  // #region ERRORS  
  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsIcerik = (errors, index) => {
    setIceriklerForm((prev) => {
      let errobj = {};
      let err = {};
      if (Object.keys(errors)[0] === 'dil_icerik') {
        if (_has({ ...prev[index].errors }, Object.keys(errors)[0])) {
          let copyerr = { ...prev[index].errors }
          copyerr.dil_icerik = { ...copyerr.dil_icerik, ...errors.dil_icerik };
          errobj = { ...copyerr }
        } else {
          errobj = { ...prev[index].errors, ...errors }
        }
      } else {
        errobj = { ...prev[index].errors, ...errors }
      }

      if (_has(errobj, 'dil_icerik')) {
        const dilicerik = _pickBy(errobj['dil_icerik'], _identity);
        if (_isEmpty(dilicerik)) {
          err = _pickBy({ ...errobj, 'dil_icerik': null }, _identity);
        } else {
          err = _pickBy({ ...errobj, 'dil_icerik': dilicerik }, _identity);
        }
      } else {
        err = _pickBy(errobj, _identity);
      }

      return immer(prev, (next) => {
        next[index].errors = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsFoto = (errors, index) => {
    setUrunResimler((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsBarkod = (errors, index) => {
    setUrunBarkodlar((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsKampanya = (errors, index) => {
    setKampanya((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsFiyat = (errors, index, index2) => {
    setFiyatlarForm((prev) => {
      const err = _pickBy({ ...prev[index]["form"][index2]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : true;
        next[index]["form"][index2]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  // #endregion
  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}
        variantButton={true}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            id={ID}
            loading={loading}
            hata={hataGenel}
            add={add}
            hataKampanya={hataKampanya}
            loadingKampanya={loadingKampanya}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            kampanya={kampanya}

            varyant={varyant}
            onChangeFormVaryant={handleChangeVaryants}
            reloadVaryants={handleReloadVaryants}

            urunTipleri={urunTipleri}
            urunTipleriDic={urunTipleriDic}
            birimList={birimList}
            birimListDic={birimListDic}
            birim={birim}
            satisBirimGrup={satisBirimGrup}
            satisBirimGrupDic={satisBirimGrupDic}
            carpanList={carpanList}
            ureticiList={ureticiList}
            ureticiListDic={ureticiListDic}

            onChangeFormKampanya={handleKampanyaChangeForm}
            onChangeForm={handleTabGenelChangeForm}
            onCheckErrors={(errors) => handleErrors(errors)}
            onCheckErrorsKampanya={(errors, index) => handleErrorsKampanya(errors, index)}
          />
        )}

        {selectedTabIndex === 1 &&
          <TabMiktarlar
            loading={loadingMiktar}
            hata={hataMiktar}
            miktarlar={miktarlar}
          ></TabMiktarlar>
        }

        {selectedTabIndex === 2 &&
          <TabIcerikler
            loading={LOADINGICERIK}
            hata={hataIcerik}

            selectedTabIndex={iceriklerSelectedTabIndex}
            forms={iceriklerForm}
            onChangeForm={handleTabIceriklerChangeForm}

            onCheckErrors={(errors, index) => handleErrorsIcerik(errors, index)}

            setDeleteId={setDeleteIdDetailIcerik}
            openDeleteForm={setShowDeleteDialogDetailIcerik}
            deleteDetailWithIndex={handleDeleteDetailIcerikWithIndex}
          ></TabIcerikler>
        }

        {selectedTabIndex === 3 &&
          <TabFiyatlar
            loading={LOADINGFIYAT}
            hata={hataFiyat}

            selectedTabIndex={fiyatlarSelectedTabIndex}
            forms={fiyatlarForm}
            parabirim={parabirimList}
            parabirimListDic={parabirimListDic}
            onChangeFormData={handleTabFiyatlarChangeForm}
            onCheckErrors={(errors, index, index2) => handleErrorsFiyat(errors, index, index2)}
          />
        }

        {selectedTabIndex === 4 &&
          <TabOzellikler
            loading={loadingOzellik}
            hata={hataOzellik}
            loading2={LOADINGMETINOZELLIK}
            hata2={hataMetinOzellik}

            ozelliklist={ozellikList}
            onChangeFormData={handleTabOzelliklerChangeForm}

            ozelliklist2={urunMetinOzellikler}
            onChangeFormData2={handleMetinOzellikChangeForm}

          />
        }
        {selectedTabIndex === 5 &&
          <TabResimler
            loading={loadingFoto}
            hata={hataFoto}

            resimler={urunResimler}
            onChangeFormData={handleTabResimlerChangeForm}

            setDeleteId={setDeleteIdDetailFoto}
            openDeleteForm={setShowDeleteDialogDetailFoto}

            onCheckErrors={(errors, index) => handleErrorsFoto(errors, index)}
          />
        }
        {selectedTabIndex === 6 &&
          <TabBarkodlar
            loading={loadingBarkod}
            hata={hataBarkod}

            selectedTabIndex={urunBarkodlarSelectedTabIndex}
            forms={urunBarkodlar}
            barkodBirimList={barkodBirimList}
            barkodBirimListDic={barkodBirimListDic}
            onChangeForm={handleBarkodChangeForm}
            onAddNewTab={handleBarkodAddNewTab}
            barkodAdd={barkodAdd}

            setDeleteId={setDeleteIdDetailBarkod}
            openDeleteForm={setShowDeleteDialogDetailBarkod}

            onCheckErrors={(errors, index) => handleErrorsBarkod(errors, index)}

          />
        }
        {selectedTabIndex === 7 &&
          <TabTedarikciler
            loading={loadingTedarikci}
            hata={hataTedarikci}
            tedarikciler={tedarikciler}
          ></TabTedarikciler>
        }
        {selectedTabIndex === 8 &&
          <TabEkOzellikler
            loading={LOADINGEKOZELLIK}
            hata={hataEkOzellik}
            forms={urunEkOzellikler}
            onChangeForm={handleChangeFormEkOzellik}
            onAddNewRow={handleEkOzellikAddNewRow}
            setDeleteId={setDeleteIdDetailEkOzellik}
            openDeleteForm={setShowDeleteDialogDetailEkOzellik}
            deleteDetailWithIndex={handleDeleteDetailEkOzellikWithIndex}
          />
        }
        {selectedTabIndex === 9 &&
          <TabYorumlar
            loading={loadingYorum}
            loadingMore={loadingYorumMore}
            hata={hataYorum}

            liste={urunYorum}
            listeCount={urunYorumCount}
            page={PAGEYORUM}
            pagesCount={PAGES_COUNT_YORUM}
            getMore={handleGetMoreList}
            rowCount={YORUM_PER_PAGE}

            onChangeForm={handleYorumChangeForm}
          />
        }
      </LayoutDetail>


      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Ürün siliniyor lütfen bekleyin'
                : 'Bu ürünü gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }

      {showDeleteDialogDetailIcerik &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetailIcerik
              ? isDeletingErrorMessageDetailIcerik
              : isDeletingDetailIcerik
                ? 'İçerik siliniyor lütfen bekleyin'
                : 'Bu içeriği gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetailIcerik ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetailIcerik}
          onSubmit={handleDeleteDetailIcerik}
          onCancel={() => setShowDeleteDialogDetailIcerik(false)}
        ></DialogConfirm>
      }

      {showDeleteDialogDetailBarkod &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetailBarkod
              ? isDeletingErrorMessageDetailBarkod
              : isDeletingDetailBarkod
                ? 'Barkod siliniyor lütfen bekleyin'
                : 'Bu barkodu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetailBarkod ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetailBarkod}
          onSubmit={handleDeleteDetailBarkod}
          onCancel={() => setShowDeleteDialogDetailBarkod(false)}
        ></DialogConfirm>
      }

      {showDeleteDialogDetailFoto &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetailFoto
              ? isDeletingErrorMessageDetailFoto
              : isDeletingDetailFoto
                ? 'Fotoğraf siliniyor lütfen bekleyin'
                : 'Bu fotoğrafı gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetailFoto ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetailFoto}
          onSubmit={handleDeleteDetailFoto}
          onCancel={() => setShowDeleteDialogDetailFoto(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetailEkOzellik &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetailEkOzellik
              ? isDeletingErrorMessageDetailEkOzellik
              : isDeletingDetailEkOzellik
                ? 'Ek özellik siliniyor lütfen bekleyin'
                : 'Bu ek özelliği gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetailEkOzellik ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetailEkOzellik}
          onSubmit={handleDeleteEkOzellik}
          onCancel={() => setShowDeleteDialogDetailEkOzellik(false)}
        ></DialogConfirm>
      }

    </>
  );
};

export default TableDetailExampleDetail;
