import React, { useMemo } from 'react';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { Hata } from '../../base/Hata';

import { ThemeProvider } from '@material-ui/core/styles';

import Store from '../../../libs/store'
import Themes from '../../../themes/index';

const appTheme = Themes[Store.get('themeChoice')?.theme ?? 'light'];

const theme = {
  overrides: {
    MuiTable: {
      root: {
        '& th:first-child, & td:first-child': {
          paddingLeft: appTheme.spacing(4),
        },

        '& th:last-child, & td:last-child': {
          paddingRight: appTheme.spacing(4),
        },

        '& tbody tr:hover td': {
          backgroundColor: appTheme.palette.action.hover,
        },
        '& td,th': {
          fontSize: '0.75rem'
        }
      },
    },
  },
};

const CariDetailTab_Sepet = ({ sepet, hata, loading }) => {
  const LOADING = useMemo(() => loading, [loading]);
  const HATA = useMemo(() => hata, [hata]);
  const SEPET = useMemo(() => sepet, [sepet]);

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className="py-4 relative w-full h-full flex flex-row items-start overflow-x-auto">
              <ThemeProvider theme={theme}>
                {SEPET.length > 0 &&
                  <>
                    {SEPET.map((x) => (
                      <div key={x.magaza?.id} className="w-full h-full flex flex-col">
                        <div className="border-b border-primary px-8 text-sm text-primary font-semibold">Mağaza - {x.magaza?.tanim}</div>
                        <Table
                          className="focus:outline-none whitespace-nowrap"
                          size="small"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell component="th" scope="row" style={{ width: 1 }}>Ürün Kodu</TableCell>
                              <TableCell component="th" scope="row" style={{ width: 1 }}>Ürün Adı</TableCell>
                              <TableCell component="th" scope="row" style={{ width: 1 }}>Birim</TableCell>
                              <TableCell component="th" scope="row" align="right" style={{ width: 1 }}>Miktar</TableCell>
                              <TableCell component="th" scope="row" />
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <>
                              {x.sepetdetaylar.length > 0 &&
                                <>
                                  {x.sepetdetaylar.map((urun) => {
                                    return (
                                      <TableRow key={urun.id}>
                                        <TableCell>{urun.urun.kodu}</TableCell>
                                        <TableCell>{urun.urun.tanim}</TableCell>
                                        <TableCell>{urun.birim.tanim}</TableCell>
                                        <TableCell align="right">{urun.miktar}</TableCell>
                                        <TableCell />
                                      </TableRow>
                                    );
                                  })}
                                </>
                              }
                              {x.sepetdetaylar.length === 0 &&
                                <>
                                  <TableRow>
                                    <TableCell colSpan="5">Sepet boş.</TableCell>
                                  </TableRow>
                                </>
                              }
                            </>
                          </TableBody>
                        </Table>
                      </div>
                    ))}
                  </>
                }

                {SEPET.length === 0 &&
                  <div className="px-8">Bu üyeye ait sepet kaydı bulunamadı.</div>
                }
              </ThemeProvider>
            </div>
          )}
        </>
      }
    </>
  );
};

export default CariDetailTab_Sepet;
