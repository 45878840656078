import django from '../../api/django';

import {
  result as _result,
  isEmpty as _isEmpty,
  isNull as _isNull,

} from 'lodash';

export const handleInputErrors = async (url, form, formdefault, func, add, prop, empty, unique, multiple, multipletext, propname, pagename, prop2 = '') => {
  let errors = {};
  let column = '';
  let columnDefault = '';
  if (prop2 !== '') {
    column = _result(form[prop], `${prop2}`) || '';
    columnDefault = _result(formdefault[prop], `${prop2}`) || '';
    errors[prop] = {};
  } else {
    column = _result(form, `${prop}`) || '';
    columnDefault = _result(formdefault, `${prop}`) || '';
  }

  const setErrors = async () => {
    if (prop2 !== '') {
      if (empty) {
        if (_isEmpty(column) || column === '-1') {
          if (multiple) {
            errors[prop][prop2] = [`${multipletext}`];
          } else {
            errors[prop][prop2] = ['Bu alan boş bırakılmamalı.'];
          }
        } else {
          if (unique) {
            if (add) {
              await django(`${url}/count`, { params: { [`${prop}`]: column, size: 1 } }).then(({ data }) => {
                if (data > 0) {
                  errors[prop][prop2] = [`Bu ${propname} alanına sahip ${pagename} zaten mevcut.`]
                } else {
                  errors[prop][prop2] = null;
                }
              });
            } else {
              if (column !== columnDefault) {
                await django(`${url}/count`, { params: { [`${prop}`]: column, size: 1 } }).then(({ data }) => {
                  if (data > 0) {
                    errors[prop][prop2] = [`Bu ${propname} alanına sahip ${pagename} zaten mevcut.`]
                  } else {
                    errors[prop][prop2] = null;
                  }
                });
              } else {
                errors[prop][prop2] = null;
              }
            }
          } else {
            errors[prop][prop2] = null;
          }
        }
      }
    } else {
      if (empty) {
        if (_isEmpty(column) || column === '-1') {
          if (multiple) {
            errors[prop] = [`${multipletext}`];
          } else {
            errors[prop] = ['Bu alan boş bırakılmamalı.'];
          }
        } else {
          if (unique) {
            if (add) {
              await django(`${url}/count`, { params: { [`${prop}`]: column, size: 1 } }).then(({ data }) => {
                if (data > 0) {
                  errors[prop] = [`Bu ${propname} alanına sahip ${pagename} zaten mevcut.`]
                } else {
                  errors[prop] = null;
                }
              });
            } else {
              if (column !== columnDefault) {
                await django(`${url}/count`, { params: { [`${prop}`]: column, size: 1 } }).then(({ data }) => {
                  if (data > 0) {
                    errors[prop] = [`Bu ${propname} alanına sahip ${pagename} zaten mevcut.`]
                  } else {
                    errors[prop] = null;
                  }
                });
              } else {
                errors[prop] = null;
              }
            }
          } else {
            errors[prop] = null;
          }
        }
      }
    }
  }
  await setErrors().then(() => func(errors));
  return errors;
}

export const checkAllList = async (url, form, formdefault, func, add, list) => {
  const error = [];
  for (const x of list) {
    const res = await handleInputErrors(url, form, formdefault, func, add, x.prop, x.empty, x.unique, x.multiple, x.multipletext, x.propname, x.pagename, x.prop2);
    if (x.prop2 !== '' ? !_isNull(res[x.prop][x.prop2]) || !_isEmpty(res[x.prop][x.prop2]) : !_isNull(res[x.prop]) || !_isEmpty(res[x.prop])) {
      error.push(res)
    }
  }
  return error;
}

export const handleInputErrors2 = async (form, prop, func, prop2 = '') => {
  let errors = {};
  let column = '';
  if (prop2 !== '') {
    column = _result(form[prop], `${prop2}`) || '';
    errors[prop] = {};
  } else {
    column = _result(form, `${prop}`) || '';
  }

  const setErrors = async () => {
    if (prop2 !== '') {
      if (_isEmpty(column) || column === '-1') {
        errors[prop][prop2] = ['Bu alan boş bırakılmamalı.'];
      } else {
        errors[prop][prop2] = null;
      }
    } else {
      if (_isEmpty(column) || column === '-1') {
        errors[prop] = ['Bu alan boş bırakılmamalı.'];
      } else {
        errors[prop] = null;
      }
    }
  }
  await setErrors().then(() => func(errors));
  return errors;
}

export const checkAllList2 = async (form, func, list) => {
  const error = [];
  for (const x of list) {
    const res = await handleInputErrors2(form, x.prop, func, x.prop2);
    if (x.prop2 !== '' ? !_isNull(res[x.prop][x.prop2]) || !_isEmpty(res[x.prop][x.prop2]) : !_isNull(res[x.prop]) || !_isEmpty(res[x.prop])) {
      error.push(res)
    }
  }
  return error;
}