import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import RightMenu from '../helpers/rightMenu';

import {
  Button,
  Checkbox,
  Paper,
  CircularProgress,
  LinearProgress,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Tooltip,
} from '@material-ui/core';

import {
  ErrorOutline as IconErrorOutline,
  Close as IconClose,
  Search as IconSearch,
  KeyboardArrowRight as IconKeyboardArrowRight,
  KeyboardArrowLeft as IconKeyboardArrowLeft,
  Refresh as IconRefresh,
} from '@material-ui/icons';

import django from '../api/django';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      backgroundColor: theme.palette.toolbar,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1),
      borderBottom: '1px solid white',
      minHeight: '36px !important'
    }
  };
});

const PER_PAGE = 100;
const ROW_HEIGHT = 34;

const LoadingMessage = ({ text }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <CircularProgress />
      </div>

      <p className="font-medium">{text}</p>
    </div>
  );
};

const ErrorMessage = ({ text, submitText, onSubmit }) => {
  return (
    <div className="w-full max-h-full flex flex-col items-center justify-center p-8">
      <div className="w-full h-16 flex items-center justify-center">
        <IconErrorOutline
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      </div>

      <p className="font-medium mb-2">{text}</p>

      {onSubmit &&
        <Button
          color="primary"
          onClick={onSubmit}
        >{submitText ?? 'TEKRAR DENE'}</Button>
      }
    </div>
  );
};

const OptionItemFirst = ({ optionFirst, viewProp, highlightQuery, onClick }) => {
  return (
    <div
      key={`${optionFirst.id}${optionFirst[viewProp]}`}
      className={clsx('w-full flex items-center justify-between hover:text-primary px-4 space-x-3 select-none cursor-pointer')}
      style={{
        height: ROW_HEIGHT,
      }}
    >
      <Highlighter
        textToHighlight={`${optionFirst[viewProp]}`}
        searchWords={[highlightQuery ?? '']}
        className=""
        activeClassName=""
        highlightClassName="leading-none"
        unhighlightClassName="leading-none"
      />
      <span className="w-12 flex items-center justify-center">
        <IconButton
          size="small"
          disableRipple
          onClick={() => onClick(optionFirst)}
        ><IconKeyboardArrowRight /></IconButton>
      </span>
    </div>
  );
};

const OptionItem = ({ option, isChecked, viewProp, highlightQuery, onClick }) => {
  return (
    <div
      key={`${option.id}${option[viewProp]}`}
      className={clsx('w-full flex items-center justify-start hover:text-primary px-4 space-x-3 select-none cursor-pointer')}
      style={{
        height: ROW_HEIGHT,
      }}
      onClick={() => onClick(!isChecked)}
    >
      <Checkbox
        color="primary"
        checked={isChecked}
        onChange={(e, checked) => onClick(checked)}
        style={{
          padding: 0,
        }}
      ></Checkbox>

      <Highlighter
        textToHighlight={`${option[viewProp]}`}
        searchWords={[highlightQuery ?? '']}
        className=""
        activeClassName=""
        highlightClassName="leading-none"
        unhighlightClassName="leading-none"
      />
    </div>
  );
};

const MultipleFilterSelectorTwoStep = ({ title, firstOptionsPath, optionsPath, firstOptionViewProp, filterProp, sortProp, viewProp, selectedOptionKeys, onSetSelectedOptionKeys, onSubmit, onClose, newpath }) => {
  const [firstOptions, setFirstOptions] = useState([]);
  const [firstOptionsLoading, setFirstOptionsLoading] = useState(false);
  const [firstOptionsLoadingErrorMessage, setFirstOptionsLoadingErrorMessage] = useState(null);

  const [firstOptionsCount, setFirstOptionsCount] = useState(0);
  const [moreFirstOptionsLoading, setMoreFirstOptionsLoading] = useState(false);

  const [searchQueryFirst, setSearchQueryFirst] = useState('');
  const [searchFirstOptions, setSearchFirstOptions] = useState([]);
  const [searchFirstOptionsLoading, setSearchFirstOptionsLoading] = useState(false);
  const [searchFirstOptionsLoadingErrorMessage, setSearchFirstOptionsLoadingErrorMessage] = useState(null);

  const [options, setOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [optionsLoadingErrorMessage, setOptionsLoadingErrorMessage] = useState(null);

  const [optionsCount, setOptionsCount] = useState(0);
  const [moreOptionsLoading, setMoreOptionsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchOptionsLoading, setSearchOptionsLoading] = useState(false);
  const [searchOptionsLoadingErrorMessage, setSearchOptionsLoadingErrorMessage] = useState(null);

  const [selectedFirstOption, setSelectedFirstOption] = useState(null);
  const [listOpen, setListOpen] = useState(false);

  const [showSelectedOptions, setShowSelectedOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsLoading, setSelectedOptionsLoading] = useState(false);
  const [selectedOptionsLoadingErrorMessage, setSelectedOptionsLoadingErrorMessage] = useState(null);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const classes = useStyles();

  const mainContainerRef = useRef();

  // set first options
  useLayoutEffect(() => {
    setFirstOptionsLoading(true);
    django(`${firstOptionsPath}/mini_list`, { params: { sort: firstOptionViewProp } }).then(({ data }) => {
      setFirstOptions(data);
    }).catch((error) => {
      setFirstOptionsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
    }).finally(() => {
      setFirstOptionsLoading(false);
    });
  }, [firstOptionsPath, firstOptionViewProp, lastUpdateTime]);

  // set first options count
  useLayoutEffect(() => {
    django(`${firstOptionsPath}/count`).then(({ data }) => {
      setFirstOptionsCount(data);
    });
  }, [firstOptionsPath, lastUpdateTime]);

  // handle search first options
  useEffect(() => {
    const handleGet = ({ url, config }) => {
      setSearchFirstOptions([]);
      setSearchFirstOptionsLoading(true);
      setSearchFirstOptionsLoadingErrorMessage(null);

      django(url, config).then(({ data }) => {
        setSearchFirstOptions(data);
      }).catch(function (error) {
        if (django?.isCancel(error)) {
        } else {
          setSearchFirstOptionsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
        }
      }).finally(() => {
        setSearchFirstOptionsLoading(false);
      });
    };

    if (searchQueryFirst !== '') {
      const CancelToken = django.CancelToken;
      const cancelTokenSource = CancelToken.source();
      const config = {
        params: {
          tanim: `*${searchQueryFirst}*`,
        },
        cancelToken: cancelTokenSource.token,
      };
      const debounce = setTimeout(() => handleGet({ url: firstOptionsPath + '/mini_list', config }), 500);
      return () => {
        cancelTokenSource.cancel();
        clearTimeout(debounce);
      };
    }
  }, [firstOptionsPath, searchQueryFirst, lastUpdateTime]);

  // set options
  useLayoutEffect(() => {
    if (selectedFirstOption) {
      setOptionsLoading(true);
      django(`${firstOptionsPath}/${selectedFirstOption["id"]}/${optionsPath}/mini_list`, { params: { sort: sortProp } }).then(({ data }) => {
        setOptions(data);
      }).catch((error) => {
        setOptionsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
      }).finally(() => {
        setOptionsLoading(false);
      });
    }
  }, [selectedFirstOption, firstOptionsPath, optionsPath, sortProp, lastUpdateTime]);

  // set options count
  useLayoutEffect(() => {
    if (selectedFirstOption) {
      django(`${firstOptionsPath}/${selectedFirstOption["id"]}/${optionsPath}/count`).then(({ data }) => {
        setOptionsCount(data)
      })
    }
  }, [selectedFirstOption, firstOptionsPath, optionsPath, lastUpdateTime]);

  // handle search
  useEffect(() => {
    const handleGet = ({ url, config }) => {
      setSearchOptions([]);
      setSearchOptionsLoading(true);
      setSearchOptionsLoadingErrorMessage(null);

      django(url, config).then(({ data }) => {
        setSearchOptions(data);
      }).catch(function (error) {
        if (django?.isCancel(error)) {
        } else {
          setSearchOptionsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
        }
      }).finally(() => {
        setSearchOptionsLoading(false);
      });
    };

    if (searchQuery !== '') {
      const CancelToken = django.CancelToken;
      const cancelTokenSource = CancelToken.source();

      const config = {
        params: {
          tanim: `*${searchQuery}*`,
        },
        cancelToken: cancelTokenSource.token,
      };

      const debounce = setTimeout(() => handleGet({ url: `${firstOptionsPath}/${selectedFirstOption["id"]}/${optionsPath}/mini_list`, config }), 500);

      return () => {
        cancelTokenSource.cancel();

        clearTimeout(debounce);
      };
    }
  }, [firstOptionsPath, selectedFirstOption, optionsPath, searchQuery, lastUpdateTime]);

  // handle selected options
  useEffect(() => {
    if (selectedFirstOption) {
      if (showSelectedOptions) {
        if (filterProp === 'id') {
          const id = [...selectedOptionKeys].map((id) => id);
          setSelectedOptionsLoading(true);
          django(`${firstOptionsPath}/${selectedFirstOption["id"]}/${optionsPath}/mini_list`, { params: { id, sort: sortProp } }).then(({ data }) => {
            setSelectedOptions(data);
          }).catch(function (error) {
            if (django?.isCancel(error)) {
            } else {
              setSelectedOptionsLoadingErrorMessage('Beklenmeyen bir hata oluştu');
            }
          }).finally(() => {
            setSelectedOptionsLoading(false);
          });
        } else {
          const options = [...selectedOptionKeys].map(((value, index) => ({ id: index, [viewProp]: value })));
          setSelectedOptions(options);
        }
      }
    }
  }, [showSelectedOptions, selectedOptionKeys, filterProp, sortProp, viewProp, optionsPath, selectedFirstOption, firstOptionsPath, lastUpdateTime]);

  const handleCheckOption = (option, checked) => {
    const copySelectedOptionKeys = [...selectedOptionKeys].map((k) => (filterProp === 'id' ? Number(k) : k));

    if (checked) {
      copySelectedOptionKeys.push(option[filterProp]);
    } else {
      const index = copySelectedOptionKeys.findIndex((key) => key === option[filterProp]);
      if (index > -1) {
        copySelectedOptionKeys.splice(index, 1);
      }
    }
    onSetSelectedOptionKeys(copySelectedOptionKeys);
  };

  const handleGetMoreOptionsFirst = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setMoreFirstOptionsLoading(true);
    django(firstOptionsPath, { params: { page, sort: firstOptionViewProp } }).then(({ data }) => {
      setOptions((prev) => {
        return [...prev, ...data];
      });
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setMoreFirstOptionsLoading(false);
    });
  };

  const handleGetMoreOptions = (page) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setMoreOptionsLoading(true);
    django(`${firstOptionsPath}/${selectedFirstOption.id}/${optionsPath}`, { params: { page, sort: sortProp } }).then(({ data }) => {
      setOptions((prev) => {
        return [...prev, ...data];
      });
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setMoreOptionsLoading(false);
    });
  };

  const handleGoToList = (item) => {
    setSelectedFirstOption(item);
    setListOpen(true);

    setSearchQueryFirst('');
    setFirstOptionsLoading(false);
    setFirstOptionsLoadingErrorMessage(null);
    setSearchQuery('');
    setOptionsLoading(false);
    setOptionsLoadingErrorMessage(null);
  }

  const handleGoBackToMain = () => {
    setSelectedFirstOption(null);
    setListOpen(false);

    setSearchQueryFirst('');
    setFirstOptionsLoading(false);
    setFirstOptionsLoadingErrorMessage(null);
    setSearchFirstOptions([]);
    setSearchQuery('');
    setOptionsLoading(false);
    setOptionsLoadingErrorMessage(null);
    setSearchOptions([]);
  }

  const PAGE_FIRST = useMemo(() => firstOptions.length > 0 ? Math.ceil(firstOptions.length / PER_PAGE) : 1, [firstOptions.length]);
  const PAGES_COUNT_FIRST = useMemo(() => Math.ceil(firstOptionsCount / PER_PAGE), [firstOptionsCount]);

  const PAGE = useMemo(() => options.length > 0 ? Math.ceil(options.length / PER_PAGE) : 1, [options.length]);
  const PAGES_COUNT = useMemo(() => Math.ceil(optionsCount / PER_PAGE), [optionsCount]);

  const SHOW_LINEAR_PROGRESS_FIRST = useMemo(() => {
    if (firstOptionsLoading || moreFirstOptionsLoading || searchFirstOptionsLoading) {
      return true;
    }
    return false;
  }, [firstOptionsLoading, moreFirstOptionsLoading, searchFirstOptionsLoading]);

  const LOADING_MESSAGE_FIRST = useMemo(() => {
    if (firstOptionsLoading) {
      return 'Seçenekler yükleniyor';
    }
    if (searchFirstOptionsLoading) {
      return 'Arama yapılıyor';
    }
    return null;
  }, [firstOptionsLoading, searchFirstOptionsLoading]);

  const ERROR_MESSAGE_FIRST = useMemo(() => {
    if (firstOptionsLoadingErrorMessage) {
      return firstOptionsLoadingErrorMessage;
    }
    if (searchFirstOptionsLoadingErrorMessage) {
      return searchFirstOptionsLoadingErrorMessage;
    }

    return null;
  }, [firstOptionsLoadingErrorMessage, searchFirstOptionsLoadingErrorMessage]);

  const SHOW_LINEAR_PROGRESS = useMemo(() => {
    if (optionsLoading || moreOptionsLoading || searchOptionsLoading || selectedOptionsLoading) {
      return true;
    }
    return false;
  }, [optionsLoading, moreOptionsLoading, searchOptionsLoading, selectedOptionsLoading]);

  const LOADING_MESSAGE = useMemo(() => {
    if (optionsLoading) {
      return 'Seçenekler yükleniyor';
    }
    if (searchOptionsLoading) {
      return 'Arama yapılıyor';
    }
    return null;
  }, [optionsLoading, searchOptionsLoading]);

  const ERROR_MESSAGE = useMemo(() => {
    if (optionsLoadingErrorMessage) {
      return optionsLoadingErrorMessage;
    }

    if (searchOptionsLoadingErrorMessage) {
      return searchOptionsLoadingErrorMessage;
    }

    if (selectedOptionsLoadingErrorMessage) {
      return selectedOptionsLoadingErrorMessage;
    }

    return null;
  }, [optionsLoadingErrorMessage, searchOptionsLoadingErrorMessage, selectedOptionsLoadingErrorMessage]);

  const FILTERED_OPTIONS_FIRST = useMemo(() => {
    if (searchQueryFirst !== '') {
      return searchFirstOptions;
    }
    return firstOptions;
  }, [firstOptions, searchFirstOptions, searchQueryFirst]);

  const FILTERED_OPTIONS = useMemo(() => {
    if (showSelectedOptions) {
      return selectedOptions;
    }

    if (searchQuery !== '') {
      return searchOptions;
    }

    return options;
  }, [showSelectedOptions, selectedOptions, options, searchOptions, searchQuery]);

  const mainRowRenderer = ({ index, style }) => {
    const item = FILTERED_OPTIONS_FIRST[index];
    return (
      <div key={item.id} style={style}
        onContextMenu={(e) => {
          e.preventDefault();
          setSelectedItem(item);
          setSelectedId("null")
          setSelectedPosition({ top: e.clientY, left: e.clientX });
        }}
      >
        {index !== 0 && <Divider />}
        <OptionItemFirst
          optionFirst={item}
          viewProp={firstOptionViewProp}
          highlightQuery={searchQueryFirst}
          onClick={() => { handleGoToList(item) }}
        />
      </div>
    )
  }

  const rowRenderer = ({ index, style }) => {
    const option = FILTERED_OPTIONS[index];
    const key = option[filterProp];

    const copySelectedOptionKeys = [...selectedOptionKeys].map((k) => (filterProp === 'id' ? Number(k) : k));
    const isChecked = copySelectedOptionKeys.indexOf(key) > -1;

    return (
      <div key={key} style={style}
        onContextMenu={(e) => {
          e.preventDefault();
          setSelectedItem(option);
          setSelectedId(option.id)
          setSelectedPosition({ top: e.clientY, left: e.clientX });
        }}
      >
        {index !== 0 && <Divider />}
        <OptionItem
          option={option}
          isChecked={isChecked}
          viewProp={viewProp}
          highlightQuery={!showSelectedOptions ? searchQuery : ''}
          onClick={(checked) => handleCheckOption(option, checked)}
        ></OptionItem>
      </div>
    );
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  return (
    <>
      <Paper className="w-full h-full flex flex-col overflow-hidden">
        <AppBar
          className="border-b border-palette-action-selected"
          position="sticky"
          color="transparent"
          elevation={0}
        >
          <Toolbar
            className={classes.toolbar}
            variant="dense"
            disableGutters
          >
            <h3 className="font-medium font-roboto text-base leading-none m-0 p-0">{!listOpen ? title : selectedFirstOption[firstOptionViewProp]}</h3>

            <span>
              <Tooltip title="Yenile">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={handleReload}
                ><IconRefresh /></IconButton>
              </Tooltip>
              <Tooltip title="Kapat">
                <IconButton
                  edge="end"
                  color="inherit"
                  size="small"
                  onClick={onClose}
                ><IconClose /></IconButton>
              </Tooltip>
            </span>
          </Toolbar>

          <nav className="w-full flex-1 flex items-center justify-between bg-palette-background-default py-2 px-4 space-x-4">
            <span className="relative w-full h-8 leading-none bg-palette-background-paper apperance-none">
              <input
                className={clsx([
                  'absolute inset-0 w-full h-full px-10 bg-transparent rounded border border-palette-action-selected',
                  'focus:outline-none focus:border-primary-300 focus:ring-2 focus:ring-primary-200',
                ])}
                value={!listOpen ? searchQueryFirst ?? '' : searchQuery ?? ''}
                onChange={(e) => {
                  if (!listOpen) {
                    setSearchQueryFirst(e.target.value)
                  } else {
                    setSearchQuery(e.target.value)
                  }
                }}
              />

              <span className="absolute top-2 left-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                <IconSearch />
              </span>

              {!listOpen && searchQueryFirst.length > 0 &&
                <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchQueryFirst('');
                    }}
                  >
                    <IconClose />
                  </IconButton>
                </span>
              }
              {listOpen && searchQuery.length > 0 &&
                <span className="absolute top-2 right-3 w-4 h-4 flex items-center justify-center rounded-full text-palette-text-disabled">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchQuery('');
                    }}
                  >
                    <IconClose />
                  </IconButton>
                </span>
              }
            </span>
            {listOpen &&
              <span>
                <Tooltip title="Sadece seçili seçenekleri göster">
                  <Checkbox
                    color="primary"
                    checked={showSelectedOptions}
                    onChange={(e, checked) => setShowSelectedOptions(checked)}
                    disableRipple
                    style={{
                      padding: 0,
                    }}
                  ></Checkbox>
                </Tooltip>
              </span>
            }

          </nav>

          {!listOpen && (
            <span className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', { 'hidden': !SHOW_LINEAR_PROGRESS_FIRST })}>
              <LinearProgress style={{ height: 2 }} />
            </span>
          )}

          {listOpen && (
            <span className={clsx('absolute left-0 bottom-0 right-0 w-full h-auto', { 'hidden': !SHOW_LINEAR_PROGRESS })}>
              <LinearProgress style={{ height: 2 }} />
            </span>
          )}

        </AppBar>

        <main className="relative flex flex-grow flex-col overflow-hidden" ref={mainContainerRef}>
          {!listOpen && (
            <>
              <div className="h-full flex-grow">
                {LOADING_MESSAGE_FIRST && (
                  <LoadingMessage text={LOADING_MESSAGE} />
                )}

                {ERROR_MESSAGE_FIRST && (
                  <ErrorMessage
                    text={ERROR_MESSAGE}
                  />
                )}

                {(!LOADING_MESSAGE_FIRST && !ERROR_MESSAGE_FIRST && FILTERED_OPTIONS_FIRST.length === 0) && (
                  <ErrorMessage
                    text="Seçenek bulunamadı"
                  />
                )}
                {(!LOADING_MESSAGE_FIRST && !ERROR_MESSAGE_FIRST && FILTERED_OPTIONS_FIRST.length > 0) && (
                  <InfiniteLoader
                    minimumBatchSize={100}
                    threshold={50}
                    isRowLoaded={({ index }) => !!FILTERED_OPTIONS_FIRST[index]}
                    loadMoreRows={({ startIndex, stopIndex }) => {
                      if (PAGES_COUNT_FIRST > PAGE_FIRST && !moreFirstOptionsLoading) {
                        handleGetMoreOptionsFirst(PAGE_FIRST + 1);
                      }
                    }}
                    rowCount={firstOptionsCount > 0 ? firstOptionsCount : 1000}
                  >
                    {({ onRowsRendered, registerChild }) => (
                      <AutoSizer>
                        {({ width, height }) => (
                          <List
                            width={width}
                            height={height}
                            rowHeight={ROW_HEIGHT + 1}
                            rowCount={FILTERED_OPTIONS_FIRST.length}
                            estimatedRowSize={PAGES_COUNT_FIRST > 0 ? PAGES_COUNT_FIRST * (ROW_HEIGHT + 1) : undefined}
                            rowRenderer={mainRowRenderer}

                            onRowsRendered={onRowsRendered}
                            ref={registerChild}
                            onScroll={() => setSelectedItem(null)}
                          />
                        )}
                      </AutoSizer>
                    )}
                  </InfiniteLoader>
                )}
              </div>
            </>
          )}
          {listOpen && (
            <>
              {(!LOADING_MESSAGE && !ERROR_MESSAGE) && (
                <div>
                  <button
                    className="w-full h-10 flex items-center justify-between bg-palette-background-default hover:bg-palette-action-selected cursor-pointer focus:outline-none px-5"
                    onClick={() => handleGoBackToMain()}
                  >
                    <span className="flex items-center justify-start font-medium">Listeye Geri Dön</span>
                    <IconKeyboardArrowLeft />
                  </button>
                </div>
              )}
              <div className="h-full flex-grow">
                {LOADING_MESSAGE && (
                  <LoadingMessage text={LOADING_MESSAGE} />
                )}

                {ERROR_MESSAGE && (
                  <ErrorMessage
                    text={ERROR_MESSAGE}
                  />
                )}

                {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_OPTIONS.length === 0) && (
                  <ErrorMessage
                    text="Seçenek bulunamadı"
                  />
                )}
                {(!LOADING_MESSAGE && !ERROR_MESSAGE && FILTERED_OPTIONS.length > 0) && (
                  <InfiniteLoader
                    minimumBatchSize={100}
                    threshold={50}
                    isRowLoaded={({ index }) => !!FILTERED_OPTIONS[index]}
                    loadMoreRows={({ startIndex, stopIndex }) => {
                      if (PAGES_COUNT > PAGE && !moreOptionsLoading && !showSelectedOptions) {
                        handleGetMoreOptions(PAGE + 1);
                      }
                    }}
                    rowCount={optionsCount > 0 ? optionsCount : 1000}
                  >
                    {({ onRowsRendered, registerChild }) => (
                      <AutoSizer>
                        {({ width, height }) => (
                          <List
                            width={width}
                            height={height}
                            rowHeight={ROW_HEIGHT + 1}
                            rowCount={FILTERED_OPTIONS.length}
                            estimatedRowSize={PAGES_COUNT > 0 ? PAGES_COUNT * (ROW_HEIGHT + 1) : undefined}
                            rowRenderer={rowRenderer}

                            onRowsRendered={onRowsRendered}
                            ref={registerChild}
                            onScroll={() => setSelectedItem(null)}
                          />
                        )}
                      </AutoSizer>
                    )}
                  </InfiniteLoader>
                )}
              </div>

              <div className="flex items-center justify-between border-t border-palette-action-selected p-2">
                <span></span>
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disableRipple
                    onClick={onSubmit}
                  >FİLTRELE</Button>
                </span>
              </div>
            </>
          )}
          {selectedItem && (
            <RightMenu
              url={newpath}
              itemId={selectedItem?.id}
              ustId={selectedId}
              position={selectedPosition}
            ></RightMenu>
          )}
        </main>
      </Paper>
    </>
  );
};

export default MultipleFilterSelectorTwoStep;
