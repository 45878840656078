import React, { useMemo, useRef } from 'react';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import {
  Divider,
} from '@material-ui/core';
import {
  Check as IconCheck
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { Hata } from '../../base/Hata';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1),
      overflow: 'hidden'
    },
    listHeader: {
      display: "grid",
      gridTemplateColumns: "150px 150px 150px 350px 150px 150px auto",
      padding: spacing(2, 0, 1, 0),
      marginBottom: spacing(2),
      borderBottom: `1px solid ${palette.primary.main}`,
      width: '100%',
      gap: 10
    },
    headerCol: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "left",
    }
  };
};

const CariDetailTab_OdemeLog = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const LOADING_MORE = useMemo(() => props.loadingMore, [props.loadingMore]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const LISTE = useMemo(() => props.liste, [props.liste]);
  const PAGE = useMemo(() => props.page, [props.page]);
  const PAGES_COUNT = useMemo(() => props.pagesCount, [props.pagesCount]);

  const mainContainerRef = useRef();

  const rowRenderer = ({ index, style }) => {
    const item = LISTE[index];
    return (
      <div key={item.id} style={style}
        className="cursor-pointer"
      >
        {index !== 0 && <Divider />}
        <div className="grid items-center" style={{ height: 44, gridTemplateColumns: "150px 150px 150px 350px 150px 150px auto", gap: 10 }}>
          <span>{item.olusturma_zamani}</span>
          <span className="text-right">{item.tutar}</span>
          <span className="text-center">
            {item.result === true &&
              <IconCheck className="text-palette-text-secondary" />
            }
          </span>
          <span>{item.odeme_son_durum}</span>
          <span>{item.evrak}</span>
          <span>{item.odeme_tanim}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <div className={classes.layoutContainer}>
                <div className={classes.listHeader}>
                  <div className={classes.headerCol}>
                    <span>Oluşturma Zamanı</span>
                  </div>
                  <div className={classes.headerCol} style={{ justifyContent: "right" }}>
                    <span>Tutar</span>
                  </div>
                  <div className={classes.headerCol} style={{ justifyContent: "center" }}>
                    <span>Ödeme Gerçekleşti mi</span>
                  </div>
                  <div className={classes.headerCol} >
                    <span>Ödeme Akışındaki Son Mesaj</span>
                  </div>
                  <div className={classes.headerCol} >
                    <span>Evrak</span>
                  </div>
                  <div className={classes.headerCol}>
                    <span>Ödeme Tanım</span>
                  </div>
                  <div></div>
                </div>
                <main className="relative flex flex-grow flex-col overflow-hidden w-full" ref={mainContainerRef}>
                  <div className="h-full flex-grow">
                    <InfiniteLoader
                      minimumBatchSize={props.rowCount}
                      threshold={props.rowCount}
                      isRowLoaded={({ index }) => !!LISTE[index]}
                      loadMoreRows={({ startIndex, stopIndex }) => {
                        if (props.listeCount > 0 && PAGES_COUNT > PAGE && !LOADING_MORE) {
                          props.getMore(PAGE + 1, mainContainerRef);
                        }
                      }}
                      rowCount={props.listeCount > 0 ? props.listeCount : props.rowCount * 2}
                    >
                      {({ onRowsRendered, registerChild }) => (
                        <AutoSizer>
                          {({ width, height }) => (
                            <List
                              width={width}
                              height={height}
                              rowHeight={45}
                              rowCount={LISTE.length}
                              estimatedRowSize={PAGES_COUNT > 0 ? PAGES_COUNT * (45) : undefined}
                              rowRenderer={rowRenderer}
                              onRowsRendered={onRowsRendered}
                              ref={registerChild}
                            />
                          )}
                        </AutoSizer>
                      )}
                    </InfiniteLoader>

                  </div>
                </main>
              </div>
            </div>
          )}
        </>
      }
    </>
  );
});

export default CariDetailTab_OdemeLog;
