import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const defaultTheme = createTheme();

const theme = {
  overrides: {
    MuiDialog: {
      paper: {
        width: '320px',
      },
    },

    MuiDialogTitle: {
      root: {
        padding: defaultTheme.spacing(2, 3, 1, 3),
      },
    },

    MuiDialogContentText: {
      root: {
        marginBottom: undefined,
      },
    },

    MuiDialogActions: {
      root: {
        padding: defaultTheme.spacing(1, 3, 2, 3),
      },
    },

    MuiButton: {
      root: {
        '&:nth-child(2)': {
          marginLeft: defaultTheme.spacing(3),
        },
      },
    },
  },
};

const DialogConfirm = ({ title, message, cancelText, submitText, submittingText, isSubmitting, onCancel, onSubmit, cancelButtonProps, submitButtonProps }) => {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={true}
        onClose={!isSubmitting ? onCancel : undefined}
        //onEscapeKeyDown={!isSubmitting ? onCancel : undefined}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>

        <DialogActions disableSpacing>
          <Button
            onClick={!isSubmitting ? onCancel : undefined}
            disabled={isSubmitting}
            {...cancelButtonProps}
          >{cancelText}</Button>

          <Button
            disabled={isSubmitting}
            onClick={onSubmit}
            {...submitButtonProps}
          >{isSubmitting ? submittingText : submitText}</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

DialogConfirm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  submittingText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  cancelButtonProps: PropTypes.object,
  submitButtonProps: PropTypes.object,
};

DialogConfirm.defaultProps = {
  title: 'Devam Et',
  message: 'Devam etmek istiyor musunuz?',
  cancelText: 'Vazgeç',
  submitText: 'Devam Et',
  submittingText: 'Devam Ediliyor',
  isSubmitting: false,
  cancelButtonProps: {},
  submitButtonProps: {},
};

export default DialogConfirm;
