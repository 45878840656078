import React, { useCallback, useMemo, useState, useLayoutEffect, useRef } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';


import {
  TextField,
  Checkbox,
  Tooltip,
  Typography,
  Button,
  Dialog,
  DialogContent,
  IconButton
} from '@material-ui/core';

import {
  Search as IconSearch
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import ListSelectorSingle from '../../../components/ListSelectorSingle';
import CategorySelector from '../../../components/CategorySelector';
import ListSelectorTwoStep from '../../../components/ListSelectorTwoStep';
import MenuLinkGenerator from '../../../components/MenuLinkGenerator';
import { handleInputErrors, handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

import LayoutDetailVerticalTabSub from '../../base/LayoutDetailVerticalTabSub';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';

import ListPhotoSelector from '../../../components/ListPhotoSelector';
import FotoAddDialog from '../../../components/FotoAddDialog';

const styles = ({ palette, spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 0, 1, 0),
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    column: {
      display: 'flex',
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button2: {
      minWidth: 40,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& + &': {
        marginLeft: 10,
      },
    },
    addNew: {
      display: "flex",
      marginBottom: 10,
    },
    imageDiv: {
      width: 400,
      height: 400,
      display: "flex",
      borderRadius: 4,
      border: '1px solid #eacfea',
      padding: spacing(2, 2, 2, 2),
      marginTop: 10,
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      cursor: "pointer"
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${palette.primary.main}`
      },
    }, rowtitle: {
      minWidth: 100,
      maxWidth: 100,
    }
  };
};

const MenuDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const LOADING_ICERIK = useMemo(() => props.loadingIcerik, [props.loadingIcerik]);
  const HATA_ICERIK = useMemo(() => props.hataIcerik, [props.hataIcerik]);
  const HATADIL = useMemo(() => props.hataDil, [props.hataDil]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(props.defaultSelectedTabIndex);
  const COUNT_TABS = useMemo(() => props?.forms?.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);
  const TREE_LIST = useMemo(() => props.menuTree ?? [], [props.menuTree]);

  const [openSelectorKategori, setOpenSelectorKategori] = useState(false);
  const [openSelectorOzellik, setOpenSelectorOzellik] = useState(false);
  const [openSelectorSabitSayfa, setOpenSelectorSabitSayfa] = useState(false);
  const [openSelectorOzellikDeger, setOpenSelectorOzellikDeger] = useState(false);
  const [detayListOpen, setDetayListOpen] = useState(true);
  const [openSelectorLink, setOpenSelectorLink] = useState(false);

  const [tabsDil, setTabsDil] = useState([]);
  const [selectedTabIndexDil, setSelectedTabIndexDil] = useState(props.selectedTabIndexDil);

  const [openSelectorResim, setOpenSelectorResim] = useState(false);
  const [uploadImageFile, setUploadImageFile] = useState('');
  const [uploadImageTypeAndName, setUploadImageTypeAndName] = useState(['', '']);
  const [openSelector, setOpenSelector] = useState(false);

  const COUNT_TABS_DİL = useMemo(() => props?.dilform?.length ?? 0, [props]);
  const FORM_DİL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.form ?? null, [props, selectedTabIndex, selectedTabIndexDil]);
  const ERROR_DIL = useMemo(() => props?.dilform?.[selectedTabIndex]?.[selectedTabIndexDil]?.errors ?? null, [props, selectedTabIndex, selectedTabIndexDil]);

  const inputRef = useRef();

  useLayoutEffect(() => {
    if (props.dilform?.length > 0) {
      const copyForms = [...props.dilform];
      const newTabs = copyForms[selectedTabIndex]?.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabsDil(newTabs);
    } else {
      setTabsDil([]);
    }
  }, [props.dilform, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.menuAdd) {
      setSelectedTabIndex(props.defaultSelectedTabIndex);
    } else {
      setSelectedTabIndex(null);
    }
  }, [LOADING, props.menuAdd, props.defaultSelectedTabIndex]);

  const createTextFieldPropsIcerik = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const createTextFieldPropsDil = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM_DİL, `${name}`) || defaultValue,
      error: _has(ERROR_DIL, `${name}`),
      fullWidth: true,
    };
  }, [FORM_DİL, ERROR_DIL]);

  const handleChangeForm = (property, value) => {
    props.onChangeFormIcerik(selectedTabIndex, property, value);
  };

  const handleChangeFormDil = (property, value) => {
    props.onChangeDilForm(selectedTabIndex, selectedTabIndexDil, property, value);
  };

  const handleDetailDelete = (index) => {
    const deleteItem = props.forms[index];
    if (deleteItem.form.id) {
      props.openDeleteForm(true);
      props.setDeleteId(deleteItem.form.id);
    } else {
      props.deleteNewAdd(deleteItem.form.index);
      setSelectedTabIndex(null);
    }
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrorsIcerik(errors, selectedTabIndex);
  }

  const handleSetErrorsDil = (errors) => {
    props.onCheckErrorsDil(errors, selectedTabIndex, selectedTabIndexDil);
  }

  const resimEkle = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const filetype = file.name.split('.').pop();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setUploadImageFile(reader.result);
        setUploadImageTypeAndName([filetype, file.name]);
      }
      setOpenSelectorResim(true);
    }
  }

  const CloseSelectorResim = () => {
    setUploadImageFile('');
    setOpenSelectorResim(false);
  }


  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const handleDetailDeleteDil = (index) => {
    props.deleteDetailDilWithIndex(selectedTabIndex, index);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Menü Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim')}
                          onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                          onBlur={() => handleInputErrors('menu', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Menü Tanım', 'menü')}
                          inputProps={{
                            maxLength: 255,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                      ></Checkbox>
                    </li>
                  </ul>
                </div>
                <div className={classes.layoutContainerTab}>
                  <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">İÇERİKLER</span></div>
                  {!LOADING_ICERIK && (
                    <>
                      {HATA_ICERIK && (
                        <Hata />
                      )}
                      {!HATA_ICERIK && (
                        <LayoutDetailVerticalTabSub
                          treeList={TREE_LIST}
                          selectedTabIndex={selectedTabIndex}
                          onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                          addNew={props.onAddNewTab}
                          buttonText={"YENİ İÇERİK EKLE"}
                          delete={handleDetailDelete}
                          loading={LOADING}
                          toggle={props.toggle}
                          isActive={props.isActive}
                          flexDirection='column'
                        >
                          {COUNT_TABS === 0 && (
                            <div className="flex flex-col items-start justify-start p-4 space-y-4">
                              <Typography variant="body1">
                                Menüye ait içerik bulunamadı.
                              </Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                disableElevation
                                onClick={() => props.onAddNewTab(null)}
                              >YENİ İÇERİK EKLE</Button>
                            </div>
                          )}
                          {COUNT_TABS > 0 &&
                            <>
                              {selectedTabIndex !== null && (
                                <div className={classes.container}>
                                  <div className={classes.layoutContainer}>
                                    <div className={classes.column}>
                                      <ul className={classes.layoutFormList}>

                                        <li className={classes.layoutFormItem} id="tanim">
                                          <label className={classes.layoutFormItemLabel} htmlFor="tanim">İçerik Tanım *</label>
                                          <Tooltip title={ERROR?.tanim ? ERROR.tanim[0] : _isNil(FORM?.tanim) ? '' : FORM.tanim}>
                                            <TextField
                                              {...createTextFieldPropsIcerik('tanim')}
                                              onChange={(e) => handleChangeForm('tanim', e.target.value)}
                                              onBlur={() => handleInputErrors2(FORM, 'tanim', handleSetErrors)}
                                              inputProps={{
                                                maxLength: 250,
                                              }}
                                            ></TextField>
                                          </Tooltip>
                                        </li>

                                        <li className={classes.layoutFormItem} id="ozellik_baslik">
                                          <label className={classes.layoutFormItemLabel} htmlFor="ozellik_baslik">Özellik Başlık</label>
                                          <Tooltip title={ERROR?.ozellik_baslik ? ERROR.ozellik_baslik[0] : _isNil(FORM?.ozellik_baslik?.tanim) ? '' : FORM?.ozellik_baslik?.tanim}>
                                            <TextField
                                              {...createTextFieldPropsIcerik('ozellik_baslik.tanim')}
                                              InputProps={{
                                                endAdornment:
                                                  <IconButton
                                                    size="small"
                                                    className={classes.button}
                                                    disableRipple
                                                    onClick={() => setOpenSelectorOzellik(true)}
                                                  ><IconSearch /></IconButton>
                                              }}
                                            ></TextField>
                                          </Tooltip>
                                        </li>

                                        <li className={classes.layoutFormItem} id="ozellik_deger">
                                          <label className={classes.layoutFormItemLabel} htmlFor="ozellik_deger">Özellik Değer</label>
                                          <Tooltip title={ERROR?.ozellik_deger ? ERROR.ozellik_deger[0] : _isNil(FORM?.ozellik_deger?.tanim) ? '' : FORM?.ozellik_deger?.tanim}>
                                            <TextField
                                              {...createTextFieldPropsIcerik('ozellik_deger.tanim')}
                                              InputProps={{
                                                endAdornment:
                                                  <IconButton
                                                    size="small"
                                                    className={classes.button}
                                                    disableRipple
                                                    onClick={() => setOpenSelectorOzellikDeger(true)}
                                                  ><IconSearch /></IconButton>
                                              }}
                                            ></TextField>
                                          </Tooltip>
                                        </li>

                                        <li className={classes.layoutFormItem} id="sabit_sayfa">
                                          <label className={classes.layoutFormItemLabel} htmlFor="sabit_sayfa">Sabit Sayfa</label>
                                          <Tooltip title={ERROR?.sabit_sayfa ? ERROR.sabit_sayfa[0] : _isNil(FORM?.sabit_sayfa?.tanim) ? '' : FORM?.sabit_sayfa?.tanim}>
                                            <TextField
                                              {...createTextFieldPropsIcerik('sabit_sayfa.tanim')}
                                              InputProps={{
                                                endAdornment:
                                                  <IconButton
                                                    size="small"
                                                    className={classes.button}
                                                    disableRipple
                                                    onClick={() => setOpenSelectorSabitSayfa(true)}
                                                  ><IconSearch /></IconButton>
                                              }}
                                            ></TextField>
                                          </Tooltip>
                                        </li>

                                        <li className={classes.layoutFormItem} id="kategori">
                                          <label className={classes.layoutFormItemLabel} htmlFor="kategori">Kategori</label>
                                          <Tooltip title={ERROR?.kategori ? ERROR.kategori[0] : _isNil(FORM?.kategori?.parents) ? '' : FORM?.kategori?.parents}>
                                            <TextField
                                              {...createTextFieldPropsIcerik('kategori.parents')}
                                              InputProps={{
                                                endAdornment:
                                                  <IconButton
                                                    size="small"
                                                    className={classes.button}
                                                    disableRipple
                                                    onClick={() => setOpenSelectorKategori(true)}
                                                  ><IconSearch /></IconButton>
                                              }}
                                            ></TextField>
                                          </Tooltip>
                                        </li>

                                        <li className={classes.layoutFormItem} id="ozel_link_icerik">
                                          <label className={classes.layoutFormItemLabel} htmlFor="ozel_link_icerik">Link Oluştur</label>
                                          <Button
                                            onClick={() => setOpenSelectorLink(true)}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disableRipple
                                          >{FORM.ozel_link_icerik.length > 0 ? 'LİNKİ DEĞİŞTİR' : 'LİNK OLUŞTUR'}</Button>
                                        </li>

                                        {FORM.ozel_link_icerik.length > 0 && (
                                          <li className={classes.layoutFormItem} id="ozel_link_icerik_liste">
                                            <label className={classes.layoutFormItemLabel} htmlFor="ozel_link_icerik_liste">Link İçerik</label>
                                            <div>
                                              {FORM.ozel_link_icerik.map((item, index) => (
                                                <div key={`ozel_link_icerik_liste${index}`} className={classes.row}>
                                                  {item.model === "0" && (
                                                    <>
                                                      <div className={classes.rowtitle}>Gruplar</div>
                                                      <div>{item.icerik.icerik_idler.map((x) => x.tanim).join(", ")}</div>
                                                    </>
                                                  )}
                                                  {item.model === "1" && (
                                                    <>
                                                      <div className={classes.rowtitle}>Özellikler</div>
                                                      <div>{item.icerik.icerik_deger.baslik.tanim + " : " + item.icerik.icerik_deger.degerler.map((x) => x.tanim).join(", ")}</div>
                                                    </>
                                                  )}
                                                </div>
                                              ))}
                                            </div>
                                          </li>
                                        )}

                                        <li className={classes.layoutFormItem} id="url">
                                          <label className={classes.layoutFormItemLabel} htmlFor="url">Harici Link</label>
                                          <Tooltip title={ERROR?.url ? ERROR.url[0] : _isNil(FORM?.url) ? '' : FORM.url}>
                                            <TextField
                                              {...createTextFieldPropsIcerik('url')}
                                              onChange={(e) => handleChangeForm('url', e.target.value)}
                                              onBlur={() => handleInputErrors2(FORM, 'url', handleSetErrors)}
                                            ></TextField>
                                          </Tooltip>
                                        </li>

                                        <li className={classes.layoutFormItem} id="siralama">
                                          <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama *</label>
                                          <Tooltip title={ERROR?.siralama ? ERROR.siralama[0] : _isNil(FORM?.siralama) ? '' : FORM.siralama}>
                                            <TextField
                                              {...createTextFieldPropsIcerik('siralama')}
                                              type="number"
                                              inputProps={{
                                                min: -2147483648,
                                                max: 2147483647,
                                                step: 1,
                                                style: { textAlign: 'right' }
                                              }}
                                              onChange={(e) => handleChangeForm('siralama', e.target.value)}
                                              onBlur={() => handleInputErrors2(FORM, 'siralama', handleSetErrors)}
                                            ></TextField>
                                          </Tooltip>
                                        </li>
                                        <li className={classes.layoutFormItem} id="aktif">
                                          <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                                          <Checkbox
                                            name="aktif"
                                            color="primary"
                                            checked={_result(FORM, 'aktif', false)}
                                            onChange={(e, checked) => handleChangeForm('aktif', checked)}
                                          ></Checkbox>
                                        </li>
                                      </ul>
                                      <ul className={classes.layoutFormList}>
                                        <div className={classes.addNew}>
                                          <Button
                                            className={classes.button2}
                                            onClick={() => setOpenSelector(true)}
                                          >GALERİDEN EKLE</Button>
                                          <Button
                                            className={classes.button2}
                                            component="label"
                                          >
                                            YENİ EKLE
                                            <input
                                              type="file"
                                              hidden
                                              accept=".jpeg,.jpg,.png"
                                              ref={inputRef}
                                              onClick={() => inputRef.current.value = ""}
                                              onChange={(e) => resimEkle(e)}
                                            />
                                          </Button>
                                        </div>
                                        <div className={classes.imageDiv}>
                                          {FORM?.foto && (
                                            <img src={FORM.foto.image} alt={FORM.foto.tanim} className={classes.img} />
                                          )}
                                        </div>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className={classes.layoutContainerTab}>
                                    <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                                    <>
                                      {HATADIL && (
                                        <Hata />
                                      )}
                                      {!HATADIL && (
                                        <LayoutDetailVerticalTab
                                          tabs={tabsDil}
                                          selectedTabIndex={selectedTabIndexDil}
                                          onChangeTabIndex={(index) => setSelectedTabIndexDil(index)}
                                          buttonShow={false}
                                          delete={handleDetailDeleteDil}
                                          deleteButtonShow={true}
                                        >
                                          {COUNT_TABS_DİL === 0 && (
                                            <div className="flex flex-col items-start justify-start p-4 space-y-4">
                                              <Typography variant="body1">
                                                Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                                            </div>
                                          )}
                                          {COUNT_TABS_DİL > 0 && (
                                            <div className={classes.layoutContainer}>
                                              <ul className={classes.layoutFormList}>
                                                <li className={classes.layoutFormItem} id="tanim">
                                                  <label className={classes.layoutFormItemLabel} htmlFor="tanim">İçerik Tanım</label>
                                                  <Tooltip title={ERROR_DIL?.tanim ? ERROR_DIL.tanim[0] : FORM_DİL?.tanim == null ? '' : FORM_DİL.tanim}>
                                                    <TextField
                                                      {...createTextFieldPropsDil('tanim')}
                                                      onChange={(e) => handleChangeFormDil('tanim', e.target.value)}
                                                      onBlur={() => handleInputErrors2(FORM_DİL, 'tanim', handleSetErrorsDil)}
                                                    ></TextField>
                                                  </Tooltip>
                                                </li>
                                              </ul>
                                            </div>
                                          )
                                          }
                                        </LayoutDetailVerticalTab>
                                      )}
                                    </>
                                  </div>
                                </div>
                              )}
                            </>
                          }
                        </LayoutDetailVerticalTabSub>
                      )}
                      {openSelectorKategori &&
                        <Dialog
                          open={openSelectorKategori}
                          PaperProps={{
                            style: {
                              width: '480px',
                              height: '600px',
                            },
                          }}
                          onClose={() => setOpenSelectorKategori(false)}
                        >
                          <DialogContent style={{ padding: 0 }}>
                            <CategorySelector
                              selected={FORM.kategori}
                              onChange={(items) => handleChangeForm('kategori', items)}
                              onClose={() => setOpenSelectorKategori(false)}
                              single={true}
                              parentselect={false}
                            />
                          </DialogContent>
                        </Dialog>
                      }
                      {openSelectorOzellik &&
                        <Dialog
                          open={openSelectorOzellik}
                          PaperProps={{
                            style: {
                              width: '480px',
                              height: '600px',
                            },
                          }}
                          onClose={() => setOpenSelectorOzellik(false)}
                        >
                          <DialogContent style={{ padding: 0 }}>
                            <ListSelectorSingle
                              title='Özellikler'
                              listPath='ozellikbaslik/mini_list?aktif=true'
                              listCountPath='ozellikbaslik/count?aktif=true'
                              keyProp='id'
                              valueProp='tanim'
                              defaultItem={FORM.ozellik_baslik}
                              onChangeItems={(items) => handleChangeForm('ozellik_baslik', items)}
                              onClose={() => setOpenSelectorOzellik(false)}
                              newPath='tanimlar/ozellikbaslik'
                            />
                          </DialogContent>
                        </Dialog>
                      }

                      {openSelectorSabitSayfa &&
                        <Dialog
                          open={openSelectorSabitSayfa}
                          PaperProps={{
                            style: {
                              width: '480px',
                              height: '600px',
                            },
                          }}
                          onClose={() => setOpenSelectorSabitSayfa(false)}
                        >
                          <DialogContent style={{ padding: 0 }}>
                            <ListSelectorSingle
                              title='Sayfalar'
                              listPath='sabitsayfa/mini_list?aktif=true'
                              listCountPath='sabitsayfa/count?aktif=true'
                              keyProp='id'
                              valueProp='tanim'
                              defaultItem={FORM.sabit_sayfa}
                              onChangeItems={(items) => handleChangeForm('sabit_sayfa', items)}
                              onClose={() => setOpenSelectorSabitSayfa(false)}
                              newPath='tanimlar/sabit_sayfa'
                            />
                          </DialogContent>
                        </Dialog>
                      }

                      {openSelectorOzellikDeger &&
                        <Dialog
                          open={openSelectorOzellikDeger}
                          PaperProps={{
                            style: {
                              background: 'transparent',
                              height: 600,
                              width: detayListOpen ? '100%' : 480
                            },
                          }}
                          fullWidth
                          maxWidth={detayListOpen ? 'md' : 'sm'}
                          onClose={() => setOpenSelectorOzellikDeger(false)}
                        >
                          <DialogContent style={{ padding: 0 }}>
                            <ListSelectorTwoStep
                              title="Özellikler"
                              mainItemsPath='ozellikbaslik'
                              mainItemsCountPath='ozellikbaslik/count'
                              mainDefaultItems={FORM.ozellik_deger_baslik}
                              mainMultiple={false}
                              mainKeyProp='id'
                              mainValueProp='tanim'
                              listPath='ozellikdeger'
                              listCountPath='ozellikdeger/count'
                              listMultiple={false}
                              keyProp='id'
                              valueProp='tanim'
                              keyUId='baslik_id'
                              valueUV='baslik_tanim'
                              defaultItems={FORM.ozellik_deger}
                              onChangeItems={(items) => handleChangeForm('ozellik_deger', items)}
                              onClose={() => {
                                setOpenSelectorOzellikDeger(false);
                              }}
                              onDetayListClose={(res) => setDetayListOpen(res)}
                              detayListOpen={detayListOpen}
                              detayList={FORM.ozellik_tree_list}
                              url="tanimlar/ozellikdetay"
                            />
                          </DialogContent>
                        </Dialog>
                      }
                      {openSelectorResim &&
                        <Dialog
                          open={openSelectorResim}
                          PaperProps={{
                            style: {
                              width: '600px',
                              height: '600px',
                            },
                          }}
                          onClose={() => setOpenSelectorResim(false)}
                        >
                          <DialogContent style={{ padding: 0 }}>
                            <FotoAddDialog
                              image={uploadImageFile}
                              onChange={(res) => handleChangeForm('foto', res)}
                              close={CloseSelectorResim}
                              fototip={5}
                              type={uploadImageTypeAndName[0]}
                              name={uploadImageTypeAndName[1]}
                            />
                          </DialogContent>
                        </Dialog>
                      }
                      {openSelectorLink &&
                        <Dialog
                          open={openSelectorLink}
                          PaperProps={{
                            style: {
                              width: '480px',
                              height: '600px',
                            },
                          }}
                          onClose={() => setOpenSelectorLink(false)}
                        >
                          <DialogContent style={{ padding: 0 }}>
                            <MenuLinkGenerator
                              defaultLinkler={FORM.ozel_link_icerik}
                              confirm={(items) => handleChangeForm('ozel_link_icerik', items)}
                              onClose={() => setOpenSelectorLink(false)}
                            />
                          </DialogContent>
                        </Dialog>
                      }


                      {openSelector &&
                        <Dialog
                          open={openSelector}
                          PaperProps={{
                            style: {
                              width: '600px',
                              height: '600px',
                            },
                          }}
                          onClose={() => setOpenSelector(false)}
                        >
                          <DialogContent style={{ padding: 0 }}>
                            <ListPhotoSelector
                              defaultItems={FORM?.foto}
                              onClose={() => setOpenSelector(false)}
                              onChangeItems={(items) => handleChangeForm('foto', items)}
                              single={true}
                            />
                          </DialogContent>
                        </Dialog>
                      }
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      }
    </>
  );
});

export default MenuDetailTabGenel;
