import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import {
  Close as IconClose,
  Check as IconCheck
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 0, 1, 0),
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    keyDivContainer: {
      display: 'flex',
      maxWidth: 'fit-content'
    },
    keyDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 10,
      flexWrap: "wrap"
    },
    keyCloud: {
      padding: 4,
      border: "1px solid #eacfea",
      position: "relative",
      borderRadius: 4,
    },
    button3: {
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    }
  };
};

const AlisOnSiparisOnayDetailTab_Eposta = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [searchKey, setSearchKey] = useState("");
  const [searchKeyError, setSearchKeyError] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form.evrak_durum, `${name}`) || defaultValue,
      error: _has(props.error?.evrak?.evrak_durum, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const handleAddOrRemoveSearchKeys = (type, value) => {
    let list = [...props.form.evrak_durum.alici_mailleri];
    const val = value.trim();
    if (type === "add") {
      if (!list.includes(val) && val) {
        list.push(val);
        setSearchKey("");
        setSearchKeyError(null);
      } else {
        const errors = val ? "Aynı kelime birden fazla kullanılamaz" : null;
        setSearchKeyError(errors);
      }
    } else {
      if(props.form.evrak_durum.kullaniciya_mail_gondersin && value === props.form.evrak_kullanici.email){
        enqueueSnackbar("Evrak kullanıcısını silemezsiniz!", { variant: 'error' });
      }else{
        list = list.filter(k => k !== val);
      }
    }
    props.onChangeForm("alici_mailleri", list);
  }

  const handleErrors = (errors) => {
    props.onCheckErrors("evrak", errors, null, null, "evrak_durum", null, null)
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              {props.form.evrak_durum !== null &&
                <>
                  <div className={classes.layoutContainer}>
                    <ul className={classes.layoutFormList}>
                      <li className={classes.layoutFormItem} id="mail_sablon">
                        <label className={classes.layoutFormItemLabel} htmlFor="mail_sablon">Eposta Şablon *</label>
                        <Tooltip title={props.error?.evrak?.evrak_durum?.mail_sablon ? props.error.evrak.evrak_durum.mail_sablon[0] : (props.form?.evrak_durum?.mail_sablon === "-1" || _isNil(props.form?.evrak_durum?.mail_sablon)) ? '' : props.form.evrak_durum.mail_sablonlar_dic[props.form.evrak_durum.mail_sablon]}>
                          <TextField
                            {...createTextFieldProps('mail_sablon')}
                            onChange={(e) => props.onChangeForm('mail_sablon', e.target.value)}
                            select
                            SelectProps={{
                              native: true,
                            }}
                            onBlur={() => handleInputErrors('', props.form, props.form, (errors) => handleErrors(errors), false, 'evrak_durum', true, false, false, '', '', '', 'mail_sablon')}
                          >
                            <option disabled value={'-1'}>Seçilmedi</option>
                            {props?.form?.evrak_durum?.mail_sablonlar?.map((kod) => (
                              <option key={kod.id} value={kod.id}>{kod.tanim}</option>
                            ))}
                          </TextField>
                        </Tooltip>
                      </li>
                      <li className={classes.layoutFormItem} id="mail_sablon_site">
                        <label className={classes.layoutFormItemLabel} htmlFor="mail_sablon_site">Site Eposta Şablon</label>
                        <Tooltip title={props.error?.evrak?.evrak_durum?.mail_sablon_site ? props.error.evrak.evrak_durum.mail_sablon_site[0] : (props.form?.evrak_durum?.mail_sablon_site === "-1" || _isNil(props.form?.evrak_durum?.mail_sablon_site)) ? '' : props.form.evrak_durum.mail_sablonlar_site_dic[props.form.evrak_durum.mail_sablon_site]}>
                          <TextField
                            {...createTextFieldProps('mail_sablon_site')}
                            onChange={(e) => props.onChangeForm('mail_sablon_site', e.target.value)}
                            select
                            SelectProps={{
                              native: true,
                            }}
                            onBlur={() => handleInputErrors('', props.form, props.form, (errors) => handleErrors(errors), false, 'evrak_durum', true, false, false, '', '', '', 'mail_sablon_site')}
                          >
                            <option value={'-1'}>Seçilmedi</option>
                            {props?.form?.evrak_durum?.mail_sablonlar_site?.map((kod) => (
                              <option key={kod.id} value={kod.id}>{kod.tanim}</option>
                            ))}
                          </TextField>
                        </Tooltip>
                      </li>
                      <li className={classes.layoutFormItem} id="pdf_sablon">
                        <label className={classes.layoutFormItemLabel} htmlFor="pdf_sablon">Pdf Şablon</label>
                        <Tooltip title={props.error?.evrak?.evrak_durum?.pdf_sablon ? props.error.evrak.evrak_durum.pdf_sablon[0] : (props.form?.evrak_durum?.pdf_sablon === "-1" || _isNil(props.form?.evrak_durum?.pdf_sablon)) ? '' : props.form.evrak_durum.pdf_sablonlar_dic[props.form.evrak_durum.pdf_sablon]}>
                          <TextField
                            {...createTextFieldProps('pdf_sablon')}
                            onChange={(e) => props.onChangeForm('pdf_sablon', e.target.value)}
                            select
                            SelectProps={{
                              native: true,
                            }}
                          >
                            <option value={'-1'}>Seçilmedi</option>
                            {props?.form?.evrak_durum?.pdf_sablonlar?.map((kod) => (
                              <option key={kod.id} value={kod.id}>{kod.tanim}</option>
                            ))}
                          </TextField>
                        </Tooltip>
                      </li>
                    </ul>
                  </div>
                  <div className={classes.layoutContainerTab}>
                    <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">ALICI EPOSTALARI</span></div>
                    <div className={classes.layoutContainer}>
                      <div className='flex flex-col gap-5 w-full'>
                        <div className={classes.layoutFormList}>
                          <div className={classes.layoutFormItem} id="alici_mailleri_add">
                            <label className={classes.layoutFormItemLabel} htmlFor="alici_mailleri_add">Alıcı Epostası Ekle</label>
                            <Tooltip title={searchKeyError ? searchKeyError : searchKey == null ? '' : searchKey}>
                              <TextField
                                name='alici_mailleri_add'
                                variant='outlined'
                                size='small'
                                value={searchKey}
                                fullWidth={true}
                                onChange={(e) => setSearchKey(e.target.value)}
                                error={searchKeyError ? true : false}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    handleAddOrRemoveSearchKeys("add", searchKey)
                                  }
                                }}
                                InputProps={{
                                  endAdornment:
                                    <Tooltip title="Ekle">
                                      <IconButton
                                        size="small"
                                        disableRipple
                                        className={classes.button}
                                        onClick={() => handleAddOrRemoveSearchKeys("add", searchKey)}
                                      >
                                        <IconCheck />
                                      </IconButton>
                                    </Tooltip>
                                }}
                                inputProps={{ maxLength: 50 }}
                              ></TextField>
                            </Tooltip>
                          </div>
                        </div>
                        <div className={classes.keyDivContainer}>
                          <div className={classes.keyDiv}>
                            {props?.form?.evrak_durum?.alici_mailleri && props.form.evrak_durum.alici_mailleri.map((key, index) => (
                              <div className={classes.keyCloud} key={index}>
                                <span>{key}</span>
                                <Tooltip title="Sil">
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    className={classes.button3}
                                    onClick={() => handleAddOrRemoveSearchKeys("delete", key)}
                                  >
                                    <IconClose />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          )}
        </>
      }
    </>
  );
});

export default AlisOnSiparisOnayDetailTab_Eposta;
