import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import moment from 'moment';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  groupBy as _groupBy,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './KampanyaDetailTab_Genel';
import TabKosul from './KampanyaDetailTab_Kosul';
import TabHareket from './KampanyaDetailTab_Hareket';
import TabSepet from './KampanyaDetailTab_Sepet';

const PATH = 'kampanya';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'KOŞUL GRUPLARI', disabled: false, hasError: false },
  { label: 'HAREKET', disabled: false, hasError: false },
  { label: 'SEPET', disabled: false, hasError: false, display: true },
];

const FORM_DEFAULT = {
  tanim: '',
  aktif: true,
  siralama: '0',
  baslangic_tarihi: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
  bitis_tarihi: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
  magazalar: [],
  hangi_gunler: [1, 2, 3, 4, 5, 6, 7],
  hareket_tip: "0"
};

const KAMPANYA_KOSUL_GRUP_DEFAULT = {
  operator: '0',
  t_or_f: "true",
  kosullar: [{
    form: {
      gruplar: [],
      kullanici_gruplari: [],
      kategoriler: [],
      urunler: [],
      ozellik_degerler: [],
      ozellik_basliklar: [],
      ozellik_tree_list: [],
      kampanya_kosul_grup: null
    },
    errors: null
  }]
}

const KAMPANYA_KOSUL_DEFAULT = {
  form: {
    gruplar: [],
    kullanici_gruplari: [],
    kategoriler: [],
    urunler: [],
    ozellik_degerler: [],
    ozellik_basliklar: [],
    ozellik_tree_list: [],
    kampanya_kosul_grup: null
  },
  errors: null
};

const SEPET_HAREKET_DEFAULT = {
  form: {
    hareket:0,
    hareket_info: {
      indirim: "0"
    }
  },
  errors: null
}

const SEPET_KOSUL_DEFAULT = {
  form: {
    kosul_info: {
      toplam: "0"
    }
  },
  errors: null
}

const createOzellikListeler = (list) => {
  const ozellik_basliklar = [];
  const ozellik_tree_list = [];
  const ozellik_group = _groupBy(list, item => item.ozellik_baslik_tanim);
  if (Object.keys(ozellik_group).length > 0) {
    Object.keys(ozellik_group).forEach((o, i) => {
      ozellik_tree_list.push({
        title: o,
        list: Object.values(ozellik_group)[i].map((l) => ({
          key: l.id,
          value: l.tanim
        }))
      })
    })
  }
  list.forEach((l) => {
    if (ozellik_basliklar.some(p => p.id === l.odeme_tanim_id) === false) {
      ozellik_basliklar.push({ id: l.odeme_tanim_id, tanim: l.ozellik_baslik_tanim })
    }
  });
  return [ozellik_basliklar, ozellik_tree_list];
}

const createKosulGrupListeler = async (id, kosulgrup) => {
  const states = [];
  for (const grup of kosulgrup) {
    const res = (await django(`kampanya/${id}/kampanyakosul?kampanya_kosul_grup=${grup.id}`)).data;
    const forms = [];
    res.forEach((kosul) => {
      const res2 = createOzellikListeler(kosul.ozellik_degerler);
      const form = {
        form: {
          id: kosul.id,
          tanim: kosul.tanim,
          gruplar: kosul.gruplar,
          kullanici_gruplari: kosul.kullanici_gruplari,
          kategoriler: kosul.kategoriler,
          urunler: kosul.urunler,
          ozellik_degerler: kosul.ozellik_degerler,
          ozellik_basliklar: res2[0],
          ozellik_tree_list: res2[1],
        },
        errors: null
      }
      forms.push(form);
    });
    const data = {
      id: grup.id,
      operator: grup.operator,
      t_or_f: String(grup.t_or_f),
      kosullar: forms
    }
    states.push(data);
  }
  return states;
}

const KampanyaDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingKosul, setLoadingKosul] = useState(true);
  const [loadingKosul2, setLoadingKosul2] = useState(true);
  const [loadingHareket, setLoadingHareket] = useState(true);
  const [loadingSepetKosul, setLoadingSepetKosul] = useState(true);
  const [loadingSepetHareket, setLoadingSepetHareket] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataKosul, setHataKosul] = useState(null);
  const [hataHareket, setHataHareket] = useState(null);
  const [hataSepetKosul, setHataSepetKosul] = useState(null);
  const [hataSepetHareket, setHataSepetHareket] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [grup, setGrup] = useState([]);
  const [kosulGrup, setKosulGrup] = useState([]);
  const [kosulGrupDefault, setKosulGrupDefault] = useState([]);

  const [hareket, setHareket] = useState([]);
  const [hareketStr, setHareketStr] = useState("");
  const [hareketStrDefault, setHareketStrDefault] = useState("");
  const [hareketError, setHareketError] = useState([]);

  const [sepetKosul, setSepetKosul] = useState([]);
  const [sepetKosulDefault, setSepetKosulDefault] = useState([]);
  const [sepetHareket, setSepetHareket] = useState([]);
  const [sepetHareketDefault, setSepetHareketDefault] = useState([]);

  const [haftaGunler, setHaftaGunler] = useState([]);
  const [kampanyaTip, setKampanyaTip] = useState([]);
  const [kampanyaTipDic, setsetKampanyaTipDic] = useState({});

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteIdGrup, setDeleteIdDetailGrup] = useState(null);
  const [showDeleteDialogDetailGrup, setShowDeleteDialogDetailGrup] = useState(false);
  const [isDeletingDetailGrup, setIsDeletingDetailGrup] = useState(false);
  const [isDeletingErrorMessageDetailGrup, setIsDeletingErrorMessageDetailGrup] = useState(null);

  const [detailDeleteIdKosul, setDeleteIdDetailKosul] = useState(null);
  const [showDeleteDialogDetailKosul, setShowDeleteDialogDetailKosul] = useState(false);
  const [isDeletingDetailKosul, setIsDeletingDetailKosul] = useState(false);
  const [isDeletingErrorMessageDetailKosul, setIsDeletingErrorMessageDetailKosul] = useState(null);

  const [detailDeleteIdKosulSepet, setDeleteIdDetailKosulSepet] = useState(null);
  const [showDeleteDialogDetailKosulSepet, setShowDeleteDialogDetailKosulSepet] = useState(false);
  const [isDeletingDetailKosulSepet, setIsDeletingDetailKosulSepet] = useState(false);
  const [isDeletingErrorMessageDetailKosulSepet, setIsDeletingErrorMessageDetailKosulSepet] = useState(null);

  const [detailDeleteIdHareketSepet, setDeleteIdDetailHareketSepet] = useState(null);
  const [showDeleteDialogDetailHareketSepet, setShowDeleteDialogDetailHareketSepet] = useState(false);
  const [isDeletingDetailHareketSepet, setIsDeletingDetailHareketSepet] = useState(false);
  const [isDeletingErrorMessageDetailHareketSepet, setIsDeletingErrorMessageDetailHareketSepet] = useState(null);

  const [type, setType] = useState(props.eventType ?? 0);
  const [loadDone, setLoadDone] = useState(false);
  const [loadTab1, setLoadTab1] = useState(false);
  const [loadTab2, setLoadTab2] = useState(false);
  const [loadTab3, setLoadTab3] = useState(false);
  const [loadTab4, setLoadTab4] = useState(false);
  const [loadTab5, setLoadTab5] = useState(false);
  const [equal1, setEqual1] = useState(true);
  const [equal2, setEqual2] = useState(true);
  const [equal3, setEqual3] = useState(true);
  const [equal4, setEqual4] = useState(true);
  const [equal5, setEqual5] = useState(true);
  const [saveErrors, setSaveErrors] = useState([[], [], [], [], []]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props.id]);
  const IS_ADD = useMemo(() => props.addnew, [props.addnew]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(kosulGrup, []) && _isEqual(hareketStr, "") && _isEqual(sepetKosul, []) && _isEqual(sepetHareket, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualKosul = _isEqual(kosulGrup, kosulGrupDefault);
    const isEqualHareket = _isEqual(hareketStr, hareketStrDefault);
    const isEqualHareketSepet = _isEqual(sepetHareket, sepetHareketDefault);
    const isEqualKosulSepet = _isEqual(sepetKosul, sepetKosulDefault);
    return isEqualGenel && isEqualKosul && isEqualHareket && isEqualHareketSepet && isEqualKosulSepet;
  }, [add, genelForm, genelFormDefault, kosulGrup, kosulGrupDefault, hareketStr, hareketStrDefault, sepetHareket, sepetHareketDefault, sepetKosul, sepetKosulDefault]);

  const LOADINGKOSUL = useMemo(() => {
    if (!loadingKosul && !loadingKosul2) {
      return false;
    }
    return true;
  }, [loadingKosul, loadingKosul2]);

  const LOADINGSEPET = useMemo(() => {
    if (!loadingSepetHareket && !loadingSepetKosul) {
      return false;
    }
    return true;
  }, [loadingSepetHareket, loadingSepetKosul]);

  const HATASEPET = useMemo(() => {
    if (!hataSepetKosul && !hataSepetHareket) {
      return null;
    }
    return true;
  }, [hataSepetKosul, hataSepetHareket]);

  useLayoutEffect(() => {
    setAdd(false);
    setLoadDone(false);
  }, [ID]);

  useLayoutEffect(() => {
    if (loadDone) {
      setType(2);
    } else {
      setType(props.eventType ?? 0);
    }
  }, [props.eventType, loadDone]);

  useLayoutEffect(() => {
    if (type === 0) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setKosulGrup([]);
      setKosulGrupDefault([]);
      setHareket([]);
      setHareketStr("");
      setHareketStrDefault("");
      setSepetHareket([]);
      setSepetHareketDefault([]);
      setSepetKosul([]);
      setSepetKosulDefault([]);
      setTabs(TABS_DEFAULT);
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          setLoadTab2(true);
          break;
        case 2:
          setLoadTab1(true);
          setLoadTab3(true);
          break;
        case 3:
          setLoadTab1(true);
          setLoadTab4(true);
          setLoadTab5(true);
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else if (type === 1) {
      setGenelForm(null);
      setGenelFormDefault(null);
      if (!saveErrors[1].includes("err")) {
        setKosulGrup([]);
        setKosulGrupDefault([]);
      }
      if (!saveErrors[2].includes("err")) {
        setHareket([]);
        setHareketStr("");
        setHareketStrDefault("");
      }
      if (!saveErrors[3].includes("err")) {
        setSepetKosul([]);
        setSepetKosulDefault([]);
      }
      if (!saveErrors[4].includes("err")) {
        setSepetHareket([]);
        setSepetHareketDefault([]);
      }
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          if (!saveErrors[1].includes("err")) {
            setLoadTab2(true);
          }

          break;
        case 2:
          setLoadTab1(true);
          if (!saveErrors[2].includes("err")) {
            setLoadTab3(true);
          }
          break;
        case 3:
          setLoadTab1(true);
          if (!saveErrors[3].includes("err")) {
            setLoadTab4(true);
          }
          if (!saveErrors[4].includes("err")) {
            setLoadTab5(true);
          }
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else {
      if (!add) {
        switch (selectedTabIndex) {
          case 0:
            if (equal1) {
              setLoadTab1(true);
            }
            break;
          case 1:
            if (equal2) {
              setLoadTab2(true);
            }
            break;
          case 2:
            if (equal3) {
              setLoadTab3(true);
            }
            break;
          case 3:
            if (equal4) {
              setLoadTab4(true);
            }
            if (equal5) {
              setLoadTab5(true);
            }
            break;
          default:
            break;
        }
      } else {
        setLoading(false);
        setLoadingKosul(false);
        setLoadingKosul2(false);
        setLoadingHareket(false);
        setLoadingSepetHareket(false);
        setLoadingSepetKosul(false);
        setLoadTab1(false);
        setLoadTab2(false);
        setLoadTab3(false);
        setLoadTab4(false);
        setLoadTab5(false);
      }
    }
  }, [type, selectedTabIndex, saveErrors, equal1, equal2, equal3, equal4, equal5, add]);

  useLayoutEffect(() => {
    if (add) {
      setEqual1(_isEqual(genelForm, FORM_DEFAULT));
    } else {
      setEqual1(_isEqual(genelForm, genelFormDefault));
    }
  }, [add, genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual2(_isEqual(kosulGrup, []));
    } else {
      setEqual2(_isEqual(kosulGrup, kosulGrupDefault));
    }
  }, [add, kosulGrup, kosulGrupDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual3(_isEqual(hareketStr, ""));
    } else {
      setEqual3(_isEqual(hareketStr, hareketStrDefault));
    }
  }, [add, hareketStr, hareketStrDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual4(_isEqual(sepetKosul, []));
    } else {
      setEqual4(_isEqual(sepetKosul, sepetKosulDefault));
    }
  }, [add, sepetKosul, sepetKosulDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual5(_isEqual(sepetHareket, []));
    } else {
      setEqual5(_isEqual(sepetHareket, sepetHareketDefault));
    }
  }, [add, sepetHareket, sepetHareketDefault]);

  useLayoutEffect(() => {
    if (loadTab1) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              aktif: data.aktif,
              siralama: String(data.siralama),
              baslangic_tarihi: moment(data.baslangic_tarihi).format('YYYY-MM-DD[T]HH:mm'),
              bitis_tarihi: moment(data.bitis_tarihi).format('YYYY-MM-DD[T]HH:mm'),
              magazalar: data.magazalar,
              hangi_gunler: data.hangi_gunler,
              hareket_tip: String(data.hareket_tip),
            };
            setGenelFormDefault(form);
            setGenelForm(form);
            setHataGenel(null);
            if (String(data.hareket_tip) === '1') {
              setTabs((prev) => {
                return immer(prev, (next) => {
                  next[3].display = false;
                });
              });
            } else {
              setSelectedTabIndex(0);
            }
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setLoadTab1(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DEFAULT);
        setGenelFormDefault(FORM_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
        setLoadTab1(false);
      }
    }
  }, [ID, IS_ADD, loadTab1]);

  useLayoutEffect(() => {
    if (loadTab2) {
      setLoadingKosul(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/kampanyakosulgrup`).then(({ data }) => {
            setGrup(data);
            setHataKosul(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataKosul(true);
              }
            }
          }).finally(() => {
            setLoadingKosul(false);
            setLoadTab2(false);
          })
        }, 300);
        return () => {
          clearTimeout(debounce);
        }
      } else {
        setGrup([]);
        setHataKosul(null);
        setLoadingKosul(false);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, loadTab2]);

  useLayoutEffect(() => {
    setLoadingKosul2(true);
    createKosulGrupListeler(ID, grup).then((states) => {
      setKosulGrupDefault(states);
      setKosulGrup(states);
      setHataKosul(null);
    }).catch((error) => {
      setHataKosul(true);
    }).finally(() => {
      setLoadingKosul2(false);
    })
  }, [grup, ID]);

  useLayoutEffect(() => {
    if (loadTab3) {
      setLoadingHareket(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/kampanyahareket`).then(({ data }) => {
            const forms = data.map((hareket) => ({ id: hareket.id, yuzde: hareket.yuzde }));
            const formsStr = forms.map(x => x.yuzde).join('+')
            setHareket(forms);
            setHareketStr(formsStr);
            setHareketStrDefault(formsStr);
            setHataHareket(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataHareket(true);
              }
            }
          }).finally(() => {
            setLoadingHareket(false);
            setLoadTab3(false);
          })
        }, 300);
        return () => {
          clearTimeout(debounce);
        }
      } else {
        setHareket([]);
        setHareketStr("");
        setHareketStrDefault("");
        setHataHareket(null);
        setLoadingHareket(false);
        setLoadTab3(false);
      }
    }
  }, [ID, IS_ADD, loadTab3]);

  useLayoutEffect(() => {
    if (loadTab4) {
      setLoadingSepetKosul(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/sepetkampanyakosul`).then(({ data }) => {
            const forms = data.map((kosul) => (
              {
                form: {
                  id: kosul.id,
                  kosul_info: {
                    toplam: kosul.kosul_info.toplam
                  }
                },
                errors: null
              }
            ));
            setSepetKosul(forms);
            setSepetKosulDefault(forms);
            setHataSepetKosul(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataSepetKosul(true);
              }
            }
          }).finally(() => {
            setLoadingSepetKosul(false);
            setLoadTab4(false);
          })
        }, 300);
        return () => {
          clearTimeout(debounce);
        }
      } else {
        setSepetKosul([]);
        setSepetKosulDefault([]);
        setHataSepetKosul(null);
        setLoadingSepetKosul(false);
        setLoadTab4(false);
      }
    }
  }, [ID, IS_ADD, loadTab4]);

  useLayoutEffect(() => {
    if (loadTab5) {
      setLoadingSepetHareket(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/sepetkampanyahareket`).then(({ data }) => {
            const forms = data.map((hareket) => (
              {
                form: {
                  id: hareket.id,
                  hareket:hareket.hareket,
                  hareket_info: {
                    indirim: hareket.hareket_info.indirim
                  }
                },
                errors: null
              }
            ));
            setSepetHareket(forms);
            setSepetHareketDefault(forms);
            setHataSepetHareket(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataSepetHareket(true);
              }
            }
          }).finally(() => {
            setLoadingSepetHareket(false);
            setLoadTab5(false);
          })
        }, 300);
        return () => {
          clearTimeout(debounce);
        }
      } else {
        setSepetHareket([]);
        setSepetHareketDefault([]);
        setHataSepetHareket(null);
        setLoadingSepetHareket(false);
        setLoadTab5(false);
      }
    }
  }, [ID, IS_ADD, loadTab5]);

  useLayoutEffect(() => {
    django(`${PATH}/hafta_gunleri`).then(({ data }) => setHaftaGunler(data));
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django('kampanya/tip').then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setsetKampanyaTipDic(dict);
      setKampanyaTip(data);
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
    if (property === "hareket_tip") {
      if (value === "1") {
        setTabs((prev) => {
          return immer(prev, (next) => {
            next[3].display = false;
          });
        });
      } else {
        setTabs((prev) => {
          return immer(prev, (next) => {
            next[3].display = true;
          });
        });
      }
    }
  };

  const handleKosulChangeForm = (index, index2, property, value) => {
    if (index2 !== null) {
      setKosulGrup((prev) => {
        return immer(prev, (next) => {
          if (property === 'ozellik_degerler') {
            const res = createOzellikListeler(value);
            next[index].kosullar[index2].form['ozellik_basliklar'] = res[0];
            next[index].kosullar[index2].form['ozellik_tree_list'] = res[1];
          }
          next[index].kosullar[index2].form[property] = value;
        });
      });
    } else {
      setKosulGrup((prev) => {
        return immer(prev, (next) => {
          next[index][property] = value;
        });
      });
    }
  };

  const handleKosulGrupAddNewTab = () => {
    setKosulGrup((prev) => immer(prev, (next) => {
      next.push({ ...KAMPANYA_KOSUL_GRUP_DEFAULT });
    }));
  }

  const handleKosulAddNewTab = (index) => {
    setKosulGrup((prev) => immer(prev, (next) => {
      next[index]["kosullar"].push({ ...KAMPANYA_KOSUL_DEFAULT });
    }));
  }

  const handleChangeKosulHareket = (value) => {
    setHareketStr(value);
  }

  const handleSepetKosulChangeForm = (index, property, property2, value) => {
    setSepetKosul((prev) => {
      return immer(prev, (next) => {
        next[index]["form"][property][property2] = value;
      });
    });
  };

  const handleSepetKosulAddNewTab = () => {
    setSepetKosul((prev) => immer(prev, (next) => {
      next.push({ ...SEPET_KOSUL_DEFAULT });
    }));
  }

  const handleSepetHareketChangeForm = (index, property, property2, value) => {
    setSepetHareket((prev) => {
      return immer(prev, (next) => {
        next[index]["form"][property][property2] = value;
      });
    });
  };

  const handleSepetHareketAddNewTab = () => {
    setSepetHareket((prev) => immer(prev, (next) => {
      next.push({ ...SEPET_HAREKET_DEFAULT });
    }));
  }

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setKosulGrup([]);
    setHareketStr("");
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
        next[3].hasError = false;
        next[3].display = true;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
    setLoadTab1(true);
    setLoadTab2(true);
    setLoadTab3(true);
    if (String(genelForm.hareket_tip) === '1') {
      setTabs([
        { label: 'GENEL', disabled: false, hasError: false },
        { label: 'KOŞUL GRUPLARI', disabled: false, hasError: false },
        { label: 'HAREKET', disabled: false, hasError: false },
        { label: 'SEPET', disabled: false, hasError: false, display: false },
      ])
    } else {
      setSelectedTabIndex(0);
      setTabs(TABS_DEFAULT);
    }
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    setKosulGrup(kosulGrupDefault);
    setHareketStr(hareketStrDefault);
    if (!IS_ADD) {
      setAdd(false);
    }
    if (genelFormDefault.hareket_tip === "1") {
      setTabs((prev) => {
        return immer(prev, (next) => {
          next[0].hasError = false;
          next[1].hasError = false;
          next[2].hasError = false;
          next[3].hasError = false;
          next[3].display = true;
        });
      });
    } else {
      setSelectedTabIndex(0);
      setTabs((prev) => {
        return immer(prev, (next) => {
          next[0].hasError = false;
          next[1].hasError = false;
          next[2].hasError = false;
          next[3].hasError = false;
          next[3].display = false;
        });
      });
    }
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.kampanya.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteDetailGrup = () => {
    setIsDeletingDetailGrup(true);
    setIsDeletingErrorMessageDetailGrup(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/kampanyakosulgrup/${detailDeleteIdGrup}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetailGrup(false);
        setIsDeletingErrorMessageDetailGrup(null);
      } else {
        setIsDeletingErrorMessageDetailGrup(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetailGrup(errorMessage);
    }).finally(() => {
      setIsDeletingDetailGrup(false);
      setLoadTab2(true);
    });
  }

  const handleDeleteDetailGrupWithoutId = (index) => {
    setKosulGrup((prev) => immer(prev, (next) => {
      next.splice(index, 1);
    }));
  }

  const handleDeleteDetailKosul = () => {
    setIsDeletingDetailKosul(true);
    setIsDeletingErrorMessageDetailKosul(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/kampanyakosul/${detailDeleteIdKosul}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetailKosul(false);
        setIsDeletingErrorMessageDetailKosul(null);
      } else {
        setIsDeletingErrorMessageDetailKosul(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetailKosul(errorMessage);
    }).finally(() => {
      setIsDeletingDetailKosul(false);
      setLoadTab2(true);
    });
  }

  const handleDeleteDetailKosulWithoutId = (index, index2) => {
    setKosulGrup((prev) => immer(prev, (next) => {
      next[index].kosullar.splice(index2, 1);
    }));
  }

  const handleDeleteDetailSepetKosul = () => {
    setIsDeletingDetailKosulSepet(true);
    setIsDeletingErrorMessageDetailKosul(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/sepetkampanyakosul/${detailDeleteIdKosulSepet}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetailKosulSepet(false);
        setIsDeletingErrorMessageDetailKosulSepet(null);
      } else {
        setIsDeletingErrorMessageDetailKosulSepet(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetailKosulSepet(errorMessage);
    }).finally(() => {
      setIsDeletingDetailKosulSepet(false);
      setLoadTab4(true);
    });
  }

  const handleDeleteDetailSepetKosulWithoutId = (index) => {
    setSepetKosul((prev) => {
      return immer(prev, (next) => {
        next.splice(index, 1);
      })
    });
  }

  const handleDeleteDetailSepetHareket = () => {
    setIsDeletingDetailHareketSepet(true);
    setIsDeletingErrorMessageDetailHareketSepet(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/sepetkampanyahareket/${detailDeleteIdHareketSepet}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetailHareketSepet(false);
        setIsDeletingErrorMessageDetailHareketSepet(null);
      } else {
        setIsDeletingErrorMessageDetailHareketSepet(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetailHareketSepet(errorMessage);
    }).finally(() => {
      setIsDeletingDetailHareketSepet(false);
      setLoadTab2(true);
    });
  }

  const handleDeleteDetailSepetHareketWithoutId = (index) => {
    setSepetHareket((prev) => {
      return immer(prev, (next) => {
        next.splice(index, 1);
      })
    });
  }

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Kampanya Tanım', pagename: 'kampanya', prop2: '' },
    ]
    let errors = [];
    await checkAllList('kampanya', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {
    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (add ? _isEqual(genelForm, FORM_DEFAULT) : _isEqual(genelForm, genelFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            const data = { ...genelForm, siralama: genelForm.siralama ? Number(genelForm.siralama) : 0, magazalar: genelForm.magazalar?.map((m) => m.id) }
            reqopt = {
              data: data,
              method: add ? "POST" : "PUT",
              id: null,
              successMessage: `Kampanya ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Kampanya ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabKosulGrup = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next, 'id');
      const prev = kosulGrupDefault.find(x => x.id === next.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        await createRequestOptionsTabKosul(index).then((res) => {
          const data = { ...next };
          delete data.kosullar;
          if (isHas) {
            reqopt = {
              data: data,
              method: "PUT",
              id: next.id,
              successMessage: `Koşul grup güncelleme başarıyla tamamlandı`,
              errorMessageUnexpected: `Koşul grup güncellenirken beklenmeyen bir hata oluştu`,
              kosullar: res,
              index: index
            }
          } else {
            reqopt = {
              data: data,
              method: "POST",
              id: null,
              successMessage: `Koşul grup ekleme başarıyla tamamlandı`,
              errorMessageUnexpected: `Koşul grup eklenirken beklenmeyen bir hata oluştu`,
              kosullar: res,
              index: index
            }
          }
          err = null;
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabKosul = async (index) => {
      const liste = [];
      for (const i in kosulGrup[index].kosullar) {
        const next = kosulGrup[index].kosullar[i];
        const isHas = _has(kosulGrup[index].kosullar[i].form, 'id');
        const prev = kosulGrupDefault[index]?.kosullar.find(x => x.form.id === kosulGrup[index].kosullar[i].form.id);
        const isEqual = !isHas ? false : _isEqual(prev, next);

        const copydata = { ...next.form };
        const gruplarid = copydata.gruplar?.map((m) => m.id);
        const kullanicigruplarid = copydata.kullanici_gruplari?.map((k) => k.id);
        const kategorilerid = copydata.kategoriler?.map((m) => m.id);
        const urunlerid = copydata.urunler?.map((m) => m.id);
        const ozellikid = copydata.ozellik_degerler?.map((m) => m.id);
        const data = { ...next.form, gruplar: gruplarid, kullanici_gruplari: kullanicigruplarid, kategoriler: kategorilerid, urunler: urunlerid, ozellik_degerler: ozellikid };

        if (isEqual) {
          liste.push(null)
        } else {
          if (isHas) {
            liste.push({
              data: data,
              method: "PUT",
              id: next.form.id,
              successMessage: `Koşul güncelleme başarıyla tamamlandı`,
              errorMessageUnexpected: `Koşul güncellenirken beklenmeyen bir hata oluştu`,
            })
          } else {
            liste.push({
              data: data,
              method: "POST",
              id: null,
              successMessage: `Koşul ekleme başarıyla tamamlandı`,
              errorMessageUnexpected: `Koşul eklenirken beklenmeyen bir hata oluştu`,
            })
          }
        }
      }
      return liste;
    }

    const createRequestOptionsTabHareket = async (str, next) => {
      let reqopt = null;
      let err = null;

      const postData = str !== null && next === null ? true : false;
      const putData = str !== null && next !== null ? true : false;
      const deleteData = str === null && next !== null ? true : false;

      if (postData) {
        if (str === "") {
          reqopt = null;
        } else {
          reqopt = {
            data: { yuzde: str },
            method: "POST",
            id: null,
            successMessage: `${str} yüzde ekleme başarıyla tamamlandı`,
            errorMessageUnexpected: `${str} yüzde eklenirken beklenmeyen bir hata oluştu`,
          }
        }
      }
      if (putData) {
        const isEqual = _isEqual(str, next?.yuzde);
        if (!isEqual) {
          reqopt = {
            data: { id: next.id, yuzde: str },
            method: "PUT",
            id: next.id,
            successMessage: `${str} yüzde güncelleme başarıyla tamamlandı`,
            errorMessageUnexpected: `${str} yüzde güncellenirken beklenmeyen bir hata oluştu`,
          }
        }
      }
      if (deleteData) {
        reqopt = {
          data: { ...next },
          method: "DELETE",
          id: next.id,
          successMessage: `${str} yüzde ekleme başarıyla tamamlandı`,
          errorMessageUnexpected: `${str} yüzde eklenirken beklenmeyen bir hata oluştu`,
        }
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabSepetKosul = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = sepetKosulDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'kosul_info', prop2: 'toplam' },
        ];
        await checkAllTabs(next.form, (err) => handleErrorsSepetKosul(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const data = { ...next.form }
            if (isHas) {
              reqopt = {
                data: data,
                method: 'PUT',
                id: next.form.id,
                successMessage: `Sepet koşul güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Sepet koşul güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `Sepet koşul ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Sepet koşul eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabSepetHareket = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = sepetHareketDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'hareket_info', prop2: 'indirim' },
        ];
        await checkAllTabs(next.form, (err) => handleErrorsSepetHareket(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const data = { ...next.form }
            if (isHas) {
              reqopt = {
                data: data,
                method: 'PUT',
                id: next.form.id,
                successMessage: `Sepet hareket güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Sepet hareket güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `Sepet hareket ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Sepet hareket eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    let errors = [[], [], [], [], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'kosul', request: [], error: [] },
        { index: 2, name: 'hareket', request: [], error: [] },
        { index: 3, name: 'sepetkosul', request: [], error: [] },
        { index: 4, name: 'sepethareket', request: [], error: [] },
      ];

      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in kosulGrup) {
        const kosulstate = [...kosulGrup];
        const res = await createRequestOptionsTabKosulGrup(kosulstate[i], i)
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      const str = hareketStr.split('+');
      if (str.length === hareket.length) {
        setHareketError(Array(str.length).fill({}))
        for (const i in str) {
          const res = await createRequestOptionsTabHareket(str[i], hareket[i])
          tabs[2].request.push(res[0]);
          tabs[2].error.push(res[1]);
        }
      } else if (str.length > hareket.length) {
        setHareketError(Array(str.length).fill({}))
        for (const i in str) {
          const res = await createRequestOptionsTabHareket(str[i], i > hareket.length - 1 ? null : hareket[i])
          tabs[2].request.push(res[0]);
          tabs[2].error.push(res[1]);
        }
      } else {
        setHareketError(Array(hareket.length).fill({}))
        for (const i in hareket) {
          const res = await createRequestOptionsTabHareket(i > str.length - 1 ? null : str[i], hareket[i])
          tabs[2].request.push(res[0]);
          tabs[2].error.push(res[1]);
        }
      }

      for (const i in sepetKosul) {
        const sepetkosulstate = [...sepetKosul];
        const res = await createRequestOptionsTabSepetKosul(sepetkosulstate[i], i)
        tabs[3].request.push(res[0]);
        tabs[3].error.push(res[1]);
      }

      for (const i in sepetHareket) {
        const sepethareketstate = [...sepetHareket];
        const res = await createRequestOptionsTabSepetHareket(sepethareketstate[i], i)
        tabs[4].request.push(res[0]);
        tabs[4].error.push(res[1]);
      }


      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "kampanya" : `kampanya/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.kampanya.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded();
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }

            if (tab.name === 'kosul') {
              if (SAVEID !== null) {
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `kampanya/${SAVEID}/kampanyakosulgrup/${request.id}` : `kampanya/${SAVEID}/kampanyakosulgrup`
                }).then(({ data }) => {
                  asyncForEach(request.kosullar, async (kosul, kosulIndex) => {
                    if (kosul !== null) {
                      setKosulGrup((prev) => {
                        return immer(prev, (next) => {
                          next[requestIndex].kosullar[kosulIndex].errors = null;
                        })
                      });
                      await django({
                        method: kosul.method,
                        data: { ...kosul.data, kampanya_kosul_grup: data.id },
                        url: kosul.id ? `kampanya/${SAVEID}/kampanyakosul/${kosul.id}` : `kampanya/${SAVEID}/kampanyakosul`
                      }).then(() => {
                        enqueueSnackbar(kosul.successMessage, { variant: 'success' });
                        errors[tab.index].splice(requestIndex, 0, null);
                      }).catch((error) => {
                        setSelectedTabIndex(tab.index);
                        if (error.response) {
                          setKosulGrup((prev) => {
                            return immer(prev, (next) => {
                              next[requestIndex].kosullar[kosulIndex].errors = error.response.data;
                            })
                          });
                        } else {
                          enqueueSnackbar(kosul.errorMessageUnexpected, { variant: 'error' });
                        }
                        errors[tab.index].splice(requestIndex, 0, "err");
                      });
                    }
                  })
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }

            if (tab.name === 'hareket') {
              setHareketError((prev) => {
                return immer(prev, (next) => {
                  next[requestIndex] = null;
                })
              });
              if (SAVEID !== null) {
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `kampanya/${SAVEID}/kampanyahareket/${request.id}` : `kampanya/${SAVEID}/kampanyahareket`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  if (error.response) {
                    setHareketError((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex] = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }

            if (tab.name === "sepetkosul") {
              if (SAVEID !== null) {
                setSepetKosul((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `kampanya/${SAVEID}/sepetkampanyakosul/${request.id}` : `kampanya/${SAVEID}/sepetkampanyakosul`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(3);
                  if (error.response) {
                    setSepetKosul((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }

            if (tab.name === "sepethareket") {
              if (SAVEID !== null) {
                setSepetHareket((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `kampanya/${SAVEID}/sepetkampanyahareket/${request.id}` : `kampanya/${SAVEID}/sepetkampanyahareket`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(3);
                  if (error.response) {
                    setSepetHareket((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }

          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }
    await start();
    if (SAVEID !== null) {
      if (!add) {
        if (!errors[0].includes("err")) {
          setLoadTab1(true);
        }
        if (!errors[1].includes("err")) {
          setLoadTab2(true);
        }
        if (!errors[2].includes("err")) {
          setLoadTab3(true);
        }
        if (!errors[3].includes("err")) {
          setLoadTab4(true);
        }
        if (!errors[4].includes("err")) {
          setLoadTab5(true);
        }
      } else {
        setSaveErrors(errors);
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
      await checkAll();
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? false : true : true;
        next[1].hasError = !errors[1].includes("err") ? false : true;
        next[2].hasError = !errors[2].includes("err") ? false : true;
        next[3].hasError = (!errors[3].includes("err") || !errors[4].includes("err")) ? false : true;
      });
    });
  };

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsDetail = (errors, index, index2) => {
    setKosulGrup((prev) => {
      const err = _pickBy({ ...prev[index][index2]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index][index2]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsSepetKosul = (errors, index) => {
    setSepetKosul((prev) => {
      let errobj = { ...prev[index]["errors"], ...errors };
      let err = {};
      if (_has(errobj, 'kosul_info')) {
        const kosul_info = _pickBy(errobj['kosul_info'], _identity);
        if (_isEmpty(kosul_info)) {
          err = _pickBy({ ...errobj, 'kosul_info': null }, _identity);
        } else {
          err = _pickBy({ ...errobj, 'kosul_info': kosul_info }, _identity);
        }
      } else {
        err = _pickBy(errobj, _identity);
      }
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });    
  }

  const handleErrorsSepetHareket = (errors, index) => {
    setSepetHareket((prev) => {
      let errobj = { ...prev[index]["errors"], ...errors };
      let err = {};
      if (_has(errobj, 'hareket_info')) {
        const hareket_info = _pickBy(errobj['hareket_info'], _identity);
        if (_isEmpty(hareket_info)) {
          err = _pickBy({ ...errobj, 'hareket_info': null }, _identity);
        } else {
          err = _pickBy({ ...errobj, 'hareket_info': hareket_info }, _identity);
        }
      } else {
        err = _pickBy(errobj, _identity);
      }
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });     
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            haftaGunler={haftaGunler}
            kampanyaTip={kampanyaTip}
            kampanyaTipDic={kampanyaTipDic}
            onChangeForm={handleGenelChangeForm}

            onCheckErrors={(errors) => handleErrors(errors)}
          />
        )}

        {selectedTabIndex === 1 && (
          <TabKosul
            loading={LOADINGKOSUL}
            hata={hataKosul}

            forms={kosulGrup}
            onChangeForm={handleKosulChangeForm}
            onAddNewGrup={handleKosulGrupAddNewTab}
            onAddNewKosul={handleKosulAddNewTab}

            setDeleteIdDetailIdGrup={setDeleteIdDetailGrup}
            setDeleteIdDetailIdKosul={setDeleteIdDetailKosul}
            openDeleteFormGrup={setShowDeleteDialogDetailGrup}
            openDeleteFormKosul={setShowDeleteDialogDetailKosul}
            openDeleteFormGrupWithoutId={handleDeleteDetailGrupWithoutId}
            openDeleteFormKosulWithoutId={handleDeleteDetailKosulWithoutId}
            onCheckErrors={(errors, index, index2) => handleErrorsDetail(errors, index, index2)}
          />
        )}

        {selectedTabIndex === 2 && (
          <TabHareket
            loading={loadingHareket}
            hata={hataHareket}

            hareket={hareketStr}
            onChangeForm={handleChangeKosulHareket}
            hareketError={hareketError}
          />
        )}

        {selectedTabIndex === 3 && (
          <TabSepet
            loading={LOADINGSEPET}
            hata={HATASEPET}

            sepetKosul={sepetKosul}
            sepetHareket={sepetHareket}

            onChangeFormKosul={handleSepetKosulChangeForm}
            onSepetKosulAddNew={handleSepetKosulAddNewTab}

            onChangeFormHareket={handleSepetHareketChangeForm}
            onSepetHareketAddNew={handleSepetHareketAddNewTab}

            onCheckErrorsKosul={handleErrorsSepetKosul}
            onCheckErrorsHareket={handleErrorsSepetHareket}

            setDeleteIdDetailIdHareket={setDeleteIdDetailHareketSepet}
            setDeleteIdDetailIdKosul={setDeleteIdDetailKosulSepet}

            openDeleteFormHareket={setShowDeleteDialogDetailHareketSepet}
            openDeleteFormKosul={setShowDeleteDialogDetailKosulSepet}

            openDeleteFormHareketWithoutId={handleDeleteDetailSepetHareketWithoutId}
            openDeleteFormKosulWithoutId={handleDeleteDetailSepetKosulWithoutId}
          />
        )}

      </LayoutDetail>


      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Kampanya siliniyor lütfen bekleyin'
                : 'Bu kampanyayı gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetailGrup &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetailGrup
              ? isDeletingErrorMessageDetailGrup
              : isDeletingDetailGrup
                ? 'Koşul grubu siliniyor lütfen bekleyin'
                : 'Bu koşul grubunu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetailGrup ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetailGrup}
          onSubmit={handleDeleteDetailGrup}
          onCancel={() => setShowDeleteDialogDetailGrup(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetailKosul &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetailKosul
              ? isDeletingErrorMessageDetailKosul
              : isDeletingDetailKosul
                ? 'Koşul siliniyor lütfen bekleyin'
                : 'Bu koşulu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetailKosul ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetailKosul}
          onSubmit={handleDeleteDetailKosul}
          onCancel={() => setShowDeleteDialogDetailKosul(false)}
        ></DialogConfirm>
      }

      {showDeleteDialogDetailKosulSepet &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetailKosulSepet
              ? isDeletingErrorMessageDetailKosulSepet
              : isDeletingDetailKosulSepet
                ? 'Sepet koşul siliniyor lütfen bekleyin'
                : 'Bu sepet koşulu gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetailKosulSepet ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetailKosulSepet}
          onSubmit={handleDeleteDetailSepetKosul}
          onCancel={() => setShowDeleteDialogDetailKosulSepet(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetailHareketSepet &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetailHareketSepet
              ? isDeletingErrorMessageDetailHareketSepet
              : isDeletingDetailHareketSepet
                ? 'Sepet hareket siliniyor lütfen bekleyin'
                : 'Bu sepet hareketi gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetailHareketSepet ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetailHareketSepet}
          onSubmit={handleDeleteDetailSepetHareket}
          onCancel={() => setShowDeleteDialogDetailHareketSepet(false)}
        ></DialogConfirm>
      }

    </>
  );
}

export default KampanyaDetail;