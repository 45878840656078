import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Typography,
  IconButton,
  Tooltip
} from '@material-ui/core';

import { Editor } from "@tinymce/tinymce-react";

import {
  Close as IconClose,
  Check as IconCheck
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      display: 'flex',
      '& + &': {
        marginTop: 5,
      },
    },
    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: 0,
      margin: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemEditor: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: 5,
      width: 800,
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    keyDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: 10,
      flexWrap: "wrap"
    },
    keyCloud: {
      padding: 4,
      border: "1px solid #eacfea",
      position: "relative",
      borderRadius: 4,
      margin: "0 5px 5px",
    },
  };
};

const UrunDetailXTabIcerikler = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  const ACIKLAMA = useMemo(() => _result(FORM?.["dil_icerik"], 'aciklama', ''), [FORM]);
  const [searchKey, setSearchKey] = useState(Array(COUNT_TABS).fill(""));
  const [searchKeyError, setSearchKeyError] = useState(Array(COUNT_TABS).fill(null));

  useLayoutEffect(() => {
    if (props.forms?.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms]);

  const createTextFieldProps = useCallback((name, name2, defaultValue = '') => {
    if (name2 !== '') {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(FORM?.[name], `${name2}`) || defaultValue,
        error: _has(ERROR?.[name], `${name2}`),
        fullWidth: true,
      };
    } else {
      return {
        name,
        variant: 'outlined',
        size: 'small',
        value: _result(FORM, `${name}`) || defaultValue,
        error: _has(ERROR, `${name}`),
        fullWidth: true,
      };
    }

  }, [FORM, ERROR]);

  const handleChangeForm = (property, property2, value) => {
    props.onChangeForm(selectedTabIndex, property, property2, value);
  };

  const handleChangeSearchKey = (value) => {
    const keys = [...searchKey];
    keys[selectedTabIndex] = value;
    setSearchKey(keys);
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }

  const handleAddOrRemoveSearchKeys = (type, value) => {
    let list = [...FORM.arama_kelimeleri];
    const val = value.trim();
    if (type === "add") {
      if (!list.includes(val) && val) {
        list.push(val);
        const errors = [...searchKeyError];
        errors[selectedTabIndex] = null;
        const keys = [...searchKey];
        keys[selectedTabIndex] = "";
        setSearchKey(keys);
        setSearchKeyError(errors);
      } else {
        const errors = [...searchKeyError];
        errors[selectedTabIndex] = val ? "Aynı kelime birden fazla kullanılamaz" : null;
        setSearchKeyError(errors);
      }
    } else {
      list = list.filter(k => k !== val);
    }
    handleChangeForm("arama_kelimeleri", "", list);
  }

  const handleDetailDelete = (index) => {
    if (props.forms[index].form.id) {
      props.openDeleteForm(true);
      props.setDeleteId(props.forms[index].form.id);
    } else {
      props.deleteDetailWithIndex(index);
    }
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <LayoutDetailVerticalTab
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                buttonShow={false}
                delete={handleDetailDelete}
                deleteButtonShow={true}
              >
                {COUNT_TABS === 0 && (
                  <div className="flex flex-col items-start justify-start p-4 space-y-4">
                    <Typography variant="body1">Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                  </div>
                )}
                {COUNT_TABS > 0 && (
                  <div className={classes.tabDiv}>

                    <div className={classes.column}>
                      <ul className={classes.formList}>

                        <li className={classes.formItem} id="slug">
                          <label className={classes.formItemLabel} htmlFor="slug">Slug(URL) *</label>
                          <Tooltip title={ERROR?.slug ? ERROR.slug[0] : _isNil(FORM?.slug) ? '' : FORM.slug}>
                            <TextField
                              {...createTextFieldProps('slug', '')}
                            ></TextField>
                          </Tooltip>
                        </li>

                        <li className={classes.formItem} id="dil_icerik.tanim">
                          <label className={classes.formItemLabel} htmlFor="dil_icerik.tanim">Tanım *</label>
                          <Tooltip title={ERROR?.dil_icerik?.tanim ? ERROR.dil_icerik.tanim[0] : _isNil(FORM?.dil_icerik?.tanim) ? '' : FORM.dil_icerik.tanim}>
                            <TextField
                              {...createTextFieldProps('dil_icerik', 'tanim')}
                              onChange={(e) => handleChangeForm('dil_icerik', 'tanim', e.target.value)}
                              onBlur={() => {
                                handleInputErrors2(FORM, 'dil_icerik', handleSetErrors, 'tanim');
                              }}
                              inputProps={{
                                maxLength: 200,
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>

                        <li className={classes.formItem} id="dil_icerik.baslik">
                          <label className={classes.formItemLabel} htmlFor="dil_iceril.baslik">Başlık *</label>
                          <Tooltip title={ERROR?.dil_icerik?.baslik ? ERROR.dil_icerik.baslik[0] : _isNil(FORM?.dil_icerik?.baslik) ? '' : FORM.dil_icerik.baslik}>
                            <TextField
                              {...createTextFieldProps('dil_icerik', 'baslik')}
                              onChange={(e) => handleChangeForm('dil_icerik', 'baslik', e.target.value)}
                              onBlur={() => handleInputErrors2(FORM, 'dil_icerik', handleSetErrors, 'baslik')}
                            ></TextField>
                          </Tooltip>
                        </li>

                        <li className={classes.formItem} id="dil_icerik.bilgilendirme">
                          <label className={classes.formItemLabel} htmlFor="dil_icerik.bilgilendirme">Bilgilendirme</label>
                          <Tooltip title={ERROR?.dil_icerik?.bilgilendirme ? ERROR.dil_icerik.bilgilendirme[0] : _isNil(FORM?.dil_icerik?.bilgilendirme) ? '' : FORM.dil_icerik.bilgilendirme}>
                            <TextField
                              {...createTextFieldProps('dil_icerik', 'bilgilendirme')}
                              onChange={(e) => handleChangeForm('dil_icerik', 'bilgilendirme', e.target.value)}
                            ></TextField>
                          </Tooltip>
                        </li>

                        <li className={classes.formItem} id="dil_icerik.anahtar_kelimeler">
                          <label className={classes.formItemLabel} htmlFor="dil_icerik.anahtar_kelimeler">Anahtar Kelimeler</label>
                          <Tooltip title={ERROR?.dil_icerik?.anahtar_kelimeler ? ERROR.dil_icerik.anahtar_kelimeler[0] : _isNil(FORM?.dil_icerik?.anahtar_kelimeler) ? '' : FORM.dil_icerik.anahtar_kelimeler}>
                            <TextField
                              {...createTextFieldProps('dil_icerik', 'anahtar_kelimeler')}
                              onChange={(e) => handleChangeForm('dil_icerik', 'anahtar_kelimeler', e.target.value)}
                            ></TextField>
                          </Tooltip>
                        </li>

                        <li className={classes.formItem} id="dil_icerik','kisa_aciklama">
                          <label className={classes.formItemLabel} htmlFor="dil_icerik','kisa_aciklama">Kısa Açıklama</label>
                          <Tooltip title={ERROR?.dil_icerik?.kisa_aciklama ? ERROR.dil_icerik.kisa_aciklama[0] : _isNil(FORM?.dil_icerik?.kisa_aciklama) ? '' : FORM.dil_icerik.kisa_aciklama}>
                            <TextField
                              {...createTextFieldProps('dil_icerik', 'kisa_aciklama')}
                              onChange={(e) => handleChangeForm('dil_icerik', 'kisa_aciklama', e.target.value)}
                            ></TextField>
                          </Tooltip>
                        </li>
                      </ul>
                      <ul className={classes.formList}>
                        <li className={classes.formItem} id="arama_kelimeleri">
                          <label className={classes.formItemLabel} htmlFor="arama_kelimeleri">Arama Kelimeleri</label>
                          <Tooltip title={searchKey == null ? '' : searchKey}>
                            <TextField
                              name='arama_kelimeleri'
                              variant='outlined'
                              size='small'
                              value={searchKey[selectedTabIndex]}
                              fullWidth={true}
                              onChange={(e) => handleChangeSearchKey(e.target.value)}
                              error={searchKeyError[selectedTabIndex] ? true : false}
                              helperText={searchKeyError[selectedTabIndex]}
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  handleAddOrRemoveSearchKeys("add", searchKey[selectedTabIndex])
                                }
                              }}
                              InputProps={{
                                endAdornment:
                                  <Tooltip title="Ekle">
                                    <IconButton
                                      size="small"
                                      disableRipple
                                      onClick={() => handleAddOrRemoveSearchKeys("add", searchKey[selectedTabIndex])}
                                    >
                                      <IconCheck />
                                    </IconButton>
                                  </Tooltip>
                              }}
                            ></TextField>
                          </Tooltip>
                        </li>
                        <li className={classes.formItem} id="arama_kelimeleri">
                          <div className={classes.keyDiv}>
                            {FORM && FORM.arama_kelimeleri && FORM.arama_kelimeleri.map((key, index) => (
                              <div className={classes.keyCloud} key={index}>
                                <span >{key}</span>
                                <Tooltip title="Sil">
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    onClick={() => handleAddOrRemoveSearchKeys("delete", key)}
                                  >
                                    <IconClose />
                                  </IconButton>
                                </Tooltip>
                              </div>

                            ))}
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className={classes.column}>
                      <div className="mb-4" id="dil_icerik','aciklama">
                        <label htmlFor="dil_icerik','aciklama">Açıklama</label>
                        <div className="mt-2">
                          <Editor
                            initialValue={ACIKLAMA}
                            init={{
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar: 'undo redo copy cut paste remove removeformat selectall | formatselect | fontselect | fontsizeselect |' +
                                'bold italic underline lineheight backcolor forecolor blockquote | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | code | help',
                              language: 'tr',
                              valid_elements: '+*[*]',
                              valid_children: "+body[style]",
                              verify_html: false
                            }}
                            onChange={(e) => handleChangeForm('dil_icerik', 'aciklama', e.target.getContent())}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
                }
              </LayoutDetailVerticalTab>
            </div>
          )}
        </>
      }
    </>
  );
});

export default UrunDetailXTabIcerikler;
