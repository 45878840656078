import React, { useLayoutEffect, useState, useMemo } from 'react';
import moment from 'moment';
import {
  trimEnd as _trimEnd
} from 'lodash';

import django from '../../../api/django';
import LayoutDetail from '../../base/LayoutDetail';
import TabGenel from './KullaniciOdemeLogDetailTab_Genel';

const PATH = 'kullaniciodemelog';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
];


const OdemeLogDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [hataGenel, setHataGenel] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelForm, setGenelForm] = useState(null);

  const ID = useMemo(() => props.id, [props]);

  const TITLE = useMemo(() => {
    return genelForm?.title ?? "";
  }, [genelForm]);

  const IS_EQUAL = true;

  useLayoutEffect(() => {
    setLoading(true);
    const debounce = setTimeout(() => {
      django(`${PATH}/${ID}`).then(({ data }) => {
        const form = {
          olusturan_kullanici: data.olusturan_kullanici?.username ?? '',
          olusturma_zamani: moment(data.olusturma_zamani).format('DD.MM.YYYY, HH.mm'),
          tutar: String(data.tutar),
          odeme_son_durum: data.odeme_son_durum,
          sepet: data.sepet,
          evrak: data.evrak?.kodu ?? '',
          odeme_tanim: data.odeme_tanim?.tanim ?? '',
          result: data.result,
          callback_3d_request: data.callback_3d_request,
          odeme_istek: data.odeme_istek,
          odeme_cevap: data.odeme_cevap,
          title: `${moment(data.olusturma_zamani).format('DD.MM.YYYY, HH.mm')} Tarihli ${data.tutar} Tutarındaki Ödeme Log Detayı`
        };
        setGenelForm(form);
        setHataGenel(null);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            setHataGenel(true);
          }
        }
      }).finally(() => {
        setTabs(TABS_DEFAULT);
        setLoading(false);
        setAdd(false);
      });
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoading(false);
    };
  }, [ID, lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add}
        disableReloadButton={loading || add}
        disableCancelButton={!add && (loading || IS_EQUAL)}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add}
        disablePreviousButton={props.disablePreviousButton || add}
        disableNextButton={props.disableNextButton || add}

        hideAddButton={true}
        hideCancelButton={true}
        hideSaveButton={true}
        hideDeleteButton={true}

        onClickCloseButton={props.onClose}
        onClickAddButton={null}
        onClickReloadButton={handleReload}
        onClickCancelButton={null}
        onClickSaveButton={null}
        onClickDeleteButton={null}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            form={genelForm}
          />
        )}
      </LayoutDetail>
    </>
  )
}
export default OdemeLogDetail;