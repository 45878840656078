import React, { useLayoutEffect, useState, useMemo } from 'react';

import { useSnackbar } from 'notistack';
import immer from 'immer';

import django from '../../../api/django';

import LayoutDetailWithoutTabs from '../../../pages/base/LayoutDetailWithoutTabs';
import Inside from './DetailOzellikDegerInside';

const pathBaslik = "enttanim-ozellikbaslik";
const pathDeger = "ozellikdeger"
const PER_PAGE = 100;

const PageBirimDetail = (props) => {
  const [loadingBaslik, setLoadingBaslik] = useState(true);
  const [loadingBaslikMore, setLoadingBaslikMore] = useState(false);
  const [loadingDeger, setLoadingDeger] = useState(false);
  const [loadingDegerMore, setLoadingDegerMore] = useState(false);

  const [hataBaslik, setHataBaslik] = useState(null);
  const [hataDeger, setHataDeger] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const [genelFormBaslik, setGenelFormBaslik] = useState([]);
  const [genelFormBaslikCount, setGenelFormBaslikCount] = useState(0);
  const [selectedBaslik, setSelectedBaslik] = useState(null);

  const [genelFormDeger, setGenelFormDeger] = useState([]);
  const [genelFormDegerCount, setGenelFormDegerCount] = useState(0);

  const PAGE_BASLIK = useMemo(() => genelFormBaslik.length > 0 ? Math.ceil(genelFormBaslik.length / PER_PAGE) : 1, [genelFormBaslik.length]);
  const PAGES_BASLIK_COUNT = useMemo(() => Math.ceil(genelFormBaslikCount / PER_PAGE), [genelFormBaslikCount]);

  const PAGE_DEGER = useMemo(() => genelFormDeger.length > 0 ? Math.ceil(genelFormDeger.length / PER_PAGE) : 1, [genelFormDeger.length]);
  const PAGES_DEGER_COUNT = useMemo(() => Math.ceil(genelFormDegerCount / PER_PAGE), [genelFormDegerCount]);

  const LOADING = useMemo(() => {
    if (loadingBaslik || loadingDeger || loadingBaslikMore || loadingDegerMore) {
      return true;
    }
    return false;
  }, [loadingBaslik, loadingDeger, loadingBaslikMore, loadingDegerMore]);

  const { enqueueSnackbar } = useSnackbar();

  useLayoutEffect(() => {
    django(`${pathBaslik}/count`).then(({ data }) => {
      setGenelFormBaslikCount(data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataBaslik(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setLoadingBaslik(true);
    const debounce = setTimeout(() => {
      django(pathBaslik, { params: { size: PER_PAGE } }).then(({ data }) => {
        const forms = data.map((ozbas) => (
          {
            id: ozbas.id,
            tanim: ozbas.tanim,
            ozellik_baslik: ozbas.ozellik_baslik
          }
        ));
        setGenelFormBaslik(forms);
        setHataBaslik(null);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            setHataBaslik(true);
          }
        }
      }).finally(() => {
        setLoadingBaslik(false);
      });
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoadingBaslik(false);
    };
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (selectedBaslik) {
      django(`${pathBaslik}/${selectedBaslik.id}/${pathDeger}/count`).then(({ data }) => {
        setGenelFormDegerCount(data);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            setHataDeger(true);
          }
        }
      });
    } else {
      setGenelFormDegerCount(0);
    }
  }, [selectedBaslik, lastUpdateTime]);

  useLayoutEffect(() => {
    if (selectedBaslik) {
      setLoadingDeger(true);
      const debounce = setTimeout(() => {
        django(`${pathBaslik}/${selectedBaslik.id}/${pathDeger}`, { params: { size: PER_PAGE } }).then(({ data }) => {
          const forms = data.map((ozdeg) => (
            {
              id: ozdeg.id,
              tanim: ozdeg.tanim,
              ozellik_deger: ozdeg.ozellik_deger ? { id: ozdeg.ozellik_deger.id, tanim: ozdeg.ozellik_deger.tanim, ozellik_baslik_id: ozdeg.ozellik_deger.ozellikbaslik.id, ozellik_baslik_tanim: ozdeg.ozellik_deger.ozellikbaslik.tanim } : null,
              ozellik_baslik: ozdeg.ozellik_deger ? { id: ozdeg.ozellik_deger.ozellikbaslik.id, tanim: ozdeg.ozellik_deger.ozellikbaslik.tanim } : null,
              treeList: ozdeg.ozellik_deger ? [{ title: ozdeg.ozellik_deger.ozellikbaslik.tanim, list: [{ key: ozdeg.ozellik_deger.id, value: ozdeg.ozellik_deger.tanim }] }] : []
            }
          ));
          setGenelFormDeger(forms);
          setHataDeger(null);
        }).catch((error) => {
          setGenelFormDeger([]);
          if (error.response) {
            if (error.response.status === 500) {
              setHataDeger(true);
            }
          }
        }).finally(() => {
          setLoadingDeger(false);
        });
      }, 300);
      return () => {
        clearTimeout(debounce);
        setLoadingDeger(false);
      };
    } else {
      setGenelFormDeger([]);
    }
  }, [selectedBaslik, lastUpdateTime]);

  const handleGetMoreBaslik = (page, mainContainerRef) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setLoadingBaslikMore(true);
    const url = pathBaslik;
    const config = {
      params: {
        size: PER_PAGE,
        page
      },
    };
    django(url, config).then(({ data }) => {
      const datas = data.map((ozbas) => ({ id: ozbas.id, tanim: ozbas.tanim, ozellik_baslik: ozbas.ozellik_baslik }));
      setGenelFormBaslik((prev) => [...prev, ...datas]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setLoadingBaslikMore(false);
    });
  };

  const handleGetMoreDeger = (page, mainContainerRef, query) => {
    const firstScrollTop = mainContainerRef?.current.scrollTop;
    setLoadingDegerMore(true);
    const url = `${pathBaslik}/${selectedBaslik.id}/${pathDeger}`;
    const config = {
      params: {
        size: PER_PAGE,
        page
      },
    };
    for (let index = 0; index < query.length; index++) {
      const element = query[index];
      if (element.value !== "") {
        config.params[element.key] = `*${element.value}*`
      }
    }
    django(url, config).then(({ data }) => {
      const datas = data.map((ozdeg) => ({
        id: ozdeg.id,
        tanim: ozdeg.tanim,
        ozellik_deger: ozdeg.ozellik_deger ? { id: ozdeg.ozellik_deger.id, tanim: ozdeg.ozellik_deger.tanim, ozellik_baslik_id: ozdeg.ozellik_deger.ozellikbaslik.id, ozellik_baslik_tanim: ozdeg.ozellik_deger.ozellikbaslik.tanim } : null,
        ozellik_baslik: ozdeg.ozellik_deger ? { id: ozdeg.ozellik_deger.ozellikbaslik.id, tanim: ozdeg.ozellik_deger.ozellikbaslik.tanim } : null,
        treeList: ozdeg.ozellik_deger ? [{ title: ozdeg.ozellik_deger.ozellikbaslik.tanim, list: [{ key: ozdeg.ozellik_deger.id, value: ozdeg.ozellik_deger.tanim }] }] : []
      }));
      setGenelFormDeger((prev) => [...prev, ...datas]);
    }).finally(() => {
      mainContainerRef.current.scrollTo({
        top: firstScrollTop,
        left: 0,
        behavior: 'auto',
      });
      setLoadingDegerMore(false);
    });
  };

  const searchListSet = (query) => {
    const config = {
      params: {
        size: PER_PAGE,
      },
    };
    const configCount = {
      params: {}
    }
    for (let index = 0; index < query.length; index++) {
      const element = query[index];
      if (element.value !== "") {
        config.params[element.key] = `*${element.value}*`;
        configCount.params[element.key] = `*${element.value}*`;
      }
    }
    const handleGet = ({ url, config }) => {
      setLoadingDeger(true);
      django(url, config).then(({ data }) => {
        const forms = data.map((ozdeg) => (
          {
            id: ozdeg.id,
            tanim: ozdeg.tanim,
            ozellik_deger: ozdeg.ozellik_deger ? { id: ozdeg.ozellik_deger.id, tanim: ozdeg.ozellik_deger.tanim, ozellik_baslik_id: ozdeg.ozellik_deger.ozellikbaslik.id, ozellik_baslik_tanim: ozdeg.ozellik_deger.ozellikbaslik.tanim } : null,
            ozellik_baslik: ozdeg.ozellik_deger ? { id: ozdeg.ozellik_deger.ozellikbaslik.id, tanim: ozdeg.ozellik_deger.ozellikbaslik.tanim } : null,
            treeList: ozdeg.ozellik_deger ? [{ title: ozdeg.ozellik_deger.ozellikbaslik.tanim, list: [{ key: ozdeg.ozellik_deger.id, value: ozdeg.ozellik_deger.tanim }] }] : []
          }
        ));
        setGenelFormDeger(forms);
        setHataDeger(null);
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            setHataDeger(true);
          }
        }
      }).finally(() => {
        setLoadingDeger(false);
      });
    };
    const handleGetCount = ({ urlCount, configCount }) => {
      django(urlCount, configCount).then(({ data }) => {
        setGenelFormDegerCount(data);
        setHataDeger(null);
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            setHataDeger(true);
          }
        }
      })
    };
    handleGetCount({ url: `${pathBaslik}/${selectedBaslik.id}/${pathDeger}/count`, config });
    handleGet({ url: `${pathBaslik}/${selectedBaslik.id}/${pathDeger}`, config });
  }

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  const handleChangeListe = (index, value) => {
    setGenelFormDeger((prev) => {
      return immer(prev, (next) => {
        next[index].ozellik_deger = value;
        next[index].ozellik_baslik = value ? { id: value.ozellik_baslik_id, tanim: value.ozellik_baslik_tanim } : null;
        next[index].treeList = value ? [{ title: value.ozellik_baslik_tanim, list: [{ key: value.id, value: value.tanim }] }] : [];
      });
    });
  }

  const handleSave = (item) => {
    const data = { ...item, ozellik_deger: item.ozellik_deger ? Number(item.ozellik_deger.id) : null };
    delete data.ozellik_baslik;
    delete data.treeList;
    const requestoptions = {
      config: {
        method: 'PUT',
        url: `${pathBaslik}/${selectedBaslik.id}/${pathDeger}/${item.id}`,
        data: data
      },
      successMessage: `${item.tanim} güncelleme başarıyla tamamlandı`,
      errorMessageUnexpected: `${item.tanim} güncellenirken beklenmeyen bir hata oluştu`,
    }
    const start = async () => {
      await django(requestoptions.config).then(({ data }) => {
        enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
          } else {
            enqueueSnackbar(error.response.data, { variant: 'error' });
          }
        } else {
          enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
        }
      });
    }
    start();
  }


  return (
    <>
      <LayoutDetailWithoutTabs
        loading={LOADING}
        title="Özellik Değer Eşleştirme"
        single={true}

        canClose={false}
        canOpenNew={false}
        canAdd={false}
        canReload={true}
        canSave={false}
        canCancel={false}
        canDelete={false}
        canPrevNext={false}
        disableReloadButton={LOADING}
        onClickReloadButton={handleReload}
      >
        <Inside
          loadingBaslik={loadingBaslik}
          loadingBaslikMore={loadingBaslikMore}
          loadingDeger={loadingDeger}
          loadingDegerMore={loadingDegerMore}
          hataBaslik={hataBaslik}
          hataDeger={hataDeger}

          baslik={genelFormBaslik}
          baslikCount={genelFormBaslikCount}
          baslikPage={PAGE_BASLIK}
          baslikPagesCount={PAGES_BASLIK_COUNT}
          getMoreBaslik={handleGetMoreBaslik}


          deger={genelFormDeger}
          degerCount={genelFormDegerCount}
          degerPage={PAGE_DEGER}
          degerPagesCount={PAGES_DEGER_COUNT}
          getMoreDeger={handleGetMoreDeger}
          searchDegerSet={searchListSet}

          selectBaslik={(id) => setSelectedBaslik(id)}
          rowCount={PER_PAGE}
          onChange={handleChangeListe}
          onSave={handleSave}
        />
      </LayoutDetailWithoutTabs>
    </>
  );
};

export default PageBirimDetail;