import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  has as _has,
  isEmpty as _isEmpty,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach } from '../../../helpers/helpers';

import LayoutDetail from '../../../pages/base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './EkAlanDetailTab_Genel';
import TabKosul from './EkAlanDetailTab_Kosul/KosulList';

const PATH = 'ek_alan_tanim';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'KOŞUL', disabled: false, hasError: false, display: true },
];

const FORM_DATA_DEFAULT = {
  tanim: '',
  alan_iliski_tip: [],
  magazalar: [],
  aktif: true,
  siralama: '0',
  detay: false
};

const DILLER_FORM_DEFAULT = {
  dil_id: '',
  baslik: '',
  yardim: ''
};

const DILLER_FORM_DEFAULT2 = {
  dil_id: '',
  deger: '',
};

const FORM_DATA_ALAN_DEFAULT = {
  form: {
    tanim: 'Tanımsız',
    alan_tip: "0",
    secim_degerleri: [],
    diller: [],
    arayuzde_sor: false,
    zorunlu: false,
    aktif: true,
    siralama: '0',
  },
  errors: null
};


const createDillerStates = async (diller, Diller) => {
  const states = [];
  diller.sort((a, b) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...DILLER_FORM_DEFAULT,
        dil_id: dil.id,
      },
      errors: null,
    };

    const d = Diller?.find((bd) => bd.dil_id === dil.id);
    if (d) {
      data.form.dil_id = d.dil_id;
      data.form.baslik = d.baslik;
      data.form.yardim = d.yardim;
    }
    states.push(data);
  });
  return states;
};

const createDillerStates2 = async (diller, Diller) => {
  const states = [];
  diller.sort((a, b) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...DILLER_FORM_DEFAULT2,
        dil_id: dil.id,
      },
      errors: null,
    };

    const d = Diller?.find((bd) => bd.dil_id === dil.id);

    if (d) {
      data.form.dil_id = d.dil_id;
      data.form.deger = d.deger;
    }
    states.push(data);
  });
  return states;
};


const OdemeTanimDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingAlan, setLoadingAlan] = useState(true);
  const [loadingAlanDil, setLoadingAlanDil] = useState(true);
  const [loadingAlanSecim, setLoadingAlanSecim] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataAlan, setHataAlan] = useState(null);
  const [hataDil, setHataDil] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(FORM_DATA_DEFAULT);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const [ekAlanSelectedTabIndex, setEkAlanSelectedTabIndex] = useState(null);
  const [ekAlanFormsDefault, setEkAlanFormsDefault] = useState([]);
  const [ekAlanForms, setEkAlanForms] = useState([]);
  const [ekAlanAdd, setEkAlanAdd] = useState(true);

  const [ekAlanSecimleri, setEkAlanSecimleri] = useState([]);
  const [ekAlanSecimDeger, setEkAlanSecimDeger] = useState([]);
  const [ekAlanSecimDegerDefault, setEkAlanSecimDegerDefault] = useState([]);

  const [dilMiniList, setDilMiniList] = useState([]);
  const [ekAlanDiller, setEkAlanDiller] = useState([]);
  const [dillerSelectedTabIndexIcerik] = useState(0);
  const [dillerFormDefaultEkAlan, setDillerFormDefaultEkAlan] = useState(null);
  const [dillerFormEkAlan, setDillerFormEkAlan] = useState(null);

  const [tip, setTip] = useState([]);
  const [tipDic, setTipDic] = useState({});

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteId, setDeleteIdDetail] = useState(null);
  const [showDeleteDialogDetail, setShowDeleteDialogDetail] = useState(false);
  const [isDeletingDetail, setIsDeletingDetail] = useState(false);
  const [isDeletingErrorMessageDetail, setIsDeletingErrorMessageDetail] = useState(null);

  const [saveErrors, setSaveErrors] = useState([[], []]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props.id]);
  const IS_ADD = useMemo(() => props.addnew, [props.addnew]);

  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DATA_DEFAULT) && _isEqual(ekAlanForms, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualEkAlan = _isEqual(ekAlanForms, ekAlanFormsDefault);
    const isEqualEkAlanDiller = _isEqual(dillerFormEkAlan, dillerFormDefaultEkAlan);

    return isEqualGenel && isEqualEkAlan && isEqualEkAlanDiller;
  }, [add, genelForm, genelFormDefault, ekAlanForms, ekAlanFormsDefault, dillerFormEkAlan, dillerFormDefaultEkAlan]);

  const LOADINGEKALAN = useMemo(() => {
    if (!loadingAlan && !loadingAlanDil && !loadingAlanSecim) {
      return false;
    }
    return true;
  }, [loadingAlan, loadingAlanDil, loadingAlanSecim]);

  useLayoutEffect(() => {
    if (props.eventType === 0) {
      setSaveErrors([[], []]);
      setEkAlanSelectedTabIndex(0);
      setTabs(TABS_DEFAULT);
    }
  }, [props.eventType, ID]);

  useLayoutEffect(() => {
    if (!saveErrors[0].includes("err")) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              alan_iliski_tip: data.alan_iliski_tip,
              magazalar: data.magazalar,
              siralama: String(data.siralama),
              aktif: data.aktif,
              detay: data.detay,
            };
            setGenelFormDefault(form);
            setGenelForm(form);
            setHataGenel(null);
            if (data.detay) {
							setTabs((prev) => {
								return immer(prev, (next) => {
									next[1].display = false;
								});
							});
            }else{
              setSelectedTabIndex(0);
            }
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setAdd(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DATA_DEFAULT);
        setGenelFormDefault(FORM_DATA_DEFAULT);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setLoading(false);
        setSelectedTabIndex(0);
      }
    }
  }, [ID, lastUpdateTime, IS_ADD, saveErrors]);

  useLayoutEffect(() => {
    if (!saveErrors[1].includes("err")) {
      if (!IS_ADD) {
        setLoadingAlan(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/ek_alan_alan`).then(({ data }) => {
            const forms = data.map((alan) => (
              {
                form: {
                  id: alan.id,
                  tanim: alan.tanim,
                  alan_tip: String(alan.alan_tip.key),
                  secim_degerleri: alan.secim_degerleri,
                  arayuzde_sor: alan.arayuzde_sor,
                  zorunlu: alan.zorunlu,
                  diller: alan.diller,
                  siralama: String(alan.siralama),
                  aktif: alan.aktif,
                },
                errors: null
              }
            ));
            setEkAlanFormsDefault(forms);
            setEkAlanForms(forms);
            setEkAlanDiller(forms.map((alan) => alan.form.diller));
            setEkAlanSecimleri(forms.map((el) => el.form.secim_degerleri));
            setHataAlan(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataAlan(true);
              }
            }
          }).finally(() => {
            setLoadingAlan(false);
          })
        }, 300);
        return () => {
          clearTimeout(debounce);
        }
      } else {
        setEkAlanFormsDefault([]);
        setEkAlanForms([]);
        setEkAlanDiller([]);
        setHataAlan(null);
        setLoadingAlan(false);
      }
    }
  }, [ID, lastUpdateTime, IS_ADD, saveErrors]);

  useLayoutEffect(() => {
    django('dil/mini_list').then(({ data }) => setDilMiniList(data)).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataDil(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    setLoadingAlanDil(true);
    const setDil = async () => {
      let diller = [];
      ekAlanDiller.forEach((dil) => {
        createDillerStates(dilMiniList, dil).then((states) => diller.push(states));
      })
      return diller;
    }
    setDil().then((state) => {
      setDillerFormEkAlan(state);
      setDillerFormDefaultEkAlan(state);
    }).finally(() => setLoadingAlanDil(false));

  }, [dilMiniList, ekAlanDiller]);

  useLayoutEffect(() => {
    setLoadingAlanSecim(true);
    const setDil = async () => {
      let ss = [];
      ekAlanSecimleri.forEach((secimler) => {
        let s = [];
        secimler.forEach((secim) => {
          createDillerStates2(dilMiniList, secim?.diller ?? []).then((states) => s.push({ deger: secim?.deger ?? "", diller: states }));
        });
        ss.push(s)
      })
      return ss;
    }
    setDil().then((state) => {
      setEkAlanSecimDeger(state);
      setEkAlanSecimDegerDefault(state);
    }).finally(() => setLoadingAlanSecim(false));
  }, [dilMiniList, ekAlanSecimleri]);

  useLayoutEffect(() => {
    django("ek_alan_tanim/alan_tip").then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setTipDic(dict);
      setTip(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
    if (property === "detay") {
      if (value === true) {
        setTabs((prev) => {
          return immer(prev, (next) => {
            next[1].display = false;
          });
        });
      } else {
        setTabs((prev) => {
          return immer(prev, (next) => {
            next[1].display = true;
          });
        });
      }
    }
  };

  const handleEkAlanChangeForm = (index, property, value) => {
    setEkAlanForms((prev) => {
      return immer(prev, (next) => {
        next[index]["form"][property] = value;
      });
    });
  }

  const handleDillerChangeFormEkAlan = (index, index2, property, value) => {
    setDillerFormEkAlan((prev) => {
      return immer(prev, (next) => {
        next[index][index2].form[property] = value;
      });
    });
  }

  const handleEkAlanAddNewTab = (ust, toggleIndex = '') => {
    setEkAlanAdd(false);
    setEkAlanForms((prev) => immer(prev, (next) => {
      next.push({ ...FORM_DATA_ALAN_DEFAULT });
    }));
    setEkAlanDiller((prev) => immer(prev, (next) => {
      next.push([]);
    }));
    setEkAlanSecimleri((prev) => immer(prev, (next) => {
      next.push([]);
    }));
  };

  const handleElemanSecimChangeForm = (index, index2, index3, value) => {
    if (index3 !== "") {
      setEkAlanSecimDeger((prev) => {
        return immer(prev, (next) => {
          next[index][index2].diller[index3].form["deger"] = value;
        });
      });
    } else {
      setEkAlanSecimDeger((prev) => {
        return immer(prev, (next) => {
          next[index][index2].deger = value;
        });
      });
    }

  }

  const handleElemanSecimAddNewRow = (index) => {
    createDillerStates2(dilMiniList, []).then((res) => {
      setEkAlanSecimDeger((prev) => immer(prev, (next) => {
        next[index].push({ deger: "", diller: res });
      }));
    })
  }

  const handleElemanSecimRemoveRow = (index, index2) => {
    setEkAlanSecimDeger((prev) => immer(prev, (next) => {
      next[index].splice(index2, 1);
    }));
  }

  const handleAdd = () => {
    setAdd(true);
    setGenelForm(FORM_DATA_DEFAULT);
    setGenelError(null);
    setEkAlanForms([]);
    setEkAlanDiller([]);
    setEkAlanSecimleri([]);
    setEkAlanAdd(true);
    setSelectedTabIndex(0);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[1].display = true;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
    setEkAlanAdd(true);
    if (genelForm.detay) {
      setTabs([
        { label: 'GENEL', disabled: false, hasError: false },
        { label: 'KOŞUL', disabled: false, hasError: false, display: false },
      ]);
    } else {
      setSelectedTabIndex(0);
      setTabs(TABS_DEFAULT);
    }
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    setEkAlanForms(ekAlanFormsDefault);
    setEkAlanDiller(ekAlanDiller.filter((x) => !_isEmpty(x)));
    setEkAlanSecimDeger(ekAlanSecimDegerDefault);
    setEkAlanAdd(true);
    if (!IS_ADD) {
      setAdd(false);
    }
    if (genelForm.detay) {
      setTabs((prev) => {
        return immer(prev, (next) => {
          next[0].hasError = false;
          next[1].hasError = false;
          next[1].display = true;
        });
      });
    } else {
      setSelectedTabIndex(0);
      setTabs((prev) => {
        return immer(prev, (next) => {
          next[0].hasError = false;
          next[1].hasError = false;
          next[1].display = false;
        });
      });
    }    

  };

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Ek Alan Tanım', pagename: 'ek alan', prop2: '' },
      { prop: 'alan_iliski_tip', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
      { prop: 'magazalar', empty: true, unique: false, multiple: false, multipletext: '', propname: '', pagename: '', prop2: '' },
    ]
    let errors = [];
    await checkAllList('ek_alan_tanim', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  };

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {
    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (!add ? _isEqual(genelForm, genelFormDefault) : _isEqual(genelForm, FORM_DATA_DEFAULT)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          const data = { ...genelForm, magazalar: genelForm.magazalar?.map((d) => d.id), siralama: genelForm.siralama ? Number(genelForm.siralama) : 0, alan_iliski_tip: genelForm.alan_iliski_tip?.map((d) => d.key) }
          if (_isEmpty(res)) {
            reqopt = {
              data: data,
              method: add ? 'POST' : 'PUT',
              id: null,
              successMessage: `Ek Alan ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Ek Alan ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabEkAlan = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = ekAlanFormsDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next) && _isEqual(dillerFormEkAlan[index], dillerFormDefaultEkAlan[index]) && _isEqual(ekAlanSecimDeger[index], ekAlanSecimDegerDefault[index]);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'alan_tip', prop2: '' },
          { prop: 'tanim', prop2: '' },
        ];
        const diller = dillerFormEkAlan[index].filter((dil) => (!_isEmpty(dil.form.baslik))).map((d) => d.form);
        const secenekler = ekAlanSecimDeger[index].filter((secim) => (!_isEmpty(secim.deger))).map((d) => ({ deger: d.deger, diller: d.diller.map((dil) => dil.form) }));
        const data = {
          ...next.form, siralama: next.form.siralama ? Number(next.form.siralama) : 0, diller: diller, secim_degerleri: secenekler, alan_tip: Number(next.form.alan_tip)
        }
        await checkAllTabs(next.form, (err) => handleErrorsEkAlan(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            if (isHas) {
              reqopt = {
                data: data,
                method: 'PUT',
                id: next.form.id,
                successMessage: `${next.form.ek_alan_tanim} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.ek_alan_tanim} güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `${next.form.ek_alan_tanim} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.ek_alan_tanim} eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    let errors = [[], []];

    let SAVEID = add ? null : ID;

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'icerik', request: [], error: [] },
      ];
      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in ekAlanForms) {
        const eleman = [...ekAlanForms];
        const res = await createRequestOptionsTabEkAlan(eleman[i], i)
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "ek_alan_tanim" : `ek_alan_tanim/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.ekalan.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (SAVEID !== null) {
              if (tab.name === 'icerik') {
                setEkAlanForms((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: { ...request.data, ek_alan_tanim: SAVEID },
                  url: request.id ? `ek_alan_tanim/${SAVEID}/ek_alan_alan/${request.id}` : `ek_alan_tanim/${SAVEID}/ek_alan_alan`
                }).then(() => {
                  setEkAlanAdd(true);
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setEkAlanAdd(false);
                  setEkAlanSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setEkAlanForms((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
          } else {
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }
    await start();
    if (SAVEID !== null) {
      setSaveErrors(errors);
      if (!add) {
        if (!errors[0].includes("err") || !errors[1].includes("err")) {
          setLastUpdateTime(Date.now());
        }
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? !errors[1].includes("err") ? false : true : true : true;
      });
    });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);

        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.ekalan.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteDetail = () => {
    setEkAlanAdd(false);
    setIsDeletingDetail(true);
    setIsDeletingErrorMessageDetail(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/ek_alan_alan/${detailDeleteId}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetail(false);
        setIsDeletingErrorMessageDetail(null);
      } else {
        setIsDeletingErrorMessageDetail(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetail(errorMessage);
    }).finally(() => {
      setIsDeletingDetail(false);
      setLastUpdateTime(Date.now());
    });
  }

  const handleDeleteDetailDilIndex = (index, index2) => {
    setDillerFormDefaultEkAlan((prev) => {
      return immer(prev, (next) => {
        next[index][index2].form = { ...DILLER_FORM_DEFAULT, dil_id: next[index][index2].form.dil_id };
        next[index][index2].errors = null;
      })
    });
  }

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsEkAlan = (errors, index) => {
    setEkAlanForms((prev) => {
      let errobj = { ...prev[index]["errors"], ...errors };
      let err = {};
      if (_has(errobj, 'dogrulama')) {
        const dogrulama = _pickBy(errobj['dogrulama'], _identity);
        if (_isEmpty(dogrulama)) {
          err = _pickBy({ ...errobj, 'dogrulama': null }, _identity);
        } else {
          err = _pickBy({ ...errobj, 'dogrulama': dogrulama }, _identity);
        }
      } else {
        err = _pickBy(errobj, _identity);
      }
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsEkAlanDil = (errors, index, index2) => {
    setDillerFormEkAlan((prev) => {
      const err = _pickBy({ ...prev[index][index2]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index][index2]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={loading}
            hata={hataGenel}
            add={add}

            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}

            onCheckErrors={(errors) => handleErrors(errors)}

            loadingAlan={LOADINGEKALAN}
            hataAlan={hataAlan}
            hataDil={hataDil}

            selectedTabIndex={ekAlanSelectedTabIndex}
            forms={ekAlanForms}
            onChangeFormEkAlan={handleEkAlanChangeForm}
            selectedTabIndexDil={dillerSelectedTabIndexIcerik}
            dilform={dillerFormEkAlan}
            onChangeDilForm={handleDillerChangeFormEkAlan}
            onAddNewTab={handleEkAlanAddNewTab}
            ekAlanAdd={ekAlanAdd}
            setDeleteId={setDeleteIdDetail}
            openDeleteForm={setShowDeleteDialogDetail}
            deleteDil={handleDeleteDetailDilIndex}
            onCheckErrorsEkAlan={(errors, index) => handleErrorsEkAlan(errors, index)}
            onCheckErrorsEkAlanDil={(errors, index, index2) => handleErrorsEkAlanDil(errors, index, index2)}
            secimler={ekAlanSecimDeger}
            onChangeSecimForm={handleElemanSecimChangeForm}
            onAddNewSecim={handleElemanSecimAddNewRow}
            onRemoveSecim={handleElemanSecimRemoveRow}
            tip={tip}
            tipDic={tipDic}
          />
        )}
        {selectedTabIndex === 1 && (
          <TabKosul
            ID={ID}
          />
        )}
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Ödeme Tanım siliniyor lütfen bekleyin'
                : 'Bu ödeme tanımı gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }

      {showDeleteDialogDetail &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetail
              ? isDeletingErrorMessageDetail
              : isDeletingDetail
                ? 'Menü içerik siliniyor lütfen bekleyin'
                : 'Bu menü içeriği gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetail ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetail}
          onSubmit={handleDeleteDetail}
          onCancel={() => setShowDeleteDialogDetail(false)}
        ></DialogConfirm>
      }
    </>
  );
}
export default OdemeTanimDetail;