import React, { useLayoutEffect, useState, useMemo } from 'react';

import { useSnackbar } from 'notistack';
import immer from 'immer';
import {
  isEqual as _isEqual,
} from 'lodash';

import django from '../../../../api/django';
import LayoutDetailWithoutTabs from '../../../base/LayoutDetailWithoutTabs';

import TabGenel from './UrunDetailTab_Genel';

const UrunDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [hataGenel, setHataGenel] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  const [genelFormDefault, setGenelFormDefault] = useState(null);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const UST_ID = useMemo(() => props.ustId, [props]);
  const PATH = useMemo(() => props.path, [props]);
  const TITLE = useMemo(() => genelForm?.urun?.tanim, [genelForm]);
  const IS_EQUAL = useMemo(() => {
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    return isEqualGenel;
  }, [genelForm, genelFormDefault]);

  useLayoutEffect(() => {
    setLoading(true);
    const debounce = setTimeout(() => {
      django(`${PATH}/${ID}`).then(({ data }) => {
        const form = {
          urun: data.urun,
          publish_google_shopping: data.publish_google_shopping,
          gmerchant_shopping_description: data.gmerchant_shopping_description,
          product_data: data.product_data,
          google_result: data.google_result,
          urun_eksik_bilgi: data.urun_eksik_bilgi,
          gmerchant_category: data.gmerchant_category
        };
        setGenelFormDefault(form);
        setGenelForm(form);
        setHataGenel(null);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            setHataGenel(true);
          }
        }
      }).finally(() => {
        setLoading(false);
        setGenelError(null);
      });
    }, 300);
    return () => {
      clearTimeout(debounce);
      setLoading(false);
    };
  }, [PATH, ID, lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    setGenelForm((prev) => {
      return immer(prev, (next) => {
        next[property] = value;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
  };

  const handleSave = () => {
    let requestoptions = null;
    const data = { ...genelForm, urun: genelForm.urun?.id ?? null };
    if (IS_EQUAL) {
      requestoptions = null;
    } else {
      requestoptions = {
        config: {
          method: 'PUT',
          url: `${PATH}/${ID}`,
          data: data
        },
        successMessage: `Ürün güncelleme başarıyla tamamlandı`,
        errorMessageUnexpected: `Ürün güncellenirken beklenmeyen bir hata oluştu`,
      }
    }
    const start = async () => {
      let errorCounts = 0;
      if (requestoptions !== null) {
        setGenelError(null);
        await django(requestoptions.config).then(({ data }) => {
          enqueueSnackbar(requestoptions.successMessage, { variant: 'success' });
          props.onUpdated();
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
            } else {
              setGenelError(error.response.data);
            }
          } else {
            enqueueSnackbar(requestoptions.errorMessageUnexpected, { variant: 'error' });
          }
          ++errorCounts;
        });
        if (errorCounts === 0) {
          setLastUpdateTime(Date.now());
        }
      }
    }
    start();
  };

  return (
    <>
      <LayoutDetailWithoutTabs
        loading={loading}
        title={TITLE}
        single={props.single}

        canClose={true}
        canOpenNew={true}
        canAdd={false}
        canReload={true}
        canSave={true}
        canCancel={true}
        canDelete={false}
        canPrevNext={true}
        disableCloseButton={loading}
        disableReloadButton={loading}
        disableCancelButton={loading || IS_EQUAL}
        disableSaveButton={loading || IS_EQUAL}
        disablePreviousButton={props.disablePreviousButton}
        disableNextButton={props.disableNextButton}

        onClickCloseButton={props.onClose}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => { window.open(`${window.location.origin}/${props.singledetail}/${props.ustId}/${ID}/`, '_blank'); }}
      >
        <TabGenel
          loading={loading}
          hata={hataGenel}
          form={genelForm}
          formdefault={genelFormDefault}
          error={genelError}
          onChangeForm={handleGenelChangeForm}
          ustId={UST_ID}
        />
      </LayoutDetailWithoutTabs>
    </>
  );
}

export default UrunDetail;