import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Typography,
  Tooltip
} from '@material-ui/core';

import { Editor } from "@tinymce/tinymce-react";
import { withStyles } from '@material-ui/core/styles';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors, handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing, palette }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemEditor: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: 5,
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: 0,
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
  };
};

const IcerikSablonDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATADIL = useMemo(() => props.hataDil, [props.hataDil]);
  const add = useMemo(() => props.add, [props.add]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.dilform?.length ?? 0, [props]);
  const FORM = useMemo(() => props?.dilform?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.dilform?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.dilform?.length > 0) {
      const copyForms = [...props.dilform];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.dil.tanim,
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    }
  }, [props.dilform]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const createTextFieldProps2 = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const handleChangeForm = (property, value) => {
    props.onChangeDilForm(selectedTabIndex, property, value);
  };

  const handleSetErrors = (errors) => {
    props.onCheckErrorsDil(errors, selectedTabIndex);
  }

  const handleDetailDelete = (index) => {
    props.deleteDetailWithIndex(index);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim')}
                          onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                          onBlur={() => handleInputErrors('iceriksablon', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Şablon Tanım', 'şablon')}
                          inputProps={{
                            maxLength: 250,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="tip">
                      <label className={classes.layoutFormItemLabel} htmlFor="tip">Tip *</label>
                      <Tooltip title={props.error?.tip ? props.error.tip[0] : _isNil(props.form?.tip) ? '' : props.tipListDic ? props.tipListDic[props.form.tip] : ''}>
                        <TextField
                          {...createTextFieldProps('tip')}
                          onChange={(e) => props.onChangeForm('tip', e.target.value)}
                          select
                          SelectProps={{
                            native: true,
                          }}
                        >
                          {props.tipList.map((tip) => (
                            <option key={tip.key} value={tip.key}>{tip.value}</option>
                          ))}
                        </TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="siralama">
                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                      <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                        <TextField
                          {...createTextFieldProps('siralama')}
                          onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                          type="number"
                          inputProps={{
                            min: -2147483648,
                            max: 2147483647,
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                      ></Checkbox>
                    </li>
                  </ul>
                </div>
                <div className={classes.layoutContainerTab}>
                  <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">DİLLER</span></div>
                  <>
                    {HATADIL && (
                      <Hata />
                    )}
                    {!HATADIL && (
                      <LayoutDetailVerticalTab
                        tabs={tabs}
                        selectedTabIndex={selectedTabIndex}
                        onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                        buttonShow={false}
                        delete={handleDetailDelete}
                        deleteButtonShow={true}
                      >
                        {COUNT_TABS === 0 && (
                          <div className="flex flex-col items-start justify-start p-4 space-y-4">
                            <Typography variant="body1">Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                          </div>
                        )}
                        {COUNT_TABS > 0 && (
                          <div className={classes.layoutContainer}>
                            <div className={classes.column}>
                              <ul className={classes.layoutFormList}>
                                {props.form.tip === "2" &&
                                  <>
                                    <li className={classes.layoutFormItem} id="konu">
                                      <label className={classes.layoutFormItemLabel} htmlFor="konu">Konu *</label>
                                      <Tooltip title={ERROR?.konu ? ERROR.konu[0] : _isNil(FORM?.konu) ? '' : FORM.konu}>
                                        <TextField
                                          {...createTextFieldProps2('konu')}
                                          onChange={(e) => handleChangeForm('konu', e.target.value)}
                                          onBlur={() => handleInputErrors2(FORM, 'konu', handleSetErrors)}
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                    <li className={classes.layoutFormItem} id="yazi_sablon">
                                      <label className={classes.layoutFormItemLabel} htmlFor="yazi_sablon">Yazı Şablon *</label>
                                      <Tooltip title={ERROR?.yazi_sablon ? ERROR.yazi_sablon[0] : _isNil(FORM?.yazi_sablon) ? '' : FORM.yazi_sablon}>
                                        <TextField
                                          {...createTextFieldProps2('yazi_sablon')}
                                          onChange={(e) => handleChangeForm('yazi_sablon', e.target.value)}
                                          onBlur={() => handleInputErrors2(FORM, 'yazi_sablon', handleSetErrors)}
                                          multiline
                                          rows={3}
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                    <li className={classes.layoutFormItemEditor} id="sablon">
                                      <label className={classes.layoutFormItemLabel} htmlFor="sablon">Mail Şablon *</label>
                                      <div style={{ borderWidth: 1, borderStyle: 'solid', borderColor: _has(ERROR, `sablon`) ? 'red' : 'transparent' }}>
                                        <Editor
                                          initialValue={_result(FORM, 'sablon') || ''}
                                          init={{
                                            plugins: [
                                              'advlist autolink lists link image charmap print preview anchor',
                                              'searchreplace visualblocks code fullscreen',
                                              'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo copy cut paste remove removeformat selectall | formatselect | fontselect | fontsizeselect |' +
                                              'bold italic underline lineheight backcolor forecolor blockquote | alignleft aligncenter ' +
                                              'alignright alignjustify | bullist numlist outdent indent | ' +
                                              'removeformat | code | help',
                                            language: 'tr',
                                            valid_elements: '+*[*]',
                                            valid_children : "+body[style]",
                                            verify_html: false
                                          }}
                                          onChange={(e) => handleChangeForm('sablon', e.target.getContent())}
                                          onBlur={() => handleInputErrors2(FORM, 'sablon', handleSetErrors)}
                                        />
                                      </div>
                                    </li>
                                    {_has(ERROR, `sablon`) && (
                                      <li className={classes.layoutFormItem}>
                                        <label className={classes.layoutFormItemLabel}></label>
                                        <Typography variant="caption" color="error">{_result(ERROR, `sablon[0]`)}</Typography>
                                      </li>
                                    )}
                                  </>
                                }
                                {props.form.tip === "3" &&
                                  <>
                                    <li className={classes.layoutFormItemEditor} id="pdf_sablon">
                                      <label className={classes.layoutFormItemLabel} htmlFor="pdf_sablon">Pdf Şablon *</label>
                                      <div style={{ borderWidth: 1, borderStyle: 'solid', borderColor: _has(ERROR, `pdf_sablon`) ? 'red' : 'transparent' }}>
                                        <Editor
                                          initialValue={_result(FORM, 'pdf_sablon') || ''}
                                          init={{
                                            plugins: [
                                              'advlist autolink lists link image charmap print preview anchor',
                                              'searchreplace visualblocks code fullscreen',
                                              'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo copy cut paste remove removeformat selectall | formatselect | fontselect | fontsizeselect |' +
                                              'bold italic underline lineheight backcolor forecolor blockquote | alignleft aligncenter ' +
                                              'alignright alignjustify | bullist numlist outdent indent | ' +
                                              'removeformat | code | help',
                                            language: 'tr',
                                            valid_elements: '+*[*]',
                                            valid_children : "+body[style]",
                                            verify_html: false
                                          }}
                                          onChange={(e) => handleChangeForm('pdf_sablon', e.target.getContent())}
                                          onBlur={() => handleInputErrors2(FORM, 'pdf_sablon', handleSetErrors)}
                                        />
                                      </div>
                                    </li>
                                    {_has(ERROR, `pdf_sablon`) && (
                                      <li className={classes.layoutFormItem}>
                                        <label className={classes.layoutFormItemLabel}></label>
                                        <Typography variant="caption" color="error">{_result(ERROR, `pdf_sablon[0]`)}</Typography>
                                      </li>
                                    )}
                                  </>
                                }
                              </ul>
                            </div>
                          </div>
                        )}
                      </LayoutDetailVerticalTab>
                    )}
                  </>
                </div>
              </div>
            </>
          )}
        </>
      }
    </>
  );
});

export default IcerikSablonDetailTabGenel;
