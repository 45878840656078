import React from 'react';
import Layout from '../../../base/LayoutTableDetailInside';
import Detail from './UrunDetail';


const COLUMNS_DEFAULT = [
  { id: 'urun',                     tableProp: 'urun.tanim',                    filterProp: 'urun.id',                  label: 'Ürün',                        element: 'select-multiple',   type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'urun',       multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null,  selectKey: null,  selectValue: null, newpath: "tanimlar/urun" },
  { id: 'gmerchant_category',       tableProp: 'gmerchant_category.tanim',      filterProp: 'gmerchant_category.id',    label: 'Google Merchant Kategori',    element: 'select-multiple',   type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'urun',       multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'tanim',    multipleSelectOptionViewProp: 'tanim',    shortDescription: null,  selectKey: null,  selectValue: null, newpath: null },
  { id: 'publish_google_shopping',  tableProp: 'publish_google_shopping',       filterProp: 'publish_google_shopping',  label: 'Ürün Verisi Gönderildi',      element: 'select',            type: 'boolean',  arrayTableProp: null,       show: true,   sortable: false,  highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: 'key', selectValue: 'value' },
  { id: 'olusturan_kullanici',      tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',   label: 'Oluşturan Kullanıcı',         element: 'select-multiple',   type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistiren_kullanici',     tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',  label: 'Değiştiren Kullanıcı',        element: 'select-multiple',   type: 'number',   arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'olusturma_zamani',         tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',         label: 'Oluşturma',                   element: 'input',             type: 'datetime', arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistirme_zamani',        tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',        label: 'Son Değişiklik',              element: 'input',             type: 'datetime', arrayTableProp: null,       show: true,   sortable: true,   highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
];

const PageEntegrasyonUrunList = (props) => {

  return (
    <>
      <Layout
        title="GOOGLE MERCHANT ÜRÜNLER"
        path={props.ID ? `gmerhantaccount/${props.ID}/gmerhanturun` : ""}
        ustId={props.ID}
        columnsDefault={COLUMNS_DEFAULT}
        detailComponent={Detail}
        storekey="gmerchant-urun"
        singledetail="tanimlar/gmerchant-urun"
        filtreButtonShow={false}
        manuelIslem={false}
        filter={false}
      />
    </>
  );
};

export default PageEntegrasyonUrunList;
