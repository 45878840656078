import React from 'react';
import clsx from 'clsx';

const ColorCard = ({ classNameColor }) => {
  return (
    <span className="flex items-center space-x-4">
      <div className={clsx('w-16 h-16 rounded-full', [`bg-${classNameColor}`])} />

      <div className="block">.{`{$}`}-{classNameColor}</div>
    </span>
  );
};

const Palettes = () => {
  const handleSwitchDarkMode = () => {
    document.documentElement.classList.toggle('dark');
  };

  return (
    <>
      <div className="grid gap-8 p-8 bg-palette-background-default text-palette-text-primary">

        <div>
          <h3>Typography</h3>

          <div className="grid grid-cols-3 gap-4 place-items-start">
            <ColorCard classNameColor="palette-text-primary" />
            <ColorCard classNameColor="palette-text-secondary" />
            <ColorCard classNameColor="palette-text-disabled" />
          </div>
        </div>

        <div>
          <h3>Buttons</h3>

          <div className="grid grid-cols-3 gap-4 place-items-start">
            <ColorCard classNameColor="palette-action-hover" />
            <ColorCard classNameColor="palette-action-selected" />
            <ColorCard classNameColor="palette-action-disabledBackground" />
            <ColorCard classNameColor="palette-action-disabled" />
            <ColorCard classNameColor="palette-action-active" />
          </div>
        </div>

        <div>
          <h3>Background</h3>

          <div className="grid grid-cols-3 gap-4 place-items-start">
            <ColorCard classNameColor="palette-background-default" />
            <ColorCard classNameColor="palette-background-paper" />
          </div>
        </div>

        <div>
          <h3>Divider</h3>

          <div className="grid grid-cols-3 gap-4 place-items-start">
            <ColorCard classNameColor="palette-divider" />
          </div>
        </div>

        <div>
          <small>$ = text|bg|border</small>
        </div>

      </div>

      <div className="fixed right-0 bottom-0 p-4">
        <button className="bg-primary-500 text-white rounded-sm cursor-pointer p-2" onClick={handleSwitchDarkMode}>dark mode</button>
      </div>
    </>
  );
};

export default Palettes;
