import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';

import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },
    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
  };
};




const UrunDetailTabBarkod = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.selectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  useLayoutEffect(() => {
    const copyForms = [...props.forms];
    const newTabs = copyForms.map((form) => {
      return {
        label: form.form.barkod,
        hasError: form.errors,
      };
    });
    setTabs(newTabs);
  }, [props.forms, props.genelBirim]);

  useLayoutEffect(() => {
    if (!props.barkodAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.barkodAdd, COUNT_TABS]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const handleChangeForm = (property, value) => {
    props.onChangeForm(selectedTabIndex, property, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <LayoutDetailVerticalTab
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                add={props.onAddNewTab}
                buttonText={"YENİ BARKOD EKLE"}
                buttonShow={props.barkodAdd}
                delete={handleDetailDelete}
              >
                {COUNT_TABS === 0 && (
                  <div className="flex flex-col items-start justify-start p-4 space-y-4">
                    <Typography variant="body1">Ürüne ait barkod bulunamadı.</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      onClick={props.onAddNewTab}
                    >YENİ BARKOD EKLE</Button>
                  </div>
                )}

                {COUNT_TABS > 0 && (
                  <>
                    <div className={classes.tabDiv}>
                      <div className={classes.column}>
                        <ul className={classes.formList}>
                          <li className={classes.formItem} id="barkod">
                            <label className={classes.formItemLabel} htmlFor="barkod">Barkod *</label>
                            <Tooltip title={ERROR?.barkod ? ERROR.barkod[0] : _isNil(FORM?.barkod) ? '' : FORM.barkod}>
                              <TextField
                                {...createTextFieldProps('barkod')}
                                onChange={(e) => handleChangeForm('barkod', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'barkod', handleSetErrors)}
                                inputProps={{
                                  maxLength: 13,
                                }}
                              ></TextField>
                            </Tooltip>
                          </li>
                          <li className={classes.formItem} id="birim">
                            <label className={classes.formItemLabel} htmlFor="kodu">Birim *</label>
                            <Tooltip title={ERROR?.birim ? ERROR.birim[0] : (FORM?.birim === "-1" || _isNil(FORM?.birim)) ? '' : props.barkodBirimListDic ? props.barkodBirimListDic[FORM.birim] : ''}>
                              <TextField
                                {...createTextFieldProps('birim')}
                                onChange={(e) => handleChangeForm('birim', e.target.value)}
                                onBlur={() => handleInputErrors2(FORM, 'birim', handleSetErrors)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                <option value={'-1'} >Seçilmedi</option>
                                {props?.barkodBirimList?.map((birim) => (
                                  <option key={birim.id} value={birim.id}>{birim.tanim}</option>
                                ))}
                              </TextField>
                            </Tooltip>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </LayoutDetailVerticalTab>
            </div>
          )}
        </>
      }
    </>
  );
});

export default UrunDetailTabBarkod;
