import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Tooltip,
  Typography
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { handleInputErrors, handleInputErrors2 } from '../../base/InputErrors';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { Hata } from '../../base/Hata';

const styles = ({ palette, spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    layoutContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutContainerTab: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: spacing(1, 0, 1, 0),
    },
    layoutTabHeader: {
      backgroundColor: palette.toolbar,
      minHeight: 36,
      width: '100%',
      padding: spacing(1, 2, 1, 2),
      border: '1px solid white',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
  };
};


const OzellikDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const PATH = useMemo(() => props.path, [props.path]);
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const LOADINGORAN = useMemo(() => props.loadingoran, [props.loadingoran]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const HATAORAN = useMemo(() => props.hataOran, [props.hataOran]);
  const add = useMemo(() => props.add, [props.add]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.defaultSelectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.formsOran?.length ?? 0, [props.formsOran]);
  const FORM = useMemo(() => props?.formsOran?.[selectedTabIndex]?.form ?? null, [props.formsOran, selectedTabIndex]);
  const ERROR = useMemo(() => props?.formsOran?.[selectedTabIndex]?.errors ?? null, [props.formsOran, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.formsOran?.length > 0) {
      const copyForms = [...props.formsOran];
      const newTabs = copyForms.map((form) => {
        return {
          label: form.ulke.tanim,
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.formsOran]);


  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const createTextFieldPropsOran = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const handleChangeForm = (property, value) => {
    props.onChangeFormOran(selectedTabIndex, property, value);
  };

  const handleSetErrors = (errors) => {
    props.onCheckErrorsOran(errors, selectedTabIndex);
  }

  const handleDetailDelete = (index) => {
    if (props.formsOran[index].form.id) {
      props.openDeleteForm(true);
      props.setDeleteId(props.formsOran[index].form.id);
    } else {
      props.deleteDetailWithIndex(index);
    }
  }


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.container}>
                <div className={classes.layoutContainer}>
                  <ul className={classes.layoutFormList}>
                    <li className={classes.layoutFormItem} id="tanim">
                      <label className={classes.layoutFormItemLabel} htmlFor="tanim">Grup Tanım *</label>
                      <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                        <TextField
                          {...createTextFieldProps('tanim')}
                          onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                          onBlur={() => handleInputErrors('grup', props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Grup Tanım', 'grup')}
                          inputProps={{
                            maxLength: 250,
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="siralama">
                      <label className={classes.layoutFormItemLabel} htmlFor="siralama">Sıralama</label>
                      <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                        <TextField
                          {...createTextFieldProps('siralama')}
                          onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                          type="number"
                          inputProps={{
                            min: -2147483648,
                            max: 2147483647,
                            step: 1,
                            style: { textAlign: 'right' }
                          }}
                        ></TextField>
                      </Tooltip>
                    </li>
                    <li className={classes.layoutFormItem} id="aktif">
                      <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                      <Checkbox
                        name="aktif"
                        color="primary"
                        checked={_result(props.form, 'aktif', false)}
                        onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                      ></Checkbox>
                    </li>
                  </ul>
                </div>

                {PATH !== "vergi_tip" && (
                  <div className={classes.layoutContainerTab}>
                    <div className={classes.layoutTabHeader}><span className="font-medium truncate text-white text-base">ORANLAR</span></div>
                    {!LOADINGORAN && (
                      <>
                        {HATAORAN && (
                          <Hata />
                        )}
                        {!HATAORAN && (
                          <LayoutDetailVerticalTab
                            tabs={tabs}
                            selectedTabIndex={selectedTabIndex}
                            onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                            buttonShow={false}
                            delete={handleDetailDelete}
                            deleteButtonShow={true}
                          >
                            {COUNT_TABS === 0 && (
                              <div className="flex flex-col items-start justify-start p-4 space-y-4">
                                <Typography variant="body1">Panele ait tanımlanmış bir dil bulunamadı.Lütfen önce bir dil tanımlayın.</Typography>
                              </div>
                            )}
                            {COUNT_TABS > 0 && (
                              <div className={classes.layoutContainer}>
                                <div className={classes.column}>
                                  <ul className={classes.layoutFormList}>
                                    <li className={classes.layoutFormItem} id="oran">
                                      <label className={classes.layoutFormItemLabel} htmlFor="oran">Oran *</label>
                                      <Tooltip title={ERROR?.oran ? ERROR.oran[0] : _isNil(FORM?.oran) ? '' : FORM.oran}>
                                        <TextField
                                          {...createTextFieldPropsOran('oran', '')}
                                          onChange={(e) => handleChangeForm('oran', e.target.value)}
                                          onBlur={() => handleInputErrors2(FORM, 'oran', handleSetErrors)}
                                          type="number"
                                          inputProps={{
                                            style: { textAlign: 'right' }
                                          }}
                                        ></TextField>
                                      </Tooltip>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </LayoutDetailVerticalTab>
                        )}
                      </>
                    )}
                  </div>
                )}


              </div>
            </>
          )}
        </>
      }
    </>
  );

});

export default OzellikDetailTabGenel;