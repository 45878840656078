import React, { useState, useLayoutEffect, useMemo, useCallback } from 'react';

import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  Button,
  TextField,
  Typography,
  Tooltip,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import LayoutDetailVerticalTab from '../../base/LayoutDetailVerticalTab';
import { handleInputErrors2 } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    container: {
      width: 'max-content',
      minWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    tabDiv: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      padding: spacing(1),
    },
    column: {
      '& + &': {
        marginLeft: 32,
      },
      '& .MuiTypography-root': {
        margin: spacing(0, 0, 2, 0),
      },
    },
    formList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
    },
    formItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    formItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    layoutFormItemCaptionText: {
      fontStyle: 'italic',
      fontSize: "11px !important",
    },
  };
};


const EntZamanlama = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(props.defaultSelectedTabIndex);

  const COUNT_TABS = useMemo(() => props?.forms.length ?? 0, [props]);
  const FORM = useMemo(() => props?.forms?.[selectedTabIndex]?.form ?? null, [props, selectedTabIndex]);
  const ERROR = useMemo(() => props?.forms?.[selectedTabIndex]?.errors ?? null, [props, selectedTabIndex]);

  useLayoutEffect(() => {
    if (props.forms.length > 0) {
      const copyForms = [...props.forms];
      const newTabs = copyForms.map((form) => {
        return {
          label: props.entPeriyotTipDic[form.form.periyot_tip] + ' - ' + (form.form.periyot_tip === '2' ? form.form.saatdakika : form.form.zaman_araligi),
          hasError: form.errors,
        };
      });
      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [props.forms, props.entPeriyotTipDic]);



  useLayoutEffect(() => {
    if (!props.zamanAdd) {
      setSelectedTabIndex(COUNT_TABS - 1);
    } else {
      setSelectedTabIndex(0);
    }
  }, [props.zamanAdd, COUNT_TABS]);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(FORM, `${name}`) || defaultValue,
      error: _has(ERROR, `${name}`),
      fullWidth: true,
    };
  }, [FORM, ERROR]);

  const handleChangeForm = (property, value) => {
    props.onChangeForm(selectedTabIndex, property, value);
  };

  const handleDetailDelete = (index) => {
    props.openDeleteForm(true);
    props.setDeleteId(props.forms[index].form.id);
  }

  const handleSetErrors = (errors) => {
    props.onCheckErrors(errors, selectedTabIndex);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <div className={classes.container}>
              <LayoutDetailVerticalTab
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onChangeTabIndex={(index) => setSelectedTabIndex(index)}
                add={props.onAddNewTab}
                buttonText={"YENİ ZAMANLAYICI EKLE"}
                buttonShow={props.zamanAdd}
                delete={handleDetailDelete}
              >
                {COUNT_TABS === 0 && (
                  <div className="flex flex-col items-start justify-start p-4 space-y-4">
                    <Typography variant="body1">
                      Entegrasyona ait bir zamanlayıcı bulunamadı.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      onClick={props.onAddNewTab}
                    >YENİ ZAMANLAYICI EKLE</Button>
                  </div>
                )}

                {COUNT_TABS > 0 && (
                  <>
                    <div className={classes.tabDiv}>
                      <div className={classes.column}>
                        <ul className={classes.formList}>

                          <li className={classes.formItem} id="islem_tipi">
                            <label className={classes.formItemLabel} htmlFor="islem_tipi">İşlem Tipi</label>
                            <Tooltip title={ERROR?.islem_tipi ? ERROR.islem_tipi[0] : _isNil(FORM?.islem_tipi) ? '' : props.entIslemTipDic ? props.entIslemTipDic[FORM.islem_tipi] : ''}>
                              <TextField
                                {...createTextFieldProps('islem_tipi')}
                                onChange={(e) => handleChangeForm('islem_tipi', e.target.value)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                {props?.entIslemTip?.map((et) => (
                                  <option key={et.key} value={et.key}>{et.value}</option>
                                ))}
                              </TextField>
                            </Tooltip>
                          </li>

                          <li className={classes.formItem} id="periyot_tip">
                            <label className={classes.formItemLabel} htmlFor="periyot_tip">Zaman Periyodu</label>
                            <Tooltip title={ERROR?.periyot_tip ? ERROR.periyot_tip[0] : _isNil(FORM?.periyot_tip) ? '' : props.entPeriyotTipDic ? props.entPeriyotTipDic[FORM.periyot_tip] : ''}>
                              <TextField
                                {...createTextFieldProps('periyot_tip')}
                                onChange={(e) => handleChangeForm('periyot_tip', e.target.value)}
                                select
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                {props?.entPeriyotTip?.map((et) => (
                                  <option key={et.key} value={et.key}>{et.value}</option>
                                ))}
                              </TextField>
                            </Tooltip>
                          </li>
                          {FORM.periyot_tip !== '2' && (
                            <li className={classes.formItem} id="zaman_araligi">
                              <label className={classes.formItemLabel} htmlFor="zaman_araligi">Zaman Aralığı</label>
                              <Tooltip title={ERROR?.zaman_araligi ? ERROR.zaman_araligi[0] : _isNil(FORM?.zaman_araligi) ? '' : FORM.zaman_araligi}>
                                <TextField
                                  {...createTextFieldProps('zaman_araligi')}
                                  onChange={(e) => handleChangeForm('zaman_araligi', e.target.value)}
                                  type="number"
                                  onBlur={() => {
                                    handleInputErrors2(FORM, 'zaman_araligi', handleSetErrors);
                                  }}
                                  inputProps={{
                                    min: 1,
                                    max: 1439,
                                    step: 1,
                                    style: { textAlign: 'right' }
                                  }}
                                ></TextField>
                              </Tooltip>
                            </li>
                          )}

                          {FORM.periyot_tip === '2' && (
                            <>
                              <li className={classes.formItem} id="saatdakika">
                                <label className={classes.formItemLabel} htmlFor="saatdakika">Saat</label>
                                <Tooltip title={ERROR?.saat ? ERROR.saat[0] : ERROR?.dakika ? ERROR.dakika[0] : _isNil(FORM?.saatdakika) ? '' : FORM.saatdakika}>
                                  <TextField
                                    {...createTextFieldProps('saatdakika')}
                                    onChange={(e) => { handleChangeForm('saatdakika', e.target.value) }}
                                    onBlur={() => {
                                      handleInputErrors2(FORM, 'saatdakika', handleSetErrors);
                                    }}
                                    type="time"
                                  ></TextField>
                                </Tooltip>
                              </li>
                            </>
                          )}
                          <li className={classes.formItem} id="aciklama">
                            {FORM.periyot_tip === "0" && (
                              <Typography variant="caption" color="textPrimary" className={classes.layoutFormItemCaptionText}>{`“Her ${FORM.zaman_araligi ? FORM.zaman_araligi : '?'} dakikada bir çalışır.”`}</Typography>
                            )}
                            {FORM.periyot_tip === "1" && (
                              <Typography variant="caption" color="textPrimary" className={classes.layoutFormItemCaptionText}>{`“Her ${FORM.zaman_araligi ? FORM.zaman_araligi : '?'} saatte bir çalışır.”`}</Typography>
                            )}
                            {FORM.periyot_tip === "2" && (
                              <Typography variant="caption" color="textPrimary" className={classes.layoutFormItemCaptionText}>{`“Her gün saat ${FORM.saatdakika ? FORM.saatdakika : '?'} itibari ile bir kez çalışır.”`}</Typography>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </LayoutDetailVerticalTab>
            </div>
          )}
        </>
      }
    </>
  );
});

export default EntZamanlama;
