import React, { useCallback, useMemo, useState } from 'react';
import {
  has as _has,
  result as _result,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Checkbox,
  Tooltip,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';

import {
  Close as IconClose,
  Check as IconCheck
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import { handleInputErrors } from '../../base/InputErrors';
import { Hata } from '../../base/Hata';

const styles = ({ spacing }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      padding: 0,
      '& + &': {
        marginLeft: 32,
      },
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& + &': {
        marginTop: 5,
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    keyDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: 10,
      flexWrap: "wrap"
    },
    keyCloud: {
      padding: 4,
      border: "1px solid #eacfea",
      position: "relative",
      borderRadius: 4,
      margin: "0 5px 5px",
    },
    button: {
      position: 'absolute',
      right: 5,
      borderRadius: 4,
      backgroundColor: '#eacfea',
      '&:hover': {
        background: '#976797',
        color: '#fff'
      },
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    button2: {
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    },
    layoutFormItemCaptionText: {
      fontStyle: 'italic',
      fontSize: "11px !important",
    },
  };
};

const UlkeSehirIlceDetailTabGenel = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const add = useMemo(() => props.add, [props.add]);

  const [searchKey, setSearchKey] = useState("");
  const [searchKeyError, setSearchKeyError] = useState(null);

  const createTextFieldProps = useCallback((name, defaultValue = '') => {
    return {
      name,
      variant: 'outlined',
      size: 'small',
      value: _result(props.form, `${name}`) || defaultValue,
      error: _has(props.error, `${name}`),
      fullWidth: true,
    };
  }, [props.error, props.form]);

  const handleChangeSearchKey = (value) => {
    setSearchKey(value);
  }

  const handleAddOrRemoveSearchKeys = (type, value) => {
    let list = [...props.form.alternatif_kelimeler];
    const val = value.trim();
    if (type === "add") {
      if (!list.includes(val) && val) {
        list.push(val);
        setSearchKey("");
        setSearchKeyError(null);
      } else {
        const errors = val ? "Aynı kelime birden fazla kullanılamaz" : null;
        setSearchKeyError(errors);
      }
    } else {
      list = list.filter(k => k !== val);
    }
    props.onChangeForm("alternatif_kelimeler", list);
  }

  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>
                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="tanim">
                    <label className={classes.layoutFormItemLabel} htmlFor="tanim">Tanım *</label>
                    <Tooltip title={props.error?.tanim ? props.error.tanim[0] : _isNil(props.form?.tanim) ? '' : props.form.tanim}>
                      <TextField
                        {...createTextFieldProps('tanim')}
                        onChange={(e) => props.onChangeForm('tanim', e.target.value)}
                        onBlur={() => {
                          if (props.location === "ulke") {
                            handleInputErrors(props.path, props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Ülke Tanım', 'ülke')
                          } else if (props.location === "sehir") {
                            handleInputErrors(props.path, props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'Şehir Tanım', 'şehir')
                          } else {
                            handleInputErrors(props.path, props.form, props.formdefault, props.onCheckErrors, add, 'tanim', true, true, false, '', 'İlçe Tanım', 'ilçe')
                          }
                        }}
                        inputProps={{ maxLength: 250 }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  {props.location === "ulke" && (
                    <li className={classes.layoutFormItem} id="kodu">
                      <label className={classes.layoutFormItemLabel} htmlFor="kodu">Ülke Kodu *</label>
                      <Tooltip title={props.error?.kodu ? props.error.kodu[0] : _isNil(props.form?.kodu) ? '' : props.form.kodu}>
                        <TextField
                          {...createTextFieldProps('kodu', '')}
                          onChange={(e) => props.onChangeForm('kodu', e.target.value)}
                          onBlur={() => handleInputErrors(props.path, props.form, props.formdefault, props.onCheckErrors, add, 'kodu', true, false, false, '', '', '')}
                          inputProps={{ maxLength: 10 }}
                        >
                        </TextField>
                      </Tooltip>
                    </li>
                  )}
                  {props.location === "ulke" && (
                    <li className={classes.layoutFormItem} id="telefon_kodu">
                      <label className={classes.layoutFormItemLabel} htmlFor="telefon_kodu">Telefon Kodu *</label>
                      <Tooltip title={props.error?.telefon_kodu ? props.error.telefon_kodu[0] : _isNil(props.form?.telefon_kodu) ? '' : props.form.telefon_kodu}>
                        <TextField
                          {...createTextFieldProps('telefon_kodu')}
                          onChange={(e) => props.onChangeForm('telefon_kodu', e.target.value)}
                          onBlur={() => handleInputErrors(props.path, props.form, props.formdefault, props.onCheckErrors, add, 'telefon_kodu', true, false, false, '', '', '')}
                          inputProps={{ maxLength: 5 }}
                        >
                        </TextField>
                      </Tooltip>
                    </li>
                  )}
                  {props.location === "sehir" && (
                    <li className={classes.layoutFormItem} id="plaka">
                      <label className={classes.layoutFormItemLabel} htmlFor="plaka">Plaka *</label>
                      <Tooltip title={props.error?.plaka ? props.error.plaka[0] : _isNil(props.form?.plaka) ? '' : props.form.plaka}>
                        <TextField
                          {...createTextFieldProps('plaka')}
                          onChange={(e) => props.onChangeForm('plaka', e.target.value)}
                          onBlur={() => handleInputErrors(props.path, props.form, props.formdefault, props.onCheckErrors, add, 'plaka', true, false, false, '', '', '')}
                          inputProps={{ maxLength: 10 }}
                        >
                        </TextField>
                      </Tooltip>
                    </li>
                  )}

                  <li className={classes.layoutFormItem} id="siralama">
                    <label className={classes.layoutFormItemLabel} htmlFor="tanim">Sıralama</label>
                    <Tooltip title={props.error?.siralama ? props.error.siralama[0] : _isNil(props.form?.siralama) ? '' : props.form.siralama}>
                      <TextField
                        {...createTextFieldProps('siralama')}
                        onChange={(e) => props.onChangeForm('siralama', e.target.value)}
                        type="number"
                        inputProps={{
                          min: -2147483648,
                          max: 2147483647,
                          step: 1,
                          style: { textAlign: 'right' }
                        }}
                      ></TextField>
                    </Tooltip>
                  </li>

                  <li className={classes.layoutFormItem} id="aktif">
                    <label className={classes.layoutFormItemLabel} htmlFor="aktif">Aktif</label>
                    <Checkbox
                      name="aktif"
                      color="primary"
                      checked={_result(props.form, 'aktif', false)}
                      onChange={(e, checked) => props.onChangeForm('aktif', checked)}
                    ></Checkbox>
                  </li>
                </ul>

                <ul className={classes.layoutFormList}>
                  <li className={classes.layoutFormItem} id="alternatif_kelimeler">
                    <label className={classes.layoutFormItemLabel} htmlFor="alternatif_kelimeler">Alternatif Tanımlar</label>
                    <Tooltip title={searchKeyError ? searchKeyError : searchKey == null ? '' : searchKey}>
                      <TextField
                        name='alternatif_kelimeler'
                        variant='outlined'
                        size='small'
                        value={searchKey}
                        fullWidth={true}
                        onChange={(e) => handleChangeSearchKey(e.target.value)}
                        error={searchKeyError ? true : false}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            handleAddOrRemoveSearchKeys("add", searchKey)
                          }
                        }}
                        InputProps={{
                          endAdornment:
                            <Tooltip title="Ekle">
                              <IconButton
                                size="small"
                                disableRipple
                                className={classes.button}
                                onClick={() => handleAddOrRemoveSearchKeys("add", searchKey)}
                              >
                                <IconCheck />
                              </IconButton>
                            </Tooltip>
                        }}
                        inputProps={{ maxLength: 50 }}
                      ></TextField>
                    </Tooltip>
                  </li>
                  <li className={classes.layoutFormItem} id="alternatif_kelimeler">
                    <div className={classes.keyDiv}>
                      {props.form && props.form.alternatif_kelimeler && props.form.alternatif_kelimeler.map((key, index) => (
                        <div className={classes.keyCloud} key={index}>
                          <span >{key}</span>
                          <Tooltip title="Sil">
                            <IconButton
                              size="small"
                              disableRipple
                              className={classes.button2}
                              onClick={() => handleAddOrRemoveSearchKeys("delete", key)}
                            >
                              <IconClose />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                  </li>
                  {props.extraTanim.length > 0 && (
                    <li className={classes.layoutFormItem} style={{ flexDirection: "column" }}>
                      <div className="flex w-full justify-start">
                        <section className="w-full border border-palette-action-selected bg-palette-background-default rounded">
                          <header className="flex items-center justify-between py-2 px-4">
                            <Button
                              className="pointer-events-none"
                              color="inherit"
                            >İLİŞKİLİ TANIMLAR
                            </Button>
                          </header>
                          <main className="flex flex-wrap items-center justify-start border-t border-palette-action-hover py-1 max-h-48 overflow-y-auto">
                            {props.extraTanim.map((item) => (
                              <div
                                key={item.id}
                                className="bg-palette-background-paper p-1 cursor-pointer"
                                style={{ border: "1px solid #eacfea", borderRadius: 4, margin: (0, 5, 5) }}
                                onClick={() => {
                                  if (props.form?.tanim === "") {
                                    props.onChangeForm('tanim', item.tanim)
                                  } else {
                                    handleAddOrRemoveSearchKeys("add", item.tanim)
                                  }
                                }}
                              >
                                <span>{item.tanim}</span>
                              </div>
                            ))}
                          </main>
                        </section>
                      </div>
                      <div className="flex w-full justify-start">
                        <Typography variant="caption" color="textPrimary" className={classes.layoutFormItemCaptionText}>“İlişkili tanımların üzerine tıklayarak alternatif tanımlara ekleyebilirsiniz.”</Typography>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </>
          )}
        </>
      }
    </>
  );
});

export default UlkeSehirIlceDetailTabGenel;