import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import immer from 'immer';

import moment from 'moment';

import {
  isEqual as _isEqual,
  pickBy as _pickBy,
  identity as _identity,
  isEmpty as _isEmpty,
  has as _has,
  trimEnd as _trimEnd,
} from 'lodash';

import django from '../../../api/django';
import URLS from '../../../urls';
import { asyncForEach, SlugMaker } from '../../../helpers/helpers';

import LayoutDetail from '../../base/LayoutDetail';
import DialogConfirm from '../../../components/DialogConfirm';
import { checkAllList, checkAllList2 } from '../../base/InputErrors';

import TabGenel from './FormTanimDetailTab_Genel';
import TabEleman from './FormTanimDetailTab_Eleman';
import TabIsleyiciAyar from './FormTanimDetailTab_İsleme';

const PATH = 'form';

const TABS_DEFAULT = [
  { label: 'GENEL', disabled: false, hasError: false },
  { label: 'FORM ELEMANLAR', disabled: false, hasError: false },
  { label: 'FORM İŞLEME AYARLARI', disabled: false, hasError: false },
];

const FORM_DEFAULT = {
  tanim: '',
  slug: '',
  adres: '',
  siteler: [],
  kullanici_al: false,
  yayina_baslangic: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
  yayin_bitis: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
  pasif_sayfa_basligi: '',
  aktif_sayfa_basligi: '',
  pasif_formset_html: '',
  siralama: '0',
  aktif: true,
  diller: [],
};

const DILLER_FORM_DEFAULT = {
  dil_id: '',
  tanim: '',
  kisa_tanim: ''
};

const ELEMAN_FORM_DEFAULT = {
  form: {
    tanim: "Tanımsız",
    tip: "0",
    secim_degerleri: [],
    secim_modeli: "-1",
    dogrulama: {
      min_val: "",
      max_val: "",
      default: "",
    },
    zorunlu: true,
    secim_oto_tamamlamali_calissin: true,
    siralama: '0',
    aktif: true,
    diller: []
  },
  errors: null
}

const DILLER_FORM_DEFAULT2 = {
  dil_id: '',
  baslik: '',
  yardim: ''
};

const DILLER_FORM_DEFAULT3 = {
  dil_id: '',
  deger: '',
};

const ISLEYICI_FORM_DEFAULT = {
  form: {
    tip: "0",
  },
  errors: null
}

const FORM_ISLEME_AYAR_EPOSTA = {
  form: {
    gonderecek_mail_adresi: "",
    gonderilecek_mail_adresi: "",
    konu: "",
    tip: "emailayar",
    isleyici: null
  },
  errors: null
}

const FORM_ISLEME_AYAR_FORMPOST = {
  form: {
    gonderilecek_adres: "",
    tip: "formpost",
    isleyici: null
  },
  errors: null
}

const FORM_ISLEME_AYAR_OTOCEVAP = {
  form: {
    gonderecek_mail_adresi: "",
    mail_sablon: "",
    konu: "",
    tip: "otocevap",
    isleyici: null
  },
  errors: null
}

const createDillerStates = async (diller, Diller) => {
  const states = [];
  diller.sort((a, b) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...DILLER_FORM_DEFAULT,
        dil_id: dil.id,
      },
      errors: null,
    };

    const d = Diller?.find((bd) => bd.dil_id === dil.id);

    if (d) {
      data.form.dil_id = d.dil_id;
      data.form.tanim = d.tanim;
      data.form.kisa_tanim = d.kisa_tanim;
    }
    states.push(data);
  });
  return states;
};

const createDillerStates2 = async (diller, Diller) => {
  const states = [];
  diller.sort((a, b) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...DILLER_FORM_DEFAULT2,
        dil_id: dil.id,
      },
      errors: null,
    };

    const d = Diller?.find((bd) => bd.dil_id === dil.id);

    if (d) {
      data.form.dil_id = d.dil_id;
      data.form.baslik = d.baslik;
      data.form.yardim = d.yardim;
    }
    states.push(data);
  });
  return states;
};

const createDillerStates3 = async (diller, Diller) => {
  const states = [];
  diller.sort((a, b) => a.id - b.id).forEach((dil) => {
    const data = {
      dil: {
        id: dil.id,
        tanim: dil.tanim,
      },
      form: {
        ...DILLER_FORM_DEFAULT3,
        dil_id: dil.id,
      },
      errors: null,
    };

    const d = Diller?.find((bd) => bd.dil_id === dil.id);

    if (d) {
      data.form.dil_id = d.dil_id;
      data.form.deger = d.deger;
    }
    states.push(data);
  });
  return states;
};

const createAyarlar = async (isleyiciAyar, ID) => {
  const ayarlar = [];
  for (let ayar of isleyiciAyar) {
    if (ayar.tip === "1") {
      const res1 = (await django(`${PATH}/${ID}/isleyici/${ayar.id}/emailayar`)).data;
      let ayar1 = {
        form: {
          gonderecek_mail_adresi: "",
          gonderilecek_mail_adresi: "",
          konu: "",
          tip: "emailayar",
          isleyici: ayar.id
        },
        errors: null
      }
      if (res1.length > 0) {
        ayar1 = {
          form: {
            id: res1[0].id,
            gonderecek_mail_adresi: res1[0].gonderecek_mail_adresi,
            gonderilecek_mail_adresi: res1[0].gonderilecek_mail_adresi,
            konu: res1[0].konu,
            tip: "emailayar",
            isleyici: ayar.id
          },
          errors: null
        }
      }
      ayarlar.push(ayar1);
    } else if (ayar.tip === "2") {
      const res2 = (await django(`${PATH}/${ID}/isleyici/${ayar.id}/formpost`)).data;
      let ayar2 = {
        form: {
          gonderilecek_adres: "",
          tip: "formpost",
          isleyici: ayar.id
        },
        errors: null
      }
      if (res2.length > 0) {
        ayar2 = {
          form: {
            id: res2[0].id,
            gonderilecek_adres: res2[0].gonderilecek_adres,
            tip: "formpost",
            isleyici: ayar.id
          },
          errors: null
        }
      }
      ayarlar.push(ayar2);
    } else if (ayar.tip === "3") {
      const res3 = (await django(`${PATH}/${ID}/isleyici/${ayar.id}/otocevap`)).data;
      let ayar3 = {
        form: {
          gonderecek_mail_adresi: "",
          mail_sablon: "",
          konu: "",
          tip: "otocevap",
          isleyici: ayar.id
        },
        errors: null
      }
      if (res3.length > 0) {
        ayar3 = {
          form: {
            id: res3[0].id,
            gonderecek_mail_adresi: res3[0].gonderecek_mail_adresi,
            mail_sablon: res3[0].mail_sablon,
            konu: res3[0].konu,
            tip: "otocevap",
            isleyici: ayar.id
          },
          errors: null
        }
      }
      ayarlar.push(ayar3);
    } else {
      const ayar4 = {
        form: {
          tip: "",
          isleyici: ayar.id
        },
        errors: null
      }

      ayarlar.push(ayar4);
    }
  }
  return ayarlar;
}

const FormTanimDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadingDil, setLoadingDil] = useState(true);
  const [loadingEleman, setLoadingEleman] = useState(true);
  const [loadingElemanDil, setLoadingElemanDil] = useState(true);
  const [loadingElemanSecim, setLoadingElemanSecim] = useState(true);
  const [loadingIsleyici, setLoadingIsleyici] = useState(true);
  const [loadingIsleyiciAyar, setLoadingIsleyiciAyar] = useState(true);

  const [hataGenel, setHataGenel] = useState(null);
  const [hataEleman, setHataEleman] = useState(null);
  const [hataIsleyici, setHataIsleyici] = useState(null);
  const [hataIsleyiciAyar, setHataIsleyiciAyar] = useState(null);
  const [hataDil, setHataDil] = useState(null);

  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [add, setAdd] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS_DEFAULT);

  const [genelFormDefault, setGenelFormDefault] = useState(FORM_DEFAULT);
  const [genelForm, setGenelForm] = useState(null);
  const [genelError, setGenelError] = useState(null);
  const [dillerSelectedTabIndex] = useState(0);
  const [dillerFormDefault, setDillerFormDefault] = useState(null);
  const [dillerForm, setDillerForm] = useState(null);
  const [dillerFormAdd, setDillerFormAdd] = useState(null);
  const [tanimDilleri, setTanimDilleri] = useState([]);

  const [elemanForm, setElemanForm] = useState([]);
  const [elemanFormDefault, setElemanFormDefault] = useState([]);
  const [elemanSelectedTabIndex, setElemanSelectedTabIndex] = useState(0);
  const [elemanAdd, setElemanAdd] = useState(true);

  const [elemanDillerSelectedTabIndex] = useState(0);
  const [elemanDillerFormDefault, setElemanDillerFormDefault] = useState(null);
  const [elemanDillerForm, setElemanDillerForm] = useState(null);
  const [elemanDilleri, setElemanDilleri] = useState([]);

  const [elemanSecimleri, setElemanSecimleri] = useState([]);
  const [elemanSecimDeger, setElemanSecimDeger] = useState([]);
  const [elemanSecimDegerDefault, setElemanSecimDegerDefault] = useState([]);

  const [isleyiciForm, setIsleyiciForm] = useState([]);
  const [isleyiciFormDefault, setIsleyiciFormDefault] = useState([]);
  const [isleyiciSelectedTabIndex, setIsleyiciSelectedTabIndex] = useState(0);
  const [isleyiciAdd, setIsleyiciAdd] = useState(true);
  const [isleyiciIdler, setisleyiciIdler] = useState([]);

  const [isleyiciFormAyarlar, setIsleyiciFormAyarlar] = useState([]);
  const [isleyiciFormAyarlarDefault, setIsleyiciFormAyarlarDefault] = useState([]);

  const [dilMiniList, setDilMiniList] = useState([]);
  const [tip, setTip] = useState([]);
  const [tipDic, setTipDic] = useState({});
  const [secimModel, setSecimModel] = useState([]);
  const [secimModelDic, setSecimModelDic] = useState({});
  const [isleyiciTip, setIsleyiciTip] = useState([]);
  const [isleyiciTipDic, setIsleyiciTipDic] = useState({});

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingErrorMessage, setIsDeletingErrorMessage] = useState(null);

  const [detailDeleteIdEleman, setDeleteIdDetailEleman] = useState(null);
  const [showDeleteDialogDetailEleman, setShowDeleteDialogDetailEleman] = useState(false);
  const [isDeletingDetailEleman, setIsDeletingDetailEleman] = useState(false);
  const [isDeletingErrorMessageDetailEleman, setIsDeletingErrorMessageDetailEleman] = useState(null);

  const [detailDeleteIdAyar, setDeleteIdDetailAyar] = useState(null);
  const [showDeleteDialogDetailAyar, setShowDeleteDialogDetailAyar] = useState(false);
  const [isDeletingDetailAyar, setIsDeletingDetailAyar] = useState(false);
  const [isDeletingErrorMessageDetailAyar, setIsDeletingErrorMessageDetailAyar] = useState(null);

  const [type, setType] = useState(props.eventType ?? 0);
  const [loadDone, setLoadDone] = useState(false);
  const [loadTab1, setLoadTab1] = useState(false);
  const [loadTab2, setLoadTab2] = useState(false);
  const [loadTab3, setLoadTab3] = useState(false);
  const [loadTab4, setLoadTab4] = useState(false);
  const [equal1, setEqual1] = useState(true);
  const [equal2, setEqual2] = useState(true);
  const [equal3, setEqual3] = useState(true);
  const [equal4, setEqual4] = useState(true);
  const [saveErrors, setSaveErrors] = useState([[], [], [], []]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const ID = useMemo(() => props.id, [props]);
  const IS_ADD = useMemo(() => props.addnew, [props]);
  const TITLE = useMemo(() => {
    if (add) {
      return 'Yeni Ekle';
    }
    return genelForm?.tanim;
  }, [add, genelForm]);

  const IS_EQUAL = useMemo(() => {
    if (add) {
      return _isEqual(genelForm, FORM_DEFAULT) && _isEqual(dillerForm, dillerFormAdd) && _isEqual(elemanForm, []) && _isEqual(isleyiciForm, []);
    }
    const isEqualGenel = _isEqual(genelForm, genelFormDefault);
    const isEqualDiller = _isEqual(dillerForm, dillerFormDefault);
    const isEqualEleman = _isEqual(elemanForm, elemanFormDefault);
    const isEqualElemanDiller = _isEqual(elemanDillerForm, elemanDillerFormDefault);
    const isEqualElemanSecimDeger = _isEqual(elemanSecimDeger, elemanSecimDegerDefault);
    const isEqualIsleyici = _isEqual(isleyiciForm, isleyiciFormDefault);
    const isEqualIsleyiciAyar = _isEqual(isleyiciFormAyarlar, isleyiciFormAyarlarDefault);
    return isEqualGenel && isEqualDiller && isEqualEleman && isEqualElemanDiller && isEqualElemanSecimDeger && isEqualIsleyici && isEqualIsleyiciAyar;
  }, [add, genelForm, genelFormDefault, dillerForm, dillerFormDefault, dillerFormAdd, elemanForm, elemanFormDefault, elemanDillerForm, elemanDillerFormDefault, elemanSecimDeger, elemanSecimDegerDefault, isleyiciForm, isleyiciFormDefault, isleyiciFormAyarlar, isleyiciFormAyarlarDefault]);

  useLayoutEffect(() => {
    setAdd(false);
    setLoadDone(false);
  }, [ID]);

  useLayoutEffect(() => {
    if (loadDone) {
      setType(2);
    } else {
      setType(props.eventType ?? 0);
    }
  }, [props.eventType, loadDone]);

  useLayoutEffect(() => {
    if (type === 0) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setDillerForm(null);
      setDillerFormDefault(null);

      setElemanForm([]);
      setElemanFormDefault([]);
      setElemanDillerForm(null);
      setElemanDillerFormDefault(null);
      setElemanSecimDeger([]);
      setElemanSecimDegerDefault([]);

      setIsleyiciForm([]);
      setIsleyiciFormDefault([]);
      setIsleyiciFormAyarlar([]);
      setIsleyiciFormAyarlarDefault([]);
      setTabs(TABS_DEFAULT);
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          setLoadTab2(true);
          break;
        case 2:
          setLoadTab1(true);
          setLoadTab3(true);
          setLoadTab4(true);
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else if (type === 1) {
      setGenelForm(null);
      setGenelFormDefault(null);
      setDillerForm(null);
      setDillerFormDefault(null);
      if (!saveErrors[1].includes("err")) {
        setElemanForm([]);
        setElemanFormDefault([]);
        setElemanDillerForm(null);
        setElemanDillerFormDefault(null);
        setElemanSecimDeger([]);
        setElemanSecimDegerDefault([]);
      }
      if (!saveErrors[2].includes("err")) {
        setIsleyiciForm([]);
        setIsleyiciFormDefault([]);
      }
      if (!saveErrors[3].includes("err")) {
        setIsleyiciFormAyarlar([]);
        setIsleyiciFormAyarlarDefault([]);
      }
      switch (selectedTabIndex) {
        case 0:
          setLoadTab1(true);
          break;
        case 1:
          setLoadTab1(true);
          if (!saveErrors[1].includes("err")) {
            setLoadTab2(true);
          }
          break;
        case 2:
          setLoadTab1(true);
          if (!saveErrors[2].includes("err")) {
            setLoadTab3(true);
          }
          if (!saveErrors[3].includes("err")) {
            setLoadTab4(true);
          }
          break;
        default:
          break;
      }
      setLoadDone(true);
    } else {
      if (!add) {
        switch (selectedTabIndex) {
          case 0:
            if (equal1) {
              setLoadTab1(true);
            }
            break;
          case 1:
            if (equal2) {
              setLoadTab2(true);
            }
            break;
          case 2:
            if (equal3) {
              setLoadTab3(true);
            }
            if (equal4) {
              setLoadTab4(true);
            }
            break;
          default:
            break;
        }
      } else {
        setLoading(false);
        setLoadingDil(false);
        setLoadingEleman(false);
        setLoadingElemanDil(false);
        setLoadingElemanSecim(false);
        setLoadingIsleyici(false);
        setLoadingIsleyiciAyar(false);
        setLoadTab1(false);
        setLoadTab2(false);
        setLoadTab3(false);
        setLoadTab4(false);
      }
    }
  }, [type, selectedTabIndex, saveErrors, equal1, equal2, equal3, equal4, add]);

  useLayoutEffect(() => {
    if (add) {
      setEqual1(_isEqual(genelForm, FORM_DEFAULT) && _isEqual(dillerForm, dillerFormAdd));
    } else {
      setEqual1(_isEqual(genelForm, genelFormDefault) && _isEqual(dillerForm, dillerFormDefault));
    }
  }, [add, genelForm, genelFormDefault, dillerForm, dillerFormDefault, dillerFormAdd]);

  useLayoutEffect(() => {
    if (add) {
      setEqual2(_isEqual(elemanForm, []));
    } else {
      setEqual2(_isEqual(elemanForm, elemanFormDefault) && _isEqual(elemanDillerForm, elemanDillerFormDefault) && _isEqual(elemanSecimDeger, elemanSecimDegerDefault));
    }
  }, [add, elemanForm, elemanFormDefault, elemanDillerForm, elemanDillerFormDefault, elemanSecimDeger, elemanSecimDegerDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual3(_isEqual(isleyiciForm, []));
    } else {
      setEqual3(_isEqual(isleyiciForm, isleyiciFormDefault));
    }
  }, [add, isleyiciForm, isleyiciFormDefault]);

  useLayoutEffect(() => {
    if (add) {
      setEqual4(_isEqual(isleyiciFormAyarlar, []));
    } else {
      setEqual4(_isEqual(isleyiciFormAyarlar, isleyiciFormAyarlarDefault));
    }
  }, [add, isleyiciFormAyarlar, isleyiciFormAyarlarDefault]);

  const LOADING = useMemo(() => {
    if (!loading && !loadingDil) {
      return false;
    }
    return true;
  }, [loading, loadingDil]);

  const LOADINGELEMAN = useMemo(() => {
    if (!loadingEleman && !loadingElemanDil && !loadingElemanSecim) {
      return false;
    }
    return true;
  }, [loadingEleman, loadingElemanDil, loadingElemanSecim]);

  useLayoutEffect(() => {
    if (loadTab1) {
      setLoading(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}`).then(({ data }) => {
            const form = {
              tanim: data.tanim,
              slug: data.slug,
              adres: data.adres,
              siteler: data.siteler,
              kullanici_al: data.kullanici_al,
              yayina_baslangic: moment(data.yayina_baslangic).format('YYYY-MM-DD[T]HH:mm'),
              yayin_bitis: moment(data.yayin_bitis).format('YYYY-MM-DD[T]HH:mm'),
              pasif_sayfa_basligi: data.pasif_sayfa_basligi,
              aktif_sayfa_basligi: data.aktif_sayfa_basligi,
              pasif_formset_html: data.pasif_formset_html,
              siralama: String(data.siralama),
              aktif: data.aktif,
              diller: data.diller,
            };
            setGenelFormDefault(form);
            setGenelForm(form);
            setTanimDilleri(form.diller);
            setHataGenel(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataGenel(true);
              }
            }
          }).finally(() => {
            setLoading(false);
            setGenelError(null);
            setLoadTab1(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
          setLoading(false);
        };
      } else {
        setGenelForm(FORM_DEFAULT);
        setGenelFormDefault(FORM_DEFAULT);
        setTanimDilleri([]);
        setGenelError(null);
        setHataGenel(null);
        setAdd(true);
        setSelectedTabIndex(0);
        setLoading(false);
        setLoadTab1(false);
      }
    }
  }, [ID, IS_ADD, loadTab1]);

  useLayoutEffect(() => {
    setLoadingDil(true);
    createDillerStates(dilMiniList, tanimDilleri).then((states) => {
      setDillerFormDefault(states);
      setDillerForm(states);
    }).finally(() => setLoadingDil(false));
  }, [dilMiniList, tanimDilleri]);

  useLayoutEffect(() => {
    createDillerStates(dilMiniList, []).then((states) => {
      setDillerFormAdd(states);
    });
  }, [dilMiniList]);

  useLayoutEffect(() => {
    if (loadTab2) {
      if (!IS_ADD) {
        setLoadingEleman(true);
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/formeleman`).then(({ data }) => {
            const forms = data.map((eleman) => (
              {
                form: {
                  id: eleman.id,
                  tanim: eleman.tanim,
                  tip: String(eleman.tip.key),
                  secim_degerleri: eleman.secim_degerleri,
                  secim_modeli: String(eleman.secim_modeli ?? "-1"),
                  dogrulama: eleman.dogrulama,
                  zorunlu: eleman.zorunlu,
                  secim_oto_tamamlamali_calissin: eleman.secim_oto_tamamlamali_calissin,
                  aktif: eleman.aktif,
                  siralama: String(eleman.siralama),
                  diller: eleman.diller,
                },
                errors: null
              }
            ))
            setElemanForm(forms);
            setElemanFormDefault(forms);
            setElemanDilleri(forms.map((el) => el.form.diller));
            setElemanSecimleri(forms.map((el) => el.form.secim_degerleri));
            setHataEleman(null);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataEleman(false);
              }
            }
          }).finally(() => {
            setLoadingEleman(false);
            setElemanAdd(true);
            setLoadTab2(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setElemanForm([]);
        setElemanFormDefault([]);
        setElemanDilleri([]);
        setElemanSecimleri([]);
        setElemanAdd(true);
        setHataEleman(null);
        setLoadingEleman(false);
        setLoadTab2(false);
      }
    }
  }, [ID, IS_ADD, loadTab2]);

  useLayoutEffect(() => {
    setLoadingElemanDil(true);
    const setDil = async () => {
      let diller = [];
      elemanDilleri.forEach((dil) => {
        createDillerStates2(dilMiniList, dil).then((states) => diller.push(states));
      })
      return diller;
    }
    setDil().then((state) => {
      setElemanDillerForm(state);
      setElemanDillerFormDefault(state);
    }).finally(() => setLoadingElemanDil(false));

  }, [dilMiniList, elemanDilleri]);

  useLayoutEffect(() => {
    setLoadingElemanSecim(true);
    const setDil = async () => {
      let ss = [];
      elemanSecimleri.forEach((secimler) => {
        let s = [];
        secimler.forEach((secim) => {
          createDillerStates3(dilMiniList, secim?.diller ?? []).then((states) => s.push({ deger: secim?.deger ?? "", diller: states }));
        });
        ss.push(s)
      })
      return ss;
    }
    setDil().then((state) => {
      setElemanSecimDeger(state);
      setElemanSecimDegerDefault(state);
    }).finally(() => setLoadingElemanSecim(false));
  }, [dilMiniList, elemanSecimleri]);

  useLayoutEffect(() => {
    if (loadTab3) {
      setLoadingIsleyici(true);
      if (!IS_ADD) {
        const debounce = setTimeout(() => {
          django(`${PATH}/${ID}/isleyici`).then(({ data }) => {
            const forms = data.map((eleman) => (
              {
                form: {
                  id: eleman.id,
                  tip: String(eleman.tip.key),
                },
                errors: null
              }
            ));
            setisleyiciIdler(forms.map(x => x.form));
            setIsleyiciForm(forms);
            setIsleyiciFormDefault(forms);
          }).catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                setHataIsleyici(false);
              }
            }
          }).finally(() => {
            setLoadingIsleyici(false);
            setIsleyiciAdd(true);
            setLoadTab3(false);
          });
        }, 300);
        return () => {
          clearTimeout(debounce);
        };
      } else {
        setisleyiciIdler([]);
        setIsleyiciForm([]);
        setIsleyiciFormDefault([]);
        setLoadingIsleyici(false);
        setLoadTab3(false);
        setIsleyiciAdd(true);
      }
    }
  }, [ID, IS_ADD, loadTab3]);

  useLayoutEffect(() => {
    if (loadTab4 && !loadTab3) {
      setLoadingIsleyiciAyar(true);
      if (!IS_ADD) {
        createAyarlar(isleyiciIdler, ID).then((states) => {
          setIsleyiciFormAyarlar(states);
          setIsleyiciFormAyarlarDefault(states);
          setHataIsleyiciAyar(null);
        }).catch(() => {
          setHataIsleyiciAyar(false);
        }).finally(() => {
          setLoadingIsleyiciAyar(false);
          setLoadTab4(false);
        })
      } else {
        setIsleyiciFormAyarlar([]);
        setIsleyiciFormAyarlarDefault([]);
        setHataIsleyiciAyar(null);
        setLoadingIsleyiciAyar(false);
        setLoadTab4(false);
      }
    }
  }, [ID, IS_ADD, isleyiciIdler, loadTab4, loadTab3]);

  useLayoutEffect(() => {
    django('dil/mini_list').then(({ data }) => {
      setDilMiniList(data);
      setHataDil(null);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          setHataDil(true);
        }
      }
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django("form/eleman_tip").then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setTipDic(dict);
      setTip(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django("form/choices_model_name").then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setSecimModelDic(dict);
      setSecimModel(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    django("form/isleyici_tip").then(({ data }) => {
      const dict = Object.assign({}, ...data.map((x) => ({ [x.key]: x.value })));
      setIsleyiciTipDic(dict);
      setIsleyiciTip(data)
    });
  }, [lastUpdateTime]);

  useLayoutEffect(() => {
    if (!props.single) {
      props.onDisableClose(!IS_EQUAL);
    }
  }, [props, IS_EQUAL]);

  const handleGenelChangeForm = (property, value) => {
    if (property === "slug" || property === "adres") {
      const slug = SlugMaker(value);
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next[property] = slug;
        });
      });
    } else {
      setGenelForm((prev) => {
        return immer(prev, (next) => {
          next[property] = value;
        });
      });
    }
  };

  const handleDillerChangeForm = (index, property, value) => {
    setDillerForm((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      });
    });
  }

  const handleElemanChangeForm = (index, property, value, property2) => {
    if (property2 !== "") {
      setElemanForm((prev) => {
        return immer(prev, (next) => {
          next[index]["form"][property][property2] = value;
        });
      });
    } else {
      if (property === "tanim") {
        const slug = SlugMaker(value);
        setElemanForm((prev) => {
          return immer(prev, (next) => {
            next[index]["form"][property] = slug;
          });
        });
      } else if (property === "tip") {
        setElemanForm((prev) => {
          return immer(prev, (next) => {
            next[index]["form"][property] = value;
            next[index]["form"]["dogrulama"]["min_val"] = "";
            next[index]["form"]["dogrulama"]["max_val"] = "";
            next[index]["form"]["dogrulama"]["default"] = "";
          });
        });
      } else {
        setElemanForm((prev) => {
          return immer(prev, (next) => {
            next[index]["form"][property] = value;
          });
        });
      }

    }
  };

  const handleDillerChangeFormEleman = (index, index2, property, value) => {
    setElemanDillerForm((prev) => {
      return immer(prev, (next) => {
        next[index][index2].form[property] = value;
      });
    });
  }

  const handleElemanAddNewTab = () => {
    setElemanAdd(false);
    setElemanForm((prev) => immer(prev, (next) => {
      next.push({ ...ELEMAN_FORM_DEFAULT });
    }));
    setElemanDilleri((prev) => immer(prev, (next) => {
      next.push([]);
    }));
    setElemanSecimleri((prev) => immer(prev, (next) => {
      next.push([]);
    }));
  }

  const handleElemanSecimChangeForm = (index, index2, index3, value) => {
    if (index3 !== "") {
      setElemanSecimDeger((prev) => {
        return immer(prev, (next) => {
          next[index][index2].diller[index3].form["deger"] = value;
        });
      });
    } else {
      setElemanSecimDeger((prev) => {
        return immer(prev, (next) => {
          next[index][index2].deger = value;
        });
      });
    }

  }

  const handleElemanSecimAddNewRow = (index) => {
    createDillerStates3(dilMiniList, []).then((res) => {
      setElemanSecimDeger((prev) => immer(prev, (next) => {
        next[index].push({ deger: "", diller: res });
      }));
    })
  }

  const handleElemanSecimRemoveRow = (index, index2) => {
    setElemanSecimDeger((prev) => immer(prev, (next) => {
      next[index].splice(index2, 1);
    }));
  }

  const handleIsleyiciChangeForm = (index, value) => {
    setIsleyiciForm((prev) => {
      return immer(prev, (next) => {
        next[index].form.tip = value;
      });
    });
    switch (value) {
      case "0":
        setIsleyiciFormAyarlar((prev) => {
          return immer(prev, (next) => {
            next[index] = { form: { tip: "0", isleyici: null }, errors: null };
          });
        });
        break;
      case "1":
        setIsleyiciFormAyarlar((prev) => {
          return immer(prev, (next) => {
            next[index] = { ...FORM_ISLEME_AYAR_EPOSTA };
          });
        });
        break;
      case "2":
        setIsleyiciFormAyarlar((prev) => {
          return immer(prev, (next) => {
            next[index] = { ...FORM_ISLEME_AYAR_FORMPOST };
          });
        });
        break;
      case "3":
        setIsleyiciFormAyarlar((prev) => {
          return immer(prev, (next) => {
            next[index] = { ...FORM_ISLEME_AYAR_OTOCEVAP };
          });
        });
        break;
      default:
        break;
    }
  }

  const handleIsleyiciAddNewTab = () => {
    setIsleyiciAdd(false);
    setIsleyiciForm((prev) => immer(prev, (next) => {
      next.push({ ...ISLEYICI_FORM_DEFAULT });
    }));
    setIsleyiciFormAyarlar((prev) => immer(prev, (next) => {
      next.push({ form: { tip: "", isleyici: null }, errors: null });
    }));
  }

  const handleIsleyiciAyarChangeForm = (index, property, value) => {
    setIsleyiciFormAyarlar((prev) => {
      return immer(prev, (next) => {
        next[index].form[property] = value;
      });
    });
  }

  const handleAdd = () => {
    setAdd(true);
    setSelectedTabIndex(0);
    setGenelForm(FORM_DEFAULT);
    setGenelError(null);
    setDillerForm(dillerFormAdd);
    setElemanForm([]);
    setElemanDilleri([]);
    setElemanSecimleri([]);
    setElemanAdd(true);
    setIsleyiciForm([]);
    setIsleyiciFormAyarlar([]);
    setIsleyiciAdd(true);
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
      });
    });
  };

  const handleReload = () => {
    setLastUpdateTime(Date.now());
    setLoadTab1(true);
    setLoadTab2(true);
    setLoadTab3(true);
    setLoadTab4(true);
    setTabs(TABS_DEFAULT);
  };

  const handleCancel = () => {
    setGenelForm(genelFormDefault);
    setGenelError(null);
    setDillerForm(dillerFormDefault);
    setElemanForm(elemanFormDefault);
    setElemanDilleri(elemanDilleri.filter((x) => !_isEmpty(x)));
    setElemanSecimDeger(elemanSecimDegerDefault);
    setElemanAdd(true);
    setIsleyiciForm(isleyiciFormDefault);
    setIsleyiciFormAyarlar(isleyiciFormAyarlarDefault);
    setIsleyiciAdd(true);
    if (!IS_ADD) {
      setAdd(false);
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = false;
        next[1].hasError = false;
        next[2].hasError = false;
      });
    });
  };

  const checkAll = async () => {
    const list = [
      { prop: 'tanim', empty: true, unique: true, multiple: false, multipletext: '', propname: 'Form Tanım', pagename: 'form tanım', prop2: '' },
      { prop: 'slug', empty: true, unique: false, multiple: false, multipletext: '', propname: 'Form İsmi', pagename: 'form tanım', prop2: '' },
      { prop: 'adres', empty: true, unique: false, multiple: false, multipletext: '', propname: 'Adres', pagename: 'form tanım', prop2: '' },
      { prop: 'siteler', empty: true, unique: false, multiple: false, multipletext: '', propname: 'Siteler', pagename: 'form tanım', prop2: '' }
    ];
    let errors = [];
    await checkAllList('form', genelForm, genelFormDefault, handleErrors, add, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const checkAllTabs = async (form, func, list) => {
    let errors = [];
    await checkAllList2(form, func, list).then((res) => errors = !_isEmpty(res) ? [...errors, res] : [...errors]);
    return errors;
  }

  const handleSave = async () => {
    const createRequestOptionsTabGenel = async () => {
      let reqopt = null;
      let err = null;
      if (add ? _isEqual(genelForm, FORM_DEFAULT) && _isEqual(dillerForm, dillerFormAdd) : _isEqual(genelForm, genelFormDefault) && _isEqual(dillerForm, dillerFormDefault)) {
        reqopt = null;
        err = null;
      } else {
        await checkAll().then((res) => {
          if (_isEmpty(res)) {
            const diller = dillerForm.filter((dil) => (!_isEmpty(dil.form.tanim))).map((d) => d.form);
            const data = { ...genelForm, siralama: genelForm.siralama ? Number(genelForm.siralama) : 0, diller: diller, siteler: genelForm.siteler?.map((m) => m.id) }
            reqopt = {
              data: data,
              method: add ? 'POST' : 'PUT',
              id: null,
              successMessage: `Form Tanım ${add ? 'ekleme' : 'güncelleme'} başarıyla tamamlandı`,
              errorMessageUnexpected: `Form Tanım ${add ? 'eklenirken' : 'güncellenirken'} beklenmeyen bir hata oluştu`,
            };
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabEleman = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = elemanFormDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next) && _isEqual(elemanDillerForm[index], elemanDillerFormDefault[index]) && _isEqual(elemanSecimDeger[index], elemanSecimDegerDefault[index]);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [
          { prop: 'tip', prop2: '' },
          { prop: 'tanim', prop2: '' },
        ];
        const diller = elemanDillerForm[index].filter((dil) => (!_isEmpty(dil.form.baslik))).map((d) => d.form);
        const secenekler = elemanSecimDeger[index].filter((secim) => (!_isEmpty(secim.deger))).map((d) => ({ deger: d.deger, diller: d.diller.map((dil) => dil.form) }));
        const data = {
          ...next.form, secim_modeli: next.form.secim_modeli !== "-1" ? Number(next.form.secim_modeli) : null, siralama: next.form.siralama ? Number(next.form.siralama) : 0, diller: diller, secim_degerleri: secenekler
        }
        await checkAllTabs(next.form, (err) => handleErrorsEleman(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            if (isHas) {
              reqopt = {
                data: data,
                method: 'PUT',
                id: next.form.id,
                successMessage: `${next.form.tanim} güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.tanim} güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `${next.form.tanim} ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `${next.form.tanim} eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    const createRequestOptionsTabIsleyici = async (next, index) => {
      let reqopt = null;
      let err = null;
      let id = null;
      const isHas = _has(next.form, 'id');
      const prev = isleyiciFormDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
        id = isHas ? next.form.id : null
      } else {
        const list = [];
        await checkAllTabs(next.form, (err) => handleErrorsIsleyici(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            if (isHas) {
              reqopt = {
                data: { ...next.form },
                method: 'PUT',
                id: next.form.id,
                successMessage: `Form işleyici güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Form işleyici güncellenirken beklenmeyen bir hata oluştu`,
              }
            } else {
              reqopt = {
                data: { ...next.form },
                method: 'POST',
                id: null,
                successMessage: `Form işleyici ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Form işleyici eklenirken beklenmeyen bir hata oluştu`,
              }
            }
            err = null;
            id = isHas ? next.form.id : null
          } else {
            reqopt = null;
            err = res;
            id = isHas ? next.form.id : null
          }
        })
      }
      return [reqopt, err, id]
    }

    const createRequestOptionsTabIsleyiciAyar = async (next, index) => {
      let reqopt = null;
      let err = null;
      const isHas = _has(next.form, 'id');
      const prev = isleyiciFormAyarlarDefault.find(x => x.form.id === next.form.id);
      const isEqual = !isHas ? false : _isEqual(prev, next);
      if (isEqual) {
        reqopt = null;
        err = null;
      } else {
        const list = [];
        await checkAllTabs(next.form, (err) => handleErrorsIsleyiciAyar(err, index), list).then((res) => {
          if (_isEmpty(res)) {
            const data = { ...next.form }
            delete data.tip;
            delete data.isleyici;
            if (isHas) {
              reqopt = {
                data: data,
                method: 'PUT',
                id: next.form.id,
                successMessage: `Form işleyici ayar güncelleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Form işleyici ayar güncellenirken beklenmeyen bir hata oluştu`,
                tip: next.form.tip
              }
            } else {
              reqopt = {
                data: data,
                method: 'POST',
                id: null,
                successMessage: `Form işleyici ayar ekleme başarıyla tamamlandı`,
                errorMessageUnexpected: `Form işleyici ayar eklenirken beklenmeyen bir hata oluştu`,
                tip: next.form.tip
              }
            }
            err = null;
          } else {
            reqopt = null;
            err = res;
          }
        })
      }
      return [reqopt, err]
    }

    let errors = [[], [], [], []];

    let SAVEID = add ? null : ID;

    let ISLEYICI_IDS = [];

    const start = async () => {
      const tabs = [
        { index: 0, name: 'genel', request: [], error: [] },
        { index: 1, name: 'eleman', request: [], error: [] },
        { index: 2, name: 'isleyici', request: [], error: [], id: [] },
        { index: 3, name: 'isleyiciayar', request: [], error: [] },
      ];
      const res = await createRequestOptionsTabGenel();
      tabs[0].request.push(res[0]);
      tabs[0].error.push(res[1]);

      for (const i in elemanForm) {
        const eleman = [...elemanForm];
        const res = await createRequestOptionsTabEleman(eleman[i], i)
        tabs[1].request.push(res[0]);
        tabs[1].error.push(res[1]);
      }

      for (const i in isleyiciForm) {
        const isleyici = [...isleyiciForm];
        const res = await createRequestOptionsTabIsleyici(isleyici[i], i);
        tabs[2].request.push(res[0]);
        tabs[2].error.push(res[1]);
      }

      for (const i in isleyiciFormAyarlar) {
        const isleyiciayar = [...isleyiciFormAyarlar];
        const res = await createRequestOptionsTabIsleyiciAyar(isleyiciayar[i], i);
        tabs[3].request.push(res[0]);
        tabs[3].error.push(res[1]);
      }

      await asyncForEach(tabs, async (tab) => {
        await asyncForEach(tab.request, async (request, requestIndex) => {
          if (request !== null) {
            if (tab.name === 'genel') {
              setGenelError(null);
              await django({
                method: request.method,
                data: request.data,
                url: add ? "form" : `form/${SAVEID}`
              }).then(({ data }) => {
                enqueueSnackbar(request.successMessage, { variant: 'success' });
                if (!add) {
                  props.onUpdated();
                }
                if (props.single && add) {
                  history.push(URLS.tanimlar.formtanim.detail(data.id, "detay"));
                }
                if (!props.single && add) {
                  props.onAdded(data);
                }
                SAVEID = data.id;
                errors[tab.index].splice(requestIndex, 0, null);
              }).catch((error) => {
                setSelectedTabIndex(tab.index);
                if (error.response) {
                  if (error.response.status === 500) {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  } else {
                    setGenelError(error.response.data);
                  }
                } else {
                  enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                }
                errors[tab.index].splice(requestIndex, 0, "err");
              });
            }
            if (SAVEID !== null) {
              if (tab.name === 'eleman') {
                setElemanForm((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `form/${SAVEID}/formeleman/${request.id}` : `form/${SAVEID}/formeleman`
                }).then(() => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  setElemanAdd(true);
                  errors[tab.index].splice(requestIndex, 0, null);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  setElemanSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setElemanForm((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'isleyici') {
                setIsleyiciForm((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                await django({
                  method: request.method,
                  data: request.data,
                  url: request.id ? `form/${SAVEID}/isleyici/${request.id}` : `form/${SAVEID}/isleyici`
                }).then(({ data }) => {
                  enqueueSnackbar(request.successMessage, { variant: 'success' });
                  setIsleyiciAdd(true);
                  errors[tab.index].splice(requestIndex, 0, null);
                  ISLEYICI_IDS.push(data.id);
                }).catch((error) => {
                  setSelectedTabIndex(tab.index);
                  setIsleyiciSelectedTabIndex(requestIndex);
                  if (error.response) {
                    setIsleyiciForm((prev) => {
                      return immer(prev, (next) => {
                        next[requestIndex].errors = error.response.data;
                      })
                    });
                  } else {
                    enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                  }
                  errors[tab.index].splice(requestIndex, 0, "err");
                });
              }
            }
            if (SAVEID !== null) {
              if (tab.name === 'isleyiciayar') {
                setIsleyiciFormAyarlar((prev) => {
                  return immer(prev, (next) => {
                    next[requestIndex].errors = null;
                  })
                });
                if (request.tip !== "") {
                  await django({
                    method: request.method,
                    data: request.data,
                    url: request.id ? `form/${SAVEID}/isleyici/${ISLEYICI_IDS[requestIndex]}/${request.tip}/${request.id}` : `form/${SAVEID}/isleyici/${ISLEYICI_IDS[requestIndex]}/${request.tip}`
                  }).then(() => {
                    enqueueSnackbar(request.successMessage, { variant: 'success' });
                    errors[tab.index].splice(requestIndex, 0, null);
                  }).catch((error) => {
                    setSelectedTabIndex(2);
                    if (error.response) {
                      setIsleyiciFormAyarlar((prev) => {
                        return immer(prev, (next) => {
                          next[requestIndex].errors = error.response.data;
                          next[requestIndex].form["isleyici"] = ISLEYICI_IDS[requestIndex];
                        })
                      });
                    } else {
                      enqueueSnackbar(request.errorMessageUnexpected, { variant: 'error' });
                    }
                    errors[tab.index].splice(requestIndex, 0, "err");
                  });
                }
              }
            }
          } else {
            if (tab.name === 'isleyici') {
              ISLEYICI_IDS.push(tab.id[requestIndex]);
            }
            if (!_isEmpty(tab.error[requestIndex])) {
              errors[tab.index].splice(requestIndex, 0, "err");
            } else {
              errors[tab.index].splice(requestIndex, 0, null);
            }
          }
        });
      });
    }

    await start();
    if (SAVEID !== null) {
      if (!add) {
        if (!errors[0].includes("err")) {
          setLoadTab1(true);
        }
        if (!errors[1].includes("err")) {
          setLoadTab2(true);
        }
        if (!errors[2].includes("err")) {
          setLoadTab3(true);
        }
        if (!errors[3].includes("err")) {
          setLoadTab4(true);
        }
      } else {
        setSaveErrors(errors);
      }
    } else {
      enqueueSnackbar("Genel tanımları giriniz!", { variant: 'error' });
      await checkAll();
    }
    setTabs((prev) => {
      return immer(prev, (next) => {
        next[0].hasError = !errors[0].includes("err") ? SAVEID ? false : true : true;
        next[1].hasError = !errors[1].includes("err") ? false : true;
        next[2].hasError = !errors[2].includes("err") ? !errors[3].includes("err") ? false : true : true;
      });
    });
  }

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeletingErrorMessage(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialog(false);
        setIsDeletingErrorMessage(null);
        setTimeout(() => {
          if (props.single) {
            history.push(URLS.tanimlar.formtanim.list);
          } else {
            props.onDeleted();
          }
        }, 100);
      } else {
        setIsDeletingErrorMessage(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessage(errorMessage);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const handleDeleteDilIndex = (index) => {
    setDillerForm((prev) => {
      return immer(prev, (next) => {
        next[index].form = { ...DILLER_FORM_DEFAULT, dil_id: next[index].form.dil_id };
        next[index].errors = null;
      })
    });
  }

  const handleErrors = (errors) => {
    setGenelError((prev) => {
      const next = _pickBy({ ...prev, ...errors }, _identity);
      if (Object.keys(next).length === 0) {
        return null;
      }
      return next;
    });
  }

  const handleErrorsDetail = (errors, index) => {
    setDillerForm((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsEleman = (errors, index) => {
    setElemanForm((prev) => {
      let errobj = { ...prev[index]["errors"], ...errors };
      let err = {};
      if (_has(errobj, 'dogrulama')) {
        const dogrulama = _pickBy(errobj['dogrulama'], _identity);
        if (_isEmpty(dogrulama)) {
          err = _pickBy({ ...errobj, 'dogrulama': null }, _identity);
        } else {
          err = _pickBy({ ...errobj, 'dogrulama': dogrulama }, _identity);
        }
      } else {
        err = _pickBy(errobj, _identity);
      }
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsElemanDil = (errors, index, index2) => {
    setElemanDillerForm((prev) => {
      const err = _pickBy({ ...prev[index][index2]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index][index2]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsIsleyici = (errors, index) => {
    setIsleyiciForm((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleErrorsIsleyiciAyar = (errors, index) => {
    setIsleyiciFormAyarlar((prev) => {
      const err = _pickBy({ ...prev[index]["errors"], ...errors }, _identity);
      return immer(prev, (next) => {
        next[index]["errors"] = Object.keys(err).length === 0 ? null : err;
      });
    });
  }

  const handleDeleteEleman = () => {
    setIsDeletingDetailEleman(true);
    setIsDeletingErrorMessageDetailEleman(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/formeleman/${detailDeleteIdEleman}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetailEleman(false);
        setIsDeletingErrorMessageDetailEleman(null);
      } else {
        setIsDeletingErrorMessageDetailEleman(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetailEleman(errorMessage);
    }).finally(() => {
      setIsDeletingDetailEleman(false);
      setLoadTab2(true);
    });
  }

  const handleDeleteElemanDilIndex = (index, index2) => {
    setElemanDillerForm((prev) => {
      return immer(prev, (next) => {
        next[index][index2].form = { ...DILLER_FORM_DEFAULT2, dil_id: next[index][index2].form.dil_id };
        next[index][index2].errors = null;
      })
    });
  }

  const handleDeleteAyar = () => {
    setIsDeletingDetailAyar(true);
    setIsDeletingErrorMessageDetailAyar(null);
    const errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin';
    django.delete(`${PATH}/${ID}/isleyici/${detailDeleteIdAyar}`).then(({ status }) => {
      if (status === 204) {
        setShowDeleteDialogDetailAyar(false);
        setIsDeletingErrorMessageDetailAyar(null);
      } else {
        setIsDeletingErrorMessageDetailAyar(errorMessage);
      }
    }).catch(() => {
      setIsDeletingErrorMessageDetailAyar(errorMessage);
    }).finally(() => {
      setIsDeletingDetailAyar(false);
      setLoadTab3(true);
      setLoadTab4(true);
    });
  }


  return (
    <>
      <LayoutDetail
        loading={loading}
        title={TITLE}

        single={props.single}

        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onChangeSelectedTabIndex={(index) => setSelectedTabIndex(index)}

        disableCloseButton={loading}
        disableAddButton={loading || add || IS_ADD}
        disableReloadButton={loading || add || IS_ADD}
        disableCancelButton={!add && (loading || IS_EQUAL) && !IS_ADD}
        disableSaveButton={loading || IS_EQUAL}
        disableDeleteButton={loading || add || IS_ADD}
        disablePreviousButton={props.disablePreviousButton || add || IS_ADD}
        disableNextButton={props.disableNextButton || add || IS_ADD}

        onClickCloseButton={props.onClose}
        onClickAddButton={handleAdd}
        onClickReloadButton={handleReload}
        onClickCancelButton={handleCancel}
        onClickSaveButton={handleSave}
        onClickDeleteButton={() => setShowDeleteDialog(true)}
        onClickPreviousButton={props.onGoPrevious}
        onClickNextButton={props.onGoNext}
        onOpenInNewTab={() => {
          if (IS_ADD) {
            window.open(`${_trimEnd(window.location.pathname, '/')}/yeni/yeni`, '_blank')
          } else {
            window.open(`${_trimEnd(window.location.pathname, '/')}/${ID}/detay`, '_blank')
          }
        }}
      >
        {selectedTabIndex === 0 && (
          <TabGenel
            loading={LOADING}
            hata={hataGenel}
            hataDil={hataDil}
            add={add}
            form={genelForm}
            formdefault={genelFormDefault}
            error={genelError}
            onChangeForm={handleGenelChangeForm}
            selectedTabIndex={dillerSelectedTabIndex}
            dilform={dillerForm}
            onChangeDilForm={handleDillerChangeForm}
            deleteDil={handleDeleteDilIndex}
            onCheckErrors={(errors) => handleErrors(errors)}
            onCheckErrorsDil={(errors, index) => handleErrorsDetail(errors, index)}
          />
        )}

        {selectedTabIndex === 1 && (
          <TabEleman
            loading={LOADINGELEMAN}
            hata={hataEleman}
            hataDil={hataDil}

            selectedTabIndex={elemanSelectedTabIndex}
            forms={elemanForm}
            onChangeForm={handleElemanChangeForm}
            selectedTabIndexDil={elemanDillerSelectedTabIndex}
            dilform={elemanDillerForm}
            onChangeDilForm={handleDillerChangeFormEleman}
            onAddNewTab={handleElemanAddNewTab}
            elemanAdd={elemanAdd}
            setDeleteId={setDeleteIdDetailEleman}
            openDeleteForm={setShowDeleteDialogDetailEleman}
            deleteDil={handleDeleteElemanDilIndex}
            onCheckErrors={(errors, index) => handleErrorsEleman(errors, index)}
            onCheckErrorsDil={(errors, index, index2) => handleErrorsElemanDil(errors, index, index2)}
            secimler={elemanSecimDeger}
            onChangeSecimForm={handleElemanSecimChangeForm}
            onAddNewSecim={handleElemanSecimAddNewRow}
            onRemoveSecim={handleElemanSecimRemoveRow}
            tip={tip}
            tipDic={tipDic}
            secimModel={secimModel}
            secimModelDic={secimModelDic}
          />
        )}

        {selectedTabIndex === 2 && (
          <TabIsleyiciAyar
            loading={loadingIsleyici}
            loadingAyar={loadingIsleyiciAyar}
            hata={hataIsleyici}
            hataAyar={hataIsleyiciAyar}
            selectedTabIndex={isleyiciSelectedTabIndex}
            forms={isleyiciForm}
            onChangeForm={handleIsleyiciChangeForm}
            onAddNewTab={handleIsleyiciAddNewTab}
            isleyiciAdd={isleyiciAdd}
            ayarform={isleyiciFormAyarlar}
            onChangeAyarForm={handleIsleyiciAyarChangeForm}
            setDeleteId={setDeleteIdDetailAyar}
            openDeleteForm={setShowDeleteDialogDetailAyar}
            onCheckErrorsAyar={(errors, index) => handleErrorsIsleyiciAyar(errors, index)}
            tip={isleyiciTip}
            tipDic={isleyiciTipDic}
          />
        )}
      </LayoutDetail>

      {showDeleteDialog &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessage
              ? isDeletingErrorMessage
              : isDeleting
                ? 'Form tanımı siliniyor lütfen bekleyin'
                : 'Bu form tanımını gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessage ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeleting}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
        ></DialogConfirm>
      }
      {showDeleteDialogDetailEleman &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetailEleman
              ? isDeletingErrorMessageDetailEleman
              : isDeletingDetailEleman
                ? 'Adres siliniyor lütfen bekleyin'
                : 'Bu adresi gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetailEleman ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetailEleman}
          onSubmit={handleDeleteEleman}
          onCancel={() => setShowDeleteDialogDetailEleman(false)}
        ></DialogConfirm>
      }

      {showDeleteDialogDetailAyar &&
        <DialogConfirm
          title="Sil"
          message={
            isDeletingErrorMessageDetailAyar
              ? isDeletingErrorMessageDetailAyar
              : isDeletingDetailAyar
                ? 'Adres siliniyor lütfen bekleyin'
                : 'Bu adresi gerçekten silmek istiyor musunuz?'
          }
          cancelText="VAZGEÇ"
          submitText={isDeletingErrorMessageDetailAyar ? 'TEKRAR DENE' : 'SİL'}
          submittingText="SİLİNİYOR"
          submitButtonProps={{ color: 'secondary' }}
          isSubmitting={isDeletingDetailAyar}
          onSubmit={handleDeleteAyar}
          onCancel={() => setShowDeleteDialogDetailAyar(false)}
        ></DialogConfirm>
      }
    </>
  );
};

export default FormTanimDetail;

