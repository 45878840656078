import React, { useState, useLayoutEffect } from 'react';
import Layout from '../../base/LayoutTableDetail';
import Detail from './SablonDetail';


const COLUMNS_DEFAULT = [
  { id: 'tanim',                      tableProp: 'tanim',                         filterProp: 'tanim',                    label: 'Şablon Paket Tanım',            element: 'input',           type: 'text',     arrayTableProp: null,    show: true,  sortable: true,  highlightable: true,  alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'site',                       tableProp: 'site',                          filterProp: 'site.id',                  label: 'Siteler',                       element: 'select-multiple', type: 'array',    arrayTableProp: 'name',  show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'site',       multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'name',     multipleSelectOptionViewProp: 'name',     shortDescription: null,  selectKey: null,  selectValue: null  , newpath:"tanimlar/site" },
  { id: 'yayina_baslama_zamani',      tableProp: 'yayina_baslama_zamani',         filterProp: 'yayina_baslama_zamani',    label: 'Yayına Başlama Zamanı',         element: 'input',           type: 'datetime', arrayTableProp: null,    show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'yayini_bitirme_zamani',      tableProp: 'yayini_bitirme_zamani',         filterProp: 'yayini_bitirme_zamani',    label: 'Yayını bitirme zamanı',         element: 'input',           type: 'datetime', arrayTableProp: null,    show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'aktif',                      tableProp: 'aktif',                         filterProp: 'aktif',                    label: 'Aktif',                         element: 'select',          type: 'boolean',  arrayTableProp: null,    show: true,  sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'varsayilan',                 tableProp: 'varsayilan',                    filterProp: 'varsayilan',               label: 'Varsayılan',                    element: 'select',          type: 'boolean',  arrayTableProp: null,    show: true,  sortable: false, highlightable: false, alignment: 1, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey:'key',  selectValue:'value'},
  { id: 'olusturan_kullanici',        tableProp: 'olusturan_kullanici.username',  filterProp: 'olusturan_kullanici.id',   label: 'Oluşturan Kullanıcı',           element: 'select-multiple', type: 'number',   arrayTableProp: null,    show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistiren_kullanici',       tableProp: 'degistiren_kullanici.username', filterProp: 'degistiren_kullanici.id',  label: 'Değiştiren Kullanıcı',          element: 'select-multiple', type: 'number',   arrayTableProp: null,    show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: 'kullanici',  multipleSelectOptionFilterProp: 'id', multipleSelectOptionSortProp: 'username', multipleSelectOptionViewProp: 'username', shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'olusturma_zamani',           tableProp: 'olusturma_zamani',              filterProp: 'olusturma_zamani',         label: 'Oluşturma',                     element: 'input',           type: 'datetime', arrayTableProp: null,    show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
  { id: 'degistirme_zamani',          tableProp: 'degistirme_zamani',             filterProp: 'degistirme_zamani',        label: 'Son Değişiklik',                element: 'input',           type: 'datetime', arrayTableProp: null,    show: true,  sortable: true,  highlightable: false, alignment: 0, width: 1, selectOptions: null, multipleSelectOptionsPath: null,         multipleSelectOptionFilterProp: null, multipleSelectOptionSortProp: null,       multipleSelectOptionViewProp: null,       shortDescription: null,  selectKey: null,  selectValue: null  },
];

const PageSablonList = () => {
  const [columnsDefault, setColumnsDefault] = useState(COLUMNS_DEFAULT);

  useLayoutEffect(() => {
    const selectOptions = [
      { key: true, value: 'Evet' },
      { key: false, value: 'Hayır' },
    ];

    setColumnsDefault((prev) => {
      return prev.map((column) => {
        if (column.type === 'boolean') {
          return {
            ...column,
            selectOptions,
          };
        }
        return column;
      });
    });
  }, []);

  return (
    <>
      <Layout
        title="TEMALAR VE TEMA AYARLARI"
        path="sablon_paket"
        columnsDefault={columnsDefault}
        detailComponent={Detail}
        storekey="sablon_paket"
      />
    </>
  );
};

export default PageSablonList;
