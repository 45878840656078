import React, { useMemo } from 'react';

import {
  has as _has,
  isNil as _isNil
} from 'lodash';

import {
  TextField,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import {
  Add as IconAdd,
  Delete as IconDelete,
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import { Hata } from '../../base/Hata';
import { handleInputErrors2 } from '../../base/InputErrors';

const styles = ({ spacing, palette }) => {
  return {
    layoutContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      overflowX: 'auto',
      padding: spacing(1),
    },
    layoutFormItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 360,
      maxWidth: 360,
      '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
      },
    },
    layoutFormItemLabel: {
      minWidth: 120,
      maxWidth: 120,
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    tablehead: {
      display: 'flex',
      borderBottom: '1px solid #976797',
      marginBottom: spacing(0.5)
    },
    tablebody: {
      display: 'flex',
      flexDirection: 'column',
    },
    tablerow: {
      display: 'flex',
      padding: spacing(0.5, 1, 0.5, 1),
      alignItems: "center"
    },
    tablecell: {
      display: 'flex',
      padding: spacing(0, 0.5, 0, 0.5),
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
      }
    }

  };
};

const SepetKosulHareket = withStyles(styles)(({ classes, ...props }) => {
  const LOADING = useMemo(() => props.loading, [props.loading]);
  const HATA = useMemo(() => props.hata, [props.hata]);
  const LISTE = useMemo(() => props.sepetKosul, [props.sepetKosul]);
  const LISTE2 = useMemo(() => props.sepetHareket, [props.sepetHareket]);

  const handleDeleteRow = (index) => {
    const isHas = _has(LISTE[index].form, "id");
    if (isHas) {
      props.setDeleteIdDetailIdKosul(LISTE[index].form.id);
      props.openDeleteFormKosul(true);
    } else {
      props.openDeleteFormKosulWithoutId(index);
    }
  }

  const handleDeleteRow2 = (index) => {
    const isHas = _has(LISTE2[index].form, "id");
    if (isHas) {
      props.setDeleteIdDetailIdHareket(LISTE2[index].form.id);
      props.openDeleteFormHareket(true);
    } else {
      props.openDeleteFormHareketWithoutId(index);
    }
  }


  return (
    <>
      {!LOADING &&
        <>
          {HATA && (
            <Hata />
          )}
          {!HATA && (
            <>
              <div className={classes.layoutContainer}>

                <div className={classes.table}>
                  <div className={classes.tablehead}>
                    <div className={classes.tablerow}>
                      <div className={classes.tablecell}>
                        <Tooltip title="Sepet Koşul Ekle">
                          <IconButton
                            size="small"
                            disableRipple
                            onClick={() => props.onSepetKosulAddNew()}
                          >
                            <IconAdd />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className={classes.tablecell}>Sepet Koşulları</div>
                    </div>
                  </div>
                  <div className={classes.tablebody}>
                    {LISTE && LISTE.map((kosul, index) => (
                      <div className={classes.tablerow} key={`sepetkosul-${index}`}>
                        <div className={classes.tablecell}>
                          <Tooltip title="Sil">
                            <IconButton
                              size="small"
                              disableRipple
                              onClick={() => handleDeleteRow(index)}
                            >
                              <IconDelete />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div className={classes.tablecell}>
                          <div className={classes.layoutFormItem}>
                            <label className={classes.layoutFormItemLabel} htmlFor="tanim">Sepet Toplam</label>
                            <Tooltip title={kosul?.errors?.kosul_info?.toplam ? kosul.errors.kosul_info.toplam[0] : _isNil(kosul?.form?.kosul_info?.toplam) ? "" : kosul.form.kosul_info.toplam}>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                value={kosul.form.kosul_info.toplam}
                                error={_has(kosul.errors?.["kosul_info"], "toplam")}
                                onChange={(e) => props.onChangeFormKosul(index, "kosul_info", "toplam", e.target.value)}
                                onBlur={() => handleInputErrors2(kosul.form, 'kosul_info', (err) => props.onCheckErrorsKosul(err, index), 'toplam')}
                              ></TextField>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className={classes.table}>
                  <div className={classes.tablehead}>
                    <div className={classes.tablerow}>
                      <div className={classes.tablecell}>
                        <Tooltip title="Sepet Hareket Ekle">
                          <IconButton
                            size="small"
                            disableRipple
                            onClick={() => props.onSepetHareketAddNew()}
                          >
                            <IconAdd />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className={classes.tablecell}>Sepet Hareketleri</div>
                    </div>
                  </div>
                  <div className={classes.tablebody}>
                    {LISTE2 && LISTE2.map((hareket, index) => (
                      <div className={classes.tablerow} key={`sepethareket-${index}`}>
                        <div className={classes.tablecell}>
                          <Tooltip title="Sil">
                            <IconButton
                              size="small"
                              disableRipple
                              onClick={() => handleDeleteRow2(index)}
                            >
                              <IconDelete />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div className={classes.tablecell}>
                          <div className={classes.layoutFormItem}>
                          <label className={classes.layoutFormItemLabel} htmlFor="tanim">Sepet İndirim</label>
                            <Tooltip title={hareket?.errors?.hareket_info?.indirim ? hareket.errors.hareket_info.indirim[0] : _isNil(hareket?.form?.hareket_info?.indirim) ? "" : hareket.form.hareket_info.indirim}>
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                value={hareket.form.hareket_info.indirim}
                                error={_has(hareket.errors?.["hareket_info"], "indirim")}
                                onChange={(e) => props.onChangeFormHareket(index, "hareket_info", "indirim", e.target.value)}
                                onBlur={() => handleInputErrors2(hareket.form, 'hareket_info', (err) => props.onCheckErrorsHareket(err, index), 'indirim')}
                              ></TextField>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              </div>

            </>
          )}
        </>
      }
    </>
  );
});

export default SepetKosulHareket;
