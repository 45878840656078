import React, { useState, useLayoutEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import {
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
} from '@material-ui/core';

import {
  Alert,
} from '@material-ui/lab';

import {
  LockOutlined as IconLockOutlined,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

import django from 'api/django';

import AuthBase from './base/AuthBase';

const IS_DEV = process.env.NODE_ENV === 'development';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Login = (props) => {
  const [username, setUsername] = useState(IS_DEV ? 'admin@egan.com.tr' : '');
  const [password, setPassword] = useState(IS_DEV ? '123456*1ab2' : '');
  const [error, setError] = useState('');

  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();

  // check authentication
  useLayoutEffect(() => {
    if (django.isAuthenticated()) {
      history.push('/');
    }
  }, [history]);

  const handleSubmit = (e) => {
    e.preventDefault();

    django.post('/token/', { username, password }).then((response) => {
      if (django.authenticate(response.data)) {
        if (query.get('next')) {
          window.location.href = process.env.PUBLIC_URL + query.get('next');
        } else {
          window.location.href = process.env.PUBLIC_URL + '/';
        }
      }
    }).catch(error => {
      if (error.response) {
        setError(error.response.data.detail);
      } else {
        setError('Beklenmeyen bir hata oluştu');
      }
    });
  };

  return (
    <AuthBase>
      <Avatar className={classes.avatar}>
        <IconLockOutlined />
      </Avatar>

      <Typography component="h1" variant="h5">
        Oturum Aç
      </Typography>

      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="E-Posta Adresi"
          name="email"
          autoComplete="email"
          autoFocus
          value={username}
          onChange={e => setUsername(e.target.value)}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Parola"
          type="password"
          id="password"
          value={password}
          autoComplete="current-password"
          onChange={e => setPassword(e.target.value)}
        />

        {error && (
          <Alert severity="error">{error}</Alert>
        )}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >OTURUM AÇ</Button>

        <Grid container>
          <Grid item xs>
            <Link to="/password-reset#" variant="body2">
              Parolamı Unuttum
            </Link>
          </Grid>
        </Grid>
      </form>
    </AuthBase>
  );
};

export default Login;
